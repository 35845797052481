import React from 'react';
import { useTheme } from '@emotion/react';
import config from '../config';

const EmailFooter = ({ theme }) => {

    const year = new Date();

    return (
        <>
            <tr>
                <td colSpan="2"
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        textAlign: 'center'
                    }}
                >
                    <p style={{ fontSize: '14px' }} >{`${config.APPTITLE} - copyright © ${year.getFullYear()}`}<br />
                        Designed &amp; Developed by <a style={{ color: theme.palette.primary.contrastText }} title="Cambridge Applcation Developers" href="https://www.design-monkey.co.uk" rel="noreferrer" target="_blank"> Design Monkey Media ltd</a>.<br />
                        Powered by <a style={{ color: theme.palette.primary.contrastText }} href='https://aws.amazon.com/' rel="noreferrer" target="_blank">AWS</a></p>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <p style={{ fontSize: '10px' }}>*This email and any attachments are confidential and intended solely for the named recipient. If you have received this email in error, please notify the sender immediately and delete it from your system. Unauthorized use, disclosure, or distribution of this email is strictly prohibited. Please note that email communication may not be secure. Please exercise caution when sharing sensitive information.</p>
                </td>
            </tr>
        </>
    )
};

export default EmailFooter;  