import React, { useContext, useState } from 'react';
import {
    Grid,
    TextField,
    Button
} from '@mui/material';
import {
    faPaperPlane,
} from '@fortawesome/pro-light-svg-icons';
import { UserContext } from '../store/UserContext';
import GlobalDialog from '../reusable/GlobalDialog';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import setLanguageText from '../language/setLanguageText';
import { LayoutContext } from '../store/LayoutContext';
import useActivityLog from '../users/activity/useActivityLog';
import AttachmentEmail from './AttachmentEmail';
import { useTheme } from '@emotion/react';
import axiosRequest from '../axios/axoisRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import ReactDOMServer from 'react-dom/server';
import config from '../config';
import DialogLoader from '../reusable/DialogLoader';

const filter = createFilterOptions();

const EmailPDF = ({ downloadObject, title, text }) => {

    const [emailOpen, setEmailOpen] = useState(false);
    const { user } = useContext(UserContext);
    const [emailList, setEmalList] = useState();
    const [emails, setEmails] = useState('');
    const { updateActivityLog } = useActivityLog();
    const { app, theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme()

    const send_email = useMutation(({ title, emails }) => {

        let recipient = emails.map(email => email.email);

        var blob = new Blob([downloadObject.blob], { type: "application/pdf;charset=utf-8" });

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64data = reader.result;
            //console.log(base64data);
            //render the email
            const emailString = ReactDOMServer.renderToString(<AttachmentEmail text={text} title={title} logo={layoutTheme.email_logo} theme={theme} />)
            //console.log(order);
            return axiosRequest({
                gateway: config.factoryAPI.URL,
                endpoint: 'email',
                api_key: user.api_key,
                method: 'post',
                body: {
                    email: recipient,
                    html: emailString,
                    subject: title,
                    attachment: base64data
                },
            });
        }
    }, {
        onSuccess: (data) => {
            updateActivityLog.mutate({
                activity_details: {
                    area: app.area,
                    sent_to: emails.map(email => email.email),
                    email: text,
                    title: title,
                },
                activity_type: "emailed pdf"
            })
        }
    });

    const handleSubmit = () => {
        send_email.mutate({ "title": title, "emails": emails });
    }

    const openEmailDialog = e => {
        setEmailOpen(true)
    };

    const closeEmailDialog = e => {
        setEmailOpen(false)
    };

    const updateEmails = (e, newValue) => {
        if (typeof newValue === 'string') {
            setEmails({ title: newValue })
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setEmails({ title: newValue.inputValue })
        } else {
            setEmails(newValue)
        }
    }

    return (
        <>
            <Grid item>
                <Button
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                    //iconSize="1x"
                    color="primary"
                    onClick={openEmailDialog}
                >
                    Email PDF
                </Button>
            </Grid>
            <GlobalDialog
                open={emailOpen}
                onClose={closeEmailDialog}
                title="Email Service Report"
                buttonClick={handleSubmit}
                buttonTitle="Email Service Report"
                successMessage="Email Service Report Success."
                fullWidth
                maxWidth="md"
            >
                <Grid item>
                    <Autocomplete
                        aria-required
                        fullWidth
                        multiple // for having mutilple select
                        value={emails || []}
                        onChange={updateEmails}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    email: inputValue,
                                    title: `Add "${inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        //selectOnFocus
                        //clearOnBlur
                        handleHomeEndKeys
                        id="supervisor_email"
                        options={emailList || []}
                        getOptionLabel={option => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.email;
                        }}
                        renderOption={(props, option) => <li {...props} key={option.id} >{option.email}</li>}
                        //freeSolo
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    //required
                                    label={setLanguageText("Select or add multiple email addresses")}
                                    variant="outlined"
                                    type="text"
                                //autoComplete="true"
                                />
                            )
                        }}
                    />
                </Grid>
                {send_email.isLoading || send_email.isSuccess || send_email.isError ?
                    <DialogLoader
                        isLoading={send_email.isLoading}
                        mutation={send_email}
                        loadingMessage="Sending Email"
                        successMessage="Email Sent"
                        fixed={true}
                        closeDialog={() => {
                            closeEmailDialog();
                        }}
                    />
                    : null}
            </GlobalDialog>
        </>
    )
}

export default EmailPDF;