import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, ListItemButton, ListItemIcon, Typography, useMediaQuery } from '@mui/material';
import setLanguageText from '../../language/setLanguageText';
import { Link } from 'react-router-dom';

export default function AreaMenuItem({ item, selected, disabled, handleClose }) {

	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

	return (
		<Box
			sx={{
				padding: '2em 0',
				opacity: (disabled && !item.externalLink) ? 0.3 : 1,
				'& a': {
					color: (theme) => selected ? theme.palette.primary.main : theme.palette.text.primary,
					fontWeight: selected ? 'bold' : 'normal',
					transition: 'all 0.3s ease',
					textDecoration: 'none',
					'&:hover': {
						color: (theme) => (disabled && !item.externalLink) ? "inherit" : theme.palette.primary.main
					}
				},
			}}
		>
			{item.externalLink ?
				<a href={item.externalLink} target="_blank" rel="noreferrer" >
					<Grid
						spacing={mdUp ? 2 : 0}
						container direction='column' alignItems='center'>
						<Grid item >
							<FontAwesomeIcon style={{ margin: '0 auto' }} icon={item.icon} size={mdUp ? '3x' : '2x'} />
						</Grid>
						<Grid item>
							<Typography
								sx={{
									fontSize: mdUp ? '18px' : '14px',
								}}
								variant='body1'
							>{setLanguageText(item.title)}</Typography>
						</Grid>
					</Grid>
				</a>
				:
				<Link
					to={!disabled && item.link}
					onClick={() => !disabled && handleClose(item) }

				>
					<Grid
						spacing={mdUp ? 2 : 0}
						container direction='column' alignItems='center'>
						<Grid item >
							<FontAwesomeIcon style={{ margin: '0 auto' }} icon={item.icon} size={mdUp ? '3x' : '2x'} />
						</Grid>
						<Grid item>
							<Typography
								sx={{
									fontSize: mdUp ? '18px' : '14px',
								}}
								variant='body1'
							>{setLanguageText(item.title)}</Typography>
						</Grid>
					</Grid>
				</Link>
			}
		</Box>
	);
};
