import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import config from '../config';
import { useMutation } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import DialogLoader from '../reusable/DialogLoader';
import AllForms from '../users/forms/AllForms';
import setLanguageText from '../language/setLanguageText';
import userFormObject from '../users/helpers/userFormObject';
import userDBObject from '../users/helpers/userDBObject';
import GlobalDialog from '../reusable/GlobalDialog';
import * as Yup from "yup"

const editValdation = Yup.object({
	buyers: Yup.object(),
	b_group: Yup
		.mixed().nullable(true)
		.when("buyers", {
			is: true,
			then: () => Yup.object().nullable().required("Please select a user level")
		}),
	engineers: Yup.object(),
	e_group: Yup
		.mixed().nullable(true)
		.when("engineers", {
			is: true,
			then: () => Yup.object().nullable().required("Please select a user level")
		}),
	vend: Yup.object(),
	coolantcare: Yup.object(),
	c_group: Yup
		.mixed().nullable(true)
		.when("coolantcare", {
			is: true,
			then: () => Yup.object().nullable().required("Please select a user level")
		}),
	parametric: Yup.object(),
	p_group: Yup
		.mixed().nullable(true)
		.when("parametric", {
			is: true,
			then: () => Yup.object().required("Please select a user level").nullable()
		}),
	application: Yup.object(),
	a_group: Yup
		.mixed().nullable(true)
		.when("application", {
			is: true,
			then: () => Yup.object().required("Please select a user level").nullable()
		}),
	solution: Yup.object(),
	s_group: Yup
		.mixed().nullable(true)
		.when("solution", {
			is: true,
			then: () => Yup.object().required("Please select a user level").nullable()
		}),
	fixtures: Yup.object(),
	f_group: Yup
		.mixed().nullable(true)
		.when("fixtures", {
			is: true,
			then: () => Yup.object().required("Please select a user level").nullable()
		}),
	design: Yup.object(),
	d_group: Yup
		.mixed().nullable(true)
		.when("design", {
			is: true,
			then: () => Yup.object().required("Please select a user level").nullable()
		})
});

const EditUserPermissions = ({ template, open, setOpen, handleClose }) => {

	const { user } = useContext(UserContext);
	const [templateForm, setTemplateForm] = useState();

	const update_template = useMutation((dbTemplate) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'templates',
			api_key: user.api_key,
			method: 'post',
			body: {
				"template_id": template.id,
				"template": dbTemplate
			},
		})
	}, {
		onSuccess: (data) => {
			closeRefreshDialog()
		}
	})

	const handleSubmit = (values) => {
		//console.log(values)
		let dbTemplate = userDBObject(values)
		console.log(dbTemplate[0])
		update_template.mutate(dbTemplate[0].user_permissions)
	}

	useEffect(() => {

		if (template?.template) {

			let formObject = userFormObject({
				"user_permissions": template.template
			})
			console.log(formObject[0])
			setTemplateForm(formObject[0])
		}
	}, [template])

	const closeRefreshDialog = () => {
		setOpen(false)
		handleClose()
	}

	return (
		<>
			{templateForm ?
				<Formik
					initialValues={templateForm}
					validationSchema={editValdation}
					onSubmit={handleSubmit}
				>
					{({ submitForm, values , errors }) => {
						//console.log(values)
						//console.log(errors)
						return (
							<GlobalDialog
								open={open}
								onClose={() => closeRefreshDialog(false)}
								title={`Update Template`}
								buttonClick={() => submitForm()}
								buttonTitle="Update Template"
								maxWidth="lg"
							>
								<AllForms template />
								{update_template.isLoading || update_template.isSuccess || update_template.isError ?
									<DialogLoader
										isLoading={update_template.isLoading}
										mutation={update_template}
										loadingMessage={setLanguageText("Updating Template...")}
										successMessage={setLanguageText("Template Updated")}
									//closeDialog={closeRefreshDialog}
									/>
									: null}
							</GlobalDialog>
						)
					}}
				</Formik >
				: null}
		</>
	);
};

export default EditUserPermissions;
