import React, { useEffect, useState } from 'react';
import EmailContainer from './EmailContainer';
import EmailHeader from './EmailHeader';
import EmailFooter from './EmailFooter';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DesignCommentEmail = ({ comment, theme, logo }) => {

    const demo_comment = {
        "name": "Test",
        "surname": "User",
        "company_id": 0,
        "company_name": "Test Company",
        "comment_submit_date": new Date(),
        "request_id": 0,
        "comment_details": "This is the comment added to your design request.",
    }

    return (
        <EmailContainer>
            <table style={{ width: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <EmailHeader logo={logo} theme={theme} />
                <tbody>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h1 style={{ marginBottom: 10, color: theme.palette.primary.main }}>New Comment Added.</h1>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr><td valign="top" align="center" colSpan={2}>

                        <p><strong>Added by: </strong> {!comment ? demo_comment.name : comment?.comment?.comment_user?.user_details.name} {!comment ? demo_comment.surname : comment?.comment?.comment_user?.user_details?.surname} </p>
                        <p><strong>Data: </strong>  {!comment ? new Date().toLocaleDateString() : new Date(comment.comment.comment_submit_date).toLocaleDateString()} @ {!comment ? new Date().toLocaleTimeString() : new Date(comment.comment.comment_submit_date).toLocaleTimeString()}</p>
                        <p><strong>Company: </strong>{!comment ? demo_comment.company_name : comment?.comment?.company_name}</p>

                    </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" align="center" colSpan={2}>
                            <div style={{ display: "block", backgroundColor: '#efefef', width: '600px', padding: '12px' }} >
                                <p style={{ fontSize: '20px' }}>&quot;{!comment ? demo_comment.comment_details : comment?.comment?.comment_details ? comment.comment.comment_details : 'No request details'}&quot;</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} colSpan={2}>
                            <div style={{ borderRadius: '4px', padding: '10px', backgroundColor: theme.palette.primary.main, margin: '0 auto', width: '220px' }}>
                                <FontAwesomeIcon style={{ marginRight: '10px', width: '20px', height: '20px', color: '#fff', float: 'left' }} icon={faMagnifyingGlass} />
                                {!comment ?
                                    <a style={{ textDecoration: 'none', color: theme.palette.primary.contrastText, width: 'auto' }} >
                                        View Latest Comment
                                    </a>
                                    :
                                    <a style={{ textDecoration: 'none', color: theme.palette.primary.contrastText, width: 'auto' }}
                                        href={`${window.location.origin}/design/view?request=${comment?.request.request_id}&company=${comment?.comment?.company_id}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        View Latest Comment
                                    </a>
                                }
                            </div>
                        </td>
                    </tr>

                    <EmailFooter theme={theme} />
                </tbody>
            </table >
        </EmailContainer>
    )
};

export default DesignCommentEmail;

