import React, { useCallback, useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_APPLICATION_FILTERS, UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";

const CheckboxBoolean = ({ field, updateValue }) => {

    const { selected, dispatchSelected } = useContext(SelectedContext);
    const [checked, setChecked] = useState(false);
    const [hide, setHide] = useState(true);

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let appSearch = JSON.parse(urlParams.get("app"));
            //console.log(appSearch)
            if (appSearch && Object.values(appSearch).length ) {
                let found = appSearch[0].filter(element => element.includes(`${field.Abbreviation}§`));
                //console.log(found)
                if (Object.values(found).length > 0) {
                    //console.log(found)
                    dispatchSelected({
                        type: UPDATE_PARAMETRIC_FILTERS,
                        payload: {
                            'Field': field.Field, 'Values': [1],  'Name' : field.Name, 'Ref' : field.Ref
                        }
                    });
                    setChecked(true)
                }
            }
        }
    }, []);

    const updateSelected = useCallback((value) => {

        if (value) {
            updateValue({ 'fieldAbb': field.Abbreviation, 'value': `${value}${field.Unit}` })
        } else {
            updateValue({ 'fieldAbb': field.Abbreviation })
        }
        dispatchSelected({
            type: UPDATE_APPLICATION_FILTERS,
            payload: {
                'Field': field.Field, 'Values': [value ? 1 : '']
            }
        });
        setChecked(value)

    }, [field, dispatchSelected, updateValue])

    //hide fields based on the logic
    useEffect(() => {
        if (field.Condition) {
            let showField = showFieldLogic(field.Condition, selected.applicationFilters)
            setHide(!showField)
            if (!showField && checked) {
                //clear if input has a value selected and the field become hidden
                //console.log('remove')
                setLocalValue(null)
                setChecked(false)
                updateSelected(false)
            }
        } else {
            //if there are not conditions show field
            setHide(false)
        }
    }, [field.Condition, selected.applicationFilters])

    return (
        <>
            {field.Name ?
                <FormControlLabel
                    control={
                        <Checkbox checked={checked} onChange={(e, value) => updateSelected(value)} name={field.ID} />
                    }
                    label={field.Name}
                />
                : null
            }
        </>

    );
};

export default CheckboxBoolean;
