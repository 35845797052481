import { snakeCase } from 'lodash-es';

const extractGroups = machines => {
  // if fetched Company doesn't have any group
  if (!machines.length) {
    return machines;
  }

  // get machine group names
  const groupNames = machines.map(machine => machine.machine_group);
  const uniqueGroups = [...new Set(groupNames)];
  // format for React Select
  const groups = uniqueGroups.map(group => {
    return {
      group_id: snakeCase(group),
      group_name: group
    };
  });
  return groups;
};
export default extractGroups;
