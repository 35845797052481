import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField, Button, Autocomplete, ListItemText, Box, Typography, Card, CardHeader, CardContent } from '@mui/material';
import config from '../config';
import { useQuery } from 'react-query';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import { LoadingButton } from '@mui/lab';
import { CompanyContext } from '../store/CompanyContext';

const GetTemplate = ({ type, setTemplate, buttonText, loading, showCard }) => {


    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const [selected, setSelected] = useState(null);

    //console.log(selectedCompany)

    const { data, isFetching } = useQuery(['templates', type],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'get',
            params: {
                "template_type": JSON.stringify(type),
            }
        }), {
        enabled: !!type,
        retry: 1
    })

    useEffect(() => {
        if (data && selectedCompany) {
            if (type[0] === "catalogue_brands") {
                if (selectedCompany?.company_settings?.catalogue_template?.id) {
                    let found = data.find(template => template.id === selectedCompany.company_settings.catalogue_template.id)
                    setSelected(found)
                }
            } else if (type[0] === "engineer_brands") {
                if (selectedCompany?.company_settings?.engineer_template?.id) {
                    let found = data.find(template => template.id === selectedCompany.company_settings.engineer_template.id)
                    setSelected(found)
                }
            } else if (type[0] === "opensearch_brands") {
                if (selectedCompany?.company_settings?.opensearch_template?.id) {
                    let found = data.find(template => template.id === selectedCompany.company_settings.opensearch_template.id)
                    setSelected(found)
                }
            }
        }
        return () => {
            setSelected(null)
        }

    }, [selectedCompany, data, type])

    //console.log(selected)


    return (
        <Grid container item xs="auto" spacing={2} alignItems="center" justifyItems="flex-end">
            {showCard ?
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant='h6'>
                                    <strong>Current Template: </strong> {selected?.name}
                                </Typography>
                            }
                            //subheader={"Current calalogue visibility template"}
                            sx={{
                                background: theme => theme.palette.cards.header,
                                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                        />
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Details: </strong> {selected?.description}
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                : null}
            <Grid item>
                <Autocomplete
                    disableClearable
                    id="templates"
                    value={selected || null}
                    options={data || []}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option?.id}>
                            <ListItemText
                                primary={option.name}
                                secondary={option.description}
                            />
                        </Box>
                    )}
                    onChange={(event, newValue) => setSelected(newValue)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                                padding: 0
                            }}
                            placeholder='Select a Template...'
                        />
                    )}
                    sx={{
                        minWidth: 240,
                        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                            padding: '1px'
                        },
                        '& legend': {
                            display: 'none'
                        }
                    }}
                    loading={isFetching}
                    disabled={isFetching}
                    loadingText="Updating Brands...."
                />
            </Grid>
            <Grid item xs>
                <LoadingButton
                    variant='contained'
                    color="primary"
                    onClick={e => setTemplate(selected)}
                    disabled={!selected}
                    size='large'
                    loading={loading ? loading : false}
                >
                    {buttonText}
                </LoadingButton>
            </Grid>

        </Grid>
    );
};

export default GetTemplate;
