import React from "react";
//import { CompanyProvider } from "./store/CompanyContext";
import { MachineProvider } from "../coolantcare/store/MachineContext";
import { AdditiveProvider } from "../coolantcare/store/AdditiveContext";
import { CoolantProvider } from "../coolantcare/store/CoolantContext";
import { BrandsProvider } from "../coolantcare/store/BrandsContext";
import GlobalMeta from "../reusable/GlobalMeta";

const AdminRoutesWrap = (props) => {

    return (
        <MachineProvider>
            <AdditiveProvider>
                <CoolantProvider>
                    <BrandsProvider>
                        <GlobalMeta title={props.title} />
                        {props.children}
                    </BrandsProvider>
                </CoolantProvider>
            </AdditiveProvider>
        </MachineProvider>
    );
};

export default AdminRoutesWrap;
