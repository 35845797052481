import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    flexRender
} from '@tanstack/react-table'
import config from '../../../config'
import { UserContext } from '../../../store/UserContext'
import axiosRequest from '../../../axios/axoisRequest';
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import Filter from '../../../tables/Filter'
import CompanyEnable from './CompanyEnable'


export default function CompaniesTable() {

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')
    const { user } = useContext(UserContext);
    const defaultData = useMemo(() => [], [])
    const [data, setData] = useState();


    function useSkipper() {
        const shouldSkipRef = useRef(true)
        const shouldSkip = shouldSkipRef.current

        // Wrap a function with this to skip a pagination reset temporarily
        const skip = React.useCallback(() => {
            shouldSkipRef.current = false
        }, [])

        useEffect(() => {
            shouldSkipRef.current = true
        })

        return [shouldSkip, skip]
    }

    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

    const columns = useMemo(() => [
        {
            accessorKey: 'company_name',
            header: () => '',
            name: 'Company Name',
            cell: info => info.getValue(),
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.length;
                return <strong>{total} Companies</strong>
            },
            //enableColumnFilter: false,
        },
        {
            header: () => 'Users',
            accessorKey: 'total_users',
            cell: info => info.getValue(),
            enableColumnFilter: false,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.total_users !== '-' ? parseInt(row.original.total_users) : 0), 0);
                return <strong>{total}</strong>;
            },
        },
        {
            header: () => 'Machines',
            accessorKey: 'total_machines',
            cell: info => info.getValue(),
            enableColumnFilter: false,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.total_machines !== '-' ? parseInt(row.original.total_machines) : 0), 0);
                return <strong>{total}</strong>;
            },
        },
        {
            header: () => 'Is Disabled',
            accessorKey: 'company_disabled',
            cell: ({ row, table }) => {
                return (
                    <CompanyEnable row={row} table={table ? table : null} />
                );
            },
            enableColumnFilter: false,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.company_disabled  ? parseInt(row.original.company_disabled) : 0), 0);
                return <strong>{total}</strong>;
            },
        }

        //, 
        // {
        //     header: () => 'Actions',
        //     accessorKey: 'order',
        //     cell: info => {
        //         //console.log(info)
        //         return (
        //             <Grid container spacing={1}>
        //                 <DotsMenu>
        //                     <ViewOrder order={info.row.original} />
        //                     <ContinueCheckout order={info.row.original} title="View Order" buttonText="Buy Again" />
        //                 </DotsMenu>
        //             </Grid>
        //         )
        //     },

        //     footer: props => props.column.id,
        //     enableColumnFilter: false,
        // }
    ], [])

    const dataQuery = useQuery({
        queryKey: ['companies'],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.coolantAPI.URL,
            endpoint: 'companies',
            api_key: user.api_key,
            method: 'get',
            params: {
                table: true,
                //companies_id: user.user.user_permissions.access.companies_id,
                user_level: user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator ? 9 : null,
            }
        }),
        enabled: !!user?.user?.user_permissions
    })

    useEffect(() => {
        if (dataQuery.data) {
            setData(dataQuery.data)
        }
    }, [dataQuery.data])


    const table = useReactTable({
        data: data || defaultData,
        columns,
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        autoResetPageIndex,
        meta: {
            updateData: (rowIndex, columnId, value) => {
                // Skip page index reset until after next rerender
                skipAutoResetPageIndex()
                setData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            }
                        }
                        return row
                    })
                )
            },
        },
        debugTable: true,
    })

    const { pageSize, pageIndex } = table.getState().pagination

    return (

        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} >
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100, 200]}
                        component="div"
                        count={table.getFilteredRowModel().rows.length}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    //ActionsComponent={TablePaginationActions}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                            <TableHead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow
                                        key={headerGroup.id}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {headerGroup.headers.map((header, i) => {
                                            return (
                                                <TableCell key={i} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {header.column.getCanFilter() ? (
                                                                <div>
                                                                    <Filter column={header.column} table={table} />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {dataQuery.isFetching ?
                                    //create array from a number
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>

                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={36} variant='rounded' /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !dataQuery.isFetching && dataQuery?.data.length > 0 ? table.getRowModel().rows.map(row => {
                                        //console.log(row)
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => (theme) => row.original.company_disabled ? theme.palette.table.disabled : theme.palette.table.hover,
                                                    },
                                                    backgroundColor: (theme) => row.original.company_disabled ? theme.palette.table.disabled : theme.palette.table.row,
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                        :

                                        <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No orders found</Alert>

                                }
                            </TableBody>
                            <TableFooter sx={{ background: (theme) => theme.palette.table.footer }} >
                                {table.getFooterGroups().map(footerGroup => (
                                    <TableRow key={footerGroup.id}>
                                        {footerGroup.headers.map(header => (
                                            <TableCell key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.footer,
                                                        header.getContext()
                                                    )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}
