import React from 'react';
import EmailContainer from './EmailContainer';
import EmailHeader from './EmailHeader';
import EmailFooter from './EmailFooter';
import config from '../config';

const SendQuoteEmail = ({ order, theme, logo }) => {

    const demo_order = {
        "order_user": {
            "user_name": "Test User",
            "user_id": 0,
            "user_email": "test.user@factory.com"
        },
        "order_company": {
            "company_id": 0,
            "company_name": "Test Company",
        },
        "order_items":
            [{
                "product_id": "221114171037156",
                "product_name": "4100 12.030mm 6 STR/RHC 40mm F/L REAMER",
                "product_image": [{ "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/221114171037156?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=s41" }],
                "price_incl_discount": 56.45,
                "product_stock": 2,
                "product_number": "96782",
                "product_company": "GARR",
                "product_quantity": "2",
                "product_pack_size": 1,
                "product_attributes": []
            }, {
                "product_id": "49333e63-5fdf-4051-9a07-64b0a9aef793",
                "product_name": "Parting & grooving insert (double sided)",
                "product_image": [{ "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/49333e63-5fdf-4051-9a07-64b0a9aef793?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=s41" }, { "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/49333e63-5fdf-4051-9a07-64b0a9aef793?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=s3" }, { "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/49333e63-5fdf-4051-9a07-64b0a9aef793?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=step_png3d" }],
                "price_incl_discount": 15.15,
                "product_stock": 0,
                "product_number": "ZTKD0608-MG YBG302",
                "product_company": "ZCC",
                "product_quantity": "1",
                "product_pack_size": 1,
                "product_attributes": []
            }],
        "order_total": {
            "total_quantity": 3,
            "total_price_incl_discount": 127,
            "total_price_rrp": 150,
        },
        "order_number": "TEST-ORDER-001"
    }

    return (
        <EmailContainer>
            <table style={{ width: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <EmailHeader logo={logo} theme={theme} />
                <tbody>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h1 style={{ color: theme.palette.primary.main, marginBottom: 0 }}>Quote for Authorisation.</h1>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" align="center" colSpan="2">
                            <p><strong>Quote from: </strong>{!order ? demo_order.order_user.user_name : order?.order_user?.user_name} ({!order ? demo_order.order_user.user_email : order?.order_user?.user_email})</p>
                            <p><strong>Date: </strong> {new Date().toLocaleDateString()} @ {new Date().toLocaleTimeString()}</p>
                            <p><strong>Company: </strong>{!order ? demo_order.order_company.company_name : order?.order_company?.company_name}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>

                    {order?.order_items ?
                        <>
                            {order.order_items.map((item, i) => (
                                <tr key={i}>

                                    <td colSpan="1"  >
                                        <p><strong>Name: </strong>{item?.product_name ? item.product_name : item.product_number}<br />
                                            {item?.product_id ?
                                                <><strong>{config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No"}: </strong>{item?.product_id}<br /></>
                                                : null}
                                            {item?.product_number ?
                                                <><strong>Manufacturer No (J21): </strong>{item.product_number}<br /></>
                                                : null}
                                            {item?.product_manufacturer ?
                                                <><strong>Manufacturer: </strong>{item.product_manufacturer}<br /></>
                                                : null}
                                            {/* {item?.product_id !== -1 ?
                                       <><strong>Article ID: </strong>{item?.product_id}<br /></>
                                       : null} */}
                                            <strong>Quantity: </strong>{item?.product_quantity} x £{item?.price_incl_discount.toFixed(2)} each</p>
                                        <p
                                            style={{
                                                display: "block",
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                padding: '12px',
                                                fontSize: '16px'
                                            }}
                                        ><strong>Total Item Price: </strong>£{(item.price_incl_discount * item?.product_quantity).toFixed(2)} </p>
                                    </td>
                                    {item?.product_image ?
                                        <td colSpan="1" align="center" >
                                            {item.product_image.length > 0 ?
                                                <div style={{ width: '200px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                                    <img style={{ width: '200px', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={item.product_image[0].url} />
                                                </div>
                                                : null}
                                        </td>
                                        : null}
                                </tr>
                            ))}
                        </>
                        : demo_order?.order_items ?
                            <>
                                {demo_order.order_items.map((item, i) => (
                                    <tr>
                                        <td colSpan="1" align="center" >
                                            {item.product_image.length > 0 ?
                                                <div style={{ width: '200px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                                    <img style={{ width: '200px', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={item.product_image[0].url} />
                                                </div>
                                                : null}
                                        </td>
                                        <td colSpan="1"  >
                                            <p><strong>Name: </strong>{item?.product_name}<br />
                                                <strong>Article Number: </strong>{item?.product_number}<br />
                                                <strong>Company Code: </strong>{item?.product_company}<br />
                                                <strong>Article ID: </strong>{item?.product_id}<br />
                                                <strong>Quantity: </strong>{item?.product_quantity} x £{item?.price_incl_discount} each</p>
                                            <p
                                                style={{
                                                    display: "block",
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    padding: '12px',
                                                    fontSize: '16px'
                                                }}
                                            ><strong>Total Item Price: </strong>£{item.price_incl_discount * item?.product_quantity} </p>
                                        </td>
                                    </tr>
                                ))}
                            </>
                            : null}
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" align="center" colSpan="2">
                            <p
                                style={{
                                    display: "block",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    padding: '12px',
                                    fontSize: '24px'
                                }}
                            ><strong>Total Order Price: </strong>£{!order ? demo_order.total_price_incl_discount.toFixed(2) : order?.order_total?.total_price_incl_discount.toFixed(2)}</p>
                            {order.order_total.total_price_incl_discount < order.order_total.total_price_rrp ?
                                <p
                                    style={{
                                        display: "inline-block",
                                        backgroundColor: theme.palette.success.main,
                                        color: theme.palette.primary.contrastText,
                                        padding: '12px 24px',
                                        margin: '0px auto',
                                        fontSize: '16px'
                                    }}
                                ><strong>Total Saving: </strong> £{(order.order_total.total_price_rrp - order.order_total.total_price_incl_discount).toFixed(2)}</p>
                                : null}
                            <p><strong>Total Item Quantity: </strong> {!order ? demo_order.order_total.total_quantity : order?.order_total?.total_quantity}</p>
                        </td>
                    </tr>
                    <EmailFooter theme={theme} />
                </tbody>
            </table>
        </EmailContainer >
    )
};

export default SendQuoteEmail;  