import { faDownload, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React, {  useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';
import DesignPDF from '../../pdf/DesignPDF';
import setLanguageText from '../../language/setLanguageText';
const Buffer = require('buffer').Buffer;

const DesignPDFDialog = ({ data }) => {

	const [open, setOpen] = useState(false);
	const [request, setRequest] = useState();
	const [downloadObject, setDownloadObject] = useState(false);
	const [error, setError] = useState();
	const [techImage, setTechImage] = useState();

	const closeModal = () => {
		setTechImage()
		setOpen(false)
	}

	const downloadButton = () => {
		var blob = new Blob([downloadObject.blob], { type: "application/pdf;charset=utf-8" });
		saveAs(blob, `design.pdf`);
	}

	useEffect(() => {
		if (data) {
			setRequest(data)
		}
	}, [data])

	return (

		<>
			<Button
				fullWidth
				variant="contained"
				color="success"
				onClick={() => {
					axios({
						url: request.request_wizard.image,
						method: "GET",
						responseType: 'arraybuffer'
					}).then(response => {
						//console.log("update")
						const buffer = Buffer.from(response.data, 'base64');
						setTechImage(buffer)
						setOpen(true)
					});
				}}
				endIcon={
					<FontAwesomeIcon
						icon={faMagnifyingGlass}
					/>
				}
			>
				{setLanguageText("PDF")}
			</Button>
			{open && techImage ?
				<Dialog
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={open}
					onClose={closeModal}
					error={error}
					fullScreen
					sx={{ margin: '5%' }}
				>
					<DialogTitle >
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid item>{setLanguageText("Design")}</Grid>
							<Grid item>
								<IconButton onClick={closeModal} size="large">
									<Close />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					<Divider />
					<DialogContent sx={{ position: 'relative', padding: '0px', overflow: 'hidden' }}>
						{(request) ?
							<DesignPDF
								orientation="portrait"
								request={request}
								setDownloadObject={setDownloadObject}
								techImage={request.request_wizard.image}
							/>
							: null}
					</DialogContent>
					<Divider />
					{error && (
						<>
							<Grid item  >
								<Alert severity="error" variant="standard">
									{setLanguageText(error)}
								</Alert>
							</Grid>
							<Divider />
						</>
					)}
					<DialogActions >
						<Grid container spacing={1} justifyContent="flex-end">
							{downloadObject &&
								<Grid item>
									<Button
										onClick={e => downloadButton()}
										color="primary"
										variant="contained"
									>
										<FontAwesomeIcon style={{ marginRight: '10px', textDecoration: 'none' }} icon={faDownload} />
										{setLanguageText("Download PDF")}
									</Button>
								</Grid>
							}
							<Grid item>
								<Button onClick={closeModal} variant="contained">
									{setLanguageText("Close")}
								</Button>
							</Grid>

						</Grid>
					</DialogActions>
				</Dialog>
				: null}
		</>
	);
};

export default DesignPDFDialog;
