import React from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import setLanguageText from '../language/setLanguageText';


const Kits = () => {

	return (
		<>

			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("My Kits")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
			<Alert severity="info">{setLanguageText("Create full tool kits with your favourites - Coming Soon")}</Alert>
			</TabContent>
		</>
	);
}

export default Kits;

