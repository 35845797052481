import React, {
    useState,
    useEffect,
    useContext
} from 'react';
import {
    faFilePdf,
    faFileExport
} from '@fortawesome/pro-light-svg-icons';
import {
    Grid,
    Button
} from '@mui/material';
import { buttonStyles } from '../styles/buttonStyles';
import SingleMachinePDF from '../pdf/SingleMachinePDF';
import { PDFViewer } from '@react-pdf/renderer';
import { CompanyContext } from '../../store/CompanyContext';
import GlobalDialog from '../../reusable/GlobalDialog';
import LoadingScreen from '../reusable/LoadingScreen';
import LoadingButton from '../buttons/LoadingButton';
import { MachineContext } from '../store/MachineContext';
import { ChartsContext } from '../store/ChartsContext';
import { LayoutContext } from '../../store/LayoutContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import setLanguageText from '../../language/setLanguageText';

const ExportSingleMachine = () => {

    const { selectedCompany } = useContext(CompanyContext);
    const { selectedMachine } = useContext(MachineContext);
    const { charts } = useContext(ChartsContext);
    const { language } = useContext(LayoutContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [localOrientation, setLocalOrientation] = useState('landscape');
    const [orientation, setOrientation] = useState();
    const buttonStyle = buttonStyles();
    const [showPreview, setShowPreview] = useState(false);
    const [reload, setReload] = useState(false);


    const handleClick = e => {
        setAnchorEl(e.currentTarget);
        setShowPreview(true);
    };

    const handleClose = () => {
        setShowPreview(false);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    //set orintation
    useEffect(() => {
        if (localOrientation) {
            setOrientation(localOrientation);
        }
        return () => {
            setOrientation();
        }
    }, [localOrientation]);

    // //Show Reload Button
    // const reloadPDF = () => {
    //     setReload(false)
    //     //setShowPreview(true); 
    //     //setSelectedOptions(localOptions);
    //     setTimeout(() => {
    //         setShowPreview(true)
    //     }, 100)
    // };

    return (
        <>
            <Button
                disabled={!selectedMachine?.machine_id}
                variant="contained"
                onClick={e => handleClick(e)}
                sx={{ height: '100%' }}
                //size="large"
                endIcon={<FontAwesomeIcon icon={faFileExport} />}
            >
                {setLanguageText("Export Machine")}
            </Button>
            <GlobalDialog
                open={open}
                title="Export Single Machine"
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
                fullHeight
            >
                <SingleMachinePDF
                    company={selectedCompany}
                    charts={charts}
                    machine={selectedMachine}
                    orientation={orientation}
                    language={language}
                />
            </GlobalDialog>
        </>
    );
};

export default ExportSingleMachine;
