import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemButton, ListItemIcon, Tooltip, Typography } from '@mui/material';
import setLanguageText from '../../../language/setLanguageText';
import config from '../../../config';

export default function DrawItemSub({ title, icon, onClick, selected, disabled, showToolTip }) {

	return (
		<>
			<Tooltip title={showToolTip ? "" : title} placement="right" arrow
				PopperProps={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, -30],
							},
						},
					],
				}}>
				<ListItemButton
					selected={selected}
					disabled={disabled}
					onClick={onClick}
					sx={{
						padding: '1em 0',
						transition: 'all 0.3s ease',
						padding: '1em 0',
						color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
						'& svg': {
							color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
						},
						'&.MuiButtonBase-root:hover': {
							background: (theme) => theme.palette.primary.main,
							'& svg': {
								color: (theme) => theme.palette.primary.contrastText
							}
						},
						'&.Mui-selected, &:hover , &.Mui-selected:hover': {
							background: (theme) => theme.palette.primary.main,
							'& svg': {
								color: (theme) => theme.palette.primary.contrastText
							}
						}
					}}
				>
					<ListItemIcon sx={{ width: (theme) => `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }}>
						<FontAwesomeIcon style={{ margin: '0 auto' }} icon={icon} size={'xl'} />
					</ListItemIcon>
					<Typography>{setLanguageText(title.props.children)}</Typography>
				</ListItemButton>
			</Tooltip>
		</>

	);
};

