import React, { useState } from 'react';
import {
    faPaperPlane
} from '@fortawesome/pro-light-svg-icons';
import config from './../../config';
import cognitoPromise from '../../cognito/cognitoPromise';
import { Avatar, MenuItem, Portal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../reusable/Loader';
import setLanguageText from '../../language/setLanguageText';

const ResendEmail = ({ row, handleClose }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()

    const resetMessages = () => {
        setSuccessMessage()
        setErrorMessage()
    }

    const resendEmail = async (e, row) => {
        setIsLoading(true)
        //console.log(row)
        const userParams = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: row.original.Username,
            MessageAction: "RESEND",
        };
        try {
            const sent = await cognitoPromise({ params: userParams, fn: 'adminCreateUser' });
            if (sent) {
                setIsLoading(false)
                setSuccessMessage("Email sent")
                handleClose()
            }

        } catch (err) {
            setIsLoading(false)
            setErrorMessage(err.message)
        }
    };

    return (
        <>
            {row.original.UserStatus === "FORCE_CHANGE_PASSWORD" ?
                <MenuItem
                    variant='contained'
                    onClick={e => resendEmail(e, row)}
                >
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <FontAwesomeIcon size="xs" icon={faPaperPlane} />
                    </Avatar>
                    {setLanguageText("Resend Invite")}
                </MenuItem>
                : null}
            {isLoading || successMessage || errorMessage ?
                <Portal>
                    <Loader
                        isLoading={isLoading}
                        loadingMessage="Sending Email..."
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                        resetMessages={resetMessages}
                    />
                </Portal>
                : null}
        </>
    );
};

export default ResendEmail;
