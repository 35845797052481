
import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';

const CustomRow = ({ header, value, index, isFetching }) => {

	return (

		<Grid key={index} container item xs={12}
			sx={{
				borderBottom: '1px solid #e0e0e0',
				padding: '8px',
				backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'none',
			}}
		>
			<Grid item xs={6} md={7}>
				{!isFetching ?
					<Typography variant='subtitle2' component='p'><strong>{header}</strong></Typography>
					:
					<Typography variant='subtitle2' component='p'><strong><Skeleton width="90%" height={20} /></strong></Typography>
				}
			</Grid>
			<Grid item xs={6} md={5}>
				{!isFetching ?
					<Typography variant='subtitle2' component='p'>{value}</Typography>
					:
					<Typography variant='subtitle2' component='p'><Skeleton width="30%" height={20} /></Typography>
				}
			</Grid>
		</Grid>
	)
}
export default CustomRow
