import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import { CompanyContext } from '../store/CompanyContext';
import { CategoryContext } from '../store/CategoryContext';
import { useQuery } from '@tanstack/react-query';
import createProductObject from "../products/opensearch/createProductObject";

export default function useEnhancedEzbase({ setSelectedProduct, product }) {

	const { user } = useContext(UserContext);

	//console.log("product", product)

	const { data: ezbase_data, isFetching: ezbase_isFecthing } = useQuery({
		queryKey: ['enhance ezbase', product?.product_number, product?.product_id, product?.product_ezbase_code],
		queryFn: ({ signal }) => {

			let query = {
				"query": {
					"bool": {
						"must": [{
								"term": {
									"ArticleNumber.keyword": product?.product_number ? product?.product_number : product.product_supplier_number
								}
							}
						],
						"should": [],
					}
				}
			}
			if (product?.product_ezbase_code) {
				query.query.bool.should.push({
					"match": {
						"SupplierGLN.keyword": {
							"query": product?.product_ezbase_code ? product?.product_ezbase_code : null,
						}
					}
				})
				//query.query.bool.minimum_should_match = 1
			}

			return axiosRequest({
				signal: signal,
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/enhance/ezbase',
				api_key: user.api_key,
				method: 'post',
				body: {
					"query": query
				}
			})
		},
		enabled: (!!product?.product_number || !!product?.product_supplier_number ) && !!product?.product_ezbase_code,
		//enabled: (!!product?.product_number || !!product?.product_supplier_number )&& !product?.product_cim_code, //only try if no cim code is present
		retry: 1
	})

	//EZBASE product enhancement
	useEffect(() => {

		//update to new product object
		const createProduct = async (ezbase_data) => {
			const clone = structuredClone(ezbase_data, product)
			let productObject = await createProductObject([{ ...clone, "data": "ezbase" }])
			setSelectedProduct(current => ({
				...current,
				...product,
				...productObject
			}))
		}
		if (ezbase_data && ezbase_data?.hits?.hits?.[0]?._source) {
			createProduct(ezbase_data?.hits?.hits?.[0]?._source, product)
		}

	}, [ezbase_data, product])



	return {
		"ezbase_isFecthing": ezbase_isFecthing,
	}
};

