import React, { useEffect, useState } from "react";
import ItemCard from "./ItemCard";
import { Divider, Grid, Skeleton, Box, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import '../../slider/slickSlider.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";


const Row = ({ isFetching, data, title, handleSelect, level }) => {

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [selected, setSelected] = useState()
    const [startSlide, setStartSlide] = useState();

    function Arrows(props) {
        const { style, onClick , currentSlide, slideCount, left, right } = props;
        //console.log(props)
        return (
            <Box
            //className={className}
            sx={{  
                ...style,
                cursor: theme => currentSlide === 0 && left ? 'unset' : currentSlide === slideCount - 1 && right ? 'unset' : 'pointer',
                zIndex: 1,
                position: 'absolute',
                height : '100%', 
                width: '40px', 
                background:  theme => currentSlide === 0 && left ? theme.palette.grey[500] : currentSlide === slideCount - 1 && right ? theme.palette.grey[500] : theme.palette.primary.main,
                color: theme => theme.palette.primary.contrastText,
                left: left ?  0 : 'auto',
                right: right ?  0 : 'auto',
                display: 'grid',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'background 0.3s ease-in-out',
                '& svg': {
                    color: theme => theme.palette.primary.contrastText,
                    fill: theme => theme.palette.primary.contrastText,
                    margin: 'auto'
                },
                '&:hover'   : { 
                    background:  theme => currentSlide === 0 && left ? theme.palette.grey[500] : currentSlide === slideCount - 1 && right ? theme.palette.grey[500] : theme.palette.primary.dark,
                },
                opacity : currentSlide === 0 && left ? 0.3 : currentSlide === slideCount - 1 && right ? 0.3 : 1
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={right ? faChevronRight : faChevronLeft} />
        </Box>
 
        );
    }

    useEffect(() => {
        if (data && data.length > 1) {
            setStartSlide(Math.floor(data.length / 2))
        }
    }, [data])

    const settings = {
        arrows: true,
        dots: true,
        infinite: false,
        focusOnSelect: true,
        dragable: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        initialSlide : startSlide,
        nextArrow: <Arrows right />,
        prevArrow: <Arrows left />
    };

    return (
        <>
            <Grid
                item textAlign="center" xs={12}>
                <Divider/>
            </Grid>
            <Grid
                container
                item
                xs={12}
                //justifyContent="center"
                sx={{
                    padding: mobile ? '14px' : '1em',
                    width: '100%'
                }}
            >

                {isFetching ?
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            display: "grid"
                        }}
                    >
                        <Box
                            sx={{
                                margin: 'auto',
                            }}
                        >
                            <Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' : 220} height={mobile ? '140px' : 180} />
                        </Box>
                    </Grid>
                    :
                    <> 
                        {data && data.length > 1 && startSlide &&
                            <Grid
                                item
                                xs={12}
                                //justifyContent="center"
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <Slider {...settings} >
                                    {data.map((item, i) =>

                                        <Box
                                            onClick={e => {
                                                handleSelect(e, item, level)
                                                setSelected(item)
                                            }}
                                            sx={{
                                                padding: '2px'
                                            }}
                                            key={i}
                                        >
                                            <ItemCard item={item} selected={selected} />
                                        </Box>
                                    )}

                                </Slider>
                            </Grid>
                        }
                        {data && data.length === 1 &&
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    width: '100%',
                                    alignItems: 'center',
                                    display: "grid"
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 'auto',
                                    }}
                                    onClick={e => {
                                  
                                        handleSelect(e, data[0], level)
                                        setSelected(data[0])
                                    }}
                                >
                                    <ItemCard item={data[0]} selected={selected} />
                                </Box>
                            </Grid>
                        }
                    </>
                }
            </Grid >
        </>
    );
}
export default Row;



// const Row = ({ isFetching, data, handleSelect, level }) => {

//     const flickRef = useRef()
//     const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
//     const [selected, setSelected] = useState();

//     //console.log(data)
//     useEffect(() => {

//         if (flickRef.current) {
//             flickRef.current.on('staticClick', function (event, pointer, cellElement, cellIndex) {
//                 //console.log(cellIndex)
//                 // console.log(event)
//                 // console.log(cellElement)
//                 flickRef.current.select(cellIndex)
//                 handleSelect(event, data[cellIndex], level)
              

//             });
//             // flickRef.current.on('settle', () => {
//             //  //console.log('settle')
//             // });
//         }
//     }, [flickRef.current]);


//     return (
//         <>
//             {isFetching ?
//                 <Grid item xs={12} sx={{ margin: '1em' }}>
//                     <Grid item container spacing={2} justifyContent="center">
//                         <Grid item><Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' :  220} height={mobile ? '140px' :180} /></Grid>
//                         <Grid item><Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' :  220} height={mobile ? '140px' :180} /></Grid>
//                         <Grid item><Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' :  220} height={mobile ? '140px' :180} /></Grid>
//                         <Grid item><Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' :  220} height={mobile ? '140px' :180} /></Grid>
//                     </Grid>
//                 </Grid>
//                 :
//                 <>

//                     <Box
//                         sx={{
//                             width: '100%',
//                             '& .flickity-enabled' :{
//                                 width: '100%'
//                             },
//                             '& .flickity-button': {
//                                 background: (theme) => theme.palette.primary.light,
//                                 borderColor: (theme) => theme.palette.primary.light,
//                                 borderRadius: 0,
//                                 height: '100%',
//                                 color: '#fff',
//                                 width: mobile ? '25px' : '44px',
//                                 '&:hover': {
//                                     background: (theme) => theme.palette.primary.main,
//                                     borderColor: (theme) => theme.palette.primary.main,
//                                 },
//                                 '&:focus': {
//                                     boxShadow: 'none'
//                                 }
//                             },
//                             '& .flickity-prev-next-button.previous': {
//                                 left: mobile ? '-20px' : '-16px',

//                             },
//                             '& .flickity-prev-next-button.next': {
//                                 right: mobile ? '-20px' : '-16px',
//                             }
//                         }}

//                     >
//                         <Grid container item xs={12} justifyContent="center" 
//                         sx={{ 
//                             padding: mobile ? '14px' : '1em', 
//                             width: '100%' 
//                             }}>
//                             {data && data.length > 1 &&
//                             <>
//                              {/* <Button onClick={ e => flickRef.current.previous()}>
//                                 back
//                             </Button>  */}
//                             <Flickity
//                                 flickityRef={(carouselRef) => flickRef.current = carouselRef}
//                                 className={'carousel'} // default ''
//                                 elementType={'div'} // default 'div'
//                                 options={{
//                                     //autoPlay: 4000,
//                                     //pauseAutoPlayOnHover: true,
//                                     //wrapAround: true,
//                                     //fullscreen: true,
//                                     //adaptiveHeight: true,
//                                     //prevNextButtons: false,
//                                     //groupCells: true,
//                                     pageDots: false,
//                                    // contain: true,
//                                     initialIndex: data ? data.length / 2 : 0
//                                 }}
//                                 // takes flickity options {}
//                                 disableImagesLoaded={false} // default false
//                                 //reloadOnUpdate // default false
//                                 static // default false
//                             >
//                                 {data.map((item, i) =>
//                                     <div
//                                         onClick={e => {
//                                             handleSelect(e, item, level)
//                                             setSelected(item)
//                                         }}
//                                         style={{
//                                             marginLeft: '10px'
//                                         }}
//                                         key={i}
//                                     >
//                                         <ItemCard item={item} selected={selected} />
//                                     </div>
//                                 )}

//                             </Flickity>
//                             {/* <Button onClick={ e => flickRef.current.next()}>
//                                 next
//                             </Button>  */}
//                             </>
//                         }
//                         {/* {data && data.length === 1 &&
//                             <div
//                                 onClick={e => {
//                                     handleSelect(e, data[0] , level)
//                                 }}
//                             >
//                                 <ItemCard item={data[0]} selected={selected} />
//                             </div>
//                         }  */}
                         
//                             {/* {data && data.length && data.map((item, i) =>
//                                 <Grid
//                                     item
//                                     sx={{
//                                         margin: '5px',
//                                         display: 'inline-block'
//                                     }}
//                                     onClick={e => {
//                                         handleSelect(e, item)
//                                     }}
//                                 >
//                                     <ItemCard item={item} selected={selected} />
//                                 </Grid>
//                             )} */}
//                         </Grid>
//                     </Box>
//                 </>
//             }
//             {/* <Grid item xs={12} textAlign="center">
//                 <FontAwesomeIcon icon={faCircleChevronDown} size="2x" color={selected ? theme.palette.primary.main : "#ccc"} />
//             </Grid> */}
//         </>
//     );
// }
// export default Row;
