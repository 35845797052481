import React from "react";
import { Avatar, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Typography, Skeleton } from '@mui/material';


const BasketItemSkeleton = () => {

	return (
		<>
			<ListItem divider
				secondaryAction={
					<IconButton
						edge="end" aria-label="remove all"
					>
						<Skeleton variant="circular" width={30} height={30} />
					</IconButton>
				}
			>
				<ListItemAvatar>
					<Skeleton variant="circular" width={50} height={50} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={<Typography color="text.secondary" variant="body1" fontSize="13px" lineHeight="1.4" ><Skeleton /></Typography>}
					secondary={
						<>
							<Typography variant="body1" fontSize="16px" color="primary.main" lineHeight="1.4" ><strong><Skeleton /></strong></Typography>
							<Typography color="text.secondary" variant="subtitle2" lineHeight="1.4">
								<Skeleton />
							</Typography>
						</>
					}
				/>
			</ListItem>
		</>
	);
}


export default BasketItemSkeleton;