import React from 'react'
import DebouncedInput from './DebounceInput'
import CheckboxFilter from './CheckboxFilter'
import { Autocomplete, TextField } from '@mui/material';
import setLanguageText from '../language/setLanguageText';

const Filter = ({ column, table }) => {

    let firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
    if (!firstValue) {
        firstValue = table.getPreFilteredRowModel().flatRows[1]?.getValue(column.id)
    }
    console.log(firstValue)
    const columnFilterValue = column.getFilterValue()
    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    label="Min"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                        ? `(${column.getFacetedMinMaxValues()?.[0]})`
                        : ''
                        }`}
                />
                <DebouncedInput
                    type="number"
                    label="Max"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                        ? `(${column.getFacetedMinMaxValues()?.[1]})`
                        : ''
                        }`}
                />
            </div>
            <div className="h-1" />
        </div>
    ) : typeof firstValue === 'string' ? (
        <>
            <Autocomplete
                options={sortedUniqueValues}
                getOptionLabel={option => option}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant='outlined'
                        sx={{
                            '& input': { zIndex: 2, fontSize: '0.875rem', padding: '2px' },
                            '& legend': { display: 'none' },
                            '& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
                            '& .MuiAutocomplete-endAdornment ': {
                                zIndex: 2
                            }
                        }}
                        //label={setLanguageText(column.columnDef.name)}
                        placeholder={`${setLanguageText(column.columnDef.name)}`}
                    />
                )}
                value={columnFilterValue}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(e, newValue) => {
                    column.setFilterValue(newValue)
                }}
            />
            {/* <DebouncedInput
                label={column.columnDef.name}
                type="text"
                value={(columnFilterValue ?? '')}
                onChange={value => column.setFilterValue(value)}
                placeholder={`${column.columnDef.name}... (${column.getFacetedUniqueValues().size})`}
            /> */}
        </>
    ) : typeof firstValue === 'boolean' ? (
        <CheckboxFilter
            label={column.columnDef.name}
            type="text"
            value={(columnFilterValue ?? '')}
            onChange={value => column.setFilterValue(value ? value : null)}
        />
    ) : null
}

export default Filter