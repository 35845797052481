import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { buttonStyles } from '../styles/buttonStyles';
import { faVial } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../language/setLanguageText';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: theme.spacing(1)
    },
}))(Tooltip);

function AdditiveTooltip({ additives, mobile }) {

    return (

        <>
            {mobile ?
                <>
                    {additives &&
                        additives.map((item, i) => {
                            return (
                                <Typography variant="caption">
                                    {item.product_name} {item?.product_type_name ? `- ${item.product_type_name}` : ''} {item?.product_input?.dilution ? `(${item.product_input.dilution})` : ''}
                                    {i < additives.length - 1 ? ', ' : ''}
                                </Typography>
                            );
                        })}
                </>
                :
                <HtmlTooltip
                    title={
                        <>
                            <Typography variant="h6">{setLanguageText("Additives")}</Typography>
                            <Grid container>
                                {additives &&
                                    additives.map((item, i) => {
                                        return (
                                            <Grid item xs={12} key={i}>
                                                <Typography variant="caption">
                                                    {item.product_name} {item?.product_type_name ? `- ${item.product_type_name}` : ''} {item?.product_input?.dilution ? `(${item.product_input.dilution})` : ''}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </>}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={buttonStyles().icon_md}
                        style={{ cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faVial} />
                    </Grid>
                </HtmlTooltip>
            }
        </>
    );
}

export default AdditiveTooltip;

