import { Alert, Autocomplete, Button, Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import { SAVE_COMPANIES, SELECTED_COMPANY } from '../../store/CompanyReducers';
import axiosRequest from '../../coolantcare/helpers/axiosRequest';
import useActivityLog from '../../users/activity/useActivityLog';
import config from '../../config';
import { useMutation } from 'react-query';
import GlobalDialog from '../../reusable/GlobalDialog';
import DialogLoader from '../../reusable/DialogLoader';

const AddCompany = ({ setIsDialog, open }) => {

    const { companies, dispatchCompanies, dispatchSelectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [name, setName] = useState('');
    const [names, setNames] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();

    const closeModal = () => setIsDialog();

    useEffect(() => {
        if (companies.length > 0) {
            let names = companies.map(company => company.company_name)
            //console.log(names)
            setNames(names);
        }
    }, [companies])


    const get_companies = useMutation((user) => {

        let params = {
            "companies_id": user.user.user_permissions.access.companies ? JSON.stringify(user.user.user_permissions.access.companies) : null
        }
        if (user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator) {
            params = {
                ...params,
                "all_companies": true
            }
        }
        //console.log(user)
        return axiosRequest({
            method: 'get',
            gateway: config.coolantAPI.URL,
            endpoint: 'companies',
            api_key: user.api_key,
            params: params
        })
    }, {
        onSuccess: (data) => {
            //update companies
            dispatchCompanies({ type: SAVE_COMPANIES, payload: data });
        }
    })

    const set_selected_company = useMutation((company_id) => {
        //console.log(user)
        return axiosRequest({
            method: 'get',
            endpoint: 'companies',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            params: {
                id: company_id
            }
        })
    }, {
        onSuccess: (data) => {
            setLoading(false)
            dispatchSelectedCompany({
                type: SELECTED_COMPANY,
                payload: data[0]
            });

            window.sessionStorage.setItem('selectedCompany', data[0].company_id);
        }
    })
    const add_company = useMutation((name) => {
        //console.log(user)
        return axiosRequest({
            method: 'post',
            endpoint: 'company',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            body: {
                company_name: name
            }
        })
    }, {
        onSuccess: (data) => {
            //console.log(data)
            //TODO when you add a compnany, you need to add the id to the user
            get_companies.mutate(user)
            //get new company  
            set_selected_company.mutate(data[0].company_id)
            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    company_id: data[0].company_id,
                    company_name: data[0].company_name
                },
                activity_type: "add company"
            })
            setName('');
            closeModal();
        }
    })

    const handleSubmit = () => {
        add_company.mutate(name)
        setLoading(true)
    };

    const handleNameChange = (event, newValue) => {
        setName(newValue);
    };

    const filteredSuggestions = names.filter((suggestion) => {
        //console.log(suggestion)
        if (suggestion) {
            return suggestion.toLowerCase().includes(name.toLowerCase()) && !names.includes(name)
        }
    });

    return (
        <>
            <GlobalDialog
                open={open}
                onClose={closeModal}
                title="Add New Company"
                buttonClick={handleSubmit}
                buttonTitle="Add Company"
                maxWidth="sm"
                fullWidth
                disabled={name === '' || names.includes(name)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ minWidth: 140 }}
                            freeSolo
                            options={filteredSuggestions ? filteredSuggestions : []}
                            inputValue={name}
                            onInputChange={handleNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    {names.includes(name) ?
                        <Grid item xs={12} >
                            <Alert sx={{ width: '100%' }} severity="warning">
                                Name is already taken
                            </Alert>
                        </Grid>
                        : name !== '' ?
                            <Grid item xs={12} >
                                <Alert sx={{ width: '100%' }} severity="success">
                                    Company name available
                                </Alert>
                            </Grid>
                            :
                            <Grid item xs={12} >
                                <Alert sx={{ width: '100%' }} severity="info">
                                    Please enter a new company name. You can only add a company name that is not already in the list.
                                </Alert>
                            </Grid>
                    }
                </Grid>
                {loading ?
                    <DialogLoader
                        fixed
                        isLoading={loading}
                        mutation={set_selected_company}
                        loadingMessage="Adding Company..."
                        successMessage="Adding Company"
                    //closeDialog={closeRefreshDialog}
                    />
                    : null}
            </GlobalDialog>
        </>
    );
};

export default AddCompany;
