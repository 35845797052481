import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, ArcElement } from 'chart.js';
import { useTheme } from '@emotion/react';
ChartJS.register(LineElement, PointElement, LinearScale, Title, ArcElement);


const DonutChart = () => {

    const theme = useTheme();

    const data = {
        labels: ['Red', 'Green', 'Yellow'],
        datasets: [
            {
                data: [60, 40],
                backgroundColor: [theme.palette.charts.first, theme.palette.charts.third],
                hoverBackgroundColor: [theme.palette.charts.first, theme.palette.charts.third],
            },
        ],
    };

    const options = {
        rotation: -110,
        circumference: 220,
        // layout: {
        //     padding: 20
        // },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        }
    };

    return <Doughnut data={data} options={options} width={200} height={200} />;
};

export default DonutChart;
