import {
	Button,
	Card,
	CardContent,
	Grid,
	Skeleton,
	TextField,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
	Collapse,
	useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from "yup"
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChain, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import CustomCard from '../reusable/CustomCard';
import FileView from '../media/FileView';
import timePast from '../helpers/timePast';
import FileUpload from '../media/FileUpload';
import FilePreviewOld from '../media/FilePreviewOld';
import { Storage } from 'aws-amplify';
import FileUploadProgress from '../media/FileUploadProgress';
import NewComment from './emails/NewComment';
import { renderToString } from 'react-dom/server';
import { CompanyContext } from '../store/CompanyContext';
import Loader from '../reusable/Loader';
import { LayoutContext } from '../store/LayoutContext';

const FixturesComments = ({ request, checked, setChecked }) => {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { theme } = useContext(LayoutContext);
	const [comments, setComments] = useState();
	const [latestComments, setLatestComments] = useState();
	const [files, setFiles] = useState([])
	const [uploading, setUploading] = useState(false)
	const [addFiles, setAddFiles] = useState(false)

	const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

	//set s3 bucket
	Storage.configure({
		AWSS3: {
			bucket: config.fixturesS3.BUCKET,
			region: config.fixturesS3.REGION
		}
	});

	//set form validation
	const validationSchema = Yup.object({
		comment_details: Yup.string()
			.min(2, "Must be at least 2 characters")
			.max(1000, "Must be 1000 characters or less")
			.required("A comment is required")
	})

	const initialValues = {
		comment_details: '',
		files: []
	}

	//get comments
	const { data } = useQuery(['request_comments',request?.request_id],
		({ signal }) => axiosRequest({
			signal: signal,
			method: 'get',
			endpoint: 'comments',
			gateway: config.fixturesAPI.URL,
			api_key: user.api_key,
			params: {
				comment_request_id: request.request_id
			}
		}), {
		retry: 1,
		enabled: !!request?.request_id
	})

	useEffect(() => {
		if (data) {
			setComments(data)
		}
	}, [data])


	//send new request email
	const send_email = useMutation((comment) => {
		
		let emails = []
		//if original request user is the comment added user
		if (request.request_user.user_id === comment.user_id) {
			//send to company email if set
			if (selectedCompany?.company_settings?.fixtures?.email) {
				if (Array.isArray(selectedCompany.company_settings.fixtures.email)) {
					//add all emails to emails array
					selectedCompany.company_settings.fixtures.email.forEach((email) => {
						emails.push(email)
					})
				} else {
					//add single email to emails array
					emails.push(selectedCompany.company_settings.fixtures.email)
				}
			} else if (!selectedCompany?.company_settings?.fixtures?.email && app?.emails?.fixtures_email) {
				//send to global application email if compnay is not set
				if (Array.isArray(selectedCompany.company_settings.fixtures.email)) {
					//add all emails to emails array
					app.emails.fixtures_email.forEach((email) => {
						emails.push(email)
					})
				} else {
					//add single email to emails array
					emails.push(app.emails.fixtures_email)
				}
			}
		} else {
			//send to user who created the request
			emails.push(request.request_user.email)
		}

		const emailString = renderToString(<NewComment comment={comment} theme={theme}/>)
		return axiosRequest({
			gateway: config.fixturesAPI.URL,
			endpoint: 'email',
			api_key: user.api_key,
			method: 'patch',
			body: {
				email: emails,
				//email: request.request_user.user_id !== user.user_id  ? ['jim@design-monkey.co.uk', selectedCompany?.company_settings?.fixtures?.email ]  :  [request.request_user.user_id],
				html: emailString,
				subject: 'New Comment Added'
			}
		})
	})

	//add comment to request
	const add_comment = useMutation(comment => {
		return axiosRequest({
			endpoint: `comments`,
			method: 'post',
			gateway: config.fixturesAPI.URL,
			api_key: user.api_key,
			body: comment
		})
	}, {
		onSuccess: ( data, variables ) => {
			// console.log(data)
			//console.log(variables)
			setLatestComments(variables) 
			//start file upload
			if (Object.values(files).length) {
				setUploading(true)
			} else {
				finshedUploading(variables,request)
			}
			add_comment.reset();

		}
	})

	const setStatus = (user, request, checked) => {
		if (checked) {
			return 'Closed'
		}
		else if (request.request_status === 'New' && (user.user.user_id !== request.request_user.user_id)) {
			return 'Open'
		}
		else if (request.request_status === 'Closed' && !checked) {
			return 'Open'
		} else {
			return 'New'
		}
	}

	const handleSubmit = (values) => {

		//'YYYY-MM-DD hh:mm:ss' 
		let today = new Date()
		const filesPaths = files.map((file) => {
			const fileObject = {
				type: file.type,
				name: file.name,
			}
			return fileObject
		})

		const comment = {
			comment_request_id: request.request_id,
			comment_submit_date: Date.parse(today.toString()),
			comment_details: values.comment_details,
			comment_files: { files: filesPaths },
			comment_user: {
				user_id: user.user.user_id,
				user_details: user.user.user_details,
				email: user.user.email
			},
			comment_archived: 0,
			request_status: setStatus(user, request, checked)
		}
		//console.log(comment)
		add_comment.mutate(comment);
		//set loacl states
		setComments(current => [...current, comment])

	}

	// add files to current files
	const handleSelectUploadedFile = (newFiles) => {
		setFiles(currentFiles => [...currentFiles, newFiles])
	}
	//remove file
	const handleRemoveItem = (file) => {
 //console.log(file)
		setFiles(files => files.filter((f) => f.name !== file.name))
	}

	// useEffect(() => {
	//   if (latestComments ) {
	//     //console.log(folder)
	//     //console.log(completedRequest)
	//     if (Object.values(files).length) {
	//       setUploading(true)
	//     } else {
	//       finshedUploading()
	//     }
	//   }
	// }, [latestComments, files])
	
	const finshedUploading = (latestComments,request) => {
 //console.log('here')
		send_email.mutate({'comment':latestComments,'request' : request})
	}

	return (
		<>
			<Box sx={{ padding: isMd  ? '3em 2em' : '1em', width: '100%' }}>
				<Grid item container spacing={isMd  ? 4 : 2}>
					{comments ? comments.map((comment, i) => {

						const commentUsers = typeof comment.comment_user === 'object' ? comment.comment_user : JSON.parse(comment.comment_user)
						const files = typeof comment.comment_files === 'object' ? comment.comment_files : JSON.parse(comment.comment_files)
						//console.log(commentUsers.user_id)
						//console.log(user.user.user_id)
						return (
							<Grid container item key={i} justifyContent={commentUsers.user_id === user.user.user_id ? 'flex-start' : 'flex-end'}>
								<Grid item sx={{
									maxWidth: isMd  ? '80%' : 'auto',
									width: '100%'

								}}>
									
									<Box sx={{
										marginLeft: isMd  ?  commentUsers.user_id === user.user.user_id ? '15px' : 'auto' : 'none',
										marginRight: isMd  ? commentUsers.user_id === user.user.user_id ? 'auto' : '15px' : 'none'
									}}>
										<Card sx={{
											position: 'relative',
											overflow: 'visible',
											width: '100%',
										}}>
											{commentUsers.user_id === user.user.user_id ?
												<Box
													sx={{
														position: 'absolute',
														width: 0,
														height: 0,
														borderTop: '0px solid transparent',
														borderBottom: '25px solid transparent',
														borderRight: (theme) => `15px solid ${theme.palette.background.paper}`,
														left: '-10px',
													}}
												/>
												:
												<Box
													sx={{
														position: 'absolute',
														width: 0,
														height: 0,
														borderTop: '0px solid transparent',
														borderBottom: '25px solid transparent',
														borderLeft: (theme) => `15px solid ${theme.palette.background.paper}`,
														right: '-10px',
													}}
												/>
											}
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} md={10}>
														<Typography component="p" variant='p' fontWeight={600}>{commentUsers.user_details.name} {commentUsers.user_details.surname}</Typography>
													</Grid>
													<Grid item xs={12} md={2} >
														<Typography textAlign={ isMd ? 'right' : 'left'}>{timePast(comment.comment_submit_date)}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography>{comment.comment_details}</Typography>
													</Grid>
													<Grid container item spacing={4} >
														{files.files && files.files.map((file, i) => {
															return (
																<React.Fragment key={i}>
																	<FileView
																		request_id={request.request_id}
																		file={file}
																		small
																	/>
																</React.Fragment>
															)
														})}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Box>
								</Grid>
							</Grid>

						)
					}) :
						<>
							<Grid item xs={12} container justifyContent={'flex-end'}>
								<Grid item sx={{
									maxWidth: '80%',
									width: '100%'
								}}>
									<Skeleton variant="rectangular" height={80} />
								</Grid>
							</Grid>
							<Grid item container xs={12} alignContent={'flex-start'}>
								<Grid item sx={{
									maxWidth: '80%',
									width: '100%'
								}}>
									<Skeleton variant="rectangular" height={80} />
								</Grid>
							</Grid>
							<Grid item xs={12} container justifyContent={'flex-end'}>
								<Grid item sx={{
									maxWidth: '80%',
									width: '100%'
								}}>
									<Skeleton variant="rectangular" height={80} />
								</Grid>
							</Grid>
						</>
					}
				</Grid>
			</Box>
			<Grid item xs={12}>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm }) => {
						handleSubmit(values)
						resetForm()
					}}
				>
					{({ values, submitForm, handleChange, touched, errors }) => {
						return (
							<Form>
								<Grid container spacing={2} >
									<Grid item xs={12} md={9}>
										<TextField
											sx={{
												'& legend': { display: 'none' },
												width: '100%',
												'& .MuiInputBase-root': {
													background: (theme) => theme.palette.background.paper
												}
											}}
											id="comment_details"
											name="comment_details"
											value={values.comment_details}
											onChange={handleChange}
											fullWidth
											variant="outlined"
											multiline
											rows={3}
											error={touched.comment_details && Boolean(errors.comment_details)}
											helperText={touched.comment_details && errors.comment_details}
											placeholder="Type a message"
										/>

									</Grid>
									<Grid item container xs={12} md={3}>
										<Grid item xs={12}>
											<Button
												//fullWidth
												size='large'
												color="primary"
												variant="contained"
												onClick={submitForm}
												disabled={!comments}
												endIcon={
													<FontAwesomeIcon
														icon={faPaperPlane}
													/>
												}
											>
												Send
											</Button>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={checked}
														onChange={e => setChecked(current => !current)} />
												}
												label="Marked as Closed"
											/>
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<Button
											size='large'
											color="primary"
											variant="contained"
											onClick={() => {
												setAddFiles(current => !current)
											}}
											disabled={!request}
											startIcon={
												<FontAwesomeIcon
													icon={faChain}
												/>
											}
										>
											Attach Files
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Collapse in={addFiles}>

											<CustomCard title="Comment Attachments">
												<Grid container item spacing={4}>
													<Grid item xs={12}>
														<FileUpload
															handleSelectUploadedFile={handleSelectUploadedFile}
															dropzoneProps={{
																noClick: true,
																// accept: {
																//   'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
																// }
																maxFiles: 10,
																//maxSize: 5242880,
																multiple: true
															}}
														/>
													</Grid>
													<Grid container item spacing={4} >
														{!files.length && (
															<Grid item xs={12}>
																<Typography>Added files will show here</Typography>
															</Grid>
														)}
														{files && files.map((item, index) => {
															return (
																<React.Fragment key={index}>
																	<FilePreviewOld
																		item={item}
																		handleRemoveItem={handleRemoveItem}
																		index={index}
																	/>
																</React.Fragment>
															)
														})}
													</Grid>
												</Grid>
											</CustomCard>
										</Collapse>
									</Grid>
								</Grid>
							</Form>
						)
					}}
				</Formik>
			</Grid>
			{add_comment.isLoading || add_comment.isSuccess || add_comment.isError ?
				<Loader
					isLoading={add_comment.isLoading}
					mutation={add_comment}
					loadingMessage="Adding Comment..."
					successMessage="Comment Added"
					callback={() => {
						//navigate('/solution/dashboard')
					 //console.log('uploading files')
					}}
				/>
				: null}
			{uploading && Object.values(files).length && request && latestComments ?
				<FileUploadProgress
					folder={request.request_id}
					files={files}
					open={uploading}
					callback={() => {
					 //console.log('here')
						finshedUploading(latestComments,request);
						setUploading(false)
						setFiles([])
					}}
				/>
				: null}
		</>
	);
}

export default FixturesComments;
