import { Grid } from '@mui/material';
import React from 'react';
import PageContent from '../PageContent';
import AdditiveUsageByAdditive from './additive/AdditiveUsageByAdditive';
import CoolantUsageByCompany from './coolant/CoolantUsageByCompany';
import CoolantUsageByCoolant from './coolant/CoolantUsageByCoolant';
import DipslideQRCodesGlobal from './QRCodes/DipslideQRCodesGlobal';
import MachineQRCodes from './QRCodes/MachineQRCodes';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import ServiceReports from './service/ServiceReports';

const Reports = () => {

	return (
		<>
			<InnerHeaderWrap>
				<Grid spacing={2} container justifyContent="space-between" alignItems="center" >
					<Grid item xs={12} md={3} >
						<GlobalCompanySelect />
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent className="reports">
				<Grid item container spacing={2}>
					<Grid item container spacing={2}>
						<Grid item xs={12} lg={6}>
							<CoolantUsageByCompany />
						</Grid>
						<Grid item xs={12} lg={6}>
							<CoolantUsageByCoolant />
						</Grid>
						<Grid item xs={12} lg={6}>
							<AdditiveUsageByAdditive />
						</Grid>
						<Grid item xs={12} lg={6}>
							<ServiceReports />
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={12} lg={6}>
							<DipslideQRCodesGlobal />
						</Grid>
						<Grid item xs={12} lg={6}>
							<MachineQRCodes />
						</Grid>
					</Grid>
				</Grid>
			</PageContent>
		</>
	);
}

export default Reports;
