import { Box, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Skeleton, Stack, TextField, Typography, List, ListItem, InputAdornment, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_RESULTS_FILTERS } from "../../store/SelectedReducers";
import { VariableSizeList } from "react-window";
import { matchSorter } from "match-sorter";
import { useWindowResize } from './../libs/useWindowResize'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";

const Row = ({ filtered, index, setSize, windowWidth, handleChange, field }) => {

    //console.log(filtered)
    const rowRef = useRef();
    const isEven = index % 2 === 0;
    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [setSize, index, windowWidth]);

    return (
        <Box
            ref={rowRef}
            sx={{
                backgroundColor: (theme) => isEven ? theme.palette.background.paper : theme.palette.background.default
            }}
        >
            <FormControlLabel
                sx={{ padding: '5px 10px', width: '100%', lineHeight: 1 }}
                control={
                    <Checkbox size="small"
                        checked={filtered[index].Checked}
                        onChange={(e, checked) => {
                            handleChange(!filtered[index].Checked, filtered[index].Value, field);
                        }}
                        name={filtered[index].Value} />
                }
                label={
                    <Grid sx={{ lineHeight: 1 }}>
                        <Typography
                            variant="body1"
                        > {filtered[index].Name ? filtered[index].Name : filtered[index].Value} <small>({filtered[index].AmountOfHits}) </small></Typography>
                    </Grid>
                }
            />
        </Box>
    );
};

const ResultFiltersCard = ({ field }) => {

    const listRef = useRef();
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const { dispatchLayout } = useContext(LayoutContext);
    const [checkboxes, setCheckboxes] = useState();
    const [filtered, setFiltered] = useState();

    //Set field value on first load using URL Querystring
    useEffect(() => {
        let fieldValue = ''
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("filters"))
            //console.log(searchArr);
            if (searchArr && searchArr[0]) {
                let found = searchArr[0].filter(element => element.includes(`${field.Field}§`));
                //console.log(found);
                if (Object.values(found).length > 0) {
                    //console.log(found)
                    fieldValue = found[0].split('§')[1]
                    //console.log(fieldValue);
                    dispatchSelected({
                        type: UPDATE_RESULTS_FILTERS,
                        payload: {
                            'Field': field.Field,
                            'Values': fieldValue.split(','),
                            'DIN': field.DIN,
                            'Name': field.Name,
                            'Unit': field.Unit,
                            'Toggle': true
                        }
                    });
                }
            }
        }

        let boxes = field.Values.map((item) => {
            if (fieldValue.split(',').includes(item.Value)) {
                return ({
                    ...item,
                    'Checked': true
                })
            }
            return ({
                ...item,
                'Checked': false
            })
        })
        const sorted = boxes.sort((a, b) => {
            return a.Value.localeCompare(b.Value, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        })

        setCheckboxes(sorted)
        setFiltered(sorted)

    }, [field]);

    const handleChange = (checked, value, field) => {
        setCheckboxes(current => {
            let copy = [...current]
            let index = copy.findIndex((item) => item.Value === value)
            copy[index].Checked = checked
            return copy
        })
        let filters = []
        if (Object.values(selected.resultFilters).length) {
            filters = [...selected.resultFilters]
        }
        let index = (filters).findIndex((obj) => obj.Field === field.Field);
        //console.log(index)
        if (index > -1) {
            //ticked - add
            if (checked) {
                let addValue = [...filters[index].Values]
                addValue.push(value)
                //console.log('add')
                dispatchSelected({
                    type: UPDATE_RESULTS_FILTERS,
                    payload: {
                        'Field': field.Field,
                        'Values': addValue,
                        'DIN': field.DIN,
                        'Name': field.Name,
                        'Unit': field.Unit,
                        'Toggle': true
                    }
                });
                //untick - remove
            } else {
                //console.log('remove')
                let removeValue = [...filters[index].Values]
                removeValue = removeValue.filter((item) => item !== value)
                dispatchSelected({
                    type: UPDATE_RESULTS_FILTERS,
                    payload: {
                        'Field': field.Field,
                        'Values': removeValue,
                        'Name': field.Name,
                        'Unit': field.Unit,
                        'Toggle': true
                    }
                });
            }
        } else {
            if (checked) {
                //add
                dispatchSelected({
                    type: UPDATE_RESULTS_FILTERS,
                    payload: {
                        'Field': field.Field,
                        'Values': [value],
                        'DIN': field.DIN,
                        'Name': field.Name,
                        'Unit': field.Unit,
                        'Toggle': true
                    }
                });
            }
        }
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1
            }
        })
    }

    const onFilterMouseUp = (e) => {
        //console.log(e.target.value)
        const value = e.target.value;
        //console.log(value)
        let filtered = matchSorter(checkboxes, value, { keys: ['Name'] });
        filtered = filtered.filter(item => item.Checked !== true)
        let selected = checkboxes.filter(box => box.Checked === true);
        if (!selected) {
            const sorted = checkboxes.sort((a, b) => {
                return a.Name.localeCompare(b.Name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            })
            setFiltered(sorted)
        } else {
            let merged = [...selected, ...filtered]
            //console.log(merged)
            setFiltered(merged)
        }
    };

    const sizeMap = useRef({});
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();


    return (
        <>
            <Grid item xs={12}>
                <Typography> {field.DIN ? field.DIN : ""} {field.DIN ? " - " : ""} {field.Name}</Typography>
            </Grid>
            <Grid item xs={12}>
                {checkboxes?.length > 5 ?
                    <TextField
                        sx={{
                            backgroundColor: "background.paper",
                            '& legend': {
                                display: 'none'
                            },
                            marginBottom: '5px'
                        }}
                        fullWidth
                        size="small"
                        placeholder={`Filter ${field.Name}..`}
                        onKeyUp={onFilterMouseUp}
                        type={field.Unit ? 'number' : 'text'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{field.Unit}</InputAdornment>,
                        }}

                    />
                    : null}
                {filtered?.length ?
                    <VariableSizeList
                        ref={listRef}
                        height={filtered?.length > 5 ? 200 : filtered?.length * 50}
                        width="100%"
                        itemCount={filtered?.length}
                        itemSize={getSize}
                        itemData={filtered}
                        style={{ 
                            overflowX: 'hidden', 
                            border: '1px solid #ccc',
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary 
                        }}
                    >
                        {({ data, index, style }) => (
                            <div style={style}>
                                <Row
                                    filtered={data}
                                    index={index}
                                    setSize={setSize}
                                    windowWidth={windowWidth}
                                    handleChange={handleChange}
                                    field={field}
                                />
                            </div>
                        )}
                    </VariableSizeList>
                    : null}
            </Grid>
        </>
    );
};

export default ResultFiltersCard;
