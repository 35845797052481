import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Grid, Skeleton } from '@mui/material';
import { useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { useParams } from "react-router-dom";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { isMobileOnly } from "react-device-detect";
import { faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExportSingleProduct from "./../shared/ExportSingleProduct";
import ProductDetails from "./../cim/ProductDetails";
import AddToBasket from "../../basket/AddToBasket";
import ProductTabs from "./../cim/ProductTabs";
import createProductObject from "../../helpers/createProductObject";
import ProductSlider from "../../reusable/ProductSlider";
import SingleImage from "../../shop/reusable/SingleImage";
import axiosRequest from '../../axios/axoisRequest';
import { LayoutContext } from "../../store/LayoutContext";
import HTMLReactParser from "html-react-parser";
import Header from "../../buyers/layout/Header";
import AddToFavourites from "../../favourites/AddToFavourites";

const ProductGroupFullPage = (props) => {

    const { id } = useParams()
    const { user } = useContext(UserContext);
    const { app } = useContext(LayoutContext);
    const [erpDetails, setErpDetails] = useState();
    const [productDetails, setProductDetails] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [variants, setVariants] = useState();
    const [noImage, setNoImage] = useState(false)


    const { data, isFetching } = useQuery(['products', id],
        ({ signal }) => axiosRequest({
            signal: signal,
            name: 'get full product',
            method: 'get',
            endpoint: 'products',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: {
                ArticleID: id
            }
        }), {
        enabled: !!id,
        refetchOnWindowFocus: false, //todo set globally
        retry: 1
    })

    useEffect(() => {
        if (data) {
            const createProduct = async (product) => {
                const clone = structuredClone(product)
                let productObject = await createProductObject([{ ...clone, "area": app.selectedArea, "product_dataset": "ezbase" }])
                setProductDetails(productObject[0])
            }
            if (data) {
                //console.log(product)
                createProduct(data[0])
            }
        }
    }, [data])

    const { data: group } = useQuery(['group', productDetails?.product_group],
        ({ signal }) => axiosRequest({
            signal: signal,
            name: 'get product variants',
            method: 'get',
            endpoint: 'products/group',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: {
                groupID: productDetails.product_group
            }
        }), {
        enabled: !!productDetails?.product_group,
        refetchOnWindowFocus: false, //todo set globally
        retry: 1
    })


    useEffect(() => {

        //update to new product object
        const createProduct = async (group, productDetails) => {

            //loop through variants and add attributes headers
            const variants = await structuredClone(group);
            const promises = variants.map(async (product) => {
                //create product object
                let productObject = await createProductObject([
                    { ...product, area: app.selectedArea, "product_dataset": productDetails.product_dataset },
                ]);

                //add table headers
                productObject[0].product_attributes.map((attribute) => {
                    let header = productDetails.product_attributes.find((header) => header.id === attribute.id)
                    //console.log(header)
                    attribute.name = header?.name ? header.name : null
                    //console.log(attribute)
                    return attribute
                })
                return productObject[0];
            });

            let all = await Promise.all(promises);
            //create product object for all variants
            setVariants(all)

            //merge product details with selected to get attributes
            let found = group.find((product) => product.ArticleID === productDetails.product_id)
            //console.log(found)
            if (found?.TableProperties) {
                let selected = found?.TableProperties
                //check if it is an object or a string
                if (typeof selected === "string") {
                    selected = JSON.parse(selected)
                }
                // add attribute values to product object
                productDetails.product_attributes.map((attribute) => {
                    let value = selected.find((header) => header.id === attribute.id)
                    attribute.value = value?.value
                    return attribute
                })
                setSelectedProduct(productDetails)
            }
        }

        if (group && productDetails) {
            //console.log(data)
            createProduct(group, productDetails)
        }

    }, [group, productDetails])


    return (
        <>
            <Header {...props} noBreadcrumb />
            <InnerContentWrap>
                <Card>
                    <Grid item container alignContent="center" >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            sx={{
                                padding: isMobileOnly ? '1em' : '2em',
                                position: 'relative'
                            }} >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    left: 16,
                                    zIndex: 2,
                                }}
                            >
                                <AddToFavourites product={productDetails} isFetching={isFetching} big />
                            </Box>
                            <Grid item xs={12}
                                sx={{
                                    background: '#fff',
                                    position: 'relative'
                                }}
                            >
                                {productDetails?.product_image.length > 1 ?
                                    <ProductSlider images={productDetails.product_image} url="url" descrition="type" />
                                    : productDetails?.product_image.length === 1 ?
                                        <SingleImage imageUrl={productDetails.product_image[0].url} alt={productDetails.product_name} icon="6x" padding="1em" />
                                        : productDetails?.product_image.length === 0 && noImage ?
                                            <Box sx={{
                                                height: '100%',
                                                width: '100%',
                                                position: 'absolute',
                                                zIndex: 2,
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'grid',
                                                top: 0
                                            }}>
                                                <FontAwesomeIcon icon={faImageSlash} color="#ccc" size="6x" />
                                            </Box>
                                            : null
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={6}
                            sx={{
                                padding: isMobileOnly ? '1em' : '2em',
                                maxWidth: '500px',
                            }} >
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={isMobileOnly ? 1 : 2}
                                alignContent="flex-start"
                            //sx={{ maxWidth: '500px!important' }}
                            >
                                <Grid item xs={12}>
                                    {productDetails ?
                                        <Typography color="text.primary" variant="h5" sx={{ maringBottom: '1em' }} >{productDetails.product_name}</Typography>
                                        :
                                        <Skeleton width="100%" height={80} />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {productDetails?.product_brand ?
                                        <Box sx={{
                                            width: '300px',
                                            maxHeight: isMobileOnly ? '200px' : '100px',
                                            overflow: 'hidden'
                                        }}>
                                            <img
                                                src={productDetails?.product_brand?.url}
                                                alt={productDetails?.product_brand?.description}
                                                onError={e => {
                                                }}
                                            //style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            />
                                        </Box>
                                        : null}
                                </Grid>
                                <ProductDetails product={productDetails} isFetching={isFetching} erpDetails={erpDetails} />
                                {productDetails?.product_html ?
                                    <Grid item xs={12}
                                        sx={{
                                            maxHeight: '30vh',
                                            overflow: 'auto',
                                            fontSize: '1em',
                                            '& p ': {
                                                fontSize: '1em'
                                            }
                                        }}
                                    >
                                        {HTMLReactParser(productDetails.product_html)}
                                    </Grid>
                                    : null}
                                <Grid item xs={12}>
                                    <Box sx={{ maxWidth: '500px!important' }}>
                                        {productDetails ?
                                            <AddToBasket
                                                isFetching={isFetching}
                                                product={productDetails}
                                                large={true}
                                                area="shop"
                                                erpDetails={erpDetails}
                                                setErpDetails={setErpDetails}
                                            />
                                            :
                                            <Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <ExportSingleProduct product={productDetails} erpDetails={erpDetails} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', background: (theme) => theme.palette.cards.header }}>
                        <ProductTabs isFetching={isFetching} variants={variants} selectedProduct={productDetails} setSelectedProduct={setProductDetails} fullHeight />
                    </Box>
                </Card>
            </InnerContentWrap>
        </>
    );
};
export default ProductGroupFullPage;