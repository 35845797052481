import React, { useContext } from "react";
import GlobalMeta from "../reusable/GlobalMeta";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../reusable/ErrorFallback";
import logErrors from "../helpers/logErrors";
import { UserContext } from "../store/UserContext";

const BuyerRoutesWrap = (props) => {

    const { user } = useContext(UserContext);

    return (
        <>
            <GlobalMeta title={props.title} />
            <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={(error, info) => {
                      return logErrors({
                        'component':  props.title,
                        'user': user,
                        'full': info.componentStack.toString(),
                        'message': error.message.toString()
                      })
                    }}
                    onReset={() => {
                      //force reload
                      //location.reload();
                    }}
                  >
            {props.children}
            </ErrorBoundary>
        </>
    );
};

export default BuyerRoutesWrap;
