import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import options from "./options.json";

export default function Sort({ searchParams, setSearchParams }) {

    const [selected, setSelected] = useState();

    //on first load set selected
    useEffect(() => {
        const urlParams = new URLSearchParams(searchParams);
        const sort = urlParams.get('sort');
        if (sort) {
            setSelected(options.find(option => option.id === parseInt(sort)))
        }

    }, [searchParams])  

    return (
        <Autocomplete
            //disabled
            size="small"
            disableClearable
            disablePortal
            id="sort"
            options={options}
            value={selected || null}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) =>
                <TextField
                    {...params}
                    //label="sort by"
                    variant="outlined"
                    sx={{
                        width: '180px',
                        '& input': { zIndex: 2,  fontSize: '14px' },
                        '& legend': { display: 'none' },
                        '& fieldset': {
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary,
                            //fontSize: '14px'
                        },
                        '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
                            zIndex: 2
                        },
                    }}
                />
            }
            onChange={(event, value) => {

                const newUrl = new URLSearchParams(searchParams);
                //add filter to url
                newUrl.set("sort", value.id);
                //reset page
                newUrl.set("page", 0);
                setSearchParams(newUrl)
                //setSelected(value)

            }}
           
        />
    )
}
