import React from "react";
import {  Grid, Skeleton } from "@mui/material";

const SkeletonParametricForm = () => {

    return (
        <>
            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%"  height={60} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
            </Grid>

            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%"  height={60} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
            </Grid>

            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%"  height={60} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
            </Grid>

            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%"  height={60} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
            </Grid>

            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%" height={40} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton width="100%"  height={60} variant="text" animation="wave" />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton  width="100%" height={40} variant="text" animation="wave" />
                </Grid>
            </Grid>

        </>
    );
};

export default SkeletonParametricForm;
