import { Alert, Grid, ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import GlobalDialog from "../../../reusable/GlobalDialog";
import { useMutation } from "react-query";
import axiosRequest from '../../../axios/axoisRequest';
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import DialogLoader from "../../../reusable/DialogLoader";
import ProductItems from "../orders/ProductItems";

const DeleteQuote = ({ order, title, handleClose, refreshTable }) => {

    const [open, setOpen] = useState(false)
    const { user } = useContext(UserContext);

    const handleCloseDialog = () => {
        setOpen(false);
        handleClose();
        refreshTable();
    }

    const delete_quote = useMutation((order) => {
        //save the request
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'orders',
            api_key: user.api_key,
            method: 'post',
            body: {
                "remove": true,
                "order_id": order.order_id
            }
        })
    })

    const handleDelete = () => {
        delete_quote.mutate(order)
    }

    return (
        <>

            <MenuItem onClick={() => setOpen(true)} >
                <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                Delete Quote
            </MenuItem>
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={handleCloseDialog}
                    title={title}
                    buttonClick={handleDelete}
                    buttonTitle="Delete Quote"
                    closeText="Close"
                    maxWidth="sm"
                >
                    <Grid container spacing={2}>
                        <ProductItems order={order} />
                        <Grid item >
                            <Alert severity="error">Once this quote has been deleted, you will not be able to reverse this action.</Alert>
                            { delete_quote.isLoading || delete_quote.isSuccess || delete_quote.isError ?
                        <DialogLoader
                            isLoading={delete_quote.isLoading}
                            mutation={delete_quote}
                            loadingMessage="Deleting Quote..."
                            successMessage="Quote Delete"
                            closeDialog={handleCloseDialog}
                            fixed
                        />
                        : null} 
                        </Grid>
                    </Grid>
                </GlobalDialog>
                : null}
        </>
    );
};

export default DeleteQuote;