
export const UPDATE_PRODUCT_COUNT = 'UPDATE_PRODUCT_COUNT';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const ProductsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS:
      //console.log(action.payload.allProducts)
      return {
        ...state,
        allProducts: action.payload.allProducts ? action.payload.allProducts : state.allProducts,
        noProducts: action.payload.noProducts >= 0 ? action.payload.noProducts > 0 ? false : true : state.noProducts,
        offset: action.payload.offset ? action.payload.offset : state.offset,
        count: action.payload.count >= 0 ? action.payload.count : state.count,
        groupCount: action.payload.groupCount >= 0 ? action.payload.groupCount : state.groupCount,
        limit: action.payload.limit >= 0 ? action.payload.limit : state.limit
      };
    case UPDATE_ORDER:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_PRODUCT_COUNT:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_PRODUCTS:
      return {
        ...state,
        allProducts: [],
      };
    case RESET_PRODUCTS:
      return {
        ...state,
        allProducts: [],
        noProducts: false,
        offset: 0,
        count: 0,
        groupCount: 0,
      };
    default:
      return state;
  }
};

