import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import setLanguageText from '../../language/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { buttonStyles } from '../styles/buttonStyles';

const PrimaryBtnIcon = ({ icon, text, iconSize, textVariant, color, ...props }) => {
	const buttonStyle = buttonStyles();
	const { language } = useContext(LayoutContext);

	return (
		<Button variant="contained" fullWidth color={color ? color : 'primary'} {...props}>
			<FontAwesomeIcon icon={icon} size={iconSize} />
			{text &&
				<Typography variant={textVariant} className={buttonStyle.buttonIconText}>
					{setLanguageText(text)}
				</Typography>
			}
		</Button>
	);
};

export default PrimaryBtnIcon;
