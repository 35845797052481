
import QRCode from 'qrcode'
import config from '../../config';
const Buffer = require('buffer').Buffer;

const reverse = s => {
  return s.split('').reverse().join('');
};

export const machineQRCodeCreater = details => {

  //console.log(details)

  let value, data;
  let options = {
    errorCorrectionLevel: 'L'
  }
  if (details) {
    //if (Object.entries(details).length) {
    //order to make sure qr codes are the same everytime
    //console.log(Object.entries(details).length)
    const ordered = Object.fromEntries(Object.entries(details).sort())
    //console.log(ordered)
    //let qrDetails = reverse(btoa(JSON.stringify(ordered)));
    let qrDetails = reverse(Buffer.from(JSON.stringify(ordered)).toString('base64'));

    //console.log(qrDetails);
    //let qrSalting = reverse(btoa(config.SALTING));
    let qrSalting = reverse(Buffer.from(config.SALTING).toString('base64'));
    //console.log(qrSalting);
    //let imgUrl = `https://quickchart.io/qr?text=${qrDetails}${qrSalting}`;
    value = `${qrDetails}${qrSalting}`;
    //console.log(qrDetails)
    //console.log(value)
    QRCode.toDataURL(value, options, function (err, url) {
      data = url
    })
    //console.log(data)
  }
  return data

};

