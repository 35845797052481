function flatCategoriesToTree(list, parent, id, startID) {

    const getTree = (data, root) => {
        const t = {};
        data.forEach(category => {
            //console.log(category)
            return ((t[category[`${parent}`]] ??= {}).children ??= []).push(
                Object.assign(t[category[`${id}`]] ??= {}, category)
            )
        });
        //console.log(t[root])
        return t[root].children;
    }
    let tree = getTree(list, startID)
    return tree;
}
export default flatCategoriesToTree
