const showFieldLogic = (condition,applicationFilters) => {

    //console.log(arr, itemId, path)
    //console.log(condition)

    let splitAmp = condition.split('&&');
    //(A1=NF/UNF||A1=UNF||A1=UNJF)&&A23=#Empty 

    if (splitAmp) {

        //console.log(splitAmp)
        //check fro brakets
        var array = new Array(splitAmp.length).fill(false);
        //console.log(array);
        var regExp = /\(|\)|\[|\]/g;
        
        splitAmp.forEach((item, arrayIndex) => {
            //check for brakets
            let match = regExp.test(item)
            if (match) {
                //console.log(match) // true
                //remove brakets
                item = item.replace(regExp, '');
                //console.log(item)
                //split any OR ||
                let splitOr = item.split('||')
                //console.log(splitOr)
                splitOr.some((item) => { //loop through OR || until true is returned
                    //console.log(item)
                    let field = item.split('=');
                    //console.log(field)
                    //console.log(applicationFilters)
                    let inFilters = applicationFilters.find(item => item.Field === field[0])

                    if (field[1] === '#Empty') {
                        // A23=#Empty
                        if (!inFilters) {
                            //field is not in filter - so its empty
                            array[arrayIndex] = true
                        }
                    } else if (inFilters) {
                        //console.log(array)
                        //console.log(match.Selected)
                        let show = inFilters.Values.includes(field[1])
                        //console.log(show)
                        //if the value is in the
                        if (show) {
                            array[arrayIndex] = true
                            // hide = !show
                            return
                        }
                    }
                })
            } else {
                //no brakets
                //console.log(item)
                let field = item.split('=');
                //console.log(field)
                //console.log(applicationFilters)
                let inFilters = applicationFilters.find(item => item.Field === field[0])
                //console.log(inFilters)
                if (field[1] === '#Empty') {
                    // A23=#Empty
                    if (!inFilters) {
                        //field is not in filter - so its empty
                        array[arrayIndex] = true
                    }
                } else {
                    if (inFilters) {
                        //normal equal to value   A1=UNJF
                        let show = inFilters.Values.includes(field[1])
                        //if the value is in the
                        if (show) {
                            array[arrayIndex] = true
                        }
                    }
                }
            }
        })
    }
    //console.log(array);
    //check all array items are true
    function allAreTrue(arr) {
        return arr.every(element => element === true);
    }
      
    let showField = allAreTrue(array)
    return showField
    
}
export default showFieldLogic;