import { Grid, Skeleton, TextField, Typography, Autocomplete, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function SearchSelect({ filter, title, isFetching, updateFilters, searchParams }) {

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (searchParams && filter?.length && title) {
            // console.log(searchParams)
            // console.log(title)
            // console.log(filter)
            let urlParams = new URLSearchParams(searchParams);
            const selected = urlParams.get(title.toString());
            if (!selected) {
                setSelected([])
                return
            }
            let selectedArray = selected.split(',');
            let found = [];
            selectedArray.forEach((item) => {
                let match  =  filter.find(filterItem => filterItem.key === decodeURI(item));
                if (match) {
                    found.push(match)
                }
            })
            //console.log('found', found)
            //check if array is empty or undefined
            if (found.length === 0 || found === undefined) {
                setSelected([])
                updateFilters([])
                return
            }
            //console.log('found', found) 
            setSelected(found)
        }
    }, [searchParams, title, filter])

    const updateSelected = (newValue) => {
        setSelected(newValue)
        updateFilters(newValue)
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography>{isFetching ? <Skeleton width="30%" variant="text" animation="wave" /> : title}</Typography>
            </Grid>
            <Grid item xs={12}>
                {isFetching ?
                    <Skeleton width="100%" height={100} variant="box" animation="wave" />
                    : filter?.length ?
                        <Autocomplete
                            //disableClearable
                            limitTags={1}
                            multiple={true}
                            id="selected-brands"
                            value={selected}
                            options={filter || []}
                            renderTags={(tagValue, getTagProps) => {
                                return tagValue.map((option, index) => (
                                    <Chip {...getTagProps({ index })} label={`${option?.key}  (${option?.doc_count})`} />
                                ));
                            }}
                            getOptionLabel={option => `${option.key} (${option.doc_count})`}
                            isOptionEqualToValue={(option, value) => option?.key === value?.key}
                            onChange={(event, newValue) => {
                                updateSelected(newValue)
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    sx={{
                                        width: '100%',
                                        '& input': { zIndex: 2 },
                                        '& legend': { display: 'none' },
                                        '& fieldset': {
                                            backgroundColor: theme => theme.palette.background.paper,
                                            color: theme => theme.palette.text.primary,
                                        },
                                        '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
                                            zIndex: 2
                                        }
                                    }}
                                    //placeholder={selected.length > 0 ? '' : 'Select Brands...'}
                                    variant="outlined"
                                    placeholder={title}
                                />
                            )}
                            // loading={isFetching || !brands?.currentBrands ? true : false}
                            //disabled={isFetching || !brands?.currentBrands ? true : false}
                            loadingText="Updating Brands...."
                        />
                        : null}
            </Grid>
        </>
    );
};

