
import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react"
import PageContent from "../coolantcare/PageContent"
import InnerHeaderWrap from "../layout/InnerHeaderWrap"
import { LayoutContext } from "../store/LayoutContext";


const IframePage = ({ url }) => {

    const { app } = useContext(LayoutContext);
    const [iframeUrl, setIframeUrl] = useState();

    // useEffect(()=>{
    //  //console.log(app)
    //     if (app.selectedArea) {
    //         let current = app.menu.find(item => item.area === area)
    //        //console.log(current)
    //         if (current.url) {
    //             setIframeUrl(current.url)
    //         }
    //     }
    // },[area,app])

    useEffect(() => {
        if (url) {
            setIframeUrl(url)
        }
    }, [url])

    return (
        <>

            <Grid sx={{ height: '800px' }}>

                {iframeUrl ?
                    <iframe
                        src={iframeUrl}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                    />
                    : null

                }
            </Grid>

        </>


    )
}

export default IframePage
