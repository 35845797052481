import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { UserContext } from "../../store/UserContext"
import Row from "./Row";
import { SelectedContext } from "../../store/SelectedContext";
import { CLEAR_URL_FILTERS } from "../../store/SelectedReducers";

const ThirdRow = ({ selectedID, setToolClass, setSelectedID, form, setForm }) => {

    const { user } = useContext(UserContext)
    const [selected, setSelected] = useState();
    const { dispatchSelected } = useContext(SelectedContext)

    //--------------------------- API CALLS  ------------------------------//

    const { data, error, isFetching } = useQuery(['applicationParent', selectedID[1]],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'applicationsearchrow',
            api_key: user.api_key,
            method: 'get',
            params: {
                parent: selectedID[1]
            }
        }), {
        enabled: !!user.api_key && !!selectedID[1],
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })
    // console.log(props)
    //setSelected on first load
    useEffect(() => {
        if (selectedID && selectedID[2]) {
            setSelected(selectedID[2])
        } else if (form && form[0]) {
            setSelected(form[0])
        } else {
            setSelected()
        }
    }, [selectedID, form])

    const handleSelect = (e, value) => {
        if (value.HasChildren) {
            setToolClass(current => [current[0], current[1], value])
            setSelectedID(current => [current[0], current[1], value.ID])
            //reset form
            dispatchSelected({
                type: CLEAR_URL_FILTERS
            });
            setForm()
        } else {
            //no more children - set form
            setForm([value.ID])
            //remove
            setToolClass(current => [current[0], current[1]])
            setSelectedID(current => [current[0], current[1]])
        }
    }

    //console.log(parent)

    return (
        <>
            {selectedID[1] ?
                <Row isFetching={isFetching} data={data} title="Working Process" selected={selected} handleSelect={handleSelect} />
                : null}
        </>
    );
}
export default ThirdRow;
