import React from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import setLanguageText from '../language/setLanguageText';

const UserNotifications = () => {

  //fetch react table data from table state in new order
  // const getNotification = useCallback(async (id) => {
  //   if (id) {
  //     await executeAsync({
  //       endpoint: 'notifications',
  //       api_key: user.api_key,
  //       method: 'get',
  //       params: {
  //         'user_id': id
  //       }
  //     });
  //   }
  // }, []);

  return (
    <>
      <TabHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText("My Notification")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </TabHeader>
      <TabContent>
        <Alert severity='info'>Coming Soon</Alert>
      </TabContent>
    </>
  )
};

export default UserNotifications;


