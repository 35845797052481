import React, { useMemo, useEffect, useContext } from 'react';
import {
	Document,
	Page,
	StyleSheet,
	Text,
	View,
	usePDF
} from '@react-pdf/renderer';
import { useTheme } from '@mui/styles';
import setLanguageText from '../../../language/setLanguageText';
import splitString from '../../../helpers/splitString';
import formatDate from '../../helpers/formatDate';

export default function CoolantUsageByCoolantPDF({
	setDownloadObject,
	title,
	data,
	manufacturer,
	coolant
}) {

	const exclude_columns = ['company_name', 'coolant_id'];
	const expand_columns = ['machine_name', 'machine_group', 'coolant_name'];
	const reduce_columns = ['machine_id', 'conc', 'ph'];
	const theme = useTheme();

	const styles = StyleSheet.create({
		BODY: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(6),
			paddingHorizontal: theme.spacing(2)
		},
		ROW_ABOVE_TABLE: {
			padding: theme.spacing(1),
			// backgroundColor: theme.palette.primary.main,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			SMALL_TEXT: {
				fontSize: theme.spacing(1.5)
			},
			BIG_TEXT: {
				fontSize: theme.spacing(1.75)
			}
		},
		TABLE: {
			display: 'flex',
			width: 'auto',
			marginBottom: 20,
			borderTop: '1px solid #ccc',
			borderLeft: '1px solid #ccc',
			borderRight: '1px solid #ccc',
			borderBottom: 'none',
			ROW: {
				padding: 5,
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#fff',
				color: '#222',
				borderBottom: '1px solid #ccc',
			},
			COL: {
				border: 0
				// flex: 1
			},
			CELL: {
				padding: 5,
				fontSize: 8,
				textAlign: 'left',
				overflow: 'hidden'
			},
			HEADER: {
				padding: 5,
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#efefef',
				color: '#222',
				borderBottom: '1px solid #ccc',
				CELL: {
					padding: 5,
					fontSize: 8,
					textAlign: 'left',
					overflow: 'hidden'
				},
			},
			GROUP_ROW: {
				backgroundColor: theme.palette.background.light,
				flexDirection: 'row',
				display: 'flex',
				borderBottom: '1px solid #ccc',
				backgroundColor: '#efefef',
				CELL: {
					padding: 5,
					fontSize: 12,
					fontWeight: 'bold',
					textAlign: 'left',
					overflow: 'hidden'
				},
			}
		},
		PAGE_NUMBER: {
			position: 'absolute',
			fontSize: theme.spacing(1.5),
			bottom: theme.spacing(1.5),
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey'
		}
	});

	const create_pdf_headers = ({
		headers,
		styles,
		exclude_columns,
		expand_columns,
		reduce_columns
	}) => {
		const filter_header_columns = exclude_columns
			? Object.keys(headers).filter(header => !exclude_columns.includes(header))
			: Object.keys(headers);

		return filter_header_columns.map((header, i) => {
			const to_expand = expand_columns?.includes(header)
				? 2
				: reduce_columns?.includes(header)
					? 0.5
					: 1;
			return (
				<View
					key={i}
					style={{
						flex: to_expand
					}}
				>
					<Text style={styles.TABLE.HEADER.CELL}>{setLanguageText(splitString(header))}</Text>
				</View>
			);
		});
	}

	const create_pdf_rows = ({
		rows,
		styles,
		group_value,
		exclude_columns,
		expand_columns,
		reduce_columns
	}) => {
		const rows_values = rows.map(row => {
			return Object.entries(row).map(cell => ({
				name: cell[0],
				value: cell[1]
			}));
		});

		let group_headers = {};

		const pdf_rows = rows_values.map((row) => {
			row.forEach(({ value, name }) => {
				if (name === group_value) {
					if (value !== group_headers.name) {
						group_headers = {
							name: value,
							count: 1
						};
					} else group_headers.count++;
				}
			});

			return (
				<React.Fragment key={`${group_headers.name}-row-${group_headers.count}`}>
					{/* Row with selected Grouped */}
					{group_headers.count === 1 && (
						<View style={styles.TABLE.GROUP_ROW} wrap={false}>
							{row.map((value, i) => {
								return !i ? (
									<View
										key={`table-row-${value.value}`}
										style={{ ...styles.TABLE.GROUP_ROW.COL }}
									>
										<Text style={styles.TABLE.GROUP_ROW.CELL}>
											{group_headers.name || '---'}
										</Text>
									</View>
								) : (
									<View key={`${value.name}-${value.value}`}></View>
								);
							})}
						</View>
					)}
					{/* PDF rows */}
					<View style={styles.TABLE.ROW} wrap={false}>
						{row?.map(({ value, name }, i) => {
							const is_date =
								name === 'last_coolant_change' || name === 'date_stamp';
							const value_or_date = is_date ? formatDate(value) : value;
							const to_expand = expand_columns?.includes(name)
								? 2
								: reduce_columns?.includes(name)
									? 0.5
									: 1;
							return (
								!exclude_columns?.includes(name) && (
									<View
										key={`table-cell-${i}`}
										style={{ ...styles.TABLE.COL, flex: to_expand }}
									>
										<Text style={styles.TABLE.CELL}>{value_or_date}</Text>
									</View>
								)
							);
						})}
					</View>
				</React.Fragment>
			);
		});
		return pdf_rows;
	};

	const document = useMemo(() =>
		<Document key={title} title={title}>
			<Page
				style={styles.BODY}
			//orientation="landscape"
			>
				<View fixed style={styles.ROW_ABOVE_TABLE}>
					<Text style={styles.ROW_ABOVE_TABLE.BIG_TEXT}>
						{`${manufacturer?.brand_name} - ${coolant?.product_name}`}
					</Text>
					<Text style={styles.ROW_ABOVE_TABLE.SMALL_TEXT}>
						Total Machines: {data?.length}
					</Text>
				</View>
				<View style={styles.TABLE}>
					<View style={styles.TABLE.HEADER} fixed>
						{create_pdf_headers({
							headers: data[0],
							styles,
							exclude_columns,
							expand_columns,
							reduce_columns
						})}
					</View>
					{create_pdf_rows({
						rows: data,
						styles,
						group_value: 'company_name',
						exclude_columns,
						expand_columns,
						reduce_columns
					})}
				</View>
			</Page>
		</Document>,
		[
			title,
			data,
			styles,
			exclude_columns,
			expand_columns,
			reduce_columns,
		]);


	const [instance, updateInstance] = usePDF({ document });

	useEffect(() => {
		if (document) {
			updateInstance();
		}
	}, [document]);


	useEffect(() => {

		if (instance.blob) {
			// console.log(instance)
			setDownloadObject(instance)
		}

	}, [instance, setDownloadObject])


	return (
		<>
			{instance?.url ?
				<object
					data={instance.url}
					type="application/pdf"
					height="100%"
					width="100%"
				>
					{/* <ReactPDFFallback url={instance.url} /> */}
				</object>
				:
				null
			}
		</>
	)
};
