import { Grid, useTheme } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UPDATE_ATTRIBUTE } from '../../store/SelectionsReducers';
import EntryCircleDial from './Entry_CircleDial';
import EntryNumbers from './Entry_Numbers';
import RadioButtons from './Entry_RadioButtons';

export default function EntryAttributeTypeSelector({ attribute, initialValue, setError, edit }) {
	
	const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
	const theme = useTheme();

	// Wrap the dispatchFn in useCallback to void infinite Re-renders
	const dispatchFnCallback = useCallback(
		attribute => {
			return dispatchAddNewEntry({
				type: UPDATE_ATTRIBUTE,
				payload: attribute
			});
		},
		[dispatchAddNewEntry]
	);

	switch (attribute.three.attr_form_output) {
		case 'dial':
			return (
				<Grid
					item
					container
					key={attribute.one.attr_id}
					direction="column"
					xs={12}
					sm={6}
				>
					<EntryCircleDial
						width={150}
						min={attribute.one.attr_min}
						max={attribute.one.attr_max * 10}
						hideLabelValue
						knobSize={40}
						progressSize={20}
						trackSize={20}
						progressColorFrom={theme.palette.background.dark}
						progressColorTo={theme.palette.background.dark}
						trackColor={theme.palette.background.light}
						attr={attribute}
						dispatchFn={dispatchFnCallback}
						company={addNewEntry.company}
						machine={addNewEntry.machine}
						factored={addNewEntry.entry.factored}
						factor={addNewEntry.entry.factor}
						initialValue={initialValue}
						setError={setError}
						edit={edit}
					/>
				</Grid>
			);
		case 'radio':
			return (
				<Grid item key={attribute.one.attr_id} xs={12}>
					<RadioButtons
						options={attribute.three.attr_form_options}
						attr={attribute}
						initialValue={initialValue}
						dispatchFn={dispatchFnCallback}
						company={addNewEntry.company}
						setError={setError}
						edit={edit}
						notNeeded
					/>
				</Grid>
			);
		case 'number':
			return (
				<Grid item key={attribute.one.attr_id} xs={12} >
					<EntryNumbers
						initialValue={initialValue}
						attr={attribute}
						dispatchFn={dispatchFnCallback}
						setError={setError}
						edit={edit}
					/>
				</Grid>
			);
		default:
			return null;
	}
};
