import React, { useContext } from "react";
import { Grid, Box, Typography, Breadcrumbs, Link } from "@mui/material";
import InnerHeaderWrap from "../../layout/InnerHeaderWrap";
import SearchBar from "../search/SearchBarOLD";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalCompanySelect from "../../reusable/GlobalCompanySelect"
import { ProductsContext } from "../../store/ProductsContext";
import { SelectedContext } from "../../store/SelectedContext";
import { RESET_PRODUCTS } from "../../store/ProductsReducers";
import { CLEAR_RESULTS_FILTERS } from "../../store/SelectedReducers";
import { CategoryContext } from "../../store/CategoryContext";

export default function ShopBreadcrumbs(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const { dispatchProducts } = useContext(ProductsContext)
    const { dispatchSelected } = useContext(SelectedContext)
    const { categories } = useContext(CategoryContext)
    
    const resetAll = () => {
        dispatchProducts({
            type: RESET_PRODUCTS
        });
        //clear result filter not parametric
        dispatchSelected({
            type: CLEAR_RESULTS_FILTERS,
            payload: []
        });
    }

    return (
        <>
            {props.application && location.pathname !== '/application/search' ?
                <Box
                    sx={{
                        padding: '.5em 1em',
                        width: '100%',
                        //background: (theme) => theme.palette.background.header,
                        // background: (theme) => theme.palette.background.breadcrumb,
                        borderColor: '#0000001a',
                        borderWidth: ' 1px 0 1px 0',
                        borderStyle: 'solid',
                    }} >
                    <Grid item container xs={12} sm={6} md="auto">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link

                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: (theme) => theme.palette.text.primary
                                }}
                                //component="button"
                                variant="body2"
                                onClick={e => {
                                    //clear filters
                                    resetAll()
                                    let urlParams = new URLSearchParams(window.location.search);
                                    //console.log(urlParams)
                                    let search = urlParams.get("search")
                                    let c = urlParams.get("c")
                                    let appClass = urlParams.get("class")
                                    let appForm = urlParams.get("form")

                                    //remove filters
                                    navigate(`/application/search?c=${c}&search=${search}&filters=[]&class=${appClass}&form=${appForm}`)
                                }}
                            >
                                Application Seach
                            </Link>
                            <Link
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 500
                                }}
                                variant="body2"
                            >
                                {categories.currentCat.Name}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Box>

                : null}
            {props.parametric && location.pathname !== '/parametric/search' ?
                <Box
                    sx={{
                        padding: '.5em 1em',
                        width: '100%',
                        //background: (theme) => theme.palette.background.header,
                        // background: (theme) => theme.palette.background.breadcrumb,
                        borderColor: '#0000001a',
                        borderWidth: ' 1px 0 1px 0',
                        borderStyle: 'solid',
                    }} >
                    <Grid item container xs={12} sm={6} md="auto">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link

                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: (theme) => theme.palette.text.primary
                                }}
                                //component="button"
                                variant="body2"
                                onClick={e => {
                                    //clear filters
                                    resetAll()
                                    let urlParams = new URLSearchParams(window.location.search);
                                    //console.log(urlParams)
                                    let search = urlParams.get("search")
                                    let c = urlParams.get("c")
                                    //remove filters
                                    navigate(`/parametric/search?c=${c}&search=${search}&filters=[]&app=[]`)
                                }}
                            >
                                Parametric Search
                            </Link>
                            <Link
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 500
                                }}
                                variant="body2"
                            >
                                {categories.currentCat.Name}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Box>
                : null}
            {props.catalogue ?
                <Box
                    sx={{
                        padding: '.5em 1em',
                        width: '100%',
                        //background: (theme) => theme.palette.background.header,
                        // background: (theme) => theme.palette.background.breadcrumb,
                        borderColor: '#0000001a',
                        borderWidth: ' 1px 0 1px 0',
                        borderStyle: 'solid',
                    }} >
                    <Grid item container xs={12} sm={6} md="auto">
                        <Breadcrumb {...props} />
                    </Grid>
                </Box>
                : null}
        </>
    );
};


