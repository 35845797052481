
import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';
import setLanguageText from '../../../../language/setLanguageText';
import { useMutation, useQuery } from '@tanstack/react-query';
import axiosRequest from '../../../../axios/axoisRequest';
import { CompanyContext } from '../../../../store/CompanyContext';
import config from '../../../../config';
import { UserContext } from '../../../../store/UserContext';
import UserDialog from './UserDialog';
import { LoadingButton } from '@mui/lab';

const UserReport = () => {

    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)
    const [users, setUsers] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [userConfig, setUserConfig] = useState()
    const [open, setOpen] = useState(false)
    const [details, setDetails] = useState()
    const [permissions, setPermissions] = useState()
    const [settings, setSettings] = useState()
    const [loading, setLoading] = useState(false)

    // //get database users
    const get_users = useMutation((id) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'administrator/allusers',
            api_key: user.api_key,
            method: 'get',
            params: {
                "company_id": id
            }

        })
    }, {
        onSuccess: (data) => {
            if (!data.errerMessage) {
                setUsers(data)
            }
        }
    })

    const get_user = useMutation((user) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'user',
            method: 'get',
            params: {
                'aws_sub': user.aws_sub
            }
        })
    }, {
        onSuccess: (data) => {
            if (!data.errerMessage) {
                // setUserConfig(data)
                // setOpen(true)
                //console.log(data)
                let permissions = data.user.user_permissions
                let settings = data.user.user_settings
                let details = {
                    "email": data.user.email,
                    "aws_sub": data.user.aws_sub,
                    ...data.user.user_details
                }
                setPermissions(permissions)
                setSettings(settings)
                setDetails(details)
                setLoading(false)
                setOpen(true)
            }
        }
    })

    const getUserConfig = () => {
        setLoading(true)
        //get users and company config
        get_user.mutate(selectedUser)
    }

    useEffect(() => {
        if (selectedCompany.company_id) {
            get_users.mutate(selectedCompany.company_id)
        }
    }, [selectedCompany])

    const { data: roles } = useQuery({
        queryKey: ['roles'],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'roles',
            api_key: user.api_key,
            method: 'get',
            params: {
                all_roles: true
            }
        }),
        retry: false
    })


    return (
        <Grid item xs={12}>
            <Card sx={{ background: theme => theme.palette.cards.header }}>
                <CardHeader title={setLanguageText("Company User Access")} />
                <CardContent
                    sx={{
                        background: (theme) => theme.palette.cards.main,
                        flexDirection: 'row-reverse',
                        display: "grid",
                        height: "100%",
                        alignContent: "start"
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="users"
                                options={users || []}
                                loading={get_users.isLoading}
                                disabled={!users}
                                value={selectedUser}
                                onChange={(event, newValue) => {
                                    setSelectedUser(newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.aws_sub}>
                                        <Typography>
                                            {option.user_details.name} {option?.user_details?.surname ? option?.user_details?.surname : ''}  <br />
                                            {option?.email}
                                        </Typography>
                                    </Box>
                                )}
                                getOptionLabel={
                                    option => `${option.user_details.name}  ${option?.user_details?.surname ? `${option?.user_details?.surname} - ${option?.email}` : ''}` || ''}
                                renderInput={params => (
                                    <TextField {...params} required label={setLanguageText("Users")} variant="outlined" />
                                )}
                                sx={{ width: 300 }}
                                disableClearable
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={() => getUserConfig()}
                            loading={loading}
                            disabled={!selectedUser}
                        >
                            Run Access Report
                        </LoadingButton>
                    </Grid>
                </CardActions>
            </Card>
            {details && open ?
                <UserDialog
                    details={details}
                    settings={settings}
                    permissions={permissions}
                    open={open}
                    setOpen={setOpen}
                    roles={roles}
                />
                : null}
        </Grid>
    );
};

export default UserReport;
