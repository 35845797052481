import {
    Grid
} from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../../../store/UserContext';
import { MachineContext } from '../../store/MachineContext';
import Alert from '@mui/material/Alert';
import {
    UPDATE_MACHINE_SETTINGS
} from '../../store/MachineReducers';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { SAVE_SELECTED_COOLANT } from '../../store/CoolantReducers';
import { CoolantContext } from '../../store/CoolantContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import DialogLoader from '../../../reusable/DialogLoader';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useMutation } from '@tanstack/react-query';

const ConfirmReset = ({
    open,
    handleClose,
    handleConfirmClose,
    resetDate,
    resetMachineSettings,
    currentCoolant,
    coolant,
    selectedMachine,
    selectedDate
}) => {

    const { user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { dispatchSelectedCoolant } = useContext(CoolantContext);
    const {
        updateActivityLog,
    } = useActivityLog();

    const resetMachine = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'patch',
            body: body
        }),
        onSuccess: () => {

            if (currentCoolant.product_id !== coolant.product_id) {
                dispatchSelectedCoolant({
                    type: SAVE_SELECTED_COOLANT,
                    payload: coolant
                });
            }

            if (resetMachineSettings) {
                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        product_id: coolant.product_id,
                        machine_defaults: {
                            "factor": coolant.product_defaults.defaults.factor,
                            "op_con_max": coolant.product_defaults.defaults.con_max,
                            "op_con_min": coolant.product_defaults.defaults.con_min,
                            "ph_max": coolant.product_defaults.defaults.ph_max,
                            "ph_min": coolant.product_defaults.defaults.ph_min
                        }
                    }
                });
            }

            if (resetDate) {
                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        last_product_change: selectedDate
                    }
                });
            }

            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    machine_id: selectedMachine.machine_id,
                    new_settings: {
                        "newCoolant": currentCoolant.product_id !== coolant.product_id,
                        "resetDate": resetDate,
                        "selectedDate": selectedDate,
                        "resetMachineSettings": resetMachineSettings
                    }
                },
                activity_type: currentCoolant ? 'change coolant' : 'update settings',
            })
            handleConfirmClose();
        }
    })

    const handleSubmit = () => {

        resetMachine.mutate({
            edit: 'coolant',
            machine_id: selectedMachine.machine_id,
            coolant: coolant,
            newCoolant: currentCoolant.product_id !== coolant.product_id,
            resetDate: resetDate,
            selectedDate: selectedDate,
            resetMachineSettings: resetMachineSettings
        })
    };

    return (
        <GlobalDialog
            open={open}
            onClose={handleClose}
            title="Reset/Refresh Coolant"
            buttonClick={handleSubmit}
            buttonTitle="Yes"
            successMessage="Entry Updated successfully!"
            maxWidth="sm"
        >
            <Grid item container spacing={2}>
                <Grid item>
                    Are you Sure you want to:
                </Grid>
                {currentCoolant.product_id !== coolant.product_id ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Change the machine coolant to {coolant.product_name}
                        </Alert>
                    </Grid>
                    : null}
                {resetDate ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Reset the Coolant Change Date
                        </Alert>
                    </Grid>
                    : null}
                {resetMachineSettings ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Set Machine Values to Coolant Defaults
                        </Alert>
                    </Grid>
                    : null}

            </Grid>
            {resetMachine.isLoading || resetMachine.isSuccess || resetMachine.isError ?
                <DialogLoader
                    isLoading={resetMachine.isLoading}
                    mutation={resetMachine}
                    loadingMessage="Resetting Machine..."
                    successMessage="Machine Reset"
                    fixed
                />
                : null}
        </GlobalDialog>
    );
};

export default ConfirmReset;
