import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../../language/setLanguageText';

const DrawItem = ({ title, icon, onClick, selected, disabled, isOpen, submenu }) => {

  const theme = useTheme()

  return (
    <>
      <ListItemButton
        selected={selected}
        disabled={disabled}
        onClick={onClick}
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
          padding: '1em 0',
        }}
      >
        <ListItemIcon sx={{ width: (theme) => `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }}>
          <FontAwesomeIcon style={{ margin: '0 auto' }} color={theme.palette.primary.contrastText} icon={icon} size={'xl'} />
        </ListItemIcon>
        {setLanguageText(title)}
        {submenu ? <FontAwesomeIcon style={{ margin: '0 auto' }} color={theme.palette.primary.contrastText} icon={faChevronRight} size={'sm'} /> : null}
      </ListItemButton>
    </>


  );
};

export default DrawItem;
