import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Grid, useMediaQuery, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AreaDropdown from '../menu/areaMenu/AreaDropdown';
import { isMobile, isMobileOnly } from 'react-device-detect';
import AdminMenu from '../menu/AdminMenu';
import Basket from '../basket/Basket';
import AccountMenu from '../menu/AccountMenu';
import { UserContext } from '../store/UserContext';

export default function ACTHeader() {

    const [actLogo, setActLogo] = useState('');
    const { user } = useContext(UserContext);
    import(`../images/act-logo-dark.png`).then((module) => {
        setActLogo(module.default);
    });

    const mdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <>
            <AppBar position='relative' elevation={0} sx={{ height: isMobileOnly ? '90px' : '100px', backgroundColor: 'background.main' }} ></AppBar >
            <AppBar position="fixed" sx={{ zIndex: 1001 }} >
                <Toolbar
                    sx={{
                        padding: '1em!important',
                        background: '#FFF',
                        height: isMobileOnly ? '90px' : '100px',
                        borderBottom: theme => `4px solid ${theme.palette.primary.main}`,
                        '& svg': {
                            color: '#222'
                        }
                    }}>
                    <Box sx={{ width: '100%' }}>
                        <Grid container alignItems="center">
                            <Grid item container alignItems="center" justifyItems='center' xs={isMobileOnly ? 12 : 6} md={4} >
                                <Grid item xs="auto" >
                                    <AreaDropdown />
                                </Grid>
                                <Grid item container xs>
                                    <Grid item xs={12} sx={{ position: 'relative' }}>
                                        <Link to="/">
                                            <img
                                                style={{
                                                    //marginLeft: '14px',
                                                    //marginTop: '11px',
                                                    width: isMobileOnly ? '180px' : isMobile ? '225px' : '225px'
                                                }}
                                                alt="My Factory"
                                                src={actLogo}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            color="#333"
                                            variant="caption"
                                            component="h2"
                                        >
                                            The Cutting Tool &amp; Lubricant Search Engine
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!isMobileOnly ?
                                <Grid item container alignItems="right" justifyContent="end" spacing={isMobile ? 1 : 2} xs={6} md={8} >
                                    {mdDown ? null :

                                        <Grid
                                            item
                                            xs
                                            container
                                            textAlign="right"
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    color="#333"
                                                    textAlign="right"
                                                    variant="body"
                                                    sx={{
                                                        'span': {
                                                            color: theme => theme.palette.primary.main
                                                        }
                                                    }}
                                                >
                                                    The <span>Right</span> Cutting Tool &amp; Lubricants.<br />
                                                    On Time. Every Time.
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    }

                                    <Grid item >
                                        <AdminMenu />
                                    </Grid>
                                    <Grid item >
                                        <AccountMenu />
                                    </Grid>
                                    {(
                                        user?.user?.user_permissions?.buyers?.access === 1 ||
                                        user?.user?.user_permissions?.application?.access === 1 ||
                                        user?.user?.user_permissions?.engineers?.access === 1 ||
                                        user?.user?.user_permissions?.parametric?.access === 1 ||
                                        user?.user?.user_permissions?.cadcam?.access === 1
                                    ) ?
                                        <Grid item >
                                            <Basket />
                                        </Grid>
                                        : null}
                                </Grid>
                                : null}
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}

