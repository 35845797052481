import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button } from '@mui/material';
import { MachineContext } from '../../store/MachineContext';
import { SELECTED_MACHINE } from '../../store/MachineReducers';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';

function ResetMachine() {

	const { user } = useContext(UserContext);
	const { dispatchSelectedMachine, selectedMachine } = useContext(MachineContext);
	const [open, setOpen] = useState(false);

	const {
		updateActivityLog
	} = useActivityLog();

	const openPopover = () => {
		setOpen(true);
	};

	const resetMachine = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response) => {

			updateActivityLog.mutate({
				activity_details: {
					area: "coolant",
					machine_id: selectedMachine.machine_id,
					company_id: selectedMachine.company_id
				},
				activity_type: "machine reset settings"
			})

			//not sure if this works
			const selected = {
				...selectedMachine,
				machine_settings: response[0].machine_settings
			};
			//console.log(selected)
			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: selected
			});

			setOpen(false);
		}
	})

	const confirmReset = () => {
		resetMachine.mutate({
			edit: 'reset',
			machine_id: selectedMachine.machine_id,
			company_id: selectedMachine.company_id
		})
	};

	return (
		<>
			<Button
				variant="contained"
				color="warning"
				onClick={openPopover}
				startIcon={<FontAwesomeIcon icon={faUndo} />}
			>
				{setLanguageText("Reset Machine Settings")}
			</Button>
			<GlobalDialog
				open={open}
				onClose={e => setOpen(false)}
				buttonClick={e => confirmReset(e)}
				buttonTitle={"Confirm Reset"}
				title={"Reset Machine Defaults"}
			>
				<Alert severity="error">
					{setLanguageText("Reset this machine to the default settings?")}
				</Alert>
			</GlobalDialog>
		</>
	);
}

export default ResetMachine;
