import { Box } from "@mui/material";
import React, { useContext } from "react";
import { isMobileOnly } from 'react-device-detect';
import { MachineProvider } from "./../coolantcare/store/MachineContext";
import { AdditiveProvider } from "./../coolantcare/store/AdditiveContext";
import { CoolantProvider } from "./../coolantcare/store/CoolantContext";
import { ProductsProvider } from "./../coolantcare/store/ProductsContext";
import { SelectionsProvider } from "./../coolantcare/store/SelectionsContext";
import { BrandsProvider } from "./../coolantcare/store/BrandsContext";
import { ChartsProvider } from "./../coolantcare/store/ChartsContext";
import FloatingDial from './../coolantcare/FloatingActionButton/FloatingActionButton';
import QrCode from './../coolantcare/FloatingActionButton/QrCode';
import config from "../config";
import { Storage } from 'aws-amplify';
import GlobalMeta from "../reusable/GlobalMeta";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../reusable/ErrorFallback";
import logErrors from "../helpers/logErrors";
import { UserContext } from "../store/UserContext";

const CoolantRoutesWrap = (props) => {

  Storage.configure({
    bucket: config.s3.BUCKET,
    region: config.s3.REGION
  });
  const { user } = useContext(UserContext);
  

  return (
    <MachineProvider>
      <AdditiveProvider>
        <CoolantProvider>
          <ProductsProvider>
            <SelectionsProvider>
              <BrandsProvider>
                <ChartsProvider>
                  <GlobalMeta title={props.title} />
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={(error, info) => {
                      return logErrors({
                        'component':  props.title,
                        'user': user,
                        'full': info.componentStack.toString(),
                        'message': error.message.toString()
                      })
                    }}
                    onReset={() => {
                      //force reload
                      //location.reload();
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '100%',
                        display: 'block',
                        width: '100%',
                        zIndex: 2,
                        minHeight: theme => `calc(100vh - ${theme.spacing(20)})`,
                        marginBottom: theme => isMobileOnly ? 0 : theme.spacing(10)
                      }}
                    >
                      {props.children}
                      <Box
                        sx={{
                          position: 'fixed',
                          bottom: theme => isMobileOnly ? 0 : theme.spacing(2),
                          right: theme => theme.spacing(0),
                          zIndex: 1000,
                          display: 'flex',
                          alignItems: 'flex-end',
                          width: isMobileOnly ? '100%' : 'auto'
                        }}>
                        <QrCode />
                        <FloatingDial />
                      </Box>
                    </Box>
                  </ErrorBoundary>
                </ChartsProvider>
              </BrandsProvider>
            </SelectionsProvider>
          </ProductsProvider>
        </CoolantProvider>
      </AdditiveProvider>
    </MachineProvider >
  );
};

export default CoolantRoutesWrap;
