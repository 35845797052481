import {
    Button,
    Grid,
    Typography,
    useTheme
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from "yup"
import { renderToString } from 'react-dom/server';
import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import { useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from '../store/CompanyContext';
import Loader from '../reusable/Loader';
import CustomCard from '../reusable/CustomCard';
import FileUpload from '../media/FileUpload';
import { useNavigate } from 'react-router-dom';
import FilePreviewOld from '../media/FilePreviewOld';
import FileUploadProgress from '../media/FileUploadProgress';
import DesignRequestEmail from '../emails/DesignRequestEmail';
import { Storage } from 'aws-amplify';
import DesignForm from './DesignForm';
import DesignWizard from './wizard/DesignWizard';
import DesignDetails from './DesignDetails';
import { LayoutContext } from '../store/LayoutContext';

const DesignNew = () => {

    const navigate = useNavigate()
    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { theme: layoutTheme, app } = useContext(LayoutContext);
    const theme = useTheme();
    const [files, setFiles] = useState([])
    const [folder, setFolder] = useState()
    const [completedRequest, setCompletedRequest] = useState()
    const [uploading, setUploading] = useState(false)


    Storage.configure({
        AWSS3: {
            bucket: config.designS3.BUCKET,
            region: config.designS3.REGION
        }
    });

    const validationSchema = Yup.object({
        name: Yup.string()
            .min(2, "Must be at least 2 characters")
            .max(50, "Must be 50 characters or less")
            .required("Name is required"),
        // details: Yup.string()
        //   .required("Detail are required"),
        workpiece: Yup.string()
            .required("Workpiece Material is required"),
        material: Yup.object()
            .required("Tool Material is required"),
        finish: Yup.object()
            .required("Finish is required"),
        quantity: Yup.number()
            .required("Quantity is required")
    })

    const initialValues = {
        name: '',
        workpiece: '',
        material: null,
        finish: null,
        quantity: '',
        date: null,
        details: '',
        wizard: null
    }

    //send new request email
    const send_email = useMutation((request) => {

        //console.log(request)
        const emailString = renderToString(<DesignRequestEmail request={request} theme={theme} logo={layoutTheme.email_logo} />)

        let emails = []
        //send to company email if set
        if (selectedCompany?.company_settings?.designs?.email) {
            if (Array.isArray(selectedCompany.company_settings.designs.email)) {
                //add all emails to emails array
                selectedCompany.company_settings.designs.email.forEach((email) => {
                    emails.push(email)
                })
            } else {
                //add single email to emails array
                emails.push(selectedCompany.company_settings.designs.email)
            }
        } else if (!selectedCompany?.company_settings?.designs?.email && app?.emails?.design_email) {
            //send to global application email if compnay is not set
            if (Array.isArray(selectedCompany.company_settings.designs.email)) {
                //add all emails to emails array
                app.emails.design_email.forEach((email) => {
                    emails.push(email)
                })
            } else {
                //add single email to emails array
                emails.push(app.emails.design_email)
            }
        }

        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'email',
            api_key: user.api_key,
            method: 'post',
            body: {
                email: emails,
                html: emailString,
                subject: 'New Design Request'
            }
        })
    })

    const mutation = useMutation(values => {

        //console.log(values)
        const today = new Date()
        const filesPaths = files.map((file) => {
            const fileObject = {
                type: file.type,
                name: file.name,
            }
            return fileObject
        })
        //mysql datetime
        const request = {
            request_name: values.name,
            request_workpiece: values.workpiece,
            request_material: values.material,
            request_finish: values.finish,
            request_quantity: values.quantity,
            request_details: values.details,
            request_wizard: values.wizard,
            request_required_date: Date.parse(values.date.toString()),
            request_submit_date: Date.parse(today.toString()),
            request_files: {
                //folder : `${folder}-${Date.parse(today.toString())}` , 
                files: filesPaths
            },
            request_status: 'New',
            request_last_updated: {
                user_id: user.user.user_id,
                user_details: user.user.user_details,
                email: user.user.email,
                date: Date.parse(today.toString())
            },
            request_user: {
                user_id: user.user.user_id,
                user_details: user.user.user_details,
                email: user.user.email
            },
            request_company: {
                company_id: selectedCompany.company_id,
                company_name: selectedCompany.company_name
            }
        }
        setCompletedRequest(request);
        //console.log(request)
        return axiosRequest({
            endpoint: `requests`,
            method: 'post',
            gateway: config.designAPI.URL,
            api_key: user.api_key,
            body: request
        })
    }, {
        onSuccess: (data) => {
            //console.log(data)
            setFolder(data.insertId);
        }
    })

    const finshedUploading = () => {

        send_email.mutate({
            'request': completedRequest,
            'request_id': folder,
        })
        navigate(`/design/view?request=${folder}&company=${selectedCompany.company_id}`)
    }

    //once the request has been uploaded and return an insertId
    useEffect(() => {
        if (folder && completedRequest) {
            //console.log(folder)
            //console.log(completedRequest)
            if (Object.values(files).length) {
                setUploading(true)
            } else {
                finshedUploading()
            }
        }
    }, [folder, completedRequest, files])


    // add files to current files
    const handleSelectUploadedFile = (newFiles) => {
        setFiles(currentFiles => [...currentFiles, newFiles])
    }
    //remove file
    const handleRemoveItem = (file) => {
        //console.log(file)
        setFiles(files => files.filter((f) => f.name !== file.name))
    }

    const handleSubmit = (values) => {
        //console.log(values)
        mutation.mutate(values);
    }

    return (
        <>
            <InnerHeaderWrap >
                <Grid spacing={2} container justifyContent="space-between" alignItems="center" >
                    <Grid item xs={12} md={3} >
                        <GlobalCompanySelect fullWidth />
                    </Grid>
                </Grid>
            </InnerHeaderWrap>
            <PageContent>
                <Grid container item spacing={2} xs={12}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ touched, errors, handleChange, values, submitForm }) => {
                            return (
                                <>
                                    <Grid item container xs={12} spacing={2} justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
                                        <Grid item>
                                            <Typography component="p" variant='h6'>
                                                Add a New Design Request
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Buttons navigate={navigate} submitForm={submitForm} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2} xs={12}>

                                        <Grid item xs={12}>
                                            <DesignDetails />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCard title="Design Wizard" sx={{ overflow: 'unset' }}>
                                                <Grid container item spacing={4} sx={{ overflow: 'unset' }}>
                                                    <Grid item xs={12} sx={{ overflow: 'unset' }}>
                                                        <DesignWizard />
                                                    </Grid>
                                                </Grid>
                                            </CustomCard>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCard title="Design Attachments">
                                                <Grid container item spacing={4}>
                                                    <Grid item xs={12}>
                                                        <FileUpload
                                                            handleSelectUploadedFile={handleSelectUploadedFile}
                                                            dropzoneProps={{
                                                                noClick: true,
                                                                // accept: {
                                                                //   'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
                                                                // }
                                                                maxFiles: 10,
                                                                //maxSize: 5242880,
                                                                multiple: true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid container item spacing={4} >
                                                        {!files.length && (
                                                            <Grid item xs={12}>
                                                                <Typography>Added files will show here</Typography>
                                                            </Grid>
                                                        )}
                                                        {files && files.map((item, index) => {
                                                            return (
                                                                <Grid item xs={12} md={4} lg={3} key={index}>
                                                                    <FilePreviewOld
                                                                        item={item}
                                                                        handleRemoveItem={handleRemoveItem}
                                                                        index={index}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </CustomCard>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DesignForm />
                                        </Grid>
                                        <Grid item container xs={12} spacing={2} justifyContent="flex-end">
                                            <Buttons navigate={navigate} submitForm={submitForm} />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }}
                    </Formik>
                </Grid>
                {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                    <Loader
                        isLoading={mutation.isLoading}
                        mutation={mutation}
                        loadingMessage="Creating Request..."
                        successMessage="Request Uploaded"
                        callback={() => {
                            //navigate('/solution/dashboard')
                            //console.log('uploading files')
                        }}
                    />
                    : null}
                {uploading && files && folder ?
                    <FileUploadProgress
                        folder={folder}
                        files={files}
                        open={uploading}
                        callback={finshedUploading}
                    />
                    : null}
            </PageContent>
        </>
    );
}

export default DesignNew;

const Buttons = ({ navigate, submitForm }) => {
    return (
        <Grid item container xs={12} spacing={2} justifyContent="flex-end">
            <Grid item>
                <Button
                    size='large'
                    color="error"
                    variant="text"
                    onClick={() => navigate('/design/dashboard')}
                    startIcon={
                        <FontAwesomeIcon
                            icon={faTimes}
                        />
                    }
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size='large'
                    color="primary"
                    variant="contained"
                    onClick={submitForm}
                    startIcon={
                        <FontAwesomeIcon
                            icon={faSave}
                        />
                    }
                >
                    Submit request
                </Button>
            </Grid>
        </Grid>
    )
}

