import { Pagination, Autocomplete, TextField, Grid } from "@mui/material";
import React from "react";

const limitOptions = [10, 25, 50, 100]

const SolutionsPagination = ({
    pageIndex,
    gotoPage,
    pageSize,
    setPageSize,
    // previousPage,
    // canPreviousPage,
    // nextPage,
    // canNextPage,
    pageCount
}) => {

    const handleChange = (event, value) => {
        gotoPage(value - 1);
    };

    const handlePageSize = (event, value) => {
       //console.log(value)
        setPageSize(value);
    };

    // console.log(pageIndex)
    // console.log(pageSize)
    // console.log(pageCount)

    return (
        <>{pageCount > 1 ?
            <Grid container spacing={2} justifyContent="flex-end" >
                <Grid item >
                    <Pagination
                        sx={{
                            marginBottom: '1em',
                            '& li button': {
                                padding: '19px 5px',
                                borderColor: '#b8b8b8'
                            }
                        }}
                        siblingCount={1}
                        shape="rounded"
                        color="primary"
                        variant="outlined"
                        count={pageCount}
                        page={pageIndex + 1}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item >
                    <Autocomplete
                        size="small"
                        disableClearable
                        sx={{ width: 80 }}
                        options={limitOptions}
                        value={pageSize}
                        onChange={handlePageSize}
                        autoHighlight
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Per Page'}
                                //InputLabelProps={{ shrink: false }}
                                placeholder="Please Select"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            : null}
        </>

    );
};
export default SolutionsPagination;
