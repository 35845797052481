
import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button } from '@mui/material';
import { Alert } from '@mui/material';
import { LayoutContext } from '../store/LayoutContext';
import { faTimes, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
    preview: {
        height: 'auto',
        maxWidth: '100%',
        objectFit: 'contain',
        lineHeight: 0
    }
}));

const ImageUpload = ({ edit, path, setImage }) => {

    const [imageUrl, setImageUrl] = useState();
    const [imagePreview, setImagePreview] = useState();
    const { language } = useContext(LayoutContext)

    const ref = useRef();
    const classes = useStyles();

    useEffect(() => {
        //console.log(path)
        if (path) {

            setImageUrl(path);
            setImagePreview();
            ref.current.value = "";
        } else {
            setImageUrl();
            setImagePreview();
            ref.current.value = "";
        }
        return () => {
        }
    }, [path])

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            let file = e.target.files[0]
            //console.log(file);
            setImagePreview(file);
            setImage(file);
        }
    }

    const addDefaultSrc = () => {
        setImageUrl(null)
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                {imageUrl ?
                    <img
                        className={classes.preview}
                        src={imageUrl}
                        alt=""
                        onError={addDefaultSrc}
                        style={{
                            //padding: '1em',
                            backgroundPosition: '0px 0px, 10px 10px',
                            backgroundSize: '20px 20px',
                            backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
                        }}
                    />
                    : !imagePreview ?
                        <Alert
                            severity="error"
                            elevation={1}
                            variant="standard"
                        >
                            No Image Uploaded
                        </Alert>
                        : null}
            </Grid>
            {imagePreview &&
                <Grid item xs={12}>
                    <img
                        className={classes.preview}
                        alt=""
                        src={URL.createObjectURL(imagePreview)}
                        style={{
                            //padding: '1em',
                            backgroundPosition: '0px 0px, 10px 10px',
                            backgroundSize: '20px 20px',
                            backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
                        }}
                    />
                </Grid>
            }
            <Grid item container xs={12} alignItems="center" spacing={1}>
                <Grid item>
                    {imagePreview ?
                        <Button
                            variant="contained"
                            color="secondary"
                            size='large'
                            sx={{ marginRight: '1em' }}
                            onClick={() => {
                                setImagePreview();
                                setImage();
                            }}
                        > <FontAwesomeIcon
                                icon={faTimes}
                                size="xl"
                            />
                        </Button>
                        : null
                    }
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!edit}
                    >
                        Upload File
                        <input
                            hidden
                            ref={ref}
                            disabled={!edit}
                            type="file"
                            accept="image/png,image/jpeg,image/webp"
                            onChange={handleChange}
                        />
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant='caption'>{!imagePreview ? 'No File Chosen' : imagePreview.name} </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ImageUpload;
