import React, { useEffect, useState } from 'react';
import EmailContainer from './EmailContainer';
import EmailHeader from './EmailHeader';
import config from '../config';
import EmailFooter from './EmailFooter';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'html-react-parser';

const DesignRequestEmail = ({ request, theme, logo }) => {

    console.log(request)

    const demo_request = {
        "name": "Test",
        "surname": "User",
        "company_id": 0,
        "company_name": "Test Company",
        "request_required_date": new Date(),
        "request_id": 47,
        "request_name": "latest test",
        "request_workpiece": "8",
        "request_material": { "id": 2, "name": "HSS" },
        "request_finish": { "id": 2, "name": "Uncoated" },
        "request_quantity": 7,
        "request_details": "<p>hello</p>\n",
        "request_wizard": { "image": "https://cis-act.salessupportserver.com/Images/help/fsn06.png", "design": [{ "Ref": "A1", "Name": "Cutting diameter", "Type": "float", "Unit": "mm", "Field": "DC", "value": "16", "Values": [], "Position": 0 }, { "Ref": "B2", "Name": "Depth of cut maximum", "Type": "float", "Unit": "mm", "Field": "APMX", "value": "4", "Values": [], "Position": 0 }, { "Ref": "B3", "Name": "Protruding length", "Type": "float", "Unit": "mm", "Field": "LPR", "value": "160", "Values": [], "Position": 0 }], "breadcrumb": [{ "name": "Milling cutters" }, { "name": "Solid milling cutters with shank" }, { "name": "Ball nose milling cutters" }] },
        "request_required_date": 1690758000000,
        "request_submit_date": 1689672216000,
        "request_files": { "files": [] },
        "request_comment_count": 0,
        "request_status": "New",
        "request_last_updated": { "date": 1689672216000, "email": "jim@design-monkey.co.uk", "user_id": 1, "user_details": { "name": "Jim", "surname": "Barnes" } },
        "request_user": { "email": "jim@design-monkey.co.uk", "user_id": 1, "user_details": { "name": "Jim", "surname": "Barnes" } },
        "request_company": { "company_id": 1, "company_name": "Company 1" },
        "request_archived": 0
    }


    return (
        <EmailContainer>
            <table style={{ width: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <EmailHeader logo={logo} theme={theme} />
                <tbody>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h1 style={{ marginBottom: 10, color: theme.palette.primary.main }}>New Design Request</h1>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr><td valign="top" align="center" colSpan={2}>

                        <p>Added by <strong>{!request ? demo_request.name : request?.request?.request_user?.user_details.name} {!request ? demo_request.surname : request?.request?.request_user?.user_details?.surname}
                        </strong> on <strong>{!request ? new Date().toLocaleDateString() : new Date(request.request.request_submit_date).toLocaleDateString()} @ {!request ? new Date().toLocaleTimeString() : new Date(request.request.request_submit_date).toLocaleTimeString()}</strong></p>
                        <p>Required by <strong>{!request ? new Date(demo_request.request_required_date).toLocaleDateString() : new Date(request.request.request_required_date).toLocaleDateString()}</strong></p>
                        <p>Company <strong>{!request ? demo_request.company_name : request?.request?.request_company?.company_name}</strong></p>

                    </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h2 style={{ marginBottom: 16, color: theme.palette.primary.main }}>{!request ? demo_request.request_name : request.request.request_name}</h2>
                            <h5>{
                                !request ?
                                    <>
                                        {demo_request?.request_wizard?.breadcrumb[0]?.name} &gt; {demo_request?.request_wizard?.breadcrumb[1]?.name} &gt; {demo_request?.request_wizard?.breadcrumb[2]?.name}
                                    </>
                                    :
                                    <>
                                        {request?.request_wizard?.breadcrumb[0]?.name} &gt; {request?.request_wizard?.breadcrumb[1]?.name} &gt; {request?.request_wizard?.breadcrumb[2]?.name}
                                    </>
                            }</h5>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" align="center" >
                            {!request ?
                                <div style={{ width: '250px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                    <img style={{ maxWidth: '250px', maxHeight: '100%', objectFit: 'contain' }} src={demo_request?.request_wizard?.image} />
                                </div>
                                : null}

                            {request?.request?.request_wizard?.image ?
                                <div style={{ width: '250px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                    <img style={{ maxWidth: '250px', maxHeight: '100%', objectFit: 'contain' }} src={request.request.request_wizard?.image} />
                                </div>
                                : null}
                        </td>

                    </tr>
                    {request?.request?.request_wizard?.design || demo_request?.request_wizard?.design ?
                        <tr>
                            <td colSpan="2" align="center" style={{ paddingBottom: 0 }}>
                                <h2 style={{ marginBottom: 0, color: theme.palette.primary.main }}>Requested Attributes</h2>
                            </td>
                        </tr>
                        : null}

                    {request?.request?.request_wizard?.design ?
                        <tr>
                            <td colSpan="2" align="left" >
                                <table
                                    style={{ border: '1px solid #ccc' }}
                                    width="100%"
                                    cellSpacing="0"
                                    cellPadding="5"
                                    align="left"
                                >
                                    <tbody>
                                        {request.request.request_wizard.design.map((prop, i) => {
                                            let value
                                            if (prop.Type === "dropdown" || prop.Type === "imageDropdown") {
                                                value = prop?.value?.Name
                                            } else {
                                                value = prop?.value
                                            }
                                            return (
                                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#f6f6f6', padding: '5px' }}>
                                                    <td colSpan="1" align="left" >
                                                        {value} ({prop.Ref})
                                                    </td>
                                                    <td colSpan="1"  >
                                                        {value} {prop.Unit}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        : !request && demo_request?.request_wizard?.design ?
                            <tr>
                                <td colSpan="2" align="left" >
                                    <table
                                        style={{ border: '1px solid #f6f6f6' }}
                                        width="100%"
                                        cellSpacing="0"
                                        cellPadding="5"
                                        align="left"
                                    >
                                        <tbody>
                                            <tr style={{ backgroundColor: '#FFF', padding: '5px' }}>
                                                <td colSpan="1" align="left" >
                                                    Quantity
                                                </td>
                                                <td colSpan="1"  >
                                                    {!request ? demo_request.request_quantity : request?.request?.request_quantity}
                                                </td>
                                            </tr>
                                            <tr style={{ backgroundColor: '#f6f6f6', padding: '5px' }}>
                                                <td colSpan="1" align="left" >
                                                    Workpiece Material
                                                </td>
                                                <td colSpan="1"  >
                                                    {!request ? demo_request.request_workpiece : request?.request?.request_workpiece}
                                                </td>
                                            </tr>
                                            <tr style={{ backgroundColor: '#FFF', padding: '5px' }}>
                                                <td colSpan="1" align="left" >
                                                    Finish
                                                </td>
                                                <td colSpan="1"  >
                                                    {!request ? demo_request.request_finish.name : request?.request?.request_finish?.name}
                                                </td>
                                            </tr>
                                            <tr style={{ backgroundColor: '#f6f6f6', padding: '5px' }}>
                                                <td colSpan="1" align="left" >
                                                    Tool Material
                                                </td>
                                                <td colSpan="1"  >
                                                    {!request ? demo_request.request_material.name : request?.request?.request_material?.name}
                                                </td>
                                            </tr>

                                            {/* <td colSpan="1"  >
                            <p>Quantity: <strong>{!request ? demo_request.request_quantity : request?.request?.request_quantity}</strong></p>
                            <p>Workpiece Material: <strong>{!request ? demo_request.request_workpiece : request?.request?.request_workpiece} </strong></p>
                            <p>Finish: <strong>{!request ? demo_request.request_finish.name : request?.request?.request_finish?.name}</strong></p>
                            <p>Tool Material: <strong>{!request ? demo_request.request_material.name : request?.request?.request_material?.name}</strong></p>
                        </td> */}
                                            {demo_request?.request_wizard?.design.map((prop, i) => (
                                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#f6f6f6', padding: '5px' }}>
                                                    <td colSpan="1" align="left" >
                                                        {prop.Name} ({prop.Ref})
                                                    </td>
                                                    <td colSpan="1"  >
                                                        {prop.value} {prop.Unit}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            : null}

                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#efefef', width: '600px', padding: '12px', margin: '0 auto' }} >
                                {!request ? ReactHtmlParser(demo_request.request_details) : request?.request?.request_details ? ReactHtmlParser(request.request.request_details) : 'No request details'}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }} colSpan={2}>
                            <div style={{ borderRadius: '4px', padding: '10px', backgroundColor: theme.palette.primary.main, margin: '0 auto', width: '220px' }}>
                                <FontAwesomeIcon style={{ marginRight: '10px', width: '20px', height: '20px', color: '#fff', float: 'left' }} icon={faMagnifyingGlass} />
                                {!request ?
                                    <a style={{ textDecoration: 'none', color: theme.palette.primary.contrastText, width: 'auto' }} >
                                        View Request Details
                                    </a>
                                    :
                                    <a style={{ textDecoration: 'none', color: theme.palette.primary.contrastText, width: 'auto' }} href={`${window.location.origin}/design/view?request=${request?.request_id}&company=${request?.request?.request_company?.company_id}`} target="_blank" rel="noreferrer">
                                        View Request Details
                                    </a>
                                }
                            </div>
                        </td>
                    </tr>
                    <EmailFooter theme={theme} />
                </tbody>
            </table >
        </EmailContainer >
    )
};

export default DesignRequestEmail;  
