import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Grid, useMediaQuery, Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AreaDropdown from '../menu/areaMenu/AreaDropdown';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { LayoutContext } from '../store/LayoutContext';
import AdminMenu from '../menu/AdminMenu';
import Basket from '../basket/Basket';
import AccountMenu from '../menu/AccountMenu';
import { UserContext } from '../store/UserContext';

export default function CISHeader() {

    const [logo, setLogo] = useState('');
    const { user } = useContext(UserContext);
    const { theme } = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (theme) {
            //console.log(theme)
            setLogo(theme.login_logo)
        }
    }, [theme])

    const mdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
    const lgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const xlDown = useMediaQuery(theme => theme.breakpoints.down('xl'));

    return (
        <>
            <AppBar position='relative' elevation={0} sx={{ height: isMobileOnly ? '90px' : '100px', backgroundColor: 'background.main' }} ></AppBar >
            <AppBar position="fixed" sx={{ zIndex: 1001 }} >
                <Toolbar
                    sx={{
                        padding: '1em!important',
                        background: '#222',
                        height: isMobileOnly ? '80px' : '90px',
                        //borderBottom: theme => `4px solid ${theme.palette.primary.main}`,
                    }}>
                    <Box sx={{ width: '100%' }}>
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item container alignItems="center" justifyItems='center' xs={6} md={6} xl={4} >
                                <Grid item xs="auto" >
                                    <AreaDropdown />
                                </Grid>
                                <Grid item container xs>
                                    <Grid item xs={12}>
                                        <Link
                                            style={{
                                                lineHeight: '0'
                                            }}
                                            to="/"
                                        >
                                            {logo &&
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <img
                                                        src={logo}
                                                        style={{
                                                            width: isMobileOnly ? '260px' : lgDown ? '280px' : '360px',
                                                        }}
                                                        alt="Logo"
                                                        onClick={e => navigate('/')}
                                                    />
                                                    {/* <Typography
                                                        variant={isMobile ? 'caption' : 'h6'}
                                                        component="span"
                                                        sx={{
                                                            position: 'absolute',
                                                            fontWeight: '400',
                                                            right: isMobile ? '-17px' : '-22px',
                                                            top: isMobile ? '10px' : '5px',
                                                            color: (theme) => theme.palette.primary.contrastText
                                                        }}
                                                    >™</Typography> */}
                                                </Box>
                                            }
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!isMobileOnly ?
                                <Grid item container alignItems="right" justifyContent="end" spacing={isMobile ? 1 : 2} xs={6} xl={8}>
                                    {mdDown ? null :
                                        <Grid
                                            item
                                            xs
                                            container
                                            textAlign="right"
                                            alignItems={"center"}
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    color="#fff"
                                                    textAlign="right"
                                                    variant={xlDown ? "p" : "h6"}
                                                    sx={{
                                                        'span': {
                                                            color: theme => theme.palette.primary.main
                                                        }
                                                    }}
                                                >
                                                    Specialising in Cutting Tools, Oils and Lubricants
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item >
                                        <AdminMenu />
                                    </Grid>
                                    <Grid item >
                                        <AccountMenu />
                                    </Grid>
                                    {(
                                        user?.user?.user_permissions?.buyers?.access === 1 ||
                                        user?.user?.user_permissions?.application?.access === 1 ||
                                        user?.user?.user_permissions?.engineers?.access === 1 ||
                                        user?.user?.user_permissions?.parametric?.access === 1 ||
                                        user?.user?.user_permissions?.cadcam?.access === 1
                                    ) ?
                                        <Grid item >
                                            <Basket />
                                        </Grid>
                                        : null}
                                </Grid>
                                : null}
                        </Grid>
                        {/* {!mdDown ?
                            <Grid item container alignItems="end" justifyContent="end" spacing={isMobileOnly ? 1 : 2} xs={12} sm={6}>
                            <Grid
                                        item
                                        xs
                                        container
                                        textAlign="right"
                                    >
                                        <Grid item xs={12}>
                                            <Typography
                                                color="#fff"
                                                variant="h6"
                                                sx={{
                                                    'span': {
                                                        color: theme => theme.palette.primary.main
                                                    }
                                                }}
                                            >
                                                Specialising in Cutting Tools, Oils and Lubricants
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : null} 
                    </Grid>*/}
                    </Box>
                </Toolbar>
                <Box
                    sx={{
                        width: '100%',
                        height: '10px',
                        backgroundColor: theme => theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: "flex-end"
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#2ba99b',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#27bd60',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#a5c63b',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#ffcd02',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#ffa800',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#ff6000',
                            width: '100px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)"
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: '#e05a09',
                            width: '120px',
                            height: '100%',
                            display: 'inline-block',
                            transform: "skew(-30deg, 0)",
                            marginRight: '-20px'
                        }}
                    />
                </Box>
            </AppBar >
        </>
    );
}

