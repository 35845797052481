import { Box, Grid, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import axiosRequest from '../../axios/axoisRequest';
import ResultFiltersCard from "../filters/ResultFiltersCard";
import _ from "lodash";
import { CompanyContext } from "../../store/CompanyContext";
import MobileDrawWrapper from "../../reusable/MobileDrawWrapper";
import SearchBrandsDropdown from "./SearchBrandsDropdown";
import Float from "../filters/Float";
import searchString from "../../helpers/searchString";
import { UPDATE_ALL_FILTERS } from "../../store/ShopReducers";
import { ShopContext } from "../../store/ShopContext";

const BuyerSearchFilters = () => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { dispatchFilters } = useContext(ShopContext)
    const [filters, setFilters] = useState();
    const [noFilters, setNoFilters] = useState();
    const [search, setSearch] = useState();

    useEffect(() => {
        if (location.search) {
            let urlParams = new URLSearchParams(location.search);
            let search = urlParams.get("s")
            if (search) {
                setSearch(search)
            }
        }
    }, [location.search]);

    //--------------------------- API CALLS  ------------------------------//

    const getFilters = useMutation((values) => {
        //create search string
        let search_string = searchString(values[0])
        //console.log(user)
        return axiosRequest({
            method: 'get',
            endpoint: 'filters',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: {
                search: encodeURI(search_string),
                company_id: values[1]
            }
        })
    }, {
        onSuccess: (data) => {
            if (data && Object.values(data).length) {
                setNoFilters(false)
                let filters = []
                data.forEach((field) => {
                    if (field.DistinctFilterValues) {
                        //parse both strings
                        field.DistinctFilterValues = JSON.parse(field.DistinctFilterValues)
                        filters.push(field)
                    }
                })
                //console.log(filters)
                let merged = _.uniqWith(filters, (pre, cur) => {
                    if (pre.FilterID === cur.FilterID) {
                        cur.DistinctFilterValues = _.union(cur.DistinctFilterValues, pre.DistinctFilterValues)
                        if (typeof cur.FilterMax === 'object' && cur.FilterMax !== null) {
                            cur.FilterMax = _.union(cur.FilterMax, [pre.FilterMax])
                        } else {
                            cur.FilterMax = _.union([cur.FilterMax], [pre.FilterMax])
                        }
                        if (typeof cur.FilterMin === 'object' && cur.FilterMin !== null) {
                            cur.FilterMin = _.union(cur.FilterMin, [pre.FilterMin])
                        } else {
                            cur.FilterMin = _.union([cur.FilterMin], [pre.FilterMin])
                        }
                        return true
                    }
                    return false;
                });
                //console.log(merged)
                setFilters(merged)
                // } else {
                //     setNoFilters(true)
                //     setFilters([])
                dispatchFilters({
                    type: UPDATE_ALL_FILTERS,
                    payload: merged
                });


            } else {
                setFilters([])
                dispatchFilters({
                    type: UPDATE_ALL_FILTERS,
                    payload: []
                });
            }
        }
    })

    useEffect(() => {
        //console.log(shop.currentProductCat)
        if (search && selectedCompany.company_id) {
            getFilters.mutate([search, selectedCompany.company_id])
        }
    }, [
        selectedCompany.company_id,
        search
    ])

    // const resetForm = () => {
    //     console.log('reset')
    // }

    return (
        <MobileDrawWrapper
            //resetFilters={resetForm}
            isFetching={getFilters.isLoading}
        >
            <Box sx={{ padding: '1em' }} >
                <Grid item xs={12}>
                    <SearchBrandsDropdown search={search} />
                </Grid>
                {!getFilters.isLoading && filters && !noFilters ?
                    (filters).map((field, i) =>
                        <Grid container item spacing={1} key={i} >
                            {field.FilterType === 'ScalarNumber' ?
                                // return <ResultSlider field={field} index={i} key={i} />
                                <Float field={field} />
                                :
                                <ResultFiltersCard field={field} index={i} />
                            }
                        </Grid>
                    )
                    : null}
            </Box>
        </MobileDrawWrapper>
    );
};

export default BuyerSearchFilters;
