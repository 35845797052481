import {
    Button,
    Grid,
    Typography,
    TextField,
    LinearProgress,
    Autocomplete,
    Popover,
    InputAdornment,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GlobalDialog from '../reusable/GlobalDialog';
import { LayoutContext } from '../store/LayoutContext';
import { useFormikContext } from 'formik';
import InputWrap from '../reusable/InputWrap';
import FilePreview from '../media/FilePreview';
import FileUpload from '../media/FileUpload';
import setLanguageText from '../language/setLanguageText';
import ResourcesTree from './ResourcesTree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import ImageUpload from '../reusable/ImageUpload';
import { ContactlessOutlined } from '@mui/icons-material';


const ResourceForm = ({
    edit, //edit only
    fileUrl, //edit only
    preview, //edit only
    categories,
    setFileUpload,
    setPreviewUpload,
    setSilderUpload,
    slider,
    selectedCategory,
    progress,
    openLevels,
    setOpenLevels,
    file,
    loading,
    setLoading
}) => {

    const [anchorElCats, setAnchorElCats] = useState(null);
    const openCats = Boolean(anchorElCats);
    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();


    const handleClick = (event) => {
        //console.log(event.currentTarget)
        setAnchorElCats(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElCats(null);
    };

    useEffect(() => {
        if (selectedCategory && categories) {
            let find = categories.find(category => category.resource_cat_id === parseInt(selectedCategory))
            if (find) {
                setFieldValue('resource_cat_id', find.resource_cat_id);
                setFieldValue('resource_cat_name', find.resource_cat_name);
            }
        }
    }, [categories, selectedCategory])


    return (
        <>
            <Grid container alignItems="start" spacing={2}>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12}>
                        <InputWrap name="Resource Name *">
                            <TextField
                                id="resource_file_name"
                                name="resource_file_name"
                                value={values.resource_file_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                error={touched.resource_file_name && Boolean(errors.resource_file_name)}
                                helperText={touched.resource_file_name && errors.resource_file_name}
                                sx={{ '& legend': { display: 'none' } }}
                            />
                        </InputWrap>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <InputWrap name="Resource Category *">
                                <TextField
                                    id="selected_category"
                                    name="selected_category"
                                    value={values.resource_cat_name}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.resource_cat_name && Boolean(errors.resource_cat_name)}
                                    helperText={touched.resource_cat_name && errors.resource_cat_name}
                                    sx={{ '& legend': { display: 'none' } }}
                                    inputProps={{ readOnly: true }}
                                    onClick={handleClick}
                                    InputProps={{
                                        endAdornment: <FontAwesomeIcon icon={faChevronDown} />
                                    }}
                                />
                                <Popover
                                    open={openCats}
                                    anchorEl={anchorElCats}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <ResourcesTree
                                        categories={categories}
                                        selectedCategory={selectedCategory}
                                        setSearchParams={(value) => {
                                            handleClose()
                                            //console.log(value.cid)
                                            // setSelectedCategory(value)
                                            let found = categories.find(category => category.resource_cat_id === parseInt(value.cid))
                                            if (found) {
                                                //console.log(found)
                                                setFieldValue('resource_cat_id', found.resource_cat_id);
                                                setFieldValue('resource_cat_name', found.resource_cat_name);
                                            }
                                        }}
                                        openLevels={openLevels}
                                        setOpenLevels={setOpenLevels}
                                    />
                                </Popover>
                            </InputWrap>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <InputWrap name="Resource Description">
                            <TextField
                                multiline
                                rows={5}
                                id="resource_description"
                                name="resource_description"
                                value={values.resource_description}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                error={touched.resource_description && Boolean(errors.resource_description)}
                                helperText={touched.resource_description && errors.resource_description}
                                sx={{ '& legend': { display: 'none' } }}
                            />
                        </InputWrap>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} >
                    <InputWrap name={edit ? "Preview image" : "Upload Resource File *"}>
                        <Grid container item spacing={2}>
                            <Grid container item xs={12} >
                                {/* {preview ?
                                    <img
                                        style={{ width: '300px', height: '300px', objectFit: 'contain' }}
                                        src={preview}
                                        alt="Thumb"
                                    />
                                    : null} */}

                                <FilePreview
                                    edit={edit}
                                    item={file?.[0]} //only one file
                                    setPreviewUpload={setPreviewUpload}
                                    loading={loading}
                                    setLoading={setLoading}
                                />

                            </Grid>
                            <Grid item xs={12} >

                                {!edit ?
                                    <FileUpload
                                        handleSelectUploadedFile={(file) => {
                                            setFileUpload(file)
                                            setFieldValue('resource_type', file?.type);
                                        }}
                                        isSingle
                                        disabled={Object.values(file).length ? true : false}
                                        dropzoneProps={{
                                            noClick: true,
                                            accept: {
                                                'application/pdf': ['.pdf'],
                                                'video/mp4': ['.mp4'],
                                                //'video/webm': ['.webm'] //removed for now
                                            }
                                            // maxFiles: 1
                                            //maxSize: 5242880,
                                            //multiple: true
                                        }}
                                    />
                                    : null}
                            </Grid>
                        </Grid>
                    </InputWrap>
                </Grid>

                <Grid item xs={12}>
                    <InputWrap name="Set as Promotion or Announcement">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    //size='small'
                                    checked={values.resource_promotion || false}
                                    onChange={(e, value) => {
                                        setFieldValue('resource_promotion', value);
                                    }}
                                />
                            }
                            label="Promotion"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    //size='small'
                                    checked={values.resource_announcement || false}
                                    onChange={(e, value) => {
                                        setFieldValue('resource_announcement', value);
                                    }}
                                />
                            }
                            label="Announcement"
                        />
                    </InputWrap>
                    {values.resource_announcement || values.resource_promotion ?
                        <Grid item xs={12} >
                            <InputWrap name="Add Slideshow Image">
                                <Grid container item spacing={2}>
                                    <ImageUpload
                                        edit
                                        path={slider ? slider : null}
                                        setImage={(file) => {
                                            setSilderUpload(file)
                                            setFieldValue('resource_silder_image', file.type);
                                        }}
                                    />
                                </Grid>
                            </InputWrap>
                        </Grid>
                        : null}
                </Grid>

                {progress > 0 && !edit && (
                    <Grid item xs={12}>
                        <Typography>{setLanguageText("File Uploading Progress")}:</Typography>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={11}>
                                <LinearProgress
                                    value={progress}
                                    variant="determinate"
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>{`${Math.round(progress)}%`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ResourceForm;
