import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import { CompanyContext } from '../store/CompanyContext';

const useSearchCount = () => {

    const [searchError, setsearchError] = useState()
    const [searchResponse, setsearchResponse] = useState()
    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)

    const updateSearchCount = useMutation((type) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'searches',
            api_key: user.api_key,
            method: 'post',
            body: {
                user_id: user.user.user_id,
                company_id: selectedCompany.company_id,
                search_type: type
            }
        })
    }, {
        onSuccess: (response) => {
            //console.log(response)
            if (response) {
                setsearchResponse(response)
            }
        },
        onError: (error) => {
            //console.log(data)
            if (error) {
                setsearchError(error)
            }
        }
    })

    return {
        updateSearchCount,
        searchResponse,
        searchError
    }
};

export default useSearchCount

