import React, { useContext, useEffect, useState } from "react";
import { Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { BasketContext } from "../store/BasketContext";
import { cs } from "date-fns/locale";

const BasketTotals = () => {

	const { basket } = useContext(BasketContext);
	const [total, setTotal] = useState()
	const [totalRRP, setTotalRRP] = useState()
	const [saving, setSaving] = useState()

	useEffect(() => {
		if (basket?.items && !basket.items.items) {
			//console.log(basket)
			let total_price_incl_discount = 0
			let total_price_rrp = 0
			basket.items.forEach((item) => {
				//console.log(item)
				if (item.price_rrp !== item.price_incl_discount) {
					//add savings up
					total_price_rrp = total_price_rrp + (item.price_rrp * parseInt(item.product_quantity))
				}
				if (item.price_incl_discount) {
					//console.log(item.price_incl_discount * item.product_quantity)
					//console.log(price_incl_discount + (item.price_incl_discount * parseInt(item.product_quantity)))
					total_price_incl_discount = total_price_incl_discount + (item.price_incl_discount * parseInt(item.product_quantity))
				} 
			})
			//console.log(price_incl_discount)
			setTotal(total_price_incl_discount)
			setTotalRRP(total_price_rrp)
			setSaving(total_price_rrp - total_price_incl_discount)
		}
	}, [basket])

	//console.log(total)
	//console.log(saving)
	//console.log(basket.items)

	return (
		<>
			{/* <Stack>
				<Grid container item>
					<Grid item xs={6}>
						<Typography color="text.secondary" variant="subtitle2" >Subtotal:</Typography>
					</Grid>
					<Grid item xs={6} sx={{ textAlign: 'right' }}>
						<Typography color="text.secondary" variant="subtitle2" >{basket?.totals?.price_incl_discount ? `£${(basket.totals.price_incl_discount).toFixed(2)}` : null}</Typography>
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item xs={6}>
						<Typography color="text.secondary" variant="subtitle2" >Shipping:</Typography>
					</Grid>
					<Grid item xs={6} sx={{ textAlign: 'right' }}>
						<Typography color="text.secondary" variant="subtitle2" >calculated at checkout</Typography>
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item xs={6}>
						<Typography color="text.secondary" variant="subtitle2" >Taxes:</Typography>
					</Grid>
					<Grid item xs={6} sx={{ textAlign: 'right' }}>
						<Typography color="text.secondary" variant="subtitle2" >{basket?.totals?.price_tax ? `£${(basket.totals.price_tax).toFixed(2)}` : null}</Typography>
					</Grid>
				</Grid>
			</Stack> 
			<Divider sx={{ margin: '1em 0' }} />*/}
			<Grid container item alignItems="center">
				<Grid item xs={3}>
					<Typography  variant="h5" >Total:</Typography>
				</Grid>

				<Grid item xs="auto" md={9} sx={{ textAlign: 'right' }}>
					<Typography variant="h5" sx={{ color: theme => theme.palette.success.main }}>
						{saving && saving > 0 && totalRRP ?
							<Typography variant="p" component="span" sx={{
								textDecoration: 'line-through',
								marginRight: '10px',
								fontSize: '16px',
								color: '#ccc'
							}} >
								£{totalRRP.toFixed(2)}
							</Typography>
							: null}
						{total ? `£${(total).toFixed(2)}` : null}
						{/* { count > 0  ? <Typography color="text.secondary" variant="p" >+ {count} x POA</Typography> : null} */}
						{saving && saving > 0 ?
							<Chip
								sx={{ marginLeft: '5px' }}
								//size="small"
								color="success"
								label={`Saving £${saving.toFixed(2)}`}
							/>
							: null}
					</Typography>
				</Grid>
			</Grid>
			<Divider sx={{ margin: '1em 0' }} />
		</>
	);
}

export default BasketTotals;