import React, { useContext } from "react";
import { ProductsContext } from "../../store/ProductsContext";
import { Grid } from "@mui/material";
import ProductLoadingCard from "./ProductLoadingCard";
import ProductGridItem from "../../products/cim/ProductGridItem";

const ProductGrid = ({ isFetching, attributes }) => {

    const { products } = useContext(ProductsContext);

    return (
        <Grid container item spacing={2}>

            {Object.values(products.allProducts).length ?
                products.allProducts.map((product, i) =>
                    <Grid key={i} item xs={12} sm={6} md={12} lg={6} xl={3}>
                        <ProductGridItem product={product} isFetching={isFetching} attributes={attributes} />
                    </Grid>
                ) : isFetching ?
                    <>

                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                    </>
                    : null
            }
        </Grid>
    );
};

export default ProductGrid;

