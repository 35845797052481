import React, { useContext, useState } from 'react';
import { Button, Typography, Grid, Alert } from '@mui/material';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import config from '../../config';
import { useMutation } from 'react-query';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';
import DialogLoader from '../../reusable/DialogLoader';
import { useNavigate } from 'react-router-dom';
import userValidation from './../helpers/userValidation'
import userDBObject from './../helpers/userDBObject';
import userFormObject from './../helpers/userFormObject';
import AllForms from './../forms/AllForms';
import useActivityLog from './../activity/useActivityLog';
import setLanguageText from '../../language/setLanguageText';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import { Auth } from 'aws-amplify';
import { set } from 'lodash';
import GlobalDialog from '../../reusable/GlobalDialog';

const AddUser = () => {

	const navigate = useNavigate();
	const { user: userContext } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState();
	const [cognitoResponse, setCognitoResponse] = useState();
	const newUser = userFormObject()[0];
	const { updateActivityLog } = useActivityLog();
	const [valid, setValid] = useState(false);
	const [error, setError] = useState();

	//console.log(valid)

	//save user
	const mutation = useMutation(({ user, values }) => {
		//console.log(user)
		//console.log(values)
		let sub = (user.Attributes).find((item) => item.Name === 'sub');
		let userObject = userDBObject(values, sub?.Value)[0]

		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'administrator/user',
			api_key: userContext.api_key,
			method: 'post',
			body: userObject
		})
	}, {
		onSuccess: (data, variables) => {
			setIsLoading(false);
			//closeDialog()
			updateActivityLog.mutate({
				activity_details: {
					area: "factory",
					...variables
				},
				activity_type: "user added"
			})
		}
	}
	)

	const handleSubmit = (values) => {
		//console.log(values)
		//let userObject = userDBObject(values, 'test')[0]
		//console.log(userObject)
		setIsLoading(true);

		const userParams = {
			UserPoolId: config.cognito.USER_POOL_ID,
			Username: values.username,
			DesiredDeliveryMediums: ['EMAIL'],
			ForceAliasCreation: false,
			//MessageAction: "SUPPRESS",
			//TemporaryPassword: password,
			UserAttributes: [
				{ Name: 'email', Value: values.email.toLowerCase() },
				{ Name: 'email_verified', Value: 'true' }
			]
		};

		Auth.currentCredentials().then(async (credentials) => {

			const cognito = new AWS.CognitoIdentityServiceProvider({
				apiVersion: "2016-04-18",
				region: config.cognito.REGION,
				credentials: Auth.essentialCredentials(credentials)
			})

			const createCognitoUser = new Promise((resolve, reject) => {
				//* CREATE USER
				cognito.adminCreateUser(userParams, (err, userData) => {
					if (err) {
						setCognitoResponse({
							result: 'error',
							message: `Cognito: ${err.message}`
						});
						reject(err);
					} else {

						if (values.administrator) {
							const groupParams = {
								UserPoolId: config.cognito.USER_POOL_ID,
								Username: newUser.username,
							};
							cognito.adminAddUserToGroup(
								{ ...groupParams, GroupName: "Administrator" },
								(err, groupData) => {
									if (err) {
										setCognitoResponse({
											result: 'error',
											message:`Cognito: ${err.message}`
										});
										reject(groupData);
									} else {
										setCognitoResponse({
											result: 'success',
											message: groupData
										});
										resolve(groupData);
									}
								}
							);
						} else {
							resolve(userData);
						}
					}
				});
			});
			try {
				const aws = await createCognitoUser;

				if (aws.User && values) {
					mutation.mutate({ "user": aws.User, "values": values })
					//need logging adding
				}
			} catch (err) {
				//console.log('error')
				setIsLoading(false)
				//console.log(values)
				//console.log(err.message)
				setError(err.message);
			}
		})
	};


	//cloase dialog and refresh table
	const closeRefreshDialog = () => {
		navigate('/settings/users')
	}

	console.log(error)

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("Add New User")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>

				{newUser ?
					<Formik
						initialValues={newUser}
						validationSchema={userValidation}
						onSubmit={handleSubmit}
					>
						{({ submitForm, validateForm, errors, values }) => {
							 console.log(values)
							 console.log(errors)
							 console.log(valid)
							return (
								<Grid item container spacing={1} xs={12} alignItems="stretch">
									<AllForms valid={valid} setValid={setValid} />
									<Grid container item xs={12} justifyContent="flex-start" spacing={2}>
										<Grid item>
											{/* <Button
												size='large'
												color="error"
												variant="text"
												onClick={() => navigate('/settings/users')}
												startIcon={
													<FontAwesomeIcon
														icon={faTimes}
													/>
												}
											>
												{setLanguageText("Cancel")}
											</Button> */}
											<Button
												size='large'
												color="primary"
												variant="contained"
												onClick={() => {
													//check object is empty
													if (Object.keys(errors).length > 0) {
														setError("Required fields must be filled out before submitting.")
													} else {
														submitForm()
													}
												}}
												disabled={!valid ? true : false}
												// disabled={
												// 	Object.keys(errors).length ? true : 
												// 	!valid ? true : false
												// }
												startIcon={
													<FontAwesomeIcon
														icon={faSave}
													/>
												}
											>
												{setLanguageText("Add New User")}
											</Button>
										</Grid>
										{error ?
										<GlobalDialog
											open={error ? true : false}
											onClose={() => setError()}
											title={setLanguageText("Error")}
										>
											<Grid item>
												<Alert severity="error">
													{error}
												</Alert>
											</Grid>
										</GlobalDialog>
									: null}
									</Grid>

									{isLoading || mutation.isLoading || mutation.isSuccess || mutation.isError ?
										<DialogLoader
											fixed
											isLoading={isLoading}
											mutation={mutation}
											loadingMessage={setLanguageText("Adding User")}
											successMessage={setLanguageText("Added User")}
											closeDialog={closeRefreshDialog}
										/>
										: null}
								</Grid>
							)
						}}
					</Formik>
					: null}
			</TabContent>

		</>
	);
};

export default AddUser;
