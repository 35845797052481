import React, { useState } from 'react';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Menu,
	Checkbox,
	Typography,
	MenuItem,
	FormControlLabel
} from '@mui/material';
import setLanguageText from '../../../language/setLanguageText';

export default function MachineTableSettings({ table }){

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				variant="contained"
				onClick={handleClick}
				color="primary"
				startIcon={<FontAwesomeIcon icon={faCog} />}
			>
			{setLanguageText("Edit Columns")}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				//getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				{table.getAllLeafColumns().map(column => {
					//console.log(column)
					return (
						<MenuItem key={column.id}>
							<FormControlLabel
								control={
									<Checkbox
										checked={column.getIsVisible()}
										onChange={column.getToggleVisibilityHandler()}
									/>
								}
								label={setLanguageText(column.columnDef.name)}
							/>
						</MenuItem>
					)
				})}
			</Menu>
		</>
	);
};
