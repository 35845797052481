import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpWideShort, faSortAlphaDown, faSortAlphaDownAlt } from '@fortawesome/pro-light-svg-icons';

const ProductSort = ({ order, setOrder }) => {


    return (
        <ButtonGroup disableElevation sx={{ border: '1px solid #ccc' }} >
            <Button
                sx={{ padding: '1em' }}
                variant="contained"
                color={
                    order === 'most' ? 'primary' : 'inherit'
                }
                onClick={() => setOrder('most')}
            >
                <FontAwesomeIcon icon={faArrowUpWideShort} size="2x" />
            </Button>
            <Button
                sx={{
                    padding: '1em',
                    borderLeft: '1px solid #ccc',
                }}
                variant="contained"
                color={
                    order === 'asc' ? 'primary' : 'inherit'
                }
                onClick={() => setOrder('asc')}
            >
                <FontAwesomeIcon icon={faSortAlphaDown} size="2x" />
            </Button>
            <Button
                sx={{
                    padding: '1em',
                    borderLeft: '1px solid #ccc'
                }}
                variant="contained"
                color={
                    order === 'desc' ? 'primary' : 'inherit'
                }
                onClick={() => setOrder('desc')}
            >
                <FontAwesomeIcon icon={faSortAlphaDownAlt} size="2x" />
            </Button>
        </ButtonGroup>
    );
};

export default ProductSort;
