
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Skeleton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../store/UserContext";
import { UPDATE_FAVOURITES } from "../store/UserReducers";
import { useMutation } from "@tanstack/react-query";
import config from "../config";
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from "../store/CompanyContext";
//import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";

const AddToFavourites = ({ product, isFetching, button, big }) => {

    const { user, dispatchUser } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)
    const [selected, setSelected] = useState();


    const saveFavourites = useMutation({
        mutationFn: ({ type, company, user_details, product }) => {
            let body = {
                "type": type,
                "user_id": user_details.user.user_id,
                "product_id": product.product_id,
                "company_id": company.company_id
            }
            if (type === 'add') {
                body = {
                    ...body,
                    "user": {
                        "user_id": user_details.user.user_id,
                        "user_details": user_details.user.user_details,
                        "email": user_details.user.email
                    },
                    "product": product,
                }
            }
            return axiosRequest({
                gateway: config.factoryAPI.URL,
                endpoint: 'favourites',
                api_key: user.api_key,
                method: 'post',
                body: body
            })
        }
    })

    const toggleFavourite = (e) => {

        console.log(product)

        if (selected === true) {
            //remove from favourites
            let newFavourites = user.favourites.filter(fav => fav.product_id !== product.product_id.toString())
            dispatchUser({ type: UPDATE_FAVOURITES, payload: newFavourites })
            setSelected(false)

            saveFavourites.mutate({
                "type": "remove",
                "user_details": user,
                "company": selectedCompany,
                "product": product
            })

            //console.log(product)

        } else if (selected === false) {
            //add to favourites
            let newFavourites = [
                ...user.favourites,
                { "product_id": product.product_id.toString() }
            ]
            dispatchUser({ type: UPDATE_FAVOURITES, payload: newFavourites })
            setSelected(true)
            //add to favourites
            saveFavourites.mutate({
                "type": "add",
                "user_details": user,
                "company": selectedCompany,
                "product": product
            })
            //console.log(product)
        }
    }


    useEffect(() => {
        if (user?.favourites && product?.product_id) {
            //check if product is in favourites object
            let found = user.favourites.find(fav => fav.product_id.toString() === product.product_id.toString())
            if (found) {
                setSelected(true)
            } else {
                setSelected(false)
            }
        }
    }, [user, product])


    return (
        <>
            {isFetching ?
                <Skeleton variant="circular" width={big ? '50px' : '30px'} height={big ? '50px' : '30px'} />
                : button ?

                    <Button
                        onClick={toggleFavourite}
                        sx={{
                            backgroundColor: selected ? '#ea606a' : 'grey.100',
                            color: selected ? 'white' : '#ea606a',
                            '& svg': {
                                width: '15px',
                                height: '15px',
                                color: selected ? 'white' : '#ea606a'
                            },
                            '&:hover': {
                                backgroundColor: selected ? '#ea606a' : 'grey.100',
                                color: selected ? 'white' : '#ea606a',
                            }
                        }}
                        startIcon={<FontAwesomeIcon icon={faHeart} />}
                        variant="contained"
                    >{selected ? 'Added to ' : 'Add to '}Favourites</Button>
                    :
                    <Tooltip
                        arrow
                        title={selected ? 'Remove from favourites' : 'Add to favourites'}
                    >
                        <Avatar
                            onClick={toggleFavourite}
                            sx={{
                                cursor: 'pointer',
                                width: big ? '50px' : '30px',
                                height: big ? '50px' : '30px',
                                backgroundColor: selected ? '#ea606a' : 'grey.100',
                                transition: 'all 0.3s ease',
                                '& svg': {
                                    width: big ? '25px' : '15px',
                                    height: big ? '25px' : '15px',
                                    color: selected ? 'white' : '#ea606a',
                                    transition: 'all 0.3s ease',
                                },
                                '&:hover': {
                                    backgroundColor: selected ? '#c0424b' : 'grey.300',
                                    // '& svg': {
                                    //     width: big ? '40px' : '20px',
                                    //     height: big ? '40px' : '20px',
                                    //     color: 'ea606a'
                                    // }
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faHeart} />
                        </Avatar>
                    </Tooltip>
            }
        </>
    );
};

export default AddToFavourites; 