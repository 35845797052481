import { useEffect, useState } from 'react';

const useColorChip = color_rules => {
  const [status, setStatus] = useState();
  const [valueHexColor, setValueHexColor] = useState('');

  useEffect(() => {
    //console.log(status)
    //console.log(color_rules)
    if (typeof status === 'number') {
      const value_color_rule = color_rules.filter(
        color_rule => Number(status) === Number(color_rule.value)
      )[0];
      setValueHexColor(value_color_rule.colour.hex);
    } else setValueHexColor('');
  }, [color_rules, status]);

  return [valueHexColor, setStatus];
};

export default useColorChip;
