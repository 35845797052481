import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Tab,
	Card,
	Button,
	Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../language/setLanguageText';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import PageContent from '../coolantcare/PageContent';
import { UserContext } from '../store/UserContext';
import UserLevels from './levels/UserLevels';
import Users from './users/Users';
import UserTemplates from './UserTemplates';
import AddUser from './users/AddUser';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const UserSettings = () => {

	const navigate = useNavigate();
	const [value, setValue] = useState();
	const { user } = useContext(UserContext)

	//change URL parameters
	const handleChange = (event, newValue) => {
		navigate(`/settings/users?tab=${newValue}`)
		//setValue(parseInt(newValue));
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const tab = params.get('tab');
		if (tab) {
			setValue(tab);
		} else {
			setValue("0");
		}
	}, [window.location.search])

	return (
		<>
			<InnerHeaderWrap>
				{user.user.user_permissions.administrator ?
					<Button
						variant="contained"
						color="primary"
						size={'large'}
						onClick={e => navigate('/settings/users?tab=2')}
					>
						{setLanguageText("Add New User")}
					</Button>
					: null}
			</InnerHeaderWrap>
			<PageContent>
				{user.user.user_permissions.administrator ?
					<Card>
						<Box sx={{ display: 'flex' }}>
							<TabContext value={value}>
								<Box
									sx={{
										overflow: "visible",
										minWidth: "240px",
										background: "#fbfbfb",
										'& .MuiTab-root': {
											textAlign: 'left',
											alignItems: 'start',
											borderBottom: '1px solid #e8e8e8'
										}
									}}>
									<TabList
										orientation={isMobileOnly ? "horizontal" : "vertical"}
										onChange={handleChange}
									>
										<Tab label={setLanguageText("User Management")} value={"0"} />
										<Tab label={setLanguageText("User Permission Templates")} value={"1"} />
										<Tab label={setLanguageText("Add New User")} value={"2"} />
										<Tab label={setLanguageText("User Roles")} value={"3"} />
									</TabList>
								</Box>
								<Box sx={{
									flexGrow: 1,
									'& .MuiTabPanel-root': {
										padding: 0,
										borderLeft: '1px solid #e8e8e8'
									}
								}}>
									<TabPanel value={"0"}>
										<Users />
									</TabPanel>
									<TabPanel value={"1"}>
										<UserTemplates type={["user_permissions"]} />
									</TabPanel>
									<TabPanel value={"2"}>
										<AddUser />
									</TabPanel>
									<TabPanel value={"3"}>
										<UserLevels />
									</TabPanel>
								</Box>
							</TabContext>
						</Box>
					</Card>
					:
					<Alert severity='error'>{setLanguageText("Administrator access required")}</Alert>
				}
			</PageContent>
		</>
	);
}

export default UserSettings;

