import React, { useContext, useEffect, useState } from "react";
import { Alert, Card, CardContent, CardMedia, Fade, Grid, Skeleton, Typography } from "@mui/material";
import { ShopContext } from "../../store/ShopContext";
import { Box } from "@mui/system";
import sortAlphaNumerically from "../../helpers/sortAlphaNumerically";
import { useSearchParams } from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';

const CatCard = ({ cat, updateCurrentCategory, isLoading }) => {

    const [loaded, setLoaded] = useState(false)
    const [brandCount, setBrandCount] = useState()
    const [fullCount, setFullCount] = useState()
    const { brands } = useContext(ShopContext)



    useEffect(() => {
        if (brands.selectedBrands && Object.values(brands.selectedBrands).length) {
            // console.log(brands.selectedBrands)
            //console.log(cat.cName)
            //console.log(cat.bcount)
            let count = 0
            if (cat.bcount) {
                brands.selectedBrands.forEach(selected => {
                    //console.log(selected.Brand)
                    let found = cat.bcount.find((brand) => brand.b === selected.Brand)
                    //console.log(found)
                    if (found) {
                        count = count + found.c
                    }
                });
            }
            setBrandCount(count)
        } else {
            //reset
            setBrandCount()
        }
        if (brands.currentBrands) {
            let full_count = 0
            brands.currentBrands.forEach(selected => {
                //console.log(selected.Brand)
                let found = cat.bcount.find((brand) => brand.b === selected.Brand)
                //console.log(found)
                if (found) {
                    full_count = full_count + found.c
                }
            });
            setFullCount(full_count)
        }
    }, [brands, cat])

    return (
        <>
            {fullCount !== 0 && brandCount !== 0 ?
                <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} >

                    <Card
                        sx={{
                            height: '100%',
                            position: 'relative',
                            cursor: 'pointer',
                            backgroundColor: 'cards.header',
                            opacity: brandCount === 0 ? 0.4 : 1
                        }}
                        elevation={1}
                        color={'background.paper'}
                        onClick={(e) => {
                            if (cat.count > 0) {
                                updateCurrentCategory(cat);
                            }
                        }}
                    >
                        {!isLoading &&
                            <Grid sx={{
                                top: 0,
                                right: 0,
                                backgroundColor: 'cards.header',
                                borderColor: 'cards.footer',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                position: 'absolute',
                                borderRadius: ' 0 0 0 5px',
                                fontSize: '10px',
                                margin: '-1px',
                                padding: '2px 8px',
                                zIndex: 3
                            }}>
                                <Typography variant="caption" color="text.primary" >

                                    {brandCount ? <Typography variant="span" fontWeight={600} color="primary.main" >{brandCount}</Typography> : null}
                                    {brandCount ? ' / ' : null}
                                    {/* {cat.count} */}
                                    {fullCount}
                                </Typography>
                            </Grid>
                        }

                        <Box
                            sx={{
                                height: 140,
                                width: '100%',
                                position: 'relative',
                                backgroundColor: 'cards.header',
                            }}>
                            <>
                                {!loaded || cat.image === null || cat.image === "" &&
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            backgroundColor: 'cards.header',
                                            position: 'absolute',
                                            zIndex: 2
                                        }} />
                                }
                                {isLoading ?
                                    <Skeleton variant="rectangular" width={'100%'} height={140} />
                                    :

                                    <Fade in={loaded && cat.image !== null}>

                                        <CardMedia
                                            component="img"
                                            sx={{
                                                height: 140,
                                                width: '100%',
                                                backgroundColor: '#FFF',
                                                padding: '10px',
                                                objectFit: 'contain',
                                                position: 'relative',
                                                zIndex: 1
                                            }}
                                            image={cat.image}
                                            alt={cat.cName}
                                            onLoad={() => setLoaded(true)}
                                            onError={() => setLoaded(false)}
                                        />
                                    </Fade>
                                }
                            </>
                        </Box>
                        <CardContent sx={{ display: 'flex', width: '100%', alignItems: 'center', paddingBottom: '1em!important', backgroundColor: 'cards.header' }}>
                            <Typography variant="body1" color={'text.primary'} sx={{ width: '100%' }} textAlign="center">
                                {isLoading ? <Skeleton width="100%" /> : cat.cName}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                : null}
        </>
    )
}

const BuyerCategoryCards = ({ isFetching }) => {

    const { shop, brands } = useContext(ShopContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = useState(null);

    const updateCurrentCategory = (category) => {
        console.log(category)
        if (Object.values(category.cpID).length < 3) {
            //top 3 levels
            setSearchParams({ 'c': category.ID })
        } else {
            //bottom level
            setSearchParams({ 'c': shop.currentCat.ID, 'p': category.ID })
        }
    };

    useEffect(() => {
        if (shop?.currentCat) {
            let ordered = sortAlphaNumerically(shop.currentCat.children, 'cName')
            setCategories(ordered)
        }
    }, [shop.currentCat])

    return (
        <>
            <Grid container item xs={12} spacing={isMobileOnly ? 1 : 2} justifyItems="stretch">
                {categories && brands?.currentBrands && Object.values(brands?.currentBrands).length ? (
                    categories.map((cat, i) => {
                        return (
                            <React.Fragment key={i}>
                                <CatCard
                                    cat={cat}
                                    updateCurrentCategory={updateCurrentCategory}
                                    isLoading={isFetching} 
                                />
                            </React.Fragment>
                        )
                    })
                ) : categories && brands?.currentBrands && Object.values(brands?.currentBrands).length === 0 ?
                    <Grid item>
                        <Alert severity="info">This company has no access to brands, please contact an administrator to allow access.</Alert>
                    </Grid>
                    : <>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                        <Grid item xs={isMobileOnly ? 6 : 12} sm={6} md={4} xl={3} ><Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={220} /></Grid>
                    </>
                }
            </Grid >
        </>
    );
};

export default BuyerCategoryCards;
