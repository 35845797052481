import React, { useContext, useEffect, useState } from 'react';
import {
	Grid,
	TextField,
	Typography
} from '@mui/material';
import ImageUpload from '../../reusable/ImageUpload';
import config from '../../config';
import setLanguageText from '../../language/setLanguageText';
import useSignedUrl from '../../hooks/useSignedUrl';

export default function BrandForm({ brand, updateBrand, updateAdditiveImage, updateCoolantImage, newBrand }) {

	const [coolantUrl, setCoolantUrl] = useState();
	const [additiveUrl, setAdditiveUrl] = useState();
	const { getSignedUrl } = useSignedUrl();

	useEffect(() => {
		if (brand?.brand_id) {
			//console.log(brand)
			getSignedUrl(config.s3.BUCKET, `public/brands/${brand.brand_id}_coolant.png`).then((url) => setCoolantUrl(url))
			getSignedUrl(config.s3.BUCKET, `public/brands/${brand.brand_id}_additive.png`).then((url) => setAdditiveUrl(url))
		}
	}, []);

	return (
		<Grid item container spacing={2}>
			<Grid item xs={12}>
				<TextField
					fullWidth
					name="brand_name"
					label={setLanguageText("Name")}
					variant="outlined"
					onChange={e => updateBrand(e)}
					value={brand ? brand.brand_name : ''}
				/>
			</Grid>
			{!newBrand ?
			<Grid item container spacing={2}>
				<Grid item container xs={6} spacing={2}>
					<Grid item >
						<Typography>{setLanguageText("Coolant Image")}</Typography>
					</Grid>
					<ImageUpload
						path={coolantUrl ? coolantUrl : null}
						setImage={updateCoolantImage}
						edit={true}
					/>
				</Grid>
				<Grid item container xs={6} spacing={2} >
					<Grid item >
						<Typography>{setLanguageText("Additive Image")}</Typography>
					</Grid>
					<ImageUpload
						path={additiveUrl ? additiveUrl : null}
						setImage={updateAdditiveImage}
						edit={true}
					/>
				</Grid>
			</Grid>
			: null}
		</Grid >

	);
};
