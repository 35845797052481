import React, { useContext } from 'react';
import { faBorderAll, faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Button, Grid } from '@mui/material';
import { MachineContext } from '../../store/MachineContext';
import { GRID_VIEW } from '../../store/MachineReducers';

export default function GridButton() {

	const { gridView, dispatchGridView } = useContext(MachineContext);

	const toggleListView = () => {
		dispatchGridView({
			type: GRID_VIEW,
			payload: false
		});
	};
	const toggleGridView = () => {
		dispatchGridView({
			type: GRID_VIEW,
			payload: true
		});
	};

	return (
		<Grid item>
			<ButtonGroup
				variant="contained"
				color="primary"
				size="large"

			>
				<Button
					//className={buttonStyle.button}
					variant="contained"
					onClick={toggleGridView}
					color={gridView ? 'primary' : 'inherit'}
					sx={{
						padding: '1em'
					}}
				>
					<FontAwesomeIcon icon={faBorderAll} size='xl' />
				</Button>
				<Button
					//className={buttonStyle.button}
					variant="contained"
					onClick={toggleListView}
					color={gridView ? 'inherit' : 'primary'}
					sx={{
						padding: '1em'
					}}
				>
					<FontAwesomeIcon icon={faBars} size='xl' />
				</Button>
			</ButtonGroup>
		</Grid>
	);
};
