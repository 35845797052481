
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
export const SHOW_PRODUCTS = 'SHOW_PRODUCTS';
export const HIDE_PRODUCTS = 'HIDE_PRODUCTS';
export const SHOW_CATEGORIES = 'SHOW_CATEGORIES';
export const HIDE_CATEGORIES = 'HIDE_CATEGORIES';
export const UPDATE_MENU = 'UPDATE_MENU';
export const LANGUAGE = 'LANGUAGE';
export const APP_THEME = 'APP_THEME';
export const COMPANY_THEME = 'COMPANY_THEME';
export const USER_THEME = 'USER_THEME';
export const ADD_LOGO = 'ADD_LOGO';
export const UPDATE_EMAILS = 'UPDATE_EMAILS';

export const LayoutReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LAYOUT:
      return {
        ...state,
        page: action.payload.page ? action.payload.page : state.page,
        layout: action.payload.layout ? action.payload.layout : state.layout,
        sort: action.payload.sort ? action.payload.sort : state.sort,
        type: action.payload.type ? action.payload.type : state.type,
        colorMode: action.payload.colorMode ? action.payload.colorMode : state.colorMode,
      };
    case SHOW_PRODUCTS:
      return {
        ...state,
        showProducts: true,
        showCategories: false
      };
    case SHOW_CATEGORIES:
      return {
        ...state,
        showProducts: false,
        showCategories: true
      };
    case UPDATE_MENU:
      if (action.payload.selectedArea) {
        sessionStorage.setItem("selectedArea", action.payload.selectedArea);
      }
      if (action.payload.selectedMenu) {
        sessionStorage.setItem("selectedMenu", action.payload.selectedMenu);
      }
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_EMAILS:
      return {
        ...state,
        emails: action.payload
      };
    case LANGUAGE:
      return action.payload;
    case APP_THEME:
     //console.log(action.payload)
      return {
        ...state,
        ...action.payload
      };
    case COMPANY_THEME:
      return {
        ...state,
        company: action.payload
      };
    case USER_THEME:
      return {
        ...state,
        user: action.payload
      };
    case ADD_LOGO:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

