import React, { useMemo, useContext, useState, useEffect } from "react";
import { Avatar, Box, Card, CardContent, Dialog, Grid, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import BrandLogo from "../../shop/reusable/BrandLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faDolly, faMagnifyingGlass, faPlane, faTimes } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../../store/UserContext";
import { ProductsContext } from "../../store/ProductsContext";
import { UPDATE_ORDER } from "../../store/ProductsReducers";
import ProductPopup from "./ProductPopup";
import { trackEvent } from "../../google_analytics/ga";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";
import config from "../../config";
import useGetPrices from "../../hooks/useGetPrices";


const CustomRow = ({ item, ind, columns, hidden, addCustomRowClick }) => {

	//console.log(item)
	const [productDetails, setProductDetails] = useState();
	const { isFetchingPrice } = useGetPrices({ productDetails, setProductDetails });

	useEffect(() => {
		if (item) {
			setProductDetails(current => ({
				...current,
				...item
			}))
		}
		return () => {
			setProductDetails()
		}
	}, [item])

	return (

		<TableRow
			hover
			key={ind}
			sx={{
				cursor: 'pointer',
				borderBottom: '1px solid #ccc',
				backgroundColor: (theme) => theme.palette.background.paper,
				'&:nth-of-type(odd)': {
					backgroundColor: (theme) => theme.palette.background.default,
				},
				// hide last border
				'&:last-child td, &:last-child th': {
					borderBottom: 0,
				},
			}}>
			{productDetails && columns.map((column, index) => {
				//console.log(props)
				//console.log(item)
				const tData = productDetails[column.accessor] ? productDetails[column.accessor] : "——";
				if (!column.hidden && !hidden.includes(column.accessor)) {
					return (
						<TableCell
							key={index}
							sx={{
								maxWidth: column.maxWidth ? column.maxWidth : 'auto',
								minWidth: column.minWidth ? column.minWidth : 'auto'
							}}
							onClick={() => {
								if (column.accessor !== 'price') {
									addCustomRowClick(productDetails)
								}
							}}
						>
							{column.Cell ? column.Cell(productDetails) : tData}
						</TableCell>
					)
				}
			})}
		</TableRow>
	)
		;
}


const ProductTable = ({ isFetching, attributes }) => {

	const { user } = useContext(UserContext);
	const { dispatchProducts, products } = useContext(ProductsContext);
	const [open, setOpen] = useState(false);
	const [selectedProduct, setSelectedProducts] = useState();
	const [sortColumn, setSortColumn] = useState();
	const [sortOrder, setSortOrder] = useState();
	const [hidden, setHidden] = useState([]);

	const handleClose = () => {
		setOpen(false);
		setSelectedProducts();
	}

	//set hidden
	useEffect(() => {
		let hidden = []
		//let hidden = ["ID", "J3", "J20"]
		if (attributes) {
			attributes.forEach((field) => {
				if (field.checked) {
					return
				} else {
					hidden.push(field.Ref)
				}
			})
		}
		setHidden(hidden)
	}, [attributes])


	const columns = useMemo(() => {
		let columns = [
			{
				id: "ID",
				name: 'Product ID',
				accessor: "product_id",
				hidden: true

			},
			//{
			// 	id: "Actions",
			// 	name: 'Full',
			// 	maxWidth: 50,
			// 	noSort: true,
			// 	menuHidden: true,
			// 	Cell: (row) => {
			// 		return (
			// 			<Box sx={{ cursor: 'pointer' }}>
			// 				<FontAwesomeIcon
			// 					icon={faMagnifyingGlass}
			// 					onClick={e => {
			// 						//console.log(e)
			// 						setOpen(true)
			// 						setSelectedProducts(row)
			// 					}}
			// 				/>
			// 			</Box>
			// 		)
			// 	}
			// },
			{
				id: "Image",
				name: 'Graphics',
				Header: () => (
					<Tooltip title="Graphics" placement="bottom-start" >
						<Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} >Graphics</Typography>
					</Tooltip>
				),
				accessor: "product_image",
				minWidth: 180,
				maxWidth: 180,
				noSort: true,
				Cell: (row) => {
					//console.log(row)
					let photo = row.product_image.find(item => item.type === "Photo")
					let diagram = row.product_image.find(item => item.type === "SchematicDiagram")
					let cat = row.product_image.find(item => item.type === "CategoryTechincalDiagram")
					//console.log(productDetails)
					let has3D = false
					row.product_image.forEach((image) => {
						if (image.viewer) {
							has3D = true
						}
					})
					return (
						<Grid container alignContent="center" alignItems="center" >
							<Grid item xs={has3D ? 8 : 12} sx={{ width: '80px', height: '50px', position: 'relative' }}>
								{(photo || diagram || cat) ?
									<img
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'contain'
										}}
										src={photo?.url ? photo.url : diagram?.url ? diagram.url : cat.url}
										onError={e => { e.target.src = cat.url }}
									/>
									: '-'}
							</Grid>
							{has3D ?
								<Grid item xs={4}>
									<Tooltip title={"3D Models Available"} placement="bottom" arrow>
										<Avatar
											//variant="rounded"
											sx={{
												width: '30px',
												height: '30px',
												float: 'left',
												cursor: 'pointer',
												marginLeft: '10px',
												backgroundColor: (theme) => theme.palette.primary.main,
												'& svg': {
													width: '18px',
													height: '18px'
												}
											}}
										>
											<FontAwesomeIcon icon={faCube} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
						</Grid>
					)
				},
			},
			// {
			// 	id: "product_number",
			// 	name: 'Product No',
			// 	Header: () => <Typography>Product No</Typography>,
			// 	accessor: "product_number",
			// 	//minWidth: 220,
			// }, 
			{
				id: "product_id",
				name: 'Product ID',
				Header: () => config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No",
				accessor: "product_id",
				//minWidth: 220,
			},
			{
				id: "product_company",
				maxWidth: 180,
				name: 'Manufacture',
				Header: () => (
					<Tooltip title="Manufacture" placement="bottom-start" >
						<Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} >Manufacture</Typography>
					</Tooltip>
				),
				accessor: "product_company",
				Cell: (row) => {
					return (
						<Box sx={{ width: '80px', height: '50px' }}>
							<BrandLogo product={row} />
						</Box>
					)
				}
			}, {
				id: "product_name",
				name: 'L3 - Description',
				Header: () => <Typography>Name</Typography>,
				accessor: "product_name",
				minWidth: 220,
			}, {
				id: "product_price",
				name: 'price',
				Header: () => <Typography>Price</Typography>,
				accessor: "product_price",
				minWidth: 260,
				Cell: (row) => {
					//console.log(row)
					return (
						<Prices
							table={true}
							product={row}
						/>
					)
				}
			},
			{
				id: "product_delivery",
				name: 'Delivery',
				Header: () => <Typography>Delivery</Typography>,
				accessor: "product_delivery",
				minWidth: 240,
				Cell: (row) => {
					//console.log(row)
					return (
						<Delivery
							table={true}
							product={row}
						/>
					)
				}
			}
		]

		if (attributes) {
			//console.log(attributes)
			attributes.map((item) => {
				columns.push({
					id: item.Ref,
					name: `${item.Ref} - ${item.Name}`,
					Header: () =>
						<Tooltip title={item.Name} placement="bottom-start" arrow >
							<Typography sx={{ cursor: 'pointer' }} >{item.Ref}
							</Typography>
						</Tooltip>
					,
					accessor: item.Ref,
					field: item.Field,
					Cell: (row) => {
						let found = row.product_attributes.find((attr) => attr.code === item.Ref);
						//console.log(found)
						return (

							<Typography fontSize={14} sx={{ cursor: 'pointer' }} >
								{found ? found.value : '-'} <Typography component="span" fontSize={14} sx={{ opacity: 0.4 }}>{found?.value && item?.unit ? item.unit : ''}</Typography>
							</Typography>
						)


					},
				});
			});
		}

		return columns;
	}, [user, attributes]);

	const addCustomRowClick = (row) => {
		setOpen(true)
		setSelectedProducts(row)

		//add to google analytics
		trackEvent({
			action: 'view_item',
			params: {
				"currency": "GBP",
				"value": row.price_incl_discount,
				"items": [
					{
						"item_id": row.product_id,
						"item_name": row.product_description,
						"product_number": row.product_number,
						"product_supplier": row.product_supplier
					}
				]
			}
		})

	}

	const handleSorting = (field) => {
		//console.log(field)
		//if field is currently the sortColumn
		if (sortColumn === field.field) {
			//set decending
			if (sortOrder === "asc") {
				setSortOrder("desc")
				//remove sortColumn and sortOrder
			} else if (sortOrder === "desc") {
				setSortOrder();
				setSortColumn();
			}
		} else {
			//set ascending
			setSortColumn(field.field);
			setSortOrder("asc");
		}
		//console.log(field)
	}

	useEffect(() => {
		if (sortOrder && sortColumn) {
			dispatchProducts({
				type: UPDATE_ORDER,
				payload: {
					sortOrder: sortOrder,
					sortColumn: sortColumn,
				}
			})
		} else {
			dispatchProducts({
				type: UPDATE_ORDER,
				payload: {
					sortOrder: null,
					sortColumn: null,
				}
			})
		}
	}, [sortOrder, sortColumn])

	return (
		<Grid container item xs={12}>
			<Grid item sx={{
				display: 'grid',
				width: '100%'
			}}>
				{(products?.allProducts && !isFetching) ?
					<Card>
						<CardContent
							sx={{
								overflowY: 'auto',
								overflowY: 'auto',
								width: '100%',
								padding: 0,
								height: 'calc(100vh - 400px)'
							}}
						>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow
										sx={{ background: (theme) => theme.palette.background.header }}
									>
										{columns.map((column, i) => {
											//console.log(column)
											if (!column.hidden && !hidden.includes(column.accessor)) {
												//console.log(name)
												return (
													<TableCell
														key={i}
														padding={column.disablePadding ? 'none' : 'normal'}
														sx={{
															background: (theme) => sortColumn === column.accessor ? theme.palette.primary.light : theme.palette.primary.main,
															color: (theme) => theme.palette.primary.contrastText,
															'&:hover ': {
																color: (theme) => theme.palette.primary.contrastText,
															},
															maxWidth: column.maxWidth ? column.maxWidth : 'auto',
															minWidth: column.minWidth ? column.minWidth : 'auto'
														}}
													>
														<Box
															sx={{
																height: '80%',
																marginTop: 0,
																marginBottom: 0,
																width: '1px',
																background: (theme) => theme.palette.primary.light,
																position: 'absolute',
																left: 0,
																top: '10%'
															}}
														/>
														{!column.noSort && column.field ?
															<TableSortLabel
																active={sortColumn === column.field}
																direction={sortColumn === column.field ? sortOrder : "asc"}
																onClick={(e) => handleSorting(column)}
															>
																{column.Header ? column.Header() : column.name}
															</TableSortLabel>
															:
															column.Header ? column.Header() : column.name
														}
													</TableCell>
												)
											}
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{products?.allProducts && columns && products.allProducts.map((item, ind) => {
										return (

											<CustomRow item={item} ind={ind} columns={columns} hidden={hidden} addCustomRowClick={addCustomRowClick} />
											// <TableRow
											// 	hover

											// 	key={ind}
											// 	sx={{
											// 		cursor: 'pointer',
											// 		borderBottom: '1px solid #ccc',
											// 		backgroundColor: (theme) => theme.palette.background.paper,
											// 		'&:nth-of-type(odd)': {
											// 			backgroundColor: (theme) => theme.palette.background.default,
											// 		},
											// 		// hide last border
											// 		'&:last-child td, &:last-child th': {
											// 			borderBottom: 0,
											// 		},
											// 	}}>
											// 	{columns.map((column, index) => {
											// 		//console.log(props)
											// 		//console.log(item)
											// 		const tData = item[column.accessor] ? item[column.accessor] : "——";
											// 		if (!column.hidden && !hidden.includes(column.accessor)) {
											// 			return (
											// 				<TableCell
											// 					key={index}
											// 					sx={{
											// 						maxWidth: column.maxWidth ? column.maxWidth : 'auto',
											// 						minWidth: column.minWidth ? column.minWidth : 'auto'
											// 					}}
											// 					onClick={() => {
											// 						if (column.accessor !== 'price') {
											// 							addCustomRowClick(item)
											// 						}
											// 					}}
											// 				>
											// 					{column.Cell ? column.Cell(item) : tData}
											// 				</TableCell>
											// 			)
											// 		}
											// 	})}
											// </TableRow>
										)
									})}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
					:
					<Skeleton width="100%" height={'calc(100vh - 400px)'} variant="rectangular" animation="wave" />
				}
				{open && selectedProduct &&
					<Dialog
						fullWidth
						maxWidth="md"
						open={open}
						onClose={handleClose}
					>
						<IconButton
							sx={{
								cursor: 'pointer',
								position: 'absolute',
								right: 8,
								top: 8,
								background: '#fff',
								padding: '10px 15px'
							}}
							onClick={handleClose}
						>
							<FontAwesomeIcon
								aria-label="close"
								sx={{
									color: (theme) => theme.palette.grey[500],
								}}
								icon={faTimes}
							/>
						</IconButton>
						<ProductPopup productDetails={selectedProduct} closeDialog={handleClose} />
					</Dialog>
				}
			</Grid>
		</Grid>
	);
};
export default ProductTable;
