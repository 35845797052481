import React, { useState } from 'react';
import { Alert, Grid, Link, Typography } from '@mui/material';
import config from '../config';
import packageJson from '../../package.json';
import { isMobile } from 'react-device-detect';

export default function ACTFooter({ login }) {

	const year = new Date();
	const [actLogo, setActLogo] = useState('');

	import(`../images/act-group-black.png`).then((module) => {
		setActLogo(module.default);
	});

	return (
		<Grid
			container
			spacing={2}
			alignItems={login || isMobile ? "center" : "end"}
			justifyContent={login || isMobile ? "center" : "space-between"}
			sx={{
				//backgroundColor: (theme) => theme.palette.background.default,
				color: (theme) => theme.palette.text.primary,
				padding: '2em 1em 2em 1em',
				//marginTop: login || isMobile  ? '1em' : '2em',
				marginBottom: isMobile ? '3em' : '0',
			}}
		>
			{/* {login &&
			<Grid item xs={12} md={login || isMobile ? 12 : "auto"} sx={{ textAlign: login || isMobile ? "center" : 'left' }}>
				<Link sx={{ color: (theme) => theme.palette.primary.primary }} title="Contact us" href="https://www.my-act.co.uk/contact-us/" rel="noreferrer" target="_blank">
					<Typography
						//color={(theme) => theme.palette.text.secondary}
						fontWeight={600}
						variant="subtitle2"
						sx={{ marginBottom: login ? '10px' : '0' }}
					>Don&apos;t have an account login?
					</Typography>
				</Link>
			</Grid>
			} */}
			<Grid item xs={12} md={login || isMobile ? 12 : "auto"} sx={{ textAlign: login || isMobile ? "center" : 'left' }}>
				<Typography
					color={(theme) => theme.palette.text.secondary}
					fontWeight={600}
					variant="subtitle2"
					sx={{ marginBottom: login ? '10px' : '0' }}
				>
					{`${config.APPTITLE}`} - v{packageJson.version}
				</Typography>
				<Typography fontWeight={600} color={(theme) => theme.palette.text.secondary} variant="subtitle2">
					Copyright © {year.getFullYear()} My ACT, All Rights Reserved 
					{/*| Designed &amp; Developed by <Link sx={{ color: (theme) => theme.palette.primary.main }} title="Cambridge Applcation Developers" href="https://www.design-monkey.co.uk" rel="noreferrer" target="_blank"> Design Monkey Media ltd</Link>.
					Powered by <Link sx={{ color: (theme) => theme.palette.primary.main }} href='https://aws.amazon.com/' rel="noreferrer" target="_blank">AWS</Link> */}
				</Typography>
			</Grid>
			<Grid container item xs={12} md={login || isMobile ? 12 : "auto"} >
				<Grid item xs={12}
					sx={{
						marginBottom: login || isMobile ? '10px' : '0',
						textAlign: login || isMobile ? "center" : 'right'
					}}
				>
					<img
						width={login ? "180px" : "220px"}
						alt="ACT Group"
						src={actLogo}
					/>
				</Grid>
			</Grid>

		</Grid>
	)
};
