import React, { useReducer } from 'react';
import { MachinesReducer, GroupsReducer } from './MachineReducers';

export const MachineContext = React.createContext();

export const MachineProvider = ({ children }) => {

	const [noMachines, dispatchNoMachines] = useReducer(MachinesReducer, null);
	const [machines, dispatchMachines] = useReducer(MachinesReducer, []);
	const [selectedMachine, dispatchSelectedMachine] = useReducer(MachinesReducer, {});
	const [selectedMachines, dispatchSelectedMachines] = useReducer(MachinesReducer, []);
	const [filteredMachines, dispatchFilteredMachines] = useReducer(MachinesReducer,[]);
	const [gridView, dispatchGridView] = useReducer(MachinesReducer, false);
	const [displayView, dispatchDisplayView] = useReducer(MachinesReducer, false);
	const [outOfControl, dispatchOutOfControl] = useReducer(MachinesReducer, []);
	const [isOutOfControl, dispatchIsOutOfControl] = useReducer(MachinesReducer,false);
	const [groups, dispatchGroups] = useReducer(GroupsReducer, []);
	const [selectedGroup, dispatchSelectedGroup] = useReducer(GroupsReducer, {});

	const contextValues = {
		noMachines,
		dispatchNoMachines,
		machines,
		dispatchMachines,
		groups,
		dispatchGroups,
		selectedGroup,
		dispatchSelectedGroup,
		selectedMachine,
		dispatchSelectedMachine,
		selectedMachines,
		dispatchSelectedMachines,
		filteredMachines,
		dispatchFilteredMachines,
		gridView,
		dispatchGridView,
		outOfControl,
		dispatchOutOfControl,
		isOutOfControl,
		dispatchIsOutOfControl,
		displayView,
		dispatchDisplayView
	};

	return (
		<MachineContext.Provider value={contextValues}>
			{children}
		</MachineContext.Provider>
	);
};
