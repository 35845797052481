import { Button, ButtonGroup, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import createAttributeOutputFunction from '../../helpers/createAttributeOutputFunction';
import { buttonStyles } from '../../styles/buttonStyles';
import OutlinedDiv from '../../reusable/OutlinedDiv';
import useColorChip from '../../reusable/useColorChip';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { Alert } from '@mui/material';

export default function RadioButtons({
	attr,
	options,
	label,
	initialValue,
	company,
	dispatchFn,
	setIsPending,
	setError,
	notNeeded
}) {
	const [attribute, setAttribute] = useState({
		attribute_id: attr.one.attr_id,
		attribute_values: {
			status: Number.isInteger(initialValue) ? -20 : '',
			value: Number.isInteger(initialValue) ? initialValue : -2
		}
	});
	const [warning, setWarning] = useState();
	const [tooltip, setToolTip] = useState();
	const buttonStyle = buttonStyles();
	const [valueHexColor, setStatus] = useColorChip(company.company_colour_rules);

	//set warnings
	useEffect(() => {
		if (attribute.attribute_values.status === 30) {
			setError({
				attr_id: attribute.attribute_id,
				type: "warning",
				message: `Warning - ${attr.one.attr_name} is high`
			})
			setWarning(true);
		} else {
			setError({
				attr_id: attribute.attribute_id,
				type: "none",
			});
			setWarning(false);
		}
	}, [
		attribute.attribute_id,
		attribute.attribute_values.status,
		setWarning
	]);

	useEffect(() => {
		dispatchFn && dispatchFn(attribute);
	}, [attribute, dispatchFn]);

	useEffect(() => {
		if (typeof attribute.attribute_values.status === 'number') {
			setStatus(attribute.attribute_values.status);
		}
		//set pending on higher component to hide/show qr scanner
		if (setIsPending) {
			if (attribute.attribute_values.status === -10) {
				setIsPending(true);
			} else {
				setIsPending(false);
			}
		}

	}, [attribute.attribute_values.status, setStatus]);

	useEffect(() => {
		setAttribute(prevState => ({
			...prevState,
			// When user change value and then return to pending, we need to reset the pending status manually, spread operator will keep other properties like min, max etc...
			attribute_values:
				attribute.attribute_values.value !== -1
					? {
						...prevState.attribute_values,
						...createAttributeOutputFunction(attr)(
							attribute.attribute_values.value
						)
					}
					: {
						status: -10,
						value: -1,
						output: 'Pending'
					}
		}));
	}, [attr, attribute.attribute_values.value]);

	const handleChange = (event, tooltip) => {
		event.persist();
		// Chip (div) has a value, but has div element don't allow 'value' we need to extract it from the attributes
		// const divValue = Number(event.target.getAttribute('value'));
		setAttribute(prevState => ({
			...prevState,
			attribute_values: {
				...prevState.attribute_values,
				value: Number(event.target.value)
			}
		}));
		//set mobile tooptip
		if (tooltip.tooltip) {
			setToolTip(tooltip);
		} else {
			setToolTip();
		}
	};

	const HtmlTooltip = withStyles((theme) => ({
		tooltip: {
			title: '',
			backgroundColor: 'transparent',
			color: 'transparent',
			border: 'none'
		},
	}))(Tooltip);

	const removeValue = () => {
		setAttribute(prevState => ({
			...prevState,
			attribute_values: {
				...prevState.attribute_values,
				status: -20,
				value: -2,
				output: "-"
			}
		}));
		//remove error
		setError({
			attr_id: attribute.attribute_id,
			type: "none",
		});
	}

	return (
		<>
			{attr &&
				<OutlinedDiv
					label={attr.one.attr_name}
					error={warning}
				>
					<Grid container spacing={1}>
						{label &&
							<Grid item xs={12}>
								<Typography variant="h6">{label}</Typography>
							</Grid>
						}
						<Grid item xs={12}>
							<ButtonGroup
								orientation={isMobileOnly ? 'vertical' : 'horizontal'}
								fullWidth
								disableElevation
							>
								{options.map((opt, i) => {
									if (opt.tooltip) {
										return (
											<HtmlTooltip
												key={i}
												position="top"
												title={<Paper><Alert severity={opt.severity ? opt.severity : null} >{opt.tooltip}</Alert></Paper>}
											>
												<Button
													fullWidth
													value={opt.value}
													onClick={(e) => handleChange(e, opt)}
													className={buttonStyle.fixPointerEvent}
													style={{
														backgroundColor:
															attribute.attribute_values.value === Number(opt.value) &&
															valueHexColor
													}}
													variant={
														attribute.attribute_values.value !== Number(opt.value)
															? 'outlined'
															: 'contained'
													}
												>
													{opt.output}
												</Button>
											</HtmlTooltip>
										)
									} else {
										return (
											<Button
												key={i}
												fullWidth
												value={opt.value}
												onClick={(e) => handleChange(e, opt)}
												className={buttonStyle.fixPointerEvent}
												style={{
													backgroundColor:
														attribute.attribute_values.value === Number(opt.value) &&
														valueHexColor
												}}
												variant={
													attribute.attribute_values.value !== Number(opt.value)
														? 'outlined'
														: 'contained'
												}
											>
												{opt.output}
											</Button>
										)
									}
								})}
								{notNeeded &&
									<Button
										fullWidth
										onClick={(e) => removeValue()}
										className={(attribute.attribute_values.value === -2) ? buttonStyle.danger : ''}
										variant='outlined'
									>
										Not Needed
									</Button>
								}
							</ButtonGroup>
							{tooltip &&
								<Alert style={{ marginTop: '5px' }} severity={tooltip.severity} >{tooltip.tooltip}</Alert>
							}
						</Grid>
					</Grid>
				</OutlinedDiv>
			}
		</>
	);
};
