import React, { useContext, useEffect, useState } from "react";
import { Alert, Autocomplete, Grid, TextField, Typography, Button } from '@mui/material';
import axiosRequest from "../../../axios/axoisRequest";
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import { CompanyContext } from "../../../store/CompanyContext";
import BuyCredits from "../../../settings/companies/credits/BuyCredits";
import { useMutation } from "react-query";
import DialogLoader from "../../../reusable/DialogLoader";
import { Storage } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { SELECTED_COMPANY } from "../../../store/CompanyReducers";

const ProductExports = ({ product, options }) => {

    const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    const [success, showSuccess] = useState(false);

    function getTUCs(exportBase, fileExtension) {
        let tucCost = 0;
        // Check the ExportBase and FileExtension to determine the TUCs cost
        if (exportBase === "Manufacturer" && fileExtension === "zip") {
            tucCost = 1;
        } else if (exportBase === "Generated" && fileExtension === "zip") {
            tucCost = 3;
        } else if (exportBase === "Manufacturer" && fileExtension === "xlsx") {
            tucCost = 3;
        } else if (exportBase === "Generated" && fileExtension === "stp") {
            tucCost = 3;
        } else if (exportBase === "Generated" && fileExtension === "dxf") {
            tucCost = 3;
        } else if (exportBase === "Manufacturer" && fileExtension === "stp") {
            tucCost = 1;
        } else if (exportBase === "Manufacturer" && fileExtension === "dxf") {
            tucCost = 1;
        }
        return tucCost;
    }

    // useEffect(() => {
    //     if (selected) {
    //         let tucCost = getTUCs(selected.ExportBase, selected.FileExtension);
    //         console.log(tucCost);

    //     }
    // }, [selected])


    Storage.configure({
        AWSS3: {
            bucket: config.s3.BUCKET,
            region: config.s3.REGION
        }
    });

    //gets companies full details
    const refresh_company = useMutation((company_id) => {
        //console.log(user)
        return axiosRequest({
            method: 'get',
            endpoint: 'companies',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            params: {
                id: company_id
            }
        })
    }, {
        onSuccess: (data) => {

            dispatchSelectedCompany({
                type: SELECTED_COMPANY,
                payload: data[0]
            });
            window.sessionStorage.setItem('selectedCompany', data[0].company_id);
        }
    })

    const minus_credits_total = useMutation((credits) => {
        return axiosRequest({
            endpoint: 'company/settings',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            method: 'patch',
            body: {
                credits: credits,
                credit_type: "minus",
                company_id: selectedCompany.company_id,
            }
        })
    }, {
        onSuccess: (data) => {
            //console.log(data)
            refresh_company.mutate(selectedCompany.company_id)
        }
    })

    const dowloadFile = async (filename) => {

        // get the signed URL string
        await Storage.get(`downloads/${filename}`, {
            download: true,
            cacheControl: 'no-cache'
        }).then(async (resp) => {
            //check if the file exsits
            const signedURL = await Storage.get(`downloads/${filename}`, {
                download: false,
                cacheControl: 'no-cache'
            })
            //open smae window
            window.location.href = signedURL
            //window.open(signedURL, 'download_window')

        }).catch((err) => {
            if (err.Code === "AccessDenied") {
                console.log("This file has been marked as infected, you will not be able to download")
            } else {
                console.log(err.Code)
            }
            // handle errors 
        })
    }

    const save_dowload = useMutation((body) => {
        //console.log(user)
        return axiosRequest({
            method: 'post',
            endpoint: 'downloads',
            gateway: config.shopAPI.URL,
            api_key: user.api_key,
            body: body
        })

    }, {
        onSuccess: (data) => {
            console.log(data)
            if (data?.body?.download_path) {
                dowloadFile(data?.body?.download_path)


                minus_credits_total.mutate(1)
            }
        }
    })

    const dowloadSeleceted = (selected) => {
        let url = selected.Link.replace('https://cis-act.salessupportserver.com/asp-productive/EN/Article/', '').replace('&sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&autoBuy=True', '')
        let body = {
            "download_path": url,
            "download_bucket": config.s3.BUCKET,
            "download_product_id": `${selected.ExportBase}_${product.product_id}_${selected.Type}`,
            "download_extension": selected.FileExtension,
            "download_details": {
                "file_details": selected,
                "product_details": product
            },
            "download_user": {
                "user_name": user.user.username,
                "user_id": user.user.user_id,
                "user_email": user.user.email
            },
            "download_company": {
                "company_id": selectedCompany.company_id,
                "company_name": selectedCompany.company_name,
            },
            "download_credit": getTUCs(selected.ExportBase, selected.FileExtension)
        }

        //console.log(body)
        save_dowload.mutate(body)
    }

    return (

        <Grid container spacing={2} >

            <Grid container item spacing={2}>

                <Grid item xs={8} >
                    <Alert
                        //icon={false}
                        severity={selectedCompany.company_credits > 0 ? 'info' : 'warning'}
                    >
                        {selectedCompany.company_credits > 0 ?
                            <Typography variant="p">
                                {selectedCompany.company_name}:  <Typography variant="p" component="span" fontWeight={600}>{selectedCompany.company_credits} Download Tokens</Typography> available
                            </Typography>
                            :
                            <Typography variant="p">
                                {selectedCompany.company_name}:  <Typography variant="p" component="span" fontWeight={600}>No Download Tokens</Typography> available
                            </Typography>
                        }
                    </Alert>
                </Grid>
                <Grid item xs={4}>
                    <BuyCredits />
                </Grid>
            </Grid>

            {success ?
                <Grid item xs={12}>
                    <Alert
                        //icon={false}
                        severity='success'
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="p" component="span">
                            File downloaded. Please go to your Company downloads area if you wish to re-download any of your files
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate('/settings/company?section=0&tab=4')}
                        >
                            Company Product Downloads
                        </Button>
                    </Alert>
                </Grid>
                : null}
            <Grid container item spacing={1}>
                <Grid item xs>
                    <Autocomplete
                        id="download"
                        value={selected}
                        options={options}
                        getOptionLabel={option => `${option.ExportBase}: ${option.GraphicType ? option.GraphicType : ''} ${option.Type}.${option.FileExtension} (${ getTUCs(option.ExportBase, option.FileExtension)} Tokens)`}
                        onChange={(event, newValue) => setSelected(newValue)}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" placeholder="Select Download" />
                        )}
                        disabled={selectedCompany.company_credits > 0 ? false : true}
                        
                        sx={{
                            '& legend': {
                                display: 'none'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs="auto" >
                    <Button
                        variant="contained"
                        color="primary"
                        //disabled={product?.product_cim_id !== "b8549f63601140cd8793521fca16e32d"}
                        onClick={() => dowloadSeleceted(selected)}
                        sx={{ height: '100%' }}
                    >
                        Download File
                    </Button>
                </Grid>
                {save_dowload.isLoading || save_dowload.isSuccess || save_dowload.isError ?
                    <DialogLoader
                        isLoading={save_dowload.isLoading}
                        mutation={save_dowload}
                        loadingMessage="Saving Download..."
                        successMessage="Download Saved"
                        fixed
                        closeDialog={() => { showSuccess(true) }}
                    />
                    : null}
            </Grid>

        </Grid>
    );
};
export default ProductExports;

