import { faMagnifyingGlass, faPencil, faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Alert,
	Box,
	Button,
	Chip,
	Grid,
	Typography
} from '@mui/material';
import React, {
	useContext, useEffect, useMemo, useState
} from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import GlobalDialog from '../reusable/GlobalDialog';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from '../store/CompanyContext';
import { LayoutContext } from '../store/LayoutContext';
import { UPDATE_MENU } from '../store/LayoutReducers';
import { UserContext } from '../store/UserContext';
import DesignTable from './DesignTable';
import SpinningLoader from '../reusable/SpinnningLoader';
import { stripHtml } from "string-strip-html";
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import Loader from '../reusable/Loader';
import CompanyFilter from '../reusable/CompanyFilter';
import SelectColumnFilter from '../reusable/SelectColumnFilter';
import DesignPDFDialog from './pdf/DesignPDFDialog';
import getUserLevel from '../helpers/getUserLevel';

const Archive = ({ request_id, setRequests }) => {

	const [open, setOpen] = useState(false);
	const { user } = useContext(UserContext);

	const handleClose = () => {
		setOpen(false);
	}

	const archive_mutation = useMutation((request_id) => {
		//console.log(request_id)
		return axiosRequest({
			method: 'patch',
			endpoint: 'requests',
			gateway: config.designAPI.URL,
			api_key: user.api_key,
			body: {
				request_id: request_id
			}
		})
	}, {
		onSuccess: (data) => {

			setRequests(current => current.filter((req) => req.request_id !== request_id));
			handleClose();
			archive_mutation.reset();
		}
	})

	const archive_request = () => {
		archive_mutation.mutate(request_id)
	}

	return (
		<>
			<Button
				variant="contained"
				color="error"
				size='large'
				onClick={() => setOpen(true)}
			> <FontAwesomeIcon
					icon={faTrash}
					size="xl"
				/>
			</Button>
			{open ?
				<GlobalDialog
					open={open}
					onClose={handleClose}
					title="Delete Request"
					buttonClick={archive_request}
					buttonTitle="Yes Please, Delete"
					maxWidth="sm"
				>
					<Alert severity="error">
						Are yo sure you want to delete this request?
					</Alert>
				</GlobalDialog>
				: null}
			{archive_mutation.isLoading || archive_mutation.isSuccess || archive_mutation.isError ?
				<Loader
					isLoading={archive_mutation.isLoading}
					mutation={archive_mutation}
					loadingMessage="Deleting Request..."
					successMessage="Request Deleted"
				// callback={
				//   navigate('/solution/dashboard')
				// }
				/>
				: null}
		</>
	)
}

const Design = () => {

	const navigate = useNavigate()
	const { dispatchApp } = useContext(LayoutContext)
	const { selectedCompany } = useContext(CompanyContext)
	const { user } = useContext(UserContext)
	const [requests, setRequests] = useState()

	// gets companies designs
	const getDesigns = useMutation((company_id) => {
		//console.log(user)
		return axiosRequest({
			method: 'get',
			endpoint: 'requests',
			gateway: config.designAPI.URL,
			api_key: user.api_key,
			params: {
				company_id: company_id, // if company id is equal to 0
				all: (user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator) ? true : false, //has access to all
				companies_id: user.user.user_permissions.access.companies ? user.user.user_permissions.access.companies : null
			}
		})
	}, {
		onSuccess: (data) => {
			//console.log(data)
			if (data) {
				setRequests(data)
			}
		}
	})

	useEffect(() => {

		//console.log(selectedCompany)
		if (user && selectedCompany?.company_id) {
			getDesigns.mutate(selectedCompany.company_id)
		}
	}, [user, selectedCompany])

	//console.log(selectedCompany)

	const columns = React.useMemo(() => {
		return [
			{
				id: "id",
				name: 'Ref',
				minWidth: 70,
				width: 70,
				maxWidth: 70,
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Ref</Typography>,
				accessor: 'request_id',
				Cell: ({ row, value }) => (
					<Typography>#{value}</Typography>
				)
			}, {
				id: "status",
				name: 'Status',
				minWidth: 120,
				width: 120,
				maxWidth: 120,
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Status</Typography>,
				Filter: (column) => SelectColumnFilter(column),
				accessor: "request_status",
				//hidden: true
				Cell: ({ value }) => (
					<Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }} >
						<Chip
							color={value === 'New' ? 'error' : value === 'Open' ? 'warning' : 'secondary'}
							label={value}
							sx={{ borderRadius: '5px', fontSize: '1em' }}
						/>
					</Box>
				)
			}, {
				id: "request_details",
				name: 'Details',
				minWidth: 300,
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Details</Typography>,
				accessor: "request_details",
				Cell: ({ row }) => {
					//console.log(row)
					return (
						<Box>
							<Typography fontWeight={700}>{row.original.request_name}</Typography>
							<Typography>
								{(stripHtml(row.original.request_details).result).substring(0, 80)}{row.original.request_details.length > 80 ? '...' : ''}
							</Typography>
						</Box>
					)
				}
			}, {
				id: "company",
				name: 'company',
				minWidth: 200,
				width: 200,
				maxWidth: 200,
				hidden: selectedCompany.company_id !== 0 ? true : false,
				Filter: (column) => CompanyFilter(column),
				//Filter: (column) => SelectColumnFilter(column),
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Company</Typography>,
				accessor: "request_company",
				//hidden: true
				Cell: ({ value }) => {
					//console.log(value)
					const company = value
					return (
						<Box>
							<Typography>{company.company_name}</Typography>
						</Box>
					)
				}
			}, {
				id: "updated",
				name: 'Last Updated',
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Last Updated</Typography>,
				accessor: "request_last_updated",
				Cell: ({ value }) => {
					//console.log(value)
					const user = value
					//console.log(user)
					const date = new Date(user.date)
					return (
						<Box>
							<Typography>by {user.user_details.name} {user.user_details.surname}</Typography>
							<Typography>{date.toLocaleDateString()} {date.toLocaleTimeString()}</Typography>
						</Box>
					)
				}
			}, {
				id: "comments",
				name: 'Comments',
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Comments</Typography>,
				accessor: "request_comment_count",
				minWidth: 120,
				width: 120,
				maxWidth: 120,
				Cell: ({ value }) => {
					//console.log(value)
					return (
						<Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }} >
							<FontAwesomeIcon icon={faComment} size="3x" color='#666' />
							<Typography
								color="#fff"
								sx={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									top: 0,
									padding: '7px'
								}}>{value}</Typography>
						</Box>
					)
				}
			}, {
				id: "actions",
				name: '',
				Header: () => <Typography componenet="p" variant="body1" fontWeight={700}>Actions</Typography>,
				minWidth: 200,
				width: 200,
				maxWidth: 200,
				noSort: true,
				accessor: 'request_id',
				Cell: ({ row, value }) => {
					//console.log(value)
					//console.log(JSON.parse(row.original.request_company).company_id)
					return (
						<Grid container spacing={2} justifyContent="end">
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									endIcon={
										<FontAwesomeIcon icon={faPencil}/>
									}
									onClick={e => navigate(`/design/view?request=${value}&company=${row.original.request_company.company_id}`)}
								>
									Update
								</Button>
							</Grid>
							<Grid item>
								<DesignPDFDialog data={row.original} />
							</Grid>
							{getUserLevel(user.user, 'design', 'design_delete', 8) ?
								<Grid item>
									<Archive setRequests={setRequests} request_id={value} />
								</Grid>
								: null}
						</Grid>
					)
				}
			}]
	}, [selectedCompany.company_id])


	const addCustomRowClick = (row) => {
		navigate(`/design/view?request=${row.original.request_id}&company=${row.original.request_company.company_id}`)
	}
	//console.log(requests)

	return (
		<>
			<InnerHeaderWrap >
				{/* <GlobalCompanySelect removeAll /> */}
				<Grid spacing={2} container justifyContent="space-between" alignItems="center" >
					<Grid item xs={12} md={3} >
						<GlobalCompanySelect fullWidth addAll />
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid container item spacing={2} xs={12}>
					<Grid
						container
						item
						xs={12}>
						<Grid item>
							<Button
								variant='contained'
								//color='info'
								onClick={e => {
									dispatchApp({
										type: UPDATE_MENU,
										payload: {
											selectedArea: 'design',
											selectedMenu: 'new'
										}
									})
									navigate('/design/new');
								}}
								disabled={!Object.values(selectedCompany).length}
								size="large"
								startIcon={<FontAwesomeIcon icon={faPlus} size="xs" />}
							>
								Create a New Design Request
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12}>

						{(requests && Object.values(requests).length > 0) && !getDesigns.isLoading ?
							<DesignTable
								columns={columns}
								data={requests ? requests : []}
								hidden={[]}
								//setHidden={setHidden}
								sortColumn={[]}
								//sortOrder={sortOrder}
								//handleSorting={handleSorting}
								addCustomRowClick={addCustomRowClick}
							/>
							: null}
						{getDesigns.isLoading ?
							<Box sx={{ padding: '1em' }} >
								<SpinningLoader text="Loading Design..." size={40} />
							</Box>
							: null}
						{(requests && Object.values(requests).length > 0) || getDesigns.isLoading ? null :
							<Box sx={{ padding: '1em' }} >
								<Alert severity='error' sx={{ width: '100%' }}>No Design Requests have been created yet for <strong>{selectedCompany.company_name}</strong></Alert>
							</Box>
						}

					</Grid>
				</Grid>
			</PageContent>
		</>
	);
}

export default Design;
