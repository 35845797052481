import {
    Alert,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useFormikContext } from "formik"
import config from '../../config';
import cognitoPromise from '../../cognito/cognitoPromise';
import CustomCard from '../../reusable/CustomCard';
import GetTemplate from '../../templates/GetTemplates';
import userFormObject from '../helpers/userFormObject';
import { UserContext } from '../../store/UserContext';

const UserDetailsForm = ({ edit, testing, template , valid, setValid }) => {

    const { user } = useContext(UserContext);
    const { values, touched, errors, handleChange, setValues, handleBlur } = useFormikContext();
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);




    //console.log(values) 
    console.log(touched)

    const checkUsername = async (username) => {
        setLoading(true)
        try {
            let params = {
                UserPoolId: config.cognito.USER_POOL_ID,
                Username: username
            };
            const user = await cognitoPromise({ params: params, fn: 'adminGetUser' });
            console.log(user)
            if (user) {
                setValid(false)
                setLoading(false)
                setError(true)
            } 
        } catch (e) {
            setValid(true)
            setLoading(false)
            setError(false)
        }
    };

    const setTemplate = (template) => {
        //get current user details
        //console.log(template)
        //console.log(values)
        setTemplateLoading(true)
        let formObject = userFormObject({
            "user_details": {
                "name": values.name,
                "surname": values.surname,
            },
            "email": values.email,
            "confirm_email": values.email,
            "username": values.username,
            "aws_sub": values.aws_sub,
            "user_permissions": template.template
        })
        //console.log(formObject

        setValues(formObject[0]);
        // //timeout	
        setTimeout(() => {
            setTemplateLoading(false)
        }, [1000])
    }

    return (
        <Grid item container spacing={2}>
            {!template ?
                <Grid item xs={12}>
                    <CustomCard title="User Details">
                        <Grid item container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    disabled={testing}
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={values?.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={testing}
                                    id="surname"
                                    name="surname"
                                    label="Surname"
                                    value={values?.surname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.surname && Boolean(errors.surname)}
                                    helperText={touched.surname && errors.surname}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Username"
                                    value={values?.username}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={edit || testing}
                                    variant="outlined"
                                    error={touched.username && Boolean(errors.username)}
                                    helperText={touched.username && errors.username}
                                    onBlur={(e) => {
                                        handleBlur(e)
                                        checkUsername(values.username)
                                    }}
                                // InputProps={{
                                //     endAdornment:
                                //         <InputAdornment position="end">
                                //             {valid ? <FontAwesomeIcon size='2x' icon={faCheckCircle} /> : <FontAwesomeIcon size='2x' icon={faTimesCircle} />}
                                //         </InputAdornment>,
                                // }}
                                />
                                {!errors.username && error && values.username.length > 0 ?
                                    <Alert severity='error' sx={{ mt: 1 }}>Username already taken, please try something different</Alert>
                                    : valid && values.username.length > 0 &&
                                    <Alert severity='success' sx={{ mt: 1 }}>Username available</Alert>
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="aws_sub"
                                    name="aws_sub"
                                    label="User ID"
                                    value={values?.aws_sub}
                                    //onBlur={handleChange}
                                    //onChange={handleChange}
                                    fullWidth
                                    disabled={true}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={testing}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={testing}
                                    id="confirm_email"
                                    name="confirm_email"
                                    label="Confirm Email"
                                    value={values.confirm_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.confirm_email && Boolean(errors.confirm_email)}
                                    helperText={touched.confirm_email && errors.confirm_email}
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                : null}
            {!template ?
                <Grid item xs={12}>
                    <CustomCard title="Permission Template">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GetTemplate type={["user_permissions"]} setTemplate={setTemplate} buttonText="Set Users Permissions" loading={templateLoading} />
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    Select a template to apply the new user, this will overwrite any existing permissions you have entered below.
                                </Alert>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                : null }
            {user?.user?.user_permissions?.s_administrator ?
                <Grid item xs={12}>
                    <CustomCard title="System Administrator Permissions" hightlight>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={values.administrator} onChange={handleChange} name="administrator" />
                                    }
                                    label="Make this user a System Administrators"
                                />
                            </Grid>
                            {values.administrator ?
                                <Grid item xs={12}>
                                    <Alert severity='error' sx={{ mt: 1 }}><strong>System Administrator</strong> accounts have access to <strong>all data</strong> across the application.</Alert>
                                </Grid>
                                : null}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={values.s_administrator} onChange={handleChange} name="s_administrator" />
                                    }
                                    label="Allow this user to create System Administrators"
                                />
                            </Grid>
                            {values.s_administrator ?
                                <Grid item xs={12}>
                                    <Alert severity='error' sx={{ mt: 1 }}>This user can create <strong>System Administrators</strong>.</Alert>
                                </Grid>
                                : null}
                        </Grid>
                    </CustomCard>
                </Grid>
                : null}

        </Grid>

    );
}
export default UserDetailsForm;