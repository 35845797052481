import React from "react";
import { Box } from "@mui/material";

const TabContent = ({children}) => {
    
    return (
        <Box sx={{padding: '1em', width: '100%', background: (theme) => theme.palette.tabs.main}} >
            {children}
        </Box>
    );
};
export default TabContent;