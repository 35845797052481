import React, { useCallback, useState } from "react";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import InputLables from "./InputLables";

const Dropdown = ({ field, updateField }) => {

    const [localValue, setLocalValue] = useState(null);
    //update select
    const updateSelected = useCallback((value) => {
        setLocalValue(value);
        updateField(field, value)
    }, [field])

    return (
        <>
            <InputLables field={field} />
            <Grid item xs={12}>
                <Autocomplete
                    id={field.ID}
                    sx={{ minWidth: 300 }}
                    options={field.Values}
                    value={localValue}
                    onChange={(event, newValue) => {
                        updateSelected(newValue);
                    }}
                    //inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //  //console.log(newInputValue)
                    //     setInputValue(newInputValue);
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.ID} : ${option.Name}`}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
                            {option.Icon &&
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={option.Icon}
                                    srcSet={option.Icon}
                                    alt=""
                                />
                            }
                            {option.ID} : {option.Name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={''}
                            sx={{ '& legend': { display: 'none' } }}
                            InputLabelProps={{ shrink: false }}
                            placeholder={`Please Select ${field.Name}`}
                            size="small"
                        />
                    )}
                />
            </Grid>
        </>
    );
};


export default Dropdown;



// import React, { useContext, useEffect, useRef, useState } from "react";
// import { Autocomplete, Box, TextField } from "@mui/material";
// import { useFormContext } from "react-hook-form";
// import { SelectedContext } from "../store/SelectedContext";
// import ReactTestUtils from "react-dom/test-utils";

// const Dropdown = ({ field, index }) => {

//     const [localValue, setLocalValue] = useState(null);
//     const [inputValue, setInputValue] = useState('');
//     const [localOptions, setLocalOptions] = useState([]);
//     const { selected } = useContext(SelectedContext);
//     const { setValue, unregister, register } = useFormContext(); // retrieve all hook methods
//     //const unique = `${field.Field}_${index}`
//     const unique = field.Field

//   //   useEffect(()=>{
//   //     return () =>{
//   //         //unmount
//   //         unregister(unique)
//   //     }
//   // },[unique])

//     //use QueryString on first load to set fields that are availble
//     useEffect(() => {

//         //register(unique);

//         if (window.location.search) {
//             //console.log('Querystring');
//             let urlParams = new URLSearchParams(window.location.search);
//             let searchArr = JSON.parse(urlParams.get("search"))
//             //console.log(searchArr)
//             let index = (searchArr).findIndex((obj) => obj.name === field.Field);
//             //console.log(searchArr[index].value) 
//             //console.log(field.Values) 
//             if (index > -1) {
//                 setValue(unique, searchArr[index].value);
//                 //update selected on Autocomplete
//                 let valueCopy = [...field.Values]
//                 //console.log(valueCopy)
//                 let foundItem = (valueCopy).find((value) => value.ID === searchArr[index].value)
//                 //console.log(foundItem)
//                 setLocalValue(foundItem);

//             } else { 
//               setLocalValue(unique, '');
//             }
//         }
//     }, [unique]);

//     //set local options
//     useEffect(() => {
//         if (field.Values) {
//             //console.log(field.Values)
//             const options = [...field.Values] // create copy of values
//             setLocalOptions(options.map((item) => item.ID))
//         }
//     }, [field.Values])

//     // hidden select needed to force react-hooks-form to see onChange
//     const hiddenSelect = useRef();
//     const updateHiddenSelect = (event, item) => {
//         //console.log(item)
//         if(item){
//           setLocalValue(item);
//           setValue(unique, item.ID);
//         } else {
//           setLocalValue(null);
//           setValue(unique,null);
//           ReactTestUtils.Simulate.change(hiddenSelect.current);
//           hiddenSelect.current.dispatchEvent(
//               new Event("change", { bubbles: true })
//           );
//         }
//     };

//     //force onchange event on hiddden select
//     useEffect(() => {
//         if (localValue) {
//             //console.log(field.Values)
//             // ReactTestUtils.Simulate.change(hiddenSelect.current);
//             // hiddenSelect.current.dispatchEvent(
//             //     new Event("change", { bubbles: true })
//             // );
//         }
//     }, [localValue, hiddenSelect])

//     return (
//         <>
//             <select
//                 ref={hiddenSelect}
//                 //multiple
//                 name={field.Name}
//                 value={localValue}
//                 onChange={(e) => {
//                     //console.log(e);
//                 }}
//                 readOnly
//                 className="d-none"
//             >
//                 {localOptions &&
//                     (localOptions).map((option, i) => (
//                         <option key={i} value={option}>
//                             {option}
//                         </option>
//                     ))}
//             </select>

//             <Autocomplete
//                 id={field.ID}
//                 //multiple
//                 sx={{ minWidth: 300 }}
//                 options={field.Values}
//                 value={localValue}
//                 onChange={(event, newValue) => {
//                     //console.log(newValue)
//                     //console.log(event)
//                     //update hidden select, as react-hooks-form doesn't pickup onChange
//                     updateHiddenSelect(event, newValue);
//                 }}
//                 inputValue={inputValue}
//                 onInputChange={(event, newInputValue) => {
//                     setInputValue(newInputValue);
//                 }}
//                 autoHighlight
//                 getOptionLabel={(option) =>  `${option.ID} : ${ option.Name}`}
//                 renderOption={(props, option) => (
//                     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//                         <img
//                             loading="lazy"
//                             width="20"
//                             src={option.Icon}
//                             srcSet={option.Icon}
//                             alt=""
//                         />
//                         {option.ID} : { option.Name}
//                     </Box>
//                 )}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label={field.Name}
//                         placeholder="Please Select"
//                     />
//                 )}

//             />
//         </>
//     );
// };

// export default Dropdown;

