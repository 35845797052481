import { Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ShopContext } from "../../store/ShopContext";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UPDATE_FILTERS } from "../../store/ShopReducers";
import { useLocation } from "react-router-dom";

const ResultToggleFilters = () => {

	const { filters, dispatchFilters } = useContext(ShopContext);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const search = searchParams.get('s');

	const toggleFilter = (filter) => {
		//console.log(filter)
		//console.log(filters.productFilters)
		let clone = structuredClone(filters.productFilters)
		//see if there is already a selected filter 
		let index = clone.findIndex((item) => (item.i === filter.i));
		//console.log(index)
		if (index > -1) {
			clone[index].s = !clone[index].s
		}
		//set Show to remove / add back to the API call
		dispatchFilters({
			type: UPDATE_FILTERS,
			payload: clone
		});
	}

	//console.log(filters?.productFilters)

	return (
		<>
			{search || (filters?.productFilters && Object.values(filters.productFilters).length) ?
				<Grid item xs={12} >
					<Grid item container alignItems="center" spacing={1}>
						{search ?
							<Grid item>
								<Box
									sx={{
										padding: '.5em 1em',
										border: theme => `1px solid ${theme.palette.primary.main}`,
										color: theme => theme.palette.primary.main,
										fontSize: '14px',
										borderRadius: '5px',
										textAlign: 'center'

									}}
								//fullWidth
								//variant="outlined"
								//onClick={handleOpen}
								>
									{search}
								</Box>
							</Grid>
							: null}
						{filters?.productFilters && Object.values(filters.productFilters).length ?
							<>
								{filters.productFilters.map((button, index) => {
									return (
										<Grid item key={index}>
											<Box
												sx={{
													borderRadius: '5px',
													backgroundColor: 'cards.header',
													//marginRight : '5px', 
													padding: '5px 10px ',
													boxShadow: theme => theme.shadows[1],
													opacity: button.s ? 1 : 0.5

												}}
											>
												<Grid item container alignItems="center" spacing={1 / 2} >
													<Grid item>
														<Typography
															sx={{
																textTransform: 'capitalize'
															}}
															variant="subtitle"
															fontWeight={600}
														>{button.h} = </Typography>
														{/* <Button size="small" color="inherit">
															{button.h}:
														</Button> */}
													</Grid>
													{
														//check if value is an array
														// button.v[0] === button.v[1] //if values are the same
														!Array.isArray(button.v) ?
															<Grid item>
																<Typography variant="subtitle" >{button.v}<small>{button?.u ? button.u : ''}</small></Typography>
															</Grid>
															:
															<>
																<Grid item >
																	<Typography variant="subtitle" >{button.v[0]}<small>{button?.u ? button.u : ''}</small></Typography>
																</Grid>
																{(button.v[0] !== button.v[1]) && button.t === "ScalarNumber" ?
																	<>
																		<Grid item ><small>±</small></Grid>
																		<Grid item >
																			<Typography variant="subtitle" >{(button.v[1] - button.v[0])}<small>{button?.u ? button.u : ''}</small></Typography>
																		</Grid>
																	</>
																	: null}

																{/* {Object.values(button.v).map((value, i) => {

																return (
																<React.Fragment key={i}>
																	{button.t === "ScalarNumber" && i === 1 ?
																	<Grid item ><small>±</small></Grid>
																	: null}
																	<Grid item >
																	<Typography variant="caption" >{value}<small>{button?.u ? button.u : ''}</small></Typography>
																	</Grid>
																</React.Fragment>
																)
															})} */}
															</>}
													<Grid item>
														<IconButton
															onClick={() => toggleFilter(button)}
															edge="end" aria-label="delete"
															sx={{ width: '25px', height: '25px', marginRight: '1px' }}
														>
															<FontAwesomeIcon style={{ width: '15px', height: '15px' }} icon={button.s ? faTimes : faPlus} />
														</IconButton>
													</Grid>
												</Grid>
											</Box>
										</Grid>
									)
								}
								)}
							</>
							: null}
					</Grid >
				</Grid >
				: null}
		</>
	);
};

export default ResultToggleFilters;
