import React from 'react';
import { Box } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';

const PageContent = ({ children, background, noPadding }) => {
  return (
    <Box sx={{
      padding: noPadding ? 0 : isMobileOnly ? "0.5em" : "1em",
      width: '100%',
      background: (theme) => background ? background : theme.palette.background.main,
      position: 'relative',
      overflow: 'hidden',
    }} >
      {children}
    </Box>
  );
};

export default PageContent;
