import {
    Grid
} from '@mui/material';
import React, {
    useContext,
    useMemo,
    useState
} from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import EntryAttributeTypeSelector from '../types/Entry_AttributeTypeSelector';

function CustomEntries({ setError , edit}) {

    //****************** CONTEXT ******************
    const { addNewEntry } = useContext(SelectionsContext);
    //****************** STATE ******************
    const [attributes, setAttributes] = useState([]);
    //****************** USE EFFECT ******************

    //remove Standard Attributes
    useMemo(() => {
        const filteredAttributes = addNewEntry.company.company_attributes.filter(
            attr =>
                attr.one.attr_id !== 'ph' &&
                attr.one.attr_id !== 'conc' &&
                attr.one.attr_id !== 'tramp_oil' &&
                attr.one.attr_id !== 'bacteria' &&
                attr.one.attr_id !== 'fungi'
        );
        setAttributes(filteredAttributes);
    }, [addNewEntry.company.company_attributes]);

    //console.log(addNewEntry.company.company_attributes)

    return (
        <Grid container spacing={2}>
            {attributes.map(attr => {
                const attribute = addNewEntry.entry.attributes_new.find(
                    contextAttr => contextAttr.attribute_id === attr.one.attr_id
                );
                return (
                    <EntryAttributeTypeSelector
                        key={attr.one.attr_id}
                        attribute={attr}
                        initialValue={attribute?.attribute_values.value}
                        //dispatchFnCallback={dispatchFnCallback}
                        setError={setError}
                        edit={edit}
                    />
                );
            })}
        </Grid>
    );
}

export default CustomEntries;
