import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { UserContext } from '../../store/UserContext';
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import { CompanyContext } from '../../store/CompanyContext';
import extractGroups from '../helpers/extractGroups';
import DialogLoader from '../../reusable/DialogLoader';
import { useNavigate } from 'react-router-dom';

export default function AddMachineDialog({ isDialog, setIsDialog }) {

	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { companies, selectedCompany } = useContext(CompanyContext);
	const { updateActivityLog } = useActivityLog(null);
	const [company, setCompany] = useState(selectedCompany);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [dropDownGroup, setDropDownGroup] = useState('');
	const [options, setOptions] = useState(null);
	const [machineName, setMachineName] = useState(null);

	const closeModal = () => setIsDialog();

	//get machine entries
	const { data } = useQuery({
		queryKey: ['groups', company?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'get',
			params: {
				company_id: company?.company_id,
				allow_access: true
			}
		}),
		//check if company_id select is a number
		enabled: !!company,
		retry: false
	})

	useEffect(() => {
		if (data) {
			console.log(data)
			setOptions(extractGroups(data))
		}
	}, [data])

	const addMachine = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines',
			api_key: user.api_key,
			method: 'post',
			body: body
		}),
		onSuccess: (response) => {
			//update activity log
			updateActivityLog.mutate({
				activity_details: {
					area: "coolant",
					machine_name: machineName,
					machine_group: selectedGroup,
					company_id: selectedCompany.company_id
				},
				activity_type: "new machine"
			})
			setMachineName('')
			setSelectedGroup('')
			setDropDownGroup('')
			closeModal()
			navigate(`/coolantcare/machine/${response.insertId}`)
			//TODO refesh all companies
		}
	})

	const handleSubmit = () => {
		let body = {
			machine_name: machineName,
			machine_group: selectedGroup,
			company_id: selectedCompany.company_id
		}
		addMachine.mutate(body)
	};

	
	return (
		<GlobalDialog
			open={isDialog}
			onClose={closeModal}
			title="Add New Machine"
			buttonClick={handleSubmit}
			buttonTitle="Add Machine"
			successMessage="Machine Added Successfully!"
			fullWidth
			disabled={!machineName || !selectedGroup || !company}
			maxWidth="md"
		>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Autocomplete
						id="companies"
						disableClearable
						options={companies}
						getOptionLabel={option => option.company_name || ""}
						isOptionEqualToValue={(option, value) => option.company_id === value.company_id}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.company_id}>
									{option.company_name}
								</li>
							);
						}}
						renderInput={(params) =>
							<TextField
								{...params}
								variant="outlined"
								label={setLanguageText("Select Company")}
								required
							/>
						}
						value={company || ""}
						onChange={(event, newValue) => {
							setCompany(newValue);

						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						value={machineName || ""}
						required
						id="machine-name"
						label={setLanguageText("Machine Name")}
						variant="outlined"
						onChange={(e) => setMachineName(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						disableClearable
						id="selected-group"
						value={dropDownGroup || ""}
						options={options || []}
						getOptionLabel={option => option.group_name || ""}
						isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
						onChange={(event, newValue) => {
							setDropDownGroup(newValue);
							setSelectedGroup(newValue.group_name)
						}}
						renderInput={params => (
							<TextField {...params} variant="outlined" label={setLanguageText("Select A Group...")} />
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required
						fullWidth
						id="machine-group"
						label={setLanguageText("Or Create New Group...")}
						variant="outlined"
						value={selectedGroup || ""}
						onChange={(e) => {
							setSelectedGroup(e.target.value)
							setDropDownGroup('')
						}}
					/>
				</Grid>
			</Grid>
			{addMachine.isLoading || addMachine.isSuccess || addMachine.isError ?
				<DialogLoader
					isLoading={addMachine.isLoading}
					mutation={addMachine}
					loadingMessage="Adding New Machine..."
					successMessage="Added Machine"
					//closeDialog={closeRefreshDialog}
					fixed
				/>
				: null}
		</GlobalDialog>
	);
};

