import React, { useReducer, createContext } from 'react';
import { BasketReducer } from './BasketReducers';

export const BasketContext = createContext();

BasketContext.displayName = 'Baskets';

export const BasketProvider = ({ children }) => {

	const [basket, dispatchBasket] = useReducer( BasketReducer, {
		items: [],
		totals: [],
		basket_id:  null,
		basket_name : null,
		order_id: null,
		basket_open: false
	});
	// const [checkout, dispatchCheckout] = useReducer( BasketReducer, {
	// 	order: [],
	// });
	const contextValues = {
		basket,
		dispatchBasket
		//checkout,
		//dispatchCheckout
	};

	return (
		<BasketContext.Provider value={contextValues}>
			{children}
		</BasketContext.Provider>
	);
};
