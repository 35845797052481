
import { Alert,  Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

function ErrorMessage({ error }) {

    return (

        <Card sx={{marginTop : '1em', maxWidth: '450px'}}>
            <CardHeader
                title="  Whoops, something went wrong!"
                sx={{ background: (theme) => theme.palette.grey[200], marginBottom: '1em', paddingRight: '2em', textAlign : 'center' }} />
            <CardContent sx={{ padding: '1em', position: 'relative' }}>
                <Typography component="p" variant="h6" textAlign="center" sx={{marginBottom: '1em'}}>We are really sorry for the inconvenience.</Typography>
                <Alert
                    //variant="outlined"
                    severity="error"
                    sx={{ marginBottom: '1em' }}
                >
                    <strong>Error message</strong>: {error}.
                </Alert>
                <Typography component="p" textAlign="center" padding="1em">Our development team has been alerted to this issue and is currently working on a fix.</Typography>
            </CardContent>
        </Card>

    )
}

export default ErrorMessage;