import React, { useContext, useEffect, useState } from 'react';
import {
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import setLanguageText from '../../language/setLanguageText';
import useSignedUrl from '../../hooks/useSignedUrl';
import { useFormikContext } from 'formik';
import CustomCard from '../../reusable/CustomCard';
import { useForm } from 'react-hook-form';

export default function ShopForm() {


	const { values, touched, errors, setErrors, setValues, handleChange } = useFormikContext()


	return (

		<Grid container spacing={2}>
			<Grid item xs={12}>
				<CustomCard
					title="Dataset Details"
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="dataset"
								name="dataset"
								label="Dataset Name"
								value={values?.dataset}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.dataset && Boolean(errors.dataset)}
								helperText={touched.dataset && errors.dataset}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="supplier_code"
								name="supplier_code"
								label="Supplier Code"
								value={values?.supplier_code}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.supplier_code && Boolean(errors.supplier_code)}
								helperText={touched.supplier_code && errors.supplier_code}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="supplier_name"
								name="supplier_name"
								label="Supplier Name"
								value={values?.supplier_name}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.supplier_name && Boolean(errors.supplier_name)}
								helperText={touched.supplier_name && errors.supplier_name}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="brand_name"
								name="brand_name"
								label="Brand Name"
								value={values?.brand_name}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.brand_name && Boolean(errors.brand_name)}
								helperText={touched.brand_name && errors.brand_name}
							/>
						</Grid>
					</Grid>
				</CustomCard>
			</Grid>
			<Grid item xs={12}>
				<CustomCard
					title="ERP Details"
				>
					<Grid container item spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="erp_code"
								name="erp_code"
								label="Supplier Code"
								value={values?.erp_code}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.erp_code && Boolean(errors.erp_code)}
								helperText={touched.erp_code && errors.erp_code}
							/>
						</Grid>
					</Grid>
				</CustomCard>
			</Grid>
			<Grid item xs={12}>
				<CustomCard
					title="Shop Settings"
				>
					<Grid container item spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="brand_logo"
								name="brand_logo"
								label="Brand Logo URL"
								value={values?.brand_logo}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.brand_logo && Boolean(errors.brand_logo)}
								helperText={touched.brand_logo && errors.brand_logo}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="external_delivery"
								name="external_delivery"
								label="External Delivery"
								value={values?.external_delivery}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.external_delivery && Boolean(errors.external_delivery)}
								helperText={touched.external_delivery && errors.external_delivery}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="local_delivery"
								name="local_delivery"
								label="Local Delivery"
								value={values?.local_delivery}
								onChange={handleChange}
								fullWidth
								variant="outlined"
								error={touched.local_delivery && Boolean(errors.local_delivery)}
								helperText={touched.local_delivery && errors.local_delivery}
							/>
						</Grid>
					</Grid>
				</CustomCard>
			</Grid>
		</Grid>
	);
};
