import React, { Fragment, useEffect, useContext, useState, useMemo } from 'react';
import _ from 'lodash';
import {
    Button,
    Box,
    Typography
} from '@mui/material';
import { FormOne, FormTwo, FormThree, FormFour } from './CompanyAttributeForms';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../language/setLanguageText';
import { useMutation } from "@tanstack/react-query";
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import useActivityLog from '../../users/activity/useActivityLog';
import GlobalDialog from '../../reusable/GlobalDialog';
import { useForm, FormProvider } from 'react-hook-form';
import DialogLoader from '../../reusable/DialogLoader';

function getSteps() {
    return ['Attribute info', 'Styling rules', 'Form output', 'Chart options'];
}

function getStepContent(
    activeStep,
    currentVariables,
    isEdit
) {
    switch (activeStep) {
        case 0:
            return <FormOne {...{ isEdit }} />;
        case 1:
            return <FormTwo {...{ currentVariables }} />;
        case 2:
            return <FormThree />;
        case 3:
            return <FormFour />;
        default:
            return 'Unknown step';
    }
}

export default function EditAttributeDialog({
    currentAttributes,
    currentVariables,
    selectedAttribute,
    closeModalRefresh,
    open,
    handleClose
}) {

    const methods = useForm({ mode: 'all' });
    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const form = methods.watch();
    const { updateActivityLog } = useActivityLog();

    useEffect(() => {
        if (selectedCompany?.company_id) {
            //console.log(selectedCompany);
            //add company_id to form as string
            let id = selectedCompany?.company_id;
            methods.setValue('one.company_id', id);
        }
    }, [methods.setValue, selectedCompany.company_id]);

    useEffect(() => {
        //if attribute is set then set edit mode
        if (selectedAttribute && Object.values(selectedAttribute).length) {
            methods.reset({ ...selectedAttribute });
        }
    }, [selectedAttribute]);

    const handleNext = () => {
        let canContinue = true;

        if (_.isEmpty(methods.formState.errors)) {
            switch (activeStep) {
                case 0:
                    canContinue = true;
                    break;
                case 1:
                    canContinue = true;
                    break;
                case 2:
                    canContinue = true;
                    break;
                case 3:
                    canContinue = handleSubmit({ ...form });
                    break;
                default:
                    return 'not a valid step';
            }
            if (canContinue) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
            switch (activeStep) {
                case 1:
                    break;
                case 2:
                    break;
                case 3:
                    break;
                default:
                    return 'not a valid step';
            }
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const closeModal = () => {
        setActiveStep(0);
        handleClose();
    };

    const updateAttributes = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'company/attributes',
            api_key: user.api_key,
            method: 'post',
            body: body
        }),
        onSuccess: (data) => {
            //update activity log
            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant"
                    //attribute: 
                },
                activity_type: "machine attributes updated"
            })
            //refetch attributes
            closeModalRefresh();
            handleClose();
            //set active step to 0
            setActiveStep(0);
        }
    })

    const handleSubmit = data => {
        if (_.isEmpty(methods.formState.errors)) {

            let updatedAttributes;
            if (currentAttributes) {
                let current = [...currentAttributes];
                if (selectedAttribute) {
                    //update selected attribute
                    const index = current.findIndex(
                        e => e.one.attr_id === selectedAttribute.one.attr_id
                    );
                    //console.log(index);
                    if (index > -1) {
                        current[index] = data;
                    }
                }
                updatedAttributes = current;
            } else {
                //add first attribute
                updatedAttributes = [data];
            }
            //console.log(updatedAttributes)
            let body = {
                id: selectedCompany.company_id,
                attributes: updatedAttributes
            };

            //console.log(body)

            //update attributes
            updateAttributes.mutate(body);
        }
    };


    return (
        <FormProvider {...methods}>
            <GlobalDialog
                open={open}
                onClose={closeModal}
                fullWidth
                disabled={activeStep !== steps.length - 1}
                maxWidth="md"
                title={setLanguageText('Edit Custom Attribute')}
                customActions={
                    <>
                        {activeStep > 0 && <Button onClick={handleBack}>{setLanguageText('Back')}</Button>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!_.isEmpty(methods.formState.errors)}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </>
                }
            >
                <Box sx={{ width: '100%' }}>
                    {activeStep === steps.length ? (
                        <div>
                            <Fragment>
                                <Typography>{setLanguageText('Completed')}</Typography>
                                <Button onClick={handleReset}>{setLanguageText('Close')}</Button>
                            </Fragment>
                        </div>
                    ) : (
                        <div>
                            {getStepContent(activeStep, currentVariables, true)}
                        </div>
                    )}
                </Box>
                {updateAttributes.isLoading || updateAttributes.isSuccess || updateAttributes.isError ?
                    <DialogLoader
                        isLoading={updateAttributes.isLoading}
                        mutation={updateAttributes}
                        loadingMessage={setLanguageText("Updating Attribute")}
                        successMessage={setLanguageText("Attribute Updated")}
                        fixed
                    />
                    : null}
            </GlobalDialog >
        </FormProvider>
    );
};
