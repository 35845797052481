import {
	Alert,
	Card,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material';
import React, {
	useContext, useEffect, useState
} from 'react';
import { useMutation } from 'react-query';
import config from '../config';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from '../store/CompanyContext';
import AddKPI from './AddKPI';
import { LayoutContext } from '../store/LayoutContext';
import KPIRow from './KPIRow';

const KPI = () => {

	const { user } = useContext(UserContext)
	const { app } = useContext(LayoutContext)
	const [kpi, setKpi] = useState()
	const { selectedCompany } = useContext(CompanyContext)
	const [areaOptions, setAreaOptions] = useState([]);

	const groupOptions = [
		{ id: 1, name: 'Accounts' },
		{ id: 2, name: 'Operations' },
		{ id: 3, name: 'Engineer' }
	];

	useEffect(() => {
		if (app.menu) {
			let areas = []
			app.menu.forEach((item, i) => {
				if (!item.hidden) {
					areas.push({ 'value': i, 'name': item.buttonText })
				}
			})
			setAreaOptions(areas)
		}
	}, [app.menu])

	//gets kpi 
	const mutation = useMutation((id) => {
		//console.log(user)
		return axiosRequest({
			method: 'get',
			endpoint: 'kpi',
			gateway: config.factoryAPI.URL,
			api_key: user.api_key,
			params: {
				company: id
			}
		})
	}, {
		onSuccess: (data) => {
			//console.log(data)
			if (data && !data?.errorMessage) {
				//console.log(data)
				const kpis = data.map((item) => {
					return ({
						...item,
						"kpi_tags": item.kpi_tags ? JSON.parse(item.kpi_tags) : []
					})
				})
				setKpi(kpis)
			}
		}
	})

	useEffect(() => {
		//console.log(selectedCompany)
		mutation.mutate(selectedCompany.company_id)
	}, [selectedCompany])

	const refetchKPIs = () => {
		mutation.mutate(selectedCompany.company_id)
	}

	// const kpiresults = [
	//   { id: 1, name: 'Hours saved in', area: 'Catalogue', value: 120, description: "hours saved", type: 'int', tags: ['Operations'] },
	//   { id: 2, name: 'Machines in coolancare', area: 'Coolantcare', value: 50, description: "machines monitored", type: 'int', tags: ['Operations', 'Enginner'] },
	//   { id: 3, name: 'Money Saved', area: 'Factory', value: '£6000', description: "money saved in overheads", type: 'string', tags: ['Accounts'] },
	//   { id: 4, name: 'Machines in coolancare', area: 'Factory', value: '500T', description: "tonnes C02 offset", type: 'string', tags: ['Operations'] },
	//   { id: 5, name: 'Machines in coolancare', area: 'Factory', value: 60, description: "percent efficient", type: 'donut', tags: ['Operations'] },
	//   { id: 6, name: 'Machines in coolancare', area: 'Coolantcare', value: 3, description: "machines out of control", type: 'int', tags: ['Operations', 'Enginner'] },
	//   { id: 7, name: 'Machines in coolancare', area: 'Vend', value: 340, description: "product used through vending machines this month", type: 'machines', tags: ['Accounts'] },
	//   { id: 8, name: 'Machines in coolancare', area: 'Catalogue', value: '1.2M', description: "total products", type: 'string', tags: ['Operations', 'Enginner'] }
	// ]

	const header = ["KPI ID", "Name", "Description", "Associated Area", "Tags", "Import Type", "Global", "Chart", "Locked", "Prefix", "Actions"]

	return (
		<>
			<InnerHeaderWrap >

				<Grid item container spacing={2} justifyContent="space-between" alignItems="center">
					<Grid item>
						<AddKPI refetchKPIs={refetchKPIs} groupOptions={groupOptions} areaOptions={areaOptions} />
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid container item spacing={2} xs={12}>
					<Grid item xs={12}>
						<Card sx={{
							position: 'relative',
							width: '100%',
							display: 'flex', justifyContent: 'space-between', flexDirection: 'column', background: (theme) => theme.palette.background.default
						}} >
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead sx={{ background: (theme) => theme.palette.table.header }} >
									<TableRow sx={{ background: (theme) => theme.palette.table.header }} >
										{header.map((column, i) => (
											<TableCell
												key={i}
												sx={{
													background: (theme) => theme.palette.primary.main,
													color: (theme) => theme.palette.primary.contrastText,
												}}
											>
												{column}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{kpi && kpi.length > 0 ?
										<>
											{kpi.map((kpi, index) => (
												<KPIRow kpi={kpi} refetchKPIs={refetchKPIs} groupOptions={groupOptions} areaOptions={areaOptions} />
											))}
										</>
										:
										<Grid item xs={12}>
											<Alert severity="info">No KPIs found</Alert>
										</Grid>
									}
								</TableBody>
							</Table>
						</Card>
					</Grid>
				</Grid>
			</PageContent>
		</>
	);
}

export default KPI;
