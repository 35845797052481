import React, { useContext, useState, useEffect } from 'react';
import HorizontalChart from './charts/HorizontalChart';
import DashboardCharts from './charts/DashboardCharts';
import DropdownGroup from './Selection_Row/DropdownGroups';
import DropdownMachines from './Selection_Row/DropdownMachines';
import { Grid, useTheme, Box } from '@mui/material';
import PageContent from '../PageContent';
import ExportCharts from '../Exports/ExportCharts';
import { MachineContext } from '../store/MachineContext';
import CreateChartData from '../helpers/createChartData';
import LoadingScreen from '../reusable/LoadingScreen';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import AddMachineDialog from '../FloatingActionButton/AddMachineDialog';
import { LayoutContext } from '../../store/LayoutContext';
import { CompanyContext } from '../../store/CompanyContext';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import { UserContext } from '../../store/UserContext';
import getUserLevel from '../../helpers/getUserLevel';

export default function Dashboard() {

	const theme = useTheme();
	const { user } = useContext(UserContext);
	const {
		filteredMachines,
		selectedMachine,
		selectedGroup,
		noMachines
	} = useContext(MachineContext);
	const { language } = useContext(LayoutContext)
	const { selectedCompany } = useContext(CompanyContext);
	const [chartData, setChartData] = useState(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		// Prioritize if user select a specific machine from the Machine dropdown
		if (selectedCompany?.company_attributes && selectedCompany?.company_colour_rules) {
			if (selectedMachine?.machine_id) {
				setChartData(
					CreateChartData([selectedMachine], selectedCompany.company_attributes, theme, selectedCompany.company_colour_rules, language)
				);
				// Otherwise create chart data based on filteredMachines
			} else if (Object.values(filteredMachines).length) {
				//console.log('all machines')
				setChartData(
					CreateChartData(filteredMachines, selectedCompany.company_attributes, theme, selectedCompany.company_colour_rules, language)
				);
			} else {
				setChartData()
			}
		}
		return () => {
			//console.log('clean up')
		}
	}, [filteredMachines, selectedCompany, selectedMachine, theme]);


	return (
		<>
			<InnerHeaderWrap>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<GlobalCompanySelect addAll fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<DropdownGroup all />
					</Grid>
					<Grid item xs={12} md={3}>
						<DropdownMachines all />
					</Grid>
					<Grid item xs={12} md={2}>
						{(chartData && selectedCompany && selectedGroup) &&
							<ExportCharts
								chartData={chartData}
								selectedCompany={selectedCompany}
								selectedGroup={selectedGroup}
							/>
						}
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Box
				//sx={{ opacity: isLoading ? 0.5 : 1 }}
				>
					{noMachines ?
						<LoadingScreen
							position="relative"
							loading={false}
							text={`Company has no machines, please add a machine`}
							customAction={
								<Grid container justifyContent="space-between" alignContent="center">
									<Grid item style={{ margin: 'auto' }}>
										{getUserLevel(user.user, 'coolantcare', 'machine_add', 6) ?
											<>
												<PrimaryBtnIcon
													//disabled
													icon={faPlus}
													text="Add First Machine"
													onClick={e => setOpen(true)}
												//className={buttonStyle.danger}
												/>
												<AddMachineDialog
													isDialog={open}
													setIsDialog={setOpen}
												/>
											</>
											: null}
									</Grid>
								</Grid>
							}
						/>
						: null}

					{(chartData && Object.values(chartData).length && selectedCompany.company_attributes) ?
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<HorizontalChart chartData={chartData} attributes={selectedCompany.company_attributes} />
							</Grid>
							<Grid item xs={12}>
								<DashboardCharts chartData={chartData} attributes={selectedCompany.company_attributes} />
							</Grid>
						</Grid>
						: null}
				</Box>
			</PageContent>
		</>
	);
};
