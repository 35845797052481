import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageContent from "../coolantcare/PageContent";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import { Alert, Card, CardContent, Grid } from "@mui/material";
import AreaDetails from "../reusable/AreaDetails";
import { LayoutContext } from "../store/LayoutContext";

const Preview = () => {

    const { app } = useContext(LayoutContext);
    const [area, setArea] = useState();


    useEffect(() => {
        if (app.menu) {
            console.log(app)
            let menuItem = app.menu.find(item => item.area === app.selectedArea);
            console.log(menuItem)
            if (menuItem) setArea(menuItem);
        }
    }, [app])

    return (
        <>
            <InnerHeaderWrap>
                {area?.title} Preview
            </InnerHeaderWrap>
            <PageContent>
                <Card>
                    <CardContent>
                        {area ? <AreaDetails area={area} full /> : null}
                        <Grid sx={{ marginTop : '1em'}}>
                            <Alert severity="info">
                                for full access to this area please contact your administrator
                            </Alert>
                        </Grid>
                    </CardContent>
                </Card>
            </PageContent>
        </>
    );
}
export default Preview;