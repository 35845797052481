import React, { useContext, useEffect, useState } from 'react';
import { ProductsContext } from '../store/ProductsContext';
import { Grid, Autocomplete, TextField, Alert, Card, Button } from '@mui/material';
import PageContent from '../PageContent';
import CoolantCard from './cards/CoolantCard';
import { BrandsContext } from '../store/BrandsContext';
import { SAVE_BRANDS } from '../store/BrandsReducers';
import { CompanyContext } from '../../store/CompanyContext';
import LayoutSelect from '../reusable/LayoutSelect';
import { UserContext } from '../../store/UserContext';
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import { useQuery } from '@tanstack/react-query';
import setLanguageText from '../../language/setLanguageText';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import CardAdditives from './cards/CardAdditive';
import AddProducts from './AddProducts';
import getUserLevel from '../../helpers/getUserLevel';
import ProductsList from './ProductList';
import { useNavigate } from 'react-router-dom';
import ProductSort from './ProductSort';
import ProductCard from './cards/ProductCard';


export default function Products() {

	const { productsOptions } = useContext(ProductsContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { user } = useContext(UserContext);
	const { dispatchBrands } = useContext(BrandsContext);
	const [selectedType, setSelectedType] = useState(null);
	const [searchOptions, setSearchOptions] = useState();
	const [visibleProducts, setVisibleProducts] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const [order, setOrder] = useState('most');
	const navigate = useNavigate();

	//get brands
	const { data: brands } = useQuery({
		queryKey: ['brands', user],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'get',
		}),
		retry: false
	})

	useEffect(() => {
		if (brands) {
			dispatchBrands({
				type: SAVE_BRANDS,
				payload: brands
			});
		}
	}, [brands]);

	//get types
	const { data: types } = useQuery({
		queryKey: ['types'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants', //products
			api_key: user.api_key,
			method: 'get',
			params: {
				get_types: true
			}
		}),
		retry: false
	})

	useEffect(() => {
		if (types?.length) {
			//set to coolant type 
			setSelectedType(types[0]);
		}
	}, [types])

	//get products
	const { data, isFetching, refetch } = useQuery({
		queryKey: ['products', selectedType, selectedCompany?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				company_id: selectedCompany.company_id,
				type: selectedType.type_id
			}
		}),
		enabled: !!selectedType && !!selectedCompany?.company_id,
		retry: false
	})

	useEffect(() => {
		if (data?.length && order) {
			//set to coolant type 
			//set order
			let sorted
			console.log(order)
			if (order === 'most') {
				sorted = data.sort((a, b) => b?.machines_using_product?.length - a?.machines_using_product?.length);
			} else if (order === 'desc') {
				sorted = data.sort((a, b) => a?.product_name.localeCompare(b?.product_name));
			} else if (order === 'asc') {
				sorted = data.sort((a, b) => b?.product_name.localeCompare(a?.product_name));
			}
			setVisibleProducts(sorted);
			setSearchOptions(sorted);

		} else {

			setVisibleProducts([]);
			setSearchOptions([]);
		}

	}, [data, order])

	const onCompare = (newValue) => {
		if (!newValue.length) {
			setVisibleProducts(data);
			setSelectedProduct([]);
			return;
		}
		setVisibleProducts(newValue);
		setSelectedProduct(newValue);
	}

	return (
		<>
			<InnerHeaderWrap>
				<Grid container spacing={2} justifyContent='space-between'>
					<Grid item xs={12} md={3}>
						<GlobalCompanySelect fullWidth />
					</Grid>
					<Grid item xs={12} md={6} justifyContent="flex-end" alignItems="center" container spacing={2}>
						{getUserLevel(user.user, 'coolantcare', 'product_add', 8) ?
							<Grid item >
								<AddProducts types={types} />
							</Grid>
							: null}
						{user.user.user_permissions.administrator ?
							<Grid item >
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										navigate('/settings/company?section=2&tab=6')
									}}
								>
									Update Product Visibility
								</Button>
							</Grid>
							: null}
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid
					container
					//alignItems="stretch"
					//justifyContent="space-between"
					spacing={2}
				>
					<Grid item xs={12} md={3}>
						<Autocomplete
							disableClearable
							id="tags-outlined"
							options={types || []}
							disabled={!types?.length}
							getOptionLabel={option => option.type_name || ''}
							renderInput={params => (
								<TextField
									{...params}
									variant="outlined"
									//label={setLanguageText("Select product type")}
									sx={{
										'& input': { zIndex: 2 },
										'& legend': { display: 'none' },
										'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
										'& .MuiAutocomplete-endAdornment ': {
											zIndex: 2
										}
									}}
									placeholder={setLanguageText("Select Type")}
								/>
							)}
							onChange={(e, newValue) => setSelectedType(newValue)}
							value={selectedType}
						/>
					</Grid>
					<Grid item container xs={12} md={3} justifyContent="flex-end">
						<Autocomplete
							value={selectedProduct || []}
							fullWidth
							multiple
							id="tags-outlined"
							options={searchOptions || []}
							getOptionLabel={option => option.product_name}
							filterSelectedOptions
							renderInput={params => (
								<TextField
									{...params}
									variant="outlined"
									//label={setLanguageText("Search products")}
									sx={{
										'& input': { zIndex: 2 },
										'& legend': { display: 'none' },
										'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
										'& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag ': {
											zIndex: 2
										}
									}}
									placeholder={selectedProduct?.length ? '' : setLanguageText("Search & Compare products")}
								/>
							)}
							onChange={(e, newValue) => onCompare(newValue)}
						/>
					</Grid>
					<Grid item container xs={12} md={6} justifyContent="flex-end" spacing={2}>
						{/* <Grid item >
							<ProductSort order={order} setOrder={setOrder} />
						</Grid> */}
						<Grid item >
							<LayoutSelect />
						</Grid>
					</Grid>
				</Grid>
			</PageContent>
			<PageContent>
				<Grid container spacing={productsOptions.toggleView === 'grid' ? 2 : 0} >

					{visibleProducts && Object.values(visibleProducts).length > 0 ?
						<>
							{productsOptions.toggleView === 'grid' ?
								<>
									{visibleProducts.map((product, i) =>

										<Grid item xs={12} md={6} lg={4} xl={3} key={i}>
											{selectedType?.type_id === 1 ?
												<CoolantCard coolant={product} types={types} selectedType={selectedType} refetch={refetch}/>
												: selectedType?.type_id === 2 ?
													<CardAdditives additive={product} types={types} selectedType={selectedType} refetch={refetch}/>
													:
													<ProductCard coolant={product} types={types} selectedType={selectedType}  refetch={refetch}/>
											}
										</Grid>
									)}
								</>
								: productsOptions.toggleView === 'list' ?
									<Grid item xs={12} >
										<Grid item container spacing={2} justifyContent="space-between" alignItems="center">
											{visibleProducts.map((product, i) =>
												<Grid item xs={12} key={i} >
													<Card sx={{ padding: '1em' }}>
														<ProductsList  product={product} />
													</Card>
												</Grid>
											)}
										</Grid>
									</Grid>
									: null}
						</>
						: !isFetching ?
							<Grid item xs={12}>
								<Alert
									variation='contained'
									severity='info'
								>No <strong>{selectedType?.type_name}</strong> available for <strong>{selectedCompany?.company_name}</strong></Alert>
							</Grid>



							: null}
				</Grid>
			</PageContent>
		</>
	);
};

