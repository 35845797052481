import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import ResultButton from "../parametric/ResultButton";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import { UserContext } from "../../store/UserContext";
import { faWandMagicSparkles } from "@fortawesome/pro-light-svg-icons";
import { CategoryContext } from "../../store/CategoryContext";

const IsoAssistant = ({ field }) => {

	const [open, setOpen] = useState(false);
	//data
	const [allData, setAllData] = useState();
	//type
	const [typeID, setTypeID] = useState();
	const [selectedType, setSelectedType] = useState();
	//properties
	const [propertyID, setPropertyID] = useState();
	const [selectedProperty, setSelectedProperty] = useState();
	const { user } = useContext(UserContext);
	const { dispatchSelected } = useContext(SelectedContext);
	const { categories } = useContext(CategoryContext);
	const theme = useTheme();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		setAllData(current => {
			console.log(current)
			 const copy = [...current]
			  copy.map((item)=> 
			 	item.Properties.map(prop => {
			 		delete prop.Selected
			 		console.log(prop)
			 	})
			)
			return copy
		})
	};


	//--------------------------- API CALLS  ------------------------------//

	const { status, data, error, isFetching } = useQuery(['isoassistant', categories.currentCat.ID],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/isoassistant',
			api_key: user.api_key,
			method: 'get',
			params: {
				toolClass: categories.currentCat.ID
			}
		}), {
		enabled: !!open && !!categories.currentCat.ID,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	useEffect(() => {
		//create empty object for each
		if (data) {
			setAllData(data)
		}
	}, [data])

	//set selected type from all data
	useEffect(() => {
		if (allData && typeID) {
			console.log(allData)
			let type = allData.find(type => type.ID === typeID)

			console.log(type)

			let isoString = type.Properties.map(item => {
				console.log(item)
				if(item.Selected){
					return item.Selected 
				}
				return item.StartValue
			});
			isoString = isoString.join('')
			//console.log(isoString)
			updateISOFilters(isoString);
			setSelectedType(type);
		}
	}, [allData, typeID, setSelectedType])


	//set selected types selected values alues from all data
	useEffect(() => {
		if (selectedType && propertyID) {
			//console.log(propertyID)
			//console.log(selectedType)
			let property = selectedType.Properties.find(type => type.ID === propertyID)
			setSelectedProperty(property)
			//console.log(property)
		}
	}, [allData, selectedType, propertyID, setSelectedProperty])

	const updateISOFilters = (iso) => {
		//console.log(item)
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'STDDES', 'Values': [iso], 'Name': 'Standard designation', 'Ref': 'J11'
			}
		});
	}

	return (
		<>
			<Button
				sx={{ width: '35px', height: '35px', minWidth: 'auto', boxShadow: 'none', borderRadius: '50%' }}
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				<FontAwesomeIcon size="lg" icon={faWandMagicSparkles} />
			</Button>
			<Dialog
				open={open}
				fullWidth
				maxWidth="false"
				sx={{ height: '100%' }}
				PaperProps={{
					height: '90%'
				}}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ background: theme.palette.background.default, textAlign: 'center' }} id="alert-dialog-title">
					<Typography variant="body1" sx={{ margin: '.5em auto 1em' }} >Wizard to specify ISO.</Typography>
				</DialogTitle>

				<DialogContent sx={{ padding: '1em!important' }}>
					<Grid item container spacing={2} >
						<Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '1em' }} >
							<Typography variant="h6" >Tool type</Typography>
						</Grid>
						<Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '1em' }} >
							{data && data.map((item, i) => {
								return (
									<Button key={i}
										onClick={e => {
											setTypeID(item.ID);
										}}
										sx={{ marginRight: '1em' }} variant="contained" color={selectedType && selectedType.ID === item.ID ? 'primary' : 'secondary'}  >{item.Label}</Button>
								)
							})}
						</Grid>

						{!selectedType &&
							<Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '1em', tetAlign: 'center' }} >
								<Alert severity="info" sx={{maxWidth: '250px', margin : '0 auto'}}>Please select a tool type</Alert>
							</Grid>
						}

						<Grid container item xs={12} spacing={2} justifyContent="center" >
							{selectedType && selectedType.Properties.map((item, i) =>
								<Grid item key={i} >
									<Button size="large" variant="outlined" color={item.Selected ? 'primary' : 'secondary'} >{item.Selected ? item.Selected : item.StartValue}</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={selectedProperty?.Image ? 5 : 6}>
							{selectedType &&

								<List
									subheader={
										<ListSubheader sx={{ bgcolor: 'background.default' }} component="div" id="nested-list-subheader" disableGutters>
											<Typography sx={{ padding: '1em' }}>Standard designation</Typography>
											<Divider />
										</ListSubheader>
									}
									dense={true}
									sx={{
										margin: '1em 0',
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 425,
										border: '1px solid #ccc',
										padding: 0,
									}}
								>
									{selectedType.Properties.map((prop, i) => {
										return (
											<ListItem
												key={prop.ID}
												disablePadding
												divider
											>
												<ListItemButton
													selected={selectedProperty?.ID === prop.ID}
													onClick={(e) => {
														setPropertyID(prop.ID);
													}}
												>
													<ListItemText id={prop.ID} primary={prop.Label} />
												</ListItemButton>
											</ListItem>
										);
									})}
								</List>
							}
						</Grid>
						{selectedProperty?.Image &&
							<Grid item xs={2} sx={{ marginTop: '2em' }}>
								<img
									style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
									alt={selectedProperty.Label}
									src={selectedProperty.Image}
								/>
							</Grid>
						}
						<Grid item xs={selectedProperty?.Image ? 5 : 6}>
							{selectedProperty &&
								<List
									subheader={
										<ListSubheader sx={{ bgcolor: 'background.default', }} component="div" id="nested-list-subheader" disableGutters>
											<Typography sx={{ padding: '1em' }}>Values</Typography>
											<Divider />
										</ListSubheader>
									}
									dense={true}
									sx={{
										margin: '1em 0',
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 425,
										border: '1px solid #ccc',
										padding: 0,
									}}
								>
									{selectedProperty.Values.map((item, i) => {
										//check condition and hide				
										let hide = false
										if (item.Condition) {
											let typeIndex = allData.findIndex(type => type.ID === typeID);
											if (typeIndex > -1) {
												let field = item.Condition.split(' = ')[0];
												let match = allData[typeIndex].Properties.find(item => item.Field === field)
												if (match) {

													let array = item.Condition.split(' = ')[1].split(',');
													//console.log(array)
													//console.log(match.Selected)
													let show = array.includes(match.Selected)
													//console.log(show)
													hide = !show
												}
											}
										}
										//if(!hide){
										return (
											<ListItem
												key={item.ID}
												disablePadding
												divider
											>
												<ListItemButton
													disabled={hide}
													selected={selectedProperty.Selected === item.Value}
													onClick={() => {
														setAllData(current => {
															//console.log(current)
															const copy = [...current]
															let typeIndex = copy.findIndex(type => type.ID === typeID)
															if (typeIndex > -1) {
																//console.log(allData[typeIndex])
																//reset other first
																let propertyIndex = copy[typeIndex].Properties.findIndex(prop => prop.ID === selectedProperty.ID)
																//console.log(propertyIndex)
																if (copy[typeIndex].Properties[propertyIndex].Selected !== item.Value) {
																	copy[typeIndex].Properties[propertyIndex].Selected = item.Value
																} else {
																	delete copy[typeIndex].Properties[propertyIndex].Selected
																}
																//console.log(allData)
																return copy
															}
															return current
														})
													}}
												>
													<Checkbox
														edge="start"
														checked={selectedProperty.Selected === item.Value}
														tabIndex={-1}
														disableRipple
														inputProps={{ 'aria-labelledby': item.Label }}
													/>
													<ListItemText id={item.ID} primary={item.Value} secondary={item.Label} />
													{item.Image &&
														<img
															style={{ width: '100px', height: '100px', objectFit: 'contain' }}
															alt={item.Label}
															src={item.Image}
														/>
													}
												</ListItemButton>
											</ListItem>
										)
										//}
									})}
								</List>
							}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ background: theme.palette.background.default, disply: 'flex', justifyContent: 'center' }} >
					<Grid>
						<Button sx={{ marginRight: '1em' }} variant="contained" color="secondary" onClick={handleReset}>
							Reset
					</Button>
						<ResultButton />
					</Grid>

				</DialogActions>
			</Dialog>
		</>
	);
};

export default IsoAssistant;

