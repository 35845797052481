
import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, Grid, Box, IconButton, Chip } from '@mui/material';
import { useFormikContext } from "formik"
import setLanguageText from '../../../../language/setLanguageText';
import { LayoutContext } from '../../../../store/LayoutContext';

export default function File({ field, edit }) {

    const { values, setFieldValue, touched, handleChange } = useFormikContext();
    const [file, setFile] = useState();
    const [acceptedFiles, setAcceptedFiles] = useState();
    const { language } = useContext(LayoutContext);

    const uploadFile = e => {
        const file = e.target.files[0];
        setFile(file);
        setFieldValue(`uploads.[${field.name}]`, { "name": field.name, "type": field.allow, "label": field.label, "language": [language.id], "file": file });
    };

    const removeFile = () => {
        setFile(null);
        setFieldValue(`uploads.[${field.name}]`, null);
    }

    useEffect(() => {
        if (field.allow === "pdf") {
            setAcceptedFiles("application/pdf");
        }
    }, [field])

    useEffect(() => {
        if (values?.uploads?.[field.name]?.file) {
            setFile(values?.uploads?.[field.name]?.file);
        }
    }, [field, values])

    useEffect(() => {
        if (edit) {
            //console.log(field.name)
            //console.log(values[field.name])
            if (values[field.name]?.label) {
                setFile({ "name": values[field.name]?.label });
            }
        }
    }, [field, edit, values])

    return (

        <Box sx={{ border: '1px solid #ccc', padding: '1em' }}>
            <Grid item container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Typography variant='p'>
                        {setLanguageText("Upload")} {field?.label} ({field?.allow})
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        component="label"
                        color="primary"
                        disabled={!acceptedFiles}
                    >
                        {setLanguageText(!file ? "Upload" : "Change")}
                        <input
                            type="file"
                            accept={acceptedFiles}
                            onChange={uploadFile}
                            hidden
                        />
                    </Button>
                </Grid>
                {file ?
                    <Grid item xs={6}>
                        <Chip
                            size='large'
                            color="success"
                            label={file?.name}
                            onDelete={removeFile}
                        />
                    </Grid>
                    : null}
            </Grid>
        </Box>
    );
};

