import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Typography, Box } from "@mui/material"
import { faFileCircleQuestion, faFileImage, faFilePdf, faFileVideo } from "@fortawesome/pro-light-svg-icons"
import { Storage } from 'aws-amplify';
import FullScreenError from "../reusable/FullScreenError";


const FileView = ({
    request_id,
    file,
    small
}) => {

    const [errorMessage, setErrorMessage] = useState();

    const dowloadFile = async (filename) => {

        // get the signed URL string
        await Storage.get(filename, {
            download: true,
            cacheControl: 'no-cache'
        }).then(async (resp) => {
            //check if the file exsits
            //console.log(resp)
            const signedURL = await Storage.get(filename, {
                download: false,
                cacheControl: 'no-cache'
            })
            window.open(signedURL, 'download_window')

        }).catch((err) => {
            //console.log(err)
            if (err.Code === "AccessDenied") {
                setErrorMessage("This file has been marked as infected, you will not be able to download")
            } else {
                setErrorMessage(err.Code)
            }
            // handle errors 
        })
        // window.open(signedURL, 'download_window')
    }

    const IconSelect = ({ type, small }) => {
        //console.log(type)
        let icon = faFileCircleQuestion
        if (type === "image/jpeg") {
            icon = faFileImage
        } else if (type === "image/png") {
            icon = faFileImage
        } else if (type === "application/pdf") {
            icon = faFilePdf
        } else if (type === "video/mp4") {
            icon = faFileVideo
        }
        return (
            <FontAwesomeIcon icon={icon} size={small ? '2x' : '3x'} />
        )
    }


    return (
        <>
            <Grid item  >
                <Box
                    sx={{
                        position: 'relative',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '#ccc',
                        width: small ? '60px' : '120px',
                        height: small ? '60px' : '120px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        background: (theme) => theme.palette.background.main
                    }}
                    onClick={() => dowloadFile(`${request_id}/${file.name}`)}
                >
                    <Box sx={{
                        padding: small ? '13px 10px' : '35px 10px',
                        textAlign: 'center',
                        "& svg ": {
                            color: (theme) => theme.palette.secondary.main
                        }
                    }} >
                        <IconSelect type={file.type} small={small} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: small ? '60px' : '120px',
                        overflow: 'hidden',
                        paddingTop: '5px',
                        wordBreak: 'break-all'
                    }}
                >
                    <Typography variant="caption" lineHeight={1.4} component="p" textAlign="center">
                        {file.name}
                    </Typography>
                </Box>
            </Grid >
            {errorMessage && <FullScreenError errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
        </>
    )
}

export default FileView
