
import { faCube } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Avatar, Button, Dialog, DialogActions, Fade, Grid, Tooltip } from "@mui/material";
import SpinningLoader from "../../reusable/SpinnningLoader";
import { UserContext } from "../../store/UserContext";

const ProductViewer = ({ viewer, thumbnail }) => {

    const [showViewer, setShowViewer] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    // if(!user.user.user_permissions.administrator){
    //     return
    // }

    const handleClose = () => {
        setShowViewer(false)
        setIsLoaded(false)
    }


    return (
        <>
            {viewer && 
                <Tooltip title={thumbnail ? "3D Available" : "View 3D Model"} placement="bottom" arrow>
                    <Avatar
                        //variant="rounded"
                        sx={{
                            width: thumbnail ? '20px' : '50px',
                            height: thumbnail ? '20px' : '50px',
                            position: 'absolute',
                            left: thumbnail ? '5px' : '10px',
                            bottom: thumbnail ? '5px' : '10px',
                            cursor: 'pointer',
                            backgroundColor: (theme) => theme.palette.primary.main,
                            '& svg': {
                                width: thumbnail ? '10px' : '30px',
                                height: thumbnail ? '10px' : '30px'
                            }
                        }}
                        onClick={() => {
                            //only show on larg images
                            if (!thumbnail) {
                                setShowViewer(true)
                            }

                        }}
                    >
                        <FontAwesomeIcon icon={faCube} />
                    </Avatar>
                </Tooltip>
            }
            <Dialog
                fullScreen
                open={showViewer}
                onClose={() => setShowViewer(false)}
                scroll='paper'
                sx={{ margin: '5%' }}
            >
                {!isLoaded ?
                    <Grid container justifyContent="center" alignItems="center"
                        sx={{
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                            top: 0
                        }}
                    >
                        <Grid item>
                            <SpinningLoader size={100} />
                        </Grid>
                    </Grid>
                    : null}
                <Fade in={true} >
                    <iframe
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            minHeight: '800px',
                        }}
                        src={viewer}
                        onLoad={() => setIsLoaded(true)}
                    />
                </Fade>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default ProductViewer