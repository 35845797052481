import { Alert, Button, Dialog, DialogActions, DialogContent, Grid, TextField, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import config from "../../../config";
import { BasketContext } from "../../../store/BasketContext";
import { UserContext } from "../../../store/UserContext";
import SendQuoteEmail from "../../../emails/SendQuoteEmail";
import { LayoutContext } from "../../../store/LayoutContext";
import DialogLoader from "../../../reusable/DialogLoader";
import axiosRequest from '../../../axios/axoisRequest';
import { renderToString } from 'react-dom/server';

const EmailQuote = ({ open, handleClose, email, setEmail, order }) => {

    const { basket } = useContext(BasketContext);
    const { theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme();
    const { user } = useContext(UserContext);
    const [valid, setValid] = useState(false);


    const handleChange = event => {
        const val = event.target.value;
        setEmail(val);
        let valid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(val)
        setValid(valid)
    }

    const send_email = useMutation((order) => {

        //render the email
        const emailString = renderToString(<SendQuoteEmail order={order} logo={layoutTheme.email_logo} theme={theme} />)

        let emails = [email]

        //push application request email
        if (order.request_user.email) {
            emails.push(order.request_user.email)
        }

        //send the email
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'email',
            api_key: user.api_key,
            method: 'post',
            body: {
                //email: order.request_user.request_email,
                email: emails,
                html: emailString,
                subject: 'Send Quote'
            }
        })
    }, {
        onSuccess: ({ variables }) => {
            //send email on success
            console.log(variables)
            //setLoading(false)
        }
    })

    const handleSend = (order) => {
        console.log(order)
        const fullOrder = {
            ...order,
            request_user: {
                user_id: user.user.user_id,
                user_details: user.user.user_details,
                email: user.user.email
            },
        }
        //console.log(fullOrder)
        send_email.mutate(fullOrder)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>

                <Alert severity="info" variant="filled">Please enter a valid email address you wish to send this quote to.</Alert>
                <Grid container item xs={12} justifyContent="end" alignItems="end">
                    <TextField
                        fullWidth
                        error={!valid}
                        sx={{ marginTop: '1em' }}
                        id="order-email"
                        label="Email"
                        value={email}
                        type="email"
                        onChange={handleChange}
                    />
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button disabled={!valid} variant="contained" color="primary" onClick={e => handleSend(order)}>Send Quote Email</Button>
                <Button variant="contained" color="secondary" onClick={e => handleClose()}>Cancel</Button>
            </DialogActions>
            {send_email.isLoading || send_email.isSuccess || send_email.isError ?
                <DialogLoader
                    isLoading={send_email.isLoading}
                    mutation={send_email}
                    loadingMessage="Sending Request..."
                    successMessage="Request Sent"
                    closeDialog={handleClose}
                    fixed
                />
                : null}
        </Dialog>
    );
};

export default EmailQuote;