import { darken } from "@mui/material";
import setLanguageText from "../../language/setLanguageText";
import config from "../../config";

export default function CreateChartData(machines, company_attributes, theme, company_colour_rules) {

	const getColorByStatus = (status) => {
		const rule = company_colour_rules.find(attr => parseInt(attr.value) === parseInt(status));
		return rule ? (rule.colour.hex === 'transparent' ? '' : rule.colour.hex) : '';
	};

	let name = setLanguageText('Meets All Requirements');
	let results = [{
		'id': 'totals',
		'name': name,
		'chart_type': null, //remove chart from all
		//'chart_type': config.APPNAME === "act" ? null : 'pie',
		'data': {
			'out': { 'count': 0, 'label': setLanguageText('Out Control'), 'colour': getColorByStatus(30) || theme.palette.charts.first || theme.palette.error.main  },
			'edge': { 'count': 0, 'label': setLanguageText('On Edge'), 'colour': getColorByStatus(20) || theme.palette.charts.fourth || theme.palette.warning.main },
			'in': { 'count': 0, 'label': setLanguageText('In Control'), 'colour':  getColorByStatus(10) || theme.palette.charts.third || theme.palette.success.main },
			'none': { 'count': 0, 'label': 'N/A' },
			'has_entries': { 'count': 0, 'label': setLanguageText('Entries') },
			'no_entries': { 'count': 0, 'label': setLanguageText('No Entries'), 'colour':  getColorByStatus(-10) || theme.palette.charts.second }
		}
	}];

	if (company_attributes) {
		company_attributes.forEach(attributes => {
			let data;

			if (attributes.four.attr_chart === "pie") {
				data = {
					'out': { 'count': 0, 'label': setLanguageText('Out Control'), 'colour': getColorByStatus(30) || theme.palette.charts.first || theme.palette.error.main },
					'edge': { 'count': 0, 'label': setLanguageText('On Edge'), 'colour': getColorByStatus(20) || theme.palette.charts.fourth || theme.palette.warning.main },
					'in': { 'count': 0, 'label': setLanguageText('In Control'), 'colour': getColorByStatus(10) || theme.palette.charts.third || theme.palette.success.main },
					'none': { 'count': 0, 'label': 'N/A', 'colour':  getColorByStatus(-10) || theme.palette.charts.second },
					'no_entries': { 'count': 0, 'label': setLanguageText('No Entries') }
				};

				results.push({
					'id': attributes.one.attr_id,
					'name': attributes.one.attr_name,
					'chart_type': attributes.four.attr_chart,
					'data': data
				});

			} else if (attributes.four.attr_chart === "bar") {

				if (attributes.three.attr_form_output === 'radio') {

					//this loops through the radio button colours
					data = attributes.three.attr_form_options.map((item, i) => ({
						'count': 0,
						'label': item.output,
						'value': item.value,
						//'colour': item?.colour?.hex ? item.colour.hex : null,
						'status': item?.status
					}));

				} else if (attributes.three.attr_form_output === 'dial') {

					//we create these colours 
					data = [
						{ 'count': 0, 'label': setLanguageText('Out Control'), 'status': '', 'colour': getColorByStatus(30) || theme.palette.charts.first || theme.palette.error.main },
						{ 'count': 0, 'label': setLanguageText('On Edge'), 'status': '', 'colour': getColorByStatus(20) || theme.palette.charts.second },
						{ 'count': 0, 'label': setLanguageText('In Control'), 'status': '', 'colour': getColorByStatus(10) || theme.palette.charts.third || theme.palette.success.main }
					];

				} else {

					// we need to get the coulours status by using the rules
					data = attributes.two.attr_rules.map((item, i) => ({
						'count': 0,
						'label': item.name,
						'status': item?.status,
						'colour': getColorByStatus(item?.status)

					}));
				}

				results.push({
					'id': attributes.one.attr_id,
					'name': attributes.one.attr_name,
					'chart_type': attributes.four.attr_chart,
					'data': data
				});
			}
		});
	}


	if (machines && Object.values(machines).length) {

		let total_index = results.findIndex(item => item.id === 'totals');

		machines.forEach(machine => {
			if (machine.last_entry.attributes_new) {
				if (total_index >= 0) {
					results[total_index].data.has_entries.count += 1;
					if (machine.last_entry.out_of_control) {
						results[total_index].data.out.count += 1;
					} else {
						results[total_index].data.in.count += 1;
					}
				}

				let attributes = machine.last_entry.attributes_new;
				attributes.forEach(attribute => {

					let attr_index = results.findIndex(item => item.id === attribute.attribute_id);

					if (results[attr_index]?.chart_type === "pie") {
						if (results[attr_index].data.out) {
							if (attribute.attribute_values.status === 30) {
								results[attr_index].data.out.count += 1;
							} else if (attribute.attribute_values.status === 20) {
								results[attr_index].data.edge.count += 1;
							} else if (attribute.attribute_values.status === 10) {
								results[attr_index].data.in.count += 1;
							} else {
								results[attr_index].data.none.count += 1;
							}
						} else {
							let data_index = results[attr_index].data.findIndex(item => String(item.value) === String(attribute.attribute_values.value));
							results[attr_index].data[data_index].count += 1;
						}

					} else if (results[attr_index]?.chart_type === "bar") {
						let data_index = results[attr_index].data.findIndex(data => String(data.value) === String(attribute.attribute_values.value));
						if (data_index >= 0) {
							results[attr_index].data[data_index].count += 1;
						} else {
							data_index = results[attr_index].data.findIndex(data => String(data.status) === String(attribute.attribute_values.status));
							if (data_index >= 0) {
								results[attr_index].data[data_index].count += 1;
							}
						}
						if (results[attr_index].data[data_index]) {
							console.log(attribute?.attribute_values?.status)
							//check if status is a integer
							if (Number.isInteger(attribute?.attribute_values?.status)) {
								//console.log(results[attr_index].data[data_index])
								results[attr_index].data[data_index].status = attribute?.attribute_values?.status;
								results[attr_index].data[data_index].colour = getColorByStatus(attribute?.attribute_values?.status);

							}
						}
					}
				});

			} else {
				if (total_index >= 0) {
					results[total_index].data.no_entries.count += 1;
				}
			}
		});
	}


	console.log('results', results)
	return results;
}
