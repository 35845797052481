import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { renderToString } from 'react-dom/server';
const NewComment = ({ comment, theme }) => {
 //console.log(comment)

    //'comment':{
    // comment_request_id: 
    // comment_submit_date: 
    // comment_details:
    // comment_files: 
    // comment_user: {
    //   user_id: 
    //   user_details: 
    //   email: 
    // }
    //},
    // 'request' : request}

    return (
        <div style={{ backgroundColor: '#fff', padding: '2em', width: '100%', maxWidth: '800px', display: 'table' }} >
            <div style={{ backgroundColor: '#fff', width: '100%', textAlign: 'center', color: '#333' }} >
            <img
                src={theme?.email_logo}
                alt="logo"
              />
            </div>
            <table style={{ width: '100%', maxWidth: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <thead style={{ backgroundColor: '#fff', padding: '2em', textAlign: 'center', color: '#333' }}>
                    <th>
                        <h2>New Comment Added</h2>
                    </th>
                </thead>
                <tbody style={{ backgroundColor: 'rgb(238, 238, 238)', boxShadow: '0 0 5px #999' }} >
                    <tr><td valign="top" align="left" colSpan="2">
                        <p>Added by <strong>{comment?.comment?.comment_user?.user_details.name} {comment?.comment?.comment_user?.user_details?.surname ? comment?.comment?.comment_user.user_details.surname : "Bob"}</strong> on <strong>{comment?.comment?.comment_submit_date ? new Date(comment.comment.comment_submit_date).toLocaleDateString() : new Date().toLocaleDateString()} {comment?.comment?.comment_submit_date ? new Date(comment.comment.comment_submit_date).toLocaleTimeString() : new Date().toLocaleTimeString()}</strong></p>
                        <p>Company <strong>{comment?.request?.request_company?.company_name ? comment.request.request_company.company_name : 'Company name'}</strong></p>
                    </td>
                    </tr>
                    <tr>
                        <td valign="top" align="left" colSpan="2"> <p style={{ color: '#333' }} >{comment?.comment?.comment_details ? comment.comment.comment_details : 'No Comment details'}</p></td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ borderRadius: '4px', padding: '10px', backgroundColor: "#949292", float: 'left' }}>
                                <FontAwesomeIcon style={{ marginRight: '10px', width: '20px', height: '20px', color: '#fff', float: 'left' }} icon={faMagnifyingGlass} />
                                <a style={{ textDecoration: 'none', color: '#fff', width: 'auto' }} href={`${window.location.origin}/fixtures/view?request=${comment?.request?.request_id}&company=${comment?.request?.request_company?.company_id}`} target="_blank" rel="noreferrer">
                                    View Latest Comment
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table >
        </div >
    )
};

export default NewComment;  