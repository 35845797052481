import React, { useEffect, useState } from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';

const options = [
  { value: 0, name: 'All Files' },
  { value: 3, name: 'Announcements' },
  { value: 4, name: 'Promotions' },
  { value: 1, name: 'PDFs' },
  { value: 2, name: 'Videos' }
];


const FileTypes = ({
  fileTypeValue,
  setFileTypeValue
}) => {

  const [selected, setSelected] = useState();

  useEffect(() => {
      setSelected(options.find((item) => item.value === fileTypeValue))
  }, [fileTypeValue])

  const updateSelected = (event, value) => {
    setSelected(value);
    setFileTypeValue(value.value);
  };

  return (
    <Grid
      item
      container
      justifyContent="flex-end"
    >
      {selected ?
        <Autocomplete
          sx={{ minWidth: "300px" }}
          disableClearable
          id="selected-machine"
          value={selected}
          options={options || []}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) =>
            option.value === value.value
          }
          onChange={updateSelected}
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Resource Type" />
          )}

        />
        : null}

      {/* <ButtonGroup >
        {fileTypes.map(type => {
          return (
            <Button
              disabled={edit}
              sx={{
                '& .MuiButton-startIcon': {
                  'pointerEvents': 'none'
                }
              }}
              key={type.name}
              variant="contained"
              color={fileTypeValue === type.value ? 'primary' : 'secondary'}
              value={type.value}
              onClick={handleFileTypeChange}
              startIcon={type.value > 0 && (
                <FontAwesomeIcon
                  style={{ 'pointerEvents': 'none' }}
                  //size="lg"
                  icon={
                    type.value === 1
                      ? faFilePdf
                      : type.value === 2
                        ? faVideo
                        : null
                  }
                />
              )}
            >
              {type.name}
            </Button>
          );
        })}
      </ButtonGroup> */}
    </Grid>
  );
};

export default FileTypes;
