import React, { useContext } from "react";
import { Switch } from "@mui/material";
import config from "../../../config";
import axiosRequest from "../../../axios/axoisRequest";
import { useMutation } from "@tanstack/react-query";
import { UserContext } from "../../../store/UserContext";

const CompanyEnable = ({ row, table }) => {

    const { user } = useContext(UserContext)

    //disable company
    const disabled_company = useMutation((company_id) => {
        return axiosRequest({
            endpoint: 'company/settings',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            method: 'patch',
            body: {
                company_disabled: true,
                company_id: company_id,
            }
        })
    })
    const enable_company = useMutation((company_id) => {
        return axiosRequest({
            endpoint: 'company/settings',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            method: 'patch',
            body: {
                company_enable: true,
                company_id: company_id,
            }
        })
    })
    //console.log(row.original)
    return (
        <>
            <Switch
                checked={row?.original?.company_disabled}
                onChange={() => {
                    if (row?.original?.company_disabled) {
                        table.options.meta?.updateData(row.index, 'company_disabled', null)
                        //console.log(row.original.company_id)
                        enable_company.mutate(row.original.company_id)
                    } else {
                        //console.log(row.original.company_id)
                        table.options.meta?.updateData(row.index, 'company_disabled', 1)
                        disabled_company.mutate(row.original.company_id)
                    }
                }}
                color="default"
            />
        </>
    );
};

export default CompanyEnable;