import React, { useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import {
	IS_OUT_OF_CONTROL,
	OUT_OF_CONTROL
} from '../../store/MachineReducers';
import { MachineContext } from '../../store/MachineContext';
import { LayoutContext } from '../../../store/LayoutContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../../language/setLanguageText';

const OutOfControl = () => {
	const {
		dispatchIsOutOfControl,
		dispatchOutOfControl,
		isOutOfControl,
		filteredMachines,
		outOfControl
	} = useContext(MachineContext);

	const { language } = useContext(LayoutContext);

	useEffect(() => {
		// set out of control button to false
		dispatchIsOutOfControl({
			type: IS_OUT_OF_CONTROL,
			payload: false
		});
		dispatchOutOfControl({
			type: OUT_OF_CONTROL,
			payload: filteredMachines.filter(machine => machine.last_entry.out_of_control)
		});
	}, [dispatchIsOutOfControl, dispatchOutOfControl, filteredMachines]);

	const toggleOutOfControl = () => {
		dispatchIsOutOfControl({
			type: IS_OUT_OF_CONTROL,
			payload: !isOutOfControl
		});
	};


	return (
		<Button
			color={!isOutOfControl ? 'error' : 'success'}
			variant="contained"
			onClick={toggleOutOfControl}
			sx={{ height: '100%' }}
			//size="large"
			startIcon={<FontAwesomeIcon icon={faExclamationTriangle} />}
		>
			{!isOutOfControl ? `${setLanguageText("Out of Control")} (${outOfControl.length})` : `${setLanguageText("Show All")} (${filteredMachines.length}) `}
		</Button>
	);
};

export default OutOfControl;
