
import translations from './translations';
import config from '../config';

const setLanguageText = (string) => {
    //console.log(config.LANGUAGE)
    let lan = config.LANGUAGE
    if( sessionStorage.getItem('language')){
        lan = JSON.parse(sessionStorage.getItem('language'));
    }
   
    if (lan.id !== 'en') {
        let translation = translations[lan.id];
        if (translation && translation[string]) {
            return translation[string];
        }
    }
    return string;
};


export default setLanguageText;
