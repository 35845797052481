import React, {
	useContext
} from "react";
import ProductTable from "../../products/cim/ProductTable";
import ProductGrid from "./ProductGrid";
import { LayoutContext } from "../../store/LayoutContext";

const ProductLayout = ({ isFetching, attributes }) => {
	//Global state values
	const { layout } = useContext(LayoutContext);

	return (
		<>
			{layout.layout === "grid" ? (
				<ProductGrid isFetching={isFetching} attributes={attributes} />
			) : layout.layout === "engineer" ? (
				<ProductTable isFetching={isFetching} attributes={attributes} />
			) : null
			}
		</>
	);
};

export default ProductLayout;
