import React, { useContext } from 'react';
import {
	TextField,
	Select,
	MenuItem,
	Grid,
	InputLabel,
	FormControl,
	FormControlLabel,
	Button,
	Switch,
	Divider,
	Card,
	CardContent,
	CardActions,
	Chip,
	Box
} from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import setLanguageText from '../../language/setLanguageText';


export const FormOne = ({ isEdit }) => {

	const { setValue, control } = useFormContext();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<Controller
					name="one.company_id"
					control={control}
					defaultValue=""
					rules={{ required: setLanguageText('Company ID required') }}
					render={({ field: { onChange, value } }) => (
						<TextField
							fullWidth
							label={setLanguageText("Company ID")}
							variant="outlined"
							value={value}
							disabled={true}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Controller
					name="one.attr_id"
					control={control}
					defaultValue=""
					rules={{ required: setLanguageText('Attribute ID required') }}
					render={({ field: { onChange, value } }) => (
						<TextField
							fullWidth
							label={setLanguageText("Attribute ID")}
							variant="outlined"
							value={value}
							disabled={true}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			{/* This is the id for the attribute e.g conc or ph */}
			<Grid item xs={12} sm={6}>
				<Controller
					name="one.attr_name"
					control={control}
					defaultValue=""
					rules={{ required: setLanguageText('Attribute Name') }}
					render={({ field: { onChange, value } }) => (
						<TextField
							fullWidth
							label={setLanguageText("Attribute Name")}
							variant="outlined"
							value={value}
							onChange={e => {
								if (!isEdit) {
									setValue('one.attr_id', (e.target.value).replace(/\s/g, '_').toLowerCase())
								}
								onChange(e.target.value)
							}
							}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<FormControl fullWidth variant="outlined">
					<InputLabel htmlFor="attr_type_label">Value Type</InputLabel>
					<Controller
						name="one.attr_type"
						control={control}
						rules={{ required: setLanguageText('Attribute Type') }}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								onChange={onChange}
								label={setLanguageText("Attribute Type")}
								labelId="attr_type_label"
							>
								<MenuItem value="int">{setLanguageText("Number")}</MenuItem>
								<MenuItem value="str">{setLanguageText("String")}</MenuItem>
								<MenuItem value="flt">{setLanguageText("Float")}</MenuItem>
							</Select>
						)}
						defaultValue="" // make sure to set up defaultValue
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Controller
					name="one.attr_min"
					control={control}
					defaultValue=""
					//rules={{ required: 'Attribute Min' }}
					render={({ field: { onChange, value } }) => (
						<TextField
							fullWidth
							label={setLanguageText("Minimum Value")}
							variant="outlined"
							type="number"
							value={value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Controller
					name="one.attr_max"
					control={control}
					defaultValue=""
					//rules={{ required: 'Attribute Max' }}
					render={({ field: { onChange, value } }) => (
						<TextField
							fullWidth
							type="number"
							label={setLanguageText("Maximum Value")}
							variant="outlined"
							value={value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item>
				<Controller
					name="one.attr_column"
					control={control}
					render={({ field: { onChange, value } }) => {
						//console.log(value)
						return (
							<FormControlLabel
								control={
									<Switch
										onChange={onChange}
										checked={value}
									/>
								}
								label={setLanguageText("Available in Columns & Reports")}
							/>
						)
					}
					}
					defaultValue={false}
				/>
				<Controller
					name="one.attr_locked"
					control={control}
					render={({ field: { onChange, value } }) => (
						<FormControlLabel
							control={
								<Switch
									onChange={onChange}
									checked={value}
								/>
							}
							label={setLanguageText("Locked Attribute (only available to admin)")}
						/>
					)}
					defaultValue={false}
				/>
			</Grid>
		</Grid>
	);
};

export const FormTwo = ({
	currentVariables
}) => {

	const { register, control } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'two.attr_rules'
	});

	return (
		<Grid item container xs={12} spacing={2}>
			{fields.map((item, index) => {
				//pass field name to child components
				const fieldName = `two.attr_rules[${index}]`;

				return (
					<Grid container item key={item.id}>
						<Card variant="outlined">
							<CardContent>
								<Grid container item spacing={2} xs={12}>
									<Grid item xs={4}>
										<Controller
											name={`two.attr_rules[${index}].name`}
											control={control}
											defaultValue={item.name}
											//rules={{ required: 'Attribute Max' }}
											render={({ field: { onChange, value } }) => (
												<TextField
													fullWidth
													label={setLanguageText("Rule Name")}
													variant="outlined"
													value={value}
													onChange={onChange}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={4}>
										<Controller
											name={`two.attr_rules[${index}].output`}
											control={control}
											defaultValue={item.output}
											//rules={{ required: 'Attribute Max' }}
											render={({ field: { onChange, value } }) => (
												<TextField
													fullWidth
													label={setLanguageText("Rule Output Value")}
													variant="outlined"
													value={value}
													onChange={onChange}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth variant="outlined">
											<InputLabel htmlFor="attr_status_label">{setLanguageText("State/Colour")}</InputLabel>
											<Controller
												name={`two.attr_rules[${index}].status`}
												control={control}
												rules={{ required: 'Status Required' }}
												render={({ field: { onChange, value } }) => (
													<Select
														value={value}
														onChange={onChange}
														label={setLanguageText("Status")}
														labelId="attr_status_label"
													>
														{currentVariables &&
															currentVariables.map(item => {
																//console.log(item)
																return (
																	<MenuItem key={item.value} value={item.value}>
																		<Chip
																			icon={null}
																			style={{
																				marginLeft: '10px',
																				backgroundColor: item.colour
																					? item.colour.hex
																					: 'transparent'
																			}}
																			label={<span>{item.name}</span>}
																		/>
																	</MenuItem>
																);
															})}
													</Select>
												)}
												defaultValue={item.status} // make sure to set up defaultValue
											/>
										</FormControl>
									</Grid>
									<LogicForm
										register={register}
										control={control}
										fieldName={fieldName}
									/>
								</Grid>
							</CardContent>
							<CardActions>
								<Grid item>
									<Button
										variant="contained"
										color="secondary"
										onClick={() => remove(index)}
									>
										{setLanguageText("Remove Rule")}
									</Button>
								</Grid>
							</CardActions>
						</Card>
					</Grid>
				);
			})}
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						append({
							name: setLanguageText("rule name"),
							label: setLanguageText("rule label"),
							colour: setLanguageText("rule colour")
						})
					}
				>
					{setLanguageText("Add Rule")}
				</Button>
			</Grid>
		</Grid>
	);
};

export const FormThree = ({ compiledForm }) => {

	const { watch, control } = useFormContext();
	const watchFields = watch(['three.attr_form_output']);
	//console.log(watchFields)

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'three.attr_form_options'
		// this is an array of objects {title: '', id: ''}
	});

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl fullWidth variant="outlined">
						<InputLabel htmlFor="attr_form_output-label">Form Output Type</InputLabel>
						<Controller
							name="three.attr_form_output"
							control={control}
							rules={{ required: 'Form Output Type Required' }}
							render={({ field: { onChange, value } }) => (
								<Select
									value={value}
									onChange={onChange}
									label={setLanguageText("Form Output Type")}
									labelId="attr_form_output-label"
								>
									<MenuItem value="number">{setLanguageText("Number Field")}</MenuItem>
									<MenuItem value="radio">{setLanguageText("Radio Select")}</MenuItem>
									<MenuItem value="dial">{setLanguageText("Dial")}</MenuItem>
								</Select>
							)}
							defaultValue="" // make sure to set up defaultValue
						/>
					</FormControl>
				</Grid>
				{watchFields &&
					watchFields[0] === 'radio' && (
						<Grid container item spacing={2} alignContent="center">
							{fields.map((item, index) => {
								//const LogicName = `logic[${index}]`;
								return (
									<Grid key={item.id} item >
										<Card variant="outlined">
											<CardContent>
												<Grid container item spacing={2}>
													<Grid item xs={6}>
														<Controller
															name={`three.attr_form_options[${index}].output`}
															control={control}
															defaultValue={item.output}
															//rules={{ required: 'Attribute Max' }}
															render={({ field: { onChange, value } }) => (
																<TextField
																	fullWidth
																	label={setLanguageText("Label")}
																	variant="outlined"
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
													</Grid>
													<Grid item xs={6}>
														<Controller
															name={`three.attr_form_options[${index}].value`}
															control={control}
															defaultValue={item.value}
															//rules={{ required: 'Attribute Max' }}
															render={({ field: { onChange, value } }) => (
																<TextField
																	fullWidth
																	label={setLanguageText("Value")}
																	variant="outlined"
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
													</Grid>
													<Grid item xs={6}>
														<Controller
															name={`three.attr_form_options[${index}].tooltip`}
															control={control}
															defaultValue={item.tooltip}
															//rules={{ required: 'Attribute Max' }}
															render={({ field: { onChange, value } }) => (
																<TextField
																	fullWidth
																	multiline={true}
																	label={setLanguageText("Tooltip")}
																	variant="outlined"
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
													</Grid>
													<Grid item xs={6}>
														<FormControl fullWidth variant="outlined">
															<InputLabel htmlFor="attr_form_output-severity">Tooltip severity Level</InputLabel>
															<Controller
																name={`three.attr_form_options[${index}].severity`}
																control={control}
																//rules={{ required: 'Form Output Type Required' }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		value={value}
																		onChange={onChange}
																		label={setLanguageText("Tooltip severity Level")}
																		labelId="attr_form_output-severity"
																	>
																		<MenuItem value="success">{setLanguageText("Low")}</MenuItem>
																		<MenuItem value="warning">{setLanguageText("Medium")}</MenuItem>
																		<MenuItem value="error">{setLanguageText("High")}</MenuItem>
																	</Select>
																)}
																defaultValue={item.severity} // make sure to set up defaultValue
															/>
														</FormControl>
													</Grid>
													{/* <Grid item xs={12} container spacing={2}>
														<Grid item >Bar Colour</Grid>
														<Grid item>
															<Controller
																name={`three.attr_form_options[${index}].colour`}
																control={control}
																defaultValue={item.colour}
																//rules={{ required: 'Attribute Max' }}
																render={({ field: { onChange, value } }) => (
																	// <TextField
																	// 	fullWidth
																	// 	multiline={true}
																	// 	label={setLanguageText("Colour")}
																	// 	variant="outlined"
																	// 	value={value}
																	// 	onChange={onChange}
																	// />
																	<CirclePicker
																		color={value}
																		onChange={onChange}
																		colors={[
																			//'#D0021B',
																			'#fc466f',
																			'#ffc500',
																			// '#ffd686',
																			// '#8B572A',
																			// '#7ED321',
																			 '#4caf50',
																			// '#BD10E0',
																			// '#9013FE',
																			// '#4A90E2',
																			// '#50E3C2',
																			// '#94e58b',
																			'#00d79b',
																			// '#000000',
																			// '#4A4A4A',
																			// '#9B9B9B',
																			// '#bdbdbd',
																			'transparent'
																		]}
																	/>
																	// <ColourPicker 
																	// 	name="Bar colour"
																	// 	value={value}
																	// 	handleChange={onChange} 
																	// />
																)}
															/>

														</Grid>
													</Grid> */}
												</Grid>
											</CardContent>
											<CardActions>
												<Grid item>
													<Button
														variant="contained"
														color="secondary"
														onClick={() => remove(index)}
													>
														{setLanguageText("Remove Option")}
													</Button>
												</Grid>
											</CardActions>
										</Card>
									</Grid>
								);
							})}
							<Grid container item>
								<Button
									variant="contained"
									color="primary"
									onClick={() => append({ label: '', value: '' })}
								>
									{setLanguageText("Add Option")}
								</Button>
								<Divider />
							</Grid>
						</Grid>
					)}
			</Grid>
		</>
	);
};

const LogicForm = ({ control, fieldName }) => {

	const { fields, append, remove } = useFieldArray({
		control,
		name: `${fieldName}.logic`
	});

	return (
		<Grid container item xs={12} spacing={2}>
			{fields.map((item, index) => {
				//const LogicName = `logic[${index}]`;
				return (
					<Grid key={item.id} container item xs={12} spacing={2}>
						<Grid item xs={3}>
							<FormControl fullWidth variant="outlined">
								<InputLabel htmlFor="operator-label">{setLanguageText("State/Colour")}</InputLabel>
								<Controller
									name={`${fieldName}.logic[${index}].operator`}
									control={control}
									//rules={{ required: 'Required' }}
									render={({ field: { onChange, value } }) => (
										<Select
											value={value}
											onChange={onChange}
											label={setLanguageText("Operator")}
											labelId="operator-label"
										>
											<MenuItem value=">">{setLanguageText("Greater than")}</MenuItem>
											<MenuItem value=">=">{setLanguageText("Greater than & Equal to")}</MenuItem>
											<MenuItem value="==">{setLanguageText("Equal to")}</MenuItem>
											<MenuItem value="<">{setLanguageText("Lower than")}</MenuItem>
											<MenuItem value="<=">{setLanguageText("Lower than & Equal to")}</MenuItem>
										</Select>
									)}
									defaultValue={item.operator} // make sure to set up defaultValue
								/>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<Controller
								name={`${fieldName}.logic[${index}].value`}
								control={control}
								defaultValue={item.value}
								//rules={{ required: 'Attribute Max' }}
								render={({ field: { onChange, value } }) => (
									<TextField
										fullWidth
										label={setLanguageText("Value")}
										variant="outlined"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth variant="outlined">
								<InputLabel htmlFor="connection-label">Connection</InputLabel>
								<Controller
									name={`${fieldName}.logic[${index}].connection`}
									control={control}
									//rules={{ required: 'Required' }}
									render={({ field: { onChange, value } }) => (
										<Select
											value={value}
											onChange={onChange}
											label={setLanguageText("Connection")}
											labelId="connection-label"
										>
											<MenuItem value="&&">{setLanguageText("AND")}</MenuItem>
											<MenuItem value="||">{setLanguageText("OR")}</MenuItem>
											<MenuItem value="">{setLanguageText("NONE")}</MenuItem>
										</Select>
									)}
									defaultValue={item.connection} // make sure to set up defaultValue
								/>
							</FormControl>
						</Grid>
						<Grid item xs={3}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => remove(index)}
							>
								{setLanguageText("Remove Logic")}
							</Button>
						</Grid>
					</Grid>
				);
			})}
			<Grid container item>
				<Button
					variant="contained"
					color="primary"
					onClick={() => append({ operator: '', value: '', connection: '' })}
				>
					{setLanguageText("Add Logic")}
				</Button>
				<Divider />
			</Grid>
		</Grid>
	);
};


export const FormFour = () => {

	const { control } = useFormContext();


	return (

		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FormControl fullWidth variant="outlined">
					<InputLabel htmlFor="attr_chart_label">{setLanguageText("Chart Type")}</InputLabel>
					<Controller
						name="four.attr_chart"
						control={control}
						rules={{ required: 'Chart Type Required' }}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								onChange={onChange}
								label={setLanguageText("Chart Type")}
								labelId="attr_chart_label"
							>
								<MenuItem value="pie">{setLanguageText("Pie")}</MenuItem>
								<MenuItem value="bar">{setLanguageText("Bar")}</MenuItem>

							</Select>
						)}
						defaultValue="" // make sure to set up defaultValue
					/>
				</FormControl>
			</Grid>
		</Grid>

	);
};
