
import React from 'react';
import { Typography, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from "formik"
import setLanguageText from '../../../../language/setLanguageText';

export default function Select({ field }) {

    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();

    return (
        <Autocomplete
            fullWidth
            id={field?.name}
            label={setLanguageText(field?.label)}
            options={field.options || []}
            value={values?.[field.name] !== "" ? values?.[field.name] : null}
            onChange={(e, value) => {
                setFieldValue(field.name, value);
            }}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
                <TextField
                    {...params}
                    required={field?.required}
                    variant="outlined"
                    placeholder={setLanguageText(field?.label)}
                    label={setLanguageText(field?.label)}
                />
            )}
            sx={{ width: 300 }}
            disableClearable
        />
    );
};

