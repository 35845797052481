import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { faCheckCircle, faKeyboard } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../Hooks/useAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyContext } from '../../store/CompanyContext';
import config from '../../config';
import { dipslideQRCodeCreater } from '../helpers/dipslideQRCodeCreater';
import setLanguageText from '../../language/setLanguageText';
import GlobalDialog from '../../reusable/GlobalDialog';

const useStyles = makeStyles(theme => ({
    card: { height: '100%', width: '100%' },
    cardContainer: { height: '100%' },
    cardContent: {
        paddingTop: 0,
        flex: 1
    },
    tab: {
        minWidth: 'auto'
    },
    li: {
        display: 'inline'
    },
    cardFooter: {
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.background.light}`,
        background: `${theme.palette.background.light}`
    },
    dialog: {
        height: 'calc(100% - 64px)'
    },
    leftButton: {
        paddingLeft: '4px'
    },
    rightButton: {
        paddingRight: '4px'
    },
    button: {
        padding: theme.spacing(2)
    }
}));

const ManualInputs = ({ qrDetails }) => {

    const { user } = useContext(UserContext);
    const { companies, selectedCompany } = useContext(CompanyContext);
    const [QRCode, setQRCode] = useState();
    const [QRError, setQRError] = useState();
    const [textQRCode, setTextQRCode] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const {
        error,
        data,
        loading,
        executeAsync,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });


    const checkCode = async (fullCode) => {
        await executeAsync({
            endpoint: 'qr',
            api_key: user.api_key,
            method: 'post',
            body: {
                code: fullCode,
                entry: true
            }
        });
    }

    //update text box
    const updateCode = (e) => {
        e.preventDefault();
        if (e.target.value.length <= 12) {
            setTextQRCode(e.target.value.toUpperCase())
        }
    }

    useEffect(() => {
        if (textQRCode.length === 12) {
            setQRCode({ app: config.APPNAME, code: textQRCode })
        }
    }, [textQRCode]);

    //fecth linked entry
    const getLinkedEntry = useCallback((e) => {
        e.preventDefault();
        if (QRCode) {
            //console.log(QRCode);
            const ordered = Object.fromEntries(Object.entries(QRCode).sort())
            checkCode(ordered);
        }

    }, [QRCode]);

    //remove all
    useEffect(() => {
        if (data) {
            //console.log(allCompanies)
            if (data[0].entry_id) {
                setQRError();
                qrDetails(data)
            } else {
                setQRError({ message: 'Not a QR code' });
                setData();
            }
        }
    }, [
        data,
        setData,
        qrDetails
    ]);


    //close and reset
    const closeDialog = () => {
        setData();
        setQRError();
        setQRCode();
        setTextQRCode('');
        setOpen(false);
    }

    return (
        <>

            <Button
                variant="contained"
                color="secondary"
                onClick={e => {
                    setOpen(true)
                }}
                startIcon={<FontAwesomeIcon icon={faKeyboard} size="2x" />}
            >
                {setLanguageText("Manual Input")}
            </Button>

            <GlobalDialog
                open={open}
                onClose={closeDialog}
                title="Manual QR input"
                buttonClick={e => getLinkedEntry(e)}
                buttonTitle="Go"
                successMessage="Success!"
                loading={loading}
                error={error || QRError}
                data={data && data.length ? data : null}
                fullWidth
                maxWidth="sm"
            >
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={setLanguageText("12 character code")}
                            fullWidth
                            value={textQRCode}
                            variant="outlined"
                            placeholder={"12 character code"}
                            //helperText={'Scan QR code or type in 12 character code'}
                            onChange={e => updateCode(e)}
                            InputProps={{
                                maxLength: 12,
                                endAdornment: textQRCode.length === 12 ? (
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            className={classes.sign_check}
                                            size="lg"
                                        />
                                    </InputAdornment>
                                ) :
                                    <InputAdornment position="end">
                                        <small>({12 - textQRCode.length} left)</small>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>

                    {QRCode &&
                        <Grid item xs={12}  >
                            <img src={dipslideQRCodeCreater(QRCode)} />
                        </Grid>
                    }
                </Grid>
            </GlobalDialog>
        </>
    );
};



export default ManualInputs;
