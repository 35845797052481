const searchString = (string) => {

    if(!string) return;

    console.log(string);

    //replace hyphen with underscore but only if there is no space before or after
    let searchString = string.replaceAll(/(?<!\s)-(?!\s)/g, '_');
    //let searchString = string.replaceAll('-', '_');

    //check if words are on 2 letters and add an underscore to the end
    searchString = searchString.replaceAll(/\b\w{1,2}\b/g, '$&_');

    //console.log(searchString);
    //add wildcard to every word
    //let searchString = string.replaceAll(' ', '* ');
    //searchString += '*';

    return searchString;

}
export default searchString;

