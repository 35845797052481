import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import List from '@mui/material/List';
import { Box } from '@mui/system';
import { ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from '../../../store/LayoutContext';
import { UPDATE_MENU } from '../../../store/LayoutReducers';
import { useNavigate } from 'react-router-dom';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import DrawItemSub from './DrawItemSub';
import getAccessID from '../../../helpers/getAccessID';
import config from '../../../config';
const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create(['width', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create(['width', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`,
    },
});

export default function DesktopMenuSub() {

    const theme = useTheme();
    const { user } = useContext(UserContext);
    const { app, dispatchApp } = useContext(LayoutContext);
    const [subMenu, setSubMenu] = useState([])
    const [showText, setShowText] = useState()
    const [defaultOpen, setDefaultOpen] = useState(false);
    const [close, setClose] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        //update seleced areafrom url
        if (app.menu) {
            //split url into pieces
            let splitUrl = window.location.pathname.split('/')
            //use first piece as area
            let area = splitUrl[1]
            //use second piece as menu
            let menu = splitUrl[2]
            //console.log(area, menu)
            dispatchApp({
                type: UPDATE_MENU,
                payload: {
                    selectedArea: area ? area : "",
                    selectedMenu: menu ? menu : "dashboard", //if there is no menu use dashboard
                }
            })
        }
    }, [app.menu, window.location.pathname])

    useEffect(() => {
        //console.log(app)
        if (app.menu && app.selectedArea) {
            let current = app.menu.find(item => item.area === app.selectedArea)
            //console.log(current)
            if (current?.submenu) {
                let visible = current.submenu.filter((items) => !items.hidden)
                //console.log(visible)
                if (visible.length) {
                    setSubMenu(visible)
                    setClose(false)
                } else {
                    // remove sub menu
                    setSubMenu()
                    setDefaultOpen(false)
                    setClose(true)
                }
            } else {
                // remove sub menu
                setSubMenu()
                setDefaultOpen(false)
                setClose(true)
            }
        } else {
            // remove sub menu
            setSubMenu()
            setDefaultOpen(false)
            setClose(true)
        }
    }, [app])

    const handleDrawer = () => {
        //setShowText(current => !current);
        setDefaultOpen(current => !current);
    };

    useEffect(() => {
        setShowText(defaultOpen)
    }, [defaultOpen])

    const handleClose = (menuItem) => {
        navigate(menuItem.link);
    };
    //console.log("sub menu show text", showText)

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                overflowX: 'hidden',
                ...(showText && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(!showText && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
                ...(close && {
                    width: '0!important',
                    display: 'none!important',
                    '& .MuiDrawer-paper': {
                        width: 0,
                        display: 'none'
                    }
                }),
                backgroundColor: config.APPNAME === "act" ? '#C6C3C3' : '#222'
            }}
            variant="permanent"
            open={showText}
            PaperProps={{
                sx: {
                    flex: '0 0 auto',
                    backgroundColor: config.APPNAME === "act" ? '#C6C3C3' : '#222',
                    zIndex: 10,
                    overflow: 'visible!important'
                }
            }}
        >
            <Box
                sx={{
                    overflow: 'hidden',
                }} >
                <List style={{
                    marginTop: '100px',
                    width: '130%',
                    padding: '0 0 70px 0',
                    overflow: 'hidden auto',
                    height: '100%',
                    color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
                    'svg': {
                        color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
                    }
                }}>
                    {subMenu && subMenu.map((item, i) => {
                        if (user?.user?.user_permissions && (getAccessID(user.user.user_permissions[item.area]?.access) === 1 || getAccessID(user.user.user_permissions[item.area]?.access) === 2)) {
                            if (item.disabled) {
                                return (
                                    <DrawItemSub key={i} title={item.menuComponent} icon={item.icon} disabled showToolTip={true} />
                                )
                            }
                            if (item.externalLink) {
                                return (
                                    <DrawItemSub key={i} title={item.menuComponent} icon={item.icon}
                                        onClick={e => window.open(item.externalLink, '_blank')}
                                        showToolTip={true}
                                    />
                                )
                            } else {
                                return (
                                    <DrawItemSub key={i} title={item.menuComponent} icon={item.icon}
                                        onClick={e => handleClose(item)} //send full object 
                                        selected={app.selectedMenu === item.menu}
                                        showToolTip={true}
                                    />
                                )
                            }
                        }
                    })}
                    <ListItem
                        onClick={handleDrawer}
                        sx={{
                            cursor: 'pointer',
                            padding: '1em 0',
                            color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
                            'svg': {
                                color: (theme) => config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText,
                            },
                            '&:hover': {
                                background: (theme) => theme.palette.primary.main,
                            }
                        }}
                    >
                        <ListItemIcon sx={{ width: `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }} >
                            {!showText ?
                                <FontAwesomeIcon style={{ margin: '0 auto' }} icon={faChevronCircleRight} size={'sm'} />
                                :
                                <FontAwesomeIcon style={{ margin: '0 auto' }} icon={faChevronCircleLeft} size={'sm'} />
                            }
                        </ListItemIcon>
                        <ListItemText primary={setLanguageText("Collapse menu")} />
                    </ListItem>
                </List>
            </Box>
        </Drawer>

    );
};


