import React, { useEffect, useContext } from "react";
import { UserContext } from "../store/UserContext";
import { CategoryContext } from "../store/CategoryContext";
import { SelectedContext } from "../store/SelectedContext";
import { ProductsContext } from "../store/ProductsContext";
import { LayoutContext } from "../store/LayoutContext";
import { CompanyContext } from "../store/CompanyContext";
import { CLEAR_PRODUCTS, UPDATE_PRODUCTS } from "../store/ProductsReducers";
import axiosRequest from '../axios/axoisRequest';
import { useQuery } from "react-query";
import convertFiltersForAPI from "../shop/functions/convertFiltersForAPI";
import config from "../config";
import createProductObject from "../helpers/createProductObject";
import { useLocation } from "react-router-dom";
import { BrandsContext } from "../store/BrandsContext";

const useProductObject = () => {
    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext);
    const { selected } = useContext(SelectedContext);
    const { products, dispatchProducts } = useContext(ProductsContext);
    const { app, layout } = useContext(LayoutContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { brands } = useContext(BrandsContext);

    const location = useLocation();

    const fetchProducts = async (
        signal,
        page,
        limit,
        parametricFilters,
        resultFilters,
        sortOrder,
        sortColumn,
        catid,
        selectedCompany,
        location
    ) => {

        console.log("signal", signal)
        dispatchProducts({
            type: CLEAR_PRODUCTS
        });


        let filters = convertFiltersForAPI(parametricFilters);
        let search = convertFiltersForAPI(resultFilters);

        let urlParams = new URLSearchParams(location.search);
        let id = urlParams.get("product")
        let direction = urlParams.get("direction")

        if (
            !filters.find((field) => field.Field === "COMPC") &&
            selectedCompany?.company_supplier_order
        ) {
            filters.push({
                Field: "COMPC",
                Values: selectedCompany.company_supplier_order,
                DIN: "J3",
            });
        }

        let param = {
            category: catid,
            search: JSON.stringify(search),
            filters: JSON.stringify(filters),
            skip: limit * (page - 1),
            take: limit,
            ascending: sortOrder,
            orderField: sortColumn,
        };

        if (id && direction) {
            param.matchingComponentID = id;
            param.matchDirection = direction;
        }

        const res = await axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: "products",
            api_key: user.api_key,
            method: "get",
            params: param,
        });
        return res;
    };

    const {
        data,
        isFetching
    } = useQuery([
            "any_products",
            layout.page,
            products.limit,
            selected.parametricFilters,
            selected.resultFilters,
            products.sortOrder,
            products.sortColumn,
            categories.currentCat?.ID,
            selectedCompany,
            location
        ],
        ({ signal }) =>
            fetchProducts(
                signal,
                layout.page,
                products.limit,
                selected.parametricFilters,
                selected.resultFilters,
                products.sortOrder,
                products.sortColumn,
                categories.currentCat?.ID,
                selectedCompany,
                location
            ),
        {
            enabled: !!categories.currentCat?.ID,
            notifyOnChangeProps: "tracked",
            keepPreviousData: true,
            retry: 1
        }
    );

    useEffect(() => {
        const createProduct = async (products, hits) => {

            const clone = await structuredClone(products);

            const promises = clone.map(async (product) => {

                //add category image to product
                if (product?.Category) {
                    let cat = categories.flatCats.find(
                        (category) => category.ID === product.Category
                    );
                    if (cat?.Image) {
                        product.Graphics.push({
                            URL: cat.Image,
                            Type: "CategoryTechincalDiagram",
                        });
                    }
                }
                let productObject = await createProductObject([
                    {
                        ...product,
                        area: app.selectedArea
                    },
                ]);
                //add brand
                if (brands.allBrands) {
                    //add brand logo
                    let found = brands.allBrands.find(brand => brand.ID === product.CompanyCode)
                    //console.log('found', found)
                    if (found?.Icon) {
                        productObject[0].product_brand_url = found.Icon
                    }
                }
                //console.log(brands.openSearchBrands)
                let brand;
                if (productObject[0].product_cim_code) {
                    brand = brands.openSearchBrands.find(brand => brand?.supplier_code === productObject[0].product_cim_code)
                }
                if (brand) {
                    productObject[0].product_external_delivery = brand?.external_delivery ? brand?.external_delivery : ""
                    productObject[0].product_local_delivery = brand?.local_delivery ? brand?.local_delivery : ""
                }
                //console.log('productObject', productObject)
                return productObject[0];
            });

            let all = await Promise.all(promises);

            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    count: hits,
                    allProducts: all,
                    noProducts: hits,
                }
            });





        };

        //console.log(data)

        if (typeof data?.Hits === 'number' && data?.Components) {
            createProduct(data.Components, data?.Hits);
        }
    }, [data, brands.allBrands, brands.openSearchBrands]);


    // useEffect(()=>{
    //     if(companyID && brands.allBrands ) {
    //         let found = brands.allBrands.find(brand => brand.ID === CompanyCode)
    //         //console.log('found',found)
    //         if(found){
    //             setImage(found)
    //         }
    //     }
    //     return () => {
    //         setImage()
    //         setPlaceholder()
    //     }
    // },[brands.allBrands,CompanyCode])

    return { isFetching };
};

export default useProductObject;
