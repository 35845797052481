import {
  Button, Grid, Paper
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../store/UserContext';
import config from '../config';
import useActivityLog from '../users/activity/useActivityLog';
import { Formik } from 'formik';
import * as Yup from "yup"
import { useMutation } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-light-svg-icons';
import DialogLoader from '../reusable/DialogLoader';
import GlobalDialog from '../reusable/GlobalDialog';
import KPIForm from './KPIForm';

const AddKPI = ({ refetchKPIs , groupOptions, areaOptions}) => {

  const formRef = useRef();
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const resouceValidation = Yup.object({
    kpi_name: Yup
      .string('Enter the KPI name')
      .required('KPI name is required')
  });

  const newKpi = {
    //kpi_id
    "kpi_name": "",
    "kpi_area": "",
    "kpi_description": "",
    "kpi_type": "",
    "kpi_tags": [],
    "prefix": "",
    "global": "",
    "chart": ""
  }

  const {
    updateActivityLog,
    //loggingError,
    //loggingResponse
  } = useActivityLog();


  const mutation = useMutation(values => {

    //console.log(values)
    //console.log(request)
    return axiosRequest({
      endpoint: `kpi`,
      method: 'post',
      gateway: config.factoryAPI.URL,
      api_key: user.api_key,
      body: {
        "new": true,
        "data": values
      }
    })
  }, {
    onSuccess: async (response, variables) => {
      //console.log(response)
      //console.log(variables)
      //console.log(file)
      if (!response.errorMessage) {

        updateActivityLog.mutate({
          activity_details: {
            area: "factory",
          },
          activity_type: "KPI added"
        })
      }
    }
  })

  const closeModal = () => {
    setOpen(false)
    //console.log(formRef)
    formRef.current.resetForm()
  }

  //save a kpi
  const submitResource = (values) => {
    //console.log(values)
    let object = {
      "kpi_name": values.kpi_name,
      "kpi_type": values.kpi_type,
      "kpi_area": values.kpi_area,
      "kpi_description": values.kpi_description,
      "kpi_tags": values.kpi_tags,
      "kpi_settings": {
        "prefix": values.prefix,
        "global" : values.global,
        "chart" : values.chart
      }
    }
    //console.log(object)
    mutation.mutate(object)
  }

  return (
    <>
      <Button
        //fullWidth
        variant="contained"
        component="label"
        onClick={() => setOpen(true)}
        startIcon={<FontAwesomeIcon icon={faBook} />}
      >
        Add New KPI
      </Button>

      <Formik
        innerRef={current => formRef.current = current} //access methods
        initialValues={newKpi}
        validationSchema={resouceValidation}
        onSubmit={submitResource}
      >
        {({ handleSubmit, values }) => {
          //console.log(values)
          return (
            <GlobalDialog
              open={open}
              onClose={closeModal}
              title={'Add KPI'}
              buttonTitle={'Add KPI'}
              buttonClick={handleSubmit}
            //disabled={true}
            >
              <KPIForm groupOptions={groupOptions} areaOptions={areaOptions}/>
              {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                <DialogLoader
                  isLoading={mutation.isLoading}
                  mutation={mutation}
                  loadingMessage="Adding KPI..."
                  successMessage="Add KPI"
                  closeDialog={() => {
                    closeModal();
                    refetchKPIs()
                  }}
                  fixed
                />
                : null}
            </GlobalDialog>
          )
        }}
      </Formik>
    </>
  );
};

export default AddKPI;
