import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  Typography
} from '@mui/material';
import React, {
  useContext, useEffect, useState
} from 'react';
import { useQuery } from 'react-query';
import config from '../config';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import axiosRequest from '../axios/axoisRequest';
import ErrorMessage from '../shop/reusable/ErrorMessage';
import CadLayoutOptions from './CadLayoutOptions';
import { CategoryContext } from '../store/CategoryContext';
import { LayoutContext } from '../store/LayoutContext';
import { ProductsContext } from '../store/ProductsContext';
import { UPDATE_PRICES, UPDATE_PRODUCTS, UPDATE_VISIBLE } from '../store/ProductsReducers';
import { SelectedContext } from '../store/SelectedContext';
import { UserContext } from '../store/UserContext';
import CadProductLayout from './CadProductLayout';
import CadSearch from './CadSearch';

const CadCam = () => {

  const { user } = useContext(UserContext)
  const { categories, dispatchCategories } = useContext(CategoryContext);
  const { selected } = useContext(SelectedContext);
  const { products, visible, dispatchProducts } = useContext(ProductsContext);
  const { layout } = useContext(LayoutContext);
  const [price, setPrice] = useState()

  //--------------------------- ProductsAPI CALLS  ------------------------------//

  const fetchProducts = async (signal, page, limit, parametricFilters, sortOrder, sortColumn) => {
    //create copy of filters and remove reduntant one from API call
    const filters = parametricFilters.map(a => ({ ...a }));
    //console.log(filters)
    filters.map(filter => {
      if (filter.Field === "Volltext") {
        filter.Values = [encodeURI(filter.Values)] //stringify or the API won't work
      }
      delete filter.Name
      delete filter.ValueNames
      return filter
    })

    //console.log(JSON.stringify(filters))
    const res = await axiosRequest({
      signal: signal,
      gateway: config.shopAPI.URL,
      endpoint: 'products',
      api_key: user.api_key,
      method: 'get',
      params: {
        category: "Root",
        search: JSON.stringify(filters),
        skip: (limit * (page - 1)),
        take: limit,
        ascending: sortOrder,
        orderField: sortColumn
      }
    });
    return res;
  }

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,

  } = useQuery(['products', layout.page, products.limit, selected.parametricFilters, products.sortOrder, products.sortColumn], ({ signal }) =>
    fetchProducts(signal, layout.page, products.limit, selected.parametricFilters, products.sortOrder, products.sortColumn)
    , {
      enabled: !!Object.values(selected.parametricFilters).length,
      notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
      keepPreviousData: true,
      retry: 1
    })


  // const { data: prices } = useQuery(['prices', price],
  //   ({ signal }) => axiosRequest({
  //     signal: signal,
  //     gateway: config.shopAPI.URL,
  //     endpoint: 'products/price',
  //     api_key: user.api_key,
  //     method: 'post',
  //     body: {
  //       itemRefs: data.Components.map(item => item.OrderNumber)
  //     }
  //   }), {
  //   enabled: !!data && !!price
  //   //retry: 1
  // })

  //--------------------------- API CALLS  ------------------------------//

  useEffect(() => {
    //console.log(data)
    if (data?.Components) {
      dispatchProducts({
        type: UPDATE_PRODUCTS,
        payload: {
          count: data.Hits,
          allProducts: data.Components
        }
      });
      setPrice(current => current ? current + 1 : 1)
    }
  }, [data])

   //--------------------------- CALLS PROFILE TO HGET TABLE HEADERS  ------------------------------//

   const [allHeaders, setAllHeaders] = useState()
   const [visibleHeaders, setVisibleHeaders] = useState()
     
   const {  data : detailProfile } = useQuery(['tableheaders', "result_cs0"],
     ({ signal, queryKey }) => axiosRequest({
       signal: signal,
       gateway: config.shopAPI.URL,
       endpoint: 'categories/parametric',
       api_key: user.api_key,
       method: 'get',
       params: {
         form_id: "result_cs0"
       }
     }), {
     notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
     retry: 1
   })
 
     const {  data : resultProfile } = useQuery(['visibleheaders', "result_cs0"],
     ({ signal, queryKey }) => axiosRequest({
       signal: signal,
       gateway: config.shopAPI.URL,
       endpoint: 'categories/parametric',
       api_key: user.api_key,
       method: 'get',
       params: {
         form_id: "result_cs0"
       }
     }), {
     notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
     retry: 1
   })
 
   useEffect(()=>{
     if(resultProfile && detailProfile){
       setAllHeaders(detailProfile) 
       setVisibleHeaders(resultProfile) 
     }
   },[resultProfile,detailProfile])



  return (
    <>
      <InnerHeaderWrap >
        {/* <GlobalCompanySelect removeAll /> */}
      </InnerHeaderWrap>
      <PageContent>
        <Grid container item spacing={2} xs={12}>
          <CadSearch isFetching={isFetching} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} md={6} lg={9} xxl={10}>
          {visible.noProducts ? (
            <Alert variant="standard" severity="error">
              No products returned from that search term.
            </Alert>
          ) :
            <>
              {data && data.Hits > 1 &&
                <Grid item xs={12} sx={{ margin: '0 0 1em 0' }} >
                  <CadLayoutOptions isFetching={isFetching} />
                </Grid>
              }
              <CadProductLayout isFetching={isFetching}  headers={allHeaders} visible={visibleHeaders} />
              {data && data.Hits > 1 &&
                <Grid item xs={12} sx={{ margin: '0 0 1em 0' }} >
                  <CadLayoutOptions isFetching={isFetching} />
                </Grid>
              }
            </>
          }
        </Grid>
      </PageContent>
      {data?.errorMessage &&
        <ErrorMessage errorMessage={data.errorMessage} />
      }
    </>
  );
}

export default CadCam;
