import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Box, CardActions, Fade, Grid, Tooltip } from '@mui/material';
import AddToFavourites from "../favourites/AddToFavourites";
import ProductDetails from "../products/opensearch/ProductDetails";
import { faCube, faDolly, faImageSlash, faTruck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BrandLogo from "../shop/reusable/BrandLogo";
import useEnhancedCIM from "../hooks/useEnhancedCIM";
import useEnhancedEzbase from "../hooks/useEnhancedEzbase";
import SpinningLoader from "../reusable/SpinnningLoader";
import config from "../config";
import Prices from "../products/shared/Prices";
import Delivery from "../products/shared/Delivery";
import AddToBasket from "../basket/AddToBasket";


export default function FavouritesProductGrid({ product, productClick }) {

	const [selectedProduct, setSelectedProduct] = useState(product);
	const { cim_isFecthing } = useEnhancedCIM({ setSelectedProduct, product });
	const { ezbase_isFecthing } = useEnhancedEzbase({ setSelectedProduct, product });

	console.log(product)

	useEffect(() => {
		if (product) {
			setSelectedProduct(product);
		}
	}, [product])

	return (
		<>
			<Card
				sx={{
					position: 'relative',
					height: '100%',
					display: 'flex', justifyContent: 'space-between', flexDirection: 'column',
					background: (theme) => theme.palette.cards.main,
					zIndex: 0,
					cursor: 'pointer',
					'&:hover': {
						boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
						transition: 'box-shadow 0.3s ease-in-out',
					}
				}}
			>
				<CardContent
					sx={{
						background: (theme) => theme.palette.cards.main,
						width: '100%',
						flexDirection: 'row-reverse',
						display: "grid",
						height: "100%",
						alignContent: "start",
						padding: '0px',
						position: 'relative',
					}}
				>
					<Grid item
						sx={{
							position: 'absolute',
							zIndex: 10,
							top: '10px',
							left: '10px'
						}}
					>
						<AddToFavourites
							product={selectedProduct}
							isFetching={selectedProduct ? false : true}
						/>
					</Grid>

					<Box
						onClick={() => productClick(selectedProduct)}
						sx={{
							position: 'absolute',
							//width: '90px',
							height: '40px',
							overflow: 'hidden',
							top: 0,
							right: 0,
							zIndex: 3,
							borderBottom: '1px solid #eeeeee29',
							borderLeft: '1px solid #eeeeee29',
							background: '#ffffff60',
							borderRadius: '0 0 0 10px',
							cursor: 'pointer',
							textAlign: 'center',
							whiteSpace: 'nowrap',
						}}>
						{selectedProduct ?
							<Box
								sx={{
									padding: '5px',
									width: selectedProduct?.product_brand_url ? '90px' : 'auto',
									height: '40px',
								}}
							>
								<BrandLogo product={selectedProduct} />
							</Box>
							: null
						}
					</Box>

					<Box
						sx={{
							height: "140px",
							width: '100%',
							textAlign: 'center',
							background: '#fff',
							display: 'grid',
							zIndex: 0,
							position: 'relative',
						}}>
						<Box
							sx={{
								display: 'grid',
								position: 'relative',
							}}
							onClick={() => productClick(selectedProduct)}
						>
							{cim_isFecthing || ezbase_isFecthing ?
								<Grid
									container
									alignContent="center"
									justifyContent="center"
									sx={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										top: 0,
										left: 0,
										zIndex: 1
									}}>
									<Grid item xs={12} sx={{ textAlign: 'center' }}>
										<SpinningLoader size={40} grey />
									</Grid>
								</Grid>

								: selectedProduct?.product_image?.length > 0 ?
									<Fade in={true}>
										<Grid container alignContent="center" justifyContent="center">
											<Box
												onClick={() => productClick(selectedProduct)}
												sx={{
													position: 'absolute',
													width: '100%',
													height: '100%',
													top: 0,
													left: 0,
													zIndex: 2,
													padding: '1em'
												}}>

												<img
													style={{
														pointerEvents: 'none',
														width: '100%',
														height: '100%',
														objectFit: 'contain',
													}}
													src={selectedProduct.product_image[0].url}
													onError={(e) => {
														if (selectedProduct?.product_image?.[1]?.url) {
															e.target.src = selectedProduct?.product_image?.[1]?.url
														}
													}}
												// alt={selectedProduct?.product_id}
												/>
											</Box>
										</Grid>
									</Fade>
									:
									<Fade in={true}>
										<Box
											sx={{
												width: '100%',
												height: '100%',
												top: 0,
												left: 0,
												zIndex: 0,
												textAlign: 'center',
											}}>
											{selectedProduct?.product_brand_url ?
												<img
													onClick={() => productClick(selectedProduct)}
													alt={selectedProduct?.product_manufacturer}
													src={selectedProduct?.product_brand_url}
													style={{
														//grey scale	
														filter: 'grayscale(100%)',
														left: 0,
														opacity: 0.1,
														position: 'absolute',
														width: '100%', height: '100%',
														objectFit: 'contain',
														margin: '0 auto',
														padding: '1em'
													}}
												/>
												:
												<FontAwesomeIcon size={'4x'} icon={faImageSlash} color="#ccc" style={{ margin: '.45em auto' }} />
											}
										</Box>
									</Fade>}


						</Box>
						<Grid container spacing={1 / 2}
							sx={{
								position: 'absolute',
								left: '10px',
								bottom: '10px',
								zIndex: 2
							}}>
							{selectedProduct?.product_3d ?
								<Grid item >
									<Tooltip title={"3D Models Available"} placement="bottom" arrow>
										<Avatar
											//variant="rounded"
											sx={{
												width: '30px',
												height: '30px',
												float: 'left',
												cursor: 'pointer',
												backgroundColor: (theme) => theme.palette.primary.main,
												'& svg': {
													width: '18px',
													height: '18px'
												}
											}}
										>
											<FontAwesomeIcon icon={faCube} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
							{product?.product_stock > 0 ?
								<Grid item >
									<Tooltip title={`${config.APPNAME === "act" ? "ACT" : config.APPNAME === "cis" ? "CIS" : "ERP"} Stock Available`} placement="bottom" arrow>
										<Avatar
											//variant="rounded"
											sx={{
												width: '30px',
												height: '30px',
												float: 'left',
												cursor: 'pointer',
												backgroundColor: (theme) => theme.palette.primary.main,
												'& svg': {
													width: '18px',
													height: '18px'
												}
											}}
										>
											<FontAwesomeIcon icon={faDolly} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
							<Grid item >
								<Tooltip title={"Manufacturer Stock Available"} placement="bottom" arrow>
									<Avatar
										//variant="rounded"
										sx={{
											width: '30px',
											height: '30px',
											float: 'left',
											cursor: 'pointer',
											backgroundColor: (theme) => theme.palette.primary.main,
											'& svg': {
												width: '18px',
												height: '18px'
											}
										}}
									>
										<FontAwesomeIcon icon={faTruck} />
									</Avatar>
								</Tooltip>
							</Grid>

						</Grid>

					</Box>
					<Box
						sx={{
							display: 'block',
							height: '100%',
							position: 'relative',
							zIndex: 1,
							padding: '0.5em 1em',
							background: (theme) => theme.palette.cards.main,
							borderTop: theme => `4px solid ${theme.palette.primary.main}`,
						}}
						onClick={() => productClick(selectedProduct)}
					>
						<Grid item xs={12}>
							{selectedProduct?.product_number ?
								<Typography gutterBottom variant="p" fontWeight={700} component="div">
									{selectedProduct.product_number}
								</Typography>
								:
								null
							}
							<ProductDetails product={selectedProduct} isFetching={false} />
						</Grid>
						<Grid container spacing={1 / 2}
							sx={{
								//position: 'absolute',
								left: '10px',
								bottom: '10px'
							}}>
						</Grid>
					</Box>
				</CardContent>
				<CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
					<Grid container spacing={1 / 2}>
						<Grid item xs={12}>
							<Prices
								product={selectedProduct}
							/>
						</Grid>
						<Grid item xs={12}>
							<Delivery
								product={selectedProduct}
							/>
						</Grid>
						<Grid item xs={12}>
							<AddToBasket
								product={selectedProduct}
								area="opensearch"
							/>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</>
	);
}
