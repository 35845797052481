import React, { useState, useEffect } from 'react';
import config from '../../config';
import ReactPDFFallback from './ReactPDFFallback';
import useSignedUrl from '../../hooks/useSignedUrl';
import { isMobileOnly, isTablet } from 'react-device-detect';

const PDFviewer = ({ path }) => {

	const [url, setUrl] = useState();
	const { getSignedUrl } = useSignedUrl();

	useEffect(() => {
		if (path) {
			//console.log(path)
			getSignedUrl(config.s3.BUCKET, `public/${path}`).then((url) => setUrl(url))
		}
	}, [path, setUrl]);
	//console.log(url);

	return (
		<>
			{url && (
				<>
					{isMobileOnly || isTablet ?
						<ReactPDFFallback url={url} />
						:
						<object
							data={url}
							type="application/pdf"
							width="100%"
							height="100%"
						>
						</object>
					}
				</>
			)}
		</>
	);
};

export default PDFviewer;
