import React, { useContext, useEffect, useState } from "react";
import SortableTree, { getTreeFromFlatData, changeNodeAtPath, toggleExpandedForAll, addNodeUnderParent, removeNodeAtPath, getFlatDataFromTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from "../store/UserContext";
import config from "../config";
import useActivityLog from "../users/activity/useActivityLog";
import GlobalDialog from "../reusable/GlobalDialog";
import DialogLoader from "../reusable/DialogLoader";

const ResourcesSortableTree = ({ categories, setEdit, refetchCategories, open }) => {

    const { user } = useContext(UserContext);
    const [catStructured, setCatStructured] = useState();
    const [searchFocusIndex, setSearchFocusIndex] = useState();
    const [searchString, setSearchString] = useState();
    const [searchFoundCount, setSearchFoundCount] = useState();
    const [highestID, setHighestID] = useState();

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();

    useEffect(() => {
        if (categories) {
            //console.log(categories)
            let ids = []
            categories.forEach(element => {
                ids.push(element.resource_cat_id)
            });
            setHighestID(_.max(ids))
            let tree = getTreeFromFlatData({
                flatData: categories.map(node => ({ ...node, title: node.resource_cat_name, selected: false, expanded: true })),
                getKey: node => node.resource_cat_id, // resolve a node's key
                getParentKey: node => node.resource_cat_parent_id, // resolve a node's parent's key
                rootKey: 0, // The value of the parent key when there is no parent (i.e., at root level)
            });
            // resource_cat_id
            // resource_cat_name
            // resource_cat_parent_id
            // resource_cat_settings
            //console.log(tree)
            // const categoryStructured = [{
            //     "children": tree,
            //     "resource_cat_id": 0,
            //     "resource_cat_name": "Resources",
            //     "title": "Resources",
            //     selected: false,
            //     expanded: true
            // }]
            //console.log(categoryStructured)
            setCatStructured(tree)
        }
    }, [categories])

    const toggleNodeExpansion = expanded => {
        setCatStructured(defaultCats => toggleExpandedForAll({
            treeData: defaultCats,
            expanded,
        }));
    }

    const removeCat = (e, { node, path, treeIndex }) => {
        setCatStructured(defaultCats => removeNodeAtPath({
            treeData: defaultCats,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        }));
    }

    const addNewCatUnder = (e, rowInfo) => {
        //console.log(defaultCats)	
        if (rowInfo.node.resource_cat_id) {
            const NEW_NODE = {
                title: 'new category',
                resource_cat_id: highestID + 1,
                resource_cat_name: 'new category',
                resource_cat_parent_id: rowInfo.node.resource_cat_id
            };
            const newTree = addNodeUnderParent({
                treeData: catStructured,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: rowInfo ? rowInfo.treeIndex : undefined,
                getNodeKey: ({ treeIndex }) => treeIndex,
            });
            setCatStructured(newTree.treeData);
            setHighestID(highestID + 1)
        }
    }

    const customSearchMethod = ({ node, searchQuery }) => searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    const selectPrevMatch = () => {
        if (searchFocusIndex !== null) {
            setSearchFocusIndex((searchFoundCount + searchFocusIndex - 1) % searchFoundCount);
        } else {
            setSearchFocusIndex(searchFoundCount - 1);
        }
    }

    const selectNextMatch = () => {
        if (searchFocusIndex !== null) {
            setSearchFocusIndex((searchFocusIndex + 1) % searchFoundCount);
        } else {
            setSearchFocusIndex(0);
        }
    }

    const mutation = useMutation(values => {
        console.log(values)
        //console.log(request)
        return axiosRequest({
            endpoint: `resources`,
            method: 'post',
            gateway: config.factoryAPI.URL,
            api_key: user.api_key,
            body: {
                category: true,
                categories: values
            }
        })
    }, {
        onSuccess: (response, variables) => {
            //console.log(response)
            updateActivityLog.mutate({
                activity_details: {
                    area: "factory",
                },
                activity_type: "categories updated"
            })
        }
    })

    const onSave = () => {
        let newObj = []
        const flatObject = getFlatDataFromTree({
            treeData: catStructured,
            getNodeKey: ({ node }) => node.resource_cat_id, // This ensures your "id" properties are exported in the path
            ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
        }).map(({ node, path }, index) => ({
            resource_cat_id: node.resource_cat_id ? node.resource_cat_id : null,
            resource_cat_name: node.resource_cat_name,
            resource_order: index,
            resource_cat_parent_id: node.resource_cat_parent_id ? node.resource_cat_parent_id : 0,
        }));
        //console.log(flatObject)
        //push each row
        flatObject.filter((item) => {
            if (item.resource_cat_id !== null) {
                newObj.push(item)
            }
            return
        })
        //console.log(newObj)
        mutation.mutate(newObj)
        //setDefaultFlatCats(newObj);
    }

    const closeRefreshDialog = () => {
        setEdit(false)
        refetchCategories()
    }

    return (

        <GlobalDialog
            open={open}
            onClose={() => setEdit(false)}
            title="Edit Category Structure"
            buttonClick={onSave}
            buttonTitle={"Save Categories"}
            successMessage={"Categories saved successfully!"}
        >
            {/* <Grid container item spacing={2} alignItems="center">
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleNodeExpansion.bind(this, true)}
                    >
                        Expand all
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleNodeExpansion.bind(this, false)}
                    >
                        Collapse all
                    </Button>
                </Grid>
                <Grid item>
                    <input
                        type="text"
                        className="tree-search bg-light small ml-4"
                        placeholder="Search for..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setSearchString(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={selectPrevMatch}
                    >
                        Previous
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={selectNextMatch}
                    >
                        Next
                    </Button>
                </Grid>
                <Grid item>
                    <Typography>({searchFocusIndex} / {searchFoundCount})</Typography>
                </Grid> 
            </Grid>*/}
            <Grid item xs={12}>
                {catStructured ? //check for ID so seleted works
                    <SortableTree
                        isVirtualized={false}
                        treeData={catStructured}
                        onChange={treeData => {
                            setCatStructured(treeData);
                        }}
                        //onVisibilityToggle={args => recordCall('onVisibilityToggle', args)}
                        onMoveNode={args => {
                            //recordCall('onMoveNode', args);
                            const { node, path, nextParentNode } = args;
                            const getNodeKey = ({ treeIndex }) => treeIndex;

                            let parent
                            //set new depth
                            if (!nextParentNode) {
                                parent = 0
                            } else {
                                parent = nextParentNode.resource_cat_id
                            }
                            setCatStructured(defaultCats => changeNodeAtPath({
                                treeData: defaultCats,
                                path,
                                getNodeKey,
                                newNode: { ...node, resource_cat_parent_id: parent },
                            })
                            );
                        }}
                        //onDragStateChanged={args => recordCall('onDragStateChanged', args)}
                        generateNodeProps={(rowInfo) => ({
                            title: (
                                <input
                                    style={{ fontSize: '14px', fontWeight: 'normal' }}
                                    value={rowInfo.node.title}
                                    onChange={event => {
                                        const title = event.target.value;
                                        setCatStructured(defaultCats => changeNodeAtPath({
                                            treeData: defaultCats,
                                            path: rowInfo.path,
                                            getNodeKey: ({ treeIndex }) => treeIndex,
                                            newNode: { ...rowInfo.node, title: title, resource_cat_name: title },
                                        })
                                        );

                                    }}
                                />
                            ),
                            style: {
                                //backgroundColor:`#${rowInfo.node.CatOutput === 'leaf' ?'fff':'ffe1de'}`
                            },
                            buttons: [
                                <Tooltip title="Add Sub Category">
                                    <IconButton

                                        color="success"
                                        onClick={(e) => addNewCatUnder(e, rowInfo)}
                                        disabled={false}
                                    >
                                        <FontAwesomeIcon icon={faAdd} size="xs" />
                                    </IconButton>
                                </Tooltip>
                                ,
                                <Tooltip title="Delete Category">
                                    <IconButton
                                        color="error"
                                        onClick={(e) => removeCat(e, rowInfo)}
                                        disabled={false}
                                    >
                                        <FontAwesomeIcon icon={faTrash} size="xs" />
                                    </IconButton>
                                </Tooltip>
                                ,
                            ],
                        })}
                        //onlyExpandSearchedNodes
                        searchMethod={customSearchMethod}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        searchFinishCallback={(matches) => {
                            setSearchFoundCount(matches.length);
                            setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0);
                        }
                        }
                    />
                    : null}
            </Grid>
            <Grid item>
                <Alert severity="info">Edit mode, please save once you have finished or the categories will be reset</Alert>
            </Grid>
            {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                <DialogLoader
                    isLoading={mutation.isLoading}
                    mutation={mutation}
                    loadingMessage="Saving Categories..."
                    successMessage="Updating Categories"
                    closeDialog={closeRefreshDialog}
                    fixed
                />
                : null}
        </GlobalDialog>



    )
};

export default ResourcesSortableTree;
