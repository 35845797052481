import { Heading, Text, View, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { Button, Typography, Link } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import config from "../config";
import { LayoutContext } from "../store/LayoutContext";
import { isMobileOnly } from 'react-device-detect';
import Footer from "../layout/Footer";
import ACTFooter from "../layout/ACTFooter";
import setLanguageText from "../language/setLanguageText";

const components = {

  Header() {

    const { theme } = useContext(LayoutContext)

    useEffect(() => {
      if (theme) {
        //console.log(theme)
        setLogo(theme.login_logo)
      }
    }, [theme])

    const { tokens } = useTheme();
    //let app = config.APPNAME
    const [logo, setLogo] = useState('');
    const [actLogo, setActLogo] = useState('');

    import(`../images/act-logo-dark.png`).then((module) => {
      setActLogo(module.default);
    });

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {config.APPNAME === "act" && actLogo ?
          <>
            <img
              style={{ width: isMobileOnly ? '100%' : '340px' }}
              alt="My Factory"
              src={actLogo}
            />
            <Typography
              color="#222"
              variant="body"
              component="p"
              fontFamily="avenir_book"
              fontSize="1rem"
              fontWeight="600"
            >
              The Cutting Tool &amp; Lubricant Search Engine
            </Typography>
          </>
          : logo &&
          <img
            style={{ width: isMobileOnly ? '100%' : '400px' }}
            alt="Shop logo"
            src={logo}
          />
        }
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    const year = new Date();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {config.APPNAME === "act" ?
          <ACTFooter login />
          :
          <Footer white />
        }

      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={4}
          textAlign="left"
        >
          {config.APPNAME === "act" ?
            <Typography
              color="#222"
              variant="body"
              component="p"
              fontFamily="avenir_book"
            >
              {setLanguageText("Login to ")} MyFactory
            </Typography>
            :
            <Typography
              color="#222"
              variant="body"
              component="p"
            >
              {setLanguageText("Login to ")} {config.APPTITLE}
            </Typography>
          }
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      return (
        <View className="reset_password" textAlign="center" >
          <Button
            onClick={toResetPassword}
            variation="link"
          >
            Forgot your password?
          </Button>
          {config.APPNAME === "act" &&
            <Link
              sx={{
                color: (theme) => theme.palette.primary.primary,
                display: 'grid',
              }}
              title="Contact us" href="https://www.my-act.co.uk/contact-us/" rel="noreferrer" target="_blank">
              <Typography
                //color={(theme) => theme.palette.text.secondary}
                fontWeight={600}
                variant="subtitle2"
                sx={{
                  marginBottom: '10px'
                }}
              >Don&apos;t have login credentials? REQUEST HERE
              </Typography>
            </Link>

          }
        </View>
      );
    },
  },
};

export default components