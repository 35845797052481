import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Button } from '@mui/material';
import { Alert } from '@mui/material';
import setLanguageText from '../../language/setLanguageText';

const ReportCard = ({
	children,
	title,
	buttonLabel,
	disabled,
	error,
	noData,
	runReport,
	customAction
}) => {

	return (

		<Card
			sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
		>
			<CardHeader
				disableTypography
				title={title}
				sx={theme => ({
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					padding: `${theme.spacing(2)} ${theme.spacing(2)}`
				})}
			/>
			<CardContent sx={{ flex: 1 }}>{children}</CardContent>
			<Divider />
			<CardActions sx={{ textAlign: 'center', marginTop: 'auto', backgroundColor: theme => theme.palette.cards.footer }}>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						{error ? (
							<Alert severity="error">{setLanguageText(error.message)}</Alert>
						) : (
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
							>
								{noData && (
									<Grid item>
										<Alert severity="error">{setLanguageText("No Data found")}</Alert>
									</Grid>
								)}
							</Grid>
						)}
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={runReport}
							disabled={disabled}
						>
							{buttonLabel}
						</Button>
					</Grid>
					{customAction && <Grid item>{customAction}</Grid>}
				</Grid>
			</CardActions>
		</Card>

	);
};

export default ReportCard;
