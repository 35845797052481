import React, { Fragment, useContext } from 'react';
import { Typography, Grid, Alert } from '@mui/material';;
import formatDate from '../../helpers/formatDate';
import calcDaysPast from '../../helpers/calcDaysPast';
import CreateChip from '../../reusable/CreateChip';
import SelectColumnFilter from '../../reusable/SelectColumnFilter';
import AdditiveTooltip from '../../reusable/AdditiveTooltip';
import setLanguageText from '../../../language/setLanguageText';

const machinesTable_columns = ({ selectedCompany, language }) => {

	let columns = [
		// {
		// 	header: () => <Typography componenet="p" variant="body2" fontWeight={700}>OOC</Typography>,
		// 	accessorKey: 'last_entry.out_of_control',
		// 	cell: info => info.getValue(),
		// },
		{
			id: "machine_name",
			name: setLanguageText("Name"),
			header: () => null,
			accessorKey: "machine_name",
			//Filter: (column) => SelectColumnFilter(column, setLanguageText(column.column.name), language),
			cell: (info) => <Typography componenet="p" variant="body2" fontWeight={700}>{setLanguageText(info.getValue())}</Typography>,
			size: 220,
			sticky: true
		},
		{
			id: "machine_group",
			name: setLanguageText("Group"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Machine Group")}</Typography>,
			accessorKey: "machine_group",
			cell: info => info.getValue(),
			enableColumnFilter: false,
		},
		{
			id: "last_coolant_change",
			name: setLanguageText("Last Coolant Change"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Last Coolant Change")}</Typography>,
			accessorKey: "last_coolant_change",
			enableColumnFilter: false,
			cell: (info) => {
				return (
					<>
						<Typography variant="body2">
							{info ? formatDate(info.getValue()) : '-'}
						</Typography>
						<Typography variant="caption">{calcDaysPast(info.getValue(), language, setLanguageText)}</Typography>
					</>
				);
			}
		},
		{
			id: 'date_stamp',
			name: setLanguageText("Last Entry"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Last Entry")}</Typography>,
			accessorKey: 'last_entry.date_stamp',
			enableColumnFilter: false,
			cell: (info) => {
				return (
					<>
						{info ?
							<Fragment>
								<Typography variant="body2">{formatDate(info.getValue())}</Typography>
								<Typography variant="caption">{calcDaysPast(info.getValue(), language, setLanguageText)}</Typography>
							</Fragment>
							: '-'}
					</>
				);
			}
		}
	];

	if (Object.entries(selectedCompany).length) {
		//console.log(selectedCompany.company_attributes)
		selectedCompany.company_attributes.forEach(item => {
			//console.log(item);
			columns = [
				...columns,
				{
					id: item.one.attr_id,
					name: setLanguageText(item.one.attr_name),
					header: <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText(item.one.attr_name)}</Typography>,
					accessorKey: "item.one.attr_id",
					enableSorting: false,
					enableColumnFilter: false,
					cell: ({ row }) => {
						//console.log(row.original.last_entry)
						return (
							<CreateChip
								company_attr_id={item.one.attr_id}
								entry_attributes={row.original.last_entry.attributes_new}
								factorApplied={row.original.last_entry?.factor ? true : false}
								entryFactor={row.original.last_entry?.factor}
							/>
						);
					}
				}
			];
		});
	}

	//add status and notes
	columns = [
		...columns,
		{
			id: "coolant_name",
			name: setLanguageText("Product Name"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Product Name")}</Typography>,
			accessorKey: 'coolant_name',
			cell: info => {
				let value = info.getValue()
				//console.log(value)
				return (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						{value ?
							value
							:
							'-'
						}
					</Grid>
				);
			},
			enableSorting: false,
			enableColumnFilter: false,
		},
		{
			id: "notes",
			name: setLanguageText("Notes"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Notes / Actions")}</Typography>,
			accessorKey: "last_entry.notes",
			enableSorting: false,
			enableColumnFilter: false,
			cell: ({ row }) => {
				let value = row?.original?.last_entry?.notes
				if (value) {
					return (
						<>
							{row.original.last_entry.note_action === 'C' &&
								<Alert style={{ width: '100%' }} severity="error"><strong>{setLanguageText("Details")}:</strong> {value}</Alert>
							}
							{row.original.last_entry.note_action === 'N' &&
								<Alert style={{ width: '100%' }} severity="success"><strong>{setLanguageText("Details")}:</strong> {value}</Alert>
							}
							{row.original.last_entry.note_action === 'Y' &&
								<Alert style={{ width: '100%' }} severity="warning"><strong>{setLanguageText("Details")}:</strong>  {value}</Alert>
							}
						</>
					)
				} else {
					return '-'
				}
			}
		},
		{
			id: 'additives',
			name: setLanguageText("Additives"),
			header: () => <Typography componenet="p" variant="body2" fontWeight={500}>{setLanguageText("Additives")}</Typography>,
			accessorKey: "last_entry.additives",
			enableSorting: false,
			enableColumnFilter: false,
			cell: (info) => {
				//console.log(info)	
				let value = info.getValue()
				return (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						{value?.length > 0 ? (
							<AdditiveTooltip additives={value} />
						) : '-'}
					</Grid>
				);
			}
		}
	];
	return columns;
};

export default machinesTable_columns;
