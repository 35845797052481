import { Alert, Grid, ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import GlobalDialog from "../reusable/GlobalDialog";
import { CompanyContext } from "../store/CompanyContext";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";
import { UserContext } from "../store/UserContext";
import { BasketContext } from "../store/BasketContext";
import { BASKET_TOGGLE, UPDATE_BASKET } from "../store/BasketReducers";
import DialogLoader from "../reusable/DialogLoader";
import ProductItems from "../settings/companies/orders/ProductItems";
import { updateBasketTotals } from "../basket/helpers/updateBasketTotals";

const ContinueCheckout = ({ order, title, buttonText, handleClose }) => {

    const [open, setOpen] = useState(false)
    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const { dispatchBasket } = useContext(BasketContext);
    //const storedToken = sessionStorage.getItem('token');
    const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');
    const [loading, setLoading] = useState(false);

    const handleCloseDialog = () => {
        setOpen(false);
        handleClose();
    }

    const handleSubmit = () => {

        setLoading(true)


        //update for new

        let createItems = order.order_items.map(async (product) => {

            //console.log(product)

            //if a token then retrun product
            if (product.product_id === -1) {
                return product
            }
            // check product and fetch new price
            let items = await axiosRequest({
                gateway: config.opensearchAPI.URL,
                endpoint: 'tools-search/price',
                api_key: user.api_key,
                method: 'get',
                params: {
                    "id": product.product_id, //cis uses this
                    "code": product.product_number, //act uses this
                    "account": selectedCompany.company_erp.AccountNumber,
                    "supplier": product.product_supplier,
                    "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
                }
            }).then((data) => {
                //any other errors
                if (data?.errorMessage || data?.ErrorMessage) {
                    //console.log(data?.errorMessage)
                    //fallback until live api
                    if (product.product_rrp) {
                        return ({
                            ...product,
                            "price_rrp": parseFloat(product.product_rrp),
                            "price_incl_discount": parseFloat(product.product_rrp),
                            "price_discount": 0,
                            "product_unit": product?.product_packet_size ? parseInt(product.product_packet_size) : 1,
                            "product_stock": 0,
                            "product_free_stock": 0,
                            "product_erp_code": product?.product_id
                        })
                    }

                } else {

                    return ({
                        ...product,
                        "price_rrp": data?.PriceRRP,
                        "price_incl_discount": data?.PriceInclDiscount,
                        "price_discount": data?.PriceDiscount,
                        "product_unit": data?.Unit,
                        "product_stock": data?.TotalStock,
                        "product_free_stock": data?.FreeStock,
                        "product_erp_code": data?.ArticleNumber,
                        "product_erp": data?.FullProduct
                    })
                }
            })

            return items
        })
        Promise.all(createItems).then((result) => {

            dispatchBasket({
                type: UPDATE_BASKET,
                payload: {
                    "items": result,
                    "basket_id": -1,
                    "basket_company": selectedCompany.company_id,
                    "basket_name": `Quote ${order.order_number}`,
                    "order_id": order.order_id,
                }
            });

            updateBasketTotals(result, dispatchBasket);

            dispatchBasket({
                type: BASKET_TOGGLE,
                payload: {
                    "basket_open": true
                }
            });

            setLoading(false);
            handleCloseDialog();
            handleClose();
            //console.log(result)
            //update_basket.mutate({ ...basket, items: result })
        })
    }

    return (
        <>
            <MenuItem onClick={() => setOpen(true)} >
                <ListItemIcon>
                    <FontAwesomeIcon icon={faShoppingCart} />
                </ListItemIcon>
                {buttonText ? buttonText : 'Complete Checkout'}
            </MenuItem>
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={handleCloseDialog}
                    title={title}
                    buttonClick={handleSubmit}
                    buttonTitle="Continue to Checkout"
                    closeText="Close"
                    maxWidth="sm"
                >
                    <Grid container spacing={2}>
                        <ProductItems order={order} />
                        <Grid item >
                            <Alert severity="info">Added this quote to checkout may result in a different price and you will only be able to purchase if the items are in stock.</Alert>
                            {loading ?
                                <DialogLoader
                                    isLoading={loading}
                                    //mutation={update_basket}
                                    loadingMessage="Updating Basket"
                                    successMessage="Updated"
                                    closeDialog={handleCloseDialog}
                                    fixed
                                />
                                : null}
                        </Grid>
                    </Grid>
                </GlobalDialog>
                : null}
        </>
    );
};

export default ContinueCheckout;