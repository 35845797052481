import React, {
	useContext,
	useEffect,
	useMemo,
	useState,
	useCallback
} from 'react';
import { Grid, Button, Alert } from '@mui/material';
import { UserContext } from '../../../store/UserContext';
import ReportCard from '../ReportCard';
import slugifyString from '../../helpers/slugifyString';
import setLanguageText from '../../../language/setLanguageText';
import { CompanyContext } from '../../../store/CompanyContext';
import { MachineContext } from '../../store/MachineContext';
import { useMutation } from '@tanstack/react-query'
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import CoolantUsageByCoolantPDF from './CoolantUsageByCoolantPDF';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import formatDate from '../../helpers/formatDate';
import { saveAs } from 'file-saver';
import splitString from '../../../helpers/splitString';
import LoadingScreen from '../../reusable/LoadingScreen';
import DropdownCoolantsByManufacturer from './DropdownCoolantsByManufacturer';
import DropdownCoolantsManufacturers from './DropdownCoolantsManufacturers';

export default function CoolantUsageByCoolant() {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { selectedGroup } = useContext(MachineContext);
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState();
	const [csv_data, setCsv_data] = useState([]);
	const [data, setData] = useState();
	const [downloadObject, setDownloadObject] = useState();
	const [manufacturer, setManufacturer] = useState(null);
	const [coolant, setCoolant] = useState(null);

	const getCoolantsByCoolant = useMutation({
		mutationFn: (params) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'reports/coolant-usage',
			api_key: user.api_key,
			method: 'get',
			params: params
		}),
		onSuccess: (response) => {
			if (!response.errorMessage) {


				let clone = structuredClone(response);
				// Create 'conc' column
				clone.forEach((result, i) => {
					if (result.attributes_new) {
						const conc_column = result.attributes_new.find(
							attribute => attribute.attribute_id === 'conc'
						);
						// add conc property to object
						clone[i].conc = conc_column.attribute_values.value;
						const ph_column = result.attributes_new.find(
							attribute => attribute.attribute_id === 'ph'
						);
						// add ph property to object
						clone[i].ph = ph_column.attribute_values.value;
						// remove columns that we don't need
						delete clone[i].attributes_new;
						delete clone[i].void;
						delete clone[i].additives;
					}
				});
				setData(clone);
			}
		}
	});

	useEffect(() => {
		if (selectedCompany) {
			setTitle(`${setLanguageText("Coolant Usage")}: ${selectedCompany.company_name}`);
			setData();
			setCsv_data();
			setDownloadObject();
		}
	}, [selectedCompany]);

	const runReport = () => {
		setOpen(true);
		let params = {
			coolant_id: coolant?.product_id,
			company_ids: selectedCompany.company_id,
		}
		getCoolantsByCoolant.mutate(params);
	};

	const csv_filename = useMemo(() =>
		selectedGroup &&
		`report_${slugifyString(selectedCompany.company_name)}_${slugifyString(
			selectedGroup.group_name
		)}`,
		[selectedCompany, selectedGroup]);


	const downloadButton = () => {
		var blob = new Blob([downloadObject.blob], { type: "application/pdf;charset=utf-8" });
		saveAs(blob, `${title}.pdf`);
	}

	const create_csv_data = useCallback(() => {
		const headers = Object.keys(data[0]).map(header => splitString(header));
		const values = data.map(value => {
			let format_dates = { ...value };
			if (value.date_stamp)
				format_dates = {
					...format_dates,
					date_stamp: formatDate(value.date_stamp)
				};
			if (value.last_coolant_change)
				format_dates = {
					...format_dates,
					last_coolant_change: formatDate(value.last_coolant_change)
				};
			return Object.values(format_dates);
		});
		setCsv_data([headers, ...values]);
		//setShowPreview(true);
	}, [data]);

	const handleClose = () => {
		setOpen(false);
		setData();
	}

	return (
		<>
			<ReportCard
				title={title}
				buttonLabel={setLanguageText("Run Report")}
				disabled={!coolant || !manufacturer ? true : false}
				runReport={runReport}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<DropdownCoolantsManufacturers setManufacturer={setManufacturer} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<DropdownCoolantsByManufacturer
							manufacturer={manufacturer}
							setCoolant={setCoolant}
						/>
					</Grid>
				</Grid>
			</ReportCard>
			{open &&
				<GlobalDialog
					open={open}
					onClose={handleClose}
					title={title}
					maxWidth="xl"
					fullWidth
					fullHeight
					noPadding
					customActions={
						<>
							{data && data.length && downloadObject ?
								<Grid item>
									<Button
										onClick={e => downloadButton()}
										color="primary"
										variant="contained"
									>
										<FontAwesomeIcon style={{ marginRight: '10px', textDecoration: 'none' }} icon={faDownload} />
										{setLanguageText("Download PDF")}
									</Button>
								</Grid>

								: null}
							{data && data.length && csv_data ?
								<Grid item>
									<CSVLink
										data={csv_data}
										filename={`${csv_filename}.csv`}
									>
										<Button onClick={create_csv_data} variant="contained" color="primary">
											{setLanguageText('Download CSV')}
										</Button>
									</CSVLink>
								</Grid>
								: null}
							<Grid item>
								<Button onClick={handleClose} variant="contained">
									{setLanguageText("Close")}
								</Button>
							</Grid>
						</>
					}
				>
					{data && data.length ?
						<CoolantUsageByCoolantPDF
							title={title}
							data={data}
							setDownloadObject={setDownloadObject}
							manufacturer={manufacturer}
							coolant={coolant}
						/>
						: data && data.length === 0 ?

							<Alert severity="info">
								{setLanguageText("No data to display")}
							</Alert>
							:
							<LoadingScreen
								position="relative"
								loading={false}
								text={setLanguageText("Loading")}
							/>

					}
				</GlobalDialog >
			}
		</>
	);
};

