import React, { useContext, useEffect, useState } from "react";
import Paginations from "../../shop/reusable/Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTable,
	faBorderAll
} from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from "../../store/LayoutContext";
import { ProductsContext } from "../../store/ProductsContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { Autocomplete, Box, Grid, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import AttributeToggle from "../cim/AttributeToggle";

const ProductOptions = ({ isFetching, attributes, setAttributes, bottom }) => {

	//Global states
	const { layout, dispatchLayout } = useContext(LayoutContext);
	const { products, dispatchProducts } = useContext(ProductsContext);
	const [value, setValue] = useState();

	//componenet states
	const limitOptions = [{
		id: 1, label: 10
	}, {
		id: 1, label: 20
	}, {
		id: 2, label: 30
	}]

	//Update layout
	const updateLayout = (e, value) => {
		if (value) {
			dispatchLayout({
				type: UPDATE_LAYOUT,
				payload: {
					layout: value,
				}
			});
			sessionStorage.setItem("layout", value);
		}
	};

	//Update limit
	const updateLimit = (value) => {
		console.log('update page')
		dispatchProducts({
			type: UPDATE_PRODUCTS,
			payload: {
				limit: value.label
			}
		});
		dispatchLayout({
			type: UPDATE_LAYOUT,
			payload: {
				page: 1 //reset page
			}
		});
		sessionStorage.setItem("limit", value.label);
	};

	useEffect(() => {
		//console.log(products.limit)
		if (products.limit) {
			let found = limitOptions.find(item => item.label === products.limit)
			//console.log(found)
			setValue(found)

		}
	}, [products.limit])

	return (
		<Grid item container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: 0 }} >
			<Grid item xs={6} md={2} >
				{layout.layout ?
					<ToggleButtonGroup
						size="small"
						color="primary"
						fullWidth
						value={layout.layout}
						exclusive
						onChange={updateLayout}
						aria-label="layout"
						sx={{ maxWidth: '120px' }}
						disabled={isFetching}
					>
						{!bottom && (attributes && attributes.length > 0) ?
							<AttributeToggle attributes={attributes} setAttributes={setAttributes} />
							: null}

						<ToggleButton value="engineer">
							<FontAwesomeIcon
								icon={faTable}
								size="2x"
							/>
						</ToggleButton>
						<ToggleButton value="grid" >
							<FontAwesomeIcon
								icon={faBorderAll}
								size="2x"
							/>
						</ToggleButton>
					</ToggleButtonGroup>
					: null}
			</Grid>
			{/* <Grid item md="auto">
				<Paginations isFetching={isFetching} updateLimit={updateLimit} />
			</Grid> */}


			<Grid item container xs={12} md={10} alignItems="center" justifyContent="flex-end">
				<Grid item>
					{isFetching ? <Skeleton width="100px" /> :
						<Typography
							color={(theme) => theme.palette.text.primary}
							sx={{ marginRight: '1em' }}
							fontSize="14px"
						>
							{products.groupCount ?
								<>Products: {products.groupCount} (<Typography variant="span" color="primary.main" >{products.count} variants</Typography>) </>
								:
								<>Products:
									{/* {brandCount ? <Typography variant="span" fontWeight={600} color="primary.main" > {brandCount}</Typography> : null}
									{brandCount ? ' / ' : null} */}
									{products.count}
								</>
							}
						</Typography>
					}
				</Grid>

				<Grid item md="auto">
					<Paginations isFetching={isFetching} />
				</Grid>

				{value &&
					<Grid item sx={{ display: 'grid', justifyItems: 'flex-end' }} >
						<Autocomplete
							disabled={isFetching}
							size="small"
							disableClearable
							sx={{
								width: 80,
								'& legend': {
									display: 'none'
								}
							}}
							options={limitOptions}
							value={value}
							onChange={(event, newValue) => {
								//console.log(newValue)
								updateLimit(newValue);
							}}
							isOptionEqualToValue={(option, value) => option.label === value.label}
							autoHighlight
							getOptionLabel={(option) => option.label ? String(option.label) : ''}
							renderInput={(params) => (
								<TextField
									{...params}
									//label={'Per Page'}
									//InputLabelProps={{ shrink: false }}
									placeholder="Please Select"
								/>
							)}
						/>
					</Grid>
				}
			</Grid>

		</Grid>
	);
};

export default ProductOptions;
