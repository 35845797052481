import {
    Alert,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../store/CompanyContext';
import { useFormikContext } from "formik"

const UserCompanyForm = ({ roles }) => {

    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
    const { companies } = useContext(CompanyContext);
    const [localCompanies, setLocalCompanies] = useState([])
    const [userRoles, setUserRoles] = useState(null);

    useEffect(() => {
        if (roles) {
            setUserRoles(roles);
        }
    }, [roles])


    useEffect(() => {
        if (values?.access?.companies) {
            //console.log(values.companies)
            let value = []
            values.access.companies.forEach((id) => {
                //console.log(id)
                let found = companies.find((company) => company.company_id === id)
                //console.log(found)
                if (found) {
                    value.push(found)
                }
            })
            //console.log(value)
            setLocalCompanies(value)
        }
    }, [values.access.companies, companies])


    return (
        <Grid container spacing={2}>
            {!values.administrator ?
                <Grid item xs={12}>
                    <Alert severity='info' >Please select the companies this user will have access to across the whole application. Then set thier global role</Alert>
                </Grid>
                :
                <Grid item xs={12}>
                    <Alert severity='error' sx={{ mt: 1 }}><strong>System Administrator</strong> accounts have access to <strong>all companies</strong> across the application.</Alert>
                </Grid>
            }
            <Grid item container spacing={2}>
                {!values.administrator ?
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={values.access.all_companies} onChange={handleChange} name="access.all_companies" />
                            }
                            label="Allow User Access to ALL companies"
                        />
                    </Grid>
                    : null}
                {values.access.all_companies ?
                    <Grid item xs={12}>
                        <Alert severity='warning' >User will have access to ALL companies across the whole application.</Alert>
                    </Grid>
                    : null}
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        disabled={!localCompanies}
                        multiple={true}
                        options={companies}
                        //getOptionLabel={company => company.company_name ? company.company_name : null}
                        getOptionLabel={option => {
                            //console.log(option)
                            let string = '';
                            if (option.company_name) {
                                string = option.company_name;
                            }
                            if (option?.company_erp) {
                                //check it is a string
                                let erp = []
                                if (typeof option.company_erp === 'string') {
                                    erp = option.company_erp
                                }
                                if (erp.AccountNumber) {
                                    string += ` - ${erp.AccountNumber}`;
                                }
                            }
                            return string
                        }}
                        isOptionEqualToValue={(option, value) => option.company_id === value.company_id}
                        onChange={(e, value) => {
                            setLocalCompanies(value)
                            //set field as array of ids
                            let ids = []
                            value.forEach((company) => {
                                ids.push(company.company_id)
                            })
                            setFieldValue('access.companies', ids);

                        }}
                        value={localCompanies}
                        filterSelectedOptions
                        // renderOption={(props, option) => (
                        //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.company_id}>
                        //         {option.company_name}
                        //     </Box>
                        // )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.company_id}>
                                    {option.company_name}{option?.company_erp && option?.company_erp?.AccountNumber ? ` - ${option.company_erp.AccountNumber}` : ''}
                                </li>
                            );
                        }}
                        renderInput={params => {
                            return (
                                <TextField
                                    disabled={!localCompanies}
                                    {...params}
                                    variant="outlined"
                                    //label={setLanguageText("Select Companies")}
                                    label={values.administrator || values.access.all_companies ? "Select Primary Company" : "Select Visible Companies"}
                                // placeholder={
                                //     !localCompanies.length
                                //         ? 'Primary Company'
                                //         : 'Other Company'
                                // }
                                />
                            );
                        }}
                    />
                </Grid>

                {userRoles ?
                    <Grid item xs={12} lg={6}>
                        <Autocomplete
                            disableClearable
                            options={userRoles}
                            getOptionLabel={options => options.role_name ? options.role_name : ''}
                            isOptionEqualToValue={(option, value) => option.role_group === value.role_group}
                            //value={values.home.role}
                            value={userRoles.find(option => option.role_id === values?.home.role) || null}
                            renderInput={params => (
                                <TextField
                                    required {...params}
                                    variant="outlined"
                                    name="home.role"
                                    //label={setLanguageText("User Level")} 
                                    label="Select Role"
                                    error={touched.home?.role && Boolean(errors.home?.role)}
                                    helperText={touched.home?.role && errors.home?.role}
                                />
                            )}
                            onChange={(e, value) => {
                                //add role id to roles array
                                setFieldValue('home.role', value.role_id);
                            }}
                        />
                    </Grid>
                    : null}
            </Grid>
        </Grid>
    );

}
export default UserCompanyForm;