import { faEdit } from '@fortawesome/pro-light-svg-icons';
import {
    Grid,
    TextField,
    useTheme,
    Button
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { MachineContext } from '../../store/MachineContext';
import { SELECTED_MACHINE } from '../../store/MachineReducers';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../language/setLanguageText';
import useActivityLog from '../../../users/activity/useActivityLog';
import { useMutation } from "@tanstack/react-query";
import DialogLoader from '../../../reusable/DialogLoader';
import config from '../../../config';

function EditMachine({ selectedMachine }) {

    const {
        updateActivityLog
    } = useActivityLog();
    const { user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const [openEdit, setOpenEdit] = useState(false);
    const [machineToEdit, setMachineToEdit] = useState();

    useEffect(() => {
        if (Object.entries(selectedMachine).length) {
            setMachineToEdit({
                machine_name: selectedMachine.machine_name,
                machine_group: selectedMachine.machine_group
            });
        }
    }, [selectedMachine]);

    const openDialog = () => {
        setOpenEdit(true)
    };

    const closeDialog = () => {
        setOpenEdit(false);
        //Reset the Machine Name/Group if close the edit dialog
        setMachineToEdit({
            machine_name: selectedMachine.machine_name,
            machine_group: selectedMachine.machine_group
        });
    };

    const handleEditMachine = event => {
        event.persist();
        setMachineToEdit(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const editMachine = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'patch',
            body: body
        }),
        onSuccess: () => {

            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    machine_id: selectedMachine.machine_id,
                    machine_detail: machineToEdit
                },
                activity_type: "machine edit"
            })
            dispatchSelectedMachine({
                type: SELECTED_MACHINE,
                payload: {
                    ...selectedMachine,
                    ...machineToEdit
                }
            });
            setOpenEdit(false);
        }
    })

    const confirmEditMachine = () => {
        editMachine.mutate({
            edit: 'machine',
            machine_id: selectedMachine.machine_id,
            ...machineToEdit
        })
    };


    return (
        <>
            <Button
                variant="contained"
                color="error"
                onClick={openDialog}
                startIcon={<FontAwesomeIcon icon={faEdit} size="xs" />}
            >
                Edit Machine
            </Button>

            <GlobalDialog
                open={openEdit}
                onClose={closeDialog}
                title="Edit Machine Details"
                buttonClick={confirmEditMachine}
                buttonTitle="Confirm"
                successMessage="Updated successfully!"
            >
                {machineToEdit &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="machine_name"
                                label={setLanguageText("Machine Name")}
                                value={machineToEdit.machine_name}
                                onChange={handleEditMachine}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                name="machine_group"
                                label={setLanguageText("Machine Group")}
                                value={machineToEdit.machine_group}
                                onChange={handleEditMachine}
                            />
                        </Grid>
                    </Grid>
                }
                {editMachine.isLoading || editMachine.isSuccess || editMachine.isError ?
                    <DialogLoader
                        isLoading={editMachine.isLoading}
                        mutation={editMachine}
                        loadingMessage="Updating Machine..."
                        successMessage="Machine Updated"
                        // closeDialog={() => {
                        //     navigate('/coolantcare/machines');
                        // }}
                        fixed

                    />
                    : null}
            </GlobalDialog>
        </>
    );
}

export default EditMachine;
