import React, {
    useState,
    useEffect,
    useContext,
} from 'react';
import {
    faFilePdf,
    faFileExport
} from '@fortawesome/pro-light-svg-icons';
import {
    useTheme,
    Grid,
    Button
} from '@mui/material';
import { buttonStyles } from '../styles/buttonStyles';
import GlobalDialogPDF from '../../reusable/GlobalDialogPDF';
import ChartsPDF from '../pdf/ChartsPDF';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExportCharts = ({ chartData, selectedCompany, selectedGroup }) => {

    const theme = useTheme();
    const { language } = useContext(LayoutContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [localOrientation, setLocalOrientation] = useState('landscape');
    const [orientation, setOrientation] = useState();

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    //set orintation
    useEffect(() => {
        if (localOrientation) {
            setOrientation(localOrientation);
        }
        return () => {
            setOrientation();
        }
    }, [localOrientation]);


    return (
        <>
            <Button
                disabled={chartData ? false : true}
                variant="contained"
                onClick={e => handleClick(e)}
                sx={{ height: '100%' }}
                //size="large"
                endIcon={<FontAwesomeIcon icon={faFileExport} />}
            >
                {setLanguageText("Export Machines")}
            </Button>
            <GlobalDialogPDF
                title={`${selectedCompany.company_name} - ${setLanguageText("Export Charts")}`}
                open={open}
                onClose={handleClose}
                maxWidth
                //downloadObject={downloadObject}
                orientation={orientation}
                updateOrientation={setOrientation}
            //options={localOptions}
            //handleOptionChange={handleChange}
            >
                <ChartsPDF
                    selectedCompany={selectedCompany}
                    selectedGroup={selectedGroup}
                    chartData={chartData}
                    orientation={orientation}
                    theme={theme.palette}
                    language={language}
                />

            </GlobalDialogPDF>
        </>
    );
};

export default ExportCharts;
