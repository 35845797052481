import React, { useContext } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography
} from '@mui/material';
import formatDate from '../../helpers/formatDate';
import CardCell from './CardCell';
import CreateGridChip from '../../reusable/CreateGridChip';
import { CompanyContext } from '../../../store/CompanyContext';
import { Link } from 'react-router-dom';
import calcOverdueCoolant from '../../helpers/calcOverdueCoolant';
import { Alert } from '@mui/material';
import AdditiveTooltip from '../../reusable/AdditiveTooltip';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../language/setLanguageText';

const MachineCard = ({ machine }) => {


    const { selectedCompany } = useContext(CompanyContext);
    const { language } = useContext(LayoutContext);

    return (
        <Card
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: (theme) => machine.last_entry.out_of_control ? theme.palette.table.out : theme.palette.table.rows,
            }}
        >
            <Link
                to={`/coolantcare/machine/${machine.machine_id}`}
                style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}
            >
                <CardHeader
                    sx={{
                        //backgroundColor: theme => machine.last_entry.out_of_control ? theme.palette.error.light : theme.palette.success.light,
                        backgroundColor: (theme) => machine.last_entry.out_of_control ? theme.palette.table.out : theme.palette.cards.header,
                        borderBottom: theme => `1px solid ${theme.palette.divider}`,
                        padding: '10px'
                    }}
                    title={<Typography variant="h6">{machine.machine_name} {machine.machine_group ? ` - ${machine.machine_group}` : ''}</Typography>}
                />

            </Link>
            <CardContent>
                <Grid container spacing={1}>
                    {/* <Grid item xs={12}>
                        <Typography variant="body2">
                            <strong>{setLanguageText("Group")}</strong>: {machine.machine_group}
                        </Typography>
                    </Grid> */}
                    {machine?.machine_settings?.admin?.operator_email?.name ?
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>{setLanguageText("Operator")}</strong> : {machine?.machine_settings?.admin?.operator_email?.name} <br />
                            </Typography>
                        </Grid>
                        : null}
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <strong>{setLanguageText("Last Coolant Chang")}e</strong>:{' '}
                            {formatDate(
                                machine.last_coolant_change ? machine.last_coolant_change :
                                    machine?.machine_settings?.machine_defaults?.last_coolant_change ?
                                        machine?.machine_settings?.machine_defaults?.last_coolant_change :
                                        null
                            )}
                        </Typography>
                    </Grid>
                    {machine.machine_settings.machine_defaults?.change_freq ? calcOverdueCoolant(machine.last_coolant_change, machine.machine_settings.machine_defaults.change_freq, language) : null}
                    <Grid item container spacing={1}>
                        {selectedCompany.company_attributes &&
                            selectedCompany.company_attributes.map(item => {
                                return (
                                    <Grid item container xs={6} key={item.one.attr_id}>
                                        <CreateGridChip
                                            title={item.one.attr_name}
                                            company_attr_id={item.one.attr_id}
                                            entry_attributes={machine.last_entry.attributes_new}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                    {/* {selectedCompany.company_attributes &&
                        selectedCompany.company_attributes.map(item => {

                            if (selectedCompany?.company_settings?.machines_defaults) {
                                let found = (selectedCompany.company_settings.machines_defaults).find(attr => attr.id === item.one.attr_id)
                                if (found && found.isVisible) {
                                    return (
                                        <Grid item container xs={6} key={item.one.attr_id}>
                                            <CardCell title={item.one.attr_name}>
                                                <CreateGridChip
                                                    company_attr_id={item.one.attr_id}
                                                    entry_attributes={machine.last_entry.attributes_new}
                                                />
                                            </CardCell>
                                        </Grid>
                                    );
                                }
                            } else {
                                return (
                                    <Grid item container xs={6} key={item.one.attr_id}>
                                        <CardCell title={item.one.attr_name}>
                                            <CreateGridChip
                                                company_attr_id={item.one.attr_id}
                                                entry_attributes={machine.last_entry.attributes_new}
                                            />
                                        </CardCell>
                                    </Grid>
                                )
                            }


                        })} */}
                    {machine?.last_entry?.additives?.length ? (
                        <Grid item xs={12} >
                            <Alert icon={false} sx={{ width: '100%' }} severity="info"><strong>{setLanguageText("Additives")}:</strong> <AdditiveTooltip additives={machine.last_entry.additives} mobile={true} /></Alert>
                        </Grid>
                    ) : null}
                    {machine?.last_entry?.notes ? (
                        <Grid item xs={12} >
                            {machine.last_entry.note_action === 'C' &&
                                <Alert
                                    icon={false}
                                    sx={{ width: '100%' }} severity="error"><strong>{setLanguageText("Notes")}:</strong> {machine.last_entry.notes}</Alert>
                            }
                            {machine.last_entry.note_action === 'N' &&
                                <Alert
                                    icon={false}
                                    sx={{ width: '100%' }} severity="success"><strong>{setLanguageText("Notes")}:</strong> {machine.last_entry.notes}</Alert>
                            }
                            {machine.last_entry.note_action === 'Y' &&
                                <Alert
                                    icon={false}
                                    sx={{ width: '100%' }} severity="warning"><strong>{setLanguageText("Notes")}:</strong>  {machine.last_entry.notes}</Alert>
                            }
                        </Grid>
                    ) : null}
                </Grid>
            </CardContent>
        </Card >
    );
};

export default MachineCard;
