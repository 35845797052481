import React, { useReducer } from 'react';
import { ChartsReducer } from './ChartsReducers';

export const ChartsContext = React.createContext();

export const ChartsProvider = ({ children }) => {

    const [charts, dispatchCharts] = useReducer(ChartsReducer, []);

    const contextValues = {
        charts,
        dispatchCharts,
    };
    return (
        <ChartsContext.Provider value={contextValues}>
            {children}
        </ChartsContext.Provider>
    );
};
