import React, { useEffect, useState, useMemo, Fragment, useContext } from 'react';
import { machineQRCodeCreater } from '../helpers/machineQRCodeCreater';
import config from '../../config';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    usePDF
    //PDFDownloadLink,
} from '@react-pdf/renderer';
import formatDate from '../helpers/formatDate';
import calcDaysPast from '../helpers/calcDaysPast';
import setLanguageText from '../../language/setLanguageText';
import ReactPDFFallback from './ReactPDFFallback';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useTheme } from '@emotion/react';
import { LayoutContext } from '../../store/LayoutContext';

const SingleMachinePDF = ({ company, machine, charts, orientation, language }) => {

    //console.log(company)
    const [machineQrCode, setMachineQrCode] = useState();
    const [url, setUrl] = useState();
    const s3 = new AWS.S3({ region: config.s3.REGION });
    const { theme: layoutTheme } = useContext(LayoutContext);
    const [mainImage, setMainImage] = useState();
    const theme = useTheme();
    const [actLogo, setActLogo] = useState('');

    import(`../../images/MyFactory-Logo-Black.png`).then((module) => {
        setActLogo(module.default);
    });

    useEffect(() => {
        if (layoutTheme) {
            //console.log(theme)
            setMainImage(layoutTheme.login_logo)
        }
    }, [layoutTheme])


    // Create styles
    const styles = StyleSheet.create({
        page: {
            padding: 10
        },
        body: {
            height: '80vh'
        },
        header: {
            width: '100%',
            textAlign: 'center',
            height: '5vh'
        },
        header_title: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        text_container: {
            padding: '10px 0',
        },
        text: {
            fontSize: 10,
            fontWeight: 'normal',
            lineHeight: '3px',
        },
        bold: {
            fontSize: 9,
            fontWeight: 'bold'
        },
        title: {
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '3px',
        },
        chart_title: {
            fontSize: 11,
            fontWeight: 'bold',
            padding: '2px 0',
            width: '100%',
            textAlign: 'center'
        },
        logo_contanier: {
            width: '100%',
            height: '120px'
        },
        logo: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        machineQr: {
            width: '100%'
        },
        qr: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            marginLeft: '-10px'
        },
        chart: {
            height: '33.3333%',
            width: 'auto',
            //padding: 10
        },
        column_container: {
            width: '100%',
            flexDirection: 'row'
            //alignItems: 'stretch'
        },
        column_1: {
            width: '20%',
        },
        column_2: {
            width: '40%'
            //padding: 10
        },
        column_3: {
            width: '40%'
            //padding: 10
        },
        footer: {
            textAlign: 'center',
            height: '5vh',
            position: 'absolute',
            fontSize: 12,
            bottom: 0,
            left: 0,
            right: 0,
        },
        footer_text: {
            fontSize: 8,
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 'normal'
        }
    });

    useEffect(() => {
        if (machine) {
            let url = machineQRCodeCreater({
                company_id: machine.company_id,
                machine_id: machine.machine_id,
                instance: config.INSTANCE
            });
            setMachineQrCode(url);
        }
    }, [machine]);

    const getSignedUrl = async (bucket, key) => {
        var params = {
            Bucket: bucket,
            Key: key,
        };
        let url = s3.getSignedUrl("getObject", params);
        //console.log(url) 
        return url
    }

    useEffect(() => {
        if (company) {
            getSignedUrl(config.s3.BUCKET, `public/company/${company.company_logo_path}`).then((url) => setUrl(url))
        }
    }, [company, setUrl]);


    const renderCharts = charts => {
        //NEED TO HARD CODE THE LAYOUT FOT NOW
        return (
            <Fragment>
                <View style={styles.column_2}>
                    {charts?.conc_base64 &&
                        <View style={styles.chart} wrap={false}>
                            <Text style={styles.chart_title}>{setLanguageText("Concentration levels")}</Text>
                            <Image src={charts.conc_base64} allowDangerousPaths />
                        </View>
                    }
                    {charts?.ph_base64 &&
                        <View style={styles.chart} wrap={false}>
                            <Text style={styles.chart_title}>{setLanguageText("PH levels")}</Text>
                            <Image src={charts.ph_base64} allowDangerousPaths />
                        </View>
                    }
                    {charts?.fungi_base64 &&
                        <View style={styles.chart} wrap={false}>
                            <Text style={styles.chart_title}>{setLanguageText("Fungi levels")}</Text>
                            <Image src={charts.fungi_base64} allowDangerousPaths />
                        </View>
                    }
                </View>
                <View style={styles.column_3}>

                    {charts?.bacteria_base64 &&
                        <View style={styles.chart} wrap={false}>
                            <Text style={styles.chart_title}>{setLanguageText("Bacteria levels")}</Text>
                            <Image src={charts.bacteria_base64} allowDangerousPaths />
                        </View>
                    }
                    {charts?.tramp_base64 &&
                        <View style={styles.chart} wrap={false}>
                            <Text style={styles.chart_title}>{setLanguageText("Tramp oil levels")}</Text>
                            <Image src={charts.tramp_base64} allowDangerousPaths />
                        </View>
                    }
                </View>
            </Fragment>
        );
        //LOOP
        // return Object.values(charts).map((image,i) => {
        //     return <View key={i} style={styles.chart}>
        //             <Image
        //             src={image}
        //             allowDangerousPaths
        //         />
        //     </View>
        // })
    };

    const document = useMemo(() =>
        <Document title={`${machine.machine_name} Export`}>
            <Page style={styles.page} orientation={'landscape'}>
                <View fixed style={{ backgroundColor: config.APPNAME === "act" ? "#000" : theme.palette.primary.main, textAlign: 'center', width: '100%', padding: '10px' , marginBottom : '5px'}}>
                    <View style={{ textAlign: 'center', width: '100%' }}>
                        {mainImage ? <Image src={config.APPNAME === "act" ? actLogo : mainImage} style={{ width: '100%', maxWidth: '120px', height: 'auto', maxHeight: '50px', objectFit: 'contain', margin: '0 auto' }} /> : null}
                    </View>
                </View>
                <View style={styles.body}>
                    <View style={styles.column_container}>

                        <View style={styles.column_1}>
                            <View style={styles.text_container}>
                                <Text style={styles.header_title}>{machine.machine_name}</Text>

                                {machineQrCode ? <Image style={styles.qr} src={machineQrCode} /> : null}

                                <Text style={styles.title}>{company.company_name}</Text>
                                <Text style={styles.text}>{machine.machine_group}</Text>

                                <Text style={styles.title}>{setLanguageText("Last Machine Check")}</Text>
                                <Text style={styles.text}>{formatDate(machine.last_entry.date_stamp)} - {machine.name}</Text>
                                <Text style={styles.text}>{calcDaysPast(machine.last_entry.date_stamp, language, setLanguageText)}*</Text>

                                <Text style={styles.title}>{setLanguageText("Coolant Details")}</Text>
                                <Text style={styles.text}>{setLanguageText("Name")} : {machine.coolant_name}</Text>
                                {machine?.coolant_defaults?.coolant.manufacturer ?
                                    <Text style={styles.text}>{setLanguageText("Manufacturer")}:{' '}{machine?.coolant_defaults?.coolant.manufacturer}</Text>
                                    : null}
                                <Text style={styles.text}>{setLanguageText("Last Coolant Change")}:{' '}{formatDate(machine.last_coolant_change)}</Text>
                                {machine.last_coolant_change &&
                                    <Text style={styles.text}>{calcDaysPast(machine.last_coolant_change, language, setLanguageText)}*</Text>
                                }
                                <Text style={styles.title}>{setLanguageText("Machine Settings")}</Text>
                                {machine.machine_settings.machine_defaults.sump ?
                                    <Text style={styles.text}>{setLanguageText("Sump Size")}: {machine.machine_settings.machine_defaults.sump}</Text>
                                    : null}
                                <Text style={styles.text}>Refractometer Factor:{' '}{machine.machine_settings.machine_defaults.factor ? machine.machine_settings.machine_defaults.factor : machine.machine_settings.machine_defaults.refractomer_factor}</Text>
                                <Text style={styles.text}>{setLanguageText("PH Min")}:{' '}{machine.machine_settings.machine_defaults.ph_min}</Text>
                                <Text style={styles.text}>{setLanguageText("PH Max")}:{' '}{machine.machine_settings.machine_defaults.ph_max}</Text>
                                <Text style={styles.text}>{setLanguageText("Conc Min")}:{' '}{machine.machine_settings.machine_defaults.op_con_min}</Text>
                                <Text style={styles.text}>{setLanguageText("Conc Max")}:{' '}{machine.machine_settings.machine_defaults.op_con_max}</Text>

                            </View>
                        </View>
                        {charts && renderCharts(charts)}
                    </View>
                </View>
                <View style={styles.footer}>
                    <Text style={styles.footer_text}>
                        * {setLanguageText("Since date of report printed")}
                        {formatDate(Date.now())} - {config.NAME} {setLanguageText("PDF Export")}
                    </Text>
                </View>
            </Page>
        </Document>,
        [styles, machine, company, machineQrCode, url, charts, language, mainImage]);

    const [instance, updateInstance] = usePDF({ document });

    useEffect(() => {
        if (document) {
            updateInstance(document);
        }
    }, [document]);

    // useEffect(() => {

    //     if (instance.blob) {
    //         // console.log(instance)
    //         setDownloadObject(instance)
    //     }

    // }, [instance, setDownloadObject])


    return (
        <>
            {instance?.url ?
                <>
                    {isMobileOnly || isTablet ?
                        <ReactPDFFallback url={instance.url} />
                        :
                        <object
                            data={instance.url}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                        </object>
                    }
                </>
                : null}
        </>
    )
};

export default SingleMachinePDF;
