import React from 'react';
import { Fade, Grid, Typography } from '@mui/material';
import { faTint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';
import setLanguageText from '../../language/setLanguageText';

export default function LoadingScreen({
    loading,
    background,
    colour,
    text,
    position,
    height,
    customAction
}){
    return (
        <Fade in={!loading} unmountOnExit={true}>
            <Grid
                sx={{
                    backgroundColor: (theme) => background ? background : theme.palette.background.main,
                    color: (theme) => colour ? colour : theme.palette.text.primary,
                    position: position ? position : 'absolute',
                    minHeight: height ? height : '300px',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'grid',
                    alignItems: 'center',
                    fontSize: '4em',
                    zIndex: 100,
                    opacity: 0.9
                }}
            >
                <Grid>
                    <FontAwesomeIcon icon={faTint} />
                    <Typography>{text ? setLanguageText(text) : `${setLanguageText("Loading")} ${config.NAME}`}</Typography>
                    {customAction && customAction}
                </Grid>
            </Grid>
        </Fade>
    );
};