import React from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';
import PercentageBar from './cards/PercentageBar';

export default function ProductsList({ product }) {

    const percentage = product?.machines_using_product?.length ? (product.machines_using_product.length / product.total_machines) * 100 : 0;

    return (

        <Grid
            item
            container
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12} md={4}  >
                <Typography variant='p'><strong>{product?.product_name}</strong> {product?.brand_name ? `(${product?.brand_name})` : ''}</Typography>
                {/* <Typography variant='caption' >{product?.product_defaults?.description}</Typography> */}
            </Grid>
			<PercentageBar percentage={percentage} machines={product?.machines_using_product?.length} total={product?.total_machines} />
		
        </Grid>

    );
};
