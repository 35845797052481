import React from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { useFormikContext } from "formik"

const UserForm = () => {

    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();

    return (
        <Grid item container spacing={2} >
            <Grid item xs={6}>
                <TextField
                    disabled={true}
                    id="name"
                    name="name"
                    label="Name"
                    value={values?.name}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={true}
                    id="surname"
                    name="surname"
                    label="Surname"
                    value={values?.surname}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.surname && Boolean(errors.surname)}
                    helperText={touched.surname && errors.surname}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={true}
                    id="username"
                    name="username"
                    label="Username"
                    value={values?.username}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                // InputProps={{
                //     endAdornment:
                //         <InputAdornment position="end">
                //             {valid ? <FontAwesomeIcon size='2x' icon={faCheckCircle} /> : <FontAwesomeIcon size='2x' icon={faTimesCircle} />}
                //         </InputAdornment>,
                // }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={true}
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    disabled={true}
                    id="aws_sub"
                    name="aws_sub"
                    label="AWS sub"
                    value={values.aws_sub}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.aws_sub && Boolean(errors.aws_sub)}
                    helperText={touched.aws_sub && errors.aws_sub}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox checked={values.can_checkout} onChange={handleChange} name="can_checkout" />
                    }
                    label="Allow user to complete checkout process"
                />
            </Grid>
        </Grid>
    );
}
export default UserForm;