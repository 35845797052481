import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Checkbox, Grid, Skeleton, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const ProductVariants = ({ variants, isFetching, setSelectedProduct, selectedProduct, link, fullHeight }) => {

	const navigate = useNavigate();
	//console.log(selectedProduct)

	const columns = useMemo(() => {
		let columns = [
			{
				id: 'Selected',
				label: 'Selected',
				minWidth: 100,
				align: 'left',
				format: (value, row) => {
					return (
						<Checkbox size="small"
							//checked={row.product_id === selectedProduct?.product_id || parseInt(row.product_id) === group?.product_id}
							checked={row.product_id === selectedProduct?.product_id}
							onClick={() => {
								if (link) {
									navigate(`/buyers/catalogue/product/${row.product_id}`)
								} else {
									setSelectedProduct(row)
								}
							}}
							name='selected' />
					)
				}
			},
			{
				id: 'product_number',
				label: 'Article Number',
				minWidth: 100,
				align: 'left',
				format: (value, row) => {
					// console.log(value)
					if (link) {
						return (
							<Link to={`/buyers/catalogue/product/${row.product_id}`}>
								{value}
							</Link>
						)
					} else if (selectedProduct && (value !== selectedProduct?.product_number)) {
						return (
							<Typography variant='p'

								sx={{
									color: theme => theme.palette.primary.main,
									cursor: 'pointer'
								}}
								onClick={() => setSelectedProduct(row)}
							>
								{value}
							</Typography>
						)
					} else {
						return (
							<Typography variant='p'>
								{value}
							</Typography>
						)
					}
				}
			}]
		if (Object.values(selectedProduct.product_attributes).length) {
			selectedProduct.product_attributes.map((item) => {
				//console.log(item.id)
				columns.push({
					id: 'Attributes',
					label: item.name,
					minWidth: 100,
					align: 'left',
					format: (value, row) => {
						//console.log(row)
						let properties = row.product_attributes
						let match = properties.find((attr) => attr.id === item.id);
						//console.log(match)
						return match ? `${match.value} ${match.unit ? match.unit : ''}` : '-'
					}
				});
			});
		}
		return columns;
	}, [selectedProduct, link]);

	return (
		<Grid item sx={{
			display: 'grid',
			width: '100%'
		}}>
			{isFetching ?
				<Skeleton />
				:
				<Box
					sx={{
						overflowY: 'auto',
						overflowY: 'auto',
						width: '100%',
						//height: 'calc(100vh - 400px)'
					}}
				>
					<TableContainer
						sx={{
							height: fullHeight ? 'auto' : '300px'
						}}
					>
						<Table stickyHeader aria-label="sticky table" size="small">
							<TableHead sx={{ background: (theme) => theme.palette.table.header }} >
								<TableRow sx={{ background: (theme) => theme.palette.table.header }} >
									{columns.map((column, i) => (
										<TableCell
											key={i}
											align={column.align}
											sx={{
												background: (theme) => theme.palette.primary.main,
												color: (theme) => theme.palette.primary.contrastText,
												whiteSpace: 'nowrap'
											}}
										>
											<Box
												sx={{
													height: '80%',
													marginTop: 0,
													marginBottom: 0,
													width: '1px',
													background: (theme) => theme.palette.primary.light,
													position: 'absolute',
													left: 0,
													top: '10%'
												}}
											/>
											<strong>{column.label}</strong>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody  >
								{variants && variants.map((row, i) => {
									return (
										<TableRow key={i}
											sx={{
												background: (theme) => (row.product_id === selectedProduct?.product_id) ? theme.palette.table.out : theme.palette.table.row
											}}
										>
											{columns.map((column, i) => {
												const value = row[column.id];
												//console.log(value)
												return (
													<TableCell
														sx={{ position: 'relative', minWidth: '100px' }}
														key={i} align={column.align}>
														<Box
															sx={{
																height: '80%',
																marginTop: 0,
																marginBottom: 0,
																width: '1px',
																background: (theme) => (row.product_id === selectedProduct?.product_id) ? '#fff' : theme.palette.table.header,
																position: 'absolute',
																left: 0,
																top: '10%'
															}}
														/>
														{column.format(value, row)}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			}
		</Grid>

	);
}

export default ProductVariants