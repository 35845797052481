import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import { useWindowResize } from '../../shop/libs/useWindowResize'
import { ShopContext } from "../../store/ShopContext";
import { UPDATE_FILTERS } from "../../store/ShopReducers";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { ProductsContext } from "../../store/ProductsContext";
import { matchSorter } from "match-sorter";

const Row = ({ filtered, index, setSize, windowWidth, handleChange, field }) => {

    //console.log(filtered)
    const rowRef = useRef();
    const isEven = index % 2 === 0;
    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [setSize, index, windowWidth]);

    return (
        <Box
            ref={rowRef}
            sx={{
                backgroundColor: (theme) => isEven ? theme.palette.background.paper : theme.palette.background.default
            }}
        >
            <FormControlLabel
                sx={{
                    padding: '0 10px',
                    width: '100%',
                    lineHeight: 1
                }}
                control={
                    <Checkbox size="small"
                        checked={filtered[index].c}
                        onChange={(e, checked) => {
                            handleChange(!filtered[index].c, filtered[index].v, field);
                        }}
                        name={filtered[index].v} />
                }
                label={
                    <Grid
                        sx={{ lineHeight: 1 }}
                    >
                        <Typography
                            variant="body1"
                        > {filtered[index].n ? filtered[index].n : filtered[index].v}</Typography>
                    </Grid>
                }
            />
        </Box>
    );
};

const ResultFiltersCard = ({ field, index }) => {

    //console.log(field)
    const listRef = useRef();
    const [checkboxes, setCheckboxes] = useState();
    const [filtered, setFiltered] = useState();
    const { filters, dispatchFilters } = useContext(ShopContext);
    const { products, dispatchProducts } = useContext(ProductsContext);
    const { dispatchLayout } = useContext(LayoutContext)

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log(field)
        let fieldValue = ''
        //console.log(products.productFilters)
        let boxes = field.DistinctFilterValues.map((item) => {
            //console.log(item)
            //console.log(fieldValue)
            //{"h": field.FilterHeader, "u": field.FilterUnit, "v": addValue}
            if (fieldValue.includes(item.DistinctFilterValues)) {

                return ({
                    "i": field.FilterID, //ID
                    'h': field.FilterHeader, //Header
                    'v': item, //Value
                    'u': field.FilterUnit, //Unit
                    'c': true, // Checked
                    "s": true //Show
                })
            }
            return ({
                "i": field.FilterID,
                'h': field.FilterHeader,
                'v': item,
                'u': field.FilterUnit,
                'c': false,
                "s": false
            })
        })
        const sorted = boxes.sort((a, b) => {
            return a.v.localeCompare(b.v, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        })
        setCheckboxes(sorted)
        setFiltered(sorted)
    }, [field]);

    const handleChange = (checked, value, field) => {

        setCheckboxes(current => {
            let copy = [...current]
            let index = copy.findIndex((item) => item.v === value)
            copy[index].c = checked
            return copy
        })
        //console.log(filters.productFilters)
        //see if there is already a selected filter 
        //let index = (filters.productFilters).findIndex((item) => (item.h === field.FilterHeader && item.u === field.FilterUnit));
        let index = (filters.productFilters).findIndex((item) => (item.i === field.FilterID));
        //console.log(index)
        if (index > -1) {
            //ticked - add if there is already this filter in productFilters
            if (checked) {
                //console.log('checked + add value')
                filters.productFilters[index].v = [...filters.productFilters[index].v, value] //add value to clone
            } else {
                //console.log('unchecked + remove value')
                if (Object.values(filters.productFilters[index].v).length === 1) {

                    //only 1 values so remove filter from clone
                    filters.productFilters.splice(index, 1)
                    //delete filters.productFilters[index]
                } else {
                    // console.log('remove one')
                    let valuesLeft = filters.productFilters[index].v.filter((current) => current !== value)
                    filters.productFilters[index].v = valuesLeft //add value to clone
                }
            }
        } else {
            if (checked) {
                //console.log('checked + new value')
                //add if not already in selectedFilters
                filters.productFilters.push({
                    "i": field.FilterID,
                    "t": field.FilterType,
                    "h": field.FilterHeader,
                    "u": field.FilterUnit,
                    "v": [value],
                    "s": true
                })
            }
        }
        //console.log(filters.productFilters)
        dispatchFilters({
            type: UPDATE_FILTERS,
            payload: filters.productFilters
        });
        dispatchProducts({
            type: UPDATE_PRODUCTS,
            payload: {
                offset: 0,
            }
        });
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1,
            }
        });
    }

    const onFilterMouseUp = (e) => {
        //console.log(e.target.value)
        const value = e.target.value;
        console.log(value)

        let filtered = matchSorter(checkboxes, value, { keys: ['v'] });

        filtered = filtered.filter(item => item.c !== true)
        console.log(filtered)

        let selected = checkboxes.filter(box => box.c === true);
        console.log(selected)

        if (!selected) {
            const sorted = checkboxes.sort((a, b) => {
                return a.v.localeCompare(b.v, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            })
            setFiltered(sorted)
        } else {
            let merged = [...selected, ...filtered]
            //console.log(merged)
            setFiltered(merged)
        }
    };

    const sizeMap = useRef({});
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = index => sizeMap.current[index] || 40;
    const [windowWidth] = useWindowResize();


    return (
        <>
            <Grid item xs={12}>
                <Typography sx={{ color: 'text.primary' }} >
                    <span style={{ textTransform: 'capitalize' }}>{field.FilterHeader}</span> {field.FilterUnit ? `(${field.FilterUnit})` : ''}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {checkboxes?.length > 5 ?
                    <TextField
                        sx={{
                            backgroundColor: "background.paper",
                            '& legend': {
                                display: 'none'
                            },
                            marginBottom: '5px'
                        }}
                        fullWidth
                        size="small"
                        placeholder={`Filter ${field.FilterHeader}..`}
                        onKeyUp={onFilterMouseUp}
                        type={field.Unit ? 'number' : 'text'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{field.FilterUnit}</InputAdornment>,
                        }}
                    />
                    : null}
                {filtered?.length ?
                    <VariableSizeList
                        ref={listRef}
                        height={filtered?.length > 5 ? 160 : filtered?.length * 39}
                        width="100%"
                        itemCount={filtered?.length}
                        itemSize={getSize}
                        itemData={filtered}
                        style={{
                            overflowX: 'hidden',
                            border: '1px solid #ccc',
                            width: '100%',
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary
                        }}
                    >
                        {({ data, index, style }) => (
                            <div style={style}>
                                <Row
                                    filtered={data}
                                    index={index}
                                    setSize={setSize}
                                    windowWidth={windowWidth}
                                    handleChange={handleChange}
                                    field={field}
                                />
                            </div>
                        )}
                    </VariableSizeList>
                    : null}
            </Grid>
        </>
    );
};

export default ResultFiltersCard;
