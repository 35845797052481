import { LoadingDots } from './../reusable/LoadingDots';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
	primary: {
		fill: `${theme.palette.primary.contrastText}`
	},
	secondary: {
		fill: `${theme.palette.secondary.contrastText}`
	}
}));

const LoadingSuccessButton = ({
	loading,
	text,
	color,
	textVariant,
	className,
	iconSize,
	icon,
	fullWidth,
	type,
	disabled,
	data,
	...props
}) => {


	const [success, setSuccess] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (data) {
			//console.log(data)
			setSuccess(true);
			setTimeout(() => {
				setSuccess(false);
			}, 2000)
		}
	}, [data])

	return (
		<Button
			variant="contained"
			type={type}
			fullWidth={fullWidth}
			color={success ? 'success' : color ? color : 'primary'}
			className={className}
			disabled={disabled}
			{...props}
		>
			<span>
				{loading ? (
					<LoadingDots
						className={
							color === 'primary' ? classes.primary : classes.secondary
						}
						width={40}
					/>
				) : (
					<>
						{success ? setLanguageText("Saved") : setLanguageText(text)}
					</>
				)}
			</span>
			<>
				{icon && !loading && !success ? (
					<FontAwesomeIcon style={{ marginLeft: '5px' }} icon={icon} size={iconSize} />
				) : null}

				{success ? (
					<FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faCheck} size={iconSize} />
				) : null}
			</>
		</Button>
	);
};

export default LoadingSuccessButton;
