import {
    Box,
    Grid,
    TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from "formik"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import AccessAlert from './AccessAlert';
import options from './options.json';

const UserDynamicForm = ({ roles, name }) => {

    const { values, touched, errors, setFieldValue } = useFormikContext();
    const [availableRoles, setAvailableRoles] = useState(null);

    useEffect(() => {
        if (roles) {
            setAvailableRoles(roles);
        }
    }, [roles])

    return (

        <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} lg={availableRoles ? 6 : 12}>
                <Autocomplete
                    disableClearable
                    options={options}
                    getOptionLabel={options => options.label ? options.label : ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={options.find(option => option.id === values[name]?.access) || null}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > svg': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                            {option?.color &&
                                <FontAwesomeIcon icon={faCircle} style={{ color: option?.color }} />
                            }
                            {option?.label}
                        </Box>
                    )}
                    renderInput={(params) =>
                        <TextField
                            required {...params}
                            variant="outlined"
                            name={`${name}.access`}
                            label="Select Users Access"
                            error={touched?.[name]?.access && Boolean(errors?.[name]?.access)}
                            helperText={touched?.[name]?.access && errors?.[name]?.access}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: <FontAwesomeIcon icon={faCircle} style={{ color: params?.InputProps?.endAdornment?.props?.ownerState?.value?.color }} />
                            }}
                        />
                    }
                    onChange={(e, value) => {
                        setFieldValue(`${name}.access`, value.id);
                        if (value.id !== 1) {
                            setFieldValue(`${name}.role`, null);
                        } else if (availableRoles) {
                            setFieldValue(`${name}.role`, availableRoles[0].id);
                        }
                    }}
                />
            </Grid>
            {availableRoles ?
                <Grid item xs={12} lg={6}>
                    <Autocomplete
                        disableClearable
                        disabled={values?.[name]?.access !== 1}
                        options={availableRoles}
                        getOptionLabel={options => options.role_name ? options.role_name : ''}
                        isOptionEqualToValue={(option, value) => option.role_group === value.role_group}
                        value={availableRoles.find(option => option.role_id === values?.[name].role) || null}
                        renderInput={params => (
                            <TextField
                                required {...params}
                                variant="outlined"
                                name={`${name}.role`}
                                label="Select Role"
                                error={touched?.[name]?.role && Boolean(errors?.[name]?.role)}
                                helperText={touched?.[name]?.role && errors?.[name]?.role}
                            />
                        )}
                        onChange={(e, value) => {
                            setFieldValue(`${name}.role`, value.role_id);
                        }}
                    />
                </Grid>
                : null}
            <AccessAlert id={values?.[name]?.access} />

        </Grid>
    );

}
export default UserDynamicForm;