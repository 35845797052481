import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import DeleteTemplate from './DeleteTemplate';
import DotsMenu from '../reusable/DotsMenu';
import EditTemplate from './EditTemplate';


const TemplateTable = ({ templates }) => {


    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const [count, setCount] = useState()
    const defaultData = useMemo(() => [], [])
    const pagination = useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize])


    const columns = useMemo(() => [
        // {
        //     accessorKey: 'id',
        //     header: () => 'Template ID',
        //     cell: info => info.getValue(),
        // },
        {
            header: () => 'Template Name',
            accessorKey: 'name',
            cell: info => info.getValue(),
        },
        {
            header: () => 'Template Description',
            accessorKey: 'description',
            cell: info => info.getValue(),
        },
        {
            header: () => 'Template Type',
            accessorKey: 'type',
            cell: info => {
                //console.log(info)
                return info.row.original.type === "engineer_brands" ? "Engineer" : info.row.original.type === "user_permissions" ? "User Permissions" : info.row.original.type === "opensearch_brands" ? "Search" : "Catalogue"
            }
        }, {
            header: () => 'Actions',
            accessorKey: 'id',
            cell: info => {
                return (
                    <Grid container spacing={1}>
                        <DotsMenu>
                            <DeleteTemplate template={info.row.original} refetch={templates.refetch} />
                            <EditTemplate template={info.row.original} refetch={templates.refetch} />
                        </DotsMenu>
                    </Grid>
                )
            }
        }
    ], [])


    useEffect(() => {
        if (templates.data) {
            setCount(templates?.data[0]?.order_count)
        } else {
            setCount(0)
        }
        return () => {
            setCount(0)
        }

    }, [templates.data])

    //console.log(dataQuery.data)

    const table = useReactTable({
        data: templates?.data ?? defaultData,
        columns,
        pageCount: templates.data?.lenght ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })

    return (

        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        component="div"
                        count={count ? count : -1}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    //ActionsComponent={TablePaginationActions}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table" >
                            <TableHead>
                                {table.getHeaderGroups().map((headerGroup, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {headerGroup.headers.map((header, i) => {
                                            return (
                                                <TableCell key={i} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {/* {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null} */}
                                                        </>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {templates.isInitialLoading ?
                                    //create array from a number
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>

                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !templates.isInitialLoading && templates?.data.length > 0 ? table.getRowModel().rows.map((row, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell, i) => {
                                                    return (
                                                        <TableCell key={i}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                        :
                                        <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No orders found</Alert>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TemplateTable;


// const TemplateTable = ({
//     columns,
//     data,
//     hidden,
//     sortColumn,
//     sortOrder,
//     handleSorting
// }) => {
//     //console.log(sortColumn)
//     //console.log(sortOrder)
//     //Render the UI for your table
//     return (
//         <Box
//             sx={{
//                 overflowY: 'auto',
//                 overflowY: 'auto',
//                 width: '100%',
//                 //height: 'calc(100vh - 400px)'
//             }}
//         >
//             <TableContainer sx={{
//                 maxHeight: '500px',
//                 //border: (theme) => `1px solid ${theme.palette.table.border}`
//             }}>
//                 <Table stickyHeader aria-label="sticky table" size="small">
//                     <TableHead>
//                         <TableRow
//                             sx={{ background: (theme) => theme.palette.background.header }}
//                         >
//                             {/* <TableCell
//                                 sx={{
//                                     background: theme => theme.palette.primary.main,
//                                     color: (theme) => theme.palette.primary.contrastText,
//                                 }}
//                             >
//                             </TableCell> */}
//                             {columns.map((column, i) => {
//                                 //console.log(column)
//                                 if (!column.hidden && !hidden.includes(column.accessor)) {
//                                     //console.log(name)
//                                     return (
//                                         <TableCell
//                                             key={i}
//                                             padding={column.disablePadding ? 'none' : 'normal'}
//                                             sx={{
//                                                 background: theme => theme.palette.primary.main,
//                                                 color: (theme) => theme.palette.primary.contrastText,
//                                                 '&:hover ': {
//                                                     color: (theme) => theme.palette.primary.contrastText,
//                                                 },
//                                                 maxWidth: column.maxWidth ? column.maxWidth : 'auto'
//                                             }}
//                                         >
//                                             <Box
//                                                 sx={{
//                                                     height: '80%',
//                                                     marginTop: 0,
//                                                     marginBottom: 0,
//                                                     width: '1px',
//                                                     background: (theme) => theme.palette.primary.light,
//                                                     position: 'absolute',
//                                                     left: 0,
//                                                     top: '10%'
//                                                 }}
//                                             />
//                                             {!column.noSort && column.accessor ?
//                                                 <TableSortLabel
//                                                     active={sortColumn === column.accessor}
//                                                     direction={sortColumn === column.accessor ? sortOrder : "asc"}
//                                                     onClick={(e) => handleSorting(column)}
//                                                 >
//                                                     {column.Header ? column.Header() : column.name}
//                                                 </TableSortLabel>
//                                                 :
//                                                 column.Header ? column.Header() : column.name
//                                             }
//                                         </TableCell>
//                                     )
//                                 }
//                             })}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {data && columns && data.map((item, ind) => {
//                             return (
//                                 <CustomRow
//                                     key={ind}
//                                     item={item}
//                                     columns={columns}
//                                     hidden={hidden}
//                                 />
//                             )
//                         })}

//                     </TableBody>
//                 </Table >
//             </TableContainer>
//         </Box>
//     );
// }

// export default TemplateTable