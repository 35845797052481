import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Card,
	CardContent,
	Grid,
	TableBody,
	TableCell,
	Table,
	TableRow,
} from '@mui/material';
import React, { useContext } from 'react';
import PageContent from '../PageContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles, makeStyles } from '@mui/styles';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);



const Diagnostic = () => {

	const classes = useStyles();
	const { language } = useContext(LayoutContext)

	return (
		<>
			<InnerHeaderWrap>
				<Grid item container alignItems="stretch" spacing={2}>
					<Grid item style={{ alignSelf: 'center' }}>
						<Typography variant="h5">{setLanguageText("Diagnostic")}</Typography>
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent className="reports">
				<Grid item container spacing={2}>
					<Card
						sx={{
							margin: '1em',
							padding: '1em'
						}}
					>
						<CardContent>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography className={classes.heading}>{setLanguageText("Excessive misting")}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Table className={classes.table} aria-label="customized table">
										<TableBody>
											<TableRow sx={{ background: (theme) => theme.palette.table.row }}>
												<StyledTableCell align="left">{setLanguageText("Concentration")}</StyledTableCell>
												<StyledTableCell align="left">{setLanguageText("Adjust")}.....</StyledTableCell>
											</TableRow>
											<TableRow sx={{ background: (theme) => theme.palette.table.row }} >
												<StyledTableCell align="left">{setLanguageText("Temperature")}</StyledTableCell>
												<StyledTableCell align="left">{setLanguageText("Ensure")}....</StyledTableCell>
											</TableRow>
										</TableBody>
									</Table>
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
								>
									<Typography className={classes.heading}>{setLanguageText("Corrosion")}</Typography>
								</AccordionSummary>
								<AccordionDetails>
								</AccordionDetails>
							</Accordion>
						</CardContent>
					</Card>
				</Grid>
			</PageContent>

		</>
	);

};

export default Diagnostic;
