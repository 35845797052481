import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import { snakeCase } from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useQuery } from '@tanstack/react-query';
import { CompanyContext } from '../../../store/CompanyContext';

const DropdownAdditivesByType = ({ selectedType, setSelectedAdditive, selectedAdditive }) => {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);

	const { data } = useQuery({
		queryKey: ['additive types', selectedType?.path],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				type: 2,
				additive_type: selectedType?.path,
				company_id: selectedCompany.company_id,
				get_list: true
			}
		}),
		enabled: !!selectedType?.path,
		retry: false
	})

	const onSelectCoolant = (e, coolant) => {
		setSelectedAdditive(coolant);
	};

	return (
		<Autocomplete
			disableClearable
			id="additives"
			options={data || []}
			getOptionLabel={option => option.product_name || ''}
			renderInput={params => (
				<TextField
					required
					{...params}
					//label={setLanguageText("Select Additive")}
					variant="outlined"
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Additive")}
				/>
			)}
			value={selectedAdditive}
			onChange={onSelectCoolant}
		/>
	);
};

export default DropdownAdditivesByType;
