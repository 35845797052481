import React, {
	useContext,
	useEffect,
	useCallback,
	useMemo,
	useState
} from 'react';
import { Grid, Typography, Button, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { MachineContext } from '../../store/MachineContext';
import { CoolantContext } from '../../store/CoolantContext';
import MachineDefaults from './MachineDefaults';
import CoolantSettings from './CoolantSettings';
import { useNavigate, useParams } from 'react-router-dom';
import {
	ADD_ENTRIES,
	SELECTED_MACHINE,
	SELECTED_MACHINES
} from '../../store/MachineReducers';
import EntriesTable from './EntriesTable/EntriesTable';
import PageContent from '../../PageContent';
import { CompanyContext } from '../../../store/CompanyContext';
import entriesTable_columns from './EntriesTable/entriesTable_columns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChartScatter, faPlus } from '@fortawesome/pro-light-svg-icons';
import orderList from '../../helpers/orderList';
import { useTheme } from '@mui/styles';
import useTrampChart from '../../charts/useTrampChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartCard from '../../charts/ChartCard';
import useBacteriaChart from '../../charts/useBacteriaChart';
import usePhChart from '../../charts/usePhChart';
import useConcChart from '../../charts/useConcChart';
import useFungiChart from '../../charts/useFungiChart';
import LoadingScreen from '../../reusable/LoadingScreen';
import ExportSingleMachine from '../../Exports/ExportSingleMachine';
import AddCoolant from './AddCoolant';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import { LayoutContext } from '../../../store/LayoutContext';
import InnerHeaderWrap from '../../../layout/InnerHeaderWrap';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import axiosRequest from '../../../axios/axoisRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import config from '../../../config';
Chart.register(...registerables)
import { isMobileOnly } from 'react-device-detect';
import EditMachine from './EditMachine';
import getUserLevel from '../../../helpers/getUserLevel';
import GlobalCompanySelect from '../../../reusable/GlobalCompanySelect';
import DropdownMachines from '../../dashboard/Selection_Row/DropdownMachines';
import DropdownGroups from '../../dashboard/Selection_Row/DropdownGroups';
import { SELECTED_COMPANY } from '../../../store/CompanyReducers';


export default function MachineDetails() {

	const theme = useTheme();
	const { dispatchSelectedMachine, selectedMachine, dispatchSelectedMachines } = useContext(MachineContext);
	const { selectedCoolant } = useContext(CoolantContext);
	const { user } = useContext(UserContext);
	const { selectedCompany, companies, dispatchSelectedCompany } = useContext(CompanyContext);
	const { language } = useContext(LayoutContext)
	const [plots, setPlots] = useState(true);
	const [tooltips, setTooltips] = useState(true);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const { createTrampChart, trampData, trampOptions, trampChartRef } =
		useTrampChart({ plots, tooltips });

	const {
		createBacteriaChart,
		bacteriaData,
		bacteriaOptions,
		bacteriaChartRef
	} = useBacteriaChart({ plots, tooltips });

	const { createPhChart, phData, phOptions, phChartRef } = usePhChart({
		plots,
		tooltips
	});

	const { createConcChart, concData, concOptions, concChartRef } = useConcChart({
		plots,
		tooltips
	});

	const { createFungiChart, fungiData, fungiOptions, fungiChartRef } =
		useFungiChart({
			plots,
			tooltips
		});

	const [dateFrom, setDateFrom] = useState();
	const [dateTo, setDateTo] = useState();

	let { id } = useParams();

	//gets companies full details
	// const getCompanyDetails = useMutation((company) => {
	// 	//console.log(user)
	// 	return axiosRequest({
	// 		method: 'get',
	// 		endpoint: 'companies',
	// 		gateway: config.coolantAPI.URL,
	// 		api_key: user.api_key,
	// 		params: {
	// 			id: company.company_id === 0 ? 'zero' : company.company_id,
	// 			// 0 company doesn't exist in the database , so fetch atrributes from another company
	// 			company_name: company.company_id === 0 ? company.company_name : null
	// 		}
	// 	})
	// }, {
	// 	onSuccess: (data) => {
	// 		dispatchSelectedCompany({
	// 			type: SELECTED_COMPANY,
	// 			payload: data[0]
	// 		});
	// 		window.sessionStorage.setItem('selectedCompany', data[0].company_id);
	// 	}
	// })

	//get machine
	const { data: machine, isFetching: isFecthingMachine } = useQuery({
		queryKey: ['machine', id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'machines',
			api_key: user.api_key,
			method: 'get',
			params: {
				machine_id: id,
				allow_access: user.user.user_permissions.administrator || user.user.user_permissions.access.all_companies || user.user.user_permissions.access.companies.includes(selectedCompany.company_id) ? true : false
			}
		}),
		enabled: !!user.user.user_permissions && !!id,
		retry: false
	})

	useEffect(() => {
		if (machine) {
			//console.log(machine)
			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: {
					...machine[0],
					machine_settings: machine[0]?.machine_settings && machine[0].machine_settings
				}
			});
			let lastEntry = new Date()
			let lastYear = new Date().setMonth(lastEntry.getMonth() - 12);

			if (machine[0]?.last_entry?.date_stamp) {
				lastEntry = new Date(machine[0]?.last_entry?.date_stamp)
				lastYear = new Date(machine[0]?.last_entry?.date_stamp).setMonth(lastEntry.getMonth() - 12);
			}
			setDateFrom(lastYear);
			setDateTo(lastEntry.getTime());

			// save in context, this is used in Dashboard charts
			dispatchSelectedMachines({
				type: SELECTED_MACHINES,
				payload: {
					...machine[0],
					machine_settings: machine[0]?.machine_settings
				}
			});
			setLoading(false);

		}
	}, [machine])

	// useEffect(() => {
	// 	//for mobile and QR scanner only
	// 	if (isMobileOnly) {
	// 		if (selectedCompany?.company_id && machine?.[0]?.company_id) {

	// 			if (selectedCompany?.company_id !== machine?.[0]?.company_id) {
	// 				//console.log("company id not the same")
	// 				//find company
	// 				let selected = companies.find(company => company.company_id === machine[0].company_id)
	// 				console.log(selected)
	// 				getCompanyDetails.mutate(selected)

	// 			}
	// 		}
	// 	}
	// }, [machine, companies, selectedCompany, isMobileOnly])


	//get machine entries
	const { data: entries, isFetching, isFetched } = useQuery({
		queryKey: ['machine entries', machine?.[0]?.machine_id, dateFrom, dateTo],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'get',
			params: {
				machine_id: machine?.[0]?.machine_id,
				date_from: dateFrom,
				date_to: dateTo,
				voided: true,
				allow_access: user.user.user_permissions.administrator || user.user.user_permissions.access.all_companies || user.user.user_permissions.access.companies.includes(selectedCompany.company_id) ? true : false
			}
		}),
		enabled: !!machine?.[0]?.machine_id && !!dateFrom && !!dateTo,
		retry: false
	})

	useEffect(() => {
		if (entries && entries?.length) {
			dispatchSelectedMachine({
				type: ADD_ENTRIES,
				payload: orderList(entries)
			});
		} else {
			dispatchSelectedMachine({
				type: ADD_ENTRIES,
				payload: []
			});
		}
	}, [entries, isFetched])

	const getTableDataFn = useCallback(
		tablePage => {
			if (tablePage) {
				//setEntries(tablePage);
				createTrampChart(tablePage);
				createBacteriaChart(tablePage);
				createPhChart(tablePage);
				createConcChart(tablePage);
				createFungiChart(tablePage);
				//setTablePage(tablePage);
			}
		},
		[
			createBacteriaChart,
			createTrampChart,
			createPhChart,
			createConcChart,
			createFungiChart
		]
	);


	const entriesColumns = useMemo(
		() => entriesTable_columns({
			selectedCompany: selectedCompany,
			selectedMachine: selectedMachine,
			language: language,
			user: user
		}),
		[selectedCompany, selectedMachine, user]
	);

	//Show and hide plots
	const handlePlots = plots => {
		setPlots(prevState => !prevState);
	};
	//Show and hide tooltips
	const handleTooltips = plots => {
		setTooltips(prevState => !prevState);
	};

	return (
		<Grid item container direction="column">
			<InnerHeaderWrap>
				<Grid container spacing={2} >
					<Grid
						item
						container
						alignItems="center"
					>
						{!isMobileOnly ?
							<Grid item xs={12} md={3}>
								<Button
									variant='contained'
									color="secondary"
									onClick={e => navigate('/coolantcare/machines')}
									// size="small"
									startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
								>
									Back to Machines
								</Button>
							</Grid>
							: null}
						<Grid item xs={12} md={6}>
							<Grid item sx={{ textAlign: 'center' }}>
								<Typography
									sx={{ display: 'inline-block', color: (theme) => theme.palette.text.primary }}
									variant={isMobileOnly ? 'h6' : 'h3'}
								>
									{selectedMachine?.machine_name}
								</Typography>
							</Grid>
							<Grid item sx={{ textAlign: 'center' }}>
								<Typography sx={{ color: (theme) => theme.palette.text.primary }}  >{selectedMachine?.machine_group}</Typography>
							</Grid>
						</Grid>
						{!isMobileOnly ?
							<Grid item container spacing={2} xs={12} md={3} justifyContent="flex-end">

								{getUserLevel(user.user, 'coolantcare', 'machine_edit', 7) ?
									<Grid item>
										{selectedMachine ? <EditMachine selectedMachine={selectedMachine} /> : null}
									</Grid>
									: null}
								<Grid item>
									<ExportSingleMachine
										//tablePage={tablePage}
										entries={entries}
									/>
								</Grid>
							</Grid>

							: null}

					</Grid>
				</Grid>
				<Box sx={{ opacity: 0, width: 0, height: 0 }}>

				</Box>
				{/* <Grid item >
					<Grid item xs={12} container spacing={2} >
						<Grid item xs={3}>
							<GlobalCompanySelect fullWidth />
						</Grid>
						<Grid item xs={3}>
							<DropdownGroups />
						</Grid>
						<Grid item xs={3}>
							<DropdownMachines updateURL removeAll />
						</Grid>

						{!isMobileOnly ?
							<Grid item xs={3} container justifyContent="flex-end">
								<ExportSingleMachine
									//tablePage={tablePage}
									entries={selectedMachine?.entries}
								/>
							</Grid>
							: null}
					</Grid>
				</Grid>  */}

			</InnerHeaderWrap>
			<PageContent>
				<Grid container spacing={1}>
					{selectedMachine ?
						<Grid item xs={12} md={6}>
							<MachineDefaults selectedMachine={selectedMachine} />
						</Grid>
						: null}
					{selectedMachine ?
						<Grid item xs={12} md={6}>
							<CoolantSettings selectedMachine={selectedMachine} />
						</Grid>
						: null}
					{selectedMachine?.machine_name && !selectedMachine?.coolant_id && !selectedCoolant ?
						<Grid item xs={12} sx={{ position: 'relative' }}>
							<LoadingScreen
								loading={false}
								background={theme.palette.background.lighter}
								colour={theme.palette.text.secondary}
								text="This machine has no coolant set"
								position="relative"
								customAction={
									<Grid container justifyContent="space-between" alignContent="center">
										{getUserLevel(user.user, 'coolantcare', 'machine_edit', 6) ?
											<AddCoolant selectedMachine={selectedMachine} />
											: null}
									</Grid>
								}
							/>
						</Grid>

						: selectedMachine && selectedMachine?.machine_name ?
							<>
								<Grid item xs={12}>
									<Accordion expanded={true}>
										<AccordionSummary>
											<Typography variant={isMobileOnly ? 'p' : 'h6'}>
												{setLanguageText("Machine Entries Table")}:{' '}
												<span style={{ color: '#888' }}>{selectedMachine?.machine_name}</span>
											</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{ borderTop: '1px solid #ececec' }} >
											<Grid item container spacing={1} xs={12}>
												<EntriesTable
													dateFrom={dateFrom}
													setDateFrom={setDateFrom}
													dateTo={dateTo}
													setDateTo={setDateTo}
													columns={entriesColumns}
													data={selectedMachine?.entries}
													getTableDataFn={getTableDataFn}
													headerSettings={['out_of_control', 'factored']}
													loading={loading || isFetching || isFecthingMachine}
												/>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
								<Grid item xs={12} >
									<Accordion expanded={true}>
										<AccordionSummary>
											<Grid container spacing={1}>
												<Grid item xs={12} md={6}>
													<Typography variant={isMobileOnly ? 'p' : 'h6'}>
														{setLanguageText("Charts")}:{' '}
														<span style={{ color: '#888' }}>{selectedMachine?.machine_name}</span>
													</Typography>
												</Grid>
												<Grid item xs={12} md={6} container spacing={2} justifyContent="flex-end">
													<Grid item xs={6} sm={'auto'}>
														<Button
															color="primary"
															variant="contained"
															fullWidth
															onClick={handleTooltips}
															startIcon={<FontAwesomeIcon icon={faChartScatter} />}
														>
															{setLanguageText(`${tooltips ? 'Hide' : 'Show'} Tooltips`)}
														</Button>
													</Grid>
													<Grid item xs={6} sm={'auto'}>
														<Button
															color="primary"
															variant="contained"
															fullWidth
															onClick={handlePlots}
															startIcon={<FontAwesomeIcon icon={faChartScatter} />}
														>
															{setLanguageText(`${plots ? 'Hide' : 'Show'} Plots`)}
														</Button>
													</Grid>
												</Grid>
											</Grid>

										</AccordionSummary>
										<AccordionDetails sx={{ borderTop: '1px solid #ececec' }} >
											<Grid item container spacing={2} xs={12}>

												<Grid
													item
													xs={12}
													md={6}
													sx={{ marginBottom: theme.spacing(2) }}
												>
													<ChartCard>
														<Grid sx={{ textAlign: 'center' }}>
															<Typography variant="h6" >{setLanguageText("Concentration levels")}</Typography>
														</Grid>
														<Line
															ref={concChartRef}
															data={concData}
															options={concOptions}
															plugins={[ChartDataLabels]}
														/>
													</ChartCard>
												</Grid>
												<Grid
													item
													xs={12}
													md={6}
													style={{ marginBottom: theme.spacing(2) }}
												>
													<ChartCard>
														<Grid style={{ textAlign: 'center' }}>
															<Typography variant="h6" >{setLanguageText("PH levels")}</Typography>
														</Grid>
														<Line
															ref={phChartRef}
															data={phData}
															options={phOptions}
															plugins={[ChartDataLabels]}
														/>
													</ChartCard>
												</Grid>
												<Grid
													item
													xs={12}
													lg={4}
													sx={{ marginBottom: theme.spacing(2) }}
												>
													<ChartCard>
														<Grid sx={{ textAlign: 'center' }}>
															<Typography variant="h6" >{setLanguageText("Bacteria levels")}</Typography>
														</Grid>
														<Line
															ref={bacteriaChartRef}
															data={bacteriaData}
															options={bacteriaOptions}
															plugins={[ChartDataLabels]}
														/>
													</ChartCard>
												</Grid>
												<Grid
													item
													xs={12}
													lg={4}
													sx={{ marginBottom: theme.spacing(2) }}
												>
													<ChartCard>
														<Grid sx={{ textAlign: 'center' }}>
															<Typography variant="h6" >{setLanguageText("Fungi levels")}</Typography>
														</Grid>
														<Line
															ref={fungiChartRef}
															data={fungiData}
															options={fungiOptions}
															plugins={[ChartDataLabels]}
														/>
													</ChartCard>
												</Grid>
												<Grid
													item
													xs={12}
													lg={4}
													sx={{ marginBottom: theme.spacing(2) }}
												>
													<ChartCard>
														<Grid sx={{ textAlign: 'center' }}>
															<Typography variant="h6" >{setLanguageText("Tramp oil levels")}</Typography>
														</Grid>
														<Line
															ref={trampChartRef}
															data={trampData}
															options={trampOptions}
															plugins={[ChartDataLabels]}
														/>
													</ChartCard>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</>
							: null}
				</Grid>
			</PageContent >
		</Grid >
	);
};
