import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_ALL_RESULTS_FILTERS } from "../../store/SelectedReducers";

const ResultToggleSearch = () => {

  const { selected, dispatchSelected } = useContext(SelectedContext);
  const toggleFilter = (filter) => {

    let clone = structuredClone(selected.resultFilters)
    //see if there is already a selected filter 
    let index = clone.findIndex((item) => (item.Field === filter.Field));
    //console.log(index)
    if (index > -1) {
      clone[index].Toggle = !clone[index].Toggle
    }
    //set Show to remove / add back to the API call
    dispatchSelected({
      type: UPDATE_ALL_RESULTS_FILTERS,
      payload: clone
    })
  }

  return (
    <>
      {selected?.resultFilters && Object.values(selected.resultFilters).length ?
        <Grid item xs={12} container spacing={1}>
          {selected.resultFilters.map((button, index) => {
            return (
              <Grid item key={index}>
                <Box
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: 'cards.header',
                    //marginRight : '5px', 
                    padding: '5px 10px ',
                    boxShadow: theme => theme.shadows[1],
                    opacity: button.Toggle ? 1 : 0.5

                  }}
                >
                  <Grid item container alignItems="center" spacing={1} >
                    <Grid item>
                      <Typography variant="subtitle2" fontWeight={600}>{button.Name}: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" > {button.ValueNames ? button.ValueNames.toString() : button.Values.toString().replace(',String_Empty', '')} {button.Tolerance ? "±" : ""} {button.Tolerance && button.Tolerance !== "String_Empty" ? button.Tolerance : ""}{button.Unit ? button.Unit : ''}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => toggleFilter(button)}
                        edge="end" aria-label="delete"
                        sx={{ width: '30px', height: '30px', marginRight: '1px' }}
                      >
                        <FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={button.Toggle ? faTimes : faPlus} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          })}
        </Grid>
        : null}
    </>
  );
};

export default ResultToggleSearch;
