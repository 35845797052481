import React, { useCallback, useMemo, useEffect } from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    usePDF
    //PDFDownloadLink,
} from '@react-pdf/renderer';
import createPieChart from '../charts/createPieChart';
import createBarChart from '../charts/createBarChart';
import createHorizontalBar from '../charts/createHorizontalBar';
import formatDate from '../helpers/formatDate';
import setLanguageText from '../../language/setLanguageText';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ReactPDFFallback from './ReactPDFFallback';

const ChartsPDF = ({ chartData, selectedCompany, selectedGroup, orientation, theme, language }) => {


    const index = chartData.findIndex(item => item.id === 'totals');
    const checked = chartData[index].data.has_entries.count
    const not_checked = chartData[index].data.no_entries.count
    const total = checked + not_checked
    const percentage = (checked * 100) / total

    // Create styles
    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingHorizontal: 10,
            height: '100%'
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 10,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        },
        bar_container: {
            width: '100%',
            display: 'flex'
        },
        bar: {
            height: '80px',
            width: '100%',
        },
        container: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignContent: 'space-between',
            flexDirection: 'row'
        },
        charts: {
            width: '30%',
            flexBasis: '30%',
            padding: '10px 20px'
        },
        chart_container: {
            width: '100%',
        },
        chart_header: {
            fontSize: 14,
            width: '100%',
            textAlign: 'center',
            marginBottom: 5
        },
        barchart: {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        },
        header: {
            width: '100%',
            textAlign: 'center',
            margin: '10px 0 0 0'
        },
        header_title: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        header_subtitle: {
            marginTop: 5,
            fontSize: 10
        },
        footer: {
            textAlign: 'center',
            height: '5vh',
            position: 'absolute',
            fontSize: 12,
            bottom: 0,
            left: 0,
            right: 0,
        },
        footer_text: {
            fontSize: 8,
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 'normal'
        }
    });
    const Charts = (chartData) => {


        //console.log(chartData)


        return chartData.map((chart, i) => {
            //console.log(chart)
            let url
            if (chart.chart_type === 'pie') {
                url = createPieChart(chart);
            } else if (chart.chart_type === 'bar') {
                //create bar chart
                url = createBarChart(chart);
            }
            return (
                <>
                    {url ?
                        <View wrap={false} key={i} style={styles.charts} >
                            <Text style={styles.chart_header} >{chart.name}</Text>
                            <View style={styles.chart_container}>
                                <Image src={url} />
                            </View>
                        </View >
                        : null}
                </>
            );
        })
    }

    const Bar = (chartData, theme, checked, not_checked, percentage) => {
        //console.log(attributes)
        let url = createHorizontalBar(chartData, theme, checked, not_checked, percentage, language);
        return (
            <View style={styles.bar}>
                <Image style={styles.barchart} src={url} />
            </View>
        );
    }

    const document = useMemo(() =>
        <Document title={setLanguageText("Dashboard Export")}>
            <Page style={styles.body} orientation={`${orientation}`}>
                <View style={styles.header}>
                    <Text style={styles.header_title}>{setLanguageText("Dashboard Report")} / {selectedCompany.company_name} / {selectedGroup.group_name}({total})</Text>
                    <Text style={styles.header_subtitle}>{setLanguageText("Total Machines")}: {total}</Text>
                </View>
                <View style={styles.bar_container}>
                    {Bar(chartData, theme, checked, not_checked, percentage)}
                </View>
                <View style={styles.container}>
                    {Charts(chartData)}
                </View>
                <View fixed style={styles.footer}>
                    <Text style={styles.footer_text}>
                        {setLanguageText("Dashboard Export")} {formatDate(Date.now())}
                    </Text>
                </View>
            </Page>
        </Document>,
        [styles, chartData, orientation, theme, checked, not_checked, percentage]);

    const [instance, updateInstance] = usePDF({ document });

    useEffect(() => {
        if (document) {
            updateInstance(document);
        }
    }, [document]);

    return (
        <>
            {instance?.url ?
                <>
                    {isMobileOnly || isTablet ?
                        <ReactPDFFallback url={instance.url} />
                        :
                        <object
                            data={instance.url}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                        </object>
                    }
                </>
                : null}
        </>
    )
};
export default ChartsPDF;
