import { Fade, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { UserContext } from '../store/UserContext';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import { useMutation, useQuery } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import FileCard from './FileCard';
import ResourcesTree from './ResourcesTree';
import AddResource from './AddResource';
import FileTypes from './FileTypes';
import ResourcesSortableTree from './ResourcesSortableTree';
import ResourceBreadcrumbs from './ResourceBreadcrumbs';
import { useSearchParams } from "react-router-dom";
import useSignedUrl from '../hooks/useSignedUrl';
import GlobalDialog from '../reusable/GlobalDialog';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ReactPDFFallback from '../coolantcare/pdf/ReactPDFFallback';

const Resources = () => {

	const { user } = useContext(UserContext);
	const [resources, setResources] = useState();
	const [visibleResources, setVisibleResources] = useState([]);
	const [fileTypeValue, setFileTypeValue] = useState(0);
	const [categories, setCategories] = useState([]);
	const [edit, setEdit] = useState(false);
	const [openLevels, setOpenLevels] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedCategory, setSelectedCategory] = useState();
	const { getSignedUrl } = useSignedUrl();

	//set selected category from url params
	useEffect(() => {
		let urlParams = new URLSearchParams(searchParams.toString())
		let category = urlParams.get('cid')
		if (category) {
			setSelectedCategory(category)
		} else if (categories && Object.values(categories).length && !category) {
			const resource_id = sessionStorage.getItem('resource_id')
			if (resource_id) {
				setSearchParams({ 'cid': resource_id })
			} else {
				//set default category if no url params are set
				setSearchParams({ 'cid': categories[0].resource_cat_id })
			}

		}
	}, [searchParams, categories])

	const mutation = useMutation(() => {
		return axiosRequest({
			method: 'get',
			endpoint: 'resources',
			gateway: config.factoryAPI.URL,
			api_key: user.api_key,
			params: {
				get_categories: true
			}
		})
	}, {
		onSuccess: (response) => {
			let levels = []
			response.forEach((item) => {
				levels.push(item.resource_cat_id)
			})
			setOpenLevels(levels)
			setCategories(response)
		}
	})

	//get comments
	const { data, isLoading } = useQuery(['resources', selectedCategory],
		({ signal }) => axiosRequest({
			signal: signal,
			method: 'get',
			endpoint: 'resources',
			gateway: config.factoryAPI.URL,
			api_key: user.api_key,
			params: {
				resource_cat: selectedCategory
			}
		}), {
		retry: 1,
		enabled: !!selectedCategory
	})

	useEffect(() => {
		mutation.mutate()
	}, [])

	useEffect(() => {
		if (data?.resource) {
			//console.log(data)
			let clone = structuredClone(data.resource)
			clone = clone.map((item) => {
				return ({
					...item,
					"resource_settings": item?.resource_settings ? item.resource_settings[0] : null
				})
			})
			//console.log(clone)
			setResources(clone)

		}
	}, [data])

	useEffect(() => {
		if (resources) {
			if (fileTypeValue) {
				// const options = [
				//   { value: 0, name: 'All Files' },
				//   { value: 3, name: 'Announcements' },
				//   { value: 4, name: 'Promotions' },
				//   { value: 1, name: 'PDFs' },
				//   { value: 2, name: 'Videos' }
				// ];

				let clone = structuredClone(resources)
				//console.log(clone)

				if (fileTypeValue === 1) {
					//filter by PDF
					let pdfOnly = clone.filter((item) => item.resource_type === 'application/pdf')
					setVisibleResources(pdfOnly)
				} else if (fileTypeValue === 2) {
					let videosOnly = clone.filter((item) => item.resource_type == 'video/mp4')
					setVisibleResources(videosOnly)
				} else if (fileTypeValue === 3) {
					let announcementOnly = clone.filter((item) => item?.resource_settings?.resource_announcement === true)
					setVisibleResources(announcementOnly)
				} else if (fileTypeValue === 4) {
					let promotionsOnly = clone.filter((item) => item?.resource_settings?.resource_promotion === true)
					setVisibleResources(promotionsOnly)
				}
			} else {
				//all
				setVisibleResources(resources)
			}
		}
	}, [resources, fileTypeValue])

	const refetchCategories = () => {
		mutation.mutate()
	}

	const refreshResources = () => {
		let current = selectedCategory
		setSelectedCategory()
		setSelectedCategory(current)
	}

	const [open, setOpen] = useState(false);
	const [signedUrl, setSignedUrl] = useState();
	const [selectedResource, setSelectedResource] = useState();

	const showResource = async (resource) => {
		let extension = resource.resource_type.split('/')[1]
		let url = await getSignedUrl(config.s3.BUCKET, `public/resources/${resource.resource_id}.${extension}`)
		//console.log(url)
		setSelectedResource(resource)
		setSignedUrl(url)
		setOpen(true)
	};

	return (
		<>
			<InnerHeaderWrap title="Resources">
				<Grid container item xs={12} spacing={2} alignItems="center"  >
					<Grid item xs={12} md>
						{selectedCategory && categories ?
							<ResourceBreadcrumbs
								categories={categories}
								selectedCategory={selectedCategory}
								setSearchParams={setSearchParams}
							/>
							: null}
					</Grid>
					<Grid container spacing={2} alignItems="center" justifyContent="flex-end" item xs={12} md>
						{user?.user?.user_permissions?.administrator && (
							<Grid item >
								<AddResource
									edit={edit}
									categories={categories}
									setCategories={setCategories}
									refreshResources={refreshResources}
									refetchCategories={refetchCategories}
									selectedCategory={selectedCategory}
								/>
							</Grid>
						)}
						<Grid item >
							<FileTypes
								edit={edit}
								fileTypeValue={fileTypeValue}
								setFileTypeValue={setFileTypeValue}
							/>
						</Grid>
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid container item spacing={2} xs={12}>
					<Grid item xs={12} md="auto">
						{categories && selectedCategory ?
							<ResourcesTree
								categories={categories}
								selectedCategory={selectedCategory}
								setSearchParams={setSearchParams}
								setOpenLevels={setOpenLevels}
								openLevels={openLevels}
								edit={edit}
								setEdit={setEdit}
							/>
							: null}

						<ResourcesSortableTree
							categories={categories}
							setEdit={setEdit}
							open={edit}
							refetchCategories={refetchCategories}
						//selectedCategory={selectedCategory}
						//setSelectedCategory={setSelectedCategory}
						/>

						{/* {user?.user?.user_permissions?.administrator &&
							<Grid item sx={{ marginTop: '1em' }}>
								<Button
									fullWidth
									disabled={edit}
									color='primary'
									variant='contained'
									onClick={() => setEdit(true)}
									startIcon={<FontAwesomeIcon icon={faPencil} />}
								>
									Edit Categories
								</Button>
							</Grid>
						} */}
					</Grid>

					<Grid
						container
						item
						xs={12}
						md
						alignItems="flex-start"
						alignContent="flex-start"
						spacing={2}
					>
						<Grid
							container
							alignItems="center"
							alignContent="center"
							item
							xs={12}
							spacing={2}
						>
						</Grid>
						<Fade in={!isLoading}>
							<Grid
								container
								item
								xs={12}
								spacing={2}
							>
								{/* {user?.user?.user_permissions?.administrator && (
									<Grid
										item
										xs={12}
										lg={6}
										xl={3}
									>
										<AddResource
											edit={edit}
											categories={categories}
											setCategories={setCategories}
											refreshResources={refreshResources}
											refetchCategories={refetchCategories}
											selectedCategory={selectedCategory}
										/>
									</Grid>

								)} */}
								{Object.values(visibleResources).length ? visibleResources.map(resource => (
									<Grid
										item
										sx={{
											width: '100%',
											maxWidth: isMobileOnly ? "100%"  : isTablet ? '220px' : '280px'
										}}
										key={resource.resource_id}
									>
										<FileCard
											resource={resource}
											categories={categories}
											refreshResources={refreshResources}
											refetchCategories={refetchCategories}
											showResource={showResource}
										//getResources={getResources}
										/>
									</Grid>
								)) : null}
							</Grid>
						</Fade>
					</Grid>
				</Grid>
			</PageContent>
			<GlobalDialog
				open={open}
				onClose={e => setOpen(false)}
				title="View"
				fullWidth
				fullHeight
				fullScreen
				//maxWidth='xl'
				noPadding
			>
				{signedUrl && selectedResource.resource_type === 'application/pdf' ?
					<>
						{isMobileOnly || isTablet ?
							<ReactPDFFallback url={signedUrl} />
							:
							<object
								data={signedUrl}
								type="application/pdf"
								width="100%"
								height="100%"
							>
							</object>
						}
					</>

					: signedUrl && selectedResource.resource_type === 'video/mp4' ?

						<video
							width='100%'
							height='100%'
							src={signedUrl}
							type='video/mp4'
							autoPlay
							controls
						>

						</video>
						: null

				}
			</GlobalDialog>
		</>
	);
};

export default Resources;
