import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../store/ShopContext";
import { Autocomplete, Chip, InputBase, TextField } from "@mui/material";
import { UPDATE_BRANDS } from "../../store/ShopReducers";
import { useMutation, useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { CompanyContext } from "../../store/CompanyContext";

const BrandsDropdown = () => {

    const { selectedCompany } = useContext(CompanyContext);
    const { shop, brands, dispatchBrands } = useContext(ShopContext);
    const [selected, setSelected] = useState([]);
    //const [options, setOptions] = useState()
    const { user } = useContext(UserContext);
    const [currentCat, setCurrentCat] = useState();

    //--------------------------- GET ALL BRANDS ONCE ON TOP LEVEL ------------------------------//

    const { status, data, error, isFetching } = useQuery(['brands', currentCat, selectedCompany],
        ({ signal }) => {
            let params
            if (currentCat.cPath) {
                const classes = currentCat.cPath.split('|')
                params = {
                    c1: encodeURI(classes[0]),
                    c2: encodeURI(classes[1]),
                    c3: encodeURI(classes[2]),
                    c4: encodeURI(classes[3])
                }
            }
            params = {
                ...params,
                company_id: selectedCompany.company_id
            }
            //console.log(user)
            return axiosRequest({
                signal: signal,
                name: 'get brands', //added to see which call is erroring
                gateway: config.ezbaseAPI.URL,
                endpoint: 'brands',
                api_key: user.api_key,
                method: 'get',
                params: params
            })
        }, {
        retry: 1,
        enabled: !!currentCat && !!selectedCompany?.company_id
    })

    useEffect(() => {
        //console.log(data)
        if (!data?.errorMessage) {
            if (data && Object.values(data).length) {
                let updateBrands = data.filter((brand) => brand.Brand !== "")
                dispatchBrands({
                    type: UPDATE_BRANDS,
                    payload: {
                        currentBrands: updateBrands,
                    }
                });

            } else if (data && Object.values(data).length === 0) {
                //no brands for this company
                dispatchBrands({
                    type: UPDATE_BRANDS,
                    payload: {
                        currentBrands: [],
                    }
                });
            }
        }
    }, [data])

    // const getBrands = useMutation((values) => {
    //     //console.log(values)
    //     let params
    //     if (values[0].cPath) {
    //         const classes = values[0].cPath.split('|')
    //         params = {
    //             c1: encodeURI(classes[0]),
    //             c2: encodeURI(classes[1]),
    //             c3: encodeURI(classes[2]),
    //             c4: encodeURI(classes[3])
    //         }
    //     }
    //     //console.log(user)
    //     return axiosRequest({
    //         name: 'get brands', //added to see which call is erroring
    //         gateway: config.ezbaseAPI.URL,
    //         endpoint: 'brands',
    //         api_key: user.api_key,
    //         method: 'get',
    //         params: params
    //     })
    // }, {
    //     onSuccess: (response) => {
    //         if (Object.values(response).length) {
    //             let updateBrands = response.filter((brand) => brand.Brand !== "")
    //             dispatchBrands({
    //                 type: UPDATE_BRANDS,
    //                 payload: {
    //                     currentBrands: updateBrands,
    //                 }
    //             });
    //         }
    //         //getProducts.reset()
    //     }
    // })

    useEffect(() => {
        if (shop.currentCat && Object.values(shop.currentCat).length) {
            if (shop.currentProductCat && Object.values(shop.currentProductCat).length) {
                if (shop.productSubCat && Object.values(shop.productSubCat).length) {
                    setCurrentCat(shop.productSubCat)
                } else {
                    setCurrentCat(shop.currentProductCat)
                }
            } else {
                setCurrentCat(shop.currentCat)
            }
        }
    }, [
        shop.currentCat,
        shop.currentProductCat,
        shop.productSubCat
    ])

    useEffect(() => {
        if (brands.selectedBrands && Object.values(brands.selectedBrands).length) {
            setSelected(brands.selectedBrands)
        }
    }, [])

    const updateSelected = (event, newValue) => {
        //console.log(newValue)
        setSelected(newValue);
        if (newValue) {
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    selectedBrands: newValue,
                }
            });
        } else {
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    selectedBrands: [],
                }
            });
        }
    };

    // useEffect(() => {
    //     if (brands.currentBrands) {
    //         setOptions(brands.currentBrands)
    //     }
    // }, [brands.currentBrands])

    return (
        <Autocomplete
            //disableClearable
            multiple={true}
            id="selected-brands"
            value={selected}
            options={brands.currentBrands || []}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={option.Brand} />
                ));
            }}
            getOptionLabel={option => `${option.Brand} (${option.count})`}
            isOptionEqualToValue={(option, value) =>
                option.Brand === value.Brand
            }
            onChange={(event, newValue) => updateSelected(event, newValue)}
            renderInput={params => (
                
                <TextField
                    {...params}
                    sx={{
                        width: '100%',
                        '& input': { zIndex: 2 },
                        '& legend': { display: 'none' },
                        '& fieldset': {
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary,
                        },
                        '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
                           zIndex: 2
                        }
                    }}
                    placeholder={selected.length > 0 ? '' : 'Select Brands...'}
                    variant="outlined"
                />
            )}
            loading={isFetching || !brands?.currentBrands ? true : false}
            disabled={isFetching || !brands?.currentBrands ? true : false}
            loadingText="Updating Brands...."

        />
    )
};

export default BrandsDropdown;
