import React from 'react'
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

const ActivityTable = ({ data, isFetching, columns }) => {

	const defaultData = React.useMemo(() => [], [])

	const table = useReactTable({
		data: data ?? defaultData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: true,
	})

	const { pageSize, pageIndex } = table.getState().pagination

	return (
		<Box sx={{ width: '100%' }}>
			<Grid item xs={12} >
				<TablePagination
					rowsPerPageOptions={[10, 50, 100, 200]}
					component="div"
					count={table.getFilteredRowModel().rows.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					SelectProps={{
						inputProps: { 'aria-label': 'rows per page' },
						native: true,
					}}
					onPageChange={(_, page) => {
						table.setPageIndex(page)
					}}
					onRowsPerPageChange={e => {
						const size = e.target.value ? Number(e.target.value) : 10
						table.setPageSize(size)
					}}
					//ActionsComponent={TablePaginationActions}
				/>
			</Grid>

			<Grid item xs={12}>
				<TableContainer >
					<Table
						size="small"
						sx={{ minWidth: 650, border: '1px solid #ccc' }}
						aria-label="simple table
					 ">
						<TableHead>
							{table.getHeaderGroups().map((headerGroup, i) => (
								<TableRow
									key={i}
									sx={{
										backgroundColor: (theme) => theme.palette.table.header,
									}}
								>
									{headerGroup.headers.map((header, i) => {
										return (
											<TableCell key={i} colSpan={header.colSpan}>
												{header.isPlaceholder ? null : (
													<>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
														{/* {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null} */}
													</>
												)}
											</TableCell>
										)
									})}
								</TableRow>
							))}
						</TableHead>
						<TableBody>
							{isFetching ?
								//create array from a number
								new Array(pageSize).fill(0).map((_, i) => {
									return (
										<TableRow
											key={i}
											sx={{
												cursor: 'pointer',
												borderBottom: '1px solid #ccc',
												backgroundColor: (theme) => theme.palette.table.row,
												'&:nth-of-type(odd)': {
													backgroundColor: (theme) => theme.palette.table.hover,
												},
												// hide last border
												'&:last-child td, &:last-child th': {
													borderBottom: 0,
												},
											}}>

											<TableCell><Skeleton height={30} /></TableCell>
											<TableCell><Skeleton height={30} /></TableCell>
											<TableCell><Skeleton height={30} /></TableCell>
											<TableCell><Skeleton height={30} /></TableCell>
											<TableCell><Skeleton height={36} variant='rounded' /></TableCell>
										</TableRow>
									)
								})

								: !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {
									return (
										<TableRow
											key={i}
											sx={{
												cursor: 'pointer',
												borderBottom: '1px solid #ccc',
												backgroundColor: (theme) => theme.palette.table.row,
												'&:nth-of-type(odd)': {
													backgroundColor: (theme) => theme.palette.table.hover,
												},
												// hide last border
												'&:last-child td, &:last-child th': {
													borderBottom: 0,
												},
											}}
										>
											{row.getVisibleCells().map((cell, i) => {
												return (
													<TableCell key={i}>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</TableCell>
												)
											})}
										</TableRow>
									)
								})
									:
									<Alert sx={{ width: '100%', margin: '1em' }} severity="info">No orders found</Alert>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Box >
	)
}

export default ActivityTable;
