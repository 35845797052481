import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import CompanyUserTable from './CompanyUserTable';
import axiosRequest from '../../../axios/axoisRequest';
import { useMutation } from 'react-query';
import config from '../../../config';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';

const CompanyUsers = () => {

    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)
    const [users, setUsers] = useState()
    const [userCount, setUserCount] = useState()

    //get database users
    const get_users = useMutation((id) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'administrator/allusers',
            api_key: user.api_key,
            method: 'get',
            params: {
                "company_id": id
            }

        })
    }, {
        onSuccess: (data) => {
            if (!data.errerMessage) {
                setUsers(data)
                setUserCount(data.length)
            }
        }
    })

    useEffect(() => {
        if (selectedCompany.company_id) {
            get_users.mutate(selectedCompany.company_id)
        }
    }, [selectedCompany])

    const refreshTable = useCallback(() => {
        get_users.mutate(selectedCompany.company_id)
    },[selectedCompany])

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        padding={1}
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Users")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CompanyUserTable users={users} count={userCount} isFetching={get_users.isLoading} refreshTable={refreshTable} />
                    </Grid>
                </Grid>
            </TabContent>
        </>
    );
};

export default CompanyUsers;
