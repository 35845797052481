const userFormObject = (user) => {

	//console.log(user)
	let sub = ''

	if (user?.aws_sub) {
		sub = user?.aws_sub
	} else if (user?.Attributes) {
		let found = user.Attributes.find(item => item.Name = 'sub')
		if (found) {
			sub = found.Value
		}
	}
	let username = ''
	if (user?.Username) {
		username = user?.Username
	} else if (user?.username) {
		username = user?.username
	}

	const userObject = [{
		"name": user?.user_details?.name || '',
		"surname": user?.user_details?.surname || '',
		"email": user?.email || '',
		"confirm_email": user?.email || '',
		"username": username,
		"aws_sub": sub, //update to string to fix error
		//is administrator
		"administrator": user?.user_permissions?.administrator || false,
		"s_administrator": user?.user_permissions?.s_administrator || false,
		//access
		"access": {
			"all_companies": user?.user_permissions?.access?.all_companies || false,
			"companies": user?.user_permissions?.access?.companies || [],
		},
		"home": {
			"access": user?.user_permissions?.home?.access || 1, //always have access to home dashboard
			"role": user?.user_permissions?.home?.role || null
		},
		"buyers": {
			"access": user?.user_permissions?.buyers?.access || 3,
			"role": user?.user_permissions?.buyers?.role || null
		},
		"engineers": {
			"access": user?.user_permissions?.engineers?.access || 3,
			"role": user?.user_permissions?.engineers?.role || null
		},
		"vend": {
			"access": user?.user_permissions?.vend?.access || 3,
			"role": user?.user_permissions?.vend?.role || null
		},
		"coolantcare": {
			"access": user?.user_permissions?.coolantcare?.access || 3,
			"role": user?.user_permissions?.coolantcare?.role || null
		},
		"parametric": {
			"access": user?.user_permissions?.parametric?.access || 3,
			"role": user?.user_permissions?.parametric?.role || null
		},
		"application": {
			"access": user?.user_permissions?.application?.access || 3,
			"role": user?.user_permissions?.application?.role || null
		},
		"solution": {
			"access": user?.user_permissions?.solution?.access || 3,
			"role": user?.user_permissions?.solution?.role || null
		},
		"design": {
			"access": user?.user_permissions?.design?.access || 3,
			"role": user?.user_permissions?.design?.role || null
		},
		// "cadcam": {
		// 	"access": user?.user_permissions?.cadcam?.access || 3,
		// 	"role": user?.user_permissions?.cadcam?.role || null
		// },
		"cnc": {
			"access": user?.user_permissions?.cnc?.access || 3,
			"role": user?.user_permissions?.cnc?.role || null
		},
		"kpi": {
			"access": user?.user_permissions?.kpi?.access || 3,
			"role": user?.user_permissions?.kpi?.role || null
		},
		"fixtures": {
			"access": user?.user_permissions?.fixtures?.access || 3,
			"role": user?.user_permissions?.fixtures?.role || null
		},
		"resources": {
			"access": user?.user_permissions?.resources?.access || 3,
			"role": user?.user_permissions?.resources?.role || null
		},
	}]
	return userObject
}
export default userFormObject