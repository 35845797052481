import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputTypes from "./input/InputTypes";

const DesignWizardForm = ({ form, updateField }) => {

    const [fields, setFields] = useState();
    const [extendedFields, setExtendedFields] = useState(false);

    //--------------------------- SET FORM  ------------------------------//

    useEffect(() => {
        if (form) {
            //console.log('set forms')
            let fields = [], extended = []
            form.Fields.forEach(item => {
                if (item.Position === 0) {
                    fields.push(item)
                } else if (item.Position === 1) {
                    extended.push(item)
                }
            })
            setFields(fields)
            setExtendedFields(extended)
        }
    }, [form])

 //console.log(extendedFields)

    return (
        <>
            <Grid item xs={12} >
                <Grid container spacing={1} >
                    {fields ?
                        fields.map((field, i) => {
                            return <InputTypes field={field} index={i} key={i} updateField={updateField} />
                        })
                        : null}
                </Grid>
            </Grid>
            {extendedFields &&
                <Grid container item >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls="extended-options"
                            id="extended-options"
                            sx={{ background: (theme) => theme.palette.background.default, width: '100%', flexDirection: 'row-reverse' }}
                        >
                            <Typography sx={{ color: 'text.primary', paddingLeft: 2 }}>Extended Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} >
                                {(extendedFields).map((field, i) => {
                                    return <InputTypes field={field} index={i} key={i} updateField={updateField} />
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
        </>
    );
};

export default DesignWizardForm;
