import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { UserProvider } from "./store/UserContext";
import { ShopProvider } from "./store/ShopContext";
import { CategoryProvider } from "./store/CategoryContext";
import { ProductsProvider } from "./store/ProductsContext";
import { LayoutProvider } from "./store/LayoutContext";
import { BrandsProvider } from "./store/BrandsContext";
import { BasketProvider } from "./store/BasketContext";
import { SelectedProvider } from "./store/SelectedContext";
import { CompanyProvider } from "./store/CompanyContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import config from "./config";
import App from "./App";
import { QueryClient as QueryClientv1, QueryClientProvider as QueryClientProviderv1 } from "react-query";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from "react-router-dom";

// Initialize Google Analytics
const initializeGA = () => {
	if (!config.ANALYTICS || process.env.NODE_ENV !== "production" ) return;
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', config.ANALYTICS, { debug_mode: true });

};
initializeGA();


Amplify.configure({
	Auth: {
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	}
});


const queryClientv1 = new QueryClientv1({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false
		},
	},
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false
		},
	},
})

//render full factory app
let app = document.getElementById("ecommerce-app");


if (app) {
	//console.log(hideLogin);
	ReactDOM.render(

		<QueryClientProviderv1 client={queryClientv1}>
			<QueryClientProvider client={queryClient}>
				<ShopProvider>
					<UserProvider>
						<CategoryProvider>
							<ProductsProvider>
								<LayoutProvider>
									<BrandsProvider>
										<BasketProvider>
											<SelectedProvider>
												<CompanyProvider>
													<BrowserRouter>
														<App />
													</BrowserRouter>
												</CompanyProvider>
											</SelectedProvider>
										</BasketProvider>
									</BrandsProvider>
								</LayoutProvider>
							</ProductsProvider>
						</CategoryProvider>
					</UserProvider>
				</ShopProvider>
			</QueryClientProvider>
		</QueryClientProviderv1>

		,
		app
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();