import React, {
	useCallback,
	useContext,
	useState,
	useEffect
} from 'react';
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import ReportCard from '../ReportCard';
import MachineQRCodesPDF from './MachineQRCodesPDF';
import config from '../../../config';
import { machineQRCodeCreater } from '../../helpers/machineQRCodeCreater';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import formatDate from '../../helpers/formatDate';
import setLanguageText from '../../../language/setLanguageText';
import { CompanyContext } from '../../../store/CompanyContext';
import DropdownGroups from '../../dashboard/Selection_Row/DropdownGroups';
import { MachineContext } from '../../store/MachineContext';
import { LayoutContext } from '../../../store/LayoutContext';
import { useTheme } from '@emotion/react';

const MachineQRCodes = () => {

	const { machines, selectedGroup } = useContext(MachineContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [open, setOpen] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [pdf_codes, setPdf_codes] = useState();
	const [selectedSize, setSelectedSize] = useState(1);
	const { theme: layoutTheme } = useContext(LayoutContext);
	const [mainImage, setMainImage] = useState();
	const theme = useTheme();
	const [actLogo, setActLogo] = useState('');

	import(`../../../images/MyFactory-Logo-Black.png`).then((module) => {
		setActLogo(module.default);
	});


	const create_pdf_codes = useCallback(async () => {
		let machineInGroup = machines;
		//console.log(machines)
		if (selectedGroup.group_id !== 0) {
			//console.log('not All');
			machineInGroup = machines.filter(machine => machine.machine_group === selectedGroup.group_name);
		}
		let codes = [];
		machineInGroup.forEach((machine) => {

			let url = machineQRCodeCreater({
				company_id: `${machine.company_id}`,
				machine_id: `${machine.machine_id}`,
				instance: config.INSTANCE
			});
			//{"company_id":"1","machine_id":"392","instance":"prod"}
			codes.push({ url: url, machine: machine, company: selectedCompany })
		})
		//console.log(codes)
		setPdf_codes(codes);
		setShowPreview(true);
	}, [machines, selectedGroup, selectedCompany]);

	const runReport = useCallback(async () => {
		setOpen(true);
		create_pdf_codes();
	}, [selectedCompany, selectedSize, selectedGroup, machines]);

	useEffect(() => {
		if (layoutTheme) {
			//console.log(theme)
			setMainImage(layoutTheme.login_logo)
		}
	}, [layoutTheme])


	const create_pdf_data = useCallback(
		(styles) => {
			return (
				<Document title={setLanguageText('Machine QR codes')}>
					<Page style={styles.BODY} orientation='landscape'>
						<View style={styles.BODY}>
							{pdf_codes && pdf_codes.map(machine => (
								<View wrap={false} key={`${machine.machine.company_id}-${machine.machine.machine_id}`} style={selectedSize === 1 ? styles.SIZE_1 : styles.SIZE_2}>

									<View style={styles.CONTAINER} >

										<View style={{ backgroundColor: config.APPNAME === "act" ? "#000" : theme.palette.primary.main, textAlign: 'center', width: '100%', padding: '8px' }}>
											<View style={{ textAlign: 'center', width: '100%' }}>
												{mainImage ? <Image src={config.APPNAME === "act" ? actLogo : mainImage} style={{ width: '100%', maxWidth: '180px', height: 'auto', maxHeight: '60px', objectFit: 'contain', margin: '0 auto' }} /> : null}
											</View>
										</View>
										<View style={styles.FULL} >
											<Text style={styles.MAINTITLE}>{machine.machine.machine_name}</Text>
										</View>
										<View style={styles.FULL} >
											<View style={styles.HALF}>
												<View style={styles.QR}>
													<Image src={machine.url} />
												</View>
											</View>
											<View style={[styles.HALF, { marginTop: '10px' }]}>
												<Text style={styles.TITLE}>{machine.company.company_name}</Text>
												<Text style={styles.TEXT}>{machine.machine.machine_group}</Text>
												<Text style={styles.TITLE}>{machine.machine.coolant_name}</Text>
												{/* <Text style={styles.TEXT}>{machine.machine.brand_name}</Text> */}
												{machine.machine?.machine_settings?.machine_defaults?.sump ?
													<Text style={styles.TEXT}>{setLanguageText('SUMP')}:{machine.machine?.machine_settings?.machine_defaults?.sump}Ltrs</Text>
													: null}
												<Text style={styles.TEXT}>{setLanguageText('LCC')}:{formatDate(machine.machine.last_coolant_change)}</Text>
											</View>
										</View>
										<View style={styles.FULL} >
											<Text style={styles.TITLE}>MACHINE SETTINGS</Text>
										</View>

										{machine.machine && (
											<View style={styles.FULL} >
												<View style={styles.HALF} >
													<Text style={styles.TEXT}>
														{setLanguageText('PH Min')} :{' '}
														{machine.machine.machine_settings?.machine_defaults?.ph_min}
														{'\n'}
													</Text>
													<Text style={styles.TEXT}>
														{setLanguageText('PH Max')} :{' '}
														{machine.machine.machine_settings?.machine_defaults?.ph_max}
														{'\n'}
													</Text>
													<Text style={styles.TEXT}>
														{setLanguageText('Refractometer Factor')} :{' '}
														{machine.machine.machine_settings?.machine_defaults?.factor}
														{'\n'}
													</Text>
												</View>
												<View style={styles.HALF} >
													<Text style={styles.TEXT}>
														{setLanguageText('Conc Min')} :{' '}
														{
															machine.machine.machine_settings?.machine_defaults?.op_con_min
														}
														{'\n'}
													</Text>
													<Text style={styles.TEXT}>
														{setLanguageText('Conc Max')} :{' '}
														{
															machine.machine.machine_settings?.machine_defaults?.op_con_max
														}
														{'\n'}
													</Text>

												</View>
											</View>
										)}
									</View>
								</View>
							))}
						</View>
					</Page>
				</Document >
			);
		}, [pdf_codes, selectedSize, mainImage, actLogo]);

	return <>
		<ReportCard
			title={setLanguageText("Machine QR Code Labels")}
			buttonLabel={setLanguageText("Create Labels")}
			//loading={loading}
			//error={error}
			disabled={!selectedCompany || !selectedSize || !selectedGroup ? true : false}
			runReport={runReport}
		>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<DropdownGroups />
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel>{setLanguageText("Per A4 page (size)")}</InputLabel>
						<Select
							value={selectedSize}
							onChange={e => setSelectedSize(e.target.value)}
							label={setLanguageText("Per A4 page (size)")}
						>
							<MenuItem value={1}>6</MenuItem>
							{/* <MenuItem value={2}>12</MenuItem> */}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</ReportCard>

		<MachineQRCodesPDF
			dialogOpen={open}
			dialogClose={() => {
				setOpen(false);
				setShowPreview(false);
				setPdf_codes();
			}}
			showPreview={showPreview}
			create_pdf_data={create_pdf_data}
		/>

	</>;
};

export default MachineQRCodes;
