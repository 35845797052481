import React, { useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Grid, InputLabel, TextField, Typography, useMediaQuery } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";
import InputLables from "./InputLables";

const Dropdown = ({ field, filtersView }) => {

    const [localValue, setLocalValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const { selected, dispatchSelected } = useContext(SelectedContext);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValue(null)
            setInputValue('')
        }
    }, [selected.resetFilters]);

    //use QueryString on first load to set fields that are availble
    useEffect(() => {
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("search"));
            //console.log(searchArr)
            if (searchArr && Object.values(searchArr).length ) {
                let found = searchArr[0].filter(element => element.includes(`${field.Field}§`));
                //console.log(field) 
                if (Object.values(found).length > 0) {
                    let valueCopy = [...field.Values]
                    let fieldValue = found[0].split('§')[1];
                    //console.log(fieldValue)
                    let foundItems = valueCopy.find((value) => value.ID === fieldValue);
                    //console.log(foundItems)
                    setLocalValue(foundItems);
                    updateSelected(foundItems);
                } else {
                    setLocalValue(null);
                }
            }
        }
    }, [field]);

    //If Wizard is used, set the form fields
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.parametricFilters) {
            //look for matching field
            let match = selected.parametricFilters.find(item => item.Field === field.Field)
            //console.log(match)
            if (match) {
                let valueCopy = [...field.Values]
                let foundItems = valueCopy.find((value) => value.ID === match.Values[0]);
                //console.log(valueCopy)
                setLocalValue(foundItems);
                //updateSelected(foundItems);
            }
        }
    }, [field, selected.parametricFilters]);


    //update select
    const updateSelected = useCallback((value) => {
        //Update poarametric search - which in turn updates URL using a debounce
        //console.log(value)
        setLocalValue(value);
        let payload
        if (value) {
            payload = {
                'Field': field.Field,
                'Values': [value.ID],
                'ValueNames': value.Name,
                'Name': field.Name,
                'DIN': field.Ref,
                'Toggle': true
            }
        } else {
            payload = {
                'Field': field.Field,
                'Values': []
            }
        }
        dispatchSelected({
            type: UPDATE_PARAMETRIC_FILTERS,
            payload: payload
        });
    }, [field.Field, dispatchSelected])


    return (
        <>
            <InputLables filtersView={filtersView} field={field} />
            <Grid item xs={12} xl={filtersView ? 12 : 6}>
                <Autocomplete
                    id={field.ID}
                    sx={{ minWidth: 300 }}
                    options={field.Values}
                    value={localValue}
                    onChange={(event, newValue) => {
                        updateSelected(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        //console.log(newInputValue)
                        setInputValue(newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.ID} : ${option.Name}`}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
                            {option.Icon &&
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={option.Icon}
                                    srcSet={option.Icon}
                                    alt=""
                                />
                            }
                            {option.ID} : {option.Name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            //label={''}
                            sx={{
                                width: '100%',
                                '& input': { zIndex: 2 },
                                '& legend': { display: 'none' },
                                '& fieldset': { 
                                    backgroundColor: theme => theme.palette.background.paper,
                                    color: theme => theme.palette.text.primary,
                                },
                                '& .MuiAutocomplete-endAdornment' : {
                                    zIndex: 2
                                }
                            }}
                            InputLabelProps={{ shrink: false }}
                            placeholder={`Select ${field.Name}`}
                            size="small"
                        />
                    )}
                />
            </Grid>
        </>
    );
};


export default Dropdown;


