import { Pagination, TablePagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { ProductsContext } from "../../store/ProductsContext";

const Paginations = ({ isFetching }) => {

    const { layout, dispatchLayout } = useContext(LayoutContext);
    const { products, dispatchProducts } = useContext(ProductsContext);
    const [currentPage, setCurrentPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: newPage
            }
        });
    };

    useEffect(() => {   
        //get page and - 1 because the API starts at 0
        if (layout.page){
            //console.log(layout.page)
        const page =  parseInt(layout.page, 10);
        setCurrentPage(page);
        }

    }, [layout.page])

    return (

        // <TablePagination
        //     disabled={isFetching}
        //     rowsPerPageOptions={[10, 20, 30]}
        //     component="div"
        //     count={products.count}
        //     rowsPerPage={products.limit}
        //     page={currentPage}
        //     onPageChange={handleChangePage}
        //     onRowsPerPageChange={handleChangeRowsPerPage}
        // />

        <Pagination
            disabled={isFetching}
            sx={{
                '& li button': {
                    padding: '19px 5px',
                   //borderColor: '#b8b8b8'
                }
            }}
            siblingCount={0} 
            boundaryCount={1}
            shape="rounded"
            color="primary"
            variant="outlined"
            count={Math.ceil(products.groupCount ? products.groupCount / products.limit : products.count / products.limit)}
            page={currentPage}
            onChange={handleChangePage}
        />
    );
};
export default Paginations;
