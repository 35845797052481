import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Grid, Tab } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AttributeTable from "../../reusable/AttributeTable";
import Image from "../../shop/reusable/Image";
import ProductInterfaceCodes from "./ProductInterfaceCodes";
import AccessoriesList from "../shared/AccessoriesList";
import ProductExports from "../shared/tabs/ProductExports";
import ProductAttributes from "../shared/ProductAttributes";
import ProductVariants from "../shared/ProductVariants";
import { useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from "../../store/UserContext";
import config from "../../config";

const ProductTabs = ({ allAccessories, variants, categoryImage, closeDialog, isFetching, selectedProduct, setSelectedProduct, fullHeight }) => {

    const [tab, setTab] = useState("1");
    const [options, setOptions] = useState()
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            let tab = urlParams.get("product_tab")
            if (tab) {
                setTab(tab)
            } else {
                setTab('1')
            }
        } else {
            setTab('1')
        }
    }, [])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const setProduct = (product) => {
        console.log(product)
        setSelectedProduct(product)
        //setTab("1");
    }


    const { data } = useQuery(['exports', selectedProduct?.product_id],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'products/exports',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: selectedProduct?.product_id,
            }
        }), {
        enabled: !!selectedProduct?.product_id && selectedProduct?.product_dataset === 'cim', 
        refetchOnWindowFocus: false, //todo set globally
        retry: 1
    })

    useEffect(() => {
        if (data) {
            setOptions(data)
        }
    }, [data])

    return (

        <Grid item xs={12} sx={{ padding: isMobileOnly ? '1em' : '1em 2em 2em 2em', width: '100%' }}>
            <TabContext value={tab}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <TabList onChange={handleChange} aria-label="Tabs">

                        <Tab sx={{ fontSize: '1.2em' }} label="Product Features" value="1" />

                        {selectedProduct?.product_matching && selectedProduct?.product_matching.length > 0 ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Matching Components${selectedProduct.product_matching ? ` (${selectedProduct?.product_matching.length})` : ''}`} value="2" />
                            : null}
                        {variants && variants.length > 1 ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Variants ${variants ? `(${variants.length})` : ''}`} value="3" />
                            : null}
                        {selectedProduct?.product_dataset === 'cim' ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Downloads${options && options.length > 0 ? ` (${options.length})` : "" }`} value="4" />
                            : null}
                        {allAccessories ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Accessories${allAccessories?.length > 0 ? ` (${allAccessories.length})` : ''}`} value="5" />
                            : null}
                    </TabList>
                </Box>


                <TabPanel
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    value="1"
                > 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={categoryImage ? 8 : 12}>
                            <ProductAttributes attributes={selectedProduct?.product_attributes} fullHeight={fullHeight} />
                        </Grid>
                        {categoryImage ?
                            <Grid item xs={12} md={4}>
                                <Image height="100%" isFetching={false} src={categoryImage?.url} alt={selectedProduct?.product_name} ShowIcon={false} zoom />
                            </Grid>
                            : null}
                    </Grid>
                 
                </TabPanel>

                {selectedProduct?.product_matching ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="2"
                    >
                        {selectedProduct?.product_matching && <ProductInterfaceCodes rows={selectedProduct.product_matching} product={selectedProduct} closeDialog={closeDialog} />}
                    </TabPanel>
                    : null}
                {variants && variants.length > 1 ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="3">
                        {variants ?
                            <ProductVariants
                                variants={variants ? variants : null}
                                isFetching={isFetching}
                                setSelectedProduct={setProduct}
                                selectedProduct={selectedProduct}
                                fullHeight={fullHeight}
                            />
                            : null}
                    </TabPanel>
                    : null}
                <TabPanel
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                    }}
                    value="4"
                >
                    { selectedProduct?.product_dataset === 'cim' ?
                        <ProductExports options={options} product={selectedProduct} />
                        : selectedProduct?.product_downloads && selectedProduct?.product_downloads.length === 0 ?
                            <Alert variant="standard" severity="info">No Downloads Available</Alert>
                            : null}
                </TabPanel>
                {allAccessories ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="5"
                    >
                        {allAccessories && Object.values(allAccessories).length ?
                            allAccessories.map((product, i) =>
                                <AccessoriesList key={i} product={product} isFetching={isFetching} />
                            )
                            : <Alert variant="standard" severity="info">No Accessories Available</Alert>}
                    </TabPanel>
                    : null}
            </TabContext>
        </Grid >
    );
};
export default ProductTabs;