import {
    Grid,
    TextField,
    Button
} from '@mui/material';
import React, { useEffect, useContext, useState } from 'react';
import { MachineContext } from '../../store/MachineContext';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { UPDATE_MACHINE_SETTINGS } from '../../store/MachineReducers';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import setLanguageText from '../../../language/setLanguageText';
import { useMutation } from '@tanstack/react-query';
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../axios/axoisRequest';
import DialogLoader from '../../../reusable/DialogLoader';

export default function Frequency() {

    const { user } = useContext(UserContext);
    const { selectedMachine, dispatchSelectedMachine } = useContext(MachineContext);
    const [open, setOpen] = useState(false);
    const [admin, setAdmin] = useState();
    const [machineDefaults, setMachineDefaults] = useState();
    const { updateActivityLog } = useActivityLog();

    const updateChange = (e) => {
        setMachineDefaults(prevState => {
            return ({
                ...prevState,
                "change_freq": e.target.value
            })
        })
    }

    useEffect(() => {
        if (selectedMachine.machine_settings) {
            setAdmin(selectedMachine.machine_settings.admin ? selectedMachine.machine_settings.admin : [])
            setMachineDefaults(selectedMachine.machine_settings.machine_defaults ? selectedMachine.machine_settings.machine_defaults : [])
        }
    }, [selectedMachine]);


    const editFrequency = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'patch',
            body: body
        }),
        onSuccess: (response) => {

            if (!response.errorMessage) {
                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: response[0].machine_settings
                });
                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        machine_id: selectedMachine.machine_id,
                        new_settings: response[0].machine_settings
                    },
                    activity_type: "check frequency updated"
                })
            }
            setOpen(false)
        }
    })

    const handleSubmit = () => {
        let settings = {
            "machine_defaults": {
                ...machineDefaults
            },
            "admin": {
                ...admin
            }
        }
        editFrequency.mutate({
            edit: 'admin',
            machine_id: selectedMachine.machine_id,
            settings: settings
        })
    }

    return (
        <>
            <Grid item xs={12} md={6} >
                <Button
                    id="edit"
                    variant="contained"
                    color="error"
                    onClick={e => setOpen(true)}
                    startIcon={<FontAwesomeIcon icon={faBell} />}
                    fullWidth
                >
                    {setLanguageText("Coolant Change Frequency")}
                </Button>
            </Grid>
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    title="Coolant Change Frequency"
                    buttonClick={handleSubmit}
                    buttonTitle="Update Details"
                    successMessage="Details updated successfully!"
                    maxWidth="md"

                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label={setLanguageText("Coolant Change Frequency (days)")}
                                id="change_freq"
                                variant="outlined"
                                type="number"
                                value={machineDefaults?.change_freq || ''}
                                onChange={updateChange}
                            />
                        </Grid>
                        <Grid container item spacing={2}>
                        </Grid>
                    </Grid>
                    {editFrequency.isLoading || editFrequency.isSuccess || editFrequency.isError ?
                        <DialogLoader
                            isLoading={editFrequency.isLoading}
                            mutation={editFrequency}
                            loadingMessage="Updating Machine..."
                            successMessage="Machine Updated"
                            fixed
                        />
                        : null}
                </GlobalDialog>
                : null}
        </>
    );
};

