
import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useMediaQuery, Drawer, Box, MenuItem, Divider, DialogContent, DialogTitle, ListItem, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LayoutContext } from '../../../store/LayoutContext';
import { SHOW_CATEGORIES, UPDATE_MENU } from '../../../store/LayoutReducers';
import { RESET_ALL_FILTERS } from '../../../store/SelectedReducers';
import { UPDATE_CATEGORIES } from '../../../store/CategoryReducers';
import { CategoryContext } from '../../../store/CategoryContext';
import { SelectedContext } from '../../../store/SelectedContext';
import findCurrentCategory from '../../../shop/libs/findCurrentCategory';
import MobileMenuItems from './MobileMenuItems'
import MobileMenuInner from './MobileMenuInner';
import { Diversity1 } from '@mui/icons-material';
import setLanguageText from '../../../language/setLanguageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustryAlt, faUser, faUserGear } from '@fortawesome/pro-light-svg-icons';
import { UserContext } from '../../../store/UserContext';
import config from '../../../config';

const MobileMenu = ({ mobileOpen, setMobileOpen }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { app, dispatchApp, dispatchLayout } = useContext(LayoutContext);
    const { categories, dispatchCategories } = useContext(CategoryContext);
    const { dispatchSelected } = useContext(SelectedContext);
    const [mainMenu, setMainMenu] = useState();
    const [slideOut, setSlideOut] = useState(false);
    const [subMenu, setSubMenu] = useState();

    //remove hidden items
    useEffect(() => {
        if (app.menu) {
            let visible = app.menu.filter((items) => !items.hidden)
            console.log(visible)    
            setMainMenu(visible)

        }
    }, [app.menu])

    const handleMobileClose = (menuItem) => {
        //reset all filters
        dispatchSelected({
            type: RESET_ALL_FILTERS
        })
        //sessionStorage.removeItem("parametricFilters");
        //console.log(categories.allCats)
        //set current catergory back to root
        const category = findCurrentCategory([categories.allCats], 'Root')
        if (category) {
            dispatchCategories({
                type: UPDATE_CATEGORIES,
                payload: {
                    currentCat: category[0].cat,
                    openLevel: category[0].path
                }
            });
            dispatchLayout({
                type: SHOW_CATEGORIES
            });
        }
        //console.log(area,menu)
        dispatchApp({
            type: UPDATE_MENU,
            payload: {
                selectedArea: menuItem.area,
                selectedMenu: menuItem.menu
            }
        })
        navigate(menuItem.link);
        setMobileOpen(false)
    };

    const handleOpenSubmenu = (menuItem) => {
        //console.log('show inner')
        if (menuItem?.submenu) {
            let visible = menuItem.submenu.filter((items) => !items.hidden)
            if (visible.length) {
                setSubMenu(visible)
            } else {
                setSubMenu();
            }
        }
        setSlideOut(true);
        setMobileOpen(false);
    }

    return (
        <>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'none'
                    },
                    '& .MuiDrawer-paper': {
                        backgroundColor: theme.palette.menu.first,
                        boxSizing: 'border-box', width: '80%'
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: 'primary.light',
                        backgroundColor: 'primary.main',
                        padding: 0,
                        textAlign: 'center'
                    }}
                >
                    <ListItem
                        sx={{ color: theme.palette.primary.contrastText }}
                    >
                        <ListItemText
                            sx={{
                                textAlign: 'center'
                            }}
                            primary={`${config.APPTITLE} Menu`} 
                            />
                    </ListItem>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'} sx={{ padding: 0, paddingTop: '1em!important', position: 'relative' }}>
                    <Box
                        sx={{
                            overflow: 'hidden',
                        }} >
                        {mainMenu ?
                            <MobileMenuItems menu={mainMenu} handleMobileClose={handleMobileClose} handleOpenSubmenu={handleOpenSubmenu} />
                            : null}
                    </Box>
                    {user?.user?.user_permissions?.administrator &&
                        <>
                            <Divider />
                            <ListItemButton
                                onClick={e => {
                                    handleMobileClose({
                                        "area": "settings",
                                        "menu": "users",
                                        "link": "/settings/users"
                                    })
                                }}
                                sx={{
                                    color: (theme) => theme.palette.primary.contrastText,
                                    padding: '1em 0',
                                }}
                            >
                                <ListItemIcon sx={{ width: (theme) => `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }}>
                                    <FontAwesomeIcon
                                        style={{
                                            margin: '0 auto',
                                            color: theme.palette.primary.contrastText
                                        }}
                                        icon={faUserGear}
                                        size={'xl'}
                                    />
                                </ListItemIcon>
                                {setLanguageText("User Management")}
                            </ListItemButton>
                            <ListItemButton
                                onClick={e => {
                                    handleMobileClose({
                                        "area": "settings",
                                        "menu": "company",
                                        "link": "/settings/company?section=0&tab=0"
                                    })
                                }}
                                sx={{
                                    color: (theme) => theme.palette.primary.contrastText,
                                    padding: '1em 0',
                                }}
                            >
                                <ListItemIcon sx={{ width: (theme) => `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }}>
                                    <FontAwesomeIcon
                                        style={{
                                            margin: '0 auto',
                                            color: theme.palette.primary.contrastText
                                        }}
                                        icon={faIndustryAlt}
                                        size={'xl'}
                                    />
                                </ListItemIcon>
                                {setLanguageText("Company Management")}
                            </ListItemButton>
                        </>
                    }
                </DialogContent>
            </Drawer>
            <MobileMenuInner setSlideOut={setSlideOut} slideOut={slideOut} subMenu={subMenu} setMobileOpen={setMobileOpen} />

        </>

    );
}

export default MobileMenu;


