import React from "react";
import Image from "./Image";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import SingleImage from "../../shop/reusable/SingleImage";


const ItemCard = ({ item, selected }) => {

    // console.log(selected)

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (

        <Grid container alignContent="stretch"
            sx={{
                position: 'relative',
                width: mobile ? '80px' : '140px',
                height: mobile ? '100px' : '180px',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: (theme) => selected?.ID !== item.ID ? theme.palette.cards.footer : theme.palette.primary.light,
                background: (theme) => selected?.ID !== item.ID ? theme.palette.cards.header : theme.palette.primary.light,
                '&:hover': {
                    borderColor: (theme) => theme.palette.primary.light,
                    //background: (theme) => theme.palette.cards.header,
                },
                // '.Mui-selected &': {
                //     borderColor: (theme) => theme.palette.primary.light,
                //     background: (theme) => theme.palette.primary.light,
                // }
            }}>
            <Grid item xs={12} >
                {/* <Image isFetching={false} src={item.Icon} alt={item.Name} ShowIcon={false}
                    height="100%"
                    sx={{
                        padding: mobile ? '10px' :'1em',
                        width:  '100%',
                        height:  mobile ? '60px' : '80px',
                        textAlign: 'center',
                        background : '#fff'
                        //background: (theme) => theme.palette.background.paper
                    }} /> */}
                <Box
                    sx={{
                        width: '100%',
                        height: mobile ? '60px' : '80px',
                        textAlign: 'center',
                        background: '#fff',
                        display: 'grid',
                        position: 'relative',
                    }}>
                     <SingleImage imageUrl={item.Icon}  />
                </Box>
            </Grid>
            <Grid container item xs={12} sx={{
                padding: mobile ? '5px' : '10px',
                height: mobile ? '50px' : '100px'
            }} alignContent="center" >
                <Grid item xs={12} >
                    <Typography
                        textAlign="center"
                        variant="h6"
                        fontSize={mobile ? '13px' : "14px"}
                        lineHeight="1.2"
                        sx={{

                            color: (theme) => selected !== item.ID ? theme.palette.text.primary : theme.palette.primary.contrastText
                        }}
                    >{item.Name}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default ItemCard;
