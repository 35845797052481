import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import InputTypes from "./inputs/InputTypes";
import axiosRequest from "../../axios/axoisRequest";
import { CategoryContext } from "../../store/CategoryContext";
import { SelectedContext } from "../../store/SelectedContext";
import { CLEAR_PARAMETRIC_FILTERS, RESET_ALL_FILTERS, UPDATE_URL_FILTERS } from "../../store/SelectedReducers";
import useDebounce from "../libs/useDebounce";
import { UserContext } from "../../store/UserContext";
import { useNavigate } from "react-router-dom";
import createUrl from "../libs/createUrl";
import { ProductsContext } from "../../store/ProductsContext";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import Image from "../reusable/Image";
import SkeletonParametricForm from './SkeletonParametricForm'
import { LayoutContext } from "../../store/LayoutContext";
import convertFiltersForAPI from "../functions/convertFiltersForAPI";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { CompanyContext } from "../../store/CompanyContext";
import useSearchCount from "../../hooks/useSearchCount";

const ResultButton = ({ count, resetForm, isFetching, selected }) => {

    const { categories } = useContext(CategoryContext);
    const { app, dispatchLayout } = useContext(LayoutContext);
    const navigator = useNavigate();
    const { updateSearchCount } = useSearchCount()

    const gotoProducts = (e, selected) => {

        //update search count
        updateSearchCount.mutate('parametric')

        //console.log(selected)
        e.preventDefault();

        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1,
            }
        });

        let url = createUrl(`/${app.selectedArea}/${app.selectedMenu}/results`, selected, categories);
        navigator(url);
    };

    return (

        <Grid container item xs={12} spacing={1} justifyContent="center">
            <Grid item>
                <Button
                    fullWidth
                    color="secondary"
                    variant="text"
                    onClick={(e) => resetForm()}
                    size="large"
                >
                    Clear Filters
                </Button>
            </Grid>
            <Grid item>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={(e) => gotoProducts(e, selected)}
                    size="large"
                //disabled={false}
                >
                    {isFetching ? 'Finding products...'
                        : count === 0 ? `No products with thoses filters`
                            :
                            <span> Show <strong>{count ? count : '...'}</strong> Product{count > 1 && 's'}</span>
                    }
                </Button>
            </Grid>
        </Grid>
    );
};


const ParametricForm = () => {

    const [fields, setFields] = useState();
    const [firstLoad, setFirstload] = useState(true);
    const [extendedFields, setExtendedFields] = useState(false);
    const [checkFields, setCheckFields] = useState();
    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const { selectedCompany } = useContext(CompanyContext)
    const [noOptions, setNoOptions] = useState(false)

    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

    //--------------------------- API CALLS  ------------------------------//

    const { status, data, error, isFetching } = useQuery(['catID', categories.currentCat.ID],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: categories.currentCat.SearchProfile
            }
        }), {
        enabled: !!categories.currentCat.ID,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    const { data: result_data, isFetching: result_isFetching } = useQuery(['search', categories.currentCat.ID, selected.parametricFilters, selected.resultFilters, selectedCompany],
        ({ signal }) => {

            let search = convertFiltersForAPI(selected.parametricFilters);

            //check if compnay has allowed companies in options
            //console.log(selectedCompany?.company_supplier_order)
            if (!selectedCompany?.company_supplier_order) {
                setNoOptions(true)
                return 0
            } else {
                setNoOptions(false)
            }
            //if there is no company filter, add it
            if (!search.find(field => field.Field === "COMPC") && selectedCompany?.company_supplier_order) {
                search.push({ "Field": "COMPC", "Values": selectedCompany.company_supplier_order, "DIN": "J3" })
                //[{"Field":"COMPC","Values":["Aura","BK"],"DIN":"J3"}]
            }

            return axiosRequest({
                signal: signal,
                gateway: config.shopAPI.URL,
                endpoint: 'categories/parametric/results',
                api_key: user.api_key,
                method: 'get',
                params: {
                    category: categories.currentCat.ID,
                    search: JSON.stringify(search)
                }
            })
        }, {
        enabled: !!categories.currentCat.ID && !!selected.parametricFilters,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    useEffect(() => {
        //console.log(result_data)
        if (!result_data?.errorMessage) {
            //setCount(result_data)
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    count: result_data,
                    offset: 0 //reset offset
                }
            });
        }
    }, [result_data])


    //--------------------------- FETCHING FORM  ------------------------------//

    useEffect(() => {
        if (isFetching) {
            //console.log('fetching')
            //clear all fields
            setFields()
            setExtendedFields()
            setCheckFields()
            {
                !firstLoad &&
                    dispatchSelected({
                        type: CLEAR_PARAMETRIC_FILTERS,
                        payload: null
                    })
            }
        }
    }, [isFetching, firstLoad, dispatchSelected])

    //--------------------------- SET FORM  ------------------------------//

    useEffect(() => {
        if (data && !isFetching) {
            //console.log('set forms')
            let fields = [], extended = [], checked = []
            data.Fields.forEach(item => {
                if (item.Position === 0) {
                    fields.push(item)
                } else if (item.Position === 1) {
                    extended.push(item)
                } else if (item.Position === 2) {
                    checked.push(item)
                }
            })
            setFields(fields)
            setExtendedFields(extended)
            setCheckFields(checked)
        }
    }, [isFetching, data])

    //--------------------------- UPDATE URL ATTRIBUTES ------------------------------//

    const debouceValue = useDebounce(selected.parametricFilters, 500);

    useEffect(() => {
        //console.log(debouceValue)
        if (debouceValue && Object.values(debouceValue).length > 0) {

            let updatedUrlValues = []
            debouceValue.forEach((item) => {
                //remove if there are no values
                if (item.Values) {
                    //check for Comparison
                    if ((item.Comparison)) {
                        if (item.Tolerance) {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values},${item.Tolerance}]`)
                        } else {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values}]`)
                        }
                    } else {
                        updatedUrlValues.push(`${item.Field}§${item.Values}`)
                    }
                }
            })
            //console.log(updatedUrlValues)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'search': [updatedUrlValues],
                    'filters': [] //reset filter on parametric from page
                }

            });
            setFirstload(false);
        } else if (!firstLoad) {
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'search': [],
                    'filters': [] //reset filter on parametric from page
                }
            });
        }
    }, [debouceValue, firstLoad])

    //--------------------------- RESET SELECTED ATTRIBUTES ------------------------------//

    const resetForm = () => {
        dispatchSelected({
            type: RESET_ALL_FILTERS
        });
    };

    return (
        <>

            <Card
                elevation={1}
                color={'background.paper'}
            //sx={{ padding: 2, maxHeight: !isMd ? "auto" : "calc(100vh - 297px)", overflow: "auto" }}
            ><CardHeader
                    disableTypography
                    sx={{
                        background: (theme) => theme.palette.cards.header,
                        //color: theme => theme.palette.primary.contrastText,
                        fontSize: '1em',
                        padding: 1
                    }}
                    title={
                        noOptions ?
                            <Grid container item xs={12} spacing={1} justifyContent="center">
                                <Grid item>
                                    <Alert severity="info">No company options set, please contact support.</Alert>
                                </Grid>
                            </Grid>
                            :
                            <ResultButton count={result_data} resetForm={resetForm} isFetching={result_isFetching} selected={selected} />
                    }
                />

                <CardContent
                    sx={{
                        borderColor: (theme) => theme.palette.cards.main,
                        borderWidth: '1px 0 1px 0',
                        borderStyle: 'solid',
                        padding: 2,
                        maxHeight: !isMd ? "auto" : "calc(100vh - 450px)",
                        overflow: "auto"
                    }}
                >
                    <Grid container spacing={2}>

                        <Grid item xs={12} justifyContent="center">

                            <Image
                                isFetching={isFetching && !!categories.currentCat}
                                src={categories?.currentCat?.Image}
                                alt="Technical Image"
                                ShowIcon={false}
                                height={200}
                                border
                                zoom
                                sx={{
                                    background: '#fff',
                                    padding: '10px',
                                    textAlign: "center"
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >

                            <Grid container spacing={1} >
                                {isFetching ?
                                    <SkeletonParametricForm />
                                    : fields ?
                                        fields.map((field, i) => {
                                            return <InputTypes field={field} index={i} key={i} />
                                        })
                                        : null}
                            </Grid>
                        </Grid>
                        {extendedFields && extendedFields.length > 0 &&
                            <Grid container item >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                        aria-controls="extended-options"
                                        id="extended-options"
                                        sx={{ background: (theme) => theme.palette.background.default, width: '100%', flexDirection: 'row-reverse' }}
                                    >
                                        <Typography sx={{ color: 'text.primary', paddingLeft: 2 }}>Extended Options</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} >
                                            {(extendedFields).map((field, i) => {
                                                return <InputTypes field={field} index={i} key={i} />
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        }
                        {checkFields && checkFields.length > 0 &&
                            <Grid item xs={12} >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                        aria-controls="graphics-options"
                                        id="graphics-options"
                                        sx={{ background: (theme) => theme.palette.background.default, width: '100%', flexDirection: 'row-reverse' }}
                                    >
                                        <Typography sx={{ color: 'text.primary', paddingLeft: 2 }}>Graphics</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} >
                                            {(checkFields).map((field, i) => {
                                                return <InputTypes field={field} index={i} key={i} checkFields />
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <CardActions sx={{
                    background: (theme) => theme.palette.cards.header,
                }}>
                    {!noOptions ?
                        <ResultButton count={result_data} resetForm={resetForm} isFetching={result_isFetching} selected={selected} />
                        : null}
                </CardActions>
            </Card>

        </>
    );
};

export default ParametricForm;
