import React, { useCallback, useContext, useEffect, useState } from 'react';
import config from '../config';
import { useMutation, useQuery } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import DialogLoader from '../reusable/DialogLoader';
import setLanguageText from '../language/setLanguageText';
import GlobalDialog from '../reusable/GlobalDialog';
import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import SupplierTable from '../settings/companies/visibility/ecommerce/SupplierTable';

const EditableField = ({ BrandID, BrandOrder, updateBrandOrder }) => {

	const [value, setValue] = useState(BrandOrder);
	useEffect(() => {
		if (BrandOrder) {
			setValue(BrandOrder)
		}
	}, [BrandOrder])

	return (
		<input
			type="number"
			value={value}
			onChange={(e) => {
				setValue(e.target.value)
				updateBrandOrder(BrandID, e.target.value)
			}}
		/>
	)
}

const EditCatalogueBrands = ({ template, open, setOpen, handleClose }) => {

	const { user } = useContext(UserContext);
	const [selectAll, setSelectAll] = useState(false);
	const [mergedData, setMergedData] = useState();
	const [allBrands, setAllBrands] = useState();

	const columns = [{
		id: "BrandVisible",
		Header: () => <Typography>is Visible</Typography>,
		accessor: "v",
		noSort: true,
		Cell: (row) => {
			//console.log(row.v)
			return (
				<Checkbox
					checked={row.v ? row.v : false}
					onChange={(e) => handleChange(row, e)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			)
		}
	},
	{
		id: "BrandName",
		Header: () => <Typography>Supplier / Brand Name</Typography>,
		accessor: "n",
		noSort: true,
		//maxWidth: 240,
	}, {
		id: "BrandOrder",
		Header: () => <Typography>Order</Typography>,
		accessor: "o",
		Cell: (row) => {
			if (row.v) {
				return (
					<EditableField
						BrandID={row.i}
						BrandOrder={row.o ? row.o : null}
						updateBrandOrder={updateBrandOrder}
					/>
				)

			} else {
				return (
					<Typography>{row.o}</Typography>
				)
			}
		}
	}]

	const { data } = useQuery(['brand_order'],
		({ signal }) => {
			//console.log(user)
			return axiosRequest({
				signal: signal,
				name: 'get brands', //added to see which call is erroring
				gateway: config.ezbaseAPI.URL,
				endpoint: 'brands',
				api_key: user.api_key,
				method: 'get'
			})
		}, {
		retry: 1,
		//enabled: !!selectedCompany.company_id
	})

	useEffect(() => {
		if (data) {
			setAllBrands(data)
		}
	}, [data])

	const update_template = useMutation((dbTemplate) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'templates',
			api_key: user.api_key,
			method: 'post',
			body: {
				"template_id": template.id,
				"template": dbTemplate
			},
		})
	}, {
		onSuccess: (data) => {
			closeRefreshDialog()
		}
	})

	const save_to_all_companies = useMutation(dbTemplate => {
		//console.log(values)
		return axiosRequest({
			endpoint: 'company/settings',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			method: 'patch',
			body: {
				"template_id": template.id,
                "template": dbTemplate,
                "template_type": template.type
			}
		})
	})

	const handleSubmit = (values) => {
		//console.log(values)
		const clone = structuredClone(values)
		//flatten array
		const flattened = Object.values(clone).flat()
		//order
		const ordered = flattened.sort((a, b) => {
			if (a.o == b.o) {
				return a.n.localeCompare(b.n);
			}
			return (a.o != null ? a.o : Infinity) - (b.o != null ? b.o : Infinity)
		})
		//remove all entries where v is false and create array of ids
		//const finalArray = ordered.filter(item => item.v === true).map(item => [item.i, item.n,item.o])
		const finalArray = ordered.filter(item => item.v === true).map(item => ({ "i": item.i, "o": item.o }))
		//console.log(finalArray)
		update_template.mutate(finalArray)
		save_to_all_companies.mutate(finalArray)
	}


	const updateBrandOrder = (id, number) => {

		setMergedData(current => {
			//console.log(current)
			let clone = structuredClone(current)
			// Iterate through each brand's array to find and update the object with the matching ID
			Object.entries(clone).some((entries) => {
				//console.log(entries)
				let index = entries[1].findIndex((brand) => brand.i === id)
				//console.log(index)
				if (index > -1) {
					clone[entries[0]][index].o = parseInt(number)
				}
			});
			return clone
		})
	}

	useEffect(() => {
		if (allBrands && Object.values(allBrands).length) {
			if (template?.template) {
				let clone = structuredClone(allBrands)
				clone = clone.map(item => {
					return ({
						"i": item.BrandID,
						"s": item.SupplierName,
						"si": item.SupplierID,
						"o": null,
						"v": false,
						"n": decodeURI(item.BrandName)
					})
				})
				//console.log(allBrands)
				template?.template.map((item, i) => {
					//console.log(item)
					let index = clone.findIndex((brand) => parseInt(brand.i) === parseInt(item.i))
					if (index > -1) {
						clone[index].o = item.o
						clone[index].v = true
					}
				})
				//console.log(clone)
				//loop through and group by supplier
				let sorted = _.groupBy(clone, 's')
				//console.log(sorted)
				setMergedData(sorted)

			} else {
				//no settings
				let values = allBrands.map(item => {
					return ({
						"i": item.BrandID,
						"s": item.SupplierName,
						"si": item.SupplierID,
						"o": null,
						"v": false,
						"n": decodeURI(item.BrandName)
					})
				})
				let sorted = _.groupBy(values, 's')
				setMergedData(sorted)
				setSelectAll(false)
			}
		}
	}, [allBrands, template])

	//console.log(templateForm)
	const closeRefreshDialog = () => {
		setOpen(false)
		handleClose()
	}

	const handleChange = (row, e) => {
		//console.log(row)
		//console.log(e)
		setMergedData(current => {
			//console.log(current)
			let clone = structuredClone(current)
			// Iterate through each brand's array to find and update the object with the matching ID
			Object.entries(clone).some((entries) => {
				//console.log(entries)
				let index = entries[1].findIndex((brand) => brand.i === row.i)
				//console.log(index)
				if (index > -1) {
					clone[entries[0]][index].v = e.target.checked
					clone[entries[0]][index].o = e.target.checked ? 9999 : null
				}
			});
			return clone
		})
	}

	const handleAllChange = useCallback((e) => {
		//console.log(e.target.checked)
		//console.log(mergedData)
		let clone = structuredClone(mergedData)

		const updated = {};
		for (const [key, value] of Object.entries(clone)) {
			updated[key] = value.map((each) => ({
				...each,
				'v': e.target.checked,
				'o': each.o < 9999 && each.o !== null ? each.o : e.target.checked ? 9999 : null,
			}));
		}
		setMergedData(updated)
		setSelectAll(e.target.checked)

	}, [mergedData])

	const supplierToggle = (supplier, count) => {
		if (count > 0) {
			//toggle all supplers brands on
			setMergedData(current => {
				let clone = structuredClone(current);
				clone[supplier] = clone[supplier].map((each) => ({
					...each,
					'v': true,
					'o': 9999,
				}));
				return clone;
			})
		} else {
			//toggle all supplers brands off
			setMergedData(current => {
				let clone = structuredClone(current);
				clone[supplier] = clone[supplier].map((each) => ({
					...each,
					'v': false,
					'o': null,
				}));
				return clone;
			})
		}
	}

	console.log(mergedData)

	return (
		<>
			<GlobalDialog
				open={open}
				onClose={() => closeRefreshDialog(false)}
				title={`Update Template: ${template?.name}`}
				buttonClick={() => handleSubmit(mergedData)}
				buttonTitle="Update Template"
				maxWidth="lg"
			>
				<Grid item xs={12}>
					<Alert severity="info">
						Updating this template will update all companies that use this template.
					</Alert>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						label="Toggle all visibility"
						control={<Checkbox
							color='default'
							checked={selectAll}
							onChange={(e) => handleAllChange(e)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ minHeight: '500px', border: '1px solid #ccc' }}>
						{mergedData && Object.values(mergedData).length ?
							<SupplierTable
								columns={columns}
								data={mergedData}
								hidden={[]}
								//sortColumn={sortColumn}
								//sortOrder={sortOrder}
								//handleSorting={handleSorting}
								supplierToggle={supplierToggle}
							/>
							: null
						}
					</Box>
				</Grid>
				{update_template.isLoading || update_template.isSuccess || update_template.isError ?
					<DialogLoader
						fixed
						isLoading={update_template.isLoading}
						mutation={update_template}
						loadingMessage={setLanguageText("Updating Template...")}
						successMessage={setLanguageText("Template Updated")}
					//closeDialog={closeRefreshDialog}
					/>
					: null}
			</GlobalDialog>
		</>
	);
};

export default EditCatalogueBrands;



