import React from "react";
import { Box, Grid } from "@mui/material";
import BuyerCategoryTree from "./categories/BuyerCategoryTree";
import BrandsDropdown from "./filters/BrandsDropdown";
import MobileDrawWrapper from "../reusable/MobileDrawWrapper";

const BuyerCategoriesSideBar = ({ isFetching }) => {


    return (
        <MobileDrawWrapper
            isFetching={isFetching}
            categories
        >
            <Box sx={{ padding: '1em' }} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BrandsDropdown />
                    </Grid>
                    <Grid item xs={12}>
                        <BuyerCategoryTree
                            isFetching={isFetching}
                        //getCurrentProductCategory={getCurrentProductCategory}
                        //getCurrentCategory={getCurrentCategory}
                        />
                    </Grid>
                </Grid>
            </Box>
        </MobileDrawWrapper >
    );
};

export default BuyerCategoriesSideBar;
