
export default function createSingleProduct({ product, brands }){

    return ({
        "product_id": product?._source?.product_id,
        "product_cim_id": product?._source?.product_cim_id,
        "product_number": product?._source?.product_code,
        "product_description": product?._source?.description,
        "product_supplier_number": product?._source?.supplier_number,
        "product_supplier": product?._source?.supplier,
        "product_manufacturer": product?._source?.manufacturer,
        "product_rrp": product?._source?.price,
        "product_cim_code": product?._source?.cim_code,
        "product_ezbase_code": product?._source?.ezbase_code,
        "product_packet_size": product?._source?.packet_size,
        //"product_stock": product?._source?.stock,
    })
}
