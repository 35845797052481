const userDBObject = (values, sub) => {
	const userObject = [{
		"username": values?.username,
		"email": values?.email.toLowerCase(),
		"aws_sub": sub,
		"user_details": {
			 "name": values?.name, 
			 "surname": values?.surname 
		},

		"user_permissions": {
			"administrator": values.administrator, 
			"s_administrator": values.s_administrator,
			"access": {
				"all_companies": values.access?.all_companies, 
				"companies": values.access?.companies 
			},
			"home": {
				"access": values.home?.access,
				"role": values.home?.role
			},
			"buyers": {
				"access": values.buyers?.access,
				"role": values.buyers?.role
			},
			"engineers": {
				"access": values.engineers?.access,
				"role": values.engineers?.role
			},
			"vend": {
				"access": values.vend?.access,
				"role": values.vend?.role
			},
			"coolantcare": {
				"access": values.coolantcare?.access,
				"role": values.coolantcare?.role
			},
			"parametric": {
				"access": values.parametric?.access,
				"role": values.parametric?.role
			},
			"application": {
				"access": values.application?.access,
				"role": values.application?.role
			},
			"solution": {
				"access": values.solution?.access,
				"role": values.solution?.role
			},
			"design": {
				"access": values.design?.access,
				"role": values.design?.role
			},
			// "cadcam": {
			// 	"access": values.cadcam?.access,
			// 	"role": values.cadcam?.role
			// },
			"cnc": {
				"access": values.cnc?.access,
				"role": values.cnc?.role
			},
			"kpi": {
				"access": values.kpi?.access,
				"role": values.kpi?.role
			},
			"fixtures": {
				"access": values.fixtures?.access,
				"role": values.fixtures?.role
			},
			"resources": {
				"access": values.resources?.access,
				"role": values.resources?.role
			},
		}
	}]

	return userObject
}
export default userDBObject