import React, {
	useContext,
	useState,
	useMemo,
	useEffect
} from 'react';
import { Grid } from '@mui/material';
import GridView from './GridView/GridView';
import PageContent from '../PageContent';
import MachineTable from './MachinesTable/MachinesTable';
import machinesTable_columns from './MachinesTable/machinesTable_columns';
import LoadingScreen from '../reusable/LoadingScreen';
import { isMobileOnly } from 'react-device-detect';
import { LayoutContext } from '../../store/LayoutContext';
import { CompanyContext } from '../../store/CompanyContext';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import { MachineContext } from '../store/MachineContext';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import AddMachineDialog from '../FloatingActionButton/AddMachineDialog';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import OutOfControl from './Headers/OutOfControl';
import GroupDropdown from '../dashboard/Selection_Row/DropdownGroups';
import GridButton from './Headers/GridButton';

export default function Machines() {

	const { selectedCompany } = useContext(CompanyContext);
	const [open, setOpen] = useState(false);
	const { language } = useContext(LayoutContext);
	const [visibleMachines, setVisibleMachines] = useState();
	const [isFetching, setIsFetching] = useState(true);

	const {
		gridView,
		noMachines,
		filteredMachines,
		outOfControl,
		isOutOfControl
	} = useContext(MachineContext);

	useEffect(() => {
		if (filteredMachines || isOutOfControl) {
			setVisibleMachines(isOutOfControl ? outOfControl : filteredMachines);
		}
	}, [filteredMachines, isOutOfControl, outOfControl])

	const columns = useMemo(() => machinesTable_columns({
		selectedCompany: selectedCompany,
		language: language,
	}), [selectedCompany, language]);

	useEffect(() => {
		if (filteredMachines.length > 0 && !noMachines) {
			setIsFetching(false);
		} else if (filteredMachines.length === 0 && noMachines) {
			setIsFetching(false);
		}
	}, [filteredMachines])

	return (
		<>
			<InnerHeaderWrap>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<GlobalCompanySelect fullWidth addAll />
					</Grid>
					<Grid item xs={12} md={3}>
						<GroupDropdown />
					</Grid>
					<Grid item xs={6} md={3} >
						<OutOfControl />
					</Grid>
					{!isMobileOnly ?
						<Grid container item xs={6} md={3} justifyContent="end">
							<GridButton />
						</Grid>
						: null}
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				{noMachines ?
					<LoadingScreen
						position="relative"
						loading={false}
						text={`Company has no machines, please add a machine`}
						customAction={
							<Grid container justifyContent="space-between" alignContent="center">
								<Grid item style={{ margin: 'auto' }}>
									<PrimaryBtnIcon
										icon={faPlus}
										text="Add First Machine"
										onClick={e => setOpen(true)}
									/>
									<AddMachineDialog
										isDialog={open}
										setIsDialog={setOpen}
									/>
								</Grid>
							</Grid>
						}
					/>
					: gridView || isMobileOnly ?
						<GridView
							columns={columns}
							defaultHiddenColumns={selectedCompany?.company_settings?.machines_defaults}
						/>
						: visibleMachines &&
						<MachineTable
							//isFetching={isFetching}
							columns={columns}
							data={visibleMachines}
							defaultHiddenColumns={selectedCompany?.company_settings?.machines_defaults}
							headerSettings={
								['out_of_control']
							}
							isFetching={isFetching}
						/>
				}
			</PageContent >
		</ >
	);
}

