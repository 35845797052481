import {
    Button, Grid, Paper
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Storage } from 'aws-amplify';
import { UserContext } from '../store/UserContext';
import config from '../config';
import useActivityLog from '../users/activity/useActivityLog';
import { Formik } from 'formik';
import * as Yup from "yup"
import { useMutation } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import getFileExtension from '../helpers/getFileExtension';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-light-svg-icons';
import ResourceForm from './ResourceForm';
import DialogLoader from '../reusable/DialogLoader';
import GlobalDialog from '../reusable/GlobalDialog';


const AddResource = ({
    categories,
    refreshResources,
    refetchCategories,
    selectedCategory,
    edit
}) => {

    const formRef = useRef();
    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(null);
    const [openLevels, setOpenLevels] = useState(["0"]);
    //image uploads
    const [file, setFile] = useState([])
    const [slider, setSlider] = useState()
    const [preview, setPreview] = useState();
    const [loading, setLoading] = useState(false);

    const resouceValidation = Yup.object({
        resource_cat_id: Yup
            .number('Select category')
            .required('Please select a category'),
        resource_file_name: Yup
            .string('Enter the resource name')
            .required('Resource name is required'),
        resource_description: Yup
            .string('Enter resource description')
    });

    const newResource = {
        resource_cat_id: "",
        resource_type: "",
        resource_settings: "",
        resource_description: "",
        resource_file_name: "",
        //resource_upload_time: "",
        resource_cat_name: "",
        resource_promotion: false,
        resource_announcement: false,
        resource_silder_image: ""
    }

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();

    Storage.configure({
        AWSS3: {
            bucket: config.s3.BUCKET,
            region: config.s3.REGION
        }
    });

    const mutation = useMutation(values => {
        //console.log(values)
        //console.log(request)
        return axiosRequest({
            endpoint: `resources`,
            method: 'post',
            gateway: config.factoryAPI.URL,
            api_key: user.api_key,
            body: values
        })
    }, {
        onSuccess: async (response, variables) => {
            //console.log(response)
            //console.log(variables)
            //console.log(file)
            if (!response.errorMessage) {

                const file_ext = getFileExtension(file[0])
                const file_path = `resources/${response.insertId}.${file_ext}`;

                try {
                    //add main file
                    await Storage.put(file_path, file[0], {
                        progressCallback(progress) {
                            setProgress((progress.loaded / progress.total) * 100);
                        },
                        contentType: variables.resource_type
                    });

                    console.log(variables)
                    // add thmbnail if preview image is set
                    if (preview) {
                        await Storage.put(`resources/${response.insertId}_preview.png`, preview,
                            {
                                progressCallback(progress) {
                                    setProgress((progress.loaded / progress.total) * 100);
                                },
                                //contentEncoding: "base64",
                                contentType: "image/png"
                            }
                        );
                    }

                    //add slider image for promotion slider
                    if (slider) {
                        const image_ext = getFileExtension(slider)
                        //save image as jpeg
                        if (image_ext === "jpg") {
                            image_ext = "jpeg"
                        }
                        await Storage.put(`resources/slider-${response.insertId}.${image_ext}`, slider, {
                            progressCallback(progress) {
                                setProgress((progress.loaded / progress.total) * 100);
                            },
                            contentType: variables.resource_silder_image
                        });
                    }

                } catch (error) {
                    console.log(error);
                } finally {
                    updateActivityLog.mutate({
                        activity_details: {
                            area: "factory",
                        },
                        activity_type: "resource added"
                    })
                }

            } else {
                console.log(response.errorMessage)
            }
        }
    })

    const closeModal = () => {
        setOpen(false)
        //console.log(formRef)
        formRef.current.resetForm()
        setProgress()
        setFile([])
        setSlider()
        setPreview()
    }


    //save columns
    const submitResource = (values) => {
        //console.log(values)
        let object = {
            "resource_cat_id": values.resource_cat_id,
            "resource_type": values.resource_type,
            "resource_description": values.resource_description,
            "resource_file_name": values.resource_file_name,
            //"resource_upload_time": values.resource_upload_time,
            "resource_cat_name": values.resource_cat_name,
            "resource_settings": [{
                "resource_promotion": values.resource_promotion,
                "resource_announcement": values.resource_announcement,
                "resource_silder_image": values.resource_silder_image,
                "resource_preview_image": preview ? true : false
            }],

        }
        mutation.mutate(object)
    }


    useEffect(() => {
        if (categories) {
            let levels = ["0"]
            categories.forEach((item) => {
                levels.push(JSON.stringify(item.resource_cat_id))
            })
            setOpenLevels(levels)
            //console.log(response)
        }
    }, [categories])

    //set file
    const setFileUpload = (newFiles) => {
        setLoading(true)
        setFile([newFiles])
    }

    //set silder image
    const setSilderUpload = (image) => {
        setSlider(image)
    }

    //set preview image
    const setPreviewUpload = (image) => {
        if (image) {
            setPreview(image)
        } else {
            setPreview()
            setFile([])
        }
    }

    return (
        <>
            <Grid container 
            //alignItems='center' 
            //justifyContent='center'
            // sx={{
            //     height: '100%',
            //     minHeight: '200px',
            //     background: (theme) => theme.palette.background.default
            // }}
            >
                <Grid item>
                    <Button
                        //fullWidth
                        disabled={edit}
                        variant="contained"
                        component="label"
                        onClick={() => setOpen(true)}
                        startIcon={<FontAwesomeIcon icon={faBook} />}
                    >
                        Add New Resource
                    </Button>
                </Grid>
            </Grid>
            <Formik
                innerRef={current => formRef.current = current} //access methods
                initialValues={newResource}
                validationSchema={resouceValidation}
                onSubmit={submitResource}
            >
                {({ handleSubmit, values }) => {
                    //console.log(values)
                    return (

                        <GlobalDialog
                            open={open}
                            onClose={closeModal}
                            title={'Add Resource'}
                            buttonTitle={'Add Resource'}
                            buttonClick={handleSubmit}
                            maxWidth="md"
                            disabled={!file.length}
                        >
                            <ResourceForm
                                categories={categories}
                                setFileUpload={setFileUpload}
                                setPreviewUpload={setPreviewUpload}
                                preview={preview}
                                setSilderUpload={setSilderUpload}
                                slider={slider}
                                selectedCategory={selectedCategory}
                                progress={progress}
                                openLevels={openLevels}
                                setOpenLevels={setOpenLevels}
                                file={file}
                                loading={loading}
                                setLoading={setLoading}
                            />
                            {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                                <DialogLoader
                                    isLoading={mutation.isLoading}
                                    mutation={mutation}
                                    loadingMessage="Adding Resource..."
                                    successMessage="Add Resource"
                                    closeDialog={() => {
                                        closeModal()
                                        refreshResources()
                                        refetchCategories()
                                    }}
                                    fixed
                                />
                                : null}
                        </GlobalDialog>
                    )
                }}
            </Formik>
        </>
    );
};

export default AddResource;
