import React, { useReducer, createContext } from 'react';
import { CategoryReducer } from './CategoryReducers';

export const CategoryContext = createContext();

CategoryContext.displayName = 'Shop Categories';

export const CategoryProvider = ({ children }) => {
	const [categories, dispatchCategories] = useReducer( CategoryReducer, {
		currentCat: {},
		allCats: [],
		subCats: [],
		menuCats: [],
		flatCats: [],
		openLevel: []
	});

	const contextValues = {
		categories,
		dispatchCategories
	};

	return (
		<CategoryContext.Provider value={contextValues}>
			{children}
		</CategoryContext.Provider>
	);
};
