import React, { useCallback,  useState } from "react";
import { Button, Divider, Grid, InputAdornment, InputLabel, Stack, TextField, Typography } from "@mui/material";
import InputLables from "../parametric/inputs/InputLables";

const IntegerFormula = ({ field, updateSizeFilters,ValuesMetric,  formula }) => {


    const [localValue, setLocalValue] = useState('');

    Number.prototype.pad = function(size) {
        var s = String(this);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    }

    const updateSelected = useCallback((value) => {

            let number = (value * 10).pad(4);
            //console.log(number)
            updateSizeFilters(number)
    }, [])

    const updateFromDefaults = useCallback((value) => {

        let number = value.replace(/\s|ø/g, "")
        setLocalValue(number)  
        number = (parseInt(number) * 10).pad(4);
        //console.log(number)
        updateSizeFilters(number)

    }, [])
    
    return (
        <>
            <InputLables filtersView={false} field={field} />
            {/* <Grid item xs={6}>
                <InputLabel htmlFor={field.Name} sx={{ position: 'relative', transform: 'inherit', margin: '0' }}>
                    <Typography sx={{ color: 'text.primary' }} >
                        {field.Name}
                    </Typography>
                </InputLabel>
            </Grid> */}
            <Grid item xs={6} >
                <TextField
                    sx={{width: '100%','& legend' : { display: 'none'}}}
                    autoComplete='off'
                    id={field.Name}
                    value={localValue}
                    label={''}
                    name={field.Field}
                    onChange={e => {
                        setLocalValue(e.target.value)
                        updateSelected(e.target.value)
                    }}
                    type="number"
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    inputProps={{ step: 0.1, max: 999.99 }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                                {field.Unit}
                            </InputAdornment>
                        ,
                    }}
                />
            </Grid>
            
               <Stack direction="row" spacing={2} sx={{margin : '1em 0'}} >
               { ValuesMetric && Object.entries(ValuesMetric).map((item, i) => {
                   return (
                       <Grid>
                           <Button key={i} disabled={!item[1]} variant="contained" onClick={e => updateFromDefaults(item[0])}>{item[0]}</Button>
                       </Grid>
                   )
               })}
               </Stack>
            
        </>
    );
};

export default IntegerFormula;
