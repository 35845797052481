
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { useMemo } from 'react';

const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = [];
    preFilteredRows.forEach((row) => {
      options.push(row.values[id]);
    });

    var filtered = options.filter(x => x !== undefined);

    const unique = [...new Set(filtered)]

 //console.log(unique)

    return [...unique.values()];

  }, [id, preFilteredRows]);

  //console.log(filterValue)
  //console.log(options)
  // Render a multi-select box

  return (

    <Autocomplete
      id={id}
      options={options ? options : []}
      getOptionLabel={option => option || ''}
      renderInput={params => (
        <TextField
          sx={{
            '& .MuiSvgIcon-root': {
              color: (theme) => theme.palette.primary.contrastText
            },
            '& .MuiInputBase-root': {
              marginTop: '5px'
            }, 
            '& .MuiInputBase-input input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block'
            },
            '& .MuiInput-root:before, & .MuiInput-root:hover:not(.Mui-disabled):before' :{
              borderColor: (theme) => theme.palette.primary.contrastText
            },
            '& .MuiInput-underline': {
              color: (theme) => theme.palette.primary.contrastText
            }
          }}
          //required
          {...params}
          //label={title}
          variant="standard"
        //InputLabelProps={{ shrink: true }}  
        />
      )}
      sx={{ color: (theme) => theme.palette.primary.contrastText }}
      value={filterValue || `All (${options.length})`}
      //value={filterValue}
      //isOptionEqualToValue={(option, value) => option.label === value.label}
      onChange={(e, value) => {
        setFilter(value || undefined);
      }}
    />
    // <Autocomplete
    //     id={id}
    //     options={options}
    //     getOptionLabel={option => option || ''}
    //     renderInput={params => (
    //         <TextField
    //             //required
    //             {...params}
    //             label={title}
    //             variant="standard"
    //         />
    //     )}
    //     //value={filterValue || `All (${options.length})`}
    //     value={filterValue}
    //     onChange={(e,value) => {
    //       //console.log(value)
    //       setFilter(value || undefined);
    //     }}

    // />
  );
}
export default SelectColumnFilter;