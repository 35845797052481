import React, { useContext, useEffect, useState } from 'react';
import { Card, CardActions, CardContent, ListItemIcon, Checkbox, FormControlLabel, Grid, Menu, ListItem, Typography, ListItemText, ListItemButton } from '@mui/material';
import { LayoutContext } from '../../store/LayoutContext';
import checkboxes from './checkboxes.json';
import setLanguageText from '../../language/setLanguageText';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../axios/axoisRequest';
import { useQuery } from "@tanstack/react-query";
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserLevels = () => {

    const [groupedRoles, setGroupedRoles] = useState();
    const [selectedAreaID, setSelectedAreaID] = useState();
    const [selectedRoleID, setSelectedRoleID] = useState();
    const [boxes, setboxes] = useState();
    const [userRoles, setUserRoles] = useState();
    const [userRolePermissions, setUserRolePermissions] = useState();

    const { user } = useContext(UserContext);
    const { app } = useContext(LayoutContext);

    const { data } = useQuery({
        queryKey: ['roles'],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'roles',
            api_key: user.api_key,
            method: 'get',
            params: {
                all_roles: true
            }
        }),
        retry: false
    })



    useEffect(() => {
        if (data) {
            //console.log(data)
            //group by area
            const grouped = (data) => {
                return data.reduce((r, a) => {
                    r[a.role_area] = [...r[a.role_area] || [], a];
                    return r;
                }, {});
            }
            let groupedData = grouped(data);
            //console.log(groupedData)
            setGroupedRoles(groupedData);

        }
    }, [data])

    const onUserAreaChange = (area, i) => {
        //console.log(area[0])
        if (area[0]) {

            //set role checkboxs
            let selected = checkboxes.find(checkbox => checkbox.id === area[1][0].role_area);
            setboxes(selected);
            //selected states
            setSelectedAreaID(i);
            setSelectedRoleID(0); //select first role
            //set table values
            setUserRoles(area[1])
            setUserRolePermissions(area[1][0].role_permissions); //select first role permissions

        }
    }

    const onUserLevelChange = (level, i) => {
        //console.log(level)
        if (level) {

            //selected states
            setSelectedRoleID(i); //select first role

            //current role permissions
            setUserRolePermissions(level.role_permissions);

        }
    }


    return (

        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText("User Roles")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Card
                    elevation={0}
                    sx={(theme) => ({
                        border: '1px solid #ccc',
                        padding: 0,
                        '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText
                        }
                    })}>
                    <CardContent sx={{ padding: 0, paddingBottom: ' 0!important' }}>
                        <Grid container  >
                            <Grid item xs={12} sm={4} sx={{ borderRight: '1px solid #ccc' }} >
                                <ListItem sx={(theme) => ({
                                    backgroundColor: theme.palette.background.default,
                                    borderBottom: '1px solid #ccc',
                                })}>
                                    <ListItemText primary="Application area" />
                                </ListItem>
                                {groupedRoles ? Object.entries(groupedRoles).map((area, i) => {

                                    //get matching icon from app.menu
                                    const appArea = app.menu.find(app => app.area === area[0]);
                                    console.log(appArea)

                                    return (

                                        <ListItemButton
                                            onClick={() => onUserAreaChange(area, i)}
                                            key={i}
                                            selected={selectedAreaID === i}
                                            sx={{
                                                padding: '1em 0',
                                                transition: 'all 0.3s ease',
                                                padding: '1em 0',
                                                '&.Mui-selected, &:hover , &.Mui-selected:hover': {
                                                    background: theme => `${theme.palette.primary.main}!important`,
                                                    color: theme => theme.palette.primary.contrastText,
                                                    '& svg': {
                                                        color: theme => theme.palette.primary.contrastText,
                                                    }
                                                }
                                            }}
                                        >
                                            <ListItemIcon sx={{ width: theme => `calc(${theme.spacing(10)} + 1px)`, textAlign: 'center' }}>
                                                <FontAwesomeIcon style={{ margin: '0 auto' }} icon={appArea.icon} size={'xl'} />
                                            </ListItemIcon>
                                            <Typography >{appArea ? appArea.buttonText : area[0]}</Typography>
                                        </ListItemButton>
                                    )
                                })
                                    : null}
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ borderRight: '1px solid #ccc' }} >
                                <ListItem sx={(theme) => ({
                                    backgroundColor: theme.palette.background.default,
                                    borderBottom: '1px solid #ccc',
                                })}>
                                    <ListItemText primary="User Roles" />
                                </ListItem>
                                {userRoles ? userRoles.map((level, i) => (
                                    <ListItemButton
                                        onClick={() => onUserLevelChange(level, i)}
                                        key={i}
                                        selected={selectedRoleID === i}
                                        sx={{
                                            '&.Mui-selected, &:hover , &.Mui-selected:hover': {
                                                background: theme => `${theme.palette.primary.main}!important`,
                                                color: theme => theme.palette.primary.contrastText,
                                                '& svg': {
                                                    color: theme => theme.palette.primary.contrastText,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={level?.role_name}
                                            secondary={level?.role_description}
                                        />
                                    </ListItemButton>
                                )) : null}
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <ListItem sx={(theme) => ({
                                    backgroundColor: theme.palette.background.default,
                                    borderBottom: '1px solid #ccc',
                                })}>
                                    <ListItemText primary="Permissions" />
                                </ListItem>
                                {boxes?.checkboxes && userRolePermissions ? boxes.checkboxes.map((item, i) => {
                                    //match values with userRolePermissions
                                    console.log(item)

                                    //check to see if the permission is in the array
                                    const checked = userRolePermissions.includes(item.group);
                                    console.log(checked)

                                    if (checked === true && item) {
                                        return (
                                            <ListItem key={i}>
                                                <FormControlLabel
                                                    sx={{
                                                        padding: '0 10px',
                                                        width: '100%',
                                                        lineHeight: 1
                                                    }}
                                                    control={
                                                        <Checkbox size="small"
                                                            checked={true}
                                                            onChange={(e, checked) => {
                                                                //handleChange(!filtered[index].c, filtered[index].v, field);
                                                            }}
                                                            name={item.group} />
                                                    }
                                                    label={
                                                        <Grid
                                                            sx={{ lineHeight: 1 }}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                            > {item.description}</Typography>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    } else {
                                        return (
                                            <ListItem key={i}>
                                                <FormControlLabel
                                                    sx={{
                                                        padding: '0 10px',
                                                        width: '100%',
                                                        lineHeight: 1
                                                    }}
                                                    control={
                                                        <Checkbox size="small"
                                                            checked={false}
                                                            onChange={(e, checked) => {
                                                                //handleChange(!filtered[index].c, filtered[index].v, field);
                                                            }}
                                                            name={item.group} />
                                                    }
                                                    label={
                                                        <Grid
                                                            sx={{ lineHeight: 1 }}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                            > {item.description}</Typography>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    }
                                }) : null}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </TabContent>
        </>

    );
};

export default UserLevels;
