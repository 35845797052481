import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useQuery } from '@tanstack/react-query';

const DropdownCoolantsByManufacturer = ({ manufacturer, setCoolant }) => {

	const { user } = useContext(UserContext);
	const [coolants, setCoolants] = useState([]);
	const [value, setValue] = useState(null);

	const { data } = useQuery({
		queryKey: ['all coolants', manufacturer],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				manufacturer: manufacturer.brand_id
			}
		}),
		enabled: !!manufacturer?.brand_id,
		retry: false
	})

	useEffect(() => {
		if (data) {
			setCoolants(data);
			setValue(data[0] || null);
		}
	}, [data]);

	useEffect(() => {
		if (value) {
			setCoolant(value);
		}
	}, [setCoolant, value]);

	const onSelectCoolant = (e, coolant) => {
		setValue(coolant);
	};

	return (
		<Autocomplete
			disableClearable
			id="coolants"
			options={coolants}
			getOptionLabel={option => option.product_name || ''}
			renderInput={params => (
				<TextField
					required
					{...params}
					variant="outlined"
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Coolant")}
				/>
			)}
			value={value}
			onChange={onSelectCoolant}
		/>
	);
};

export default DropdownCoolantsByManufacturer;
