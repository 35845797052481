import setLanguageText from "../language/setLanguageText";

const addAllOption = (companies) => {
    const copy = [...companies]
    // if fetched Companies is empty
    if (!copy.length) {
        return copy;
    }
    // add All company if there is more than 1 company
    if (copy.length > 1) {
        copy.unshift({ 'company_id': 0, 'company_name': `${setLanguageText('All Companies')} (${companies.length})` });
    }
    return copy;
};
export default addAllOption;
