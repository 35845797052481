import React, {
    useEffect,
    useContext,
    useState,
} from "react";
import { UserContext } from "../../store/UserContext";
import {  useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { ShopContext } from "../../store/ShopContext";
import { SAVE_SHOP_CATEGORIES, UPDATE_FILTERS } from "../../store/ShopReducers";
import Header from "./Header";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { Grid, useMediaQuery } from "@mui/material";
import flatCategoriesToTree from "../../shop/reusable/flatCategoriesToTree";
import BuyerCategoriesSideBar from "../BuyerCategoriesSideBar";
import { useSearchParams } from "react-router-dom";
import { CLEAR_PRODUCTS, UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { LayoutContext } from "../../store/LayoutContext";
import ResultToggleFilters from "../filters/ResultToggleFilters";
import { isMobile } from 'react-device-detect';
import Filters from "../filters/Filters";
import { ProductsContext } from "../../store/ProductsContext";
import useProductObjectEzbase from "../../hooks/useProductObjectEzbase";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";
import ProductOptions from "../../products/shared/ProductOptions";
import ProductGroupedItems from "../../products/ezbase/ProductGroupedItems";
import ProductGroupTable from "../../products/ezbase/ProductGroupTable";
import BuyerCategoryCards from "../categories/BuyerCategoryCards";

const BuyerCatalogueLayout = (props) => {

    console.log(props)

    const { user } = useContext(UserContext);
    const { dispatchShop, dispatchFilters, brands } = useContext(ShopContext);
    const { products, dispatchProducts } = useContext(ProductsContext)
    const { layout, dispatchLayout } = useContext(LayoutContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [level, setLevel] = useState();
    const { isFetching } = useProductObjectEzbase();
    const [categoryId, setCategoryId] = useState();
    const [productCategoryId, setProductCategoryId] = useState();

    //fires on params change
    useEffect(() => {

        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            let categoryLevel = urlParams.get("c")
            let productLevel = urlParams.get("p")
            if (categoryLevel && !productLevel) {
                //clear products
                //console.log('reset products')
                setLevel('category')
                dispatchProducts({
                    type: CLEAR_PRODUCTS,
                });
                //Clear Filters
                dispatchFilters({
                    type: UPDATE_FILTERS,
                    payload: []
                });
                //category level
                setCategoryId({ 'id': parseInt(categoryLevel), 'type': 'categoryLevel' })
                setProductCategoryId()

            } else if (categoryLevel && productLevel) {
                setLevel('product')
                //reset products counts
                dispatchProducts({
                    type: UPDATE_PRODUCTS,
                    payload: {
                        offset: 0,
                        count: null,
                        groupCount: null
                    }
                });
                //reset page
                dispatchLayout({
                    type: UPDATE_LAYOUT,
                    payload: {
                        page: 1,
                    }
                });
                //product level
                setCategoryId({ 'id': parseInt(categoryLevel), 'type': 'productLevel' })
                setProductCategoryId({ 'id': parseInt(productLevel) })
            }
        }
        else {

            //if not URL parameters
            setSearchParams({ 'c': 0 })
            setCategoryId({ 'id': 0, 'type': 'categoryLevel' })
            //clear products
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    allProducts: [],
                    offset: 0,
                    count: null,
                    groupCount: null
                }
            });
            //Clear Filters
            dispatchFilters({
                type: UPDATE_FILTERS,
                payload: []
            });
            //reset page
            dispatchLayout({
                type: UPDATE_LAYOUT,
                payload: {
                    page: 1,
                }
            });

        }

    }, [window.location.search]);


    //--------------------------- GET ALL CATS ------------------------------//

    const { data: dataTree, isFetching: isFetchingTree } = useQuery(['tree_categories'],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.ezbaseAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
            params: {
                'tree': true
            }
        }), {
        retry: 1
    })

    //if categories are returned
    useEffect(() => {

        if (dataTree && brands?.currentBrands && Object.values(brands.currentBrands).length) {
            let clone = structuredClone(dataTree);
            const removedCategories = clone.filter(category => {
                const bcount = category.bcount;
                return bcount.length > 0 && bcount.some(brand => {
                    return brands.currentBrands.some(currentBrand => currentBrand.Brand === brand.b);
                });
            });
            if (removedCategories.length > 0) {
                const categoryStructured = flatCategoriesToTree(removedCategories, 'pID', 'ID', 0);
                //console.log(categoryStructured)
                const full = [{
                    "ID": 0,
                    "cName": "All Products",
                    "children": categoryStructured
                }];
                dispatchShop({
                    type: SAVE_SHOP_CATEGORIES,
                    payload: {
                        allCats: full
                    }
                });
            }
        }
    }, [
        dataTree,
        brands?.currentBrands
    ]);

    //create a full streucture to help build breacrumbs
    useEffect(() => {
        if (dataTree) {
            let clone = structuredClone(dataTree);
            let breadcrumbCats = flatCategoriesToTree(clone, 'pID', 'ID', 0);
            const breadcrumbCatsfull = [{
                "ID": 0,
                "cName": "All Products",
                "children": breadcrumbCats
            }];

            dispatchShop({
                type: SAVE_SHOP_CATEGORIES,
                payload: {
                    breadcrumbCats: breadcrumbCatsfull
                }
            });
        }
    }, [dataTree]);

    //--------------------------- GET CURRENT CATEGORY SUB FOR PRODUCT LEVEL ------------------------------//

    const { data: dataProCat, isFetching: isFetchingProCat } = useQuery(['product_category_id', productCategoryId?.id],
        ({ signal }) => axiosRequest({
            signal: signal,
            name: 'product category level',
            gateway: config.ezbaseAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
            params: {
                parentID: productCategoryId?.id
            }
        }),
        {
            enabled: !!productCategoryId,
            retry: 1
        }
    )

    useEffect(() => {
        if (dataProCat) {
            //console.log(dataProCat)
            dispatchShop({
                type: SAVE_SHOP_CATEGORIES,
                payload: {
                    currentProductCat: dataProCat[0]
                }
            });
        }
    }, [dataProCat]);

    //--------------------------- GET CURRENT CATEGORY ------------------------------//


    const { data: dataCat, isFetching: isFetchingCat } = useQuery(['category_id', categoryId?.id, categoryId?.type],
        ({ signal }) => axiosRequest({
            signal: signal,
            name: 'category level',
            gateway: config.ezbaseAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
            params: {
                parentID: categoryId.id,
                type: categoryId.type
            }
        }), {
        enabled: !!categoryId,
        retry: 1
    }
    )


    useEffect(() => {
        if (dataCat && categoryId) {
            let payload = {
                currentCat: dataCat[0],
                productSubCat: []
            }
            //remove product sub cat if category level
            if (categoryId?.type === 'categoryLevel') {
                payload = {
                    ...payload,
                    currentProductCat: []
                }
            }
            //save category
            dispatchShop({
                type: SAVE_SHOP_CATEGORIES,
                payload: payload
            });
        }
    }, [dataCat, categoryId]);


    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <Header  {...props}  />
            <InnerContentWrap>
                <Grid container item spacing={isMobile ? 1 : 2} alignItems="flex-start" >
                    <Grid container spacing={isMobile ? 1 : 2} item xs={12} md={4} lg="auto"
                        sx={{
                            width: lgUp ? '400px!important' : 'auto',
                            maxWidth: lgUp ? '400px!important' : '100%'
                        }}>
                        {level && level === "product" ?
                            <Grid item xs={12} >
                                <Filters />
                            </Grid>
                            : level && level === "category" ?
                                <BuyerCategoriesSideBar
                                    isFetching={isFetchingTree}
                                />
                                : null}
                    </Grid>
                    <Grid item container spacing={isMobile ? 1 : 2} xs={12} md={8} lg>
                        {level && level === "product" ?
                            <>
                                <ResultToggleFilters />

                                {products.noProducts ? (
                                    <Grid item xs={12}>
                                        <ProductsNotAvailable />
                                    </Grid>
                                ) :
                                    <>
                                        <ProductOptions isFetching={isFetching} />
                                        {layout.layout === "grid" ? (
                                            <>
                                                {products?.allProducts ? products?.allProducts.map((product, i) => {
                                                    return (
                                                        <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                                                            <ProductGroupedItems product={product} isFetching={isFetching} />
                                                        </Grid>
                                                    )
                                                }) : null}
                                            </>
                                        ) : layout.layout === "engineer" ? (
                                            <ProductGroupTable isFetching={isFetching} />
                                        ) : null
                                        }
                                        {products?.allProducts ? <ProductOptions isFetching={isFetching} /> : null}
                                    </>
                                }
                            </>
                            : level && level === "category" ?
                                <BuyerCategoryCards isFetching={isFetchingCat} />
                                : null}
                    </Grid>
                </Grid>
            </InnerContentWrap>

        </>
    );
};

export default BuyerCatalogueLayout;
