import React from "react";
import { Link } from "react-router-dom";
import PageContent from "../coolantcare/PageContent";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import { Alert } from "@mui/material";

const NoAccess = () => {
    return (
        <>
        <InnerHeaderWrap>
            <Alert severity="error">Sorry, you don&apos;t have access to the area.</Alert>
        </InnerHeaderWrap>
        <PageContent>
            <Link className="btn btn-lg btn-primary btn-block" to="/">
                Back Home
            </Link>
        </PageContent>
    </>
    );
}
export default NoAccess;