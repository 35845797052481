import { Card, CardContent } from '@mui/material';
import { makeStyles } from "@mui/styles"
import React from 'react';

const useStyles = makeStyles(theme => ({
	card: { height: '100%' },
	cardContainer: { height: '100%' },
	cardContent: {
		paddingTop: 0,
		flex: 1
	},
	cardFooter: {
		padding: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.background.light}`
	}
}));

export default function ChartCard({ children }){
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardContent justify="center">{children}</CardContent>
		</Card>
	);
};

