import React, { useContext, useState } from 'react';
import {
    MenuItem,
    Grid,
    Alert,
    Avatar
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../reusable/GlobalDialog';
import { UserContext } from '../store/UserContext';
import { useMutation } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import setLanguageText from '../language/setLanguageText';
import DialogLoader from '../reusable/DialogLoader';

const DeleteTemplate = ({ template, refetch, handleClose }) => {

    const [confirmDelete, setConfirmDelete] = useState(false);
    const { user } = useContext(UserContext)

    const deleteTemplate = useMutation((template_id) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'get',
            params: {
                "delete": true,
                "template_id": template_id
            }
        })
    }, {
        onSuccess: (data) => {
            //console.log(data)
        }
    })

    const handleDeleteFile = (template_id) => {
        deleteTemplate.mutate(template_id)
    }

    return (
        <>
            <MenuItem
                onClick={() => {
                    setConfirmDelete(true)
                }}
            >
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <FontAwesomeIcon size="xs" icon={faTrash} />
                </Avatar>
                {setLanguageText("Delete Template")}
            </MenuItem>

            {confirmDelete &&
                <GlobalDialog
                    open={confirmDelete}
                    onClose={() => {
                        setConfirmDelete(false)
                        handleClose()
                    }}
                    title={`Delete Template : ${template.name}`}
                    buttonClick={() => handleDeleteFile(template.id)}
                    buttonTitle="Delete Template"
                    //disabled={mutation.isLoading}
                    //loading={mutation.isLoading}
                    maxWidth="sm"
                >
                    <Grid item>
                        <Alert severity="error">
                            Are you sure you want to delete template: <strong>{template.name}</strong><br />
                            This action can not be undone.
                        </Alert>
                    </Grid>

                    {deleteTemplate.isLoading || deleteTemplate.isSuccess || deleteTemplate.isError ?
                        <DialogLoader
                            fixed
                            isLoading={deleteTemplate.isLoading}
                            mutation={deleteTemplate}
                            loadingMessage="Deleting Template..."
                            successMessage="Deleted Template"
                            closeDialog={() => {
                                refetch()
                                setConfirmDelete(false)
                            }}
                        />
                        : null
                    }
                </GlobalDialog>
            }
        </>
    );
};

export default DeleteTemplate;
