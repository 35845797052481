import React from "react";
import config from '../../config';
import cognitoPromise from '../../cognito/cognitoPromise';
import { Switch } from "@mui/material";

//disable cognito user
const disableUser = async (row) => {
    //console.log(row)
    const userParams = {
        UserPoolId: config.cognito.USER_POOL_ID,
        Username: row.original.Username
    };
    try {
        await cognitoPromise({
            params: userParams,
            fn: 'adminDisableUser'
        });
    } catch (err) {
        console.log(err)
    }
};

//enable cognito user
const enableUser = async (row) => {
    const userParams = {
        UserPoolId: config.cognito.USER_POOL_ID,
        Username: row.original.Username
    };
    try {
        await cognitoPromise({
            params: userParams,
            fn: 'adminEnableUser'
        });

    } catch (err) {
        console.log(err)
    }
};

const EnableUser = ({ row, setData }) => {
    //console.log(row.original)
    return (
        <>
            <Switch
                checked={row?.original?.Enabled}
                onChange={() => {
                    if (row?.original?.Enabled) {
                        disableUser(row).then(() => {
                            setData(current => {
                                let clone = structuredClone(current)
                                clone[row.index].Enabled = false
                                //console.log(clone)
                                return clone
                            })
                        })

                    } else {
                        enableUser(row).then(() => {
                            setData(current => {
                                let clone = structuredClone(current)
                                clone[row.index].Enabled = true
                                //console.log(clone)
                                return clone
                            })
                        })
                    }
                }}
                color="default"
            />
        </>
    );
};

export default EnableUser;