import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";
import { UserContext } from "../store/UserContext";
import { ShopContext } from "../store/ShopContext";
import { LayoutContext } from "../store/LayoutContext";
import { CompanyContext } from "../store/CompanyContext";
import createProductObject from "../helpers/createProductObject";
import { ProductsContext } from "../store/ProductsContext";
import { UPDATE_PRODUCTS } from "../store/ProductsReducers";
import searchString from "../helpers/searchString";

const useProductSearchObjectEzbase = () => {

    const { user } = useContext(UserContext);
    const { brands, filters } = useContext(ShopContext);
    const { dispatchProducts, products } = useContext(ProductsContext);
    const { app, layout } = useContext(LayoutContext);
    const { selectedCompany } = useContext(CompanyContext);
    const [parameters, setParameters] = useState();
    const [searchTerm, setSearcTerm] = useState();


    useEffect(() => {
        if (location.search) {
            let urlParams = new URLSearchParams(location.search);
            let search = urlParams.get("s")
            if (search) {
                setSearcTerm(search)
            }
        }
    }, [location.search]);

    const { data, isFetching } = useQuery(['products', parameters, selectedCompany],
        ({ signal }) => {

            //create search string
            let search_string = searchString(parameters.search)

            let params = {
                search: encodeURI(search_string),
                offset: (parameters.limit * (parameters.page - 1)),
                limit: parameters.limit,
                company_id: selectedCompany.company_id,
                products_only: true
            }
            //add filters
            if (parameters?.filters) {
                const removeHidden = parameters.filters.filter((items) => items.s !== false)
                //console.log(removeHidden)
                params = {
                    ...params,
                    filters: encodeURI(JSON.stringify(removeHidden)),
                }
            }
            if (parameters?.brands && parameters.brands.length > 0) {
                //add brand filters
                const brands = parameters.brands.map(brand => brand.Brand)
                if (brands && Object.values(brands).length) {
                    params = {
                        ...params,
                        brands: encodeURI(JSON.stringify(brands))
                    }
                }
            }
            return axiosRequest({
                signal: signal,
                method: 'get',
                endpoint: 'search',
                gateway: config.ezbaseAPI.URL,
                api_key: user.api_key,
                params: params
            })
        }, {
        //notifyOnChangeProps: currentCat,
        retry: 1,
        enabled: !!parameters && !!selectedCompany?.company_id
    })

    useEffect(() => {
        //sert parameters when search term changes
        if (searchTerm) {
            setParameters({
                search: searchTerm,
                limit: products.limit,
                page: layout.page,
                filters: filters.productFilters,
                brands: brands.selectedBrands
            })
        }
    }, [
        searchTerm,
        products.limit,
        layout.page,
        filters.productFilters,
        brands.selectedBrands
    ])

    useEffect(() => {
        const createProduct = async (products, count) => {
            const clone = await structuredClone(products);
            const promises = clone.map(async (product) => {

                let productObject = await createProductObject([
                    { ...product, area: app.selectedArea },
                ]);
                return productObject[0];
            });

            let all = await Promise.all(promises);

            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    count: count,
                    groupCount: null,
                    allProducts: all,
                    noProducts: count,
                },
            });
        };

        //console.log(data)

        if (data && !data.errorMessage) {
            //console.log(data)
            createProduct(data.results, data.fullProductCount);
        }
    }, [data]);

    return { isFetching };
};

export default useProductSearchObjectEzbase;
