import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import TabHeader from '../../../../layout/TabHeader';
import TabContent from '../../../../layout/TabContent';
import setLanguageText from '../../../../language/setLanguageText';
import { UserContext } from '../../../../store/UserContext';
import config from '../../../../config';
import axiosRequest from '../../../../axios/axoisRequest';
import useActivityLog from '../../../../users/activity/useActivityLog';
import DialogLoader from '../../../../reusable/DialogLoader';
import { CompanyContext } from '../../../../store/CompanyContext';
import { useQuery, useMutation } from '@tanstack/react-query';
import ProductVisibilityTable from './ProductVisibilityTable';


function IndeterminateCheckbox({ header, indeterminate, className = '', ...rest }) {
	const ref = useRef(null)
	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<Checkbox
			ref={ref}
			{...rest}
			sx={{
				color: header ? 'secondary.main' : 'default',
				' &.Mui-checked': {
					color: header ? 'secondary.main' : 'default'
				}
			}}
		/>
	)
}

export default function ProductVisibility() {

	const { selectedCompany } = useContext(CompanyContext);
	const { user } = useContext(UserContext);
	const [mergedData, setMergedData] = useState();
	const { updateActivityLog } = useActivityLog();
	const [rowSelection, setRowSelection] = useState()

	//get types
	const { data: types } = useQuery({
		queryKey: ['types'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants', //products
			api_key: user.api_key,
			method: 'get',
			params: {
				get_types: true
			}
		}),
		retry: false
	})

	//get products
	const { data, isFetching } = useQuery({
		queryKey: ['all products'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				get_full_list: true
			}
		}),
		retry: false
	})

	//get brands
	const { data: brands } = useQuery({
		queryKey: ['brands', user],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'get',
		}),
		retry: false
	})

	//get products visibility template
	const { data: visible, refetch } = useQuery({
		queryKey: ['visible products', selectedCompany.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				get_visible: true,
				company_id: selectedCompany.company_id
			}
		}),
		enabled: !!selectedCompany.company_id,
		retry: false
	})

	useEffect(() => {
		if (data && visible) {
			// console.log(data)	
			// console.log(visible)
			if (visible?.[0]?.visible_products.length > 0) {
				//loop through product and add checked property
				let clone = structuredClone(data)
				clone.forEach((product) => {
					//find matching product id in visible
					let match = visible[0].visible_products.includes(product.product_id)
					product.checked = match
				})
				//console.log(clone)
				setMergedData(clone)
			} else {
				setMergedData(data)
			}
		}
	}, [data, visible])



	const updateVisibility = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response, variables) => {
			if (!response.errorMessage) {
				updateActivityLog.mutate({
					activity_details: {
						area: "settings",
						details: variables,
					},
					activity_type: "company product visibility updated"
				})
				refetch()
			}
		}
	})

	//update visibility
	const handleSubmit = (mergedData, rowSelection) => {
		//get id from data based on rowSelection index
		let ids = []
		Object.keys(rowSelection).forEach((i) => {
			console.log(i)
			ids.push(mergedData[i].product_id)
		})
		//console.log(ids)
		updateVisibility.mutate({
			"update_template": true,
			"company_id": selectedCompany.company_id,
			"visible_products": ids
		})
	}

	// const handleChange = (row, e) => {
	// 	setMergedData(current => {
	// 		//find matching product id in visible
	// 		let clone = structuredClone(current)
	// 		let index = clone.findIndex((product) => product.product_id === row.original.product_id)
	// 		clone[index].checked = e.target.checked
	// 		//check if all are checked or unchecked
	// 		let allChecked = clone.every((product) => product.checked === true)
	// 		console.log(allChecked)
	// 		setSelectAll(allChecked)
	// 		return clone
	// 	})
	// }

	// const handleAllChange = useCallback((e) => {

	// 	setSelectAll(e.target.checked)
	// 	let clone = structuredClone(data)
	// 	clone.forEach((product) => {
	// 		product.checked = e.target.checked
	// 	})
	// 	//console.log(clone)
	// 	setMergedData(clone)

	// }, [data])


	const columns = useMemo(() => [
		{
			id: 'select',
			header: ({ table }) => (
				<IndeterminateCheckbox

					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
					header
				/>
			),
			cell: ({ row }) => (
				<div className="px-1">
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},

		// {
		// 	accessorKey: 'product_id',
		// 	header: () => {
		// 		return (
		// 			<FormControlLabel
		// 				label="Toggle all"
		// 				sx={{
		// 					marginLeft: '0'
		// 				}}
		// 				control={
		// 					<Checkbox
		// 						color='default'
		// 						checked={selectAll}
		// 						onChange={(e) => handleAllChange(e)}
		// 						inputProps={{ 'aria-label': 'controlled' }}
		// 					/>
		// 				}
		// 			/>
		// 		)
		// 	},
		// 	cell: ({ row }) => {
		// 		return (
		// 			<Checkbox
		// 				checked={row.original.checked}
		// 				onChange={(e) => handleChange(row, e)}
		// 				inputProps={{ 'aria-label': 'controlled' }}
		// 			/>
		// 		)
		// 	},
		// 	size: 10,
		// 	enableSorting: false,
		// 	enableColumnFilter: false,
		// 	//minSize: 400,
		// 	//maxSize: 400
		// }, 
		{
			accessorKey: 'product_name',
			name: "Products",
			header: () => 'Name',
			cell: info => info.getValue(),
			size: 200,
		}, {
			accessorKey: 'product_type',
			header: () => 'Type',
			cell: ({ row }) => {
				let type = types?.find((type) => type.type_id === row.original.product_type)
				if (type) {
					return type?.type_name
				}
				return null
			},
			size: 200,
			enableColumnFilter: false
		}, {
			accessorKey: 'brand_id',
			header: () => 'Brand',
			cell: ({ row }) => {
				let brand = brands?.find((brand) => brand.brand_id === row.original.brand_id)
				if (brand) {
					return brand?.brand_name
				}
				return null
			},
			size: 200,
			enableColumnFilter: false
		}, {
			accessorKey: 'product_defaults.description',
			header: () => 'Description',
			cell: info => info.getValue() || "",
			size: 200,
			enableSorting: false,
			enableColumnFilter: false
		}
	], [mergedData, types, brands])


	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Product Visibility")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid container spacing={2}>
					<Grid container item xs={12} spacing={2} justifyContent="space-between" >
						{/* <Grid item xs={6}>
							<FormControlLabel
								label="Toggle all visibility"
								control={
									<Checkbox
										color='default'
										checked={selectAll}
										onChange={(e) => handleAllChange(e)}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
							/>
						</Grid> */}
						<Grid item xs={6} >
							<Button
								disabled={updateVisibility.isLoading}
								variant='contained'
								color='primary'
								onClick={() => handleSubmit(mergedData, rowSelection)}
								size='large'
							>
								{setLanguageText("Update visibility")}
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12} >
						<ProductVisibilityTable
							columns={columns}
							data={mergedData}
							isFetching={isFetching}
							rowSelection={rowSelection}
							setRowSelection={setRowSelection}
						/>
					</Grid>
					<Grid item xs={12} >
						<Button
							disabled={updateVisibility.isLoading}
							variant='contained'
							color='primary'
							onClick={() => handleSubmit(mergedData, rowSelection)}
							size='large'
						>
							{setLanguageText("Update visibility")}
						</Button>
					</Grid>
				</Grid>
			</TabContent>
			{updateVisibility.isLoading || updateVisibility.isSuccess || updateVisibility.isError ?
				<DialogLoader
					isLoading={updateVisibility.isLoading}
					mutation={updateVisibility}
					loadingMessage="Saving Visibility"
					successMessage="Saved"
					fixed
				/>
				: null
			}
		</>
	);
};


