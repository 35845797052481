import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CustomCard from '../../reusable/CustomCard';
import UserCompanyForm from './UserCompanyForm';
import UserDetailsForm from './UserDetailsForm';
import { LayoutContext } from '../../store/LayoutContext';
import config from '../../config';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../axios/axoisRequest';
import { useQuery } from '@tanstack/react-query';
import UserDynamicForm from './UserDynamicForm';

const AllForms = ({ template, fullWidth, testing, edit, valid, setValid }) => {

	const [roles, setRoles] = useState([])
	const { user } = useContext(UserContext)

	const { data } = useQuery({
		queryKey: ['roles'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.factoryAPI.URL,
			endpoint: 'roles',
			api_key: user.api_key,
			method: 'get',
			params: {
				all_roles: true
			}
		}),
		retry: false
	})

	useEffect(() => {
		if (data) {
			//group by area
			const grouped = (data) => {
				return data.reduce((r, a) => {
					r[a.role_area] = [...r[a.role_area] || [], a];
					return r;
				}, {});
			}
			let groupedData = grouped(data);
			//console.log(groupedData)
			setRoles(groupedData)
		}
	}, [data])

	const { app } = useContext(LayoutContext);

	return (
		<Grid item container spacing={1} xs={12} alignItems="stretch">

			<UserDetailsForm testing={testing} edit={edit} template={template} setValid={setValid} valid={valid} />
			<Grid item xs={12}>
				<CustomCard
					title="User Access to Companies"
					icon={
						//find home icon
						app.menu && app.menu.find(item => item.area === 'home').icon
					}
				>
					<UserCompanyForm template={template} roles={roles?.home} />
				</CustomCard>
			</Grid>
			{app.menu && app.menu.map((item, i) => {
				//skip home and settings
				if (!item.area || item.area === 'home' || item.area === 'settings') return null
				//find macthing roles
				let matchingRoles = roles?.[item.area]
				return (
					<Grid item xs={12} md={fullWidth ? 12 : 6} key={i}>
						<CustomCard title={item.buttonText} icon={item.icon} >
							<UserDynamicForm roles={matchingRoles} name={item.area} />
						</CustomCard>
					</Grid>

				)
			})}
		</Grid>
	);
};

export default AllForms;
