import React, {
    useState,
    useEffect,
    useContext
} from "react";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import CategoryCards from "./CategoryCards";
import { CategoryContext } from "../../store/CategoryContext";
import { BrandsContext } from "../../store/BrandsContext";
import { Grid } from "@mui/material";

const SubCategories = ({ customHistory }) => {

    //console.log(customHistory)

    //Gloabal states
    const { categories, dispatchCategories } = useContext(CategoryContext);
    const { brands } = useContext(BrandsContext);
    const [localCurrent, setLocalCurrentCat] = useState();

    //--------------------------- HISTORY  ------------------------------//

    useEffect(() => {
        //listen for custon history router event
        const unlisten = customHistory.listen(({ location, event, action }) => {
            if (action === "POP") {
                //console.log('load last currentCat')
                if (location.state && location.state.lastCurrentCat) {
                    setLocalCurrentCat(location.state.lastCurrentCat)
                }
            } 
        });
        return () => {
            unlisten();
        };
    }, [customHistory, setLocalCurrentCat, categories.currentCat]);

    useEffect(() => {
        //listen for custon history router event
        setLocalCurrentCat(categories.currentCat)
        return () => {
        };
    }, [categories.currentCat]);


    //set subcategories on currentCat update
    useEffect(() => {
        //console.log(categories)
        if (localCurrent && localCurrent.Items) {
            //let subCats = categories.allCats.filter((cat) => cat.ParentID === currentCat.CatID)
            let subCats = localCurrent.Items
            dispatchCategories({
                type: UPDATE_CATEGORIES,
                payload: {
                    subCats: subCats
                }
            });
        }
    }, [localCurrent, dispatchCategories]);

    return (
        <>
            {categories.subCats ? (
                <Grid>
                    <CategoryCards
                        categories={categories.subCats}
                        selectedBrands={brands.brandsSelected}
                    />
                </Grid>
            ) : null }
        </>
    );
};

export default SubCategories;
