import {
    Autocomplete,
    Grid,
    TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import InputWrap from '../reusable/InputWrap';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WysiwygEditor from '../reusable/WysiwygEditor';
import { Box } from '@mui/system';
import CustomCard from '../reusable/CustomCard';

const DesignForm = () => {

    const { values, setFieldValue, handleChange, touched, errors } = useFormikContext();

    const [localMaterial, setLocalMaterial] = useState(null);
    const materialOptions = [
        { name: "Carbide", id: 1 },
        { name: "HSS", id: 2 },
        { name: "HSCo", id: 3 },
        { name: "Powder", id: 4 },
        { name: "Metallurgy", id: 5 },
        { name: "Diamond", id: 6 }
    ]
    const [localFinish, setLocalFinish] = useState(null);
    const finishOptions = [
        { name: "Coated", id: 1 },
        { name: "Uncoated", id: 2 }
    ]

    return (

        <CustomCard title="Design More Details">

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <InputWrap name="Quantity *">
                        <TextField
                            id="quantity"
                            name="quantity"
                            type="number"
                            value={values.quantity}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.quantity && Boolean(errors.quantity)}
                            helperText={touched.quantity && errors.quantity}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputWrap name="Workpiece Material *">
                        <TextField
                            id="workpiece"
                            name="workpiece"
                            value={values.workpiece}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.workpiece && Boolean(errors.workpiece)}
                            helperText={touched.workpiece && errors.workpiece}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputWrap name="Tool Material *">
                        <Autocomplete
                            //multiple
                            options={materialOptions}
                            getOptionLabel={option => option.name ? option.name : null}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, value) => {
                               //console.log(value)
                                setLocalMaterial(value)
                                setFieldValue('material', value);
                            }}
                            value={localMaterial}
                            filterSelectedOptions
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={params => {
                                return (
                                    <TextField
                                        //disabled={!localCompanies}
                                        {...params}
                                        variant="outlined"
                                    //label={setLanguageText("Select Companies")}
                                    //label="Tool Material"
                                    />
                                );
                            }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputWrap name="Finish *">
                        <Autocomplete
                            // multiple
                            options={finishOptions}
                            getOptionLabel={option => option.name ? option.name : null}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, value) => {
                               //console.log(value)
                                setLocalFinish(value)
                                setFieldValue('finish', value);
                            }}
                            value={localFinish}
                            filterSelectedOptions
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={params => {
                                return (
                                    <TextField
                                        //disabled={!localFinish}
                                        {...params}
                                        variant="outlined"
                                    //label={setLanguageText("Select Companies")}
                                    //label="Finish"
                                    />
                                );
                            }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Description">
                        <WysiwygEditor
                            initialValue={values.details}
                            fieldName="details"
                        />
                    </InputWrap>
                </Grid>
            </Grid>

        </CustomCard>
    );
}


export default DesignForm;
