import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_APPLICATION_FILTERS } from "../../../store/SelectedReducers";
import showFieldLogic from "../../libs/showFieldLogic";

const Dropdown = ({ field, focus, focusField, updateValue }) => {

    const [hide, setHide] = useState();
    const [localValue, setLocalValue] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const focusRef = useRef();

    useEffect(() => {
        if (focus === field.Abbreviation) {
            focusRef.current.focus()
        }
    }, [focus, field]);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValue(null)
        }
    }, [selected.resetFilters]);

    //use QueryString on first load to set fields that are availble
    useEffect(() => {
        if (window.location.search && firstLoad) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let appSearch = JSON.parse(urlParams.get("app"));
            //console.log(appSearch)
            if (appSearch && Object.values(appSearch).length) {
                let found = appSearch[0].filter(element => element.includes(`${field.Abbreviation}§`));
                //console.log(found) 
                //console.log(field)
                if (Object.values(found).length > 0) {
                    let valueCopy = field.DiscreteValues ? [...field.DiscreteValues] : []
                    let fieldValue = found[0].split('§')[1];
                    //console.log(fieldValue)
                    let foundItems = valueCopy.find((value) => value.ID === fieldValue);
                    if (foundItems) {
                        //console.log(foundItems)
                        setLocalValue(foundItems);
                        //updateSelected(foundItems);
                    }
                }
            }
        }
    }, [firstLoad]);

    //hide fields based on the logic
    useEffect(() => {
        //console.log(field.Condition)
        if (field.Condition && selected.applicationFilters) {
            let showField = showFieldLogic(field.Condition, selected.applicationFilters)
            setHide(!showField)
        }
        if (!field.Condition) {
            setHide(false)
        }

    }, [field, selected.applicationFilters])


    //set value if field condition is visible
    useEffect(() => {
        if (localValue && !hide) {
            //console.log(localValue) 
            //console.log(hide)
            updateSelected(localValue)
            setFirstLoad(false);
        }
    }, [localValue, hide])

    //if field is hidden clear the value onot of first load as that clears all the other fields
    useEffect(() => {
        if (!firstLoad && hide) {
            //reset field if hidden
            setLocalValue(null)
            updateValue({ 'fieldAbb': field.Abbreviation })
            dispatchSelected({
                type: UPDATE_APPLICATION_FILTERS,
                payload: { 'Field': field.Abbreviation, 'Values': [] }
            });
        }
    }, [firstLoad, hide])

    //update select
    const updateSelected = useCallback((value) => {
        //console.log(value)
        let payload
        //if value is not null or undefined remove from selected
        if (value !== null && value !== undefined && !hide) {
            //console.log(value)
            //console.log(field)
            setLocalValue(value);
            payload = { 'Field': field.Abbreviation, 'Values': [value.ID] }
            updateValue({ 'fieldAbb': field.Abbreviation, 'value': `${value.ID}` })
        } else {
            setLocalValue(null);
            updateValue({ 'fieldAbb': field.Abbreviation })
            payload = { 'Field': field.Abbreviation, 'Values': [] }
        }
        dispatchSelected({
            type: UPDATE_APPLICATION_FILTERS,
            payload: payload
        });
    }, [field, dispatchSelected, hide])



    return (
        <>
            {!hide ?
                <Grid
                    item
                    xs={12}
                    sx={{
                        //visibility: hide ? "hidden" : "visible",
                        //display: hide ? "none" : "grid"
                    }}
                >
                    <InputLabel htmlFor={field.Label} sx={{ position: 'relative', transform: 'inherit', margin: '0 0 5px 0' }}>
                        <Typography sx={{ color: 'text.primary' }} ><span style={{ display: 'inline-block', color: '#ccc' }}>{field.Abbreviation} - </span> {field.Label}</Typography>
                    </InputLabel>

                    <Autocomplete

                        id={field.ID}
                        sx={{ minWidth: 300 }}
                        options={field.DiscreteValues || []}
                        value={localValue}
                        onChange={(event, newValue) => {
                            updateSelected(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     //console.log(newInputValue)
                        //     setInputValue(newInputValue);
                        // }}
                        isOptionEqualToValue={(option, value) => option.ID === value.ID}
                        onClick={e => focusField(field.Abbreviation)}
                        autoHighlight
                        getOptionLabel={(option) => option.Name}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
                                {option.Icon &&
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={option.Icon}
                                        srcSet={option.Icon}
                                        alt=""
                                    />
                                }
                                {option.Name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                //ref={focusRef}
                                autoFocus
                                inputRef={input => {
                                    focusRef.current = input;
                                }}
                                {...params}
                                label={''}
                                sx={{ '& legend': { display: 'none' } }}
                                InputLabelProps={{ shrink: false }}
                                placeholder={`Please Select ${field.Label}`}
                                size="small"
                            />
                        )}
                    />

                </Grid>
                : null}
        </>
    );
};

export default Dropdown;
