import { useContext, useEffect, useState } from "react";
import config from "../config";
import { useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from "../store/UserContext";
import { AUTH_USER } from "../store/UserReducers";
import { CompanyContext } from "../store/CompanyContext";

const useCustomerDetails = () => {

    const { user, dispatchUser } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    //const storedToken = sessionStorage.getItem('token');
    const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');

    const [getOnce, setGetOnce] = useState(false);

    // Use a mutation to fetch erp customer details
    const getCustomer = useMutation((account) => {
        return axiosRequest({
            gateway: config.erpAPI.URL,
            endpoint: 'user',
            api_key: user.api_key,
            method: 'get',
            params: {
                "account": account,
                "token":  config.APPNAME === "act"  ? ACTstoredToken : CISstoredToken || null
            }
        });
    },
        {
            onSuccess: (data) => {
                if (data) {
                    dispatchUser({
                        type: AUTH_USER,
                        payload: {
                            customerDetails: {
                                ...data,
                                customerAddressOnly : 1
                            }
                        }
                    });
                }
            },
            onError: (error) => {
                console.log(error)
            }
        }
    );

    useEffect(() => {

        //don't run this hook in production   
        //console.log(process.env.NODE_ENV)
        if (process.env.REACT_APP_CONFIG_PATH !== "act") return;

        //only run if user is logged in and has a token
        //getOnce is used to stop the hook from running on every render
        if (selectedCompany?.erp_details && ACTstoredToken && !getOnce) {
            getCustomer.mutate(selectedCompany.erp_details.AccountNumber)
            setGetOnce(true)
        }

        //unmount
        return () => {  
            setGetOnce(false)
        }
    }, [user, CISstoredToken , ACTstoredToken])


};

export default useCustomerDetails;

