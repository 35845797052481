import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCogs, faUserGear, faUsers } from '@fortawesome/pro-light-svg-icons';
import { Avatar, Box, Divider, IconButton, ListItemText, Tooltip } from '@mui/material';
import SettingsMenu from './SettingsMenu';
import { UPDATE_MENU } from '../store/LayoutReducers';
import { LayoutContext } from '../store/LayoutContext';
import { UserContext } from '../store/UserContext';
import setLanguageText from '../language/setLanguageText';


export default function AdminMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { dispatchApp } = React.useContext(LayoutContext)
    const { user } = React.useContext(UserContext)

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Tooltip title={setLanguageText("Settings Menu")}>
                <IconButton
                    color="inherit"
                    aria-label="Settings Menu"
                    //edge="start"
                    onClick={handleClick}
                >
                    <FontAwesomeIcon icon={faCog} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                //onClick={handleClose}
                elevation={0}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box
                    sx={{
                        mb: 1.5,
                        mx: 2.5,
                    }}
                >
                    <ListItemText><strong>Settings Menu</strong></ListItemText>
                </Box>
                <Divider sx={{ marginBottom : '10px' }} />
                {user?.user?.user_permissions?.administrator &&
                    <MenuItem onClick={e => {
                        navigate('/settings/users')
                        //console.log(area,menu)
                        dispatchApp({
                            type: UPDATE_MENU,
                            payload: {
                                selectedArea: "settings",
                                selectedMenu: "users"
                            }
                        })
                        handleClose()
                    }}
                        disableRipple
                    >
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                            <FontAwesomeIcon size="xs" icon={faUserGear} />
                        </Avatar>
                        {setLanguageText("User Management")}
                    </MenuItem>
                }
                <SettingsMenu handleClose={handleClose} />
            </Menu>
        </>
    );
}
