import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { DialogTitle, MenuItem, ListItemIcon } from '@mui/material';
import React, { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import { UserContext } from '../../store/UserContext';
import useActivityLog from '../../users/activity/useActivityLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from "@tanstack/react-query";
import DialogLoader from '../../reusable/DialogLoader';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';

export default function DeleteProduct({ product }) {

    const { updateActivityLog } = useActivityLog();

    const { user } = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);

    const openDialog = () => {
        setOpenDelete(true)
    };

    const closeDialog = () => {
        setOpenDelete(false);
    };

    const deleteProduct = useMutation({
        mutationFn: (id) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'coolants',
			api_key: user.api_key,
			method: 'patch',
			body: {
                "delete_product": true,
                "product_id": id
            }
        }),
        onSuccess: () => {
            //update activity log
            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    product: product
                },
                activity_type: "product deleted"
            })
        }
    })

    const confirmDeleteProduct = () => {
        deleteProduct.mutate(product.product_id)
    };

    return (
        <>
            <MenuItem
                onClick={openDialog}
            >
                <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                Delete Product
            </MenuItem>

            <GlobalDialog
                open={openDelete}
                onClose={closeDialog}
                title="Remove Product"
                buttonClick={confirmDeleteProduct}
                buttonTitle="Confirm"
                successMessage="Product deleted successfully!"
            >
                <DialogTitle>
                    <Alert variant="standard" color="error">
                        {setLanguageText("Are you sure you want to delete this product,this action will be permanent?")}
                    </Alert>
                </DialogTitle>
                {deleteProduct.isLoading || deleteProduct.isSuccess || deleteProduct.isError ?
                    <DialogLoader
                        isLoading={deleteProduct.isLoading}
                        mutation={deleteProduct}
                        loadingMessage="Deleting Product..."
                        successMessage="Deleted Product"
                        closeDialog={() => {
                            //navigate('/coolantcare/machines');
                            closeDialog()   
                        }}
                        fixed

                    />
                    : null}
            </GlobalDialog>
        </>
    );
}

