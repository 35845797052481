import React, { useContext, useEffect, useState } from "react";
import SubCategories from "../categories/SubCategories";
import { Grid, useMediaQuery } from "@mui/material";
import { LayoutContext } from "../../store/LayoutContext";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { isMobileOnly } from "react-device-detect";
import { ProductsContext } from "../../store/ProductsContext";
import Filters from "../filters/Filters";
import ResultToggleFilters from "../filters/ResultToggleFilters";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";
import ProductOptions from "../../products/shared/ProductOptions";
import ProductLayout from "../products/ProductLayout";
import useProductAttrbuites from "../../hooks/useProductAttributes";
import useProductObject from "../../hooks/useProductObject";
import SearchHeader from "../../search/SearchHeader";


const CatalogueLayout = (props) => {

    //states
    const { layout } = useContext(LayoutContext);
    const [showProducts, setShowProducts] = useState(layout.showProducts)
    const [showCategories, setShowCategories] = useState(layout.showCategories)
    const { products } = useContext(ProductsContext);
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [focused, setFocused] = useState(false);

    //set product attributes
    const { attributes, setAttributes } = useProductAttrbuites();

    //fetch products and create new v2 object
    const { isFetching } = useProductObject();
    
    //--------------------------- HISTORY  ------------------------------//

    useEffect(() => {
        //console.log('CatalogueLayout useEffect')  
        //console.log(props.customHistory)
        //listen for custon history router event
        const unlisten = props.customHistory.listen(({ location, event, action }) => {
            console.log(action)
            if (action === "POP") {
                console.log('load last currentCat')
                //console.log(location.state.lastShowProducts)
                //console.log(location.state.lastShowCategories)
                if (location.state) {
                    setShowProducts(location.state.lastShowProducts)
                    setShowCategories(location.state.lastShowCategories)
                }
            }
        });
        return () => {
            unlisten();
        };
    }, [props.customHistory, setShowProducts, setShowCategories]);


    useEffect(() => {
        if (layout) {
            setShowProducts(layout.showProducts)
            setShowCategories(layout.showCategories)
        }
    }, [layout])


    return (
        <>
            <SearchHeader
                link={true}
                large={true}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
                props={props}
            />
            <InnerContentWrap>
                {showCategories ?
                    <Grid item xs={12} >
                        <SubCategories {...props} />
                    </Grid>
                    : null}
                {showProducts ?
                    <Grid item xs={12} >
                        <Grid container item spacing={2}  >
                            <Grid container spacing={isMobileOnly ? 1 : 2} item xs={12} md={4} lg="auto"
                                sx={{
                                    width: lgUp ? '400px!important' : 'auto',
                                    maxWidth: lgUp ? '400px!important' : '100%'
                                }}>
                                <Filters />
                            </Grid>
                            <Grid item container spacing={isMobileOnly ? 1 : 2} xs={12} md={8} lg alignContent="flex-start">
                                <ResultToggleFilters />
                                {products.noProducts ? (
                                    <Grid item xs={12}>
                                        <ProductsNotAvailable />
                                    </Grid>
                                ) :
                                    <>
                                        <ProductOptions isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                        <ProductLayout isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                        <ProductOptions isFetching={isFetching} bottom />
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    : null}
            </InnerContentWrap>
        </>
    );
};

export default CatalogueLayout;
