import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Menu,
  IconButton,
  MenuItem,
  Grid,
  Alert
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faTrash,
  faUpload,
} from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../language/setLanguageText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditKPI from './EditKPI';
import KPIUpload from './KPIUpload';
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import DialogLoader from '../reusable/DialogLoader';

const KPIMenu = ({ kpi, refetchKPIs, groupOptions , text}) => {

  const { user } = useContext(UserContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editKPI, setEditKPI] = useState(false);
  const [upload, setUpload] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);


  //gets kpi 
  const mutation = useMutation((kpi) => {
    //console.log(user)
    return axiosRequest({
      method: 'get',
      endpoint: 'kpi',
      gateway: config.factoryAPI.URL,
      api_key: user.api_key,
      params: {
        delete: true,
        kpi_id: kpi.kpi_id
      }
    })
  }, {
    onSuccess: (data) => {
      //console.log(data)
      if (!data.errorMessage) {
        refetchKPIs()
      }
    }
  })

  const handleDeleteKpi = (kpi) => {
    mutation.mutate(kpi)
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openMenu ? 'long-menu' : undefined}
        aria-expanded={openMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: theme => text ? theme.palette.primary.text :  theme.palette.primary.contrastText }} />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            setEditKPI(true)
          }}
        >
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} />
          {setLanguageText("Edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            setUpload(true)
          }}
        >
          <FontAwesomeIcon icon={faUpload} style={{ marginRight: '10px' }} />
          {setLanguageText("Upload")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            setConfirmDelete(true)
          }}
        >
          <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />
          {setLanguageText("Delete")}
        </MenuItem>
      </Menu>
      {editKPI ?
        <EditKPI kpi={kpi} setEditKPI={setEditKPI} editKPI={editKPI} refetchKPIs={refetchKPIs} groupOptions={groupOptions} />
        : null}

      {upload ?
        <KPIUpload kpi={kpi} setUpload={setUpload} upload={upload} refetchKPIs={refetchKPIs} />
        : null}

      {confirmDelete &&
        <GlobalDialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          title="Delete KPI and all data"
          buttonClick={() => handleDeleteKpi(kpi)}
          buttonTitle="Delete KPI"
          //disabled={mutation.isLoading}
          //loading={mutation.isLoading}
          maxWidth="sm"
        >
          <Grid item>
            <Alert severity="error">
              Are you sure you want to delete this KPI?
              This action will remove all data associated with this resource, and can not be undone.
            </Alert>
          </Grid>
        </GlobalDialog>
      }
      {mutation.isLoading || mutation.isSuccess || mutation.isError ?
        <DialogLoader
          isLoading={mutation.isLoading}
          mutation={mutation}
          loadingMessage="Removing KPI and data..."
          successMessage="Removed KPI"
          closeDialog={() => {
            setAnchorEl(null);
            refetchKPIs()
          }}
          fixed
        />
        : null}

    </>
  );
};

export default KPIMenu;
