import {
	Grid,
	TextField,
	Autocomplete
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Text from './fields/Text';
import TextArea from './fields/TextArea';
import Float from './fields/Float';
import { useFormikContext } from 'formik';
import setLanguageText from '../../../language/setLanguageText';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from '../../../store/UserContext';
import List from './fields/List';
import File from './fields/File';
import Select from './fields/Select';

export default function ProductForm({ form }) {

	const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
	const { user } = useContext(UserContext);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [options, setOptions] = useState(null);

	//get brands
	const { data } = useQuery({
		queryKey: ['brands'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'get'
		}),
		enabled: !!user.api_key,
		retry: false
	})

	useEffect(() => {
		if (data) {
			setOptions(data);
		}
	}, [data])

	return (

		<>
			<Grid item xs={12} sm={6}>
				<TextField
					id="product_name"
					name="product_name"
					value={values?.product_name}
					onChange={handleChange}
					fullWidth
					variant="outlined"
					error={touched.product_name && Boolean(errors.product_name)}
					helperText={touched.product_name && errors.product_name}
					placeholder={setLanguageText("Product Name")}
					label={setLanguageText("Product Name")}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Autocomplete
					disableClearable
					id="product_brand"
					value={selectedBrand || null}
					options={options || []}
					getOptionLabel={option => option.brand_name}
					onChange={(e, newValue) => {
						setSelectedBrand(newValue);
						setFieldValue('product_brand', newValue);
					}}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label={setLanguageText("Product Brand")}
							required
							error={touched.product_brand && Boolean(errors.product_brand)}
							helperText={touched.product_brand && errors.product_brand}
						/>
					)}

				/>
			</Grid>
			<Grid item xs={12} >
				<TextField
					id="product_description"
					name="product_description"
					value={values?.product_description}
					onChange={handleChange}
					rows={3}
					multiline
					fullWidth
					variant="outlined"
					error={touched.product_description && Boolean(errors.product_description)}
					helperText={touched.product_description && errors.product_description}
					placeholder={setLanguageText("Product Description")}
					label={setLanguageText("Product Description")}
				/>
			</Grid>
			{form && form.map((field, i) => {
				if (field.type === "text") {
					return (
						<Grid item xs={12} sm={6} key={i}>
							<Text field={field} />
						</Grid>
					)
				}
				if (field.type === "float") {
					return (
						<Grid item xs={12} sm={6} key={i}>
							<Float field={field} />
						</Grid>
					)
				}
				if (field.type === "textarea") {
					return (
						<Grid item xs={12} key={i}>
							<TextArea field={field} />
						</Grid>
					)
				}
				if (field.type === "list") {
					return (
						<Grid item xs={12} key={i}>
							<List field={field} />
						</Grid>
					)
				}
				if (field.type === "file") {
					return (
						<Grid item xs={12} key={i}>
							<File field={field} />
						</Grid>
					)
				}
				if (field.type === "select") {
					return (
						<Grid item xs={12} sm={6} key={i}>
							<Select field={field} />
						</Grid>
					)
				}
			})}
		</>
	);
};

