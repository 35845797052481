import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useQuery } from '@tanstack/react-query';

const DropdownCoolantsManufacturers = ({ setManufacturer }) => {

	const { user } = useContext(UserContext);
	const [manufacturers, setManufacturers] = useState([]);
	const [value, setValue] = useState(null);

	const { data } = useQuery({
		queryKey: ['all brands', manufacturers],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'get',

		}),
		enabled: !!manufacturers,
		retry: false
	})

	useEffect(() => {
		setManufacturer(value);
	}, [setManufacturer, value]);

	const onSelectManufacturer = (e, manufacturer) => {
		setValue(manufacturer);
	};

	return (
		<Autocomplete
			disableClearable
			id="manufacturers"
			options={data || []}
			loading={!data}
			getOptionLabel={option => option.brand_name || ''}
			renderInput={params => (
				<TextField
					required
					{...params}
					variant="outlined"
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Manufacturer")}
				/>
			)}
			value={value}
			onChange={onSelectManufacturer}
		/>
	);
};

export default DropdownCoolantsManufacturers;
