import React, { useState } from 'react';
import {
    MenuItem,
    Avatar
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencil
} from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../language/setLanguageText';
import EditUserPermissions from './EditUserPermissions';
import EditCatalogueBrands from './EditCatalogueBrands';
import EditEngineerBrands from './EditEngineerBrands';
import EditOpenSearchBrands from './EditOpenSearchBrands';

const EditTemplate = ({ template, refetch, handleClose }) => {

    const [open, setOpen] = useState(false);

    const handleAllClose = () => {
        handleClose()
        refetch()
        setOpen(false)
    }

    return (
        <>
            <MenuItem
                onClick={() => {
                    setOpen(true)
                }}
            >
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <FontAwesomeIcon size="xs" icon={faPencil} />
                </Avatar>
                {setLanguageText("Edit Template")}
            </MenuItem>

            {template.type === "engineer_brands" ?
                <EditEngineerBrands template={template} open={open} setOpen={setOpen} handleClose={handleAllClose} />
                : template.type === "user_permissions" ?
                    <EditUserPermissions template={template} open={open} setOpen={setOpen} handleClose={handleAllClose} />
                    : template.type === "catalogue_brands" ?
                        <EditCatalogueBrands template={template} open={open} setOpen={setOpen} handleClose={handleAllClose} />
                        : template.type === "opensearch_brands" ?
                            <EditOpenSearchBrands template={template} open={open} setOpen={setOpen} handleClose={handleAllClose} />
                            : null}
        </>
    );
};

export default EditTemplate;
