import React, { useState } from "react"
import { Storage } from 'aws-amplify';
import { Button } from "@mui/material";
import config from "../../../config";

const DownloadItem = ({
    file
}) => {

    const [errorMessage, setErrorMessage] = useState();

    Storage.configure({
        AWSS3: {
          bucket: config.s3.BUCKET,
          region: config.s3.REGION
        }
      });

    const dowloadFile = async (filename) => {

        //console.log(`downloads/${filename}`)
        // get the signed URL string
        await Storage.get(`downloads/${filename}`, {
            download: true,
            cacheControl: 'no-cache'
        }).then(async (resp) => {
            //check if the file exsits
            //console.log(resp)
            const signedURL = await Storage.get(`downloads/${filename}`, {
                download: false,
                cacheControl: 'no-cache'
            })
            window.location.href = signedURL
            //window.open(signedURL, 'download_window')

        }).catch((err) => {
            console.log(err)
            if (err.Code === "AccessDenied") {
                setErrorMessage("This file has been marked as infected, you will not be able to download")
            } else {
                setErrorMessage(err.Code)
            }
            // handle errors 
        })
        // window.open(signedURL, 'download_window')
    }


    return (
        <>
            <Button
                onClick={() => dowloadFile(file)}
                variant="contained"
                color="primary" 
            >
                Download file
            </Button>
        </>
    )
}

export default DownloadItem
