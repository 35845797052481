import React from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import { withStyles } from "@mui/styles"
import formatDate from '../../../helpers/formatDate';
import { faEdit, faVial, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateChip from '../../../reusable/CreateChip';
import { buttonStyles } from '../../../styles/buttonStyles';
import Tooltip from '@mui/material/Tooltip';
import EditEntry from '../../../Entry/EditEntry';
import { Box } from '@mui/system';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../../../reusable/ErrorFallback';
import logErrors from '../../../../helpers/logErrors';
import setLanguageText from '../../../../language/setLanguageText';
import getUserLevel from '../../../../helpers/getUserLevel';

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#fff',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		padding: theme.spacing(1)
	},
}))(Tooltip);


const entriesTable_columns = ({ selectedCompany, selectedMachine, language, user }) => {

	let columns = [
		{
			id: 'out_of_control',
			name: 'Out Of Control',
			Header: () => <Typography componenet="p" variant="body1">OOC</Typography>,
			accessor: 'out_of_control',
			disableSortBy: true,
			Cell: ({ row, value }) => (parseInt(value) === 1 ? 'true' : 'false')
		},
		{
			id: 'date_stamp',
			name: 'Entry Date',
			Header: () => <Typography componenet="p" variant="body1">{setLanguageText("Entry Date")}</Typography>,
			accessor: 'date_stamp',
			Cell: ({ value }) => {
				return (
					<Grid container justifyContent="center">
						<Typography variant="subtitle1" align="center">
							{formatDate(value)}
						</Typography>
					</Grid>
				);
			}
		},
		{
			id: 'edit',
			Cell: ({ row }) => {
				let void_reason = row.original.void ? row.original.void : null
				return (
					<React.Fragment>
						{row.original.void ? (
							<Grid item container justifyContent="center">
								<HtmlTooltip
									title={
										<>
											<Typography componenet="p" variant="body1">{setLanguageText("Void Info")}</Typography>
											<Grid container>
												{void_reason &&
													<Typography variant="caption">
														{setLanguageText("Reason")}: {void_reason.reason} <br />
														{setLanguageText("Date")}: {formatDate(void_reason.date)} <br />
														{setLanguageText("User")}: {void_reason.user_name}
													</Typography>
												}
											</Grid>
										</>}
								>
									<Grid
										container
										alignItems="center"
										justifyContent="center"
										className={buttonStyles().icon_md}
										style={{ cursor: 'pointer' }}
									>
										<FontAwesomeIcon icon={faQuestionCircle} />
									</Grid>

								</HtmlTooltip>
							</Grid>
						) : (
							<Grid container justifyContent="center" alignItems="center" spacing={2}>
								<Grid item
									sx={{
										'&:hover': {
											cursor: 'pointer'
										}
									}}
								>
									{getUserLevel(user.user, 'coolantcare', 'entry_edit', 6) ?
										<FontAwesomeIcon
											icon={faEdit}
											className={buttonStyles().icon_md}
											onClick={(e) => {
												row.setState(prevState => ({
													...prevState,
													isOpen: true,
													entry: row.original
												}));
											}}
										/>
									: null }
								</Grid>
							</Grid>
						)}
						<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onError={(error, info) => {
								return logErrors({
									'component': 'Edit Entry',
									'user': user,
									'full': info.componentStack.toString(),
									'message': error.message.toString()
								})
							}}
							onReset={() => {
								row.setState(prevState => ({
									...prevState,
									isOpen: !prevState.isOpen,
									entry: row.original
								}));
							}}
						>
							<>
								{row.state.isOpen && (
									<EditEntry
										isDialog={row.state.isOpen}
										setIsDialog={e => {
											row.setState(prevState => ({
												...prevState,
												isOpen: !prevState.isOpen,
												entry: row.original
											}));
										}}
										originalEntry={row}
										selectedCompany={selectedCompany}
										selectedMachine={selectedMachine}
									/>
								)}
							</>
						</ErrorBoundary>
					</React.Fragment>
				);
			}
		},
		{
			id: 'factored',
			name: 'Factored',
			Header: () => <Typography componenet="p" variant="body1">{setLanguageText("Factor")}</Typography>,
			accessor: 'factor',
			disableSortBy: true,
			Cell: ({ value }) => {
				return (
					<Typography style={{ textAlign: 'center' }} variant="body1">{value}</Typography>
				);
			}

		},
	];

	/*------------- LOOP THROUGH COMPANY ATTRIBUTES TO CREATE OTHER COLUMNS ---------------*/
	if (Object.entries(selectedCompany).length) {

		selectedCompany?.company_attributes.forEach(item => {
			columns = [
				...columns,
				{
					id: item.one.attr_id,
					name: item.one.attr_name,
					Header: <Typography componenet="p" variant="body1">{item.one.attr_name}</Typography>,
					accessor: props => {
						if (props.attributes_new) {
							let found = props.attributes_new.find(
								attr => attr.attribute_id === item.one.attr_id
							);
							return found && found.attribute_values.value;
						} else {
							return null;
						}
					},
					Cell: ({ row }) => {

						return (
							<Grid container justifyContent="center">
								<CreateChip
									factorApplied={selectedMachine?.machine_settings?.factored}
									entryFactor={row.original.factor}
									company_attr_id={item.one.attr_id}
									entry_attributes={row.original.attributes_new}
								/>
							</Grid>
						);
					}
				}
			];
		});
	}
	//add additives and notes
	columns = [
		...columns,
		{
			id: 'Additives',
			name: 'Status',
			Header: () => <Typography componenet="p" variant="body1">{setLanguageText("Additives")}</Typography>,
			accessor: '',
			Cell: ({ row, cell }) => {

				return (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>

						{row.original.additives && row.original.additives.length ? (
							<HtmlTooltip
								title={
									<>
										<Typography componenet="p" variant="body1">{setLanguageText("Additives")}</Typography>
										<Grid container>
											{row.original.additives &&
												row.original.additives.map((item, i) => {
													return (
														<Grid item xs={12} key={i}>
															<Typography variant="caption">
																{item.additive_name ? `${item.additive_name} - ${item.additive_type} (${item.additive_details})` : item.additive_type}
															</Typography>
														</Grid>
													);
												})}
										</Grid>
									</>}
							>
								<Grid
									container
									alignItems="center"
									justifyContent="center"
									className={buttonStyles().icon_md}
									style={{ cursor: 'pointer' }}
								>
									<FontAwesomeIcon icon={faVial} />
								</Grid>
							</HtmlTooltip>
						) : <p>-</p>}

					</Grid>
				);
			}
		},
		{
			id: 'user',
			name: 'User',
			disableSortBy: true,
			Header: () => <Typography componenet="p" variant="body1">{setLanguageText("User")}</Typography>,
			accessor: props => props.user_details,
			Cell: ({ row, value }) => {
				return (
					<HtmlTooltip
						title={
							<>
								<Typography variant="p">
									{`${value?.name}  ${value?.surname}`}
								</Typography>
							</>
						}
					>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							style={{ cursor: 'pointer' }}
						>
							{value?.name.slice(0, 1)}{value?.surname.slice(0, 1)}
						</Grid>
					</HtmlTooltip >
				)
			}
		},
		{
			id: 'notes',
			name: 'Notes',
			disableSortBy: true,
			Header: () => <Typography componenet="p" variant="body1">{setLanguageText("Notes / Actions")}</Typography>,
			accessor: props => props.notes,
			Cell: ({ row, value }) => {
				//console.log(row)
				if (value) {
					return (
						<Box alignContent="center" alignItems="center" xs={{ width: '100%', display: 'flex' }}>
							{row.original.note_action === 'C' &&
								<Alert style={{ margin: '0 auto' }} severity="error"><strong>Details: </strong>{value}</Alert>
							}
							{row.original.note_action === 'N' &&
								<Alert style={{ margin: '0 auto' }} severity="success"><strong>Details: </strong>{value}</Alert>
							}
							{row.original.note_action === 'Y' &&
								<Alert style={{ margin: '0 auto' }} severity="warning"><strong>Details: </strong>{value}</Alert>
							}
						</Box>
					)
				} else {
					return '-'
				}
			}
		}
	];
	return columns;
};

export default entriesTable_columns;