import React, { useContext, useEffect, useState } from "react";
import {
    Grid,
    Alert,
    Box
} from "@mui/material";
import { useQuery } from '@tanstack/react-query'
import config from "../config";
import axiosRequest from "../axios/axoisRequest";
import SearchResults from "./SearchResults";
import { useLocation, useSearchParams } from "react-router-dom";
import { UserContext } from "../store/UserContext";
import SearchFilters from "./SearchFilters";
import { CompanyContext } from "../store/CompanyContext";
import { isMobile } from "react-device-detect";
import MobileDrawWrapper from "../reusable/MobileDrawWrapper";
import queryGetTools from "../opensearch/queryGetTools";
import SearchHeader from "./SearchHeader";
import InnerContentWrap from "../layout/InnerContentWrap";
import Sort from "./Sort";
import options from "./options.json";

export default function FullSearch() {

    //get params
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [searchParams, setSearchParams] = useSearchParams(query);
    const { selectedCompany } = useContext(CompanyContext);
    const searchValue = searchParams.get('q');
    const { user } = useContext(UserContext);
    const [value, setValue] = useState(searchValue || '');
    const [focused, setFocused] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true)
    const [defaultManufacturers, setDefaultManufacturers] = useState(selectedCompany?.opensearch_template)
    const [filters, setFilters] = useState();
    const [pagination, setPagination] = useState();


    //fires on params change
    useEffect(() => {
        if (searchParams) {

            console.log('searchParams', searchParams)
            //check if searchParams are present
            if (searchParams.size === 0) {
                setSearchParams('q=&type=all&size=10&page=0&sort=1', { replace: true })
            } else {


                let urlParams = new URLSearchParams(searchParams);
                const searchValue = urlParams.get('q');
                const searchType = urlParams.get('type');
                const searchManufacturers = urlParams.get('manufacturer');
                const sort = urlParams.get('sort');
                const page = urlParams.get('page');
                const size = urlParams.get('size');

                setValue(searchValue)

                let manufacturers = defaultManufacturers
                //check if manufacturer is an object
                if (searchManufacturers) {
                    if (typeof searchManufacturers === 'object') {
                        manufacturers = searchManufacturers
                    } else {

                        manufacturers = decodeURI(searchManufacturers)
                        manufacturers = manufacturers.split(',')
                    }
                }
                let sortOption
                if (sort) {
                    const selected = options.find(option => option.id === parseInt(sort))
                    sortOption = selected
                }
                //console.log(manufacturers)
                //console.log('searchSort', searchSort)       
                setFilters({
                    "sort": sortOption,
                    "limit": 100,
                    "q": searchValue,
                    "type": searchType,
                    "manufacturer": manufacturers,
                })

                setPagination({
                    "pageIndex": parseInt(page),
                    "pageSize": size ? parseInt(size) : 12,
                })

            }

        } else {
            setValue(null)
            setFilters({
                "limit": 100
            })
            setPagination({
                "pageIndex": null,
                "pageSize": 12,
            })
        }
    }, [searchParams, defaultManufacturers])

    const getProducts = useQuery({
        queryKey: ['tools-search', filters, pagination],
        queryFn: () => {

            if (!filters.manufacturer) {
                return ''
            }

            const searchQuery = queryGetTools({
                "term": filters.q,
                "type": filters.type,
                "from": pagination.pageIndex * pagination.pageSize,
                "size": pagination.pageSize,
                "filters": {
                    "manufacturer": filters.manufacturer
                },
                "sort": filters?.sort ? filters.sort : []
            })
            //console.log(searchQuery)

            return axiosRequest({
                name: 'tools-seach', //added to see which call is erroring
                gateway: config.opensearchAPI.URL,
                endpoint: 'tools-search',
                api_key: user.api_key,
                method: 'post',
                body: {
                    query: searchQuery
                }
            })
        },
        keepPreviousData: true,
        enabled: !!filters?.type && !!filters.manufacturer && !!pagination, //only run if searchValue and searchType are present

    })

    useEffect(() => {
        if (selectedCompany?.opensearch_template) {
            setDefaultManufacturers(selectedCompany?.opensearch_template)
        } else {
            setDefaultManufacturers()
        }
    }, [selectedCompany])

    useEffect(() => {
        if (getProducts?.data) {
            setFirstLoad(false)
        }
    }, [getProducts])


    return (
        <>
            <SearchHeader
                large={true}
                setValue={setValue}
                value={value}
                setFocused={setFocused}
                focused={focused}
                setPagination={setPagination}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                backdrop={true}
            />
            <InnerContentWrap>
                <Grid container spacing={isMobile ? 1 : 2} >
                    <Grid item xs={12} md={3} >
                        <MobileDrawWrapper
                        // resetFilters={resetForm}
                        // isFetching={isFetching}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "cards.main",
                                    padding: "1em",
                                }}
                            >
                                {defaultManufacturers ?
                                    <SearchFilters
                                        field="manufacturer"
                                        filters={filters}
                                        defaultManufacturers={defaultManufacturers}
                                        searchParams={searchParams}
                                        setSearchParams={setSearchParams}
                                    />
                                    :
                                    <Alert severity="info">
                                        Please contact an administrator to allow access.
                                    </Alert>
                                }

                            </Box>
                        </MobileDrawWrapper>
                    </Grid>
                    <Grid item xs={12} md={9} >
                        {defaultManufacturers ?
                            <SearchResults
                                filters={filters}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                getProducts={getProducts}
                                pagination={pagination}
                                setPagination={setPagination}
                                firstLoad={firstLoad}
                                isFetching={getProducts.isFetching}
                                value={decodeURI(filters?.q)}
                            />
                            :
                            <Alert severity="info">
                                Please contact an administrator to allow access.
                            </Alert>
                        }
                    </Grid>
                </Grid>
            </InnerContentWrap>
        </>
    );
};

