import React, { useState } from "react";
import { Avatar, Button, Divider, Grid, Typography } from "@mui/material";
import GlobalDialog from "../../reusable/GlobalDialog";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BuyerSearchInfo = () => {

    const [openInfo, setOpenInfo] = useState(false);

    return (
        <>
            <Avatar
                onClick={() => setOpenInfo(true)}
                variant="rounded"
                sx={{ 
                    bgcolor: theme=> theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                        bgcolor: theme=> theme.palette.primary.dark,
                    }
                }}
            >
                <FontAwesomeIcon icon={faInfoCircle} />
            </Avatar>


            {openInfo &&
                <GlobalDialog
                    open={openInfo}
                    onClose={() => setOpenInfo(false)}
                    maxWidth="sm"
                    title={"Search Characters"}
                >
                    <Grid item>

                        <Typography fontWeight={600}>spot drill</Typography>
                        <Typography>Find products that contain at least one of the two words.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>+spot +drill</Typography>
                        <Typography>Find products that contain both words.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>+spot drill</Typography>
                        <Typography>Find products that contain the word &quot;spot&quot;, but rank rows higher if they also contain &quot;drill&quot;.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>+spot -drill</Typography>
                        <Typography>Find products that contain the word &quot;spot&quot; but not &quot;drill&quot;.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>+spot ~drill</Typography>
                        <Typography>Find products that contain the word &quot;spot&quot;, but if the row also contains the word &quot;drill&quot;, rate it lower than if row does not. This is &quot;softer&quot; than a search for +spot -drill, for which the presence of &quot;drill&quot; causes the row not to be returned at all.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>+spot +({">"}drill {"<"}welder)</Typography>
                        <Typography>Find products that contain the words &quot;spot&quot; and &quot;drill&quot;, or &quot;spot&quot; and &quot;welder&quot; (in any order), but rank &quot;spot drill&quot; higher than &quot;spot welder&quot;.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>spot*</Typography>
                        <Typography>Find products that contain words such as &quot;spot&quot;, &quot;spots&quot;, or &quot;spotting&quot;.</Typography>
                        <Divider sx={{ my: 1 }} />

                        <Typography fontWeight={600}>&quot;spot drill&quot;</Typography>
                        <Typography>Find products that contain the exact phrase &quot;spot drill&quot; (for example, products that contain &quot;spot weld drill&quot; but not &quot;spot HSCo drill&quot;).</Typography>

                    </Grid>
                </GlobalDialog>
            }
        </>
    )
};
export default BuyerSearchInfo;
