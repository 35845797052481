import { useContext, useEffect } from "react";
import config from "../config";
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from "../store/UserContext";
import { useQuery } from "@tanstack/react-query";
import { UPDATE_FAVOURITES } from "../store/UserReducers";
import { CompanyContext } from "../store/CompanyContext";

const useFavourites = () => {

    const { user, dispatchUser } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);

    const { data } = useQuery({
        queryKey: ['favourites', user?.user?.user_id,selectedCompany?.company_id],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'favourites',
            api_key: user.api_key,
            method: 'get',
            params: {
                user_id: user?.user.user_id,
                company_id: selectedCompany.company_id,
                id_only: true
            }
        }),
        enabled: !!user.user.user_id && !!selectedCompany?.company_id,
        retry: false
    })

    useEffect(() => {
        if (data) {
            dispatchUser({
                type: UPDATE_FAVOURITES,
                payload: data
            });
        }
    }, [data])

};

export default useFavourites;

