const formatDate = (date, time) => {
	if (date) {
		const getDate = new Date(date);
		const day =
			+getDate.getDate() < 10 ? `0${getDate.getDate()}` : getDate.getDate();
		const month =
			Number(getDate.getMonth()) < 9
				? `0${getDate.getMonth() + 1}`
				: `${getDate.getMonth() + 1}`;
		const year = +getDate.getFullYear();
		if (time) {
			const value_date = new Date(date);
			const value_hours =
				value_date.getUTCHours() < 10
					? `0${value_date.getUTCHours()}`
					: value_date.getUTCHours();
			const value_minutes =
				value_date.getUTCMinutes() < 10
					? `0${value_date.getUTCMinutes()}`
					: value_date.getUTCMinutes();
			const value_seconds =
				value_date.getUTCSeconds() < 10
					? `0${value_date.getUTCSeconds()}`
					: value_date.getUTCSeconds();

			return `${value_hours}:${value_minutes}:${value_seconds}`;
		}

		return `${day}/${month}/${year}`;
	}
	return '-';
};

export default formatDate;
