import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactHtmlParser from 'html-react-parser';

const NewRequest = ({ request, theme }) => {
 //console.log(request)

    //request_name: values.name,
    //request_details: values.details,
    //request_required_date: Date.parse(values.date.toString()),
    //request_submit_date

    return (
        <div style={{ backgroundColor: '#fff', padding: '2em', width: '100%', maxWidth: '800px', display: 'table' }} >
            <div style={{ backgroundColor: '#fff', width: '100%', textAlign: 'center', color: '#333' }} >
            <img
                src={theme?.email_logo}
                alt="logo"
              />
            </div>
            <table style={{ width: '100%', maxWidth: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <thead style={{ backgroundColor: '#fff', padding: '2em', textAlign: 'center', color: '#333' }}>
                    <th>
                        <h2>New Jig/Fixtures Request</h2>
                    </th>
                </thead>
                <tbody style={{ backgroundColor: 'rgb(238, 238, 238)', boxShadow: '0 0 5px #999'}} >
                    <tr>
                        <td valign="top" align="center" colSpan="2"> <h3 style={{ color: '#333' }} >{request?.request?.request_name ? request.request.request_name : 'No request title'}</h3></td>
                    </tr>
                    <tr><td valign="top" align="left" colSpan="2">
                        <p>Added by <strong>{request?.request?.request_user?.user_details.name} {request?.request?.request_user?.user_details?.surname ? request?.request.request_user.user_details.surname : "Bob"}</strong> on <strong>{request?.request?.request_submit_date ? new Date(request.request.request_submit_date).toLocaleDateString() : new Date().toLocaleDateString()} {request?.request?.request_submit_date ? new Date(request.request.request_submit_date).toLocaleTimeString() : new Date().toLocaleTimeString() }</strong></p>
                        <p>Required by <strong>{request?.request?.request_required_date ? new Date(request.request.request_required_date).toLocaleDateString() : new Date().toLocaleDateString()}</strong></p>
                        <p>Company <strong>{request?.request?.request_company?.company_name ? request.request.request_company.company_name : 'Company name'}</strong></p>
                    </td>
                    </tr>
                    <tr>
                        <td valign="top" align="left" colSpan="2">{request?.request?.request_details ? ReactHtmlParser(request.request.request_details) : 'No request details'}</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ borderRadius: '4px', padding: '10px', backgroundColor: "#949292", float: 'left' }}>
                                <FontAwesomeIcon style={{ marginRight: '10px', width: '20px', height: '20px', color: '#fff', float: 'left' }} icon={faMagnifyingGlass} />
                                <a style={{ textDecoration: 'none', color: '#fff', width: 'auto' }} href={`${window.location.origin}/fixtures/view?request=${request?.request_id}&company=${request?.request?.request_company?.company_id}`} target="_blank" rel="noreferrer">
                                    View Request Details
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table >
        </div >
    )
};

export default NewRequest;  