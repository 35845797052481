
import React, { useState, useEffect, useContext } from 'react';
import QrReader from 'react-qr-scanner';
import config from '../../config';
import { Alert } from '@mui/material';
import { Button, Grid } from '@mui/material';
import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { SelectionsContext } from '../store/SelectionsContext';
import { useNavigate } from 'react-router-dom';
import PageContent from '../PageContent';
import ManualInputs from './ManualInputs';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import setLanguageText from '../../language/setLanguageText';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import { useMutation } from '@tanstack/react-query';
import { CompanyContext } from '../../store/CompanyContext';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';


const Buffer = require('buffer').Buffer;

const Scanner = () => {

	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { addNewEntry } = useContext(SelectionsContext);
	const [salting] = useState(Buffer.from(config.SALTING).toString('base64'));
	const [result, setResult] = useState();
	const [error, setError] = useState();
	const [showCamera, setShowCamera] = useState(true);
	const [fireAgain, setFireAgain] = useState(true);
	const { dispatchSelectedCompany } = useContext(CompanyContext);

	const getCompanyDetails = useMutation((details) => {
		//console.log(user)
		return axiosRequest({
			method: 'get',
			endpoint: 'companies',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			params: {
				id: details.company_id,
				machine_id: details.machine_id
			}
		})
	}, {
		onSuccess: (data, variables) => {
			//console.log(data)
			//console.log(variables)
			dispatchSelectedCompany({
				type: SELECTED_COMPANY,
				payload: data[0]
			});
			window.sessionStorage.setItem('selectedCompany', data[0].company_id);
			navigate(`/coolantcare/machine/${variables.machine_id}`);
			//setMachineID(reverseData.machine_id);
			setError();
		}
	}
	)

	useEffect(() => {
		if (window.location.search) {
			//console.log(window.location.search)
			const urlParams = new URLSearchParams(window.location.search);
			const scan = urlParams.get("scan");
			if (scan) {
				setShowCamera(true)
				//navigate('/coolantcare/qr-code')
			}
		}
	}, [window.location.search])

	const handleScan = data => {
		if (data && showCamera && fireAgain) {
			if (data)
				setFireAgain(false);
			setResult({
				result: data.text
			});
		}
	};

	const reverse = s => {
		return s.split('').reverse().join('');
	};

	// //get machine details from qr code
	// const getMachineDetails = useMutation({
	// 	mutationFn: (machine_id) => axiosRequest({
	// 		gateway: config.coolantAPI.URL,
	// 		api_key: user.api_key,
	// 		method: 'get',
	// 		endpoint: 'machines',
	// 		params: {
	// 			machine_id: machine_id,
	// 		}
	// 	}),
	// 	onSuccess: (response) => {
	// 		if (response[0].machine_id) {
	// 			setMachineUrl(`/coolantcare/machine/${response[0].machine_id}`);
	// 		}
	// 	}
	// })

	//get dipslide details from qr code
	const getDipslideEntry = useMutation({
		mutationFn: (fullCode) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'qr',
			api_key: user.api_key,
			method: 'post',
			body: {
				code: fullCode,
				qr_details: true
			}
		}),
		onSuccess: (response) => {
			//TODO: make sure user has permissions to view entry
			if (response[0].entry_id) {
				navigate(`/coolantcare/machine/entry?entry=${response[0].entry_id}&machine=${response[0].machine_id}&company=${response[0].company_id}`)
			} else {
				setError('QR code is not linked to an entry');
			}
		}
	})

	const qrDetails = (qr) => {
		setData(qr)
	}

	//set details from scaning qr code
	useEffect(() => {
		if (result) {
			setShowCamera(false);
			const data = result.result;
			//OLD PDF QR CODES
			// if (data.substring(data.length - 3) === 'pdf') {
			// 	//Legacy QR Code e.g https://ccsdev.uk/coolantcare/pdfs/view.php?file=https://ccsdev.uk/coolantcare/uploads/75.pdf
			// 	var id = data.substring(
			// 		data.lastIndexOf('/') + 1,
			// 		data.lastIndexOf('.')
			// 	);
			// 	//Fetch
			// 	//new SDS/SDT QR code  e.g {"company_id":"1","machine_id":"392","instance":"prod"}
			// } else 
			if (data) {
				let reverseData = reverse(data);
				//machine QR codes
				if (reverseData.includes(salting)) {
					reverseData = reverseData.substring(salting.length);
					//console.log('includes salting')
					reverseData = JSON.parse(Buffer.from(reverseData, "base64").toString("utf-8"));
					// if (reverseData.app === config.APPNAME) {
					// 	//console.log('same app')
					// 	// this is a dipslide code OLD version
					// 	if (reverseData.code) {
					// 		//console.log(reverseData)
					// 		setResult();
					// 		getLinkedEntry(reverseData);
					// 		setError();
					// 		setLoading(false)
					// 	}
					// } else 
					if (reverseData.instance === config.INSTANCE) {
						//get machine details
						//console.log('machine QR')
						if (reverseData.machine_id) {


							//set company
							console.log(reverseData)

							getCompanyDetails.mutate(reverseData)
						}

					} else {
						setError('QR code not from this application or instance');
						//setLoading(false);
					}
				} else if (data) {

					//dipslide qr code
					const myArr = data.split(" ");
					if (parseInt(myArr[1]) !== config.APPNAME) {

						//console.log(myArr)
						setResult();
						//fetch entry
						getDipslideEntry.mutate({
							app: myArr[1],
							code: myArr[0]
						});
						setError();

					} else {
						setError('QR code not from this application');
						//setLoading(false);
					}
				} else {
					setError(`Not a ${config.NAME} QR code`);
					//setLoading(false);
				}
			}
		}
	}, [result, salting, addNewEntry, config]);

	return (
		<>
			<InnerHeaderWrap>
			</InnerHeaderWrap>
			<PageContent>
				{error ?
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
					: null}
				<Grid item container spacing={2} alignItems="center" justifyContent="center">
					{showCamera &&
						<Grid item xs={12} container alignItems="center" >
							<QrReader
								//resolution={1440}  
								constraints={{
									video: {
										//deviceId: { cameraId },
										facingMode: "environment"
									}
								}}
								// //facingMode="environment"
								// //chooseDeviceId={cameraId}
								// delay={delay}
								onError={e => console.log('')}
								onScan={handleScan}
								style={{ width: '100%', maxWidth: isMobileOnly ? '100%' : '60%', margin: '0 auto' }}
							/>
						</Grid>
					}
					<Grid item container spacing={2} alignItems="center" justifyContent="center">
						<Grid item>
							<ManualInputs qrDetails={qrDetails} />
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="secondary"
								disabled={showCamera}
								onClick={e => {
									setShowCamera(true);
									setMachineID();
									setMachineUrl();
									setCoolantDetails();
									setFireAgain(true);
									setError();
								}}
								startIcon={<FontAwesomeIcon icon={faQrcode} size="2x" />}
							>
								{setLanguageText("Scan Again")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</PageContent>
		</>
	);
};


export default Scanner;
