
import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import MobileMenu from './mobile/MobileMenu';
import DesktopMenuSub from './desktop/DesktopMenuSub';
import { isMobileOnly } from 'react-device-detect';

const Sidebar = ({ children, mobileOpen, setMobileOpen, mobileClose }) => {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <>
            {!isMobileOnly ?
                <DesktopMenuSub />
                :
                <MobileMenu mobileOpen={mobileOpen} mobileClose={mobileClose} setMobileOpen={setMobileOpen} />
            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    //marginTop: '65px' 
                }}
            >
                {children}
            </Box>
        </>
    );
}

export default Sidebar;
