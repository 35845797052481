import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { CompanyContext } from '../../store/CompanyContext';
import tableChipStyle from '../styles/tableChipStyles';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import setColour from '../helpers/setColour';
import setLanguageText from '../../language/setLanguageText';

// parse machines' attributes and settings
const CreateGridChip = ({ title, factorApplied, entryFactor, company_attr_id, entry_attributes }) => {

	const { selectedCompany } = useContext(CompanyContext);
	const [found_attr, setFound_attr] = useState();
	const [colour_chip, setColour_chip] = useState();
	const [showFactored, setShowFactored] = useState(false);

	useEffect(() => {
		if (entry_attributes) {
			setFound_attr(
				entry_attributes.find(attr => attr.attribute_id === company_attr_id)
			);
		}
	}, [company_attr_id, entry_attributes]);

	useEffect(() => {
		if (found_attr) {
			if (selectedCompany?.company_colour_rules) {
				setColour_chip(
					setColour(
						selectedCompany?.company_colour_rules,
						found_attr.attribute_values.status
					)
				);
			}
		}
	}, [entryFactor, factorApplied, found_attr, selectedCompany]);

	// TODO ADD ICON TO SAVED DATA FOR BETTER CONSISTENCY
	const selectIcon = (value, min, max, factor) => {
		//console.log(factor)
		if (value === -1 || value === -2) {
			return
		}
		//console.log(factor)
		if (factor > 1) {
			if (value > (factor / max)) {
				return <ArrowUpward style={{ color: 'white', width: '16px' }} />
			} else if (value < (factor / min)) {
				return <ArrowDownward style={{ color: 'white', width: '16px' }} />
			}
		} else {
			if (value > max) {
				return <ArrowUpward style={{ color: 'white', width: '16px' }} />
			} else if (value < min) {
				return <ArrowDownward style={{ color: 'white', width: '16px' }} />
			}
		}
		return
	};

	return (
		<>
		
				<Box sx={{
					backgroundColor: colour_chip && colour_chip !== 'transparent' ? colour_chip : '#f3f3f3',
					borderRadius: '5px',
					border: `1px solid ${colour_chip && colour_chip !== 'transparent' ? colour_chip : '#ccc'}`,
					width: '100%',
					padding: '10px',
					textAlign: 'center'
				}} >

					<Typography variant="body2">
						<strong>{setLanguageText(title)}</strong>: {' '}
						{found_attr && found_attr?.attribute_values.output ?
							<>
								{found_attr.attribute_id === 'bacteria' ?
									<>
										{found_attr.attribute_values?.output.includes("^") ?
											<>
												{found_attr.attribute_values.output.split("^")[0]}<sup>{found_attr.attribute_values.output.split("^")[1]}</sup>
											</>
											: found_attr.attribute_values.output !== '-' && found_attr.attribute_values.output > 0 ?
												<>
													10
													<sup>{found_attr.attribute_values.output}</sup>
												</>
												: found_attr.attribute_values.output === "0" ? 'Clear' : found_attr.attribute_values.output}
									</>
									:
									<>
										{showFactored && found_attr.attribute_values?.output > 0
											? `${(Number(found_attr.attribute_values.output) * Number(entryFactor ? entryFactor : 1)).toFixed(1)} (${Number(found_attr.attribute_values.output).toFixed(1)})` :
											Number.isInteger(found_attr.attribute_values.output) && !showFactored
												? found_attr.attribute_values.output.toFixed(2)
												: found_attr.attribute_values.output}

									</>
								}
							</>
							//no value set check status
							: found_attr?.attribute_values.status === -10 ? 'Pending'
								: found_attr?.attribute_values.status === -20 ? '-' : '-' //status false
						}
						{/* {selectIcon(
							Number(found_attr.attribute_values.value),
							Number(found_attr.attribute_values.min),
							Number(found_attr.attribute_values.max),
							found_attr.attribute_id === 'conc' ? Number(entryFactor ? entryFactor : 1) : 1
							//divide by factor if applied, set to 1 if not factor was set when taking the readings
						)} */}
					</Typography>
				</Box>
		

		</>
	);
};

export default CreateGridChip;
