import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Tabs,
	Tab,
	Card
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { UserContext } from '../../store/UserContext';
import DisplaySettings from '../../coolantcare/Display/DisplaySettings';
import setLanguageText from '../../language/setLanguageText';
import CompanyAttributes from '../../coolantcare/attributes/CompanyAttributes';
import CompanyDefaults from '../companies/CompanyDefaults';
import getUserLevel from '../../helpers/getUserLevel';
import ProductVisibility from '../companies/visibility/coolantcare/ProductVisibility';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: isMobileOnly ? 'block' : 'flex',
		//height: 224,
	},
	dropdown: {
		width: '100%',
		padding: '10px 10px 10px 0',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		minWidth: isMobileOnly ? '100%' : '240px',
		overflow: 'visible',
		background: theme.palette.tabs.menu
	},
	tabs_content: {
		width: '100%',
		overflow: 'auto',
		background: theme.palette.tabs.content
	},
}));


function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyles();
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			className={classes.tabs_content}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				children
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function createProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const CoolantSettings = () => {

	const navigate = useNavigate();
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const [edit, setEdit] = useState(false);
	const { user } = useContext(UserContext);

	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search);
		let tab = urlParams.get("tab")
		if (tab) {
			setValue(parseInt(tab));
		}
	}, [])

	const handleChange = (event, newValue) => {
		let urlParams = new URLSearchParams(window.location.search);
		let section = urlParams.get("section")
		navigate(`/settings/company?section=${section}&tab=${newValue}`)
		setValue(parseInt(newValue));
	};


	//add edit to form items
	useEffect(() => {
		if (getUserLevel(user.user, 'coolantcare', 'company_settings', 7)) {
			//if (user.user.user_permissions.coolantcare.level > 7) {
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [user])


	return (
		<Card className={classes.root} >
			<Tabs
				allowScrollButtonsMobile
				orientation={isMobileOnly ? 'horizontal' : 'vertical'}
				variant="scrollable"
				value={value}
				onChange={handleChange}
				className={classes.tabs}
			>
				<Tab label={setLanguageText("Machine Table Defaults")} {...createProps(0)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: '1px solid #e8e8e8' }} />
				<Tab label={setLanguageText("Machine Entry Attributes")} {...createProps(1)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: '1px solid #e8e8e8' }} />
				<Tab label={setLanguageText("Machine Display View")} {...createProps(2)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: '1px solid #e8e8e8' }} />
				<Tab label={setLanguageText("Product Visibility")} {...createProps(3)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: '1px solid #e8e8e8' }} />
				{/* <Tab label={setLanguageText("Notifications")} {...createProps(3)} sx={{ textAlign: 'left', alignItems: 'start', borderBottom: '1px solid #e8e8e8' }} /> */}
			</Tabs>
			<TabPanel value={value} index={0}>
				<CompanyDefaults edit={edit} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<CompanyAttributes />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<DisplaySettings edit={edit} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<ProductVisibility />
			</TabPanel>
			{/* <TabPanel value={value} index={3}>
				<CompanyNotification edit={edit} />
			</TabPanel> */}
		</Card>
	);
}

export default CoolantSettings;


