import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

function ErrorFallback({ error, resetErrorBoundary }) {

    const [open, setOpen] = useState(true);

    const onClose = () => {
        setOpen(false)
        resetErrorBoundary()
    }

    return (
        <>
            <Backdrop
                sx={{ zIndex: theme => theme.zIndex.tooltip + 10000 }}
                open={open}
            >
                <Dialog
                    open={open}
                    onClose={onClose}
                >
                    <DialogTitle sx={{ background: (theme) => theme.palette.grey[200], marginBottom: '1em', paddingRight : '2em'}} >
                        Whoops, something went wrong!
                        {onClose ? (
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{
                                    width: '35px',
                                    height: '35px',
                                    position: 'absolute',
                                    right: 8,
                                    top: 14,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent sx={{ padding: '1em', position: 'relative' }}>
                        <Alert
                            variant="filled"
                            severity="error"
                            sx={{marginBottom: '1em'}}
                        >
                            <strong>Error message</strong>: {error.message}.
                        </Alert>
                        <Typography component="p" variant="h6" textAlign="center">Sorry for the inconvenience.</Typography>
                        <Typography component="p" textAlign="center" sx={{padding: ".5em 0"}}>Please refresh and try again.</Typography>
                        <Typography component="p" textAlign="center">If the problem persits, please contact techical support.</Typography>
                    </DialogContent>
                    <DialogActions sx={{ background: (theme) => theme.palette.grey[200], padding: '1em' }} >
                        <Button
                            variant="contained"
                            onClick={onClose}
                        >
                            Close & try again
                        </Button>
                    </DialogActions>
                </Dialog >
            </Backdrop>
        </>

    )
}

export default ErrorFallback;