import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../store/LayoutContext';
import { UPDATE_LAYOUT } from '../store/LayoutReducers';
import { useTheme } from '@mui/styles';
import { Stack, Switch } from '@mui/material';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';

const DarkMode = () => {

    const { layout, dispatchLayout } = useContext(LayoutContext)
    const [colorMode, setColorMode] = useState(layout.colorMode);
    const  theme = useTheme();

    useEffect(() => {
        if (colorMode) {
            //console.log(colorMode)
            dispatchLayout({
                type : UPDATE_LAYOUT,
                payload : {
                    colorMode: colorMode
                }
            })
            sessionStorage.setItem("mode", colorMode);
        }
    }, [colorMode])

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <LightModeOutlined />
            <Switch   
                checked={ theme.palette.mode === 'dark' ? true : false}
                onChange={() => setColorMode( colorMode !== 'dark' ? 'dark': 'light')}
                color="default" 
            />
           <DarkModeOutlined />
        </Stack>

    );
};
export default DarkMode;