import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, ListItem, Switch, List, FormControlLabel, FormControl, Select, MenuItem } from '@mui/material';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { SAVE_COMPANY_SETTINGS } from '../../store/CompanyReducers';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import setLanguageText from '../../language/setLanguageText';
import useAsync from '../../coolantcare/Hooks/useAsync';
import axiosRequest from '../../coolantcare/helpers/axiosRequest';
import LoadingSuccessButton from '../../coolantcare/buttons/LoadingSuccessButton';

const CompanyDefaults = ({ edit }) => {

	const { user } = useContext(UserContext);
	const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
	const [columnOptions, setColumnOptions] = useState()
	const {
		data,
		loading,
		executeAsync,
	} = useAsync({
		axiosRequest: axiosRequest
	});

	const handleChange = (e) => {

		let newOptions = [...columnOptions]
		let index = newOptions.findIndex(obj => obj.id === e.target.name);
		newOptions[index].isVisible = !newOptions[index].isVisible;
		setColumnOptions(newOptions)
	}

	useEffect(() => {
		if (Object.values(selectedCompany).length) {
			//create option first
			let createOptions = [
				{ 'id': 'machine_name', 'name': 'Machine Name', 'isVisible': true },
				{ 'id': 'machine_group', 'name': 'Machine Group', 'isVisible': true },
				{ 'id': 'last_coolant_change', 'name': 'Last Coolant Change', 'isVisible': true },
				{ 'id': 'date_stamp', 'name': 'Last Entry', 'isVisible': true },
			]
			selectedCompany.company_attributes.forEach((item) => {
				console.log(item)
				createOptions.push({ 'id': item.one.attr_id, 'name': item.one.attr_name, 'isVisible': true })
			});
			createOptions.push(
				{ 'id': 'coolant_name', 'name': 'Coolant', 'isVisible': true },
				{ 'id': 'notes', 'name': 'Notes', 'isVisible': true },
				{ 'id': 'additives', 'name': 'Additives', 'isVisible': true }
			)
		
			// then check default and update matching option vis id
			if (selectedCompany?.company_settings?.machines_defaults) {
				createOptions.forEach((item) => {
					let index = selectedCompany?.company_settings?.machines_defaults.findIndex(obj => obj.id === item.id);
					if (index !== -1) {
						item.isVisible = selectedCompany?.company_settings?.machines_defaults[index].isVisible
					}
				})
			}

			if (selectedCompany?.company_settings?.show) {
				setShow(selectedCompany?.company_settings?.show)
			}

			console.log(createOptions)	
			setColumnOptions(createOptions)

		}
	}, [selectedCompany]);

	//save columns
	const saveSettings = async (columnOptions, show) => {

		//console.log(selectedCompany.company_settings)
		let newSettings = { ...selectedCompany.company_settings }
		newSettings.machines_defaults = columnOptions
		newSettings.show = show
		delete newSettings.required

		await executeAsync({
			endpoint: 'company/settings',
			api_key: user.api_key,
			method: 'patch',
			body: {
				company_id: selectedCompany.company_id,
				company_settings: newSettings
			}
		});
		window.sessionStorage.setItem('show', show);

		//TODO = update context
		//console.log(newSettings)
		dispatchSelectedCompany({
			type: SAVE_COMPANY_SETTINGS,
			payload: {
				"company_settings": newSettings
			}
		});

	}

	const options = [10, 20, 30, 50, 100];
	const [show, setShow] = useState(window.sessionStorage.getItem('show') ? window.sessionStorage.getItem('show') : 10);

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Table Defaults")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid
					item
					container
					spacing={2}
				>
					<Grid item xs={12}>
						<Typography variant="h5">{setLanguageText("Table items in view")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControl>
							<Select
								value={show}
								id="select_rows"
								variant="outlined"
								name={setLanguageText("Show")}
								disabled={!edit}
								onChange={e => {
									//setPageSize(Number(e.target.value));
									setShow(Number(e.target.value));
								}}
							//className={inputStyle.inputRows}
							>
								{options.map(pageSize => (
									<MenuItem key={pageSize} value={pageSize}>
										{pageSize}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h5">{setLanguageText("Machines table columns")}</Typography>
					</Grid>
					<Grid item xs={12} >
						<List>
							{columnOptions && columnOptions.map(setting => (
								<ListItem style={{ borderTop: '1px solid #ececec' }} key={setting.id}>
									<FormControlLabel
										control={
											<Switch
												onChange={handleChange}
												checked={setting.isVisible ? setting.isVisible : false}
												name={setting.id}
												disabled={!edit}
												color="primary"
											/>
										}
										name={setting.id}
										label={setting.name}
									/>
								</ListItem>
							))}
						</List>
					</Grid>
					<Grid item xs={12} >
						<LoadingSuccessButton
							disabled={!edit}
							icon={faSave}
							text="Save columns"
							iconSize="1x"
							color="primary"
							loading={loading}
							onClick={e => saveSettings(columnOptions, show)}
							data={data}
						/>
					</Grid>
				</Grid>
			</TabContent>
		</>
	);
};

export default CompanyDefaults;
