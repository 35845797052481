import React, { useContext, useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import ResetMachine from './ResetMachine';
import { MachineContext } from '../../store/MachineContext';
import { SELECTED_MACHINE } from '../../store/MachineReducers';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../language/setLanguageText';
import useActivityLog from '../../../users/activity/useActivityLog';
import { useMutation } from '@tanstack/react-query';
import {
	Button,
	Grid,
	TextField
} from '@mui/material';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteMachine from './DeleteMachine';
import getUserLevel from '../../../helpers/getUserLevel';
import DialogLoader from '../../../reusable/DialogLoader';

const settingsObj = selectedMachine => {
	const machine_defaults = selectedMachine.machine_settings.machine_defaults;
	return {
		change_freq: machine_defaults.change_freq
			? machine_defaults.change_freq
			: 0,
		factor: machine_defaults.factor ? machine_defaults.factor : machine_defaults.refractomer_factor, //refractomer_factor is legacy
		last_coolant_change: machine_defaults.last_coolant_change
			? machine_defaults.last_coolant_change
			: 'undefined',
		op_con_max: machine_defaults.op_con_max,
		op_con_min: machine_defaults.op_con_min,
		ph_max: machine_defaults.ph_max,
		ph_min: machine_defaults.ph_min,
		sump: machine_defaults.sump
	};
};

function EditSettings() {

	const {
		updateActivityLog,
	} = useActivityLog();
	const { user } = useContext(UserContext);
	const { dispatchSelectedMachine, selectedMachine } = useContext(MachineContext);
	const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
	const [settings, setSettings] = useState(null);

	useEffect(() => {
		if (selectedMachine.machine_settings && selectedMachine.machine_settings.machine_defaults) {
			setSettings(settingsObj(selectedMachine));
		}
	}, [selectedMachine]);

	const openDialog = event => {
		setDialogAnchorEl(event.currentTarget);
	};

	const closeDialog = () => {
		setDialogAnchorEl(null);
		// Reset the Machine Name/Group if close the edit dialog
		setSettings(prevState => settingsObj(selectedMachine));
	};

	const handleEditSettings = event => {
		event.persist();
		setSettings(prevState => ({
			...prevState,
			machine_id: selectedMachine.machine_id,
			[event.target.name]: event.target.value
		}));
	};

	const editMachine = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response) => {

			if (!response.errorMessage) {
				console.log(response)

				updateActivityLog.mutate({
					activity_details: {
						area: "coolant",
						machine_id: selectedMachine.machine_id,
						settings: settings,
						last_coolant_change: selectedMachine.last_coolant_change
					},
					activity_type: "machine edit settings"
				})

				dispatchSelectedMachine({
					type: SELECTED_MACHINE,
					payload: {
						...selectedMachine,
						machine_settings: response[0].machine_settings
					}
				});
			}
			//TODO : errors
			closeDialog()
		}
	})

	const confirmEdit = () => {
		editMachine.mutate({
			edit: 'settings',
			machine_id: selectedMachine.machine_id,
			settings: settings,
			last_coolant_change: selectedMachine.last_coolant_change
		})
	};

	const isDialog = Boolean(dialogAnchorEl);

	return (
		<>
			{getUserLevel(user.user, 'coolantcare', 'machine_delete', 8) ?
				<Grid item>
					<DeleteMachine selectedMachine={selectedMachine} />
				</Grid>
				: null}
			<Grid item>
				<ResetMachine selectedMachine={selectedMachine} />
			</Grid>
			<Grid item>
				<Button
					id="edit"
					variant="contained"
					color="primary"
					onClick={openDialog}
					disabled={!settings}
					startIcon={<FontAwesomeIcon icon={faEdit} />}
				>
					{setLanguageText("Edit Machine Settings")}
				</Button>
				{settings && (
					<GlobalDialog
						open={isDialog && dialogAnchorEl.id === 'edit'}
						onClose={closeDialog}
						title="Edit Machine Settings"
						buttonClick={confirmEdit}
						buttonTitle="Confirm"
						successMessage="Settings edited successfully!"
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="sump"
									label={setLanguageText("Sump")}
									value={settings.sump}
									onChange={handleEditSettings}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="factor"
									label={setLanguageText("Refractometer Factor")} //refractomer_factor is legacy
									value={settings.factor}
									onChange={handleEditSettings}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="ph_min"
									label={setLanguageText("PH Min")}
									value={settings.ph_min}
									onChange={handleEditSettings}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="ph_max"
									label={setLanguageText("PH Max")}
									value={settings.ph_max}
									onChange={handleEditSettings}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="op_con_min"
									label={setLanguageText("Op Con Min")}
									value={settings.op_con_min}
									onChange={handleEditSettings}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									variant="outlined"
									name="op_con_max"
									label={setLanguageText("Op Con Max")}
									value={settings.op_con_max}
									onChange={handleEditSettings}
									required
								/>
							</Grid>

						</Grid>
						{editMachine.isLoading || editMachine.isSuccess || editMachine.isError ?
							<DialogLoader
								isLoading={editMachine.isLoading}
								mutation={editMachine}
								loadingMessage="Updating Machine..."
								successMessage="Machine Updated"
								// closeDialog={() => {
								//     navigate('/coolantcare/machines');
								// }}
								fixed

							/>
							: null}
					</GlobalDialog>
				)}
			</Grid>

		</>
	);
}

export default EditSettings;
