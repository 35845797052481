import React from 'react';

const EmailContainer = ({ children }) => {

    return (

        <div style={{ backgroundColor: 'rgb(238, 238, 238)', padding: '2em', width: '100%' }} >
            <div style={{ backgroundColor: '#fff', width: '800px', display: 'table', margin: '0 auto' }} >
                {children}
            </div>
        </div>

    )
};

export default EmailContainer;  