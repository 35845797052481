import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, Alert, Autocomplete, Box } from '@mui/material';
import config from '../config';
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import { CompanyContext } from '../store/CompanyContext';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import DialogLoader from '../reusable/DialogLoader';
import setLanguageText from '../language/setLanguageText';
import useActivityLog from '../users/activity/useActivityLog';

const AddTemplate = ({ type, refetch }) => {

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();

    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [options, setOptions] = useState();
    const [selectedOption, setSelectedOption] = useState();

    //save template
    const save_template = useMutation(() => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'post',
            body: {
                "template_type": selectedOption.id,
                "template_name": templateName,
                "template_description": templateDescription ? templateDescription : '',
                "template": []
            },
        })
    })

    useEffect(() => {
        //if type is array create a drowpdown select
        if (Array.isArray(type) && type.length > 1) {
            setOptions(
                [{
                    "id": "catalogue_brands",
                    "name": "Catalogue"
                }, {
                    "id": "engineer_brands",
                    "name": "Engineer"
                },{
                    "id": "opensearch_brands",
                    "name": "OpenSearch"
                }])
            setSelectedOption({
                "id": "catalogue_brands",
                "name": "Catalogue"
            })
        } else {
            setOptions(null)
            setSelectedOption({
                "id": type[0],
                "name": type[0]
            })
        }
    }, [type])


    return (
        <>
            <Grid item>
                <Button
                    variant='contained'
                    color="primary"
                    onClick={e => setOpen(true)}
                    size="large"
                >
                    Create a New Template
                </Button>
            </Grid>

            {open &&
                <GlobalDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    title={`Create a New Template`}
                    buttonClick={() => {
                        save_template.mutate();
                    }}
                    buttonTitle="Save Template"
                    loading={save_template.isLoading}
                    maxWidth="sm"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name='template_name'
                                type='text'
                                fullWidth
                                variant="outlined"
                                label="Template Name"
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='template_description'
                                fullWidth
                                multiline
                                type='text'
                                rows={4}
                                variant="outlined"
                                label="Template Description"
                                value={templateDescription}
                                onChange={e => setTemplateDescription(e.target.value)}
                            />
                        </Grid>
                        {options ?
                            <Grid item xs={12}>
                                <Autocomplete
                                    //disableClearable
                                    id="selected-company"
                                    value={selectedOption || null}
                                    options={options}
                                    getOptionLabel={option => option.name}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(props, option, i) => (
                                        <Box component="li" {...props} key={i}>
                                            {option.name}
                                        </Box>
                                    )}
                                    onChange={(e, newValue) => {
                                        setSelectedOption(newValue)
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant="outlined" label={setLanguageText("Template Type")} />
                                    )}
                                />
                            </Grid>
                            : null}
                        {save_template.isLoading || save_template.isSuccess || save_template.isError ?
                            <DialogLoader
                                fixed
                                isLoading={save_template.isLoading}
                                mutation={save_template}
                                loadingMessage="Saving Template..."
                                successMessage="Saved Template"
                                closeDialog={() => {
                                    setOpen(false)
                                    setTemplateName('')
                                    setTemplateDescription('')
                                    refetch();
                                }}
                            />
                            : null}
                    </Grid>
                </GlobalDialog>
            }
        </>
    );
};

export default AddTemplate;
