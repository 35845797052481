
export default function createUrl(url, selected, categories, appClass, appForm,appApp) {
  let search, filters
  console.log(selected.urlFilters)
  if (selected.urlFilters.search) {
    search = JSON.stringify(selected.urlFilters.search.sort())
  }
  if (selected.urlFilters.filters) {
    filters = JSON.stringify(selected.urlFilters.filters.sort())
  }
  //let filters = selected.selectedFilters;
  let id = Object.keys(categories.currentCat).length ? categories.currentCat.ID : "Root";
  //let type = categories.currentCat.Items ? 'node' : 'leaf';

  let newUrl = `${url}?c=${id}&search=${search}&filters=${filters}`

  if (appClass && appForm && appApp) {
    newUrl += `&class=${appClass}&form=${appForm}&app=${appApp}`
  } else {
    newUrl += `&app=[]`
  }
  return newUrl;
}