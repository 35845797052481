import {
	Alert,
	Box,
	Button,
	DialogActions,
	Grid,
	TextField,
	Typography
} from '@mui/material';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import UserTable from './UserTable';
import { CSVLink } from 'react-csv';
import GlobalDialog from '../../../../reusable/GlobalDialog';
import setLanguageText from '../../../../language/setLanguageText';
import formatDate from '../../../../coolantcare/helpers/formatDate';
import UserTableSubComponent from '../../../../users/users/UserTableSubComponent';

const ActivityDialog = ({ open, setOpen, details, settings, permissions,roles }) => {

	

	// const [csv_data, setCsv_data] = useState([]);
	// const [columns, setColumns] = useState([]);

	// useEffect(() => {

	// 	if (data) {
	// 		let columns = []
	// 		Object.entries(data).forEach(value => {
	// 			columns.push({
	// 				accessorKey: value[0],
	// 				header: () => value[0],
	// 				cell: info => {
	// 					//console.log(info)
	// 					let details = JSON.parse(info.row.original[value[0]])
	// 					return details
	// 				}
	// 			})
	// 		})
	// 		setColumns(columns)
	// 	}

	// }, [data]);

	// console.log(columns)

	// const columns = useMemo(() => [
	// 	{
	// 		accessorKey: 'user',
	// 		header: () => 'User',
	// 		footer: props => props.column.id,
	// 		cell: info => {
	// 			//console.log(info)
	// 			let details = JSON.parse(info.row.original.user)
	// 			return details.name
	// 		}
	// 	}, {
	// 		accessorKey: 'user',
	// 		header: () => 'Email',
	// 		footer: props => props.column.id,
	// 		cell: info => {
	// 			//console.log(info)
	// 			let details = JSON.parse(info.row.original.user)
	// 			return details.email
	// 		}
	// 	},{
	// 		accessorKey: 'admin',
	// 		header: () => 'Is Administrator',
	// 		footer: props => props.column.id,
	// 		cell: info => {
	// 			//console.log(info)
	// 			return (
	// 				<Grid container spacing={1}>
	// 					{info.row.original.time ? formatDate(info.row.original.time) : "Pending Approval"}
	// 				</Grid>
	// 			)
	// 		},
	// 	}
	// ], [])

	// const csv_filename = useMemo(() => {
	// 	return `user details `;
	// }, []);

	const closeModal = () => {
		//onsole.log('close modal')
		setOpen(false);
	}

	// const create_csv_data = useCallback(() => {
	// 	let row_values = [];
	// 	const headers = columns.map(header => header.header());
	// 	data.forEach(value => {
	// 		//console.log(value)
	// 		const user = JSON.parse(value.user)
	// 		const details = JSON.parse(value?.details)
	// 		const column_value = [
	// 			user?.name,
	// 			user?.email,
	// 			value.type,
	// 			details ? JSON.stringify(value?.details).replaceAll('\"', "'") : '',
	// 			value?.time ? `${formatDate(value.time)} ${formatDate(value.time, value.time)}` : ''
	// 		];
	// 		row_values = [...row_values, column_value];
	// 	});
	// 	setCsv_data([headers, ...row_values]);
	// }, [columns, data]);

	return (
		<GlobalDialog
			open={open}
			onClose={closeModal}
			title="User Access/Permissions"
			maxWidth="xl"
			fullWidth
			customActions={
				<DialogActions>
					<Grid item>
						<Button variant="contained" onClick={closeModal} fullWidth>
							{setLanguageText("Cancel")}
						</Button>
					</Grid>
					<Grid item>
						{/* <CSVLink
							data={csv_data}
							filename={`${csv_filename}.csv`}
						>
							<Button
								onClick={() => create_csv_data()}
								variant="contained"
							>
								Download CSV
							</Button>
						</CSVLink> */}
					</Grid>
				</DialogActions>
			}
		>
			<UserTableSubComponent details={details}  permissions={permissions} roles={roles} />
			{/* <UserTable
				details={details}
				settings={settings}
				permissions={permissions}
				isFetching={isFetching}
			/> */}
		</GlobalDialog>
	)
};


export default ActivityDialog;
