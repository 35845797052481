import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Button, Box } from '@mui/material';
import EntryStepper from './stepper/EntryStepper';
import checkCustomAttributes from '../helpers/checkCustomAttributes';
import { SelectionsContext } from '../store/SelectionsContext';
import { UserContext } from '../../store/UserContext';
import config from '../../config';
import { MachineContext } from '../store/MachineContext';
import LoadingButton from '../buttons/LoadingButton';
import { faArrowRight, faExclamationTriangle, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import MobileStepper from '@mui/material/MobileStepper';
import Alerts from './Alerts';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from "../../users/activity/useActivityLog";
import GlobalDialog from '../../reusable/GlobalDialog';
import DialogLoader from '../../reusable/DialogLoader';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import useMachineEntry from '../../hooks/useMachineEntryRefresh';

//create steps
function getSteps(attributes) {
    let steps = [
        setLanguageText('Select Machine'),
        setLanguageText('Standard Values'),
        setLanguageText('Bacteria & Fungi'),
        setLanguageText('Notes'),
        setLanguageText('Additives')
    ];

    //TODO: allow option to hide bacteria and fungi
    const has_custom_attributes = checkCustomAttributes(attributes);
    if (has_custom_attributes) {
        steps.splice(2, 0, setLanguageText('Custom Attributes'));
    }
    return steps;
}

export default function AddEntry({ isDialog, setIsDialog }) {

    const { refetchMachineEntries } = useMachineEntry()
    const { addNewEntry } = useContext(SelectionsContext);
    const { user } = useContext(UserContext);
    const [activeStep, setActiveStep] = useState(0);
    const [compiledForm, setCompiledForm] = useState({});
    const [steps, setSteps] = useState([]);
    const [continueNext, setContinueNext] = useState(true);
    const [errors, setErrors] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);
    const { updateActivityLog } = useActivityLog();

    //build steps
    useEffect(() => {
        if (addNewEntry.company) {
            setSteps(getSteps(addNewEntry.company.company_attributes));
            //reset entry
        }
    }, [addNewEntry.company]);

    //check for custom attributes
    const has_custom_attributes = useMemo(() => addNewEntry.company &&
        checkCustomAttributes(addNewEntry.company.company_attributes)
        , [addNewEntry.company]);

    const handleNext = () => {
        //console.log(continueNext)
        if (!continueNext) {
            setShowWarning(true);
            setContinueNext(true);
        } else {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            let canContinue = true;
            //if you are at the end of the form
            if (activeStep === steps.length - 1) {
                //submit and stop form going any further
                handleSubmit();
                canContinue = false;
            }
            if (canContinue) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const closeModal = () => {
        setActiveStep(0);
        setCompiledForm({});
        setIsDialog();
    };

    //save QR code
    const addQRCode = useMutation({
        mutationKey: ['qr'],
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'qr',
            api_key: user.api_key,
            method: 'patch',
            body: body
        })
    })

    //save new machine entry
    const addMachineEntry = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'post',
            body: {
                "new_entry": true,
                ...body
            }
        }),
        onSuccess: (response) => {

            if (response) {
                //console.log(addNewEntry)
                if (addNewEntry.qr_codes && response.insertId) {

                    if (addNewEntry.qr_codes.length > 0) {
                        //add qr code if they exist
                        addQRCode.mutate({
                            "new_entry": true,
                            "qr_codes": addNewEntry.qr_codes,
                            "entry_id": response.insertId,
                            "machine_id": addNewEntry.machine.machine_id,
                            "user_id": user.user.user_id,
                            "app": config.APPNAME
                        })
                    }
                }

                let today = new Date(Date.now())
                //set 1 day before
                let dateTo = new Date(today).setHours(today.getHours() + 1);
                let dateFrom = new Date(today).setMonth(today.getMonth() - 12);
                refetchMachineEntries.mutate({ "dateFrom": dateFrom, "dateTo": dateTo })

                //log activity
                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        machine_id: addNewEntry.machine.machine_id,
                        entry_id: response.insertId
                    },
                    activity_type: "new entry"
                })
                closeModal()
            }
        }
    })

    //save new machine entry
    const handleSubmit = async e => {
        //set out of control
        let outOfControl = await addNewEntry.entry.attributes_new.some(item => item.attribute_values.status === 30);
        addNewEntry.entry.out_of_control = outOfControl
        //remove previous entry id
        addNewEntry.entry.entry_id = null
        addMachineEntry.mutate(addNewEntry.entry)
    };

    const setError = (error) => {
        if (error) {
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            if (errors.length) {
                let index = errors.findIndex((err) => err.attr_id === error.attr_id)
                if (index !== -1) {
                    errors[index] = error
                } else {
                    errors.push(error)
                }
            } else {
                errors.push(error)
            }
            //console.log(errors);
            setErrors(errors);
            //hide show errrors
            errors.forEach((err) => {
                if (err.type === 'error') {
                    setShowError(true);
                }
                if (err.type === 'warning') {
                    //setShowWarning(true);
                    setContinueNext(false);
                }
            })
        }
    }
    //console.log(addNewEntry)

    return (
        <GlobalDialog
            open={isDialog}
            onClose={closeModal}
            fullWidth
            disabled={activeStep !== steps.length - 1}
            maxWidth="md"
            title={"Add New Machine Entry"}
            customActions={
                <MobileStepper
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                        width: '100%',
                        background: 'none',
                        padding: 0
                    }}
                    nextButton={
                        <LoadingButton
                            icon={
                                activeStep === steps.length - 1 ? faPaperPlane :
                                    showWarning ? faExclamationTriangle
                                        : faArrowRight
                            }
                            text={
                                activeStep === steps.length - 1 ? setLanguageText("Submit Entry") :
                                    showWarning ? setLanguageText("Continue Anyway?")
                                        : setLanguageText("Next")
                            }
                            fullWidth={false}
                            iconSize="1x"
                            color="primary"
                            disabled={showError}
                            onClick={handleNext}
                        />
                    }
                    backButton={
                        <Button
                            disabled={showError || activeStep === 0}
                            onClick={handleBack} >
                            {setLanguageText("Back")}
                        </Button >
                    }
                />
            }
        >
            <Box sx={{ width: '100%' }}>
                <EntryStepper
                    edit={false}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    has_custom_attributes={has_custom_attributes}
                    compiledForm={compiledForm}
                    setError={setError}
                    errors={errors}
                    continueNext={continueNext}
                />
                <Alerts errors={errors} showWarning={showWarning} showError={showError} />
            </Box>
            {addMachineEntry.isLoading || addMachineEntry.isSuccess || addMachineEntry.isError ?
                <DialogLoader
                    isLoading={addMachineEntry.isLoading}
                    mutation={addMachineEntry}
                    loadingMessage="Adding Machine Entry..."
                    successMessage="Entry Added"
                    fixed
                />
                : null}
        </GlobalDialog >
    );
};
