import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, Grid, useMediaQuery, useTheme, CardHeader, CardContent, Card, Typography, DialogTitle, DialogContent, DialogActions, Skeleton, Box } from "@mui/material";
import React, { useState } from "react";
import _ from "lodash";
import setLanguageText from "../language/setLanguageText";

const MobileDrawWrapper = ({ children, resetFilters, isFetching, categories }) => {

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState()

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12}>
            {mdDown ?
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleDrawerOpen}
                    >
                        {setLanguageText("Filters")}
                    </Button>
                    <Drawer
                        variant="temporary"
                        // onBackdropClick={handleDrawerClose}
                        anchor="left"
                        open={open}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            '& .MuiDrawer-paperAnchorLeft': {
                                width: '80%',
                            }
                        }}
                    >
                        <DialogTitle
                            sx={{
                                borderBottom: '1px solid',
                                borderColor: 'primary.dark',
                                backgroundColor: 'primary.main',
                            }}
                            disableTypography
                        >
                            <Typography
                                sx={{
                                    color: 'primary.contrastText',
                                    fontWeight: 400,
                                    textTransform: 'uppercase'
                                }} >
                                Filters
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers={scroll === 'paper'} sx={{ padding: 0, position: 'relative' }}>

                            {children}
                            {isFetching && !categories ?
                                <Box sx={{ padding: '1em' }} >
                                    <Grid item xs={12}>
                                        <Skeleton width="30%" variant="text" animation="wave" />
                                        <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                        <Skeleton width="30%" variant="text" animation="wave" />
                                        <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                        <Skeleton width="30%" variant="text" animation="wave" />
                                        <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                        <Skeleton width="30%" variant="text" animation="wave" />
                                        <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                    </Grid>
                                </Box>
                                : null}

                        </DialogContent>
                        <DialogActions sx={{ background: (theme) => theme.palette.grey[200], padding: '1em' }} >
                            <Grid container item sx={12}>
                                {resetFilters ?
                                    <Grid item xs={6}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={resetFilters}
                                        >
                                            {setLanguageText("Clear Filters")}
                                        </Button>
                                    </Grid>
                                    : null}
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDrawerClose}
                                        startIcon={
                                            <FontAwesomeIcon
                                                icon={faFilter}
                                                color={'primary.contrastText'}
                                            />
                                        }
                                    >
                                        {setLanguageText("Close Filters")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Drawer>
                </>
                :
                <Card>
                    <CardHeader
                        sx={{
                            borderBottom: '1px solid',
                            borderColor: 'primary.dark',
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            fontSize: "1.25em",
                            padding: '10px 16px',
                            fontWeight: 400,
                            textTransform: 'uppercase',
                            borderRadius: '0px'
                        }}
                        disableTypography
                        title="Filters"
                    />
                    <CardContent
                        sx={{
                            backgroundColor: "cards.main",
                            padding: 0,
                            paddingBottom: '0!important'
                        }}
                    >

                        {children}
                        {isFetching && !categories ?
                            <Box sx={{ padding: '1em' }} >

                                <Skeleton width="30%" variant="text" animation="wave" />
                                <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                <Skeleton width="30%" variant="text" animation="wave" />
                                <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                <Skeleton width="30%" variant="text" animation="wave" />
                                <Skeleton width="100%" height={80} variant="text" animation="wave" />
                                <Skeleton width="30%" variant="text" animation="wave" />
                                <Skeleton width="100%" height={80} variant="text" animation="wave" />

                            </Box>
                            : null}

                        {resetFilters ?
                            <Box sx={{ padding: '1em' }} >

                                <Button
                                    color="secondary"
                                    variant="contained"
                                    sx={{ marginTop: '1em' }}
                                    onClick={resetFilters}
                                >
                                    {setLanguageText("Clear Filters")}
                                </Button>

                            </Box>
                            : null}
                    </CardContent>
                </Card>
            }
        </Grid >
    )
}

export default MobileDrawWrapper;