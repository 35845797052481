import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardActions, Dialog, DialogTitle, Divider, Grid, IconButton, Skeleton, useTheme } from '@mui/material';
import AddCadToBasket from "./AddCadToBasket";
import BrandLogo from "../shop/reusable/BrandLogo";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { CategoryContext } from "../store/CategoryContext";
import ProductPopup from "../products/cim/ProductPopup";
import ProductFullList from "../products/cim/ProductFullList";

const CadItemGrid = ({ product, isFetching, visible }) => {


	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [photo, setPhoto] = useState()
	const [diagram, setDiagram] = useState()
	const [catImage, setCatImage] = useState()
	const [graphic3D, setGraphic3D] = useState()
	const { categories } = useContext(CategoryContext)

	//console.log(product)
	useEffect(() => {

		if (Object.values(product.Graphics).length) {
			let photo = product.Graphics.find(item => item.Type === "Photo")
			let diagram = product.Graphics.find(item => item.Type === "SchematicDiagram")
			let graphic3D = product.Graphics.find(item => item.Type === "Detail3DModel")
			if (photo) {
				setPhoto(photo.URL)
			} else {
				setPhoto()
			}
			if (diagram) {
				setDiagram(diagram.URL)
			} else {
				setDiagram()
			}
			if (graphic3D) {
				setGraphic3D(graphic3D.URL)
			} else {
				setGraphic3D()
			}
		} else {
			//reset
			setPhoto()
			setDiagram()
			setGraphic3D()
		}

		let cat = categories.flatCats.find(category => category.ID === product.Category)
		if (cat?.Image) {
			//console.log(cat)
			setCatImage(cat.Image)
		} else {
			setCatImage()
		}

	}, [product, categories])

	//console.log(photo)
	//console.log(diagram)

	return (
		<>
			<Card sx={{
				position: 'relative',
				//display: 'flex',
				margin: '0 auto',
				maxWidth: '1200px'
			}} >
				{!isFetching || !product.Graphics ?
					<>
						{(photo || diagram || catImage || graphic3D) ?
							<CardMedia
								component="img"
								image={photo ? photo : diagram ? diagram : catImage ? catImage : graphic3D}
								alt=""
								sx={{ objectFit: "contain", padding: 2, background: '#fff', cursor: "pointer", width: 200, height: 200 }}
								onError={e => {
									setPhoto()
									setDiagram()
									setGraphic3D()
									//use CatImage

								}}
							//onClick={handleOpen}
							/>
							:
							<Box
								//onClick={handleOpen}
								sx={{ cursor: 'pointer', width: 200, height: 200, textAlign: 'center', background: '#fff' }}>
								<FontAwesomeIcon size="5x" icon={faImageSlash} color="#ccc" style={{ margin: '.5em auto' }} />
							</Box>
						}
					</> :
					<Skeleton variant="rectangular" width={'100%'} height={140} />
				}
				<CardActionArea
					//onClick={handleOpen}
					sx={{
						display: 'block',
						height: '100%'
					}}
				>
					<CardContent
						sx={{
							background: (theme) => theme.palette.background.default,
							borderColor: (theme) => theme.palette.divider,
							borderWidth: '0 1px 0 1px',
							borderStyle: 'solid',
							width: '100%',
							flexDirection: 'row-reverse',
							display: "grid",
							height: "100%",
							alignContent: "start"
						}}
					>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography gutterBottom variant="h6" fontWeight={700} component="div">
									{isFetching ? <Skeleton /> : product.Name}
								</Typography>
							</Grid>
							<Grid item>
								<Box sx={{
									width: '120px',
									height: '60px',
									overflow: 'hidden',
									borderBottom: '1px solid #eeeeee29',
									borderLeft: '1px solid #eeeeee29',
									padding: '5px',
									background: '#ffffff60',
									borderRadius: '0 0 0 10px',
									cursor: 'pointer'
								}}
								//onClick={handleOpen}
								>
									{!isFetching && <BrandLogo companyID={product.CompanyCode} />}
								</Box>
							</Grid>
						</Grid>
						{isFetching ?
							<>
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</>
							: visible?.Fields ?
								<Grid>
									<ProductFullList rows={visible.Fields} product={product} isFetching={isFetching} list />
								</Grid>
								: null}
					</CardContent>
				</CardActionArea>
				<CardActions sx={{ background: (theme) => theme.palette.cards.footer, paddin: '1em' }}>
					{isFetching ?
						<Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
						:
						<AddCadToBasket isFetching={isFetching} product={product} item />
					}
				</CardActions>
			</Card>
			{open && product &&
				<Dialog
					fullWidth
					maxWidth="md"
					open={open}
					onClose={handleClose}
				>
					<IconButton
						sx={{
							cursor: 'pointer',
							position: 'absolute',
							right: 8,
							top: 8,
							background: (theme) => theme.palette.background.default,
							padding: '10px 15px'
						}}
						onClick={handleClose}
					>
						<FontAwesomeIcon
							aria-label="close"
							sx={{
								color: (theme) => theme.palette.grey[500],
							}}
							icon={faTimes}
						/>
					</IconButton>
					<ProductPopup productDetails={product} closeDialog={handleClose} />
				</Dialog>
			}
		</>
	);
}


export default CadItemGrid;