import React, { useContext } from "react";
import CadItemList from "./CadItemList";
import { ProductsContext } from "../store/ProductsContext";
import { Grid } from "@mui/material";
import ProductLoadingCard from "../shop/products/ProductLoadingCard";

const CadProductList = ({ isFetching , visible}) => {

    const { visible : visibleProducts } = useContext(ProductsContext);

    return (
        <Grid container item spacing={2}>
            {Object.values(visibleProducts.visibleProducts).length ?
                visibleProducts.visibleProducts.map((product, i) =>
                    <Grid key={i} item xs={12} >
                        <CadItemList product={product} isFetching={isFetching} visible={visible} />
                    </Grid>
                ) : isFetching ?
                    <>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} >
                            <ProductLoadingCard />
                        </Grid>
                    </>
                    : null
            }
        </Grid>
    );
};

export default CadProductList;

