import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import { CompanyContext } from "../../../store/CompanyContext";
import axiosRequest from '../../../axios/axoisRequest';
import Prices from "../../shared/Prices";
import useGetPrices from "../../../hooks/useGetPrices";

const ProductPrices = ({ product, large, setErpDetails, value, setValue, table , isFetching}) => {

    //console.log('product', product)

    // const { user } = useContext(UserContext)
    // const { selectedCompany } = useContext(CompanyContext);
    // const [prices, setPrices] = useState()
    // const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    // const CISstoredToken = sessionStorage.getItem('CIStoken');
    //const [erpCode, setErpCode] = useState()

    // useEffect(() => {
    //     if (product?.product_id) {
    //         setErpCode(current => {
    //             return ({
    //                 ...current,
    //                 "product_code": product?.product_code,
    //                 "product_id": product?.product_id,
    //                 "product_supplier": product?.product_supplier
    //             })
    //         })
    //     }
    // }, [product])

    // const { data, isFetching } = useQuery({
    //     queryKey: ['prices', erpCode?.product_id, selectedCompany?.company_erp?.AccountNumber],
    //     queryFn: ({ signal }) => {
    //         let params = {
    //             "supplier": erpCode?.product_supplier,
    //             "account": selectedCompany?.company_erp.AccountNumber,
    //             "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
    //         }
    //         if (erpCode?.product_code) {
    //             params = {
    //                 ...params,
    //                 "code": erpCode.product_code
    //             }
    //         }
    //         if (erpCode?.product_id) {
    //             params = {
    //                 ...params,
    //                 "id": erpCode.product_id
    //             }
    //         }
    //         return (
    //             axiosRequest({
    //                 signal: signal,
    //                 gateway: config.opensearchAPI.URL,
    //                 endpoint: 'tools-search/price',
    //                 api_key: user.api_key,
    //                 method: 'get',
    //                 params: params
    //             })
    //         )
    //     },
    //     enabled: !!erpCode?.product_id && !!selectedCompany?.company_erp?.AccountNumber && (!!ACTstoredToken || !!CISstoredToken),
    //     retry: 1,
    // })

    // useEffect(() => {

    //     if (data) {
    //         //ACT TOKEN ERROR
    //         if (data?.ErrorMessage === "Invalid bearer token.") {
    //             //console.log('invalid token')
    //             sessionStorage.removeItem('ACTtoken');
    //             window.location.reload();

    //         }
    //         //any other errors
    //         else if (data?.errorMessage || data?.ErrorMessage) {
    //             //console.log(data?.errorMessage)
    //             //reset erp details
    //             if (setValue) {
    //                 setValue()
    //             }
    //             setPrices()
    //             if (setErpDetails) {
    //                 setErpDetails()
    //             }

    //             if (product.product_rrp) {
    //                 const priceObject = {
    //                     "error": data?.errorMessage || data?.ErrorMessage,
    //                     "price_rrp": parseFloat(product.product_rrp),
    //                     "price_incl_discount": parseFloat(product.product_rrp),//no discount
    //                     "price_discount": 0,
    //                     "product_unit": product?.product_packet_size ? parseInt(product.product_packet_size) : 1,
    //                     "product_stock": 0,
    //                     "product_free_stock": 0,
    //                     "product_erp_code": product?.product_id,
    //                     "product_external_delivery": product?.product_external_delivery,
    //                     "product_local_delivery": product?.product_local_delivery
    //                 }
    //                 setPrices(priceObject)
    //                 if (setValue) {
    //                     setValue(product?.product_packet_size ? parseInt(product.product_packet_size) : 1)
    //                 }
    //                 if (setErpDetails) {
    //                     setErpDetails(priceObject)
    //                 }
    //             }

    //         } else {
    //             const priceObject = {
    //                 "product_id": data?.ArticleNumber, //need this for favourites
    //                 "price_rrp": data?.PriceRRP,
    //                 "price_incl_discount": data?.PriceInclDiscount,
    //                 "price_discount": data?.PriceDiscount,
    //                 "product_unit": product?.product_packet_size ? parseInt(product.product_packet_size) : data?.Unit,
    //                 "product_stock": data?.TotalStock,
    //                 "product_free_stock": data?.FreeStock,
    //                 "product_erp_code": data?.ArticleNumber,
    //                 "product_erp": data?.FullProduct,
    //                 "product_external_delivery": product?.product_external_delivery,
    //                 "product_local_delivery": product?.product_local_delivery
    //             }
    //             setPrices(priceObject)
    //             if (setErpDetails) {
    //                 setErpDetails(priceObject)
    //             }
    //             if (setValue) {
    //                 setValue(product?.product_packet_size ? parseInt(product.product_packet_size) : data?.Unit ? data?.Unit : 1)
    //             }
    //         }
    //     }
    //     return () => {
    //         //cleanup
    //         // setPrices()
    //         // if (setErpDetails) {
    //         //     setErpDetails()
    //         // }
    //         // if (setValue) {
    //         //     setValue()
    //         // }
    //     }
    // }, [data, product])

    return (
        <>
            <Prices
                product={product}
                prices={product}
                isFetching={false}
                table={table}
                large={large}
                value={value}
            />
        </>

    );
};

export default ProductPrices;
