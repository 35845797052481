const checkCustomAttributes = attributes => {
  const has_custom_attributes = attributes?.every(
    attribute =>
      attribute.one.attr_id === 'conc' ||
      attribute.one.attr_id === 'ph' ||
      attribute.one.attr_id === 'tramp_oil' ||
      attribute.one.attr_id === 'bacteria' ||
      attribute.one.attr_id === 'fungi'
  );
  return !has_custom_attributes;
};
export default checkCustomAttributes;
