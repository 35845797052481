import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../store/MachineContext';
import Alert from '@mui/material/Alert';
import { VOID_ENTRY } from '../store/MachineReducers';
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import { useMutation } from '@tanstack/react-query';
import DialogLoader from '../../reusable/DialogLoader';
import config from '../../config';
import useMachineEntry from '../../hooks/useMachineEntryRefresh';

const VoidEntry = ({
	open,
	handleClose,
	handleVoidedClose,
	copyEntry
}) => {

	const { refetchMachineEntries } = useMachineEntry()
	const { user } = useContext(UserContext);
	const { dispatchSelectedMachine, selectedMachine } = useContext(MachineContext);
	const { updateActivityLog } = useActivityLog();
	const [voidReason, setVoidReason] = useState('');
	const [selectedReason, setSelectedReason] = useState(0);
	const [presetReasons] = useState([
		{ value: 0, reason: 'Custom Reason' },
		{ value: 1, reason: 'Input Error' },
		{ value: 2, reason: 'Wrong Machine' },
		{ value: 3, reason: 'Wrong Date' }
	]);

	const handleSelectReason = e => {
		setSelectedReason(e.target.value);
		!e.target.value
			? setVoidReason('')
			: setVoidReason(presetReasons[e.target.value].reason);
	};

	const handleTyping = e => {
		e.persist();
		setVoidReason(e.target.value);
		setSelectedReason(0);
	};

	//save new machine entry
	const voidMachine = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response) => {

			if (response) {

				const todayDate = new Date().toISOString();

				updateActivityLog.mutate({
					activity_details: {
						area: "coolant",
						entry_id: copyEntry.entry_id,
						void_reason: voidReason,
						machine_id: copyEntry.original.machine_id
					},
					activity_type: "void entry"
				})

				dispatchSelectedMachine({
					type: VOID_ENTRY,
					payload: {
						entry_id: copyEntry.original.entry_id,
						void: { date: todayDate, reason: voidReason, user_id: user.id, user_name: user.name }
					}
				});
				// //refetch all macine entries 
				// let lastEntry = new Date(selectedMachine?.last_entry?.date_stamp)
				// let lastYear = new Date(selectedMachine?.last_entry?.date_stamp).setMonth(lastEntry.getMonth() - 12);
				// refetchMachineEntries.mutate({ "lastEntry": lastEntry, "lastYear": lastYear })
			}
		}
	})

	const handleSubmit = () => {
		const todayDate = new Date().toISOString();
		voidMachine.mutate({
			toVoid: true,
			date: todayDate,
			reason: voidReason,
			user_id: user.id,
			user_name: user.name,
			entry_id: copyEntry.original.entry_id
		});
	};

	return (
		<GlobalDialog
			open={open}
			onClose={handleClose}
			title="Void Entry"
			buttonClick={handleSubmit}
			buttonTitle="Void"
			successMessage="Entry voided successfully!"
			fullWidth
			maxWidth="sm"
		>
			<Grid container spacing={3}>
				<Grid item>
					<Alert severity="error">
						Entries can not be deleted. Only voided. Voided entries will not display on graphs/exports but will still be available to view
					</Alert>
				</Grid>
				<Grid item container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="preset-reason">Preset Reason</InputLabel>
							<Select
								labelId="preset-reason"
								onChange={handleSelectReason}
								value={selectedReason}
								label={setLanguageText("Preset Reason")}
							>
								{presetReasons.map(reason => (
									<MenuItem key={reason.value} value={reason.value}>
										{reason.reason}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							rows={2}
							multiline
							required
							variant="outlined"
							label={setLanguageText("Void Reason")}
							value={voidReason}
							fullWidth
							onChange={handleTyping}
						/>
					</Grid>
				</Grid>
			</Grid>
			{voidMachine.isLoading || voidMachine.isSuccess || voidMachine.isError ?
				<DialogLoader
					isLoading={voidMachine.isLoading}
					mutation={voidMachine}
					loadingMessage="Void Machine Entry..."
					successMessage="Entry Voided"
					closeDialog={handleVoidedClose}
					fixed
				/>
				: null}
		</GlobalDialog>
	);
};

export default VoidEntry;
