import { makeStyles } from '@mui/styles';


export const buttonStyles = makeStyles(theme => {
  return {
    hideCheckbox: {
      display: 'none'
    },
    fixPointerEvent: {
      // when click the button, will refer to the button not on what we clicked in the button
      '& > *': {
        pointerEvents: 'none'
      }
    },
    box: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      margin: 0,
      width: '100%',
      height: '100%',
      // when click the button, will refer to the button not on what we clicked in the button
      '& > *': {
        pointerEvents: 'none'
      }
    },
    button: {
      borderRadius: theme.spacing(0.5),
      textDecoration : 'none',
      textTransform: 'inherit',
      margin: 0,
      width: '100%',
      height: '100%',
      '& > *': {
        pointerEvents: 'none'
      }
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
      svg: {
        backgroundColor: theme.palette.primary.contrastText
      }
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.warning.dark
      }
    },
    danger: {
      backgroundColor: theme.palette.error.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    grey: {
      backgroundColor: 'rgb(240, 240, 240)',
      color: '#222',
      '&:hover': {
        backgroundColor: 'rgb(218 218 218)'
      }
    },
    buttonText: {
      textDecoration: 'none'
    },
    buttonUppercase: {
      textTransform: 'uppercase'
    },
    icon_sm: {
      fontSize: theme.spacing(2)
    },
    icon_md: {
      fontSize: theme.spacing(2.5)
    },
    icon_lg: {
      fontSize: theme.spacing(3.25)
    },
    buttonIconText: {
      marginLeft: theme.spacing(1)
    }
  };
});
