import React, {
    useContext,
    useState,
} from "react";
import ProductLayout from "../../shop/products/ProductLayout";
import ProductOptions from "../../products/shared/ProductOptions";
import { Backdrop, Grid, useMediaQuery } from "@mui/material";
import { ProductsContext } from "../../store/ProductsContext";
import MatchingProduct from "../../shop/products/matching/MatchingProduct";
import { isMobileOnly } from "react-device-detect";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";
import useProductObject from "../../hooks/useProductObject";
import useProductAttrbuites from "../../hooks/useProductAttributes";
//import useCreateParametricFilters from "../hooks/useCreateParametricFilters";
import PageContent from "../../coolantcare/PageContent";
import InnerHeaderWrap from "../../layout/InnerHeaderWrap";
import GlobalCompanySelect from "../../reusable/GlobalCompanySelect";
import SearchBar from "../../search/SearchBar";


export default function ProductsMatching(props){

    const { products } = useContext(ProductsContext);
    const [value, setValue] = useState('')
    const [focused, setFocused] = useState(false)
    const { attributes, setAttributes, detailProfile } = useProductAttrbuites();
    const { isFetching } = useProductObject();
    //useCreateParametricFilters(detailProfile)

    const closeFilters = (e) => {
        setFilterShow(e);
    };

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <InnerHeaderWrap>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={3}>
                        <GlobalCompanySelect fullWidth large />
                    </Grid>
                    <Grid item xs>
                        <SearchBar
                            setFocused={setFocused}
                            setValue={setValue}
                            value={value}
                            link={true}
                            large
                        />
                    </Grid>
                    {/* {isMobileOnly ? null :
                        <Grid item xs={12} md={3} >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                //textAlign="right"
                                sx={{
                                    fontWeight: 600
                                }}
                            > Welcome back, {user?.user?.user_details?.name}</Typography>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                    fontWeight: 400
                                }}
                            ><strong>Jump Back in :</strong> </Typography>
                        </Grid>
                    } */}
                </Grid>
            </InnerHeaderWrap>
            <PageContent>  
                <Grid container item spacing={isMobileOnly ? 1 : 2} alignItems="flex-start" >
                    <Grid container spacing={isMobileOnly ? 1 : 2} item xs={12} md={4} lg="auto"
                        sx={{
                            width: lgUp ? '400px!important' : 'auto',
                            maxWidth: lgUp ? '400px!important' : '100%'
                        }}>

                        <MatchingProduct attributes={attributes} />

                        {/* <Grid item xs={12} >
                            <MatchingFilters closeFilters={closeFilters} />
                        </Grid> */}
                    </Grid>
                    <Grid item container spacing={isMobileOnly ? 1 : 2} xs={12} md={8} lg>
                        {products.noProducts ? (
                            <ProductsNotAvailable />
                        ) :
                            <>
                                <ProductOptions isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                <ProductLayout isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                            </>
                        }
                    </Grid>
                </Grid>
                <Backdrop
                    sx={{
                        position: 'absolute',
                        color: '#fff',
                        zIndex: 3,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        alignItems: 'flex-start',
                        padding: '1em 2em',
                        //backdropFilter: 'blur(10px)',
                        overflow: 'auto',
                    }}
                    open={focused}
                />
                </PageContent>  
        </>
    );
};

