import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import PDFviewer from '../pdf/PDFviewer';
import GlobalDialog from '../../reusable/GlobalDialog';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProductPDF({ product }) {

    const [open, setOpen] = useState(false);
    const [path, setPath] = useState();
    const { language } = useContext(LayoutContext);

    const handleOpen = (path) => {
        setPath('global_coolants/' + path);
        setOpen(true);
    }

    return (
        <>
            <Grid item container spacing={2} justifyContent="space-between" >
                {product?.product_uploads?.length ? product?.product_uploads?.map((upload, index) => {
                    if (upload?.name) {
                        return (
                            <Grid item key={index}>
                                <Button
                                    disabled={upload?.language?.includes(language?.id) ? false : true}
                                    variant='contained'
                                    color='primary'
                                    onClick={e => handleOpen(`${product?.product_id}_${language?.id}_${upload?.name}.${upload?.type}`)}
                                    startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                                >
                                    {setLanguageText(upload?.label)}
                                </Button>
                            </Grid>
                        )
                    }
                }) : null}
            </Grid>

            <GlobalDialog
                title={product.product_name}
                open={open}
                onClose={e => setOpen(false)}
                fullWidth
                fullHeight
                maxWidth="xl"
                noPadding
            >
                {(path && open) ? <PDFviewer path={path} /> : null}
            </GlobalDialog>
        </>
    );
};

