import { Box, Fade, Grid, Paper, Popover, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

const ColourPicker = ({ name, colourMode, parentName, fieldName, fieldValue, handleChange }) => {

    const [localColor, setLocalColor] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        if (fieldValue) {
            setLocalColor(fieldValue)
        }
    }, [fieldValue])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        handleChange(colourMode, parentName, fieldName, localColor)
        setAnchorEl(null);

    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const changeColor = (color) => {
        setLocalColor(color)
        //handleChange(colourMode, parentName, fieldName, color)
    }

    return (
        <Grid item xs={12} md={6} xl={4} >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{
                        borderWidth: '1px',
                        borderColor: colourMode === 'light' ? '#222' : '#FFF',
                        borderStyle: 'solid',
                        backgroundColor: localColor,
                        width: '45px',
                        height: '35px',
                        borderRadius: '5px',
                        marginRight: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={handleClick}
                />
                <TextField
                    //variant="standard"
                    autoComplete='off'
                    id="color"
                    label={name}
                    value={localColor}
                    onChange={e => {
                        //console.log(e.target.value)
                        changeColor(e.target.value)
                        handleChange(colourMode, parentName, fieldName, e.target.value)
                    }}
                    sx={{
                        input: {
                            color: colourMode === 'light' ? '#222' : '#FFF',
                            //borderWidth: '1px',
                            //borderColor:  colourMode === 'light' ? '#222' : '#FFF',
                            //borderStyle : 'solid',
                            //borderRadius : '5px'
                        },
                        label: {
                            color: colourMode === 'light' ? '#222' : '#FFF',
                        },
                        fieldset: {
                            borderColor: colourMode === 'light' ? '#222' : '#FFF',
                        },
                        '.MuiInputBase-root:hover fieldset': {
                            borderColor: colourMode === 'light' ? '#222' : '#FFF',
                        },
                        width: '100%',
                        '& legend': {
                            color: colourMode === 'light' ? '#222' : '#FFF',
                        }
                    }}
                    size="small"
                />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Fade in={open} >
                    <Paper sx={{ padding: '2em' }}>
                        <HexColorPicker color={localColor} onChange={changeColor} />
                    </Paper>
                </Fade>
            </Popover>
        </Grid>
    );
}


export default ColourPicker;
