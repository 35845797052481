import {
    Alert,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { UserContext } from '../../../../store/UserContext';
import MyDatePicker from '../../../../coolantcare/Machines/MachineDetails/MyDatePicker';
import { Autocomplete } from '@mui/material';
import setLanguageText from '../../../../language/setLanguageText';
import { CompanyContext } from '../../../../store/CompanyContext';
import { useMutation, useQuery } from 'react-query';
import config from '../../../../config';
import axiosRequest from '../../../../axios/axoisRequest';
import ActivityDialog from './ActivityDialog';
import { LoadingButton } from '@mui/lab';

const ActivityReport = () => {

    const { user } = useContext(UserContext);
    const [users, setUsers] = useState();
    const { selectedCompany } = useContext(CompanyContext);
    const [dateFrom, setDateFrom] = useState(); // from Midnight
    const [from, setFrom] = useState(new Date().setHours(0, 0, 0, 0)); // from Midnight
    const [dateTo, setDateTo] = useState(); // to current time
    const [to, setTo] = useState(new Date().getTime()); // to current time
    const [selectedUser, setSelectedUser] = useState(null);
    const [data, setData] = useState();
    const [activityTypes, setActivityTypes] = useState([]);
    const [activityType, setActivityType] = useState({ activity_type: 'all activities' });
    const [open, setOpen] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    //get database users
    const get_users = useMutation((id) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'administrator/allusers',
            api_key: user.api_key,
            method: 'get',
            params: {
                "company_id": id
            }
        })
    }, {
        onSuccess: (data) => {
            if (!data.errerMessage) {
                setUsers(data)
            }
        }
    })

    useEffect(() => {
        if (selectedCompany.company_id) {
            get_users.mutate(selectedCompany.company_id)
        }
    }, [selectedCompany])


    useEffect(() => {
        if (to) {
            //console.log('to')
            //console.log(new Date(parseInt(to)).toISOString())
            //midnight the day before
            setDateTo(new Date(to).setHours(24, 0, 0, 0))
            //console.log(new Date(to).setHours(24, 0, 0, 0))
        }
        if (from) {
            //console.log('from')
            //console.log(new Date(parseInt(from)).toISOString())  
            //current day at midnight
            setDateFrom(new Date(from).setHours(0, 0, 0, 0))
            //console.log(new Date(from).setHours(0, 0, 0, 0))
        }
    }, [to, from])

    const { data: types, isFetching } = useQuery(['types'],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'activity/logging',
            api_key: user.api_key,
            method: 'get',
            params: {
                get_types: true
            }
        }), {
        retry: 1
    })

    useEffect(() => {
        if (types) {
            setActivityTypes([{ activity_type: 'all activities' }, ...types])
        }
    }, [types])

    const getActivity = useMutation((params) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'activity/logging',
            api_key: user.api_key,
            method: 'get',
            params: params
        })
    }, {
        onSuccess: (response) => {

            if (response.errorMessage) {

                setError(response.errorMessage)
                setLoading(false)
            } else {
                console.log(response)
                setData(response)
                setOpen(true)
                setLoading(false)
            }
            getActivity.reset()
        }
    })

    const runReport = useCallback(() => {
        setLoading(true)
        let params = {
            "date_from": new Date(parseInt(dateFrom)).toISOString(),
            "date_to": new Date(parseInt(dateTo)).toISOString(),
            "company_id": selectedCompany?.company_id ? selectedCompany?.company_id : '',
        };
        if (selectedUser?.user_id) {
            params = {
                ...params,
                "user_id": selectedUser?.user_id
            }
        }
        if (activityType?.activity_type !== "all activities") {
            params = {
                ...params,
                "activity_type": activityType.activity_type
            }
        }
        getActivity.mutate(params)

    }, [
        selectedUser,
        activityType,
        user,
        selectedCompany,
        dateFrom,
        dateTo,
    ]);

    const selectedHandle = (user) => {
        //console.log(user)
        setSelectedUser(user)
    }

    return (

        <Grid item xs={12}>
            <Card sx={{ background: theme => theme.palette.cards.header }}>
                <CardHeader title={setLanguageText("Company User Activity")} />
                <CardContent
                    sx={{
                        background: (theme) => theme.palette.cards.main,
                        flexDirection: 'row-reverse',
                        display: "grid",
                        height: "100%",
                        alignContent: "start"
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item >
                            <MyDatePicker
                                dateFrom={from}
                                setDateFrom={setFrom}
                                dateTo={to}
                                setDateTo={setTo}
                                size="small"
                            />
                        </Grid>
                        <Grid item >
                            <Autocomplete
                                sx={{ minWidth: 320 }}
                                //disableClearable
                                options={users || []}
                                loading={get_users.isLoading}
                                disabled={!users}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.aws_sub}>
                                        <Typography>
                                            {option.user_details.name} {option?.user_details?.surname ? option?.user_details?.surname : ''}  <br />
                                            {option?.email}
                                        </Typography>
                                    </Box>
                                )}
                                getOptionLabel={
                                    option => `${option.user_details.name}  ${option?.user_details?.surname ? `${option?.user_details?.surname} - ${option?.email}` : ''}` || ''}
                                renderInput={params => (
                                    <TextField {...params} required label={setLanguageText("Users")} variant="outlined" />
                                )}
                                isOptionEqualToValue={(option, value) =>
                                    option.user_id === value.user_id
                                }
                                value={selectedUser}
                                onChange={(e, newValue) => {
                                    selectedHandle(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <Autocomplete
                                disableClearable
                                sx={{
                                    minWidth: 320,
                                    textTransform: 'capitalize'
                                }}
                                options={activityTypes || []}
                                loading={!activityTypes}
                                disabled={!activityTypes}
                                getOptionLabel={option => option.activity_type || ''}
                                isOptionEqualToValue={(option, value) =>
                                    option.activity_type === value.activity_type
                                }
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                        label={setLanguageText("Activity Type")}
                                        variant="outlined"
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                )}
                                value={activityType}
                                onChange={(e, newValue) => {
                                    setActivityType(newValue);
                                }}
                            />
                        </Grid>
                        {error ?
                            <Grid item ><Alert severity="error">{error}</Alert></Grid>
                            : null}
                    </Grid>
                </CardContent>
                <CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={() => runReport()}
                            loading={loading}
                        >
                            Run Activity Report
                        </LoadingButton>
                    </Grid>
                </CardActions>
            </Card>
            {data && open ?
                <ActivityDialog
                    data={data}
                    isFetching={getActivity.isFetching}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    open={open}
                    setOpen={setOpen}
                />
                : null}
        </Grid>
    )
};

export default ActivityReport;
