import React, { useContext, useState, useEffect } from 'react';
import {
	SELECTED_MACHINE,
	SELECTED_MACHINES
} from '../../store/MachineReducers';
import { MachineContext } from '../../store/MachineContext';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../../language/setLanguageText';
import { useNavigate } from 'react-router-dom';

const DropdownMachines = ({ updateURL, removeAll, all }) => {

	const navigate = useNavigate();
	const {
		selectedMachine,
		dispatchSelectedMachine,
		dispatchSelectedMachines,
		filteredMachines
	} = useContext(MachineContext);
	const [options, setOptions] = useState();
	const [storedMachine] = useState(JSON.parse(window.sessionStorage.getItem('selectedMachine')));
	// update with stored group in session storage
	useEffect(() => {
		//console.log(storedMachine)
		dispatchSelectedMachine({
			type: SELECTED_MACHINE,
			payload: storedMachine
		});
	}, [storedMachine, dispatchSelectedMachine]);

	useEffect(() => {
		if (filteredMachines && filteredMachines.length) {
			if (!removeAll) {
				setOptions([{ machine_name: 'All Machines', machine_id: 0 }, ...filteredMachines]);
			} else {
				setOptions(filteredMachines);
			}

			//check if there is a stored machine in session storage
			const storedMachine = JSON.parse(window.sessionStorage.getItem('selectedMachine'));
			const machine = filteredMachines.find(machine => machine.machine_id === storedMachine?.machine_id);
			if (machine) {
				//set stored machine
				dispatchSelectedMachine({
					type: SELECTED_MACHINE,
					payload: storedMachine
				});
			} else {
				if (removeAll) {
					//set to first machine, if all is not allowed
					dispatchSelectedMachine({
						type: SELECTED_MACHINE,
						payload: filteredMachines[0]
					});
					if (updateURL) {
						navigate(`/coolantcare/machine/${filteredMachines[0].machine_id}`);
					}
				} else {
					//set to all machines
					dispatchSelectedMachine({
						type: SELECTED_MACHINE,
						payload: { machine_name: setLanguageText("All Machines"), machine_id: 0 }
					});
					window.sessionStorage.removeItem('selectedGroup')
				}
			}
		}
		else if (filteredMachines && filteredMachines.length === 0) {
			//no machines
			setOptions([])
			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: null
			});
			// if (updateURL) {
			// 	navigate(`/coolantcare/machine/no-machines`);
			// }
		}
	}, [filteredMachines]);

	// dispatch selected machine
	const onSelectMachine = machine => {
		if (machine) {
			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: machine
			});
			//update session storage
			window.sessionStorage.setItem('selectedMachine', JSON.stringify(machine))
			dispatchSelectedMachines({
				type: SELECTED_MACHINES,
				payload: machine
			});
			if (updateURL) {
				navigate(`/coolantcare/machine/${machine.machine_id}`);
			}
		}
	};

	//add machine counter
	const modifiedValue =
		selectedMachine?.machine_id === 0 ? {
			...selectedMachine,
			machine_name: `${selectedMachine.machine_name}`
		} :
			selectedMachine;

	//do once on dashboard load
	useEffect(() => {
		if(all){
			//set to all machines
			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: { machine_name: setLanguageText("All Machines"), machine_id: 0 }
			});
		}
	}, []);

	return (
		<Autocomplete
			disableClearable
			id="selected-machine"
			value={modifiedValue && Object.entries(modifiedValue).length && modifiedValue?.machine_id ? modifiedValue : null}
			options={options || []}
			//loading={modifiedValue && options ? false : true}
			getOptionLabel={option => option.machine_id === 0 ? `${option.machine_name} (${options?.length > 0 ? (options.length - 1) : 0})` : option.machine_name}
			isOptionEqualToValue={(option, value) =>
				option.machine_id === value.machine_id
			}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.machine_id}>
						{option.machine_name}
					</li>
				);
			}}
			onChange={(event, newValue) => {
				onSelectMachine(newValue);
			}}
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					//label={setLanguageText("Selected Machine/s")}
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Machine")}
				/>
			)}
		/>
	);
};

export default DropdownMachines;
