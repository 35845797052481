import React, { useReducer } from 'react';
import { BrandsReducer } from './BrandsReducers';

export const BrandsContext = React.createContext();

export const BrandsProvider = ({ children }) => {

    const [brands, dispatchBrands] = useReducer(BrandsReducer, []);

    const contextValues = {
        brands,
        dispatchBrands,
    };
    return (
        <BrandsContext.Provider value={contextValues}>
            {children}
        </BrandsContext.Provider>
    );
};
