import React, {
	useState,
	useEffect,
	useContext,
} from "react";
import BasketItem from "./BasketItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTimes } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../store/UserContext";
import { BasketContext } from "../store/BasketContext";
import Checkout from "../checkout/Checkout";
import config from "../config";
import { BASKET_TOGGLE, UPDATE_BASKET } from "../store/BasketReducers";
import { Box, Drawer, IconButton, styled, Typography, Collapse, Button, Fade, Badge, Tooltip, Alert } from "@mui/material";
import { useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import BasketTotals from "./BasketTotals";
import { CompanyContext } from "../store/CompanyContext";
import BasketMenu from "./BasketMenu";
import BasketItemSkeleton from "./BasketItemSkeleton";
import { isMobile } from "react-device-detect";
import { updateBasketTotals } from "./helpers/updateBasketTotals";
import { trackEvent } from "../google_analytics/ga";

const Basket = () => {
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { basket, dispatchBasket } = useContext(BasketContext);
	const [open, setOpen] = useState(false);
	const [openCheckout, setOpenCheckout] = useState(false);
	const [baskets, setBaskets] = useState();

	useEffect(() => {
		if (basket.basket_open) {
			//console.log('basket open', basket.basket_open)
			setOpen(true)
			setOpenCheckout(true)
			dispatchBasket({
				type: BASKET_TOGGLE,
				payload: {
					"basket_open": false
				}
			})
		}
	}, [basket.basket_open])


	const fetchBaskets = useMutation((company_id) => {
		return axiosRequest({
			method: 'get',
			gateway: config.shopAPI.URL,
			api_key: user.api_key,
			endpoint: 'basket',
			params: {
				'company_id': company_id
			}
		})
	}, {
		onSuccess: (data) => {
			if (!data.errorMessage && data[0]) {

				let basket_id = sessionStorage.getItem("basket_id");
				let currentBasket
				if (basket_id) {
					//found basket id
					currentBasket = data.find(item => item.basket_id == basket_id)
					if (!currentBasket) {
						//basket id not found
						currentBasket = data[0]
					}
				} else {
					//set to the first basket
					currentBasket = data[0]
				}

				//update context
				dispatchBasket({
					type: UPDATE_BASKET,
					payload: {
						"items": currentBasket.basket_items,
						"basket_id": currentBasket.basket_id,
						"basket_number": currentBasket.basket_number,
						"basket_name": currentBasket.basket_name
					}
				});

				setBaskets(data)
				//sessionStorage.setItem("basketProduct", data[0].basket_items);
				sessionStorage.setItem("basket_id", currentBasket.basket_id);
				updateBasketTotals(currentBasket.basket_items, dispatchBasket);

			} else {
				//no basket
				dispatchBasket({
					type: UPDATE_BASKET,
					payload: {
						"items": [],
						"basket_id": null,
						"basket_number": null,
						"totals": {
							"total_price_inc_discount": null, //total
							"total_price_rrp": null, //total
							"total_quantity": null,
						},
					}
				});
				sessionStorage.removeItem("basket_id");
			}
		}
	})

	useEffect(() => {
		if (selectedCompany.company_id) {
			fetchBaskets.mutate(selectedCompany.company_id)
		}
	}, [selectedCompany.company_id])


	const fetchSingleBasket = useMutation((basket_id) => {
		return axiosRequest({
			method: 'get',
			gateway: config.shopAPI.URL,
			api_key: user.api_key,
			endpoint: 'basket',
			params: {
				'basket_id': basket_id
			}
		})
	}, {
		onSuccess: (data) => {
			//console.log(data)
			dispatchBasket({
				type: UPDATE_BASKET,
				payload: {
					"items": data[0].basket_items,
					"basket_id": data[0].basket_id,
					"basket_name": data[0].basket_name
				}
			});
			//sessionStorage.setItem("basketProduct", data[0].basket_items);
			sessionStorage.setItem("basket_id", data[0].basket_id);
			updateBasketTotals(data[0].basket_items, dispatchBasket);
		}
	})

	const updateSelected = (newValue) => {
		fetchSingleBasket.mutate(newValue.basket_id)
	}

	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		//justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	}));

	const toggleDrawer = (state) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setOpen(state);
	};


	const refreshBaskets = () => {
		fetchBaskets.mutate(selectedCompany.company_id)
	}
	//console.log(basket.items)

	return (
		<>
			<Tooltip title="My Basket">
				<IconButton
					color="inherit"
					aria-label="Open Basket"
					//edge="start"
					onClick={toggleDrawer(true)}
				>
					<Badge
						sx={{
							right: '2px',
							position: 'absolute',
							top: '6px',
							'& .MuiBadge-badge ': {
								//background: '#333'
							}
						}}
						badgeContent={basket?.totals?.total_quantity}
						color="primary">
					</Badge>
					<FontAwesomeIcon
						//onClick={toggleDrawer(true)}
						icon={faShoppingCart}
					/>
				</IconButton>
			</Tooltip>
			<Drawer
				variant="temporary"
				//onBackdropClick={toggleDrawer(false)}
				anchor="right"
				open={open}
				onClose={toggleDrawer(false)}
				ModalProps={{
					keepMounted: true,
				}}
				SlideProps={{
					//transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1)!important'
				}}
				PaperProps={{
					sx: { overflow: 'hidden' },
				}}
			>
				<Collapse in={openCheckout} sx={{ minWidth: '100vw' }} orientation="horizontal" collapsedSize={isMobile ? '360px' : '400px'}>
					<Fade in={!openCheckout}>
						<Box sx={{ minWidth: '100vw', height: '100%' }}>

							<DrawerHeader sx={{ background: theme => theme.palette.cards.header, borderBottom: '1px solid #ccc', paddingRight: '20px' }} >

								<IconButton disableRipple onClick={toggleDrawer(false)}>
									<FontAwesomeIcon icon={faTimes} />
								</IconButton>
								<Typography color="text.secondary" sx={{ marginLeft: '1em' }}>Basket: <strong>{basket.basket_name}</strong></Typography>
								{/* <Typography color="text.secondary" fontWeight={700} sx={{ marginLeft: '1em' }}>{selectedCompany.company_name}</Typography>  */}
								<Box
									sx={{
										position: 'absolute',
										left: '350px',
										height: '100%',
										width: '40px',
										display: 'grid',
										alignItems: 'center'
									}}
								>
									<BasketMenu basket={basket} allBaskets={baskets} refreshBaskets={refreshBaskets} updateSelected={updateSelected} />
								</Box>
							</DrawerHeader>

							<Box
								sx={{ width: isMobile ? '360px' : '400px', height: '100%' }}
							>
								{fetchBaskets.isLoading || fetchSingleBasket.isLoading ?
									<Box
										sx={{
											width: isMobile ? '360px' : '400px',
											height: '100%'
										}}
									>
										{/* <SpinningLoader text="Loading Basket..." size={40} /> */}
										<BasketItemSkeleton />
										<BasketItemSkeleton />
										<BasketItemSkeleton />
										<BasketItemSkeleton />
									</Box>
									:
									<Box sx={{ paddingBottom: '250px', height: '100%', position: 'relative' }} >
										{basket.basket_id ?
											<Fade in={true}>
												<Box sx={{ overflow: 'auto', height: '100%', padding: '0 8px' }}>

													{basket.items.length > 0 ? (
														basket.items.map((product, i) =>
															<BasketItem key={i} product={product} setOpen={setOpen} />
														)

													) : (
														<Alert severity="error" sx={{ marginTop: '10px' }}>
															Your basket is empty.
														</Alert>
													)}
												</Box>
											</Fade>
											: null}
									</Box>
								}
							</Box>
							<Box
								sx={{
									padding: '1em',
									position: 'absolute',
									bottom: 0,
									//height: '265px',
									width: '100%',
									background: theme => theme.palette.cards.header,
									borderTop: '1px solid #ccc'
								}}
							>
								<BasketTotals />
								<Button
									variant="contained"
									color="primary"
									onClick={(e) => {

										//console.log(basket)
										let items = basket.items.map(item => {
											return {
												"item_id": item.product_id,
												"item_name": item.product_description,
												"product_number": item.product_number,
												"product_supplier": item.product_supplier
											}
										})
										//add to google analytics
										trackEvent({
											action: 'begin_checkout',
											params: {
												"currency": "GBP",
												"value": basket,
												"items": items
											}
										})
										setOpenCheckout(true)
									}
									}
									sx={{ float: 'right' }}
									size="large"
									//fullWidth
									disabled={fetchBaskets.isLoading || fetchSingleBasket.isLoading ? true : false}
								>
									Go to Checkout
								</Button>
								{/* <Button
									variant="contained"
									color="secondary"
									onClick={(e) => setOpenCheckout(true)}
									//size="large"
									fullWidth
									sx={{marginTop: '1em'}}
									disabled={fetchBaskets.isLoading || fetchSingleBasket.isLoading ? true : false}
								>
									Request a quote
								</Button> */}
							</Box>
						</Box>
					</Fade>
					<Fade in={openCheckout}  >
						<Box sx={{
							width: '100vw',
							position: 'absolute',
							top: 0,
							left: 0,
							height: '100vh'
						}}
						>
							<Checkout setOpenCheckout={setOpenCheckout} closeBasket={setOpen} />
						</Box>
					</Fade>
				</Collapse>
			</Drawer >
		</>
	);
};

export default Basket;
