import {
    Grid
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../store/MachineContext';
import Alert from '@mui/material/Alert';
import {
    SAVE_ENTRY,
    VOID_ENTRY
} from '../store/MachineReducers';
import GlobalDialog from '../../reusable/GlobalDialog';
import useActivityLog from '../../users/activity/useActivityLog';
import DialogLoader from '../../reusable/DialogLoader';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import useMachineEntry from '../../hooks/useMachineEntryRefresh';

const ConfirmEdit = ({
    open,
    handleClose,
    handleConfirmClose,
    newEntry
}) => {

    const { refetchMachineEntries } = useMachineEntry()
    const { user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { updateActivityLog } = useActivityLog();

    //save new machine entry
    const voidEntry = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'patch',
            body: {
                "new_entry": true,
                ...body
            }
        }),
        onSuccess: (response) => {
            if (response) {
                addMachineEntry.mutate(newEntry.entry)
            }
        }
    })

    //save new machine entry
    const addMachineEntry = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'post',
            body: {
                "new_entry": true,
                ...body
            }
        }),
        onSuccess: (response) => {

            if (response) {

                //refetch all macine entries
                refetchMachineEntries.mutate()
                //update activity log
                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        void_reason: 'Edited',
                        voided_entry_id: newEntry.entry.entry_id,
                        new_entry_id: response.insertId,
                        machine_id: newEntry.machine_id
                    },
                    activity_type: "edit entry"
                })
                const todayDate = new Date().toISOString();

                dispatchSelectedMachine({
                    type: VOID_ENTRY,
                    payload: {
                        entry_id: newEntry.entry.entry_id,
                        void: { date: todayDate, reason: 'Edited', user_id: user.id, user_name: user.name }
                    }
                });
                dispatchSelectedMachine({
                    type: SAVE_ENTRY,
                    payload: { ...newEntry.entry, entry_id: response.insertId }
                });
            }
        }
    })



    const handleSubmit = () => {

        const todayDate = new Date().toISOString();
        let outOfControl = newEntry.entry.attributes_new.some(item => item.attribute_values.status === 30);
        newEntry.entry.out_of_control = outOfControl
        //void old entry
        voidEntry.mutate({
            toVoid: true,
            date: todayDate,
            reason: 'Edited',
            user_id: user.user.user_id,
            user_name: user.user.user_details,
            entry_id: newEntry.entry.entry_id,
            machine_id: newEntry.machine.machine_id
        })
    };

    return (
        <GlobalDialog
            open={open}
            onClose={handleClose}
            title="Update Entry"
            buttonClick={handleSubmit}
            buttonTitle="Update Entry"
            successMessage="Entry Updated successfully!"
            fullWidth
            maxWidth="sm"
        >
            <Grid item>
                <Alert severity="error">
                    When editing entries, the original entry will be voided. Voided entries will not display on graphs/exports. But will still be available to view as edit
                </Alert>
            </Grid>
            {voidEntry.isLoading || voidEntry.isSuccess || voidEntry.isError || addMachineEntry.isLoading || addMachineEntry.isError ?
                <DialogLoader
                    isLoading={voidEntry.isLoading || addMachineEntry.isLoading}
                    mutation={addMachineEntry}
                    loadingMessage="Editting Machine Entry..."
                    successMessage="Entry Editted"
                    closeDialog={() => {
                        handleConfirmClose()
                    }}
                    fixed
                />
                : null}
        </GlobalDialog>
    );
};

export default ConfirmEdit;
