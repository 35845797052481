import {
    Alert,
    Autocomplete,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import InputWrap from '../reusable/InputWrap';
import { LayoutContext } from '../store/LayoutContext';


const KPIForm = ({ groupOptions, areaOptions }) => {

    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
    const { app } = useContext(LayoutContext)
    const [selectedArea, setSelectedArea] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedGlobal, setSelectedGlobal] = useState();
    const [selectedChart, setSelectedChart] = useState();



    const typeOptions = [
        { id: 'csv', name: 'CSV' },
        { id: 'single_int', name: 'Single Number' }
    ];
    const globalOptions = [
        { id: 'average', name: 'Average' },
        { id: 'total', name: 'Total' }
    ];
    const chartOptions = [
        { id: 'none', name: 'None' },
        { id: 'pie', name: 'Pie' }
    ];

    useEffect(() => {
        if (values.kpi_area && areaOptions) {
            let area = areaOptions.find(area => area.name === values.kpi_area)
            setSelectedArea(area)
        }
        if (values.kpi_type && typeOptions) {
            let type = typeOptions.find(area => area.id === values.kpi_type)
            setSelectedType(type)
        }

        if (values.global && globalOptions) {
            let global = globalOptions.find(area => area.id === values.global)
            setSelectedGlobal(global)
        }
        if (values.chart && chartOptions) {
            let chart = chartOptions.find(area => area.id === values.chart)
            setSelectedChart(chart)
        }
    }, [app, values, areaOptions])

    //console.log(values)

    return (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <InputWrap name="KPI Name *">
                        <TextField
                            id="kpi_name"
                            name="kpi_name"
                            value={values.kpi_name}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.kpi_name && Boolean(errors.kpi_name)}
                            helperText={touched.kpi_name && errors.kpi_name}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Description">
                        <TextField
                            multiline
                            rows={5}
                            id="kpi_description"
                            name="kpi_description"
                            value={values.kpi_description}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.kpi_description && Boolean(errors.kpi_description)}
                            helperText={touched.kpi_description && errors.kpi_description}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Associated Area">
                        <Autocomplete
                            disableClearable
                            sx={{ minWidth: 220 }}
                            options={areaOptions}
                            value={selectedArea || null}
                            onChange={(e, value) => {
                                setFieldValue('kpi_area', value.name);
                                setSelectedArea(value)
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select Area"
                                />
                            )}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Tags">
                        <Autocomplete
                            multiple
                            options={groupOptions}
                            value={values.kpi_tags || []}
                            onChange={(e, value) => {
                                setFieldValue('kpi_tags', value);
                                //setTags(value)
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Add tags"
                                />
                            )}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Data Import Type">
                        <Autocomplete
                            options={typeOptions}
                            value={selectedType || null}
                            onChange={(e, value) => {
                                if (value?.id) {
                                    setFieldValue('kpi_type', value.id);
                                    setSelectedType(value)
                                } else {
                                    setFieldValue('kpi_type', null);
                                    setSelectedType(null)
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Add upload type"
                                />
                            )}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    {values.kpi_type ?
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {values.kpi_type === "csv" ?
                                <Typography variant="body2" >
                                    This will allow an import of a CSV file.<br /> The csv must include columns : company_id , value
                                </Typography>
                                : values.kpi_type === "single_int" ?
                                    <Typography variant="body2" >
                                        This will be a single number added to all companies.
                                    </Typography>
                                    : null}
                        </Alert>
                        : null}
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Global Value">
                        <Autocomplete
                            disableClearable
                            sx={{ minWidth: 220 }}
                            options={globalOptions}
                            value={selectedGlobal || null}
                            onChange={(e, value) => {
                                setFieldValue('global', value.id);
                                setSelectedGlobal(value)
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </InputWrap>
                </Grid>
                <Grid item xs={12}>
                    <InputWrap name="Chart">
                        <Autocomplete
                            disableClearable
                            sx={{ minWidth: 220 }}
                            options={chartOptions}
                            value={selectedChart || null}
                            onChange={(e, value) => {
                                setFieldValue('chart', value.id);
                                setSelectedChart(value)
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </InputWrap>
                </Grid>

                <Grid item xs={12}>
                    <InputWrap name="Value Prefix">
                        <TextField
                            id="prefix"
                            name="prefix"
                            value={values.prefix}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.prefix && Boolean(errors.prefix)}
                            helperText={touched.prefix && errors.prefix}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid>
                {/* <Grid item xs={6}>
                    <InputWrap name="Value Suffix">
                        <TextField
                            id="kpi_settings.suffix"
                            name="kpi_settings.suffix"
                            value={values.suffix}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            error={touched.suffix && Boolean(errors.suffix)}
                            helperText={touched.suffix && errors.suffix}
                            sx={{ '& legend': { display: 'none' } }}
                        />
                    </InputWrap>
                </Grid> */}
            </Grid>
        </>
    );
};

export default KPIForm;
