import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Menu,
  IconButton,
  MenuItem,
  Grid,
  Alert,
  Autocomplete,
  TextField,
  Divider,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartMinus,
  faCartPlus,
  faPencil,
  faTrash,
  faUpload,
} from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../language/setLanguageText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditBasket from './EditBasket';
import NewBasket from './NewBasket';
import GlobalDialog from '../reusable/GlobalDialog';
import { UserContext } from '../store/UserContext';
import { useMutation } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';

const BasketMenu = ({ basket, allBaskets, refreshBaskets, updateSelected }) => {

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const { user } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const deleteBasket = useMutation((basket_id) => {
    return axiosRequest({
      gateway: config.shopAPI.URL,
      endpoint: 'basket',
      api_key: user.api_key,
      method: 'patch',
      body: {
        "delete": true,
        "basket_id": basket_id
      }
    })
  }, {
    onSuccess: (data) => {
      //console.log(data)
      refreshBaskets()
    }
  })
  const handleDeleteFile = (basket) => {
    deleteBasket.mutate(basket.basket_id)
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openMenu ? 'long-menu' : undefined}
        aria-expanded={openMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {allBaskets && allBaskets.length > 0 ?
          <MenuItem>
            <Autocomplete
              sx={{ minWidth: '200px' }}
              disableClearable
              id="selected-basket"
              value={basket || null}
              options={allBaskets || []}
              getOptionLabel={option => option.basket_name}
              isOptionEqualToValue={(option, value) =>
                option.basket_id === value.basket_id
              }
              onChange={(event, newValue) => {
                updateSelected(newValue)
              }}
              renderInput={params => (
                <TextField {...params} variant="outlined" />
              )}

            />
          </MenuItem>
          : null}
        {allBaskets && allBaskets.length > 0 ?

          <MenuItem
            onClick={() => {
              handleClose()
              setOpenEdit(true)
            }}
          >
            <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} />
            {setLanguageText("Edit Basket")}
          </MenuItem>
          : null}

        {allBaskets && allBaskets.length > 0 ?
          <MenuItem
            onClick={() => {
              handleClose()
              setConfirmDelete(true)
            }}
          >
            <FontAwesomeIcon icon={faCartMinus} style={{ marginRight: '10px' }} />
            {setLanguageText("Delete Basket")}
          </MenuItem>

          : null}
        <Divider />

        <MenuItem
          onClick={() => {
            handleClose()
            setOpenNew(true)
          }}
        >
          <FontAwesomeIcon icon={faCartPlus} style={{ marginRight: '10px' }} />
          {setLanguageText("Create New Basket")}
        </MenuItem>
      </Menu>
      {openEdit ?
        <EditBasket basket={basket} setOpen={setOpenEdit} open={openEdit} refreshBaskets={refreshBaskets} edit />
        : null}

      {openNew ?
        <NewBasket setOpen={setOpenNew} open={openNew} refreshBaskets={refreshBaskets} />
        : null}

      {confirmDelete &&
        <GlobalDialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          title={`Delete Basket`}
          buttonClick={() => handleDeleteFile(basket)}
          buttonTitle="Delete Basket"
          //disabled={mutation.isLoading}
          //loading={mutation.isLoading}
          maxWidth="sm"
        >
          <Grid item>

            <Alert severity="error">
              This action can not be undone.<br />
              Are you sure you want to delete <strong>{basket.basket_name}</strong>?
            </Alert>
          </Grid>
        </GlobalDialog>
      }

    </>
  );
};

export default BasketMenu;
