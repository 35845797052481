import React, { forwardRef, useContext, useEffect, useState } from 'react';
import formatDate from '../helpers/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPen } from '@fortawesome/pro-light-svg-icons';
import OutlinedDiv from '../reusable/OutlinedDiv';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import setLanguageText from '../../language/setLanguageText';
import { CompanyContext } from '../../store/CompanyContext';
import ServiceEntriesTable from './ServiceEntriesTable';


export default function ServiceForm({ rows, serviceObj, setServiceObj, openCustomerSignOpen, openEngineerSignOpen }) {

	const { selectedCompany } = useContext(CompanyContext);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="center" variant="h5">
						{selectedCompany.company_name}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<ServiceEntriesTable
						data={rows}
						hideCheckboxes={true}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							margin="none"
							value={serviceObj.service_attr.startTime}
							onChange={(newValue) => {
								setServiceObj(current => {
									return ({
										...current,
										"service_attr": {
											...current.service_attr,
											"startTime": newValue
										}
									})
								});
							}}
							fullWidth
							inputVariant="outlined"
							label={setLanguageText("Service Start")}
							required
							//format="dd/MM/yyyy"
							sx={{ float: 'right' }}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12} sm={6}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							margin="none"
							value={serviceObj.service_attr.finishTime}
							onChange={(newValue) => {
								setServiceObj(current => {

									return ({
										...current,
										"service_attr": {
											...current.service_attr,
											"finishTime": newValue
										}
									})
								});
							}}
							fullWidth
							inputVariant="outlined"
							label={setLanguageText("Service End")}
							required
							//format="dd/MM/yyyy"
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<TextField
						value={serviceObj.service_notes}
						onChange={(e) => {
							setServiceObj(current => {
								return ({
									...current,
									"service_notes": e.target.value
								})
							});
						}}
						variant="outlined"
						label={setLanguageText("Service Notes")}
						multiline
						fullWidth
						rows={3}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid item container spacing={2}>
						<Grid item xs={12} >
							<TextField
								label={setLanguageText("Engineer Name")}
								fullWidth
								variant="outlined"
								onChange={(e) => {
									setServiceObj(current => {
										return ({
											...current,
											"service_name": e.target.value
										})
									});
								}}
								value={serviceObj.service_name}
								required
								InputProps={{
									endAdornment: serviceObj.service_sign && (
										<InputAdornment position="end">
											<FontAwesomeIcon
												icon={faCheckCircle}
												size="lg"
											/>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item container spacing={2}>
							{serviceObj.service_sign && (
								<Grid item xs={12} >
									<OutlinedDiv label={setLanguageText("Engineer Sign")}>
										<img
											src={serviceObj.service_sign}
											alt="Engineer Sign"
											style={{ width: '400px', height: '200px' }}
										/>
									</OutlinedDiv>
								</Grid>
							)}

							<Grid item xs={12} sm={serviceObj.service_sign ? 6 : 12}>
								<Button
									color={serviceObj.service_sign ? 'secondary' : 'primary'}
									variant="contained"
									fullWidth
									onClick={openEngineerSignOpen}
									startIcon={<FontAwesomeIcon icon={faPen} />}
								>
									{setLanguageText(serviceObj.service_sign
										? 'Edit Signature'
										: 'Add Signature')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid item container spacing={2}>
						<Grid item xs={12} >
							<TextField
								label={setLanguageText("Customer Name")}
								fullWidth
								variant="outlined"
								onChange={(e) => {
									setServiceObj(current => {
										return ({
											...current,
											"customer_name": e.target.value
										})
									});
								}}
								value={serviceObj.customer_name}
								required
								InputProps={{
									endAdornment: serviceObj.cust_sign && (
										<InputAdornment position="end">
											<FontAwesomeIcon
												icon={faCheckCircle}
												size="lg"
											/>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item container spacing={2}>
							{serviceObj.cust_sign && (
								<Grid item xs={12} >
									<OutlinedDiv label={setLanguageText("Customer Sign")}>
										<img
											style={{ width: '400px', height: '200px' }}
											src={serviceObj.cust_sign}
											alt="Customer Sign"
										/>
									</OutlinedDiv>
								</Grid>
							)}
							<Grid item xs={12} sm={serviceObj.cust_sign ? 6 : 12}>
								<Button
									color={serviceObj.cust_sign ? 'secondary' : 'primary'}
									variant="contained"
									fullWidth
									onClick={openCustomerSignOpen}
									startIcon={<FontAwesomeIcon icon={faPen} />}
								>
									{setLanguageText(serviceObj.cust_sign
										? 'Edit Signature'
										: 'Add Signature')}

								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		</>
	);
};


