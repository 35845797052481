import { Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React from 'react';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import setLanguageText from '../language/setLanguageText';

const KPIDropdowns = ({ selectedGroup, setSelectedGroup, groupOptions, selectedArea, setSelectedArea, areaOptions }) => {

  return (
    <Grid item container spacing={2} justifyContent="flex-end">
      <Grid item>
        {areaOptions &&
          <Autocomplete
            disabled={!areaOptions}
            //disableClearable
            sx={{ minWidth: 220 }}
            options={areaOptions || []}
            value={selectedArea || null}
            onChange={(event, newValue) => {
              setSelectedArea(newValue)
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Filter by Factory Area"
                label={setLanguageText("Filter by Area")}
              />
            )}
          />
        }
      </Grid>
      <Grid item>
        {groupOptions &&
          <Autocomplete
            disabled={!groupOptions}
            //disableClearable
            sx={{ minWidth: 220 }}
            options={groupOptions || []}
            value={selectedGroup || null}
            onChange={(event, newValue) => {
              setSelectedGroup(newValue)
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Filter by User Group"
                label={setLanguageText("Filter by Group")}
              />
            )}
          />
        }
      </Grid>
      <Grid item sx={{ minWidth: 220 }}>
        <GlobalCompanySelect addAll />
      </Grid>
    </Grid>

  );
}
export default KPIDropdowns;