import {
    Button,
    Grid
} from '@mui/material';
import React, {
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';
import { UserContext } from '../../../../store/UserContext';
import { useQuery } from 'react-query';
import config from '../../../../config';
import axiosRequest from '../../../../axios/axoisRequest';
import ActivityTable from './ActivityTable';
import { CSVLink } from 'react-csv';

const ActivityReport = () => {

    const { user } = useContext(UserContext);
    const [csv_data, setCsv_data] = useState([[]]);

    const columns = useMemo(() => [
        {
            accessorKey: 'company_name',
            header: () => 'Company',
            cell: info => info.getValue(),
            size: 400,
            enableSorting: false,
            footer: props => 'Totals:'
            //minSize: 400,
            //maxSize: 400
        },
        {
            accessorKey: 'search_count',
            header: () => 'Searches',
            cell: info => info.getValue(),
            size: 200,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.search_count !== '-' ? parseInt(row.original.search_count) : 0), 0);
                return total;
            },
        },
        {
            accessorKey: 'new_order_count',
            header: () => 'Completed Orders',
            cell: info => info.getValue(),
            size: 200,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.new_order_count !== '-' ? parseInt(row.original.new_order_count) : 0), 0);
                return total;
            },
        },
        {
            accessorKey: 'saved_quote_count',
            header: () => 'Saved Quotes',
            cell: info => info.getValue(),
            size: 200,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.saved_quote_count !== '-' ? parseInt(row.original.saved_quote_count) : 0), 0);
                return total;
            },
        },
        {
            accessorKey: 'request_count',
            header: () => 'Product Requests',
            cell: info => info.getValue(),
            size: 200,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.request_count !== '-' ? parseInt(row.original.request_count) : 0), 0);
                return total;
            },
        },
        {
            accessorKey: 'company_credits',
            header: () => 'Download Credits',
            cell: info => info.getValue(),
            size: 200,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.company_credits !== '-' ? parseInt(row.original.company_credits) : 0), 0);
                return total;
            },
        }, {
            accessorKey: 'download_count',
            header: () => 'Downloads (3D Models)',
            cell: info => info.getValue(),
            size: 240,
            footer: ({ table }) => {
                const total = table.getFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.download_count !== '-' ? parseInt(row.original.download_count) : 0), 0);
                return total;
            },
        }
    ], [])

    const { data, isFetching } = useQuery(['full_report'],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'activity/logging',
            api_key: user.api_key,
            method: 'get',
            params: {
                full_report: true
            }
        }), {
        retry: 1
    })

    const create_csv_data = useCallback(() => {
        let row_values = [];
        const headers = columns.map(header => header.header());
        data.forEach(value => {
            //console.log(value)
            const column_value = [
                value.company_name,
                value.search_count,
                value.new_order_count,
                value.saved_quote_count,
                value.request_count,
                value.company_credits,
                value.download_count
            ];
            row_values = [...row_values, column_value];
        });
        setCsv_data([headers, ...row_values]);
    }, [columns, data]);

    //console.log(data)
    //console.log(csv_data)

    return (

        <>
            <Grid item xs={12}>
                <ActivityTable
                    data={data}
                    columns={columns}
                    isFetching={isFetching}
                />
            </Grid>
            <Grid item xs={12}>
                <CSVLink
                    data={csv_data}
                    filename={`full.csv`}
                >
                    <Button
                        onClick={() => create_csv_data()}
                        variant="contained"
                    >
                        Download CSV
                    </Button>
                </CSVLink>
            </Grid>
        </>
    )
};

export default ActivityReport;
