import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import ActivityReport from './activity/ActivityReport';

const Reports = () => {

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText("Company Activity")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>

                <Grid container spacing={2}>
                    <ActivityReport />
                </Grid>

            </TabContent>

        </>
    );
};

export default Reports;
