import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Box, CircularProgress, Divider, Grid, IconButton, InputBase, ListItemAvatar, ListItemButton, ListItemText, Menu, Popover, TextField, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDoubleRight, faImageSlash, faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../../store/UserContext";
import { useMutation } from "react-query";
//import useDebounce from "../../shop/libs/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { UPDATE_BRANDS, UPDATE_FILTERS } from "../../store/ShopReducers";
import { ShopContext } from "../../store/ShopContext";
import { CompanyContext } from "../../store/CompanyContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { LayoutContext } from "../../store/LayoutContext";
import highlightText from "../../helpers/highlightText";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { ProductsContext } from "../../store/ProductsContext";
import useSearchCount from "../../hooks/useSearchCount";
import searchString from "../../helpers/searchString";
import BuyerSearchInfo from "./BuyerSearchInfo";


const BuyerSearch = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const { updateSearchCount } = useSearchCount();
	const searchParams = new URLSearchParams(location.search);
	const search = searchParams.get('s');
	let theme = useTheme();
	const { user } = useContext(UserContext);
	const { dispatchBrands, dispatchFilters } = useContext(ShopContext);
	const { dispatchProducts } = useContext(ProductsContext);
	const { dispatchLayout } = useContext(LayoutContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState(search ? search : '');
	const [count, setCount] = useState();
	const [results, setResults] = useState();
	// const [suggestion, setSuggestion] = useState();
	// const [suggestions, setSuggestions] = useState();
	const open = Boolean(anchorEl);
	const ref = useRef()

	const handleClose = () => {
		setAnchorEl(null);
	};

	//const debouceValue = useDebounce(value, 400);

	const get10Products = useMutation((values) => {

		let search_string = searchString(values.value)
		//console.log(search_string)

		//console.log(user)
		return axiosRequest({
			name: 'search', //added to see which call is erroring
			gateway: config.ezbaseAPI.URL,
			endpoint: 'search',
			api_key: user.api_key,
			method: 'get',
			params: {
				search: encodeURI(search_string),
				offset: 0,
				limit: 5,
				company_id: values.company_id,
				search_only: true
			}
		})
	}, {
		onSuccess: (response) => {
			//console.log(response)
			if (!response.errorMessage && Object.values(response).length) {
				setResults(response.results)
				setCount(response.fullProductCount)
				setAnchorEl(ref.current);
			}
			//getProducts.reset()
		}
	})

	const clearText = () => {
		console.log('clear');
		setValue('');
		setResults();
	}

	return (
		<>
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				// placeholder={`Search ${options[selectedIndex]}`}
				// inputProps={{ 'aria-label':`Search ${options[selectedIndex]}` }}
				placeholder={`Search Products...`}
				inputProps={{ 'aria-label': `Search Products...` }}
				value={value}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				onKeyUp={(e) => {
					if (e.key === "Enter") {
						setValue(e.target.value);
						get10Products.mutate({ "company_id": selectedCompany.company_id, "value": e.target.value })
					}
				}}
			/>
			{results?.Hits === 0 ?
				<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>No products</Typography>
				: results?.Hits > 0 ?
					<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }} >({results.Hits})</Typography>
					: null}

			{value?.length ?
				<Box
					sx={{
						cursor: 'pointer',
						p: '10px',
						color: (theme) => theme.palette.grey[400],
						'&:hover': {
							color: (theme) => theme.palette.grey[800]
						}
					}}
					onClick={e => clearText()}
				>
					<FontAwesomeIcon icon={faTimes} size="lg" />
				</Box>
				: null}
			<Divider sx={{ height: 28, m: '0' }} orientation="vertical" />
			<IconButton
				ref={ref}
				color="primary"
				sx={{ p: '10px ' }}
				aria-label="directions"
				onClick={e => {
					get10Products.mutate({ "company_id": selectedCompany.company_id, "value": value })
					//setAnchorEl(ref.current);
				}}
			>
				{get10Products.isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="sm" />}
			</IconButton>
			<BuyerSearchInfo/>
			{results && Object.values(results).length &&
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					PaperProps={{
						elevation: 0,
						sx: {
							bgcolor: theme => theme.palette.background.default,
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,1))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: theme => theme.palette.background.default,
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
				>
					{value?.length > 0 && results.map((product, i) => {

						//console.log(photo)
						let allAssets = product.Assets
						let mainImage
						if (Object.values(allAssets).length) {
							mainImage = allAssets.find((image) => image.category === "1")
						}


						return (
							<ListItemButton
								onClick={() => {
									//updateSearchCount.mutate('buyers_product')
									navigate(`/buyers/catalogue/product/${product.ArticleID}`)
								}}
								key={i} divider >
								<ListItemAvatar>
									<Avatar
										sx={{ background: '#fff', cursor: 'pointer', boxShadow: '0 0 6px 2px #404040' }}>
										{mainImage ?
											<img
												src={mainImage.url}
												//onError={e => { e.target.src = placeholder;}}
												style={{ width: '100%', height: '100%', objectFit: 'cover' }}
											/>
											: <FontAwesomeIcon color="#222" icon={faImageSlash} />
										}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									disableTypography
									primary={<Typography color="text.primary" variant="subtitle1" fontSize="13px" lineHeight="1.4" ><strong>Name: </strong>
										<Typography color="text.secondary" component="span" variant="subtitle2" fontSize="13px" >
											{highlightText(product.ArticleName.toString(),value.toString())}
										</Typography>
									</Typography>}
									secondary={<>
										<Typography color="text.primary" variant="subtitle2" fontSize="13px" ><strong>Description: </strong> 
										{highlightText(product.Description.toString(),value.toString())}
										</Typography>
										<Typography color="text.primary" variant="subtitle2" fontSize="13px" ><strong>Article ID: </strong> 
										{highlightText(product.ArticleID.toString(),value.toString())}
										</Typography>
										{product?.ArticleNumber ? 
										<Typography color="text.primary" variant="subtitle2" fontSize="13px" ><strong>Article Number: </strong> 
										{highlightText(product.ArticleNumber.toString(),value.toString())}
										</Typography>
										: null}
									</>
									}
								/>
							</ListItemButton>
						)
					})}
					<ListItemButton
						onClick={e => {
							//reset brands
							dispatchBrands({
								type: UPDATE_BRANDS,
								payload: {
									selectedBrands: [],
								}
							});
							dispatchProducts({
								type: UPDATE_PRODUCTS,
								payload: {
									allProducts: [],
									offset : 0 ,
									count:  0,
									groupCount:  null
								}
							});
							dispatchFilters({
								type: UPDATE_FILTERS,
								payload: []
							});
							dispatchLayout({
								type: UPDATE_LAYOUT,
								payload: {
									page: 1,
								}
							});
							updateSearchCount.mutate('buyers')
							//console.log(encodeURIComponent(value))
							navigate(`/buyers/catalogue/search?s=${encodeURIComponent(value)}`)
						}
						}
						sx={{ textAlign: 'right' }}>
						<Typography fontSize="13px" sx={{ width: '100%', color: (theme) => theme.palette.text.primary }} >
							View all {count ? count : ''} products
						</Typography>
						<FontAwesomeIcon sx={{ marginLeft: 10 }} color={theme.palette.text.primary} size="sm" icon={faChevronDoubleRight} />
					</ListItemButton>
				</Menu>
			}
		</>
	);
};

export default BuyerSearch;
