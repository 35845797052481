
const findCurrentCategory = (arr, itemId, path) => {

    //console.log(arr, itemId, path)
     
    let result =   arr.reduce((a, cat) => {
            if (!path) { path = []; }
            if (a) return a;
            if (cat.ID === itemId) return [{ cat: cat , path: path }];
            if (cat.Items) return findCurrentCategory(cat.Items, itemId, [...path, cat.ID])
        }, null)

        return result
    
}
export default findCurrentCategory;
// if (!path) { path = []; }
// if (a) return a;
// if (cat.ID === itemId) return { cat: cat, path: path };
// if (cat.Items) return findItemNested(cat.Items, itemId, [...path, cat.ID])

// arr.reduce((a, cat) => {
//     if (!path) { path = []; }
//     if (a) return a;
//     if (cat.ID === itemId) return [...path, { "Name": cat.Name, "ID": cat.ID }];
//     if (cat.Items) return findItemNested(cat.Items, itemId, [...path, { "Name": cat.Name, "ID": cat.ID }])
// }, null)