import React, { useContext, useEffect, useState } from "react";
import packageJson from '../../package.json';
import { Grid, Typography } from "@mui/material";
import { UserContext } from "../store/UserContext";
//exportconfig based on environment variable
const json = require(`./${packageJson.version}.json`);

const VersionUpdate = () => {

	const { user } = useContext(UserContext);
	//console.log(json)

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h4">Welcome to v{json?.[0]?.version}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="p">The following updates have been made:</Typography>
			</Grid>
			{json?.[0]?.hot ?
				<Grid item xs={12}>
					<Typography variant="h5">Hot Fix:</Typography>
					<ul>
						{json?.[0]?.hot?.map((text, index) => {
							return (
								<li key={index}><Typography variant="p">{text}</Typography></li>
							)
						}
						)}
					</ul>
				</Grid>
				: null}

			{json?.[0]?.ui.length ?
				<Grid item xs={12}>
					<Typography variant="h5">Features and UX:</Typography>
					<ul>
						{json?.[0]?.ui?.map((text, index) => {
							return (
								<li key={index}><Typography variant="p">{text}</Typography></li>
							)
						}
						)}
					</ul>
				</Grid>
				: null}
			{json?.[0]?.bug.length  ?
				<Grid item xs={12}>
					<Typography variant="h5">Bug fixes:</Typography>
					<ul>
						{json?.[0]?.bug?.map((text, index) => {
							return (
								<li key={index}><Typography variant="p">{text}</Typography></li>
							)
						}
						)}
					</ul>
				</Grid>
				: null}

			{user?.user?.user_permissions?.administrator && json?.[0]?.server.length  ?
				<Grid item xs={12}>
					<Typography variant="h5">Under the hood:</Typography>
					<ul>
						{json?.[0]?.server?.map((text, index) => {
							return (
								<li key={index}><Typography variant="p">{text}</Typography></li>
							)
						}
						)}
					</ul>
				</Grid>
				: null}

			{/* <Grid item>
				<Typography variant="p">Hopefully you won&apos;t experience any errors, but if you do please let us know via the feedback form.</Typography>
			</Grid> */}
		</Grid>

	)

}

export default VersionUpdate;