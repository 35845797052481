
export const UPDATE_URL_FILTERS = 'UPDATE_URL_FILTERS';
export const CLEAR_URL_FILTERS = 'CLEAR_URL_FILTERS';

export const UPDATE_APPLICATION_FILTERS = 'UPDATE_APPLICATION_FILTERS';
export const CLEAR_APPLICATION_FILTERS = 'CLEAR_APPLICATION_FILTERS';

export const UPDATE_PARAMETRIC_FILTERS = 'UPDATE_PARAMETRIC_FILTERS';
export const UDPATE_ALL_PARAMETRIC_FILTERS = 'UDPATE_ALL_PARAMETRIC_FILTERS';
export const CLEAR_PARAMETRIC_FILTERS = 'CLEAR_PARAMETRIC_FILTERS';

export const CLEAR_RESULTS_FILTERS = 'CLEAR_RESULTS_FILTERS';
export const UPDATE_RESULTS_FILTERS = 'UPDATE_RESULTS_FILTERS';
export const UPDATE_ALL_RESULTS_FILTERS = 'UPDATE_ALL_RESULTS_FILTERS';

export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';

export const SelectedReducer = (state, action) => {
    switch (action.type) {

        case UPDATE_URL_FILTERS:
            //console.log(state.urlFilters)
            return {
                ...state,
                urlFilters: {
                    ...state.urlFilters,
                    ...action.payload
                }
            };
        case CLEAR_URL_FILTERS:
            return {
                ...state,
                urlFilters: {
                    'application': [],
                    'search': [],
                    'filters': []
                },
            };
        case UPDATE_APPLICATION_FILTERS:
            let applicationFilters = [...state.applicationFilters]
            let index = (applicationFilters).findIndex((obj) => obj.Field === action.payload.Field);
            //console.log(index)
            if (index > -1) {
                //if value then update
                if (action.payload.Values.length && action.payload.Values[0] !== '') {
                    applicationFilters[index] = action.payload
                } else {
                    //if not value remove
                    applicationFilters.splice(index, 1)
                }

            } else {
                //add new filter
                if (action.payload.Values.length) {
                    applicationFilters.push(action.payload)
                }
            }
            return {
                ...state,
                applicationFilters: applicationFilters,
                applicationReset: Object.values(applicationFilters).length === 0 ? true : false
            };
        case CLEAR_APPLICATION_FILTERS:
            return {
                ...state,
                applicationFilters: [],
                applicationReset: false
            };
        case UPDATE_PARAMETRIC_FILTERS:
            //console.log(state.parametricFilters)
            let parametricFilters = [...state.parametricFilters]
            let p_index = (parametricFilters).findIndex((obj) => obj.Field === action.payload.Field);
            //console.log(index)
            if (p_index > -1) {
                //if filter already exists then update if there is a value
                if (action.payload.Values.length && action.payload.Values[0] !== '') {
                    parametricFilters[p_index] = action.payload
                } else {
                    //if not value remove
                    parametricFilters.splice(p_index, 1)
                }
            } else {
                //add new filter make sure there is a value
                if (action.payload?.Values.length  && action.payload.Values[0] !== '') {
                    parametricFilters.push(action.payload)
                }
            }
            return {
                ...state,
                parametricFilters: parametricFilters,
                resetFilters: false
            };
        case UDPATE_ALL_PARAMETRIC_FILTERS:
            //console.log(action.payload)
            //sessionStorage.setItem("parametricFilters", JSON.stringify(action.payload));
            return {
                ...state,
                parametricFilters: action.payload,
                resetFilters: false
            };
        case CLEAR_PARAMETRIC_FILTERS:
            //set local strogae so we can get the field name on page refresh
            //sessionStorage.removeItem("parametricFilters");
            return {
                ...state,
                parametricFilters: [],
                resetFilters: true
            };
        case UPDATE_RESULTS_FILTERS:
            //console.log(state.parametricFilters)
            let resultFilter = [...state.resultFilters]
            let r_index = (resultFilter).findIndex((obj) => obj.Field === action.payload.Field);
            //console.log(r_index)
            if (r_index > -1) {
                //add full payload
                //console.log(action.payload)
                //if value then update
                if (action.payload.Values.length) {
                    resultFilter[r_index] = action.payload
                } else {
                    //if not value remove
                    resultFilter.splice(r_index, 1)
                }
            } else {
                //add new filter
                if (action.payload.Values.length) {
                    resultFilter.push(action.payload)
                }
            }
            return {
                ...state,
                resultFilters: resultFilter,
            };
        case UPDATE_ALL_RESULTS_FILTERS:
            return {
                ...state,
                resultFilters: action.payload
            };
        case CLEAR_RESULTS_FILTERS:
            return {
                ...state,
                resultFilters: []
            };
        case RESET_ALL_FILTERS:
            return {
                urlFilters: {
                    'search': [],
                    'filters': []
                },
                applicationFilters: [],
                resultFilters: [],
                parametricFilters: [],
                resetFilters: true
            };
        default:
            return state;
    }
};

