
const createProductObject = async (products) => {
    //console.log(products)
    let object = products.map((product) => {

            //cim 
            return {
                "product_cim_id": product?.ID,
                "product_cim_number": product?.ArticleNumber,
                "product_name": product?.Name,
                "product_cim_code": product?.CompanyCode,
                "product_category": product?.Category,
                "product_image": product?.Graphics ? product.Graphics.map((image) => {
                    return {
                        "url": image?.URL,
                        "type": image?.Type,
                        "viewer": image?.Viewer
                    }
                }) : [],
                "product_area": product?.area,
                "product_attributes": product?.Properties ? product.Properties.map((property, i) => {
                    return {
                        "id": i,
                        "code": property?.DIN,
                        "name": property?.Label,
                        "value": property?.Value,
                        "unit": property?.Unit
                    }
                }) : [],
                "product_matching": product?.InterfaceCodes ? product.InterfaceCodes.map((item) => {
                    return item
                    //to do: add matching
                    // return {
                    //     "direction": item?.Direction,
                    //     "name": item?.Label,
                    //     "value": item?.Value,
                    //     "standard": item?.Standard
                    // }
                }) : [],
                "product_price": product?.PriceInclDiscount,
                "product_stock": product?.Stock,
                "product_quantity": product?.Quantity,
                "product_pack_size": product?.Unit,
                "product_dataset": "cim",
                "product_brand": ""
            }
        
    })
    return object

}
export default createProductObject;