import React, {
    useEffect,
    useContext,
    useState,
} from "react";
import { CategoryContext } from "../store/CategoryContext";
import { SelectedContext } from "../store/SelectedContext";
import { UserContext } from "../store/UserContext";
import { ProductsContext } from "../store/ProductsContext";
import { LayoutContext } from "../store/LayoutContext";
import { SHOW_PRODUCTS, UPDATE_LAYOUT } from "../store/LayoutReducers";
import { UPDATE_CATEGORIES } from "../store/CategoryReducers";
import { RESET_PRODUCTS, UPDATE_PRODUCT_COUNT } from "../store/ProductsReducers";
import { useQuery } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";
import findCurrentCategory from "../shop/libs/findCurrentCategory";
import { BrandsContext } from "../store/BrandsContext";
import { UPDATE_BRANDS } from "../store/BrandsReducers";
import { UPDATE_URL_FILTERS } from "../store/SelectedReducers";
import GlobalMeta from "../reusable/GlobalMeta";
import ErrorFallback from "../reusable/ErrorFallback";
import logErrors from "../helpers/logErrors";
import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "../reusable/ErrorMessage";
import { Grid } from "@mui/material";

const ShopRoutesWrap = (props) => {
    //console.log(props)
    //Global states
    const [error, setError] = useState();
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const { layout } = useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const { categories, dispatchCategories } = useContext(CategoryContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { app, dispatchLayout } = useContext(LayoutContext);
    const { dispatchBrands } = useContext(BrandsContext);

    //--------------------------- Parametric save to local storage ------------------------------//

    //BREAKS - KEEP FILTERS

    // fire once on load to check localStorage
    //seems to have fixed the refresh issue
    // useEffect(() => {
    //     let local = sessionStorage.getItem("parametricFilters");
    //     //console.log(local)
    //     if (local && local !== 'undefined') {
    //         dispatchSelected({
    //             type: UDPATE_ALL_PARAMETRIC_FILTERS,
    //             payload: JSON.parse(local)
    //         })
    //     } 
    //     else {
    //         sessionStorage.removeItem("parametricFilters");
    //     }
    // }, [])

    //--------------------------- Update on refresh and first load ------------------------------//

    useEffect(() => {
        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            //console.log(urlParams)
            let hasSearch = urlParams.get("search")
            let hasFilters = urlParams.get("filters")
            let hasApp = urlParams.get("app")
            //let hasToolClass = urlParams.get("class")
            //console.log(isSearch)
            if (hasSearch || hasFilters || hasApp) {
                dispatchSelected({
                    type: UPDATE_URL_FILTERS,
                    payload: {
                        'application': hasApp ? JSON.parse(hasApp) : [],
                        'search': hasSearch ? JSON.parse(hasSearch) : [],
                        'filters': hasFilters ? JSON.parse(hasFilters) : []
                        //'class' : hasToolClass ? hasToolClass : null
                    }
                });
            }
        }
    }, []);


    //--------------------------- GET BRANDS ONCE ON TOP LEVEL ------------------------------//

    const { data: brands } = useQuery(['brands'],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: 'search_cs0'
            }
        }), {
        retry: 1
    })

    useEffect(() => {
        if (brands) {
            //console.log(brands)
            if (brands?.errorMessage) {
                setError(`Brands: ${brands.errorMessage}`)
            } else {
                //console.log(brands)
                dispatchBrands({
                    type: UPDATE_BRANDS,
                    payload: {
                        allBrands: brands?.Fields[1]?.Values
                    }
                })
            }
        }
        return () => {
            //cleanup
            setError(null)
        }

    }, [brands])


    const { data: openbrands } = useQuery({
		queryKey: ['opensearch brands'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.opensearchAPI.URL,
			endpoint: 'tools-search/brands',
			api_key: user.api_key,
			method: 'get'
		}),
		retry: 1
	})

    useEffect(() => {
        if (openbrands) {
            //console.log(brands)
            if (openbrands?.errorMessage) {
                setError(`Brands: ${openbrands.errorMessage}`)
            } else {
                //console.log(brands)
                dispatchBrands({
                    type: UPDATE_BRANDS,
                    payload: {
                        openSearchBrands: openbrands
                    }
                })
            }
        }
        return () => {
            //cleanup
            setError(null)
        }

    }, [openbrands])

    //--------------------------- GET CATEGORIES ONCE ON TOP LEVEL ------------------------------//

    const { data } = useQuery(['categories'],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
        }), {
        retry: 1
    })

    //if categories are returned
    useEffect(() => {
        if (data) {
            if (data?.errorMessage) {
                setError(`Category: ${data.errorMessage}`)
                //console.log(data.errorMessage)

            } else {
                //flattern  object get all bottom nodes
                function* flatten(array, depth) {
                    if (depth === undefined) {
                        depth = 1;
                    }
                    for (const item of array) {
                        if (Array.isArray(item.Items) && depth > 0) {
                            yield* flatten(item.Items, depth - 1);
                        } else {
                            yield item;
                        }
                    }
                }
                const arr = [data];
                let flattened = [...flatten(arr, Infinity)];
                flattened = flattened.filter(items => items.Image !== null)
                //console.log(flattened)

                //console.log(data)
                let urlParams = new URLSearchParams(window.location.search);
                let cValue = urlParams.get("c") ? urlParams.get("c") : "Root";
                if (cValue === "null") {
                    cValue = "Root"
                }
                const category = findCurrentCategory([data], cValue)

                //console.log(category)
                //show products if bottom level
                if (!category[0].cat.Items) {
                    dispatchLayout({
                        type: SHOW_PRODUCTS
                    });
                }
                dispatchCategories({
                    type: UPDATE_CATEGORIES,
                    payload: {
                        allCats: data,
                        currentCat: category[0].cat,
                        openLevel: category[0].path,
                        flatCats: flattened
                    }
                });

                //reset products
                dispatchProducts({
                    type: RESET_PRODUCTS
                });
                dispatchProducts({
                    type: UPDATE_PRODUCT_COUNT,
                    payload: {
                        count: category[0].cat.Count,
                    }
                });

                dispatchLayout({
                    type: UPDATE_LAYOUT,
                    payload: {
                        page: 1,
                        //type: layoutType
                    }
                });
            }
        }
        return () => {
            //cleanup
            setError(null)
        }
    }, [
        data,
        dispatchCategories,
        dispatchLayout,
        dispatchProducts
    ])

    //--------------------------- CREATE URL ON FILTER AD CATEGORY UPDATE ------------------------------//

    useEffect(() => {

        if (Object.keys(categories.currentCat).length > 0) {

            let urlParams = new URLSearchParams(window.location.search);

            //don't add filters to application search page
            //if (!window.pathname.includes("application")) {
            //console.log(selected.urlFilters)

            let search = selected?.urlFilters?.search ? selected.urlFilters.search : []
            let filters = selected?.urlFilters?.filters ? selected.urlFilters.filters : []
            let app = selected?.urlFilters?.application ? selected.urlFilters.application : []

            urlParams.set('c', categories.currentCat.ID)

            if (search) urlParams.set('search', JSON.stringify(search.sort()))
            if (filters) urlParams.set('filters', JSON.stringify(filters.sort()))
            if (app) urlParams.set('app', JSON.stringify(app.sort()))


            //console.log(filters)
            let url = `${window.location.pathname}?${decodeURIComponent(urlParams.toString())}`

            //if current url doesn't matche the newly created url with urlFilters then push
            if (decodeURI(`${window.location.pathname}${window.location.search}`) !== url) {
                //console.log(decodeURI(`${window.location.pathname}${window.location.search}`))
                //console.log(url)
                //console.log('replace create url')
                let newState = {
                    lastCurrentCat: categories.currentCat,
                    lastUrlFilters: selected.urlFilters,
                    lastShowCategories: Boolean(layout.showCategories),
                    lastShowProducts: Boolean(layout.showProducts)
                }
                props.customHistory.push(url, newState);
            }
            //}
        }
    }, [
        layout.showCategories,
        layout.showProducts,
        selected.urlFilters,
        categories.currentCat
    ]);

    return (
        <>
            {!error ?
                <>
                    <GlobalMeta title={props.title} />
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onError={(error, info) => {
                            return logErrors({
                                'component': props.title,
                                'user': user,
                                'full': info.componentStack.toString(),
                                'message': error.message.toString()
                            })
                        }}
                        onReset={() => {
                            //force reload
                            //location.reload();
                        }}
                    >
                        {props.children}
                    </ErrorBoundary>
                </>
                :
                <Grid container justifyContent="center">
                    <ErrorMessage error={error} />
                </Grid>
            }
            {/* <Basket /> */}
        </>
    );
};

export default ShopRoutesWrap;
