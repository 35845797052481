import {
	Button,
	Grid,
	TextField,
	Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from "yup"
import { renderToString } from 'react-dom/server';
import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import InputWrap from '../reusable/InputWrap';
import { useMutation } from 'react-query';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WysiwygEditor from '../reusable/WysiwygEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/system';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from '../store/CompanyContext';
import Loader from '../reusable/Loader';
import CustomCard from '../reusable/CustomCard';
import FileUpload from '../media/FileUpload';
import { useNavigate } from 'react-router-dom';
import FilePreviewOld from '../media/FilePreviewOld';
import FileUploadProgress from '../media/FileUploadProgress';
import NewRequest from './emails/NewRequest';
import { Storage } from 'aws-amplify';
import { LayoutContext } from '../store/LayoutContext';

const FixturesNew = () => {

	const navigate = useNavigate()
	const { user } = useContext(UserContext);
	const { theme } = useContext(LayoutContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [files, setFiles] = useState([])
	const [folder, setFolder] = useState()
	const [completedRequest, setCompletedRequest] = useState()
	const [uploading, setUploading] = useState(false)


	Storage.configure({
		AWSS3: {
			bucket: config.fixturesS3.BUCKET,
			region: config.fixturesS3.REGION
		}
	});


	const validationSchema = Yup.object({
		name: Yup.string()
			.min(2, "Must be at least 2 characters")
			.max(50, "Must be 50 characters or less")
			.required("Name is required"),
		details: Yup.string()
			.required("Detail are required"),
		date: Yup.string()
			.required("Required date is required")
	})

	const initialValues = {
		name: '',
		date: null,
		details: ''
	}

	//send new request email
	const send_email = useMutation((request) => {

		let emails = []
		//send to company email if set
		if (selectedCompany?.company_settings?.fixtures?.email) {
			if (Array.isArray(selectedCompany.company_settings.fixtures.email)) {
				//add all emails to emails array
				selectedCompany.company_settings.fixtures.email.forEach((email) => {
					emails.push(email)
				})
			} else {
				//add single email to emails array
				emails.push(selectedCompany.company_settings.fixtures.email)
			}
		} else if (!selectedCompany?.company_settings?.fixtures?.email && app?.emails?.fixtures_email) {
			//send to global application email if compnay is not set
			if (Array.isArray(selectedCompany.company_settings.fixtures.email)) {
				//add all emails to emails array
				app.emails.fixtures_email.forEach((email) => {
					emails.push(email)
				})
			} else {
				//add single email to emails array
				emails.push(app.emails.fixtures_email)
			}
		}

		const emailString = renderToString(<NewRequest request={request} theme={theme} />)
		return axiosRequest({
			gateway: config.fixturesAPI.URL,
			endpoint: 'email',
			api_key: user.api_key,
			method: 'patch',
			body: {
				email: emails,
				html: emailString,
				subject: 'New Fixtures Request'
			}
		})
	})

	const mutation = useMutation(values => {

		//'YYYY-MM-DD hh:mm:ss' 
		const today = new Date()
		//console.log(new Date(1659999600000))
		//console.log(new Date(1659347781000))
		//required = new Date(required).toISOString()
		// console.log(required.toString())
		//Create s3 folder in bucket
		//console.log(files)
		//const folder = (values.name).replace(' ', '-').toLowerCase()
		//setFolder(`${folder}-${Date.parse(today.toString())}`)
		const filesPaths = files.map((file) => {
			const fileObject = {
				type: file.type,
				name: file.name,
			}
			return fileObject
		})
		//mysql datetime
		const request = {
			request_name: values.name,
			request_details: values.details,
			request_required_date: Date.parse(values.date.toString()),
			request_submit_date: Date.parse(today.toString()),
			request_files: {
				//folder : `${folder}-${Date.parse(today.toString())}` , 
				files: filesPaths
			},
			request_status: 'New',
			request_last_updated: {
				user_id: user.user.user_id,
				user_details: user.user.user_details,
				email: user.user.email,
				date: Date.parse(today.toString())
			},
			request_user: {
				user_id: user.user.user_id,
				user_details: user.user.user_details,
				email: user.user.email
			},
			request_company: {
				company_id: selectedCompany.company_id,
				company_name: selectedCompany.company_name
			}
		}
		setCompletedRequest(request);
		//console.log(request)
		return axiosRequest({
			endpoint: `requests`,
			method: 'post',
			gateway: config.fixturesAPI.URL,
			api_key: user.api_key,
			body: request
		})
	}, {
		onSuccess: (data) => {
			//console.log(data)
			setFolder(data.insertId);
		}
	})

	const finshedUploading = () => {

		send_email.mutate({
			'request': completedRequest,
			'request_id': folder,
		})
		navigate(`/fixtures/view?request=${folder}&company=${selectedCompany.company_id}`)
	}

	//once the request has been uploaded and return an insertId
	useEffect(() => {
		if (folder && completedRequest) {
			//console.log(folder)
			//console.log(completedRequest)
			if (Object.values(files).length) {
				setUploading(true)
			} else {
				finshedUploading()
			}
		}
	}, [folder, completedRequest, files])


	// add files to current files
	const handleSelectUploadedFile = (newFiles) => {
		setFiles(currentFiles => [...currentFiles, newFiles])
	}
	//remove file
	const handleRemoveItem = (file) => {
		//console.log(file)
		setFiles(files => files.filter((f) => f.name !== file.name))
	}

	const handleSubmit = (values) => {
		//console.log(values)
		mutation.mutate(values);
	}

	return (
		<>
			<InnerHeaderWrap >
				<Grid spacing={2} container justifyContent="space-between" alignItems="center" >
					<Grid item xs={12} md={3} >
						<GlobalCompanySelect fullWidth addAll />
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid container item spacing={2} xs={12}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ values, submitForm, handleChange, setFieldValue, touched, errors }) => {
							return (
								<>
									<Grid item container xs={12} spacing={2} justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
										<Grid item>
											<Typography component="p" variant='h6'>
												Add a New Request
											</Typography>
										</Grid>
										<Grid item>
											<Buttons navigate={navigate} submitForm={submitForm} />
										</Grid>
									</Grid>
									<Grid container item spacing={2} xs={12}>
										<Grid item xs={12}>
											<CustomCard title="Request Details">
												<Form>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<InputWrap name="Name *">
																<TextField
																	id="name"
																	name="name"
																	value={values.name}
																	onChange={handleChange}
																	fullWidth
																	variant="outlined"
																	error={touched.name && Boolean(errors.name)}
																	helperText={touched.name && errors.name}
																	sx={{ '& legend': { display: 'none' } }}
																/>
															</InputWrap>
														</Grid>
														<Grid item xs={12}>
															<InputWrap name="Required by *">
																<LocalizationProvider
																	dateAdapter={AdapterDateFns}
																>
																	<DatePicker
																		//ampm={false}
																		//orientation="portrait"
																		value={values.date}
																		onAccept={value => {
																			if (value) {
																				setFieldValue(`date`, value)
																			}
																		}}
																		onChange={(newValue) => {
																			//console.log(newValue)
																			setFieldValue(`date`, newValue)
																		}}
																		//onError={console.log}
																		format="dd/MM/yyyy"
																		mask="___/__/__"
																		disableMaskedInput
																		end
																		//componentsProps={{ textField: { variant: 'outlined' } }}
																		renderInput={({ inputRef, inputProps, InputProps }) => (
																			<Box sx={{ display: 'flex', alignItems: 'center' }}>
																				<TextField
																					ref={inputRef} {...inputProps}
																					error={touched.date && Boolean(errors.date)}
																					helperText={touched.date && errors.date}
																					sx={{ '& legend': { display: 'none' } }}
																				/>
																				{InputProps?.endAdornment}
																			</Box>
																		)}
																	/>

																</LocalizationProvider>
															</InputWrap>
														</Grid>
														<Grid item xs={12}>
															<InputWrap name="Details *">
																<WysiwygEditor
																	initialValue={values.details}
																	fieldName="details"
																/>
															</InputWrap>
														</Grid>
													</Grid>
												</Form>
											</CustomCard>
										</Grid>
										<Grid item xs={12}>
											<CustomCard title="Request Attachments">
												<Grid container item spacing={4}>
													<Grid item xs={12}>
														<FileUpload
															handleSelectUploadedFile={handleSelectUploadedFile}
															dropzoneProps={{
																noClick: true,
																// accept: {
																//   'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
																// }
																maxFiles: 10,
																//maxSize: 5242880,
																multiple: true
															}}
														/>
													</Grid>
													<Grid container item spacing={4} >
														{!files.length && (
															<Grid item xs={12}>
																<Typography>Added files will show here</Typography>
															</Grid>
														)}
														{files && files.map((item, index) => {
															return (
																<React.Fragment key={index}>
																	<FilePreviewOld
																		item={item}
																		handleRemoveItem={handleRemoveItem}
																		index={index}
																	/>
																</React.Fragment>
															)
														})}
													</Grid>
												</Grid>
											</CustomCard>
										</Grid>
										<Grid item container xs={12} spacing={2} justifyContent="flex-end">
											<Buttons navigate={navigate} submitForm={submitForm} />
										</Grid>
									</Grid>
								</>
							)
						}}
					</Formik>
				</Grid>
				{mutation.isLoading || mutation.isSuccess || mutation.isError ?
					<Loader
						isLoading={mutation.isLoading}
						mutation={mutation}
						loadingMessage="Creating Request..."
						successMessage="Request Uploaded"
						callback={() => {
							//navigate('/Fixtures/dashboard')
							//console.log('uploading files')
						}}
					/>
					: null}
				{uploading && files && folder ?
					<FileUploadProgress
						folder={folder}
						files={files}
						open={uploading}
						callback={finshedUploading}
					/>
					: null}
			</PageContent>
		</>
	);
}


export default FixturesNew;

const Buttons = ({ navigate, submitForm }) => {
	return (
		<Grid item container xs={12} spacing={2} justifyContent="flex-end">
			<Grid item>
				<Button
					size='large'
					color="error"
					variant="text"
					onClick={() => navigate('/fixtures/dashboard')}
					startIcon={
						<FontAwesomeIcon
							icon={faTimes}
						/>
					}
				>
					Cancel
				</Button>
			</Grid>
			<Grid item>
				<Button
					size='large'
					color="primary"
					variant="contained"
					onClick={submitForm}
					startIcon={
						<FontAwesomeIcon
							icon={faSave}
						/>
					}
				>
					Submit request
				</Button>
			</Grid>
		</Grid>
	)
}

