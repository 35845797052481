import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, IconButton, Tooltip, Typography, Box } from "@mui/material"
import { faFile, faFileCircleQuestion, faFileImage, faFilePdf, faFileVideo, faImage, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons"

const IconSelect = (type) => {
  //console.log(type)
  let icon = faFileCircleQuestion
  if (type.type === "image/jpeg") {
    icon = faFileImage
  } else if (type.type === "image/png") {
    icon = faFileImage
  } else if (type.type === "application/pdf") {
    icon = faFilePdf
  } else if (type.type === "video/mp4") {
    icon = faFileVideo
  }
  return (
    <FontAwesomeIcon icon={icon} size="3x" />
  )
}

const FilePreviewOld = ({
  item,
  handleRemoveItem,
  index
}) => {
  return (
    <Grid item key={index} >
      <Box
        sx={{
          position: 'relative',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#ccc',
          width: '120px',
          height: '120px',
          borderRadius: '5px',
          background: (theme) => theme.palette.background.main
        }}
      >
        {/* BUTTONS */}
        <Box
          sx={{
            position: "absolute",
            right: '-10px',
            top: '-10px',
            ".MuiIconButton-root ": {
              borderRadius: 50,
              width: '35px',
              height: '35px',
              background: (theme) => theme.palette.secondary.main,
              ".cross": {
                color: (theme) => theme.palette.secondary.contrastText
              },
              "&:hover": {
                background: (theme) => theme.palette.secondary.dark,
              }
            },
          }}
        >
          <Tooltip title="Remove" placement="top" arrow>
            <IconButton onClick={() => handleRemoveItem(item)}>
              <FontAwesomeIcon icon={faTimes} size="sm" className="cross" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{
          padding: '35px 10px',
          textAlign: 'center',
          "& svg ": {
            color: (theme) => theme.palette.secondary.main
          }
        }} >
          <IconSelect type={item.type} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '120px',
          overflow: 'hidden',
          paddingTop: '5px',
          wordBreak: 'break-all'
        }}
      >
        <Typography variant="caption" lineHeight={1.4} component="p" textAlign="center">
          {item.name}
        </Typography>
      </Box>
    </Grid >

  )
}

export default FilePreviewOld
