import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { CategoryContext } from '../../../store/CategoryContext';
import { UPDATE_CATEGORIES } from '../../../store/CategoryReducers';
import { LayoutContext } from '../../../store/LayoutContext';
import { SelectedContext } from '../../../store/SelectedContext';
import { RESET_ALL_FILTERS } from '../../../store/SelectedReducers';
import { useQuery } from '@tanstack/react-query';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { UserContext } from '../../../store/UserContext';

export default function ProductInterfaceCodes({ codes, closeDialog, selectedProduct }) {

	const { categories, dispatchCategories } = useContext(CategoryContext);
	const { dispatchSelected } = useContext(SelectedContext);
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Box sx={{ maxHeight: 300, width: '100%', overflowX: 'auto', padding: '1em' }}>
			{codes && codes.map((row, i) =>
				<React.Fragment key={i}>
					<Grid item container alignItems="center" sx={{ width: '100%', margin: '.5em 0 .5em 0' }}>
						<Grid item xs >
							<Typography variant="h6" sx={{ color: 'text.primary' }} >{row.Direction}-{row.Label}</Typography>
						</Grid>
					</Grid>
					<TableContainer >
						<Table sx={{ border: `1px solid ${theme.palette.table.border}` }} size="small" >
							<TableHead sx={{ background: (theme) => theme.palette.table.header }}>
								<TableRow>
									<TableCell>Interface code</TableCell>
									<TableCell>Interface code (max)</TableCell>
									<TableCell>Type of interface code</TableCell>
									<TableCell sx={{ width: '200px' }} ></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow
									sx={{ background: (theme) => theme.palette.table.row }}
								>
									<TableCell>{row.Value}</TableCell>
									<TableCell>{row.Max}</TableCell>
									<TableCell>{row.Standard}</TableCell>
									<TableCell sx={{ width: '200px' }}>
										<Button
											variant="contained"
											sx={{ width: '200px', lineHeight: 1.2 }}
											endIcon={<FontAwesomeIcon icon={faWrench} />}
											onClick={e => {
												//check if already on component page
												let root = { ...categories.allCats } //parametric breadcrumb fix
												delete root.Items
												//reset filters fo matching componenets
												dispatchSelected({
													type: RESET_ALL_FILTERS
												})
												dispatchCategories({
													type: UPDATE_CATEGORIES,
													payload: {
														currentCat: root,
														openLevel: ["Root"]
													}
												});
												navigate(`/parametric/search/matching?c=Root&search=[]&product=${selectedProduct.product_cim_id}&direction=${row.Direction}`)
												// navigate(`/${app.selectedArea}/${app.selectedMenu}/matching?c=Root&search=[]&product=${product.product_cim_id}&direction=${row.Direction}`)
												if (closeDialog) closeDialog()
											}
											}>
											View Matching Components
										</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</React.Fragment>
			)}
		</Box>
	);
}