
import React from 'react';
import { Typography } from '@mui/material';
import { faPresentationScreen, faHome, faShoppingCart, faScrewdriverWrench, faOilCanDrip, faCogs, faCashRegister, faHandHoldingBox, faDisplay, faChartLine, faSparkles, faMagnifyingGlassPlus, faSquarePollVertical, faWrench, faTachometerAltSlowest, faTint, faVials, faVial, faChartPie, faScrewdriver, faStethoscope, faMoneyCheckEdit, faBook, faSave, faDownload, faUserCog, faIndustryWindows, faPencil, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../language/setLanguageText';


const menu_object = [{
    icon: faMagnifyingGlass,
    buttonText: "Product Search",
    title: "Product Search",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Factory Floor</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Product Search</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Product Search</Typography>,
    link: "/",
    area: "home",
    menu: "",
    noCard: true,
    order: 0,
    submenu: [{
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Search")}</Typography>,
        link: "/search/search",
        area: "search",
        menu: "search",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Product</Typography>,
        link: "/search/search/product",
        area: "search",
        menu: "product",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Matching Products</Typography>,
        link: "/search/search/matching",
        area: "search",
        menu: "matching",
        hidden: true
    }]
},

// {
//     icon: faShoppingCart,
//     title: "Catalogue",
//     text: "Over 185,000 products at your fingertips for all your general production needs. Whether durable or consumables requirements, available 24/7.",
//     bullets: [
//         "300+ brands available",
//         "185,000 available products",
//         "Competitive pricing structure",
//         "Technical backup",
//         "Quality assurance",
//         "Fast logistics",
//         "Secure environment",
//         "Satisfaction guarantee"
//     ],
//     video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Catalogue.mp4",
//     buttonText: "Catalogue",
//     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Catalogue</Typography>,
//     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Catalogue</Typography>,
//     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Catalogue</Typography>,
//     link: "/buyers/catalogue",
//     area: "buyers",
//     menu: "catalogue",
//     hidden: true,
//     submenu: [{
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Products details</Typography>,
//         link: "/buyers/catalogue/product",
//         area: "buyers",
//         menu: "product",
//         hidden: true
//     }, {
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Products search</Typography>,
//         link: "/buyers/catalogue/search",
//         area: "buyers",
//         menu: "search",
//         hidden: true
//     }]
// }, 

// {
//     icon: faUserCog,
//     title: "Engineer",
//     text: "Over 500,000 production tooling products available with our technical engineers store, sourced from all the world’s leading premium brands. ",
//     bullets: [
//         "500,000+ tooling products",
//         "Premium branded tooling",
//         "Extensive filtering system",
//         "Technical drawings and downloads",
//         "Browse 3D CAD drawings online",
//         "Component matching tool",
//         "Competitive pricing structure",
//         "Technical backup"
//     ],
//     video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/01/Engineer.mp4",
//     buttonText: "Engineer",
//     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Engineer</Typography>,
//     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Engineer</Typography>,
//     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Engineer</Typography>,
//     link: "/engineers/catalogue",
//     area: "engineers",
//     menu: "catalogue",
//     hidden: true,
//     submenu: [{
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Results</Typography>,
//         link: "/engineers/catalogue/results",
//         area: "engineers",
//         menu: "results",
//         hidden: true
//     }, {
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Product</Typography>,
//         link: "/engineers/catalogue/product",
//         area: "engineers",
//         menu: "product",
//         hidden: true
//     }, {
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Matching Components</Typography>,
//         link: "/engineer/catalogue/matching",
//         area: "engineers",
//         menu: "matching",
//         hidden: true
//     }]
// }, 

{
    icon: faCashRegister,
    text: "Login to your Tool Management Vending Portal service.",
    title: "Vending",
    order: 5,
    text: "You can increase your productivity with our business solutions. With our holistic vending solution, you get an automated dispensing system that enables you to manage your accounts, monitor your product usage, and track your monthly requirements.",
    bullets: [
        "Control of your processes",
        "Manage stocks and assets",
        "Automated ordering",
        "Reduce waiting times",
        "Reduce costs"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Vend.mp4",
    buttonText: "Vending",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Vending</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Vending</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Vending</Typography>,
    //externalLink: "https://supplysystem.supplypro.com/Login.aspx"
    link: "/vend/dashboard",
    area: "vend",
    menu: "dashboard",
    url: "https://supplysystem.supplypro.com/Login.aspx"
}, {
    icon: faOilCanDrip,
    title: "MWF",
    order: 4,
    text: "Managing a safe and healthy environment is an important aspect of health and safety in a world driven by sustainability. Our Oil and Lubrication Division has developed and implemented a world-class data recording portal, which manages PH and concentration levels as well as bacteria factors and publishes historical and current measurements.",
    bullets: [
        "Enterprise/Factory-wide management of machines",
        "Customised entry attributes",
        "Multi-machine exports and service reports",
        "Data entry and machine access via QR codes",
        "Instant access to technical and safety information",
        "Machine performance dashboards",
        "Multi-device data entry",
        "Access with multiple permissions"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/01/Lubrication.mp4",
    buttonText: "MWF",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">MWF</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>MWF</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >MWF</Typography>,
    //disabled: true,
    link: "/coolantcare",
    area: "coolantcare",
    menu: "dashboard",
    //menu: "", //same as the first child
    submenu: [{
        icon: faTachometerAltSlowest,
        text: "Dashboard",
        title: "MWF",
        buttonText: "MWF",
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Dashboard</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Dashboard</Typography>,
        titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Dashboard</Typography>,
        //disabled: true,
        link: "/coolantcare",
        area: "coolantcare",
        menu: "dashboard",
    }, {
        icon: faCogs,
        text: "Machines",
        title: "Machines",
        buttonText: "Machines",
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Machines</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Machines</Typography>,
        titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Machines</Typography>,
        //disabled: true,
        link: "/coolantcare/machines",
        area: "coolantcare",
        menu: "machines",
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Machine</Typography>,
        link: "/coolantcare/machine",
        area: "coolantcare",
        menu: "machine",
        hidden: true,
        submenu: [
            {
                breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Entry")}</Typography>,
                link: "/coolantcare/machine/entry",
                area: "coolantcare",
                menu: "entry",
                hidden: true
            }]
    }, {
        icon: faVials,
        text: "Products",
        title: "Products",
        buttonText: "Products",
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Products</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Products</Typography>,
        titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Products</Typography>,
        //disabled: true,
        link: "/coolantcare/products",
        area: "coolantcare",
        menu: "products",
    }, {
        icon: faChartPie,
        text: "Reports",
        title: "Reports",
        buttonText: "Reports",
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Reports</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Reports</Typography>,
        titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Reports</Typography>,
        //disabled: true,
        link: "/coolantcare/reports",
        area: "coolantcare",
        menu: "reports",
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Scanner")}</Typography>,
        link: "/coolantcare/qr-code",
        area: "coolantcare",
        menu: "scanner",
        hidden: true
    },
    // {
    //     icon: faScrewdriver,
    //     text: "Maintenance",
    //     title: "Maintenance",
    //     buttonText: "Maintenance",
    //     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Maintenance</Typography>,
    //     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Maintenance</Typography>,
    //     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Maintenance</Typography>,
    //     //disabled: true,
    //     link: "/coolantcare/maintenance",
    //     area: "coolantcare",
    //     menu: "maintenance",
    // }, {
    //     icon: faStethoscope,
    //     text: "Diagnostic",
    //     title: "Diagnostic",
    //     buttonText: "Diagnostic",
    //     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Diagnostic</Typography>,
    //     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Diagnostic</Typography>,
    //     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Diagnostic</Typography>,
    //     //disabled: true,
    //     link: "/coolantcare/diagnostic",
    //     area: "coolantcare",
    //     menu: "diagnostic",
    // }, 
    {
        icon: faMoneyCheckEdit,
        text: "Service",
        title: "Service",
        buttonText: "Service",
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Service Reports</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Service Reports</Typography>,
        titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Service Reports</Typography>,
        //disabled: true,
        link: "/coolantcare/service",
        area: "coolantcare",
        menu: "service",
    },
        // {
        //     icon: faPresentationScreen,
        //     text: setLanguageText("Display"),
        //     title: setLanguageText("Display"),
        //     buttonText: setLanguageText("Display"),
        //     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Display")}</Typography>,
        //     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>{setLanguageText("Display")}</Typography>,
        //     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >{setLanguageText("Display")}</Typography>,
        //     //disabled: true,
        //     link: "/coolantcare/display",
        //     area: "coolantcare",
        //     menu: "display",
        // },
    ]
}, {
    icon: faMagnifyingGlassPlus,
    title: "Parametric",
    order: 1,
    text: "Our Parametric store offers a search like never before, which allows you to find products based on specific parameters. It’s a powerful feature for users who know exactly what they’re looking for. Allowing the user to search for products that match their exact specifications without wasting their time on irrelevant items.",
    bullets: [
        "Search by the parameter in detail",
        "Over 500,000 tooling products",
        "Premium tooling brands",
        "Options for extensive filtering",
        "3D CAD technical drawings can be viewed in the browser and downloaded",
        "Components that match tooling",
        "Competitive pricing structure"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Parametric.mp4",
    buttonText: "Parametric",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Parametric</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Parametric</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Parametric</Typography>,
    link: "/parametric/search",
    area: "parametric",
    menu: "search",
    submenu: [{
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Results</Typography>,
        link: "/parametric/search/results",
        area: "parametric",
        menu: "search",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Product</Typography>,
        link: "/parametric/search/product",
        area: "parametric",
        menu: "product",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Matching Components</Typography>,
        link: "/parametric/search/matching",
        area: "parametric",
        menu: "matching",
        hidden: true
    }]
}, {
    icon: faCogs,
    title: "Application",
    order: 2,
    text: "The application search facility allows users to quickly and easily find products that meet their specific machining needs. The process begins by selecting the cutting tool technology, followed by the type of tool. The next steps are automatically indicated as a result of these selections; users do not have to waste time searching for what they need.",
    bullets: [
        "An application-led search approach",
        "Over 500,000 tooling products",
        "Premium tooling brands",
        "Options for extensive filtering",
        "3D CAD technical drawings can be viewed in the browser and downloaded",
        "Components matched to tools",
        "Competitive pricing structure"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Application.mp4",
    buttonText: "Application",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Application</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Application</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Application</Typography>,
    link: "/application/search",
    area: "application",
    menu: "search",
    submenu: [{
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Results</Typography>,
        link: "/application/search/results",
        area: "application",
        menu: "search",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Product</Typography>,
        link: "/application/search/product",
        area: "application",
        menu: "product",
        hidden: true
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Matching Components</Typography>,
        link: "/application/search/matching",
        area: "application",
        menu: "matching",
        hidden: true
    }]
}, {
    icon: faHandHoldingBox,
    title: "Solution",
    order: 7,
    text: "Full turnkey component manufacturing solution. A service that makes it easy to identify the require tooling solutions allowing confidence in accurate production process. From identifying the best in class tooling solutions, all the way through to quotation, confirmation, delivery and onsite support.",
    bullets: [
        "A fully customised solution to meet your needs",
        "Documents related to your solution can be uploaded",
        "The ability to chat live with an engineer 24/7",
        "Multiple solutions that can be tracked",
        "Status and comment updates sent via email",
        "Specify a requirement deadline"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Solution.mp4",
    buttonText: "Solution",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Solution</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Solution</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Solution</Typography>,
    link: "/solution/dashboard",
    area: "solution",
    menu: "dashboard",
    //menu: "", //same as the first child
    submenu: [{
        icon: faTachometerAltSlowest,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Dashboard</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Dashboard</Typography>,
        link: "/solution/dashboard",
        area: "solution",
        menu: "dashboard",
    }, {
        icon: faSave,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">New Solution</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>New Solution</Typography>,
        link: "/solution/new",
        area: "solution",
        menu: "new",
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">View Request</Typography>,
        link: "/solution/view",
        area: "solution",
        menu: "view",
        hidden: true
    }]
}, {
    icon: faScrewdriverWrench,
    title: "Custom Tool",
    order: 3,
    text: "Increasing the efficiency and effectiveness of your production to make your company more profitable. When there isn’t a standard solution available, why not design one? With Custom, you can design it, and we will make it. With a quick turnaround for those customers who need it now.",
    bullets: [
        "Providing you with a fully customised tooling solution",
        "Tooling request form with design wizard",
        "Indicate the date by which the tool is needed",
        "Documents related to your solution can be uploaded",
        "Chat with an engineer live 24/7",
        "The ability to track multiple solutions",
        "Status and comment updates are sent via email"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Custom.mp4",
    buttonText: "Custom Tool",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Custom Tool</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Custom Tool</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Custom Tool</Typography>,
    //externalLink: "https://www.cis-tools.co.uk/quotation-request/"
    link: "/design/dashboard",
    area: "design",
    menu: "dashboard",
    submenu: [{
        icon: faTachometerAltSlowest,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Dashboard</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Dashboard</Typography>,
        link: "/design/dashboard",
        area: "design",
        menu: "dashboard",
    }, {
        icon: faSave,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">New Design</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>New Design</Typography>,
        link: "/design/new",
        area: "design",
        menu: "new",
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">View Request</Typography>,
        link: "/design/view",
        area: "design",
        menu: "view",
        hidden: true
    }]
},
// {
//     icon: faDisplay,
//     text: "Search and Download CAD CAM data files for products.",
//     title: "CAD/CAM",
//     buttonText: "CAD/CAM",
//     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">CAD/CAM</Typography>,
//     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>CAD/CAM</Typography>,
//     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >CAD/CAM</Typography>,
//     link: "/cadcam/search",
//     area: "cadcam",
//     menu: "search",
//     submenu: [{
//         icon: faMagnifyingGlassPlus,
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Search</Typography>,
//         menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Search</Typography>,
//         link: "/cadcam/search",
//         area: "cadcam",
//         menu: "search",
//     }, {
//         icon: faDownload,
//         breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Downloads</Typography>,
//         menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Downloads</Typography>,
//         link: "/cadcam/downloads",
//         area: "cadcam",
//         menu: "downloads",
//     }]
// },
{
    icon: faChartLine,
    title: "CNC Metrics",
    order: 8,
    text: "Plug & Play manufacturing analytics software. Maximise manufacturing productivity. The industry’s MOST accessible and affordable manufacturing analytics software platform.",
    bullets: [
        "Improve productivity with real-time decision-making&nbsp;",
        "Solving problems from the root cause",
        "Making informed decisions",
        "An improved culture and communication system",
        "Increase the efficiency of resource planning and job costing",
        "Grow profitability and capacity"
    ],
    video: null,
    buttonText: "CNC Metrics",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">CNC Metrics</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>CNC Metrics</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >CNC Metrics</Typography>,
    //externalLink: "https://www.cis-tools.co.uk/cnc-analytics/"
    link: "/cnc/",
    area: "cnc",
}, {
    icon: faSquarePollVertical,
    title: "KPI",
    order: 8,
    text: "How is CIS performing within your business? Monitor key performance indicators that are critical not just to industry, but to your production. Covering productivity improvements, lubrication management, delivery performance and much more.",
    bullets: [
        "User-customisable dashboard",
        "Keep track of factory performance",
        "Visual aid on overall performance",
        "Data driven reviews",
        "Full transparency nothing hidden"
    ],
    video: null,
    buttonText: "KPI",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">KPI</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>KPI</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >KPI</Typography>,
    link: "/kpi/dashboard",
    area: "kpi",
    menu: "dashboard",
    submenu: [{
        icon: faTachometerAltSlowest,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Dashboard</Typography>,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Dashboard</Typography>,
        link: "/kpi/dashboard",
        area: "kpi",
        menu: "dashboard",
    },
    {
        icon: faPencil,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>{setLanguageText("Edit KPIs")}</Typography>,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Edit  KPIs")}</Typography>,
        link: "/kpi/edit",
        area: "kpi",
        menu: "edit",
    }]
}, {
    icon: faWrench,
    title: "Jig/Fixtures",
    text: "The system offers custom-made fixturing and work-holding solutions to provide enhanced performance and cost-effectiveness. The system allows users to get exactly what they need, resulting in improved productivity.",
    bullets: [
        "Providing you with a fully customised JIG/Fixtures solution",
        "Design wizard for JIG/Fixtures requests",
        "Provide a date by which the item is required",
        "Upload your solution’s related documents",
        "Chat with an engineer live",
        "Solution with multiple trackable"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Jig.mp4",
    buttonText: "Jig/Fixtures",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Jig/Fixtures</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Jig/Fixtures</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Jig/Fixtures</Typography>,
    //disabled: true,
    link: "/fixtures/dashboard",
    area: "fixtures",
    menu: "dashboard",
    submenu: [{
        icon: faTachometerAltSlowest,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Dashboard</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Dashboard</Typography>,
        link: "/fixtures/dashboard",
        area: "fixtures",
        menu: "dashboard",
    }, {
        icon: faSave,
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">New Jig/Fixture</Typography>,
        menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>New Jig/Fixture</Typography>,
        link: "/fixtures/new",
        area: "fixtures",
        menu: "new",
    }, {
        breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">View Request</Typography>,
        link: "/fixtures/view",
        area: "fixtures",
        menu: "view",
        hidden: true
    }]
},
// {
//     icon: faSparkles,
//     text: "Find all available promotions and manufacturer offers.",
//     title: "Promotions",
//     buttonText: "Promotions",
//     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Promotions</Typography>,
//     menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Promotions</Typography>,
//     titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Promotions</Typography>,
//     //disabled: true,
//     link: "/promotions/",
//     area: "promotions",
//     //hidden : true,
//     //menu: "promotions"
// },
{
    icon: faBook,
    title: "Resources",
    order: 9,
    text: "The resources page provides users with a centralised collection of resources and guides that they can use to make more informed decisions.",
    bullets: [
        "Video and PDF information",
        "Education information",
        "Best practice",
        "H&S documentation",
        "Calculators",
        "Productivity Improvement Analysis PIA",
        "Organise categories",
        "Published resources and guides"
    ],
    video: "https://www.cis-tools.co.uk/wp-content/uploads/2023/02/Resource.mp4",
    buttonText: "Resources",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Resources</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>Resources</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Resources</Typography>,
    //disabled: true,
    link: "/resources",
    area: "resources",
    //menu: "reports",
}, {
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Settings</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >Settings</Typography>,
    link: "/settings/company",
    area: "settings",
    //menu: "company",
    hidden: true,
    noCard: true,
    submenu: [
        {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Users</Typography>,
            link: "/settings/users",
            area: "settings",
            menu: "users",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Add Users</Typography>,
            link: "/settings/users/add",
            area: "settings",
            menu: "add",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Company</Typography>,
            link: "/settings/company",
            area: "settings",
            menu: "company",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">All Companies</Typography>,
            link: "/settings/allcompanies",
            area: "settings",
            menu: "allcompanies",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Application</Typography>,
            link: "/settings/app",
            area: "settings",
            menu: "app",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Account</Typography>,
            link: "/settings/account",
            area: "settings",
            menu: "account",
            hidden: true
        }, {
            breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">Chat</Typography>,
            link: "settings/chat",
            area: "settings",
            menu: "chat",
            hidden: true
        }
        // ,{
        //     breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">{setLanguageText("Favourites")}</Typography>,
        //     link: "/settings/favourites",
        //     area: "settings",
        //     menu: "favourites",
        //     hidden: true
        // }
    ]
}, {
    icon: faHome,
    buttonText: "CIS Tools",
    title: "CIS Tools",
    breadcrumbComponent: <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }} component="h3">CIS Tools</Typography>,
    menuComponent: <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>CIS Tools</Typography>,
    titleComponent: <Typography variant="h5" sx={{ fontWeight: '400', color: (theme) => theme.palette.text.primary }} >CIS Tools</Typography>,
    externalLink: "https://www.cis-tools.co.uk/"
}]


export default menu_object

