import React, { useContext } from 'react';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import setLanguageText from '../language/setLanguageText';
import TemplateTable from './TemplateTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from '../store/UserContext';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import AddTemplate from './AddTemplate';
import { CompanyContext } from '../store/CompanyContext';

const Templates = ({ type }) => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);

    const templates = useQuery({
        queryKey: ['templates', type],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'get',
            params: {
                "template_type": JSON.stringify(type),
            }
        }),
        enabled: !!type,
        retry: 1
    })

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        padding={1}
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Visibility Templates")}</Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Tooltip title="Refresh Templates">
                                <Avatar
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => templates.refetch()}
                                >
                                    <FontAwesomeIcon icon={faArrowsRotate} spin={templates.isFetching} />
                                </Avatar>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <TemplateTable templates={templates} />
                    </Grid>
                    <Grid item xs={12}>
                        <AddTemplate type={type} refetch={templates.refetch} />
                    </Grid>
                </Grid>

            </TabContent>
        </>
    );
};

export default Templates;


