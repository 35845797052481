import React, { useEffect, useState, } from "react";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from "@mui/material";

const AreaDetails = ({ area, full }) => {

    const [column1, setColumn1] = useState()
    const [column2, setColumn2] = useState()
    const [videoLoading, setVideoLoading] = useState(true);

    const handleVideoLoad = () => {
        setVideoLoading(false);
    };

    useEffect(() => {
        //console.log(area)
        if (area?.bullets) {
            const clone = structuredClone(area?.bullets)
            //split area into 2 columns
            let half = Math.ceil(clone.length / 2);
            let firstHalf = clone.splice(0, half);
            let secondHalf = clone.splice(-half);
            setColumn1(firstHalf)
            setColumn2(secondHalf)
        }
    }, [area])

    return (
        <Grid container item spacing={2}>
            <Grid item xs={full ? 6 : 12}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {area?.text}
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12} md={6}>
                        <List dense={true}
                            sx={{
                                '& .MuiListItemIcon-root': {
                                    minWidth: '30px',
                                    top: '11px',
                                    left: 0,
                                    position: 'absolute'
                                },
                                '& .MuiListItemText-root': {
                                    marginLeft: '10px'
                                },
                                '& svg': { color: (theme) => theme.palette.primary.main }
                            }}
                        >
                            {column1 && column1.map((point, i) => {
                                return (
                                    <ListItem key={i}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={point}
                                        />
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <List dense={true}
                            sx={{
                                '& .MuiListItemIcon-root': {
                                    minWidth: '30px',
                                    top: '11px',
                                    left: 0,
                                    position: 'absolute'
                                },
                                '& .MuiListItemText-root': {
                                    marginLeft: '10px'
                                },
                                '& svg': { color: (theme) => theme.palette.primary.main }
                            }}
                        >
                            {column2 && column2.map((point, i) => {
                                return (
                                    <ListItem key={i}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={point}
                                        />
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            {area?.video ?
                <Grid item container xs={full ? 6 : 12}>
                    {videoLoading ? (
                        <Skeleton variant="rect" width="100%" height={320} />
                    ) : null}
                    <video
                        width="100%"
                        src={area.video}
                        loop
                        muted
                        autoPlay
                        //controls
                        onCanPlayThrough={handleVideoLoad}
                        style={{ display: videoLoading ? 'none' : 'block' }}
                    />

                </Grid>
                : null}
        </Grid>
    );
}
export default AreaDetails;