
export const UPDATE_BRANDS = 'UPDATE_BRANDS';

export const BrandsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BRANDS:
      return {
          allBrands:  action.payload.allBrands ? action.payload.allBrands : state.allBrands,
          openSearchBrands:  action.payload.openSearchBrands ? action.payload.openSearchBrands : state.openSearchBrands,
          selectedBrands:  action.payload.selectedBrands ? action.payload.selectedBrands : state.selectedBrands
      };
    default:
      return state;
  }
};

