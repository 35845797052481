import createMainQuery from "./createMainQuery";
import search_pipline from "./searchPipeline.json";

export default function queryGetTools({ term, from, size, filters, sort, type }) {


	let query = {};

	console.log(term)

	if (!term) {

		query = {
			"query": {
				"bool": {
					"must": [
						{
							"match_all": {}
						}
					],
					filter: []
				}
			},
			...search_pipline,
			"from": from,
			"size": size,
			"track_total_hits": true
		};

		if (filters) {
			query.query.bool.filter.push(
				{
					"terms": filters ? filters : []
				}
			)
		}

		query.query.bool.filter.push({
			"range": {
				"price": {
					"gt": 0
				}
			}
		});

	} else {

		let { minScore, mainQuery, highlight } = createMainQuery({ term, type })

		if (filters) {
			mainQuery.bool.filter.push({
				"terms": filters ? filters : []
			});
			mainQuery.bool.filter.push({
				"range": {
					"price": {
						"gt": 0
					}
				}
			});
		}

		query = {
			"query": mainQuery,
			...search_pipline,
			"min_score": minScore,
			"track_scores": true,
			"from": from,
			"size": size,
			"track_total_hits": true
		};

		if (highlight) {
			query.highlight = highlight
		}
	}

	if (sort) {
		query.sort = [
			{ ...sort?.value ? sort.value : null }
		];
	}

	//query.explain = true;
	//console.log('query', query)

	return query;
}

