import React, { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import { Alert, Grid, Skeleton, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { ShopContext } from "../../store/ShopContext";
import { uniq } from "lodash";
import { filterTree, expandFilteredNodes, getIDsExpandFilter } from "../helpers/categoryFilterTree";
import { useSearchParams } from "react-router-dom";

const BuyerCategoryTree = ({ isFetching }) => {

    const [openLevels, setOpenLevel] = useState(["0"]);
    const { shop, brands } = useContext(ShopContext);
    const [searchParams, setSearchParams] = useSearchParams();


    const handleToggle = (event, nodeIds) => {
        setOpenLevel(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        //setSelected(nodeIds);
    };

    useEffect(() => {
        if (shop?.currentCat?.cpID) {
            //console.log(JSON.parse(shop.currentCat.cpID))
            const strs = Array.from(shop.currentCat.cpID, x => `${x}`);
            setOpenLevel(strs)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    }, [shop.currentCat])

    //console.log(openLevels)

    const CustomContent = forwardRef(function CustomContent(props, ref) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
            category
        } = props;

        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
        } = useTreeItem(nodeId);

        const icon = iconProp || expansionIcon || displayIcon;

        const handleExpansionClick = (e) => {
            e.preventDefault();
            handleExpansion(e); //set expanded
        };

        const handleSelectionClick = (e, props) => {
            //console.log(props)
            if (props.category.count > 0) {

                e.preventDefault();
                handleSelection(e); //set selected
                if (props.id !== 0) {
                    //if (Object.values(category.cpID).length < 3) {
                    if (Object.values(JSON.parse(props.category.cpID)).length < 3) {
                        //top 3 levels
                        setSearchParams({ 'c': props.id })
                        //navigate(`/buyers/catalogue?c=${props.id}`);
                        //getCurrentCategory.mutate(parseInt(props.id));
                        //getCurrentCategory.mutate({ 'categoryLevel': parseInt(props.id), 'type': 'categoryLevel' });
                        //setSelectedCategory(props.ID)
                    } else {
                        console.log(props)
                        //bottom level
                        setSearchParams({ 'c': props.category.pID, 'p': props.id });
                        //navigate(`/buyers/catalogue/products?c=${props.id}`);
                        //getCurrentProductCategory.mutate(parseInt(props.id));
                    }
                }
            }
        };

        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                ref={ref}
            >
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    {icon}
                </div>
                <Typography
                    onClick={e => handleSelectionClick(e, props)}
                    component="div"
                    className={classes.label}
                >
                    {label}
                </Typography>
            </div>
        );
    });

    //add callback to prvent image re-fetch
    const CustomeTreeItem = (category) => {
        //console.log(category)
        if (typeof category.ID === "number") {
            return (
                <>
                    {category.ID === 0 ?
                        <>
                            {Array.isArray(category.children) ? category.children.map((subCategory, i) => (
                                <React.Fragment key={i}>
                                    <CustomeTreeItem  {...subCategory} />
                                </React.Fragment>

                            ))
                                : null}

                        </>
                        :
                        <TreeItem
                            sx={{ color: 'text.primary' }}
                            //key={category.ID}
                            nodeId={category.ID.toString()}
                            ContentProps={{
                                id: category.ID.toString(),
                                category: category
                            }}
                            ContentComponent={CustomContent}
                            // onLabelClick={(e) => {
                            // 	e.preventDefault();
                            // }}
                            label={
                                <Grid item container alignItems="center" sx={{ margin: '5px 0' }} >
                                    {/* <Typography>{category.cName} ({category.count})</Typography> */}
                                    <Typography>{category.cName}</Typography>
                                </Grid>
                            }
                        >
                            {Array.isArray(category.children) ? category.children.map((subCategory, i) => (
                                <React.Fragment key={i}>
                                    <CustomeTreeItem  {...subCategory} />
                                </React.Fragment>
                            )) : null}
                        </TreeItem>
                    }
                </>
            )
        }
        return null
    };

    const [filteredData, setFilteredData] = useState();

    const onFilterMouseUp = (e) => {
        //console.log(e.target.value)
        const value = e.target.value;
        const filter = value.trim();
        let expandedTemp = openLevels;
        if (!filter) {
            setFilteredData(() => shop.allCats);
            setOpenLevel(['0']);
            return;
        }
        //let filtered = filterTree(shop.allCats, filter)
        let filtered = shop.allCats.map((categories) => filterTree(categories, filter))
        //console.log(value)
        //console.log(filtered)

        // filtered = expandFilteredNodes(filtered, filter);
        // if (filtered && filtered.children) {
        //     expandedTemp = [];
        //     expandedTemp.push(...getIDsExpandFilter(filtered));
        // }
        // //console.log(expandedTemp)
        // //console.log(filtered)
        // setOpenLevel(uniq(expandedTemp));
        //console.log(filtered)
        setFilteredData([filtered[0]]);
    };

    useEffect(() => {
        if (shop?.allCats && Object.values(shop.allCats).length) {

            setFilteredData(shop.allCats)
        }
    }, [shop])


    return (

        <>

            {filteredData && Object.values(filteredData).length && brands?.currentBrands && Object.values(brands.currentBrands).length !== 0 ?
                <>
                    <TextField
                        //disabled={isFetching}
                        fullWidth
                        //label="Find a Category..."
                        onKeyUp={onFilterMouseUp}
                        sx={{
                            width: '100%',
                            '& input': { zIndex: 2 },
                            '& legend': { display: 'none' },
                            '& fieldset': {
                                backgroundColor: theme => theme.palette.background.paper,
                                color: theme => theme.palette.text.primary,
                            },
                            marginBottom: '1em'
                        }}
                        placeholder="Find a Category..."
                    />
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={openLevels}
                        selected={shop?.currentCategory?.ID.toString() || '0'}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                    >
                        {filteredData.map((category, i) => (
                            <React.Fragment key={i}>
                                <CustomeTreeItem {...category} />
                            </React.Fragment>
                        ))}
                    </TreeView>
                </>
                : brands?.currentBrands && Object.values(brands?.currentBrands).length === 0 && !isFetching && filteredData ?
                    <Alert severity="info">This company has no access to brands</Alert>
                    :
                    <>
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                        <Skeleton height={35} />
                    </>
            }
        </>
    )
};

export default BuyerCategoryTree;
