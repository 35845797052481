import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import AreaBreadcrumb from "./AreaBreadcrumb";
import { isMobileOnly } from 'react-device-detect';
import IconMenu from "./IconMenu";
import { isMobile } from "react-device-detect";
import ShopBreadcrumbs from "../shop/breadcrumb/ShopBreadcrumbs";

const InnerHeaderWrap = ({ props, children }) => {

    //console.log(props)

    const [title, setTitle] = useState()

    return (

        <>
            <Box
                sx={{
                    padding: '.5em 1em',
                    width: '100%',
                    background: (theme) => theme.palette.background.header,
                    background: (theme) => theme.palette.background.breadcrumb,
                    borderColor: '#0000001a',
                    borderWidth: ' 0 0 1px 0',
                    borderStyle: 'solid',
                    position: 'relative',
                    zIndex: 10,
                }} >
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    spacing={2} >
                    <Grid item xs>
                        <AreaBreadcrumb setTitle={setTitle} />
                    </Grid>
                    {!isMobileOnly ?
                        <Grid
                            item
                            container
                            xs="auto"
                            spacing={2}
                            alignContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid item>
                                <IconMenu />
                            </Grid>
                        </Grid>
                        : null}
                </Grid>
            </Box>

            {children ?
                <Box
                    sx={{
                        padding: isMobileOnly ? '.5em' : '1em',
                        width: '100%',
                        background: (theme) => theme.palette.background.header,
                        position: 'relative',
                        zIndex: 10,
                    }} >
                    {children}
                </Box>
                : null}
            {props?.catalogue || props?.application || props?.engineers || props?.parametric ?
                <ShopBreadcrumbs {...props} />
            : null }
        </>

    );
};
export default InnerHeaderWrap;
