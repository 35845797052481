import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
//import TableColumns from './TableColumns';

const ReactBrandsTable = ({
    columns,
    data,
    hidden,
    sortColumn,
    sortOrder,
    handleSorting,
    //addCustomRowClick
}) => {
    //console.log(sortColumn)
    //console.log(sortOrder)
    //Render the UI for your table
    return (
        <Box
            sx={{
                overflowY: 'auto',
                overflowY: 'auto',
                width: '100%',
                //height: 'calc(100vh - 400px)'
            }}
        >
            <TableContainer sx={{
                maxHeight: '500px',
                //border: (theme) => `1px solid ${theme.palette.table.border}`
            }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow
                            sx={{ background: (theme) => theme.palette.background.header }}
                        >
                            {columns.map((column, i) => {
                                //console.log(column)
                                if (!column.hidden && !hidden.includes(column.accessor)) {
                                    //console.log(name)
                                    return (
                                        <TableCell
                                            key={i}
                                            padding={column.disablePadding ? 'none' : 'normal'}
                                            sx={{
                                                background: (theme) => sortColumn === column.accessor ? theme.palette.primary.light : theme.palette.primary.main,
                                                color: (theme) => theme.palette.primary.contrastText,
                                                '&:hover ': {
                                                    color: (theme) => theme.palette.primary.contrastText,
                                                },
                                                maxWidth: column.maxWidth ? column.maxWidth : 'auto'
                                            }}
                                        //sortDirection={orderBy === props.id ? order : false}
                                        >
                                            <Box
                                                sx={{
                                                    height: '80%',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    width: '1px',
                                                    background: (theme) => theme.palette.primary.light,
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: '10%'
                                                }}
                                            />
                                            {!column.noSort && column.accessor ?
                                                <TableSortLabel
                                                    active={sortColumn === column.accessor}
                                                    direction={sortColumn === column.accessor ? sortOrder : "asc"}
                                                    onClick={(e) => handleSorting(column)}
                                                >
                                                    {column.Header ? column.Header() : column.name}
                                                </TableSortLabel>
                                                :
                                                column.Header ? column.Header() : column.name
                                            }
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && columns && data.map((item, ind) => {
                            return (
                                <TableRow
                                    hover
                                    //onClick={() => addCustomRowClick(item)}
                                    key={ind}
                                    sx={{
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: (theme) => theme.palette.background.default,
                                        },
                                        // hide last border
                                        '&:last-child td, &:last-child th': {
                                            borderBottom: 0,
                                        },
                                    }}>
                                    {columns.map((column, index) => {
                                        //console.log(props)
                                        //console.log(item)
                                        const tData = item[column.accessor] ? item[column.accessor] : "——";
                                        if (!column.hidden && !hidden.includes(column.accessor)) {
                                            return (
                                                <TableCell
                                                    key={index}
                                                    sx={{ maxWidth: column.maxWidth ? column.maxWidth : 'auto' }}
                                                >
                                                    {column.Cell ? column.Cell(item) : tData}
                                                </TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table >
            </TableContainer>
        </Box>
    );
}

export default ReactBrandsTable