import React, { useContext, useEffect, useState } from 'react';
import {
    darken,
    lighten,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Switch,
    FormControlLabel,
    Typography,
    Divider,
    Button
} from '@mui/material';
import { APP_THEME } from '../../../store/LayoutReducers';
import { LayoutContext } from '../../../store/LayoutContext';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../language/setLanguageText';
import { Box } from '@mui/system';
import ColourPicker from './ColourPicker';
import config from '../../../config';
import { useMutation } from 'react-query';
import Loader from '../../../reusable/Loader';
import axiosRequest from '../../../axios/axoisRequest';

const ThemeForm = () => {

    const { user } = useContext(UserContext);
    const { dispatchTheme, theme } = useContext(LayoutContext);
    const [first, setFirst] = useState(true);
    const [resetTheme, setResetTheme] = useState(false);
    const [currentTheme, setCurrentTheme] = useState();
    const [originalTheme, setOriginalTheme] = useState();

    useEffect(() => {
        //set old them incase user reverts it
        if (first) {
            //console.log('set theme')
            const copy = structuredClone(theme.app);
            setOriginalTheme(copy); //copy object
            setFirst(false)
        }
        const copy = structuredClone(theme.app)
        setCurrentTheme(copy); //copy object
    }, [first, theme])

    const handleChange = (colourMode, parentName, fieldName, color) => {
        //console.log(colourMode, object, parentName, fieldName, color)
        const copy = structuredClone(currentTheme)
        copy[`${colourMode}`][`${parentName}`][`${fieldName}`] = color
        setCurrentTheme(copy)
    }

    const testTheme = (theme) => {
        dispatchTheme({
            type: APP_THEME,
            payload: {
                app: theme
            }
        });
        setResetTheme(true);
    }

    const mutation = useMutation((theme) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'theme',
            api_key: user.api_key,
            method: 'post',
            body: {
                theme_type: 'app',
                app_id: config.APPID,
                app_theme: JSON.stringify(theme)
            }
        })
    })

    const saveTheme = (theme) => {
        console.log(theme)
        setResetTheme(false)
        dispatchTheme({
            type: APP_THEME,
            payload: {
                app: theme
            }
        });
        mutation.mutate(theme)
    }

    return (
        <>
            {currentTheme &&
                <Grid container spacing={2}>

                    {Object.entries(currentTheme).map((colourMode, i) => {

                        const mode = colourMode[0]
                        const object = colourMode[1]
                        const primary = object.primary
                        const menu = object.menu
                        const secondary = object.secondary
                        const factory = object.factory
                        const background = object.background
                        const cards = object.cards
                        const table = object.table
                        const charts = object.charts

                        return (
                            <Grid item xs={12} lg={6} key={i}>
                                <Box>
                                    <Card sx={{ background: mode !== 'light' ? '#222' : '#FFF' }} >
                                        <CardHeader
                                            title={<Typography component="p" variant="h4" sx={{
                                                textTransform: 'capitalize',
                                                color: mode === 'light' ? '#222' : '#FFF'
                                            }}>{mode} mode</Typography>}
                                        />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Primary colour</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Main colour" parentName="primary" fieldName="main" fieldValue={primary.main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Text contrast" parentName="primary" fieldName="contrastText" fieldValue={primary.contrastText} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Secondary colour</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Main colour" parentName="secondary" fieldName="main" fieldValue={secondary.main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Text contrast" parentName="secondary" fieldName="contrastText" fieldValue={secondary.contrastText} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Sidebar menu</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Top level" parentName="menu" fieldName="first" fieldValue={menu.first} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Second level" parentName="menu" fieldName="second" fieldValue={menu.second} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Third level" parentName="menu" fieldName="third" fieldValue={menu.third} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Factory floor</Typography>
                                                    </Grid>
                                                    <ColourPicker name="First" parentName="factory" fieldName="primary_main" fieldValue={factory.primary_main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="First highlight" parentName="factory" fieldName="primary_highlight" fieldValue={factory.primary_highlight} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="First shadow" parentName="factory" fieldName="primary_shadow" fieldValue={factory.primary_shadow} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Second" parentName="factory" fieldName="secondary_main" fieldValue={factory.secondary_main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Second highlight" parentName="factory" fieldName="secondary_highlight" fieldValue={factory.secondary_highlight} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Second shadow" parentName="factory" fieldName="secondary_shadow" fieldValue={factory.secondary_shadow} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Third" parentName="factory" fieldName="third_main" fieldValue={factory.third_main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Third highlight" parentName="factory" fieldName="third_highlight" fieldValue={factory.third_highlight} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Third shadow" parentName="factory" fieldName="third_shadow" fieldValue={factory.third_shadow} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fourth" parentName="factory" fieldName="fourth_main" fieldValue={factory.fourth_main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fourth highlight" parentName="factory" fieldName="fourth_highlight" fieldValue={factory.fourth_highlight} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fourth shadow" parentName="factory" fieldName="fourth_shadow" fieldValue={factory.fourth_shadow} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fifth" parentName="factory" fieldName="fifth_main" fieldValue={factory.fifth_main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fifth highlight" parentName="factory" fieldName="fifth_highlight" fieldValue={factory.fifth_highlight} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Fifth shadow" parentName="factory" fieldName="fifth_shadow" fieldValue={factory.fifth_shadow} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Background top" parentName="factory" fieldName="background_1" fieldValue={factory.background_1} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Background bottom" parentName="factory" fieldName="background_2" fieldValue={factory.background_2} colourMode={mode} handleChange={handleChange} />
                                                    <Grid item xs={12} md={6} xl={4}></Grid>
                                                    <ColourPicker name="Walkways" parentName="factory" fieldName="walkway" fieldValue={factory.walkway} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Backgrounds</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Main" parentName="background" fieldName="main" fieldValue={background.main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Header" parentName="background" fieldName="header" fieldValue={background.header} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Breadcrumb" parentName="background" fieldName="breadcrumb" fieldValue={background.breadcrumb} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Cards</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Main" parentName="cards" fieldName="main" fieldValue={cards.main} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Header" parentName="cards" fieldName="header" fieldValue={cards.header} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Footer" parentName="cards" fieldName="footer" fieldValue={cards.footer} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Tables</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Header" parentName="table" fieldName="header" fieldValue={table.header} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Footer" parentName="table" fieldName="footer" fieldValue={table.footer} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Border" parentName="table" fieldName="border" fieldValue={table.border} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Row" parentName="table" fieldName="rows" fieldValue={table.rows} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Row hover" parentName="table" fieldName="hover" fieldValue={table.hover} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Row disabled" parentName="table" fieldName="disabled" fieldValue={table.disabled} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="Row OOC" parentName="table" fieldName="out" fieldValue={table.out} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}><Divider /></Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ color: mode === 'light' ? '#222' : '#FFF' }}>Charts</Typography>
                                                    </Grid>
                                                    <ColourPicker name="Out of control" parentName="charts" fieldName="first" fieldValue={charts.first} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="No Result / Pending" parentName="charts" fieldName="second" fieldValue={charts.second} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="In control" parentName="charts" fieldName="third" fieldValue={charts.third} colourMode={mode} handleChange={handleChange} />
                                                    <ColourPicker name="On edge" parentName="charts" fieldName="fourth" fieldValue={charts.fourth} colourMode={mode} handleChange={handleChange} />
                                                </Grid>
                                            </Grid>

                                            {/* {Object.entries(colourMode[1]).map((parent, i) => {



                        return (
                          <Grid item container key={i} spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6"
                                sx={{
                                  textTransform: 'capitalize',
                                  color: mode === 'light' ? '#222' : '#FFF', marginTop: '1em'
                                }}
                              >{parent[0]}</Typography>
                            </Grid>

                            <Grid container item spacing={2} >
                              {Object.entries(parent[1]).map((field, i) => {

                                const fieldName = updateFieldName(parent, field)
                                return (
                                  <ColourPicker name={fieldName ? fieldName : field[0]} parentName={parentName} fieldName={field[0]} fieldValue={field[1]} colourMode={mode} handleChange={handleChange} />
                                )

                              })}
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Grid>
                        )
                      }
                      )} */}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )
                    })}
                    <Grid container item xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => testTheme(currentTheme)}
                            >
                                {setLanguageText("Test Theme")}
                            </Button>
                        </Grid>
                        {resetTheme ?
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        //reset to old theme
                                        //console.log(originalTheme)
                                        //setCurrentTheme(originalTheme)
                                        dispatchTheme({
                                            type: APP_THEME,
                                            payload: originalTheme
                                        });
                                        setResetTheme(false)
                                    }}
                                >
                                    {setLanguageText("Reset Theme")}
                                </Button>
                            </Grid>
                            : null}

                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => saveTheme(currentTheme)}
                            >
                                {setLanguageText("Save Theme")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                <Loader
                    isLoading={mutation.isLoading}
                    errorMessage={mutation.isError}
                    mutation={mutation}
                    loadingMessage="Updating Application Theme..."
                    successMessage="Updated Theme"
                    callback={() => {
                        //navigate('/solution/dashboard')
                        mutation.reset();
                    }}
                />
                : null}
        </>

    );
};

export default ThemeForm;
