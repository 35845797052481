import { Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedContext } from "../../store/SelectedContext";
import { UDPATE_ALL_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";

const ResultToggleFilters = ({ fixed }) => {

	const { selected, dispatchSelected } = useContext(SelectedContext);
	const toggleFilter = (filter) => {

		let clone = structuredClone(selected.parametricFilters)
		//see if there is already a selected filter 
		let index = clone.findIndex((item) => (item.Field === filter.Field));
		//console.log(index)
		if (index > -1) {
			clone[index].Toggle = !clone[index].Toggle
		}
		//set Show to remove / add back to the API call
		dispatchSelected({
			type: UDPATE_ALL_PARAMETRIC_FILTERS,
			payload: clone
		})
	}

	return (
		<>
			{selected?.parametricFilters && Object.values(selected.parametricFilters).length ?
				<Grid item xs={12} container spacing={1}>
					{selected.parametricFilters.map((button, index) => {

						let comparison = ''
						if (button.Comparison && button.Comparison === 'l') {
							comparison = '=<'
						}
						if (button.Comparison && button.Comparison === 'LU') {
							comparison = '<'
						}
						if (button.Comparison && button.Comparison === 'e') {
							comparison = '='
						}
						if (button.Comparison && button.Comparison === 'GU') {
							comparison = '>'
						}
						if (button.Comparison && (button.Comparison === 'g' || button.Comparison === 'G')) {
							comparison = '>='
						}
						if (fixed) {
							return (
								<Grid item key={index}>
									<Box
										sx={{
											padding: '.5em 1em',
											border: theme => `1px solid ${theme.palette.primary.main}`,
											color: theme => theme.palette.primary.main,
											fontSize: '15px',
											borderRadius: '5px',
											textAlign: 'center'
										}}
									>
										{button.Name} {comparison ? comparison : ''} {button.ValueNames ? button.ValueNames.toString() : button.Values.toString().replace(',String_Empty', '')} {button.Tolerance ? "±" : ""} {button.Tolerance && button.Tolerance !== "String_Empty" ? button.Tolerance : ""}{button.Unit ? `(${button.Unit})` : ''}
									</Box>
								</Grid>
							)
						} else {
							return (
								<Grid item key={index}>
									<Box
										sx={{
											borderRadius: '5px',
											backgroundColor: 'cards.header',
											//marginRight : '5px', 
											padding: '5px 10px ',
											boxShadow: theme => theme.shadows[1],
											opacity: button.Toggle ? 1 : 0.5

										}}
									>
										<Grid item container alignItems="center" spacing={1} >
											<Grid item>
												<Typography variant="subtitle"
													sx={{
														textTransform: 'capitalize'
													}}
													fontWeight={600}>{button.Name}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle" > {comparison ? comparison : ''} {button.ValueNames ? button.ValueNames.toString() : button.Values.toString().replace(',String_Empty', '')} {button.Tolerance ? "±" : ""} {button.Tolerance && button.Tolerance !== "String_Empty" ? button.Tolerance : ""}{button.Unit ? button.Unit : ''}</Typography>
											</Grid>
											<Grid item>
												<IconButton
													onClick={() => toggleFilter(button)}
													edge="end" aria-label="delete"
													sx={{ width: '25px', height: '25px', marginRight: '1px' }}
												>
													<FontAwesomeIcon style={{ width: '15px', height: '15px' }} icon={button.Toggle ? faTimes : faPlus} />
												</IconButton>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							)
						}
					}
					)}
				</Grid>
				: null}
		</>
	);
};

export default ResultToggleFilters;
