import React, { useContext, useEffect, useState } from "react";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import PageContent from "../coolantcare/PageContent";
import { Box, Fade, Grow, Typography } from "@mui/material";

import { ReactComponent as Walkways } from './areas/svg/walkways.svg';
import { ReactComponent as Loaderback } from './areas/svg/loaderback.svg';

import { LayoutContext } from "../store/LayoutContext";
import { UserContext } from "../store/UserContext";
import { useNavigate } from "react-router-dom";
import { UPDATE_MENU } from "../store/LayoutReducers";
import Catalogue from "./areas/Catalogue";
import Parametric from "./areas/Parametric";
import Fixtures from "./areas/Fixtures";
import Engineer from "./areas/Engineer";
import Resources from "./areas/Resources";
import KPI from "./areas/KPI";
import Vend from "./areas/Vend";
import Application from "./areas/Application";
import CNC from "./areas/CNC";
import Solution from "./areas/Solution";
import Coolantcare from "./areas/Coolantcare";
import Design from "./areas/Design";
import { useTheme } from "@emotion/react";
import { isMobileOnly } from "react-device-detect";

const Landing = () => {

    const { user } = useContext(UserContext);
    const { app, dispatchApp } = useContext(LayoutContext);
    const [boxIndex, setBoxIndex] = useState(0);
    const [hoveredBoxIndex, setHoveredBoxIndex] = useState(-1);
    const navigate = useNavigate();
    const theme = useTheme()

    useEffect(() => {
        if (boxIndex < 14) {
            const timer = setTimeout(() => {
                setBoxIndex(prevIndex => prevIndex + 1);
            }, 50);

            return () => clearTimeout(timer);
        }
    }, [boxIndex]);




    return (
        <>
            <InnerHeaderWrap>
            </InnerHeaderWrap>
            <PageContent
                background={`linear-gradient(0deg, ${theme.palette.factory.background_2}, ${theme.palette.factory.background_1})`} >
                {isMobileOnly ?
                    <Box sx={{
                        width: '100%',
                        position: 'relative',
                    }}>

                        <Catalogue
                            svgprops={{
                                position: 'relative',
                                width: '48%',
                                height: 'auto',
                                float: 'left',
                                padding: '10px',
                                maxWidth: '220px'
                            }}
                            textprops={{
                                top: '80%',
                                left: '48%',
                                fontSize: '11px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Engineer
                            svgprops={{
                                position: 'relative',
                                width: '48%',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px'
                            }}
                            textprops={{
                                top: '16%',
                                left: '47%',
                                fontSize: '14px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Vend
                            svgprops={{
                                position: 'relative',
                                width: '48%',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px'
                            }}
                            textprops={{
                                top: '92%',
                                left: '70%',
                                fontSize: '11px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Coolantcare
                            svgprops={{
                                position: 'relative',
                                width: '48%',
                                height: 'auto',
                                float: 'left',
                                maxWidth: '220px'
                            }}
                            textprops={{
                                // top: process.env.REACT_APP_CONFIG_PATH === "act" ? '27px' : '12px',
                                top: '10%',
                                left: '66%',
                                fontSize: '16px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Parametric
                            svgprops={{
                                position: 'relative',
                                width: '44%',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px',
                                marginTop: '15px'
                            }}
                            textprops={{
                                top: '8%',
                                left: '29%',
                                fontSize: '15px',
                                padding: '10px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Application
                            svgprops={{
                                width: '48%',
                                height: 'auto',
                                float: 'left',
                                maxWidth: '240px',
                                marginTop: '15px'
                            }}
                            textprops={{
                                top: '3%',
                                left: '45%',
                                fontSize: '14px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Design
                            svgprops={{
                                width: '44%',
                                position: 'relative',
                                height: 'auto',
                                float: 'left',
                                maxWidth: '220px'
                            }}
                            textprops={{
                                top: '12%',
                                left: '12%',
                                fontSize: '14px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Solution
                            svgprops={{
                                width: '48%',
                                position: 'relative',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px',
                                marginTop: '-15px'
                            }}
                            textprops={{
                                top: '92%',
                                left: '64%',
                                fontSize: '11px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <CNC
                            svgprops={{
                                width: '48%',
                                position: 'relative',
                                height: 'auto',
                                float: 'left',
                                maxWidth: '220px',
                                clear: 'left',
                                paddingTop: '10px'
                            }}
                            textprops={{
                                top: '0%',
                                left: '40%',
                                fontSize: '14px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />
                        <KPI
                            svgprops={{
                                width: '44%',
                                position: 'relative',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px',
                            }}
                            textprops={{
                                top: '75%',
                                left: '54%',
                                fontSize: '16px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />
                        <Fixtures
                            svgprops={{
                                width: '48%',
                                position: 'relative',
                                height: 'auto',
                                float: 'left',
                                maxWidth: '220px',
                                marginTop: '15px'
                            }}
                            textprops={{
                                top: process.env.REACT_APP_CONFIG_PATH === "act" ? '70%' : '88%',
                                left: '35%',
                                fontSize: '12px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}
                        />

                        <Resources
                            svgprops={{
                                width: '44%',
                                position: 'relative',
                                height: 'auto',
                                float: 'right',
                                maxWidth: '220px',
                                marginTop: '15px'
                            }}
                            textprops={{
                                top: '3%',
                                left: '25%',
                                fontSize: '14px'
                            }}
                            setHoveredBoxIndex={setHoveredBoxIndex}
                            boxIndex={boxIndex}
                            hoveredBoxIndex={hoveredBoxIndex}

                        />

                    </Box>
                    :
                    <Box sx={{
                        width: '100%',
                        position: 'relative',
                        display: 'grid',
                        justifyContent: 'center',
                        top: '-35px'
                    }} >
                        <Box sx={{
                            width: '1400px',
                            height: '1000px',
                            position: 'relative',
                            marginLeft: '-400px'
                        }}>
                            <Grow
                                in={1 <= boxIndex}
                                style={{ transformOrigin: '0 0 0' }}
                                timeout={1000}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '84px',
                                        '& .walkways': {
                                            fill: theme => theme.palette.factory.walkway
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: "-324px",
                                            left: "-291px",
                                            width: "2000px",
                                            height: "1377px",
                                            opacity: 0.4
                                        }}
                                    >
                                        <Walkways />
                                    </Box>
                                </Box>
                            </Grow>

                            <Catalogue
                                svgprops={{
                                    position: 'absolute',
                                    top: '35px',
                                    left: '550px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '196px',
                                    left: '75px',
                                    fontSize: '12px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={2}
                            />

                            <Engineer
                                svgprops={{
                                    position: 'absolute',
                                    top: '153px',
                                    left: '782px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '41px',
                                    left: '108px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={5}
                            />

                            <Vend
                                svgprops={{
                                    position: 'absolute',
                                    top: '327px',
                                    left: '996px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '191px',
                                    left: '152px',
                                    fontSize: '12px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={8}
                            />


                            <Parametric
                                svgprops={{
                                    position: 'absolute',
                                    top: '50px',
                                    left: '920px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '20px',
                                    left: '65px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={3}
                            />

                            <Application
                                svgprops={{
                                    position: 'absolute',
                                    top: "587px",
                                    left: "507px",
                                    width: "240px",
                                    height: "240px",
                                }}
                                textprops={{
                                    top: '27px',
                                    left: '110px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={9}
                            />

                            <Coolantcare
                                svgprops={{
                                    position: 'absolute',
                                    top: '688px',
                                    left: '710px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: process.env.REACT_APP_CONFIG_PATH === "act" ? '27px' : '12px',
                                    left: '84px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={12}
                            />


                            <Solution
                                svgprops={{
                                    position: 'absolute',
                                    top: '537px',
                                    left: '1151px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '200px',
                                    left: '139px',
                                    fontSize: '12px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={11}
                            />

                            <Design
                                svgprops={{
                                    position: 'absolute',
                                    top: '676px',
                                    left: '946px',
                                    width: '240px',
                                    height: '240px',
                                }}
                                textprops={{
                                    top: process.env.REACT_APP_CONFIG_PATH === "act" ? '2px' : '8px',
                                    left: process.env.REACT_APP_CONFIG_PATH === "act" ? '125px' : '85px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={13}
                            />

                            <CNC
                                svgprops={{
                                    position: 'absolute',
                                    top: '432px',
                                    left: '757px',
                                    width: '300px',
                                    height: '300px'
                                }}
                                textprops={{
                                    top: '17px',
                                    left: '124px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={10}
                            />

                            <KPI
                                svgprops={{
                                    position: 'absolute',
                                    top: '332px',
                                    left: '603px',
                                    width: '193px',
                                    height: '213px',
                                }}
                                textprops={{
                                    top: '0px',
                                    left: '115px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={7}
                            />


                            <Fixtures
                                svgprops={{
                                    position: 'absolute',
                                    top: '219px',
                                    left: '428px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: process.env.REACT_APP_CONFIG_PATH === "act" ? '184px' : '192px',
                                    left: '91px',
                                    fontSize: '12px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={4}
                            />

                            <Resources
                                svgprops={{
                                    position: 'absolute',
                                    top: '150px',
                                    left: '1130px',
                                    width: '220px',
                                    height: '220px'
                                }}
                                textprops={{
                                    top: '6px',
                                    left: '73px',
                                    fontSize: '16px'
                                }}
                                setHoveredBoxIndex={setHoveredBoxIndex}
                                boxIndex={boxIndex}
                                hoveredBoxIndex={hoveredBoxIndex}
                                areaIndex={6}
                            />





                            <Grow
                                in={14 <= boxIndex}
                                style={{ transformOrigin: '0 0 0' }}
                                key={14}
                                timeout={1000}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '545px',
                                        left: '287px',
                                        width: '220px',
                                        height: '220px',
                                        '& .primary': {
                                            fill: theme => theme.palette.factory.primary_main
                                        },
                                        '& .primary_darker': {
                                            fill: theme => theme.palette.factory.primary_shadow
                                        },
                                        '& .primary_lighter': {
                                            fill: theme => theme.palette.factory.primary_highlight
                                        },

                                        'svg': {
                                            transition: "opacity 0.3s",
                                            opacity: 1,
                                        },
                                        '&.greyed-out > svg ': {
                                            opacity: '0.3!important'
                                        }
                                    }}
                                    className={hoveredBoxIndex !== -1 && hoveredBoxIndex !== 14 ? 'greyed-out' : ''}
                                >
                                    <Loaderback />
                                </Box>
                            </Grow>
                        </Box>
                    </Box>
                }
            </PageContent>
        </>
    );
};


export default Landing;
