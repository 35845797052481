import React, { useContext } from 'react';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import setLanguageText from '../language/setLanguageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from '../store/UserContext';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import TemplateTable from '../templates/TemplateTable';

const UserTemplates = ({ type }) => {

    const { user } = useContext(UserContext);

    const templates = useQuery({
        queryKey: ['templates', type],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'get',
            params: {
                "template_type": JSON.stringify(type),
            }
        }),
        enabled: !!type,
        retry: 1
    })


    return (
        <>
            <TabHeader>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                >
                    <Grid item xs={12} sm={'auto'}>
                        <Typography variant="h5">{setLanguageText("User Permission Templates")}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Refresh Templates">
                            <Avatar
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => templates.refetch()}
                            >
                                <FontAwesomeIcon icon={faArrowsRotate} spin={templates.isFetching} />
                            </Avatar>
                        </Tooltip>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <TemplateTable templates={templates} />
                    </Grid>
                </Grid>
            </TabContent>
        </>
    );
};

export default UserTemplates;


