import React, { useEffect, useState } from "react";
import { Avatar, Chip, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuoteItem = ({ product }) => {

	const [placeholder, setPlaceholder] = useState('');
	import(`../../../images/placeholder.jpg`).then((module) => {
		setPlaceholder(module.default);
	});
	const [photo, setPhoto] = useState()

	useEffect(() => {
		if (product?.product_image && Object.values(product.product_image).length > 0) {
			setPhoto(product.product_image[0].url)
		}
	}, [product.product_image])

	return (
		<>
			<ListItem
				divider
			>
				<ListItemAvatar>
					<Avatar
						sx={{ background: '#fff', cursor: 'pointer', boxShadow: '0 0 6px 2px #404040' }}>
						{photo ?
							<img
								src={photo}
								onError={e => {
									e.target.src = placeholder;
								}}
								style={{ width: '100%', height: '100%', objectFit: 'cover' }}
							/>
							: <FontAwesomeIcon color="#222" icon={faImageSlash} />
						}
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={<Typography variant="body1" fontSize="13px" lineHeight="1.4" fontWeight={600}>{product.product_name}</Typography>}
					secondary={
						<Grid container alignItems="center">
							{product?.price_rrp !== product?.price_incl_discount &&
								<Grid item xs={12}
									sx={{
										marginTop: '5px'
									}}>
									<Typography variant="p" component="p" sx={{
										textDecoration: 'line-through',
										fontSize: '14px',
										color: '#777'
									}} >
										£{(product.product_quantity * product.price_rrp).toFixed(2)}
										<Chip
											sx={{
												marginLeft: '5px'
											}}
											size="small"
											color="success"
											label={`Save ${product?.price_discount ? `${product.price_discount}%` : `£${((product.price_rrp - product.price_incl_discount) * product.product_quantity).toFixed(2)}`}`}
										//label={`Save £${((product.price_rrp - product.price_incl_discount) * product.product_quantity).toFixed(2)}`}
										/>
									</Typography>
								</Grid>
							}
							<Grid item xs={12}>
								{product.price_incl_discount ?
									<Typography variant="body1" fontSize="14px"  >Total: <Typography fontWeight={700} component="span" sx={{ color: theme => theme.palette.success.main }}>£{(product.product_quantity * product.price_incl_discount).toFixed(2)}</Typography></Typography>
									:
									<Typography variant="body1" fontSize="14px"  ><strong>Total: £POA</strong></Typography>
								}
							</Grid>
							<Grid item xs={12}>
								<Typography color="text.secondary" variant="subtitle2" lineHeight="1.4">
									<small>Quantity: {product.product_quantity} x £{product?.price_incl_discount ? product?.price_incl_discount.toFixed(2) : null } each</small>
								</Typography>
							</Grid>
						</Grid>
					}
				/>
			</ListItem>
			{/* <ListItemText
					disableTypography
					primary={<Typography color="text.secondary" variant="body1" fontSize="13px" lineHeight="1.4" >{product.product_name}</Typography>}
					secondary={
						<>
							{product?.price_incl_discount ?
								<>
									<Typography variant="body1" fontSize="16px" color="primary.main" lineHeight="1.4" ><strong>Total: £{(product.product_quantity * product.price_incl_discount).toFixed(2)}</strong></Typography>
									<Typography color="text.secondary" variant="subtitle2" lineHeight="1.4">
										<small>Quantity: {product.product_quantity} x £{product.price_incl_discount.toFixed(2)} each</small>
									</Typography>
								</>
								: null}
						</>
					}
				/> 
			</ListItem>*/}
		</>
	);
}


export default QuoteItem;