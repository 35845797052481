import React, { useContext, useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Typography, Grid, Button, Fade, CardActions } from "@mui/material";
import Parametric from "./areas/Parametric";
import Application from "./areas/Application";
import Design from "./areas/Design";
import Coolantcare from "./areas/Coolantcare";
import Vend from "./areas/Vend";
import Resources from "./areas/Resources";
import { isMobileOnly } from 'react-device-detect';
import { UserContext } from "../store/UserContext";
import { LayoutContext } from "../store/LayoutContext";

export default function LandingOptions() {

    const { user } = useContext(UserContext);
    const { app } = useContext(LayoutContext)
    const [coolantTitle, setCoolantTitle] = useState()

    useEffect(() => {
        if (app.menu) {
            let coolant = app.menu.find(app => app.area === "coolantcare")
            setCoolantTitle(coolant.buttonText)
        }
    }, [app.menu])

    return (

        <Fade in={true}>
            <Grid
                container
                sx={{
                    position: isMobileOnly ? 'relative' : 'absolute',
                    zIndex: 10,
                    marginTop: '1em'
                }}
                spacing={2}
                alignContent="flex-start"
            >
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Card>
                        <CardHeader
                            sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                            //disableTypography
                            title="Help me find a product"
                            subheader=" know what I want to do, but I don’t know what products I require."
                        />

                        <CardContent sx={{ margin: "auto 0", padding: '8px!important' }} >
                            <Grid container spacing={2}>
                                {user.user.user_permissions.parametric.access === 1 ?
                                    <Grid item container spacing={2} textAlign="center" sx={{ maxWidth: isMobileOnly ? '100%' : '350px' }}>
                                        <Grid item xs={12}>
                                            <Parametric
                                                noClick
                                                svgprops={{
                                                    width: '140px',
                                                    height: '140px',
                                                    margin: 'auto'
                                                }}
                                                textprops={{
                                                    display: 'none'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>I know the specific</Typography>
                                            <Typography>tooling parameters?</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                            >Parametric Search</Button>
                                        </Grid>
                                    </Grid>
                                    : null}
                                {user.user.user_permissions.application.access === 1 ?
                                    <Grid item container spacing={2} textAlign="center" sx={{ maxWidth: isMobileOnly ? '100%' : '350px' }}>
                                        <Grid item xs={12}>
                                            <Application
                                                noClick
                                                svgprops={{
                                                    width: '140px',
                                                    height: '140px',
                                                    margin: 'auto'
                                                }}
                                                textprops={{
                                                    display: 'none'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>I know the dimensions </Typography>
                                            <Typography>of the application.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                            >Application Search</Button>
                                        </Grid>
                                    </Grid>
                                    : null}
                                {user.user.user_permissions.design.access === 1 ?
                                    <Grid item container spacing={2} textAlign="center" sx={{ maxWidth: isMobileOnly ? '100%' : '350px' }}>
                                        <Grid item xs={12}>
                                            <Design
                                                noClick
                                                svgprops={{
                                                    width: '140px',
                                                    height: '140px',
                                                    margin: 'auto'
                                                }}
                                                textprops={{
                                                    display: 'none'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>No standard tool? </Typography>
                                            <Typography> Make a custom tool. </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                            >Custom Tool</Button>
                                        </Grid>
                                    </Grid>
                                    : null}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container spacing={2} justifyContent="center">
                    {user.user.user_permissions.application.access === 1 ?
                        <Grid item sx={{ width: '100%', maxWidth: isMobileOnly ? '100%' : '340px' }} >
                            <Card >
                                <CardHeader
                                    sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                                    //disableTypography
                                    title={coolantTitle}
                                />
                                <CardContent sx={{ margin: "auto 0" }} >
                                    <Coolantcare
                                        noClick
                                        svgprops={{
                                            width: '140px',
                                            height: '140px',
                                            margin: 'auto'
                                        }}
                                        textprops={{
                                            display: 'none'
                                        }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        {coolantTitle}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        : null}

                    {user.user.user_permissions.vend.access === 1 ?
                        <Grid item sx={{ width: '100%', maxWidth: isMobileOnly ? '100%' : '340px' }}>
                            <Card>
                                <CardHeader
                                    sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                                    //disableTypography
                                    title="Vending"
                                />
                                <CardContent sx={{ margin: "auto 0" }} >
                                    <Vend
                                        noClick
                                        svgprops={{
                                            width: '140px',
                                            height: '140px',
                                            margin: 'auto'
                                        }}
                                        textprops={{
                                            display: 'none'
                                        }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        Vending
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        : null}
                    {user.user.user_permissions.resources.access === 1 ?
                        <Grid item sx={{ width: '100%', maxWidth: isMobileOnly ? '100%' : '340px' }} >
                            <Card >
                                <CardHeader
                                    sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                                    //disableTypography
                                    title="Resources"
                                />
                                <CardContent sx={{ margin: "auto 0" }} >
                                    <Resources
                                        noClick
                                        svgprops={{
                                            width: '140px',
                                            height: '140px',
                                            margin: 'auto'
                                        }}
                                        textprops={{
                                            display: 'none'
                                        }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        Resources
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        : null}
                </Grid>
            </Grid >
        </Fade >
    );
};

