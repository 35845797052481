import {
	Button,
	ButtonGroup,
	Grid,
	TextField,
	Typography
} from '@mui/material';
import React, { useContext } from 'react';
import setLanguageText from '../../../language/setLanguageText';
import { SelectionsContext } from '../../store/SelectionsContext';
import { ADD_NOTES, ADD_NOTE_ACTION } from '../../store/SelectionsReducers';
import { buttonStyles } from '../../styles/buttonStyles';

export default function Notes() {

	const buttonStyle = buttonStyles();
	const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);

	const handleButton = (e) => {
		//console.log(e.target.value)
		e.preventDefault();
		dispatchAddNewEntry({
			type: ADD_NOTE_ACTION,
			payload: e.target.value
		});
	};

	const handleNote = (e) => {
		//console.log(e.target.value)
		e.preventDefault();
		dispatchAddNewEntry({
			type: ADD_NOTES,
			payload: e.target.value
		});
	};

	return (
		<>
			<Grid container item spacing={2}>
				<Grid item xs={12}>
					<TextField
						multiline
						label={setLanguageText("Note body")}
						rows={10}
						variant="outlined"
						fullWidth
						onChange={e => handleNote(e)}
						value={addNewEntry.entry.notes || ''}
					/>
				</Grid>
				<Grid item container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="h6">{setLanguageText("Need Action")}?</Typography>
					</Grid>
					<Grid item>
						<ButtonGroup>
							<Button
								value="N"
								onClick={e => handleButton(e)}
								className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'N' && buttonStyle.success
									}`}>
								{setLanguageText("No")}
							</Button>
							<Button
								value="Y"
								onClick={e => handleButton(e)}
								className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'Y' && buttonStyle.warning
									}`}
							>
								{setLanguageText("Yes")}
							</Button>
							<Button
								value="C"
								onClick={e => handleButton(e)}
								className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'C' && buttonStyle.danger
									}`}
							>
								{setLanguageText("Critical")}!
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

