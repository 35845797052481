import React, { useState } from "react";
import { Divider, Grid, InputAdornment, TextField } from "@mui/material";
import InputLables from "./InputLables";

const Float = ({ field, updateField }) => {

    const [localValue, setLocalValue] = useState('');

    return (
        <>
            <InputLables field={field} />
            <Grid item xs={12}>
                <TextField
                    sx={{width: '100%', '& legend' : { display: 'none'}}}
                    size="small"
                    fullWidth
                    value={localValue}
                    name={field.Field}
                    onChange={e => {
                        setLocalValue(e.target.value)
                        updateField(field, e.target.value)
                    }}
                    type={"number"}
                    placeholder={field.Name}
                    InputProps={{
                        endAdornment:
                            field.Unit ? 
                                <InputAdornment position="end">
                                    <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                                    {field.Unit}
                                </InputAdornment>
                            :null 
                        ,
                    }}
                />
            </Grid>
        </>
    );
};

export default Float;

