import { faBadgePercent, faCloudExclamation, faExclamationCircle, faTags } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { CompanyContext } from "../../store/CompanyContext";
import RequestPrice from "./RequestPrice";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const Price = ({ product, large, table, isFetching }) => {

    return (
        <>
            <Alert
                icon={
                    <Tooltip title={isFetching ? "Fetching prices" : product?.price_incl_discount !== product?.price_rrp ? "Your discounted price" : false} arrow>
                        <FontAwesomeIcon
                            icon={isFetching ? faTags : product?.price_incl_discount !== product?.price_rrp ? faBadgePercent : faTags}
                            style={{ marginTop: '2px' }}
                            size={large ? "lg" : "sm"}
                        />
                    </Tooltip>
                }
                sx={{
                    background: '#f4fef4',
                    border: theme => `1px solid ${theme.palette.success.main}`,
                    padding: '4px 12px',
                    alignItems: 'center',
                    borderRadius: 0,
                    minHeight: table ? '50px' : 'auto',
                }}
                severity="success"
                variant="outlined"
            >
                <Box
                    sx={{
                        color: (theme) => theme.palette.text.primary
                    }}
                > {product?.price_incl_discount ?
                    <Typography variant="p" component="p" width="100%"  >
                        {(product?.price_incl_discount !== product?.price_rrp) && product?.price_rrp ?
                            <Typography
                                sx={{
                                    marginRight: '10px',
                                    fontSize: large ? '26px' : table ? '16px' : '22px',
                                    color: '#999',
                                    position: 'relative',
                                    '&:before': {
                                        content: '" "',
                                        position: 'absolute',
                                        left: '-0.1em',
                                        right: '-0.1em',
                                        top: '0.38em',
                                        bottom: '0.38em',
                                        background: 'linear-gradient(to left top, transparent 45.5%, currentColor 47.5%, currentColor 52.5%, transparent 54.5%)',
                                        pointerEvents: 'none'
                                    }
                                }}
                                variant="p"
                                component="span"
                            >
                                £{product.price_rrp.toFixed(2)}

                            </Typography>
                            : null}

                        <Typography variant="p" component="span" fontSize={large ? '26px' : table ? '16px' : '22px'} fontWeight={700} sx={{ color: theme => theme.palette.success.main, marginRight: '5px' }} >
                            £{product?.price_incl_discount ? parseFloat(product.price_incl_discount).toFixed(2) : product.price_rrp}
                        </Typography>
                        <Typography variant="p" component="span" fontSize={large ? '14px' : table ? '12px' : '12px'} sx={{ color: theme => theme.palette.success.main }} >
                            each
                        </Typography>
                    </Typography>
                    :
                    <Skeleton width={large ? 200 : table ? 100 : 150} height={large ? 40 : table ? 20 : 30} />
                    }
                </Box>
            </Alert>
        </>
    )
}


export default function Prices({ product, table, large, isFetching }) {

    const { selectedCompany } = useContext(CompanyContext);

    return (
        <>
            {!selectedCompany?.company_erp ?
                <Grid item xs={12}>
                    <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ background: '#fef4f4', textAlign: 'center', marginBottom: '10px', fontSize: '14px' }}
                    >
                        No ERP link
                    </Alert>
                </Grid>
                : product?.error && product?.error_type === "no_product" ?
                    <Grid item xs={12}>
                        <RequestPrice product={product} table={table} error={product?.error} />
                    </Grid>
                    : product?.error && product?.error_type === "erp_error" ?
                        <Grid item xs={12}>
                            <Alert
                                icon={table? false: <FontAwesomeIcon icon={faExclamationCircle} style={{ marginTop: '2px' }} size="sm" />}
                                severity="error"
                                variant="outlined"
                                sx={{ 
                                    background: '#fef4f4', 
                                    textAlign: 'left', 
                                    fontSize: table ? '12px' : '14px' 
                                }}
                            >
                                {product?.error}
                            </Alert>
                        </Grid>
                        :
                    <>
                        {table ?
                            <Price product={product} table={table} isFetching={isFetching} />
                            :
                            <Price product={product} large={large} isFetching={isFetching} />
                        }
                    </>
            }
        </>

    );
};
