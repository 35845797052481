import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { makeStyles } from "@mui/styles"
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';

const useStyles = makeStyles(theme => ({
	inputRows: {
		backgroundColor: '#ffffff',
		//fontWeight: 'bold',
		margin: 'auto',
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(1, 4)
		}
	},
	inputPage: {
		backgroundColor: '#ffffff',
		//fontWeight: 'bold',
		width: theme.spacing(8),
		margin: 'auto',
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(1)
		}
	}
}));

const Pagination = ({
	allColumns,
	rowCount,
	pageIndex,
	gotoPage,
	pageCount,
	pageSize,
	setPageSize,
	previousPage,
	canPreviousPage,
	nextPage,
	canNextPage
}) => {

	const inputStyle = useStyles();
	const [options, setOptions] = useState([10, 20, 30, 50, 100]);
	const { selectedCompany } = useContext(CompanyContext)
	const [show, setShow] = useState(
		window.sessionStorage.getItem('show') ? window.sessionStorage.getItem('show') :
			selectedCompany?.company_settings?.show ? selectedCompany.company_settings.show :
				100);

	useEffect(() => {
		setPageSize(show)
		window.sessionStorage.setItem('show', show);
	}, [show])

	return (

		<Grid
			item
			xs={12}
			container
			className="pagination"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				padding: '1em',
				background: (theme) => theme.palette.table.footer,
				//borderTop: (theme) => `1px solid ${theme.palette.table.border}`
			}}
		>
			{!isMobileOnly && rowCount &&
				<Grid item
					xs={12}
					md={3}
				>
					{setLanguageText("Total")}: <strong>{allColumns[0]?.filteredRows ? allColumns[0].filteredRows.length : rowCount}</strong> / {rowCount}
				</Grid>
			}
			{!isMobileOnly ?
				<Grid
					item
					container
					alignItems="center"
					spacing={1}
					xs={7}
					md={3}
				>
					<Grid item>
						<Typography variant="button">{setLanguageText("Page")}</Typography>
					</Grid>
					<Grid item>
						<TextField
							id="outlined-number"
							value={pageIndex + 1}
							type="number"
							variant="outlined"
							onChange={e => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								gotoPage(page);
							}}
							className={inputStyle.inputPage}
							disabled={pageCount > 1 ? false : true}
						/>
					</Grid>
					<Grid item>
						<Typography variant="button">
							/<strong> {pageCount}</strong>
						</Typography>
					</Grid>
				</Grid>
				: null}
			<Grid
				item
				container
				alignItems="center"
				justifyContent="center"
				xs={3}
				md={3}
			>
				<FormControl>
					<Select
						value={pageSize}
						id="select_rows"
						variant="outlined"
						onChange={e => {
							//setPageSize(Number(e.target.value));
							setShow(Number(e.target.value));
						}}
						className={inputStyle.inputRows}
					>
						{options.map(pageSize => (
							<MenuItem key={pageSize} value={pageSize}>
								{pageSize}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid
				item
				container
				alignItems="flex-end"
				//justify={!isMobileOnly ? 'flex-end' : 'space-between'}
				justifyContent="flex-end"
				xs={7}
				md={3}
				spacing={1}
			>
				<Grid item  >
					<PrimaryBtnIcon
						icon={faChevronLeft}
						onClick={e => previousPage()}
						disabled={!canPreviousPage}
						iconSize="lg"
					/>
				</Grid>
				<Grid item >
					<PrimaryBtnIcon
						icon={faChevronRight}
						onClick={e => nextPage()}
						disabled={!canNextPage}
						iconSize="lg"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Pagination;
