import React from 'react';
import config from '../config';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import PageContent from '../coolantcare/PageContent';

const Chat = () => {
	return (
		<>
			<InnerHeaderWrap>
			</InnerHeaderWrap>
			<PageContent
				noPadding={true}
			>
				<iframe src={config.TIDIOCHAT} style={{
					width: "100%",
					border: "none",
					height: "300px",
					height: "100vh"
				}}
					allowFullScreen
					title="TIDIO">

				</iframe>
			</PageContent>
		</>
	);
};
export default Chat;
