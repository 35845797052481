import { useTheme } from '@mui/styles';
import { useContext, useRef, useMemo, useState, useCallback } from 'react';
import formatDate from '../helpers/formatDate';
import { ChartsContext } from '../store/ChartsContext';
import { SAVE_CHARTS } from '../store/ChartsReducers';

const useFungiChart = ({ plots, tooltips }) => {
	const [labels, setLabels] = useState();
	const [fungi, setFungi] = useState();
	const [fungiMax, setFungiMax] = useState();
	const [fungiMedium, setFungiMedium] = useState();
	const colour = useTheme().palette.error.main;
	const { dispatchCharts } = useContext(ChartsContext);
	const chartRef = useRef(null);
	const [base64Image, setBase64Image] = useState(null);

	const createFungiChart = useCallback(entries => {
		const labelsObject = [];
		const fungiObject = [];

		entries.forEach(entry => {
			labelsObject.push(formatDate(entry.original.date_stamp).slice(0, -5));
			const details = entry.original.attributes_new.find(
				attr => attr.attribute_id === 'fungi'
			);
			if (details) {
				//console.log(details)
				//remove pending and N/A results
				if (
					details.attribute_values.status === -10 ||
					details.attribute_values.status === -20
				) {
					fungiObject.push(null);
				} else {
					fungiObject.push(details.attribute_values.value);
				}
			}
		});

		setLabels(labelsObject);
		setFungi(fungiObject);
		setFungiMax(entries.map(entry => 5));
		setFungiMedium(entries.map(entry => 3));

	}, []);

	const data = useMemo(() => {
		return {
			labels: labels,
			datasets: [
				{
					data: fungi,
					label: 'Fungi',
					fill: false,
					pointRadius: plots ? 4 : 0,
					lineTension: 0.3,
					pointBackgroundColor: colour,
					pointHoverRadius: 6,
					borderColor: colour,
					borderWidth: 2,
					spanGaps: true,
					datalabels: tooltips
						? {
							align: 'center',
							anchor: 'center'
						}
						: null
				},
				{
					data: fungiMedium,
					label: 'Low',
					backgroundColor: 'rgb(0, 215, 155, 0.100)',
					borderColor: 'transparent',
					fill: 'origin',
					lineTension: 0,
					pointRadius: 0,
					pointBackgroundColor: 'rgb(0, 215, 155);'
				},
				{
					data: fungiMedium,
					label: 'Medium',
					backgroundColor: 'rgb(255, 197,0, 0.100)',
					borderColor: 'transparent',
					fill: 3,
					lineTension: 0,
					pointRadius: 0,
					pointBackgroundColor: 'rgb(0, 215, 155);'
				},
				{
					data: fungiMax,
					label: 'High',
					backgroundColor: 'rgba(252, 70, 112, 0.100)',
					borderColor: 'transparent',
					fill: 'end',
					lineTension: 0,
					pointRadius: 0,
					pointBackgroundColor: 'rgb(0, 215, 155);'
				}
			]
		};
	}, [labels, fungi, plots, colour, tooltips, fungiMedium, fungiMax]);

	const options = useMemo(() => {
		return {
			elements: {
				point: {
					radius: 4,
					pointHitRadius: 0,
					hoverRadius: 0,
					pointHoverBorderWidth: 0,
					pointHoverRadius: 0
				}
			},
			plugins: {
				datalabels: {
					backgroundColor: colour,
					borderRadius: 4,
					color: 'white',
					font: {
						weight: 'bold'
					},
					display: context => (context.dataset.datalabels ? true : false),
					padding: 4
				},
				title: {
					display: false,
					text: 'Fungi levels',
					fontSize: 20
				},
				legend: { display: false }
			},
			bezierCurve: false,
			scales: {
				y:
				{
					display: true,
					ticks: {
						stepSize: 1,
						suggestedMax: fungiMax && fungiMax[0] + 1,
						callback: function (value) {
							//console.log(value)
							if (value === -2) {
								return 'N/A';
							} else if (value === -1) {
								return 'Pending';
							} else if (value === 0) {
								return 'Clear';
							} else if (value === 2) {
								return 'Low';
							} else if (value === 4) {
								return 'Medium';
							} else if (value === 6) {
								return 'High';
							}
						}
					}
				}

			},
			animation: {
				onComplete: () => {

					let b64 = chartRef?.current?.toBase64Image();
					if (b64) {
						setBase64Image(b64);
						dispatchCharts({
							type: SAVE_CHARTS,
							payload: {
								fungi_base64: b64
							}
						});
					}
				}
			}
		};
	}, [colour, dispatchCharts, fungiMax, chartRef]);

	//console.log(data)

	return {
		createFungiChart,
		fungiData: data,
		fungiOptions: options,
		fungiBase64: base64Image,
		fungiChartRef: chartRef
	};
};

export default useFungiChart;
