import React from "react";
import { Link } from "react-router-dom";
import PageContent from "../coolantcare/PageContent";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";

const NotFound = () => {
    return (
        <>
            <InnerHeaderWrap>
                <h3>Whoops! 404 Error, page not found.</h3>
            </InnerHeaderWrap>
            <PageContent>
                <Link className="btn btn-lg btn-primary btn-block" to="/">
                    Back Home
                </Link>
            </PageContent>
        </>
    );
}
export default NotFound;