//Import helper functions

import { UPDATE_BASKET } from "../../store/BasketReducers";

//Update totals
export function updateBasketTotals(currentItems, dispatchBasket) {

	let total_price_rrp = 0
	let total_price_incl_discount = 0
	let total_quantity = 0

	currentItems.forEach((item) => {
		total_price_rrp = total_price_rrp + (item.price_rrp * item.product_quantity)
		total_price_incl_discount = total_price_incl_discount + (item.price_incl_discount * item.product_quantity)
		total_quantity = total_quantity + parseInt(item.product_quantity)
	})
	
	dispatchBasket({
		type: UPDATE_BASKET,
		payload:{
			"totals": {
				"total_price_incl_discount": total_price_incl_discount, //total discount
				"total_price_rrp": total_price_rrp, //total rrp
				"total_quantity": total_quantity,
			},
		}
	});
}
