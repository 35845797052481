
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { useMemo } from 'react';

const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

  // Calculate the options for filtering

  console.log(filterValue)
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = [];
    preFilteredRows.forEach((row) => {
      options.push(row.values[id]);
    });

    var filtered = options.filter(x => x !== undefined);

    const unique = [...new Set(filtered)]

 //console.log(unique)

    return [...unique.values()];

  }, [id, preFilteredRows]);

  //console.log(filterValue)
  //console.log(options)
  // Render a multi-select box

  return (
    <>
      {options ?
        <Autocomplete
          id={id}
          options={options ? options : []}
          getOptionLabel={option => {
           //console.log(option)
            return (option && Object.values(option) ? JSON.parse(option).company_name : '')
          }}
          renderInput={params => (
            <TextField
              sx={{
                '& .MuiSvgIcon-root': {
                  color: (theme) => theme.palette.primary.contrastText
                },
                '& .MuiInputBase-root': {
                  marginTop: '5px'
                },
                '& .MuiInput-root:before, & .MuiInput-root:hover:not(.Mui-disabled):before': {
                  borderColor: (theme) => theme.palette.primary.contrastText
                },
                '& .MuiInputBase-input input': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block'
                },
                '& .MuiInput-underline': {
                  color: (theme) => theme.palette.primary.contrastText
                }
              }}
              //required
              {...params}
              //label={title}
              variant="standard"
            //InputLabelProps={{ shrink: true }}  
            />
          )}
          value={filterValue || [`{"company_id" : 0, "company_name" : "All Companies (${options.length})"}`]}
          //value={filterValue}
          //value={filterValue}
          //isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(e, value) => {
            setFilter(value || undefined);
          }}
        />
        : null}
    </>
  );
}
export default SelectColumnFilter;