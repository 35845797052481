import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from '../config';

const cognitoPromise = ({ params, fn }) =>
	Auth.currentCredentials().then(async (credentials) =>
		new Promise((resolve, reject) => {
			const cognito_identity_service_provider = new AWS.CognitoIdentityServiceProvider(
				{
					apiVersion: '2016-04-18',
					region: config.cognito.REGION,
					credentials: Auth.essentialCredentials(credentials)
				}
			);
			cognito_identity_service_provider[fn](params, (err, data) => {
				if (err) reject(err);
				resolve(data);
			});
		})
	);
export default cognitoPromise;
