import { Alert, Button, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import config from "../config";
import { UserContext } from "../store/UserContext";
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from "../store/CompanyContext";
import DialogLoader from "../reusable/DialogLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import GlobalDialog from "../reusable/GlobalDialog";
import { Formik } from "formik";
import createProductObject from "../helpers/createProductObject";
import { UPDATE_BASKET } from "../store/BasketReducers";
import { BasketContext } from "../store/BasketContext";
import { updateBasketTotals } from "../basket/helpers/updateBasketTotals";


const SaveQuote = ({ basket, setOpenCheckout, closeBasket }) => {

    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const { dispatchBasket } = useContext(BasketContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    //update basket
    const update_basket = useMutation((basket) => {
        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'basket',
            api_key: user.api_key,
            method: 'patch',
            body: {
                "basket_id": basket.basket_id,
                "basket_name": basket.basket_name,
                'basket_items': []
            }
        })
    })


    const save_quote = useMutation((full_order) => {

        //save the request
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'orders',
            api_key: user.api_key,
            method: 'post',
            body: full_order
        })

    }, {
        onSuccess: () => {
            //clear basket
            dispatchBasket({
                type: UPDATE_BASKET,
                payload: {
                    "items": [],
                }
            })
            update_basket.mutate(basket)
            updateBasketTotals([], dispatchBasket);
        }
    })

    const handleSend = async (values) => {

        //loop through basket items and create object for order
        //let updateObject = await createProductObject(basket.items)

        const full_quote = {
            "order_user": {
                "user_name": user.user.username,
                "user_id": user.user.user_id,
                "user_email": user.user.email
            },
            "order_company": {
                "company_id": selectedCompany.company_id,
                "company_name": selectedCompany.company_name,
            },
            "order_items": basket.items,
            "order_stage": "saved_quote",
            "order_total": basket.totals,
            "order_date": new Date(),
            "order_number": values.reference
        }
        //console.log(request)
        save_quote.mutate(full_quote);
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                //color="success"
                //size="small"
                startIcon={<FontAwesomeIcon icon={faSave} />}
            >
                Save Quote
            </Button>

            <Formik
                initialValues={{ reference: '' }}
                //validate={}
                onSubmit={(values, { setSubmitting }) => {
                    handleSend(values)
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    //isSubmitting,
                    /* and other goodies */
                }) => (

                    <GlobalDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        title="Save Quote"
                        buttonClick={handleSubmit}
                        buttonTitle="Save Quote"
                        maxWidth="md"
                    >
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    sx={{ marginTop: '1em', minWidth: '300px' }}
                                    name="reference"
                                    label="Your Quote Reference"
                                    value={values.reference}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.reference && Boolean(errors.reference)}
                                    helperText={touched.reference && errors.reference}

                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Alert severity="info" >This saved quote can be accessed in &quot;My Account&quot; area.</Alert>
                            </Grid>
                        </Grid>
                        {loading || save_quote.isLoading || save_quote.isSuccess || save_quote.isError ?
                            <DialogLoader
                                isLoading={loading || save_quote.isLoading}
                                mutation={save_quote}
                                loadingMessage="Saving Quote"
                                successMessage="Quote Saved"
                                closeDialog={() => {
                                    setOpen(false);
                                    setOpenCheckout(false);
                                    closeBasket(false);
                                }}
                                fixed
                            />
                            : null}
                    </GlobalDialog>

                )}
            </Formik>

        </>
    );
};

export default SaveQuote;