import React, { useMemo, useContext, useState, useEffect } from "react";
import { Avatar, Box, Card, CardContent, Fade, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography, Tooltip } from "@mui/material";
import BrandLogo from "../../shop/reusable/BrandLogo";
import { UserContext } from "../../store/UserContext";
import ProductPrices from "./old/ProductPrices";
import highlightText from "../../helpers/highlightText";
import config from "../../config";
import useEnhancedCIM from "../../hooks/useEnhancedCIM";
import useEnhancedEzbase from "../../hooks/useEnhancedEzbase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImageSlash } from "@fortawesome/pro-light-svg-icons";
import SpinningLoader from "../../reusable/SpinnningLoader";
import { faCube } from "@fortawesome/pro-light-svg-icons";
import Delivery from "../shared/Delivery";
import useGetPrices from "../../hooks/useGetPrices";
import useEnhancedBrand from "../../hooks/useEnhancedBrand";

function CustomRow({ columns, item, ind, productClick, hidden = [] }) {

    const [selectedProduct, setSelectedProduct] = useState();
    const { cim_isFecthing } = useEnhancedCIM({ setSelectedProduct, product: item });
    const { ezbase_isFecthing } = useEnhancedEzbase({ setSelectedProduct, product: item });
    const { isFetchingPrice } = useGetPrices({ productDetails: item, setProductDetails: setSelectedProduct });
    const { brand_isFetching } = useEnhancedBrand({ setSelectedProduct, product: item });

    useEffect(() => {
        if (item) {
            setSelectedProduct(current => ({
                ...current,
                ...item
            }));
        }
        return () => {
            setSelectedProduct()
        }
    }, [item.product_id])

    //console.log(selectedProduct?.product_image)

    return (
        <TableRow
            hover
            key={ind}
            sx={{
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
                backgroundColor: (theme) => theme.palette.background.paper,
                '&:nth-of-type(odd)': {
                    backgroundColor: (theme) => theme.palette.background.default,
                },
                // hide last border
                '&:last-child td, &:last-child th': {
                    borderBottom: 0,
                },
            }}>
            {selectedProduct ? columns.map((column, index) => {
                //console.log(props)
                //console.log(item)
                const tData = selectedProduct[column.accessor] ? selectedProduct[column.accessor] : "——";
                if (column.accessor === "product_image") {
                    return (
                        <TableCell
                            key={index}
                            sx={{
                                maxWidth: column.maxWidth ? column.maxWidth : 'auto',
                                minWidth: column.minWidth ? column.minWidth : 'auto',
                                position: 'relative',
                            }}
                            onClick={() => {
                                if (column.accessor !== 'price') {
                                    productClick(selectedProduct)
                                }
                            }}
                        >
                            {cim_isFecthing || ezbase_isFecthing ?
                                <Grid
                                    container
                                    alignContent="center"
                                    justifyContent="center"
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1
                                    }}>
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                        <SpinningLoader size={40} grey />
                                    </Grid>
                                </Grid>
                                :
                                column.Cell ? column.Cell(selectedProduct) : tData
                            }
                        </TableCell>
                    )
                }
                if (!column.hidden && !hidden.includes(column.accessor)) {
                    return (
                        <TableCell
                            key={index}
                            sx={{
                                maxWidth: column.maxWidth ? column.maxWidth : 'auto',
                                minWidth: column.minWidth ? column.minWidth : 'auto',
                                position: 'relative',
                            }}
                            onClick={() => {
                                if (column.accessor !== 'price') {
                                    productClick(selectedProduct)
                                }
                            }}
                        >
                            {column.Cell ? column.Cell(selectedProduct) : tData}
                        </TableCell>
                    )
                }
            })
                : null}
        </TableRow>
    )
}

export default function ProductTable({ firstLoad, products, productClick, pagination, value, filters }) {

    const { user } = useContext(UserContext);
    const [sortColumn, setSortColumn] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [hidden, setHidden] = useState([]);

    const columns = useMemo(() => {
        let columns = [
            {
                id: "product_image",
                name: 'Product ID',
                accessor: "product_image",
                Header: () => 'Graphic',
                maxWidth: 140,
                Cell: (row) => {
                    return (
                        <>
                            {row?.product_image?.[0]?.url ?
                                <Fade in={true}>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box sx={{ width: '100px', height: '30px' }}>
                                            <img
                                                style={{
                                                    pointerEvents: 'none',
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}
                                                src={row.product_image[0].url}
                                                onError={(e) => {
                                                    if (row?.product_image?.[1]?.url) {
                                                        e.target.src = row?.product_image?.[1]?.url
                                                    }
                                                }}
                                            //alt={selectedProduct?.product_id}
                                            />
                                        </Box>
                                        {row?.product_3d ?
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '18px',
                                                }}
                                            >
                                                <Tooltip title={"3D Models Available"} placement="bottom" arrow>
                                                    <Avatar
                                                        //variant="rounded"
                                                        sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                            float: 'left',
                                                            cursor: 'pointer',
                                                            backgroundColor: (theme) => theme.palette.primary.main,
                                                            '& svg': {
                                                                width: '13px',
                                                                height: '13px'
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faCube} size="xs" />
                                                    </Avatar>
                                                </Tooltip>
                                            </Box>
                                            : null}
                                    </Box>
                                </Fade >
                                :

                                <Fade in={true}>

                                    <Box
                                        sx={{
                                            width: '100px',
                                            height: '30px',
                                            top: 0,
                                            left: 0,
                                            zIndex: 0,
                                            textAlign: 'center',
                                        }}>
                                        {row?.product_brand_url ?
                                            <img

                                                alt={row?.product_manufacturer}
                                                src={row?.product_brand_url}
                                                style={{
                                                    //grey scale	
                                                    filter: 'grayscale(100%)',
                                                    left: 0,
                                                    opacity: 0.1,
                                                    width: '100%', height: '100%',
                                                    objectFit: 'contain',
                                                    //padding: '1em',
                                                }}
                                            />
                                            :
                                            <FontAwesomeIcon size={'2x'} icon={faImageSlash} color="#ccc" style={{ margin: '.45em auto' }} />
                                        }

                                    </Box>
                                </Fade>
                            }
                        </>
                    )
                },
            }, {
                id: "product_id",
                maxWidth: 180,
                name: 'ERP No',
                Header: () => config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No",
                accessor: "product_id",
                Cell: (row) => {
                    let text = row.product_id;
                    //console.log(text)
                    if (value && text) {
                        return highlightText(text, value, filters?.type)
                    } else if (text) {
                        return text
                    } else {
                        return '-'
                    }
                }
            }, {
                id: "product_number",
                maxWidth: 180,
                name: 'Manufacturer No',
                accessor: "product_number",
                Header: () => 'Manufacturer No',
                Cell: (row) => {
                    let text = row.product_number;
                    //console.log(text)
                    if (value && text) {
                        return highlightText(text, value, filters?.type)
                    } else if (text) {
                        return text
                    } else {
                        return '-'
                    }
                }
            }, {
                id: "product_manufacturer",
                maxWidth: 180,
                name: 'Manufacturer',
                Header: () => 'Manufacturer',
                accessor: "product_manufacturer",
                Cell: (row) => {
                    return (
                        <>
                            {row ?
                                <Box sx={{ width: '80px', height: '50px' }}>
                                    <BrandLogo product={row} />
                                </Box>
                                :
                                <Typography variant="caption">
                                    {row.product_manufacturer}
                                </Typography>
                            }
                        </>
                    )
                }
            }, {
                id: "product_description",
                name: "Description",
                Header: () => "Description",
                accessor: "product_description",
                maxWidth: 220,
                Cell: (row) => {
                    let text = row.product_description;
                    //console.log(text)
                    if (value && text) {
                        return highlightText(text, value, filters?.type)
                    } else if (text) {
                        return text
                    } else {
                        return '-'
                    }
                }
            }, {
                id: 'product_packet_size',
                accessor: "product_packet_size",
                Header: () => 'Packet Size'
            }, {
                id: "product_price",
                name: 'price',
                Header: () => "Price",
                accessor: "product_price",
                minWidth: 220,
                Cell: (row) => {
                    //console.log(row)
                    return (
                        <ProductPrices product={row} table={true} />
                    )
                }
            }, {
                id: "product_delivery",
                name: 'delivery',
                Header: () => "Delivery",
                accessor: "product_delivery",
                minWidth: 220,
                Cell: (row) => {
                    //console.log(row)
                    return (
                        <Delivery product={row} table={true} />
                    )
                }
            }]
        return columns;
    }, [products, user]);


    const handleSorting = (field) => {
        //console.log(field)
        //if field is currently the sortColumn
        if (sortColumn === field.field) {
            //set decending
            if (sortOrder === "asc") {
                setSortOrder("desc")
                //remove sortColumn and sortOrder
            } else if (sortOrder === "desc") {
                setSortOrder();
                setSortColumn();
            }
        } else {
            //set ascending
            setSortColumn(field.field);
            setSortOrder("asc");
        }
        //console.log(field)
    }

    return (
        <Grid container item xs={12}>
            <Grid item sx={{
                display: 'grid',
                width: '100%'
            }}>
                <Card>
                    <CardContent
                        sx={{
                            overflowY: 'auto',
                            overflowY: 'auto',
                            width: '100%',
                            padding: 0,
                            //height: 'calc(100vh - 400px)'
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow
                                    sx={{ background: (theme) => theme.palette.background.header }}
                                >
                                    {columns.map((column, i) => {
                                        //console.log(column)
                                        if (!column.hidden && !hidden.includes(column.accessor)) {
                                            //console.log(name)
                                            return (
                                                <TableCell
                                                    key={i}
                                                    padding={column.disablePadding ? 'none' : 'normal'}
                                                    sx={{
                                                        background: (theme) => sortColumn === column.accessor ? theme.palette.primary.light : theme.palette.primary.main,
                                                        color: (theme) => theme.palette.primary.contrastText,
                                                        '&:hover ': {
                                                            color: (theme) => theme.palette.primary.contrastText,
                                                        },
                                                        maxWidth: column.maxWidth ? column.maxWidth : 'auto',
                                                        minWidth: column.minWidth ? column.minWidth : 'auto'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: '80%',
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                            width: '1px',
                                                            background: (theme) => theme.palette.primary.light,
                                                            position: 'absolute',
                                                            left: 0,
                                                            top: '10%'
                                                        }}
                                                    />
                                                    {!column.noSort && column.field ?
                                                        <TableSortLabel
                                                            active={sortColumn === column.field}
                                                            direction={sortColumn === column.field ? sortOrder : "asc"}
                                                            onClick={(e) => handleSorting(column)}
                                                        >
                                                            {column.Header ? column.Header() : column.name}
                                                        </TableSortLabel>
                                                        :
                                                        column.Header ? column.Header() : column.name
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {firstLoad ?
                                    new Array(pagination?.pageSize ? pagination.pageSize : 10).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    }
                                                }}>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : products ? products.map((item, ind) => {
                                        return (
                                            <CustomRow
                                                item={item}
                                                columns={columns}
                                                key={ind}
                                                ind={ind}
                                                productClick={productClick}
                                                hidden={hidden}
                                            />
                                        )
                                    })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={columns.length} align="center">
                                                <Typography variant="h6">No products found</Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    );
};