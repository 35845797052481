import { Chip, Grid, Typography } from "@mui/material";
import React from "react";
import QuoteItem from "../quotes/QuoteItem";

const ProductItems = ({ order }) => {

    return (
        <>
            <Grid item xs={12}>
                {order.order_items.map((item, index) => (
                    <QuoteItem product={item} key={index} />
                ))}
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={5}>
                    <Typography color="text.secondary" variant="h6" >Total:</Typography>
                </Grid>
                <Grid item xs="auto" md={7} sx={{ textAlign: 'right' }}>
                    <Typography variant="h5" sx={{ color: theme => theme.palette.success.main }}>
                        {order.order_total.total_price_incl_discount < order.order_total.total_price_rrp ?
                            <Typography variant="p" component="span" sx={{
                                textDecoration: 'line-through',
                                marginRight: '10px',
                                fontSize: '16px',
                                color: '#ccc'
                            }} >
                                £{order.order_total.total_price_rrp.toFixed(2)}
                            </Typography>
                            : null}
                        {order.order_total.total_price_incl_discount ? `£${(order.order_total.total_price_incl_discount).toFixed(2)}` : null}
                        {/* { count > 0  ? <Typography color="text.secondary" variant="p" >+ {count} x POA</Typography> : null} */}
                        {order.order_total.total_price_incl_discount < order.order_total.total_price_rrp ?
                            <Chip
                                sx={{ marginLeft: '5px' }}
                                //size="small"
                                color="success"
                                label={`Saving £${(order.order_total.total_price_rrp - order.order_total.total_price_incl_discount).toFixed(2)}`}
                            />
                            : null}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default ProductItems;