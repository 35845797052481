import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Grid,
	MenuItem,
	Typography
} from '@mui/material';
import { UserContext } from '../../store/UserContext';
import { BrandsContext } from '../../store/BrandsContext';
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import TabContent from '../../layout/TabContent';
import TabHeader from '../../layout/TabHeader';
import ShopTable from './ShopTable';
import DotsMenu from '../../reusable/DotsMenu';
import ShopForm from './ShopForm';
import { Formik } from 'formik';
import config from '../../config';
import DialogLoader from '../../reusable/DialogLoader';
import axiosRequest from '../../axios/axoisRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UPDATE_BRANDS } from '../../store/BrandsReducers';

export default function ShopBrands() {

	const { user } = useContext(UserContext);
	const { dispatchBrands } = useContext(BrandsContext);
	const [editOpen, setEditOpen] = useState(false);
	const [selected, setSelected] = useState();
	const [brands, setBrands] = useState();
	const [newBrand, setNewBrand] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { data, isFetching, refetch } = useQuery({
		queryKey: ['opensearch brands'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.opensearchAPI.URL,
			endpoint: 'tools-search/brands',
			api_key: user.api_key,
			method: 'get'
		}),
		retry: 1
	})

	useEffect(() => {
		if (data) {
			//console.log(brands)
			if (!data?.errorMessage) {
				//console.log(brands)
				dispatchBrands({
					type: UPDATE_BRANDS,
					payload: {
						openSearchBrands: data
					}
				})
				setBrands(data)
			}
		}
	}, [data])


	const { updateActivityLog } = useActivityLog();

	const columns = useMemo(() => [
		{
			accessorKey: 'id',
			header: () => 'id',
			cell: info => info.getValue(),
			size: 40,
			enableSorting: false,
			hidden: true,
			//minSize: 400,
			//maxSize: 400
		}, {
			header: 'Product Dataset',
			footer: props => props.column.id,
			columns: [
				{
					name: 'Name',
					accessorKey: 'dataset',
					header: () => 'Name',
					cell: info => info.getValue(),
					size: 140,
					
				}, {
					name: 'Supplier Code',
					accessorKey: 'supplier_code',
					header: () => 'Supplier Code',
					cell: info => info.getValue(),
					size: 200,
				}, {
					name: 'Supplier Name',
					accessorKey: 'supplier_name',
					header: () => 'Supplier Name',
					cell: info => info.getValue(),
					size: 200,
				}, {
					name: 'Brand Name',
					accessorKey: 'brand_name',
					header: () => 'Brand Name',
					cell: info => info.getValue(),
					size: 240,
				}]
		}, {
			header: 'ERP Details',
			footer: props => props.column.id,
			columns: [
				{
					name: 'Supplier Code',
					accessorKey: 'erp_code',
					header: () => 'Supplier Code',
					cell: info => info.getValue(),
					size: 200,
				}]
		}, {
			header: 'Shop Settings',
			footer: props => props.column.id,
			columns: [
				{
					accessorKey: 'brand_logo',
					header: () => 'Brand Logo URL',
					enableColumnFilter: false,
					cell: info =>
						<Box
							sx={{
								width: '100px',
								height: '80px'
							}}
						>
							<img src={info.getValue()} alt="logo" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
						</Box>,
					size: 240,

				}, {
					accessorKey: 'local_delivery',
					header: () => 'Local Delivery',
					cell: info => info.getValue(),
					enableColumnFilter: false,
					size: 200,
				}, {
					accessorKey: 'external_delivery',
					header: () => 'External Delivery',
					cell: info => info.getValue(),
					enableColumnFilter: false,
					size: 200,
				}]
		}, {
			header: () => 'Actions',
			id: 'actions',
			enableColumnFilter: false,
			//accessorKey: 'order',
			cell: info => {
				//console.log(info)
				return (
					<Grid container spacing={1}>
						<DotsMenu>
							<MenuItem onClick={(e) => openEditDialog(e, info.row)}>Edit</MenuItem>
							<MenuItem onClick={(e) => openDeleteDialog(e, info.row)}>Delete</MenuItem>

							{/* <MenuItem onClick={(e) => openDeleteDialog(e, info.row)}>Delete</MenuItem> */}
						</DotsMenu>
					</Grid>
				)
			},
			size: 100
		}
	], [])


	const updateBrand = useMutation({
		mutationKey: ['edit brand'],
		mutationFn: (values) => {
			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/brands',
				api_key: user.api_key,
				method: 'post',
				body: {
					...values,
					"add_brand": newBrand
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response)
			setEditOpen(false)
			refetch()
			setSelected()
		}
	})

	const deleteBrand = useMutation({
		mutationKey: ['delete brand'],
		mutationFn: (values) => {
			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/brands',
				api_key: user.api_key,
				method: 'post',
				body: {
					...values,
					"remove_brand": true
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response)
			setConfirmDelete(false)
			refetch()
			setSelected()
		}
	})

	const openEditDialog = (e, row) => {
		setSelected(row.original);
		setEditOpen(true);
	};

	const openDeleteDialog = (e, row) => {
		setSelected(row.original);
		setConfirmDelete(true);
	};

	return (
		<>
			<TabHeader>

				<Grid 
				container 
				justifyContent="space-between"
				>
					<Grid item xs={12} sm={'auto'}>
						<Typography variant="h5">{setLanguageText("Shop Brands")}</Typography>
					</Grid>
					<Grid item >
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setSelected({
									"dataset": "",
									"supplier_code": "",
									"supplier_name": "",
									"brand_name": "",
									"brand_logo": "",
									"erp_code": "",
									"local_delivery": "",
									"external_delivery": ""
								})
								setEditOpen(true)
								setNewBrand(true)
							}}
						>
							Add Brand
						</Button>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<ShopTable
					data={brands}
					columns={columns}
					isFetching={isFetching}
				/>
			</TabContent>

			{selected && editOpen ?
				<Formik
					initialValues={selected}
					//validationSchema={validation}
					onSubmit={(values, { setSubmitting }) => {
						//console.log(values)
						updateBrand.mutate(values)
					}}
				>
					{({ submitForm, setValues }) =>

						<GlobalDialog
							open={editOpen}
							onClose={() => setEditOpen(false)}
							title={newBrand ? "New Brand" : "Edit Brand"}
							buttonClick={submitForm}
							buttonTitle={newBrand ? "New Brand" : "Edit Brand"}
							successMessage={newBrand ? "New Brand addded!" : "Brand editted successfully!"}
							fullWidth
							maxWidth="sm"
						>
							<ShopForm />
							{updateBrand.isLoading || updateBrand.isSuccess || updateBrand.isError ?
								<DialogLoader
									isLoading={updateBrand.isLoading}
									mutation={updateBrand}
									loadingMessage="Saving Brand"
									successMessage="Saved Brand"
									//closeDialog={closeRefreshDialog}
									fixed
								/>
								: null}
						</GlobalDialog>
					}
				</Formik>
				: null}

			{confirmDelete &&
				<GlobalDialog
					open={confirmDelete}
					onClose={() => setConfirmDelete(false)}
					title="Delete Brands"
					buttonTitle="Delete Brand"
					buttonClick={() => {
						deleteBrand.mutate(selected)
					}}
					maxWidth="sm"
					fullWidth
				>
					<Grid item>
						<Alert severity="error">
							Are you sure you want to delete this brand?
						</Alert>
					</Grid>
				</GlobalDialog>
			}
		</>
	);
};

