export const UPDATE_BASKET = 'UPDATE_BASKET';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const BASKET_TOGGLE = 'BASKET_TOGGLE';

export const BasketReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BASKET:
      return {
          ...state,
          "items": action.payload.items ? action.payload.items : state.items,
          "totals": action.payload.totals ? action.payload.totals : state.totals,
          "basket_id": action.payload.basket_id ? action.payload.basket_id : state.basket_id,
          "basket_name": action.payload.basket_name ? action.payload.basket_name : state.basket_name,
          "order_id": action.payload.order_id ? action.payload.order_id : state.order_id,
      };
      case BASKET_TOGGLE:
      return {
          ...state,
          "basket_open": action.payload.basket_open > 0 ? true : false,
      };
      case UPDATE_ORDER:
        return {
            "order": action.payload.order ? action.payload.order : state.order
        };
      case CLEAR_BASKET:
      return {
          "items": [],
          "totals": '',
          "basket_id":  null,
          "basket_name" : null,
          "order_id": null,
          "basket_open": false,
      };
    default:
      return state;
  }
};


