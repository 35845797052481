import React, { useReducer, createContext } from 'react';
import { UserReducer } from './UserReducers';

export const UserContext = createContext();

UserContext.displayName = 'User Details';

export const UserProvider = ({ children }) => {

	const [user, dispatchUser] = useReducer( UserReducer, {
		authenticated: false,
		api_key: null,
		aws_sub: null,
		user: [],
		customerDetails: [],
		favourites: []
	});

	const contextValues = {
		user,
		dispatchUser
	};

	return (
		<UserContext.Provider value={contextValues}>
			{children}
		</UserContext.Provider>
	);
};
