import { Card, CardContent, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import React from "react";

const Payment = () => {

    return (
        <Grid item container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: 'text.primary' }} >Payment Method</Typography>
        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardContent sx={{padding : '1em!important'}} >
                    <FormControlLabel sx={{ margin: 0 }} value="invoice" control={<Radio checked={true} />} label="Invoice on order Only" />
                </CardContent>
            </Card>
        </Grid>
        </Grid>
    );
};

export default Payment;