const createUrlParameters = (parameters) => {

    let updatedUrlValues = []
    parameters.forEach((item) => {
        //remove if there are no values
        //console.log(item.Values)
        if (item.Values) {
            //console.log(item.Values) 
            //update commas to fullstops
            let values = item.Values.map((value)=> value.replace(",","."))
            //check for Comparison
            if ((item.Comparison)) {
                if (item.Tolerance) {
                    updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${values},${item.Tolerance}]`)
                } else {
                    updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${values}]`)
                }
            } else {
                updatedUrlValues.push(`${item.Field}§${values}`)
            }
        }
    })
    return updatedUrlValues
    
}
export default createUrlParameters;