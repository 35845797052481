import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Divider, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { UPDATE_APPLICATION_FILTERS, UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";
import { SelectedContext } from "../../../store/SelectedContext";
import showFieldLogic from "../../libs/showFieldLogic";

const Integer = ({ field, focus, focusField, updateValue }) => {

    const [hide, setHide] = useState(true);
    const [localValue, setLocalValue] = useState('');
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const focusRef = useRef()

    useEffect(() => {
        if (focus === field.Abbreviation) {
            focusRef.current.focus()
        }
    }, [focus, field]);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValue('')
        }
    }, [selected.resetFilters]);

    //Set field value on first load using URL Querystring
    useEffect(() => {

        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let appSearch = JSON.parse(urlParams.get("app"))
            if (appSearch && Object.values(appSearch).length ) {
                let found = appSearch[0].filter(element => element.includes(`${field.Abbreviation}§`));
                //console.log(found)
                if (Object.values(found).length > 0) {
                    //console.log(found)
                    let fieldValue = found[0].split('§')[1]
                    setLocalValue(fieldValue)
                    updateSelected(fieldValue);
                }
            }
        }
    }, [field]);

    const updateSelected = useCallback((value) => {
        if (value) {
            updateValue({ 'fieldAbb': field.Abbreviation, 'value': `${value}${field.Unit}` })
        } else {
            updateValue({ 'fieldAbb': field.Abbreviation })
        }
        dispatchSelected({
            type: UPDATE_APPLICATION_FILTERS,
            payload: {
                'Field': field.Abbreviation, 
                'Values': [value]
            }
        });
    }, [field, dispatchSelected, updateValue])



    //hide fields based on the logic
    useEffect(() => {
        if (field.Condition) {
            let showField = showFieldLogic(field.Condition, selected.applicationFilters)
            setHide(!showField)
            //console.log(showField)
            if (!showField && localValue !== '') {
                //clear if input has a value selected and the field become hidden
                //console.log('remove')
                setLocalValue('')
                updateSelected('')
            }
        } else {
            //if there are not conditions show field
            setHide(false)
        }
    }, [field.Condition, selected.applicationFilters])

    return (
        <Grid item xs={12} sx={{ display: hide ? "none" : "grid" }} >
            <InputLabel htmlFor={field.Label} sx={{ position: 'relative', transform: 'inherit', margin: '0 0 5px 0' }}>
                <Typography sx={{ color: 'text.primary' }} ><span style={{ display: 'inline-block', color: '#ccc' }}>{field.Abbreviation} - </span> {field.Label}</Typography>
            </InputLabel>
            <TextField
                inputRef={focusRef}
                autoComplete='off'
                id={field.Name}
                value={localValue}
                label={''}
                name={field.Field}
                onClick={e => focusField(field.Abbreviation)}
                onChange={e => {
                    setLocalValue(e.target.value)
                    updateSelected(e.target.value)
                }}
                type="number"
                sx={{ width: '100%', '& legend': { display: 'none' } }}
                size="small"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                            {field.Unit}
                        </InputAdornment>
                    ,
                }}

            />
        </Grid>
    );
};

export default Integer;
