import * as React from 'react';
import { Box, CircularProgress, circularProgressClasses, Grid, Typography } from "@mui/material"
// Inspired by the former Facebook spinners.

const SpinningLoader = ({ text, size , grey }) => {
    return (
        <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"

        >
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 900],
                    }}
                    size={size ? size : 100}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => grey ? theme.palette.grey[theme.palette.mode === 'light' ? 700 : 200] : theme.palette.primary.main,
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={size ? size : 100}
                    thickness={4}
                />
            </Box>
            <Typography>{text}</Typography>
        </Grid>
    )
}

export default SpinningLoader;