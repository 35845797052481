import { Alert, Box, Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { CategoryContext } from "../../store/CategoryContext";
import { SelectedContext } from "../../store/SelectedContext";
import { UserContext } from "../../store/UserContext";
import ResultFiltersCard from "./ResultFiltersCard";
import { RESET_ALL_FILTERS, UPDATE_URL_FILTERS } from "../../store/SelectedReducers";
import Image from "../reusable/Image";
import useDebounce from "../libs/useDebounce";
import convertFiltersForAPI from "../functions/convertFiltersForAPI";
import { CompanyContext } from "../../store/CompanyContext";
import MobileDrawWrapper from "../../reusable/MobileDrawWrapper";


const ResultFilters = () => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext)
    const { categories } = useContext(CategoryContext);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const [firstLoad, setFirstload] = useState(true);
    const [visible, setVisible] = useState();

    //--------------------------- UPDATE URL ATTRIBUTES ------------------------------//

    const debouceValue = useDebounce(selected.resultFilters, 500);

    useEffect(() => {
        //console.log(debouceValue)
        if (Object.values(debouceValue).length > 0) {

            let updatedUrlValues = []
            debouceValue.forEach((item) => {
                //remove if there are no values
                if (item.Values) {
                    //check for Comparison - input fiellds
                    //console.log(Array.isArray(item.value))

                    updatedUrlValues.push(`${item.Field}§${item.Values}`)

                }
            })
            //console.log(updatedUrlValues)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'filters': [updatedUrlValues]
                }

            });
            setFirstload(false);
        } else if (!firstLoad) {
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'filters': []
                }
            });
        }

    }, [debouceValue, firstLoad])

    //--------------------------- GET FILTERS  ------------------------------//

    const { status, data, error, isFetching } = useQuery(['result_filters', categories.currentCat.ID, selected.parametricFilters],
        ({ signal, queryKey }) => {

            let filters = convertFiltersForAPI(selected.parametricFilters);

            return axiosRequest({
                signal: signal,
                gateway: config.shopAPI.URL,
                endpoint: 'filters',
                api_key: user.api_key,
                method: 'get',
                params: {
                    //category: 'bnn', testing when cats where down
                    category: categories.currentCat.ID,
                    search: JSON.stringify(filters)
                }
            })
        }, {
        enabled: !!categories.currentCat.ID,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })


    useEffect(() => {

        if (data && !data.errorMessage) {
            //console.log(data)
            let visible = []
            data.forEach((filter) => {

                //console.log(filter)
                if (filter.Values.length > 1) {

                    if (filter.Field === 'COMPC') {
                        //console.log(field.Values)
                        const clone = structuredClone(filter.Values)

                        let values = clone.filter(item => {
                            if (selectedCompany?.company_supplier_order?.includes(item.Value)) {
                                return item
                            }
                        })
                        visible.push({ ...filter, Values: values })
                    } else {

                        visible.push(filter)
                    }

                }
            })
            setVisible(visible)
        }
    }, [data, selectedCompany])

    // const resetForm = () => {
    //     dispatchSelected({
    //         type: RESET_ALL_FILTERS
    //     });
    // };

    return (
        <MobileDrawWrapper
            //resetFilters={resetForm}
            isFetching={isFetching}
        >
            {!isFetching ?
                <Box sx={{ padding: '1em' }} >
                    {categories.currentCat.Image ?
                        <Grid sx={{ textAlign: 'center', background: '#fff', padding: '1em' }} item xs={12} alignContent="center">
                            <Image isFetching={isFetching} src={categories.currentCat.Image} alt="Technical Image" ShowIcon={false} height={200} zoom />
                        </Grid>
                        : null}
                    <Grid container item spacing={1} >
                        {(visible && Object.values(visible).length) > 0 ?
                            (visible).map((field, i) => {
                                return <ResultFiltersCard index={i} field={field} key={i} />

                            }) : (visible && Object.values(visible).length === 0) && <Alert severity="warning">No Further Filters for this Selection</Alert>
                        }
                    </Grid>
                </Box>
                : null}
        </MobileDrawWrapper >
    );
};

export default ResultFilters;
