import React from "react";
import { Grid, InputLabel, Typography } from "@mui/material";

const InputLables = ({ field }) => {

    return (
        <Grid item container xs={12} alignContent="center">
            <InputLabel htmlFor={field.Name} sx={{ position: 'relative', transform: 'inherit', margin: '0 0 5px 0' }}>
                <Typography sx={{ color: 'text.primary' }} >
                    <span style={{ display: 'inline-block', color: '#ccc' }}>
                        {field.Ref}
                    </span>- {field.Name}
                </Typography>
            </InputLabel>
        </Grid>
    );
};


export default InputLables;
