import React from "react";

const FixturesRoutesWrap = (props) => {

    return (
        <>
            {props.children}
        </>
    );
};

export default FixturesRoutesWrap;
