import React, { useEffect, useState } from 'react';
import { Grid, Breadcrumbs, Skeleton, Link } from '@mui/material';

const ResourceBreadcrumbs = ({ categories, selectedCategory , setSearchParams}) => {

  const [breadcrumb, setBreadcrumb] = useState();

  //console.log(categories, selectedCategory)
  //create breadcrumb
  useEffect(() => {
    if (categories && selectedCategory) {
      setBreadcrumb()
      let breadcrumb = []
      const createBreadcrumb = (arr, catId, path) => (
        arr.reduce((a, cat) => {
          if (!path) { path = []; }
          if (a) return a;
          if (cat.resource_cat_id === catId) return [...path, { "resource_cat_name": cat.resource_cat_name, "resource_cat_id": cat.resource_cat_id }];
          if (cat.children) return createBreadcrumb(cat.children, catId, [...path, { "resource_cat_name": cat.resource_cat_name, "resource_cat_id": cat.resource_cat_id }])
        }, null)
      );
      breadcrumb = createBreadcrumb(categories, parseInt(selectedCategory));
      // if (shop.currentProductCat && Object.values(shop.currentProductCat).length) {
      //     breadcrumb.push({ Name: shop.currentProductCat.resource_cat_name })
      // }
      //console.log(breadcrumb)
      setBreadcrumb(breadcrumb)
    }
  }, [selectedCategory,categories])



  const updateCats = (e,id) => {
    e.preventDefault();
    //console.log(id)
    setSearchParams({ 'cid': id })
  }

  return (
    <Grid item xs={12} >
      {breadcrumb ?
        <Breadcrumbs
          aria-label="breadcrumb">
          {breadcrumb.map((bread, i) =>
            breadcrumb.length !== i + 1 ? (
              <Link
                key={i}
                sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: (theme) => theme.palette.text.primary
                }}
                //component="button"
                variant="body2"
                onClick={(e) => {
                  updateCats(e, bread.resource_cat_id);
                }}
              >
                {bread.resource_cat_name}
              </Link>

            ) : (

              <Link
                key={i}
                sx={{
                  textDecoration: 'none',
                  fontWeight: 500
                }}
                //component="button"
                variant="body2"
                onClick={(e) => {
                    updateCats(e,bread.resource_cat_id);
                }}
              >
                {bread.resource_cat_name}
              </Link>

            )
          )}
        </Breadcrumbs>
        :
        <Skeleton />
      }
    </Grid>
  );
};

export default ResourceBreadcrumbs;
