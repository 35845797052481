import React, { useContext, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCog,
	faMoneyCheckEdit,
	faPencilRuler
} from '@fortawesome/pro-light-svg-icons';
import { ClickAwayListener, Grid } from '@mui/material';
import AddMachineDialog from './AddMachineDialog';
import AddEntry from '../Entry/AddEntry';
import AddCompany from '../../settings/companies/AddCompany';
import { UserContext } from '../../store/UserContext';
import { useNavigate } from 'react-router';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../language/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { makeStyles } from "@mui/styles"
import { UPDATE_MENU } from '../../store/LayoutReducers';
import { ErrorBoundary } from 'react-error-boundary';
import logErrors from '../../helpers/logErrors';
import ErrorFallback from '../../reusable/ErrorFallback';
import getUserLevel from '../../helpers/getUserLevel';

const useStyles = makeStyles(theme => ({
	speedDial: {
		marginRight: isMobileOnly ? 0 : theme.spacing(2),
		width: isMobileOnly ? '50%' : 'auto',

		'& .MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
			height: isOpen => (!isOpen ? 0 : 'initial')
		},
		'& .MuiSpeedDialAction-staticTooltipLabel': {
			width: 'max-content'
		}
	},
	action: {
		'& .MuiSpeedDial-fab': {
			width: '100%',
			borderRadius: 0
		},
		'& #SpeedDial-actions': {
			alignSelf: 'flex-end'
		}
	},
	item: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		fontSize: theme.spacing(3),

	}
}));

export default function FloatingActionButton() {
	const [isOpen, setIsOpen] = useState(false);
	const [isDialog, setIsDialog] = useState();
	const { user } = useContext(UserContext);
	const { dispatchApp } = useContext(LayoutContext);
	const classes = useStyles(isOpen);
	const navigate = useNavigate();
	const openFloating = () => setIsOpen(true);
	const closeFloating = () => setIsOpen(false);

	// Each Dial Item do something different based on the operation attribute
	const handleDialAction = (e, operation) => {
		switch (operation) {
			case 'add_machine':
				closeFloating();
				setIsDialog(operation);
				break;
			case 'add_entry':
				closeFloating();
				setIsDialog(operation);
				break;
			case 'add_company':
				closeFloating();
				setIsDialog(operation);
				break;
			case 'add_service':
				closeFloating();
				dispatchApp({
					type: UPDATE_MENU,
					payload: {
						selectedArea: "coolantcare",
						selectedMenu: "service"
					}
				})
				navigate('/coolantcare/service');
				break;
			case 'add_user':
				closeFloating();
				dispatchApp({
					type: UPDATE_MENU,
					payload: {
						selectedArea: "settings",
						selectedMenu: "account"
					}
				})
				navigate('/settings/users?tab=2')
				break;
			default:
				break;
		}
	};

	return (

		<Grid item className={classes.speedDial}>
			<ClickAwayListener onClickAway={closeFloating}>
				<SpeedDial
					ariaLabel="SpeedDial"
					icon={<SpeedDialIcon />}
					onClick={isOpen ? closeFloating : openFloating}
					open={isOpen}
					direction="up"
					className={isMobileOnly ? classes.action : ''}
				>
					{getUserLevel(user.user, 'coolantcare', 'report_add', 6) ?
						<SpeedDialAction
							open={isOpen}
							icon={<FontAwesomeIcon icon={faMoneyCheckEdit} size="xs" />}
							tooltipTitle={setLanguageText('Create Service Report')}
							tooltipOpen
							onClick={e => handleDialAction(e, 'add_service')}
							className={classes.item}
						/>
						: null}
					{getUserLevel(user.user, 'coolantcare', 'entry_add', 6) ?
						<SpeedDialAction
							open={isOpen}
							icon={<FontAwesomeIcon icon={faPencilRuler} size="xs" />}
							tooltipTitle={setLanguageText('Create Machine Entry')}
							tooltipOpen
							onClick={e => handleDialAction(e, 'add_entry')}
							className={classes.item}
						/>
						: null}
					{getUserLevel(user.user, 'coolantcare', 'machine_add', 6) ?
						<SpeedDialAction
							open={isOpen}
							icon={<FontAwesomeIcon icon={faCog} size="xs" />}
							tooltipTitle={setLanguageText('Add New Machine')}
							tooltipOpen
							onClick={e => handleDialAction(e, 'add_machine')}
							className={classes.item}
						/>
						: null}

					{/*{//admin olny
						user.user.user_permissions.administrator &&
						<SpeedDialAction
							open={isOpen}
							icon={<FontAwesomeIcon icon={faIndustryAlt} size="xs" />}
							tooltipTitle={setLanguageText('Add Company')}
							tooltipOpen
							onClick={e => handleDialAction(e, 'add_company')}
							className={classes.item}
						/>
					}
					 { //admin olny
						user.user.user_permissions.administrator &&
						<SpeedDialAction
							open={isOpen}
							icon={<FontAwesomeIcon icon={faUser} size="xs" />}
							tooltipTitle={setLanguageText('Add User')}
							tooltipOpen
							onClick={e => handleDialAction(e, 'add_user')}
							className={classes.item}
						/>
					} */}
				</SpeedDial>
			</ClickAwayListener>
			{isDialog === 'add_machine' ? (
				<AddMachineDialog
					isDialog={Boolean(isDialog === 'add_machine')}
					setIsDialog={setIsDialog}
				/>
			) : isDialog === 'add_entry' ? (
				<ErrorBoundary
					FallbackComponent={ErrorFallback}
					onError={(error, info) => {
						return logErrors({
							'component': 'Add Entry - Floating Button',
							'user': user,
							'full': info.componentStack.toString(),
							'message': error.message.toString()
						})
					}}
					onReset={() => {
						setIsDialog()
					}}
				>
					<AddEntry
						isDialog={isDialog === 'add_entry'}
						setIsDialog={setIsDialog}
					/>
				</ErrorBoundary>
			) : isDialog === 'add_company' ? (
				<AddCompany
					open={isDialog === 'add_company'}
					setIsDialog={setIsDialog}
				/>
			) : null}
		</Grid>
	);
}
