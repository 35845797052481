import React, {
	useEffect,
	useCallback,
	useContext,
	useState,
} from "react";
import { UserContext } from "../store/UserContext";
import { AUTH_USER } from "../store/UserReducers";
import { Auth } from 'aws-amplify';
import config from "../config";
import '@aws-amplify/ui-react/styles.css';
import axiosRequest from '../axios/axoisRequest';
import {
	useMutation,
} from 'react-query'
import Alert from '@mui/material/Alert';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import useActivityLog from "../users/activity/useActivityLog";
import GlobalCompanySelect from "../reusable/GlobalCompanySelect";
import Loader from "../reusable/Loader";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade } from "@mui/material";
import { CompanyContext } from "../store/CompanyContext";
//import useERPToken from "../hooks/useERPToken";
import { UPDATE_EMAILS } from "../store/LayoutReducers";
import { LayoutContext } from "../store/LayoutContext";
import GlobalDialog from "../reusable/GlobalDialog";

const Login = ({ children, firstLogin }) => {

	const { updateActivityLog } = useActivityLog();
	const { user: userContext, dispatchUser } = useContext(UserContext);
	const { selectedCompany, companies } = useContext(CompanyContext);
	const { dispatchApp } = useContext(LayoutContext);
	const navigate = useNavigate();
	const { user } = useAuthenticator((context) => [context.user]);
	const { authStatus } = useAuthenticator(context => [context.authStatus]);
	const [open, setOpen] = useState(false);
	//console.log(firstLogin)

	//--------------------------- CLEAR SESSION ------------------------------//

	const clearSession = useCallback(() => {
		dispatchUser({
			type: AUTH_USER,
			payload: {
				authenticated: false,
				api_key: null,
				aws_sub: null,
				user: [],
				customerDetails: [],
				favourites: []
			}
		});
		Auth.signOut();
		navigate('/');
	}, []);

	const get_emails = useMutation((api) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'application',
			method: 'get',
			api_key: api,
			params: {
				type: 'emails',
				app_id: config.APPID
			}
		})
	}, {
		onSuccess: (data) => {
			//console.log(data)
			//update paramteric filters
			dispatchApp({
				type: UPDATE_EMAILS,
				payload: data[0].app_emails
			});
			get_emails.reset()
		},
		onError: () => {
			console.log('error fetching global emails')
			get_emails.reset()
		}
	})


	const get_user = useMutation((user) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'user',
			method: 'get',
			params: {
				'aws_sub': user.attributes.sub
			}
		})
	}, {
		onSuccess: (data) => {
			//update paramteric filters
			//console.log(data)
			get_emails.mutate(data.api_key)

			//check to see id  data.user.user_details is an object
			if (typeof data.user.user_details === 'string') {
				data.user.user_details = JSON.parse(data.user.user_details)
			}
			//check to see id  data.user.user_settings is an object
			if (typeof data.user.user_settings === 'string') {
				data.user.user_settings = JSON.parse(data.user.user_settings)
			}
			//check to see id  data.user.user_permissions is an object
			if (typeof data.user.user_permissions === 'string') {
				data.user.user_permissions = JSON.parse(data.user.user_permissions)
			}

			dispatchUser({
				type: AUTH_USER,
				payload: {
					authenticated: true,
					api_key: data.api_key,
					aws_sub: data.user.aws_sub,
					user: {
						aws_sub: data.user.aws_sub,
						email: data.user.email,
						user_details: data.user.user_details,
						user_id: data.user.user_id,
						user_permissions: data.user.user_permissions,
						user_roles: data.user.user_roles,
						user_settings: data.user.user_settings,
						username: data.user.username,

					},
				}
			});
			//set user access key
			//useAccessToken()
			//Auth.currentCredentials().then(async (credentials) => {
			// 	//console.log(credentials)
			// 	return AWS.config.update({
			// 		accessKeyId: credentials.accessKeyId,
			// 		secretAccessKey: credentials.secretAccessKey,
			// 		sessionToken: credentials.sessionToken
			// 	})
			// })
			// on log this once
			if (firstLogin) {
				updateActivityLog.mutate({
					activity_details: {
						area: "factory",
					},
					activity_type: "login"
				})
			}
			get_user.reset();
		},
		onError: () => {
			//console.log('error logging in')
			get_user.reset()
			clearSession()
		}
	})

	// console.log(user)
	// console.log(userContext)

	useEffect(() => {
		//stop infiniate render
		if (authStatus === 'authenticated' && !Object.values(userContext.user).length) {
			//console.log(user)
			get_user.mutate(user)
		}
	}, [user, authStatus, userContext]);

	useEffect(() => {
		if (firstLogin) {
			setOpen(true)
		}
	}, [firstLogin])

	const handleClose = useCallback(() => {
		if (selectedCompany.company_id) {
			setOpen(false)
		}
	}, [selectedCompany])

	return (
		<>
			{companies.length > 1 &&
				<GlobalDialog
					title="Please Select Company"
					open={open}
					//onClose={handleClose}
					buttonClick={handleClose}
					maxWidth={'sm'}
					fullWidth
					customActions={
						<Button
							variant="contained"
							color="primary"
							onClick={handleClose}
						>
							Continue
						</Button>
					}
				>
					<GlobalCompanySelect fullWidth />
					{!selectedCompany ?
						<Alert severity="warning"> Please select a company</Alert>
						: null}

				</GlobalDialog>
			}
			{!get_user.isLoading ? (
				<>
					{userContext.authenticated ? (
						<Fade in={true}>
							<Box>
								{children}
							</Box>
						</Fade>
					) : (
						<Alert variant="standard" severity="error">User not authenticated</Alert>
					)}
				</>
			) :
				<Loader
					isLoading={true}
				/>
			}
		</>
	);
};

export default Login;
