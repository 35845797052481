import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/styles';
import List from '@mui/material/List';
import { Box } from '@mui/system';
import { Divider, ListItem, ListItemIcon, ListItemText, Drawer, DialogContent, DialogTitle } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from '../../../store/LayoutContext';
import MobileMenuItems from './MobileMenuItems';
import { CategoryContext } from '../../../store/CategoryContext';
import { SelectedContext } from '../../../store/SelectedContext';
import { RESET_ALL_FILTERS } from '../../../store/SelectedReducers';
import { UPDATE_MENU } from '../../../store/LayoutReducers';
import { useNavigate } from 'react-router-dom';
const drawerWidth = 220;


const MobileMenuInner = ({ setSlideOut, slideOut, subMenu, setMobileOpen }) => {

    const theme = useTheme();
    const { dispatchApp } = useContext(LayoutContext);
    const { dispatchSelected } = useContext(SelectedContext);
    const navigate = useNavigate();

    const handleBackButton = () => {
        setSlideOut(false)
        setMobileOpen(true)
    };

    const handleItemClick = (menuItem) => {

        //reset all filters
        dispatchSelected({
            type: RESET_ALL_FILTERS
        })
        //console.log(area,menu)
        dispatchApp({
            type: UPDATE_MENU,
            payload: {
                selectedArea: menuItem.area,
                selectedMenu: menuItem.menu,
                selectedSubMenu: menuItem.submenu
            }
        })
        navigate(menuItem.link);
        setSlideOut(false)
        setMobileOpen(false)
    };

    return (
        <Drawer
            variant="temporary"
            open={slideOut}
            onClose={() => setSlideOut(false)}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: {
                    xs: 'block',
                    sm: 'none'
                },
                '& .MuiDrawer-paper': {
                    backgroundColor: theme.palette.menu.first,
                    boxSizing: 'border-box', width: '80%'
                },
            }}
        >
            <DialogTitle
                sx={{
                    borderBottom: '1px solid',
                    borderColor: 'primary.light',
                    backgroundColor: 'primary.main',
                    padding: 0,
                }}
            >
                <ListItem
                    onClick={handleBackButton}
                    sx={{ color: theme.palette.primary.contrastText }}
                >
                    <ListItemIcon sx={{ width: '20px', textAlign: 'center' }} >
                        <FontAwesomeIcon style={{ margin: '0 auto' }} color={theme.palette.primary.contrastText} icon={faChevronDoubleLeft} size={'sm'} />
                    </ListItemIcon>
                    <ListItemText primary={"Back"} />
                </ListItem>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'} sx={{ padding: 0, paddingTop: '1em!important', position: 'relative' }}>
                <List style={{
                    //marginTop: '64px',
                    width: '130%',
                    //padding: '0 0 70px 0',
                    overflow: 'hidden auto',
                    height: '100%'
                }}>
                    <MobileMenuItems menu={subMenu} handleMobileClose={handleItemClick} />
                </List>
            </DialogContent>
        </Drawer>

    );
};

export default MobileMenuInner;

