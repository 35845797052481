import React, { useEffect, useState } from 'react';
import EmailContainer from './EmailContainer';
import EmailHeader from './EmailHeader';
import config from '../config';
import EmailFooter from './EmailFooter';

const RequestPriceEmail = ({ request, theme, logo }) => {

    const demo_request = {
        "request_user": {
            "user_name": "Test User",
            "user_id": 0,
            "user_email": "test.user@factory.com"
        },
        "request_amount": 1,
        "request_company": {
            "company_id": 0,
            "company_name": "Test Company",
        },
        "request_email": "test.user@factory.com",
        "request_product": {
            "product_id": "c27beb33-54e4-4dbc-8b2d-eaf4223a73e8",
            "product_number": "ZTKS0608-MG YBG205",
            "product_name": "Parting & grooving insert (single sided)",
            "product_company": "ZCC",
            "product_image": [
                {
                    "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/c27beb33-54e4-4dbc-8b2d-eaf4223a73e8?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=s41"
                },
                {
                    "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/c27beb33-54e4-4dbc-8b2d-eaf4223a73e8?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=s3"
                },
                {
                    "url": "https://cis-act.salessupportserver.com/asp-productive/EN/DesignMonkeys/GetComponentImage/c27beb33-54e4-4dbc-8b2d-eaf4223a73e8?sessionID=emJSRlZ1MndjeDFrZEhBUy83WGdtblk2am5peGM1Mkx0RmhoVmVqYUtyMjJyQlM5dGVkSVowWkJHbmd6MjY0N05UMEZwdytYQkVidWswODQ0dlVkQ2NyY3RtTFdpa0Y4SllvS04xUkZPN1pVM1M0YWlTNEEzRFBURkJTQnIzSkpTWU1acFBtRjRvcm9CY09PaVI3Q2o0aHRQbHR1V2wxWWlKVTNaSEliS2RLRWpiTytQV2tLSS81aXRMRWEvMmUwRks4ZG5UQ3pORWFQSHQrdm5rMGdvN0hRejBtODdPSUVCYVgwL2syNXJVRmJWNXJYSW5ycVc4UUF0K0tBYk9kQ1htS1FVc1FNNDh2b2NTenhRL1lYanoxSVVydUN3cEpuRXJtc25Xc2lHNzA9&type=step_png3d"
                }
            ],
            "product_attributes": [
                {
                    "id": "J21",
                    "name": "Article number",
                    "value": "ZTKS0608-MG YBG205"
                },
                {
                    "id": "J3",
                    "name": "Company code",
                    "value": "ZCC"
                },
                {
                    "id": "NSM",
                    "name": "Standard number of properties layout",
                    "value": "DIN4000-77"
                },
                {
                    "id": "BLD",
                    "name": "Tool style code",
                    "value": "1"
                },
                {
                    "id": "A2",
                    "name": "Cutting width",
                    "value": "6"
                },
                {
                    "id": "C5",
                    "name": "Corner radius right hand",
                    "value": "0.8"
                },
                {
                    "id": "C6",
                    "name": "Corner radius left hand",
                    "value": "0.8"
                },
                {
                    "id": "E6",
                    "name": "Chip breaker face count",
                    "value": "1"
                },
                {
                    "id": "E9",
                    "name": "Chip breaker manufacturers designation",
                    "value": "G"
                },
                {
                    "id": "F2",
                    "name": "Flute count",
                    "value": "1"
                },
                {
                    "id": "G2",
                    "name": "Insert width",
                    "value": "6"
                },
                {
                    "id": "H11",
                    "name": "Standard designation of the grade, main application",
                    "value": "M20-M40"
                },
                {
                    "id": "H12",
                    "name": "Standard designation of the grade, 2nd application",
                    "value": "S05-S30"
                },
                {
                    "id": "H3",
                    "name": "Grade manufacturer's designation",
                    "value": "YBG205"
                },
                {
                    "id": "H5",
                    "name": "Coating",
                    "value": "TiAlN"
                }
            ]
        }
    }

    return (
        <EmailContainer>
            <table style={{ width: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <EmailHeader logo={logo} theme={theme} />
                <tbody>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h1 style={{ marginBottom: 10, color: theme.palette.primary.main }}>New Price Request</h1>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" align="center" colSpan="2">
                            <p><strong>Requested by: </strong>{!request ? demo_request.request_user.user_name : request?.request_user?.user_name} ({!request ? demo_request.request_user.user_email : request?.request_user?.user_email})</p>
                            <p><strong>Date: </strong> {new Date().toLocaleDateString()} @ {new Date().toLocaleTimeString()}</p>
                            <p><strong>Company: </strong>{!request ? demo_request.request_company.company_name : request?.request_company?.company_name}</p>
                            <p><strong>Request Email: </strong>{!request ? demo_request.request_email : request?.request_email}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="1" align="center" >
                            {!request ?
                                <div style={{ width: '200px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                    <img style={{ maxWidth: '200px', maxHeight: '100%', objectFit: 'contain' }} src={demo_request?.request_product?.product_image[0].url} />
                                </div>
                                : null}

                            {request?.request_product?.product_image && request?.request_product?.product_image.length > 0 ?
                                <div style={{ width: '200px', backgroundColor: '#fff', textAlign: 'center', color: '#333', margin: '0 auto' }} >
                                    <img style={{ maxWidth: '200px', maxHeight: '100%', objectFit: 'contain' }} src={request?.request_product?.product_image[0].url} />
                                </div>
                                : null}
                        </td>
                        <td colSpan="1"  >
                            <p><strong>Name: </strong>{!request ? demo_request.request_product.product_name : request?.request_product?.product_name}</p>
                            <p><strong>Article Number: </strong>{!request ? demo_request.request_product.product_number : request?.request_product?.product_number}</p>
                            <p><strong>Company Code: </strong>{!request ? demo_request.request_product.product_company : request?.request_product?.product_company}</p>
                            <p><strong>Article ID: </strong>{!request ? demo_request.request_product.product_id : request?.request_product?.product_id}</p>
                            <p><strong>Request Amount: </strong>{!request ? demo_request.request_amount : request?.request_amount}</p>

                        </td>
                    </tr>
                    {request?.request_product?.product_attributes ?
                        <tr>
                            <td colSpan="2" align="left" >
                                <table
                                    style={{ border: '1px solid #f6f6f6' }}
                                    width="100%"
                                    cellSpacing="0"
                                    cellPadding="5"
                                    align="left"
                                >
                                    <tbody>
                                        {request.request_product.product_attributes.map((prop, i) => (
                                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#f6f6f6', padding: '5px' }}>
                                                <td colSpan="1" align="left" >
                                                    {prop.name}({prop.code})
                                                </td>
                                                <td colSpan="1"  >
                                                    {prop.value}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        : !request && demo_request?.request_product?.product_attributes ?
                            <tr>
                                <td colSpan="2" align="left" >
                                    <table
                                        style={{ border: '1px solid #f6f6f6' }}
                                        width="100%"
                                        cellSpacing="0"
                                        cellPadding="5"
                                        align="left"
                                    >
                                        <tbody>
                                            {demo_request.request_product.product_attributes.map((prop, i) => (
                                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#f6f6f6', padding: '5px' }}>
                                                    <td colSpan="1" align="left" >
                                                        {prop.name}{prop.id ? `(${prop.id})` : null}
                                                    </td>
                                                    <td colSpan="1"  >
                                                        {prop.value}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            : null}
                    <EmailFooter theme={theme} />
                </tbody>
            </table>
        </EmailContainer >
    )
};

export default RequestPriceEmail;  