import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../../config";
import { CategoryContext } from "../../../store/CategoryContext";
import { SelectedContext } from "../../../store/SelectedContext";
import { UserContext } from "../../../store/UserContext";
import { UPDATE_URL_FILTERS } from "../../../store/SelectedReducers";
import Image from "../../reusable/Image";
import useDebounce from "../../libs/useDebounce";
import ResultFiltersCard from "../../parametric/ResultFiltersCard";
import axiosRequest from "../../../axios/axoisRequest";
import MobileDrawWrapper from "../../../reusable/MobileDrawWrapper";

const MatchingFilters = () => {

    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const [firstLoad, setFirstload] = useState(true);

    //--------------------------- UPDATE URL ATTRIBUTES ------------------------------//

    const debouceValue = useDebounce(selected.resultFilters, 500);

    useEffect(() => {
        //console.log(debouceValue)
        if (Object.values(debouceValue).length > 0) {

            let updatedUrlValues = []
            debouceValue.forEach((item) => {
                //remove if there are no values
                if (item.Values) {
                    //check for Comparison - input fiellds
                    //console.log(Array.isArray(item.value))
                    updatedUrlValues.push(`${item.Field}§${item.Values}`)
                }
            })
            //console.log(updatedUrlValues)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'filters': [updatedUrlValues]
                }

            });
            setFirstload(false);
        } else if (!firstLoad) {
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'filters': []
                }
            });
        }
    }, [debouceValue, firstLoad])

    //--------------------------- GET FILTERS  ------------------------------//

    const { status, data, error, isFetching } = useQuery(['result_filters'],
        ({ signal, queryKey }) => {

            let urlParams = new URLSearchParams(window.location.search);
            let product = urlParams.get('product')
            let direction = urlParams.get('direction')

            return axiosRequest({
                signal: signal,
                gateway: config.shopAPI.URL,
                endpoint: 'filters',
                api_key: user.api_key,
                method: 'get',
                params: {
                    category: "Root",
                    search: "[]",
                    product: product,
                    direction: direction,
                }
            })
        }, {
        enabled: !!Boolean(window.location.search),
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    //--------------------------- API CALLS  ------------------------------//

    return (
        <MobileDrawWrapper
            isFetching={isFetching}
        >
            <Box sx={{ padding: '1em' }} >
                {categories.currentCat.Image ?
                    <Grid sx={{ textAlign: 'center', background: '#fff', padding: '1em' }} item xs={12} alignContent="center">
                        <Image isFetching={isFetching} src={categories.currentCat.Image} alt="Technical Image" ShowIcon={false} height={200} zoom />

                    </Grid>
                    : null}
                <Grid container item spacing={1} >
                    {(data && !data.errorMessage) && (data).map((field, i) => {

                        if (field.Values.length > 1) {
                            return <ResultFiltersCard field={field} key={i} />
                        } else {
                            return null
                        }
                    })}
                </Grid>
            </Box>
        </MobileDrawWrapper >
    );
};

export default MatchingFilters;
