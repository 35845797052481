import React, { useState } from "react";
import {  Grid, TextField } from "@mui/material";
import InputLables from "./InputLables";

const String = ({ field, updateField }) => {

    const [localValue, setLocalValue] = useState('');

    return (
        <>
            <InputLables field={field} />
            <Grid item xs={12}>
                <TextField
                    sx={{width: '100%', '& legend' : { display: 'none'}}}
                    size="small"
                    fullWidth
                    value={localValue}
                    //label={field.Field}
                    name={field.Field}
                    onChange={e => {
                        setLocalValue(e.target.value)
                        updateField(field, e.target.value)
                    }}
                    placeholder={field.Name}
                />
            </Grid>
        </>
    );
};

export default String;

