import React, {
	useContext
} from "react";
//import ProductTable from "./ProductTable";
import CadProductList from "./CadProductList";
import CadProductGrid from "./CadProductGrid";
import { LayoutContext } from "../store/LayoutContext";
import { useMediaQuery } from "@mui/material";

const CadProductLayout = ({ isFetching, visible }) => {
	//Global state values
	const { layout } = useContext(LayoutContext);
	const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			{mobile ?
				<CadProductGrid isFetching={isFetching} visible={visible} />
				:
				<>
					{layout.layout === "grid" ? (
						<CadProductList isFetching={isFetching} visible={visible} />
						// <CadProductGrid isFetching={isFetching} visible={visible} />
					) : layout.layout === "engineer" ? (
						// <CadProductTable isFetching={isFetching} />
						<CadProductList isFetching={isFetching} visible={visible} />
					) : null
					}
				</>
			}
		</>
	);
};

export default CadProductLayout;
