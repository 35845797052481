import React from 'react';
import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Grid } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';

export default function QrCode(){

	return (
		<Grid
			item
			sx={{
				marginRight: theme => isMobileOnly ? 0 : theme.spacing(2),
				width: isMobileOnly ? '50%' : 'auto'
			}}
		>
			<Link to="/coolantcare/qr-code?scan=true">
				<Fab color="secondary"
					sx={{
						width: isMobileOnly ? '100%' : '56px',
						borderRadius: isMobileOnly ? 0 : '50%'
					}}
				>
					<FontAwesomeIcon icon={faQrcode} size="lg" />
				</Fab>
			</Link>
		</Grid >
	);
};
