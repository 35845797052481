import React, { useCallback, useContext, useEffect, useState } from "react";
import { Divider, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";
import { SelectedContext } from "../../../store/SelectedContext";
import InputLables from "./InputLables";

const Integer = ({ field, filtersView }) => {

    const [localValue, setLocalValue] = useState('');
    const { selected, dispatchSelected } = useContext(SelectedContext);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValue('')
        }
    }, [selected.resetFilters]);

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("search"))
            //console.log(searchArr[0])
            if (searchArr && Object.values(searchArr).length ) {
                let found = searchArr[0].filter(element => element.includes(`${field.Field}§`));
                //console.log(found)
                if (Object.values(found).length > 0) {
                    //console.log(found)
                    let fieldValue = found[0].split('§')[1]
                    setLocalValue(fieldValue)
                    updateSelected(fieldValue);
                }
            }
        }
    }, [field]);

    const updateSelected = useCallback((value) => {

        //Update poarametric search - which in turn updates URL using a debounce
        dispatchSelected({
            type: UPDATE_PARAMETRIC_FILTERS,
            payload: {
                'Field': field.Field, 
                'Values': [value], 
                'Name': field.Name, 
                "DIN" : field.Ref,
                "Toggle": true
            }
        });
    }, [field.Field, dispatchSelected])
    
    return (
        <>
            <InputLables filtersView={filtersView} field={field} />
            <Grid item xs={filtersView ? 12 : 6} >
                <TextField
                    autoComplete='off'
                    id={field.Name}
                    value={localValue}
                    label={''}
                    name={field.Field}
                    onChange={e => {
                        setLocalValue(e.target.value)
                        updateSelected(e.target.value)
                    }}
                    type="number"
                    sx={{
                        width: '100%',
                        '& input': { zIndex: 2 },
                        '& legend': { display: 'none' },
                        '& fieldset': {
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary,
                        }
                    }}
            
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                                {field.Unit}
                            </InputAdornment>
                        ,
                    }}

                />
            </Grid>
        </>
    );
};

export default Integer;
