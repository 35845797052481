
import { faMagnifyingGlass, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogContent, Grid, IconButton, Skeleton, Button } from "@mui/material";
import React, { useState } from "react";

const Image = ({ isFetching, src, alt, height, showIcon, border, zoom, sx }) => {

    //console.log(src)
    const [error, setError] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onError = () => {
        //console.log('error')
        setError(true);
    };

    return (
        <>
            {isFetching ?
                <Skeleton height={height} variant="rectangular" animation="wave" />
                : error && showIcon ?
                    <FontAwesomeIcon icon={faImageSlash} />
                    : src && !error ?
                        <Box onClick={handleClickOpen} 
                        sx={{ 
                            textAlign: 'center',
                            position: 'relative', cursor: zoom ? 'zoom-in' : 'inherit', border: border ? '1px solid #cccccc40' : 'none', ...sx }}
                        >
                            <img
                                style={{ 
                                    pointerEvents: 'none',
                                    maxWidth: '100%', 
                                    height: height ? height : 'auto', 
                                    objectFit: 'contain' , 
                                    margin: '0 auto'
                                }}
                                src={src}
                                alt={alt}
                                onError={onError}

                            />
                            {zoom &&
                                <Box
                                    sx={{
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        padding: '10px',
                                        minWidth: 'auto',
                                        background: '#ccc'
                                    }}

                                >
                                    <FontAwesomeIcon size="lg" icon={faMagnifyingGlass} />
                                </Box>
                            }
                        </Box>
                        : null
            }
            {zoom && open &&
                <Dialog
                    open={open}
                    maxWidth='xl'
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            width: 40,
                            height: 40,
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                    <DialogContent sx={{ padding: '3em', background: '#fff' }}>
                        <img style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }} src={src} alt={alt} />
                    </DialogContent>
                </Dialog>
            }
        </>
    )
};

export default Image