import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import setLanguageText from '../../language/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { Formik } from 'formik';
import * as Yup from "yup"
import config from '../../config';
import { useMutation } from 'react-query';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';
import Loader from '../../reusable/Loader';
import { UPDATE_EMAILS } from '../../store/LayoutReducers';

const ApplicationEmails = () => {

    const [emailSetting, setEmailSetting] = useState();
    const { user } = useContext(UserContext)
    const { app, dispatchApp } = useContext(LayoutContext)

    useEffect(() => {
        if (app) {
            if (app.emails) {
                //console.log(JSON.parse(data[0].theme_settings))
                setEmailSetting(app.emails)
            } else {
                setEmailSetting({
                    design_email: '',
                    solution_email: '',
                    fixtures_email: '',
                    request_email: '',
                    order_email: ''
                })
            }
        }
    }, [app])

    const validation = Yup.object({
        design_email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        solution_email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        fixtures_email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        request_email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        order_email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
    });

    const mutation = useMutation((values) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'application',
            api_key: user.api_key,
            method: 'post',
            body: {
                app_id: config.APPID,
                app_emails: values
            }
        })
    }, {
        onSuccess: (context, variables) => {
            //update emails
            console.log(variables)
            dispatchApp({
                type: UPDATE_EMAILS,
                payload: variables
            });
        }
    })

    //save columns
    const handleSubmit = (values) => {
        console.log(values)
        mutation.mutate(values)
    }

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText("Application Emails")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Formik
                    enableReinitialize={true}
                    initialValues={emailSetting}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, handleChange, touched, errors, values }) =>

                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={6} >
                                <TextField
                                    id="design_email"
                                    name="design_email"
                                    label="Design Email"
                                    value={values?.design_email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.design_email && Boolean(errors.design_email)}
                                    helperText={touched.design_email && errors.design_email}
                                    InputLabelProps={{ shrink: values?.design_email ? true : false }}

                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Alert severity='warning'>This email address will receive all Design requests from the Design area.</Alert>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="solution_email"
                                    name="solution_email"
                                    label="Solution Email"
                                    value={values?.solution_email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.solution_email && Boolean(errors.solution_email)}
                                    helperText={touched.solution_email && errors.solution_email}
                                    InputLabelProps={{ shrink: values?.solution_email ? true : false }}

                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Alert severity='warning'>This email address will receive all Solution requests from the Solution area.</Alert>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="fixtures_email"
                                    name="fixtures_email"
                                    label="Fixtures Email"
                                    value={values?.fixtures_email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.fixtures_email && Boolean(errors.fixtures_email)}
                                    helperText={touched.fixtures_email && errors.fixtures_email}
                                    InputLabelProps={{ shrink: values?.fixtures_email ? true : false }}

                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Alert severity='warning'>This email address will receive all Fixtures requests from the Fixtures area.</Alert>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="request_email"
                                    name="request_email"
                                    label="Price Request Email"
                                    value={values?.request_email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.request_email && Boolean(errors.request_email)}
                                    helperText={touched.request_email && errors.request_email}
                                    InputLabelProps={{ shrink: values?.request_email ? true : false }}

                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Alert severity='warning'>This email address will receive all Price Requests.</Alert>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="order_email"
                                    name="order_email"
                                    label="Order Placed Email"
                                    value={values?.order_email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={touched.order_email && Boolean(errors.order_email)}
                                    helperText={touched.order_email && errors.order_email}
                                    InputLabelProps={{ shrink: values?.order_email ? true : false }}

                                />

                            </Grid>
                            <Grid item xs={6} >
                                <Alert severity='warning'>This email address will receive all Orders.</Alert>
                            </Grid>
                            <Grid item xs={6} >
                                <Button
                                    //
                                    variant='contained'
                                    color='primary'
                                    onClick={submitForm}
                                >
                                    Update Emails
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Formik>

            </TabContent>
            {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                <Loader
                    isLoading={mutation.isLoading}
                    errorMessage={mutation.isError}
                    mutation={mutation}
                    loadingMessage="Updating Application Emails..."
                    successMessage="Updated Emails"
                    callback={() => {
                        //navigate('/solution/dashboard')
                        mutation.reset();
                    }}
                />
                : null}

        </>
    );
};

export default ApplicationEmails;

