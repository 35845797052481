import React, { useContext, useEffect, useState } from 'react';
import { MachineContext } from '../../store/MachineContext';
import machinesByGroup from '../../helpers/machinesByGroup';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../../language/setLanguageText';
import { CompanyContext } from '../../../store/CompanyContext';
import { useQuery } from "@tanstack/react-query";
import { UserContext } from '../../../store/UserContext';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import {
	SELECTED_GROUP,
	FILTER_MACHINES,
	SAVE_GROUPS,
	SAVE_MACHINES,
	SELECTED_MACHINES,
	SELECTED_MACHINE
} from '../../store/MachineReducers';

function DropdownGroups({ all }) {

	const {
		groups,
		machines,
		dispatchFilteredMachines,
		selectedGroup,
		dispatchSelectedGroup,
		dispatchSelectedMachine,
		dispatchSelectedMachines,
		dispatchNoMachines,
		dispatchMachines,
		dispatchGroups
	} = useContext(MachineContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { user } = useContext(UserContext);
	const [options, setOptions] = useState();

	//console.log(selectedCompany)

	//get machine entries to create groups
	const { data } = useQuery({
		queryKey: ['machine entries', selectedCompany?.company_id],
		queryFn: ({ signal }) => {

			let params = {
				company_id: selectedCompany?.company_id,
				allow_access: true
			}
			//select all machines available to user
			if (selectedCompany?.company_id === 0) {
				//admin or all access
				if (user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator) {
					params = {
						all_machine_entires: true,
						allow_access: true
					}
				} else {
					//not admin or all access, so get all compnaies they are allowed
					params = {
						company_ids: user.user.user_permissions.access.companies,
						allow_access: true
					}
				}
			}
			return axiosRequest({
				signal: signal,
				gateway: config.coolantAPI.URL,
				endpoint: 'machines/entries',
				api_key: user.api_key,
				method: 'get',
				params: params
			})
		},
		enabled: !!selectedCompany?.company_id?.toString(),
		refetchOnMount: true
	})

	//console.log(selectedCompany?.company_id)

	useEffect(() => {
		if (data) {
			//console.log(data)
			dispatchSelectedMachines({
				type: SELECTED_MACHINES,
				payload: data
			});
			dispatchGroups({ type: SAVE_GROUPS, payload: data });
			dispatchMachines({ type: SAVE_MACHINES, payload: data });
			//if no machines, show add machine button
			if (Object.values(data).length) {
				dispatchNoMachines({ type: SAVE_MACHINES, payload: false });
			} else {
				dispatchNoMachines({ type: SAVE_MACHINES, payload: true });
			}
		}
	}, [data])

	useEffect(() => {
		// Check what is the selected group and filter the machine accordingly
		if (selectedGroup?.group_name) {
			dispatchFilteredMachines({
				type: FILTER_MACHINES,
				payload: selectedGroup.group_name === setLanguageText("All Groups") ? machines : machinesByGroup(machines, selectedGroup.group_name)
			});
		}
	}, [selectedGroup, machines]);

	useEffect(() => {
		if (groups.length) {
			setOptions([{ group_name: setLanguageText("All Groups"), group_id: 0 }, ...groups]);
			//check if there is a stored group in session storage
			const storedGroup = JSON.parse(window.sessionStorage.getItem('selectedGroup'));
			//console.log(storedGroup)
			const group = groups.find(group => group.group_id === storedGroup?.group_id);
			//console.log(group)
			if (group) {
				//set stored group
				dispatchSelectedGroup({
					type: SELECTED_GROUP,
					payload: group
				});
			} else {
				//set to all groups
				dispatchSelectedGroup({
					type: SELECTED_GROUP,
					payload: { group_name: setLanguageText("All Groups"), group_id: 0 }
				});
				window.sessionStorage.removeItem('selectedGroup')
			}
		} else {
			setOptions([]);
			dispatchSelectedGroup({
				type: SELECTED_GROUP,
				payload: null
			});
			
		}
	}, [groups]);


	const onSelectGroup = group => {

		if (group) {
			
			dispatchSelectedGroup({
				type: SELECTED_GROUP,
				payload: group
			});

			dispatchSelectedMachine({
				type: SELECTED_MACHINE,
				payload: { machine_id: 0, machine_name: setLanguageText("All Machines") }
			});

			//update session storage
			window.sessionStorage.setItem('selectedGroup', JSON.stringify(group));
			window.sessionStorage.setItem('selectedMachine', JSON.stringify({ machine_id: 0, machine_name: setLanguageText("All Machines") }))

			dispatchSelectedMachines({
				type: SELECTED_MACHINES,
				payload: []
			});

			dispatchFilteredMachines({
				type: FILTER_MACHINES,
				payload: group.group_name === setLanguageText("All Groups") ? machines : machinesByGroup(machines, group.group_name)
			});
		}
	};

	//add groups counter
	const modifiedValue = selectedGroup?.group_id === 0 ? {
		...selectedGroup,
		group_name: `${selectedGroup.group_name}`
	} : selectedGroup;

	
	//do once on dashboard load
	useEffect(() => {
		if(all){
			//set to all groups
			dispatchSelectedGroup({
				type: SELECTED_GROUP,
				payload: { group_name: setLanguageText("All Groups"), group_id: 0 }
			});	
		}
	}, []);


	return (
		<Autocomplete
			disableClearable
			id="selected-group"
			value={modifiedValue && Object.entries(modifiedValue).length ? modifiedValue : null}
			options={options || []}
			getOptionLabel={option => option.group_id === 0 ? `${option.group_name} (${options?.length > 0 ? (options.length - 1) : 0})` : option.group_name}
			onChange={(event, newValue) => {
				onSelectGroup(newValue);
			}}
			isOptionEqualToValue={(option, value) =>
				option.group_id === value.group_id
			}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.group_id}>
						{option.group_name}
					</li>
				);
			}}
			renderInput={params => (
				<TextField {...params}
					variant="outlined"
					//label={setLanguageText("Selected Group/s")} 
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Group")}
				/>
			)}

		/>
	);
}

export default DropdownGroups;




