export const AUTH_USER = 'AUTH_USER';
export const RESET_USER = 'RESET_USER';
export const TEST_PERMISSIONS = 'TEST_PERMISSIONS';
export const UPDATE_FAVOURITES = 'UPDATE_FAVOURITES';

export const UserReducer = (state, action) => {
	switch (action.type) {
		case AUTH_USER:
			return {
				authenticated: action.payload.authenticated ? action.payload.authenticated : state.authenticated,
				api_key: action.payload.api_key ? action.payload.api_key : state.api_key,
				user: action.payload.user ? action.payload.user : state.user,
				aws_sub: action.payload.aws_sub ? action.payload.aws_sub : state.aws_sub,
				customerDetails: action.payload.customerDetails ? action.payload.customerDetails : state.customerDetails,
				favourites: action.payload.favourites ? action.payload.favourites : state.favourites
			};
		case RESET_USER:
			return {
				authenticated: false,
				api_key: null,
				aws_sub: null,
				user: [],
				customerDetails: [],
				favourites: []
			};
		case TEST_PERMISSIONS:
			return {
				...state,
				user: action.payload
			};
		case UPDATE_FAVOURITES:
			return {
				...state,
				favourites: action.payload
			};
		default:
			return state;
	}
};

