import formatDate from "./formatDate";

const createServicePDFData = ({
	selectedCompany,
	rowData,
	otherData,
	fromTable,
	headers
}) => {

	// console.log(headers)
	// console.log(rowData)
	// console.log(otherData)

	const setColour = (status) => {
		let colour = '';
		selectedCompany.company_colour_rules.some(attr => {
			if (parseInt(attr.value) === parseInt(status)) {
				// remove transparent as pdf renders as black!
				colour = attr.colour.hex === 'transparent' ? '' : attr.colour.hex;
			}
			return null;
		});
		return colour;
	};

	//if the data come from the entries table (create service report)
	if (fromTable) {
		rowData = rowData.map((item) => item.original)
	}

	let localHeaders;

	if (!headers) {
		let createHeaders = [
			{ 'id': 'entry_date', 'name': 'Date', 'isVisible': true },
			{ 'id': 'machine_name', 'name': 'Machine', 'isVisible': true },
			{ 'id': 'machine_group', 'name': 'Group', 'isVisible': true }
		]
		selectedCompany.company_attributes.forEach((item) => {
			createHeaders.push({ 'id': item.one.attr_id, 'name': item.one.attr_name, 'isVisible': true })
		});
		createHeaders.push(
			{ 'id': 'additives', 'name': 'Additives', 'isVisible': true },
			{ 'id': 'notes', 'name': 'Notes', 'isVisible': true }
		)
		localHeaders = createHeaders

	} else {
		//if headers are sent through from the column selection options
		localHeaders = headers
	}


	//rows are alway built from the original data
	let localRows = rowData.map((item) => {


		let row = [
			{ 'id': 'entry_id', 'value': item.entry_id },
			{ 'id': 'entry_date', 'value': item.date_stamp },
			{ 'id': 'machine_name', 'value': item.machine_name },
			{ 'id': 'machine_group', 'value': item.machine_group },
			{ 'id': 'additives', 'value': item.additives },
			{ 'id': 'notes', 'value': item.notes, 'action': item.note_action }
		]
		item.attributes_new.forEach((attr) => {

			let control = setColour(attr.attribute_values.status);

			
			if (attr.attribute_id === 'conc') {
				console.log(attr.attribute_id)
				row.push({
					'id': attr.attribute_id,
					'value': attr.attribute_values.output ? attr.attribute_values.output : '-',
					'factor': item.factor,
					'color': control
				})
			} else {
				row.push({
					'id': attr.attribute_id,
					'value': attr.attribute_values.output ? attr.attribute_values.output : '-',
					'color': control
				})
			}
		})
		return row
	})

	// reorder 
	console.log(localRows)

	var sortedArray = localRows.sort((a, b) => a[2].value.localeCompare(b[2].value))
	//console.log(sortedArray)

	//console.log(localRows)
	//create final reduce row, using the headers
	let finalRows = []
	sortedArray.forEach((row, i) => {
		let singleRow = []
		localHeaders.forEach((header, i) => {
			const output = row.find((rowItem) => rowItem.id === header.id);
			let value = output
			//console.log(output)
			if (output) {

				//data format
				if (output.id === 'entry_date') {
					value = value = { ...output, value: formatDate(output.value) }
				}
				//additive is an object
				if (output.id === 'additives') {
					if (output.value && Object.values(output.value).length) {

						let values = output.value.map((additive) => {
							console.log(additive)
							if (additive.product_input.dilution) {
								return `${additive.product_name} - ${additive.product_type_name} (${additive.product_input.dilution})`
							} else {
								return `${additive.product_type} (${additive.product_type_name})`
							}
						})
						value = { ...output, value: values }

					} else {
						value = { ...output, value: '-' }
					}
				}

				if (output?.id === 'conc' && output?.factor > 1) {
					//value = `${(Number(output.value) * Number(output.factor ? output.factor : 1)).toFixed(1)} (${Number(output.value).toFixed(1)})`
					value = {
						...output,
						value: `${(Number(output.value) * Number(output.factor ? output.factor : 1)).toFixed(1)} (${Number(output.value).toFixed(1)})`,
					}
				}
				// if (output?.id === 'notes') {
				// 	value = { type: 'notes', value: output.value, action: output.action }
				// }
				singleRow.push(value);
			}
		})
		finalRows.push(singleRow)
	})

	console.log(finalRows)

	return {
		'company': {
			'name': selectedCompany.company_name,
			'logo_path': selectedCompany.company_logo_path
		},
		'columns': localHeaders,
		'rows': finalRows,
		...otherData
	}

};

export default createServicePDFData;
