import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import config from './../../config';
import UserTable from './UserTable';
import { UserContext } from '../../store/UserContext';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import setLanguageText from '../../language/setLanguageText';
import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../cognito/cognitoFunctions';
import { faCheckCircle, faMagnifyingGlass, faTimes } from '@fortawesome/pro-light-svg-icons';
import EditUser from './EditUser';
import UpdateSub from './UpdateSub';
import ResendEmail from './ResendEmail';
import DotsMenu from '../../reusable/DotsMenu';
import SaveUserTemplate from './SaveUserTemplate';
import EnableUser from './EnableUser';
import axiosRequest from '../../axios/axoisRequest';
import { CSVLink } from 'react-csv';

const Users = () => {

    const { user } = useContext(UserContext);
    const [users, setUsers] = useState();
    const [csv_data, setCsv_data] = useState([[]]);

    //get database users
    const usersDBQuery = useQuery({
        queryKey: ['database', user],
        queryFn: ({ signal }) =>
            axiosRequest({
                signal: signal,
                gateway: config.factoryAPI.URL,
                endpoint: 'administrator/allusers',
                api_key: user.api_key,
                method: 'get',
                params: {
                    all_users: true
                }
            })
    })

    //get cognito users
    const usersCognitoQuery = useQuery({
        queryKey: ['cognito'],
        queryFn: getUsers
    })

    //merge cognito and database users
    useEffect(() => {
        if (usersDBQuery.data && usersCognitoQuery.data) {
            const dbClone = structuredClone(usersDBQuery.data);
            const cognitoClone = structuredClone(usersCognitoQuery.data);
            const map = new Map();
            const updateCognitoUsers = cognitoClone.map((user) => {
                let email = (user.Attributes).find((item) => item.Name === 'email');
                return ({
                    ...user,
                    email: email.Value,
                })
            })
            updateCognitoUsers.forEach(user => map.set(user.email.toLowerCase(), user));
            dbClone.forEach(item => map.set(item.email.toLowerCase(), { ...map.get(item.email.toLowerCase()), ...item }));
            const mergedArr = Array.from(map.values());
            setUsers(mergedArr)
        }
    }, [usersDBQuery.data, usersCognitoQuery.data])

    //refresh all user table
    const refreshTable = () => {
        usersCognitoQuery.refetch()
        usersDBQuery.refetch()
    }

    const columns = useMemo(() => [
        {
            header: setLanguageText("User Details"),
            columns: [{
                id: 'surname',
                name: 'Full Name',
                accessorFn: row => `${row?.user_details?.name} ${row?.user_details?.surname}`,
                header: () => null,
            },
            {
                accessorKey: 'email',
                name: 'Email',
                header: () => null,
            },
            {
                accessorKey: 'user_permissions.administrator',
                id: 'user_permissions.administrator',
                name: 'System Administrator',
                header: () => null,
                cell: ({ row }) => {
                    //console.log(row.original.user_permissions.administrator)
                    return (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {row.original?.user_permissions?.administrator ?
                                    <Alert severity='success'>
                                        <strong>System Administrator</strong>
                                    </Alert>
                                    :
                                    <Alert severity='error'>
                                        No Administrator permission
                                    </Alert>}
                            </Grid>
                            {row.original?.user_permissions?.s_administrator ?
                                <Grid item xs={12}>
                                    <Alert severity='warning'>
                                        This user can create <strong>System Administrators</strong>
                                    </Alert>
                                </Grid>
                                : null}
                        </Grid>
                    )
                }
            }, {
                accessorKey: 'cognito',
                header: setLanguageText("Account"),
                enableColumnFilter: false,
                cell: ({ row }) => {
                    if (!row.original?.Username) {
                        return <Alert severity='warning'>No Cognito Account</Alert>
                    } else {
                        return (
                            <>
                                {row.original?.UserStatus === 'CONFIRMED' &&
                                    <Alert severity='success'>
                                        <strong>{setLanguageText("Username")}:</strong> {row.original?.Username}<br />
                                        <strong>{setLanguageText("Status")}:</strong> {setLanguageText("Activated")}</Alert>
                                }
                                {row.original?.UserStatus === 'FORCE_CHANGE_PASSWORD' &&
                                    <Alert severity='info'>
                                        <strong>{setLanguageText("Username")}:</strong> {row.original?.Username}<br />
                                        <strong>{setLanguageText("Status")}:</strong> {setLanguageText("Password Reset")}</Alert>
                                }
                                {!row?.original?.user_id &&
                                    <Alert severity='error'>{setLanguageText("No Database User")}</Alert>
                                }
                            </>
                        )
                    }
                }
            }]
        }, {
            id: 'expander',
            header: () => null,
            enableColumnFilter: false,
            cell: ({ row }) => {
                return row.getCanExpand() && (
                    <Button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                        }}
                        size="small"
                        variant='contained'
                        color={!row.getIsExpanded() ? 'primary' : 'error'}
                        startIcon={<FontAwesomeIcon icon={!row.getIsExpanded() ? faMagnifyingGlass : faTimes} />}
                    >
                        {row.getIsExpanded() ? setLanguageText("Close Permissions") : setLanguageText("View Permissions")}
                    </Button>
                )
            },
        }, {
            accessorKey: 'Enable',
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <EnableUser row={row} setData={setUsers} />
                );
            }
        }, {
            accessorKey: 'Actions',
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <Grid container spacing={1} justifyContent="end">
                        <Grid container spacing={1}>
                            <DotsMenu>
                                <EditUser user={row.original} refreshTable={refreshTable} users={users} />
                                <SaveUserTemplate type="user_permissions" template={row.original.user_permissions} />
                                <ResendEmail row={row} />
                                <UpdateSub row={row} />
                            </DotsMenu>
                        </Grid>
                    </Grid>
                );
            }
        }
    ], [])


    const { data: roles } = useQuery({
        queryKey: ['roles'],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.factoryAPI.URL,
            endpoint: 'roles',
            api_key: user.api_key,
            method: 'get',
            params: {
                all_roles: true
            }
        }),
        retry: false
    })



    const create_csv_data = useCallback(() => {
        let row_values = [];
        const headers = [
            'First Name',
            'Last Name',
            'Email',
            'System Administrator',
            'Username',
            'Status',

        ];
        //console.log(users)
        users.forEach(value => {
            //console.log(value)
            const column_value = [
                value?.user_details?.name,
                value?.user_details?.surname,
                value?.email,
                value?.user_permissions?.s_administrator ? 'Yes' : 'No',
                value?.Username,
                value?.UserStatus
            ];
            row_values = [...row_values, column_value];
        });
        setCsv_data([headers, ...row_values]);
    }, [columns, users]);

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText("User Management")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UserTable
                            columns={columns}
                            data={users}
                            isFetching={!users}
                            getRowCanExpand={() => true}
                            roles={roles}
                        />
                    </Grid>
                    {user?.user?.user_permissions?.s_administrator &&
                        <Grid item xs={12}>
                            <CSVLink
                                data={csv_data}
                                filename={`users.csv`}
                            >
                                <Button
                                    onClick={() => create_csv_data()}
                                    variant="contained"
                                >
                                    Download All users CSV
                                </Button>
                            </CSVLink>
                        </Grid>
                    }
                </Grid>
            </TabContent>
        </>
    );
};

export default Users;
