import React, { useContext, useEffect } from "react";
import { UDPATE_ALL_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import { SelectedContext } from "../../store/SelectedContext";


const useCreateParametricFilters = (detailProfile) => {

  const { selected, dispatchSelected } = useContext(SelectedContext);

  //console.log(detailProfile)

  useEffect(() => {

    if (detailProfile) {
      let urlParams = new URLSearchParams(window.location.search);
      //console.log(urlParams)
      let hasSearch = urlParams.get("search")
      if (hasSearch && JSON.parse(hasSearch)[0]) {

        let selected = []
        //console.log(JSON.parse(hasSearch))
        JSON.parse(hasSearch)[0].forEach((filter, i) => {
          //console.log(filter)
          let string = filter.split("§")
          let match = detailProfile.Fields.find((row) => row.Field === string[0])
          //console.log(match)

          if (string[1].includes("LGE")) {
            let values = string[1].replace("LGE", '').slice(1, -1)
            let array = values.split(",")
            if (array[0] === 'e') {
              selected.push({
                ...match,
                "Field": string[0],
                "Values": [array[1]],
                //"Values": array.slice(1, -1),
                "Comparison": array[0],
                "Tolerance": array[2] ? array[2] : 0.0,
                "Toggle": true
              })
            } else {
              // GU LU G g i - all others
              selected.push({
                ...match,
                "Field": string[0],
                "Values": array.slice(1, array.length),
                //"Values": array.slice(1, -1),
                "Comparison": array[0],
                "Tolerance": 0.0,
                "Toggle": true
              })
            }
          } else {
            let value = string[1].split(",")
            //console.log(value)
            selected.push({
              ...match,
              "Field": string[0],
              "Values": value,
              "Comparison": null,
              "Tolerance": 0.0,
              "Toggle": true
            })
          }
        })
        console.log(selected)
        dispatchSelected({
          type: UDPATE_ALL_PARAMETRIC_FILTERS,
          payload: selected
        })
      }
    }

  }, [detailProfile]);
};

export default useCreateParametricFilters;

