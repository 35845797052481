import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../store/CategoryContext";
import { UserContext } from "../../store/UserContext";
import { useNavigate } from "react-router-dom";
import createUrl from "../libs/createUrl";
import { ProductsContext } from "../../store/ProductsContext";
import { SelectedContext } from "../../store/SelectedContext";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";

const ResultButton = ({ isFetching }) => {

    const { categories } = useContext(CategoryContext);
    const { products } = useContext(ProductsContext);
    const { app, dispatchLayout } = useContext(LayoutContext);
    const { selected } = useContext(SelectedContext);
    const [count, setCount] = useState();

    const navigator = useNavigate();

    const gotoProducts = (e, selected) => {
        //console.log(selected)

        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                layout: "engineer",
            }
        });
        //sessionStorage.setItem("layout", value);

        e.preventDefault();
        let url = createUrl(`/${app.selectedArea}/search/results`, selected, categories);
        navigator(url);
    };

    // const { data:result_data , isFetching:result_isFetching } = useQuery(['search', categories.currentCat.ID, selected.parametricFilters],
    //     ({ signal }) => axiosRequest({
    //         signal: signal,
    //         gateway: config.shopAPI.URL,
    //         endpoint: 'categories/parametric/results',
    //         api_key: user.api_key,
    //         method: 'get',
    //         params: {
    //             category: categories.currentCat.ID,
    //             search : JSON.stringify(selected.parametricFilters)
    //         }
    //     }), {
    //     enabled: !!categories.currentCat.ID && !!selected.parametricFilters,
    //     notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
    //     retry: 1
    // })


    useEffect(() => {
        setCount(products.count)
    }, [products])

    return (

        <Button
            variant="contained"
            onClick={(e) => gotoProducts(e, selected)}
        //disabled={false}
        >
            {isFetching ? 'Finding products...'
                : count === 0 ? `No products with thoses filters`
                    :
                    <span> Show <strong>{count ? count : '...'}</strong> Product{count > 1 && 's'}</span>
            }

        </Button>
    );
};

export default ResultButton;