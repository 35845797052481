import React, { useReducer, createContext } from 'react';
import { SelectedReducer } from './SelectedReducers';

export const SelectedContext = createContext();

SelectedContext.displayName = 'Shop Selected Filters';

export const SelectedProvider = ({ children }) => {

	const [selected, dispatchSelected] = useReducer( SelectedReducer, {
		urlFilters: {
			'application' : [],
			'search' : [],
			'filters' : []
		},
		applicationFilters: [], //application filters 
		parametricFilters: [], //parametric & shop filters
		resultFilters: [] //result filters - parametric checkboxes
	});
	const contextValues = {
		selected,
		dispatchSelected,
	};
	return (
		<SelectedContext.Provider value={contextValues}>
			{children}
		</SelectedContext.Provider>
	);
};
