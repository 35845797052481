import React, { useContext, useEffect, useState } from 'react';
import { Alert, Autocomplete, Button, Card, CardContent, CardHeader, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import { useMutation, useQuery } from 'react-query';
import axiosRequest from '../../../axios/axoisRequest';
import { CompanyContext } from '../../../store/CompanyContext';
import config from '../../../config';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import Loader from '../../../reusable/Loader';
import { LoadingButton } from '@mui/lab';

const CompanyERP = () => {

	const { language } = useContext(LayoutContext);
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [selectedERP, setSelectedERP] = useState();
	const [erpUsers, setErpUsers] = useState();


	const {
		updateActivityLog,
		//loggingError,
		//loggingResponse
	} = useActivityLog();

	//const storedToken = sessionStorage.getItem('token');
	const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');

	//get erp users
	const { data } = useQuery(['allerpusers'],
		({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.erpAPI.URL,
			endpoint: 'allusers',
			api_key: user.api_key,
			method: 'get',
			params: {
				"token": config.APPNAME === "act"  ? ACTstoredToken : CISstoredToken || null
			}
		}), {
		retry: 1,
		enabled: (!!ACTstoredToken || !!CISstoredToken),
	})

	const save_company_erp = useMutation(values => {
		//console.log(values)
		return axiosRequest({
			endpoint: 'company/settings',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			method: 'patch',
			body: {
				company_id: selectedCompany.company_id,
				company_erp: values || {}
			}
		})
	}, {
		onSuccess: (data, context) => {
			updateActivityLog.mutate({
				activity_details: {
					area: "settings",
					machine_id: context.company_id,
				},
				activity_type: "company erp updated"
			})
			save_company_erp.reset();
		}
	})

	useEffect(() => {
		if (data) {
			setErpUsers(data)
		}
	}, [data])

	useEffect(() => {
		if (selectedCompany) {
			//console.log(selectedCompany.company_erp);
			if (selectedCompany?.company_erp) {
				let erp = selectedCompany.company_erp
				if (erp?.AccountNumber) {
					setSelectedERP(selectedCompany.company_erp)
				}	else {
					setSelectedERP(null)
				}
			}
		}
	}, [selectedCompany]);

	const handleSubmit = (erpDetails) => {
		save_company_erp.mutate(erpDetails)
	}

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("ERP Connection")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Autocomplete
							disabled={!erpUsers}
							loading={!erpUsers}
							options={erpUsers || []}
							value={selectedERP || null}
							onChange={(e, value) => {
								setSelectedERP(value)
							}}
							isOptionEqualToValue={(option, value) => option.AccountNumber === value.AccountNumber}
							autoHighlight
							getOptionLabel={(option) => `${option.AccountNumber}: ${option.AccountName}`}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.AccountNumber}>
										<strong>{option.AccountNumber}:</strong> {option.AccountName}
									</li>
								);
							}}
							renderInput={(params, index) => (
								<TextField
									{...params}
									key={index}
									label="Link to ERP"
									placeholder="Please Select"
								/>
							)}
						/>

					</Grid>
					{selectedERP ?
					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={selectedERP.AccountName}
								subheader={"ERP Details"}
							/>
							<CardContent>
								<Typography variant="body1">
									<strong>Account name</strong>: {selectedERP.AccountName}
								</Typography>
								<Typography variant="body1">
									<strong>Account Number</strong>: {selectedERP.AccountNumber}
								</Typography>

								{selectedERP.AddressLine1 ?
									<Typography variant="body1">
										{selectedERP.AddressLine1}
									</Typography>
									: null}
								{selectedERP.AddressLine2 ?
									<Typography variant="body1">
										<strong>Address</strong>: {selectedERP.AddressLine2}
									</Typography>
									: null}
								{selectedERP.AddressLine3 ?
									<Typography variant="body1">
										{selectedERP.AddressLine3}
									</Typography>
									: null}
								{selectedERP.AddressLine4 ?
									<Typography variant="body1">
										{selectedERP.AddressLine4}
									</Typography>
									: null}
								{selectedERP.City ?
									<Typography variant="body1">
										<strong>City</strong>: {selectedERP.City}
									</Typography>
									: null}
								{selectedERP.County ?
									<Typography variant="body1">
										<strong>County</strong>: {selectedERP.County}
									</Typography>
									: null}
								{selectedERP.Country ?
									<Typography variant="body1">
										<strong>Country</strong>: {selectedERP.Country}
									</Typography>
									: null}
								{selectedERP.PostCode ?
									<Typography variant="body1">
										<strong>Postcode</strong>: {selectedERP.PostCode}
									</Typography>
									: null}
							</CardContent>
						</Card>
					</Grid>
						: null}
					<Grid item xs={12} >
						<LoadingButton
							disabled={save_company_erp.isLoading || save_company_erp.isLoading}
							loading={save_company_erp.isLoading}
							variant='contained'
							color='primary'
							onClick={() => handleSubmit(selectedERP)}
						>
							Update erp link
						</LoadingButton>
					</Grid>
				</Grid>
			</TabContent>
			{save_company_erp.isLoading || save_company_erp.isSuccess || save_company_erp.isError ?
				<Loader
					isLoading={save_company_erp.isLoading}
					mutation={save_company_erp}
					loadingMessage="Saving ERP Link..."
					successMessage="Saved ERP Link"
				/>
				: null}
		</>
	);
};

export default CompanyERP;
