const setColour = (company_colour_rule, status) => {
  let colour = 'transparent';
  company_colour_rule.some(attr => {
    if (parseInt(attr.value) === parseInt(status)) {
      colour = attr.colour.hex;
    }
    return null;
  });
  return colour;
};

export default setColour;
