import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Skeleton,
	TableContainer,
	TablePagination,
	Alert,
	Box,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useReactTable,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,

	flexRender
} from '@tanstack/react-table';
import { MachineContext } from '../../store/MachineContext';
import {
	SELECTED_MACHINE,
	SELECTED_MACHINES
} from '../../store/MachineReducers';
import MachineTableSettings from './MachineTableSettings';
import TableHeaderCell from '../../../tables/TableHeaderCell';
import ExportMachines from '../../Exports/ExportMachines';
import { isMobileOnly } from 'react-device-detect';
import { CompanyContext } from '../../../store/CompanyContext';
import setLanguageText from '../../../language/setLanguageText';

const MachinesTable = ({ columns, data, defaultHiddenColumns, isFetching }) => {

	const {
		dispatchSelectedMachine,
		dispatchSelectedMachines,
		filteredMachines
	} = useContext(MachineContext);
	const { selectedCompany } = useContext(CompanyContext);
	const navigate = useNavigate();
	const [columnFilters, setColumnFilters] = useState([])
	const [columnVisibility, setColumnVisibility] = useState({})
	const [globalFilter, setGlobalFilter] = useState('')
	const defaultData = useMemo(() => [], [])

	// When click on Machine's row
	const handleRowClick = (row) => {
		// fetch all the entries for the selected machine
		// const entries = await fetchEntries(event.data.id, user.api_key);

		const machine = filteredMachines.find(machine => {
			return machine.machine_id === row.original.machine_id;
		});
		// save the selected machine
		dispatchSelectedMachine({ type: SELECTED_MACHINE, payload: machine });
		dispatchSelectedMachines({
			type: SELECTED_MACHINES,
			payload: machine
		});
		window.sessionStorage.setItem('selectedMachine', JSON.stringify(machine))

		// create machine id route when clicking on Machine's row
		navigate(`/coolantcare/machine/${row.original.machine_id}`);
	};

	const table = useReactTable({
		data: data ?? defaultData,
		columns,
		state: {
			columnVisibility,
			columnFilters,
			globalFilter,
		},
		onColumnVisibilityChange: setColumnVisibility,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		// debugTable: true,
		// debugHeaders: true,
		// debugColumns: true,
	})

	const [size, setSize] = useState( window.sessionStorage.getItem('show') ? window.sessionStorage.getItem('show') :
			selectedCompany?.company_settings?.show ? selectedCompany.company_settings.show :
				100);

	useEffect(() => {
		table.setPageSize(size)
		window.sessionStorage.setItem('show', size);
	}, [size])

	const { pageSize, pageIndex } = table.getState().pagination



	//set hidden columns for company gloabl default
	useEffect(() => {
		if (defaultHiddenColumns && columns) {
			let hide = {}
			columns.forEach(column => {
				//add column to hidden columns
				let found = defaultHiddenColumns.find(item => item.id === column.id)
				hide = {
					...hide,
					[column.id]: found ? found.isVisible : false
				}
			})
			setColumnVisibility(hide)
		}
	}, [columns, defaultHiddenColumns])

	return (
		// apply the table props
		<Accordion expanded={true}>
			<AccordionSummary>
				<Grid container>
					<Grid item xs={6}>
						<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Machines")}</Typography>
					</Grid>
					<Grid container xs={6} item justifyContent="flex-end" alignItems="center" spacing={2}>
						<Grid item>
							<MachineTableSettings table={table} />
						</Grid>
						<Grid item >
							<ExportMachines columns={columns} columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility} data={data} />
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails sx={{ borderTop: '1px solid #ececec' }} >
				<Grid item xs={12}>
					<Box sx={{
						display: 'grid',
						width: '100%',
						overflow: 'auto'
					}}>
						<TableContainer
							sx={{
								display: 'grid',
								border: '1px solid #ccc'
							}}
						>
							<Table
								//size="small"
								aria-label="simple table"
							>
								<TableHead>
									{table.getHeaderGroups().map((headerGroup, i) => (
										<TableRow
											key={i}
											sx={{
												backgroundColor: (theme) => theme.palette.table.header,
											}}
										>
											{//added a custom header cell
												headerGroup.headers.map((header, i) =>
													<TableHeaderCell key={i} header={header} i={i} table={table} />
												)
											}
										</TableRow>
									))}
								</TableHead>
								<TableBody>
									{isFetching ?
										//create array from a number
										new Array(pageSize).fill(0).map((_, i) => {
											return (
												<TableRow
													key={i}
													sx={{
														cursor: 'pointer',
														borderBottom: '1px solid #ccc',
														backgroundColor: (theme) => theme.palette.table.row,
														'&:nth-of-type(odd)': {
															backgroundColor: (theme) => theme.palette.table.hover,
														},
														//backgroundColor: theme => row.state.Enabled ? theme.palette.table.rows : theme.palette.table.disabled,
														// hide last border
														'&:last-child td, &:last-child th': {
															borderBottom: 0
														},
													}}>
													{table.getHeaderGroups().map((headerGroup, i) => (
														<>
															{headerGroup.headers.map((header, i) => <TableCell key={i}><Skeleton height={30} /></TableCell>)}
														</>
													))}
												</TableRow>
											)
										})

										: !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {

											return (
												<>
													<TableRow
														key={i}
														onClick={() => handleRowClick(row)}
														sx={{
															cursor: 'pointer',
															//borderBottom: '1px solid #ccc',
															'&:last-child td, &:last-child th': {
																borderBottom: 0,
															},
														}}
													>
														{row.getVisibleCells().map((cell, i) => {
															return (
																<TableCell
																	key={i}
																	sx={{
																		width: cell.column.getSize(),
																		minWidth: cell.column.getSize(),
																		zIndex: cell.column.columnDef.sticky ? 1000 : 0,
																		position: cell.column.columnDef.sticky ? 'sticky' : 'initial',
																		//borderRight: cell.column.columnDef.sticky ? '1px double #ccc' : 'none',
																		backgroundColor: (theme) => row.original.last_entry.out_of_control ? theme.palette.table.out : theme.palette.table.rows,
																		left: 0
																	}}
																>
																	{flexRender(
																		cell.column.columnDef.cell,
																		cell.getContext()
																	)}
																	<Box
																		sx={{
																			position: 'absolute',
																			right: '0',
																			top: '0',
																			height: '100%',
																			display: 'block',
																			width: '2px',
																			backgroundColor: '#ccc',
																		}}
																	/>
																</TableCell>
															)
														})}
													</TableRow>
												</>

											)
										})
											: <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No Machines</Alert>
									}
								</TableBody>
							</Table>
							{/* <pre>{JSON.stringify(table.getState().columnVisibility, null, 2)}</pre> */}
						</TableContainer>
					</Box>
					<TablePagination
						rowsPerPageOptions={[10, 50, 100, 200]}
						component="div"
						count={table.getFilteredRowModel().rows.length}
						rowsPerPage={pageSize}
						
						page={pageIndex}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							native: true,
						}}
						onPageChange={(_, page) => {
							table.setPageIndex(page)
						}}
						onRowsPerPageChange={e => {
							const size = e.target.value ? Number(e.target.value) : 10
							table.setPageSize(size)
							window.sessionStorage.setItem('show', size);
						}}
					//ActionsComponent={TablePaginationActions}
					/>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default MachinesTable;
