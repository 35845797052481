import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import DesignEmails from './DesignEmails';
import SolutionEmails from './SolutionEmails';
import FixturesEmails from './FixturesEmails';
import OrderEmails from './OrderEmails';
import RequestEmails from './RequestEmails';
import { CompanyContext } from '../../../store/CompanyContext';

const EmailAddress = () => {

    const { selectedCompany } = useContext(CompanyContext)

    return (
        <>
            <TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Email Recipients")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <OrderEmails />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <RequestEmails />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DesignEmails />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <SolutionEmails />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FixturesEmails />
                    </Grid>
                </Grid>
            </TabContent>
        </>
    );
};

export default EmailAddress;

