
import config from '../config';
import axiosRequest from '../axios/axoisRequest';

const logErrors = (details) => {
  //console.log(process.env.NODE_ENV)
  if(process.env.NODE_ENV === "production"){
    axiosRequest({
      gateway: config.factoryAPI.URL,
      endpoint: 'activity/error',
      api_key: details.user.api_key,
      method: 'post',
      body: {
        error_details: {
          error: details.full.replace(/(\r\n|\n|\r)/gm, ""),
          message: details.message,
          component: details.component
        },
        error_user: {
          aws_sub: details.user.user.aws_sub,
          email: details.user.user.email,
          username: details.user.user.username,
          name: details.user.user.user_details.name,
          surname: details.user.user.user_details.surname
        }
      }
    })
  } else {
   //console.log('not saved')
  }
};

export default logErrors;