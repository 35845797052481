import {
    Alert,
    Backdrop,
} from "@mui/material"
import React, { useEffect, useState } from "react"


const FullScreenError = ({ errorMessage, setErrorMessage }) => {

    const [open, setOpen] = useState(true);
    //console.log(isLoading)

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage()
                setOpen(false)
            }, 3000)
        }
        return () => {
            //clean up
        } 
    }, [errorMessage])

    return (
        <>
            <Backdrop
                sx={{ zIndex: theme => theme.zIndex.tooltip + 1 }}
                open={open}
            >
                <Alert
                    variant="filled"
                    severity="error"
                >
                    An error occurred: {errorMessage}
                </Alert>
            </Backdrop>
        </>
    )
}

export default FullScreenError
