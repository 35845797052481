import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import SliderImage from "./SliderImage";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselSlider = ({ images }) => {

  const [currentSlide, setCurrentSlide] = useState(1);
  const slider1 = useRef()
  const slider2 = useRef()

  const [slider, setSlider] = useState({
    nav1: slider1.current,
    nav2: slider2.current
  });

  const settings = {
    dots: false,
    infinite: false,
    //speed: 500,
    //adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true
  };

  const navSetting = {
    dots: false,
    infinite: false,
    //speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true
  };

  return (

    <>
      <div
        style={{
          display: 'grid',
          alignContent: 'center'
        }}
      >
        <Box
          style={{
            display: 'grid',
            overflow: 'hidden',
            height: '220px',
            width: '220px',
            padding: '10px'
          }}
        >
          <Slider
            asNavFor={slider2.current}
            ref={slider => (slider1.current = slider)}
            //centerMode={true}
            {...settings}
          >
            {images && images.map((image, i) =>
              <div key={i} >
                <img
                  style={{
                    width: '250px',
                    height: '250px',
                    objectFit: 'contain'
                  }}
                  src={image.URL} />
              </div>
            )}
          </Slider>
        </Box>
      </div>
      <div
        style={{
          display: 'grid',
          alignContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'grid',
            overflow: 'hidden',
            height: '220px',
            width: '100px',
            padding: '10px',
            '& .slick-vertical .slick-current' : {
              border : '1px solid #ccc'
            }
          }}
        >
          <Slider
            asNavFor={slider1.current}
            ref={slider => (slider2.current = slider)}
            {...navSetting}
          >
            {images && images.map((image, i) =>
              <div key={i} >
               <img
                  style={{
                    width: '100%',
                    height: '60px',
                    objectFit: 'contain'
                  }}
                  src={image.URL} />
              </div>
            )}
          </Slider>
        </Box>
      </div>
    </>
  );

}

export default CarouselSlider