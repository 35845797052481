import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Typography, TextField, Grid, Chip } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { CirclePicker } from 'react-color';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import { useMutation } from "@tanstack/react-query";
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import useActivityLog from '../../users/activity/useActivityLog';
import DialogLoader from '../../reusable/DialogLoader';
import getUserLevel from '../../helpers/getUserLevel';
import ColourChipsTable from './ColourChipsTable';

export default function CompanyVariables({ currentVariables, closeModalRefresh, isFetching }) {

	const { selectedCompany } = useContext(CompanyContext);
	const { user } = useContext(UserContext);
	const [selectedVariable, setSelectedVariable] = useState();
	const [open, setOpen] = useState(false);

	const {
		updateActivityLog,
	} = useActivityLog();


	const { handleSubmit, control, reset } = useForm({
		mode: 'all',
		defaultValue: selectedVariable
	});

	useEffect(() => {
		if (selectedVariable) {
			reset({ ...selectedVariable });
		}
	}, [reset, selectedVariable]);

	//update chip colour
	const updateChips = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'company/attributes',
			api_key: user.api_key,
			method: 'post',
			body: body
		}),
		onSuccess: (data) => {
			//update activity log
			updateActivityLog.mutate({
				activity_details: {
					area: "coolant"
					//attribute: 
				},
				activity_type: "chip colour updated"
			})
			//refetch attributes
			setOpen(false);
			closeModalRefresh();
		}
	})

	const onSubmit = data => {
		let updatedVariables;
		if (currentVariables) {
			let current = [...currentVariables];
			if (currentVariables) {
				//update selected attribute
				const index = current.findIndex(e => e.id === selectedVariable.id);

				if (index > -1) {
					current[index] = data;
				}
			} else {
				//add new attribute
				current.push(data);
			}
			updatedVariables = current;
		} else {
			//add first attribute
			updatedVariables = [data];
		}
		//console.log(updatedVariables)
		let body = {
			id: selectedCompany.company_id,
			variables: updatedVariables
		};

		//update chip colour
		updateChips.mutate(body)
	};


	const handleClose = () => {
		setOpen(false);
	};

	const editVariable = selected => {
		//add selected Variable to edit
		setSelectedVariable(selected);
		setOpen(true);
	};



	return (
		<>
			<ColourChipsTable
				data={currentVariables}
				isFetching={isFetching}
				editVariable={editVariable}
			/>
			<GlobalDialog
				open={open}
				onClose={handleClose}
				title={selectedVariable?.name}
				buttonClick={handleSubmit(onSubmit)}
				buttonTitle={setLanguageText("Save")}
				fullWidth
				maxWidth="sm"
			>
				<Grid container item spacing={2} alignItems="center">
					{/* <Grid item xs={6}>
						<Controller
							name="id"
							control={control}
							defaultValue=""
							rules={{ required: 'ID required' }}
							render={({ field: { onChange, value } }) => (
								<TextField
									fullWidth
									label={setLanguageText("ID")}
									variant="outlined"
									value={value}
									disabled={true}
									onChange={onChange}
								/>
							)}
						/>
					</Grid> */}
					<Grid item xs={6}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value } }) => (
								<TextField
									fullWidth
									label={setLanguageText("Name")}
									variant="outlined"
									value={value}
									disabled={true}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					{/* <Grid item xs={6}>
						<Controller
							name="value"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value } }) => (
								<TextField
									fullWidth
									label={setLanguageText("ID")}
									variant="outlined"
									value={value}
									disabled={true}
									onChange={onChange}
								/>
							)}
						/>
					</Grid> */}
					<Grid item xs={6}>
						<Controller
							name="colour"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value } }) => (
								<CirclePicker
									color={value.hex}
									onChange={onChange}
									colors={[
										'#D0021B',
										'#fc466f',
										'#ffc500',
										'#ffd686',
										'#8B572A',
										'#7ED321',
										'#4caf50',
										'#BD10E0',
										'#9013FE',
										'#4A90E2',
										'#50E3C2',
										'#94e58b',
										'#00d79b',
										'#000000',
										'#4A4A4A',
										'#9B9B9B',
										'#bdbdbd',
										'transparent'
									]}
								/>
							)}
						/>
					</Grid>
				</Grid>
				{updateChips.isLoading || updateChips.isSuccess || updateChips.isError ?
					<DialogLoader
						isLoading={updateChips.isLoading}
						mutation={updateChips}
						loadingMessage="Updating Chip"
						successMessage="Updated Chip"
						//closeDialog={closeRefreshDialog}
						fixed
					/>
					: null}
			</GlobalDialog>
		</>
	);
}
