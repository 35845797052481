
import React, { useContext } from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import setLanguageText from '../../../language/setLanguageText';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import CompaniesTable from './CompaniesTable';
import { UserContext } from '../../../store/UserContext';

export default function Companies() {

	const { user } = useContext(UserContext);

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("Company Overview")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				{user.user.user_permissions.administrator ?
					<CompaniesTable />
					: <Alert severity='error'>Administrator access required</Alert>
				}
			</TabContent>
		</>
	);
};
