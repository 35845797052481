import React from "react";
import GlobalMeta from "./reusable/GlobalMeta";

const NoRoutesWrap = (props) => {

    return (
        <>
            <GlobalMeta title={props.title} />
            {props.children}
        </>

    );
};

export default NoRoutesWrap;
