import React, { useContext, useState } from 'react';
import { Avatar, MenuItem } from '@mui/material';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';
import { useMutation } from 'react-query';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';

const UpdateSub = ({ row, closeDialog }) => {

	const { user } = useContext(UserContext)
	const [done, setDone] = useState(false);

	//save user
	const set_aws_sub = useMutation((object) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'administrator/user',
			api_key: user.api_key,
			method: 'post',
			body: {
				update: true,
				...object
			}
		})
	}, {
		onSuccess: (data) => {
			setDone(true);
			closeDialog()
		}
	})

	const handleSubmit = () => {
		let clone = structuredClone(row.original)
		let found = clone.Attributes.find(item => item.Name = 'sub')
		if (found) {
			clone.aws_sub = found.Value
			//console.log(clone)
			set_aws_sub.mutate(clone)
		}
	}

	return (
		<>
			{process.env.NODE_ENV !== "production" && !row.original.aws_sub && row?.original?.user_id && !done ?
				<MenuItem
					variant='contained'
					onClick={e => handleSubmit()}
				>
					<Avatar sx={{ bgcolor: 'primary.warning' }}>
						<FontAwesomeIcon size="xs" icon={faEdit} />
					</Avatar>
					{setLanguageText("Update Sub")}
				</MenuItem>
				: null}
		</>
	);
};

export default UpdateSub;
