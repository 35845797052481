import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import Row from "./Row";
import Image from "./Image";
import { Alert, Box, Card, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import FormInput from "./input/String"
import { useFormikContext } from "formik";
import DesignWizardForm from "./DesignWizardForm";
import ErrorMessage from "../../reusable/ErrorMessage";

const DesignWizard = () => {

    const imageRef = useRef();
    const ref1 = useRef();
    const { user } = useContext(UserContext)
    const [mainImage, setMainImage] = useState();
    const [form, setForm] = useState();
    const [level_1, setLevel_1] = useState();
    const [level_2, setLevel_2] = useState();
    const [isFetching_2, setIsFetching_2] = useState(true);
    const [level_3, setLevel_3] = useState();
    const [isFetching_3, setIsFetching_3] = useState(true);
    const [level_4, setLevel_4] = useState();
    const [isFetching_4, setIsFetching_4] = useState(true);
    const [form_id, setForm_id] = useState();
    const [error, setError] = useState();

    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const { values, setFieldValue, handleChange, touched } = useFormikContext();

    const [design, setDesign] = useState([])

    const mutation = useMutation((form_id) => {

        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: form_id
            }
        })
    }, {
        onSuccess: (data) => {
            if (!data.errorMessage) {
                setForm(data);
            }
        }
    })

    //console.log(data)
    useEffect(() => {
        //console.log(form_id)
        if (form_id) {
            mutation.mutate(form_id)
        }
    }, [form_id]);


    const { data } = useQuery('categories',
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
        }), {
        retry: 1
    })

    //console.log(data)
    useEffect(() => {
        //console.log(data)
        if (data) {
            if (data.errorMessage) {
                //console.log(data.errorMessage)
                setError(`Category: ${data.errorMessage}`)
            } else {
                //remove unwanted categories
                const items = data.Items.filter((items) => items.ID !== "ptm2" && items.ID !== "zub" && items.ID !== "ptm")
                setLevel_1(items)
            }
        }
        return () => {
            //cleanup
            setError(null)
        }
        

    }, [data]);

    const handleSelect = (e, items, level) => {
        //console.log(items, level)
        if (level === 1) {
            setIsFetching_2(true)
            setLevel_2(items);
            setLevel_3();
            setLevel_4();
            setIsFetching_2(false)
            setForm_id()
            setMainImage()

        } else if (level === 2) {
            setIsFetching_3(true)
            setLevel_3(items);
            setLevel_4();
            setIsFetching_3(false)
            setForm_id()
            setMainImage()
        }
        else if (level === 3) {
            setIsFetching_4(true)
            setLevel_4(items);
            setIsFetching_4(false)
            setForm_id()
            setMainImage()
        }
        if (!items.Items) {
            setForm_id(items.SearchProfile)
            setMainImage(items.Image)
            // window.scrollTo({
            //     top: imageRef.current.offsetTop - 160, //- toolbar
            //     left: 0,
            //     behavior: "smooth"
            // });
        }

    }

    const updateField = (field, value) => {
        setDesign(current => {
            let index = current.findIndex(element => element.Ref === field.Ref)
            //console.log(index)
            if (index > -1) {
                current[index].value = value
                return current
            } else {
                //not in current array
                return ([
                    ...current,
                    { ...field, value: value }
                ])
            }
        })
    }

    //console.log(data)
    useEffect(() => {
        //console.log(level_2)
        //console.log(level_3)
        //console.log(level_4)
        if (design) {
            let designObject = {
                breadcrumb: [
                    { name: level_2?.Name },
                    { name: level_3?.Name },
                    { name: level_4?.Name },
                ],
                image: mainImage,
                design: design
            }
            setFieldValue('wizard', designObject);
        }
    }, [design, level_1, level_2, level_3, level_4, mainImage]);

    //console.log(design)

    return (
        <Grid container spacing={2} sx={{ overflow: 'unset' }}>

            <Grid item textAlign="" xs={12} sx={{ overflow: 'unset' }}>
                <Typography variant="h5">Tool Type</Typography>
                <Typography variant="body2" >Please select the type of tool you require.</Typography>
            </Grid>
            {error ?
                <Grid item xs={12}>
                   <ErrorMessage error={error} />
                </Grid>
                : null}
            <Grid item ref={ref1} xs={12} >
                {level_1 ?
                    <Row data={level_1} isFetching={false} handleSelect={handleSelect} level={1} />
                    : null}
            </Grid>
            <Grid item xs={12} >
                {level_2 ?
                    <Row data={level_2.Items} isFetching={isFetching_2} handleSelect={handleSelect} level={2} />
                    : null}
            </Grid>
            <Grid item xs={12} >
                {level_3 ?
                    <Row data={level_3.Items} isFetching={isFetching_3} handleSelect={handleSelect} level={3} />
                    : null}
            </Grid>
            <Grid item xs={12} >
                {level_4 ?
                    <Row data={level_4.Items} isFetching={isFetching_4} handleSelect={handleSelect} level={4} />
                    : null}
            </Grid>
            <Grid ref={imageRef} item container spacing={4} sx={{ overflow: 'unset' }}>
                {mainImage ?
                    <>
                        <Grid item textAlign="" xs={12}>
                            <Typography variant="h5">Tool Details</Typography>
                        </Grid>
                        <Grid item textAlign="" xs={12}>
                            <Typography variant="body2" >Please specify your requirements by using the fields below and the graphic for reference.</Typography>
                        </Grid>
                        <Grid item container xs={12} md={6} spacing={2} alignContent="start">
                            {form ?
                                <DesignWizardForm form={form} updateField={updateField} />
                                : null}
                            {/* {form?.Fields && form.Fields.map((field) => {

                                if (
                                    field.Position === 0
                                    && field.Ref !== "J21" //remove Article
                                    && field.Ref !== "J3" //remove Company
                                    && field.Ref !== "W1" //remove material
                                    && field.Ref !== "H3" //remove Grade
                                ) {
                                    return (
                                        <FormInput field={field} updateField={updateField} />
                                    )
                                }
                            })} */}
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ overflow: 'unset' }}>
                            <Card sx={{
                                position: "sticky",
                                top: "100px",
                            }}>
                                <Image
                                    isFetching={false}
                                    src={mainImage}
                                    alt="diagram"
                                    height={'auto'}
                                    ShowIcon={false}
                                    zoom
                                    sx={{
                                        padding: '1em',
                                        background: '#fff'
                                    }} />
                            </Card>
                        </Grid>
                    </>
                    : null}
            </Grid>
        </Grid>

    );
}
export default DesignWizard;
