import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";


const InterfaceImage = ({ field, name, reference,  item, setSelected , selected, setActiveStep }) => {

    const { dispatchSelected} = useContext(SelectedContext)
    const theme = useTheme();
    //console.log(theme)

    const updateFilters = (item) =>{
        //console.log(item)
      
        dispatchSelected({
            type: UPDATE_PARAMETRIC_FILTERS,
            payload: {
                'Field': field, 'Values': [item.ID], 'Name': name , "Ref" : reference 
            }
        });
        setSelected(item.ID)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    return (
        <Card 
            onClick={e => updateFilters(item)}
            sx={{ 
                //border : selected === item.ID ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.background.paper}`,
                background : selected === item.ID ? `${theme.palette.primary.main}24` : `${theme.palette.background.paper}`,
                cursor: 'pointer',
                display: 'flex', 
                justiyContent: 'space-between', 
                flexDirection: 'column', 
                height: '100%' ,
                '&:hover': {
                    boxShadow: '0px 0px 5px 0px #222'
                  }
            }} 
        >
             <Typography color={'text.primary'} sx={{padding: '5px', textAlign: 'right'}}>{item.Hits}</Typography>
            {item.Icon ? 
            <CardMedia
                component="img"
                height="140"
                image={item.Icon}
                alt=""
                sx={{ objectFit: "contain", padding: 2, background: '#ffffff8c' }}
                onError={e => {
                    /** 
                     * Any code. For instance, changing the `src` prop with a fallback url.
                     * In our code, I've added `e.target.className = fallback_className` for instance.
                     */
                    e.target.src = placeholder;
                }}
            />
            : 
            <Box sx={{background: '#ffffff8c', height: '140px', textAlign: 'center' }} >
                <FontAwesomeIcon size="3x" icon={faImageSlash} color="#ccc" style={{margin: '1em auto'}} />
            </Box>
            }
            <CardContent
                sx={{ background: 'background.highlight', width: '100%', flexDirection: 'row-reverse' }}
            >
                <Typography color={'text.primary'} >{item.Name}</Typography>
            </CardContent>
        </Card>
    );
};
export default InterfaceImage;