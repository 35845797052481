import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import config from "../config";
import { UserContext } from "../store/UserContext";
import { CompanyContext } from "../store/CompanyContext";

const usePageTracking = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {

    //console.log(user)
    //console.log(selectedCompany)

    //if (config.ANALYTICS && process.env.NODE_ENV !== "development") {
      // Multiple products (previously known as trackers)
      ReactGA.initialize([{
        "trackingId": config.ANALYTICS,
        "testMode": process.env.NODE_ENV === "development",
        "gaOptions" :{
          "titleCase": false
        }
        //gaOptions: { ...}, // optional
        //gtagOptions: { ...}, // optional
      },
      {
        //trackingId: "your second GA measurement id",
      },
      ]);

      ReactGA.set({
        "user_id": user?.user?.user_id,
        "company_id": selectedCompany?.company_id,
      });

      // Send pageview with a custom path
      ReactGA.send({ 
        "hitType": "pageview", 
        "page": location.pathname + location.search 
      });
      
      //console.log('ga')
      // Send a custom event
      // ReactGA.event({
      //   category: "your category",
      //   action: "your action",
      //   label: "your label", // optional
      //   value: 99, // optional, must be a number
      //   nonInteraction: true, // optional, true/false
      //   transport: "xhr", // optional, beacon/xhr/image
      // });
   // }
  }, [location, config, user, selectedCompany]);
};

export default usePageTracking;

