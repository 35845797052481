import React, {
    useMemo,
    useRef,
    useCallback
} from 'react';
import {
    Page,
    Document,
    StyleSheet,
    usePDF,
    View,
    Text,
    Image
} from '@react-pdf/renderer';
import { useTheme } from '@mui/styles';
import GlobalDialog from '../../../reusable/GlobalDialog';
import LoadingScreen from '../../reusable/LoadingScreen';
import config from '../../../config';
import { makeStyles } from '@mui/styles';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ReactPDFFallback from '../../pdf/ReactPDFFallback';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        '& .MuiDialogContent-root': {
            overflow: 'hidden'
        },
        '& .MuiDialog-paper': {
            width: '100%',
            height: '100%'
        }
    }
}));

const create_pdf_styles = theme => {
    return StyleSheet.create({
        BODY: {
            paddingTop: '3mm',
            paddingBottom: '2mm',
            paddingLeft: '5mm',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        SIZE_1: {
            width: '21.17mm',
            height: '38.2mm',
            display: 'grid',
            alignItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
            //border: '1px solid #ccc',
            //borderRadius : '20%',
            padding: '6px 0 0  ',
            marginBottom: '5px'
        },
        TEXT: {
            fontSize: 8,
            textAlign: 'center',
            width: '100%'
        },
        QR: {
            maxWidth: '20mm',
            height: '20mm'
        },
        PAGE_NUMBER: {
            position: 'absolute',
            fontSize: theme.spacing(1.5),
            bottom: theme.spacing(1.5),
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        }
    });
};

const DipslideQRCodesPDF = ({
    dialogOpen,
    dialogClose,
    pdf_codes,
    selectedCompany,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const pdf_styles = useMemo(() => create_pdf_styles(theme), [theme]);

    const ref = useRef()

    const create_pdf = useCallback((pdf_codes) => {
        return (
            <Document ref={ref} title="QR codes">
                <Page style={pdf_styles.BODY} orientation='landscape'>
                    <View style={pdf_styles.BODY}>
                        {pdf_codes && pdf_codes.map(item => (
                            <View key={item.code} style={pdf_styles.SIZE_1}>
                                <Text style={pdf_styles.TEXT}>{selectedCompany?.company_name ? selectedCompany?.company_name : config.NAME}</Text>
                                <View style={pdf_styles.QR}>
                                    <Image src={item.url} />
                                </View>
                                <Text style={pdf_styles.TEXT}>{item.code.substring(0, 4)}</Text>
                                <Text style={pdf_styles.TEXT}>{item.code.substring(4, 8)}</Text>
                                <Text style={pdf_styles.TEXT}>{item.code.substring(8, 12)}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        )

    }, [selectedCompany, pdf_styles]);

    const [instance, updateInstance] = usePDF({ document: create_pdf(pdf_codes) });

    // console.log(instance)
    // console.log(updateInstance)
    // useEffect(() => {
    //     if (pdf_codes) {
    //        //console.log(pdf_codes)
    //         updateInstance({ document: create_pdf(pdf_codes) })
    //     }
    //     return () =>{
    //         ///updateInstance();
    //     }
    // }, [pdf_codes, selectedCompany.company_name, pdf_styles, create_pdf])

    const handleClose = () => {
        dialogClose();
    };

    return (
        <GlobalDialog
            open={dialogOpen}
            onClose={handleClose}
            title="QR codes"
            maxWidth="xl"
            fullWidth
            fullHeight
        >

                {instance?.url ?
                <>
                    {isMobileOnly || isTablet ?
                        <ReactPDFFallback url={instance.url} />
                        :
                        <object
                            data={instance.url}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                        </object>
                    }
                </>
                : null}
            {/* { !instance.loading ?
                <object data={instance.url} type="application/pdf" height="100%" width="100%"></object>
                :
                <LoadingScreen
                    loading={false}
                    background="#666"
                    colour="#fff"
                    text="Loading PDF Preview"
                    position="relative"
                />
            } */}

        </GlobalDialog>
    );
};

export default DipslideQRCodesPDF;
