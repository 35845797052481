import React from "react";
import { Card, CardContent, CardHeader, Grid, useTheme, Button, CardActions } from "@mui/material";
import Image from "../shop/reusable/Image";
import setLanguageText from "../language/setLanguageText";

const VendCard = ({ logo, text, buttonText, title, link }) => {

    const theme = useTheme();

    const handleClick = () => {
        if (link) {
            window.open(link)
        }
    }

    return (
        <Grid container alignItems="stretch" item sm={12} md={12} xl={4} >
            <Card
                sx={{
                    backgroundColor: "background.paper",
                    display: 'flex',
                    justiyContent: 'space-between',
                    flexDirection: 'column',
                    width: '100%'
                }}
                elevation={1}>
                <CardHeader
                    sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                    //disableTypography
                    title={title}
                />
                <CardContent style={{ color: 'text.primary', textAlign: 'center' }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={logo}  style={{height: '80px', width: 'auto', margin : '0 auto', border :'1px solid #0c0c0c24', lineHeight : 0 ,  backgroundColor: '#fff'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        {text}
                    </Grid>
                    </Grid>
                </CardContent>

                <CardActions style={{ textAlign: 'center', marginTop: 'auto', padding: '1em', backgroundColor: theme.palette.cards.footer }}>
                    <Button
                        sx={{ margin: '0 auto', width: '60%', padding: '10px' }}
                        variant="contained"
                        color="primary"
                        size={'large'}
                        onClick={handleClick}
                    >
                        {setLanguageText(buttonText)}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default VendCard;
