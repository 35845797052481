import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContent, Box, Avatar, MenuItem, Alert } from '@mui/material';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalStepperDialog from '../reusable/GlobalStepperDialog';
import { Formik } from 'formik';
import { UserContext } from '../store/UserContext';
import userValidation from './helpers/userValidation';
import userDBObject from './helpers/userDBObject';
import AllForms from './forms/AllForms';
import userFormObject from './helpers/userFormObject';
import { TEST_PERMISSIONS } from '../store/UserReducers';
import { useMutation } from 'react-query';
import { SAVE_COMPANIES } from '../store/CompanyReducers';
import { CompanyContext } from '../store/CompanyContext';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import setLanguageText from '../language/setLanguageText';

const PermissionTesting = ({ handleClose }) => {

	const { user, dispatchUser } = useContext(UserContext)
	const { dispatchCompanies } = useContext(CompanyContext)
	const [currentUser, setCurrentUser] = useState();
	const [open, setOpen] = useState(false);

	//close dialog
	const closeDialog = () => {
		setOpen(false);
		handleClose()
	}

	//gets companies again
	const set_companies = useMutation((userObject) => {

		let params = {
            "companies_id": user.user.user_permissions.access.companies ? JSON.stringify(user.user.user_permissions.access.companies) : null
        }
        if (user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator) {
            params = {
                ...params,
                "all_companies": true
            }
        }
		//console.log(user)
		return axiosRequest({
			method: 'get',
			endpoint: 'companies',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			params: params
		})
	}, {
		onSuccess: (data) => {
			dispatchCompanies({
				type: SAVE_COMPANIES,
				payload: data
			}); //only set all option on the dropdown
		}
	})

	const handleSubmit = (values) => {
		//console.log(values)
		let userObject = userDBObject(values, values?.aws_sub)[0]
		//console.log(userObject)
		dispatchUser({
			type: TEST_PERMISSIONS,
			payload: userObject
		});
		set_companies.mutate(userObject);
		handleClose();
	}

	useEffect(() => {
		if (user) {
			//console.log(user)
			const editUser = userFormObject(user.user)
			//console.log(editUser)
			if (editUser[0]) {
				setCurrentUser(editUser[0])
			}
		}
	}, [user])

	return (
		<>
			<MenuItem
				onClick={e => {
					setOpen(true)
				}}
			>
				<Avatar sx={{ bgcolor: 'primary.main' }}>
					<FontAwesomeIcon size="xs" icon={faCog} />
				</Avatar>
				{setLanguageText("Test Permissons")}
			</MenuItem>
			{currentUser ?
				<Formik
					initialValues={currentUser}
					validationSchema={userValidation}
					onSubmit={handleSubmit}
				>
					{({ submitForm, validateForm, errors, values }) => {
						//console.log(values)
						return (
							<GlobalStepperDialog
								open={open}
								onClose={closeDialog}
								fullWidth
								maxWidth="md"
								title={setLanguageText("Edit User")}
								customActions={
									<Box sx={{ display: 'flex', flexDirection: 'row' }}>
										<Button
											variant='text'
											color='inherit'
											onClick={closeDialog}
											sx={{ marginRight: '1em' }}
										>
											{setLanguageText("Cancel")}
										</Button>
										<Box sx={{ flex: '1 1 auto' }} />
										<Button
											variant='contained'
											color='primary'
											onClick={(e) =>
												//validate fields
												validateForm().then((error) => submitForm())}>
											{setLanguageText("Test Permission")}
										</Button>
									</Box>
								}
							>
								<DialogContent>
									<Alert severity='error'>
										{setLanguageText("Changing your users permission below will only temporarily change your permission for testing purposes, and will only be active until you refresh the application.")}
									</Alert>
									<AllForms fullWidth testing />
								</DialogContent>
							</GlobalStepperDialog>
						)
					}}
				</Formik>
				: null}
		</>
	);
};

export default PermissionTesting;
