import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";
import { UserContext } from "../store/UserContext";
import { ShopContext } from "../store/ShopContext";
import { LayoutContext } from "../store/LayoutContext";
import { CompanyContext } from "../store/CompanyContext";
import createProductObject from "../helpers/createProductObject";
import { ProductsContext } from "../store/ProductsContext";
import { CLEAR_PRODUCTS, UPDATE_PRODUCTS } from "../store/ProductsReducers";

const useProductObjectEzbase = () => {

    //console.log('get ezbase products')

    const { user } = useContext(UserContext);
    const { brands, shop, filters } = useContext(ShopContext);
    const { dispatchProducts, products } = useContext(ProductsContext);
    const { app, layout } = useContext(LayoutContext);
    const { selectedCompany } = useContext(CompanyContext);
    const [paramaters, setParamaters] = useState();

    const { data, isFetching } = useQuery(['get products', paramaters, selectedCompany?.company_id], ({ signal }) => {

        const classes = paramaters.category.cPath.split('|')
        let params = {
            c1: encodeURI(classes[0]),
            c2: encodeURI(classes[1]),
            c3: encodeURI(classes[2]),
            c4: encodeURI(classes[3]),
            offset: (paramaters.limit * (paramaters.page - 1)),
            limit: paramaters.limit,
            company_id: selectedCompany.company_id
        }
        if (paramaters.filters) {
            const removeHidden = paramaters.filters.filter((items) => items.s !== false)
            params = {
                ...params,
                filters: encodeURI(JSON.stringify(removeHidden)),
            }
        }
        if (paramaters.brands) {
            const brands = paramaters.brands.map(brand => brand.Brand)
            if (brands && Object.values(brands).length) {
                params = {
                    ...params,
                    brands: encodeURI(JSON.stringify(brands))
                }
            }
        }
        return axiosRequest({
            signal: signal,
            method: 'get',
            endpoint: 'grouped',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: params
        })
    }, {
        //notifyOnChangeProps: currentCat,
        retry: 1,
        enabled: !!selectedCompany?.company_id && !!paramaters
    })


    useEffect(() => {

        if (selectedCompany?.company_id && layout.page && products.limit) {
            if (shop.currentProductCat && Object.values(shop.currentProductCat).length && shop.productSubCat && Object.values(shop.productSubCat).length) {
                //console.log('fetching sub products')
                //product sub level
                setParamaters({
                    "category": shop.productSubCat,
                    "limit": products.limit,
                    "page": layout.page,
                    "filters": filters.productFilters,
                    "brands": brands.selectedBrands
                })
            } else if (shop.currentProductCat && Object.values(shop.currentProductCat).length) {
                //console.log('fetching top products')
                //product top level
                setParamaters({
                    "category": shop.currentProductCat,
                    "limit": products.limit,
                    "page": layout.page,
                    "filters": filters.productFilters,
                    "brands": brands.selectedBrands
                })
            }
        }
    }, [
        selectedCompany.company_id,
        shop.currentProductCat,
        shop.productSubCat,
        brands.selectedBrands,
        layout.page,
        products.limit,
        filters.productFilters
    ])


    useEffect(() => {

        const createProduct = async (products) => {
            const clone = await structuredClone(products);
            const promises = clone.map(async (product) => {

                let productObject = await createProductObject([
                    { ...product, area: app.selectedArea },
                ]);
                return productObject[0];
            });

            let all = await Promise.all(promises);
            //check if array is empty
            if (data?.[0]?.FullProductCount) {
                dispatchProducts({
                    type: UPDATE_PRODUCTS,
                    payload: {
                        count: data[0].FullProductCount,
                        groupCount: data[0].FullGroupCount,
                        allProducts: all,
                        noProducts: data[0].FullProductCount,
                    },
                });
            } else {
                dispatchProducts({
                    type: UPDATE_PRODUCTS,
                    payload: {
                        count: 0,
                        groupCount: null,
                        allProducts: [],
                        noProducts: 0,
                    },
                });
            }
        };
        //console.log(data)
        if (data && !data.errorMessage) {
            let count = 0
            if (data?.[0]?.FullGroupCount) {
                count = data[0].FullGroupCount
            }
            createProduct(data, count);
        }
    }, [data])


    // useEffect(() => {
    //     const createProduct = async (products, count) => {
    //         const clone = await structuredClone(products);
    //         const promises = clone.map(async (product) => {
    //             let productObject = await createProductObject([
    //                 { ...product, area: app.selectedArea },
    //             ]);
    //             return productObject[0];
    //         });

    //         let all = await Promise.all(promises);

    //         dispatchProducts({
    //             type: UPDATE_PRODUCTS,
    //             payload: {
    //                 count: count,
    //                 groupCount: count,
    //                 allProducts: all,
    //                 noProducts: count,
    //             },
    //         });

    //     };
    //     //console.log(data)
    //     if (data && !paramaters.errorMessage) {
    //         createProduct(data, data[0].FullProductCount);
    //     }
    // }, [data]);

    return { isFetching };
};

export default useProductObjectEzbase;

