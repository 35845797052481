export const ADD_PRODUCT = 'ADD_PRODUCT';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const SAVE_PRODUCTS = 'SAVE_PRODUCTS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const SAVE_SELECTED_PRODUCT = 'SAVE_SELECTED_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SHOW_VISIBLE_PRODUCTS = 'SHOW_VI//SIBLE_PRODUCTS';
export const UPDATE_PRODUCT_OPTIONS = 'UPDATE_PRODUCT_OPTIONS';
export const SAVE_PRODUCT_TYPES = 'SAVE_PRODUCTS';

export const ProductsReducer = (state, action) => {
  switch (action.type) {
    case SAVE_PRODUCT:
      return [...state, action.payload];
    case SAVE_PRODUCTS:
      return action.payload;
    case RESET_PRODUCTS:
      return [];
    case SAVE_SELECTED_PRODUCT:
      return action.payload;
    case ADD_PRODUCT :  
      return [...state, action.payload];
    case UPDATE_PRODUCT:
      //ipdate single product in context
      const index = state.findIndex(
        coolant => coolant.coolant_id === action.payload.coolant_id
      );
      const copy_coolants = [...state];
      //remove if you change coolant type
      if (copy_coolants[index].coolant_type !== action.payload.coolant_type) {
        copy_coolants.splice(index, 1);
      } else {
        copy_coolants[index] = action.payload;
      }
      //console.log(copy_coolants)
      return copy_coolants;
    // case SHOW_VISIBLE_PRODUCTS:
    //   //console.log(action.payload.data)
    //   const newArr2 = action.payload.data.map(v => Object.assign(v, { display: false }))
    //   action.payload.visible.forEach((visible) => {
    //     const index = action.payload.data.findIndex(
    //       coolant => coolant.coolant_id === visible.coolant_id
    //     );
    //     //console.log(index)
    //     if (index > -1) {
    //       newArr2[index].display = true;
    //     }
    //   })
    //   //console.log(newArr2)
    //   return newArr2;
    case UPDATE_PRODUCT_OPTIONS:
      return {
        ...state,
        visible: action.payload.visible ? action.payload.visible : state.visible,
        toggleSort: action.payload.toggleSort ? action.payload.toggleSort : state.toggleSort,
        toggleView: action.payload.toggleView ? action.payload.toggleView : state.toggleView,
        type: action.payload.type ? action.payload.type : state.type
      };
    case SAVE_PRODUCT_TYPES:
      return action.payload;
    default:
      return state;
  }
};
