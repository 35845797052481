import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../store/UserContext';
import * as Yup from "yup"
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import useActivityLog from '../users/activity/useActivityLog';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import { Formik } from 'formik';
import DialogLoader from '../reusable/DialogLoader';
import { TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Button, Alert, Typography, Grid, TextField } from '@mui/material';
import CSVReader from 'react-csv-reader';
import InputWrap from '../reusable/InputWrap';

const KPIUpload = ({ kpi, refetchKPIs, upload, setUpload }) => {

  const formRef = useRef();
  const { user } = useContext(UserContext);
  const [initialValues, setInitialValues] = useState();
  const [tabelData, setTableData] = useState();
  const [tableHeaders, setTableHeaders] = useState();

  const {
    updateActivityLog,
    //loggingError,
    //loggingResponse
  } = useActivityLog();

  useEffect(() => {
    if (kpi) {
      let object = {
        "type": kpi.kpi_type,
        "kpi_id": kpi.kpi_id,
        "single_int": null,
        "data": null
      }
      //console.log(object)
      setInitialValues(object)
    }
  }, [kpi])

  const kpiValidation = Yup.object({
    type: Yup
      .string('Type is needed')
      .required('Type is required')
  });

  const mutation = useMutation((values) => {
    console.log(values)
    return axiosRequest({
      endpoint: `kpi`,
      method: 'post',
      gateway: config.factoryAPI.URL,
      api_key: user.api_key,
      body: {
        "values": true,
        "type": values.type,
        ...values
      }
    })
  })

  const uploadKPIData = (values) => {
    //console.log(values)
    mutation.mutate(values)
  };


  return (
    <>
      {initialValues ?
        <Formik
          innerRef={current => formRef.current = current} //access methods
          initialValues={initialValues}
          validationSchema={kpiValidation}
          onSubmit={uploadKPIData}
        >
          {({ handleSubmit, errors, values, touched, handleChange , setFieldValue }) => {
            console.log(errors)
            return (
              <GlobalDialog
                open={upload}
                onClose={() => setUpload(false)}
                title={'Upload KPI Data'}
                buttonTitle={'Upload Data'}
                buttonClick={handleSubmit}

              //disabled={true}
              >
                <Alert severity="info" sx={{ mb: 2 }}>
                  {values.type === "csv" ?
                    <Typography variant="body2" >
                      This will allow an import of a CSV file.<br /> The csv must include columns : company_id , value
                    </Typography>
                    : values.type === "single_int" ?
                      <Typography variant="body2" >
                        This will be a single number added to all companies.
                      </Typography>
                      : null}
                </Alert>
                {values.type === 'single_int' ?

                  <Grid item xs={12}>
                    <InputWrap name="Single Number">
                      <TextField
                        id="single_int"
                        name="single_int"
                        value={values.single_int}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={touched.single_int && Boolean(errors.single_int)}
                        helperText={touched.single_int && errors.single_int}
                        sx={{ '& legend': { display: 'none' } }}
                      />
                    </InputWrap>
                  </Grid>

                  : null}
                {values.type === 'csv' ?

                  <>
                    <CSVReader onFileLoaded={(data, fileInfo, originalFile) => {

                      console.log(data, fileInfo, originalFile)
                      let headers = data.shift()
                      console.log(headers)
                      //remove first array of csv and set to table data 
                      let rows = data
                      console.log(rows)
                      setTableHeaders(headers)
                      setTableData(rows)
                      //console.log(data, fileInfo, originalFile)
                      setFieldValue('data', rows) 

                    }
                    } />
                    {tabelData ?
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {tableHeaders.map((value, i) => (
                                <TableCell key={i}>{value}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {tabelData.map((row) => (
                              <TableRow key={row.id}>
                                {Object.values(row).map((value) => (
                                  <TableCell key={value}>{value}</TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      : null}
                  </>

                  : null}

                {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                  <DialogLoader
                    isLoading={mutation.isLoading}
                    mutation={mutation}
                    loadingMessage="Saving KPI..."
                    successMessage="KPI Saved"
                    closeDialog={() => {
                      refetchKPIs()
                      setUpload(false)
                    }}
                    fixed
                  />
                  : null}
              </GlobalDialog>
            )
          }
          }
        </Formik >
        : null
      }
    </>
  );
};

export default KPIUpload;
