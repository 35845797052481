import React, { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import { CardContent, CardHeader, Grid, TextField, Typography, Button, Card, useMediaQuery, Alert, CardActions } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { filterTree, expandFilteredNodes, getIDsExpandFilter } from "./resourceFilterTree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faPencil } from "@fortawesome/pro-light-svg-icons";
import flatArrayToTree from "../helpers/flatArrayToTree";
import { uniq } from "lodash";
import { UserContext } from "../store/UserContext";
import { isMobileOnly, isTablet } from 'react-device-detect';

const TreeItems = ({ category, setSearchParams, selectedCategory }) => {

    const CustomContent = forwardRef(function CustomContent(props, ref) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;

        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
        } = useTreeItem(nodeId);

        const icon = iconProp || expansionIcon || displayIcon;

        const handleExpansionClick = (e) => {
            e.preventDefault();
            handleExpansion(e); //set expanded
        };

        const handleSelectionClick = (e, props) => {
            e.preventDefault();
            handleSelection(e); //set selected
            if (props.id !== 0) {
                //setSelectedCategory(props.id)
                setSearchParams({ 'cid': props.id })
                sessionStorage.setItem('resource_id', props.id);
            }
        };

        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                ref={ref}
            >
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    {icon}
                </div>
                <Typography
                    onClick={e => handleSelectionClick(e, props)}
                    component="div"
                    className={classes.label}
                >
                    {label}
                </Typography>
            </div>
        );
    });

    return (
        <TreeItem
            key={category?.resource_cat_id}
            nodeId={category?.resource_cat_id}
            ContentProps={{
                id: category?.resource_cat_id
            }}
            ContentComponent={CustomContent}

            label={
                <Grid item container alignItems="center"
                    sx={{
                        margin: '5px 0',
                        color: theme => parseInt(selectedCategory) === category?.resource_cat_id ? theme.palette.primary.main : theme.typography.body1.color,
                    }}
                >
                    <Grid item container alignItems="center" sx={{ margin: '0 5px 0 0', width: '30px' }} >
                        <FontAwesomeIcon icon={faFolder} />
                    </Grid>
                    <Grid item ><small>{category.resource_cat_name} {category.count ? `(${category.count})` : null}</small></Grid>
                </Grid>
            }
        >
            {Array.isArray(category.children)
                ? category.children.map((categoryChildren) => {
                    return <Tree category={categoryChildren} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
                })
                : null}
        </TreeItem>
    )
}

const Tree = ({ category, setSearchParams, selectedCategory }) => {

    return (
        <>
            {Array.isArray(category) && Object.values(category).length > 1 ?
                <>
                    {category.map((cat, i) =>
                        <TreeItems  key={cat?.resource_cat_id}  category={cat} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
                    )}
                </>
                :
                <TreeItems  key={category?.resource_cat_id}  category={category} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            }
        </>
    )
}


const ResourcesTree = ({ categories, selectedCategory, setSearchParams, openLevels, setOpenLevels, edit, setEdit }) => {

    const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const { user } = useContext(UserContext)
    const [filteredData, setFilteredData] = useState();
    const [catStructured, setCatStructured] = useState();
    const [allIds, setAllIds] = useState();

    useEffect(() => {
        if (categories) {
            //console.log(categories)
            let tree = flatArrayToTree(categories, "resource_cat_parent_id", "resource_cat_id")
            setCatStructured(tree)
        }
    }, [categories])

    const handleToggle = (event, nodeIds) => {
        setOpenLevels(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        //setSelected(nodeIds);
    };

    const handleExpandClick = useCallback(() => {
        setOpenLevels((oldExpanded) => oldExpanded?.length === 1 ? allIds : ["Root"]);
    }, [allIds]);

    const onFilterMouseUp = (e) => {
        //console.log(e.target.value)
        const value = e.target.value;
        const filter = value.trim();
        let expandedTemp = openLevels;
        if (!filter) {
            setFilteredData(() => catStructured);
            setOpenLevels(allIds);
            return;
        }

        let all = {
            resource_cat_id: -1,
            children: catStructured,
            resource_cat_name: ''
        }

        let filtered = filterTree(all, filter);
        filtered = expandFilteredNodes(filtered, filter);
        //console.log(expandedTemp)
        //console.log(filtered)

        if (filtered.children && filtered.children.length > 1) {
            expandedTemp = [];
            setFilteredData(filtered.children);

        } else {
            expandedTemp = [];
            setFilteredData(filtered.children[0]);
            //expandedTemp.push(...getIDsExpandFilter(filtered.children));

        }
        filtered.children.forEach((cat) => {
            expandedTemp.push(...getIDsExpandFilter(cat));
        })
        let level = uniq(expandedTemp)
        setOpenLevels(level);
    };

    useEffect(() => {
        if (catStructured && Object.values(catStructured).length) {
            setFilteredData(catStructured)
            let allIDs = [];
            const getAllIDs = (data) => {
                data.forEach(cat => {
                    allIDs.push(cat.resource_cat_id)
                    if (cat.children) {
                        getAllIDs(cat.children)
                    }
                })
            }
            getAllIDs(catStructured)
            setAllIds(allIDs)
        }
    }, [catStructured])

    return (
        <Card>
            <CardHeader
                disableTypography
                sx={{
                    background: (theme) => theme.palette.cards.header,
                    //color: theme => theme.palette.primary.contrastText,
                    fontSize: '1em',
                    padding: 1
                }}
                title={
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <TextField
                                fullWidth
                                onKeyUp={onFilterMouseUp}
                                placeholder="Filter Resources..."
                                sx={{
                                    '& input': {
                                        padding: "10px 15px"
                                    },
                                    '& legend': {
                                        display: 'none'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleExpandClick}
                            >
                                {openLevels?.length === 1 ? 'Expand all' : 'Collapse all'}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <CardContent
                sx={{
                    borderColor: (theme) => theme.palette.cards.main,
                    borderWidth: '1px 0 1px 0',
                    borderStyle: 'solid',
                    padding: 2,
                    maxHeight: !isMd ? "calc(100vh - 120px)" : "calc(100vh - 450px)",
                    overflow: "auto",
                    height: isMobileOnly ? '300px' : isTablet ?  "400px" : '100%'				
                }}
            >
                {filteredData && selectedCategory && Object.values(filteredData).length  && openLevels ? //check for ID so seleted works
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={openLevels ? openLevels : ['0']}
                        selected={selectedCategory ? parseInt(selectedCategory) : null}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        sx={{ padding: isMobileOnly || isTablet ? '0' : '1em' }}
                    >
                        <Tree category={filteredData} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
                    </TreeView>
                    :
                    <Alert severity="warning" >No results</Alert>
                }
            </CardContent>
            {user?.user?.user_permissions?.administrator &&
                <CardActions
                    sx={{
                        background: (theme) => theme.palette.cards.header,
                        //color: theme => theme.palette.primary.contrastText,
                        fontSize: '1em',
                        padding: 1
                    }}
                >
                    <Button
                        //fullWidth
                        disabled={edit}
                        color='primary'
                        variant='contained'
                        onClick={() => setEdit(true)}
                        startIcon={<FontAwesomeIcon icon={faPencil} />}
                    >
                        Edit Categories
                    </Button>
                </CardActions>
            }

        </Card>
    )
};

export default ResourcesTree;
