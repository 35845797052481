import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Tab, Box, Grid, Skeleton } from '@mui/material';
import BrandLogo from "../shop/reusable/BrandLogo";
import { useQuery } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from "../store/UserContext";
import ProductExports from "../products/shared/tabs/ProductExports";
import InnerContentWrap from "../layout/InnerContentWrap";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ProductInterfaceCodes from "../products/shared/tabs/ProductInterfaceCodes";
import Image from "../shop/reusable/Image";
import { CategoryContext } from "../store/CategoryContext";
import config from "../config";
import AddCadToBasket from "./AddCadToBasket";
import ProductSlider from "../reusable/ProductSlider";

const ProductFullPage = ({id}) => {

    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext)
    const [diagram, setDiagram] = useState();
    const [product, setProduct] = useState();
    const [tab, setTab] = useState();
    const [price, setPrice] = useState();

    const fetchProduct = (id) => axiosRequest({
        gateway: config.shopAPI.URL,
        endpoint: 'products/full',
        api_key: user.api_key,
        method: 'get',
        params: {
            id: id,
        }
    }).then((res) => res)

    const {
        data,
        isFetching,
    } = useQuery(['products', id], () =>
        fetchProduct(id)
        , {
            enabled: !!id,
            notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
            retry: 1
        })

    // const { data: price } = useQuery(['price'],
    //     ({ signal }) => axiosRequest({
    //         gateway: config.shopAPI.URL,
    //         endpoint: 'products/price',
    //         api_key: user.api_key,
    //         method: 'post',
    //         body: {
    //             itemRefs: [data].map(item => item.OrderNumber)
    //         }
    //     }), {
    //     enabled: !!data,
    //     retry: 1
    // })

    useEffect(() => {
        //console.log(data)
        //console.log(price)
        if (price && data) {
            setProduct({ ...data, ...price[0] })
        }
    }, [price, data])

    useEffect(() => {
        //console.log(data)
        //console.log(price)
        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            let tab = urlParams.get("tab")
            if (tab) {
                setTab(tab)
            } else {
                setTab('1')
            }
        } else {
            setTab('1')
        }
    }, [])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    //console.log(product)
    useEffect(() => {

        
        if (product?.Category) {
            // console.log(product)
            //console.log(categories.flatCats)
            let cat = categories.flatCats.find(category => category.ID === product.Category)
            //console.log(cat)
            if (cat?.Image) {
                setDiagram(cat.Image)
            }
        }
        return () => {

        }

    }, [product, categories])


    return (
        <>
           
            <InnerContentWrap>

                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12} md={7} container alignContent="space-between">
                            <Card sx={{
                                display: 'flex', justiyContent: 'space-between', flexDirection: 'column', padding: '1em', width: '100%', background: '#fff'
                            }} >
                                {!isFetching || product ?
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>

                                        {product?.Graphics && Object.values(product.Graphics).length ?
                                            <ProductSlider images={[{ 'URL': diagram }, ...product.Graphics]} url="URL" descrition="descrition" />
                                            
                                            :
                                            <Image height="100%" isFetching={false} src={diagram} alt={product?.Name} ShowIcon={false} zoom 
                                            
                                            sx={{   
                                                maxHeight: '300px',
                                                height: '100%'
                                            }}
                                            
                                            />
                                        }
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width={'100%'} height={140} />
                                    </Grid>
                                }
                            </Card>
                        </Grid>
                        <Grid container item xs={12} md={5} spacing={2} alignContent="start">
                            <Grid item xs={12}>
                                {product ?
                                    <Box sx={{
                                        width: '300px',
                                        height: '100px',
                                        overflow: 'hidden',
                                        top: 0,
                                        right: 0,
                                    }}>
                                        {!isFetching && <BrandLogo companyID={product.CompanyCode} />}
                                    </Box>
                                    :
                                    <Skeleton width="300px" height='100px' />
                                }

                            </Grid>
                            <Grid item xs={12}>
                                {product ?
                                    <Typography color="text.primary" variant="h5" sx={{ maringBottom: '1em' }} >{product.Name}</Typography>
                                    :
                                    <Skeleton width="100%" height={80} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {product ?

                                    <AddCadToBasket isFetching={isFetching} product={product} large={true} />

                                    :
                                    <Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', marginTop: '1em' }}>
                        {tab ?

                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab sx={{ fontSize: '1.2em' }} label="Product Features" value="1" />
                                        <Tab sx={{ fontSize: '1.2em' }} label="Interface Codes" value="2" />
                                        <Tab sx={{ fontSize: '1.2em' }} label="Available Downloads" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel sx={{
                                    background: (theme) => theme.palette.background.paper,
                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                                    value="1"
                                >
                                    {/* {product && <ProductFullTable rows={product.Properties} product={product} diagram={diagram} />} */}
                                </TabPanel>
                                <TabPanel sx={{
                                    background: (theme) => theme.palette.background.paper,
                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                                    value="2"
                                >
                                    {product && <ProductInterfaceCodes rows={product.InterfaceCodes} product={product} />}
                                </TabPanel>
                                <TabPanel sx={{
                                    background: (theme) => theme.palette.background.paper,
                                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                                    value="3"
                                >
                                    {product && <ProductExports id={product.ID} />}
                                </TabPanel>
                            </TabContext>
                            :
                            null
                        }
                    </Box>

            </InnerContentWrap>
        </>
    );
};
export default ProductFullPage;