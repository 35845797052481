import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardActions, CardContent, CardHeader, Typography, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InnerContentWrap from '../../layout/InnerContentWrap';
import { LayoutContext } from '../../store/LayoutContext';

const ComingSoon = () => {

    const { app } = useContext(LayoutContext)
    const location = useLocation();
    const [menu, setMenu] = useState();

    useEffect(() => {
        if (app?.menu) {
            let item
            item = app.menu.find(item => item.link === location.pathname)
            setMenu(item)
        }
    }, [location, app])

    return (
        <InnerContentWrap>
            <Card
                sx={{
                    backgroundColor: "background.paper",
                    display: 'flex',
                    justiyContent: 'space-between',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '500px',
                    margin: '0 auto'
                }}
                elevation={1}>
                <CardHeader
                    sx={{ textAlign: 'center', borderBottom: '1px solid', borderColor: 'cards.footer', backgroundColor: 'cards.header' }}
                    disableTypography
                    title={menu?.titleComponent}
                />
                <CardContent sx={{ color: 'text.primary', textAlign: 'center' }}>
                    <Typography variant='h5' sx={{ color: (theme) => theme.palette.primary.main, marginBottom: '1em' }} >Coming Soon</Typography>
                    <Typography variant='body1'>{menu?.text}</Typography>
                </CardContent>
                <CardActions sx={{ textAlign: 'center', marginTop: 'auto', padding: '1em', backgroundColor: (theme) => theme.palette.cards.header }}>
                    <Button
                        sx={{ margin: '0 auto' }}
                        disabled={true}
                        variant="contained"
                        color="primary"
                        size={'large'}
                        startIcon={
                            <FontAwesomeIcon
                                icon={menu?.icon}
                                color={'primary.contrastText'}
                                style={{ marginRight: '10px' }}
                            />}

                    >
                        Coming soon
                    </Button>
                </CardActions>
            </Card>
        </InnerContentWrap>


    );
}

export default ComingSoon