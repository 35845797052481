import { Skeleton, Grid, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import config from "../../config";
import { ShopContext } from "../../store/ShopContext";
import { UserContext } from "../../store/UserContext";
import axiosRequest from '../../axios/axoisRequest';
import ResultFiltersCard from "./ResultFiltersCard";
import _ from "lodash";
import BrandsDropdown from "./BrandsDropdown";
import BuyerSubCategoryDropdown from "../categories/BuyerSubCategoryDropdown";
import { CompanyContext } from "../../store/CompanyContext";
import MobileDrawWrapper from "../../reusable/MobileDrawWrapper";
import Float from "./Float";
import { UPDATE_ALL_FILTERS } from "../../store/ShopReducers";
import { G } from "@react-pdf/renderer";

const Filters = () => {

    const { user } = useContext(UserContext);
    const { shop, dispatchFilters } = useContext(ShopContext);
    const { selectedCompany } = useContext(CompanyContext);
    const [filters, setFilters] = useState();
    const [noFilters, setNoFilters] = useState();
    const [filterParams, setSetFilterParams] = useState();

    //--------------------------- API CALLS  ------------------------------//

    const getFilters = useMutation((values) => {
        const classes = values.category.cPath.split('|')
        //console.log(user)
        return axiosRequest({
            method: 'get',
            endpoint: 'filters',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: {
                c1: encodeURI(classes[0]),
                c2: encodeURI(classes[1]),
                c3: encodeURI(classes[2]),
                c4: encodeURI(classes[3]),
                company_id: values.company_id
            }
        })
    }, {
        onSuccess: (data) => {
            if (data && Object.values(data).length) {
                setNoFilters(false)
                let filters = []
                data.forEach((field) => {
                    //console.log(field.DistinctFilterValues)
                    if (field?.DistinctFilterValues) {
                        //parse both strings
                        field.DistinctFilterValues = JSON.parse(field.DistinctFilterValues)
                        //field.FilterValues = JSON.parse(field.FilterValues)
                        //console.log(field.DistinctFilterValues)
                        if (Object.values(field.DistinctFilterValues).length > 1) {
                            filters.push(field)
                        }
                    }
                })

                let merged = _.uniqWith(filters, (pre, cur) => {
                    //console.log(cur)
                    //console.log(pre)
                    if (pre.FilterID === cur.FilterID) {
                        //cur.DistinctFilterValues = new Set([...cur.DistinctFilterValues ,... pre.DistinctFilterValues])
                        cur.DistinctFilterValues = _.union(cur.DistinctFilterValues, pre.DistinctFilterValues)

                        if (typeof cur.FilterMax === 'object' && cur.FilterMax !== null) {
                            cur.FilterMax = _.union(cur.FilterMax, [pre.FilterMax])
                        } else {
                            cur.FilterMax = _.union([cur.FilterMax], [pre.FilterMax])
                        }
                        if (typeof cur.FilterMin === 'object' && cur.FilterMin !== null) {
                            cur.FilterMin = _.union(cur.FilterMin, [pre.FilterMin])
                        } else {
                            cur.FilterMin = _.union([cur.FilterMin], [pre.FilterMin])
                        }
                        return true
                    }
                    return false;
                });
                setFilters(merged)
                dispatchFilters({
                    type: UPDATE_ALL_FILTERS,
                    payload: merged
                });

            } else {
                setFilters([])
                dispatchFilters({
                    type: UPDATE_ALL_FILTERS,
                    payload: []
                });
            }
        }
    })

    useEffect(() => {

        if (selectedCompany?.company_id && shop?.productSubCat && shop?.currentProductCat) {
            if ((Object.values(shop.currentProductCat).length && Object.values(shop?.productSubCat).length === 0)) {
                setSetFilterParams({
                    "category": shop.currentProductCat,
                    "company_id": selectedCompany.company_id,
                })
                //getFilters.mutate([shop.currentProductCat, selectedCompany.company_id])
            } else if ((Object.values(shop?.productSubCat).length)) {
                //getFilters.mutate([shop.productSubCat, selectedCompany.company_id])
                setSetFilterParams({
                    "category": shop.productSubCat,
                    "company_id": selectedCompany.company_id,
                })
            }
        }
    }, [
        selectedCompany.company_id,
        shop.currentProductCat,
        shop.productSubCat
    ])

    useEffect(() => {
        if (filterParams && Object.values(filterParams).length) {
            getFilters.mutate(filterParams)
        }
    }, [filterParams])


    return (
        <MobileDrawWrapper
            isFetching={getFilters.isLoading}
        >
            <Box sx={{ padding: '1em' }} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BrandsDropdown />
                    </Grid>
                    <Grid item xs={12}>
                        <BuyerSubCategoryDropdown />
                    </Grid>
                    {!getFilters.isLoading && filters && !noFilters ?
                        (filters).map((field, i) =>
                            //console.log(field)
                            <Grid container spacing={1} item key={i} >
                                {field.FilterType === 'ScalarNumber' ?
                                    <Float field={field} />
                                    :
                                    <ResultFiltersCard field={field} index={i} />
                                }
                            </Grid>
                        )
                        : null}
                </Grid>
            </Box>
        </MobileDrawWrapper>
    );
};

export default Filters;
