import { faDolly, faCartShopping, faPoundSign, faCircleSterling, faEnvelope, faTag, faTags, faShip, fal, faPlane, faTruck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, Box, Chip, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Skeleton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { CompanyContext } from "../../store/CompanyContext";
import RequestPrice from "../shared/RequestPrice";
import axiosRequest from '../../axios/axoisRequest';
import { BrandsContext } from "../../store/BrandsContext";

const ProductPrices = ({ product, large, setErpDetails, value, setValue, table, isFetching }) => {


    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext);
    const [prices, setPrices] = useState()
    const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');
    const [erpCode, setErpCode] = useState()
    const { brands } = useContext(BrandsContext);

    const { data: opensearch_data, isFetching: opensearch_isFetching } = useQuery({
        queryKey: ['product', product?.product_number],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.opensearchAPI.URL,
            endpoint: 'tools-search',
            api_key: user.api_key,
            method: 'post',
            body: {
                "query": {
                    "query": {
                        "bool": {
                            "should": [
                                {
                                    "match_bool_prefix": {
                                        "product_code.cleaned": {
                                            "query": product?.product_number,
                                            "fuzziness": "auto",
                                            "boost": 2.0
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "cim_code": {
                                            "query": product?.product_company,
                                            "fuzziness": 0
                                        }
                                    }
                                }
                            ],
                            "minimum_should_match": 2
                        }
                    }
                }
            }
        }),
        enabled: !!product?.product_number && product?.product_company && !!user.api_key,
        retry: 0
    })


    useEffect(() => {
        if (opensearch_data?.hits?.hits?.length > 0) {
            let brand
            //console.log("data", product)
            //cim product
            if (opensearch_data?.hits?.hits[0]?._source.supplier && brands?.openSearchBrands.length > 0) {
                brand = brands.openSearchBrands.find(brand => brand?.erp_code === opensearch_data?.hits?.hits[0]?._source.supplier)
                //ezbase product
            }
            //console.log(opensearch_data)
            setErpCode({
                "product_code": opensearch_data?.hits?.hits[0]?._source?.product_code,
                "product_id": opensearch_data?.hits?.hits[0]?._source?.product_id,
                "product_supplier": opensearch_data?.hits?.hits[0]?._source?.supplier,
                "product_supplier_number": opensearch_data?.hits?.hits[0]?._source?.supplier_number,
                "product_external_delivery": brand?.external_delivery ? brand?.external_delivery : "",
                "product_local_delivery": brand?.local_delivery ? brand?.local_delivery : "",
            })
        }
    }, [opensearch_data])


    const { data, isFetching: price_isFetching } = useQuery({
        queryKey: ['prices', erpCode?.product_id, selectedCompany?.company_erp?.AccountNumber],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.opensearchAPI.URL,
            endpoint: 'tools-search/price',
            api_key: user.api_key,
            method: 'get',
            params: {
                "id": erpCode?.product_id, //cis erp uses this
                "code": erpCode?.product_code, //act erp uses this
                "account": selectedCompany?.company_erp.AccountNumber,
                "supplier": erpCode?.product_supplier,
                "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
            }
        }),
        enabled: !!erpCode?.product_id && !!selectedCompany?.company_erp?.AccountNumber && (!!ACTstoredToken || !!CISstoredToken),
        retry: 0,
        //cacheTime: 0 
    })

    useEffect(() => {

        if (data) {

            //sconsole.log(data)
            //ACT TOKEN ERROR
            if (data?.ErrorMessage === "Invalid bearer token.") {
                //console.log('invalid token')
                sessionStorage.removeItem('ACTtoken');
                window.location.reload();
            }

            //any other errors
            else if (data?.errorMessage || data?.ErrorMessage) {

                //console.log(data?.errorMessage)
                //reset erp details
                if (setValue) {
                    setValue()
                }
                setPrices()
                if (setErpDetails) {
                    setErpDetails()
                }

                //fallback until live api
                if (product.product_rrp) {
                    const priceObject = {
                        "error": data?.errorMessage || data?.ErrorMessage,
                        "price_rrp": parseFloat(product.product_rrp),
                        "price_incl_discount": parseFloat(product.price_incl_discount),
                        "price_discount": 0,
                        "product_unit": product?.product_packet_size ? parseInt(product.product_packet_size) : 1,
                        "product_stock": 0,
                        "product_free_stock": 0,
                        "product_erp_code": product?.product_id
                    }
                    setPrices(priceObject)
                    if (setValue) {
                        setValue(product?.product_packet_size ? parseInt(product.product_packet_size) : 1)
                    }
                    if (setErpDetails) {
                        setErpDetails(priceObject)
                    }
                }

            } else {
                const priceObject = {
                    "price_rrp": data?.PriceRRP,
                    "price_incl_discount": data?.PriceInclDiscount,
                    "price_discount": data?.PriceDiscount,
                    "product_unit": product?.product_packet_size ? parseInt(product.product_packet_size) : data?.Unit,
                    "product_stock": data?.TotalStock,
                    "product_free_stock": data?.FreeStock,
                    "product_erp_code": data?.ArticleNumber,
                    "product_erp": data?.FullProduct
                }
                if (setErpDetails) {
                    setErpDetails(priceObject)

                }
                if (setValue) {
                    setValue(product?.product_packet_size ? parseInt(product.product_packet_size) : data?.Unit ? data?.Unit : 1)
                }
                setPrices(priceObject)
            }
        }
        return () => {
            //cleanup
            setPrices()
        }
    }, [data, product])

    return (
        <>
            <Grid item xs={table ? 10 : 12}>
                {!selectedCompany?.company_erp ?
                    <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ background: '#fef4f4', textAlign: 'center', marginBottom: '10px', fontSize: '14px' }}
                    >
                        No ERP link
                    </Alert>
                    : isFetching || price_isFetching || opensearch_isFetching ?
                        <Skeleton variant="rectangular" height={large ? 100 : 50} />
                        : !prices ?
                            <Grid item xs={12}>
                                <RequestPrice product={product} table={table} error={prices?.error} />
                            </Grid>
                            : prices?.price_incl_discount ?
                                <>
                                    <Alert
                                        icon={table ? false :
                                            <FontAwesomeIcon icon={faTags} style={{ marginTop: '2px' }} size={large ? "lg" : "sm"} />
                                        }
                                        sx={{
                                            background: '#f4fef4',
                                            border: theme => `1px solid ${theme.palette.success.main}`,
                                            padding: '4px 12px',
                                            alignItems: 'center',
                                            borderRadius: 0

                                        }}
                                        severity="success"
                                        variant="outlined"
                                    >
                                        <Box
                                            sx={{
                                                color: (theme) => theme.palette.text.primary
                                            }}>
                                            {prices.price_incl_discount !== prices.price_rrp ?
                                                <Typography variant="p" component="span" sx={{
                                                    textDecoration: 'line-through',
                                                    marginRight: '10px',
                                                    fontSize: '14px',
                                                    color: '#777'
                                                }} >
                                                    £{prices.price_rrp.toFixed(2)}
                                                    <Chip
                                                        sx={{ marginLeft: '5px' }}
                                                        size="small"
                                                        color="success"
                                                        label={`Save ${prices?.price_discount ? `${prices.price_discount}%` : `£${(prices.price_rrp - prices.price_incl_discount).toFixed(2)}`}`}
                                                    />
                                                </Typography>
                                                : null}
                                            <Typography variant="p" component="p"  >
                                                <Typography variant="p" component="span" fontSize={large ? '22px' : table ? '16px' : '18px'} sx={{ marginRight: '5px' }} >
                                                    Price:
                                                </Typography>
                                                <Typography variant="p" component="span" fontSize={large ? '26px' : table ? '16px' : '22px'} fontWeight={700} sx={{ color: theme => theme.palette.success.main, marginRight: '5px' }} >
                                                    £{prices?.price_incl_discount.toFixed(2)}
                                                </Typography>
                                                <Typography variant="p" component="span" fontSize={large ? '16px' : table ? '14px' : '14px'} >
                                                    each
                                                </Typography>
                                            </Typography>
                                        </Box>

                                        {/* <Box
                                        sx={{
                                            color: (theme) => theme.palette.text.primary
                                        }}>
                                        <Typography variant="p" component="p" fontSize={large ? '22px' : table ? '16px' : '18px'} >
                                            {table ? "" : "Price: "}
                                            <Typography variant="p" component="span" fontWeight={700} sx={{ color: theme => theme.palette.success.main }} >
                                                £{prices.price_incl_discount.toFixed(2)}
                                            </Typography>
                                            <Typography variant="p" component="span" sx={{ fontSize: '12px' }} > each</Typography>
                                        </Typography>
                                        {prices.PriceDiscount > 0 ? <Typography variant={"p"} component="p" ><small>Your Discount <Typography variant="p" component="span" fontWeight={700} sx={{ color: theme => theme.palette.success.main }} >£{((prices.price_rrp - prices.PriceInclDiscount)).toFixed(2)}</Typography> each</small></Typography> : null}
                                    </Box> */}
                                    </Alert>
                                </>
                                : null}
            </Grid>

            {table ?
                <Grid container item xs={2} spacing={1}>
                    {prices?.product_stock > 0 ?
                        <Grid item >
                            <Tooltip title={"Warehouse Stock Available"} placement="bottom" arrow>
                                <Avatar
                                    //variant="rounded"
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        float: 'left',
                                        cursor: 'pointer',
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        '& svg': {
                                            width: '18px',
                                            height: '18px'
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faDolly} />
                                </Avatar>
                            </Tooltip>
                        </Grid>
                        : null}
                    <Grid item >
                        <Tooltip title={"External Stock Available"} placement="bottom" arrow>
                            <Avatar
                                //variant="rounded"
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    float: 'left',
                                    cursor: 'pointer',
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    '& svg': {
                                        width: '18px',
                                        height: '18px'
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTruck} />
                            </Avatar>
                        </Tooltip>
                    </Grid>
                </Grid>
                : null}

            {isFetching || price_isFetching ?
                <Skeleton variant="rectangular" height={large ? 100 : 50} />
                : prices && prices?.product_stock !== null && !prices?.error && large ?
                    <Grid item container spacing={1 / 2} xs={12}>
    
                        <Grid item xs={12}>
                            <Alert
                                icon={false}
                                sx={{
                                    background: "#e9f6fb",
                                    alignItems: 'center',
                                    marginTop: table ? '5px' : '0',
                                    borderRadius: 0,
                                    'li': {
                                        padding: '5px 0'
                                    },
                                    '& svg': {
                                        fontSize: '22px',
                                        '& path': {
                                            fill: theme => theme.palette.info.main
                                        }
                                    },
                                    '& .MuiListItemIcon-root': {
                                        minWidth: '40px'
                                    }

                                }}
                                severity="info"
                                variant="outlined"
                            >
                                <List dense={true}
                                    sx={{
                                        color: (theme) => theme.palette.text.primary,
                                        padding: '0'

                                    }}
                                >
                                    {prices?.product_stock > 0 ?
                                        <ListItem >
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faDolly} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <>
                                                    <strong>Warehouse stock:</strong> Available
                                                    {erpCode.product_local_delivery ?
                                                        <>
                                                            <br />
                                                            <strong>Delivery:</strong> {erpCode.product_local_delivery}
                                                        </>
                                                        : null}
                                                </>
                                            </ListItemText>
                                        </ListItem>
                                        : null}
                                    <ListItem>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faTruck} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <>
                                                <strong>Manufacturer stock:</strong> Available
                                                {erpCode.product_external_delivery ?
                                                    <>
                                                        <br />
                                                        <strong>Delivery:</strong> {erpCode.product_external_delivery}
                                                    </>
                                                    : null}
                                            </>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Alert>
                        </Grid>
                    </Grid >
                    : null}
            {/* {prices?.FullProduct && !table ? <MoreInfo product={prices.FullProduct} /> : null} */}
            {prices?.Unit && !table && (value % prices?.Unit) !== 0 ?
                <Typography variant={"p"} component="p" sx={{ marginTop: '5px', fontSize: '12px', textAlign: 'left', color: theme => theme.palette.error.dark }}>Quantity must be a multiple of {prices?.Unit}</Typography>
                : null}

        </>

    );
};

export default ProductPrices;
