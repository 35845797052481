import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, Skeleton, Typography } from '@mui/material';
import HTMLReactParser from 'html-react-parser';

const ProductAttributes = ({ attributes, fullHeight }) => {


    const [list, setList] = useState([1, 2, 3, 4, 5, 6, 7, 8])
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (attributes) {
            // aphla sort
            attributes.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
            if (attributes.length > 0) {
                setList(attributes)
                setShow(true)
            } else {
                setList([])
                setShow(true)
            }
        }
        return () => {
            setList([])
            setShow(false)
        }
    }, [attributes])


    return (

        <Box sx={{ 
            height: fullHeight || list.length >= 0 ? 'auto' : '300px', width: '100%', overflowX: 'auto', 
            maxHeight: fullHeight ? 'auto' : '300px',
        }}>
            {list.length === 0 ?
                <Grid container item xs={12}>
                    <Alert sx={{width : '100%' }} variant="standard" severity="info">No Attributes Available</Alert>
                </Grid>
                :
                <Grid container
                    sx={{
                        borderLeft: '1px solid #e0e0e0',
                        borderRight: '1px solid #e0e0e0',
                        borderTop: '1px solid #e0e0e0',
                    }} >

                    {list.map((item, i) =>
                        <Grid key={i} container item xs={12}
                            sx={{
                                //borderBottom: (((i+1) === list.length && (i+1) % 4 === 1 ) || ((i+2) === list.length && (i+1) % 4 === 2 )) ? 'none' : '1px solid #e0e0e0', 
                                borderBottom: '1px solid #e0e0e0',
                                padding: '8px',
                                //borderRight: i % 2 === 0 ? '1px solid #e0e0e0' : 'none',
                                backgroundColor: i % 2 === 0 ? '#f5f5f5' : 'none',
                                //backgroundColor: ((i + 1) % 4 === 1 || (i + 1) % 4 === 2) ? '#f5f5f5' : 'none'
                            }}
                        >
                            <Grid item xs={6} md={7}>
                                {show ?
                                    <Typography variant='subtitle2' component='p'><strong>{item.name ? HTMLReactParser(item.name) : "" } {item.code ? `(${item.code})` : ''}: </strong></Typography>
                                    :
                                    <Typography variant='subtitle2' component='p'><strong><Skeleton width="90%" height={20} /></strong></Typography>
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {show ?
                                    <Typography variant='subtitle2' component='p'>{item.value} {item.unit ? item.unit : null}</Typography>
                                    :
                                    <Typography variant='subtitle2' component='p'><Skeleton width="30%" height={20} /></Typography>
                                }
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            }
        </Box>
    );
}

export default ProductAttributes