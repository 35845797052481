import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import {
	Button,
	ButtonGroup,
	Card,
	CardContent,
	Collapse,
	Grid,
	Typography,
	IconButton,
} from '@mui/material';
import { makeStyles } from "@mui/styles"
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { buttonStyles } from '../../styles/buttonStyles';
import DipslideScanner from '../../QRScanner/DipslideScanner';
import RadioButtons from '../types/Entry_RadioButtons';
import { SAVE_QRCODES, REMOVE_QRCODES, UPDATE_ATTRIBUTE } from '../../store/SelectionsReducers';
import { dipslideQRCodeCreater } from '../../helpers/dipslideQRCodeCreater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import setLanguageText from '../../../language/setLanguageText';

const useStyles = makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.background.light,
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`
	},
	qr_preview: {
		width: '100px',
		textAlign: 'center',
		'& p ': {
			margin: '0'
		},
		'& img': {
			maxWidth: '100%'
		}
	}
}));

export default function BacteriaFungi({
	attr,
	company,
	machine,
	initialValue,
	setError,
	edit
}){

	const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
	const buttonStyle = buttonStyles();
	const classes = useStyles();
	const [attribute_open, setAttribute_open] = useState(
		initialValue === -2 ? false : true
	);
	const [isPending, setIsPending] = useState(true);
	const [QRCode, setQRCode] = useState();

	const dispatchFnCallback = useCallback(
		attribute => {
			//console.log(attribute)
			return dispatchAddNewEntry({
				type: UPDATE_ATTRIBUTE,
				payload: attribute
			});
		},
		[dispatchAddNewEntry]
	);

	useEffect(() => {
		//console.log(attr.one.attr_id)
		//console.log(initialValue)
		if (initialValue === undefined) {
			//console.log('set default')
			dispatchFnCallback({
				attribute_id: attr.one.attr_id,
				attribute_values: { output: "Pending", status: -10, value: -1 }
			});
		}
	}, [attr, dispatchFnCallback, initialValue]);

	const openAttribute = () => {
		setAttribute_open(true);
		dispatchFnCallback({
			attribute_id: attr.one.attr_id,
			attribute_values: { output: "Pending", status: -10, value: -1 }
		});
	};

	const closeAttribute = () => {
		setAttribute_open(false);
		//When user click on "No", reset the status and value back to "Not Needed"
		dispatchFnCallback({
			attribute_id: attr.one.attr_id,
			attribute_values: { status: -20, value: -2, output: "-" }
		});
	};

	const setQrDetails = (details) => {

		dispatchAddNewEntry({
			type: SAVE_QRCODES,
			payload: {
				type: attr.one.attr_id,
				...details
			}
		})
	}

	useEffect(() => {
		//console.log(addNewEntry.qr_codes)
		if ((addNewEntry.qr_codes).length > 0) {
			console.log(addNewEntry);
			let found = addNewEntry.qr_codes.find(item => item.type === attr.one.attr_id);
			//console.log(found);
			setQRCode(found);
		} else {
			//remove qr code
			setQRCode();
		}
	}, [addNewEntry, attr.one.attr_id])


	const removeQRCode = () => {
		//console.log('remove last from context');
		if (addNewEntry.qr_codes.length > 0) {
			dispatchAddNewEntry({
				type: REMOVE_QRCODES,
				payload: attr.one.attr_id
			});
		}
		setQRCode();
	}

	return (
		<Card>
			<CardContent className={classes.header}>
				<Grid item container alignItems="center" justifyContent="space-evenly">
					<Grid item xs={8} >
						<Typography variant="h6">
							{attr.one.attr_id}: {setLanguageText("Take reading")}?
						</Typography>
					</Grid>
					<Grid item xs={4} >
						<ButtonGroup style={{ 'float': 'right' }}>
							<Button
								size="small"
								onClick={() => openAttribute(attr.one.attr_id)}
								className={attribute_open ? buttonStyle.success : ''}
							>
								Yes
							</Button>
							<Button
								size="small"
								onClick={() => closeAttribute(attr.one.attr_id)}
								className={!attribute_open ? buttonStyle.danger : ''}
							>
								No
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</CardContent>
			<Collapse in={attribute_open}>
				<CardContent>
					<Grid container item xs={12} spacing={1} >
						<Grid item xs={12} >
							{typeof initialValue === 'number' &&
								<RadioButtons
									key={attr.one.attr_id}
									options={attr.three.attr_form_options}
									attr={attr}
									initialValue={initialValue}
									dispatchFn={dispatchFnCallback}
									company={company}
									machine={machine}
									setIsPending={setIsPending}
									setError={setError}
									edit={edit}
								/>
							}
						</Grid>
						{isPending && !edit ?
							<DipslideScanner setQrDetails={setQrDetails} attrName={attr.one.attr_id} />
						: null}

						{isPending && QRCode ? (
							<Grid item xs={12} >
								<Grid container spacing={2} alignItems="center">
									{!edit &&
										<Grid item>
											<IconButton
												size="small"
												className={buttonStyle.danger}
												onClick={removeQRCode}
											>
												<FontAwesomeIcon icon={faTimesCircle} />
											</IconButton>
										</Grid>
									}
									<Grid item>
										<Grid className={classes.qr_preview}>
											<img alt="QR Code" src={
												dipslideQRCodeCreater(`${QRCode.code} ${QRCode.app}`)
											} />
											<p>{QRCode.code.substring(0, 4)}<br />
												{QRCode.code.substring(4, 8)}<br />
												{QRCode.code.substring(8, 12)}</p>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						) : null}
						{/* {QRCode ? JSON.stringify(QRCode) : null } */}
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	);
};
