import { useEffect } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import config from "../config";
import { setConsent } from "../google_analytics/ga";
import '../cookies.css';

export default function useCookies() {


    useEffect(() => {

        /**
         * All config. options available here:
         * https://cookieconsent.orestbida.com/reference/configuration-reference.html
         */
        CookieConsent.run({

            // root: 'body',
            // autoShow: true,
            disablePageInteraction: true,
            // hideFromBots: true,
             mode: 'opt-in',
            // revision: 0,

            cookie: {
                name: 'cc_cookie',
                // domain: location.hostname,
                // path: '/',
                // sameSite: "Lax",
                // expiresAfterDays: 365,
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
            guiOptions: {
                consentModal: {
                    layout: 'cloud inline',
                    position: 'bottom center',
                    equalWeightButtons: true,
                    flipButtons: false
                },
                preferencesModal: {
                    layout: 'box',
                    equalWeightButtons: true,
                    flipButtons: false
                }
            },

            onFirstConsent: ({ cookie }) => {
                console.log('onFirstConsent fired', cookie);

                setConsent({ params: { 
                    'analytics_storage': cookie.categories.includes('analytics') ? 'granted' : 'denied' ,
                    'security_storage': 'granted', //necessary
                }});

            },

            onConsent: ({ cookie }) => {
                console.log('onConsent fired!', cookie)
                setConsent({ params: { 
                    'analytics_storage': cookie.categories.includes('analytics') ? 'granted' : 'denied' ,
                    'security_storage': 'granted', //necessary
                }});

            },

            onChange: ({ changedCategories, changedServices }) => {
                console.log('onChange fired!', changedCategories, changedServices);
                setConsent({ params: { 
                    'analytics_storage': cookie.categories.includes('analytics') ? 'granted' : 'denied' ,
                    'security_storage': 'granted', //necessary
                }});
            },

            onModalReady: ({ modalName }) => {
                console.log('ready:', modalName);
            },

            onModalShow: ({ modalName }) => {
                console.log('visible:', modalName);
            },

            onModalHide: ({ modalName }) => {
                console.log('hidden:', modalName);
            },

            categories: {
                // necessary: {
                //     enabled: true,  // this category is enabled by default
                //     readOnly: true  // this category cannot be disabled
                // },
                analytics: {
                    enabled: true,
                    autoClear: {
                        cookies: [
                            {
                                name: /^_ga/,   // regex: match all cookies starting with '_ga'
                            },
                            {
                                name: '_gid',   // string: exact cookie name
                            }
                        ]
                    },

                    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                    services: {
                        ga: {
                            label: 'Google Analytics',
                            onAccept: () => { },
                            onReject: () => { }
                        }
                    }
                },
                // ads: {}
            },

            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description: `${config.APPTITLE} uses essential cookies to ensure its proper functionality and tracking cookies to better understand your interactions with the site.`,
                            acceptAllBtn: 'Accept all',
                            //acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences',
                            // closeIconLabel: 'Reject all and close modal',
                            //footer: ,
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            //acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            acceptAllBtn: 'Accept all',
                            closeIconLabel: 'Close modal',
                            serviceCounterLabel: 'Service|Services',

                            sections: [
                                {
                                    title: 'Your Privacy Choices',
                                    description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link.`,
                                },
                                // {
                                //     title: 'Strictly Necessary',
                                //     description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                //     //this field will generate a toggle linked to the 'necessary' category
                                //     linkedCategory: 'necessary'
                                // },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies collect information about how you use our website.',
                                    linkedCategory: 'analytics',

                                    cookieTable: {
                                        caption: 'Cookie table',
                                        headers: {
                                            name: 'Cookie',
                                            domain: 'Domain',
                                            desc: 'Description'
                                        },
                                        body: [
                                            {
                                                name: '_ga',
                                                domain: location.hostname,
                                                desc: 'This cookies provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
                                            },
                                            {
                                                name: config.ANALYTICS,
                                                domain: location.hostname,
                                                desc: 'This cookies provide information on user interaction.',
                                            }
                                        ]
                                    }
                                },
                                // {
                                //     title: 'Targeting and Advertising',
                                //     description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                                //     linkedCategory: 'ads',
                                // },
                                // {
                                //     title: 'More information',
                                //     description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                                // }
                            ]
                        }
                    }
                }
            }
        });
    }, []);

}