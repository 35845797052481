import React, { useMemo, useContext, useState, useEffect } from "react";
import { Box, Card, CardContent, Dialog, Grid, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImageSlash, faMagnifyingGlass, faTimes } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../../store/UserContext";
import { ProductsContext } from "../../store/ProductsContext";
import { UPDATE_ORDER } from "../../store/ProductsReducers";
import ProductPrices from "../old/ProductPrices";
import ProductGroupFull from "./ProductGroupFull";
import { ShopContext } from "../../store/ShopContext";


const ProductGroupTable = ({ isFetching }) => {

	const { user } = useContext(UserContext);
	const { dispatchProducts, products } = useContext(ProductsContext);
	const { filters } = useContext(ShopContext)
	const [open, setOpen] = useState(false);
	const [selectedProduct, setSelectedProducts] = useState();
	const [sortColumn, setSortColumn] = useState();
	const [sortOrder, setSortOrder] = useState();
	const [hidden, setHidden] = useState([]);

	const handleClose = () => {
		setOpen(false);
		setSelectedProducts();
	}

	const columns = useMemo(() => {
		let columns = [
			{
				id: "ID",
				name: 'Product ID',
				accessor: "product_id",
				hidden: true
			}, {
				id: "Actions",
				//name: 'Full',
				maxWidth: 50,
				noSort: true,
				menuHidden: true,
				Cell: (row) => {
					return (
						<Box sx={{ cursor: 'pointer' }}>
							<FontAwesomeIcon
								icon={faMagnifyingGlass}
								onClick={e => {
									//console.log(e)
									setOpen(true)
									setSelectedProducts(row)
								}}
							/>
						</Box>
					)
				}
			}, {
				id: "product_company",
				maxWidth: 180,
				name: 'Manufacture',
				Header: () => (
					<Tooltip title="Manufacture" placement="bottom-start" >
						<Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} >Manufacture</Typography>
					</Tooltip>
				),
				accessor: "product_company",
				Cell: (row) => {
					return (
						<Box sx={{ width: '80px', height: '50px', background: '#fff' }}>
							<img
								src={row?.product_brand?.url}
								alt={row?.product_brand?.description}
								onError={e => {
								}}
								style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0 auto' }}
							/>
						</Box>
					)
				}
			}, {
				id: "Image",
				name: 'Graphics',
				Header: () => (
					<Tooltip title="Graphics" placement="bottom-start" >
						<Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} >Graphics</Typography>
					</Tooltip>
				),
				accessor: "product_image",
				maxWidth: 160,
				noSort: true,
				Cell: (row) => {
					//console.log(row)
					return (
						<Box sx={{ width: '80px', height: '50px', background: '#fff' }}>
							{row?.product_image?.[0]?.url ?
								<img
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain'
									}}
									src={row.product_image[0].url}
								/>
								:
								<FontAwesomeIcon size="3x" icon={faImageSlash} color="#ccc" style={{ margin: 'auto' }} />

							}
						</Box>
					)
				},
			}, {
				id: "product_description",
				name: 'L3 - Description',
				Header: () => <Typography>Description</Typography>,
				accessor: "product_description",
				minWidth: 260,
				maxWidth: 260,
			}, {
				id: "product_price",
				name: 'price',
				Header: () => <Typography>Price</Typography>,
				accessor: "product_price",
				minWidth: 260,
				Cell: (row) => {
					//console.log(row)
					return (
						<Grid container spacing={1} alignItems="center">
							<ProductPrices product={row} table={true} />
						</Grid>
					)
				}
			}]

		if (filters?.filters) {

			//console.log(attributes)

			filters.filters.map((item) => {
				console.log(item)
				columns.push({
					id: item.FilterID,
					name: `${item.FilterHeader}`,
					Header: () =>
						<Typography sx={{ cursor: 'pointer' }} >{item.FilterHeader}</Typography>
					,
					accessor: item.FilterID,
					Cell: (row) => {
						console.log(row)
						let found = row?.product_normalised_attributes?.find((attr) => parseInt(attr.id) === item.FilterID);
						//console.log(found)
						return (
							<Typography fontSize={14} sx={{ cursor: 'pointer' }} >
								{found ? found.value : '-'} <Typography component="span" fontSize={14} sx={{ opacity: 0.4 }}>{found?.value && found?.unit ? found.unit : ''}</Typography>
							</Typography>
						)
					},
				});
			});
		}

		return columns;
	}, [user, filters?.filters]);

	const addCustomRowClick = (row) => {
		setOpen(true)
		setSelectedProducts(row)
	}

	const handleSorting = (field) => {

		//console.log(field)
		//if field is currently the sortColumn
		if (sortColumn === field.field) {
			//set decending
			if (sortOrder === "asc") {
				setSortOrder("desc")
				//remove sortColumn and sortOrder
			} else if (sortOrder === "desc") {
				setSortOrder();
				setSortColumn();
			}
		} else {
			//set ascending
			setSortColumn(field.field);
			setSortOrder("asc");
		}
		//console.log(field)
	}

	useEffect(() => {

		if (sortOrder && sortColumn) {
			dispatchProducts({
				type: UPDATE_ORDER,
				payload: {
					sortOrder: sortOrder,
					sortColumn: sortColumn,
				}
			})
		} else {
			dispatchProducts({
				type: UPDATE_ORDER,
				payload: {
					sortOrder: null,
					sortColumn: null,
				}
			})
		}
	}, [sortOrder, sortColumn])

	return (
		<Grid container item xs={12}>
			<Grid item sx={{
				display: 'grid',
				width: '100%'
			}}>
				{(products?.allProducts && !isFetching) ?
					<Card>
						<CardContent
							sx={{
								overflowY: 'auto',
								overflowY: 'auto',
								width: '100%',
								padding: 0,
								height: 'calc(100vh - 400px)'
							}}
						>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow
										sx={{ background: (theme) => theme.palette.background.header }}
									>
										{columns.map((column, i) => {
											//console.log(column)
											if (!column.hidden && !hidden.includes(column.accessor)) {
												//console.log(name)
												return (
													<TableCell
														key={i}
														padding={column.disablePadding ? 'none' : 'normal'}
														sx={{
															background: (theme) => sortColumn === column.accessor ? theme.palette.primary.light : theme.palette.primary.main,
															color: (theme) => theme.palette.primary.contrastText,
															'&:hover ': {
																color: (theme) => theme.palette.primary.contrastText,
															},
															maxWidth: column.maxWidth ? column.maxWidth : 'auto',
															minWidth: column.minWidth ? column.minWidth : 'auto'
														}}
													>
														<Box
															sx={{
																height: '80%',
																marginTop: 0,
																marginBottom: 0,
																width: '1px',
																background: (theme) => theme.palette.primary.light,
																position: 'absolute',
																left: 0,
																top: '10%'
															}}
														/>
														{!column.noSort && column.field ?
															<TableSortLabel
																active={sortColumn === column.field}
																direction={sortColumn === column.field ? sortOrder : "asc"}
																onClick={(e) => handleSorting(column)}
															>
																{column.Header ? column.Header() : column.name}
															</TableSortLabel>
															:
															column.Header ? column.Header() : column.name
														}
													</TableCell>
												)
											}
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{products?.allProducts && columns && products.allProducts.map((item, ind) => {
										return (
											<TableRow
												hover

												key={ind}
												sx={{
													cursor: 'pointer',
													borderBottom: '1px solid #ccc',
													backgroundColor: (theme) => theme.palette.background.paper,
													'&:nth-of-type(odd)': {
														backgroundColor: (theme) => theme.palette.background.default,
													},
													// hide last border
													'&:last-child td, &:last-child th': {
														borderBottom: 0,
													},
												}}>
												{columns.map((column, index) => {
													//console.log(props)
													//console.log(item)
													const tData = item[column.accessor] ? item[column.accessor] : "——";
													if (!column.hidden && !hidden.includes(column.accessor)) {
														return (
															<TableCell
																key={index}
																sx={{
																	maxWidth: column.maxWidth ? column.maxWidth : 'auto',
																	minWidth: column.minWidth ? column.minWidth : 'auto'
																}}
																onClick={() => {
																	if (column.accessor !== 'price') {
																		addCustomRowClick(item)
																	}
																}}
															>
																{column.Cell ? column.Cell(item) : tData}
															</TableCell>
														)
													}
												})}
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
					:
					<Skeleton width="100%" height={'calc(100vh - 400px)'} variant="rectangular" animation="wave" />
				}
				{open && selectedProduct &&
					<Dialog
						fullWidth
						maxWidth="md"
						open={open}
						onClose={handleClose}
					>
						<IconButton
							sx={{
								cursor: 'pointer',
								position: 'absolute',
								right: 8,
								top: 8,
								background: '#fff',
								padding: '10px 15px'
							}}
							onClick={handleClose}
						>
							<FontAwesomeIcon
								aria-label="close"
								sx={{
									color: (theme) => theme.palette.grey[500],
								}}
								icon={faTimes}
							/>
						</IconButton>
						<ProductGroupFull productDetails={selectedProduct} closeDialog={handleClose} />
					</Dialog>
				}
			</Grid>
		</Grid>
	);
};
export default ProductGroupTable;
