import React, { useContext } from "react";
import classNames from "classnames";
import { CategoryContext } from "../../store/CategoryContext";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import { ProductsContext } from "../../store/ProductsContext";
import { LayoutContext } from "../../store/LayoutContext";
import { RESET_PRODUCTS, UPDATE_PRODUCT_COUNT } from "../../store/ProductsReducers";
import { SHOW_CATEGORIES, SHOW_PRODUCTS, UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { SelectedContext } from "../../store/SelectedContext";
import findCurrentCategory from "../libs/findCurrentCategory";
import { isMobileOnly } from 'react-device-detect';

const CategoryCards = ({ categories }) => {

    //Gloabal states
    const { selected } = useContext(SelectedContext);
    const { dispatchCategories, categories: categoriesContext } = useContext(CategoryContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { dispatchLayout } = useContext(LayoutContext);

    const showProducts = (e) => {
        dispatchLayout({
            type: SHOW_PRODUCTS
        });
    };

    const updateCurrentCategory = (currentCat) => {

        let category = findCurrentCategory([categoriesContext.allCats], currentCat.ID);
       //console.log(category)
        //set current category
        dispatchCategories({
            type: UPDATE_CATEGORIES,
            payload: {
                currentCat: currentCat,
                openLevel: category[0].path ? category[0].path : []
            }
        });
        //reset products
        dispatchProducts({
            type: RESET_PRODUCTS
        }); 

        dispatchProducts({
            type: UPDATE_PRODUCT_COUNT,
            payload: {
                count: currentCat.ProductCount,
            }
        });
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                //type: currentCat.Items ? 'node' : 'leaf',
                page: 1
            }
        });
        //if bottom level go to products
        if (!currentCat.Items) {
            dispatchLayout({
                type: SHOW_PRODUCTS
            });
        } else {
            dispatchLayout({
                type: SHOW_CATEGORIES
            });
        }
    };
    //console.log(categories)

    return (
        <>
            <Grid container item xs={12} spacing={isMobileOnly ? 1 : 2} >
                {categories.length ? (
                    categories.map((cat, i) => {
                        const categoryClass = classNames([
                            { NoProducts: cat.Count === 0 },
                        ]);
                        if (cat.Count > 0) {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3}  key={i} alignItems="center">
                                    <Card
                                        sx={{ display: 'flex', height: '100px', position: 'relative', cursor: 'pointer' }}
                                        elevation={1}
                                        color={'background.paper'}
                                        onClick={(e) => {
                                            updateCurrentCategory(cat);
                                        }}>
                                        {cat?.Count ?
                                            <Grid sx={{
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'cards.header',
                                                borderColor: 'cards.footer',
                                                borderStyle: 'solid',
                                                borderWidth: '1px',
                                                position: 'absolute',
                                                borderRadius: ' 0 0 0 5px',
                                                fontSize: '10px',
                                                margin: '-1px',
                                                padding: '2px 8px'
                                            }}>
                                                <Typography variant="caption" color="text.primary" >
                                                    {cat.Count}
                                                </Typography>
                                            </Grid>
                                            : null}
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                width: 100,
                                                backgroundColor: 'cards.header',
                                                padding: '1em',
                                                objectFit: 'contain'
                                            }}
                                            image={cat.Icon}
                                            alt={cat.Name}
                                        />

                                        <CardContent sx={{ display: 'flex', alignItems: 'center' , paddingBottom : '1em!important'}}>
                                            <Typography variant="body1" color={'text.primary'}>
                                                {cat.Name ? cat.Name : cat.CatName}
                                            </Typography>
                                        </CardContent>

                                        {/* <Grid className="cats_innergrid">
                                                <Box className="cats_image" sx={{ background: '#f4f4f48c' }}>
                                                    {cat.Icon ?
                                                        <img src={cat.Icon} alt={cat.Name} />
                                                        :
                                                        <CategoryImage currentImage={cat.CatImg} />
                                                    }
                                                </Box>
                                                <div className="cats_info">
                                                   
                                                </div>
                                            </Grid> */}
                                    </Card>
                                </Grid>
                            )
                        }
                    })
                ) : null}
            </Grid>
            <Grid item sx={{ marginTop: '1em' }}>
                {categoriesContext.currentCat.Count ?
                    <Button
                        variant="contained"
                        onClick={(e) => showProducts(e)}
                        size="large"
                    //disabled={false}
                    >
                        <Typography variant="body2" color={'primary.contrastText'}>View all in <strong> {categoriesContext.currentCat.Count} </strong>products in &quot;<strong>{categoriesContext.currentCat.Name}</strong>&quot;</Typography>
                    </Button>
                    : null}
            </Grid>
        </>
    );
};

export default CategoryCards;
