import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../store/UserContext';
import LoadingScreen from '../reusable/LoadingScreen';
import EditEntry from './EditEntry';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import { useQuery } from "@tanstack/react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import PageContent from '../PageContent';
import { Alert } from '@mui/material';

const FullEntry = () => {

    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(true);
    const [company, setCompany] = useState();
    const [entry, setEntry] = useState();
    const [machine, setMachine] = useState();

    //get url params
    useEffect(() => {

        let urlParams = new URLSearchParams(window.location.search);
        let company = urlParams.get("company")
        let entry = urlParams.get("entry")
        let machine = urlParams.get("machine")
        setCompany(company)
        setEntry(entry)
        setMachine(machine)

    }, [window.location.search])

    //get entry details
    const { data: entryDetails, isFetching } = useQuery({
        queryKey: ['entries', entry, company],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'get',
            params: {
                entry_ids: [entry],
                allow_access: user.user.user_permissions.administrator || user.user.user_permissions.access.all_companies || user.user.user_permissions.access.companies.includes(company) ? true : false,
            }
        }),
        enabled: !!entry && !!company,
        retry: false
    })

    //get entry details
    const { data: machineDetails } = useQuery({
        queryKey: ['machine', machine],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'get',
            params: {
                machine_id: machine,
                allow_access: user.user.user_permissions.administrator || user.user.user_permissions.access.all_companies || user.user.user_permissions.access.companies.includes(company) ? true : false,
            }
        }),
        enabled: !!company && !!machine,
        retry: false
    })

    //get entry details
    const { data: companyDetails } = useQuery({
        queryKey: ['company', company],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            method: 'get',
            endpoint: 'companies',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            params: {
                id: company
            }
        }),
        enabled: !!company,
        retry: false
    })


    const closeDialog = () => {
        setOpen(false);
        navigate(`/coolantcare/machine/${machine}`)
    }

    return (
        <>
            <InnerHeaderWrap>
            </InnerHeaderWrap>
            <PageContent>
                {entryDetails && machineDetails && companyDetails &&
                    <EditEntry
                        isDialog={open}
                        setIsDialog={closeDialog}
                        originalEntry={{
                            original: entryDetails[0]
                        }}
                        selectedCompany={companyDetails[0]}
                        selectedMachine={machineDetails[0]}
                        history={history}
                        active={entryDetails[0]?.attributes_new?.length > 5 ? 2 : 1}
                        voided={entryDetails[0]?.void ? true : false}
                    />
                }
                {!user.user.user_permissions.administrator && !user.user.user_permissions.access.all_companies && !user.user.user_permissions.access.companies.includes(company) ?
                    <Alert variant="standard" severity="error" >
                        Sorry you don&apos;t have access to this entry.
                    </Alert>
                    : null}

                {isFetching ?
                    <LoadingScreen
                        loading={false}
                        background="#fafafa"
                        colour="#666"
                        text="Loading Entry"
                        position="relative"
                    />
                    : entryDetails?.length === 0 ?

                        <Alert variant="standard" severity="info" >
                            The entry you are looking for does not exist, or may have been voided.
                        </Alert>


                        : null}

            </PageContent>
        </>
    );
};

export default FullEntry;
