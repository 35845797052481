import React, { useState } from "react";
import { Backdrop, Box, Switch, Tooltip } from "@mui/material";
import config from "../config";
import ACTLandingOptions from "./ACTLandingOptions";
import CISLandingOptions from "./CISLandingOptions";
import FactoryFloor from "./FactoryFloor";
import SearchHeader from "../search/SearchHeader";
import useAppTheme from "../hooks/useAppTheme";
import InnerContentWrap from "../layout/InnerContentWrap";


export default function Landing(props) {

    const { background } = useAppTheme();
    const [value, setValue] = useState('')
    const [focused, setFocused] = useState(false)
    const [showOption, setShowOption] = useState(true)

    return (
        <>
            <SearchHeader
                landing={true}
                link={true}
                large={true}
                setValue={setValue}
                value={value}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
                props={props}
            />
            <InnerContentWrap
                noPadding={config.APPNAME === "act" ? true : false}
            >
                {config.APPNAME === "act" ?
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <img
                                src={background}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    //opacity: 0.3,
                                    zIndex: 1
                                }}
                                alt="Logo"
                            />
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '2em',
                                right: '2em',
                                zIndex: 6,
                            }}
                        >
                            <Tooltip
                                title="Close Options"
                            >
                                <Switch
                                    onClick={() => setShowOption(!showOption)}
                                    defaultChecked
                                />
                            </Tooltip>
                        </Box>
                        <Backdrop
                            sx={{
                                position: 'relative',
                                //color: '#fff',
                                zIndex: 3,
                                backgroundColor: config.APPNAME === "act" ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
                                //backgroundColor: 'rgba(0,0,0,0.5)',
                                alignItems: 'flex-start',
                                padding: '1em 2em 3em',
                                backdropFilter: config.APPNAME === "act" ? 'blur(10px)' : 'blur(10px)',
                                overflow: 'auto',
                            }}
                            open={showOption}
                        >
                      
                                <ACTLandingOptions />
                             
                        </Backdrop>
                    </>
                    : config.APPNAME === "cis" ?

                        <CISLandingOptions />
                        :
                        <FactoryFloor />
                }
            </InnerContentWrap>

        </>
    );
};

