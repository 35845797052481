import React, {
    useContext,
    useState,
} from "react";
import ProductLayout from "../products/ProductLayout";
import ProductOptions from "../../products/shared/ProductOptions";
import { Grid, useMediaQuery } from "@mui/material";
import Header from "../layout/Header";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { ProductsContext } from "../../store/ProductsContext";
import MatchingFilters from "../products/matching/MatchingFilters";
import MatchingProduct from "../products/matching/MatchingProduct";
import { isMobileOnly } from "react-device-detect";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";
import useProductObject from "../../hooks/useProductObject";
import useProductAttrbuites from "../../hooks/useProductAttributes";
import useCreateParametricFilters from "../hooks/useCreateParametricFilters";
import SearchHeader from "../../search/SearchHeader";

const MatchingProductsLayout = (props) => {

    const { products } = useContext(ProductsContext);
    const { attributes, setAttributes, detailProfile } = useProductAttrbuites();
    const { isFetching } = useProductObject();
    const [focused, setFocused] = useState(false);

    useCreateParametricFilters(detailProfile)

    const closeFilters = (e) => {
        setFilterShow(e);
    };

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <SearchHeader
                link={true}
                large={true}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
                props={props}
            />
            <InnerContentWrap >
                <Grid container item spacing={isMobileOnly ? 1 : 2} alignItems="flex-start" >
                    <Grid container spacing={isMobileOnly ? 1 : 2} item xs={12} md={4} lg="auto"
                        sx={{
                            width: lgUp ? '400px!important' : 'auto',
                            maxWidth: lgUp ? '400px!important' : '100%'
                        }}>

                        <MatchingProduct attributes={attributes} />

                        <Grid item xs={12} >
                            <MatchingFilters closeFilters={closeFilters} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={isMobileOnly ? 1 : 2} xs={12} md={8} lg>
                        {products.noProducts ? (
                            <ProductsNotAvailable />
                        ) :
                            <>
                                <ProductOptions isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                <ProductLayout isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                <ProductOptions isFetching={isFetching} bottom />
                            </>
                        }
                    </Grid>
                </Grid>
            </InnerContentWrap>
        </>
    );
};

export default MatchingProductsLayout;
