import React from 'react';
import {
    Box,
    Alert,
    Tooltip,
    Grid,
    Typography,
    LinearProgress
} from '@mui/material';

export default function PercentageBar({ percentage, machines, total }) {

    return (
        <>
            <Tooltip title="Current machine usage" placement="bottom" arrow>
                <Grid item xs={12} md={8} container spacing={2} alignItems="stretch">
                    <Grid item xs={10}>
                        <Box
                            sx={{
                                height: '40px',
                                position: 'relative',
                            }}>
                            <LinearProgress
                                value={percentage ? percentage : 0}
                                variant="determinate"
                                sx={{
                                    height: '40px'
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: (theme) => theme.palette.primary.contrastText
                                }}
                            >
                                {percentage ? `${percentage.toFixed(2)}%` : `0%`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} >
                        <Box
                            sx={{
                                height: '40px',
                                background: theme => theme.palette.primary.main,
                                color: theme => theme.palette.primary.contrastText
                            }}
                        >
                            <Typography variant="h6" align="center"
                                sx={{
                                    height: '40px',
                                    lineHeight: '40px'
                                }}
                            >
                                {machines ? `${machines}/${total}` : `0/${total}`}
                            </Typography>

                        </Box>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} >
                <Alert severity="info" sx={{ width: '100%' }}>
                    Current usage in {machines ? `${machines}/${total}` : `0/0`} machines
                </Alert>
            </Grid> */}
            </Tooltip>
        </>
    );
};
