import { TextField, Button, Grid } from '@mui/material';
import React from 'react';
import { FieldArray, useFormikContext } from "formik"
import setLanguageText from '../../../../language/setLanguageText';

export default function List({ field }) {

    const { values, setFieldValue, touched, handleChange } = useFormikContext();

    //console.log(values[field.name])

    return (
        <>
            <Grid item xs={12}>
                <FieldArray name={field?.name}>

                    {({ insert, remove, push }) => (
                        <Grid item container spacing={2} alignItems="end">
                            {values?.[field.name]?.length > 0 &&
                                values?.[field.name].map((item, index) => (
                                    <Grid item container spacing={2} alignItems="end">
                                        <Grid item xs={10}>
                                            <TextField
                                                multiline
                                                rows={2}
                                                id={`[${field.name}].${index}`}
                                                label={`${setLanguageText(field.label)} ${index + 1}`}
                                                variant="outlined"
                                                fullWidth
                                                value={values?.[field.name]?.[index]}
                                                onChange={handleChange}
                                                required={field?.required}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                component="label"
                                                color="error"
                                                onClick={() => remove(index)}
                                            >
                                                {setLanguageText("Remove")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                            <Grid item xs={12}>
                                <Button
                                    //fullWidth
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                    onClick={() => push("")}
                                >
                                    {setLanguageText(`Add ${field.label}`)}
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                </FieldArray>
            </Grid>
        </>
    );
};

