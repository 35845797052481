import React, { useContext, useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useQuery } from "react-query";
import axiosRequest from "../../../axios/axoisRequest";
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import ProductGridItem from "../../../products/cim/ProductGridItem";
import createProductObject from "../../../helpers/createProductObject";
import { LayoutContext } from "../../../store/LayoutContext";

const MatchingProduct = ({ attributes }) => {

    const { user } = useContext(UserContext);
    const [product, setProduct] = useState();
    const [id, setId] = useState();
    const { app } = useContext(LayoutContext);

    useEffect(() => {
        //get url params
        const params = new URLSearchParams(window.location.search)
        const id = params.get('product')
        if (id) {
            setId(id)
        }
    }, [window.location.search])


    const { data } = useQuery(['products', id], () =>
        axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'products/full',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: id,
            }
        })
        , {
            enabled: !!id,
            notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
            retry: 1
        })


    useEffect(() => {
        const createProduct = async (product) => {
            const clone = await structuredClone(product);
            let productObject = await createProductObject([
                { ...clone, area: app.selectedArea, "product_dataset": "cim" },
            ]);
            setProduct(productObject[0])
        };
        if (data) {
            createProduct(data);
        }
    }, [data]);


    return (
        <>
            {product ?
                <Grid item sx={{ marginBottom: '1em' }}>
                    <ProductGridItem
                        product={product}
                        attributes={attributes}
                    />
                </Grid>
                : null}
        </>
    );
};
export default MatchingProduct;