import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const CheckboxFilter = ({
    value: initialValue,
    onChange,
    label
}) => {

    const [value, setValue] = useState(initialValue)

    return (
        <FormControlLabel
            disableTypography
            control={
                <Checkbox
                    size='small'
                    checked={value}
                    onChange={(e, value) => {
                        setValue(value)
                        onChange(value)
                    }}
                    name={label}
                />
            }
            label={label}
        />
    )
}
export default CheckboxFilter;