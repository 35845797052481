import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../store/LayoutContext';
import { LANGUAGE, UPDATE_MENU } from '../store/LayoutReducers';

const LanguageSelect = () => {

    const { dispatchApp } = useContext(LayoutContext)

    const options = [
        { label: 'English', id: 'EN' },
        { label: 'German', id: 'DE' }
    ];
    const { language, dispatchLanguage } = useContext(LayoutContext)
    const [value, setValue] = useState(window.sessionStorage.getItem('language') ? JSON.parse(window.sessionStorage.getItem('language')) : language)

    useEffect(() => {
        if (value) {
            window.sessionStorage.setItem('language', JSON.stringify(value));
            dispatchLanguage({
                type: LANGUAGE,
                payload: value
            })
        }
    }, [value, dispatchLanguage])

    useEffect(() => {
        if (language) {
            console.log(language)
            const menu = require(`../_config/${process.env.REACT_APP_CONFIG_PATH || 'dev'}/menu_object.js`);
            console.log(menu.default)
            dispatchApp({
                type: UPDATE_MENU,
                payload: {
                    menu: menu.default
                }
            })
        }
    }, [language])


    return (
        <>
            <Autocomplete
                sx={{ width: 180 }}
                disableClearable
                id="selected-language"
                value={value}
                options={options}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, newValue) => {
                    setValue(newValue);
                }}

                renderInput={params => (
                    <TextField {...params} variant="outlined"
                    //label={setLanguageText("Select Language")}
                    />
                )}
            />
        </>
    );
};


export default LanguageSelect;
