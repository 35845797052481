import React, { useEffect, useContext, useState } from 'react';
import {
	Tab,
	Box,
	Card
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../language/setLanguageText';
import CompanyERP from './../erp/CompanyERP';
import Reports from './../reports/Reports';
import EmailAddress from './../emails/EmailAddress';
import getUserLevel from '../../../helpers/getUserLevel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EccommerceSuppliers from '../visibility/ecommerce/EccommerceSuppliers';
import EngineerSuppliers from '../visibility/engineer/EngineerSuppliers';
import Templates from '../../../templates/Templates';
import ProductVisibility from '../visibility/coolantcare/ProductVisibility';
import { LayoutContext } from '../../../store/LayoutContext';
import OpenSearchManufacturers from '../visibility/opensearch/OpenSearchManufacturers';


const AdminSettings = () => {

	const navigate = useNavigate();
	const [value, setValue] = useState(0);
	const [edit, setEdit] = useState(false);
	const [coolantTitle, setCoolantTitle] = useState()
	const { user } = useContext(UserContext);
	const { app } = useContext(LayoutContext)

	useEffect(() => {
		if (app.menu) {
			let found = app.menu.find(item => item.area === 'coolantcare')
			setCoolantTitle(found.buttonText)
		}
	}, [app.menu])


	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search);
		let tab = urlParams.get("tab")
		if (tab) {
			setValue(parseInt(tab));
		}
	}, [])

	const handleChange = (event, newValue) => {

		let urlParams = new URLSearchParams(window.location.search);
		let section = urlParams.get("section")
		navigate(`/settings/company?section=${section}&tab=${newValue}`)
		setValue(parseInt(newValue));
	};

	//add edit to form items
	useEffect(() => {
		if (getUserLevel(user.user, 'home', 'company_settings', 7)) {
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [user])


	return (

		<Card  >
			<Box sx={{ display: 'flex' }}>
				<TabContext value={value}>
					<Box
						sx={{
							overflow: "visible",
							minWidth: "240px",
							background: "#fbfbfb",
							'& .MuiTab-root': {
								textAlign: 'left',
								alignItems: 'start',
								borderBottom: '1px solid #e8e8e8'
							}
						}}>
						<TabList
							orientation={isMobileOnly ? "horizontal" : "vertical"}
							onChange={handleChange}
						>
							<Tab label={setLanguageText("Email Recipients")} value={0} />
							<Tab label={setLanguageText("ERP Connection")} value={1} />
							<Tab label={setLanguageText(`${coolantTitle} Product Visibility`)} value={6} />
							{/* <Tab label={setLanguageText("Catalogue Visibility")} value={3} /> */}
							<Tab label={setLanguageText("Engineer Visibility")} value={4} />
							<Tab label={setLanguageText("Search Visibility")} value={7} />
							<Tab label={setLanguageText("Visibility Templates")} value={5} />
							<Tab label={setLanguageText("Reporting")} value={2} />
						</TabList>
					</Box>
					<Box sx={{
						flexGrow: 1,
						'& .MuiTabPanel-root': {
							padding: 0,
							borderLeft: '1px solid #e8e8e8'
						}
					}}>
						<TabPanel value={0}>
							<EmailAddress />
						</TabPanel>
						<TabPanel value={1}>
							<CompanyERP edit={edit} />
						</TabPanel>
						{/* <TabPanel value={3} >
							<EccommerceSuppliers />
						</TabPanel>*/}
						<TabPanel value={4} >
							<EngineerSuppliers />
						</TabPanel>
						<TabPanel value={6}>
							<ProductVisibility />
						</TabPanel> 
						<TabPanel value={7}>
							<OpenSearchManufacturers />
						</TabPanel>
						<TabPanel value={5} >
							<Templates type={["engineer_brands", "catalogue_brands", "opensearch_brands"]} />
						</TabPanel>
						<TabPanel value={2}>
							<Reports edit={edit} />
						</TabPanel>
					</Box>
				</TabContext>
			</Box>
		</Card >
	);
}

export default AdminSettings;


