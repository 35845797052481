import * as React from 'react';

import { CardContent, Typography, Card, Avatar, Grid, CardHeader } from '@mui/material';
import setLanguageText from '../language/setLanguageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomCard = ({ children, title, sx, icon, hightlight }) => {

    return (


        <Card
            sx={{
               // marginTop: '1em',
               //padding: '1em',
                position: 'relative',
                overflow: 'visible',
                ...sx
            }}
        >
            <CardHeader
                title={
                    <>
                        {icon ?
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                                        <FontAwesomeIcon size="xs" icon={icon} />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h6'>
                                        {setLanguageText(title)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            <Typography variant='h6'>
                                {setLanguageText(title)}
                            </Typography>
                        }
                    </>
                }
                sx={{
                    background: theme => hightlight ?  theme.palette.cards.header : theme.palette.cards.header,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            />

            <CardContent>
                {children}
            </CardContent>
        </Card>

    );
}
export default CustomCard;