
import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Card, CardContent, CardHeader, Grid, Skeleton, Typography } from "@mui/material";
import { useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import MaterailGroups from "../../shop/wizards/MaterialGroups";
import FilterSelect from "../../shop/wizards/FilterSelect";
import axiosRequest from "../../axios/axoisRequest";

export default function Materials({ setMaterial, material }) {

	const { user } = useContext(UserContext);
	const [standard, setStandard] = useState();
	const [type, setType] = useState();
	const [rootClass, setRootClass] = useState();

	const handleRootClass = (item) => {
		setRootClass(item)
		setMaterial(item.Label)
		setStandard()
		setType()
	};

	const handleStandardIndex = (event, index, item) => {
		setStandard(item)
		setType()
	};

	const handleMaterialIndex = (event, index, item) => {
		setType(item)
	};

	// const updateMaterialFilters = (group) => {
	// 	console.log(group)
	// 	//setMaterial(group)
	// };

	//--------------------------- API CALLS  ------------------------------//

	const { data, isFetching } = useQuery({
		queryKey: ['materialinformation'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materialinformation',
			api_key: user.api_key,
			method: 'get',
		}),
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: m_data, isFetching: m_isFetching } = useQuery({
		queryKey: ['material', rootClass, standard],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materials',
			api_key: user.api_key,
			method: 'get',
			params: {
				rootClass: rootClass.Value,
				standard: standard.Value
			}
		}),
		enabled: !!standard && !!rootClass,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: g_data, isFetching: g_isFetching } = useQuery({
		queryKey: ['materialgroups', rootClass, standard, type],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materials/groups',
			api_key: user.api_key,
			method: 'get',
			params: {
				groups: JSON.stringify(type.Groups)
			}
		}),
		enabled: !!standard && !!rootClass && !!type,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	useEffect(() => {
		if (data?.RootClasses) {
			setRootClass(data.RootClasses[0])
			setMaterial(data.RootClasses[0].Label)
		}
	}, [data])

	return (
		<>
			<Grid container spacing={1} alignContent="stretch">
				{data && data.RootClasses.map((item, i) => {
					return (
						<Grid item xs={2} key={i} >
							<Box
								onClick={e => handleRootClass(item)}
								sx={{
									background: item.Color,
									cursor: 'pointer',
									borderRadius: '10px',
									boxShadow: item.Value === rootClass?.Value ? '0px 0px 5px 0px #666' : 'none',
									border: '2px solid #fff',
									'&:hover': {
										boxShadow: '0px 0px 5px 0px #444'
									},
									padding: '1em',
									height: '100%',
								}}
								key={i} >
								<Grid
									container

								>
									<Grid item xs={12}>
										<Typography component="p" variant="h5"  >{item.Value}</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography component="p" variant="caption" >{item.Label}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					)
				})}
				{!isFetching ?
					<Grid item container spacing={2}  >
						<Grid item xs={3}>
							<Card
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<CardHeader
									disableTypography
									title="Standard"
									sx={{
										backgroundColor: '#efefef'
									}}
								/>
								<CardContent>
									<FilterSelect options={data?.Standards} selected={standard} handleChange={handleStandardIndex} isFetching={isFetching} name={'standard'} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={3}>
							<Card
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<CardHeader
									disableTypography
									title="Material Name"
									sx={{
										backgroundColor: '#efefef'
									}}
								/>
								<CardContent>
									<FilterSelect options={m_data} selected={material} handleChange={handleMaterialIndex} isFetching={m_isFetching} name={'material'} />
								</CardContent>
							</Card>

						</Grid>
						<Grid item xs={6}>
							<Card
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<CardHeader
									disableTypography
									title="Select Group"
									sx={{
										backgroundColor: '#efefef'
									}}
								/>
								<CardContent>
									{g_isFetching ?
										<Skeleton height={200} variant="rectangular" animation="wave" />
										: g_data &&
										<Box sx={{ width: '100%', background: '#efefef', border: '1px solid #ccc', padding: '5px' }}>
											<MaterailGroups groups={g_data} isFetching={g_isFetching} setMaterial={setMaterial} />
										</Box>
									}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					: null}
				{/* <Grid item xs={12}>
					{!rootClass && !standard && data && <Alert severity="info">Please select a Class and a Standard</Alert>}
					{rootClass && !standard && data && <Alert severity="info">Please select a Standard</Alert>}
					{!material && m_data && <Alert severity="info">Please select a Material Name</Alert>}
				</Grid> */}
			</Grid>
		</>
	);
};