import React, { useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../store/CategoryContext";
import { Box, Button, Card, CardActions, Container, Drawer, Grid, useMediaQuery, useTheme } from "@mui/material";
import ParametricForm from "./ParametricForm";
import ParametricTree from "./ParametricTree";
import InnerContentWrap from "../../layout/InnerContentWrap";
import SearchHeader from "../../search/SearchHeader";

const MobileDrawWrapper = ({ children }) => {

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [open, setOpen] = useState()

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<>

			{!isMd ?
				<>
					<Grid item>
						<Button variant="contained" onClick={handleDrawerOpen}>Open Categories</Button>
					</Grid>
					<Drawer
						variant="temporary"
						open={open}
						onClose={handleDrawerClose}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						PaperProps={{
							elevation: 0,
							overflow: "auto"
							//sx: { padding : '1em'}
						}}
					>
						<Card
							elevation={0}
							color={'background.paper'}
						//sx={{ padding: 2, maxHeight: !isMd ? "auto" : "calc(100vh - 297px)", overflow: "auto" }}
						>
							{children}
							<CardActions>
								<Grid container item xs={12} justifyContent="flex-end">
									<Button
										size="large"
										sx={{ float: 'right' }}
										variant="contained"
										onClick={handleDrawerClose}
									>Close Categories</Button>
								</Grid>
							</CardActions>
						</Card>
					</Drawer>
				</>
				:
				<Grid item
					xs="auto"
				//sx={{ width: "480px" , maxWidth : "480px"}}
				//sx={{ width: "600px" , maxWidth : "600px"}}
				>
					<Box sx={{ position: 'relative' }}>
						<Drawer
							variant="permanent"
							open={open}
							onClose={handleDrawerClose}
							PaperProps={{
								elevation: 1,
								sx: {
									borderRadius: '4px',
									width: "100%",
									position: "relative",
									padding: '0',
									maxHeight: "calc(100vh - 180px)",
									overflow: "auto"
								}

							}}
						>
							<Card
								elevation={1}
								color={'background.paper'}
							//sx={{ padding: 2, maxHeight: !isMd ? "auto" : "calc(100vh - 297px)", overflow: "auto" }}
							>
								{children}
							</Card>
						</Drawer>
					</Box>
				</Grid>
			}
		</>
	)
}

const Parametric = (props) => {

	const { categories } = useContext(CategoryContext);
	const [openLevels, setOpenLevel] = useState();
	const [focused, setFocused] = useState(false);

	useEffect(() => {
		if (Object.values(categories.openLevel).length) {
			//console.log(categories.openLevel)
			setOpenLevel(categories.openLevel)
		} else {
			setOpenLevel(["Root"])
		}
	}, [
		categories.openLevel,
	])

	return (
		<>
		    <SearchHeader
                link={true}
                large={true}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
				props={props}
            />
			<InnerContentWrap >
				<Grid container item spacing={2} xs={12}
					sx={{ maxHeight: "calc(100vh - 145)" }}
				>
					{openLevels ?
						<MobileDrawWrapper>
							<ParametricTree openLevels={openLevels} setOpenLevel={setOpenLevel} />
						</MobileDrawWrapper>
						: null}
					<Grid item xs={12} md >
						<ParametricForm customHistory={props.customHistory} />
					</Grid>
				</Grid>
			</InnerContentWrap>
		</>
	);
};

export default Parametric;
