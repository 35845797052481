import React, { useMemo, useState } from 'react'
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	getFilteredRowModel
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton } from '@mui/material'
import formatDate from '../../../coolantcare/helpers/formatDate'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableHeaderCell from '../../../tables/TableHeaderCell'

export default function ServiceTable({ data, isFetching, getServiceEntries }) {


	const columns = useMemo(() => [{
			accessorKey: 'company_name',
			header: () => 'Company',
			footer: props => props.column.id,
			cell: info => info.getValue(),
			enableSorting: false,
			enableColumnFilter: false
		},{
			accessorKey: 'service_date',
			header: () => 'Date',
			footer: props => props.column.id,
			cell: info => formatDate(info.getValue()),
			enableSorting: false,
			enableColumnFilter: false
		}, {
			accessorKey: 'service_attr.startTime',
			header: () => 'Time',
			footer: props => props.column.id,
			cell: info => info.getValue(),
			enableSorting: false,
			enableColumnFilter: false
		}, {
			accessorKey: 'service_name',
			header: () => 'Enginner name',
			footer: props => props.column.id,
			cell: info => info.getValue(),
			enableSorting: false,
			enableColumnFilter: false
		}, {
			accessorKey: 'customer_name',
			header: () => 'Customer name',
			footer: props => props.column.id,
			cell: info => info.getValue(),
			enableSorting: false,
			enableColumnFilter: false
		}, {
			accessorKey: 'action',
			header: () => 'Action',
			footer: props => props.column.id,
			cell: ({ row }) => {
				//console.log(row.original)
				return (
					<Grid container justifyContent="center">
						<Grid item>
							<Tooltip title="View Pdf" placement="top">
								<IconButton
									onClick={() => getServiceEntries(row.original)}
									size="large">
									<FontAwesomeIcon icon={faFilePdf} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				)
			},
			enableSorting: false,
			enableColumnFilter: false
		}
	], [])

	const defaultData = useMemo(() => [], [])
	const [sorting, setSorting] = useState([])

	const table = useReactTable({
		data: data ?? defaultData,
		columns,
		state: {
			sorting,
			//rowSelection: rowSelection || {}
		},
		//enableRowSelection: true, //enable row selection for all rows
		//enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
		//onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel()
		//debugTable: true,
	})

	const { pageSize, pageIndex } = table.getState().pagination

	return (
		<Box sx={{ width: '100%' }}>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<TableContainer >
						<Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
							<TableHead>
								{table.getHeaderGroups().map((headerGroup, i) => (
									<TableRow
										key={i}
										sx={{
											backgroundColor: (theme) => theme.palette.table.header,
										}}
									>
										{//added a custom header cell
											headerGroup.headers.map((header, i) => <TableHeaderCell key={i} header={header} i={i} table={table} />)
										}
									</TableRow>
								))}
							</TableHead>
							<TableBody>
								{isFetching ?
									new Array(pageSize).fill(0).map((_, i) => {
										return (
											<TableRow
												key={i}
												sx={{
													cursor: 'pointer',
													borderBottom: '1px solid #ccc',
													backgroundColor: (theme) => theme.palette.table.row,
													'&:nth-of-type(odd)': {
														backgroundColor: (theme) => theme.palette.table.hover,
													},
													'&:last-child td, &:last-child th': {
														borderBottom: 0,
													},
												}}>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
											</TableRow>
										)
									})

									: !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {
										return (
											<TableRow
												key={i}
												sx={{
													cursor: 'pointer',
													borderBottom: '1px solid #ccc',
													backgroundColor: (theme) => theme.palette.table.row,
													'&:nth-of-type(odd)': {
														backgroundColor: (theme) => theme.palette.table.hover,
													},
													'&:last-child td, &:last-child th': {
														borderBottom: 0,
													},
												}}
											>
												{row.getVisibleCells().map((cell, i) => {
													return (
														<TableCell key={i}>
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext()
															)}
														</TableCell>
													)
												})}

											</TableRow>
										)
									})
										:
										<TableRow>
											<TableCell colSpan={7}>
												<Alert sx={{ width: '100%', margin: '1em' }} severity="info">No data found</Alert>
											</TableCell>
										</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12} >
					<TablePagination
						rowsPerPageOptions={[10, 50, 100, 200]}
						component="div"
						count={table.getFilteredRowModel().rows.length}
						rowsPerPage={pageSize}
						page={pageIndex}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							native: true,
						}}
						onPageChange={(_, page) => {
							table.setPageIndex(page)
						}}
						onRowsPerPageChange={e => {
							const size = e.target.value ? Number(e.target.value) : 10
							table.setPageSize(size)
						}}
					//ActionsComponent={TablePaginationActions}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

