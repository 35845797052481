import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { UserContext } from "../../store/UserContext"
import Row from "./Row";
import { CLEAR_URL_FILTERS } from "../../store/SelectedReducers";
import { SelectedContext } from "../../store/SelectedContext";


const FirstRow = ({ setToolClass, setSelectedID, selectedID, setForm }) => {

    const { user } = useContext(UserContext)
    const { dispatchSelected } = useContext(SelectedContext)
    const [appRow, setAppRow] = useState();
    const [selected, setSelected] = useState();

    //--------------------------- API CALLS  ------------------------------//

    const { data, isFetching } = useQuery(['applicationRow'],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'applicationsearchrow',
            api_key: user.api_key,
            method: 'get'
        }), {
        enabled: !!user.api_key,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    //setSelected on first load
    useEffect(() => {
        // console.log(selectedID)
        if (selectedID && selectedID[0]) {
            setSelected(selectedID[0])
        } else {
            setSelected()
        }
    }, [selectedID])

    // console.log(props)

    const handleSelect = (e, value) => {
        //console.log(value)
        //update parent with full item
        setToolClass([value])
        setSelectedID([value.ID])
        //reset form
        dispatchSelected({
            type: CLEAR_URL_FILTERS
        });
        setForm()
    }
    //console.log(selectedID)

    return (
       
        <Row isFetching={isFetching} data={data} title="Technology" selected={selected} handleSelect={handleSelect} />
       
    );
}
export default FirstRow;
