import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import showFieldLogic from "../../libs/showFieldLogic";

const Overlay = ({ field, focus, focusField, value }) => {

    const [text, setText] = useState(field.Abbreviation);
    const [hide, setHide] = useState(true);
    const { selected } = useContext(SelectedContext);

    //--------------------------- API CALLS  ------------------------------//

    //console.log(field.Abbreviation)
    useEffect(() => {
        if (value && value.fieldAbb === field.Abbreviation) {
            setText(value.value ? value.value : field.Abbreviation)
        }
    }, [value, field])

    //hide fields based on the logic
    useEffect(() => {
        if (field.Condition) {
            let showField = showFieldLogic(field.Condition, selected.applicationFilters)
            setHide(!showField)
        } else {
            //if there are not conditions show field
            setHide(false)
        }
    }, [field.Condition, selected.applicationFilters])


    return (

        <>
            {field.Height > 0 ?
                <Box
                    onClick={e => focusField(field.Abbreviation)}
                    sx={{
                        display: hide ? "none" : "grid",
                        background: (theme) => focus === field.Abbreviation ? theme.palette.primary.light :  theme.palette.background.paper,
                        background: (theme) => focus === field.Abbreviation ? theme.palette.primary.light : theme.palette.background.paper,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: (theme) => focus === field.Abbreviation ? theme.palette.primary.main : '#CCC',
                        //padding: '5px',
                        position: 'absolute',
                        width: field.Horizontal ? field.Width + 5 : field.Height + 5,
                        height: field.Horizontal ? field.Height + 5 : field.Width + 5,
                        left: `${field.PositionX}px`,
                        top: `${field.PositionY}px`,
                        cursor: 'pointer',
                        margin: field.Horizontal ? '-9px 0 0 0' : '32px -35px !important',
                        fontSize: '12px',
                        lineHeight: '19px',
                        textAlign: 'center',
                        transform: `rotate(${field.Horizontal ? "0deg" : "270deg"})`,
                        transformOrigin: 'center',
                        overflow: 'hidden'
                    }}
                >
                    {text}
                </Box>
                :
                null}
        </>

    );
}
export default Overlay;
