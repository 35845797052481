import React from "react";
import { Box, useTheme } from "@mui/material";
import { isMobileOnly } from 'react-device-detect';

const InnerContentWrap = ({ children, noPadding, background }) => {

    return (
        <Box sx={{
            padding: noPadding ? 0 : isMobileOnly ? "0.5em" : "1em",
            width: '100%',
            background: (theme) => background ? background : theme.palette.background.main,
            //maxWidth: '1600px',
            margin: '0 auto',
            position: 'relative',
            overflow: 'hidden',
        }} >
            {children}
        </Box>
    );
};
export default InnerContentWrap;