import React, { useContext, useEffect, useState, } from "react";
import { Box, Grow, Typography, darken, lighten } from "@mui/material";
import { ReactComponent as ApplicationSVG } from './svg/application.svg';
import { UserContext } from "../../store/UserContext";
import { useNavigate } from "react-router-dom";
import GlobalDialog from "../../reusable/GlobalDialog";
import AreaDetails from "../../reusable/AreaDetails";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_MENU } from "../../store/LayoutReducers";
import getAccessID from "../../helpers/getAccessID";

const Application = ({ svgprops, textprops, setHoveredBoxIndex, boxIndex, hoveredBoxIndex, areaIndex, noClick }) => {

    const { user } = useContext(UserContext);
    const { app, dispatchApp } = useContext(LayoutContext)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [area, setArea] = useState()

    useEffect(() => {
        if (app.menu) {
            let menuItem = app.menu.find(app => app.area === "application")
            if (menuItem) setArea(menuItem)
        }

    }, [app.menu])

    const closeModal = () => {
        setOpen(false);
    };

    const handleSubmit = (area) => {
        setOpen(false);
        dispatchApp({
            type: UPDATE_MENU,
            payload: {
                selectedArea: area.area,
                selectedMenu: area.menu
            }
        })
        if (area?.link) {
            navigate(area?.link)
        } else if (area?.externalLink) {
            window.open(menareaItem.externalLink, '_blank')
        }
    };


    return (
        <>
            <Grow
                in={!areaIndex ? true : areaIndex <= boxIndex}
                timeout={1000}
            >
                <Box
                    sx={{
                        ...svgprops,
                        '& .B' : {
                            fill: theme => theme.palette.factory.secondary_shadow
                        },
                        '& .C' : {
                            fill: theme => theme.palette.factory.secondary_main
                        },
                        '& .D' : {
                            fill: '#333'
                        },
                        '& .E' : {
                            fill: theme => theme.palette.factory.secondary_highlight
                        },
                        '& .F' : {
                            fill: theme => theme.palette.factory.primary_main
                        },
                        '& .G' : {
                            fill: theme => theme.palette.factory.primary_shadow
                        },
                        '& .H' : {
                            fill: theme => theme.palette.factory.primary_highlight
                        },
                        '& .I': {
                            fill: theme => theme.palette.factory.secondary_shadow
                        }
                    }}
                >
                     <Box
                        sx={{
                            position: 'relative',
                            display: 'grid',
                            width: '100%',
                            height: '100%',
                            transition: "opacity 0.3s",
                            opacity: getAccessID(user?.user?.user_permissions?.application?.access) === 1 ? 1 : 0.1,
                            '&.greyed-out ': {
                                opacity: 0.3
                            }
                        }}
                        className={hoveredBoxIndex !== -1 && hoveredBoxIndex !== areaIndex ? 'greyed-out' : ''}
                    >
                        <ApplicationSVG/>
                        <Box
                            className="hover-box"
                            onMouseEnter={() => setHoveredBoxIndex ? setHoveredBoxIndex(areaIndex) : null }
                            onClick={() => noClick ? null :setOpen(true)}
                            onMouseLeave={() => setHoveredBoxIndex ? setHoveredBoxIndex(-1) : null}
                            sx={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                //background: '#000',
                                clipPath: "polygon(0% 65%, 4% 34%, 45% 10%, 90% 36%, 91% 65%, 47% 90%)"
                            }} />
                       
                        <Typography
                            sx={{
                                ...textprops,
                                color: "#fff",
                                transform: 'skew(360deg, 30deg)',
                                textTransform: 'uppercase',
                                position: 'absolute'
                            }}
                        >{area?.title}</Typography>
                    </Box>
                </Box>
            </Grow>
            <GlobalDialog
                open={open}
                onClose={closeModal}
                title={area?.title}
                buttonTitle={`Visit ${area?.title}`}
                buttonClick={() => handleSubmit(area)}
                maxWidth="md"
            //disabled={true}
            >
                <AreaDetails area={area} />
            </GlobalDialog>
        </>
    );
};


export default Application;
