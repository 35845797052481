import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { ShopContext } from "../../store/ShopContext";
import { ProductsContext } from "../../store/ProductsContext";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { UPDATE_FILTERS } from "../../store/ShopReducers";
import useDebounce from "../../shop/libs/useDebounce";

const Float = ({ field, index }) => {

    const [localValueTo, setLocalValueTo] = useState('');
    const [localValueFrom, setLocalValueFrom] = useState('');
    const [minValue, setMinValue] = useState();
    const [maxValue, setMaxValue] = useState();
    const { filters, dispatchFilters } = useContext(ShopContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { dispatchLayout } = useContext(LayoutContext);
    const [value, setValue] = useState();

    useEffect(() => {

        let allValues = _.uniq(field.DistinctFilterValues.map(float => parseFloat(float.replace(',', '.'))))
        let maxValue = _.max(allValues)
        let minValue = _.min(allValues)

        setMinValue(minValue)
        setMaxValue(maxValue)

    }, [field])

    //debouce value
    const debouceValue = useDebounce(value, 500);

    useEffect(() => {
        //console.log(debouceValue)
        debouncedHandleChange(debouceValue)
    }, [debouceValue])

    const debouncedHandleChange = useCallback((newValue) => {
        // Your handleChange logic here
        //console.log(newValue)
        let clone = structuredClone(filters.productFilters)

        if (newValue) {
            if (newValue && !Array.isArray(newValue)) {
                newValue = [newValue, newValue]
            } else {
                if (newValue[0] && !newValue[1]) {
                    newValue = [newValue[0], newValue[0]] //if no tolerance set both to same
                } else if (!newValue[0] && newValue[1]) {
                    newValue = null //if no from value set then remove both
                } else {
                    newValue = [newValue[0], (parseInt(newValue[0]) + parseInt(newValue[1])).toString()]
                }
            }
        }
        let index = (clone).findIndex((item) => (item.i === field.FilterID));
        // console.log(index)
        // console.log(newValue)
        if (index > -1) {
            if (!newValue) {
                //remove
                clone.splice(index, 1)
            } else {
                clone[index].v = newValue //add value to cl
            }
        } else if (index === -1 && newValue) {
            //add if not already in selectedFilters
            clone.push({
                "i": field.FilterID,  //ID
                "t": field.FilterType, //Type
                "h": field.FilterHeader, //Header
                "u": field.FilterUnit, //Unit
                "v": newValue, //Value
                "s": true //Show
            })
        }
        dispatchFilters({
            type: UPDATE_FILTERS,
            payload: clone
        });
        dispatchProducts({
            type: UPDATE_PRODUCTS,
            payload: {
                offset: 0,
            }
        });
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1,
            }
        });
    }, [filters.productFilters])

    useEffect(() => {
        if (localValueTo || localValueFrom) {
            setValue([localValueFrom, localValueTo])
        } else {
            setValue()
        }
    }, [localValueTo, localValueFrom])


    const handleChangeFrom = (e) => {
        //console.log(e.target.value)
        setLocalValueFrom(e.target.value)
    }

    const handleChangeTo = (e) => {
        //console.log(e.target.value)
        setLocalValueTo(e.target.value)
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography sx={{ color: 'text.primary' }} >
                    <span style={{ textTransform: 'capitalize' }}>{field.FilterHeader}</span> {field.FilterUnit ? `(${field.FilterUnit})` : ''}
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs>
                    <TextField
                        autoComplete='off'
                        id="float-from"
                        label={''}
                        InputLabelProps={{ shrink: false }}
                        value={localValueFrom}
                        onChange={handleChangeFrom}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{
                            width: '100%',
                            '& input': { zIndex: 2 },
                            '& legend': { display: 'none' },
                            '& fieldset': { 
                                backgroundColor: theme => theme.palette.background.paper,
                                color: theme => theme.palette.text.primary,
                            }
                        }}
                        size="small"
                        placeholder={minValue ? `${minValue} - ${maxValue}${field.FilterUnit}` : null}

                    />
                </Grid>
                <Grid item xs={'auto'} sx={{ width: '20px', display: 'flex', textAlign: 'center' }} alignItems="center" >
                    <Typography sx={{ width: '100%', color: 'text.primary' }}>±</Typography>
                </Grid>
                <Grid item xs>
                    <TextField
                        autoComplete='off'
                        id="float-to"
                        label={''}
                        InputLabelProps={{ shrink: false }}
                        value={localValueTo}
                        onChange={handleChangeTo}
                        type="number"
                        inputProps={{
                            inputMode: 'decimal',
                            pattern: '[0-9]*',
                            min: 0,
                        }}
                        sx={{
                            width: '100%',
                            '& input': { zIndex: 2 },
                            '& legend': { display: 'none' },
                            '& fieldset': { 
                                backgroundColor: theme => theme.palette.background.paper,
                                color: theme => theme.palette.text.primary,
                            }
                        }}
                        size="small"
                        placeholder="tolerance"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Float;
