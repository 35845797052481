import { makeStyles } from '@mui/styles';

const tableChipStyles = makeStyles(theme => ({
  chip: {
    //width: theme.spacing(10),
    fontSize: '11px',
    width: '100px'
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    fontWeight: 600
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    fontWeight: 600
  },
  NaN: {
    backgroundColor: theme.palette.grey[400]
  },
  OOC: {
    color: theme.palette.error.main
  },
  whiteText: {
    color: theme.palette.common.white
  }
}));
export default tableChipStyles;
