
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../store/LayoutContext';

export default function useJumpback() {

    const { app } = useContext(LayoutContext);
    const [mainMenu, setMainMenu] = useState();
    let selectedArea = sessionStorage.getItem('selectedArea')

    //remove hidden items
    useEffect(() => {
        if (app.menu) {
            let visible = app.menu.filter((items) => !items.hidden)
            //order by order
            setMainMenu(visible.sort((a, b) => a?.order - b?.order))
        }
    }, [app.menu])

    useEffect(() => {
        if (mainMenu && selectedArea) {
            if (selectedArea === 'settings') {
                //ignore any settings pages
                return;
            }
            //get menu item
            let item = mainMenu.find(item => item.area === selectedArea);

            if(item){
                if (selectedArea === 'search'){
                    item = mainMenu.find(item => item?.area === 'home');
                }
                let jumpObject = JSON.parse(sessionStorage.getItem('jumpObject')) || [];
                //check if this item is already in the jumpObject
                let exists = jumpObject.find(jump => jump?.title === item?.title);
                //if exists do nothing
                if (exists) return;
                if (jumpObject && jumpObject.length === 3) {
                //remove first item
                    jumpObject.shift();
                }
                jumpObject.push({ 'title': item?.title, 'link': item?.link });
                sessionStorage.setItem('jumpObject', JSON.stringify(jumpObject));
            }
        }
    }, [mainMenu, selectedArea])

}