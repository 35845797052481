import React, { useEffect, useState } from 'react';
import EmailContainer from './EmailContainer';
import EmailHeader from './EmailHeader';
import config from '../config';
import EmailFooter from './EmailFooter';

const AttachmentEmail = ({ title, text, theme, logo }) => {

    const demo_order = {
        "title": "Service Report PDF",
    }

    return (
        <EmailContainer>
            <table style={{ width: '800px' }} cellSpacing="0" cellPadding="20" align="left">
                <EmailHeader logo={logo} theme={theme} />
                <tbody>
                    <tr>
                        <td colSpan="2" align="center" >
                            <h1 style={{ color: theme.palette.primary.main, marginBottom: 0 }}>PDF Attached.</h1>
                            <h4 style={{ marginTop: 10, marginBottom: 10 }}>{title ? title : demo_order }</h4>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <div style={{ display: "block", backgroundColor: '#ccc', width: '700px', height: '1px', margin: '0 auto' }} ></div>
                        </td>
                    </tr>
                     <tr>
                        <td valign="top" align="center" colSpan="2">
                            <p>{text}</p>
                        </td>
                    </tr> 
                    <EmailFooter theme={theme} />
                </tbody>
            </table>
        </EmailContainer >
    )
};

export default AttachmentEmail;  