import { TextField } from '@mui/material';
import React from 'react';
import { useFormikContext } from "formik"
import setLanguageText from '../../../../language/setLanguageText';

export default function Float({ field }) {

    const { values, touched , errors, handleChange} = useFormikContext();

    return (
        <TextField
            id={field?.name}
            label={setLanguageText(field?.label)}
            variant="outlined"
            fullWidth
            required={field?.required}
            value={values?.[field.name]}
            onChange={handleChange}
            error={touched?.[field?.name] && Boolean(errors?.[field?.name])}
            helperText={touched?.[field?.name] && errors?.[field?.name]}
            placeholder={setLanguageText(field?.label)}
        />
    );
};

