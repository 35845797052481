import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faMinus,
    faPlus,
    faCartShopping
} from '@fortawesome/pro-light-svg-icons';
import { Grid, Button, Snackbar, Alert, Slide, Skeleton, TextField, OutlinedInput, Typography, Autocomplete } from "@mui/material";
import { BasketContext } from "../store/BasketContext";
import { UPDATE_BASKET } from "../store/BasketReducers";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { UserContext } from "../store/UserContext";
import config from "../config";
import axiosRequest from '../axios/axoisRequest';
import { CompanyContext } from "../store/CompanyContext";
import { updateBasketTotals } from "../basket/helpers/updateBasketTotals";

const AddCadToBasket = ({ product}) => {

    const { basket, dispatchBasket } = useContext(BasketContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);


    const mutation = useMutation((basket) => {
        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'basket',
            api_key: user.api_key,
            method: 'patch',
            body: {
                "basket_id":  basket.basket_id,
                "basket_name":  basket.basket_name,
                "basket_company":selectedCompany.company_id,
                'basket_number' : basket.basket_number,
                'basket_items': basket,
            }

        })
    })

    function TransitionUp(props) {
        return <Slide {...props} direction="down" />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const addToBasket = (basket, product) => {
        setLoading(true)
        let currentItems = [...basket.items];
        //Check to see if product exisits in basket
        let index = currentItems.findIndex((item) => item.ID === product.ID);
        if (index > -1) {
            //update qyt
            currentItems[index].Quantity = parseInt(currentItems[index].Quantity) + parseInt(value);
        } else {

            product.Properties = [] //properties breaking basket due to 's in the label text
            //product.Graphics = []

            currentItems = [...currentItems, { ...product, "Quantity": value }]
        }
        //console.log(currentItems)

        sessionStorage.setItem("basketProduct", JSON.stringify(currentItems));
        //Set basket state
        dispatchBasket({
            type: UPDATE_BASKET,
            payload: {
                "items": currentItems,
            }
        });
        mutation.mutate(currentItems)
        //calculates all totals
        updateBasketTotals(currentItems, dispatchBasket);

        setTimeout(() => {
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setSuccess(false);
                setOpen(true);
            }, 1000)
        }, 1000)
    }

    return (
        <>
            <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography><strong>Price: 1 Credit</strong><small> per download</small></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        size="small"
                        disableClearable
                        disabled
                        //sx={{ width: 80 }}
                        //options={limitOptions}
                        //value={pageSize}
                        //onChange={handlePageSize}
                        autoHighlight
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'File Format'}
                                //InputLabelProps={{ shrink: false }}
                                placeholder="Please Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        color={!success ? "primary" : "success"}
                        onClick={(e) => addToBasket(basket, product)}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={!success ? <FontAwesomeIcon icon={faCartShopping} /> : <FontAwesomeIcon icon={faCheckCircle} />}
                        variant="contained"
                        disabled={true}
                        sx={{ height: '100%', width: '100%' }}
                    >
                        {!success ? 'Add to Cart' : 'Added'}
                    </LoadingButton>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={TransitionUp}
                open={open}
                autoHideDuration={1500}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {value} {product.Name} added to Cart
                </Alert>
            </Snackbar>
        </>

    );
};

export default AddCadToBasket;

