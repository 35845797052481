import React from 'react';
import PieChart from './PieChart';
import BarChart from './BarChart';
import { Grid } from '@mui/material';

export default function DashboardCharts({ chartData }) {

	return (
		<>
			{chartData ?
				<Grid
					item
					container
					spacing={2}
					sx={{ marginBottom: theme => theme.spacing(3) }}
					alignItems="stretch"
				>

					{chartData &&
						chartData.map(chart => {
							return (
								chart.chart_type === 'pie' ? (
									<Grid key={chart.id} item xs={12} sm={6} md={4} lg={3}>
										<PieChart
											results={chart}
										/>
									</Grid>
								) : chart.chart_type === 'bar' ? (
									<Grid key={chart.id} item container xs={12} sm={6} md={4} lg={3}>
										<BarChart
											results={chart}
										/>
									</Grid>
								) : null
							);
						})}
				</Grid>
				: null}
		</>
	);
}

