import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemIcon, MenuItem, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFileLines, faFilePdf, faMessage, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import OrderConfirmationEmail from "../../../emails/OrderConfirmationEmail";
import { useTheme } from "@emotion/react";
import { LayoutContext } from "../../../store/LayoutContext";
import GlobalDialog from "../../../reusable/GlobalDialog";


const ViewOrder = ({ order, handleClose }) => {

    const { app, theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme();
    const [open, setOpen] = useState(false)

    const handleCloseDialog = () => {
        setOpen(false);
        handleClose();
    }

    return (
        <>
            <MenuItem onClick={() => setOpen(true)} >
                <ListItemIcon>
                    <FontAwesomeIcon icon={faEnvelope} />
                </ListItemIcon>
                View Confirmation
            </MenuItem>
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={handleCloseDialog}
                    title=" View Order"
                    //buttonClick={handleSubmit}
                    //buttonTitle="Send Request"
                    closeText="Close"
                    maxWidth="md"
                >
                    <OrderConfirmationEmail order={order} logo={layoutTheme.email_logo} theme={theme} />
                    {/* {loading || send_email.isLoading || send_email.isSuccess || send_email.isError ?
                    <DialogLoader
                        isLoading={loading || send_email.isLoading}
                        mutation={send_email}
                        loadingMessage="Sending Request..."
                        successMessage="Request Sent"
                        closeDialog={handleCloseDialog}
                        fixed
                    />
                    : null} */}
                </GlobalDialog>
                : null}
        </>
    );
};

export default ViewOrder;