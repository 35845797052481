import { Grid, Typography } from "@mui/material"
import React from "react"

const InputWrap = ({name, children}) => {
  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <Typography fontWeight="600">{name}</Typography>
      </Grid>
      <Grid item xs={12} >
         {children}
      </Grid>
    </Grid>
  )
}

export default InputWrap
