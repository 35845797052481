import React, { useContext } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import TabHeader from '../../../../layout/TabHeader';
import TabContent from '../../../../layout/TabContent';
import setLanguageText from '../../../../language/setLanguageText';
import { CompanyContext } from '../../../../store/CompanyContext';
import { UserContext } from '../../../../store/UserContext';
import { useMutation } from 'react-query';
import config from '../../../../config';

import GetTemplate from '../../../../templates/GetTemplates';
import { SAVE_COMPANY_SETTINGS, SELECTED_COMPANY } from '../../../../store/CompanyReducers';
import axiosRequest from '../../../../axios/axoisRequest';
import useActivityLog from '../../../../users/activity/useActivityLog';

const EngineerSuppliers = () => {

    const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const { updateActivityLog } = useActivityLog();

    //gets companies full details
    const refectch_company = useMutation((params) => {
        //console.log(user)
        return axiosRequest({
            method: 'get',
            endpoint: 'companies',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            params: {
                id: selectedCompany.company_id
            }
        })
    }, {
        onSuccess: (data) => {
            dispatchSelectedCompany({
                type: SELECTED_COMPANY,
                payload: data[0]
            });
            window.sessionStorage.setItem('selectedCompany', data[0].company_id);
        }
    })

    const save_brand_order = useMutation(values => {
        console.log(values)
        return axiosRequest({
            endpoint: 'company/settings',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            method: 'patch',
            body: {
                company_id: selectedCompany.company_id,
                company_supplier_order: values
            }
        })
    }, {
        onSuccess: (data, context) => {
            updateActivityLog.mutate({
                activity_details: {
                    area: "settings",
                    machine_id: context.company_id,
                },
                activity_type: "company settings updated"
            })
            save_brand_order.reset();
            refectch_company.mutate();
        }
    })

    const save_template_id = useMutation(id => {

        //make sure compnay setting are available
        if (!selectedCompany.company_settings) {
            return false
        }
        const request = {
            ...selectedCompany.company_settings,
            "engineer_template": {
                "id": id
            }
        }

        //console.log(request)
        return axiosRequest({
            endpoint: 'company/settings',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            method: 'patch',
            body: {
                company_id: selectedCompany.company_id,
                company_settings: request
            }
        })
    }, {
        onSuccess: (data, context) => {
            const request = {
                ...selectedCompany.company_settings,
                "engineer_template": {
                    "id": context
                }
            }
            dispatchSelectedCompany({
                type: SAVE_COMPANY_SETTINGS,
                payload: {
                    "company_settings": request
                }
            });
        }
    })


    const setTemplate = (template) => {
        //console.log(template)
        save_template_id.mutate(template.id)
        save_brand_order.mutate(template.template)
    }

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        padding={1}
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Engineer Brand Visibility")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="end"
                >
                    <GetTemplate type={["engineer_brands"]} setTemplate={setTemplate} buttonText="Update Engineer Template" showCard />
                </Grid>
            </TabContent >
        </>
    );
};

export default EngineerSuppliers;


