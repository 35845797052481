import React, { useContext, useEffect, useState } from "react";
import { Avatar, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Dialog, TextField, Typography, DialogTitle, Tooltip } from '@mui/material';
import { faImageSlash, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductPopup from "../cim/ProductPopup";

export default function AccessoriesList({ product, isFetching }) {

  const [photo, setPhoto] = useState()
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (product.Image) {
      setPhoto(product.Image)
    }
  }, [product])

  return (
    <>
      <ListItem
        divider
      >
        <ListItemAvatar>
          <Avatar
            //onClick={handleOpen}
            sx={{ background: '#fff', cursor: 'pointer', boxShadow: '0 0 6px 2px #404040' }}>
            {photo ?
              <img
                src={photo}
                onError={e => {
                  e.target.src = placeholder;
                }}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              : <FontAwesomeIcon color="#222" icon={faImageSlash} />
            }

          </Avatar>
        </ListItemAvatar>

        <ListItemText
          sx={{
            '& p': {
              color: theme => theme.palette.text.primary
            }
          }}
          disableTypography
          primary={<Typography fontWeight={600} component="p" variant="body1" >{product.Name}</Typography>}
          secondary={
            <>
              <Typography component="p" variant="caption" ><strong>Type:  </strong>
                {
                  product.Type === 1 ? 'Standard spare part' :
                    product.Type === 2 ? 'Standard accessory' :
                      product.Type === 3 ? 'Optional spare part' :
                        product.Type === 1 ? 'Optional accessory' : ''
                }
              </Typography>
              {product.T61 && <Typography component="p" variant="caption"><strong>Wrench size: </strong> {product.T61}</Typography>}
              {product.T62 && <Typography component="p" variant="caption"><strong>Thread nominal size: </strong> {product.T62}</Typography>}
              {product.T63 && <Typography component="p" variant="caption"><strong>Tightening torque: </strong> {product.T63}</Typography>}
              {product.T2 && <Typography component="p" variant="caption"><strong>Position: </strong> {product.T2}</Typography>}
            </>
          }
        />
      </ListItem>
      {open && product &&
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose}

        >
          <DialogTitle>
            <FontAwesomeIcon
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              icon={faTimes}
            />
          </DialogTitle>
          <ProductPopup productDetails={product} closeDialog={handleClose} />
        </Dialog>
      }
    </>
  )
}
