
import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";

const BrandLogo = ({ product }) => {

    return (
        <>
            {product?.product_brand_url ?
                <img
                    alt={product?.product_manufacturer}
                    src={product?.product_brand_url}
                    style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0 auto' }}
                />
                : 
                    <Typography variant="caption" sx={{ padding: '5px' }}>
                        {product?.product_manufacturer}
                    </Typography>
   
            }
        </>
    );

};

export default BrandLogo