import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import { TextField } from '@mui/material';
import {
    INIT_ENTRY,
    SAVE_COMPANY,
    SAVE_MACHINE,
} from '../../store/SelectionsReducers';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../../language/setLanguageText';
import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { MachineContext } from '../../store/MachineContext';

export default function CompanySelect({ setError }) {

    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const { selectedMachine } = useContext(MachineContext);
    const { companies, selectedCompany } = useContext(CompanyContext);

    const { user } = useContext(UserContext);
    const [localCompanies, setLocalCompanies] = useState([]);
    const [localMachines, setLocalMachines] = useState([]);

    //get machines
    const getMachines = useMutation({
        mutationFn: (params) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'get',
            params: params
        }),
        onSuccess: (response) => {
            setLocalMachines(response);
            //check if select machine has been set
            console.log(response)
            console.log(selectedMachine)
            if (selectedMachine?.machine_id) {

                let find = response.find((machine) => machine.machine_id === selectedMachine.machine_id);
                if (find) {
                    dispatchAddNewEntry({
                        type: SAVE_MACHINE,
                        payload: find
                    });
                } else {
                    dispatchAddNewEntry({
                        type: SAVE_MACHINE,
                        payload: response[0] //set first machine as default
                    });
                }
            }
        }
    })

    //gets companies full details
    const getCompanyDetails = useMutation({
        mutationFn: (params) => axiosRequest({
            method: 'get',
            endpoint: 'companies',
            gateway: config.coolantAPI.URL,
            api_key: user.api_key,
            params: params
        }),
        onSuccess: (response, variables) => {
            if (!response.errorMessage) {
                //save new entry data
                dispatchAddNewEntry({
                    type: SAVE_COMPANY,
                    payload: response[0]
                });
                //get the machines for this company
                getMachines.mutate({
                    company_id: variables?.id,
                    allow_access: true
                })
            }
        }
    })

    //set errors
    useEffect(() => {
        if (addNewEntry.machine && Object.keys(addNewEntry.machine).length) {
            if (addNewEntry.machine.coolant_id) {
                setError({
                    attr_id: "machine",
                    type: "none",
                });
            } else {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "Machine doesn't have a coolant"
                })
            }
        } else {
            if (Object.keys(localMachines).length) {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "Please select a machine"
                })
            } else {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "No machines"
                })
            }
        }
    }, [addNewEntry, localMachines, setError]);

    //create a entry based on selected machine
    useEffect(() => {
        //console.log(addNewEntry)
        if (addNewEntry?.machine?.machine_id && addNewEntry?.company?.company_attributes) {
            //create empty object
            let defaultEntry = addNewEntry.company.company_attributes.map((attr) => {
                return {
                    attribute_id: attr.one.attr_id,
                    attribute_values: {

                    }
                }
            });
            dispatchAddNewEntry({
                type: INIT_ENTRY,
                payload: {
                    attributes_new: defaultEntry,
                    date_stamp: new Date().toISOString(),
                    user_id: user.user.user_id,
                    machine_id: addNewEntry.machine.machine_id,
                    coolant_id: addNewEntry.machine.coolant_id,
                    factored: addNewEntry.machine.machine_settings.factored ? addNewEntry.machine.machine_settings.factored : false,
                    factor: addNewEntry.machine.machine_settings.machine_defaults.factor || addNewEntry.machine.machine_settings.machine_defaults.refractomer_factor
                }
            });
        }
    }, [addNewEntry.company, addNewEntry.machine, dispatchAddNewEntry, user.id]);

    //set options from gloabl compnay select
    useEffect(() => {
        if (companies.length) {
            setLocalCompanies(companies);
        }
    }, [companies]);

    // if a company has been selected then set default on first load
    useEffect(() => {
        if (selectedCompany.company_id) {
            //console.log(selectedCompany)
            dispatchAddNewEntry({
                type: SAVE_COMPANY,
                payload: selectedCompany
            });
            getMachines.mutate({
                company_id: selectedCompany?.company_id,
                allow_access: true
            })

        }
    }, []);

    // save company as new entry
    const onSelectCompany = (company) => {
        getCompanyDetails.mutate({
            id: company.company_id,
            company_name: company.company_name
        });
    };

    //save machine as new entry
    const onSelectMachine = (machine) => {
        dispatchAddNewEntry({
            type: SAVE_MACHINE,
            payload: machine
        });
    };
    return (
        <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
                {localCompanies ?
                    <Autocomplete
                        disableClearable
                        id="companies"
                        options={localCompanies || []}
                        getOptionLabel={option => option.company_name || ''}
                        isOptionEqualToValue={(option, value) => option.company_id === value.company_id}
                        renderOption={(props, option) => (
                            <Box component="li"  {...props} key={option.company_id}>
                                {option.company_name}
                            </Box>
                        )}
                        renderInput={params => (
                            <TextField
                                required
                                {...params}
                                //label={setLanguageText("Select Company")}
                                sx={{
                                    '& input': { zIndex: 2 },
                                    '& legend': { display: 'none' },
                                    '& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
                                    '& .MuiAutocomplete-endAdornment ': {
                                        zIndex: 2
                                    }
                                }}
                                placeholder={setLanguageText("Select Company")}
                            />
                        )}
                        value={addNewEntry.company ? addNewEntry.company : null}
                        onChange={(e, newValue) => onSelectCompany(newValue)}
                    />
                    : null}
            </Grid>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    disableClearable
                    id="machines"
                    options={localMachines || []}
                    disabled={!localMachines}
                    getOptionLabel={option => option.machine_name || ''}
                    isOptionEqualToValue={(option, value) => option.machine_id === value.machine_id}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.machine_id}>
                            {option.machine_name}
                        </Box>
                    )}
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                            //label={setLanguageText("Select Machine")}
                            variant="outlined"
                            sx={{
                                '& input': { zIndex: 2 },
                                '& legend': { display: 'none' },
                                '& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
                                '& .MuiAutocomplete-endAdornment ': {
                                    zIndex: 2
                                }
                            }}
                            placeholder={setLanguageText("Select Machine")}
                        />
                    )}
                    value={addNewEntry.machine ? addNewEntry.machine : null}
                    onChange={(e, newValue) => onSelectMachine(newValue)}
                />
            </Grid>
        </Grid>
    );
};
