import { useTheme } from '@mui/styles';
import { useContext, useRef, useMemo, useState, useCallback } from 'react';
import formatDate from '../helpers/formatDate';
import { ChartsContext } from '../store/ChartsContext';
import { SAVE_CHARTS } from '../store/ChartsReducers';

const useBacteriaChart = ({ plots, tooltips }) => {
	const [labels, setLabels] = useState();
	const [bacteria, setBacteria] = useState();
	const [bacteriaMax, setBacteriaMax] = useState();
	const [bacteriaMedium, setBacteriaMedium] = useState();
	// const [plots, setPlots] = useState(true);
	const colour = useTheme().palette.error.main;
	const { dispatchCharts } = useContext(ChartsContext);
	const chartRef = useRef(null);
	const [base64Image, setBase64Image] = useState(null);

	const createBacteriaChart = useCallback(entries => {
		let labelsObject = [];
		let bacteriaObject = [];
		// let bacteriaMaxObject = []
		entries.forEach((entry, i) => {
			let data = formatDate(entry.original.date_stamp).slice(0, -5);
			labelsObject.push(data);
			//console.log(entry);
			const details = entry.original.attributes_new.find(
				attr => attr.attribute_id === 'bacteria'
			);
			//console.log(details);
			if (details) {
				//console.log(details)
				//remove pending and N/A results
				if (
					details.attribute_values.status === -10 ||
					details.attribute_values.status === -20
				) {
					bacteriaObject.push(null);
				} else {
					bacteriaObject.push(details.attribute_values.value);
				}
			}
		});
		setBacteriaMax(entries.map(entry => 4.5));
		setBacteriaMedium(entries.map(entry => 3.5));
		setBacteria(bacteriaObject);
		setLabels(labelsObject);
	}, []);

	const data = useMemo(() => {
		return {
			labels: labels,
			datasets: [
				{
					data: bacteria,
					label: 'Bacteria',
					fill: false,
					pointRadius: plots ? 4 : 0,
					lineTension: 0.3,
					pointBackgroundColor: colour,
					pointHoverRadius: 6,
					borderColor: colour,
					borderWidth: 2,
					spanGaps: true,
					datalabels: tooltips
						? {
							align: 'center',
							anchor: 'center'
						}
						: null
				},
				{
					data: bacteriaMedium,
					label: 'Low',
					backgroundColor: 'rgb(0, 215, 155, 0.100)',
					borderColor: 'transparent',
					fill: 'origin',
					lineTension: 0,
					pointRadius: 0
				},
				{
					data: bacteriaMedium,
					label: 'Medium',
					backgroundColor: 'rgb(255, 197,0, 0.100)',
					borderColor: 'transparent',
					fill: 3,
					lineTension: 0,
					pointRadius: 0
				},
				{
					data: bacteriaMax,
					label: 'High',
					backgroundColor: 'rgba(252, 70, 112, 0.100)',
					borderColor: 'transparent',
					lineTension: 0,
					pointRadius: 0,
					fill: 'end'
				}
			]
		};
	}, [labels, bacteria, plots, colour, tooltips, bacteriaMedium, bacteriaMax]);

	const options = useMemo(() => {
		return {
			elements: {
				point: {
					radius: 4,
					pointHitRadius: 0,
					hoverRadius: 0,
					pointHoverBorderWidth: 0,
					pointHoverRadius: 0
				}
			},
			plugins: {
				datalabels: {
					backgroundColor: colour,
					borderRadius: 4,
					color: 'white',
					font: {
						weight: 'bold'
					},
					display: context => (context.dataset.datalabels ? true : false),
					padding: 4
				},
				title: {
					display: false,
					text: 'Bacteria levels',
					fontSize: 20
				},
				legend: { display: false },
			},
			bezierCurve: false,
			scales: {
				y:
				{
					display: true,
					ticks: {
						stepSize: 1,
						suggestedMax: bacteriaMax && bacteriaMax[0] + 2,
						callback: function (value) {
							//console.log(value)
							if (value === -2) {
								return 'N/A';
							} else if (value === -1) {
								return 'Pending';
							} else if (value === 0) {
								return 'Clear';
							} else if (value > 0) {
								return `10 ^ ${value}`;
							}
						}
					}
				}

			},
			animation: {
				onComplete: () => {

					let b64 = chartRef?.current?.toBase64Image();
					if (b64) {
						setBase64Image(b64);
						dispatchCharts({
							type: SAVE_CHARTS,
							payload: {
								bacteria_base64: b64
							}
						});
					}
				}
			}
		};
	}, [bacteriaMax, colour, dispatchCharts, chartRef]);

	return {
		createBacteriaChart,
		bacteriaData: data,
		bacteriaOptions: options,
		bacteriaBase64: base64Image,
		bacteriaChartRef: chartRef
	};
};

export default useBacteriaChart;
