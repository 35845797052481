import React, { useContext, useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, Fade, Grid, Skeleton } from '@mui/material';
import AddToBasket from "../../basket/AddToBasket";
import { useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { faChevronRight, faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductDetails from "./../cim/ProductDetails";
import { useNavigate } from "react-router-dom";
import ProductSlider from "../../reusable/ProductSlider";
import { isMobileOnly } from "react-device-detect";
import SingleImage from "../../shop/reusable/SingleImage";
import ProductTabs from "./../cim/ProductTabs";
import createProductObject from "../../helpers/createProductObject";
import { LayoutContext } from "../../store/LayoutContext";
import HTMLReactParser from "html-react-parser";
import ExportSingleProduct from "./../shared/ExportSingleProduct";
import AddToFavourites from "../../favourites/AddToFavourites"; 


const ProductGroupFull = ({ productDetails, closeDialog }) => {

    const { user } = useContext(UserContext);
    const { app } = useContext(LayoutContext);
    const [variants, setVariants] = useState();
    const navigate = useNavigate();
    const [erpDetails, setErpDetails] = useState();
    const [selectedProduct, setSelectedProduct] = useState(productDetails);
    const [selectedFullProduct, setSelectedFullProduct] = useState();

    const { data, isFetching } = useQuery(['group', productDetails?.product_group],
        ({ signal }) => axiosRequest({
            signal: signal,
            name: 'get product variants',
            method: 'get',
            endpoint: 'products/group',
            gateway: config.ezbaseAPI.URL,
            api_key: user.api_key,
            params: {
                groupID: productDetails.product_group
            }
        }), {
        enabled: !!productDetails?.product_group,
        refetchOnWindowFocus: false, //todo set globally
        retry: 1
    })



    useEffect(() => {

        //update to new product object
        const createProduct = async (data, productDetails) => {
            if (productDetails?.product_dataset === "ezbase" && productDetails?.product_attributes) {
                //loop through variants and add attributes headers
                const variants = await structuredClone(data);

                //console.log(variants)
                const promises = variants.map(async (product) => {
                    //create product object
                    let productObject = await createProductObject([{ ...product, area: app.selectedArea, "product_dataset": productDetails.product_dataset }]);
                    //let productObject = await createProductObject([{ ...product, area: "buyers" }]);

                    //console.log(productObject)
                    //add table headers
                    productObject[0].product_attributes.map((attribute) => {
                        let header = productDetails?.product_attributes.find((header) => header.id === attribute.id)
                        //console.log(header)
                        attribute.name = header?.name ? header.name : null
                        //console.log(attribute)
                        return attribute
                    })
                    return productObject[0];
                });
                let all = await Promise.all(promises);
                //create product object for all variants
                setVariants(all)

                //merge product details with selected to get attributes
                let found = data.find((product) => product.ArticleID === productDetails.product_id)
                //console.log(found)
                if (found?.TableProperties) {
                    let selected = found?.TableProperties
                    //check if it is an object or a string
                    if (typeof selected === "string") {
                        selected = JSON.parse(selected)
                    }
                    // add attribute values to product object
                    productDetails?.product_attributes.map((attribute) => {
                        let value = selected.find((header) => header.id === attribute.id)
                        attribute.value = value?.value
                        return attribute
                    })
                    setSelectedFullProduct(productDetails)
                }
            }
        }
        if (data && selectedProduct) {
            //console.log(data)
            createProduct(data, selectedProduct)
        }
    }, [data, selectedProduct])


    return (

        <Grid item container  >
            <Grid item xs={12} md={6} container alignContent="center" sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 16,
                        left: 16,
                        zIndex: 3,
                    }}
                >
                    <AddToFavourites product={productDetails} isFetching={isFetching} big />
                </Box>
                <Fade in={true}>
                    <Grid item xs={12}
                        sx={{
                            padding: isMobileOnly ? '1em' : '2em',
                            background: '#fff'
                        }}>
                        <Box sx={{
                            position: 'absolute',
                            width: '200px',
                            height: '50px',
                            overflow: 'hidden',
                            top: 0,
                            right: 0,
                            borderBottom: '1px solid #eee',
                            borderLeft: '1px solid #eee',
                            borderRight: '1px solid #eee',
                            padding: '5px',
                            background: '#fff6',
                            zIndex: 2
                        }}>
                            {productDetails?.product_brand ?
                                <img
                                    src={productDetails?.product_brand?.url}
                                    alt={productDetails?.product_brand?.description}
                                    onError={e => {
                                    }}
                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                />
                                : null}
                        </Box>

                        {selectedProduct?.product_image.length > 1 ?
                            <ProductSlider images={selectedProduct.product_image} url="url" descrition="TYPE" />
                            : selectedProduct?.product_image.length === 1 ?
                                <SingleImage imageUrl={selectedProduct.product_image[0].url} alt={selectedProduct.product_name} icon="6x" padding="1em" />
                                : selectedProduct?.product_image.length === 0 ?
                                    <Box sx={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        zIndex: 2,
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'grid',
                                        top: 0
                                    }}>
                                        <FontAwesomeIcon icon={faImageSlash} color="#ccc" size="6x" />
                                    </Box>
                                    : <Skeleton variant="rectangular" width="100%" height="100%" />
                        }
                    </Grid>
                </Fade>
            </Grid>
            <Grid container item xs={12} md={6} sx={{ padding: isMobileOnly ? '1em' : '2em' }} alignItems="space-between">
                <Grid container item xs={12} spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12}>
                        {selectedProduct ?
                            <>
                                <Typography color="text.primary" variant="h5" marginBottom="10px" marginTop="5px">{selectedProduct.product_name}</Typography>
                            </>
                            :
                            <Skeleton width="100%" height={80} />
                        }
                        <ProductDetails product={selectedProduct} isFetching={false} erpDetails={erpDetails} />
                        {selectedProduct?.product_html ?
                            <Grid item xs={12}
                                sx={{
                                    maxHeight: '30vh',
                                    overflow: 'auto',
                                    fontSize: '14px',
                                    '& p ': {
                                        fontSize: '14px'
                                    }
                                }}
                            >
                                <Divider variant="middle" sx={{ margin: '1em auto' }} />
                                {HTMLReactParser(selectedProduct.product_html)}
                            </Grid>
                            : null}
                    </Grid>
                    <Grid item xs={12}>
                        {selectedProduct ?
                            <>
                                <AddToBasket
                                    //isFetching={isFetching}
                                    product={selectedProduct}
                                    large={true}
                                    area="buyers"
                                    erpDetails={erpDetails}
                                    setErpDetails={setErpDetails}
                                />
                                <Divider variant="middle" sx={{ margin: '1em auto' }} />
                            </>
                            :
                            <Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
                        }
                    </Grid>
                </Grid>

                <Grid item container spacing={1} xs={12} textAlign="right" justifyContent="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={e => {

                                let url = `/${app.selectedArea}/${app.selectedMenu}/product/${selectedProduct.product_id}?product_tab=1`;

                                if (app.selectedMenu === "account") {
                                    if (selectedProduct.product_dataset === "ezbase") {
                                        url = `/buyers/catalogue/product/${selectedProduct.product_group}?product_tab=1`;
                                    } else if (selectedProduct.product_dataset === "cim") {
                                        url = `/engineers/catalogue/product/${selectedProduct.product_id}?product_tab=1`;
                                    }
                                }
                                navigate(url)
                            }}
                            endIcon={
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    color={'primary.contrastText'}
                                />
                            }
                        >
                            Full Details
                        </Button>
                    </Grid>
                    <Grid item>
                        <ExportSingleProduct product={selectedProduct} erpDetails={erpDetails} />
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', background: (theme) => theme.palette.cards.header }}>
                <ProductTabs isFetching={isFetching} variants={variants} closeDialog={closeDialog} selectedProduct={selectedFullProduct} setSelectedProduct={setSelectedProduct} />
            </Box>
        </Grid>

    );
};
export default ProductGroupFull;