import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../store/ShopContext";
import axiosRequest from '../../axios/axoisRequest';
import { useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { Autocomplete, TextField } from "@mui/material";
import { SAVE_SHOP_CATEGORIES, UPDATE_FILTERS } from "../../store/ShopReducers";
import { ProductsContext } from "../../store/ProductsContext";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";

const BuyerSubCategoryDropdown = () => {

    const { shop, brands, dispatchShop, dispatchFilters} = useContext(ShopContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { dispatchLayout } = useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const [subcategories, setSubcategories] = useState();
    const [selected, setSelected] = useState(null);

    const { data , isFetching} = useQuery(['subcategories'],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.ezbaseAPI.URL,
            endpoint: 'categories',
            api_key: user.api_key,
            method: 'get',
            params: {
                'categoryID': shop.currentProductCat.ID
            }
        }), {
        retry: 1,
        enabled: !!shop?.currentProductCat?.ID
    })

    useEffect(() => {
        if (data && Object.values(data).length) {
            if (brands?.currentBrands) {
                let updated = []
                data.forEach((category) => {
                    let brandCount = 0
                    let bcount = category.bcount
                    brands.currentBrands.forEach(selected => {
                        let found = bcount.find((brand) => brand.b === selected.Brand)
                        if (found) {
                            brandCount = brandCount + found.c
                        }
                    });
                    if (brandCount > 0) {
                        updated.push({
                            ...category,
                            "count": brandCount
                        })
                    }
                })
                setSubcategories(updated)
            }
        }
    }, [data, brands])

    const updateCurrentCategory = (event, newValue) => {
        setSelected(newValue);
        if (newValue) {
            dispatchShop({
                type: SAVE_SHOP_CATEGORIES,
                payload: {
                    productSubCat: newValue
                }
            });
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    offset: 0,
                    count: newValue.count,
                    groupCount: null
                }
            });
        } else {
            dispatchShop({
                type: SAVE_SHOP_CATEGORIES,
                payload: {
                    productSubCat: []
                }
            });
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    offset: 0,
                    count: shop.currentProductCat.count,
                    groupCount: null
                }
            });
        }
        dispatchFilters({
            type: UPDATE_FILTERS,
            payload: []
        });
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1,
            }
        });
    };

    return (
        <>

            <Autocomplete
                //disableClearable
                id="selected-machine"
                value={selected}
                options={subcategories || []}
                getOptionLabel={option => `${option.cName} (${option.count})`}
                isOptionEqualToValue={(option, value) =>
                    option.ID === value.ID
                }
                onChange={(event, newValue) => updateCurrentCategory(event, newValue)}
                renderInput={params => (
                    <TextField {...params} variant="outlined" label="Sub Category" />
                )}
                sx={{}}
                loading={isFetching || !subcategories ? true : false}
                disabled={isFetching || !subcategories ? true : false}
                loadingText="Updating Brands"
            />

        </>
    )
};

export default BuyerSubCategoryDropdown;
