import React, { useState, useContext } from 'react';
import { Storage } from 'aws-amplify';
import { UserContext } from '../../store/UserContext';
import ProductForm from './form/ProductForm';
import { useMutation } from "@tanstack/react-query";
import config from '../../config';
import { Formik } from 'formik';
import { Button, Grid, TextField, Autocomplete } from '@mui/material';
import * as Yup from "yup"
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import axiosRequest from '../../axios/axoisRequest';
import useActivityLog from '../../users/activity/useActivityLog';
import DialogLoader from '../../reusable/DialogLoader';

export default function AddProducts({ types }) {

	const { user } = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [fields, setFields] = useState();
	const [selectedType, setSelectedType] = useState(null);
	const [loading, setLoading] = useState(false);
	const { updateActivityLog } = useActivityLog();


	const saveProduct = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'post',
			body: body
		}),
		onSuccess: (response, variables) => {

			if (response.insertId) {
				//console.log(response.insertId)
				//console.log(variables)
				//loop through uploads and upload to s3
				variables.product_uploads.forEach(async (upload) => {
					console.log(upload)
					const path = `global_coolants/${response.insertId}_${upload.language}_${upload.name}.pdf`;
					const type = upload.type === "pdf" ? "application/pdf" : "";
					//upload to s3
					const s3Upload = await Storage.put(path, upload.file, {
						contentType: type
					});
					//console.log(s3Upload)
				})
				updateActivityLog.mutate({
					activity_details: {
						area: "coolant",
						product_id: response.insertId,
						variables
					},
					activity_type: "new product"
				})
				setLoading(false)
			}

		}
	})

	//create new product
	const addProduct = (values) => {

		//console.log('add product')
		//setLoading(true)
		let productObject = {
			'product_name': values.product_name,
			//'product_description': values.product_description,
			'product_company': 0, // no compnay set at this stage
			'brand_id': values.product_brand.brand_id,
			'product_type': selectedType.type_id,
			"product_uploads": [],
			"product_defaults": {
				"defaults": {},
				"variants": [],
				"description": values.product_description,
			}
		};
		//console.log(selectedType.type_template)
		selectedType.type_template.forEach((field) => {
			if (field.type !== "file") {
				if(field.name === "type"){ //additive type
					productObject.product_defaults.defaults[field.name] = values?.[field.name].path ? values[field.name].path : ''
				} else {
					//find matching field from values
					productObject.product_defaults.defaults[field.name] = values?.[field.name] ? values[field.name] : ''
				}
			}
		})
		if (values.uploads) {
			//console.log(values.uploads)
			Object.values(values.uploads).forEach((upload) => {
				// //remove file from object
				// delete upload?.file
				productObject.product_uploads.push(upload)
			})
		}
		//console.log(productObject)
		saveProduct.mutate(productObject)

	};

	const validation = Yup.object({
		product_name: Yup.string().required('Product name required'),
		product_brand: Yup.object().required('Brand required')
	});

	const closeAddDialog = () => {
		setOpen(false);
	}

	//console.log('fields', fields)

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					setOpen(true)
					console.log('open')
				}}
			>
				Add Product
			</Button>

			<Formik
				//initialValues={fields}
				validationSchema={validation}
				onSubmit={(values, { setSubmitting }) => {
					console.log(values)
					addProduct(values)
				}}
			>
				{({ submitForm, setValues }) =>

					<GlobalDialog
						open={open}
						onClose={closeAddDialog}
						title={'Add Product'}
						buttonTitle={'Add Product'}
						buttonClick={submitForm}
						maxWidth="md"
						fullWidth
					>
						<Grid container spacing={2} >
							<Grid item xs={12} >
								<Autocomplete
									fullWidth
									disableClearable
									id="tags-outlined"
									options={types}
									getOptionLabel={option => option.type_name || ''}
									renderInput={params => (
										<TextField
											{...params}
											variant="outlined"
											//label={setLanguageText("Select product type")}
											sx={{
												'& input': { zIndex: 2 },
												'& legend': { display: 'none' },
												'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
												'& .MuiAutocomplete-endAdornment ': {
													zIndex: 2
												}
											}}
											placeholder={setLanguageText("Select Product Type")}
										/>
									)}
									onChange={
										(e, newValue) => {

											console.log(newValue)
											let clone = structuredClone(newValue.type_template);
											clone = clone.map(field => {
												//dn't save any files fields
												if (field.type !== "file") {
													return {
														[field.name]: ''
													}
												}
											})

											setFields(newValue.type_template)
											//merge arrays
											setSelectedType(newValue)
											clone = Object.assign({
												"product_name": "",
												"product_brand": "",
												"product_description": ""
											}, ...clone)
											console.log(clone)

											setValues(clone)

										}}
									value={selectedType}
								/>
							</Grid>

							{fields ?
								<ProductForm form={fields} />
								: null}

						</Grid>
						{loading || saveProduct.isLoading || saveProduct.isSuccess || saveProduct.isError ?
							<DialogLoader
								loading={loading}
								mutation={saveProduct}
								loadingMessage="Adding Product"
								successMessage="Product Added"
								closeDialog={closeAddDialog}
								fixed
							/>
							: null}
					</GlobalDialog>
				}
			</Formik>
		</>
	);
};
