//import React from "react";
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
//import config from "../config";

const useAccessToken = () => {
    // console.log('get current credentials')
    // Auth.currentSession().then( async (credentials) => {
    //     console.log(credentials)
    // })
    Auth.currentCredentials().then( async (credentials) => {
        //console.log(credentials) 
        //add check for expiration
        if(credentials.expiration){
            return AWS.config.update({
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            })
        } else {
            //refresh session
        }
    })
};

export default useAccessToken;

