import { faChevronDown, faChevronUp, faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, Fab, Grid, Avatar, useMediaQuery, useTheme, Card, CardActions, CardContent, CardHeader, Collapse, Skeleton, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
//import InputTypes from "./inputs/InputTypes";
import axiosRequest from "../../axios/axoisRequest";
import { CategoryContext } from "../../store/CategoryContext";
import { SelectedContext } from "../../store/SelectedContext";
import { RESET_ALL_FILTERS, UPDATE_URL_FILTERS } from "../../store/SelectedReducers";
import useDebounce from "../libs/useDebounce";
import { UserContext } from "../../store/UserContext";
import Image from "../reusable/Image";
import InputTypes from "../parametric/inputs/InputTypes";
import MobileDrawWrapper from "../../reusable/MobileDrawWrapper";


const Filters = () => {

    const [fields, setFields] = useState();
    const [firstLoad, setFirstload] = useState(true);
    const [extendedFields, setExtendedFields] = useState(false);
    const [open, setOpen] = useState(false);
    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext);
    const { selected, dispatchSelected } = useContext(SelectedContext);

    //--------------------------- API CALLS  ------------------------------//

    const { data, isFetching } = useQuery(['catID', categories.currentCat.ID],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: categories.currentCat.SearchProfile
            }
        }), {
        enabled: !!categories.currentCat.ID,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })


    //--------------------------- SET FORM  ------------------------------//

    useEffect(() => {
        if (data && !isFetching) {
            //console.log('set forms')
            let fields = [], extended = [], checked = []
            data.Fields.forEach(item => {
                if (item.Position === 0) {
                    fields.push(item)
                } else if (item.Position === 1) {
                    extended.push(item)
                } else if (item.Position === 2) {
                    checked.push(item)
                }

            })
            setFields(fields)
            setExtendedFields(extended)
        }
    }, [isFetching, data])

    //--------------------------- UPDATE URL ATTRIBUTES ------------------------------//

    const debouceValue = useDebounce(selected.parametricFilters, 500);

    useEffect(() => {
        //console.log(debouceValue)
        if (Object.values(debouceValue).length > 0) {

            let updatedUrlValues = []
            debouceValue.forEach((item) => {
                //remove if there are no values
                if (item.Values) {
                    //check for Comparison
                    if ((item.Comparison)) {

                        if (item.Tolerance) {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values},${item.Tolerance}]`)
                        } else {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values}]`)
                        }
                    } else {
                        updatedUrlValues.push(`${item.Field}§${item.Values}`)
                    }
                }
            })
            //console.log(updatedUrlValues)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'search': [updatedUrlValues],
                    'filters': [] //reset filter on parametric from page
                }

            });
            setFirstload(false);
        } else if (!firstLoad) {
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'search': [],
                    'filters': [] //reset filter on parametric from page
                }
            });
        }
    }, [debouceValue, firstLoad])

    //--------------------------- RESET SELECTED ATTRIBUTES ------------------------------//

    const resetForm = () => {
        dispatchSelected({
            type: RESET_ALL_FILTERS
        });

    };

    return (
        <MobileDrawWrapper
            resetFilters={resetForm}
            isFetching={isFetching}
        >
            {categories.currentCat.Image ?
                <Box sx={{ textAlign: 'center', background: '#fff' }} item xs={12} alignContent="center">
                    <Image isFetching={isFetching} src={categories.currentCat.Image} alt="Technical Image" ShowIcon={false} height={200} zoom />
                </Box>
                : null}
            <Box sx={{ padding: '1em' }} >
                {!isFetching && fields &&
                    <Grid container item spacing={1} >
                        {(fields).map((field, i) => {
                            return <InputTypes field={field} index={i} key={i} filtersView />
                        })}
                    </Grid>
                }
                {!isFetching && extendedFields && extendedFields.length > 0 &&
                    <>
                        <Grid item sx={{ margin: "1em 0 0 0" }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={(e) => setOpen(current => !current)}
                                endIcon={!open ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                            //disabled={false}
                            >
                                Extended option
                            </Button>
                        </Grid>
                        <Collapse in={open}>
                            <Grid container item spacing={1} sx={{ marginTop: "1em" }} >
                                {(extendedFields).map((field, i) => {
                                    return <InputTypes field={field} index={i} key={i} filtersView />
                                })}
                            </Grid>
                        </Collapse>
                    </>
                }
            </Box>
        </MobileDrawWrapper>
    );
};

export default Filters;
