import {
	Alert,
	Backdrop,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import SpinningLoader from "./SpinnningLoader";

const DialogLoader = ({ loadingMessage, successMessage, isLoading, mutation, closeDialog, fixed }) => {

	const [open, setOpen] = useState(true);
	//console.log(mutation)
	useEffect(() => {
		if (mutation?.data || mutation?.status === "success" || mutation?.status === "error") {
			if ((mutation?.data?.data?.status === 400 || mutation.isError)) {
				setTimeout(() => {
					setOpen(false)
					mutation?.reset();
				}, 2000)
			} else {
				setTimeout(() => {
					setOpen(false)
					mutation?.reset();
					if (closeDialog) {
						closeDialog()
					}
				}, 2000)
			}
		}
	}, [mutation])


	return (
		<>
			<Backdrop
				sx={{ position: fixed ? 'fixed' : 'absolute', color: "#fff", zIndex: theme => theme.zIndex.tooltip + 900000 }}
				open={open}
			>
				{isLoading ? (
					<SpinningLoader text={loadingMessage || "Sending request..."} size={100} />
				) : null}

				{(mutation?.isError || mutation?.data?.data?.status === 400) ? (
					<Alert
						variant="filled"
						severity="error"
					>
						An error occurred: {mutation?.errorMessage || mutation?.data?.data?.message} - Please try and submit again
					</Alert>
				) : mutation?.data?.errorMessage ?

					<Alert
						variant="filled"
						severity="error"
					>
						An error occurred: {mutation?.data?.errorMessage} - Please try and submit again
					</Alert>

					: mutation?.isSuccess ? (
						<Alert
							variant="filled"
							severity="success"
						>
							Success: {successMessage}
						</Alert>
					) : null}
			</Backdrop>
		</>
	)
}

export default DialogLoader
