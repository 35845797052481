import { Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import config from "../../config";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { CategoryContext } from "../../store/CategoryContext";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import { SelectedContext } from "../../store/SelectedContext";
import { CLEAR_APPLICATION_FILTERS, CLEAR_PARAMETRIC_FILTERS, CLEAR_URL_FILTERS, UDPATE_ALL_PARAMETRIC_FILTERS, UPDATE_URL_FILTERS } from "../../store/SelectedReducers";
import { UserContext } from "../../store/UserContext";
import Header from "../layout/Header";
import axiosRequest from "../../axios/axoisRequest";
import createUrlParameters from "../libs/createUrlParameters";
import findCurrentCategory from "../libs/findCurrentCategory";
import useDebounce from "../libs/useDebounce";
import Materials from "../wizards/Materials";
import ApplicationForm from "./ApplicationForm";
import FirstRow from "./FirstRow"
import FourthRow from "./FourthRow";
import SecondRow from "./SecondRow";
import ThirdRow from "./ThridRow";
import SearchHeader from "../../search/SearchHeader";

const ApplicationSearch = (props) => {

    const { selected, dispatchSelected } = useContext(SelectedContext)
    const { categories, dispatchCategories } = useContext(CategoryContext)
    const { user } = useContext(UserContext)
    const [toolClass, setToolClass] = useState([]);
    const [selectedID, setSelectedID] = useState([]);
    const [form, setForm] = useState([]);
    const [loading, setLoading] = useState(false);
    const [focused, setFocused] = useState(false);

    //update on first load using url params
    useEffect(() => {
        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            //console.log(urlParams)
            let hasToolClass = urlParams.get("class")
            let hasForm = urlParams.get("form")
            let classes = JSON.parse(hasToolClass)
            let form = JSON.parse(hasForm)
            if (classes) {
                setSelectedID(classes)
            }
            if (form) {
                setForm(form)
            }
            //clear all other filter
            // dispatchSelected({
            //     type: CLEAR_APPLICATION_FILTERS
            // });
        }
    }, []);

    //push new url using ids
    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.set('class', JSON.stringify(selectedID))
        if (form?.length) {
            urlParams.set('form', JSON.stringify(form))
        } else {
            urlParams.set('form','[]')
        }
        //console.log(filters)
        let url = `${window.location.pathname}?${decodeURIComponent(urlParams.toString())}`
        if (decodeURI(`${window.location.pathname}${window.location.search}`) !== url) {
            props.customHistory.push(url);
        }

    }, [selectedID, form])


    const get_search = useMutation((search) => {
        return axiosRequest({
            gateway: config.shopAPI.URL,
            endpoint: 'applicationsearchrow/search',
            api_key: user.api_key,
            method: 'get',
            params: {
                toolClass: form,
                search: JSON.stringify(search)
            }
        })
    }, {
        onSuccess: (data) => {
            // //update paramteric filters
            const category = findCurrentCategory([categories.allCats], data.ToolClass)
            //console.log(category)
            if (category) {
                dispatchCategories({
                    type: UPDATE_CATEGORIES,
                    payload: {
                        currentCat: category[0].cat,
                        openLevel: category[0].path
                    }
                });
            }
            dispatchSelected({
                type: UDPATE_ALL_PARAMETRIC_FILTERS,
                payload: data.Parameter
            });
            let urlParams = createUrlParameters(data.Parameter)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'search': [urlParams],
                    'filters': [] //reset filter on parametric from page
                }
            });
            setLoading(false)
        }
    })

    //--------------------------- UPDATE URL ATTRIBUTES ------------------------------//

    const [firstLoad, setFirstload] = useState(true);

    const debouceValue = useDebounce(selected.applicationFilters, 500);

    useEffect(() => {
        console.log(debouceValue)
        if (Object.values(debouceValue).length > 0) {

            let updatedUrlValues = []
            debouceValue.forEach((item) => {
                //remove if there are no values
                if (item.Values) {
                    //check for Comparison
                    if ((item.Comparison)) {

                        if (item.Tolerance) {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values},${item.Tolerance}]`)
                        } else {
                            updatedUrlValues.push(`${item.Field}§LGE[${item.Comparison},${item.Values}]`)
                        }
                    } else {
                        updatedUrlValues.push(`${item.Field}§${item.Values}`)
                    }
                }
            })
            //console.log(updatedUrlValues)
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'application': [updatedUrlValues]
                }

            });
            setFirstload(false);
        } else if (!firstLoad) {
            dispatchSelected({
                type: UPDATE_URL_FILTERS,
                payload: {
                    'application': [],
                }
            });
        }
    }, [debouceValue, firstLoad])

    useEffect(() => {
        //console.log(debouceValue)
        if (Object.values(debouceValue).length > 0 && form?.length > 0) {
            get_search.mutate(debouceValue)
            setLoading(true)
        } else if (selected.applicationReset) {
            //reset seach based on form
            get_search.mutate([])
            setLoading(true)
        }
    }, [debouceValue])


    //reset form

    // useEffect(() => {
    //    //console.log(form)
    //     if (form && Object.values(form).length > 0) {
    //         //get search when form changes
    //         get_search.mutate([])
    //         setLoading(true)
    //     }
    // }, [form])


    return (
        <>
            {/* <Header {...props} application /> */}
            <SearchHeader
                link={true}
                large={true}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
                props={props}
            />
            <InnerContentWrap>
                <Card
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.paper,
                        display: 'flex',
                        justiyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '0 auto 1em',
                        padding: '1em'
                    }}
                >
                    <CardContent>
                        {toolClass ?
                            <Grid item container spacing={2}>
                                <Grid item textAlign="center" xs={12} sx={{ marginBottom: '1em' }}>
                                    <Typography variant="h5" >Machining operation</Typography>
                                    <Typography variant="body2" >Select the cutting technology first, then the type of tool. Based on the selection the next steps are indicated.</Typography>
                                </Grid>
                                <Grid item container justifyContent="center" xs={12} spacing={2} >
                                    <FirstRow selectedID={selectedID} setToolClass={setToolClass} setSelectedID={setSelectedID} setForm={setForm} />
                                </Grid>
                                <Grid item container justifyContent="center" xs={12} spacing={2}>
                                    <SecondRow selectedID={selectedID} setToolClass={setToolClass} setSelectedID={setSelectedID} setForm={setForm} />
                                </Grid>
                                <Grid item container justifyContent="center" xs={12} spacing={2}>
                                    <ThirdRow selectedID={selectedID} setToolClass={setToolClass} setSelectedID={setSelectedID} setForm={setForm} form={form} />
                                </Grid>
                                <Grid item container justifyContent="center" xs={12} spacing={2}>
                                    <FourthRow selectedID={selectedID} setToolClass={setToolClass} setSelectedID={setSelectedID} setForm={setForm} form={form} />
                                </Grid>
                            </Grid>
                            : null}
                    </CardContent>
                </Card>
                {form && Object.values(form).length ?
                    <Grid item container justifyContent="center" xs={12} spacing={2}>
                        <ApplicationForm form={form} loading={loading} />
                    </Grid>
                    :
                    null
                }
            </InnerContentWrap>
        </>
    );
};

export default ApplicationSearch;
