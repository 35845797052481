import React, { useMemo, useState } from 'react'
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import formatDate from '../../../coolantcare/helpers/formatDate'

const CreditsTable = ({ data, count, isFetching, refreshTable }) => {

    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    //console.log(checkoutPermissions)

    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: () => 'ID',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'user_id',
            header: () => 'Order by',
            footer: props => props.column.id,
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            Username:  {info.row.original.user_id.user_name}
                        </Grid>
                        <Grid item xs={12} >
                            Email: {info.row.original.user_id.user_email}
                        </Grid>
                    </Grid>
                )
            },
        }, {
            accessorKey: 'credit_amount',
            header: () => 'Credit Purchased',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        }
        // , {
        //     accessorKey: 'credit_approved',
        //     header: () => 'Status',
        //     footer: props => props.column.id,
        //     cell: info => {
        //         //console.log(info)
        //         return (
        //             <Grid container spacing={1}>
        //                 {info.row.original.credit_approved ? "Approved" : "Pending Approval"}
        //             </Grid>
        //         )
        //     }
        , {
            accessorKey: 'approved_date',
            header: () => 'Approved date ',
            footer: props => props.column.id,
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        {
                        info.row.original.approved_date ? formatDate(info.row.original.approved_date) : "Pending Approval"}
                    </Grid>
                )
            },
        }
    ], [refreshTable])

    //console.log(dataQuery.data)

    const table = useReactTable({
        data: data ?? [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    // Manage your own state
    const [state, setState] = useState(table.initialState)

    // Override the state managers for the table to your own
    table.setOptions(prev => ({
        ...prev,
        state,
        onStateChange: setState,
        // These are just table options, so if things
        // need to change based on your state, you can
        // derive them here

        // Just for fun, let's debug everything if the pageIndex
        // is greater than 2
        debugTable: state.pagination.pageIndex > 2,
    }))

    return (
        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                            <TableHead>
                                {table.getHeaderGroups().map((headerGroup, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {headerGroup.headers.map((header, i) => {
                                            return (
                                                <TableCell key={i} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {/* {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null} */}
                                                        </>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {isFetching ?
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell, i) => {
                                                    return (
                                                        <TableCell key={i}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}

                                            </TableRow>
                                        )
                                    })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No data found</Alert>
                                            </TableCell>
                                        </TableRow>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} >
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        component="div"
                        count={count ? count : -1}
                        rowsPerPage={table.getState().pagination.pageSize}
                        page={table.getState().pagination.pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}

                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CreditsTable;
