import { useTheme } from "@emotion/react";
import { Alert, Box, Divider, Grid, List, ListItem, ListItemButton, ListItemText, ListSubheader, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_APPLICATION_FILTERS, UPDATE_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import { UserContext } from "../../store/UserContext";
import MaterailGroups from "../wizards/MaterialGroups";
import FilterSelect from "../wizards/FilterSelect";

const Materials = ({ checked }) => {
    //render fields
    const { user } = useContext(UserContext);
    const { dispatchSelected } = useContext(SelectedContext);
    const theme = useTheme();
    const [standardIndex, setStandardIndex] = useState();
    const [standard, setStandard] = useState();
    const [materialIndex, setMaterialIndex] = useState();
    const [material, setMaterial] = useState();
    const [rootClass, setRootClass] = useState();

    const handleRootClass = (item) => {
        setRootClass(item)
        setStandardIndex();
        setStandard();
        setMaterialIndex();
        setMaterial();
        updateMaterialFilters(item.Value)
    };

    const handleStandardIndex = (event, index, item) => {
        setStandardIndex(index);
        setStandard(item)
        setMaterialIndex();
        setMaterial()
    };

    const handleMaterialIndex = (event, index, item) => {
        setMaterialIndex(index);
        setMaterial(item)
    };


    const updateMaterialFilters = (group) => {
        //console.log(item)
        dispatchSelected({
            type: UPDATE_APPLICATION_FILTERS,
            payload: {
                'Field': 'VW1', 'Values': [group]
            }
        });
    }


    useEffect(() => {

        //console.log(checked)
        if (!checked) {
            //remove selection if user untick material
            dispatchSelected({
                type: UPDATE_APPLICATION_FILTERS,
                payload: {
                    'Field': 'VW1', 'Values': []
                }
            });
        }

    }, [checked])

    //--------------------------- API CALLS  ------------------------------//

    const { status, data, error, isFetching } = useQuery(['materialinformation'],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'wizards/materialinformation',
            api_key: user.api_key,
            method: 'get',
        }), {
        // enabled: !!open,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    //console.log(data)

    const { data: m_data, isFetching: m_isFetching } = useQuery(['material', rootClass, standard],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'wizards/materials',
            api_key: user.api_key,
            method: 'get',
            params: {
                rootClass: rootClass?.Value ? rootClass.Value : '',
                standard: standard.Value
            }
        }), {
        enabled: !!standard,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    const { data: g_data, isFetching: g_isFetching, } = useQuery(['materialgroups', rootClass, standard, material],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'wizards/materials/groups',
            api_key: user.api_key,
            method: 'get',
            params: {
                groups: JSON.stringify(material.Groups)
            }
        }), {
        enabled: !!standard && !!material,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    return (
        <>
            {checked ?
                <>
                    <Grid container spacing={2} alignContent="stretch">
                        {data && data.RootClasses.map((item, i) => {
                            return (
                                <Grid item xs={6} sm={3} lg={2} key={i}>
                                    <Box
                                        onClick={e => handleRootClass(item)}
                                        sx={{
                                            background: item.Color,
                                            width: '100%',
                                            height: '180px',
                                            padding: '2em',
                                            cursor: 'pointer',
                                            borderRadius: '10px',
                                            boxShadow: item.Value === rootClass?.Value ? '0px 0px 5px 0px #222' : 'none',
                                            border: '2px solid #fff',
                                            '&:hover': {
                                                boxShadow: '0px 0px 5px 0px #222'
                                            }
                                        }}
                                    >
                                        <Typography fontSize={40}>{item.Value}</Typography>
                                        <Typography>{item.Label}</Typography>
                                    </Box>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            {!rootClass && !standard && data && <Alert severity="info">Please select a Class and a Standard</Alert>}
                            {rootClass && !standard && data && <Alert severity="info">Please select a Standard</Alert>}
                            {!material && m_data && <Alert severity="info">Please select a Material Name</Alert>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: '1em' }} >
                        <Grid item xs={4}>
                            <Typography sx={{ mb: '1em' }}>Standard</Typography>
                            <FilterSelect options={data?.Standards} selected={standard} handleChange={handleStandardIndex} isFetching={isFetching} name={'standard'} />

                            {/* 
                                // <List
                                //     subheader={
                                //         <ListSubheader sx={{ bgcolor: 'background.default', }} component="div" id="nested-list-subheader" disableGutters>
                                //             <Typography sx={{ padding: '1em' }}>Standard</Typography>
                                //             <Divider />
                                //         </ListSubheader>
                                //     }
                                //     dense={true}
                                //     sx={{
                                //         margin: '1em 0',
                                //         width: '100%',
                                //         bgcolor: 'background.paper',
                                //         position: 'relative',
                                //         overflow: 'auto',
                                //         maxHeight: 400,
                                //         border: '1px solid #ccc',
                                //         padding: 0,
                                //     }}
                                // >
                                //     {data.Standards.map((item, i) => {
                                //         return (

                                //             <ListItem sx={{ padding: 0 }} key={i}>
                                //                 <ListItemButton
                                //                     selected={standardIndex === i}
                                //                     onClick={(event) => handleStandardIndex(event, i, item)}
                                //                 >
                                //                     <ListItemText
                                //                         primary={item.Label}
                                //                     />
                                //                 </ListItemButton>
                                //                 <Divider />
                                //             </ListItem>
                                //         )
                                //     })}
                                // </List> */}
                        </Grid>
                        <Grid item xs={4}>

                            <Typography sx={{ mb: '1em' }}>Material Name</Typography>
                            <FilterSelect options={m_data} selected={material} handleChange={handleMaterialIndex} isFetching={m_isFetching} name={'material'} />


                            {/* // <List
                                //     subheader={
                                //         <ListSubheader sx={{ bgcolor: 'background.default', }} component="div" id="nested-list-subheader" disableGutters>
                                //             <Typography sx={{ padding: '1em' }}>Material Name</Typography>
                                //             <Divider />
                                //         </ListSubheader>
                                //     }
                                //     dense={true}
                                //     sx={{
                                //         margin: '1em 0',
                                //         width: '100%',
                                //         bgcolor: 'background.paper',
                                //         position: 'relative',
                                //         overflow: 'auto',
                                //         maxHeight: 400,
                                //         border: '1px solid #ccc',
                                //         padding: 0,
                                //     }}
                                // >
                                //     {m_data.map((item, i) => {
                                //         return (
                                //             <ListItem sx={{ padding: 0 }} key={i}>
                                //                 <ListItemButton
                                //                     selected={materialIndex === i}
                                //                     onClick={(event) => handleMaterialIndex(event, i, item)}
                                //                 >
                                //                     <ListItemText
                                //                         primary={item.Label}
                                //                     />
                                //                 </ListItemButton>
                                //                 <Divider />
                                //             </ListItem>
                                //         )
                                //     })}
                                // </List> */}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ mb: '1em' }}>Select Group</Typography>

                            <Box sx={{ width: '100%', margin: '1em 0' }}>
                                {g_isFetching ?
                                    <Skeleton height={200} variant="rectangular" animation="wave" />
                                    : g_data &&
                                    <Box sx={{ width: '100%', background: '#efefef', border: '1px solid #ccc', padding: '5px' }}>
                                        <MaterailGroups groups={g_data} isFetching={g_isFetching} updateMaterialFilters={updateMaterialFilters} />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </>
                : null}
        </>
    );
};

export default Materials;

