import React, { useState, useContext } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Paper,
	Typography,
	Card,
	CardActions,
	CardContent
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { buttonStyles } from '../../styles/buttonStyles';
import EditSettings from './EditSettings';
import FactorMachine from './FactorMachine';
import { makeStyles } from '@mui/styles';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import getUserLevel from '../../../helpers/getUserLevel';

const useStyles = makeStyles(theme => ({
	card: { height: '100%', width: '100%', boxShadow: 'none' },
	accordion: {
		borderTop: '1px solid #ececec',
		padding: 0
	},
	cardContainer: { height: '100%' },
	cardContent: {
		flex: 1
	},
	cardFooter: {
		padding: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.background.light}`,
		background: `${theme.palette.background.light}`
	},
	leftButton: {
		paddingLeft: '4px'
	},
	rightButton: {
		paddingRight: '4px'
	}
}));

export default function MachineDefaults({ selectedMachine }) {

	const { user } = useContext(UserContext);
	const [expanded, setExpanded] = useState(false);
	const classes = useStyles();
	const buttonStyle = buttonStyles();
	const handleExpand = () => {
		setExpanded(state => setExpanded(!state));
	};

	return (
		<Accordion
			expanded={expanded}
			onChange={handleExpand}
			className={classes.root}
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="machine-details"
				id="machine-details"
				disabled={!selectedMachine?.machine_settings}
			>
				<Typography variant={isMobileOnly ? 'p' : 'h6'}>
					{setLanguageText("Machine")}{!isMobileOnly && ' Details'}:{' '}
					<span style={{ color: '#888' }}>{selectedMachine?.machine_name ? setLanguageText(selectedMachine?.machine_name) : setLanguageText("No Machine")}</span>
				</Typography>
			</AccordionSummary>

			<AccordionDetails className={classes.accordion}>
				{/* Machines Defaults values */}
				<Card className={classes.card}>
					<CardContent justify="center" className={classes.cardContent}>
						<Grid item container spacing={1}>

							<Grid item xs={6}>
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("PH Min")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults.ph_min}
										{/* {`Conc Min: ${defaults.con_max}`} */}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6} >
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("PH Max")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults.ph_max}
										{/* {`PH Max: ${defaults.ph_max}`} */}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("Conc Min")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults.op_con_min}
										{/* {`PH Min: ${defaults.ph_min}`} */}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("Conc Max")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults.op_con_max}
										{/* {`Conc Min: ${defaults.con_min}`} */}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("Factor")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults.factor}
										{/* {`Factor: ${defaults.factor}`} */}
									</Typography>
								</Paper>
							</Grid>

							<Grid item xs={6}>
								<Paper className={`${buttonStyle.box}`} variant="outlined">
									<Typography variant="body1" align="center">
										<strong>{setLanguageText("Sump")}: </strong>
										{selectedMachine?.machine_settings?.machine_defaults?.sump}
										{/* {`Conc Min: ${defaults.con_min}`} */}
									</Typography>
								</Paper>
							</Grid>
							<FactorMachine />
						</Grid>
					</CardContent>
					<CardActions sx={{ textAlign: 'center', marginTop: 'auto', padding: '1em', backgroundColor: theme => theme.palette.cards.footer }}>
						{getUserLevel(user.user, 'coolantcare', 'machine_edit', 6) ?
							<Grid item container spacing={2}>
								<EditSettings selectedMachine={selectedMachine} />
							</Grid>
							: null}
					</CardActions>
				</Card>
			</AccordionDetails>
		</Accordion >
	);
};
