import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    List,
    ListItem,
    Box,
    FormControlLabel,
    Switch
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFile, faTimes } from '@fortawesome/pro-light-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../language/setLanguageText';
import EmailPDF from '../emails/EmailPDF';

const GlobalDialogPDF = ({
    title,
    text,
    open,
    onClose,
    buttonTitle,
    buttonClick,
    children,
    maxWidth,
    closeText,
    loading,
    disabled,
    customActions,
    orientation,
    updateOrientation,
    options,
    handleOptionChange,
    downloadObject,
    email
}) => {


    const downloadButton = (downloadObject) => {
        var blob = new Blob([downloadObject.blob], { type: "application/pdf;charset=utf-8" });
        saveAs(blob, `${title}.pdf`);
    }

    console.log(maxWidth)

    return (
        <Dialog
            fullScreen={isMobileOnly ? true : false}
            open={open}
            onClose={onClose}
            maxWidth={maxWidth ? maxWidth : 'lg'}
            scroll='paper'
            PaperProps={{
                sx: {
                    width: '100%',
                    height: '100%'
                }
            }}
        >
            <DialogTitle
                sx={{
                    background: theme => theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
                    paddingRight: '2.5em',
                }}
            >
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            right: 8,
                            top: 14,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}
                sx={{
                    padding: 0,
                    paddingBottom: '7px',
                    marginBottom: '-7px'
                }}
            >
                <Box sx={{ height: '100%' }}>
                    <Grid item container sx={{ height: '100%' }} >
                        <Grid container item xs={4} md={3} sx={{ padding: '1em', height: '100%', overflow: "auto" }} spacing={2} alignContent="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {setLanguageText("Set Orientation")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    value={orientation}
                                    exclusive
                                    onChange={(e, value) => updateOrientation(value)}
                                    aria-label="text alignment"
                                    style={{ width: '100%', marginTop: '5px' }}
                                >
                                    <ToggleButton
                                        value="portrait"
                                        aria-label="centered"
                                        color='primary'
                                        sx={{ padding: 3 }}
                                    >
                                        <FontAwesomeIcon icon={faFile} size='2x' />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="landscape"
                                        aria-label="centered"
                                        color='primary'
                                        sx={{ padding: 3 }}
                                    >
                                        <FontAwesomeIcon icon={faFile} rotation={90} size='2x' />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {options ?
                                <Grid container item spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {setLanguageText("Options")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List dense>
                                            {options.map(setting => (
                                                <ListItem style={{ borderTop: '1px solid #ececec' }} key={setting.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={handleOptionChange}
                                                                checked={setting.isVisible}
                                                                name={setting.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        name={setting.name}
                                                        label={setLanguageText(setting.name)}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                                : null}
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            md={9}
                            sx={{
                                height: "100%",
                                background: (theme) => theme.palette.grey[400]
                            }}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ background: theme => theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200], padding: '1em' }} >
                {customActions ? customActions :
                    <Grid item container spacing={2} justifyContent="flex-end" xs={12}>
                        <Grid item>
                            <Button
                                variant="text"
                                onClick={onClose}
                                disabled={disabled ? true : false}
                            >
                                {setLanguageText(closeText ? closeText : 'Cancel')}
                            </Button>
                        </Grid>
                        {email && downloadObject &&
                            <EmailPDF downloadObject={downloadObject} title={title} text={text} />
                        }

                        {downloadObject ?

                            <Grid item>
                                <Button
                                    onClick={e => downloadButton(downloadObject)}
                                    color="primary"
                                    variant="contained"
                                >
                                    <FontAwesomeIcon style={{ marginRight: '10px', textDecoration: 'none' }} icon={faDownload} />
                                    {setLanguageText("Download PDF")}
                                </Button>
                            </Grid>

                            : null}


                        {buttonTitle ?
                            <Grid item>
                                <Button
                                    fullWidth={false}
                                    onClick={buttonClick}
                                    variant='contained'
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                    disabled={disabled ? true : false}
                                >

                                    {setLanguageText(buttonTitle)}
                                </Button>
                            </Grid>
                            : null}
                    </Grid>
                }
            </DialogActions>
        </Dialog >
    );
};

export default GlobalDialogPDF;