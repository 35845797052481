import React, { useContext, useState } from 'react';
import { Grid, TextField, MenuItem, Avatar } from '@mui/material';
import config from '../../config';
import GlobalDialog from '../../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../axios/axoisRequest';
import DialogLoader from '../../reusable/DialogLoader';
import { faEdit, faSave } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import setLanguageText from '../../language/setLanguageText';
import FocusTrap from '@mui/material/Unstable_TrapFocus';

const SaveUserTemplate = ({ type, template, handleClose }) => {

    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");


    //save template
    const save_template = useMutation(() => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'templates',
            api_key: user.api_key,
            method: 'post',
            body: {
                "template_type": type,
                "template_name": templateName,
                "template_description": templateDescription ? templateDescription : '',
                "template": template
            },
        })
    })

    const handleCloseDialog = () => {
        setOpen(false)
        handleClose()
    }

    return (
        <>

            <MenuItem
                variant='contained'
                onClick={e => setOpen(true)}
            >
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <FontAwesomeIcon size="xs" icon={faSave} />
                </Avatar>
                {setLanguageText("Save Template")}
            </MenuItem>

            {open &&
                <GlobalDialog
                    open={open}
                    onClose={() => handleCloseDialog()}
                    title={`Save User Permissions As Template`}
                    buttonClick={() => {
                        save_template.mutate();
                    }}
                    buttonTitle="Save Template"
                    //disabled={save_template.isLoading}
                    loading={save_template.isLoading}
                    maxWidth="sm"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                id='template_name'
                                name='template_name'
                                type='text'
                                fullWidth
                                variant="outlined"
                                label="Template Name"
                                value={templateName}
                                onChange={(value) => {
                                    //console.log(value.target.value)
                                    setTemplateName(value.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='template_description'
                                name='template_description'
                                type='text'
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Template Description"
                                value={templateDescription}
                                onChange={e => setTemplateDescription(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {save_template.isLoading || save_template.isSuccess || save_template.isError ?
                        <DialogLoader
                            fixed
                            isLoading={save_template.isLoading}
                            mutation={save_template}
                            loadingMessage="Saving Template"
                            successMessage="Saved Template"
                            closeDialog={() => {
                                setOpen(false)
                                setTemplateName('')
                                setTemplateDescription('')
                            }}
                        />
                        : null}
                </GlobalDialog>
            }
        </>
    );
};

export default SaveUserTemplate;
