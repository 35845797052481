import React, { useContext, useEffect, useState } from "react";
import Paginations from "../shop/reusable/Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTable,
	faBorderAll,
	faBars
} from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from "../store/LayoutContext";
import { ProductsContext } from "../store/ProductsContext";
import { UPDATE_LAYOUT } from "../store/LayoutReducers";
import { UPDATE_PRODUCTS } from "../store/ProductsReducers";
import { Autocomplete, Box, Grid, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CategoryContext } from "../store/CategoryContext";

const LayoutOptions = ({ isFetching }) => {

	//Global states
	const { layout, dispatchLayout } = useContext(LayoutContext);
	const { products, dispatchProducts } = useContext(ProductsContext);
	const { categories } = useContext(CategoryContext);
	const [value, setValue] = useState();

	//componenet states
	const limitOptions = [{
		id: 1, label: 25
	}, {
		id: 1, label: 50
	}, {
		id: 2, label: 100
	}]

	//Update layout
	const updateLayout = (e, value) => {
		if (value) {
			dispatchLayout({
				type: UPDATE_LAYOUT,
				payload: {
					layout: value,
				}
			});
			sessionStorage.setItem("layout", value);
		}
	};

	//Update limit
	const updateLimit = (value) => {
		console.log('update page')
		dispatchProducts({
			type: UPDATE_PRODUCTS,
			payload: {
				limit: value.label
			}
		});
		dispatchLayout({
			type: UPDATE_LAYOUT,
			payload: {
				page: 1 //reset page
			}
		});
		sessionStorage.setItem("limit", value.label);
	};

	useEffect(() => {
		//console.log(products.limit)
		if (products.limit) {
			let found = limitOptions.find(item => item.label === products.limit)
			//console.log(found)
			setValue(found)

		}
	}, [products.limit])

	return (
		<Grid item container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
			{/* <Grid item xs={4} >
				<ToggleButtonGroup
					size="small"
					color="primary"
					fullWidth
					value={layout.layout}
					exclusive
					onChange={updateLayout}
					aria-label="layout"
					sx={{ maxWidth: '100px' }}
					disabled={isFetching}
				//size="large"
				>
					<ToggleButton value="engineer">
						<FontAwesomeIcon
							icon={faTable}
							size="2x"
						/>
					</ToggleButton>
					<ToggleButton value="grid" >
						<FontAwesomeIcon
							icon={faBorderAll}
							size="2x"

						/>
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid> */}
			<Grid item container xs justifyContent="center" alignItems="center" >
				<Grid item>


					<Typography
						color={(theme) => theme.palette.text.primary}
						sx={{
							marginRight: '1em'
						}}
					>{isFetching ? <Skeleton width="100px" /> : `Products: ${products?.count}`}</Typography>
				</Grid>

				<Paginations isFetching={isFetching} />
				{value &&
					<Grid item >
						<Autocomplete
							disabled={isFetching}
							size="small"
							disableClearable
							sx={{ width: 80 }}
							options={limitOptions}
							value={value}
							onChange={(event, newValue) => {
								//console.log(newValue)
								updateLimit(newValue);
							}}
							isOptionEqualToValue={(option, value) => option.label === value.label}
							autoHighlight
							getOptionLabel={(option) => option.label ? String(option.label) : ''}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Per Page'}
									//InputLabelProps={{ shrink: false }}
									placeholder="Please Select"
								/>
							)}
						/>
					</Grid>
				}
			</Grid>
		</Grid>
	);
};

export default LayoutOptions;
