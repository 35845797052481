import React, { useContext, useEffect, useRef, useState } from "react";
import { CircularProgress, Grid, Button, InputBase, Box, Typography, } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import useDebounce from "../shop/libs/useDebounce";
import { CategoryContext } from "../store/CategoryContext";
import { useNavigate } from "react-router-dom";
import { SelectedContext } from "../store/SelectedContext";
import { UDPATE_ALL_PARAMETRIC_FILTERS, UPDATE_PARAMETRIC_FILTERS, UPDATE_URL_FILTERS } from "../store/SelectedReducers";
import { LayoutContext } from "../store/LayoutContext";
import { UserContext } from "../store/UserContext";

const CadSearch = ({ isFetching, isLoading }) => {

	const { dispatchSelected } = useContext(SelectedContext);
	const [value, setValue] = useState('');
	const [text, setText] = useState();
	const [results, setResults] = useState();
	const ref = useRef()

	const debouceValue = useDebounce(value, 700);

	//update store
	useEffect(() => {
		if (debouceValue) {
			setText(debouceValue);
			dispatchSelected({
				type: UDPATE_ALL_PARAMETRIC_FILTERS,
				payload: [{ "Field": "anyThreeD", "Values": ["1"] }, { "Field": "Volltext", "Values": [debouceValue] }]
			});
			dispatchSelected({
				type: UPDATE_URL_FILTERS,
				payload: {
					//search: [[`Volltext§${encodeURI(text)}`]],
					'application': [],
					'search': [[`anyThreeD§1`, `Volltext§${debouceValue}`]],
					'filters': [] //reset custom result filters
				}
			});
		}
	}, [debouceValue])

	const clearText = () => {
		console.log('clear');
		setValue('');
		setText();
		setResults();
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'Volltext', 'Values': []
			}
		});
		dispatchSelected({
			type: UPDATE_URL_FILTERS,
			payload: {
				'application': [],
				'search': [],
				'filters': []
			}
		});
	}

	return (
		<Grid item container textAlign="center" spacing={2}
			sx={{ margin: '2em 0' }}>
			<Grid item xs={12}>
				<Typography variant="h5">Search for Cad Download</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{
					display: 'flex',
					borderRadius: '5px',
					border: '1px solid #ccc',
					maxWidth: '500px',
					margin: '0 auto',
					background: theme => theme.palette.background.default
				}}>
					<InputBase
						sx={{
							flex: 1,
							fontSize: '1em',
							padding: '1em',
						}}
						placeholder="Search by tool name or Supplier part number"
						inputProps={{ 'aria-label': 'Search by tool name or Supplier part number' }}
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
					{text?.length ?
						<Box
							sx={{
								cursor: 'pointer',
								fontSize: '1.25em',
								padding: '1em',
								color: (theme) => theme.palette.grey[400],
								'&:hover': {
									color: (theme) => theme.palette.grey[800]
								}
							}}
							onClick={e => clearText()}
						>
							<FontAwesomeIcon icon={faTimes} size="lg" />
						</Box>
						: null}
					<Box
						ref={ref}
						variant="contained"
						sx={{
							fontSize: '1.25em',
							padding: '1em',
							borderRadius: '0 5px 5px 0',
							background: theme => theme.palette.primary.main,
							color: theme => theme.palette.primary.contrastText,
						}}
						aria-label="directions"
						onClick={e => {
							if (results?.Components) {
								setAnchorEl(ref.current);
							}
						}}
					>
						{isFetching || isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="lg" />}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CadSearch;
