import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

// A debounced input react component
const DebouncedInput = ({
    value: initialValue,
    onChange,
    label,
    debounce = 500,
    ...props
}) => {

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)
        return () => clearTimeout(timeout)
    }, [value])

    return (
        <TextField
            {...props}
            value={value}
            onChange={e =>
                setValue(e.target.value)
            }
            //label={label}
            sx={{
                '& input': { zIndex: 2 },
                '& legend': { display: 'none' },
                '& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
                '& .MuiAutocomplete-endAdornment ': {
                    zIndex: 2
                }
            }}
        />
    )
}

export default DebouncedInput

