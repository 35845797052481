import React, { useContext, useEffect, useState } from "react";
import packageJson from '../../package.json';
import { RESET_COMPANIES, RESET_SELECTED_COMPANY } from '../store/CompanyReducers';
import { CompanyContext } from '../store/CompanyContext';
import { CLEAR_BASKET } from '../store/BasketReducers';
import { BasketContext } from '../store/BasketContext';
import { SelectedContext } from '../store/SelectedContext';
import { RESET_ALL_FILTERS } from '../store/SelectedReducers';
import { UPDATE_MENU } from "../store/LayoutReducers";
import { LayoutContext } from "../store/LayoutContext";
import { UserContext } from "../store/UserContext";
import { Auth } from 'aws-amplify';
import { AUTH_USER, RESET_USER } from "../store/UserReducers";
import { Box, Grid, Typography } from "@mui/material";
import { useMutation } from "react-query";

import config from "../config";
import GlobalDialog from "../reusable/GlobalDialog";
import VersionUpdate from "../version/VersionUpdate";
import axiosRequest from '../axios/axoisRequest';

function compareVersions(version1, version2) {
	const version1Array = version1.split('.').map(Number);
	const version2Array = version2.split('.').map(Number);

	const maxLength = Math.max(version1Array.length, version2Array.length);
	for (let i = 0; i < maxLength; i++) {
		const v1 = version1Array[i] || 0;
		const v2 = version2Array[i] || 0;
		if (v1 < v2) {
			return -1;
		} else if (v1 > v2) {
			return 1;
		}
	}
	return 0;
}

const AppVersion = () => {
	const { dispatchUser, user } = useContext(UserContext)
	const { dispatchApp, theme } = useContext(LayoutContext)
	const { dispatchCompanies, dispatchSelectedCompany } = useContext(CompanyContext)
	const { dispatchBasket } = useContext(BasketContext)
	const { dispatchSelected } = useContext(SelectedContext)
	const [open, setOpen] = useState(false);
	const [welcome, setWelcome] = useState(false);

	const update_setting = useMutation((settings) => {

		//console.log(userObject)
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'administrator/user',
			api_key: user.api_key,
			method: 'post',
			body: {
				update: true,
				update_settings: true,
				user_settings: settings,
				user_id: user.user.user_id,
				aws_sub: user.aws_sub
			}
		})
	}, {
		onSuccess: (data, variable) => {
			if (data.errorMessage) {
				console.log(data.errorMessage)
			} else {
				console.log(variable)
				dispatchUser({
					type: AUTH_USER,
					payload: {
						user: {
							...user.user,
							user_settings: variable
						},
					}
				});
			}
		}
	})

	useEffect(() => {

		if (theme?.app_version && packageJson.version) {

			const result = compareVersions(theme?.app_version, packageJson.version);
			if (result === -1) {
				//console.log(`app is lower than package.json`);
				//setOpen(true)
			} else if (result === 1) {
				//console.log(`app is higher than package.json`);
				setOpen(true)
			} else {
				//console.log(`app is equal to package.json`);
			}
		}
	}, [theme, packageJson]);

	useEffect(() => {

		if (user?.user?.user_settings?.app_version && packageJson.version) {

			const result = compareVersions(user?.user?.user_settings?.app_version, packageJson.version);
			if (result === -1) {
				//console.log(`user is lower than package.json`);
				setWelcome(true)
			} else if (result === 1) {
				//console.log(`user is higher than package.json`);
				//this should never happen
			} else {
				//console.log(`user is equal to package.json`);
			}
		}
	}, [user, packageJson]);

	const handleRefresh = () => {

		dispatchUser({
			type: RESET_USER
		});
		dispatchCompanies({
			type: RESET_COMPANIES
		});
		dispatchSelectedCompany({
			type: RESET_SELECTED_COMPANY
		})
		dispatchBasket({
			type: CLEAR_BASKET
		})
		dispatchSelected({
			type: RESET_ALL_FILTERS
		})
		dispatchApp({
			type: UPDATE_MENU,
			payload: {
				selectedArea: '',
				selectedMenu: ''
			}
		})

		Auth.signOut(); //amplify signout 
		localStorage.clear();
		sessionStorage.clear();

		if ('caches' in window) {
			caches.keys().then((names) => {
				// Delete all the cache files
				names.forEach(name => {
					caches.delete(name);
				})
			});
			// Makes sure the page reloads. Changes are only visible after you refresh.
			window.location.reload(true);
		}
		//localStorage.setItem('version', packageJson.version);
	}
	//console.log(open)

	const handleClose = () => {
		//update version and close
		update_setting.mutate({
			...user?.user?.user_settings,
			app_version: packageJson.version
		})
		setWelcome(false)
	}

	return (

		<>

			<GlobalDialog
				open={open}
				onClose={() => handleRefresh()}
				title={`${config?.APPTITLE} update available`}
				maxWidth="sm"
				closeText={`Close`}
			>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography textAlign="center" variant="h6" component="p">Unleash the true potential of our latest update <strong>{theme?.app_version}</strong></Typography>
					</Grid>
					<Box sx={{ maxWidth: '60%', margin: '1em auto' }}>
						<Typography textAlign="center" variant="p" component="p">Featuring an array of improved functionalities, bug fixes, and refreshing UI updates!</Typography><br />
						<Typography textAlign="center" variant="p" component="p">To access these exciting enhancements, simply <strong>close</strong> this popup and <strong>sign back in</strong>.</Typography>
					</Box>
				</Grid>
			</GlobalDialog>

			<GlobalDialog
				open={welcome}
				onClose={() => handleClose()}
				title={`${config?.APPTITLE} updated`}
				maxWidth="md"
				closeText={`Close`}
			>
				{config.APPNAME === "act" ?
					<Grid container spacing={2}
						sx={{
							marginBottom: '2em'
						}}
					>
						<Grid item xs={12}>
							<Typography variant="h4" component="p">Congratulations.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="p">You just accessed the world’s first specialist Cutting Tools, Lubricant’s search engine and store for engineers - MyFactory.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" fontWeight={700} component="p">Powered by ACT Group</Typography>
						</Grid>
					</Grid>

					: null}
				<VersionUpdate />

			</GlobalDialog>

		</>
	)
};

export default AppVersion;

