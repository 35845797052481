import React, { useContext, useEffect, useState } from "react";
import ReactHtmlParser from 'html-react-parser';
import { Alert, Grid } from "@mui/material";
import { UserContext } from "../store/UserContext";
import { useQuery } from "@tanstack/react-query";
import axiosRequest from "../axios/axoisRequest";
import config from "../config";
import SpinningLoader from "../reusable/SpinnningLoader";
import { trackEvent } from "../google_analytics/ga";
import { CompanyContext } from "../store/CompanyContext";


export default function OpenAISpares({ product }) {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);

    // console.log(product)
    // const handleMaterialChange = (e) => setMaterial(e.target.value);

    //get machine entries
    // const { data, isLoading } = useQuery({
    //     queryKey: ['openai', product.product_id],
    //     queryFn: ({ signal }) => axiosRequest({
    //         signal: signal,
    //         gateway: config.factoryAPI.URL,
    //         endpoint: 'openai',
    //         api_key: user.api_key,
    //         method: 'post',
    //         body: {
    //             model: "gpt-4o-mini", // or "gpt-4" if you have access
    //             messages: [{
    //                 role: "system",
    //                 content: "You are a machining expert working on machine tools and using various branded cutting tools. Given the product code number, your task is to return all the spare parts and accessories product codes associated with this cutting tool product code number. Only provide the spares and accessories product codes if you have verified the information with the EU OEM data. If the information is not available, return an appropriate message indicating that the data is not available. Also, include the source of the data if available. Where possible get the information from the manufacturer. Use the following HTML template for the output, but the list of parts can be longer than 3, and return only the body content (without DOCTYPE, html, head, or body tags)" + 
    //                 "<h2>Here are the spare parts and accessories product codes associated with {product_name}</h2>\n" +
    //                     "<ul>\n" +
    //                     "  <li><strong>{spare_product_code}</strong> {spare_product_type}({spare_product_name})</li>\n" +
    //                     "  <li><strong>{spare_product_code}</strong> {spare_product_type}({spare_product_name})</li>\n" +
    //                     "  <li><strong>{spare_product_code}</strong> {spare_product_type}({spare_product_name})</li>\n" +
    //                     "</ul>"
    //             }, {
    //                 role: "user",
    //                 content: `Product code: ${product.product_number}  
    //                     Product description: ${product.product_description} 
    //                     Product manufacturer: ${product.product_manufacturer}`
    //             }],
    //             max_tokens: 800, // Adjust based on your needs
    //         }
    //     }),
    //     enabled: !!product.product_id,
    //     retry: false
    // })

    // useEffect(() => {
    //     if (data) {
    //         //add to google analytics
    //         trackEvent({
    //             action: 'openai_search',
    //             params: {
    //                 "company_id": selectedCompany.company_id,
    //                 "items": [{
    //                     "item_id": product.product_id,
    //                     "item_name": product.product_description,
    //                     "product_number": product.product_number,
    //                     "product_supplier": product.product_supplier
    //                 }]
    //             }
    //         })
    //     }
    // }, [data, selectedCompany, product])


    return (
        <Grid container spacing={2}>
          
                <Grid item xs={12}>
                    <Alert variant="standard" severity="warning">This feature is in Beta testing and is only available to administrators, but will be available for everyone soon.</Alert>
                </Grid>
               
            {/* {user?.user?.user_permissions?.administrator ?
                <Grid item xs={12}>
                    {isLoading ?
                        <SpinningLoader text="Fetching matching products..." size={40} />
                        : data ?
                            <>
                                {ReactHtmlParser(data)}
                             
                            </>
                            : null
                    }
                </Grid>
                : null} */}
        </Grid >
    );
}






