import { IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';

export default function MachinesList({ product }) {

	return (
		<List>
			{product?.machines_using_product?.map((machine, index) => (
				<ListItem
					secondaryAction={
						<Link to={`/coolantcare/machine/${machine.id}`}>
							<IconButton edge="end" aria-label="comments">
								<Tooltip title="Go to machine" placement="top">
									<FontAwesomeIcon icon={faSquareArrowUpRight} />
								</Tooltip>
							</IconButton>
						</Link>
					}
				>
					<ListItemText primary={machine.name} key={index} />
				</ListItem>
			))}

		</List>
	);
};

