import React from 'react';
import { Button, Grid } from '@mui/material';
import GlobalDialog from '../../reusable/GlobalDialog';
import SignatureCanvas from 'react-signature-canvas';
import { useTheme } from '@mui/styles';
import setLanguageText from '../../language/setLanguageText';

const SignatureDialog = ({
	open,
	closeDialog,
	title,
	submit_sign,
	sign_ref,
	serviceObj,
	setServiceObj
}) => {
	const theme = useTheme();
	return (
		<GlobalDialog
			open={open}
			onClose={closeDialog}
			fullWidth
			maxWidth="sm"
			title={title}
			backgroundColor={theme.palette.background.main}
			buttonTitle={setLanguageText('Confirm Signature')}
			buttonClick={submit_sign}
			customActions={
				<Grid container spacing={2}>
					<Grid item xs={4} >
						<Button
							color='error'
							onClick={() =>
								setServiceObj({
									...serviceObj,
									cust_sign: sign_ref.current.clear()
								})
							}
							variant="contained"
						>
							{setLanguageText("Clear")}
						</Button>
					</Grid>
					<Grid container spacing={2} item xs={8} justifyContent="flex-end">
						<Grid item>
							<Button
								variant="text"
								onClick={closeDialog}
							>
								{setLanguageText('Cancel')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								fullWidth={false}
								onClick={submit_sign}
								color="primary"
								type="submit"
								variant='contained'
							>

								{setLanguageText('Confirm Signature')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			}
		>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid
					item
					sx={{
						border: '1px solid #ccc',
						padding: '1em',
						textAlign: 'center',
						marginTop: '1em'
					}}
				>
					<SignatureCanvas
						ref={sign_ref}
						canvasProps={{
							width: '400px',
							height: '200px',
							className: 'sigCanvas',
							margin: '0 auto'
						}}
						backgroundColor="#FFFFFF"
						fromDataURL={serviceObj.cust_sign || ''}
					/>
				</Grid>
			</Grid>

		</GlobalDialog>
	);
};

export default SignatureDialog;
