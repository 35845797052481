import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { FieldArray, Formik } from 'formik';
import * as Yup from "yup"
import { CompanyContext } from '../../../store/CompanyContext';
import { UserContext } from '../../../store/UserContext';
import { useMutation } from 'react-query';
import { SAVE_COMPANY_SETTINGS } from '../../../store/CompanyReducers';
import axiosRequest from '../../../coolantcare/helpers/axiosRequest';
import { LoadingButton } from '@mui/lab';

const SolutionsEmail = () => {

	const { user } = useContext(UserContext);
	const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
	const [emails, setEmails] = useState();

	useEffect(() => {

		if (selectedCompany?.company_settings?.solutions?.email) {
			//check if emails is a array for old settings
			if (Array.isArray(selectedCompany.company_settings.solutions.email)) {
				setEmails({ "emails": selectedCompany.company_settings.solutions.email })
			} else {
				setEmails({ "emails": [selectedCompany.company_settings.solutions.email] })
			}
		} else {
			//set a blank array
			setEmails({ "emails": [] })
		}
	}, [selectedCompany])

	const validation = Yup.object({
		emails: Yup.array().of(
			Yup.string()
				.email('Invalid email address')
				.required('Email address is required')
		),
	});

	const mutation = useMutation(values => {

		//make sure compnay setting are available
		if (!selectedCompany.company_settings) {
			return false
		}
		const request = {
			...selectedCompany.company_settings,
			solutions: {
				email: values.emails
			}
		}

		//console.log(request)
		return axiosRequest({
			endpoint: 'company/settings',
			api_key: user.api_key,
			method: 'patch',
			body: {
				company_id: selectedCompany.company_id,
				company_settings: request
			}
		})
	}, {
		onSuccess: (data, context) => {
			const request = {
				...selectedCompany.company_settings,
				"solutions": {
					"email": context.emails
				}
			}
			dispatchSelectedCompany({
				type: SAVE_COMPANY_SETTINGS,
				payload: {
					"company_settings": request
				}
			});
		}
	})

	//save columns
	const handleSubmit = (values) => {
		mutation.mutate(values)
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} >
				<Typography variant='h6'>
					Solution emails
				</Typography>
			</Grid>
			<Grid item xs={12} >
				<Alert severity='warning'>These email address will receive all <strong>Solutions</strong> requests for <strong>{selectedCompany.company_name}</strong>.</Alert>
			</Grid>
			<Grid item xs={12} >
				{emails ?
					<Formik
						enableReinitialize={true}
						initialValues={emails}
						validationSchema={validation}
						onSubmit={(values, actions) => {
							console.log(values)
							console.log(actions)
							handleSubmit(values)
						}}
					>
						{({ submitForm, setFieldValue, values, errors, touched }) =>
							<Grid
								container
								spacing={2}
								alignItems={'center'}
							>
								<Grid item xs={12} >
									<FieldArray name="emails">
										{({ push, remove }) => (
											<Grid container spacing={2}>
												{values.emails.map((_, index) => (
													<Grid item container key={index} alignItems={'center'} spacing={2}>
														<Grid item>
															<TextField
																sx={{ minWidth: 300 }}
																name={`emails[${index}]`}
																label={`Email ${index + 1}`}
																fullWidth
																value={values.emails[index]}
																onChange={(e) => {
																	const updatedFields = [...values.emails];
																	updatedFields[index] = e.target.value;
																	setFieldValue('emails', updatedFields);
																}}
																error={touched?.emails?.[index] && Boolean(errors?.emails?.[index])}
																helperText={touched?.emails?.[index] && errors?.emails?.[index]}
															/>
														</Grid>
														<Grid item>
															<Button
																color="secondary"
																onClick={() => remove(index)}
															>
																Remove email
															</Button>
														</Grid>
													</Grid>
												))}
												<Grid item xs={12}>
													<Button
														type="button"
														onClick={() => push("")} // Add a new item with an empty value
													>
														Add another email
													</Button>
												</Grid>
											</Grid>
										)}
									</FieldArray>
								</Grid>
								<Grid item xs={12} >
									<LoadingButton
										//disabled={isLoading}
										variant='contained'
										color='primary'
										onClick={submitForm}
										loading={mutation.isLoading}
									>
										Update Solution emails
									</LoadingButton>
								</Grid>
							</Grid>
						}
					</Formik>
					: null}
			</Grid>
			<Grid item xs={12} >
				<Divider sx={{ marginBottom: '1em' }} />
			</Grid>
		</Grid>
	);
};

export default SolutionsEmail;
