import { faPencilRuler, faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Grid,
	Card,
	CardContent,
	useTheme,
	Button,
	Box,
	Skeleton
} from '@mui/material';
import { entries } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { usePagination, useFilters, useRowState, useSortBy, useTable } from 'react-table';
import { UserContext } from '../../../../store/UserContext';
import Pagination from '../../../reusable/Pagination';
import TableSettings from '../../TableSettings';
import MyDatePicker from '../MyDatePicker';
import setLanguageText from '../../../../language/setLanguageText';
import getUserLevel from '../../../../helpers/getUserLevel';
import LoadingScreen from '../../../reusable/LoadingScreen';
import AddEntry from '../../../Entry/AddEntry';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const EntriesTable = ({
	columns,
	data,
	getTableDataFn,
	headerSettings,
	dateFrom,
	setDateFrom,
	dateTo,
	setDateTo,
	loading
}) => {
	const theme = useTheme();
	const { user } = useContext(UserContext);
	const [showVoid, setShowVoid] = useState(false);
	const [filterVoid, setFilterVoid] = useState([]);
	const viewSettings = entries(user?.user_attributes?.machine_view);
	const [open, setOpen] = useState(false);

	// Hide Table columns coming from settings
	const hiddenColumns = viewSettings.map(setting =>
		!setting[1] ? setting[0] : null
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		headerGroups,
		prepareRow,
		rows,
		//page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
		// The rest of these things are super handy, too ;)
		allColumns,
		getToggleHideAllColumnsProps,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		visibleColumns,
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data: filterVoid,
			// Table's Initial state
			initialState: {
				pageIndex: 0,
				hiddenColumns: [...headerSettings, ...hiddenColumns]
			},
			// Cell's Initial state
			initialCellStateAccessor: () => ({
				// used in the Status Column to handle the hover Popover
				anchorEl: null,
				isOpen: false
			})
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowState
	);

	useEffect(() => {
		if (data) {
			if (showVoid) {
				setFilterVoid(data);
			} else {
				const noVoids = data.filter(entry => !entry.void);
				setFilterVoid(noVoids);
			}
		}
	}, [showVoid, data]);

	//returns table data to parent component
	useEffect(() => {
		if (getTableDataFn) {
			rows && getTableDataFn(rows);
		}
	}, [getTableDataFn, rows]);


	return (
		<>
			<Grid item container xs={12} spacing={2}>
				<Grid item container spacing={1} justifyContent="space-between" alignItems="center">
					<Grid item container spacing={2} xs={12} md="auto" >
						{!isMobileOnly &&
							<Grid item xs={12} md="auto">
								<TableSettings
									showEntryOption
									showEntryText={setLanguageText("Show Voided")}
									showEntry={showVoid}
									setShowEntry={setShowVoid}
									allColumns={allColumns}
									getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
								/>
							</Grid>
						}
						<Grid item xs={12} md="auto">
							{getUserLevel(user.user, 'coolantcare', 'entry_add', 6) ?
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={e => setOpen(true)}
									startIcon={<FontAwesomeIcon icon={faPencilRuler} />}
									disabled={loading}
								>
									{setLanguageText("Create Machine Entry")}
								</Button>
								: null}
						</Grid>

					</Grid>
					<Grid item xs={12} md container spacing={2} justifyContent="flex-end">
						{dateTo ?
							<MyDatePicker
								dateFrom={dateFrom}
								setDateFrom={setDateFrom}
								dateTo={dateTo}
								setDateTo={setDateTo}
								size="small"
							/>
							: null}
					</Grid>
				</Grid>
			</Grid>
			<Grid item container xs={12}>
				<Card
					square
					elevation={0}
					sx={{
						display: 'grid', width: '100%',
						border: `1px solid ${theme.palette.table.border}`,
						position: 'relative',
					}}
				>
					<CardContent
						sx={{
							overflowY: 'auto',
							overflowY: 'auto',
							width: '100%',
							padding: 0,
							maxHeight: '70vh',

						}}
					>
						<div
							{...getTableProps()}
							className="table-vertical"
							style={{
								display: 'grid',
								gridTemplateRows: `repeat(${visibleColumns.length}, auto)`,
								gridAutoFlow: 'column',
								gridTemplateColumns: theme.spacing(20)
							}}
						>
							{
								// Loop over the header rows
								headerGroups.map(headerGroup =>
									// Apply the header row props
									headerGroup.headers.map((column,i) => {
										return (
											// Apply the header cell props
											<Grid
												item
												container
												alignItems="center"
												key={i}
												{...column.getHeaderProps(
													// add sort functionality
													column.getSortByToggleProps()
												)}
												sx={{
													textAlign: 'left',
													backgroundColor: theme => theme.palette.table.header,
													position: 'sticky',
													zIndex: 1,
													left: 0,
													padding: theme.spacing(1, 2),
													borderBottom: `1px solid ${theme.palette.table.border}`,
													borderRight: `1px solid ${theme.palette.table.border}`
												}}
											>
												{
													// Render the header
													column.render('Header')
												}
												{/* Render arrow depending of sorting type */}
												{column.isSorted ? (
													column.isSortedDesc ? (
														<FontAwesomeIcon icon={faSortDown} />
													) : (
														<FontAwesomeIcon icon={faSortUp} />
													)
												) : (
													''
												)}
											</Grid>
										);
									})
								)
							}
							{loading && headerGroups?.[0]?.headers ?
								new Array(headerGroups?.[0]?.headers.length * 5).fill(0).map((_, i) => {
									return (
										<Box
											key={i}
											sx={{
												textAlign: 'center',
												padding: theme => theme.spacing(1, 2),
												backgroundColor: theme => theme.palette.table.row,
												borderBottom: `1px solid ${theme.palette.table.border}`
											}}
										>
											<Skeleton variant="rounded" width={100} height={32} sx={{margin: 'auto', borderRadius: '50px'}} />
										</Box>


									)
								})

								: !loading && rows?.length > 0 ? rows.map((row, i) => {
									// Prepare the row for display
									prepareRow(row);
									return (
										// Loop over the rows cells
										row.cells.map((cell,i) => {
											//console.log(cell.row.original.void)
											// Apply the cell props
											return (
												<Box
													key={i}
													{...cell.getCellProps()}
													sx={{
														textAlign: 'left',
														padding: theme => theme.spacing(1, 2),
														backgroundColor: theme =>
															cell.row.original.void ? '#e9e9e9' :
																cell.row.original.out_of_control ? theme.palette.table.out :
																	theme.palette.table.row,
														borderBottom: `1px solid ${theme.palette.table.border}`
													}}
												>{
														// Render the cell contents
														cell.render('Cell')
													}
												</Box>
											);
										})
									);
								})
									: !loading && rows.length === 0 ?
										<LoadingScreen
											loading={false}
											background={theme.palette.background.lighter}
											colour={theme.palette.text.secondary}
											text={setLanguageText("This machine has no entries")}
											position="absolute"
											customAction={
												<Grid container justifyContent="space-between" alignContent="center">
													<Grid item sx={{ margin: 'auto' }}>
													{getUserLevel(user.user, 'coolantcare', 'entry_add', 6) ?
														<Button
															variant='contained'
															color="primary"
															onClick={e => setOpen(true)}
															startIcon={<FontAwesomeIcon icon={faPlus} />}
														>
															{setLanguageText("Add First Entry")}
														</Button>
														: null}
													</Grid>
												</Grid>
											}
										/>
										: null}
						</div>
					</CardContent>
					<Pagination
						allColumns={allColumns}
						rowCount={data?.length}
						pageIndex={pageIndex}
						gotoPage={gotoPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						previousPage={previousPage}
						canPreviousPage={canPreviousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageCount={pageCount}
					/>


				</Card>
				{open &&
					<AddEntry
						isDialog={open}
						setIsDialog={setOpen}
					/>
				}
			</Grid>
		</>
	);
};

export default EntriesTable;
