import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
import setLanguageText from '../../../language/setLanguageText';
import CardStyles from '../../styles/cardStyles';

function BarChart({ results }) {

	//console.log(results);

	const cardStyles = CardStyles();
	const theme = useTheme();
	const data = {
		datasets: [
			{
				data:
					results.data.map((item) =>
						item.count
					),
				backgroundColor:
					results.data.map((item) => {
						if (item?.colour?.hex) { 
							return item.colour.hex
						} else {
							return item.colour
						}
					})
			}
		],
		labels:
			results.data.map((item) =>
				item.label
			)
	};

	const options = {
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false
			},
			scales: {
				y: {
					ticks: {
						beginAtZero: true,
						stepSize: 1,
						stepValue: 1
					},
				}
			}
		}

	};

	return (
		<Card sx={{ height: '100%', width: '100%', display: "flex", flexDirection: "column" }}>
			<CardHeader
				className={cardStyles.header}
				disableTypography
				title={
					<Typography variant="h5" textAlign="center" >{results.name}</Typography>
				}
			/>
			<CardContent sx={{ margin: "auto 0" }} >
				<Bar data={data} options={options} />
			</CardContent>
			<CardActions
				className={cardStyles.footer}
			>
				<Grid item container xs={12} justifyContent="center">
					<Grid item xs={12} >
						<Typography
							textAlign={"center"}
							variant="body2"
						>{setLanguageText("Total Entries")}</Typography>
					</Grid>
					<Grid item xs={12} >
						<Typography
							variant="body2"
							align="center"
							style={{ marginLeft: theme.spacing(1) }}
						>
							<strong>
								{results.data.map((item) => item.count).reduce((a, b) => a + b, 0)}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);

}

export default BarChart;
