import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Divider
} from '@mui/material';
import config from './../../config';
import { LayoutContext } from '../../store/LayoutContext';
import OutlinedDiv from '../../coolantcare/reusable/OutlinedDiv';
import setLanguageText from '../../language/setLanguageText';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import ImageUpload from '../../coolantcare/reusable/ImageUpload';
import { useMutation } from 'react-query';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';
import Loader from '../../reusable/Loader';
import { APP_THEME } from '../../store/LayoutReducers';

const ApplicationDetails = () => {

    const { theme, language, dispatchTheme } = useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const [logo, setLogo] = useState('');
    const [logoBase64, setLogoBase64] = useState();
    const [emailLogo, setEmailLogo] = useState('');
    const [emailLogoBase64, setEmailLogoBase64] = useState();
    const [pdfLogo, setPdfLogo] = useState('');
    const [pdfLogoBase64, setPdfLogoBase64] = useState();

    useEffect(() => {
        if (theme) {
            //console.log(theme)
            setLogo(theme.login_logo)
            setEmailLogo(theme.email_logo)
            setPdfLogo(theme.pdf_logo)

        }
    }, [theme])

    const updateMainLogo = (file) => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setLogoBase64(reader.result)
            };
            reader.onerror = function (error) {
                //console.log('Error: ', error);
            };
        } else {
            setLogoBase64()
        }
    }

    const updateEmailLogo = (file) => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setEmailLogoBase64(reader.result)
            };
            reader.onerror = function (error) {
                //console.log('Error: ', error);
            };
        } else {
            setEmailLogoBase64()
        }
    }

    const updatePdfLogo = (file) => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setPdfLogoBase64(reader.result)
            };
            reader.onerror = function (error) {
                //console.log('Error: ', error);
            };
        } else {
            setPdfLogoBase64()
        }
    }

    const mutation = useMutation(() => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'application',
            api_key: user.api_key,
            method: 'post',
            body: {
                app_id: config.APPID,
                logos: true,
                login_logo: logoBase64 ? logoBase64 : null,
                email_logo: emailLogoBase64 ? emailLogoBase64 : null,
                pdf_logo: pdfLogoBase64 ? pdfLogoBase64 : null
            }
        })
    })

    //console.log(emailLogoBase64)
    const handleSubmit = () => {
        //console.log()
        mutation.mutate()
    }

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText("Application Details")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            name="app_name"
                            label={setLanguageText("Instance")}
                            variant="outlined"
                            value={config.APPNAME}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            name="app_mode"
                            label={setLanguageText("Title")}
                            variant="outlined"
                            value={config.APPTITLE}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            name="app_mode"
                            label={setLanguageText("Colour Mode")}
                            variant="outlined"
                            value={config.COLOURMODE}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <OutlinedDiv label={setLanguageText("Logo")} >
                            <ImageUpload
                                id="login-logo"
                                path={logo}
                                setImage={updateMainLogo}
                                edit={true}
                                sx={{ background: theme => theme.palette.primary.main }}
                            />
                        </OutlinedDiv>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <OutlinedDiv label={setLanguageText("Email Logo")} >
                            <ImageUpload
                                id="email-logo"
                                path={emailLogo}
                                setImage={updateEmailLogo}
                                edit={true}
                                sx={{ background: theme => theme.palette.primary.main }}
                            />
                        </OutlinedDiv>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <OutlinedDiv label={setLanguageText("PDF Logo")} >
                            <ImageUpload
                                id="pdf-logo"
                                path={pdfLogo}
                                setImage={updatePdfLogo}
                                edit={true}
                                sx={{ background: theme => theme.palette.primary.main }}
                            />
                        </OutlinedDiv>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={!logoBase64 && !emailLogoBase64 && !pdfLogoBase64}
                            variant='contained'
                            color='success'
                            onClick={handleSubmit}
                        >
                            {setLanguageText("Update Application")}
                        </Button>
                        <Grid item xs={12} sx={{ marginTop: '2em' }}>
                            <Divider />
                        </Grid>
                    </Grid>
                    {process.env.NODE_ENV === 'development' ?
                        <>
                            <Grid item xs={12} >
                                <Typography variant="h6">API Details</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Factory API")}
                                    variant="outlined"
                                    value={config.factoryAPI.URL}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Factory API")}
                                    variant="outlined"
                                    value={config.coolantAPI.URL}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Ecommerce API")}
                                    variant="outlined"
                                    value={config.shopAPI.URL}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Solutions API")}
                                    variant="outlined"
                                    value={config.solutionAPI.URL}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Fixtures API")}
                                    variant="outlined"
                                    value={config.fixturesAPI.URL}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="app_mode"
                                    label={setLanguageText("Design API")}
                                    variant="outlined"
                                    value={config.designAPI.URL}
                                    disabled
                                />
                            </Grid>
                        </> : null}
                </Grid>

            </TabContent>
            {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                <Loader
                    isLoading={mutation.isLoading}
                    mutation={mutation}
                    loadingMessage="Updating Application..."
                    successMessage="Application Updated"
                    callback={() => {
                        //navigate('/solution/dashboard')
                        //console.log('uploading files')

                        if (logoBase64) {
                            dispatchTheme({
                                type: APP_THEME,
                                payload: {
                                    login_logo: logoBase64
                                }
                            });
                            setLogoBase64()
                        }

                        if (emailLogoBase64) {
                            dispatchTheme({
                                type: APP_THEME,
                                payload: {
                                    email_logo: emailLogoBase64
                                }
                            });
                            setEmailLogoBase64()
                        }
                        if (pdfLogoBase64) {
                            dispatchTheme({
                                type: APP_THEME,
                                payload: {
                                    pdf_logo: pdfLogoBase64
                                }
                            });
                            setPdfLogoBase64()
                        }


                    }}
                />
                : null}
        </>
    );
};

export default ApplicationDetails;
