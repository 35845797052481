import React from 'react';
import { Helmet } from "react-helmet";
import config from '../config';

const GlobalMeta = ({ title, page }) => {
    //console.log(yoast_head_json)
    //console.log(page)
    return (
        <Helmet>
            <title>{title}</title>
            {   //only run in production
                process.env.NODE_ENV === 'production' && config?.CLARITY ?

                    <script type="text/javascript">
                        {`(function(c,l,a,r,i,t,y){
                            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                            t=l.createElement(r);
                            t.async=1;
                            t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];
                            y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "${config?.CLARITY}");`}
                    </script>
                    : null}
            {/* <meta name="description" content={page?.description ? page?.description : page?.excerpt?.rendered} />
            <meta name="robots" content={page?.robots ? page?.robots : 'index, follow'} /> */}
        </Helmet>
    );
}

export default GlobalMeta;
