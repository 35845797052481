import React from "react";
import ToggleButton from '@mui/material/ToggleButton';
import Image from "../reusable/Image";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";

const ItemCard = ({ item, selected }) => {

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


    return (

        <Grid container alignContent="stretch"
            sx={{
                position: 'relative',
                width: mobile ? '120px' : '220px',
                height: mobile ? '140px' : '200px',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: (theme) => selected !== item.ID ? theme.palette.cards.footer : theme.palette.primary.light,
                background: (theme) => selected !== item.ID ? theme.palette.cards.header : theme.palette.primary.light,
                '&:hover': {
                    borderColor: (theme) => theme.palette.primary.light,
                    //background: (theme) => theme.palette.cards.header,
                },
                // '.Mui-selected &': {
                //     borderColor: (theme) => theme.palette.primary.light,
                //     background: (theme) => theme.palette.primary.light,
                // }
            }}>
            <Box sx={{
                position: 'absolute',
                overflow: 'hidden',
                top: 0,
                right: 0,
                padding: '0 10px',
                backgroundColor: 'cards.header',
                borderColor: 'cards.footer',
                borderStyle: 'solid',
                borderWidth: '0 0 1px 1px',
                borderRadius: '0 0 0 10px',
                zIndex: 1
            }}>
                {/* <Typography component="p" variant="body1">{item.Hits}</Typography> */}
            </Box>
            <Grid item xs={12} >
                <Image isFetching={false} src={item.Image} alt={item.Label} ShowIcon={false}
                    height="100%"
                    sx={{
                        padding: mobile ? '10px' : '1em',
                        width: '100%',
                        height: mobile ? '80px' : '100px',
                        textAlign: 'center',
                        background: '#fff'
                        //background: (theme) => theme.palette.background.paper
                    }} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                sx={{
                    padding: mobile ? '5px' : '1em',
                    height: mobile ? '50px' : '100px'
                }}
                alignContent="center" >
                <Grid item xs={12} >
                    <Typography
                        textAlign="center"
                        variant="h6"
                        fontSize={mobile ? '13px' : "1.125em"}
                        lineHeight="1.2"
                        sx={{

                            color: (theme) => selected !== item.ID ? theme.palette.text.primary : theme.palette.primary.contrastText
                        }}
                    >{item.Label}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default ItemCard;
