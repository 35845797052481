import React from "react";
import { Box } from "@mui/material";
const TabHeader = ({ children }) => {

    return (
        <>
            {children ?
                <Box
                    sx={{
                        padding: '1em', width: '100%',
                        background: (theme) => theme.palette.tabs.header,
                        borderBottom: (theme) => `1px solid ${theme.palette.tabs.border}`
                    }} >
                    {children}
                </Box>
                : null}
        </>
    );
};
export default TabHeader;
