import React, { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
	date_picker: {
		margin: 0
	}
}));

function MyDatePicker({ dateFrom, dateTo, setDateFrom, setDateTo, ...props }) {

	const classes = useStyles();
	const today = useMemo(() => new Date(), []);
	const handleDateFromChange = date => {
		setDateFrom(date.setUTCHours(0, 0, 0, 0));
	};

	const handleDateToChange = date => {
		// if date selected is today, set dateTo to current time
		if (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		)
			return setDateTo(new Date().getTime());
		// else set it to 23:59:59
		setDateTo(date.setHours(23, 59, 59, 59));
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Grid item xs={6} sm={'auto'}>
				<DatePicker
					fullWidth
					inputVariant="outlined"
					margin="normal"
					id="date-picker-dialog"
					label={setLanguageText("FROM")}
					format="dd/MM/yyyy"
					value={dateFrom}
					onChange={handleDateFromChange}
					className={classes.date_picker}
					disableFuture
					sx={{
						'& input': { zIndex: 2 },
						'& legend, & .MuiFormLabel-root': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment, & .MuiIconButton-edgeEnd ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("From")}
					{...props}
				//componentsProps={{ textField: { variant: 'outlined' } }}
				//renderInput={(params) => <TextField {...params} />}
				/>
			</Grid>
			<Grid item xs={6} sm={'auto'}>
				<DatePicker
					fullWidth
					inputVariant="outlined"
					margin="normal"
					id="date-picker-dialog"
					label={setLanguageText("TO")}
					format="dd/MM/yyyy"
					value={dateTo}
					onChange={handleDateToChange}
					disableFuture
					className={classes.date_picker}
					{...props}
					sx={{
						'& input': { zIndex: 2 },
						'& legend, & .MuiFormLabel-root': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment, & .MuiIconButton-edgeEnd ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("To")}
				//componentsProps={{ textField: { variant: 'outlined' } }}
				//renderInput={(params) => <TextField {...params} />}
				/>
			</Grid>
		</LocalizationProvider>
	);
}

export default MyDatePicker;
