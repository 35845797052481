import React, { useContext, useState } from 'react';
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import setLanguageText from '../language/setLanguageText';
import RequestPriceEmail from './RequestPriceEmail';
import { LayoutContext } from '../store/LayoutContext';
import { UserContext } from '../store/UserContext';
import TabHeader from '../layout/TabHeader';
import RequestPriceConfirmationEmail from './RequestPriceConfirmationEmail';
import TabContent from '../layout/TabContent';
import DesignRequestEmail from './DesignRequestEmail';
import DesignCommentEmail from './DesignCommentEmail';
import { useMutation } from 'react-query';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import ReactDOMServer from 'react-dom/server';
import { useTheme } from '@emotion/react';
import OrderEmail from './OrderEmail';
import OrderConfirmationEmail from './OrderConfirmationEmail';

const EmailWrapper = ({ component }) => {
    return <div>{component}</div>;
};

const EmailTemplateViewer = () => {
    const [selected, setSelected] = useState();
    const { user } = useContext(UserContext);
    const { theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme();

    const send_test = useMutation((selected) => {
        console.log(selected);
        const emailString = ReactDOMServer.renderToString(
            <EmailWrapper component={selected.component} />
        );

        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'email',
            api_key: user.api_key,
            method: 'post',
            body: {
                //email: request.request_user.request_email,
                email: ['jim@design-monkey.co.uk'],
                html: emailString,
                subject: selected.name,
            },
        });
    });

    const sendEmail = () => {
        send_test.mutate(selected);
    };

    const templates = [
        {
            id: 1,
            name: 'Request Price Email',
            component: <RequestPriceEmail logo={layoutTheme.email_logo} theme={theme} />
        },
        {
            id: 2,
            name: 'Request Price Email Confirmation',
            component: <RequestPriceConfirmationEmail logo={layoutTheme.email_logo} theme={theme} />
        },
        {
            id: 3,
            name: 'Design Request',
            component: <DesignRequestEmail logo={layoutTheme.email_logo} theme={theme} />
        },
        {
            id: 4,
            name: 'Design Comment',
            component: <DesignCommentEmail logo={layoutTheme.email_logo} theme={theme} />
        },
        {
            id: 5,
            name: 'Order Email',
            component: <OrderEmail logo={layoutTheme.email_logo} theme={theme} />
        },
        {
            id: 6,
            name: 'Order Confirmation Email',
            component: <OrderConfirmationEmail logo={layoutTheme.email_logo} theme={theme} />
        },
    ];

    return (
        <>
            <TabHeader>
                <Grid item xs={12}>
                    <Grid item container justifyContent="space-between">
                        <Grid item xs={12} sm={'auto'}>
                            <Typography variant="h5">{setLanguageText('Email Templates')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TabHeader>
            <TabContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="email-template"
                            value={selected}
                            options={templates || []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setSelected(newValue);
                            }}
                            placeholder="Select Template"
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Template" />}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {selected && selected.component ? selected.component : null}
                    </Grid>
                </Grid>
                <Button onClick={sendEmail}>Send Test</Button>
            </TabContent>
        </>
    );
};

export default EmailTemplateViewer;
