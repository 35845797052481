import { Image, View, Text } from '@react-pdf/renderer';
import React from 'react';
import config from '../config';

const PDFHeader = ({ theme, logo, company_logo, title, noDivider }) => {

    return (
        <View  >
            <View style={{
                backgroundColor: config.INSTANCE === "act" ? '#FFF' : '#000',
                borderBottom: `4px solid ${config.INSTANCE === "act" ? theme.palette.primary.main : theme.palette.primary.main}`,
                textAlign: 'center', width: '100%', padding: '16px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row'
            }}>
                <View style={{ textAlign: 'center', flex: 1}}>
                    {logo ? <Image src={logo} style={{ width: '100%', maxWidth: '300px', height: 'auto', maxHeight: '200px', objectFit: 'contain', margin: '0 auto' }} /> : null}
                </View>
                {company_logo ?
                <>
                    <View style={{width: '200px'}}>
                    </View>
                    <View style={{ textAlign: 'center', flex: 1 }}>
                        <Image src={company_logo} style={{ width: '100%', maxWidth: '300px', height: 'auto', maxHeight: '80px', objectFit: 'contain', margin: '0 auto' }} />
                    </View>
                    </>
                    : null}
            </View>
            {title ? <View style={{ textAlign: 'center', width: '100%', padding: '16px 0' }}>
                <View style={{ textAlign: 'center', width: '100%' }}>
                    <Text style={{ width: '100%', textAlign: 'center', fontSize: 18, color: theme.palette.primary.main, marginTop: '10px', marginBottom: '16px' }} >{title}</Text>
                    {/* <Text style={{ width: '100%', textAlign: 'center', fontSize : 16 }} >{title}</Text> */}
                </View>
                {noDivider ? null :
                    <View>
                        <br style={{ width: '90%', height: '1px', backgroundColor: '#ccc', margin: '10px auto' }} />
                    </View>
                }

            </View >
                : null}
        </View >
    )
};

export default PDFHeader;  
