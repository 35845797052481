import { useContext, useEffect, useState } from 'react';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import { CompanyContext } from '../store/CompanyContext';
import { useQuery } from '@tanstack/react-query';

export default function useGetPrices({ productDetails, setProductDetails }) {

    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext);
    const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');
    const [retry, setRetry] = useState(1);
    
    const { data: opensearch_data , isFetching} = useQuery({
        queryKey: ['product', productDetails?.product_cim_number],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.opensearchAPI.URL,
            endpoint: 'tools-search',
            api_key: user.api_key,
            method: 'post',
            body: {
                "query": {
                    "query": {
                        "bool": {
                            "should": [
                                {
                                    "match_bool_prefix": {
                                        "product_code.cleaned": {
                                            "query": productDetails?.product_cim_number,
                                            "fuzziness": "auto",
                                            "boost": 2.0
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "cim_code": {
                                            "query": productDetails?.product_cim_code,
                                            "fuzziness": 0
                                        }
                                    }
                                }
                            ],
                            "minimum_should_match": 2
                        }
                    }
                }
            }
        }),
        enabled: !!(productDetails?.product_dataset === "cim") && !!user.api_key && !!productDetails?.product_cim_number,
        retry: 0
    })


    //cims product update
    useEffect(() => {
        if (opensearch_data?.hits?.hits?.length > 0) {
            
            let product = {
                "product_id": opensearch_data?.hits?.hits[0]?._source?.product_id,
                "product_description": opensearch_data?.hits?.hits[0]?._source?.description,
                "product_code": opensearch_data?.hits?.hits[0]?._source?.product_code,
                "product_supplier": opensearch_data?.hits?.hits[0]?._source?.supplier,
                "product_supplier_number": opensearch_data?.hits?.hits[0]?._source?.supplier_number,
                "product_packet_size" : opensearch_data?.hits?.hits[0]?._source?.packet_size,
            }
            setProductDetails(current => {
                return ({
                    ...current,
                    ...product
                })
            })
        } else if(opensearch_data?.hits?.hits?.length === 0){ 
            //console.log('no product found')
            setProductDetails(current => {
                return ({
                    ...current,
                    "error_type": "no_product",
                    "error": "Product not found"
                })
            })

        }
    }, [opensearch_data])


    const { data, refetch } = useQuery({
        queryKey: ['prices', productDetails?.product_id, selectedCompany?.company_erp?.AccountNumber],
        queryFn: ({ signal }) => {
            let params = {
                "supplier": productDetails?.product_supplier,
                "account": selectedCompany?.company_erp.AccountNumber,
                "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
            }
            if (productDetails?.product_code) {
                params = {
                    ...params,
                    "code": productDetails.product_code
                }
            }
            if (productDetails?.product_id) {
                params = {
                    ...params,
                    "id": productDetails.product_id
                }
            }
            return (
                axiosRequest({
                    signal: signal,
                    gateway: config.opensearchAPI.URL,
                    endpoint: 'tools-search/price',
                    api_key: user.api_key,
                    method: 'get',
                    params: params
                })
            )
        },
        enabled: !!productDetails?.product_id && !!selectedCompany?.company_erp?.AccountNumber && (!!ACTstoredToken || !!CISstoredToken),
        retry: 0,
        //cacheTime: 0 
    })

    useEffect(() => {
        if (data) {
            //ACT TOKEN ERROR
            if (data?.ErrorMessage === "Invalid bearer token.") {
                //console.log('invalid token')
                sessionStorage.removeItem('ACTtoken');
                window.location.reload();
            }
            //any other errors
            else if (data?.errorMessage || data?.ErrorMessage) {

                //console.log(data?.errorMessage)
                //console.log(data?.ErrorMessage)

                if(data?.ErrorMessage && data?.ErrorMessage.includes("Withdrawn")){
                    setProductDetails(current => {
                        return ({
                            ...current,
                            "error_type": "no_product",
                            "error": data?.ErrorMessage
                        })
                    })
                    return;
                }
                //503 service unavailable
                if((data?.ErrorMessage && data?.ErrorMessage.includes("503"))){
                    setProductDetails(current => {
                        return ({
                            ...current,
                            "error_type": "erp_error",
                            "error": "We’re currently experiencing a problem connecting to our trading system, please try again in a few minutes.",
                            // "price_rrp": parseFloat(productDetails.product_rrp),
                            // "price_incl_discount": parseFloat(productDetails.product_rrp),//no discount
                            // "price_discount": 0,
                            // "product_unit": productDetails?.product_packet_size ? parseInt(productDetails.product_packet_size) : 1,
                            // "product_stock": 0,
                            // "product_free_stock": 0,
                            // "product_erp_code": productDetails?.product_id
                        })
                    })
                    return;
                }

                //if there is a timeout, refetch up to 3 times
                if(config.APPNAME === "cis" && retry < 3){
                    console.log('timeout and re-fetching - ' + retry)
                    setTimeout(() => {
                        setRetry(current => current + 1)
                        refetch()
                    }, 3000)
                    return;
                }

                if (productDetails.product_rrp) {
                    const priceObject = {
                        "error": data?.errorMessage || data?.ErrorMessage,
                        "price_rrp": parseFloat(productDetails.product_rrp),
                        "price_incl_discount": parseFloat(productDetails.product_rrp),//no discount
                        "price_discount": 0,
                        "product_unit": productDetails?.product_packet_size ? parseInt(productDetails.product_packet_size) : 1,
                        "product_stock": 0,
                        "product_free_stock": 0,
                        "product_erp_code": productDetails?.product_id
                    }
                    setProductDetails(current => {
                        return ({
                            ...current,
                            ...priceObject
                        })
                    })
                }

            } else {
                const priceObject = {
                    "error": null,
                    "product_id": data?.ArticleNumber, //need this for favourites
                    "price_rrp": data?.PriceRRP,
                    "price_incl_discount": data?.PriceInclDiscount,
                    "price_discount": data?.PriceDiscount,
                    "product_unit": productDetails?.product_packet_size ? parseInt(productDetails.product_packet_size) : data?.Unit,
                    "product_stock": data?.TotalStock,
                    "product_free_stock": data?.FreeStock,
                    "product_erp_code": data?.ArticleNumber,
                    "product_erp": data?.FullProduct,
                }
                setProductDetails(current => {
                    return ({
                        ...current,
                        ...priceObject
                    })
                })
            }
        }
        return () => {
        }
    }, [data,retry])

    return {
        "isFetchingPrice": isFetching,
    }
};

