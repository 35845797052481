import React, {
    useState,
    useContext,
} from "react";
import ProductLayout from "../products/ProductLayout";
import ProductOptions from "../../products/shared/ProductOptions";
import { ProductsContext } from "../../store/ProductsContext";
import { Grid, useMediaQuery } from "@mui/material";
import ResultFilters from "../parametric/ResultFilters";
import InnerContentWrap from "../../layout/InnerContentWrap";
import ResultToggleFilters from "../filters/ResultToggleFilters";
import { isMobileOnly } from 'react-device-detect';
import useCreateParametricFilters from "../hooks/useCreateParametricFilters";
import ResultToggleSearch from "../filters/ResultToggleSeach";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";
import useProductAttrbuites from "../../hooks/useProductAttributes";
import useProductObject from "../../hooks/useProductObject";
import SearchHeader from "../../search/SearchHeader";

const ParametricProductsLayout = (props) => {

    const { products } = useContext(ProductsContext);
    const [filterShow, setFilterShow] = useState(false);
    const { attributes, setAttributes, detailProfile } = useProductAttrbuites();
    const { isFetching } = useProductObject();
    const [focused, setFocused] = useState(false);

    //create parametric filters
    useCreateParametricFilters(detailProfile)

    const closeFilters = (e) => {
        setFilterShow(e);
    };

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <SearchHeader
                link={true}
                large={true}
                setFocused={setFocused}
                focused={focused}
                backdrop={true}
                props={props}
            />
            <InnerContentWrap>
                <Grid container item spacing={isMobileOnly ? 1 : 2} alignItems="flex-start" >
                    <Grid container spacing={isMobileOnly ? 1 : 2} item xs={12} md={4} lg="auto"
                        sx={{
                            width: lgUp ? '400px!important' : 'auto',
                            maxWidth: lgUp ? '400px!important' : '100%'
                        }}>
                        <ResultFilters closeFilters={closeFilters} />
                    </Grid>

                    <Grid item container spacing={isMobileOnly ? 1 : 2} xs={12} md={8} lg>

                        <ResultToggleFilters fixed />
                        <ResultToggleSearch />

                        {products.noProducts ? (
                            <Grid item xs={12} sx={{ margin: '0 0 1em 0' }} >
                                <ProductsNotAvailable />
                            </Grid>
                        ) :
                            <>
                                <ProductOptions isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                <ProductLayout isFetching={isFetching} attributes={attributes} setAttributes={setAttributes} />
                                <ProductOptions isFetching={isFetching} bottom />
                            </>
                        }
                    </Grid>
                </Grid>
            </InnerContentWrap>
        </>
    );
};

export default ParametricProductsLayout;
