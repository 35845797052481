import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Divider, Grid, InputAdornment, InputLabel, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_APPLICATION_FILTERS } from "../../../store/SelectedReducers";
import showFieldLogic from "../../libs/showFieldLogic";

const Float = ({ field, focus, focusField, updateValue }) => {

    const [hide, setHide] = useState(true);
    const defaultOperator = "e";
    const [localValueTo, setLocalValueTo] = useState('');
    const [localValueFrom, setLocalValueFrom] = useState('');
    const [localOperator, setLocalOperator] = useState(defaultOperator);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const focusRef = useRef()

    useEffect(() => {
        if (focus === field.Abbreviation) {
            focusRef.current.focus()
        }
    }, [focus, field]);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValueTo('')
            setLocalValueFrom('')
            setLocalOperator(defaultOperator)
        }
    }, [selected.resetFilters, defaultOperator]);

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let appSearch = JSON.parse(urlParams.get("app"))
            if (appSearch && Object.values(appSearch).length ) {
                //console.log(appSearch[0])
                let LGE = appSearch[0].filter(element => element.includes(`${field.Abbreviation}§LGE`))[0];
                //console.log(LGE)
                 if (LGE) {
                    let string = LGE.split("§")
                    let values = string[1].replace("LGE", '').slice(1, -1)
                    let array = values.split(",")
                    if (array[0]) {
                        setLocalOperator(array[0])
                    }
                    if (array[1]) {
                        setLocalValueFrom(array[1])
                    }
                    if (array[2]) {
                        setLocalValueTo(array[2])
                    }
                    updateSelected(array[1], array[2], array[0]);
                 }
            }
        }
    }, [field]);


    const updateSelected = useCallback((from, to, operator) => {
        let values = ''
        let tolerance = 0.0
        if (from) {
            values = from
            if (to && operator === "e") {
                tolerance =  parseInt(to)
            } 
        }
        let result = {
            Field: field.Abbreviation,
            Values: [values],
            Comparison: operator,
            Tolerance: tolerance
        }
        if (Object.values(result.Values).length > 0) {
            dispatchSelected({
                type: UPDATE_APPLICATION_FILTERS,
                payload: result
            });
        }
    }, [dispatchSelected, field]);


    const handleChangeFrom = (e) => {
        setLocalValueFrom(e.target.value)
        updateSelected(e.target.value, localValueTo, localOperator)
        //set values for image 
        if (e.target.value) {
            updateValue({ 'fieldAbb': field.Abbreviation, 'value': `${e.target.value}${field.Unit}` })
        } else {
            updateValue({ 'fieldAbb': field.Abbreviation })
        }
    }

    const handleChangeTo = (e) => {
        setLocalValueTo(e.target.value)
        updateSelected(localValueFrom, e.target.value, localOperator)
    }

    const handleChange = (e, value) => {
        //console.log(value)
        if (value) {
            updateSelected(localValueFrom, localValueTo, value)
            setLocalOperator(value)
        }
    }

    //hide fields based on the logic
    useEffect(() => {
        if (field.Condition) {

            let showField = showFieldLogic(field.Condition, selected.applicationFilters)
            setHide(!showField)
            if (!showField && (localValueFrom || localValueTo)) {
                //clear if input has a value selected and the field become hidden
                //console.log('remove')
                setLocalValueTo('')
                setLocalValueFrom('')
                updateSelected('', '', localOperator)
            }

        } else {
            //if there are not conditions show field
            setHide(false)
        }
    }, [field.Condition, selected.applicationFilters])

    return (
        <Grid item xs={12} sx={{ display: hide ? "none" : "grid" }} >
            <InputLabel htmlFor={field.Label} sx={{ position: 'relative', transform: 'inherit', margin: '0 0 5px 0' }}>
                <Typography sx={{ color: 'text.primary' }} ><span style={{ display: 'inline-block', color: '#ccc' }}>{field.Abbreviation} - </span> {field.Label}</Typography>
            </InputLabel>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={3} >
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        value={localOperator}
                        exclusive
                        onClick={e => focusField(field.Abbreviation)}
                        onChange={(e, value) => handleChange(e, value)}
                        sx={{ height: '100%' }}
                        size="small"
                    >
                        <ToggleButton value="l">≤</ToggleButton>
                        <ToggleButton value="e">=</ToggleButton>
                        <ToggleButton value="g">≥</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs>
                    <TextField
                        inputRef={focusRef}
                        autoComplete='off'
                        id="float-from"
                        //label={localOperator === "l" ? "Les Than" : localOperator === "g" ? "Greater Than" : localValueTo ? "Between" : "Equal To"}s
                        label={''}
                        InputLabelProps={{ shrink: false }}
                        value={localValueFrom}
                        onClick={e => focusField(field.Abbreviation)}
                        onChange={handleChangeFrom}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                                    {field.Unit}
                                </InputAdornment>
                            ,
                        }}
                        sx={{ width: '100%', '& legend': { display: 'none' } }}
                        size="small"
                    />
                </Grid>
                {localOperator == "e" ?
                    <>
                        <Grid item xs={'auto'} sx={{ width: '20px', display: 'flex', textAlign: 'center' }} alignItems="center" >
                            <Typography sx={{ width: '100%', color: 'text.primary' }}>±</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                autoComplete='off'
                                id="float-to"
                                //label="Between"
                                label={''}
                                InputLabelProps={{ shrink: false }}
                                value={localValueTo}
                                onClick={e => focusField(field.Abbreviation)}
                                onChange={handleChangeTo}
                                type="number"
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*',
                                    min: 0,
                                    //step: "0.0001" 
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Divider sx={{ height: 28, m: 0.5, mr: 1 }} orientation="vertical" />
                                            {field.Unit}
                                        </InputAdornment>
                                    ,
                                }}
                                sx={{ width: '100%', '& legend': { display: 'none' } }}
                                size="small"
                            />
                        </Grid>
                    </>
                    : null}
            </Grid>
        </Grid>
    );
};

export default Float;
