import { Alert } from "@mui/material";
import React from "react";

const ProductsNotAvailable = (props) => {

    return (
        <Alert variant="standard" severity="error"> No products with thoses attributes, please refine your filters.</Alert>
    );
};

export default ProductsNotAvailable;
