import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UPDATE_DATE } from '../../store/SelectionsReducers';
import EntryAttributeTypeSelector from '../types/Entry_AttributeTypeSelector';
import { isMobileOnly } from 'react-device-detect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import setLanguageText from '../../../language/setLanguageText';
import { parseISO } from 'date-fns';

export default function StandardEntries({ setError, edit }) {

    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const [attributes, setAttributes] = useState([]);

    useMemo(() => {
        const filteredAttributes = addNewEntry.company.company_attributes.filter(
            attr =>
                attr.one.attr_id === 'ph' ||
                attr.one.attr_id === 'conc' ||
                attr.one.attr_id === 'tramp_oil'
        );
        setAttributes(filteredAttributes);
    }, [addNewEntry.company.company_attributes]);

    const handleDate = date => {
        const createDate = new Date(date).toISOString();
        dispatchAddNewEntry({ type: UPDATE_DATE, payload: createDate });
    };

    return (
        addNewEntry.entry && (

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            size="medium"
                            label={setLanguageText("Date")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={parseISO(addNewEntry.entry.date_stamp)}
                            onChange={handleDate}
                            disableFuture
                        />
                    </LocalizationProvider>
                </Grid>

                {attributes.map(attr => {
                    const attribute = addNewEntry.entry.attributes_new.find(
                        contextAttr => contextAttr.attribute_id === attr.one.attr_id
                    );

                    return (
                        <EntryAttributeTypeSelector
                            key={attr.one.attr_id}
                            attribute={attr}
                            initialValue={attribute?.attribute_values.value}
                            setError={setError}
                            edit={edit}
                        />
                    );
                })}
            </Grid>
        )
    );
}
