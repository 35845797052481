import {
	Grid,
	IconButton,
	TextField,
	Typography,
	Button
} from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { Autocomplete, Alert } from '@mui/material';
import React, {
	useContext,
	useEffect,
	useState
} from 'react';
import { SelectionsContext } from '../store/SelectionsContext';
import { UserContext } from '../../store/UserContext';
import { useTheme } from '@mui/styles';
import { UPDATE_ADDITIVE } from '../store/SelectionsReducers';
import setLanguageText from '../../language/setLanguageText';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import { useQuery } from '@tanstack/react-query';


function AddititveEntries({ setError, errors }) {

	const theme = useTheme();
	const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
	const { user } = useContext(UserContext);
	const [additives, setAdditives] = useState([]);
	const [selectedAdditive, setSelectedAdditive] = useState(null);
	const [addedAdditives, setAddedAdditives] = useState(addNewEntry?.entry?.additives);
	const [dosage, setDosage] = useState('');

	//get types
	const { data: types } = useQuery({
		queryKey: ['types'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants', //products
			api_key: user.api_key,
			method: 'get',
			params: {
				get_types: true
			}
		}),
		retry: false
	})

	const { data } = useQuery({
		queryKey: ['additives', addNewEntry?.company?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				company_id: addNewEntry?.company?.company_id,
				type: 2 //additives
			}
		}),
		enabled: !!addNewEntry?.company?.company_id,
		retry: false
	})

	useEffect(() => {
		if (data && types) {
			//match the type name to the type id
			//get additive types
			let additiveType = types.find(type => type.type_id === 2);
			//find template for type
			let template = additiveType.type_template.find(template => template.name === 'type');
			data.map(additive => {
				//find matching type
				//console.log(additive)
				const type = template.options.find(option => option.path === additive?.product_defaults?.defaults?.type);
				//console.log(type)
				if (type) {
					additive.product_type_name = type.name
				}
				return additive;
			})
			setAdditives(data);
		}
	}, [data, types]);


	const handleAdditiveSelect = (dosage, selectedAdditive) => {

		console.log(dosage)
		console.log(selectedAdditive)
		//add new object to current additives
		setAddedAdditives(prevState => [...prevState, {
			'product_id': selectedAdditive.product_id,
			'product_name': selectedAdditive.product_name,
			'product_type_name': selectedAdditive.product_type_name,
			'product_input': {
				'dilution': dosage
			}
		}]);
		setDosage('');
		setSelectedAdditive();
	};

	useEffect(() => {
		//console.log(addedAdditives)
		dispatchAddNewEntry({ type: UPDATE_ADDITIVE, payload: addedAdditives });

	}, [addedAdditives]);

	const handleRemoveAdditive = (additive) => {
		//remove from addedAdditives
		setAddedAdditives(prevState => prevState.filter(item => item.product_id !== additive.product_id));
	};

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid container item justifyContent="space-evenly" alignItems="center" spacing={2}>
				<Grid item xs={12} md={4}>
					<Autocomplete
						fullWidth
						options={additives}
						// inside the input when selected
						getOptionLabel={option =>
							Object.entries(option).length
								? `${option.product_name} - ${option.product_type_name}`
								: ''
						}
						renderInput={params => (
							<TextField
								{...params}
								label={setLanguageText("Select additive")}
								variant="outlined"
							/>
						)}
						value={selectedAdditive ? selectedAdditive : {}}
						onChange={(e, newValue, reason) => {
							console.log(newValue)
							console.log(reason)
							if (reason === 'clear') {
								setDosage('')
								setSelectedAdditive()
							} else {
								setSelectedAdditive(newValue)
							}
						}}
					/>
				</Grid>
				<Grid item xs={6} md="auto">
					<TextField
						value={selectedAdditive?.product_defaults?.defaults?.dilution || ''}
						color='primary'
						//variant="filled"
						disabled
						id="dosage"
						label={setLanguageText("Suggested Dosage")}
						InputLabelProps={{ shrink: selectedAdditive?.product_defaults?.defaults?.dilution ? true : false }}
					/>
					{/* </Grid>
						<Chip
							color="primary"
							variant="outlined"
							label={selectedAdditive?.product_defaults?.defaults?.dilution} />
					</Grid> */}
				</Grid>
				<Grid item xs={6} md="auto">
					<TextField
						value={dosage}
						required
						id="dosage"
						label={setLanguageText("Dosage Used")}
						variant="outlined"
						onChange={(e) => {
							setDosage(e.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={12} md="auto">
					<Button
						fullWidth
						disabled={!dosage || !selectedAdditive}
						variant="contained"
						color="primary"
						onClick={() => handleAdditiveSelect(dosage, selectedAdditive)}
					>
						{setLanguageText("Add Additive")}
					</Button>

				</Grid>
			</Grid>
			{addNewEntry?.entry?.additives ? addNewEntry.entry.additives.map((addedAdditive, i) => {
				return (
					<Grid
						item
						container
						key={i}
						alignItems="center"
						xs={12}
						spacing={2}
					>
						<Grid item xs={12} md={10}>
							<Alert severity="success" >
								<Typography>
									{setLanguageText("Additive")}: {addedAdditive.product_name} - {addedAdditive.product_type} ({addedAdditive.product_details})
								</Typography>
							</Alert>

						</Grid>
						<Grid item xs={12} md={2}>
							<Button
								fullWidth
								variant="contained"
								color="error"
								onClick={() => handleRemoveAdditive(addedAdditive)}
							>
								{setLanguageText("Remove")}
							</Button>
						</Grid>
					</Grid>
				);
			})
				:
				<Grid item xs={12}>
					<Alert severity="info" >
						<Typography>
							{setLanguageText("No Additives Added")}
						</Typography>
					</Alert>
				</Grid>
			}
		</Grid>
	);
}

export default AddititveEntries;
