import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../store/LayoutContext';
import { Breadcrumbs, Typography, Link, Box, useMediaQuery } from '@mui/material';
import config from '../config';
import { useLocation, useNavigate } from 'react-router-dom';
import { UPDATE_MENU } from '../store/LayoutReducers';
import { SelectedContext } from '../store/SelectedContext';
import { RESET_ALL_FILTERS } from '../store/SelectedReducers';


const AreaBreadcrumb = ({ setTitle }) => {

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { dispatchApp , app} = useContext(LayoutContext)
    const { dispatchSelected } = useContext(SelectedContext)
    const [breadcrumb, setBreadcrumb] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        //console.log(location.pathname)
        //console.log(app)

        if (location?.pathname && app.menu) {
            let path = location.pathname

            //console.log(breadcrumb)

            const createBreadcrumb = (arr, link, path) => (
                arr.reduce((a, menu) => {
                    if (!path) { path = []; }
                    if (a) return a;
                    if (menu.link === link) return [...path, menu];
                    if (menu.submenu) return createBreadcrumb(menu.submenu, link, [...path, menu])
                }, null)
            );

            let breadcrumb
            //console.log(menu)
            //console.log(path)

            //if its a product path remove product id
            if (path.includes('product/')) {
                path = path.substring(0, path.lastIndexOf('/'));
            }
            if (path.includes('machine/')) {
                path = path.substring(0, path.lastIndexOf('/'));
            }
            if (app.menu && path) {
                breadcrumb = createBreadcrumb(app.menu, path);
            }
            //breadcrumb = findItemNested([categories.allCats], currentCat.ID);
            //console.log(breadcrumb)

            if (breadcrumb) {
                setBreadcrumb(breadcrumb)
                setTitle(breadcrumb[0]?.titleComponent)
            }
        }
    }, [location.pathname, app])

    //breadcrumb fix
    const breadcrumbClick = (breadcrumb) => {
        dispatchApp({
            type: UPDATE_MENU,
            payload: {
                selectedArea: breadcrumb.area,
                selectedMenu: breadcrumb.menu,
                selectedSubMenu: breadcrumb.submenu
            }
        })
        dispatchSelected({
            type: RESET_ALL_FILTERS
        });

        navigate(breadcrumb.link)
    }

    return (
        <Box>
            {breadcrumb ?
                <Breadcrumbs aria-label="breadcrumb" separator="›"
                    sx={{
                        color: (theme) => theme.palette.text.primary
                    }} >
                    <Link
                        onClick={e => {
                            dispatchApp({
                                type: UPDATE_MENU,
                                payload: {
                                    selectedArea: 'home',
                                    selectedMenu: ''
                                }
                            })
                            dispatchSelected({
                                type: RESET_ALL_FILTERS
                            });
                            navigate('/')
                        }}
                        underline="hover"
                        sx={{
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        {config.APPNAME === 'act' ?
                            <Typography variant="body1"
                                sx={{
                                    fontWeight: '700',
                                    color: (theme) => theme.palette.primary.main,
                                    fontSize: mobile ? '13px' : '1em',
                                    '.MuiListItemButton-root:hover &, .Mui-selected &': {
                                        color: (theme) => theme.palette.text.primary
                                    }
                                }}>My<Typography
                                    component="span"
                                    sx={{
                                        fontWeight: '400', color: (theme) => theme.palette.text.primary
                                    }}>Factory</Typography></Typography>
                            :
                            <Typography variant="body1"
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    fontSize: mobile ? '13px' : '1em'
                                }} component="h3">Smart Factory</Typography>
                        }
                    </Link>
                    {breadcrumb.map((bread, i) =>
                        breadcrumb.length !== i + 1 ? (
                            <Link
                                key={i}
                                onClick={e => breadcrumbClick(bread)}
                                underline="hover"
                                sx={{
                                    textDecoration: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                {bread.breadcrumbComponent}
                            </Link>
                        ) : (
                            <Link
                                key={i}
                                onClick={e => breadcrumbClick(bread)}
                                sx={{
                                    textDecoration: 'none',
                                    '& h3': {
                                        fontWeight: 700,
                                        fontSize: mobile ? '13px' : '1em'
                                    }
                                }}
                            >
                                {bread.breadcrumbComponent}
                            </Link>
                        )
                    )}
                </Breadcrumbs>
                : null}
        </Box>

    );
};
export default AreaBreadcrumb;