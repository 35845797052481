import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Grid,
	Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buttonStyles } from '../styles/buttonStyles';
import BrandsTable from './BrandsTable';
import {
	faEdit,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../store/UserContext';
import { BrandsContext } from '../store/BrandsContext';
import { SAVE_BRANDS } from '../store/BrandsReducers';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import GlobalDialog from '../../reusable/GlobalDialog';
import AddBrand from './AddBrand';
import BrandForm from './BrandForm';
import { Storage } from 'aws-amplify';
import config from '../../config';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import TabContent from '../../layout/TabContent';
import TabHeader from '../../layout/TabHeader';
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosRequest from '../../axios/axoisRequest';
import DialogLoader from '../../reusable/DialogLoader';
import ProductImage from '../reusable/ProductImage';

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%'
	},
	header: {
		borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
			}`,
		padding: theme.spacing(1)
	},
	image: {
		'& img': {
			height: '100px',
			width: '100px',
			objectFit: 'contain'
		}
	}
}));

export default function Brands() {

	const classes = useStyles();
	const buttonStyle = buttonStyles();
	const { user } = useContext(UserContext);
	const { brands, dispatchBrands } = useContext(BrandsContext);
	const [editOpen, setEditOpen] = useState(false);
	const [addOpen, setAddOpen] = useState(false);
	const [additiveImage, setAdditiveImage] = useState();
	const [coolantImage, setCoolantImage] = useState();
	const [brand, setBrand] = useState();
	const [loading, setLoading] = useState(false);

	const {
		updateActivityLog,
	} = useActivityLog();

	//get brands
	const { data, refetch } = useQuery({
		queryKey: ['brands'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'get'
		}),
		enabled: !!user.api_key,
		retry: false
	})

	useEffect(() => {
		if (data) {
			dispatchBrands({
				type: SAVE_BRANDS,
				payload: data
			});
		}
	}, [data])

	//add images to s3 bucket
	const uploadImages = async () => {

		Storage.configure({
			AWSS3: {
				bucket: config.s3.BUCKET,
				region: config.s3.REGION
			}
		});

		let imagesUpdated = { coolantImage: false, additiveImage: false }
		//console.log(coolantImage)
		if (coolantImage) {
			try {
				await Storage.put(`brands/${brand.brand_id}_coolant.png`, coolantImage, {
					ACL: 'public-read',
					contentType: 'image/png',
					visibility: 'public',
					level: 'public',
				});

				imagesUpdated = {
					...imagesUpdated,
					coolantImage: true,
				}

			} catch (err) {
				console.log(err);
			}
		}
		if (additiveImage) {
			try {
				await Storage.put(`brands/${brand.brand_id}_additive.png`, additiveImage, {
					ACL: 'public-read',
					contentType: 'image/png',
					visibility: 'public',
					level: 'public',
				});
				imagesUpdated = {
					...imagesUpdated,
					additiveImage: true,
				}

			} catch (err) {
				console.log(err);
			}
		}
		updateActivityLog.mutate({
			activity_details: {
				area: "coolant",
				brand_id: brand.brand_id,
				imagesUpdated
			},
			activity_type: "edit brand"
		})
		//refetch brands
		setLoading(false)
		refetch()
		setEditOpen(false)
	}

	//edit brand
	const editBrand = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: () => {
			console.log('success')
			//upload images
			uploadImages()
		}
	})

	const table_columns = useMemo(() => [
		{
			id: 'brand_name',
			name: 'Brand Name',
			Header: () => <Typography variant="h6">{setLanguageText("Brand Name")}</Typography>,
			accessor: 'brand_name',
		}, {
			id: 'coolant_image',
			name: 'Coolant Image',
			Header: () => <Typography variant="h6">{setLanguageText("Coolant Image")}</Typography>,
			accessor: 'coolant_image',
			Cell: ({ row }) => {
				console.log(row)
				return (
					<Grid className={classes.image}>
						{row.original ? <ProductImage product={row.original} /> : null}
					</Grid>
				)
			}
		}, {
			id: 'additive_image',
			name: 'Additive Image',
			Header: () => <Typography variant="h6">{setLanguageText("Additive Image")}</Typography>,
			accessor: 'additive_image',
			Cell: ({ row }) => {
				//console.log(row)
				return (
					<Grid className={classes.image}>
						{row.original ? <ProductImage product={row.original} /> : null}
					</Grid>
				)
			}
		}, {
			id: 'Actions',
			Header: () => <Typography variant="h6">{setLanguageText("Actions")}</Typography>,
			sortable: false,
			minWidth: 50,
			width: 50,
			maxWidth: 50,
			Cell: ({ row }) => {
				return (
					<Grid container spacing={1} justifyContent="center">
						<Grid item xs={12}>
							<PrimaryBtnIcon
								icon={faEdit}
								text="Edit"//language in button component
								//iconSize="2x"
								onClick={e => openEditDialog(e, row)}
								size="small"
							/>
						</Grid>
					</Grid>
				)
			}
		}
	], [classes.image]);

	const updateBrand = e => {
		setBrand((brand) => {
			return {
				...brand,
				brand_name: e.target.value
			}
		});
	};

	const handleSubmit = (e) => {
		//console.log('save edit')
		e.preventDefault()
		let body = {
			brand_id: brand.brand_id,
			brand_name: brand.brand_name,
			coolant_image: coolantImage ? `${brand.brand_id}_coolant.png` : brand.coolant_image,
			additive_image: additiveImage ? `${brand.brand_id}_additive.png` : brand.additive_image
		}
		setLoading(true)
		//edit brand
		editBrand.mutate(body)
	}

	const openEditDialog = (e, row) => {
		setBrand(row.original);
		setEditOpen(true);
	};

	const closeAddDialog = e => {
		setAddOpen(false);
	};

	const openAddDialog = e => {
		setAddOpen(true);
	};

	const updateCoolantImage = (e) => {
		setCoolantImage(e)
	}
	const updateAdditiveImage = (e) => {
		setAdditiveImage(e)
	}

	return (
		<>

			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("Coolant/Oils Brands")}</Typography>
						</Grid>
						{user.user.user_permissions.administrator && (
							<Grid item xs={12} sm={'auto'}>
								<Button
									fullWidth
									onClick={openAddDialog}
									variant="contained"
									color="primary"
									className={buttonStyle.fixPointerEvent}
								>
									<FontAwesomeIcon icon={faPlus} />
									{/* <FontAwesomeIcon icon={faIndustry} /> */}
									<Typography className={buttonStyle.buttonIconText}>
										{setLanguageText("Add Brands")}
									</Typography>
								</Button>
								<AddBrand
									brand={brand}
									setBrand={setBrand}
									updateAdditiveImage={updateAdditiveImage}
									updateCoolantImage={updateCoolantImage}
									closeAddDialog={closeAddDialog}
									open={addOpen}
									refetch={refetch}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent className="companies">
				{brands ?
					<BrandsTable data={brands} columns={table_columns} />
					:
					null}
			</TabContent>
			<GlobalDialog
				open={editOpen}
				onClose={() => setEditOpen(false)}
				title="Edit Brand"
				buttonClick={handleSubmit}
				buttonTitle={"Edit Brand"}
				successMessage={"Brand editted successfully!"}
				fullWidth
				maxWidth="sm"
			>
				<BrandForm
					brand={brand}
					updateBrand={updateBrand}
					updateAdditiveImage={updateAdditiveImage}
					updateCoolantImage={updateCoolantImage}
				/>
				{loading || editBrand.isLoading || editBrand.isSuccess || editBrand.isError ?
					<DialogLoader
						isLoading={loading}
						mutation={editBrand}
						loadingMessage="Updating Brand"
						successMessage="Updating Brand"
						//closeDialog={closeRefreshDialog}
						fixed
					/>
					: null}
			</GlobalDialog>
		</>
	);
};

