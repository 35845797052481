import {
    Box,
    Grid,
    TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import InputWrap from '../reusable/InputWrap';
import CustomCard from '../reusable/CustomCard';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DesignDetails = () => {

    const { values, setFieldValue, handleChange, touched, errors } = useFormikContext();

    return (

        <CustomCard title="Design Details">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InputWrap name="Name *">
                            <TextField
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                sx={{ '& legend': { display: 'none' } }}
                            />
                        </InputWrap>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <InputWrap name="Required by">
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                        >
                            <DatePicker
                                //ampm={false}
                                //orientation="portrait"
                                value={values.date}
                                onAccept={value => {
                                    if (value) {
                                        setFieldValue(`date`, value)
                                    }
                                }}
                                onChange={(newValue) => {
                                    //console.log(newValue)
                                    setFieldValue(`date`, newValue)
                                }}
                                //onError={console.log}
                                format="dd/MM/yyyy"
                                mask="___/__/__"
                                disableMaskedInput
                                end
                                //componentsProps={{ textField: { variant: 'outlined' } }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            ref={inputRef} {...inputProps}
                                            error={touched.date && Boolean(errors.date)}
                                            helperText={touched.date && errors.date}
                                            sx={{ '& legend': { display: 'none' } }}
                                        />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />

                        </LocalizationProvider>
                    </InputWrap>
                </Grid>
                </Grid>
        </CustomCard>
    );
}


export default DesignDetails;
