import React, {
	useContext,
	useEffect,
	useState
} from 'react';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import PageContent from '../PageContent';
import ServiceReport from './ServiceReport';
import createServicePDFData from '../helpers/createServicePDFData'
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid, Card, Button, CardContent, CardActions } from '@mui/material';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import setLanguageText from '../../language/setLanguageText';
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import { useQuery } from '@tanstack/react-query';
import ServiceEntriesTable from './ServiceEntriesTable';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Service() {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [dateFrom, setDateFrom] = useState(new Date().setUTCHours(0, 0, 0, 0));
	const [dateTo, setDateTo] = useState(new Date().getTime());
	// const [pdf_data, setPdf_data] = useState(null);
	// const [originalData, setOriginalData] = useState();
	// const [columnOptions, setColumnOptions] = useState();
	const [rowSelection, setRowSelection] = useState();
	const [filterServiced, setFilterServiced] = useState([]);
	const [showServiced, setShowServiced] = useState(false);
	const [selectedEntries, setSelectedEntries] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [openReport, setOpenReport] = useState(false);

	//get service entries
	const { data, isFetching } = useQuery({
		queryKey: ['service entries', selectedCompany?.company_id, dateFrom, dateTo],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'service-report/entries',
			api_key: user.api_key,
			method: 'get',
			params: {
				user_id: user.user.user_id,
				date_from: dateFrom,
				date_to: dateTo,
				company_id: selectedCompany.company_id
			}
		}),
		enabled: !!selectedCompany.company_id,
		retry: false
	})

	const handleDate = date => {
		setDateFrom(new Date(date).setUTCHours(0, 0, 0, 0));
		setDateTo(new Date(date).setUTCHours(23, 59, 59, 59));
	};

	const openServicePdf = (pdf_data, columns, rows) => {

		// //save the original data, so we can minipulate it later on
		// let originalData = {
		// 	'selectedCompany': selectedCompany,
		// 	'rowData': rows,
		// 	'otherData': pdf_data,
		// 	'fromTable': true
		// }
		// setOriginalData(originalData);

		// //create first set of PDF data
		// const data = createServicePDFData({
		// 	'selectedCompany': selectedCompany,
		// 	'rowData': rows,
		// 	'otherData': pdf_data,
		// 	'fromTable': true
		// })
		// setPdf_data(data);
		// //save first columns that are selected (all)
		// setColumnOptions(data.columns);
		// //console.log(data.columns)
	};


	useEffect(() => {
		if (data) {
			if (showServiced) {
				setFilterServiced(data);
			} else {
				const noServiced = data.filter(entry => !entry.serviced);
				setFilterServiced(noServiced);
			}
		}
	}, [showServiced, data]);

	const getSelectedEntries = (rowSelection) => {
		let selected = [];
		Object.keys(rowSelection).forEach((i) => {
			selected.push(data[i]);
		});
		setSelectedEntries(selected);
		setOpenReport(true);
	}

	return (
		<>
			<InnerHeaderWrap>
			<Grid spacing={2} container justifyContent="space-between" alignItems="center" >
					<Grid item xs={12} md={3} >
						<GlobalCompanySelect fullWidth />
					</Grid>
					<Grid item>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								fullWidth
								inputVariant="outlined"
								margin="normal"
								id="date-picker-dialog"
								label={setLanguageText("Date")}
								format="dd/MM/yyyy"
								value={dateFrom}
								onChange={handleDate}
								disableFuture
								sx={{
									'& input': { zIndex: 2 },
									'& legend, & .MuiFormLabel-root': { display: 'none' },
									'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
									'& .MuiAutocomplete-endAdornment, & .MuiIconButton-edgeEnd ': {
										zIndex: 2
									}
								}}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Card
					variant="outlined"
					sx={{
						display: 'grid',
						border: (theme) => `1px solid ${theme.palette.table.border}`,
					}}
				>
					<CardContent sx={{ padding: '1em', display: 'grid' }}>

						<Grid item container alignItems="center" spacing={2}>
							<Grid item>
								<Button
									onClick={() => setShowServiced(prevState => !prevState)}
									variant="contained"
									size="small"
									color={showServiced ? 'primary' : 'error'}
								>
									{showServiced ? 'Hide' : 'Show'} Serviced
								</Button>
							</Grid>
						</Grid>
						<ServiceEntriesTable
							data={filterServiced}
							isFetching={isFetching}
							rowSelection={rowSelection}
							setRowSelection={setRowSelection}
							setHeaders={setHeaders}
						/>
					</CardContent>
					<CardActions sx={{ background: (theme) => theme.palette.cards.footer, paddin: '1em' }}>
						<Grid item>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								disabled={!rowSelection}
								onClick={() => getSelectedEntries(rowSelection)}
								startIcon={<FontAwesomeIcon icon={faClipboardListCheck} size="2x" />}
							>
								{setLanguageText("Run report for")} {rowSelection && Object.values(rowSelection).length}
							</Button>
						</Grid>
					</CardActions>
				</Card>
			</PageContent>
			<ServiceReport
				openReport={openReport}
				setOpenReport={setOpenReport}
				rows={selectedEntries}
				headers={headers}
			/>

			{/* SERVICE PDF */}
			{/* {pdf_data && (
				<ServicePdf
					servicePdfOpen={!!pdf_data}
					closeServicePdf={closeServicePdf}
					//create_pdf_data={create_pdf_data}
					pdf_data={pdf_data}
					originalData={originalData}
					columnOptions={columnOptions}
					serviceID={serviceID}
				/>
			)} */}
		</>
	);
};
