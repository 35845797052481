import React from 'react';
import { Alert, Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Card, CardContent, Grid } from '@mui/material';

import {
    useTable,
    //useAsyncDebounce,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useFlexLayout,
    useResizeColumns
} from 'react-table';
import SolutionsPagination from './SolutionsPagination';
import { matchSorter } from 'match-sorter';

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}


const SolutionsTable = ({
    columns,
    data,
    hidden,
    setHidden,
    sortColumn,
    sortOrder,
    handleSorting,
    addCustomRowClick
}) => {


    const headerProps = (props, { column }) => getStyles(props, column.align)

    const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

    const getStyles = (props, align = 'left') => [
        props,
        {
            style: {
                justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
                display: 'flex',
            },
        },
    ]


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200, // maxWidth is only used as a limit for resizing
            Filter: ''
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //rows,
        page, //use page instead of row to only so current page
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // setHiddenColumns,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: {
            //pageIndex : 1,
            hiddenColumns: hidden,
            sortBy: sortColumn
        }
    },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useResizeColumns,
        useFlexLayout
    )

    // Render the UI for your table
    return (
        <>
            <SolutionsPagination
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
            />

            <Card
                sx={{
                    display: 'grid',
                }}
            >
                <CardContent
                    sx={{
                        overflowY: 'auto',
                        width: '100%',
                        padding: 'inherit',
                        display: 'grid',
                        paddingBottom: '0!important'
                    }}
                >
                    <Table  {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map((headerGroup, i) => (
                                <TableRow
                                    key={i}
                                    {...headerGroup.getHeaderGroupProps()}
                                    sx={{ background: (theme) => theme.palette.primary.main }}
                                >
                                    {headerGroup.headers.map((column, i) => {
                                        // hiden column if hidden:true prop is in header
                                        if (column.hidden) {
                                            column.toggleHidden(true);
                                        }
                                        return (

                                            <TableCell
                                                {...column.getHeaderProps()}
                                                sx={{
                                                    color: (theme) => theme.palette.primary.contrastText
                                                }}
                                            >
                                                <Box sx={{
                                                    height: '100%',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    width: '1px',
                                                    backgroundColor: (theme) => theme.palette.primary.light,
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: 0
                                                }}
                                                />
                                                <Grid container >
                                                    <Grid item xs={12} {...column.getSortByToggleProps(headerProps)} >

                                                        {column.id !== 'selection' ? (
                                                            <TableSortLabel
                                                                active={column.isSorted}
                                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                                sx={{
                                                                    '& .MuiButtonBase-root:hover': {
                                                                        color: (theme) => `${theme.palette.primary.contrastText}!important`
                                                                    },
                                                                    '& .MuiSvgIcon-root': {
                                                                        marginTop: '2px',
                                                                        color: (theme) => `${theme.palette.primary.contrastText}!important`
                                                                    }
                                                                }}>
                                                                {column.render('Header')}
                                                            </TableSortLabel>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {column.canFilter ? column.render('Filter') : null}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}
                            sx={{
                                '& tr ': {
                                    borderWidth: '0 0 1px 0',
                                    borderStyle: 'solid',
                                    borderColor: (theme) => theme.palette.table.border,
                                    backgroundColor: (theme) => theme.palette.table.rows,
                                    // hide last border
                                    '&:last-child': {
                                        borderBottom: 0,
                                    },
                                }
                            }}
                        >
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <TableRow
                                        hover
                                        {...row.getRowProps()}
                                        //onClick={() => addCustomRowClick(row)}
                                        sx={{cursor : 'pointer'}}
                                    >
                                        {row.cells.map((cell, i) => {
                                            //console.log(cell)
                                            return (
                                                <TableCell
                                                    key={i}
                                                    sx={{
                                                        border: 0,
                                                        backgroundColor: (theme) => cell.row.original.request_status === 'New' ? theme.palette.table.void
                                                            : theme.palette.table.void,
                                                    }}
                                                    {...cell.getCellProps(cellProps)}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            )
                                        })}

                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </>
    );
}

export default SolutionsTable