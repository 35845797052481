import config from '../config';
import cognitoPromise from './cognitoPromise';

//get cognito users
export const getUsers = async () => {
    try {
        const allUsers = [];
        let paginationToken = null;

        while (true) {
            const params = {
                UserPoolId: config.cognito.USER_POOL_ID,
                Limit: 60
            };
            if (paginationToken) {
                params.PaginationToken = paginationToken;
            }
            const { Users, PaginationToken } = await cognitoPromise({ params, fn: 'listUsers' });
            allUsers.push(...Users);
            if (PaginationToken) {
                paginationToken = PaginationToken;
            } else {
                break;
            }
        }
        return allUsers;
    } catch (e) {
        //console.log(e);
    }
};

