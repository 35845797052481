import React from 'react';
import { Card, CardContent, CardActions, Grid } from '@mui/material';
import Defaults from '../../Products/Coolants/Defaults';
import ProductPDF from '../../Products/ProductPDF';

export default function CoolantDefaults({ coolant }) {

	return (
		<Card elevation={0}>
			<CardContent>
				<Defaults coolant={coolant} />
			</CardContent>
			<CardActions sx={{ textAlign: 'center', marginTop: 'auto', padding: '1em', backgroundColor: theme => theme.palette.cards.footer }}>
				<ProductPDF product={coolant} />
			</CardActions>
		</Card>
	);
};

