import React, { useCallback, useContext, useEffect, useState } from 'react';
import config from '../config';
import { useMutation, useQuery } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';
import GlobalDialog from '../reusable/GlobalDialog';
import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import ReactBrandsTable from '../settings/companies/visibility/engineer/ReactBrandsTable';
import DialogLoader from '../reusable/DialogLoader';
import setLanguageText from '../language/setLanguageText';

const EditEngineerBrands = ({ template, open, setOpen, handleClose }) => {

	const { user } = useContext(UserContext);
	const [sortColumn, setSortColumn] = useState("BrandOrder");
	const [allBrands, setAllBrands] = useState();
	const [mergedData, setMergedData] = useState();
	const [selectAll, setSelectAll] = useState(false);

	//get all brands
	const { data } = useQuery(['brands'],
		({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'categories/parametric',
			api_key: user.api_key,
			method: 'get',
			params: {
				form_id: "search_cs0"
			}
		}), {
		retry: 1
	})

	useEffect(() => {
		if (data) {
			let brands = data.Fields.find(item => item.Field === "COMPC")
			if (brands) {
				setAllBrands(brands.Values)
			}
		}
	}, [data])

	useEffect(() => {

		if (allBrands && Object.values(allBrands).length) {
			if (template?.template) {
				const clone = structuredClone(allBrands)
				let values = clone.map(item => {
					if (template?.template.includes(item.ID)) {
						return ([item.ID, decodeURI(item.Name), true])
					} else {
						return ([item.ID, decodeURI(item.Name)])
					}
				})
				setMergedData(values)

			} else {
				//no settings
				let values = allBrands.map(item => {
					return ([item.ID, decodeURI(item.Name)])
				})
				setMergedData(values)
				setSelectAll(false)
			}
		}
	}, [allBrands, template])


	const update_template = useMutation((dbTemplate) => {
		return axiosRequest({
			gateway: config.factoryAPI.URL,
			endpoint: 'templates',
			api_key: user.api_key,
			method: 'post',
			body: {
				"template_id": template.id,
				"template": dbTemplate
			},
		})
	}, {
		onSuccess: (data) => {
			closeRefreshDialog()
		}
	})

	const save_to_all_companies = useMutation(dbTemplate => {
		//console.log(values)
		return axiosRequest({
			endpoint: 'company/settings',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			method: 'patch',
			body: {
				"template_id": template.id,
				"template": dbTemplate,
				"template_type": template.type
			}
		})
	})

	const handleAllChange = useCallback((e) => {
		//console.log(e.target.checked)
		//console.log(mergedData)
		let clone = structuredClone(mergedData)
		let values = clone.map((each) => {
			return ([each[0], each[1], e.target.checked])
		})
		//console.log(values);
		setSelectAll(current => !current)
		setMergedData(values)
	}, [mergedData])


	const handleChange = (row, e) => {
		//console.log(row)
		//console.log(e)
		setMergedData(current => {
			let clone = structuredClone(current)
			let index = clone.findIndex((brand) => brand[0] === row[0]) + 1
			if (index) {
				clone[index - 1][2] = e.target.checked
			}
			return clone
		})
	}

	const columns = [{
		id: "BrandVisible",
		name: 'Visible',
		Header: () => <Typography>Visible</Typography>,
		accessor: 2,
		noSort: true,
		Cell: (row) => {
			//console.log(row.v)
			return (
				<Checkbox
					checked={row[2] ? row[2] : false}
					onChange={(e) => handleChange(row, e)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			)
		}
	}, {
		id: "BrandID",
		name: 'Brand Code',
		Header: () => <Typography>Brand Code</Typography>,
		accessor: 0,
		noSort: true,
		//maxWidth: 240,
	}, {
		id: "BrandName",
		name: 'Brand Name',
		Header: () => <Typography>Brand Name</Typography>,
		accessor: 1,
		noSort: true,
		//maxWidth: 240,
	}]

	const handleSubmit = (values) => {
		const clone = structuredClone(values)
		const encoded = clone.filter(item => item[2] === true).map(item => {
			return item[0] //just return the brand id
		})
		//console.log(encoded)
		update_template.mutate(encoded)
		save_to_all_companies.mutate(encoded)
	}

	//console.log(templateForm)
	const closeRefreshDialog = () => {
		setOpen(false)
		handleClose()
	}

	return (

		<GlobalDialog
			open={open}
			onClose={() => closeRefreshDialog(false)}
			title={`Update Template: ${template.name}`}
			buttonClick={() => handleSubmit(mergedData)}
			buttonTitle="Update Template"
			maxWidth="lg"
		>
			<Grid item xs={12}>
				<Alert severity="info">
					Updating this template will update all companies that use this template.
				</Alert>
			</Grid>
			<Grid
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="end"
			>
				<Grid item xs={6}>
					<FormControlLabel
						label="Toggle all visibility"
						control={<Checkbox
							color='default'
							checked={selectAll}
							onChange={(e) => handleAllChange(e)}
							inputProps={{ 'aria-label': 'controlled' }}
						//disabled={company_attributes.isLoading}
						/>}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} >

				<Box sx={{ minHeight: '500px', border: '1px solid #ccc' }}>
					{mergedData ?
						<ReactBrandsTable
							columns={columns}
							data={mergedData}
							hidden={[]}
							sortColumn={sortColumn}
						/>
						: null
					}
				</Box>
			</Grid>
			{update_template.isLoading || update_template.isSuccess || update_template.isError ?
				<DialogLoader
					fixed
					isLoading={update_template.isLoading}
					mutation={update_template}
					loadingMessage={setLanguageText("Updating Template...")}
					successMessage={setLanguageText("Template Updated")}
				//closeDialog={closeRefreshDialog}
				/>
				: null}
		</GlobalDialog>
	);
};

export default EditEngineerBrands;



