import React, { useMemo, useState } from 'react'
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    flexRender
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import UserTableSubComponent from './UserTableSubComponent'
import Filter from '../../tables/Filter'

const UserTable = ({
    data,
    isFetching,
    columns,
    getRowCanExpand,
    roles
}) => {

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')
    const defaultData = useMemo(() => [], [])

    // const DefaultColumn = ({
    //     cell: {
    //         getValue,
    //         row: { index },
    //         column: { id },
    //         table
    //     }
    // }) => {
    //     const initialValue = getValue();
    //     // We need to keep and update the state of the cell normally
    //     const [value, setValue] = React.useState(initialValue);
    //     // When the input is blurred, we'll call our table meta's updateData function
    //     const onBlur = () => {
    //         table.options.meta && table.options.meta.updateData(index, id, value);
    //     };
    //     // If the initialValue is changed externally, sync it up with our state
    //     React.useEffect(() => {
    //         setValue(initialValue);
    //     }, [initialValue]);
    //     return (
    //         <input
    //             value={value}
    //             onChange={(e) => setValue(e.target.value)}
    //             onBlur={onBlur}
    //         />
    //     );
    // }


    const table = useReactTable({
        data: data ?? defaultData,
        columns,
        getRowCanExpand,
        //defaultColumn: DefaultColumn,
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        // meta: {
        //     updateData: (rowIndex, columnId, value) => {
        //         // Skip page index reset until after next rerender
        //         skipAutoResetPageIndex()
        //         setData(old =>
        //             old.map((row, index) => {
        //                 if (index === rowIndex) {
        //                     return {
        //                         ...old[rowIndex],
        //                         [columnId]: value,
        //                     }
        //                 }
        //                 return row
        //             })
        //         )
        //     },
        // },
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    })

    const { pageSize, pageIndex } = table.getState().pagination

    return (
        <Box sx={{ width: '100%' }} >
            <Grid item xs={12} >
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100, 200]}
                    component="div"
                    count={table.getFilteredRowModel().rows.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    // SelectProps={{
                    //     inputProps: { 'aria-label': 'rows per page' },
                    //     native: true,
                    // }}
                    onPageChange={(_, page) => {
                        table.setPageIndex(page)
                    }}
                    onRowsPerPageChange={e => {
                        const size = e.target.value ? Number(e.target.value) : 10
                        table.setPageSize(size)
                    }}
                //ActionsComponent={TablePaginationActions}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer >
                    <Table
                        size="small"
                        sx={{ minWidth: 650, border: '1px solid #ccc' }}
                        aria-label="simple table
					 ">
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup, i) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.table.header,

                                    }}
                                >
                                    {headerGroup.headers.map((header, i) => {
                                        return (
                                            <TableCell key={i} colSpan={header.colSpan}>
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {header.column.getCanFilter() ? (
                                                            <div>
                                                                <Filter column={header.column} table={table} />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {isFetching ?
                                //create array from a number
                                new Array(pageSize).fill(0).map((_, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                cursor: 'pointer',
                                                borderBottom: '1px solid #ccc',
                                                backgroundColor: (theme) => theme.palette.table.row,
                                                '&:nth-of-type(odd)': {
                                                    backgroundColor: (theme) => theme.palette.table.hover,
                                                },
                                                //backgroundColor: theme => row.state.Enabled ? theme.palette.table.rows : theme.palette.table.disabled,
                                                // hide last border
                                                '&:last-child td, &:last-child th': {
                                                    borderBottom: 0,
                                                },
                                            }}>

                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={30} /></TableCell>
                                            <TableCell><Skeleton height={36} variant='rounded' /></TableCell>
                                        </TableRow>
                                    )
                                })

                                : !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {

                                    return (
                                        <>
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: theme => row.original.Enabled ? theme.palette.table.rows : theme.palette.table.disabled,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: theme => row.original.Enabled ? theme.palette.table.hover : theme.palette.table.disabled,
                                                    },

                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell, i) => {
                                                    return (
                                                        <TableCell key={i}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                            {row.getIsExpanded() && (
                                                <tr>
                                                    {/* 2nd row is a custom 1 cell row */}
                                                    <td colSpan={row.getVisibleCells().length}>
                                                        <UserTableSubComponent permissions={row.original.user_permissions} details={row.original.user_details} roles={roles}/>
                                                    </td>
                                                </tr>
                                            )}
                                        </>

                                    )
                                })
                                :  <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No orders found</Alert>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box >
    )
}

export default UserTable;

