import React from "react";
import Highlighter from "react-highlight-words";
import { Box } from "@mui/material";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const highlightText = (text, highlight, type) => {

    // //console.log(text, highlight)
    // if (!text) return '-';
    // // remove special  characters from search
    // highlight = highlight.replace(/[+|-|<|>|~|*|"]/g, "")


    const matches = match(text, highlight, { insideWords: true });
    const parts = parse(text, matches);

    //remove all spaces the split into array of pairs and 3 letters
    let pairs = highlight.replace(/\s/g, '').match(/.{1,2}/g);
    let thirds = highlight.replace(/\s/g, '').match(/.{1,3}/g);
    //remove duplicates and single letters
    pairs = [...new Set(pairs)].filter(pair => pair.length > 1)
    thirds = [...new Set(thirds)].filter(third => third.length > 2)


    //console.log('array', [...pairs, ...thirds])

    return (
        <Box >
            {type === "description" || type === "all" ?

                <Box
                    sx={{
                        '& .YourHighlightClass': {
                            backgroundColor: 'transparent',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    {parts.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: !part.highlight ? 300 : 700,
                                color: !part.highlight ? '#444' : '#222',
                                //backgroundColor: part.highlight ? '#fff17270' : 'none',
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </Box>

                :
                <Box
                    sx={{
                        '& .YourHighlightClass': {
                            backgroundColor: 'transparent',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[...pairs, ...thirds]}
                        autoEscape={true}
                        textToHighlight={text}
                    />
                </Box>
            }
        </Box>
    )
}

export default highlightText;