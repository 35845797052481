import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { Font } from '@react-pdf/renderer';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import TableColumns from './TableColumns';


const CustomRow = ({ item, columns, supplierToggle, hidden }) => {

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState();

    useEffect(() => {

        if (item[1]) {
            let count = 0;
            item[1].map((item, i) => {
                if (item.v === true) {
                    count++;
                }
            })
            setSelected(count)
        }
    }, [item])

    //console.log(item)
    return (
        <>
            <TableRow
                hover
                //onClick={() => addCustomRowClick(item)}
                sx={{
                    cursor: 'pointer',
                    borderBottom: '1px solid #ccc',
                    background: (theme) => theme.palette.background.header
                }}>

                <TableCell
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    <FontAwesomeIcon
                        onClick={() => setOpen(!open)}
                        icon={faChevronDown}
                    />
                       <Typography variant='caption' sx={{marginLeft: '10px'}}>{selected}/{item[1].length} visible</Typography>
                </TableCell>
                <TableCell
                    colSpan={columns.length - 1}
                    sx={{
                        fontWeight: 'bold',
                    }}
                >


                    <Typography variant='body' >
                        {item[0]}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        style={{
                            float: 'right',
                        }}
                        onClick={(e) => supplierToggle(item[0], item[1].length - selected)}
                    >
                        Toggle supplier&apos;s brands
                    </Button>
                </TableCell>
            </TableRow>
            {open && (
                <>
                    {item[1].map((item, i) => {
                        return (
                            <TableRow
                                hover
                                key={i}
                                sx={{
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #ccc',
                                    backgroundColor: (theme) => theme.palette.background.paper,
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: (theme) => theme.palette.background.default,
                                    },
                                    // hide last border
                                    '&:last-child td, &:last-child th': {
                                        borderBottom: 0,
                                    },
                                }}>
                                {columns.map((column, index) => {
                                    //console.log(props)
                                    //console.log(item)
                                    const tData = item[column.accessor] ? item[column.accessor] : "——";
                                    if (!column.hidden && !hidden.includes(column.accessor)) {
                                        return (
                                            <TableCell
                                                key={index}
                                                sx={{ maxWidth: column.maxWidth ? column.maxWidth : 'auto' }}
                                            >
                                                {column.Cell ? column.Cell(item) : tData}
                                            </TableCell>
                                        )
                                    }
                                })}
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </>
            )}
        </>
    )
}

const SupplierTable = ({
    columns,
    data,
    hidden,
    sortColumn,
    sortOrder,
    handleSorting,
    supplierToggle
}) => {
    //console.log(sortColumn)
    //console.log(sortOrder)
    //Render the UI for your table
    return (
        <Box
            sx={{
                overflowY: 'auto',
                overflowY: 'auto',
                width: '100%',
                //height: 'calc(100vh - 400px)'
            }}
        >
            <TableContainer sx={{
                maxHeight: '500px',
                //border: (theme) => `1px solid ${theme.palette.table.border}`
            }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow
                            sx={{ background: (theme) => theme.palette.background.header }}
                        >
                            {columns.map((column, i) => {
                                //console.log(column)
                                if (!column.hidden && !hidden.includes(column.accessor)) {
                                    //console.log(name)
                                    return (
                                        <TableCell
                                            key={i}
                                            padding={column.disablePadding ? 'none' : 'normal'}
                                            sx={{
                                                background: (theme) => sortColumn === column.accessor ? theme.palette.primary.light : theme.palette.primary.main,
                                                color: (theme) => theme.palette.primary.contrastText,
                                                '&:hover ': {
                                                    color: (theme) => theme.palette.primary.contrastText,
                                                },
                                                maxWidth: column.maxWidth ? column.maxWidth : 'auto'
                                            }}
                                        //sortDirection={orderBy === props.id ? order : false}
                                        >
                                            <Box
                                                sx={{
                                                    height: '80%',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    width: '1px',
                                                    background: (theme) => theme.palette.primary.light,
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: '10%'
                                                }}
                                            />
                                            {!column.noSort && column.accessor ?
                                                <TableSortLabel
                                                    active={sortColumn === column.accessor}
                                                    direction={sortColumn === column.accessor ? sortOrder : "asc"}
                                                    onClick={(e) => handleSorting(column)}
                                                >
                                                    {column.Header ? column.Header() : column.name}
                                                </TableSortLabel>
                                                :
                                                column.Header ? column.Header() : column.name
                                            }
                                        </TableCell>

                                    )
                                }
                            })}
                            <TableCell
                                sx={{
                                    background: theme => theme.palette.primary.main,
                                    color: (theme) => theme.palette.primary.contrastText,
                                    '&:hover ': {
                                        color: (theme) => theme.palette.primary.contrastText,
                                    },
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data && columns && Object.entries(data).map((item, i) => {
                            //console.log(item[1])
                            return (
                                <Fragment key={i}>
                                    <CustomRow
                                        item={item}
                                        columns={columns}
                                        supplierToggle={supplierToggle}
                                        hidden={hidden}
                                    />
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table >
            </TableContainer>
        </Box>
    );
}

export default SupplierTable