import { useCallback, useState } from 'react';

const useAsync = ({ axiosRequest }) => {
  const [loading, setLoading] = useState(false);
  const [logLoading, setLogLoading] = useState(false);

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [logCompleted, setLogCompleted] = useState(false);
  const [logError, setLogError] = useState(null);

  const executeAsync = useCallback(
    async ({ endpoint, api_key, method, body, params, headers }) => {
      try {
        setLoading(true);
        const response = await axiosRequest({
          endpoint,
          api_key,
          method,
          body,
          params,
          headers
        });
        // TODO (temporary) if something wrong with the request, Lambda should return 400,

        if (response.errorMessage) {
          setError({ ...response, message: response.errorMessage });
          // remove the error after 3 sec
          setTimeout(() => {
            setError(null);
          }, 3000);
          return;
        }
        setData(response);
        return response;
      } catch (err) {
       //console.log(err);
        setError({ ...err, message: err.message });
        // remove the error after 3 sec
        setTimeout(() => {
          setError(null);
        }, 3000);
      } finally {
        setLoading(false);
      }
    },
    [axiosRequest]
  );

  const executeLog = useCallback(
    async ({ api_key, body, endpoint }) => {
      try {
        setLogLoading(true);
        const response = await axiosRequest({
          api_key,
          endpoint: `logging/${endpoint}`,
          method: 'post',
          body
        });
        // TODO (temporary) if something wrong with the request, Lambda should return 400,
        if (response.errorMessage) {
          setLogError({ ...response, message: response.errorMessage });
          setLogCompleted(true);
          return;
        }
        setLogCompleted(true);
        return response;
      } catch (err) {
        setLogError(err);
        setTimeout(() => {
          setLogError(null);
        }, 3000);
      } finally {
        setLogLoading(false);
      }
    },
    [axiosRequest]
  );

  return {
    error,
    data,
    loading,
    logLoading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  };
};

export default useAsync;
