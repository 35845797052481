import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
    Grid,
    Dialog,
    IconButton,
} from '@mui/material'
import ProductPopup from '../products/opensearch/ProductPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import SearchOptions from './SearchOptions'
import ProductTable from '../products/opensearch/ProductTable'
import { LayoutContext } from '../store/LayoutContext'
import ProductGridItem from '../products/opensearch/ProductGridItem'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { BrandsContext } from '../store/BrandsContext'
import createSingleProduct from '../products/opensearch/createProduct'
import { trackEvent } from '../google_analytics/ga'
import { CompanyContext } from '../store/CompanyContext'
import config from '../config'
import axiosRequest from '../axios/axoisRequest'
import { UserContext } from '../store/UserContext'
import { useMutation } from '@tanstack/react-query'

export default function SearchResults({ firstLoad, pagination, getProducts, isFetching, value, setSearchParams, searchParams, filters }) {

    const { layout } = useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();
    const [products, setProducts] = useState([])
    const { brands } = useContext(BrandsContext);
    const { selectedCompany } = useContext(CompanyContext);
    const ACTstoredToken = sessionStorage.getItem('ACTtoken');
    const CISstoredToken = sessionStorage.getItem('CIStoken');

    const getPrice = useMutation({
        mutationKey: 'getPrice',
        mutationFn: (product) => {
            let params = {
                "supplier": product?.product_supplier,
                "account": selectedCompany?.company_erp?.AccountNumber,
                "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
            }
            if (product?.product_code) {
                params = {
                    ...params,
                    "code": product.product_code
                }
            }
            if (product?.product_id) {
                params = {
                    ...params,
                    "id": product.product_id
                }
            }
            return (
                axiosRequest({
                    gateway: config.opensearchAPI.URL,
                    endpoint: 'tools-search/price',
                    api_key: user.api_key,
                    method: 'get',
                    params: params
                })
            )
        }
    })


    //create product object
    useEffect(() => {
        if (getProducts?.data?.hits?.hits && brands.openSearchBrands) {

            let createdProducts = getProducts.data.hits.hits.map((product) => {
                return createSingleProduct({ product, brands })
            })
            
            setProducts(createdProducts)

            // const fetchPrices = async (products) => {
            //     let clone = structuredClone(products)
            //     let prices = await Promise.all(clone.map(async (product) => {
            //         let priceData = await getPrice.mutateAsync(product)
            //         let priceObject
            //         if (!priceData?.errorMessage) {
            //             //console.log(data?.errorMessage)
            //             priceObject = {
            //                 "price_rrp": priceData?.PriceRRP,
            //                 "price_incl_discount": priceData?.PriceInclDiscount,
            //                 "price_discount": priceData?.PriceDiscount,
            //                 "product_stock": priceData?.TotalStock,
            //                 "product_free_stock": priceData?.FreeStock,
            //                 "product_erp_code": priceData?.ArticleNumber,
            //                 "product_erp": priceData?.FullProduct,
            //             }
            //         } else {
            //             priceObject = {
            //                 "error": priceData?.errorMessage,
            //             }
            //         }
            //         return {
            //             ...product,
            //             ...priceObject
            //         }
            //     }))
            //     setProducts(prices)
            // }
            // fetchPrices(createdProducts)
        }
    }, [getProducts.data, brands.openSearchBrands ])


    const handleClose = () => {
        setOpen(false);
        setSelectedProduct();
    }

    const productClick = (product) => {
        setOpen(true)
        setSelectedProduct(product)
        //add to google analytics
        trackEvent({
            action: 'view_item',
            params: {
                "currency": "GBP",
                "value": product.price_incl_discount,
                "items": [
                    {
                        "item_id": product.product_id,
                        "item_name": product.product_description,
                        "product_number": product.product_number,
                        "product_supplier": product.product_supplier
                    }
                ]
            }
        })
    }

    return (
        <>
            <Grid item xs={12} container spacing={isMobile ? 1 : 2} alignItems="center">
                <SearchOptions
                    filters={filters}
                    getProducts={getProducts}
                    pagination={pagination}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
                <Grid item xs={12}>
                    {layout.layout === 'engineer' ?
                        <ProductTable
                            products={products}
                            productClick={productClick}
                            isFetching={getPrice.isLoading || isFetching}
                            pagination={pagination}
                            value={value}
                            filters={filters}
                        />
                        :
                        <Grid container spacing={isMobile ? 1 : 2}>
                            {!firstLoad && products ? products.map((product, i) => {
                                //console.log(product)
                                return (
                                    <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                                        <ProductGridItem
                                            product={product}
                                            productClick={productClick}
                                            isFetching={getPrice.isLoading || isFetching}
                                        />
                                    </Grid>
                                )
                            }) : null}

                        </Grid>
                    }
                </Grid>
                {/* <SearchOptions
                    getProducts={getProducts}
                    table={table}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    hideIcons
                /> */}
            </Grid>
            {
                open && selectedProduct &&
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                    fullScreen={isMobileOnly}
                >
                    <IconButton
                        sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            background: '#fff',
                            padding: '10px 15px',
                            zIndex: 10
                        }}
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon
                            aria-label="close"
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                            icon={faTimes}
                        />
                    </IconButton>
                    <ProductPopup product={selectedProduct} closeDialog={handleClose} />
                </Dialog>
            }
        </>
    )
}

