import React, { useContext, useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, Fade, Grid, Skeleton } from '@mui/material';

import BrandLogo from "../../shop/reusable/BrandLogo";
import { faChevronRight, faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductSlider from "../../reusable/ProductSlider";
import ProductTabs from "../shared/tabs/ProductTabs";
import { isMobileOnly } from "react-device-detect";
import SingleImage from "../../shop/reusable/SingleImage";
import ExportSingleProduct from "../shared/ExportSingleProduct";
import ProductDetails from "./ProductDetails";
import HTMLReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import AddToFavourites from "../../favourites/AddToFavourites";
import AddToBasket from "../../basket/AddToBasket";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";
//import useEnhancedCIMPopup from "../../hooks/useEnhancedCIMPopup";


const ProductPopup = ({ product, closeDialog }) => {

	const [selectedProduct, setSelectedProduct] = useState();
	const navigate = useNavigate();
	//const { allAccessories, isFetching } = useEnhancedCIMPopup({ selectedProduct, setSelectedProduct, product });

	useEffect(() => {
		if (product) {
			//createProduct(product)
			setSelectedProduct(product)
		}
	}, [product])

	return (

		<Grid item container >
			<Grid item xs={12} md={6} container alignContent="center" sx={{ position: 'relative' }}>
				<Fade in={true}>
					<Grid
						item
						xs={12}
						sx={{
							padding: isMobileOnly ? '1em' : '2em',
							background: '#fff'
						}}>
						<Box
							sx={{
								position: 'absolute',
								top: 16,
								left: 16,
								zIndex: 4,
							}}
						>
							<AddToFavourites product={selectedProduct} isFetching={false} big />
						</Box>
						{selectedProduct?.product_image && selectedProduct?.product_image?.length > 1 ?
							<ProductSlider images={selectedProduct.product_image} url="url" descrition="TYPE" />
							: selectedProduct?.product_image && selectedProduct?.product_image.length === 1 ?
								<SingleImage imageUrl={selectedProduct.product_image[0].url} alt={selectedProduct.product_name} icon="6x" padding="1em" />
								:
								<Fade in={true}>
									<Box sx={{
										height: '100%',
										width: '100%',
										position: isMobileOnly ? 'relative' : 'absolute',
										zIndex: 2,
										textAlign: 'center',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'grid',
										top: 0,
										left: 0,
										'svg': {
											margin: '0 auto'
										}
									}}>
										{selectedProduct?.product_brand_url ?
											<img
												alt={selectedProduct?.product_manufacturer}
												src={selectedProduct?.product_brand_url}
												style={{
													//grey scale	
													filter: 'grayscale(100%)',
													left: 0,
													opacity: 0.1,
													//position: 'absolute',
													maxWidth: '100%',
													maxHeight: '100%',
													objectFit: 'contain',
													margin: '0 auto',
													padding: '2em'
												}}
											/>
											:
											<FontAwesomeIcon icon={faImageSlash} color="#ccc" size="6x" />
										}
									</Box>
								</Fade>

						}
					</Grid>
				</Fade>
			</Grid>
			<Grid container item xs={12} md={6} sx={{ padding: '1em' }} alignItems="space-between">
				<Grid container item xs={12} spacing={2} sx={{ marginTop: '20px' }}>
					<Grid item xs={12}>
						{selectedProduct ?
							<Typography color="text.primary" variant="h5" >{selectedProduct?.product_name}</Typography>
							:
							<Skeleton width="100%" height={80} />
						}
					</Grid>
					<Grid item xs={12}>
						<Box sx={{
							width: '200px',
							height: isMobileOnly ? '60px' : '80px',
							overflow: 'hidden'
						}}>
							<BrandLogo product={selectedProduct} />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<ProductDetails product={selectedProduct} isFetching={false} />
					</Grid>
					{selectedProduct?.product_html ?
						<>
							<Grid item xs={12}>
								<Divider variant="middle" sx={{ margin: '0 auto 1em auto' }} />
							</Grid>
							<Grid item xs={12}
								sx={{
									maxHeight: '120px',
									overflow: 'auto',
									fontSize: '14px',
									'& p ': {
										fontSize: '14px'
									}
								}}
							>
								{HTMLReactParser(selectedProduct.product_html)}
							</Grid>
						</>
						: null}
					<Grid item xs={12}>
						{selectedProduct ?

							<Grid container spacing={1 / 2}>
								<Grid item xs={12}>
									<Prices
										product={selectedProduct}
										large={true}
									/>
								</Grid>
								<Grid item xs={12}>
									<Delivery
										product={selectedProduct}
										large={true}
									/>
								</Grid>
								<Grid item xs={12}>
									<AddToBasket
										product={selectedProduct}
										area="opensearch"
									/>
								</Grid>
							</Grid>
							:
							<Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
						}
						<Divider variant="middle" sx={{ margin: '1em auto' }} />
					</Grid>
				</Grid>

				<Grid item container spacing={1} xs={12} textAlign="right" justifyContent="flex-end">
					<Grid item>
						<ExportSingleProduct product={selectedProduct} />
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={e => {

								let url = `/search/search/product/${selectedProduct.product_id}?tab=1`;
								navigate(url)
							}}
							endIcon={
								<FontAwesomeIcon
									icon={faChevronRight}
									color={'primary.contrastText'}
								/>
							}
						>
							Full Details
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Box sx={{ width: '100%', background: (theme) => theme.palette.cards.header }}>
				<ProductTabs
					//allAccessories={allAccessories}
					isFetching={false}
					selectedProduct={selectedProduct}
					setSelectedProduct={setSelectedProduct}
					closeDialog={closeDialog}
				/>
			</Box>
		</Grid >

	);
}


export default ProductPopup;