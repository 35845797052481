
import React, {
    useEffect,
    useContext,
    useState,
} from "react";
import { UserContext } from "../store/UserContext";
import { CategoryContext } from "../store/CategoryContext";
import axiosRequest from '../axios/axoisRequest';
import { useQuery } from "react-query";
import config from "../config";

const useProductAttrbuites = () => {


    const [attributes, setAttributes] = useState();
    const { user } = useContext(UserContext);
    const { categories } = useContext(CategoryContext);

    //get detail profile
    const { data: detailProfile } = useQuery(['tableheaders', categories?.currentCat?.DetailProfile],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: categories.currentCat.DetailProfile
            }
        }), {
        enabled: !!categories?.currentCat?.DetailProfile,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    //get result profile
    const { data: resultProfile } = useQuery(['visibleheaders', categories?.currentCat?.ResultProfile],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'categories/parametric',
            api_key: user.api_key,
            method: 'get',
            params: {
                form_id: categories.currentCat.ResultProfile
            }
        }), {
        enabled: !!categories?.currentCat?.ResultProfile,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    //set all checked attributes based on resultProfile
    useEffect(() => {
        if (detailProfile?.Fields && resultProfile?.Fields) {
            //create array of all fields 
            let allAttr = detailProfile.Fields.map((item) => {
                let checked = resultProfile?.Fields.find((field) => field.Ref === item.Ref)
                if (checked) {
                    return { ...item, checked: true }
                } else {
                    return { ...item, checked: false }
                }
            })
            setAttributes(allAttr)
        }

    }, [resultProfile, detailProfile])

    return {
         attributes,
         setAttributes,
         detailProfile,
         resultProfile
     }

};

export default useProductAttrbuites;


