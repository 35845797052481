import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTheme, Grid, CardContent, TextField, Box, Autocomplete } from '@mui/material';
import { UserContext } from '../../store/UserContext';
import ServiceReportPDF from '../pdf/ServiceReportPDF';
import createServicePDFData from '../helpers/createServicePDFData'
import sortAlphaNumerically from '../../helpers/sortAlphaNumerically'
import useSignedUrl from '../../hooks/useSignedUrl';
import config from '../../config';
import { CompanyContext } from '../../store/CompanyContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import setLanguageText from '../../language/setLanguageText';
import GlobalDialogPDF from '../../reusable/GlobalDialogPDF';


export default function ServiceReportDialog({ id, openServicePDF, setOpenServicePDF }) {

	const theme = useTheme();
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [originalData, setOriginalData] = useState();
	const [pdf_data, setPdf_data] = useState(null);
	const [columnOptions, setColumnOptions] = useState();
	const [downloadObject, setDownloadObject] = useState();
	const [orientation, setOrientation] = useState('portrait');
	const [title, setTitle] = useState();

	useEffect(() => {
		if (selectedCompany) {
			setTitle(`${setLanguageText("Service Report")}: ${selectedCompany.company_name}`);
		}
	}, [selectedCompany]);

	const { data: original } = useQuery({
		queryKey: ['service report'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'service-report',
			api_key: user.api_key,
			method: 'get',
			params: {
				"service_id": id
			}
		}),
		enabled: !!id,
		retry: false
	})

	const entriesIds = original?.[0]?.service_entry_ids

	const { data } = useQuery({
		queryKey: ['service report entries', entriesIds],
		queryFn: () => {
			let params
			if (entriesIds) {
				params = {
					allow_access: true,
					entry_ids: JSON.stringify(entriesIds),
				}
			} else {
				//legacy
				params = {
					allow_access: true,
					user_id: original[0].service_user_id,
					service_date: new Date( original[0].service_date).toISOString().substr(0, 10),
					legacy: entriesIds ? false : true
				}
			}
			let originalData = {
				'selectedCompany': selectedCompany,
				'otherData': original[0]
			}
			setOriginalData(originalData);

			return axiosRequest({
				gateway: config.coolantAPI.URL,
				endpoint: 'machines/entries',
				api_key: user.api_key,
				method: 'get',
				params: params
			})
		},
		enabled: !!entriesIds,
		retry: false
	})

	useEffect(() => {
		if (data) {
			const sorted = sortAlphaNumerically(data, 'machine_name')
			setOriginalData(current => {
				return {
					...current,
					'rowData': sorted,
				}
			});
		}
	}, [data]);


	// const getEntries = useMutation({
	// 	mutationFn: (params) => axiosRequest({
	// 		gateway: config.coolantAPI.URL,
	// 		endpoint: 'machines/entries',
	// 		api_key: user.api_key,
	// 		method: 'get',
	// 		params: params
	// 	}),
	// 	onSuccess: (response) => {
	// 		if (!response.errorMessage) {
	// 			const sorted = sortAlphaNumerically(response, 'machine_name')
	// 			console.log(sorted)
	// 			setOriginalData(current => {
	// 				return {
	// 					...current,
	// 					'rowData': sorted,
	// 				}
	// 			});
	// 		}
	// 	}
	// });

	//const getServiceEntries = (original) => {
	// 	//console.log(original)
	// 	setOpenServicePDF(true)
	// 	let query
	// 	if (original.service_entry_ids) {
	// 		query = {
	// 			allow_access: true,
	// 			entry_ids: JSON.stringify(original.service_entry_ids),
	// 		}
	// 	} else {
	// 		//legacy
	// 		query = {
	// 			allow_access: true,
	// 			user_id: original.service_user_id,
	// 			service_date: new Date(original.service_date).toISOString().substr(0, 10),
	// 			legacy: original.service_entry_ids ? false : true
	// 		}
	// 	}
	// 	let originalData = {
	// 		'selectedCompany': selectedCompany,
	// 		'otherData': original
	// 	}
	// 	setOriginalData(originalData);
	// 	getEntries.mutate(query);
	// }

	useEffect(() => {
		if (originalData?.rowData) {
			const data = createServicePDFData({
				'selectedCompany': selectedCompany,
				'rowData': originalData.rowData,
				'otherData': originalData.otherData,
			});
			setPdf_data(data)
			setColumnOptions(data.columns)
		}
	}, [originalData, selectedCompany])


	const handleChange = event => {
		//console.log(event.target.name)
		event.persist()
		//console.log(event.target.name)
		//toggle options
		let newOptions = [...columnOptions]
		let index = newOptions.findIndex(obj => obj.id === event.target.name);
		newOptions[index].isVisible = !newOptions[index].isVisible;
		setColumnOptions(newOptions)

		//create new visibale columns re create PDF date using them
		let newColumns = newOptions.filter(obj => obj.isVisible === true)
		const updatedData = createServicePDFData({ ...originalData, 'headers': newColumns });
		setPdf_data(updatedData);

	};

	const { getSignedUrl } = useSignedUrl();
	const [mainImage, setMainImage] = useState();

	useEffect(() => {
		if (pdf_data) {
			console.log(pdf_data)
			getSignedUrl(config.s3.BUCKET, `public/company/${pdf_data.company.logo_path}`).then((url) => {
				console.log(url)
				if (url) {
					setMainImage(url)
				} else {
					setMainImage({ error: 'No Image' })
				}
			})
		}
		return () => {
			setMainImage()
		}
	}, [pdf_data]);


	return (
		<>
			{openServicePDF ?
				<GlobalDialogPDF
					title={title}
					text="Service report attached"
					open={openServicePDF}
					onClose={e => {
						setPdf_data()
						setOpenServicePDF(false)
					}}
					maxWidth
					downloadObject={downloadObject}
					orientation={orientation}
					updateOrientation={setOrientation}
					options={columnOptions}
					handleOptionChange={handleChange}
					email
				>
					{pdf_data ?
						<ServiceReportPDF
							theme={theme}
							orientation={orientation}
							pdf_data={pdf_data}
							setDownloadObject={setDownloadObject}
							mainImage={mainImage}
						/>
						: null}
				</GlobalDialogPDF>
				: null}
		</>
	);
};
