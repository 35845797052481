
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';

const useActivityLog = () => {

    const [loggingError, setLoggingError] = useState()
    const [loggingResponse, setLoggingResponse] = useState()

    const { user } = useContext(UserContext)
    const updateActivityLog = useMutation((details) => {
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'activity/logging',
            api_key: user.api_key,
            method: 'post',
            body: {
                ...details,
                activity_user: {
                    aws_sub: user.user.aws_sub,
                    email: user.user.email,
                    username: user.user.username,
                    name: user.user.user_details.name,
                    surname: user.user.user_details.surname
                },
                activity_user_id: user.user.user_id,
                activity_user_companies: user.user.user_permissions.access.companies
            }
        })
    }, {
        onSuccess: (response) => {
            //console.log(response)
            if (response) {
                setLoggingResponse(response)
            }
        },
        onError: (error) => {
            //console.log(data)
            if (error) {
                setLoggingError(error)
            }
        }
    })

    return {
        updateActivityLog,
        loggingResponse,
        loggingError
    }
};

export default useActivityLog

