import createMainQuery from "./createMainQuery";
import search_pipline from "./searchPipeline.json";

export default function queryGetFilters({ term, type, field, sort, filters }) {

    let query = {};

    if (!term) {


        query = {
			"query": {
				"bool": {
					"must": [
						{
							"match_all": {}
						}
					],
					"filter": []
				}
			},
            ...search_pipline,
            "size": 0,
            "aggs": {
                "genres": {
                    "terms": {
                        "field": field,
                        "size": 1000
                    }
                }
            }
		};

        if (filters) {
			query.query.bool.filter.push(
				{
					"terms": filters ? filters : []
				}
			)
		}
		
		query.query.bool.filter.push({
			"range": {
				"price": {
					"gt": 0,
				}
			}
		});




    } else {

        let { minScore, mainQuery } = createMainQuery({ term, type })

        if (filters) {
            mainQuery.bool.filter.push({
                "terms": filters ? filters : []
            });
            mainQuery.bool.filter.push({
                "range": {
                    "price": {
                        "gt": 0,
                    }
                }
            });
        }

        query = {
            "query": mainQuery,
            ...search_pipline,
            "min_score": minScore,
            "track_scores": true,
            "size": 0,
            "aggs": {
                "genres": {
                    "terms": {
                        "field": field,
                        "size": 10000
                    }
                }
            }
        };
    }

    if (sort) {
        query.sort = [
            { ...sort?.value ? sort.value : null }
        ];
    }

    return query;



}

