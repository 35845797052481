import React, { Component, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faMinus,
    faPlus,
    faCartShopping
} from '@fortawesome/pro-light-svg-icons';
import { Grid, Button, Skeleton, OutlinedInput, Chip, Typography, Alert } from "@mui/material";
import { BasketContext } from "../store/BasketContext";
import { UPDATE_BASKET } from "../store/BasketReducers";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { UserContext } from "../store/UserContext";
import config from "../config";
import { CompanyContext } from "../store/CompanyContext";
import axiosRequest from '../axios/axoisRequest';
import { updateBasketTotals } from "./helpers/updateBasketTotals";
import { trackEvent } from "../google_analytics/ga";


export default function AddToBasket({ product, large, area }) {

    const { basket, dispatchBasket } = useContext(BasketContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [value, setValue] = useState();

    useEffect(() => {
        //check if product has packet size is 0
        if (!product?.product_packet_size || parseInt(product?.product_packet_size) === 0) {
            setValue(1)
        } else {
            setValue(parseInt(product.product_packet_size));
        }
    }, [product])

    const update_basket = useMutation({
        mutationKey: 'update_basket',
        mutationFn: (basket) => {
            //console.log(basket)
            return axiosRequest({
                gateway: config.shopAPI.URL,
                endpoint: 'basket',
                api_key: user.api_key,
                method: 'patch',
                body: {
                    "basket_id": basket.basket_id,
                    "basket_company": selectedCompany.company_id,
                    "basket_items": basket.items,
                    "basket_name": basket.basket_name ? basket.basket_name : 'Basket', //if no basket id set to 1
                }
            })
        },
        onSuccess: (context, variables) => {
            //console.log(context.insertId)
            //console.log(variables)
            dispatchBasket({
                type: UPDATE_BASKET,
                payload: {
                    "items": variables.items,
                    "basket_id": context.insertId,
                    "basket_company": selectedCompany.company_id,
                    "basket_name": variables.basket_name ? variables.basket_name : 'Basket'
                }
            });
        }
    })

    const addToBasket = (basket, product, prices) => {

        //get clone
        let clone = structuredClone({ ...product, ...prices })
        //remove properties from product not needed in basket
        //clone.product_name =  clone.product_name.replaceAll('\"', '\\"')
        clone.product_area = area
        clone.product_quantity = value
        delete clone.product_erp
        delete clone.product_matching
        delete clone.product_attributes
        //delete clone.product_image

        setLoading(true)
        let currentItems = [...basket.items];
        //Check to see if product exisits in basket
        let index = currentItems.findIndex((item) => item.product_id === clone.product_id);
        if (index > -1) {
            //update qyt
            currentItems[index].product_quantity = parseInt(currentItems[index].product_quantity) + parseInt(value);
        } else {
            //product.product_attributes = [] //properties breaking basket due to 's in the label text
            //currentItems = [...currentItems, { ...clone, "product_area": area, "product_quantity": value, ...prices }]
            currentItems = [...currentItems, clone]
        }

        //add to google analytics
        trackEvent({
            action: 'add_to_cart',
            params: {
                "currency": "GBP",
                "value": clone.price_incl_discount,
                "items": [
                    {
                        "item_id": clone.product_id,
                        "item_name": clone.product_description,
                        "product_number": clone.product_number,
                        "product_supplier": clone.product_supplier
                    }
                ]
            }
        })

        //console.log(currentItems)
        sessionStorage.setItem("basket_id", basket.basket_id);
        //sessionStorage.setItem("basketProduct", JSON.stringify(currentItems));
        //Set basket state
        update_basket.mutate({ ...basket, items: currentItems })
        //calculates all totals
        updateBasketTotals(currentItems, dispatchBasket);

        setTimeout(() => {
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setSuccess(false);
            }, 1000)
        }, 1000)
    }


    const decrease = (unit) => {
        if (value > 1) {
            //setValue(qty - 1);
            setValue(value => value - (1 * unit));
        }
    };

    const increase = (unit) => {
        //setValue(qty + 1);
        setValue(value => value + (1 * unit));
    };

    const updateValue = (value, max) => {
        //console.log(value);
        //setValue(value);
        if (/^\d*\.?\d*$/.test(value)) {
            if (parseInt(value) > max) {
                setValue(max);
            } else if (value) {
                setValue(parseInt(value));
            } else {
                setValue(0);
            }
        } else {
            setValue(0);
        }
    };

    return (

        <Grid item container spacing={1 / 2} >
            <Grid item container spacing={1 / 2} xs="auto">
                <Grid item>
                    <Button
                        //size="small"
                        variant="outlined"
                        onClick={(e) => decrease(product?.product_packet_size && parseInt(product?.product_packet_size) >  0 ? product.product_packet_size : 1)}
                        disabled={!product || product?.ErrorMessage || !selectedCompany?.company_erp ? true : value > 1 ? false : true}
                        sx={{
                            width: "35px",
                            minWidth: 'auto',
                            height: '100%',
                            backgroundColor: value > 1 ? (theme) => theme.palette.cards.main : 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faMinus}
                        />
                    </Button>

                </Grid>
                <Grid item >
                    <OutlinedInput
                        //size="small"
                        //type="number"
                        name="quantity"
                        min={product?.product_packet_size ? parseInt(product.product_packet_size) : 1}
                        max={1000}
                        value={value}
                        onChange={(e) => updateValue(e.target.value, 1000)}
                        sx={{
                            background: (theme) => theme.palette.cards.main,
                            minWidth: 'auto',
                            width: '50px',
                            textAlign: 'center',
                            padding: '5px'
                        }}
                        inputProps={{
                            style: {
                                textAlign: 'center',
                                padding: '5px'
                            }
                        }}
                        disabled={!product || product?.error || !selectedCompany?.company_erp ? true : false}
                    // inputProps={{
                    //     style: { textAlign: 'center'}
                    // }}
                    />

                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        //size="small"
                        onClick={(e) => increase(product?.product_packet_size && parseInt(product?.product_packet_size) >  0 ? parseInt(product.product_packet_size) : 1)}
                        disabled={!product || product?.error || !selectedCompany?.company_erp ? true : value >= 1000 ? true : false}
                        sx={{
                            width: "35px",
                            minWidth: 'auto',
                            height: '100%',
                            backgroundColor: value <= 1000 ? (theme) => theme.palette.cards.main : 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                        />
                    </Button>

                </Grid>
            </Grid>
            <Grid item xs>
                <LoadingButton
                    color={!success ? "primary" : "success"}
                    onClick={(e) => addToBasket(basket, product, product)}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={!success ? <FontAwesomeIcon icon={faCartShopping} /> : <FontAwesomeIcon icon={faCheckCircle} />}
                    variant="contained"
                    disabled={product?.error || !selectedCompany?.company_erp ? true : false}
                    sx={{
                        height: '100%',
                        width: '100%',
                        boxShadow: 'none'
                    }}
                >
                    {!success ? large ? 'Add to Basket' : 'Add' : 'Added'}
                </LoadingButton>

            </Grid>
            {product?.product_packet_size && parseInt(product?.product_packet_size) >  0 && (value % parseInt(product?.product_packet_size)) !== 0 ?
                <Grid item xs={12}>
                    <Alert severity="error" variant="outlined" sx={{ background: '#fef4f4', textAlign: 'center', fontSize: '12px' }}>
                        Quantity must be a multiple of {product?.product_packet_size}
                    </Alert>
                </Grid>
                : null}
        </Grid>
    );
};


