import React from "react";
import GlobalMeta from "../reusable/GlobalMeta";

const ResourceRoutesWrap = (props) => {

    return (
        <>
            <GlobalMeta title={props.title} />
            {props.children}
        </>
    );
};

export default ResourceRoutesWrap;
