import React, { useEffect, useContext, useState } from 'react';
import {
	Tab,
	Box,
	Card,
	Divider,
	ListItem
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import CompanyDetails from './CompanyDetails';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../language/setLanguageText';
import CompanyUsers from './users/CompanyUsers';
import CompanyCredits from './credits/CompanyCredits';
import Orders from '../companies/orders/Orders';
import Quotes from '../companies/quotes/Quotes';
import Downloads from './downloads/Downloads';
import getUserLevel from '../../helpers/getUserLevel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CompanyDefaults from './CompanyDefaults';
import DisplaySettings from '../../coolantcare/Display/DisplaySettings';
import CompanyAttributes from '../../coolantcare/attributes/CompanyAttributes';
import { LayoutContext } from '../../store/LayoutContext';

const CompanySettings = () => {

	const navigate = useNavigate();
	const [value, setValue] = useState(0);
	const [edit, setEdit] = useState(false);
	const { user } = useContext(UserContext);
	const [coolantTitle, setCoolantTitle] = useState()
	const { app } = useContext(LayoutContext)

	useEffect(() => {
		if (app.menu) {
			let found = app.menu.find(item => item.area === 'coolantcare')
			setCoolantTitle(found.buttonText)
		}
	}, [app.menu])


	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search);
		let tab = urlParams.get("tab")
		if (tab) {
			setValue(parseInt(tab));
		}
	}, [])

	const handleChange = (event, newValue) => {

		let urlParams = new URLSearchParams(window.location.search);
		let section = urlParams.get("section")
		navigate(`/settings/company?section=${section}&tab=${newValue}`)
		setValue(parseInt(newValue));
	};

	//add edit to form items
	useEffect(() => {

		if (getUserLevel(user.user, 'home', 'company_settings', 7)) {
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [user])

	return (

		<Card  >
			<Box sx={{ display: 'flex' }}>
				<TabContext value={value}>
					<Box
						sx={{
							overflow: "visible",
							minWidth: "240px",
							background: "#fbfbfb",
							'& .MuiTab-root': {
								textAlign: 'left',
								alignItems: 'start',
								borderBottom: '1px solid #e8e8e8'
							}
						}}>
						<TabList
							orientation={isMobileOnly ? "horizontal" : "vertical"}
							onChange={handleChange}
						>
							<Tab label={setLanguageText("Details")} value={0} />
							<Tab label={setLanguageText("Company Users")} value={1} />
							{/* {user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
								<Tab 
								sx={{borderBottom: '1px double #e8e8e8'}}
								label={setLanguageText("Ecommerce settings")} disabled />
								: null} */}
							{user.user.user_permissions.engineers.access === 1 ?
								<Tab label={setLanguageText("Download Tokens")} value={2} />
								: null}
							{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
								<Tab label={setLanguageText("Downloads")} value={4} />
								: null}
							{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
								<Tab label={setLanguageText("Orders")} value={3} />
								: null}
							{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
								<Tab label={setLanguageText("Saved Quotes")} value={5} />
								: null}
							{user.user.user_permissions.coolantcare.access === 1 ?
								<Tab label={`${coolantTitle} ${setLanguageText("Table Defaults")}`} value={6} />
								: null}
							{user.user.user_permissions.coolantcare.access === 1 ?
								<Tab label={`${coolantTitle} ${setLanguageText("Custom Attributes")}`} value={7} />
								: null}
							{user.user.user_permissions.coolantcare.access === 1 ?
								<Tab label={`${coolantTitle} ${setLanguageText("Display")}`} value={8} />
								: null}
						</TabList>
					</Box>
					<Box sx={{
						flexGrow: 1,
						'& .MuiTabPanel-root': {
							padding: 0,
							borderLeft: '1px solid #e8e8e8'
						}
					}}>
						<TabPanel value={0}>
							<CompanyDetails edit={edit} />
						</TabPanel>
						<TabPanel value={1}>
							<CompanyUsers />
						</TabPanel>
						{user.user.user_permissions.engineers.access === 1 ?
							<TabPanel value={2}>
								<CompanyCredits />
							</TabPanel>
							: null}
						{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
							<TabPanel value={3}>
								<Orders />
							</TabPanel>
							: null}
						{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
							<TabPanel value={4}>
								<Downloads />
							</TabPanel>
							: null}
						{user.user.user_permissions.application.access === 1 || user.user.user_permissions.buyers.access === 1 || user.user.user_permissions.engineers.access === 1 || user.user.user_permissions.parametric.access === 1 ?
							<TabPanel value={5}>
								<Quotes />
							</TabPanel>
							: null}
						{user.user.user_permissions.coolantcare.access === 1 ?
							<TabPanel value={6}>
								<CompanyDefaults edit={edit} />
							</TabPanel>
							: null}
						{user.user.user_permissions.coolantcare.access === 1 ?
							<TabPanel value={7}>
								<CompanyAttributes />
							</TabPanel>
							: null}
						{user.user.user_permissions.coolantcare.access === 1 ?
							<TabPanel value={8}>
								<DisplaySettings edit={edit} />
							</TabPanel>
							: null}
					</Box>
				</TabContext>
			</Box>
		</Card >
	);
}

export default CompanySettings;


