import React, {
	useCallback,
	useContext,
	useState
} from 'react';
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import ReportCard from '../ReportCard';
import DipslideQRCodesPDF from './DipslideQRCodesPDF';
import { dipslideQRCodeCreater } from '../../helpers/dipslideQRCodeCreater';
import config from '../../../config';
import setLanguageText from '../../../language/setLanguageText';

export default function DipslideQRCodesGlobal(){

	const [open, setOpen] = useState(false);
	const [pdf_codes, setPdf_codes] = useState();
	const [selectedSize, setSelectedSize] = useState(1);
	const [amount, setAmount] = useState(65);

	function make_id(length) {
		var result = [];
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result.push(
				characters.charAt(Math.floor(Math.random() * charactersLength))
			);
		}
		return result.join('');
	}

	const create_pdf_codes = useCallback(async () => {
		let codes = [];
		for (let step = 0; step < amount; step++) {
			let code = make_id(12);
			let url = dipslideQRCodeCreater(`${code} ${config.APPNAME}`);
			codes.push({
				url: url,
				code: code
			});
		}
		setPdf_codes(codes);
	},
		[amount]
	);

	const runReport = () => {
		setOpen(true);
		create_pdf_codes();
	};

	return (
		<>
			<ReportCard
				title={setLanguageText("Dipslide QR Code Labels")}
				buttonLabel={setLanguageText("Create Labels")}
				disabled={!selectedSize || !amount ? true : false}
				runReport={runReport}
			>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="select-amount">{setLanguageText("Select Page Amount")}</InputLabel>
							<Select
								value={amount}
								onChange={(e, value) => {
									setAmount(e.target.value);
								}}
								labelId="select-amount"
								label={setLanguageText("Select Page Amount")}
							>
								<MenuItem value={65}>1</MenuItem>
								<MenuItem value={130}>2</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel>{setLanguageText("Select Size")}</InputLabel>
							<Select
								value={selectedSize}
								disabled
								onChange={e => setSelectedSize(e)}
								label={setLanguageText("Select Size")}
							>
								<MenuItem value={1}>38.1mm x 21.17mm</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</ReportCard>
			{pdf_codes &&
				<DipslideQRCodesPDF
					dialogOpen={open}
					dialogClose={() => {
						setOpen(false);
						setPdf_codes();
					}}
					pdf_codes={pdf_codes}
					//selectedCompany={selectedCompany}
				/>
			}
		</>
	);
};
