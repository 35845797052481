import React from "react";
import String from "./String";
import MultiSelectImage from "./MultiSelectImage";
import Float from "./Float";
import Check from "./Check";
import Dropdown from "./Dropdown";
import Integer from "./Integer";
import InterfaceInformation from "../../wizards/InterfaceInformation";
import { Grid } from "@mui/material";
import Materials from "../../wizards/Materials";
import IsoAssistant from "../../wizards/IsoAssistant";

const InputTypes = ({ field, filtersView }) => {
	//render fields
	const renderFilterValues = (field,filtersView) => {
		//console.log(field)
		
		if (field.Type === "float") {
			return (
				<Float field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "string") {
			return (
				<String field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "imageMultiselect") {
			return (
				<MultiSelectImage field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "check") {
			return (
				<Check field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "imageDropdown") {
			return (
				<Dropdown field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "dropdown") {
			return (
				<Dropdown field={field} filtersView={filtersView} />
			);
		} else if (field.Type === "int") {
			//console.log(field)
			return (
				<Integer field={field} filtersView={filtersView} />
			);
		}
		return null;
	};

	//console.log(field)

	return (
		<Grid container item spacing={1} alignContent="center" alignItems="center">
			<Grid container item xs>
				{field ? renderFilterValues(field,filtersView) : null}
			</Grid>
			{!filtersView &&
			<Grid container item sx={{width: '40px'}}>
				{field.Ref === 'C11' && 
					<InterfaceInformation field={field} />
				}
				{field.Ref === 'W1' && 
					<Materials field={field} />
				}
				{field.Ref === 'J11' && 
					<IsoAssistant field={field} />
				}
			</Grid>
}
		</Grid>

	)
};

export default InputTypes;
