import { Card, CardContent, CardHeader, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, ArcElement } from 'chart.js';
ChartJS.register(LineElement, PointElement, LinearScale, Title, ArcElement);
import createPercentage from '../../helpers/createPercentage';
import setLanguageText from '../../../language/setLanguageText';
import CardStyles from '../../styles/cardStyles';

function PieChart({ results }) {

	const cardStyles = CardStyles();
	const theme = useTheme();

	let data

	if (results.data.in) {
		data = {
			datasets: [
				{
					data: [
						results.data.in.count,
						results.data.out.count,
						results.data.edge.count,
						results.data.none.count,
						results.data.no_entries.count
					],
					backgroundColor: [
						results.data.in.colour,
						results.data.out.colour,
						results.data.edge.colour,
						results.data.none.colour,
						results.data.no_entries.colour
					]
				}
			],
			labels: [
				results.data.in.label,
				results.data.out.label,
				results.data.edge.label,
				results.data.none.label,
				results.data.no_entries.label
			]
		};
	} else {
		data = {
			datasets: [
				{
					data:
						results.data.map((item) =>
							item.count
						),
					backgroundColor:
						results.data.map((item) =>
							item.colour
						)
				}
			],
			labels:
				results.data.map((item) =>
					item.label
				)
		};
	}

	const options = {
		plugins: {
			title: {
				display: false
			},
			legend: {
				display: false
			},
		}
	};

	//console.log(results?.data)
	// console.log(options)

	return (
		<Card sx={{ height: '100%', display: "flex", flexDirection: "column" }}>
			<CardHeader
				className={cardStyles.header}
				disableTypography
				title={
					<Typography variant="h5" textAlign="center">{results.name}</Typography>
				}
			/>
			<CardContent sx={{ margin: "auto 0" }} >
				<Pie data={data} options={options} />
			</CardContent>
			<Grid
				item
				container
				justifyContent="space-between"
				className={cardStyles.footer}
			>
				<Grid item container xs={4} justifyContent="center">
					<Grid item xs={12} >
						<Typography
							sx={{
								color: results?.data?.out?.colour
							}}
							textAlign={"center"}
							variant="body2"
						>
							{setLanguageText("Out")}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Typography
							variant="body2"
							align="center"
						>
							{/* avoid returning NaN when loading */}
							<strong>
								{results.data.out.count && createPercentage(
									(results.data.out.count /
										(results.data.in.count +
											results.data.out.count +
											(results.data.edge.count
												? results.data.edge.count
												: 0) +
											results.data.no_entries.count +
											(results.data.none.count
												? results.data.none.count
												: 0))) *
									100
								)}
							</strong>
						</Typography>
					</Grid>
				</Grid>
				<Grid item
					sx={{
						borderLeft: theme => `1px solid ${theme.palette.tabs.border}`,
						borderRight: theme => `1px solid ${theme.palette.tabs.border}`,
					}}
					container
					xs={4}
					justifyContent="center"
				>
					<Grid

						item xs={12} container justifyContent="center">
						<Typography
							textAlign={"center"}
							variant="body2"
						>
							<Typography
								sx={{
									color: results?.data?.in?.colour
								}}
								component={"span"}>
								{setLanguageText("In")}
							</Typography>
							<Typography
								sx={{
									marginLeft: theme.spacing(1/2),
									marginRight: theme.spacing(1/2),
								}}
								component={"span"}>
								+
							</Typography>

							<Typography
								sx={{
									color: results?.data?.edge.colour
								}}
								component={"span"}>
								{setLanguageText("Edge")}
							</Typography>
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Typography
							variant="body2"
							textAlign="center"
						>
							<strong>
								{results.data.in.count && createPercentage(
									((results.data.in.count +
										(results.data.edge.count
											? results.data.edge.count
											: 0)) /
										(results.data.in.count +
											results.data.out.count +
											(results.data.edge.count
												? results.data.edge.count
												: 0) +
											results.data.no_entries.count +
											(results.data.none.count
												? results.data.none.count
												: 0))) *
									100
								)}
							</strong>
						</Typography>
					</Grid>
				</Grid>

				<Grid item container xs={4} justifyContent="center">
					<Grid item xs={12} justifyContent="center">
						<Typography
							sx={{
								color: results?.data?.no_entries?.colour
							}}
							textAlign={"center"}
							variant="body2"
						>
							{setLanguageText("N/A")}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent="center">
						<Typography
							variant="body2"
							textAlign="center"
						>
							{/* avoid returning NaN when loading */}

							<strong>
								{(results.data.no_entries.count || results.data.none.count ) && createPercentage(
									((results.data.no_entries.count +
										(results.data.none.count
											? results.data.none.count
											: 0)) /
										(results.data.in.count +
											results.data.out.count +
											(results.data.edge.count
												? results.data.edge.count
												: 0) +
											results.data.no_entries.count +
											(results.data.none.count
												? results.data.none.count
												: 0))) *
									100
								)}
							</strong>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
}

export default PieChart;
