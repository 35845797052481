
import React from "react";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import SpinningLoader from "../../reusable/SpinnningLoader";
import useImageValidity from "../../hooks/useImageValidity";
import ProductViewer from "../../products/cim/ProductViewer";

const SingleImage = ({ imageUrl, alt, height, icon, padding, position, viewer, thumbnail }) => {

    const isValid = useImageValidity(imageUrl);
    //console.log(isValid)

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            position: position ? position : 'absolute',
            zIndex: 2,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            //display: 'grid',
            top: 0,
            left: 0,
            padding: padding ? padding : '0'
        }}>
            <Box sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
            }}>
                {viewer && isValid ?
                    <ProductViewer viewer={viewer} thumbnail={thumbnail} />
                    : null}
                {isValid === null ?
                    <SpinningLoader size={40} grey />
                    : isValid ?
                        <img
                            style={{
                                pointerEvents: 'none',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                height: height ? height : 'auto',
                                objectFit: 'contain',
                                margin: '0 auto'
                            }}
                            src={imageUrl}
                            alt={alt}
                        />
                        : !isValid &&
                        <FontAwesomeIcon icon={faImageSlash} color="#ccc" size={icon ? icon : "2x"} />
                }
            </Box>
        </Box>
    )
};

export default SingleImage