function getVideoFrameBase64FromFile(file, callback, secs = 1) {
    var me = this,
        video = document.createElement('video');

    video.onloadedmetadata = function () {
        if ('function' === typeof secs) {
            secs = secs(this.duration);
        }
        this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
    };

    video.onseeked = function (e) {
        var canvas = document.createElement('canvas');
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        var ctx = canvas.getContext('2d', { willReadFrequently: true })
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Get the base64-encoded PNG data
        var base64Data = canvas.toDataURL("image/png");
        callback.call(me, base64Data, this.currentTime, e);
    };

    video.onerror = function (e) {
        callback.call(me, undefined, undefined, e);
    };

    // Create a URL for the uploaded video Blob
    var videoUrl = URL.createObjectURL(file);
    video.src = videoUrl;
}

export default getVideoFrameBase64FromFile