import React, { useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import config from '../config';
import packageJson from '../../package.json';
import { isMobile } from 'react-device-detect';

export default function CISFooter({ login }) {

	const year = new Date();
	const [logo, setLogo] = useState('');

	import(`../images/cis-black.png`).then((module) => {
		setLogo(module.default);
	});

	return (
		<Grid
			container
			spacing={2}
			alignItems={login || isMobile  ? "center" : "end"}
			justifyContent={login || isMobile  ? "center" : "space-between"}
			sx={{
				//backgroundColor: (theme) => theme.palette.background.default,
				color: (theme) => theme.palette.text.primary,
				padding: '1em 1em 2em 1em',
				marginTop: login || isMobile  ? '1em' : '2em',
				marginBottom: isMobile  ? '3em' : '0',
			}}
		>
			<Grid item xs={12} md={login || isMobile  ? 12 : "auto"} sx={{ textAlign: login || isMobile  ? "center" : 'left' }}>
				<Typography
					color={(theme) => theme.palette.text.secondary}
					fontWeight={600}
					variant="subtitle2"
					sx={{ marginBottom: login ? '10px' : '0' }}
				>
					{`${config.APPTITLE}`} - v{packageJson.version}
				</Typography>
				<Typography color={(theme) => theme.palette.text.secondary} variant="subtitle2">
					Copyright © {year.getFullYear()} CIS, All Rights Reserved 
					{/* | Designed &amp; Developed by <Link sx={{ color: (theme) => theme.palette.primary.main }} title="Cambridge Applcation Developers" href="https://www.design-monkey.co.uk" rel="noreferrer" target="_blank"> Design Monkey Media ltd</Link>.
					Powered by <Link sx={{ color: (theme) => theme.palette.primary.main }} href='https://aws.amazon.com/' rel="noreferrer" target="_blank">AWS</Link> */}
				</Typography>
			</Grid>
			<Grid container item xs={12} md={login || isMobile ? 12 : "auto"} >
				<Grid item xs={12}
					sx={{
						marginBottom: login || isMobile  ? '10px' : '0',
						textAlign: login || isMobile  ? "center" : 'right'
					}}
				>
					<img
						width={login ? "180px" : "180px"}
						alt="CIS"
						src={logo}
					/>
				</Grid>
			</Grid>

		</Grid>
	)
};
