import React, { useReducer, createContext } from 'react';
import { BrandsReducer } from './BrandsReducers';
import { OpenSearch } from 'aws-sdk';

export const BrandsContext = createContext();

BrandsContext.displayName = 'Shop Brands';

export const BrandsProvider = ({ children }) => {

	const [brands, dispatchBrands] = useReducer( BrandsReducer, {
		allBrands: [],
		openSearchBrands: [],
		selectedBrands: [],
	});
	const contextValues = {
		brands,
		dispatchBrands,
	};

	return (
		<BrandsContext.Provider value={contextValues}>
			{children}
		</BrandsContext.Provider>
	);
};
