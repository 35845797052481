import React, {
	useContext,
	useState,
	useRef
} from 'react';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import ServiceForm from './ServiceForm';
import GlobalDialog from '../../reusable/GlobalDialog';
import SignatureDialog from './SignatureDialog';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import useActivityLog from '../../users/activity/useActivityLog';
import DialogLoader from '../../reusable/DialogLoader';
import ServiceReportDialog from './ServiceReportDialog';
import { set } from 'lodash';

export default function ServiceReport({ openReport, setOpenReport, rows, headers }) {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [engineerSignOpen, setEngineerSignOpen] = useState(false);
	const [customerSignOpen, setCustomerSignOpen] = useState(false);
	const { updateActivityLog } = useActivityLog();
	const [openServicePDF, setOpenServicePDF] = useState(false);
	const [newID, setNewID] = useState(null);

	const [serviceObj, setServiceObj] = useState({
		service_user_id: user.id,
		service_company_id: selectedCompany.company_id,
		service_name: user.name,
		customer_name: '',
		service_notes: '',
		service_sign: null,
		cust_sign: null,
		service_attr: {
			startTime: new Date().getTime(),
			finishTime: new Date().getTime()
		}
	});

	const openEngineerSignOpen = e => {
		setEngineerSignOpen(true);
	};
	const openCustomerSignOpen = e => {
		setCustomerSignOpen(true);
	};
	const closeEngineerSignOpen = e => {
		setEngineerSignOpen(false);
	};
	const closeCustomerSignOpen = e => {
		setCustomerSignOpen(false);
	};

	const engineer_sign_ref = useRef(null);
	const customer_sign_ref = useRef(null);
	const dialog_content_ref = useRef(null);

	const submitEngineerSign = (e, sign_ref) => {
		e.preventDefault();
		setServiceObj({
			...serviceObj,
			service_sign: sign_ref.current.getSignaturePad().toDataURL('image/png')
		});
		closeEngineerSignOpen();
	};

	const submitCustomerSign = (e, sign_ref) => {
		e.preventDefault();
		setServiceObj({
			...serviceObj,
			cust_sign: sign_ref.current.getSignaturePad().toDataURL('image/png')
		});
		closeCustomerSignOpen();
	};

	const updateServiced = useMutation({
		mutationFn: (ids) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'patch',
			body: {
				"serviced": true,
				"entry_ids": ids
			}
		}),
		onSuccess: () => {

			setOpenReport(false)

		}
	});

	const addServiceReport = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'service-report',
			api_key: user.api_key,
			method: 'post',
			body: body
		}),
		onSuccess: (response) => {

			updateActivityLog.mutate({
				activity_details: {
					area: "coolant",
					service_id: response.insertId
				},
				activity_type: "new service"
			})
			setNewID(response.insertId);
			setOpenServicePDF(true);

		}
	});


	const handleSubmit = () => {

		console.log(rows);
		const service_entry_ids = rows.map(item => item.entry_id);


		function formatTime(stamp) {
			const date = new Date(stamp);
			// Get hours and minutes
			const hours = date.getHours();
			const minutes = date.getMinutes();
			// Pad hours and minutes with leading zeros if necessary
			const formattedHours = hours < 10 ? '0' + hours : hours;
			const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
			// Construct the final time string
			const formattedTime = `${formattedHours}:${formattedMinutes}`;
			return formattedTime;
		}

		const service_obj_to_send = {
			...serviceObj,
			service_entry_ids,
			service_attr: {
				startTime: formatTime(serviceObj.service_attr.startTime),
				finishTime: formatTime(serviceObj.service_attr.finishTime)
			},
			service_user_id: user.user.user_id,
			service_company_id: selectedCompany.company_id
		};

		addServiceReport.mutate(service_obj_to_send);
		updateServiced.mutate(service_entry_ids)

	};

	return (
		<>
			<GlobalDialog
				open={openReport}
				onClose={() => setOpenReport(false)}
				title="Create Service Report"
				buttonClick={handleSubmit}
				buttonTitle="Submit Service"
				successMessage="Service Submitted!"
				disabled={!serviceObj.service_name || !serviceObj.customer_name || !serviceObj.service_sign || !serviceObj.cust_sign || addServiceReport.isLoading || updateServiced.isLoading}
				fullWidth
				maxWidth="lg"
			>
				<ServiceForm
					rows={rows}
					headers={headers}
					serviceObj={serviceObj}
					setServiceObj={setServiceObj}
					openEngineerSignOpen={openEngineerSignOpen}
					openCustomerSignOpen={openCustomerSignOpen}
				/>
				{addServiceReport.isLoading || addServiceReport.isSuccess || addServiceReport.isError ?
					<DialogLoader
						isLoading={addServiceReport.isLoading || updateServiced.isLoading}
						mutation={addServiceReport}
						loadingMessage="Adding Service"
						successMessage="Added"
						fixed
					/>
					: null}
			</GlobalDialog>
			<SignatureDialog
				open={engineerSignOpen}
				closeDialog={closeEngineerSignOpen}
				submit_sign={e => submitEngineerSign(e, engineer_sign_ref)}
				title="Engineer Signature"
				sign_ref={engineer_sign_ref}
				dialog_content_ref={dialog_content_ref}
				serviceObj={serviceObj}
				setServiceObj={setServiceObj}
			/>
			<SignatureDialog
				open={customerSignOpen}
				closeDialog={closeCustomerSignOpen}
				submit_sign={e => submitCustomerSign(e, customer_sign_ref)}
				title="Customer Signature"
				sign_ref={customer_sign_ref}
				dialog_content_ref={dialog_content_ref}
				serviceObj={serviceObj}
				setServiceObj={setServiceObj}
			/>


			<ServiceReportDialog
				id={newID}
				openServicePDF={openServicePDF}
				setOpenServicePDF={setOpenServicePDF}
			/>
		</>
	);
};

