import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const GlobalStepperDialog = ({title, open, onClose,children, maxWidth, customActions }) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
        >
             <DialogTitle  sx={{ background: (theme) => theme.palette.grey[200] }} >
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            right: 8,
                            top: 14,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent sx={{padding : '1em'}}>
                {children}
            </DialogContent>
            <DialogActions sx={{ background: (theme) => theme.palette.grey[200],  padding : '1em' }} >
               {customActions}
            </DialogActions>
        </Dialog>
    );
};

export default GlobalStepperDialog;