import * as React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGridRound } from '@fortawesome/pro-solid-svg-icons';
import { LayoutContext } from '../../store/LayoutContext';
import AreaMenu from './AreaMenu';
import { CategoryContext } from '../../store/CategoryContext';
import { RESET_ALL_FILTERS } from '../../store/SelectedReducers';
import { SelectedContext } from '../../store/SelectedContext';
import { UPDATE_CATEGORIES } from '../../store/CategoryReducers';
import { SHOW_CATEGORIES, UPDATE_MENU } from '../../store/LayoutReducers';
import findCurrentCategory from '../../shop/libs/findCurrentCategory';
import { Backdrop } from '@mui/material';
import config from '../../config';
import { isMobileOnly } from 'react-device-detect';


export default function AreaDropdown() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { app, dispatchLayout, dispatchApp } = React.useContext(LayoutContext);
    const { categories, dispatchCategories } = React.useContext(CategoryContext);
    const { dispatchSelected } = React.useContext(SelectedContext);
    const [mainMenu, setMainMenu] = React.useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        //close menu
        //reset all filters
        dispatchSelected({
            type: RESET_ALL_FILTERS
        })
        //sessionStorage.removeItem("parametricFilters");
        //console.log(categories.allCats)
        //set current catergory back to root
        const category = findCurrentCategory([categories.allCats], 'Root')
        if (category) {
            dispatchCategories({
                type: UPDATE_CATEGORIES,
                payload: {
                    currentCat: category[0].cat,
                    openLevel: category[0].path
                }
            });
            dispatchLayout({
                type: SHOW_CATEGORIES
            });
        }
        //console.log(area,menu)
        // dispatchApp({
        //     type: UPDATE_MENU,
        //     payload: {
        //         selectedArea: menuItem.area,
        //         selectedMenu: menuItem.menu
        //     }
        // })
        // navigate(menuItem.link);
        setAnchorEl(null);

    };

    //remove hidden items
    React.useEffect(() => {
        if (app.menu) {
            let visible = app.menu.filter((items) => !items.hidden)
            //setMainMenu(visible)
            //order by order
            setMainMenu(visible.sort((a, b) => a?.order - b?.order))
        }
    }, [app.menu])

    return (
        <React.Fragment>
            <Box
                sx={{
                    transition: 'all .3s',
                    background: theme => anchorEl ? theme.palette.primary.main : 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: isMobileOnly ? '10px' : '1em',
                    //marginLeft: '-5px',
                    marginRight: '15px',
                    cursor: 'pointer',
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                    position: 'relative',
                    '&:hover': {
                        background: config.APPNAME === "act" ? '#efefef' : '#222'
                    },
                    '& svg': {
                        color: theme => anchorEl ? theme.palette.primary.contrastText : config.APPNAME === "act" ? theme.palette.text.primary : theme.palette.primary.contrastText
                    }
                }}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faGridRound} size='2x' />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
            </Backdrop>
            <Popover
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => setAnchorEl(null)}
                //onClick={handleClose}
                disableScrollLock
                slotProps={{
                    paper: {
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            //filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            border: '1px solid',
                            borderColor: 'divider',
                            //width: '420px',
                            padding: '2em',
                            //marginLeft: '-5px',
                            borderRadius: '0',
                            '& ul': {
                                padding: 0
                            }

                        },
                    }
                }
                }
                //transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <AreaMenu menu={mainMenu} handleClose={handleClose} />
            </Popover>
        </React.Fragment >
    );
}