import {
  Chip,
  Grid,
  Skeleton,
  Typography,
  Alert,
  useMediaQuery,
  TableCell,
  TableRow,
  TableBody,
  Table
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import { useMutation } from 'react-query';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import CustomCard from '../reusable/CustomCard';
import ReactHtmlParser from 'html-react-parser';
import FileView from '../media/FileView';
import FixturesComments from './FixturesComments';

const FixturesView = () => {

  const { user } = useContext(UserContext);
  const [request, setRequest] = useState();
  const [checked, setChecked] = useState(false);
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  // gets request full details
  const request_mutation = useMutation((details) => {
    //console.log(user)
    return axiosRequest({
      method: 'get',
      endpoint: 'requests',
      gateway: config.fixturesAPI.URL,
      api_key: user.api_key,
      params: details
    })

  }, {
    onSuccess: (data) => {
      //console.log(data)
      if (data) {
        let parseObject = {
          ...data[0],
          request_company: data[0].request_company,
          request_files: data[0].request_files,
          request_last_updated: data[0].request_last_updated,
          request_user: data[0].request_user,
        }
        setRequest(parseObject)
        let closed = Boolean(data[0].request_status === "Closed")
        setChecked(closed)
        //comments_mutation.mutate({ comment_request_id: data[0].request_id })
      }
    }
  })

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      let company_id = urlParams.get("company")
      let request_id = urlParams.get("request")
      //console.log(user.user.user_permissions.access.companies.includes(parseInt(company_id)))
      //admin always allowed to access request
      if (user.user.user_permissions.administrator) {
        request_mutation.mutate({ company_id: company_id, request_id: request_id })
      } else if (user.user.user_permissions.access.companies.includes(parseInt(company_id))) {
        //check users allowed companies in
        request_mutation.mutate({ company_id: company_id, request_id: request_id })
      } else {
        //no access to this copnaies request
      }
      //request_mutation.mutate({ user: user, id: id })
    }
  }, [user, window.location.search])


  return (
    <>
      <InnerHeaderWrap >
      </InnerHeaderWrap>
      <PageContent>
        <Grid item container xs={12} spacing={2} justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
          <Grid item>
            <Typography component="p" variant='h6'>
              View Fixture Request
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <CustomCard title="Fixture Details">
              <Grid item container xs={12} spacing={2} justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
                <Grid item xs={10}>
                  <Typography component="h3" variant='h4'>{request ? request.request_name : <Skeleton />}</Typography>
                </Grid>
                <Grid container item xs={12} md={2} justifyContent={isMd ? "flex-end" : "flex-end"}>
                  {request ?
                    <>
                      <Grid item><Chip label={request.request_status} sx={{ marginRight: '1em', borderRadius: '5px', fontSize: '1em' }} /></Grid>
                      <Grid item><Typography component="p" variant='h6' fontWeight={400} textAlign="right">#{request.request_id}</Typography></Grid>
                    </>
                    :
                    <Typography component="p" variant='h6' fontWeight={400} textAlign="right"><Skeleton width={200} /></Typography>}
                </Grid>
                <Grid item xs={12}>
                  {request ?
                    <>
                      <Typography component="p" variant='subtitle2'>Added by <strong>{request.request_user.user_details.name} {request.request_user.user_details.surname}</strong> on <strong>{new Date(request.request_submit_date).toLocaleDateString()} {new Date(request.request_submit_date).toLocaleTimeString()}</strong></Typography>
                      
                      <Table dense sx={{ margin: '1em 0' }} >
                        <TableBody>
                          <TableRow
                            sx={{ '& td, & th': { border: 0, padding: '5px 0' } }}
                          >
                            <TableCell sx={{ width: '300px' }}>
                              <Typography component="p" variant='body1' fontWeight={600}>Required by</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="p" variant='body1'>{new Date(request.request_required_date).toLocaleDateString()}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '& td, & th': { border: 0, padding: '5px 0' } }}
                          >
                            <TableCell sx={{ width: '300px' }}>
                              <Typography component="p" variant='body1' fontWeight={600}>Company</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="p" variant='body1'>{request.request_company.company_name}</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>   
                      </>
                    :
                    <>
                      <Skeleton width={140} />
                      <Skeleton width={120} />
                      <Skeleton width={100} />
                    </>
                  }
                </Grid>
                <Grid item xs={12} sx={{
                  ' & > img ': {
                    maxWidth: '100%',
                    height: 'auto'
                  },
                  ' & > p , & > p span': {
                    fontSize : '1em!important'
                  }
                }}>
                  {request ?
                    ReactHtmlParser(request.request_details)
                    : <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton width={140} />
                    </>
                  }
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>

          <Grid item xs={12}>
            <CustomCard title="Fixture Attachments">
              {request?.request_files?.files ?
                <>
                  {Object.values(request.request_files.files).length ?

                    <Grid container item spacing={4} >
                      {request.request_files.files.map((file, i) => {
                        return (
                          <React.Fragment key={i}>
                            <FileView
                              request_id={request?.request_id}
                              file={file}
                            />
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                    :
                    <Alert severity='info'>No files uploaded with original request.</Alert>
                  }
                </>
                :
                <Grid container item spacing={4} >
                  <Grid item >
                    <Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
                  </Grid>
                  <Grid item >
                    <Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
                  </Grid>
                  <Grid item >
                    <Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
                  </Grid>
                </Grid>
              }
            </CustomCard>
          </Grid>
        </Grid>
        <FixturesComments request={request} checked={checked} setChecked={setChecked} />
      </PageContent>
    </>
  );
}

export default FixturesView;
