import React from "react";
import { Grid, Typography } from "@mui/material";
import Float from "./fields/Float";
import Integer from "./fields/Integer";
import Dropdown from "./fields/Dropdown";


const FormFields = ({ field, focus, updateValue, focusField}) => {
	//render fields
	const renderFilterValues = (field) => {
		
		if (field.Type === "F" && field.RelOperatorSelectable) {
			return <Float field={field} focus={focus} updateValue={updateValue} focusField={focusField}/>
		
		} else if (field.Type === "F" && !field.RelOperatorSelectable) {
			//console.log(field)
			return <Integer field={field} focus={focus} updateValue={updateValue} focusField={focusField}/>
		
		} else if (field.Type === "A") {
			return <Dropdown field={field} focus={focus} updateValue={updateValue} focusField={focusField}/>
		
		} else if (field.Type === "I" ) {
			//console.log(field)
			return <Integer field={field} focus={focus} updateValue={updateValue} focusField={focusField}/>

		} else if (field.Type === "B" ) {
			//console.log(field)
			return <Integer field={field} focus={focus} updateValue={updateValue} focusField={focusField}/>

		} else if (field.Type === "Info" ){
            return <Grid item sx={{marginBottom: '1em'}}>
				<Typography variant="body1" fontWeight="700">{field.Label}: </Typography>
				<Typography variant="body1">{field.DiscreteValues &&
					field.DiscreteValues.map((value) => `${value.Name}; `)
				}
				</Typography>
				</Grid>
        }
		return null;
	};

	return (
		<>
			{field ? renderFilterValues(field) : null}
		</>

	)
};

export default FormFields;
