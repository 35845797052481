import {
	faMoneyCheckEdit,
	faPencilRuler,
	faQrcode,
	faVials,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AddEntry from '../Entry/AddEntry';
import Products from './Products';
import setLanguageText from '../../language/setLanguageText';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';


export default function MobileLanding() {

	const navigate = useNavigate();
	const [addEntry, setAddEntry] = useState(false);

	const handleAddEntry = () => {
		setAddEntry(true);
	};

	const handleCloseDialog = () => setAddEntry(false);

	const handleService = () => {
		navigate('/coolantcare/service');
	};

	const handleProducts = () => {
		navigate('/coolantcare/products');
	};

	return (
		<>
			<InnerHeaderWrap></InnerHeaderWrap>
			<Grid sx={{ padding: theme => theme.spacing(1) }}>
				<Grid container spacing={1} >
					<Grid item xs={12}>
						<Link to="/coolantcare/qr-code">
							<Button fullWidth variant="contained" color="secondary" sx={{ padding: '10px' }}>
								<Grid container>
									<Grid item xs={12} >
										<FontAwesomeIcon icon={faQrcode} size="2x" />
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1" >{setLanguageText("Scan QR")}</Typography>
									</Grid>
								</Grid>
							</Button>
						</Link>
					</Grid>

					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleAddEntry}
							sx={{ padding: '10px' }}
						>
							<Grid container >
								<Grid item xs={12} >
									<FontAwesomeIcon icon={faPencilRuler} size="2x" />
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1" >{setLanguageText("Create Machine Entry")}</Typography>
								</Grid>
							</Grid>
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleProducts}
							sx={{ padding: '10px' }}
						>
							<Grid container>
								<Grid item xs={12} >
									<FontAwesomeIcon icon={faVials} size="2x" />
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1" >{setLanguageText("Products")}</Typography>
								</Grid>
							</Grid>
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleService}
							sx={{ padding: '10px' }}
						>
							<Grid container>
								<Grid item xs={12} >
									<FontAwesomeIcon icon={faMoneyCheckEdit} size="2x" />
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1" >{setLanguageText("Create Service Report")}</Typography>
								</Grid>
							</Grid>
						</Button>
					</Grid>
				</Grid>
			</Grid>

			{addEntry ?
				<AddEntry isDialog={addEntry} setIsDialog={handleCloseDialog} />
				: null}
		</>
	);
};
