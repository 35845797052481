import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Chip, Button } from '@mui/material'
import DialogLoader from '../../reusable/DialogLoader';
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table'
import TableHeaderCell from '../../tables/TableHeaderCell';
import setLanguageText from '../../language/setLanguageText';
import getUserLevel from '../../helpers/getUserLevel';
import { UserContext } from '../../store/UserContext';

export default function ColourChipsTable({ data, isFetching, editVariable }) {

	const { user } = useContext(UserContext);
	const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const [columnVisibility, setColumnVisibility] = useState({})

	useEffect(() => {
		if (user.user.user_roles) {
			setColumnVisibility({ "attr_actions": getUserLevel(user.user, 'coolantcare', 'custom_attributes', 9)})
		}
	}, [user.user.user_roles])


	const columns = useMemo(() => [
		// {
		// 	id: 'id',
		// 	hidden: true,
		// 	header: setLanguageText("ID"),
		// 	accessorKey: 'id',
		// },
		{
			header: setLanguageText("Name"),
			accessorKey: 'name',
			enableSorting: false,
			size: 100,
			cell: info => setLanguageText(info.getValue())
		}, {
			hidden: true,
			header: setLanguageText("Status"),
			accessorKey: 'value',
			enableSorting: false,
			size: 100,
		}, {
			header: setLanguageText("Colour"),
			accessorKey: 'colour.hex',
			enableSorting: false,
			size: 100,
			cell: info => {
				return (
					<Chip
						sx={{ backgroundColor: info.getValue() ? info.getValue() : 'transparent' }}
						label={<span>{setLanguageText("Example")}</span>}
					/>
				)
			}
		}, {
			accessorKey: 'attr_actions',
			enableSorting: false,
			header: '',
			width: 100,
			cell: data => (
				<div className="form-button">
					<Button
						//fullWidth
						variant="contained"
						color="primary"
						onClick={() => editVariable(data?.row?.original)}
					//className={`${buttonStyle.button}`}
					>
						{setLanguageText("Change Chip Colour")}
					</Button>
				</div>
			)
		}
	], []);

	const table = useReactTable({
		data: data ?? [],
		columns,
		state: {
			columnVisibility,
		},
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	})

	// Manage your own state
	//const [state, setState] = useState(table.initialState)
	// // Override the state managers for the table to your own
	// table.setOptions(prev => ({
	// 	...prev,
	// 	state,
	// 	onStateChange: setState,
	// 	// These are just table options, so if things
	// 	// need to change based on your state, you can
	// 	// derive them here
	// 	// Just for fun, let's debug everything if the pageIndex
	// 	// is greater than 2
	// 	debugTable: state.pagination.pageIndex > 2,
	// }))

	return (

		<Box sx={{ width: '100%' }}>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<TableContainer >
						<Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
							<TableHead>
								{table.getHeaderGroups().map((headerGroup, i) => (
									<TableRow
										key={i}
										sx={{
											backgroundColor: (theme) => theme.palette.table.header,
										}}
									>
										{//added a custom header cell
											headerGroup.headers.map((header, i) => <TableHeaderCell key={i} header={header} i={i} />)
										}
									</TableRow>
								))}
							</TableHead>
							<TableBody>
								{isFetching ?
									new Array(pageSize).fill(0).map((_, i) => {
										return (
											<TableRow
												key={i}
												sx={{
													cursor: 'pointer',
													borderBottom: '1px solid #ccc',
													backgroundColor: (theme) => theme.palette.table.row,
													'&:nth-of-type(odd)': {
														backgroundColor: (theme) => theme.palette.table.hover,
													},
													'&:last-child td, &:last-child th': {
														borderBottom: 0,
													},
												}}>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
												<TableCell><Skeleton height={30} /></TableCell>
											</TableRow>
										)
									})

									: !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {
										return (
											<TableRow
												key={i}
												sx={{
													cursor: 'pointer',
													borderBottom: '1px solid #ccc',
													backgroundColor: (theme) => theme.palette.table.row,
													'&:nth-of-type(odd)': {
														backgroundColor: (theme) => theme.palette.table.hover,
													},
													'&:last-child td, &:last-child th': {
														borderBottom: 0,
													},
												}}
											>
												{row.getVisibleCells().map((cell, i) => {
													return (
														<TableCell key={i}>
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext()
															)}
														</TableCell>
													)
												})}

											</TableRow>
										)
									})
										:
										<TableRow>
											<TableCell colSpan={7}>
												<Alert sx={{ width: '100%', margin: '1em' }} severity="info">No data found</Alert>
											</TableCell>
										</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Box>
	);
};
