import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContent, Box, MenuItem, Avatar } from '@mui/material';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalStepperDialog from '../../reusable/GlobalStepperDialog';
import { Formik } from 'formik';
import config from '../../config';
import { Auth } from "aws-amplify";
import { useMutation } from 'react-query';
import axiosRequest from '../../axios/axoisRequest';
import { UserContext } from '../../store/UserContext';
import DialogLoader from '../../reusable/DialogLoader';
import userValidation from './../helpers/userValidation';
import userDBObject from './../helpers/userDBObject';
import userFormObject from './../helpers/userFormObject';
import AllForms from './../forms/AllForms';
import useActivityLog from './../activity/useActivityLog';
import setLanguageText from '../../language/setLanguageText';
import { set } from 'lodash';

const EditUser = ({ refreshTable, user, users, handleClose }) => {

    const { user: userContext } = useContext(UserContext)
    const [currentUser, setCurrentUser] = useState();
    const [cognitoResponse, setCognitoResponse] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [valid, setValid] = useState(true);

    const {
        updateActivityLog,
        //loggingError,
        //loggingResponse
    } = useActivityLog();

    useEffect(() => {

        if (open) {
            console.log(user)
            const editUser = userFormObject(user)
            console.log(editUser)
            if (editUser[0]) {
                setCurrentUser(editUser[0])
            }
        }
    }, [user, open])

    //close dialog
    const closeDialog = () => {
        setOpen(false);
        mutation.reset();
        setIsLoading(false)
        setCurrentUser()
        handleClose()
    }

    //cloase dialog and refresh table
    const closeRefreshDialog = () => {
        setOpen(false);
        mutation.reset();
        setIsLoading(false)
        refreshTable()
        setCurrentUser()
        handleClose()
    }

    //save user
    const mutation = useMutation((values) => {

        //console.log(values)
        let userObject = userDBObject(values, values?.aws_sub)[0]
        //console.log(userObject)
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'administrator/user',
            api_key: userContext.api_key,
            method: 'post',
            body: {
                update: true,
                ...userObject
            }
        })
    }, {
        onSuccess: (data, variables) => {
            if (data.errorMessage) {
                //do something
            }

            updateActivityLog.mutate({
                activity_details: {
                    area: "factory",
                    ...variables
                },
                activity_type: "user updated"
            })
            setIsLoading(false);
            //closeDialog()
        }
    })

    const handleSubmit = (values) => {

        //console.log(values)
        setIsLoading(true);
        //console.log(values)

        let userParams = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: values.username,
            UserAttributes: [
                { Name: 'email', Value: values.email.toLowerCase() },
                { Name: 'email_verified', Value: 'true' }
            ]
        };

        Auth.currentCredentials().then(async (credentials) => {

            const cognito = new AWS.CognitoIdentityServiceProvider({
                apiVersion: "2016-04-18",
                region: config.cognito.REGION,
                credentials: Auth.essentialCredentials(credentials)
            })

            const updateCognitoUser = new Promise((resolve, reject) => {

                //* UPDATE USER
                cognito.adminUpdateUserAttributes(userParams, (err, userData) => {

                    if (err) {
                        setCognitoResponse({
                            result: 'error',
                            message: err.message
                        });
                        reject(err);

                    } else {

                        const groupParams = {
                            UserPoolId: config.cognito.USER_POOL_ID,
                            Username: values.username,
                        };
                        // //add or remove from admin group
                        if (values.administrator) {
                            //console.log('add to admin group')
                            cognito.adminAddUserToGroup(
                                { ...groupParams, GroupName: "Administrator" },
                                (err, groupData) => {
                                    if (err) {
                                        setCognitoResponse({
                                            result: 'error',
                                            message: err.message
                                        });
                                        reject(err);
                                    } else {
                                        setCognitoResponse({
                                            result: 'success',
                                            message: groupData
                                        });
                                        resolve(groupData);
                                    }
                                }
                            );
                        } else {
                            //console.log('remove to admin group')
                            cognito.adminRemoveUserFromGroup(
                                { ...groupParams, GroupName: "Administrator" },
                                (err, groupData) => {
                                    if (err) {
                                        setCognitoResponse({
                                            result: 'error',
                                            message: err.message
                                        });
                                        reject(err);
                                    } else {
                                        setCognitoResponse({
                                            result: 'success',
                                            message: groupData
                                        });
                                        resolve(groupData);
                                    }
                                }
                            );
                        }
                    }
                });
            });

            try {
                const aws = await updateCognitoUser;
                //console.log(values)
                if (values) {
                    mutation.mutate(values)
                }
            } catch (err) {
                //console.log('error', err.message)
                setIsLoading(false)
            }
        });
    }

    return (
        <>
            <MenuItem
                variant='contained'
                onClick={e => setOpen(true)}
            >
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <FontAwesomeIcon size="xs" icon={faEdit} />
                </Avatar>
                {setLanguageText("Edit User")}
            </MenuItem>
            {currentUser ?
                <Formik
                    initialValues={currentUser}
                    validationSchema={userValidation}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, validateForm, errors, values }) => {
                        //console.log(values)
                        //console.log(errors)
                        return (
                            <GlobalStepperDialog
                                open={open}
                                onClose={closeDialog}
                                //buttonTitle="Create User"
                                //buttonClick={submitForm}
                                fullWidth
                                maxWidth="lg"
                                title={`Edit User`}
                                customActions={
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            disabled={isLoading}
                                            variant='text'
                                            color='inherit'
                                            onClick={closeDialog}
                                            sx={{ marginRight: '1em' }}
                                        >
                                            {setLanguageText("Cancel")}
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button
                                            disabled={isLoading}
                                            variant='contained'
                                            color='primary'
                                            onClick={(e) =>
                                                //validate fields
                                                validateForm().then((error) => submitForm())}>
                                            {setLanguageText("Update User")}
                                        </Button>
                                    </Box>
                                }
                            >
                                <DialogContent>
                                    <AllForms 
                                    edit 
                                    users={users}
                                    setVaild={setValid}
                                    valid={valid}
                                    
                                    />
                                    {isLoading || mutation.isLoading || mutation.isSuccess || mutation.isError ?
                                        <DialogLoader
                                            isLoading={isLoading}
                                            mutation={mutation}
                                            loadingMessage="Updating User..."
                                            successMessage="Updating User"
                                            closeDialog={closeRefreshDialog}
                                        />
                                        : null}
                                </DialogContent>
                            </GlobalStepperDialog>
                        )
                    }}
                </Formik>
                : null}
        </>
    );
};

export default EditUser;
