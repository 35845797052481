import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPassword = ({ login, setLogin ,theme }) => {

    const [passwordChange, setPasswordChange] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);


    useEffect(() => {
        if (login) {

            if (login.username === '' || login.password === '') {
                return
            }
            const signIn = async (username, password) => {
                console.log('signin')
                try {
                    const user = await Auth.signIn(username, password);
                    console.log(user.challengeName)
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setPasswordChange(true)
                        setUser(user)
                    }
                    if (user.challengeName === 'PASSWORD_VERIFIER') {

                    }
                    console.log(user.challengeName)
                } catch (error) {
                    console.log('error signing in', error);
                }
            }
            signIn(login.username, login.password)
        }
    }, [login])

    const completePassword = (e) => {
        e.preventDefault()
        //console.log(newPassword)
        //console.log(user)
        Auth.completeNewPassword(
            user,               // the Cognito User Object
            newPassword,       // the new password
        ).then(user => {
            // at this time the user is logged in if no MFA required
            console.log(user);
           //refresh the page
              window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const handleClose = () => {
        setPasswordChange(false)
        setLogin()
        //wipe url params
        window.history.replaceState({}, "", "/");
    }

    return (
        <Dialog
            open={passwordChange}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent
                style={{ padding: '35px 40px' }}
            >
                <Typography
                    variant="h5"
                    style={{
                        fontWeight: 700,
                        marginBottom: '.75em'
                    }}
                >Set New Password</Typography>
                <form onSubmit={completePassword} autoComplete="off">
                    <TextField
                        id="outlined-password-input"
                        //label="Password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={(e) => {
                            setNewPassword(e.target.value)
                        }}
                        placeholder='Set Password..'
                        value={newPassword}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        size="large">
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    //inputProps={{ pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+~|{}:;<>/])[A-Za-z0-9$^@$!%*?&+~|{}:;<>/]{8,30}" }}
                    />
                    <p>Your new password must contain</p>
                    <div
                        style={{
                            background: '#f9f9f9',
                            padding: '1em 1em 1em 2em',
                            border: '1px solid #e2e2e2',
                            margin: '0 0 1em 0'
                        }}
                    >
                        <p>{newPassword.length > 7 ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} Between 8 - 30 characters</p>
                        <p>{new RegExp("[0-9]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Number</p>
                        <p>{new RegExp("[A-Z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Uppercase letter</p>
                        <p>{new RegExp("[$^@$!%*?&+~|{}:;<>/]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Special character</p>
                        <p>{new RegExp("[a-z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Lowercase letter</p>
                    </div>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ background: theme.palette.primary.main, color: '#fff', marginTop: '1em' }}

                        disabled={new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$^@$!%*?&+~|{}:;<>/])[A-Za-z0-9$^@$!%*?&+~|{}:;<>/]{8,30}").test(newPassword) ? false : true}
                        type="submit"
                        style={{
                            //borderRadius: 0,
                            //textTransform: 'uppercase',
                            fontWeight: 600,
                            padding: '1em',
                            boxShadow: 'none'
                        }}
                    >
                        Set Password
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    )

};

export default ResetPassword;
