
import React, { useContext, useState } from 'react';
import AreaMenuItem from './AreaMenuItem';
import { UserContext } from '../../store/UserContext';
import getAccessID from '../../helpers/getAccessID';
import { LayoutContext } from '../../store/LayoutContext';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export default function AreaMenu({ menu, handleClose, topLevel }) {

    const { app } = useContext(LayoutContext);
    const { user } = useContext(UserContext);

    return (
        <Grid container>
            {menu && menu.map((item, i) => {
                //console.log(item.area)

                //console.log(user.user.user_permissions[item.area]?.access.id === 1)
                if (user?.user?.user_permissions) {
                    if (getAccessID(user.user.user_permissions[item.area]?.access) === 1 || getAccessID(user.user.user_permissions[item.area]?.access) === 2) {
                        return (
                            <Grid item xs={6} md={4} lg={3} key={i}>
                                <AreaMenuItem
                                    key={i}
                                    item={item}
                                    selected={app.selectedArea === item.area}
                                    handleClose={handleClose}
                                />
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid item xs={6} md={4} lg={3} key={i}>
                                <AreaMenuItem item={item} disabled />
                            </Grid>
                        )
                    }
                }
            })}
        </Grid>
    );
}


