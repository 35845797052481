import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import axiosRequest from '../../../axios/axoisRequest';
import config from '../../../config';
import { useQuery } from '@tanstack/react-query';

const DropdownAdditivesTypes = ({ setAdditiveTypes, additiveTypes, setSelectedType, selectedType }) => {

	const { user } = useContext(UserContext);

	//get types
	const { data, isFetching } = useQuery({
		queryKey: ['types'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants', //products
			api_key: user.api_key,
			method: 'get',
			params: {
				get_types: true
			}
		}),
		retry: false
	})

	useEffect(() => {
		if (data) {
			//get additive types
			let additiveType = data.find(type => type.type_id === 2);
			//find template for type
			let template = additiveType.type_template.find(template => template.name === 'type');
			//set options
			setAdditiveTypes(template.options);
			setSelectedType(template.options[0]);
		}
	}, [data])

	const onSelectType = (e, type) => {
		setSelectedType(type);
	};

	return (
		<Autocomplete
			disableClearable
			id="additive_types"
			options={additiveTypes || []}
			getOptionLabel={option => option.name || ''}
			loading={isFetching}
			renderInput={params => (
				<TextField
					required
					{...params}
					//label={setLanguageText("Select Additive Type")}
					variant="outlined"
					sx={{
						'& input': { zIndex: 2 },
						'& legend': { display: 'none' },
						'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
						'& .MuiAutocomplete-endAdornment ': {
							zIndex: 2
						}
					}}
					placeholder={setLanguageText("Select Type")}
				/>
			)}
			value={selectedType}
			onChange={onSelectType}
		/>
	);
};

export default DropdownAdditivesTypes;
