import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { UserContext } from "../../store/UserContext"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Image from "../reusable/Image";
import ItemCard from "./ItemCard";
import { Grid, Skeleton, Typography } from "@mui/material";
import Row from "./Row";
import { CLEAR_URL_FILTERS } from "../../store/SelectedReducers";
import { SelectedContext } from "../../store/SelectedContext";

const SecondRow = ({ selectedID, setToolClass, setSelectedID, setForm }) => {

    const { user } = useContext(UserContext)
    const [appRow, setAppRow] = useState();
    const [selected, setSelected] = useState();
    const { dispatchSelected } = useContext(SelectedContext)

    //--------------------------- API CALLS  ------------------------------//

    const { data, error, isFetching } = useQuery(['applicationParent', selectedID[0]],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'applicationsearchrow',
            api_key: user.api_key,
            method: 'get',
            params: {
                parent: selectedID[0]
            }
        }), {
        enabled: !!user.api_key && !!selectedID[0],
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })
    // console.log(props)
    //setSelected on first load
    useEffect(() => {
        if (selectedID && selectedID[1]) {
            setSelected(selectedID[1])
        } else {
            setSelected()
        }
    }, [selectedID])

    const handleSelect = (e, value) => {

        if (value?.HasChildren) {
            setToolClass(current => [current[0], value])
            setSelectedID(current => [current[0], value.ID])
              //reset form
              dispatchSelected({
                type: CLEAR_URL_FILTERS
            });
            setForm()
        }

    }

    //console.log(parent)

    return (
        <>
            {selectedID[0] ?
                <Row isFetching={isFetching} data={data} title="Tool Type" selected={selected} handleSelect={handleSelect} />
                : null}
        </>
    );
}
export default SecondRow;
