import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../store/ShopContext";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { UPDATE_BRANDS } from "../../store/ShopReducers";
import { useQuery } from "react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { CompanyContext } from "../../store/CompanyContext";
import searchString from "../../helpers/searchString";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { UPDATE_PRODUCTS } from "../../store/ProductsReducers";
import { ProductsContext } from "../../store/ProductsContext";
import { LayoutContext } from "../../store/LayoutContext";

const SearchBrandsDropdown = ({ search }) => {

    const { selectedCompany } = useContext(CompanyContext);
    const { brands, dispatchBrands } = useContext(ShopContext);
    const { dispatchProducts } = useContext(ProductsContext)
    const { dispatchLayout } = useContext(LayoutContext)
    const [selected, setSelected] = useState([]);
    const { user } = useContext(UserContext);

    //--------------------------- GET ALL BRANDS ONCE ON TOP LEVEL ------------------------------//

    const { data, isFetching } = useQuery(['brands', search, selectedCompany],
        ({ signal }) => {
            //create search string
            let search_string = searchString(search)
            //console.log(search_string)
            //console.log(user)
            return axiosRequest({
                signal: signal,
                name: 'get brands', //added to see which call is erroring
                gateway: config.ezbaseAPI.URL,
                endpoint: 'brands',
                api_key: user.api_key,
                method: 'get',
                params: {
                    search: encodeURI(search_string),
                    company_id: selectedCompany.company_id
                }
            })
        }, {
        retry: 1,
        enabled: !!search && !!selectedCompany.company_id
    })

    useEffect(() => {
        //console.log(data)
        if (data && Object.values(data).length && !data.errorMessage) {
            let updateBrands = data.filter((brand) => brand.Brand !== "")
            setSelected([])
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    currentBrands: updateBrands,
                }
            });
        } else {
            setSelected([])
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    currentBrands: [],
                }
            });
        }
    }, [data])

    useEffect(() => {
        if (brands.selectedBrands && Object.values(brands.selectedBrands).length) {
            setSelected(brands.selectedBrands)
        }
    }, [])

    const updateSelected = (event, newValue) => {
        //console.log(newValue)
        setSelected(newValue);
        if (newValue) {
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    selectedBrands: newValue,
                }
            });
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    offset: 0,
                }
            });
            dispatchLayout({
                type: UPDATE_LAYOUT,
                payload: {
                    page: 1,
                }
            });

        } else {
            dispatchBrands({
                type: UPDATE_BRANDS,
                payload: {
                    selectedBrands: [],
                }
            });
            dispatchProducts({
                type: UPDATE_PRODUCTS,
                payload: {
                    offset: 0,
                }
            });
            dispatchLayout({
                type: UPDATE_LAYOUT,
                payload: {
                    page: 1,
                }
            });
        }
    };


    return (

        <Autocomplete
            //disableClearable
            multiple={true}
            id="selected-brands"
            value={selected}
            options={brands?.currentBrands || []}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={option.Brand} />
                ));
            }}
            getOptionLabel={option => `${option.Brand} (${option.count})`}
            isOptionEqualToValue={(option, value) => option.Brand === value.Brand}
            // isOptionEqualToValue={(option, value) =>
            //     option.Brand === value.Brand
            // }
            onChange={(event, newValue) => updateSelected(event, newValue)}
            renderInput={params => (
                <TextField
                    {...params}
                    sx={{
                        width: '100%',
                        '& input': { zIndex: 2 },
                        '& legend': { display: 'none' },
                        '& fieldset': {
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary,
                        },
                        marginBottom: '1em'
                    }}
                    placeholder="Select a Brand..."
                    variant="outlined"
                    //label="Brands"
                />
            )}
            loading={isFetching ? true : false}
            disabled={isFetching ? true : false}
            loadingText="Updating Brands...."
        />
    )
};

export default SearchBrandsDropdown;
