
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import EntryStepper from './stepper/EntryStepper';
import checkCustomAttributes from '../helpers/checkCustomAttributes';
import { SelectionsContext } from '../store/SelectionsContext';
import { SAVE_COMPANY, SAVE_MACHINE, SAVE_QRCODES, SET_EDIT_ENTRY } from '../store/SelectionsReducers';
import LoadingButton from './../buttons/LoadingButton';
import { faArrowRight, faEdit, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import MobileStepper from '@mui/material/MobileStepper';
import VoidEntry from './VoidEntry';
import Alerts from './Alerts';
import ConfirmEdit from './ConfirmEdit';
import { UPDATE_ENTRY } from '../store/MachineReducers';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../store/MachineContext';
import { Alert } from '@mui/material';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import GlobalDialog from '../../reusable/GlobalDialog';
import DialogLoader from '../../reusable/DialogLoader';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import useMachineEntry from '../../hooks/useMachineEntryRefresh';

//create steps
function getSteps(attributes) {

    console.log(attributes)
    let steps = [
        setLanguageText('Standard Values'),
        setLanguageText('Bacteria & Fungi'),
        setLanguageText('Notes'),
        setLanguageText('Additives')
    ];
    //if custom attributes add in extra step
    const has_custom_attributes = checkCustomAttributes(attributes);
    if (has_custom_attributes) {
        steps.splice(1, 0, `Custom Attributes`);
    }
    return steps;
}

export const EditEntry = ({ isDialog, setIsDialog, originalEntry, selectedCompany, selectedMachine, qrCodes, active, voided }) => {

    const { refetchMachineEntries } = useMachineEntry()
    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { user } = useContext(UserContext);
    const { updateActivityLog } = useActivityLog();
    const [activeStep, setActiveStep] = useState(active ? active : 0);
    const [compiledForm, setCompiledForm] = useState({});
    const [steps, setSteps] = useState([]);
    const [continueNext, setContinueNext] = useState(true);
    const [errors, setErrors] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);
    const [voidDialog, setVoidDialog] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    useEffect(() => {
        if (originalEntry) {
            console.log(originalEntry)
            dispatchAddNewEntry({
                type: SET_EDIT_ENTRY,
                payload: originalEntry.original
            });
        }
        if (selectedMachine) {
            dispatchAddNewEntry({
                type: SAVE_MACHINE,
                payload: selectedMachine
            });
        }
        if (selectedCompany) {
            dispatchAddNewEntry({
                type: SAVE_COMPANY,
                payload: selectedCompany
            });
        }
        if (qrCodes) {
            dispatchAddNewEntry({
                type: SAVE_QRCODES,
                payload: qrCodes
            });
        }

    }, [
        qrCodes,
        selectedCompany,
        selectedMachine,
        originalEntry,
        dispatchAddNewEntry
    ]);

    //build steps
    useEffect(() => {
        if (addNewEntry.company) {
            setSteps(getSteps(addNewEntry.company.company_attributes));
        }
    }, [addNewEntry.company]);

    //check for custom attributes
    const has_custom_attributes = useMemo(() =>
        addNewEntry.company &&
        checkCustomAttributes(addNewEntry.company.company_attributes),
        [addNewEntry.company]
    );

    const handleNext = () => {
        //console.log(continueNext)
        if (!continueNext) {
            setShowWarning(true);
            setContinueNext(true);
        } else {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            let canContinue = true;
            //if you are at the end of the form
            if (activeStep === steps.length - 1) {
                //submit and stop form going any further
                handleSubmit();
                canContinue = false;
            }
            if (canContinue) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }

        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const closeModal = () => {
        setActiveStep(0);
        setCompiledForm({});
        setIsDialog();
    };

    const openVoidDialog = e => {
        setVoidDialog(true);
    };

    const setError = (error) => {
        if (error) {
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            if (errors.length) {
                let index = errors.findIndex((err) => err.attr_id === error.attr_id)
                if (index !== -1) {
                    errors[index] = error
                } else {
                    errors.push(error)
                }
            } else {
                errors.push(error)
            }
            //console.log(errors);
            setErrors(errors);
            //hide show errrors
            errors.forEach((err) => {
                if (err.type === 'error') {
                    setShowError(true);
                }
                if (err.type === 'warning') {
                    //setShowWarning(true);
                    setContinueNext(false);
                }
            })
        }
    }

    //save edit machine entry
    const editMachineEntry = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'patch',
            body: {
                "edit_entry": true,
                ...body
            }
        }),
        onSuccess: (response) => {
            if (response) {
                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        entry_id: response.entry_id,
                        machine_id: response.machine_id
                    },
                    activity_type: "update pending values"
                })
                dispatchSelectedMachine({
                    type: UPDATE_ENTRY,
                    payload: response
                });
                
                //refetch all macine entries 
                let last = new Date(selectedMachine?.last_entry?.date_stamp)
                let dateTo = new Date(last);
                let dateFrom = new Date(last).setMonth(last.getMonth() - 12);
                refetchMachineEntries.mutate({ "dateFrom": dateFrom, "dateTo": dateTo.getTime() })
            }
        }
    })

    const handleSubmit = () => {

        let oldEntry = originalEntry.original.attributes_new;
        let newEntry = addNewEntry.entry.attributes_new;
        let bacteria = false
        let editted = false
        let fungi = false

        //if none pendning attriubtes have been chnaged
        oldEntry.forEach(item => {
            //console.log(item)
            let found = newEntry.find(newItem => newItem.attribute_id === item.attribute_id)
            //console.log(found)
            if (item.attribute_values.status !== -10) { //-10 is a pending result, so ignore them if they have been updated
                //console.log(item)
                if (item.attribute_values.value !== found.attribute_values.value) {
                    editted = true
                    if (found.attribute_id === 'bacteria') {
                        bacteria = true
                    }
                    if (found.attribute_id === 'fungi') {
                        fungi = true
                    }
                }
            } else {
                if (item.attribute_values.value !== found.attribute_values.value) {
                    //set if bacteria or fungi have been updated, so we can remove notifications
                    if (found.attribute_id === 'bacteria') {
                        bacteria = true
                    }
                    if (found.attribute_id === 'fungi') {
                        fungi = true
                    }
                }
            }
        });
        //if addititves have been chnaged
        if (addNewEntry.entry.additives !== originalEntry.original.additives) {
            editted = true
        }
        //if notes have been chnaged
        if (addNewEntry.entry.notes !== originalEntry.original.notes) {
            editted = true
        }
        //console.log(editted)
        if (editted) {
            //console.log('edit and void current entry');
            setConfirmDialog(true);
        } else {

            //if only pending attributes have been changed then update entry
            let outOfControl = newEntry.some(item => item.attribute_values.status === 30);
            //console.log('update current entry');
            //console.log(outOfControl);
            const updatedEntry = {
                attributes_new: newEntry,
                entry_id: originalEntry.original.entry_id,
                machine_id: originalEntry.original.machine_id,
                fungi_updated: fungi,
                bacteria_updated: bacteria,
                out_of_control: outOfControl
            };
            
            //edit entry
            editMachineEntry.mutate(updatedEntry);
        }
    };

    const changeStep = (e) => {
        //console.log(e)
        //reset errors
        setErrors([]);
        setShowError(false);
        setShowWarning(false);
        setContinueNext(true);
        setActiveStep(e);
    }

    return (

        <GlobalDialog
            open={isDialog}
            onClose={closeModal}
            fullWidth
            disabled={activeStep !== steps.length - 1}
            maxWidth="md"
            title="Edit Machine Entry"
            customActions={

                <MobileStepper
                    variant={isMobileOnly ? 'dots' : null}
                    steps={steps.length}
                    position="static"
                    sx={{
                        width: '100%',
                        background: 'none',
                        padding: 0
                    }}
                    activeStep={activeStep}
                    nextButton={
                        <LoadingButton
                            icon={
                                activeStep === steps.length - 1 ? faEdit :
                                    showWarning ? faExclamationTriangle
                                        : faArrowRight
                            }
                            text={
                                activeStep === steps.length - 1 ? setLanguageText('Update Entry') :
                                    showWarning ? setLanguageText('Continue Anyway?')
                                        : setLanguageText('Next')
                            }
                            fullWidth={false}
                            iconSize="1x"
                            color="primary"
                            loading={showError}
                            onClick={handleNext}
                        />

                    }
                    backButton={
                        <div style={{
                            display: 'flex',
                            flex: 'auto'
                        }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => openVoidDialog()}
                                sx={{ marginRight: '8px' }}
                            >
                                {setLanguageText('Void')}
                            </Button>

                            {activeStep !== 0 ?
                                <Button onClick={handleBack}>{setLanguageText('Back')}</Button>
                                : null}

                        </div>

                    }
                />
            }
        >
            {!voided ?
                <>
                    <EntryStepper
                        edit={true}
                        steps={steps}
                        activeStep={activeStep}
                        setActiveStep={changeStep}
                        has_custom_attributes={has_custom_attributes}
                        compiledForm={compiledForm}
                        setError={setError}
                        errors={errors}
                        continueNext={continueNext}
                    />
                    <Alerts errors={errors} showWarning={showWarning} showError={showError} />
                </>
                :
                <Alert severity="error">This entry has been editted or voided</Alert>
            }
            {confirmDialog ?
                <ConfirmEdit
                    open={confirmDialog}
                    handleClose={e => setConfirmDialog(false)}
                    handleConfirmClose={e => {
                        setConfirmDialog(false);
                        closeModal();
                    }}
                    newEntry={addNewEntry}
                />
                : null}
            {voidDialog ?
                <VoidEntry
                    open={voidDialog}
                    handleClose={e => setVoidDialog(false)}
                    handleVoidedClose={e => {
                        setVoidDialog(false);
                        closeModal();
                    }}
                    copyEntry={originalEntry}
                />
                : null}
            {editMachineEntry.isLoading || editMachineEntry.isSuccess || editMachineEntry.isError ?
                <DialogLoader
                    isLoading={editMachineEntry.isLoading}
                    mutation={editMachineEntry}
                    loadingMessage="Editting Machine Entry..."
                    successMessage="Entry Editted"
                    closeDialog={()=>{
                        setIsDialog();
                        closeModal();
                    }}
                    fixed

                />
                : null}
        </GlobalDialog>
    );
};

export default EditEntry;
