import React from 'react';
import {
	Grid,
	Typography
} from '@mui/material';
import ThemeForm from './ThemeForm';
import setLanguageText from '../../../language/setLanguageText';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';

//theme type passed from parent componenet
const Theme = ({ type, edit }) => {

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("Application Theme")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<ThemeForm type={type} edit={edit} />
			</TabContent>
		</>
	);
};

export default Theme;
