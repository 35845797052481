import { ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { useTheme } from "@emotion/react";
import { LayoutContext } from "../../../store/LayoutContext";
import GlobalDialog from "../../../reusable/GlobalDialog";
import SendQuoteEmail from "../../../emails/SendQuoteEmail";
import EmailQuote from "./EmailQuote";


const ViewQuote = ({ order, handleClose }) => {

    const { theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme();
    const [open, setOpen] = useState(false)
    const [openComfirm, setOpenComfirm] = useState(false)
    const [email, setEmail] = useState()

    const handleCloseDialog = () => {
        handleClose();
        setOpen(false);
        setOpenComfirm(false);
        setEmail();
    }

    const handleComfirm = () => {
        setOpenComfirm(true);
    }

    return (
        <>
            <MenuItem onClick={() => setOpen(true)} >
                <ListItemIcon>
                    <FontAwesomeIcon icon={faEnvelope} />
                </ListItemIcon>
                Email Quote
            </MenuItem>
            <EmailQuote open={openComfirm} handleClose={handleCloseDialog} order={order} email={email} setEmail={setEmail} />
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={handleCloseDialog}
                    title="View Quote"
                    buttonClick={handleComfirm}
                    buttonTitle="Email Quote"
                    closeText="Close"
                    maxWidth="md"
                >
                    <SendQuoteEmail order={order} logo={layoutTheme.email_logo} theme={theme} />
                </GlobalDialog>
                : null}
        </>
    );
};

export default ViewQuote;