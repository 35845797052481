import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import ProgressChart from './ProgressChart';
import LoadingScreen from '../../reusable/LoadingScreen';
import setLanguageText from '../../../language/setLanguageText';

export default function HorizontalChart({ chartData }) {

	const [totalMachines, setTotalMachine] = useState();
	const [checked, setChecked] = useState();


	useEffect(() => {
		if (chartData) {
			let index = chartData.findIndex(item => item.id === 'totals');
			let total = chartData[index].data.no_entries.count + chartData[index].data.has_entries.count
			setTotalMachine(total)
			setChecked(chartData[index].data.has_entries.count)
		}
	}, [chartData])

	return (
		<>
			{totalMachines ?
				<Grid
					container
					item
					style={{ marginBottom: theme => theme.spacing(3) }}
					justifyContent="space-between"
					spacing={2}
				>
					<Grid item xs={12} sm={3}>
						<Paper
							elevation={0}
							sx={{
								color: theme => theme.palette.background.light,
								backgroundColor: theme => theme.palette.primary.main,
							}}
						>
							<Grid item container justifyContent="center" direction="column" sx={{ padding: '.75em' }}>
								<Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant="h4" component="p" align="center">
									<strong>{totalMachines}</strong>
								</Typography>
								<Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant="body1" component="p" align="center" >
									<strong>{setLanguageText("Total Machines")}</strong>
								</Typography>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={9}>
						<ProgressChart
							checked={checked}
							totalMachines={totalMachines}
						/>
					</Grid>
				</Grid>
				:
				<Grid>
					<LoadingScreen
						loading={false}
						background="#fafafa"
						colour="#666"
						text="No Machines, Please add a Machine"
					//position="relative"
					/>
				</Grid>
			}
		</>
	);
}

