import {
    Alert,
    Backdrop,
    Grid,
    Box,
    LinearProgress,
    Typography,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Button
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Storage } from 'aws-amplify';

function LinearProgressWithLabel(props) {
    return (
        <Grid item>
            <Typography component="p" variant="p">{props.file.name}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress sx={{ height: '20px' }} variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        </Grid>
    );
}

const FilesWithProgress = ({ folder, index, file, updateComplete, setError, error }) => {

    const [percentage, setPercentage] = useState(0)

    //console.log(percentage)
    //set amplfy to uplaod to the correct bucket

    useEffect(() => {
        if (file) {
            const uploadFile = (file) => {
                Storage.put(`${folder}/${file.name}`, file, {
                    //tagging: 'status=NEW',
                    completeCallback: (event) => {
                        //console.log(`Successfully uploaded ${event.key}`);
                    },
                    progressCallback: (progress) => {
                        setPercentage(progress.loaded / progress.total * 100)
                        //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    },
                    errorCallback: (err) => {
                        //console.log('Unexpected error while uploading', err);
                        setError('Unexpected error while uploading', err);
                    },
                    contentType: file.type
                })
                    .then((result) => {
                        //console.log(result)
                        //console.log(index)
                        updateComplete(index)
                    })
                    .catch((err) => {

                        setError('Unexpected error uploading', err);

                    });
            }
            uploadFile(file)
        }
    }, [file, index])

    return (
        <Box sx={{ width: '100%' }}>
            {error ?
                <Alert severity="error">{error}</Alert>
                :
                <LinearProgressWithLabel value={percentage} file={file} />
            }
        </Box>
    )
}

const FileUploadProgress = ({ folder, files, callback }) => {

    const [open, setOpen] = useState(true);
    const [error, setError] = useState();
    const [complete, setComplete] = useState([]);

    // Storage.configure({
    //   AWSS3: {
    //     bucket: config.solutionS3.BUCKET,
    //     region: config.solutionS3.REGION
    //   }
    // });

    //set array with all files and set uploaded to false
    useEffect(() => {
        if (files) {
            let fileList = []
            files.forEach((file, i) => { fileList.push(false) })
            setComplete(fileList);
        }
        return () => {
            //clean up
        }
    }, [files])

    useEffect(() => {
        if (complete && Object.values(complete).length) {
            // console.log('here')
            if (complete.every(element => element === true)) {
                //console.log('all uploaded ok');
                setTimeout(() => {
                    if (callback) {
                        callback()
                    }
                    setOpen(false)

                }, 2000)
            }
        }
        return () => {
            //clean up
        }
    }, [complete])


    const updateComplete = useCallback((i) => {
        //console.log(i)
        let copy = [...complete]
        copy[i] = true
        //console.log(copy)
        setComplete(copy)
    }, [complete])

    console.log(complete)
    //console.log(isLoading)

    return (
        <>
            <Backdrop
                open={open}
                sx={{
                    zIndex: 10000,
                    padding: '30%'
                }}
            >
                <Card>
                    <CardHeader title="Please wait, uploading files...." />
                    <CardContent>
                        <Grid container spacing={2} alignContent="center">
                            {files && files.map((file, i) =>
                                <Grid key={i} item xs={12}>
                                    <FilesWithProgress index={i} folder={folder} file={file} updateComplete={updateComplete} setError={setError} error={error} />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                    {error ?
                        <CardActions>
                            <Button
                                variant="text"
                                onClick={() => setOpen(false)}
                            >
                                Close and try again
                            </Button>
                        </CardActions>
                        : null}
                </Card>
            </Backdrop>
        </>
    )
}

export default FileUploadProgress
