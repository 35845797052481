import React, { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../store/CategoryContext";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import { Box, Button, Card, CardContent, CardHeader, Grid, Skeleton, TextField, Typography, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { filterTree, expandFilteredNodes, getIDsExpandFilter } from "./filterTreeUtil";
import { uniq } from "lodash";

const ParametricTree = ({ openLevels, setOpenLevel }) => {

    const isMd = useMediaQuery( theme => theme.breakpoints.up('md'));
    const { categories, dispatchCategories } = useContext(CategoryContext);
    const [filteredData, setFilteredData] = useState();
    const [allIds, setAllIds] = useState();

    const handleToggle = (event, nodeIds) => {
        setOpenLevel(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        //setSelected(nodeIds); 
    };

    const handleExpandClick = useCallback(() => {
        setOpenLevel((oldExpanded) => oldExpanded.length === 1 ? allIds : ["Root"]);
    }, [allIds, setOpenLevel]);

    useEffect(() => {
        if (filteredData) {
            //loop through json tree strucure and add all ids to array 
            let allIDs = [];
            const getAllIDs = (data) => {
                data.forEach(category => {
                    allIDs.push(category.ID)
                    if (category.Items) {
                        getAllIDs(category.Items)
                    }
                })
            }
            getAllIDs([filteredData])
            setAllIds(allIDs)
        }
    }, [filteredData])

    const CustomContent = forwardRef(function CustomContent(props, ref) {
        const {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;

        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            handleSelection,
            preventSelection,
        } = useTreeItem(nodeId);

        const icon = iconProp || expansionIcon || displayIcon;

        const handleExpansionClick = (e, props) => {
            e.preventDefault();
            handleExpansion(e); //set expanded
            dispatchCategories({
                type: UPDATE_CATEGORIES,
                payload: {
                    currentCat: props.currentCat,
                }
            });
            handleSelection(e); //set selected

        };

        const handleSelectionClick = (e, props) => {
            e.preventDefault();
            dispatchCategories({
                type: UPDATE_CATEGORIES,
                payload: {
                    currentCat: props.currentCat,
                }
            });
            handleSelection(e); //set selected
        };

        return (

            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                ref={ref}
            >
                <div onClick={e => handleExpansionClick(e, props)} className={classes.iconContainer}>
                    {icon}
                </div>
                <Typography
                    onClick={e => handleSelectionClick(e, props)}
                    component="div"
                    className={classes.label}
                >
                    {label}
                </Typography>
            </div>
        );
    });


    //add callback to prvent image re-fetch
    const renderTree = useCallback((category,selectedCategory) => {

        return (
            <TreeItem
                sx={{ color: 'text.primary' }}
                key={category.ID}
                nodeId={category.ID}
                ContentProps={{
                    currentCat: category
                }}
                ContentComponent={CustomContent}
                // onLabelClick={(e) => {
                // 	e.preventDefault();
                // }}
                label={

                    <Grid item container alignItems="center" sx={{
                        margin: '5px 0',
                        color: theme => selectedCategory === category?.ID ? theme.palette.primary.main : theme.typography.body1.color,
                    }} >
                        {category.ID !== "Root" &&
                            <Grid item container alignItems="center" sx={{ margin: '0 5px 0 0', width: '60px' }} >
                                <img style={{ width: '60px', height: '30px', objectFit: 'contain' }} src={category.Icon} alt={category.Name} />
                            </Grid>
                        }
                        {/* {category.ID !== "Root" && <Image src={category.Icon} alt={category.Name} sx={{ margin: '0 5px 0 0' }} />} */}
                        {/* <Grid item ><small>{category.Name} {category.Count && `(${category.Count})`}</small></Grid> */}
                        <Grid item ><small>{category.Name}</small></Grid>
                    </Grid>

                }
            >
                {Array.isArray(category.Items)
                    ? category.Items.map((cat) => {
                        //remove all cats with 0 products
                        if (cat.Count > 0) {
                            //console.log(cat.Count)
                            return (renderTree(cat,selectedCategory))
                        }
                    })
                    : null}
            </TreeItem>
        )
    }, [CustomContent]);

    const onFilterMouseUp = (e) => {
        //console.log(e.target.value)
        const value = e.target.value;
        const filter = value.trim();
        let expandedTemp = openLevels;
        if (!filter) {
            setFilteredData(() => categories.allCats);
            setOpenLevel(allIds);
            return;
        }

        let filtered = filterTree(categories.allCats, filter);
        filtered = expandFilteredNodes(filtered, filter);
        if (filtered && filtered.children) {
            expandedTemp = [];
            expandedTemp.push(...getIDsExpandFilter(filtered));
        }
        //console.log(expandedTemp)
        //console.log(filtered)
        //setOpenLevel(uniq(expandedTemp));
        setFilteredData(filtered);
    };

    useEffect(() => {
        if (categories.allCats) {
            setFilteredData(categories.allCats)
        }
    }, [categories.allCats])

    // console.log(categories.currentCat.ID)
    // console.log(categories.allCats)
    // console.log(openLevels)
    // console.log(filteredData)

    return (

       <>
            <CardHeader
                disableTypography
                sx={{
                    background: (theme) => theme.palette.cards.header,
                    //color: theme => theme.palette.primary.contrastText,
                    fontSize: '1em',
                    padding: 1
                }}
                title={
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <TextField
                                fullWidth
                                //label={field.Field}
                                //name={field.Field}
                                onKeyUp={onFilterMouseUp}
                                placeholder="Filter Categories..."
                                //InputLabelProps={{ shrink: false }}
                                //size="small"
                                disabled={!filteredData}
                                sx={{
                                    '& input': {
                                        padding: "10px 15px"
                                    },
                                    '& legend': {
                                        display: 'none'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleExpandClick}
                            >
                                {openLevels.length === 1 ? 'Expand all' : 'Collapse all'}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <CardContent
                sx={{
                    borderColor: (theme) => theme.palette.cards.main,
                    borderWidth: '1px 0 1px 0',
                    borderStyle: 'solid',
                    padding: 2, 
                    maxHeight: !isMd ? "calc(100vh - 120px)" : "calc(100vh - 450px)", 
                    overflow: "auto"
                }}
            >
                {openLevels && categories.currentCat.ID && filteredData && Object.values(filteredData).length ? //check for ID so seleted works
                    <TreeView
                        //defaultSelected={categories.currentCat.ID}
                        //defaultExpanded={openLevels}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={openLevels}
                        selected={categories.currentCat.ID}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                    >
                        {renderTree(filteredData, categories.currentCat.ID)}
                    </TreeView>
                    :
                    <>
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                    </>
                }
            </CardContent>
        </>
    )
};

export default ParametricTree;
