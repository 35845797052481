import React from "react"
import { faTriangle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Stack, TableCell } from "@mui/material"
import {
    flexRender
} from '@tanstack/react-table'
import Filter from "./Filter"

const TableHeaderCell = ({ header, i, table }) => {

    return (
        <TableCell
            key={i}
            colSpan={header.colSpan}
            sx={{
                width: header.getSize(),
                //backgroundColor: (theme) => header.column.getIsSorted() ? theme.palette.table.hover : theme.palette.table.header,
                backgroundColor: (theme) => header.column.getIsSorted() ? theme.palette.primary.dark : theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                position: 'relative',
            }}
        >
            {i > 0 &&
                <Box
                    sx={{
                        height: '100%',
                        marginTop: 0,
                        marginBottom: 0,
                        width: '1px',
                        background: (theme) => theme.palette.primary.light,
                        position: 'absolute',
                        left: 0,
                        top: 0
                    }}
                />
            }
            {header.isPlaceholder ? null : (

                <Box
                    {...{
                        className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                        //onClick: header.column.getToggleSortingHandler(),

                    }}>

                    {header.column.getCanSort() ?
                        <Box
                            sx={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                height: '100%',
                                display: 'grid',
                                alignItems: 'center',
                                width: '40px',
                            }}
                            {...{
                                onClick: header.column.getToggleSortingHandler(),
                            }}
                        >
                            <Stack
                                direction="column"
                                gap={1 / 2}
                            >
                                <FontAwesomeIcon
                                    icon={faTriangle}
                                    style={{ opacity: header.column.getIsSorted() === 'asc' ? 1 : 0.2 }}
                                    size='2xs'
                                />
                                <FontAwesomeIcon
                                    icon={faTriangle}
                                    rotation={180}
                                    style={{ opacity: header.column.getIsSorted() === 'desc' ? 1 : 0.2 }}
                                    size='2xs'
                                />
                            </Stack>
                        </Box>
                        : null}
                    <Box
                        sx={{ paddingRight: '15px' }}
                    >
                        {table && header.column.getCanFilter() ? (
                            <Filter column={header.column} table={table} />
                        ) :
                            flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                    </Box>
                </Box>
            )}
        </TableCell>
    )
}

export default TableHeaderCell
