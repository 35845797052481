import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemButton, ListItemText, ListSubheader, Skeleton, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import ResultButton from "../parametric/ResultButton";
import { SelectedContext } from "../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import { UserContext } from "../../store/UserContext";
import MaterailGroups from "./MaterialGroups";
import { faWandMagicSparkles } from "@fortawesome/pro-light-svg-icons";
import axiosRequest from "../../axios/axoisRequest";

const Materials = ({ field }) => {
	//render fields
	const [open, setOpen] = useState(false);
	const { user } = useContext(UserContext);
	const { dispatchSelected } = useContext(SelectedContext);
	const theme = useTheme();
	const [standardIndex, setStandardIndex] = useState(0);
	const [standard, setStandard] = useState();
	const [materialIndex, setMaterialIndex] = useState(0);
	const [material, setMaterial] = useState();
	const [rootClass, setRootClass] = useState();

	const handleRootClass = (item) => {
		setRootClass(item)
		setStandardIndex();
		setStandard()
		setMaterialIndex();
		setMaterial()
		updateMaterialFilters(item.Value)
	};

	const handleStandardIndex = (event, index, item) => {
		setStandardIndex(index);
		setStandard(item)
		setMaterialIndex();
		setMaterial()
	};

	const handleMaterialIndex = (event, index, item) => {
		setMaterialIndex(index);
		setMaterial(item)
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const updateMaterialFilters = (group) => {
		//console.log(item)
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'VW1', 
				'Values': [group], 
				'Name': 'Workpiece material group', 
				'Ref': 'W1',
				'Toggle': true
			}
		});
	}

	const handleReset = () => {
		setRootClass()
		setStandardIndex();
		setStandard()
		setMaterialIndex();
		setMaterial()
	};


	//--------------------------- API CALLS  ------------------------------//

	const { status, data, error, isFetching } = useQuery(['materialinformation'],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materialinformation',
			api_key: user.api_key,
			method: 'get',
		}), {
		enabled: !!open,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: m_data, isFetching: m_isFetching } = useQuery(['material', rootClass, standard],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materials',
			api_key: user.api_key,
			method: 'get',
			params: {
				rootClass: rootClass.Value,
				standard: standard.Value
			}
		}), {
		enabled: !!standard && !!rootClass,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: g_data, isFetching: g_isFetching, } = useQuery(['materialgroups', rootClass, standard, material],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/materials/groups',
			api_key: user.api_key,
			method: 'get',
			params: {
				groups: JSON.stringify(material.Groups)
			}
		}), {
		enabled: !!standard && !!rootClass && !!material,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})


	return (
		<>
			<Button
				sx={{ width: '35px', height: '35px', minWidth: 'auto', boxShadow: 'none', borderRadius: '50%' }}
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				<FontAwesomeIcon size="lg" icon={faWandMagicSparkles} />
			</Button>
			<Dialog
				open={open}
				fullWidth
				maxWidth="false"
				sx={{ height: '100%' }}
				PaperProps={{
					height: '90%'
				}}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ background: theme.palette.background.default, textAlign: 'center' }} id="alert-dialog-title">
					<Typography variant="body1" sx={{ margin: '.5em auto 1em' }} >Wizard to specify materials.</Typography>
					{rootClass ? rootClass.Label : ''} - {standard ? standard.Label : ''}
				</DialogTitle>

				<DialogContent sx={{ padding: '1em!important' }}>

					<Grid container spacing={2} alignContent="stretch">
						{data && data.RootClasses.map((item, i) => {
							return (
								<Grid item xs={6} sm={3} lg={2} key={i}>
									<Box
										onClick={e => handleRootClass(item)}
										sx={{
											background: item.Color,
											width: '100%',
											height: '180px',
											padding: '2em',
											cursor: 'pointer',
											borderRadius: '10px',
											boxShadow: item.Value === rootClass?.Value ? '0px 0px 5px 0px #222' : 'none',
											border: '2px solid #fff',
											'&:hover': {
												boxShadow: '0px 0px 5px 0px #222'
											}

										}}
									>
										<Typography fontSize={40}>{item.Value}</Typography>
										<Typography>{item.Label}</Typography>
									</Box>
								</Grid>
							)
						})}

						<Grid item xs={12}>
							{!rootClass && !standard &&  data && <Alert severity="info">Please select a Class and a Standard</Alert>}
							{rootClass && !standard && data && <Alert severity="info">Please select a Standard</Alert>}
							{!material && m_data && <Alert severity="info">Please select a Material Name</Alert>}
						</Grid>
					</Grid>
					<Grid container spacing={2} >
						<Grid item xs={4}>
							{isFetching ?
								<Skeleton height={400} sx={{ margin: '1em 0', }} variant="rectangular" animation="wave" />
								: data && data?.Standards &&
								<List
									subheader={
										<ListSubheader sx={{ bgcolor: 'background.default', }} component="div" id="nested-list-subheader" disableGutters>
											<Typography sx={{ padding: '1em' }}>Standard</Typography>
											<Divider />
										</ListSubheader>
									}
									dense={true}
									sx={{
										margin: '1em 0',
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 400,
										border: '1px solid #ccc',
										padding: 0,
									}}
								>
									{data.Standards.map((item, i) => {
										return (

											<ListItem sx={{ padding: 0 }} key={i}>
												<ListItemButton
													selected={standardIndex === i}
													onClick={(event) => handleStandardIndex(event, i, item)}
												>
													<ListItemText
														primary={item.Label}
													/>
												</ListItemButton>
												<Divider />
											</ListItem>
										)
									})}
								</List>
							}

						</Grid>
						<Grid item xs={4}>
							{m_isFetching ?
								<Skeleton height={400} sx={{ margin: '1em 0', }} variant="rectangular" animation="wave" />
								: m_data &&
								<List
									subheader={
										<ListSubheader sx={{ bgcolor: 'background.default', }} component="div" id="nested-list-subheader" disableGutters>
											<Typography sx={{ padding: '1em' }}>Material Name</Typography>
											<Divider />
										</ListSubheader>
									}
									dense={true}
									sx={{
										margin: '1em 0',
										width: '100%',
										bgcolor: 'background.paper',
										position: 'relative',
										overflow: 'auto',
										maxHeight: 400,
										border: '1px solid #ccc',
										padding: 0,
									}}
								>
									{m_data.map((item, i) => {
										return (
											<ListItem sx={{ padding: 0 }} key={i}>
												<ListItemButton
													selected={materialIndex === i}
													onClick={(event) => handleMaterialIndex(event, i, item)}
												>
													<ListItemText
														primary={item.Label}
													/>
												</ListItemButton>
												<Divider />
											</ListItem>
										)
									})}
								</List>
							}
						</Grid>
						<Grid item xs={4}>
							{g_isFetching ?
								<Skeleton height={400} sx={{ margin: '1em 0', }} variant="rectangular" animation="wave" />
								: g_data &&
								<MaterailGroups groups={g_data} isFetching={g_isFetching} updateMaterialFilters={updateMaterialFilters}/>
							}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ background: theme.palette.background.default, disply: 'flex', justifyContent: 'center' }} >

					<Grid>
						<Button sx={{ marginRight: '1em' }} variant="contained" color="secondary" onClick={handleReset}>
							Reset
					</Button>
						<ResultButton />
					</Grid>

					<Button onClick={e => setOpen(false)}>
						Finish
					</Button>

				</DialogActions>
			</Dialog>
		</>
	);
};

export default Materials;

