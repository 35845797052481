import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { MachineContext } from '../../store/MachineContext';
import MachineCard from './MachineCard';

export default function GridView({ defaultHiddenColumns }) {

	const { filteredMachines, outOfControl, isOutOfControl } = useContext(MachineContext);
	const machines = isOutOfControl ? outOfControl : filteredMachines;

	return (
		<Grid container spacing={2}>
			{machines.map(machine => {
				return (
					<Grid
						item
						key={machine.machine_id}
						sm={12}
						md={6}
						lg={4}
						xl={3}
					>
						<MachineCard defaultHiddenColumns={defaultHiddenColumns} machine={machine} gridView={true} />
					</Grid>
				);
			})}
		</Grid>
	);
};

