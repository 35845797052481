import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTheme, Grid, CardContent, TextField, Box, Autocomplete } from '@mui/material';
import MyDatePicker from '../../Machines/MachineDetails/MyDatePicker';
import ReportCard from '../ReportCard';
import { UserContext } from '../../../store/UserContext';
import ServiceReportPDF from '../../pdf/ServiceReportPDF';
import GlobalDialog from '../../../reusable/GlobalDialog';
import ServiceTable from './ServiceTable';
import createServicePDFData from '../../helpers/createServicePDFData'
import sortAlphaNumerically from '../../../helpers/sortAlphaNumerically'
import useSignedUrl from '../../../hooks/useSignedUrl';
import config from '../../../config';
import { CompanyContext } from '../../../store/CompanyContext';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../../axios/axoisRequest';
import setLanguageText from '../../../language/setLanguageText';
import GlobalDialogPDF from '../../../reusable/GlobalDialogPDF';


export default function ServiceReports() {

	const theme = useTheme();
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const [dateFrom, setDateFrom] = useState(new Date().setHours(0, 0, 0, 0)); // from Midnight
	const [dateTo, setDateTo] = useState(new Date().setHours(23, 59, 59, 0)); // 1 sec to Midnight
	const [openServicePDF, setOpenServicePDF] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [originalData, setOriginalData] = useState();
	const [pdf_data, setPdf_data] = useState(null);
	const [columnOptions, setColumnOptions] = useState();
	const [downloadObject, setDownloadObject] = useState();
	const [orientation, setOrientation] = useState('portrait');
	const [data, setData] = useState();
	const [title, setTitle] = useState();

	useEffect(() => {
		if (selectedCompany) {
			setTitle(`${setLanguageText("Service Reports")}: ${selectedCompany.company_name}`);
			setData();
			setDownloadObject();
		}
	}, [selectedCompany]);

	const getServiceReports = useMutation({
		mutationFn: (params) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'service-report',
			api_key: user.api_key,
			method: 'get',
			params: params
		}),
		onSuccess: (response) => {
			if (!response.errorMessage) {
				setData(response)
			}
		}
	});

	//run report
	const runReport = () => {
		setIsModal(true);
		let params = {
			company_id: selectedCompany.company_id,
			date_from: dateFrom,
			date_to: dateTo,
		}
		getServiceReports.mutate(params);
	}

	const closeModal = () => setIsModal(false);

	const getEntries = useMutation({
		mutationFn: (params) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'get',
			params: params
		}),
		onSuccess: (response) => {
			if (!response.errorMessage) {

				const sorted = sortAlphaNumerically(response, 'machine_name')
				console.log(sorted)
				setOriginalData(current => {
					return {
						...current,
						'rowData': sorted,
					}
				});
			}
		}
	});


	const getServiceEntries = (original) => {

		//console.log(original)
		setOpenServicePDF(true)
		let query
		if (original.service_entry_ids) {
			query = {
				allow_access: true,
				entry_ids: JSON.stringify(original.service_entry_ids),
				//includeVoids : true
			}
		} else {
			//legacy
			query = {
				allow_access: true,
				user_id: original.service_user_id,
				service_date: new Date(original.service_date).toISOString().substr(0, 10),
				legacy: original.service_entry_ids ? false : true
			}
		}
		let originalData = {
			'selectedCompany': selectedCompany,
			'otherData': original
		}
		setOriginalData(originalData);
		getEntries.mutate(query);
	}

	useEffect(() => {
		if (originalData?.rowData) {
			const data = createServicePDFData({
				'selectedCompany': selectedCompany,
				'rowData': originalData.rowData,
				'otherData': originalData.otherData,
			});
			setPdf_data(data)
			setColumnOptions(data.columns)
		}
	}, [originalData, selectedCompany])



	const handleChange = event => {
		//console.log(event.target.name)
		event.persist()
		//console.log(event.target.name)
		//toggle options
		let newOptions = [...columnOptions]
		let index = newOptions.findIndex(obj => obj.id === event.target.name);
		newOptions[index].isVisible = !newOptions[index].isVisible;
		setColumnOptions(newOptions)

		//create new visibale columns re create PDF date using them
		let newColumns = newOptions.filter(obj => obj.isVisible === true)
		const updatedData = createServicePDFData({ ...originalData, 'headers': newColumns });
		setPdf_data(updatedData);

	};

	//get company logo
	const { getSignedUrl } = useSignedUrl();
	const [mainImage, setMainImage] = useState();

	useEffect(() => {
		if (pdf_data) {
			console.log(pdf_data)
			getSignedUrl(config.s3.BUCKET, `public/company/${pdf_data.company.logo_path}`).then((url) => {
				console.log(url)
				if (url) {
					setMainImage(url)
				} else {
					setMainImage({ error: 'No Image' })
				}
			})
		}
		return () => {
			setMainImage()
		}
	}, [pdf_data]);


	return (
		<>
			<Grid item xs={12}>
				<ReportCard
					title={title}
					buttonLabel="Fetch Service Reports"
					runReport={runReport}
					disabled={!selectedCompany ? true : false}
				>
					<Grid container spacing={1}>
						<MyDatePicker
							dateFrom={dateFrom}
							setDateFrom={setDateFrom}
							dateTo={dateTo}
							setDateTo={setDateTo}
							size="small"
						/>
					</Grid>
				</ReportCard>
			</Grid>
			{isModal ?
				<GlobalDialog
					open={isModal}
					onClose={closeModal}
					maxWidth="xl"
					fullWidth
					title={title}
				>
					<ServiceTable
						data={data}
						isFetching={getServiceReports.isLoading}
						getServiceEntries={getServiceEntries}
					/>
				</GlobalDialog>
				: null}
			{openServicePDF ?
				<GlobalDialogPDF
					title={title}
					text="Service report attached"
					open={openServicePDF}
					onClose={e => {
						setPdf_data()
						setOpenServicePDF(false)
					}}
					maxWidth
					downloadObject={downloadObject}
					orientation={orientation}
					updateOrientation={setOrientation}
					options={columnOptions}
					handleOptionChange={handleChange}
					email
				>
					{pdf_data ?
						<ServiceReportPDF
							theme={theme}
							orientation={orientation}
							pdf_data={pdf_data}
							setDownloadObject={setDownloadObject}
							mainImage={mainImage}
						/>
						: null}
				</GlobalDialogPDF>
				: null}
		</>
	);
};
