import {
    Alert,
    Backdrop,
  } from "@mui/material"
  import React, { useEffect, useState } from "react"
  import SpinningLoader from "./SpinnningLoader";
  
  const Loader = ({ loadingMessage, successMessage, errorMessage, isLoading , resetMessages, callback}) => {
  
    const [open, setOpen] = useState(true);
    //console.log(isLoading)
    
    useEffect(() => {
      if ((successMessage || errorMessage) && !isLoading) { 
          
          setTimeout(() => {
            if(resetMessages){
              resetMessages()
            }
            if(callback){
              setOpen(false)
              callback()
            } else {
              setOpen(false)
            }
          }, 3000)
      }
    }, [isLoading,successMessage,errorMessage])
  
    return (
      <>
        <Backdrop
          sx={{  zIndex: theme => theme.zIndex.tooltip + 1000 }}
          open={open}
        >
          { isLoading ? (
              <SpinningLoader text={loadingMessage} size={100} />
          ) : null}

          {!isLoading && errorMessage ? (
            <Alert
              variant="filled"
              severity="error"
            >
              An error occurred: {errorMessage} - Please try and submit again
            </Alert>
          ) : !isLoading && successMessage ? (
            <Alert
              variant="filled"
              severity="success"
            >
              Success: {successMessage}
            </Alert>
          ) : null }
        </Backdrop>
      </>
    )
  }
  
  export default Loader
  