import React, { useMemo, useState, useContext, useCallback } from 'react'
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Chip, Button } from '@mui/material'
import DialogLoader from '../../reusable/DialogLoader';
import { useMutation } from '@tanstack/react-query';
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../language/setLanguageText';
import TableHeaderCell from '../../tables/TableHeaderCell';
import useActivityLog from '../../users/activity/useActivityLog';
import GlobalDialog from '../../reusable/GlobalDialog';
import getUserLevel from '../../helpers/getUserLevel';

const AttributesTable = ({ currentAttributes, currentVariables, isFetching, selectedAttribute, setSelectedAttribute, setOpen,refetch }) => {

    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const { user } = useContext(UserContext);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { updateActivityLog } = useActivityLog();
    //console.log(checkoutPermissions)

    const removeAttr = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'company/attributes',
            api_key: user.api_key,
            method: 'post',
            body: body
        }),
        onSuccess: () => {
            //update activity log
            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    attribute: selectedAttribute
                },
                activity_type: "machine attribute removed"
            })
            //refetch attributes
            setSelectedAttribute();
            refetch();
        }
    })

    const editAttribute = selected => {
        //add selected attribute to edit
        setSelectedAttribute(selected);
        setOpen(true);
    };

    const removeAttribute = (selected) => {
        let clone = structuredClone(currentAttributes);
        let updatedRules = clone.filter(rule => rule.one.attr_id !== selected.one.attr_id)
        //console.log(updatedRules)
        let body = {
            id: selected.one.company_id,
            attributes: updatedRules
        };
        //remove attribute
        removeAttr.mutate(body)
    };

    const createRules = useCallback((data) => {

        let object = [...currentVariables];
        if (data?.two?.attr_rules) {

            return (
                <Grid container spacing={1}>
                    {data.two.attr_rules.map((item, i) => {
                        //set colour by matching the value to global variables
                        let found = object.find(obj => obj.value === item.status);

                        return (
                            <Grid item xs={12} key={i}>
                                <strong> {item.name}: </strong>
                                {item.logic && (
                                    <>
                                        {item.logic.map((logic, i) => {
                                            let operator = logic.operator === '==' ? setLanguageText('equal to')
                                                : logic.operator === '>' ? setLanguageText('greater than')
                                                    : logic.operator === '<' ? setLanguageText('less than')
                                                        : logic.operator === '>=' ? setLanguageText('greater than or equal to')
                                                            : logic.operator === '<=' ? setLanguageText('less than or equal to')
                                                                : logic.operator === '!=' ? setLanguageText('not equal to') : ''

                                            let connection = logic.connection === '&&' ? setLanguageText('and') : logic.connection === '||' ? 'or' : ''
                                            return (
                                                <span key={i}>
                                                    <strong>&#123;value&#125;</strong>{` ${operator} `}<strong>{logic.value}</strong>{` ${connection} `}
                                                </span>
                                            );
                                        })}
                                    </>
                                )}
                                <span>
                                    {` = `}
                                    <Chip
                                        size="small"
                                        icon={null}
                                        style={{
                                            backgroundColor: found ? found.colour.hex : 'transparent'
                                        }}
                                        label={<span>{item.output ? item.output : '{value}'}</span>}
                                    />
                                </span>
                            </Grid>
                        );
                    })}
                </Grid>
            )
        } else {
            return <p>{setLanguageText("no rules")}</p>;
        }
    }, [currentVariables]);

    const columns = useMemo(() => [
        // {
        //     header: () => setLanguageText("Attribute ID"),
        //     accessorKey: 'one.attr_id',
        //     enableSorting: false,
        //     cell: info => info.getValue()
        // },
        {
            header: () => setLanguageText("Attribute Name"),
            accessorKey: 'one.attr_name',
            enableSorting: false,
            size: 100,
            cell: info => info.getValue(),
        }, {
            header: () => setLanguageText("Locked"),
            accessorKey: 'one.attr_locked',
            enableSorting: false,
            size: 70,
            cell: info => {
                //console.log(info.getValue())
                return info.getValue() ? "Yes" : "No"
            },
        }, {
            header: () => setLanguageText("Columns"),
            accessorKey: 'one.attr_column',
            enableSorting: false,
            size: 70,
            cell: info => {
                //console.log(info.getValue())
                return info.getValue() ? "Yes" : "No"
            }
        }, {
            header: () => setLanguageText("Rules"),
            enableSorting: false,
            accessorKey: 'two.attr_rules',
            size: 500,
            cell: ({ row }) => createRules(row?.original)

        },{
            header: () => setLanguageText("Chart Type"),
            enableSorting: false,
            accessorKey: 'four.attr_chart',
            size: 120,
            cell: info => info.getValue(),

        }, {
            accessorKey: 'attr_actions',
            enableSorting: false,
            header: '',
            width: 120,
            cell: ({ row }) => {
                return (
                    <Grid container spacing={2}>
                        {getUserLevel(user.user, 'coolantcare', 'custom_attributes', 9) ?
                            <>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => editAttribute(row?.original)}
                                    >
                                        {setLanguageText("Edit")}
                                    </Button>
                                </Grid>

                                {!row?.original.one.attr_locked ? (
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                setSelectedAttribute(row?.original)
                                                setConfirmDelete(true)
                                            }}
                                        >
                                            {setLanguageText("Remove")}
                                        </Button>
                                    </Grid>
                                ) : null}
                            </>
                            : null}
                    </Grid>
                )
            }
        }
    ], [createRules]);

    console.log(currentAttributes)

    const table = useReactTable({
        data: currentAttributes ?? [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    // Manage your own state
    const [state, setState] = useState(table.initialState)

    // Override the state managers for the table to your own
    table.setOptions(prev => ({
        ...prev,
        state,
        onStateChange: setState,
        // These are just table options, so if things
        // need to change based on your state, you can
        // derive them here
        // Just for fun, let's debug everything if the pageIndex
        // is greater than 2
        debugTable: state.pagination.pageIndex > 2,
    }))

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer >
                            <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                                <TableHead>
                                    {table.getHeaderGroups().map((headerGroup, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                backgroundColor: (theme) => theme.palette.table.header,
                                            }}
                                        >
                                            {//added a custom header cell
                                                headerGroup.headers.map((header, i) => <TableHeaderCell key={i} header={header} i={i} />)
                                            }
                                        </TableRow>
                                    ))}
                                </TableHead>
                                <TableBody>
                                    {isFetching ?
                                        new Array(pageSize).fill(0).map((_, i) => {
                                            return (
                                                <TableRow
                                                    key={i}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        borderBottom: '1px solid #ccc',
                                                        backgroundColor: (theme) => theme.palette.table.row,
                                                        '&:nth-of-type(odd)': {
                                                            backgroundColor: (theme) => theme.palette.table.hover,
                                                        },
                                                        '&:last-child td, &:last-child th': {
                                                            borderBottom: 0,
                                                        },
                                                    }}>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                    <TableCell><Skeleton height={30} /></TableCell>
                                                </TableRow>
                                            )
                                        })

                                        : !isFetching && currentAttributes?.length > 0 ? table.getRowModel().rows.map((row, i) => {
                                            return (
                                                <TableRow
                                                    key={i}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        borderBottom: '1px solid #ccc',
                                                        backgroundColor: (theme) => theme.palette.table.row,
                                                        '&:nth-of-type(odd)': {
                                                            backgroundColor: (theme) => theme.palette.table.hover,
                                                        },
                                                        '&:last-child td, &:last-child th': {
                                                            borderBottom: 0,
                                                        },
                                                    }}
                                                >
                                                    {row.getVisibleCells().map((cell, i) => {
                                                        return (
                                                            <TableCell key={i}>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </TableCell>
                                                        )
                                                    })}

                                                </TableRow>
                                            )
                                        })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={7}>
                                                    <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No data found</Alert>
                                                </TableCell>
                                            </TableRow>

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/* <Grid item xs={12} >
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            component="div"
                            count={count ? count : -1}
                            rowsPerPage={table.getState().pagination.pageSize}
                            page={table.getState().pagination.pageIndex}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}

                            onPageChange={(_, page) => {
                                table.setPageIndex(page)
                            }}
                            onRowsPerPageChange={e => {
                                const size = e.target.value ? Number(e.target.value) : 10
                                table.setPageSize(size)
                            }}
                        />
                    </Grid> */}
                </Grid>
            </Box>

            {confirmDelete &&
                <GlobalDialog
                    open={confirmDelete}
                    onClose={() => setConfirmDelete(false)}
                    title={setLanguageText("Delete Attribute")}
                    buttonClick={() => removeAttribute(selectedAttribute)}
                    buttonTitle={setLanguageText("Delete Attribute")}
                    maxWidth="sm"
                >
                    <Grid item>
                        <Alert severity="error">
                            {setLanguageText("This action can not be undone.")}<br />
                            {setLanguageText("Are you sure you want to delete this attribute?")}
                        </Alert>
                    </Grid>
                    {removeAttr.isLoading || removeAttr.isSuccess || removeAttr.isError ?
                        <DialogLoader
                            isLoading={removeAttr.isLoading}
                            mutation={removeAttr}
                            loadingMessage={setLanguageText("Deleting Attribute")}
                            successMessage={setLanguageText("Deleted")}
                            closeDialog={() => {
                                    setConfirmDelete(false);
                                }
                            }
                            fixed
                        />
                        : null}
                </GlobalDialog>
            }
        </>
    )
}

export default AttributesTable;
