
import React, { useContext } from 'react';
import MobileItem from './MobileItem';
import { UserContext } from '../../../store/UserContext';
import getAccessID from '../../../helpers/getAccessID';

const MobileMenuItems = ({ menu, handleMobileClose, isOpen, handleOpenSubmenu }) => {

    const { user } = useContext(UserContext);

    return (
        <>
            {menu && menu.map((item, i) => {
                //console.log(item.area)
                //console.log(user.user.user_permissions[item.area]?.access.id === 1)
                if (user?.user?.user_permissions && (getAccessID(user.user.user_permissions[item.area]?.access) === 1 || getAccessID(user.user.user_permissions[item.area]?.access) === 2 )) {

                    if (item.disabled) {
                        return (
                            <MobileItem key={i} title={item.menuComponent} icon={item.icon} disabled isOpen={isOpen} />
                        )
                    }
                    if (item.externalLink) {
                        return (
                            <MobileItem key={i} title={item.menuComponent} icon={item.icon}
                                onClick={e => window.open(item.externalLink, '_blank')}
                                isOpen={isOpen}
                            />
                        )
                    } else if (item.submenu) {

                        let visible = item.submenu.filter((items) => !items.hidden)
                        if (visible.length) {
                            return (
                                <MobileItem key={i}
                                    title={item.menuComponent}
                                    icon={item.icon}
                                    onClick={e => handleOpenSubmenu(item)} //send full object 
                                    submenu
                                //selected={topLevel ? (app.selectedArea === item.area) : (app.selectedMenu === item.menu)}
                                />
                            )
                        } else {
                            return (
                                <MobileItem key={i} title={item.menuComponent} icon={item.icon}
                                    onClick={e => handleMobileClose(item)} //send full object 
                                //selected={topLevel ? (app.selectedArea === item.area) : (app.selectedMenu === item.menu)}
                                />
                            )
                        }

                    } else {
                        return (
                            <MobileItem key={i} title={item.menuComponent} icon={item.icon}
                                onClick={e => handleMobileClose(item)} //send full object 
                            //selected={topLevel ? (app.selectedArea === item.area) : (app.selectedMenu === item.menu)}
                            />
                        )
                    }
                }
            })}
        </>
    );
}

export default MobileMenuItems;


