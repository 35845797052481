import React, { useContext, useState } from 'react';
import Defaults from '../../Products/Coolants/Defaults';
import { faTint } from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { buttonStyles } from '../../styles/buttonStyles';
import ConfirmReset from './ConfirmReset';
import Switch from '@mui/material/Switch';
import { TextField, Button, Autocomplete, Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import config from '../../../config';
import axiosRequest from '../../../axios/axoisRequest';
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RefreshCoolant = ({ selectedCoolant, selectedMachine }) => {

	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const currentCoolant = selectedCoolant;
	const [coolant, setCoolant] = useState(selectedCoolant);
	const [refresh, setRefresh] = useState(false);
	const [resetDate, setResetDate] = useState(false);
	const [resetMachineSettings, setResetMachineSettings] = useState(false);
	const [confirmDialog, setConfirmDialog] = useState(false);
	const buttonStyle = buttonStyles();
	const [selectedDate, handleDateChange] = useState(new Date());

	//get coolants based on what compnay has access to
	const { data: coolants } = useQuery({
		queryKey: ['all coolants', selectedCompany?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'get',
			params: {
				type: 1, //coolants
                get_list: true,
                company_id: selectedCompany.company_id
			}
		}),
		enabled: !!selectedCompany?.company_id,
		retry: false
	})

	const setNewCoolant = (e, coolant) => {
		setCoolant(coolant);
	};

	const updateResetDate = (e, date) => {
		setResetDate(date);
	};

	const updateResetMachineSettings = (e, date) => {
		setResetMachineSettings(date);
	};

	const handleSubmit = () => {
		if (resetMachineSettings || resetDate) {
			setConfirmDialog(true);
		} else if (currentCoolant.product_id !== coolant.product_id) {
			setConfirmDialog(true);
		} else {
			//console.log('do nothing');

		}
	}

	const closeReset = () => {
		setConfirmDialog(false);
		setRefresh(false);
		setResetDate(false);
		setResetMachineSettings(false);
	}



	return (
		<>
			<Grid item xs={12} md={6}>
				<Button
					id="edit"
					variant="contained"
					color="error"
					onClick={e => setRefresh(true)}
					startIcon={<FontAwesomeIcon icon={faTint} />}
					fullWidth
				>
					{setLanguageText("Reset/Refresh Coolant")}
				</Button>
			</Grid>

			<GlobalDialog
				open={refresh}
				onClose={e => setRefresh(false)}
				title="Reset/Refresh Coolant"
				buttonClick={handleSubmit}
				buttonTitle="Update Details"
				successMessage="Details updated successfully!"
				fullWidth
				maxWidth="md"
			>
				<Grid container item spacing={2}>
					<Grid item xs={12}>
						<Autocomplete
							disableClearable
							id="coolant"
							options={coolants || []}
							fullWidth
							getOptionLabel={(option) => option.product_name || ''}
							isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
							renderOption={(props, option) =>
								<li {...props} key={option.product_id}>
									{option.product_name}
								</li>
							}
							renderInput={params => (
								<TextField
									required
									{...params}
									label={setLanguageText("Select Coolant")}
									variant="outlined"
								/>
							)}
							value={coolant || null}
							onChange={setNewCoolant}
						/>
					</Grid>
					<Grid item xs={12}>
						<Defaults coolant={coolant} />
					</Grid>

					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={resetMachineSettings}
									onChange={updateResetMachineSettings}
									name="set_machine"
									color="primary"
								/>
							}
							label={setLanguageText("Set Machine Settings to Coolant Defaults")}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={resetDate}
									onChange={updateResetDate}
									name="reset_date"
									color="primary"
								/>
							}
							label={setLanguageText("Reset Date of Coolant Change")}
						/>
					</Grid>
					{resetDate &&
						<Grid item xs={12} >
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									fullWidth
									inputVariant="outlined"
									margin="normal"
									id="date-picker-dialog"
									label={setLanguageText("Reset Date")}
									format="dd/MM/yyyy"
									value={selectedDate}
									onChange={handleDateChange}
									disableFuture
								//componentsProps={{ textField: { variant: 'outlined' } }}
								//renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</Grid>
					}
				</Grid>
			</GlobalDialog>
			{confirmDialog &&
				<ConfirmReset
					open={confirmDialog}
					handleClose={e => setConfirmDialog(false)}
					handleConfirmClose={closeReset}
					resetDate={resetDate}
					selectedDate={selectedDate}
					resetMachineSettings={resetMachineSettings}
					currentCoolant={currentCoolant}
					coolant={coolant}
					selectedMachine={selectedMachine}
				/>
			}
		</>
	);
};

export default RefreshCoolant;
