import React, { useState, useEffect, useContext } from 'react';
import QrReader from 'react-qr-scanner';
import config from '../../config';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material';
import { Grid, InputAdornment, TextField, Button } from '@mui/material';
import { faCheckCircle, faQrcode } from '@fortawesome/pro-light-svg-icons';
import { UserContext } from '../../store/UserContext';
import LoadingScreen from '../reusable/LoadingScreen';
import { SelectionsContext } from '../store/SelectionsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REMOVE_QRCODES } from '../store/SelectionsReducers';
import setLanguageText from '../../language/setLanguageText';
import GlobalDialog from '../../reusable/GlobalDialog';
import axiosRequest from '../../axios/axoisRequest';
import { useQuery } from "@tanstack/react-query";
const Buffer = require('buffer').Buffer;


const DipslideScanner = ({ setQrDetails, attrName }) => {

	const { user } = useContext(UserContext);
	const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
	const [salting] = useState(Buffer.from(config.SALTING).toString('base64'));
	const [open, setOpen] = useState(false);
	const [result, setResult] = useState();
	const [details, setDetails] = useState();
	const [textQRCode, setTextQRCode] = useState('');
	const [scanQRCode, setScanQRCode] = useState();
	const [confirmCode, setConfirmCode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [scanned, setScanned] = useState(false);
	const [scanAgain, setScanAgain] = useState(false);
	const [error, setError] = useState();
	const [relink, setRelink] = useState(false);
	//const [time, setTime] = useState(72);

	const handleScan = data => {
		if (data && !scanned) {
			//setLoading(true);
			//console.log(data);
			//scan once
			setScanned(true);
			setResult({
				result: data.text
			});
		}
	};

	const reverse = s => {
		return s.split('').reverse().join('');
	};

	//set code from text box once it has reached 12 characters
	useEffect(() => {
		if (textQRCode.length === 12) {
			//console.log('set final code');
			if (!scanQRCode) {
				//typed code
				//setDetails({ app: config.APPNAME, code: textQRCode, company_id: addNewEntry.company.company_id })
				//update to remove compnay id
				setDetails({ app: config.APPNAME, code: textQRCode })
			} else {
				//if a scanned qr use the scan data
				setDetails(scanQRCode)
			}
		}
	}, [
		addNewEntry,
		textQRCode,
		scanQRCode
	])

	const updateCode = (e) => {
		e.preventDefault();
		if (e.target.value.length <= 12) {
			setTextQRCode(e.target.value.toUpperCase())
		}
	}

	//set details from scaning qr code
	useEffect(() => {
		if (result) {

			setLoading(true);
			const data = result.result;

			//machine codes only
			let reverseData = reverse(data);
			if (reverseData.includes(salting)) {

				setError('This looks like a machine QR code, please use a dipslide QR code');

			} else if (data) {

				const myArr = data.split(" ");

				//all qr codes are global, no company id
				setScanQRCode({
					app: myArr[1],
					code: myArr[0],
				});
				setTextQRCode(myArr[0]);

			} else {
				setError(`Not a ${config.NAME} QR code`);
				//setLoading(false);
			}
		}
	}, [
		result,
		setDetails,
		salting,
		addNewEntry
	]);

	//check qr code isn't being used
	const { data } = useQuery({
		queryKey: ['check qr', details?.code],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'qr',
			api_key: user.api_key,
			method: 'post',
			body: {
				code: details.code,
				check_code: true
			}
		}),
		enabled: !!details?.code,
		retry: false
	})

	//use reterned data to confirm code
	useEffect(() => {
		if (data) {
			//console.log(data)
			if (Object.keys(data).length === 0) {
				//check context incase this code has already been scanned for this form
				//console.log(addNewEntry.qr_codes);
				if (Object.keys(addNewEntry.qr_codes).length === 0) {
					setConfirmCode(true);
					setLoading(false);
					//allow scan again
					setScanAgain(true);

				} else if (Object.keys(addNewEntry.qr_codes).length > 0) {
					//console.log('already a qr entry in context')
					//check that this is not the same as the other entry

					let found = addNewEntry.qr_codes.find((item) => item.code === details.code)

					if (!found) {
						//setTextQRCode(details.code);
						setConfirmCode(true);
						setLoading(false);
						//allow scan again
						setScanAgain(true);

					} else {
						setError('QR code already scanned, please use another');
						setScanAgain(true);
						setTextQRCode('')
					}
				}
			} else {
				setError('QR code already scanned, please use another');
				setScanAgain(true);
				setTextQRCode('')
			}
		}
	}, [data]);

	const reset = () => {
		setDetails();
		setError();
		setResult();
		setScanQRCode();
		setTextQRCode('');
		setLoading(false);
		setScanAgain(false);
		setConfirmCode(false);
		setScanned(false);
	}

	const handleClose = () => {
		//console.log('close & reset');
		setOpen(false);
		reset();
		setScanned(false);
	}

	//TODO: add notification time
	const handleSubmit = (e) => {
		e.preventDefault();
		setQrDetails({
			...details,
			//notification_time: time, 
			company_id: addNewEntry.company.company_id
		});
		setRelink(true)
		setOpen(false);
		reset();
	}

	const handleOpen = () => {
		//console.log('open & remove last from context');
		if (addNewEntry.qr_codes.length > 0) {
			dispatchAddNewEntry({
				type: REMOVE_QRCODES,
				payload: attrName
			});
		}
		setOpen(true);
	}

	return (
		<>
			<Grid item container spacing={2} >
				<Grid item >
					<Button
						variant='contained'
						color="error"
						onClick={e => handleOpen(e)}
						startIcon={<FontAwesomeIcon icon={faQrcode} />}
					>
						{setLanguageText(!relink ? "Link QR code" : "Relink QR code")}
					</Button>
				</Grid>
			</Grid>
			<GlobalDialog
				open={open}
				onClose={handleClose}
				title={'Scan or type in QR code'}
				buttonClick={e => handleSubmit(e)}
				disabled={!confirmCode || textQRCode.length < 12 || !scanAgain}
				buttonTitle={!relink ? "Link QR code" : "Relink QR code"}
				fullWidth
				maxWidth="md"
			>
				<Grid container spacing={2} alignItems="center" justifyItems="stretch">
					{error ?
						<Grid item xs={12}>
							<Alert severity="error">{error}</Alert>
						</Grid>
						: null}
					{!scanAgain &&
						<Grid item xs={12} >

							<QrReader
								//resolution={1440}  
								constraints={{
									video: {
										//deviceId: { cameraId },
										facingMode: "environment"
									}
								}}
								// //facingMode="environment"
								// //chooseDeviceId={cameraId}
								// delay={delay}
								onError={e => console.log('')}
								onScan={handleScan}
								style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}
							/>


							{loading && !error &&
								<LoadingScreen text="Checking QR code" height="auto" />
							}
						</Grid>
					}
					<Grid item xs={12} md={8} >
						<TextField
							//label={setLanguageText("12 character code")}
							fullWidth
							value={textQRCode || ''}
							variant="outlined"
							placeholder={"12 character code"}
							//helperText={'Scan QR code or type in 12 character code'}
							onChange={e => updateCode(e)}
							InputProps={{
								maxLength: 12,
								endAdornment: confirmCode && textQRCode.length === 12 && scanAgain ? (
									<InputAdornment position="end">
										<FontAwesomeIcon
											icon={faCheckCircle}
											size="lg"
										/>
									</InputAdornment>
								) :
									<InputAdornment position="end">
										<small>({12 - textQRCode.length} left)</small>
									</InputAdornment>
							}}
							sx={{
								'& input': { zIndex: 2 },
								'& legend': { display: 'none' },
								'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
								'& .MuiAutocomplete-endAdornment ': {
									zIndex: 2
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} >
						<Button
							fullWidth
							variant='contained'
							color="primary"
							disabled={!scanAgain}
							onClick={e => {
								reset();
							}}
							startIcon={<FontAwesomeIcon icon={faQrcode} />}
							sx={{ height: '100%' }}
						>
							{setLanguageText("Scan Again")}
						</Button>

					</Grid>
					{/* <Grid item xs={12} >
						<FormControl
							fullWidth
							variant="outlined"
							className={classes.formControl}
						>
							<InputLabel htmlFor="notifi">
								Email notification
							</InputLabel>
							<Select
								label={setLanguageText("Email notification")}
								labelId="notifi"
								value={time}
								onChange={e => setTime(e.target.value)}
							>
								<MenuItem value={72}>72 hours</MenuItem>
								<MenuItem value={48}>48 hours</MenuItem>
								<MenuItem value={24}>24 hours</MenuItem>
								<MenuItem value={0}>No notification</MenuItem>
							</Select>
						</FormControl>
					</Grid> */}

				</Grid>
			</GlobalDialog>
		</>
	);
};

export default DipslideScanner;
