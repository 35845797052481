import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContent, Box } from '@mui/material';
import { faEdit, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import config from '../../../config';
import { useMutation } from 'react-query';

import GlobalDialog from '../../../reusable/GlobalDialog';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import * as Yup from "yup"
import DialogLoader from '../../../reusable/DialogLoader';
import setLanguageText from '../../../language/setLanguageText';
import UserForm from './UserForm';
import axiosRequest from '../../../axios/axoisRequest';

const EditUser = ({ refreshTable, user }) => {

  //console.log(refreshTable) 

  const { user: userContext } = useContext(UserContext)
  const [currentUser, setCurrentUser] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const validation = Yup.object({
    name: Yup
      .string('Enter your name')
      .required('Name is required'),
    surname: Yup
      .string('Enter your name')
      .required('Name is required'),
    username: Yup
      .string('Enter your username')
      .required('Username is required')
      .min(1, 'Username must be more than 1 character')
      .max(120, 'Username must be less than 120 character'),
    //.matches(/^[\p{L}\p{M}\p{S}\p{N}\p{P}]+/, 'Username must fit constrainst'), 
    email: Yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required')
  });

  const {
    updateActivityLog,
    //loggingError,
    //loggingResponse
  } = useActivityLog();

  useEffect(() => {
    if (user && open) {

      const userObject = {
        name: user.user_details.name,
        surname: user.user_details.surname,
        username: user.username,
        email: user.email,
        aws_sub: user.aws_sub,
        user_id: user.user_id,
        can_checkout: user.user_settings?.can_checkout || false,
      }

      setCurrentUser(userObject)
    }
  }, [user, open])

  //close dialog
  const closeDialog = () => {
    setOpen(false);
    update_user.reset();
    setIsLoading(false)
    setCurrentUser()
  }

  //cloase dialog and refresh table
  const closeRefreshDialog = () => {
    setOpen(false);
    update_user.reset();
    setIsLoading(false)
    refreshTable()
    setCurrentUser()
  }

  //save user
  const update_user = useMutation((settings) => {

    console.log(settings)
     //console.log(userObject)
     return axiosRequest({
      gateway: config.factoryAPI.URL,
      endpoint: 'administrator/user',
      api_key: userContext.api_key,
      method: 'post',
      body: {
        update: true,
        update_settings: true,
        user_settings: settings.user_settings,
        user_id: settings.user_id,
        aws_sub: settings.aws_sub
      }
    })
  }, {
    onSuccess: (data, variables) => {
      if (data.errorMessage) {
        //do something
      }
      updateActivityLog.mutate({
        activity_details: {
          area: "factory",
          ...variables
        },
        activity_type: "user settings updated"
      })
      setIsLoading(false);
      refreshTable()
      //closeDialog()
    }
  })

  const handleSubmit = (values) => {
    
    //console.log(values)
    const userObject = {
      aws_sub: user.aws_sub,
      user_id: user.user_id,
      user_settings: {
        ...user.user_settings, //make sure all other settings are not lost
        can_checkout: values.can_checkout
      }
    }
    //console.log(userObject) 
    update_user.mutate(userObject)

  }

  //console.log(currentUser)

  return (
    <>
      <Button
        variant='contained'
        //color='warning'
        onClick={e => setOpen(true)}
        size="small"
        startIcon={<FontAwesomeIcon icon={faPencil} />}
      >
        {setLanguageText("Edit")}
      </Button>
      {currentUser ?
        <Formik
          initialValues={currentUser}
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          {({ submitForm, validateForm, errors, values }) => {
            //console.log(values)
            //console.log(errors)
            return (
              <GlobalDialog
                open={open}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
                title={`Edit User`}
                buttonTitle={setLanguageText("Update User")}
                buttonClick={submitForm}
              >
                <DialogContent>
                  <UserForm />
                  {isLoading || update_user.isLoading || update_user.isSuccess || update_user.isError ?
                    <DialogLoader
                      isLoading={isLoading}
                      mutation={update_user}
                      loadingMessage="Updating User..."
                      successMessage="Updating User"
                      closeDialog={closeRefreshDialog}
                    />
                    : null}
                </DialogContent>
              </GlobalDialog>
            )
          }}
        </Formik>
        : null}
    </>
  );
};

export default EditUser;
