export default function queryGetAllManufacturers() {
    

    let query = {
        "query": {
            "size": 0,
            "aggs": {
                "unique_manufacturers": {
                    "terms": {
                        "field": "manufacturer",
                        "size": 10000
                    }
                }
            }
        }
    };
    return query;
}

