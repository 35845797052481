import React from "react";
import { Grid, Paper } from "@mui/material";
import InnerHeaderWrap from "../../layout/InnerHeaderWrap";
import GlobalCompanySelect from "../../reusable/GlobalCompanySelect"
import BuyerSearch from "./../search/BuyerSearch";
import { isMobile } from "react-device-detect";

const Header = (props) => {

    return (
        <InnerHeaderWrap props={props}>
            {/* <ShopBreadcrumbs/> */}
            <Grid spacing={isMobile ? 1 : 2} container justifyContent="space-between" alignItems="center" >
                <Grid item xs={12} md={3} >
                    <GlobalCompanySelect fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item >
                        <Paper
                            elevation={0}
                            sx={{
                                p: '4px', display: 'flex', alignItems: 'center',
                                border: '1px solid #ccc',
                                width: ' 100%'
                            }}
                        >
                            <BuyerSearch />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </InnerHeaderWrap>
    );
};

export default Header;
