import React from 'react';
import config from '../config';

const EmailHeader = ({ theme, logo }) => {

    return (
        <thead style={{ backgroundColor: '#fff', textAlign: 'center', color: '#333' }}>
            <th colSpan="2" style={{
                //backgroundColor: config.INSTANCE === "act" ? '#000' : theme.palette.primary.main 
                backgroundColor: config.INSTANCE === "act" ? '#FFF' : theme.palette.primary.main,
                borderBottom: `4px solid ${config.INSTANCE === "act" ? theme.palette.primary.main : 'transparent'}`,
            }} >
                <img
                    src={logo}
                    alt="logo"
                    style={{ width: '100%', maxWidth: '300px', height: 'auto', objectFit: 'contain' }}
                />
            </th>
        </thead>
    )
};

export default EmailHeader;  
