import React, { useState, useContext, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Grid } from '@mui/material';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { Alert } from '@mui/material';
import { MachineContext } from '../../store/MachineContext';
import { SELECTED_MACHINE } from '../../store/MachineReducers';
import LoadingButton from '../../buttons/LoadingButton';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import setLanguageText from '../../../language/setLanguageText';
import getUserLevel from '../../../helpers/getUserLevel';
import axiosRequest from '../../../axios/axoisRequest';
import { useMutation } from '@tanstack/react-query';
import config from '../../../config';

const FactorMachine = () => {
	const [factor, setFactored] = useState(false);
	const { user } = useContext(UserContext);
	const { selectedMachine, dispatchSelectedMachine } = useContext(MachineContext);
	const { updateActivityLog } = useActivityLog();

	useEffect(() => {
		if (selectedMachine?.machine_settings?.factored) {
			setFactored(selectedMachine.machine_settings.factored);
		}
	}, [selectedMachine]);


	const handleChange = factor => {
		const selected = {
			...selectedMachine,
			machine_settings: {
				...selectedMachine.machine_settings,
				factored: !factor
			}
		};
		//console.log(selected)
		dispatchSelectedMachine({
			type: SELECTED_MACHINE,
			payload: selected
		});
		setFactored(!factor);
	};

	const editFactored = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'machines',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response) => {

			if (!response.errorMessage) {
				updateActivityLog.mutate({
					activity_details: {
						area: "coolant",
						machine_id: selectedMachine.machine_id,
						factored: Boolean(factor)
					},
					activity_type: "machine factor updated"
				})
			}
		}
	})

	const saveFactored = (factor) => {

		editFactored.mutate({
			edit: 'factor',
			machine_id: selectedMachine.machine_id,
			factored: Boolean(factor)
		})
	};

	return (
		<Grid
			item
			container
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
		>
			<Grid item style={{ width: '100%' }}>
				<Alert severity="info">
					{setLanguageText("If you want the Refractometer Factor settings applied to all Readings and Concentration Min And Max")}.
				</Alert>
			</Grid>
			{getUserLevel(user.user, 'coolantcare', 'machine_edit', 7) &&
				<>
					<Grid item>
						<FormControlLabel
							control={
								<Switch
									checked={factor}
									onChange={e => handleChange(factor)}
									name="checkedB"
									color="primary"
								/>
							}
							label={setLanguageText(
								!factor
									? 'Apply Refractometer Factor'
									: 'Remove Refractometer Factor'
							)}
						/>
					</Grid>
					<Grid item>
						<LoadingButton
							icon={faSave}
							text="Save Refractometer Factor"
							iconSize="1x"
							color="primary"
							loading={editFactored.isLoading}
							onClick={e => saveFactored(factor)}
						/>
					</Grid>
				</>
			}
		</Grid>
	);
};
export default FactorMachine;
