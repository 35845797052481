import axios from 'axios';
import config from '../../config';
import { Auth , Signer } from 'aws-amplify';



//cancel the request
const controller = new AbortController();

const axiosRequest = async ({ signal, endpoint, api_key, method, params, body }) => {
  
    const current_credentials = await Auth.currentCredentials();
    const credentials = Auth.essentialCredentials(current_credentials);
    // Signer don't accept params in the url, not pass in params: {...} in Axios
    //console.log(params)
    let searchParams = ''
    if(params){
        searchParams = new URLSearchParams();
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        //console.log(searchParams.toString())
    }

    let request;

    //signer needs data stingified
    if (method === 'post' || method === 'patch' || method === 'delete') {
        request = { 
            method: method.toUpperCase(),
            url: `${config.coolantAPI.URL}/${endpoint}`,
            data: body ? JSON.stringify(body) : null
        };
    }

    if (method === 'get') {
        request = {
            method: method.toUpperCase(),
            url: `${config.coolantAPI.URL}/${endpoint}${searchParams && `?${searchParams.toString()}`}`
        };
    }

    if (api_key) {
        request = {
          ...request,
          headers: { 'X-Api-Key': api_key }
        };
      }

    const access_info = {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken
    };
    const service_info = {
        service: 'execute-api',
        region: config.global.REGION
    };

    const signedRequest = Signer.sign(request, access_info, service_info);
    delete signedRequest.headers['host'];

    //add cancel token
    let options = { signal }
    
    //create instance
    let instance = axios.create(options);

    let response = await instance(signedRequest)
        .then(function (response) {
            //console.log(response);
            return response;
        })
        .catch(function (error) {
            if (axios.isCancel(error)) {
                // error.errorMessage = 'Request canceled'
                //console.log(error.errorMessage);
                throw Error("Request canceled");
            } else {
                if (error.response) {
                   //console.log(error.response.data);
                   //console.log(error.response.status);
                   //console.log(error.response.headers);
                } else if (error.request) {
                   //console.log(error.request);
                } else {
                   //console.log("Error", error.message);
                }
               //console.log(error.config);
            }
        });

        // cancel the request
        controller.abort()

        return response.data;
};

export default axiosRequest;

