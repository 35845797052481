import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import ProductImage from '../../reusable/ProductImage';
import PercentageBar from '../cards/PercentageBar';

const useStyles = makeStyles(theme => ({
	linearBarContainer: {
		position: 'relative',
		height: '100%'
	},
	linearBar: {
		height: theme.spacing(6),
		borderRadius: theme.spacing(0.5),
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: theme.palette.success.main
		},
		'&.MuiLinearProgress-determinate': {
			backgroundColor: theme.palette.error.main
		}
	},
	machineNumbers: {
		height: '100%',
		padding: '8px',
		position: 'relative',
		backgroundColor: theme.palette.warning.main
	},
	percentage: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: theme.palette.background.light
	},
	image: {
		padding: '10px',
		'& img': {
			width: '100%',
		}
	}
}));

const Overview = ({ additive }) => {

	const [percentage, setPercentage] = useState();

	useEffect(() => {
		if (additive.machines_using_product && additive.total_machines) {
			setPercentage((additive.machines_using_product.length / additive.total_machines) * 100);
		} else {
			setPercentage(0);
		}
	}, [additive])

	const linearBarStyle = useStyles();

	return (
		<Grid container direction="column" alignItems="stretch" spacing={2}>

			{percentage ?
				<PercentageBar percentage={percentage} machines={additive?.machines_using_product?.length} total={additive?.total_machines} />
				: null}

			<Grid item container className="product--details" spacing={1}>
				<Grid item xs={10}>
					<Typography variant="body2" className="product--desc">
						{additive?.product_defaults?.description}
					</Typography>
					<ul style={{ padding: '0 0 0 1em', margin: '5px 0' }}>
						{additive?.product_defaults?.defaults?.selling_points &&
							additive?.product_defaults?.defaults?.selling_points.map(
								(point, index) => (
									<li key={`point--${index}`}>
										<Typography variant="body2">
											{point}
										</Typography>
									</li>
								)
							)}
					</ul>
				</Grid>
				<Grid item className={linearBarStyle.image} xs={2}>
					<ProductImage product={additive} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Overview;
