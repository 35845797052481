
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

export const CategoryReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CATEGORIES:
      return {
        ...state,
        currentCat: action.payload.currentCat ? action.payload.currentCat: state.currentCat,
        allCats: action.payload.allCats ? action.payload.allCats : state.allCats,
        subCats: action.payload.subCats ? action.payload.subCats : state.subCats,
        menuCats: action.payload.menuCats ? action.payload.menuCats : state.menuCats,
        flatCats: action.payload.flatCats ? action.payload.flatCats : state.flatCats,
        openLevel: action.payload.openLevel ? action.payload.openLevel : state.openLevel,
      };
    default:
      return state;
  }
};

