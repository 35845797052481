import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../store/UserContext';
import { useQuery } from 'react-query';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import { Alert, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { CompanyContext } from '../store/CompanyContext';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import setLanguageText from '../language/setLanguageText';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileOnly } from 'react-device-detect';
import ProductPopup from '../products/opensearch/ProductPopup';
import FavouritesProductGrid from './FavouritesProductGrid';


const Favourites = () => {

	const { user } = useContext(UserContext)
	const { selectedCompany } = useContext(CompanyContext)
	const [favourites, setFavourites] = useState();
	const [open, setOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState();

	const { data, isFetching, refetch } = useQuery({
		queryKey: ['my_favourites', user?.user?.user_id, selectedCompany?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.factoryAPI.URL,
			endpoint: 'favourites',
			api_key: user.api_key,
			method: 'get',
			params: {
				user_id: user?.user.user_id,
				company_id: selectedCompany?.company_id,
			}
		}),
		enabled: !!user.user.user_id && !!selectedCompany?.company_id,
		retry: false
	})

	useEffect(() => {
		if (data) {
			setFavourites(data)
		}
	}, [data])

	useEffect(() => {
		if (user.favourites) {
			refetch()
		}
	}, [user.favourites])

	const handleClose = () => {
		setOpen(false);
		setSelectedProduct();
	}

	const productClick = (product) => {
		setOpen(true)
		setSelectedProduct(product)
	}

	return (
		<>

			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("My Favourites")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid container spacing={2}>

				
					{favourites?.length > 0 ? favourites.map((fav, i) => {
					
							return (
								<Grid key={i} item xs={12} sm={6} lg={4} xl={3}>
									<FavouritesProductGrid
										product={fav.product_details}
										productClick={productClick}
									/>
								</Grid>
							)
						

					})
						: favourites?.length === 0 &&
						<Grid item xs={12}>
							<Alert severity="info">You have no favourites yet</Alert>
						</Grid>
					}
				</Grid>
			</TabContent>
			{
				open && selectedProduct &&
				<Dialog
					fullWidth
					maxWidth="md"
					open={open}
					onClose={handleClose}
					fullScreen={isMobileOnly}
				>
					<IconButton
						sx={{
							cursor: 'pointer',
							position: 'absolute',
							right: 8,
							top: 8,
							background: '#fff',
							padding: '10px 15px',
							zIndex: 10
						}}
						onClick={handleClose}
					>
						<FontAwesomeIcon
							aria-label="close"
							sx={{
								color: (theme) => theme.palette.grey[500],
							}}
							icon={faTimes}
						/>
					</IconButton>
					<ProductPopup product={selectedProduct} closeDialog={handleClose} />
				</Dialog>
			}
		</>
	);
}

export default Favourites;

