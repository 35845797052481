import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../store/UserContext';
import { Link } from 'react-router-dom';
import config from '../config';

export default function Welcome() {

    let jumpObject = sessionStorage.getItem('jumpObject');
    const { user } = useContext(UserContext);
    const [links, setLinks] = useState();

    useEffect(() => {
        if (jumpObject) {
            let jumpLinks = JSON.parse(jumpObject)
            //console.log('areas', areas)
            setLinks(jumpLinks)
        }
    }, [jumpObject])

    return (

        <>
            <Typography
                variant="h6"
                color="textPrimary"
                //textAlign="end"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    fontWeight: 600
                }}
            > Welcome back, {user?.user?.user_details?.name} {user?.user?.user_details?.surname}</Typography>
            <Typography
                variant="caption"
                color="textPrimary"
                //textAlign="end"
                sx={{

                    display: {
                        xs: 'none',
                        md: 'block'
                    },
                    '& a': {
                        color: theme => theme.palette.primary.main,
                        textDecoration: 'none',
                    }
                }}
            > Jump Back In:{' '} 
                <>
                    {links && links?.length > 0 ?
                        <>
                            {links.map((area, index) => <><Link key={index} to={area.link}>{area.title}</Link>{index < links.length - 1 ? ', ' : ''}</>)}
                        </>
                        :
                        <>
                            <Link to="/search/search">Search</Link>, <Link to="/parametric/search?q=&type=all&page=0">Parametric</Link>,
                            <Link to="/coolantcare">{config.APPNAME === "act" ? " Lubricant" : " MWF"}</Link>
                        </>}
                </>
            </Typography>
        </>

    )
}
