import { LoadingDots } from './../reusable/LoadingDots';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';

const useStyles = makeStyles(theme => ({
	primary: {
		fill: `${theme.palette.primary.contrastText}`
	},
	secondary: {
		fill: `${theme.palette.secondary.contrastText}`
	}
}));

const LoadingButton = ({
	loading,
	text,
	color,
	textVariant,
	className,
	iconSize,
	icon,
	fullWidth,
	type,
	disabled,
	...props
}) => {

	const classes = useStyles();
	const { language } = useContext(LayoutContext);

	return (
		<Button
			variant="contained"
			type={type}
			fullWidth={fullWidth}
			color={color ? color : 'primary'}
			className={className}
			disabled={disabled}
			{...props}
		>
			<span>
				{loading ? (
					<LoadingDots
						className={
							color === 'primary' ? classes.primary : classes.secondary
						}
						width={40}
					/>
				) : (
					setLanguageText(text)
				)}
			</span>
			{icon && !loading ? (
				<FontAwesomeIcon style={{ marginLeft: '5px' }} icon={icon} size={iconSize} />
			) : null}
		</Button>
	);
};

export default LoadingButton;
