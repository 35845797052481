import React, { useEffect, useState } from "react";
import ItemCard from "./ItemCard";
import { Chip, Divider, Grid, Skeleton, Typography, Box, useMediaQuery, Fade } from "@mui/material";
import { useTheme } from "@emotion/react";
import Slider from "react-slick";
import '../../slider/slickSlider.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { Opacity } from "@mui/icons-material";

const Row = ({ isFetching, data, title, selected, handleSelect }) => {

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const [startSlide, setStartSlide] = useState();

    function Arrows(props) {
        const { style, onClick , currentSlide, slideCount, left, right } = props;
        //console.log(props)
        return (
            <Box
            //className={className}
            sx={{  
                ...style,
                cursor: theme => currentSlide === 0 && left ? 'unset' : currentSlide === slideCount - 1 && right ? 'unset' : 'pointer',
                zIndex: 1,
                position: 'absolute',
                height : '100%', 
                width: '40px', 
                background:  theme => currentSlide === 0 && left ? theme.palette.grey[500] : currentSlide === slideCount - 1 && right ? theme.palette.grey[500] : theme.palette.primary.main,
                color: theme => theme.palette.primary.contrastText,
                left: left ?  0 : 'auto',
                right: right ?  0 : 'auto',
                display: 'grid',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'background 0.3s ease-in-out',
                '& svg': {
                    color: theme => theme.palette.primary.contrastText,
                    fill: theme => theme.palette.primary.contrastText,
                    margin: 'auto'
                },
                '&:hover'   : { 
                    background:  theme => currentSlide === 0 && left ? theme.palette.grey[500] : currentSlide === slideCount - 1 && right ? theme.palette.grey[500] : theme.palette.primary.dark,
                },
                opacity : currentSlide === 0 && left ? 0.3 : currentSlide === slideCount - 1 && right ? 0.3 : 1
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={right ? faChevronRight : faChevronLeft} />
        </Box>
 
        );
    }

    useEffect(() => {
        if (data && data.length > 1) {
            setStartSlide(Math.floor(data.length / 2))
        }
    }, [data])

    const settings = {
        arrows: true,
        dots: true,
        infinite: false,
        focusOnSelect: true,
        dragable: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        initialSlide : startSlide,
        nextArrow: <Arrows right />,
        prevArrow: <Arrows left />
    };

    return (
        <>
            <Grid
                item textAlign="center" xs={12}>
                <Divider>
                    <Chip sx={{
                        fontSize: '1em',
                        padding: '1em',
                        background: selected ? theme.palette.primary.main : "#ccc",
                        color: selected ? theme.palette.primary.contrastText : "#333"
                    }}
                        label={title} />
                </Divider>
            </Grid>
            <Grid
                container
                item
                xs={12}
                //justifyContent="center"
                sx={{
                    padding: mobile ? '14px' : '1em',
                    width: '100%'
                }}
            >
                {isFetching ?
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            display: "grid"
                        }}
                    >
                        <Box
                            sx={{
                                margin: 'auto',
                            }}
                        >
                            <Skeleton variant="rectangular" animation="wave" width={mobile ? '120px' : 220} height={mobile ? '140px' : 180} />
                        </Box>
                    </Grid>
                    :
                    <>
                        {data && data.length > 1 && startSlide &&
                            <Grid
                                item
                                xs={12}
                                //justifyContent="center"
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <Slider {...settings} >
                                    {data.map((item, i) =>

                                        <Box
                                            onClick={e => {
                                                handleSelect(e, item)
                                            }}
                                            sx={{
                                                padding: '2px'
                                            }}
                                            key={i}
                                        >
                                            <ItemCard item={item} selected={selected} />
                                        </Box>
                                    )}

                                </Slider>
                            </Grid>
                        }
                        {data && data.length === 1 &&
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    width: '100%',
                                    alignItems: 'center',
                                    display: "grid"
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 'auto',
                                    }}
                                    onClick={e => {
                                        handleSelect(e, data[0])
                                    }}
                                >
                                    <ItemCard item={data[0]} selected={selected} />
                                </Box>
                            </Grid>
                        }
                    </>
                }
            </Grid >
        </>
    );
}
export default Row;
