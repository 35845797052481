import React, { useContext, useEffect, useState } from "react";
import { Alert, Grid, useMediaQuery } from "@mui/material";
import { UserContext } from "../../store/UserContext";
import { ShopContext } from "../../store/ShopContext";
import { LayoutContext } from "../../store/LayoutContext";
import ProductGroupedItems from "../../products/ezbase/ProductGroupedItems";
import { CompanyContext } from "../../store/CompanyContext";
import ProductGroupTable from "../../products/ezbase/ProductGroupTable";
import Header from "./Header";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { useLocation } from "react-router-dom";
import ResultToggleFilters from "../filters/ResultToggleFilters";
import BuyerSearchFilters from "../search/BuyerSearchFilters";
import { isMobile } from "react-device-detect";
import { ProductsContext } from "../../store/ProductsContext";
import useProductSearchObjectEzbase from "../../hooks/useProductSearchObjectEzbase";
//import ProductSingleItem from "../../products/ProductSingleItem";
import ProductOptions from "../../products/shared/ProductOptions";
import ProductsNotAvailable from "../../products/shared/ProductsNotAvailable";

const BuyerSearchLayout = (props) => {

    const { products } = useContext(ProductsContext);
    const { layout } = useContext(LayoutContext);
    //gets products
    const { isFetching } = useProductSearchObjectEzbase();

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <Header {...props}  noBreadcrumb />
            <InnerContentWrap>
                <Grid container spacing={isMobile ? 1 : 2} alignItems="flex-start" >
                    <Grid container item  xs={12} md={4} lg="auto"
                        sx={{
                            width: lgUp ? '400px!important' : 'auto',
                            maxWidth: lgUp ? '400px!important' : '100%'
                        }}>
                        <BuyerSearchFilters />
                    </Grid>
                    <Grid item container spacing={isMobile ? 1 : 2} xs={12} md={8} lg>
                        <ResultToggleFilters />
                        {products.noProducts ? (
                            <Grid item xs={12}>
                                <ProductsNotAvailable />
                            </Grid>
                        ) :
                            <>
                                <ProductOptions isFetching={isFetching} />
                                {layout.layout === "grid" ? (
                                    <>
                                        {products?.allProducts ? products?.allProducts.map((product, i) => {
                                            return (
                                                <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                                                    {/*<ProductSingleItem product={product} isFetching={isFetching} />  */}
                                                    <ProductGroupedItems product={product} isFetching={isFetching} />
                                                </Grid>
                                            )
                                        }) : null}
                                    </>
                                ) : layout.layout === "engineer" ? (
                                    <ProductGroupTable isFetching={isFetching} />
                                ) : null
                                }
                                {products?.allProducts ? <ProductOptions isFetching={isFetching} /> : null}
                            </>
                        }
                    </Grid>
                </Grid>
            </InnerContentWrap>

        </>
    );
};

export default BuyerSearchLayout;
