import React, { useReducer, createContext } from 'react';
import { ShopReducer ,BrandsReducer , FilterReducer} from './ShopReducers';

export const ShopContext = createContext();

ShopContext.displayName = 'Shop Details';

export const ShopProvider = ({ children }) => {


	const [shop, dispatchShop] = useReducer(ShopReducer, {
		breadcrumbCats: [],
		allCats: [],
		currentCat: null,
		currentProductCat: null,
		openLevel: [],
		productSubCat: [],
	});

	const [filters, dispatchFilters] = useReducer(FilterReducer, {
		productFilters: [],
		filters: []
	});

	const [brands, dispatchBrands] = useReducer(BrandsReducer, {
		//allBrands: [],
		currentBrands: null,
		selectedBrands: null
	});

	const contextValues = {
		shop,
		dispatchShop,
		filters,
		dispatchFilters,
		brands,
		dispatchBrands
	};

	return (
		<ShopContext.Provider value={contextValues}>
			{children}
		</ShopContext.Provider>
	);
};
