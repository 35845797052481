import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

const MaterailGroups = ({ groups, updateMaterialFilters, setMaterial }) => {

	useEffect(() => {
		if (groups) {
			if (setMaterial) {
				setMaterial(Object.entries(groups)[0][1])
			} else if (updateMaterialFilters) {
				//console.log(Object.entries(groups)[0][0])
				updateMaterialFilters(Object.entries(groups)[0][0])
			}
		}
	}, [groups])

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setMaterial(Object.entries(groups)[newValue][1])
	};


	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box >
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						{Object.entries(groups).map((group, i) => {
							return (
								<Tab key={i} label={group[0]} value={i} sx={{ background: '#fff' }} />
							)
						})}
					</TabList>
				</Box>

				{Object.entries(groups).map((group, i) => {
					return (
						<TabPanel key={i} value={i} sx={{ background: theme => theme.palette.background.default }}>
							{Object.entries(group[1]).map((item, idx) =>
								<Typography key={idx}><strong>{item[0]}</strong> -  {item[1]}</Typography>
							)}
						</TabPanel>
					)
				})}
			</TabContext>
		</Box>
	);
}

export default MaterailGroups;

