import { faGears, faIndustryAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { LayoutContext } from '../store/LayoutContext';
import { UserContext } from '../store/UserContext';
import { Avatar, MenuItem } from '@mui/material';
import setLanguageText from '../language/setLanguageText';
import { useNavigate } from 'react-router-dom';
import { UPDATE_MENU } from '../store/LayoutReducers';

const SettingsMenu = ({ handleClose }) => {

	const navigate = useNavigate();
	const {  dispatchApp } = useContext(LayoutContext)
	const { user } = useContext(UserContext)

	return (
		<>
			<MenuItem onClick={e => {
				handleClose()
				dispatchApp({
					type: UPDATE_MENU,
					payload: {
						selectedArea: "settings",
						selectedMenu: "company"
					}
				})
				navigate('/settings/company?section=0&tab=0')
			}}
				disableRipple
			>
				<Avatar sx={{ bgcolor: 'primary.main' }}>
					<FontAwesomeIcon size="xs" icon={faIndustryAlt} />
				</Avatar>
				{setLanguageText('Company Management')}
			</MenuItem>
			{user?.user?.user_permissions?.administrator &&
				<MenuItem onClick={e => {
					handleClose()
					dispatchApp({
						type: UPDATE_MENU,
						payload: {
							selectedArea: "settings",
							selectedMenu: "app"
						}
					})
					navigate('/settings/app?tab=0')
				}}
					disableRipple
				>
					<Avatar sx={{ bgcolor: 'primary.main' }}>
						<FontAwesomeIcon size="xs" icon={faGears} />
					</Avatar>
					{setLanguageText('Application Settings')}
				</MenuItem>
			}
		</>

	);
};

export default SettingsMenu;
