import React, { useContext, useEffect, useRef, useState } from "react";
import { Autocomplete, Avatar, Box, Button, CircularProgress, createFilterOptions, Divider, Grid, IconButton, InputBase, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDoubleRight, faImageSlash, faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
//import { useQuery } from "react-query";
import axiosRequest from "../../axios/axoisRequest";
//import useDebounce from "../libs/useDebounce";
import { CategoryContext } from "../../store/CategoryContext";
import { useNavigate } from "react-router-dom";
import { SelectedContext } from "../../store/SelectedContext";
import { CLEAR_RESULTS_FILTERS, CLEAR_URL_FILTERS, UPDATE_PARAMETRIC_FILTERS, UPDATE_URL_FILTERS } from "../../store/SelectedReducers";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import { LayoutContext } from "../../store/LayoutContext";
import { SHOW_PRODUCTS } from "../../store/LayoutReducers";
import highlightText from "../../helpers/highlightText";
import { useMutation } from "react-query";
import { CompanyContext } from "../../store/CompanyContext";
import useSearchCount from "../../hooks/useSearchCount";
import { isMobile } from "react-device-detect";
import { set, values } from "lodash";

const filter = createFilterOptions({
	matchFrom: 'start',
	stringify: (option) => option.Name,
})

const SearchBar = ({ large, setFocused }) => {

	const navigate = useNavigate();
	const { updateSearchCount } = useSearchCount();
	const { user } = useContext(UserContext);
	const { categories, dispatchCategories } = useContext(CategoryContext);
	const { dispatchSelected } = useContext(SelectedContext);
	const { app, dispatchLayout } = useContext(LayoutContext);
	const { selectedCompany } = useContext(CompanyContext)
	const [value, setValue] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);

	const getSuggestion = useMutation({
		mutationKey: ['suggestions'],
		mutationFn: (value) => {
			//console.log(value)
			//console.log(user)
			let search = [{ "Field": "Volltext", "Values": [encodeURI(value)] }]
			let filters = []
			//if there is no company filter, add it
			if (!filters.find(field => field.Field === "COMPC") && selectedCompany?.company_supplier_order) {
				filters.push({ "Field": "COMPC", "Values": selectedCompany.company_supplier_order, "DIN": "J3" })
				//[{"Field":"COMPC","Values":["Aura","BK"],"DIN":"J3"}]
			}

			return axiosRequest({
				gateway: config.shopAPI.URL,
				endpoint: 'products',
				api_key: user.api_key,
				method: 'get',
				params: {
					category: "Root",
					search: JSON.stringify(search),
					filters: JSON.stringify(filters),
					//search: JSON.stringify([{"Field":"IDRN","Values":[encodeURI(text)]}]),
					skip: 0,
					take: 5,
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response)
			console.log(response)
			if (!response.errorMessage && Object.values(response).length) {
				setSuggestions(response.Components)
			}
		}
	})

	useEffect(() => {
		if (inputValue.length > 2) {
			getSuggestion.mutate(inputValue)
		}
	}, [inputValue]);

	const gotoProducts = (value) => {
		//console.log(selected)
		dispatchCategories({
			type: UPDATE_CATEGORIES,
			payload: {
				currentCat: categories.allCats,
				openLevel: []
			}
		});

		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'Volltext',
				'Values': value ? [value] : [],
				'Toggle': true,
				'Tolerance': 0
			}
		});

		//only sets the search parameter
		dispatchSelected({
			type: UPDATE_URL_FILTERS,
			payload: {
				search: [[`Volltext§${value}`]],
				filters: [] //reset custom result filters
			}
		});
		//clear result filters on new search
		dispatchSelected({
			type: CLEAR_RESULTS_FILTERS,
			payload: {}
		});

		if (app.selectedArea === 'application') {
			//update search count
			updateSearchCount.mutate('application')
			navigate(`/${app.selectedArea}/search/results?c=Root&search=[["Volltext§${value}"]]`);

		}
		else if (app.selectedArea === 'parametric') {
			//update search count
			updateSearchCount.mutate('parametric')
			navigate(`/${app.selectedArea}/search/results?c=Root&search=[["Volltext§${value}"]]`);
		} else {
			//update search count
			updateSearchCount.mutate('engineer')
			dispatchLayout({
				type: SHOW_PRODUCTS
			});
			navigate(`/${app.selectedArea}/catalogue?c=Root&search=[["Volltext§${value}"]]`);
		}
		// clear text if on the same page
		// setValue('');
		// setCount();
		// setResults();
	};

	return (
		<>
			<Grid
				container
			>
				<Grid item xs >
					<Autocomplete
						sx={{
							//width: ' 100%',
							position: 'relative',
							zIndex: 1,
							'& .MuiInputBase-root': {
								//padding: '0.5em',
								height: large ? isMobile ? 'auto' : '70px' : 'auto',
								fontSize: large ? isMobile ? '1rem' : '1.5em' : '1em',
								border: 'none'
							}
						}}
						//getOptionLabel={(option) => option.title}
						getOptionLabel={(option) => {
							//console.log(option)
							// Value selected with enter, right from the input
							if (typeof option === 'string') {
								return option;
							}
							// Add "xxx" option created dynamically
							if (option.type) {
								return option.value;
							}
							// Regular option
							return option.ArticleNumber;
						}}
						onFocus={() => {
							setFocused ? setFocused(true) : null
						}}
						onBlur={() => {
							setFocused ? setFocused(false) : null
						}}
						//filterOptions={(x) => x}
						filterOptions={(options, params) => {

							//console.log(options)
							//console.log(params)
							//const filtered = filter(options, params);
							//const { inputValue } = params;
							//const filtered = options
							//console.log(filtered)

							let suggestion = options

							// Suggest the creation of a new value
							//const isExisting = options.some((option) => inputValue === option.title);
							if (inputValue !== '') {
								suggestion.push({
									"value": inputValue,
									"Name": `Search all products with "${inputValue}"`,
									"type": "all"
								});
							}
							return suggestion;
						}}
						options={suggestions}
						autoComplete
						includeInputInList
						//loading={isFetching}
						loadingText="Loading suggestions..."
						filterSelectedOptions
						value={value}
						clearOnBlur={false}
						freeSolo
						noOptionsText="No Suggestion"
						onChange={(event, newValue) => {
							if (newValue) {
								//console.log(newValue)
								if (!newValue.value) {
									//set value to the input value
									newValue = {
										"value": newValue,
										"Name": `Search all products with "${newValue}"`,
										"type": "all"
									}
								}
								//console.log(newValue)
								setSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
								setValue(newValue);
								//getProducts.mutate(newValue)
								//getCodeProducts.mutate(newValue)
								//getDescProducts.mutate(newValue)

							} else {
								//clear
								setValue('')
								setSuggestions([])
							}
						}}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								console.log(inputValue)
								gotoProducts(inputValue)
							}
						}}
						onInputChange={(event, newInputValue) => {
							//console.log(newInputValue)
							setInputValue(newInputValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									width: '100%',
									'& input': { zIndex: 2 },
									'& legend': { display: 'none' },
									'& fieldset': {
										backgroundColor: theme => theme.palette.background.paper,
										color: theme => theme.palette.text.primary,
									},
									'& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
										zIndex: 2,
										top: "auto"
									}
								}}
								placeholder="Search our products by code, number or description..."
								//label="search" 
								fullWidth
							/>
						)}
						renderOption={(props, option, value) => {
							//console.log(option)
							return (
								<MenuItem

									key={value.index}
									{...props}
									//exend the props
									onClick={e => {
										if (option.value) {
											gotoProducts(option.value)
										} else {
											if (app.selectedArea === 'application' || app.selectedArea === 'parametric') {
												navigate(`/${app.selectedArea}/search/product/${option.ID}`);
											} else {
												navigate(`/${app.selectedArea}/catalogue/product/${option.ID}`);
											}
										}

										props.onClick(e);
										setValue(option);
									}}

								>
									<ListItemIcon>
										<FontAwesomeIcon icon={faSearch} size="sm" />
									</ListItemIcon>
									<ListItemText
										sx={{
											fontSize: '10px',
											fontWeight: '700',
											color: "#ccc",
											'& span': {
												fontWeight: '700',
												color: "#000"
											},
											'& em': {
												fontStyle: 'normal',
												fontWeight: '300',
											}
										}}
										primary={highlightText(option.Name, inputValue)}
										secondary={option.ArticleNumber ? highlightText(option.ArticleNumber, inputValue) : null}
									/>

								</MenuItem>
							);
						}}
					/>
				</Grid>
				<Grid item xs="auto">
					<Button
						color="primary"
						variant="contained"
						sx={{
							//position: 'absolute',
							marginLeft: '5px',
							boxShadow: 'none',
							height: large ? "70px" : "100%",
							p: '1em 2em',
							zIndex: 2,
							'&:hover': {
								boxShadow: 'none'
							}

						}}
						aria-label="directions"
						onClick={e => {
							gotoProducts(inputValue)

						}}
					>
						{/* {getProducts.isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="sm" />} */}
						<FontAwesomeIcon
							icon={faSearch}
							size={large ? isMobile ? "lg" : "2x" : "lg"}
						/>
					</Button>
				</Grid>
				{/* 					
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				placeholder="Search All Our Products"
				inputProps={{ 'aria-label': 'Search Our Products' }}
				value={value}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				onKeyUp={(e) => {
					if (e.key === "Enter") {
						setValue(e.target.value);
						get10Products.mutate(e.target.value)
					}
				}}
			/>

			{count === 0 ?
				<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>No products</Typography>
				: count > 0 ?
					<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }} >({count})</Typography>
					: null}

			{value?.length ?
				<Box
					sx={{
						cursor: 'pointer',
						p: '10px',
						color: (theme) => theme.palette.grey[400],
						'&:hover': {
							color: (theme) => theme.palette.grey[800]
						}

					}}
					onClick={e => clearText()}
				>
					<FontAwesomeIcon icon={faTimes} size="lg" />
				</Box>
				: null}
			<Divider sx={{ height: 28, m: '0' }} orientation="vertical" />
			<IconButton
				ref={ref}
				color="primary"
				sx={{ p: '10px ' }}
				aria-label="directions"
				onClick={e => {
					get10Products.mutate(value)
					//setAnchorEl(ref.current);
				}}
			>
				{get10Products.isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="sm" />}
			</IconButton>
			{results && count > 0 && !results.errorMessage &&
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					slotProps={{
						elevation: 0,
						paper: {
							sx: {
								bgcolor: theme.palette.background.default,
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,1))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: theme.palette.background.default,
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}
					}}
				>
					{results.map((product, i) => {
						const photo = product.Graphics.find(item => item.Type === "Photo")
						const standardDes = product.Properties.find(item => item.DIN === "J11A")
						//console.log(product)
						//console.log(standardDes)
						//console.log(photo)
						return (
							<ListItemButton
								onClick={e => {
									if (app.selectedArea === 'application' || app.selectedArea === 'parametric') {
										navigate(`/${app.selectedArea}/search/product/${product.ID}`);
									} else {
										navigate(`/${app.selectedArea}/catalogue/product/${product.ID}`);
									}
								}}
								key={i} divider >
								<ListItemAvatar>
									<Avatar
										sx={{ background: '#fff', cursor: 'pointer', boxShadow: '0 0 6px 2px #404040' }}>
										{photo ?
											<img
												src={photo.URL}
												//onError={e => { e.target.src = placeholder;}}
												style={{ width: '100%', height: '100%', objectFit: 'cover' }}
											/>
											: <FontAwesomeIcon color="#222" icon={faImageSlash} />
										}
									</Avatar>
								</ListItemAvatar>

								<ListItemText
									disableTypography
									primary={<Typography color="text.primary" variant="subtitle1" fontSize="13px" lineHeight="1.4" ><strong>Name: </strong>
										<Typography color="text.secondary" component="span" variant="subtitle2" fontSize="13px" >
											{highlightText(product.Name.toString(), value.toString())}
										</Typography>
									</Typography>}
									secondary={
										<>
											<Typography color="text.primary" variant="subtitle2" fontSize="13px" ><strong>Article ID: </strong>
												{highlightText(product.Name.toString(), value.toString())}
											</Typography>
											{standardDes ?
												<Typography color="text.primary" variant="subtitle2" fontSize="13px" ><strong>Standard ID: </strong>
													{highlightText(standardDes.Value.toString(), value.toString())}
												</Typography>
												: null}
										</>
									}
								/>
							</ListItemButton>

						)

					})}

					<ListItemButton
						onClick={e =>
							gotoProducts(value)
						}
						sx={{ textAlign: 'right' }}>
						<Typography fontSize="13px" sx={{ width: '100%', color: (theme) => theme.palette.text.primary }} >
							View all {count ? count : ''} products
						</Typography>
						<FontAwesomeIcon sx={{ marginLeft: 10 }} color={theme.palette.text.primary} size="sm" icon={faChevronDoubleRight} />

					</ListItemButton>

				</Menu>
			}*/}
			</Grid>
		</>

	);
};

export default SearchBar;
