import React, { useReducer, createContext } from 'react';
import { ProductsReducer } from './ProductsReducers';

export const ProductsContext = createContext();

ProductsContext.displayName = 'Shop Products';

export const ProductsProvider = ({ children }) => {

	//limits
    const defaultLimit = sessionStorage.getItem("limit")
        ? sessionStorage.getItem("limit")
        : 10;

	const [products, dispatchProducts] = useReducer( ProductsReducer, {
		allProducts: [],
		noProducts : false,
        offset : 0 ,
        count:  0,
        limit: parseInt(defaultLimit),
		sortOrder : null,
		sortColumn : null
	});

	const contextValues = {
		products,
		dispatchProducts
	};

	return (
		<ProductsContext.Provider value={contextValues}>
			{children}
		</ProductsContext.Provider>
	);
};
