import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import ResultButton from "../parametric/ResultButton";
import { CategoryContext } from "../../store/CategoryContext";
import { SelectedContext } from "../../store/SelectedContext";
import { UserContext } from "../../store/UserContext";
import InterfaceImage from "./InterfaceImage";
import { UPDATE_PARAMETRIC_FILTERS } from "../../store/SelectedReducers";
import IntegerFormula from "./IntergerFormula";
import SpinningCogSVG from "../components/SpinningCogSVG";
import { faWandMagicSparkles } from "@fortawesome/pro-light-svg-icons";


const InterfaceInformation = ({ field }) => {
	//render fields
	const [open, setOpen] = useState(false);
	const [form, setForm] = useState();
	const [type, setType] = useState();
	const [size, setSize] = useState();
	const { categories } = useContext(CategoryContext);
	const { filters } = useContext(CategoryContext);
	const { user } = useContext(UserContext);
	const { selected, dispatchSelected } = useContext(SelectedContext);
	const theme = useTheme();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	//--------------------------- API CALLS  ------------------------------//

	const { status, data, error, isFetching } = useQuery(['wizards_cctms', categories.currentCat.ID],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/interface',
			api_key: user.api_key,
			method: 'post',
			body: {
				field: "CCTMS",
				toolClass: categories.currentCat.ID,
				search: JSON.stringify(selected.parametricFilters)
			}
		}), {
		enabled: !!categories.currentCat.ID && open,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: c_data, isFetching: c_isFetching } = useQuery(['wizards_ccfms', type],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/interface',
			api_key: user.api_key,
			method: 'post',
			body: {
				field: 'CCFMS',
				toolClass: categories.currentCat.ID,
				search: JSON.stringify(selected.parametricFilters)
			}
		}), {
		enabled: !!type,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const { data: z_data, isFetching: z_isFetching } = useQuery(['wizards_ccfms', form],
		({ signal, queryKey }) => axiosRequest({
			signal: signal,
			gateway: config.shopAPI.URL,
			endpoint: 'wizards/interface',
			api_key: user.api_key,
			method: 'post',
			body: {
				field: 'CZCMS',
				toolClass: categories.currentCat.ID,
				search: JSON.stringify(selected.parametricFilters)
			}
		}), {
		enabled: !!form,
		notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
		retry: 1
	})

	const updateSizeFilters = (size) => {
		//console.log(item)
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'CZCMS', 'Values': [size],'Name': 'Connection size code machine side','Ref': 'C2'
			}
		});
		setSize(size)
	}

	const steps = [
		`Type (${type ? type : ''})`,
		`Form (${form ? form : ''})`,
		`Size (${size ? size : ''})`,
	];

	const [activeStep, setActiveStep] = useState(0);

	const setCurrent = (current) => {
		setActiveStep(current);
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleNext = () => {
		if (activeStep === 2) {
			setOpen(false)
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleReset = () => {

		setActiveStep(0);
		setSize()
		setForm()
		setType()
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'CCFMS', 'Values': []
			}
		});
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'CCTMS', 'Values': []
			}
		});
		dispatchSelected({
			type: UPDATE_PARAMETRIC_FILTERS,
			payload: {
				'Field': 'CZCMS', 'Values': []
			}
		});
	};

	return (
		<>
			<Button
				sx={{ width: '35px', height: '35px', minWidth: 'auto', boxShadow: 'none', borderRadius: '50%' }}
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				<FontAwesomeIcon size="lg" icon={faWandMagicSparkles} />
			</Button>
			<Dialog

				open={open}
				maxWidth="md"
				// sx={{ height: '100%' }}
				// PaperProps={{
				// 	height: '90%'
				// }}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ background: theme.palette.background.default, textAlign: 'center' }} id="alert-dialog-title">
					<Typography variant="body1" color={'text.primary'} sx={{ margin: '.5em auto 1em' }} >Wizard to specify the machine sided interface.</Typography>
					<Stepper nonLinear activeStep={activeStep} sx={{ margin: '0 auto .5em auto', maxWidth: '700px' }}>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step onClick={e => setCurrent(index)} key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</DialogTitle>

				<DialogContent sx={{ padding: '1em!important' }}>
					{activeStep === 0 ?

						<Grid container spacing={2} alignContent="stretch" justifyContent="center">
							{isFetching ?
								<>
									<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
									<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
									<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
								</>
								: data && data.Values.map((item, i) => {
									return (
										<Grid item sx={{ width: '250px' }} key={i}>
											<InterfaceImage 
											field="CCTMS" 
											name="Connection code type machine side"
											reference="C11"
											item={item} 
											setSelected={setType} 
											selected={type} 
											setActiveStep={setActiveStep} 
											/>
										</Grid>
									)
								})}
						</Grid>

						: activeStep === 1 ?

							<Grid container spacing={2} alignContent="stretch" justifyContent="center">

								{c_isFetching ?
									<>
										<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
										<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
										<Skeleton sx={{margin : theme.spacing(1)}} width={250} height={450} variant="text" animation="wave" />
									</>
									: c_data && c_data.Values.map((item, i) => {
										return (
											<Grid item sx={{ width: '250px' }} key={i}>
												<InterfaceImage 
												field="CCFMS" 
												name="Connection code form at machine side"
												reference="C12"
												item={item} 
												setSelected={setForm} 
												selected={form} 
												setActiveStep={setActiveStep} />
											</Grid>
										)
									})}
							</Grid>

							: activeStep === 2 ?
								<Grid container alignContent="stretch" spacing={2} justifyContent="center">
							
										{z_isFetching ?
											<Grid item sx={12}>
												 <Skeleton width={400} height={50} variant="text" animation="wave" />
												 <Skeleton width={400} height={50} variant="text" animation="wave" />
												 <Skeleton width={400} height={50} variant="text" animation="wave" />
											</Grid>
											: z_data?.Values && z_data.Values.map((item, i) => {
												return (
													<Grid item sx={12} xs={{textAlign: 'center', justifyContent : 'center'}}>
														<Button sx={{margin : '0 auto'}} key={i} variant="contained" onClick={e => updateSizeFilters(item.ID)}>{item.Name} ({item.Hits})</Button>
													</Grid>
												)
											})}
										{!z_isFetching && z_data?.DMInterfaceCodeAssistantInformation &&
											<Grid>
												<IntegerFormula
													updateSizeFilters={updateSizeFilters}
													ValuesMetric={z_data.DMInterfaceCodeAssistantInformation?.ValuesMetric}
													field={{
														'Name': z_data.DMInterfaceCodeAssistantInformation.InputLabels[0],
														'Unit': 'mm',
														'Field': 'CZCMS',
														'Name': 'Connection size code machine side',
														'Ref': 'C2'
													}}
													formula={z_data.DMInterfaceCodeAssistantInformation.Formula}
												/>
											</Grid>
										}
									</Grid>
								: null
					}
				</DialogContent>
				<DialogActions sx={{ background: theme.palette.background.default, disply: 'flex', justifyContent: 'space-between' }} >
					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Back
					</Button>
					<Grid>
						<Button sx={{ marginRight: '1em' }} variant="contained" color="secondary" onClick={handleReset}>
							Reset
					</Button>
						<ResultButton />
					</Grid>
					<Button onClick={handleNext}>
						{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default InterfaceInformation;

