import React, { useCallback, useContext, useEffect, useState } from "react";
import { Divider, Grid, InputAdornment, InputLabel, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";
import useDebounce from "../../../shop/libs/useDebounce";
import InputLables from "./InputLables";

const Float = ({ field, filtersView }) => {

    const defaultOperator = "e";
    const [localOperator, setLocalOperator] = useState(defaultOperator);
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const [localValueTo, setLocalValueTo] = useState('');
    const [localValueFrom, setLocalValueFrom] = useState('');
    const [value, setValue] = useState({
        "from": "",
        "to": "",
        "operator": "e"
    });
    //debouce value
    const debouceValue = useDebounce(value, 500);

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValueTo('')
            setLocalValueFrom('')
            setLocalOperator(defaultOperator)
        }
    }, [selected.resetFilters, defaultOperator]);

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("search"))
            //console.log(searchArr[0])
            if (searchArr && Object.values(searchArr).length) {
                //console.log(searchArr[0])
                let LGE = searchArr[0].filter(element => element.includes(`${field.Field}§LGE`))[0];
                //console.log(LGE)
                if (LGE) {
                    let string = LGE.split("§")
                    let values = string[1].replace("LGE", '').slice(1, -1)
                    let array = values.split(",")
                    if (array[0]) {
                        setLocalOperator(array[0])
                    }
                    if (array[1]) {
                        setLocalValueFrom(array[1])
                    }
                    if (array[2]) {
                        setLocalValueTo(array[2])
                    }
                    updateSelected(array[1], array[2], array[0]);
                }
            }
        }
    }, [field]);

    useEffect(() => {
        //console.log(selected.parametricFilters)
        if (!selected.parametricFilters) {
            console.log('reset')
        }
    }, [selected])

    const updateSelected = useCallback((values) => {
        let convertedValues = ''
        //let comparison = operator
        let tolerance = 0.0
        if (values?.from) {
            //values = parseInt(from)
            convertedValues = values.from
            if (values?.to && values?.operator === "e") {
                //comparison = operator
                tolerance = parseInt(values.to)
                //tolerance =  to
            }
        }

        let result = {
            Field: field.Field,
            Values: [convertedValues],
            Comparison: values.operator,
            Tolerance: tolerance,
            Name: field.Name,
            DIN: field.Ref,
            Toggle: true
        }

        if (Object.values(result.Values).length > 0) {
            dispatchSelected({
                type: UPDATE_PARAMETRIC_FILTERS,
                payload: result
            });
        }
    }, [dispatchSelected, field.Field, field.Name, field.Ref]);

    const handleChangeFrom = (e) => {
        const numericValue = e.target.value.replace(/[^0-9.]/g, '');
        setLocalValueFrom(numericValue)
    }

    const handleChangeTo = (e) => {
        const numericValue = e.target.value.replace(/[^0-9.]/g, '');
        setLocalValueTo(numericValue)
    }

    const handleChange = (e, value) => {
        //console.log(value)
        if (value) {
            //updateSelected(localValueFrom, localValueTo, value)
            setLocalOperator(value)
        }
    }

    useEffect(() => {
        //console.log(debouceValue)
        setValue({
            "from": localValueFrom,
            "to": localValueTo,
            "operator": localOperator
        })
    }, [localValueFrom, localValueTo, localOperator])

    useEffect(() => {
        //console.log(debouceValue)
        if (debouceValue) {
            updateSelected(debouceValue)
        }
    }, [debouceValue])


    return (
        <>
            <InputLables filtersView={filtersView} field={field} />
            <Grid item xs={12} container spacing={1} xl={filtersView ? 12 : 6}>
                <Grid item xs={12} sm={3} >
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        value={localOperator}
                        exclusive
                        onChange={(e, value) => handleChange(e, value)}
                        sx={{ 
                            height: '100%', 
                            backgroundColor: theme => theme.palette.background.paper,
                            color: theme => theme.palette.text.primary,
                        }}
                        size="small"
                    >
                        <ToggleButton value="l">≤</ToggleButton>
                        <ToggleButton value="e">=</ToggleButton>
                        <ToggleButton value="g">≥</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid container item xs={12} sm={9} spacing={1}>
                    <Grid item xs>
                        <TextField
                            autoComplete='off'
                            id="float-from"
                            label={''}
                            InputLabelProps={{ shrink: false }}
                            value={localValueFrom}
                            onChange={handleChangeFrom}
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{
                                width: '100%',
                                '& input': { zIndex: 2 },
                                '& legend': { display: 'none' },
                                '& fieldset': {
                                    backgroundColor: theme => theme.palette.background.paper,
                                    color: theme => theme.palette.text.primary,
                                }
                            }}
                            size="small"
                            placeholder={field.Unit || null}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    inputMode: 'numeric'
                                }
                            }}

                        />
                    </Grid>
                    {localOperator == "e" ?
                        <>
                            <Grid item xs={12} sm={'auto'} sx={{ width: '20px', display: 'flex', textAlign: 'center' }} alignItems="center" >
                                <Typography xs={{ width: '100%', color: 'text.primary' }}>±</Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    autoComplete='off'
                                    id="float-to"
                                    label={''}
                                    InputLabelProps={{ shrink: false }}
                                    value={localValueTo}
                                    onChange={handleChangeTo}
                                    type="number"
                                    inputProps={{
                                        inputMode: 'decimal',
                                        pattern: '[0-9]*',
                                        min: 0,
                                    }}
                                    sx={{
                                        width: '100%',
                                        '& input': { zIndex: 2 },
                                        '& legend': { display: 'none' },
                                        '& fieldset': {
                                            backgroundColor: theme => theme.palette.background.paper,
                                            color: theme => theme.palette.text.primary,
                                        }
                                    }}
                                    size="small"
                                    placeholder="tolerance"
                                />
                            </Grid>
                        </>
                        : null}
                </Grid>
            </Grid>
        </>
    );
};

export default Float;
