import React, { useContext } from 'react'
import { Avatar, Box, Grid, TableBody, TableCell, TableHead, TableRow, Table, Alert, Typography } from "@mui/material"
import setLanguageText from "../../language/setLanguageText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { LayoutContext } from '../../store/LayoutContext'
import { CompanyContext } from '../../store/CompanyContext'

const UserTableSubComponent = ({ permissions, details, roles }) => {

    const { app } = useContext(LayoutContext)
    const { companies } = useContext(CompanyContext);
    // console.log(roles)
    // console.log(permissions)
    // console.log(app.menu)

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.table.header,
                padding: '1em'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {permissions.administrator ?
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {details.name} {details.surname ? details.surname : ''} is an <strong>Administrator</strong> and has access to all data across the whole application.
                        </Alert>
                        : null}
                    {permissions.access.all_companies || permissions.administrator ?
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            {details.name} {details.surname ? details.surname : ''} has access to <strong>all companies</strong>.
                        </Alert>
                        : null}
                    {!permissions.access.all_companies && permissions.access.companies && companies && !permissions.administrator ?
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {details.name} {details.surname ? details.surname : ''} has access to these companies.
                            <ul
                                style={{ padding: '0 12px' }}
                            >
                                {permissions.access.companies.map(id => {
                                    let found = companies.find(item => item.company_id === id)
                                    return (
                                        <li>{found?.company_name}</li>
                                    )
                                }
                                )}
                            </ul>
                        </Alert>
                        : null}
                </Grid>
                <Grid item xs={12}>
                    <Table
                        size="small"
                        stickyHeader
                        sx={{
                            width: '100%',
                            backgroundColor: (theme) => theme.palette.table.rows,
                            border: (theme) => `1px solid ${theme.palette.table.border}`,
                            borderBottom: 'none'
                        }}
                    >
                        <TableHead
                            sx={{
                                background: (theme) => theme.palette.table.header
                            }}
                        >
                            <TableRow >
                                <TableCell sx={{ background: (theme) => theme.palette.table.header }}>
                                    {setLanguageText("Area")}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderLeft: (theme) => `1px solid ${theme.palette.table.border}`,
                                        background: (theme) => theme.palette.table.header
                                    }}
                                >
                                    {setLanguageText("Access")}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderLeft: (theme) => `1px solid ${theme.palette.table.border}`,
                                        background: (theme) => theme.palette.table.header
                                    }}
                                >
                                    {setLanguageText("User Type")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                app.menu.map((item) => {

                                    //find maching permission
                                   
                                    let row = Object.entries(permissions).find(permission => permission[0] === item.area)
                                    //console.log(row)
                                    if (!item.area || item.area === 'home' || item.area === 'settings') {
                                        return
                                    }
                                    return (
                                        <TableRow sx={{
                                            background: (theme) => row[1].access === 1 ? theme.palette.table.row : row[1]?.access === 2 ? theme.palette.table.row : theme.palette.table.disabled
                                        }} >
                                            <TableCell >
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                                                            <FontAwesomeIcon size="xs" icon={item.icon} />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item>
                                                        {setLanguageText(item.title)}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderLeft: (theme) => `1px solid ${theme.palette.table.border}`
                                                }}
                                            >
                                                <Box sx={{ '& > svg': { mr: 2, flexShrink: 0 } }} key={row[1]?.access}>

                                                    {row[1]?.access === 1 ?
                                                        <>
                                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                                                            Full Access
                                                        </>
                                                        : row[1]?.access === 2 ?
                                                            <>
                                                                <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />
                                                                Preview
                                                            </>
                                                            : row[1]?.access === 3 ?
                                                                <>
                                                                    <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                                                                    No Access
                                                                </>
                                                                : null}
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderLeft: (theme) => `1px solid ${theme.palette.table.border}`
                                                }}
                                            >
                                                {roles.find(item => item.role_id === row[1]?.role)?.role_name}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Box>
    )
}

export default UserTableSubComponent    