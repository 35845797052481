import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { UserContext } from '../store/UserContext';
import { LayoutContext } from '../store/LayoutContext';
import { UPDATE_MENU } from '../store/LayoutReducers';
import { AUTH_USER, RESET_USER } from '../store/UserReducers';
import DarkMode from '../layout/DarkMode';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import useActivityLog from '../users/activity/useActivityLog';
import PermissionTesting from '../users/PermissionTesting';
import { faUser, faHeart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ListItemText } from '@mui/material';
import { RESET_COMPANIES, RESET_SELECTED_COMPANY } from '../store/CompanyReducers';
import { CompanyContext } from '../store/CompanyContext';
import { CLEAR_BASKET } from '../store/BasketReducers';
import { BasketContext } from '../store/BasketContext';
import { SelectedContext } from '../store/SelectedContext';
import { RESET_ALL_FILTERS } from '../store/SelectedReducers';
import packageJson from '../../package.json';
import Loader from '../reusable/Loader';
import LanguageSelect from '../language/LanguageSelect';
import setLanguageText from '../language/setLanguageText';
import { isMobileOnly } from 'react-device-detect';


const AccountMenu = () => {

    const { user, dispatchUser } = useContext(UserContext)
    const { dispatchApp } = useContext(LayoutContext)
    const { dispatchCompanies, dispatchSelectedCompany } = useContext(CompanyContext)
    const { dispatchBasket } = useContext(BasketContext)
    const { dispatchSelected } = useContext(SelectedContext)
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const { updateActivityLog, loggingResponse } = useActivityLog();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        if (loggingResponse) {
            //reset context
            dispatchUser({
                type: RESET_USER
            });
            dispatchCompanies({
                type: RESET_COMPANIES
            });
            dispatchSelectedCompany({
                type: RESET_SELECTED_COMPANY
            })
            dispatchBasket({
                type: CLEAR_BASKET
            })
            dispatchSelected({
                type: RESET_ALL_FILTERS
            })
            dispatchApp({
                type: UPDATE_MENU,
                payload: {
                    selectedArea: '',
                    selectedMenu: ''
                }
            })
            //clear all including the version
            localStorage.clear();
            sessionStorage.clear();
            Auth.signOut(); //amplify signout 
            //console.log("Session cleared");
            if ('caches' in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });
                // Makes sure the page reloads. Changes are only visible after you refresh.
                //window.location.reload(true);
            }

            localStorage.setItem('version', packageJson.version);
            navigate("/");
        }
        //console.log(loggingResponse)

    }, [loggingResponse])

    const logMeOut = (e) => {
        setLoading(true)
        updateActivityLog.mutate({
            activity_details: {
                area: "factory",
            },
            activity_type: "logout"
        })
    };

    return (
        <>
            {loading ?
                <Loader
                    isLoading={true}
                />
                : null}
            <Grid item >
                {/* 
                <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                <Typography sx={{ minWidth: 100 }}>Profile</Typography> 
                */}
                <Tooltip title="Account Menu">
                    <IconButton
                        color="inherit"
                        //edge="start"
                        onClick={handleClick}
                        //size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FontAwesomeIcon icon={faUser} />
                        {/* <Avatar sx={{ background: theme => theme.palette.primary.main }} /> */}
                    </IconButton>
                </Tooltip>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: isMobileOnly ? '-20px' : 1.5,
                            width: isMobileOnly ? '100%' : "auto",
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: isMobileOnly ? 'none' : '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ 
                    horizontal:'right', 
                    vertical:  isMobileOnly  ? 'bottom' : 'top' 
                }}
                anchorOrigin={{ 
                    horizontal: 'right', 
                    vertical:  isMobileOnly  ? 'top' :   'bottom' 
                }}
            >
                <Box
                    sx={{
                        mb: 1.5,
                        mx: 2.5,
                    }}
                >
                    <ListItemText><strong>Account Menu</strong></ListItemText>
                </Box>
                <Divider sx={{ marginBottom: '10px' }} />
                <MenuItem
                    onClick={e => {
                        navigate('/settings/account')
                        //console.log(area,menu)
                        dispatchApp({
                            type: UPDATE_MENU,
                            payload: {
                                selectedArea: "settings",
                                selectedMenu: "account"
                            }
                        })
                        handleClose()
                    }}
                >
                    <Avatar
                        sx={{ bgcolor: 'primary.main' }}
                    >
                        <FontAwesomeIcon icon={faUser} size="xs" />
                    </Avatar>
                    {setLanguageText("My Details")}
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        navigate('/settings/account?tab=3')
                        //console.log(area,menu)
                        dispatchApp({
                            type: UPDATE_MENU,
                            payload: {
                                selectedArea: "settings",
                                selectedMenu: "account"
                            }
                        })
                        handleClose()
                    }}
                >
                    <Avatar
                        sx={{ bgcolor: 'primary.main' }}
                    >
                        <FontAwesomeIcon icon={faHeart} size="xs" />
                    </Avatar>
                    {setLanguageText("My Favourites")}
                </MenuItem>
                {user?.user?.user_permissions?.administrator && <PermissionTesting handleClose={handleClose} />}
                <Divider />
                <MenuItem >
                    <DarkMode />
                </MenuItem>
                <MenuItem>
                    <LanguageSelect />
                </MenuItem>
                <MenuItem
                    onClick={logMeOut}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}
export default AccountMenu;