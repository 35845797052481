import React, { useContext, useEffect, useState, } from "react";
import { Box, Grow, Typography } from "@mui/material";
import { ReactComponent as ResourceSVG } from './svg/resource.svg';
import { UserContext } from "../../store/UserContext";
import { useNavigate } from "react-router-dom";
import GlobalDialog from "../../reusable/GlobalDialog";
import AreaDetails from "../../reusable/AreaDetails";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_MENU } from "../../store/LayoutReducers";
import getAccessID from "../../helpers/getAccessID";

const Resources = ({ svgprops, textprops, setHoveredBoxIndex, boxIndex, hoveredBoxIndex, areaIndex, noClick }) => {

    const { user } = useContext(UserContext);
    const { app, dispatchApp } = useContext(LayoutContext)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [area, setArea] = useState()

    useEffect(() => {
        if (app.menu) {
            let menuItem = app.menu.find(app => app.area === "resources")
            if (menuItem) setArea(menuItem)
        }

    }, [app.menu])

    const closeModal = () => {
        setOpen(false);
    };

    const handleSubmit = (area) => {
        setOpen(false);
        dispatchApp({
            type: UPDATE_MENU,
            payload: {
                selectedArea: area.area,
                selectedMenu: area.menu
            }
        })
        if (area?.link) {
            navigate(area?.link)
        } else if (area?.externalLink) {
            window.open(menareaItem.externalLink, '_blank')
        }
    };


    return (
        <>
            <Grow
                in={!areaIndex ? true : areaIndex <= boxIndex}
                timeout={1000}
                onMouseEnter={() => setHoveredBoxIndex ? setHoveredBoxIndex(areaIndex) : null}
                onMouseLeave={() => setHoveredBoxIndex ? setHoveredBoxIndex(-1) : null}
            >
                <Box
                    sx={{
                        ...svgprops,
                        '& .B': {
                            fill: theme => process.env.REACT_APP_CONFIG_PATH === "act" ? theme.palette.factory.third_main : theme.palette.factory.primary_main
                        },
                        '& .C': {
                            fill: theme => process.env.REACT_APP_CONFIG_PATH === "act" ? theme.palette.factory.third_highlight : theme.palette.factory.primary_highlight
                        },
                        '& .D': {
                            fill: theme => process.env.REACT_APP_CONFIG_PATH === "act" ? theme.palette.factory.third_shadow : theme.palette.factory.primary_shadow
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'grid',
                            width: '100%',
                            height: '100%',
                            transition: "opacity 0.3s",
                            opacity: getAccessID(user?.user?.user_permissions?.resources?.access) === 1 ? 1 : 0.1,
                            '&.greyed-out ': {
                                opacity: 0.3
                            }
                        }}
                        className={hoveredBoxIndex !== -1 && hoveredBoxIndex !== areaIndex ? 'greyed-out' : ''}
                    >
                        <Box
                            //className="hover-box"
                            onMouseEnter={() => setHoveredBoxIndex ? setHoveredBoxIndex(areaIndex) : null}
                            onClick={() => noClick ? null :setOpen(true)}
                            onMouseLeave={() => setHoveredBoxIndex ? setHoveredBoxIndex(-1) : null}
                            sx={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                //background: 'rgba(0,0,0,0.3)',
                                clipPath: "polygon(14% 75%, 14% 8%, 30% 0%, 74% 26%, 75% 93%, 60% 100%)"
                            }} />
                        <ResourceSVG />
                        <Typography
                            sx={{
                                color: "#fff",
                                transform: 'skew(360deg, 30deg)',
                                textTransform: 'uppercase',
                                position: 'absolute',
                                ...textprops
                            }}
                        >{area?.title}</Typography>
                    </Box>
                </Box>
            </Grow>

            <GlobalDialog
                open={open}
                onClose={closeModal}
                title={area?.title}
                buttonTitle={`Visit ${area?.title}`}
                buttonClick={() => handleSubmit(area)}
                maxWidth="md"
            //disabled={true}
            >
                <AreaDetails area={area} />
            </GlobalDialog>
        </>
    );
};


export default Resources;
