import {
    Alert,
    Grid,
} from '@mui/material';
import React from 'react';

const AccessAlert = ({ id }) => {

    return (
     <>
            {id === 1 ?
                <Grid item xs={12}>
                    <Alert sx={{ width: '100%' }} severity='success' >User has access to this area, with permission selected.</Alert>
                </Grid>
                : id === 2 ?
                    <Grid item xs={12}>
                        <Alert sx={{ width: '100%' }} severity='warning' >User can only preview this area.</Alert>
                    </Grid>
                    : id === 3 ?
                        <Grid item xs={12}>
                            <Alert sx={{ width: '100%' }} severity='error' >No Access to this area.</Alert>
                        </Grid>
                        : null}

        </>
    );
}
export default AccessAlert;