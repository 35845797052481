import React, { useContext, useEffect, useState } from 'react';
import { Typography, List, ListItemText, Link, Skeleton, Tooltip, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LayoutContext } from '../../store/LayoutContext';
import config from '../../config';

const ProductFullList = ({ product, visible, attributes, isFetching, erpDetails }) => {

	const { app } = useContext(LayoutContext)
	const navigate = useNavigate();
	const [standard, setStandard] = useState([])

	useEffect(() => {
		if (attributes && visible) {
			let rows = attributes.filter((attribute) => {
				let found = visible.find((field) => field.Ref === attribute.code && field.checked === true)
				if (found) {
					return attribute
				}
			})
			setStandard(rows)
		}
	}, [attributes, visible])


	return (
		<>
			{isFetching && !rows ?
				<>
					<Skeleton width="100%" height={50} sx={{ marginBottom: 0 }} />
					<Skeleton width="100%" sx={{ marginBottom: 0 }} />
					<Skeleton width="100%" sx={{ marginBottom: 0 }} />
					<Skeleton width="50px" sx={{ marginBottom: 0 }} />
				</>
				:
				<Grid item>
					<List dense >
						{product?.product_id &&
							<ListItemText
								primary={
									<Typography variant="subtitle2" ><strong>{config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No"}</strong> : {product.product_id}</Typography>
								}
							/>
						}
						{product?.product_supplier_number &&
							<ListItemText
								primary={
									<Typography variant="subtitle2" ><strong>Manufacturer No (J21)</strong> : {product.product_supplier_number}</Typography>
								}
							/>
						}
						{product?.product_description &&
							<ListItemText
								primary={
									<Typography variant="subtitle2" ><strong>Description</strong> : {product.product_description}</Typography>
								}
							/>
						}
						{standard && Object.values(standard).length ?
							standard.map((attribute, i) => {
								//console.log(attribute)
								if (attribute.code === "J21") {
									return
								}
								return (
									<ListItemText
										key={i}
										primary={
											<Typography variant="subtitle2" ><strong>{attribute.name}</strong> : {attribute.value} <small> {attribute.unit ? attribute.unit : ''}</small></Typography>
										}
									/>
								)
							})
							: null}
						{product?.product_unit &&
							<ListItemText
								primary={
									<Typography variant="subtitle2" ><strong>Pack Size</strong> : {product?.product_unit}</Typography>
								}
							/>
						}
					</List>
					{attributes && attributes.length > 2 ?
						<Tooltip title="View Full Product">
							<Link
								sx={{ cursor: 'pointer' }}
								variant="button"
								underline="hover"
								onClick={e => {

									let url = `/${app.selectedArea}/${app.selectedMenu}/product/${product.product_id}?tab=1`;
									if (app.selectedMenu === "account") {
										if (product.product_dataset === "ezbase") {
											url = `/buyers/catalogue/product/${product.product_group}?tab=1`;
										} else if (product.product_dataset === "cim") {
											url = `/engineers/catalogue/product/${product.product_id}?tab=1`;
										}
									}
									navigate(url)
								}}
							>
								+ {attributes.length - standard.length} More...

							</Link>
						</Tooltip>
						:
						null
					}
				</Grid>
			}
		</>
	);
}
export default ProductFullList;