import {
  Chip,
  Grid,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KPIMenu from './KPIMenu';

const KPIRow = ({ kpi, refetchKPIs, groupOptions, areaOptions }) => {

  const [prefix, setPrefix] = useState();
  const [chart, setChart] = useState();
  const [locked, setLocked] = useState();
  const [global, setGlobal] = useState();

  useEffect(() => {
    //console.log(kpi)
    if (kpi.kpi_settings) {
      const settings = JSON.parse(kpi.kpi_settings)
      setPrefix(settings.prefix)
      setChart(settings.chart)
      setGlobal(settings.global)
      setLocked(settings.locked)
    }
  }, [kpi])

  return (

    <TableRow>
      <TableCell>
        {kpi.kpi_id}
      </TableCell>
      <TableCell>
        {kpi.kpi_name}
      </TableCell>
      <TableCell>
        {kpi.kpi_description}
      </TableCell>
      <TableCell>
        {kpi.kpi_area}
      </TableCell>
      <TableCell>
      {kpi?.kpi_tags ?
            <>
              {kpi.kpi_tags.map((tag, index) =>
    
                  <Chip label={tag.name} sx={{ borderRadius: '5px' }} />
     
              )}
            </>
            : null}
      </TableCell>
      <TableCell>
        {kpi.kpi_type}
      </TableCell>
      <TableCell>
        {global}
      </TableCell>
      <TableCell>
        {chart}
      </TableCell>
      <TableCell>
        {locked}
      </TableCell>
      <TableCell>
        {prefix}
      </TableCell>
      <TableCell>
        <KPIMenu kpi={kpi} refetchKPIs={refetchKPIs} groupOptions={groupOptions} areaOptions={areaOptions} text />
      </TableCell>
    </TableRow>
  );
}

export default KPIRow;
