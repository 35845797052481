import React, { useReducer } from 'react';
import { AdditiveReducer } from './AdditiveReducers';

export const AdditiveContext = React.createContext();

export const AdditiveProvider = ({ children }) => {
    const [additiveOptions, dispatchAdditiveOptions] = useReducer(AdditiveReducer, {
        inUse: true,
        toggleSort: 'most_used',
        toggleView: 'grid'
    });
    const [additives, dispatchAdditives] = useReducer(AdditiveReducer, []);

    const [additivesInUse, dispatchAdditivesInUse] = useReducer(
        AdditiveReducer,
        []
    );
    const [additivesCompare, dispatchAdditivesCompare] = useReducer(
        AdditiveReducer,
        []
    );
    const [selectedAdditive, dispatchSelectedAdditive] = useReducer(
        AdditiveReducer
    );
    const contextValues = {
        additiveOptions,
        dispatchAdditiveOptions,
        additives,
        dispatchAdditives,
        additivesInUse,
        dispatchAdditivesInUse,
        additivesCompare,
        dispatchAdditivesCompare,
        selectedAdditive,
        dispatchSelectedAdditive
    };
    return (
        <AdditiveContext.Provider value={contextValues}>
            {children}
        </AdditiveContext.Provider>
    );
};
