import React, { useReducer, createContext } from 'react';
import {
  AddNewMachineReducer,
  AddNewEntryReducer,
  SelectionsReducer
} from './SelectionsReducers';

export const SelectionsContext = createContext();

export const SelectionsProvider = ({ children }) => {
  const [groups, dispatchGroups] = useReducer(SelectionsReducer, []);
  const [machines, dispatchMachines] = useReducer(SelectionsReducer, []);
  const [addNewMachine, dispatchAddNewMachine] = useReducer(
    AddNewMachineReducer,
    {
      company: null,
      machine_name: '',
      machine_group: '',
      group: null
    }
  );
  const [addNewEntry, dispatchAddNewEntry] = useReducer(AddNewEntryReducer, {
    company: null,
    machine: null,
    entry: null,
    qr_codes: []
  });

  const contextValues = {
    groups,
    dispatchGroups,
    machines,
    dispatchMachines,
    addNewMachine,
    dispatchAddNewMachine,
    addNewEntry,
    dispatchAddNewEntry
  };

  return (
    <SelectionsContext.Provider value={contextValues}>
      {children}
    </SelectionsContext.Provider>
  );
};

// entry: {
//       entry_id: null,
//       date_stamp: null,
//       user_id: null,
//       coolant_id: null,
//       attributes_new: [
//         {
//           attribute_id: null,
//           attribute_values: {
//             max: null,
//             min: null,
//             value: null,
//             output: null,
//             status: null
//           }
//         }
//       ],
//       mobile: null,
//       notes: null,
//       note_action: null,
//       additives: null,
//       out_of_control: 0,
//       factor: null,
//       company_name: null,
//       initial: null
//     }
