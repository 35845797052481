function convertFiltersForAPI(parametricFilters) {

    let filters = structuredClone(parametricFilters);
    //console.log(filters)

    //remove filters that are not toggled
    filters = filters.filter((filter) => filter.Toggle).filter((filter) => filter.Comparison !== "")

    filters.map(filter => {
        //console.log(filter.Values)
        if(filter.Values && Object.values(filter.Values).length > 1) {
            filter.Values = filter.Values.map((value) => encodeURI(value))
        } else if (filter.Values && Object.values(filter.Values).length === 1) {
            filter.Values = [encodeURI(filter.Values)] //stringify or the API won't work
        }
        if (filter.Field === "Volltext") {
            filter.Values = [encodeURI(filter.Values)] //stringify or the API won't work
        }
        
        if (filter.Tolerance === "") {
            filter.Tolerance = 0.0
        }
        delete filter.Name
        delete filter.ValueNames
        delete filter.Unit
        delete filter.Ref
        delete filter.Type
        delete filter.Position
        delete filter.Toggle
        return filter
    })

    // console.log(JSON.stringify(filters))
    // console.log(encodeURI(JSON.stringify(filters)))

    return filters

}

export default convertFiltersForAPI