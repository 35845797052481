import React, { useContext, useEffect, useState } from "react";
import { Avatar, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Dialog, TextField, Typography, DialogTitle, Tooltip, Chip, Grid } from '@mui/material';
import { faCoins, faImageSlash, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "react-query";
import { BasketContext } from "../store/BasketContext";
import config from "../config";
import axiosRequest from '../axios/axoisRequest';
import { UPDATE_BASKET } from "../store/BasketReducers";
import { UserContext } from "../store/UserContext";
import { LayoutContext } from "../store/LayoutContext";
import { useNavigate } from "react-router-dom";
import { UPDATE_MENU } from "../store/LayoutReducers";
import { CompanyContext } from "../store/CompanyContext";
import { updateBasketTotals } from "./helpers/updateBasketTotals";
import { trackEvent } from "../google_analytics/ga";

const BasketItem = ({ product, setOpen }) => {

	const navigate = useNavigate();
	const { basket, dispatchBasket } = useContext(BasketContext);
	const { selectedCompany } = useContext(CompanyContext);
	const { dispatchApp } = useContext(LayoutContext);
	const { user } = useContext(UserContext);
	const [placeholder, setPlaceholder] = useState('');
	import(`../images/placeholder.jpg`).then((module) => {
		setPlaceholder(module.default);
	});

	const mutation = useMutation((basket) => {
		return axiosRequest({
			gateway: config.shopAPI.URL,
			endpoint: 'basket',
			api_key: user.api_key,
			method: 'patch',
			body: {
				"basket_id": basket.basket_id,
				"basket_company": selectedCompany.company_id,
				'basket_name': basket.basket_name,
				'basket_items': basket.items
			}
		})
	})

	//console.log(product)

	const removeProduct = () => {

		let currentItems = [...basket.items];
		//Check to see if product exisits in basket
		let index = currentItems.findIndex((item) => item.product_id === product.product_id);
		if (index > -1) {
			//update qyt
			currentItems.splice(index, 1)
		}
		//console.log(currentItems)
		sessionStorage.setItem("basket_id", basket.basket_id);
		//sessionStorage.setItem("basketProduct", JSON.stringify(currentItems));
		//Set basket state
		dispatchBasket({
			type: UPDATE_BASKET,
			payload: {
				"items": currentItems,
			}
		});
		mutation.mutate({ ...basket, items: currentItems })
		updateBasketTotals(currentItems, dispatchBasket);

		trackEvent({
			action: 'remove_from_cart',
			params: {
				"currency": "GBP",
				"value": product.price_incl_discount,
				"items": [
					{
						"item_id": product.product_id,
						"item_name": product.product_description,
						"product_number": product.product_number,
						"product_supplier": product.product_supplier
					}
				]
			}
		})
	}

	const updateQuantity = (qty, unit) => {

		//check if qty is a mutipule of unit
		if (unit) {
			if (qty % unit !== 0) {
				//set qty to nearest mutiple of unit
				qty = Math.ceil(qty * unit)
			}
		}

		let currentItems = [...basket.items];
		//Check to see if product exisits in basket
		let index = currentItems.findIndex((item) => item.product_id === product.product_id);
		if (index > -1) {
			//update qyt
			currentItems[index].product_quantity = qty
		}
		//console.log(currentItems)
		sessionStorage.setItem("basket_id", basket.basket_id);
		//sessionStorage.setItem("basketProduct", JSON.stringify(currentItems));
		//Set basket state
		dispatchBasket({
			type: UPDATE_BASKET,
			payload: {
				"items": currentItems,
			}
		});
		mutation.mutate({ ...basket, items: currentItems })
		updateBasketTotals(currentItems, dispatchBasket);
	}

	return (
		<>
			<ListItem
				divider
				secondaryAction={
					<Tooltip title="Remove Product">
						<IconButton
							edge="end" aria-label="remove all"
							onClick={removeProduct}
						>
							<FontAwesomeIcon size="xs" icon={faTrash} />
						</IconButton>
					</Tooltip>
				}
			>
				<ListItemAvatar>
					<Avatar
						//onClick={handleOpen}
						onClick={() => {
							// if (product.product_area === 'shop') {
							// 	navigate(`/engineers/catalogue/product/${product.product_id}?tab=1`)
							// 	dispatchApp({
							// 		type: UPDATE_MENU,
							// 		payload: {
							// 			selectedArea: 'engineers',
							// 			selectedMenu: 'catalogue'
							// 		}
							// 	})
							// }
							// if (product.product_area === 'buyers') {
							// 	navigate(`/buyers/catalogue/product/${product.product_id}?tab=1`)
							// 	dispatchApp({
							// 		type: UPDATE_MENU,
							// 		payload: {
							// 			selectedArea: 'buyers',
							// 			selectedMenu: 'catalogue'
							// 		}
							// 	})
							// }
							if (product.product_area === 'opensearch') {
								navigate(`/search/search/product/${product.product_id}?tab=1`)
								dispatchApp({
									type: UPDATE_MENU,
									payload: {
										selectedArea: 'buyers',
										selectedMenu: 'catalogue'
									}
								})

							}
							setOpen(false)
						}}
						sx={{ background: '#fff', cursor: 'pointer', boxShadow: '0 0 3px 1px #e2e2e2' }}>
						{product?.product_image ?
							<img
								src={product?.product_image ? product.product_image?.[0]?.url : placeholder}
								onError={e => {
									e.target.src = placeholder;
								}}
								style={{ width: '100%', height: '100%', objectFit: 'contain' }}
							/>
							: product.product_area === "tokens" ?
								<FontAwesomeIcon color="#222" icon={faCoins} />
								: <FontAwesomeIcon color="#222" icon={faImageSlash} />
						}
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<Typography variant="body1" fontSize="18px" lineHeight="1.4"><strong>{product.product_number}</strong></Typography>
					}
					secondary={
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<Typography variant="subtitle2" ><strong>{config.APPNAME === "act" ? "ACT No" : config.APPNAME === "cis" ? "CIS No" : "ERP No"}</strong> : {product.product_id}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle2" ><strong>Manufacturer No (J21)</strong> : {product.product_supplier_number}</Typography>
							</Grid>
							{product?.price_rrp !== product?.price_incl_discount &&
								<Grid item xs={12}
									sx={{
										marginTop: '5px'
									}}>
									<Typography variant="p" component="span" sx={{
										textDecoration: 'line-through',
										fontSize: '14px',
										color: '#777'
									}} >
										£{(product.product_quantity * product.price_rrp).toFixed(2)}
										<Chip
											sx={{
												marginLeft: '5px'
											}}
											size="small"
											color="success"
											label={`Save ${product?.price_discount ? `${product.price_discount}%` : `£${((product.price_rrp - product.price_incl_discount) * product.product_quantity).toFixed(2)}`}`}
										//label={`Save £${((product.price_rrp - product.price_incl_discount) * product.product_quantity).toFixed(2)}`}
										/>
									</Typography>
								</Grid>
							}
							<Grid item xs={12}>
								{product.price_incl_discount ?
									<Typography variant="body1" fontSize="14px"  >Total: <Typography fontWeight={700} component="span" sx={{ color: theme => theme.palette.success.main }}>£{(product.product_quantity * product.price_incl_discount).toFixed(2)}</Typography></Typography>
									:
									<Typography variant="body1" fontSize="14px"  ><strong>Total: £POA</strong></Typography>
								}
							</Grid>

							<Grid item xs={12}>
								<Typography color="text.secondary" variant="subtitle2" >
									<TextField
										disabled={product.product_limit && product.product_limit === product.product_quantity ? true : false}
										id="outlined-start-adornment"
										sx={{
											width: '180px',
											padding: '0px'
										}}
										value={product.product_quantity}
										variant="standard"
										// onBlur={(e) => {
										// 	updateQuantity(e.target.value, product.product_unit)
										// }}
										onChange={e => {
											updateQuantity(e.target.value, product.product_unit)
										}}
										type="number"
										size="small"
										inputProps={{
											step: product.product_unit ? product.product_unit : 1,
											min: product.product_unit ? product.product_unit : 1,
											padding: 0
										}}
										InputProps={{
											startAdornment: <InputAdornment position="start"><small>Qty:</small></InputAdornment>,
											endAdornment:
												product.price_incl_discount ?
													<InputAdornment position="end"><small>@ £{product.price_incl_discount.toFixed(2)} each</small></InputAdornment>
													:
													<InputAdornment position="end"><small>@ £POA</small></InputAdornment>
										}}
									/>
								</Typography>
							</Grid>
						</Grid>
					}
				/>
			</ListItem>
		</>
	);
}


export default BasketItem;