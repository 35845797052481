
import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from "yup"
import { useMutation } from 'react-query';
import { UserContext } from '../store/UserContext';
import setLanguageText from '../language/setLanguageText';
import { LayoutContext } from '../store/LayoutContext';
import LoadingButton from '../coolantcare/buttons/LoadingButton';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';

const UserDetails = () => {

  const { user } = useContext(UserContext)
  const { language } = useContext(LayoutContext);
  const [currentUser, setCurrentUser] = useState();
  const [cognitoResponse, setCognitoResponse] = useState(null);

  useEffect(() => {

    if (user.user?.user_permissions) {
     //console.log(user)
      const newUser = {
        name: user.user.user_details.name,
        surname: user.user.user_details.surname,
        email: user.user.email,
        username: user.user.username,
        aws_sub: user.aws_sub,
      }
     //console.log(newUser)
      setCurrentUser(newUser)
    }
  }, [user])


  const userValidation = Yup.object({
    name: Yup
      .string('Enter your name')
      .required('Name is required'),
    surname: Yup
      .string('Enter your name')
      .required('Name is required'),
    username: Yup
      .string('Enter your username')
      .required('Username is required')
      .min(1, 'Username must be more than 1 character')
      .max(120, 'Username must be less than 120 character'),
    //.matches(/^[\p{L}\p{M}\p{S}\p{N}\p{P}]+/, 'Username must fit constrainst'), 
    email: Yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    confirm_email: Yup.string()
      .required("You must repeat you email")
      .oneOf([Yup.ref("email"), null], "Emails must match")
  });

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  //save user
  const mutation = useMutation((values) => {

    // return axiosRequest({
    //   gateway: config.shopAPI.URL,
    //   endpoint: 'administrator/user',
    //   api_key: userContext.api_key,
    //   method: 'post',
    //   body: {
    //     update : true,
    //     username: values?.username,
    //     email: values?.email,
    //     aws_sub: values?.aws_sub, //get retruned sub
    //     user_details: { name: values?.name, surname: values?.surname },
    //     user_permissions: {
    //       administrator: values?.administrator,
    //       coolantcare: {
    //         access: values?.coolantcare,
    //         name: values?.c_group?.name,
    //         group: values?.c_group?.group,
    //         level: values?.c_group?.level,
    //         company_id: values?.company_id,
    //         companies: values?.companies.map(company => company.company_id),
    //         department: values?.department
    //       },
    //       ecommerce: {
    //         access: values?.ecommerce,
    //         name: values?.e_group?.name,
    //         group: values?.e_group?.group,
    //         level: values?.e_group?.level
    //       }
    //     },
    //     erp_details: {
    //       id: "ERP001"
    //     }
    //   }
   // })
  }, {
    onSuccess: (data) => {
      setIsLoading(false);
      //closeDialog()
    }
  })

  const handleSubmit = (values) => {
    setIsLoading(true);
    //console.log(values)

    // let userParams = {
    //   UserPoolId: config.cognito.USER_POOL_ID,
    //   Username: values.username,
    //   UserAttributes: [
    //     { Name: 'email', Value: values.email },
    //     { Name: 'email_verified', Value: 'true' }
    //   ]
    // };

    // Auth.currentCredentials().then(async (credentials) => {

    //   const cognito = new AWS.CognitoIdentityServiceProvider({
    //     apiVersion: "2016-04-18",
    //     region: config.cognito.REGION,
    //     credentials: Auth.essentialCredentials(credentials)
    //   })

    //   const updateCognitoUser = new Promise((resolve, reject) => {

    //     //* UPDATE USER
    //     cognito.adminUpdateUserAttributes(userParams, (err, userData) => {

    //       if (err) {
    //         setCognitoResponse({
    //           result: 'error',
    //           message: err.message
    //         });
    //         reject(err);

    //       } else {

    //         const groupParams = {
    //           UserPoolId: config.cognito.USER_POOL_ID,
    //           Username: values.username,
    //         };
    //         // //add or remove from admin group
    //         if (values.administrator) {
    //          //console.log('add to admin group')
    //           cognito.adminAddUserToGroup(
    //             { ...groupParams, GroupName: "Administrator" },
    //             (err, groupData) => {
    //               if (err) {
    //                 setCognitoResponse({
    //                   result: 'error',
    //                   message: err.message
    //                 });
    //                 reject(err);
    //               } else {
    //                 setCognitoResponse({
    //                   result: 'success',
    //                   message: groupData
    //                 });
    //                 resolve(groupData);
    //               }
    //             }
    //           );
    //         } else {
    //          //console.log('remove to admin group')
    //           cognito.adminRemoveUserFromGroup(
    //             { ...groupParams, GroupName: "Administrator" },
    //             (err, groupData) => {
    //               if (err) {
    //                 setCognitoResponse({
    //                   result: 'error',
    //                   message: err.message
    //                 });
    //                 reject(err);
    //               } else {
    //                 setCognitoResponse({
    //                   result: 'success',
    //                   message: groupData
    //                 });
    //                 resolve(groupData);
    //               }
    //             }
    //           );
    //         }
    //       }
    //     });
    //   });

    //   try {
    //     const aws = await updateCognitoUser;
    //     //console.log(values)
    //     if (values) {
    //       mutation.mutate(values)
    //     }
    //   } catch (err) {
    //  //console.log('error', err.message)
    //     setIsLoading(false)
    //     setError({
    //       message: err.message
    //     });
    //   }
    // });
  }

  return (
    <>
     <TabHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText("My Details")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </TabHeader>
      <TabContent>
       {currentUser ? 
        <Formik
          initialValues={currentUser}
          validationSchema={userValidation}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleChange, submitForm }) => 
              <Grid container spacing={2}>
            <Grid item xs={6}>
               <TextField
                  disabled={true}

                   id="name"
                   name="name"
                   label="Name"
                   value={values?.name}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                   error={touched.name && Boolean(errors.name)}
                   helperText={touched.name && errors.name}
               />
           </Grid>
           <Grid item xs={6}>
               <TextField
                  disabled={true}

                   id="surname"
                   name="surname"
                   label="Surname"
                   value={values?.surname}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                   error={touched.surname && Boolean(errors.surname)}
                   helperText={touched.surname && errors.surname}
               />
           </Grid>
           <Grid item xs={12}>
               <TextField
                   id="username"
                   name="username"
                   label="Username"
                   value={values?.username}
                   onChange={handleChange}
                   fullWidth
                   disabled={true}
                   variant="outlined"
               />
           </Grid>
           <Grid item xs={12}>
               <TextField
                   id="aws_sub"
                   name="aws_sub"
                   label="User ID"
                   value={values?.aws_sub}
                   //onChange={handleChange}
                   fullWidth
                   disabled={true}
                   variant="outlined"
               />
           </Grid>
           <Grid item xs={12}>
               <TextField
                  disabled={true}

                   id="email"
                   name="email"
                   label="Email"
                   value={values?.email}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                   error={touched.email && Boolean(errors.email)}
                   helperText={touched.email && errors.email}
               />
           </Grid>
           <Grid item container xs={12}>
              <Grid item>
                <LoadingButton
                  disabled={true}
                  icon={faSave}
                  text="Update My Details"
                  iconSize="1x"
                  color="primary"
                  //loading={loading}
                  //onClick={e => handleSubmit(e)}
                />
              </Grid>
            </Grid>
           </Grid>
          }
        </Formik>
        : null }
        </TabContent>
    </>
  );
};

export default UserDetails;

