
import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button } from '@mui/material';
import { Alert } from '@mui/material';
import OutlinedDiv from './OutlinedDiv';
import setLanguageText from '../../language/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { faTimes, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
  preview: {
    height: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
    lineHeight: 0
  }
});

const ImageUpload = ({ edit, path, setImage }) => {

  const [imageUrl, setImageUrl] = useState();
  const [imagePreview, setImagePreview] = useState();
  const { language } = useContext(LayoutContext)

  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    //console.log(path)
    if (path) {
      setImageUrl(path);
      setImagePreview();
      ref.current.value = "";
    } else {
      setImageUrl();
      setImagePreview();
      ref.current.value = "";
    }
    return () => {
    }
  }, [path])

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      let file = e.target.files[0]
     //console.log(file);
      setImagePreview(file);
      setImage(file);
    }
  }

  const addDefaultSrc = () => {
    setImageUrl(null)
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        {imageUrl ?
          <img
            className={classes.preview}
            src={imageUrl}
            alt=""
            onError={addDefaultSrc}
            style={{
              //padding: '1em',
              backgroundPosition: '0px 0px, 10px 10px',
              backgroundSize: '20px 20px',
              backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
            }}
          />
          :
          <Alert
            severity="error"
            elevation={1}
            variant="standard"
          >
            {setLanguageText( "No Logo Uploaded")}
          </Alert>
        }
      </Grid>
      {imagePreview &&
        <Grid item xs={12}>
          <OutlinedDiv label={setLanguageText( "Preview")}>
            <img
              className={classes.preview}
              alt=""
              src={URL.createObjectURL(imagePreview)}
              style={{
                //padding: '1em',
                backgroundPosition: '0px 0px, 10px 10px',
                backgroundSize: '20px 20px',
                backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
              }}
            />
          </OutlinedDiv>
        </Grid>
      }
      <Grid item container xs={12} alignItems="center" spacing={1}>
        <Grid item>
          {imagePreview ?
            <Button
              variant="contained"
              color="secondary"
              size='large'
              sx={{ marginRight: '1em' }}
              onClick={() => {
                setImagePreview();
                setImage();
              }}
            > <FontAwesomeIcon
                icon={faTimes}
                size="xl"
              />
            </Button>
            : null
          }
          <Button
            variant="contained"
            component="label"
            disabled={!edit}
          >
            {setLanguageText("Upload File")} 
            <input
              hidden
              ref={ref}
              disabled={!edit}
              type="file"
              accept="image/png,image/jpeg,image/webp"
              onChange={handleChange}
            />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant='caption'>{!imagePreview ? setLanguageText("No File Chosen") : imagePreview.name} </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageUpload;
