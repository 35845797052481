import {
	Alert,
	Box,
	Button,
	DialogActions,
	Grid,
	TextField,
	Typography
} from '@mui/material';
import React, {
	useCallback,
	useMemo,
	useState
} from 'react';
import ActivityTable from './ActivityTable';
import { CSVLink } from 'react-csv';
import GlobalDialog from '../../../../reusable/GlobalDialog';
import setLanguageText from '../../../../language/setLanguageText';
import formatDate from '../../../../coolantcare/helpers/formatDate';

const ActivityDialog = ({ open, setOpen, data, isFetching, dateFrom, dateTo }) => {

	const [csv_data, setCsv_data] = useState([]);

	const columns = useMemo(() => [
		{
			accessorKey: 'user',
			header: () => 'User',
			footer: props => props.column.id,
			cell: info => {
				//console.log(info)
				if (info.row.original?.user) {
					return info.row.original.user.name
				} else {
					return "-"
				}
			}
		}, {
			accessorKey: 'user',
			header: () => 'Email',
			footer: props => props.column.id,
			cell: info => {
				if (info.row.original?.user) {
					return info.row.original.user.email
				} else {
					return "-"
				}
			}
		}, {
			accessorKey: 'type',
			header: () => 'Type',
			footer: props => props.column.id,
			cell: info => info.getValue(),
		}, {
			accessorKey: 'details',
			header: () => 'Details',
			footer: props => props.column.id,
			cell: info => {
				if (info.row.original?.details) {
					return (
						<Typography variant='p' >{info.row.original?.details?.area  ? `Area: ${info.row.original.details.area}` : 'Legacy activity'}</Typography>
					)
				} else {
					return "-"
				}
			}
		}, {
			accessorKey: 'time',
			header: () => 'Date',
			footer: props => props.column.id,
			cell: info => {
				//console.log(info)
				return (
					<Grid container spacing={1}>
						{info.row.original.time ? formatDate(info.row.original.time) : "Pending Approval"}
					</Grid>
				)
			},
		}
	], [])

	const csv_filename = useMemo(() => {
		const dateFrom_string = formatDate(new Date(dateFrom));
		const dateTo_string = formatDate(new Date(dateTo));
		return `activity_log ${dateFrom_string} - ${dateTo_string}`;
	}, [dateFrom, dateTo]);

	const closeModal = () => {
		//onsole.log('close modal')
		setOpen(false);
	}

	const create_csv_data = useCallback(() => {
		let row_values = [];

		const headers = columns.map(header => header.header());

		data.forEach(value => {
			//console.log(value)
			const column_value = [
				value.user?.name,
				value.user?.email,
				value.type,
				value?.details ? value.details : '',
				value?.time ? `${formatDate(value.time)} ${formatDate(value.time, value.time)}` : ''
			];
			row_values = [...row_values, column_value];
		});
		setCsv_data([headers, ...row_values]);
	}, [columns, data]);

	return (
		<GlobalDialog
			open={open}
			onClose={closeModal}
			title="Activity Log"
			maxWidth="xl"
			fullWidth
			customActions={
				<DialogActions>
					<Grid item>
						<Button variant="contained" onClick={closeModal} fullWidth>
							{setLanguageText("Cancel")}
						</Button>
					</Grid>
					<Grid item>
						<CSVLink
							data={csv_data}
							filename={`${csv_filename}.csv`}
						>
							<Button
								onClick={() => create_csv_data()}
								variant="contained"
							>
								Download CSV
							</Button>
						</CSVLink>
					</Grid>
				</DialogActions>
			}
		>
			<ActivityTable
				data={data}
				columns={columns}
				isFetching={isFetching}
			/>
		</GlobalDialog>
	)
};


export default ActivityDialog;
