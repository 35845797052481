import React, { useContext, useEffect, useState } from 'react';
import {
    Document,
    G,
    Image,
    Page,
    Polygon,
    StyleSheet,
    Svg,
    Text,
    View,
    usePDF
} from '@react-pdf/renderer';
import { useTheme } from '@emotion/react';
import { LayoutContext } from '../store/LayoutContext';
import PDFHeader from './PDFHeader';
import PDFFooter from './PDFFooter';
import HTMLReactParser from 'html-react-parser';

const DesignPDF = ({
    orientation,
    request,
    setDownloadObject,
    techImage
}) => {

    console.log(request)

    const { theme: themeContext } = useContext(LayoutContext)
    // const [logo, setLogo] = useState('');

    // useEffect(() => {
    //     if (themeContext) {
    //         //console.log(theme)
    //         setLogo(themeContext.pdf_logo)
    //     }
    // }, [themeContext])

    //Font.register({ family: 'Roboto', src: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap' });

    const theme = useTheme();

    //console.log(pdf_data);
    const styles = StyleSheet.create({
        BODY: {
            //paddingTop: 10,
            // paddingBottom: 35,
            //paddingHorizontal: 10,
            fontFamily: 'Helvetica'
        },
        HEADER: {
            padding: '16px',
            //borderRadius: '5px',
            ROW: {
                padding: '0 5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            },
        },
        TEXT: {
            fontSize: 10,
            lineHeight: 1.4,
            paddingBottom: '5px'
        },
        BOLDTEXT: {
            fontFamily: 'Helvetica-Bold'
        },
        HEADERTEXT: {
            textAlign: 'center',
            fontSize: 16,
            lineHeight: 1.3,
            color: theme.palette.primary.main,
            //borderBottom: `1px solid ${theme.palette.primary.main}`

        },
        WRAPHEADER: {
            fontSize: 13,
            lineHeight: 1.3,
            position: 'relative',
            //marginTop: '5px'
        },
        TITLE: {
            fontSize: 14,
            lineHeight: 1.3
        },
        WRAP: {
            //padding: '12px 16px 16px 16px',
            //padding: '16px',
            backgroundColor: '#fff',
            //border: '1px solid #CCCCCC',
            //borderRadius: '5px',
            //marginTop: '10px'
        },
        TABLE: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            //borderRightWidth: 0,
            //borderBottomWidth: 0,
            borderColor: "#E0E0E0",
            ROW: {
                margin: "auto",
                flexDirection: "row"
            },
            COL: {
                width: '50%',
                borderStyle: "solid",
                borderColor: "#E0E0E0",
                borderWidth: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0
            },
            CELL: {
                padding: '5px 10px 0 10px',
            },
        },
        BREADCRUMB: {
            fontSize: 11,
            lineHeight: 1.3
        }
    });

    const create_pdf_rows = ({
        rows,
        styles
    }) => {
        // console.log(rows)

        return (
            <View>

                <View style={[styles.TABLE.ROW, { backgroundColor: '#EFEFEF' }]} wrap={false}>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={[styles.TEXT, styles.BOLDTEXT]}>Quantity</Text>
                        </View>
                    </View>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={styles.TEXT}>{request?.request_quantity}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.TABLE.ROW, { backgroundColor: '#FFFFFF' }]} wrap={false}>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={[styles.TEXT, styles.BOLDTEXT]}>Finish</Text>
                        </View>
                    </View>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={styles.TEXT}>{request?.request_finish?.name}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.TABLE.ROW, { backgroundColor: '#EFEFEF' }]} wrap={false}>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={[styles.TEXT, styles.BOLDTEXT]}>Tool Material</Text>
                        </View>
                    </View>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={styles.TEXT}>{request?.request_material?.name}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.TABLE.ROW, { backgroundColor: '#FFFFFF' }]} wrap={false}>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={[styles.TEXT, styles.BOLDTEXT]}>Workpiece Material</Text>
                        </View>
                    </View>
                    <View style={styles.TABLE.COL} >
                        <View style={styles.TABLE.CELL} >
                            <Text style={styles.TEXT}>{request?.request_workpiece}</Text>
                        </View>
                    </View>
                </View>
                {/* <View style={styles.TABLE.CELL} >
                        <Text style={styles.TEXT}>
                            Quantity: <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_quantity}</Text>
                        </Text>
                    </View>
                    <View style={styles.TABLE.CELL} >
                        <Text style={styles.TEXT}>
                            Finish: <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_finish?.name}</Text>
                        </Text>
                    </View>
                    <View style={styles.TABLE.CELL} >
                        <Text style={styles.TEXT}>
                            Tool Material: <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_material?.name}</Text>
                        </Text>
                    </View>
                    <View style={styles.TABLE.CELL} >
                        <Text style={styles.TEXT}>
                            Workpiece Material: <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_workpiece}</Text>
                        </Text>
                    </View>
                    <View style={styles.TABLE.CELL} >
                        <Text style={styles.TEXT}>
                            Reference:  <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_id}</Text>
                        </Text>
                    </View> */}

                {rows.map((row, i) => {
                    let value
                    if (row.Type === "dropdown" || row.Type === "imageDropdown") {
                        value = row?.value?.Name
                    } else {
                        value = row?.value
                    }
                    return (
                        <View style={[styles.TABLE.ROW, { backgroundColor: i % 2 === 0 ? '#EFEFEF' : '#FFFFFF' }]} wrap={false} key={i}>
                            <View style={styles.TABLE.COL} >
                                <View style={styles.TABLE.CELL} >
                                    <Text style={[styles.TEXT, styles.BOLDTEXT]}>{row.Name} ({row.Ref})</Text>
                                </View>
                            </View>
                            <View style={styles.TABLE.COL} >
                                <View style={styles.TABLE.CELL} >
                                    <Text style={styles.TEXT}>{value} {row.Unit ? row.Unit : null}</Text>
                                </View>
                            </View>
                        </View>
                    )
                })}
            </View>
        );
    };

    const MyDoc = (
        <Document title={`Custom Tool Design`}>
            <Page orientation={`${orientation}`} style={[styles.BODY, { backgroundColor: '#FFF' }]}>
                <PDFHeader styles={styles} theme={theme} logo={themeContext.email_logo} title="Custom Tool Design" />
                <View style={styles.WRAP}>

                    <View style={styles.TABLE.ROW} >
                        <Text style={[styles.TEXT]}>Added by <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_user?.user_details.name} {request?.request_user?.user_details?.surname}</Text> on <Text style={[styles.TEXT, styles.BOLDTEXT]}>{new Date(request.request_submit_date).toLocaleDateString()} @ {new Date(request.request_submit_date).toLocaleTimeString()}</Text></Text>
                    </View>
                    <View style={styles.TABLE.ROW} >
                        <Text style={[styles.TEXT]}>Required by <Text style={[styles.TEXT, styles.BOLDTEXT]}>{new Date(request.request_required_date).toLocaleDateString()}</Text></Text>
                    </View>
                    <View style={styles.TABLE.ROW} >
                        <Text style={[styles.TEXT]}>Company <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_company?.company_name}</Text></Text>
                    </View>
                    <View style={styles.TABLE.ROW} >
                        <Text style={styles.TEXT}>Reference:  <Text style={[styles.TEXT, styles.BOLDTEXT]}>{request?.request_id}</Text></Text>
                    </View>
                    <View style={styles.TABLE.ROW} >
                        <br style={{ width: '90%', height: '1px', backgroundColor: '#ccc', margin: '16px auto 10px' }} />
                    </View>

                    <View style={[{ padding: '0 16px 0 16px' }]} >
                        <Text fixed style={[styles.TEXT, styles.HEADERTEXT, { margin: '10px 0 0 0', padding: '10px 0' }]} >
                            {request?.request_name}
                        </Text>
                    </View>
                    <View style={[styles.TABLE.ROW, { textAlign: 'center' }]}>
                        <Text style={[styles.BREADCRUMB, styles.BOLDTEXT, { margin: '10px auto' }]}>
                            {request?.request_wizard?.breadcrumb[0]?.name} &gt; {request?.request_wizard?.breadcrumb[1]?.name} &gt; {request?.request_wizard?.breadcrumb[2]?.name}
                        </Text>
                    </View>
                    {/* <View style={styles.TABLE.ROW}>
                        <Text style={{ display: "block", backgroundColor: '#efefef', width: '600px', padding: '12px' }} >
                            {request?.request_details ? HTMLReactParser(request.request_details) : 'No request details'}
                        </Text>
                    </View> */}
                    <View style={styles.TABLE.ROW}>
                        <View style={[styles.TABLE.COL, { border: 'none ' }]} >
                            {techImage &&
                                <Image cache={false} src={techImage} style={{ maxWidth: '300px', maxHeight: '200px', objectFit: 'contain', margin: '16px auto' }} />
                            }
                        </View>
                    </View>

                    {request?.request_wizard.design ?
                        <View style={[{ padding: '0 16px 16px 16px' }]} >
                            <Text fixed style={[styles.TEXT, styles.HEADERTEXT, { margin: '10px 0 0 0', padding: '16px 0 10px 0' }]} >Requested Attributes</Text>
                            <View style={styles.TABLE}>
                                {create_pdf_rows({
                                    rows: request?.request_wizard.design,
                                    styles
                                })}
                            </View>
                        </View>
                        : null}

                    {request?.request_details ?
                        <View style={styles.WRAP} wrap={false}>
                            <Text style={styles.TEXT} >
                                {HTMLReactParser(request.request_details)}
                            </Text>
                        </View>
                        : null}

                </View>
                <View fixed styles={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0
                }}>

                    <PDFFooter styles={styles} theme={theme} />
                </View>
                {/* <View render={({ pageNumber, totalPages }) => (
                    pageNumber  === totalPages && (
                        <View fixed styles={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}>
                            <PDFFooter styles={styles} theme={theme} />
                        </View>
                    )
                )} /> */}
            </Page>
        </Document >
    )

    const [instance, updateInstance] = usePDF({ document: MyDoc });

    useEffect(() => {

        if (instance.blob) {
            console.log(instance)
            setDownloadObject(instance)
        }

    }, [instance, setDownloadObject])

    return (
        <>
            {instance.url ?
                <object
                    data={instance.url}
                    type="application/pdf"
                    height="100%"
                    width="100%"
                >
                    {/* <ReactPDFFallback url={instance.url} /> */}
                </object>
                :
                null
            }
        </>
    )

};

export default DesignPDF;
