import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
    usePDF
} from '@react-pdf/renderer';
import formatDate from '../helpers/formatDate';
import ReactPDFFallback from './ReactPDFFallback';
import setLanguageText from '../../language/setLanguageText';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { LayoutContext } from '../../store/LayoutContext';
import PDFHeader from '../../pdf/PDFHeader';

const ServiceReportPDF = ({
    orientation,
    pdf_data,
    setDownloadObject,
    mainImage,
    theme
}) => {

    //console.log(mainImage)
    const { theme: themeContext } = useContext(LayoutContext)

    const styles = StyleSheet.create({
        BODY: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingHorizontal: 10
        },
        HEADER: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme?.palette?.primary?.main ? theme.palette.primary.main : '#efefef',
            maxHeight: '100px',
            marginBottom: 20,
            padding: 10,
            FONT: {
                marginBottom: 5,
                fontSize: 12,
                color: theme?.palette?.primary?.contrastText ? theme.palette.primary.contrastText : '#222'
            },
            LOGO: {
                height: '100%',
                width: '200px'
            },
            DETAILS: {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: 30,
                flex: 1,
                TEXT: {
                    fontSize: 12,
                    lineHeight: 1.3,
                },
                DATE: {
                    marginTop: 5,
                    fontSize: 10,
                }
            }
        },
        TABLE: {
            display: 'flex',
            width: 'auto',
            marginBottom: 20,
            marginTop: 10,
            // borderTop: '1px solid #ccc',
            // borderLeft: '1px solid #ccc',
            // borderRight: '1px solid #ccc',
            // borderBottom: 'none',
            HEADER: {
                //padding: 5,
                display: 'flex',
                flexDirection: 'row',
                //backgroundColor: '#efefef',
                //color: '#222',
                //borderBottom: '1px solid #ccc',
                CELL: {
                    padding: 5,
                    fontSize: 8
                }
            },
            ROW: {
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#fff',
                color: '#222',
                //borderBottom: '1px solid #ccc',
                CELL: {
                    padding: 5,
                    fontSize: 8,
                    textAlign: 'left',
                    overflow: 'hidden'
                },
            },
            ROW1: {
                padding: 5,
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'row',
                //backgroundColor: '#fff',
                color: '#222',
            }
        },
        SIGNATURES: {
            display: 'flex',
            flexDirection: 'row'
        },
        GAP: {
            width: 10
        },
        SIGNATURE: {
            flex: 1,
            fontSize: 10,
            HEADER: {
                marginBottom: 5,
                fontSize: 12
            },
            NAME: {
                marginTop: 5,
                fontSize: 10
            },
        },
        SIGNATURE_IMG: {
            border: '1px solid #ccc',
            width: 200,
            height: 100
        },
        logo_contanier: {
            width: '200px',
            height: '120px',
            overflow: 'hidden'
        },
        logo: {
            width: '200px',
            height: '120px',
            objectFit: 'contain'
        },
        notes_container: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20
        },
    });

    const create_pdf_headers = ({
        headers,
        styles
    }) => {
        return headers.map((header, i) => {
            if (header.id === "additives"
                    || header.id === "notes"
                    || header.id === "entry_id"
                    || header.id === "entry_date"
                    || header.id === "machine_name"
                    || header.id === "machine_group"
                
            ) {
                return
            }
            return (
                <View
                    key={i}
                    style={{ flex: 1, padding: '10px 3px', backgroundColor: theme?.palette?.primary?.main ? theme.palette.primary.main : '#efefef' }}
                >
                    <Text
                        style={{
                            fontSize: 8,
                            textAlign: 'center',
                            fontWeight: 500,
                            color: theme?.palette?.primary?.contrastText ? theme.palette.primary.contrastText : '#222'
                        }}
                    >
                        {setLanguageText(header.name)}
                    </Text>
                </View>
            )
        });
    }

    const create_pdf_rows = ({
        headers,
        rows,
        styles
    }) => {
        return (
            <View>
                {rows.map((row, i) => {
                    //console.log(row)
                    let machine = row.find((item) => item.id === 'machine_name');
                    let group = row.find((item) => item.id === 'machine_group');
                    let notes = row.find((item) => item.id === 'notes');
                    let additives = row.find((item) => item.id === 'additives');

                    return (
                        <View style={{
                            width: '100%',
                            //backgroundColor: i % 2 === 0 ? '#fff' : '#efefef',
                            backgroundColor: notes?.action === 'N' ? '#f0f6f0' : notes?.action === 'C' ? '#f9f0f0' : notes?.action === 'C' ? '#fdf6f1' : '#fff',
                            //backgroundColor: row.original.out_of_control ? theme.palette.table.out : theme.palette.table.rows,
                            borderBottom: '1px solid #ccc'
                        }}
                            wrap={false}
                            key={i}>
                            <View
                                style={{
                                    padding: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    //backgroundColor: '#efefef',
                                    color: '#222',
                                }}
                            >
                                <View style={{ flex: 1, padding: 1 }}>
                                    <Text
                                        style={{
                                            //padding: 5,
                                            fontSize: 12,
                                            fontWeight: 500,
                                        }}
                                    >{machine?.value ? machine.value : ''} {group?.value ? `(${group.value})` : ''}</Text>
                                    {notes?.value &&
                                        <Text
                                            style={{
                                                //padding: 5,
                                                marginTop: 5,
                                                fontSize: 8,
                                            }}
                                        >
                                            {setLanguageText("Notes")}: {notes.value}
                                        </Text>

                                    }
                                    {//check additives is an object
                                        additives?.value && Array.isArray(additives?.value) &&
                                        additives?.value.map((item) =>
                                            <Text
                                                style={{
                                                    //padding: 5,
                                                    marginTop: 5,
                                                    fontSize: 8,
                                                }}
                                            >{item}
                                            </Text>
                                        )

                                    }
                                </View>
                            </View>

                            <View style={styles.TABLE.ROW1} >
                                {headers.map((header, i) => {
                                    //find macthing row attribute
                                    const output = row.find((rowItem) => rowItem.id === header.id);
                                    //console.log(output)

                                    if (output?.id &&
                                        output?.id === "additives"
                                        || output?.id === "notes"
                                        || output?.id === "entry_id"
                                        || output?.id === "entry_date"
                                        || output?.id === "machine_name"
                                        || output?.id === "machine_group"
                                    ) {
                                        return
                                    }
                                    return (
                                        <>
                                            {output?.color ?
                                                <View style={{ flex: 1, padding: 2 }} key={i}>
                                                    <Text
                                                        key={i}
                                                        style={{
                                                            padding: 5,
                                                            fontSize: 8,
                                                            borderRadius: 16,
                                                            textAlign: 'center',
                                                            backgroundColor: output.color
                                                        }}
                                                    >
                                                        {output.value}
                                                    </Text>
                                                </View>
                                                :
                                                <View
                                                    key={i}
                                                    style={{ flex: 1, padding: 2 }}
                                                >
                                                    <Text
                                                        style={{

                                                            fontSize: 8,
                                                            textAlign: 'center',

                                                        }}
                                                    >
                                                        {output?.value ? output?.value : '-'}
                                                    </Text>
                                                </View>
                                            }
                                        </>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View >
        );
    };

    const document = useMemo(() =>
        <Document title="Service Pdf">
            <Page style={styles.BODY} orientation={`${orientation}`}>
                <PDFHeader styles={styles} theme={theme} logo={themeContext.email_logo} company_logo={mainImage} title={`${setLanguageText("Service Report")}: ${pdf_data?.company?.name}`} />
                {/* <View style={{ backgroundColor: theme.palette.primary.main, textAlign: 'center', width: '100%', padding: '8px' }}>
                    <View style={{ textAlign: 'center', width: '100%' }}>
                        {mainImage ? <Image src={mainImage} style={{ width: '100%', maxWidth: '300px', height: 'auto', maxHeight: '80px', objectFit: 'contain', margin: '0 auto' }} /> : null}
                    </View>
                </View> 
                 <View style={{ textAlign: 'center', width: '100%', padding: '16px 0 8px' }}>
                    <Text style={{ width: '100%', textAlign: 'center', fontSize: 16, color: theme.palette.primary.main }} >
                        {setLanguageText("Service Report")}: {pdf_data?.company_name}
                    </Text>
                </View>
                <View style={{ textAlign: 'center', width: '100%' }}>
                    <br style={{ width: '90%', height: '1px', backgroundColor: '#ccc', margin: '10px auto' }} />
                </View> */}
                <View style={{ textAlign: 'center', width: '100%' }}>
                    <Text style={{ fontSize: 14, marginBottom: 5 }} >
                        {pdf_data?.service_name}
                    </Text>
                    <Text style={{ fontSize: 8, marginBottom: 10 }} >
                        {`${formatDate(pdf_data?.service_date)}`}
                        {/* {`${formatDate(pdf_data?.service_date)} - ${pdf_data?.service_attr.startTime} : ${pdf_data?.service_attr.finishTime}`} */}
                    </Text>
                    {pdf_data.service_notes &&
                        <Text style={{ fontSize: 10, marginBottom: 5, lineHeight: 1.4 }} >
                            {pdf_data.service_notes}
                        </Text>
                    }
                </View>

                <View style={styles.TABLE}>
                    <View style={styles.TABLE.HEADER} fixed>
                        {create_pdf_headers({
                            headers: pdf_data?.columns,
                            styles
                        })}
                    </View>
                    {create_pdf_rows({
                        headers: pdf_data?.columns,
                        rows: pdf_data?.rows,
                        styles
                    })}
                </View>
                <View wrap={false} style={styles.SIGNATURES}>
                    <View style={styles.SIGNATURE}>
                        <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }} >
                            {setLanguageText("Serviced")}: {pdf_data?.service_name}
                        </Text>
                        <View style={{
                            textAlign: "center",
                            border: '1px solid #ccc',
                            padding: 10,
                            width: '100%',
                            height: "160px"
                        }}>
                            {pdf_data?.service_sign && <Image src={pdf_data.service_sign} />}
                        </View>
                    </View>
                    <View style={styles.GAP}>
                    </View>
                    <View style={styles.SIGNATURE}>
                        <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }} >
                            {setLanguageText("Customer")}: {pdf_data?.customer_name}
                        </Text>
                        <View style={{
                            textAlign: "center",
                            border: '1px solid #ccc',
                            padding: 10,
                            width: '100%',
                            height: "160px"
                        }}>
                            {pdf_data?.cust_sign && <Image src={pdf_data.cust_sign} />}
                        </View>
                    </View>
                </View>
                {/* <View wrap={true} style={{ backgroundColor: theme.palette.primary.main, textAlign: 'center', padding: '16px 16px 11px 16px', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, lineHeight: 1.6, color: theme.palette.primary.contrastText }}>{`${config.APPTITLE} - copyright © ${new Date().getFullYear()}`}</Text>
                    <Text style={{ fontSize: 10, lineHeight: 1.6, color: theme.palette.primary.contrastText }}> Designed &amp; Developed by Design Monkey Media ltd</Text>
                    <Text style={{ fontSize: 10, lineHeight: 1.6, color: theme.palette.primary.contrastText }}> Powered by AWS </Text>
                </View>
                */}
            </Page>
        </Document >,
        [
            theme,
            mainImage,
            pdf_data,
            orientation,
            styles,
        ]);

    const [instance, updateInstance] = usePDF({ document });

    useEffect(() => {
        if (document) {
            updateInstance(document);
        }
    }, [document]);

    useEffect(() => {

        if (instance.blob) {
            // console.log(instance)
            setDownloadObject(instance)
        }

    }, [instance, setDownloadObject])

    return (
        <>
            {instance?.url ?
                <>
                    {isMobileOnly || isTablet ?
                        <ReactPDFFallback url={instance.url} />
                        :
                        <object
                            data={instance.url}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                        </object>
                    }
                </>
                : null}
        </>
    )
};

export default ServiceReportPDF;
