import { CardContent, CardHeader, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import { Field, useFormikContext } from "formik"

const editorStyle = {
  height: "initial",
  padding: "32px 16px",
  //background: "#ffffff",
  borderRadius: "0 0 8px 8px",
  flex: 1,
}
const wrapperStyle = {
  width: "100%",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
}
const toolbarStyle = {
  borderRadius: "8px 8px 0 0",
  padding: '1em 1em .5em 1em',
  margin: 0,
  border: 'none',
  borderBottom : '1px solid rgba(0, 0, 0, 0.23)'
}

const WysiwygEditor = ({ initialValue, fieldName }) => {
  const { touched, errors } = useFormikContext()
  const [editorState, setEditorState] = useState(
    initialValue
      ? EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(initialValue || ""))
      )
      : EditorState.createEmpty()
  )

  const onEditorStateChange = ({ editorState, fieldName }) => {
    setEditorState(editorState)
  }

  return (
          <>
            <Field id={fieldName} name={fieldName}>
              {({ field, form, meta }) => {
                //console.log(form)
                //console.log(field)
                return (
                  <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    editorStyle={editorStyle}
                    toolbarStyle={toolbarStyle}
                    wrapperStyle={{
                      ...wrapperStyle,
                      border:
                        form.touched[`${fieldName}`] && Boolean(form.errors[`${fieldName}`])
                          ? "solid 1px #be4c4c"
                          : "solid 1px rgba(0, 0, 0, 0.23)",
                      borderRadius:
                        form.touched[`${fieldName}`] && Boolean(form.errors[`${fieldName}`])
                          ? "8px"
                          : "8px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "list",
                        "textAlign",
                        "link",
                        "remove",
                        "history",
                      ],
                      inline: {
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                      },
                    }}
                    onBlur={(event, editorState) => {
                      const htmlString = draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      )
                      const plainContent = editorState
                        .getCurrentContent()
                        .getPlainText()
                      // If empty remove content from form
                      if (!plainContent)
                        return form.setFieldValue(fieldName, "")

                      if (initialValue !== htmlString && plainContent)
                        form.setFieldValue(
                          fieldName,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                    }}
                    onEditorStateChange={editorState =>
                      onEditorStateChange({ editorState, form })
                    }
                  />
                )
              }}
            </Field>
          {touched[`${fieldName}`] && Boolean(errors[`${fieldName}`]) && (
                <Typography color="error" variant="caption" sx={{  marginLeft: '14px' }}>{errors[`${fieldName}`]}</Typography>
          )}
        </>

  )
}


export default WysiwygEditor
