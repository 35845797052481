import React, { useReducer, createContext, useState, useEffect } from 'react';
import { LayoutReducer } from './LayoutReducers';
import config from '../config';

export const LayoutContext = createContext();

LayoutContext.displayName = 'Layout Options';

export const LayoutProvider = ({ children }) => {

	// const defaultSelectedArea = sessionStorage.getItem("selectedArea")
    //     ? sessionStorage.getItem("selectedArea")
    //     : 'home' ;

	// const defaultSelectedMenu = sessionStorage.getItem("selectedMenu")
    //     ? sessionStorage.getItem("selectedMenu")
    //     : '' ;

	const defaultLayout = sessionStorage.getItem("layout")
        ? sessionStorage.getItem("layout")
        : "engineer";

	const defaultMode = sessionStorage.getItem("mode")
        ? sessionStorage.getItem("mode")
        : config.COLOURMODE;


	const [theme, dispatchTheme] = useReducer(LayoutReducer, {});

	const [layout, dispatchLayout] = useReducer( LayoutReducer, {
		page:  1,
        layout: defaultLayout,
        sort: null,
		type : null,
		colorMode : defaultMode,
		showProducts: false,
		showCategories : true
	});	

	const menu = require(`../_config/${process.env.REACT_APP_CONFIG_PATH||'dev'}/menu_object.js`);

	const [app, dispatchApp] = useReducer( LayoutReducer, {
		menu: menu.default,  //set full m enu object               
		emails:null,          
		selectedArea: null ,
		selectedMenu: null ,
	});


	const [language, dispatchLanguage] = useReducer(LayoutReducer, config.LANGUAGE);

	const contextValues = {
		theme,
		dispatchTheme,
		language,
		dispatchLanguage,
		layout,
		dispatchLayout,
		app,
		dispatchApp
	};

	return (
		<LayoutContext.Provider value={contextValues}>
			{children}
		</LayoutContext.Provider>
	);
};
