import React, { useState, useEffect } from 'react';
import config from '../../config';
import useSignedUrl from '../../hooks/useSignedUrl';

export default function ProductImage({ product }) {

	const [imageUrl, setImageUrl] = useState();
	const [imageFallback, setFallbackImage] = useState();
	const { getSignedUrl } = useSignedUrl();

	useEffect(() => {

		let isSubscribed = true;
		if (product.product_type === 2) {
			getSignedUrl(config.s3.BUCKET, `public/brands/additive.png`).then((url) => (isSubscribed ? setFallbackImage(url) : null))
			if (product.brand_id) {
				getSignedUrl(config.s3.BUCKET, `public/brands/${product.brand_id}_additive.png`).then((url) => (isSubscribed ? setImageUrl(url) : null))
			}
		} else if (product.product_type === 1) {
			getSignedUrl(config.s3.BUCKET, `public/brands/coolant.png`).then((url) => (isSubscribed ? setFallbackImage(url) : null))
			if (product.brand_id) {
				getSignedUrl(config.s3.BUCKET, `public/brands/${product.brand_id}_coolant.png`).then((url) => (isSubscribed ? setImageUrl(url) : null))
			}
		} else {
			getSignedUrl(config.s3.BUCKET, `public/brands/${product.brand_id}_${product.type}.png`).then((url) => (isSubscribed ? setImageUrl(url) : null))
		}
		return () => {
			//clear up 
			isSubscribed = false;
		}
	}, [product])

	function showFallbackImage(e) {
		setImageUrl(imageFallback)
	}

	return (
		<>
			{imageUrl ?
				<img
					src={imageUrl}
					alt=""
					onError={showFallbackImage}
				/>
				: null}
		</>
	);
};
