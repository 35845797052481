import React, { cloneElement, useState } from 'react';
import { Box, IconButton, Menu, Popover, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DotsMenu = ({ children }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        //console.log(event)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (values) => {
        //console.log(values)
        setAnchorEl(null);
    };

    // Function to pass the handleClose prop to children
    const renderChildrenWithProps = () => {
        return React.Children.map(children, (child) =>
            cloneElement(child, { handleClose })
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Actions">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Popover
                id="dots-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* Render children with the handleClose prop */}
                {renderChildrenWithProps()}
            </Popover>
        </>
    );
}
export default DotsMenu;
