import React, { useEffect, useState } from 'react';
import {
    Tabs,
    Tab,
    Card,
    Grid,
    Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PageContent from '../coolantcare/PageContent';
import UserDetails from './UserDetails';
import UserNotifications from './UserNotifications';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../language/setLanguageText';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import UserSettings from './UserSettings';
import Favourites from '../favourites/Favourites';
import Kits from '../favourites/Kits';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            sx={{
                width: '100%',
                //minHeight: '600px',
                overflow: 'auto',
                background: theme => theme.palette.tabs.content
            }}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                //lazy load
                children
            )}
        </Box>
    );
}

function createProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Settings = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    //change URL parameters
    const handleChange = (event, newValue) => {
        navigate(`/settings/account?tab=${newValue}`)
        setValue(parseInt(newValue));
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const tab = params.get('tab')
        if (tab) {
            setValue(parseInt(tab))
        }
    }, [])

    return (
        <>
            <InnerHeaderWrap>
                <GlobalCompanySelect />
            </InnerHeaderWrap>
            <PageContent>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}>
                        <Card>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    backgroundColor: theme => theme.palette.background.paper,
                                    display: isMobileOnly ? 'block' : 'flex',
                                }}
                            >
                                <Tabs
                                    orientation={isMobileOnly ? 'horizontal' : 'vertical'}
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    sx={{
                                        borderRight: theme => `1px solid ${theme.palette.divider}`,
                                        minWidth: isMobileOnly ? '100%' : '200px',
                                        overflow: 'visible',
                                        background: theme => theme.palette.tabs.menu,
                                        '& .MuiTab-root': {
                                            textAlign: 'left',
                                            alignItems: 'start',
                                            borderBottom: '1px solid #e8e8e8'
                                        }

                                    }}
                                >
                                    <Tab label={setLanguageText("My Details")} {...createProps(0)} />
                                    <Tab label={setLanguageText("My Settings")} {...createProps(1)} />
                                    <Tab label={setLanguageText("My Notifications")} {...createProps(2)} />
                                    <Tab label={setLanguageText("My Favourites")} {...createProps(3)} />
                                    <Tab label={setLanguageText("My Kits")} {...createProps(4)} />

                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <UserDetails />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <UserSettings />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <UserNotifications />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Favourites />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Kits />
                                </TabPanel>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </PageContent>
        </>
    );
}

export default Settings;
