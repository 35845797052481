
import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import setLanguageText from '../../../language/setLanguageText';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import DownloadsTable from './DownloadsTable';
import { CompanyContext } from '../../../store/CompanyContext';

const Downloads = () => {

	const { selectedCompany } = useContext(CompanyContext);

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Downloads")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<DownloadsTable />
			</TabContent>
		</>
	);
};

export default Downloads;

