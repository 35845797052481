import { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import jwtDecode from 'jwt-decode';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';

const useERPToken = () => {

	const { user } = useContext(UserContext);
	//const [token, setToken] = useState('');
	const ACTstoredToken = sessionStorage.getItem('ACTtoken');
	const CISstoredToken = sessionStorage.getItem('CIStoken');

	useEffect(() => {
		if (process.env.REACT_APP_CONFIG_PATH === "act") {
			if (!ACTstoredToken && user?.api_key) {
				console.log("No token found, fetching new token on load");
				getACTToken.mutate();
			}
		}
		else if (process.env.REACT_APP_CONFIG_PATH === "cis" || process.env.REACT_APP_CONFIG_PATH === "dev") {
			if (!CISstoredToken && user?.api_key) {
				console.log("No token found, fetching new token on load");
				sessionStorage.setItem('CIStoken', 'cis');
			}
		}
	}, [user]);


	// Use a mutation to fetch a new token
	const getACTToken = useMutation(() => {
		return axiosRequest({
			method: 'get',
			endpoint: 'token',
			gateway: config.erpAPI.URL,
			api_key: user.api_key
		});
	},
		{
			onSuccess: (data) => {
				if (data?.Token) {
					//setToken(data.Token);
					sessionStorage.setItem('ACTtoken', data.Token);
				}
			},
		}
	);

	// Fetch a new token if the current one has expired
	useEffect(() => {

		//only run in act environment
		if (process.env.REACT_APP_CONFIG_PATH !== "act") return;
		if (!ACTstoredToken) return;
		const decodedToken = jwtDecode(ACTstoredToken);
		const checkTokenInterval = setInterval(() => {
			// if there is less that 15 minutes left on the token, fetch a new one
			if (new Date(decodedToken.exp * 1000) < Date.now() + (60000 * 15)) {
				//alert("Token is about to expire, fetching new token");
				getACTToken.mutate();
			}
			//console.log("Checking token");
		}, 60000 * 10); // check every 10 minutes

		return () => clearInterval(checkTokenInterval);

	}, []);

};

export default useERPToken;
