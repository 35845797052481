import React, { useContext, useEffect, useState } from 'react';
import AccountMenu from '../menu/AccountMenu';
import { Grid, IconButton, Box, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { faHeart, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feedback from './Feedback';
import AdminMenu from '../menu/AdminMenu';
import { LayoutContext } from '../store/LayoutContext';
import Basket from '../basket/Basket';
import { UserContext } from '../store/UserContext';
import { UPDATE_MENU } from '../store/LayoutReducers';
import setLanguageText from '../language/setLanguageText';

export default function IconMenu({ setMobileOpen }) {

    const navigate = useNavigate();
    const { dispatchApp } = useContext(LayoutContext);
    const { user } = useContext(UserContext);

    return (

        <Grid
            container
            alignContent={'center'}
            alignItems={'center'}
            spacing={1}
        >
            <Grid item >
                <Tooltip title={setLanguageText("Search")}>
                    <IconButton
                        color="inherit"
                        aria-label="Search"
                        //edge="start"
                        onClick={e => {
                            navigate('/')
                            //console.log(area,menu)
                            dispatchApp({
                                type: UPDATE_MENU,
                                payload: {
                                    selectedArea: "home",
                                    selectedMenu: "search"
                                }
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item >
                <Tooltip title={setLanguageText("My Favourites")}>
                    <IconButton
                        color="inherit"
                        aria-label="My Favourites"
                        //edge="start"
                        onClick={e => {
                            navigate('/settings/account?tab=3')
                            //console.log(area,menu)
                            dispatchApp({
                                type: UPDATE_MENU,
                                payload: {
                                    selectedArea: "settings",
                                    selectedMenu: "account"
                                }
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faHeart} />
                    </IconButton>
                </Tooltip>
            </Grid>
            {/* 
            <Grid item >
                <AdminMenu />
            </Grid>
         
            <Grid item >
                <AccountMenu />
            </Grid>
            {(
                user?.user?.user_permissions?.buyers?.access === 1 ||
                user?.user?.user_permissions?.application?.access === 1 ||
                user?.user?.user_permissions?.engineers?.access === 1 ||
                user?.user?.user_permissions?.parametric?.access === 1 ||
                user?.user?.user_permissions?.cadcam?.access === 1
            ) ?
                <Grid item >
                    <Basket />
                </Grid>
                : null} */}
        </Grid>
    );
}

