import extractGroups from '../helpers/extractGroups';
// import parseEntries from '../helpers/parseEntries';
// import parseMachines from '../helpers/parseMachines';
export const SAVE_MACHINES = 'SAVE_MACHINES';
export const SAVE_NOMACHINES = 'SAVE_NOMACHINES';
export const DEFAULT_MACHINE = 'DEFAULT_MACHINE';
export const SELECTED_MACHINE = 'SELECTED_MACHINE';
export const SELECTED_MACHINES = 'SELECTED_MACHINES';
export const FILTER_MACHINES = 'FILTER_MACHINES';
export const ADD_ENTRIES = 'ADD_ENTRIES';
export const SAVE_ENTRY = 'SAVE_ENTRY';
export const UPDATE_MACHINE_SETTINGS = 'UPDATE_MACHINE_SETTINGS';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const VOID_ENTRY = 'VOID_ENTRY';
export const RESET_MACHINES = 'RESET_MACHINES';
export const GRID_VIEW = 'GRID_VIEW';
export const DISPLAY_VIEW = 'DISPLAY_VIEW';
export const OUT_OF_CONTROL = 'OUT_OF_CONTROL';
export const IS_OUT_OF_CONTROL = 'IS_OUT_OF_CONTROL';
export const SAVE_GROUPS = 'SAVE_GROUPS';
export const DEFAULT_GROUP = 'DEFAULT_GROUP';
export const SELECTED_GROUP = 'SELECTED_GROUP';

export const MachinesReducer = (state, action) => {
	switch (action.type) {
		case SAVE_MACHINES:
			// if fetched Company doesn't have any machine
			if (!action.payload.length) {
				// add something to the array or will fetch infinitely in DropdownCompanies
				return action.payload;
			}
			//return parseMachines(action.payload);
			return action.payload;
		case SAVE_NOMACHINES:
			return action.payload;
		case FILTER_MACHINES:
			// if fetched Company doesn't have any machine
			if (action.payload.length && !action.payload[0].machine_id) {
				// in machines dropdown, counter will look at this and return 0
				return [];
			}
			//return parseMachines(action.payload);
			return action.payload;
		case DEFAULT_MACHINE:
			return action.payload;
		case SELECTED_MACHINE:
			//return parseMachines(action.payload);
			return action.payload;
		case UPDATE_MACHINE_SETTINGS:
			//id id is sent remove from state
			if (action.payload.id) {
				return {
					...state,
					coolant_id: action.payload.coolant_id ? action.payload.coolant_id : state.coolant_id,
					machine_settings: {
						admin: action.payload.admin ? action.payload.admin : state.machine_settings.admin,
						machine_defaults: action.payload.machine_defaults ? action.payload.machine_defaults : state.machine_settings.machine_defaults,
						products: state.machine_settings.products.filter(product => product !== action.payload.id)
					},
					last_coolant_change: action.payload.last_coolant_change ? action.payload.last_coolant_change : state.last_coolant_change,
				};
			} else {
				return {
					...state,
					coolant_id: action.payload.coolant_id ? action.payload.coolant_id : state.coolant_id,
					machine_settings: {
						admin: action.payload.admin ? action.payload.admin : state.machine_settings.admin,
						machine_defaults: action.payload.machine_defaults ? action.payload.machine_defaults : state.machine_settings.machine_defaults,
						products: action.payload.products ? action.payload.products : state.machine_settings.products
					},
					last_coolant_change: action.payload.last_coolant_change ? action.payload.last_coolant_change : state.last_coolant_change,
				};
			}
		case SELECTED_MACHINES:
			if (action.payload.machine_id > 0) {
				//return [parseMachines(action.payload)];
				return [action.payload];
				// For multiple selection
				// return [...state, action.payload];
			}
			return [];

		case ADD_ENTRIES:
			return {
				...state,
				entries: action.payload
			};

		case SAVE_ENTRY:
			return {
				...state,
				entries: state.entries
					? [action.payload, ...state.entries]
					: [action.payload]
			};

		case UPDATE_ENTRY:
			if (state.entries) {
				const entry_index = state.entries.findIndex(
					entry => entry.entry_id === action.payload.entry_id
				);
				const update_entries = [...state.entries];

				update_entries[entry_index] = {
					...state.entries[entry_index],
					...action.payload
				};

				return { ...state, entries: update_entries };
			}
			return { ...state };

		case VOID_ENTRY:
			if (state.entries) {
				const entry_index = state.entries.findIndex(
					entry => entry.entry_id === action.payload.entry_id
				);
				const update_entries = [...state.entries];
				update_entries[entry_index] = {
					...state.entries[entry_index],
					void: action.payload.void
				};

				return { ...state, entries: update_entries };
			}
			return { ...state };
		case RESET_MACHINES:
			return [];
		case GRID_VIEW:
			return action.payload;
		case DISPLAY_VIEW:
			return action.payload;
		case OUT_OF_CONTROL:
			return action.payload;
		case IS_OUT_OF_CONTROL:
			return action.payload;

		default:
			return state;
	}
};

export const GroupsReducer = (state, action) => {
	switch (action.type) {
		case SAVE_GROUPS:
			return extractGroups(action.payload);

		case DEFAULT_GROUP:
			return action.payload;

		case SELECTED_GROUP:
			// window.sessionStorage.setItem(
			//   'selectedGroup',
			//   JSON.stringify(action.payload)
			// );
			return action.payload;
		default:
			return state;
	}
};
