import React, { useCallback, useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";

const Check = ({ field }) => {

    const { selected, dispatchSelected } = useContext(SelectedContext);
    const [checked, setChecked] = useState(false);

    //Set field value on first load using URL Querystring
    useEffect(() => {
        //console.log('No Querystring');
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("search"))
            //console.log(searchArr[0])
            if (searchArr && Object.values(searchArr).length ) {
                let found = searchArr[0].filter(element => element.includes(`${field.Field}§`));
                //console.log(found)
                if (Object.values(found).length > 0) {
                    //console.log(found)
                    dispatchSelected({
                        type: UPDATE_PARAMETRIC_FILTERS,
                        payload: {
                            'Field': field.Field,
                            'Values': [1],
                            'Name': field.Name,
                            'DIN': field.Ref,
                            'Toggle': true
                        }
                    });
                    setChecked(true)
                }
            }
        }
    }, []);


    // useEffect(() => {   

    //     console.log(selected.parametricFilters)
    //     if(!selected.parametricFilters){
    //         conmsole.log('reset')
    //     }

    // }, [selected])

    const handleChange = useCallback((e, value) => {
        //console.log(value)
        //Update poarametric search - which in turn updates URL using a debounce
        dispatchSelected({
            type: UPDATE_PARAMETRIC_FILTERS,
            payload: {
                'Field': field.Field, 
                'Values': [value ? 1 : ''], 
                'Name': field.Name, 
                'DIN': field.Ref,
                'Toggle': true
            }
        });
        setChecked(value)
    }, [field.Field, dispatchSelected])

    return (
        <>
            {field.Name ?
                <FormControlLabel
                    control={
                        <Checkbox checked={checked} onChange={(e, value) => handleChange(e, value)} name={field.ID} />
                    }
                    label={field.Name}
                />
                : null
            }
        </>

    );
};

export default Check;
