export default function getUserLevel(user, area, roleNeeded,  minimumLevel){
    //check if level is an object v1 permission
    //console.log(user?.user_roles)
    //check for old level
    if(user?.user_permissions?.[area]?.level){
       if(user?.user_permissions?.[area]?.level > minimumLevel){
           return true
       } else {
           return false
       }
    } else {
        //check user_roles is an array
        if(!Array.isArray(user?.user_roles)){
            return false
        }
        //check for user role
        if(user?.user_roles.includes(`${roleNeeded}`)){

            return true
        } else {
            return false
        }

    }
}