import React, { useContext } from "react";
import { ProductsContext } from "../store/ProductsContext";
import { Grid } from "@mui/material";
import ProductLoadingCard from "./../shop/products/ProductLoadingCard";
import CadItemGrid from "./CadItemGrid";

const CadProductGrid = ({ isFetching , visible}) => {

    const { visible : visibleProducts } = useContext(ProductsContext);

    return (
        <Grid container item spacing={2}>
            {Object.values(visibleProducts.visibleProducts).length ?
                visibleProducts.visibleProducts.map((product, i) =>
                    <Grid key={i} item xs={12} sm={6} md={12} lg={4} xl={3}>
                        <CadItemGrid product={product} isFetching={isFetching} visible={visible} />
                    </Grid>
                ) : isFetching ?
                    <>

                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={4} xl={3}>
                            <ProductLoadingCard />
                        </Grid>
                    </>
                    : null
            }
        </Grid>
    );
};

export default CadProductGrid;

