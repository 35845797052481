import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Grid,
	Typography
} from '@mui/material';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../language/setLanguageText';
import { useMutation, useQuery } from 'react-query';
import config from '../../config';
import axiosRequest from '../../axios/axoisRequest';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import CompaniesTable from '../../coolantcare/brands/BrandsTable';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import TabContent from '../../layout/TabContent';
import TabHeader from '../../layout/TabHeader';


const Companies = () => {

	const { user } = useContext(UserContext);

	let columns = [
		{
			id: 'company_id',
			name: 'Company ID',
			Header: () => <Typography variant="p" component="p" fontWeight={600}>{setLanguageText("Company ID")}</Typography>,
			accessor: 'company_id'
		},
		{
			id: 'company_name',
			name: 'Company Name',
			Header: () => <Typography variant="p" component="p" fontWeight={600}>{setLanguageText("Company Name")}</Typography>,
			accessor: 'company_name',
			Cell: ({ row, value }) => <Typography>{value}</Typography>,
			Footer: info => {
				const total = React.useMemo(
					() => info.rows.reduce((sum, row) => 1 + sum, 0),
					[info.rows]
				);
				return (
					<strong
						style={{ fontSize: 16, color: '#000' }}
					>{`${setLanguageText("Totals")}: ${total}`}</strong>
				);
			}
		},
		// {
		//   id: 'edit',
		//   name: 'Edit',
		//   //Header: () => <Typography variant="h6">Edit</Typography>,
		//   accessor: 'company_name',
		//   Cell: ({ row, value }) => (
		//     <FontAwesomeIcon
		//       styles={{ cursor: 'hover' }}
		//       icon={faEdit}
		//       size="lg"
		//       onClick={e => editCompany(row)}
		//     />
		//   )
		// },
		{
			id: 'total_users',
			name: 'Total Users',
			Header: () => <Typography variant="p" component="p" fontWeight={600}>{setLanguageText("Total Users")}</Typography>,
			accessor: 'total_users',
			Cell: ({ row, value }) => {
				console.log(row)
				return (<Typography>{(row.original.all_companies_access + value)}</Typography>)
			},
			Footer: info => {
				const total = React.useMemo(
					() => info.rows.reduce((sum, row) => row.original.all_companies_access + row.original.total_users + sum, 0),
					[info.rows]
				);
				return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
			}
		},
		{
			id: 'total_machines',
			name: 'Total Machines',
			Header: () => <Typography variant="p" component="p" fontWeight={600}>{setLanguageText("Total Machines")}</Typography>,
			accessor: 'total_machines',
			Cell: ({ value }) => {
				return <Typography>{value}</Typography>;
			},
			Footer: info => {
				const total = React.useMemo(
					() =>
						info.rows.reduce((sum, row) => row.values.total_machines + sum, 0),
					[info.rows]
				);
				return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
			}
		},
		{
			id: 'company_disabled',
			name: 'Disabled',
			Header: () => <Typography variant="p" component="p" fontWeight={600}>{setLanguageText("Disabled")}</Typography>,
			accessor: 'company_disabled',
			Cell: ({ value }) => {
				return <Typography>{value? true : ''}</Typography>;
			},
		}
	];

	//get companies
	const { data, isFetching } = useQuery(['companies'],
		({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'companies',
			api_key: user.api_key,
			method: 'get',
			params: {
				table: true,
				//companies_id: user.user.user_permissions.access.companies_id,
				user_level: user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator ? 9 : null,
			}
		}), {
		retry: 1,
		//enabled: !!
	})

	useEffect(() => {
		if (data) {
			console.log(data)
		}
	}, [data]);

	// const update_company = useMutation((id) => {

	// 	return axiosRequest({
	// 		name: 'update company', //added to see which call is erroring
	// 		gateway: config.coolantAPI.URL,
	// 		endpoint: 'companies',
	// 		api_key: user.api_key,
	// 		method: 'get',
	// 		params: {
	// 			id: id
	// 		}
	// 	})
	// }, {
	// 	onSuccess: (response) => {
	// 		if (Object.values(response).length) {
	// 			dispatchSelectedCompany({
	// 				type: SELECTED_COMPANY,
	// 				payload: response
	// 			});
	// 			//console.log(selected[0])
	// 			window.sessionStorage.setItem(
	// 				'selectedCompany',
	// 				JSON.stringify(response)
	// 			);
	// 			navigate({ pathname: `/settings/company/0` })
	// 		}
	// 		//getProducts.reset()
	// 	}
	// })

	// const openCompany = e => {
	//   setCompanyOpen(true);
	// };
	// const closeCompany = () => {
	//   fecthCompanies();
	//   setCompanyOpen(false)
	// };

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5">{setLanguageText("Company Overview")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				{user.user.user_permissions.administrator ?
					<Grid container item spacing={2} xs={12}>
						{data ?
							<CompaniesTable data={data} columns={columns} />
							: null}
					</Grid>
					: <Alert severity='error'>Administrator access required</Alert>}
			</TabContent>
		</>
	);
};

export default Companies;
