export const SAVE_COOLANTS = 'SAVE_COOLANTS';
export const SAVE_COOLANT = 'SAVE_COOLANT';
export const UPDATE_COOLANT = 'UPDATE_COOLANT';
export const SHOW_VISIBLE_COOLANT = 'SHOW_VISIBLE_COOLANT';
export const SAVE_COOLANTS_IN_USE = 'SAVE_COOLANTS_IN_USE';
export const SAVE_COOLANTS_COMPARE = 'SAVE_COOLANTS_COMPARE';
export const SAVE_SELECTED_COOLANT = 'SAVE_SELECTED_COOLANT';
export const RESET_COOLANTS_IN_USE = 'RESET_COOLANTS_IN_USE';
export const TOGGLE_IN_USE = 'TOGGLE_IN_USE';
export const TOGGLE_SORT = 'TOGGLE_SORT';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';

export const CoolantReducer = (state, action) => {
  switch (action.type) {
    case SAVE_COOLANTS:
      return action.payload;
    case SAVE_COOLANT:
      return [...state, action.payload];
    case UPDATE_COOLANT:
      //console.log(action.payload);
      const index = state.findIndex(
        coolant => coolant.coolant_id === action.payload.coolant_id
      );
      const copy_coolants = [...state];
      copy_coolants[index] = action.payload;

      return copy_coolants;
    case SHOW_VISIBLE_COOLANT:
      //console.log(action.payload.data)
      const newArr2 = action.payload.data.map(v => Object.assign(v, {display: false}))
      action.payload.visible.forEach((visible)=>{
        const index = action.payload.data.findIndex(
          coolant => coolant.coolant_id === visible.coolant_id
        );
        //console.log(index)
        if(index > -1){
          newArr2[index].display = true;
        }
      })
      //console.log(newArr2)
      return newArr2;
      
    case SAVE_COOLANTS_IN_USE:
      return action.payload;
    case SAVE_COOLANTS_COMPARE:
      return action.payload;
    case SAVE_SELECTED_COOLANT:
      return action.payload;
    case RESET_COOLANTS_IN_USE:
      return [];
    case TOGGLE_IN_USE:
      return { ...state, inUse: action.payload };
    case TOGGLE_SORT:
      return { ...state, toggleSort: action.payload };
    case TOGGLE_VIEW:
      return { ...state, toggleView: action.payload };
    default:
      return state;
  }
};
