import React, { useEffect, useRef, useState } from 'react';
import {
	Grid,
	Card,
	Typography,
	CardHeader,
	CardContent,
	Button,
	CardActions,
	Avatar,
	Alert,
	Menu,
	IconButton,
	MenuItem,
	Tooltip,
	Box,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFilePdf,
	faMegaphone,
	faPencil,
	faTags,
	faTrash,
	faVideo
} from '@fortawesome/pro-light-svg-icons';
import { useContext } from 'react';
import { UserContext } from '../store/UserContext';
import setLanguageText from '../language/setLanguageText';
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import useActivityLog from '../users/activity/useActivityLog';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useSignedUrl from '../hooks/useSignedUrl';
import { Storage } from 'aws-amplify';
import EditResource from './EditResource';

const FileCard = ({ resource, categories, refreshResources, refetchCategories, showResource }) => {

	const { user } = useContext(UserContext);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [editResource, setEditResource] = useState(false);
	const [initialValues, setInitialValues] = useState();

	//image uploads
	const [slider, setSlider] = useState()
	const [preview, setPreview] = useState();
	const { updateActivityLog } = useActivityLog();
	const { getSignedUrl } = useSignedUrl();


	useEffect(() => {
		if (resource) {
			let object = {
				"resource_id": resource?.resource_id,
				"resource_cat_id": resource?.resource_cat,
				"resource_type": resource?.resource_type,
				"resource_description": resource?.resource_description,
				"resource_file_name": resource?.resource_file_name,
				"resource_cat_name": resource?.resource_cat_name,
				"resource_promotion": resource?.resource_settings?.resource_promotion,
				"resource_announcement": resource?.resource_settings?.resource_announcement,
				"resource_silder_image": resource?.resource_settings?.resource_silder_image
			}
			//console.log(resource)
			if (resource?.resource_settings?.resource_silder_image && (resource?.resource_settings?.resource_promotion && resource?.resource_settings?.resource_announcement)) {
				const getSlider = async () => {
					let extension = resource?.resource_settings?.resource_silder_image.split('/')[1]
					let url = await getSignedUrl(config.s3.BUCKET, `public/resources/slider-${resource.resource_id}.${extension}`)
					setSlider(url)
				};
				getSlider()
			}
			if (resource?.resource_settings?.resource_preview_image) {
				const getCoverImage = async () => {
					let url = await getSignedUrl(config.s3.BUCKET, `public/resources/${resource.resource_id}_preview.png`)
					if (url) {
						setPreview(url)
					}
				};
				getCoverImage()
			}
			//console.log(object)
			setInitialValues(object)
		}
	}, [resource])

	const mutation = useMutation((values) => {
		//console.log(values)
		return axiosRequest({
			endpoint: `resources`,
			method: 'post',
			gateway: config.factoryAPI.URL,
			api_key: user.api_key,
			body: values
		})
	}, {
		onSuccess: async (response, variables) => {
			if (!response.errorMessage) {
				// const file_ext = getFileExtension(file[0])
				// const file_path = `resources/${response.insertId}.${file_ext}`;
				try {

					if (slider) {
						//const image_ext = getFileExtension(slider.type)
						const image_ext = slider.type.split('/')[1]
						//save image as jpeg
						if (image_ext === "jpg") {
							image_ext = "jpeg"
						}
						const slider_image_path = `resources/slider-${variables.resource_id}.${image_ext}`;
						await Storage.put(slider_image_path, slider, {
							progressCallback(progress) {
								//setProgress((progress.loaded / progress.total) * 100);
							},
							contentType: variables.resource_silder_image
						});
					}


				} catch (error) {
					console.log(error);
				} finally {
					const string = `resource ${variables.delete ? 'deleted' : 'updated'}`
					updateActivityLog.mutate({
						activity_details: {
							area: "factory",
						},
						activity_type: string
					})
					setConfirmDelete(false)
					setEditResource(false)
					refreshResources()
					refetchCategories()
					mutation.reset()
				}

			} else {
				console.log(response.errorMessage)
			}
		}
	})

	const handleDeleteFile = () => {
		mutation.mutate({
			delete: true,
			resource_id: resource.resource_id,
		})
	};

	// //console.log(title)
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	return (
		<>
			<Card sx={{
				position: 'relative',
				height: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				background: theme => theme.palette.cards.header,
				'&:hover .resource_description ': {
					bottom: 0,
				}
			}} >
				<CardHeader
					sx={{
						lineHeight: '1.2',
					}}
					disableTypography
					title={
						<>
							<Typography fontWeight="600" variant='p' fontSize='14px' >{resource.resource_file_name}</Typography>
							{/* <Typography variant='caption' component='p' >{resource.resource_description}</Typography> */}
						</>
					}
					action={
						<>
							{user.user.user_permissions.administrator && (
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls={openMenu ? 'long-menu' : undefined}
									aria-expanded={openMenu ? 'true' : undefined}
									aria-haspopup="true"
									onClick={handleClick}
								>
									<MoreVertIcon />
								</IconButton>
							)}
						</>
					}
				/>
				<Menu
					id="long-menu"
					MenuListProps={{
						'aria-labelledby': 'long-button',
					}}
					anchorEl={anchorEl}
					open={openMenu}
					onClose={handleClose}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					slotProps={{
						paper: {
							sx: {
								padding: 0,
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}
					}}
				>
					<MenuItem
						onClick={() => {
							handleClose()
							setEditResource(true)
						}}
					>
						<FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} />
						{setLanguageText("Edit")}
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleClose()
							setConfirmDelete(true)
						}}
					>
						<FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />
						{setLanguageText("Delete")}
					</MenuItem>
				</Menu>
				<CardContent
					sx={{
						background: theme => theme.palette.cards.header,
						flexDirection: 'row-reverse',
						display: "grid",
						height: "100%",
						alignContent: "start",
						p: 0,
						position: 'relative',
						overflow: 'hidden',
					}}
				>
					<Grid container justifyContent="space-between" sx={{ height: 300, position: 'relative' }} >

						{preview ?
							<Box sx={{ width: '100%', height: 300 }}>
								<img src={preview}
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain',
										//objectPosition: '0 0'
									}} />
							</Box>
							: null}
					</Grid>
					{resource.resource_description ?
						<Box
							className="resource_description"
							item
							xs={12}
							sx={{
								position: 'absolute',
								bottom: '-100%',
								left: 0,
								width: '100%',
								background: '#FFFFFF',
								padding: '10px',
								transition: 'all 0.3s ease-in-out',
								boxShadow: '0 0 10px #a8a8a8'
							}}>
							<Typography
								variant="caption"
								sx={{
									//lineClamp: 4,
									//boxOrient: 'vertical',
									// overflow: 'hidden',
									// display: '-webkit-box',
									// height: '82px',
									display: 'flex',
									alignItems: 'flex-end'
								}}
							>{resource.resource_description}</Typography>
						</Box>
						: null}
				</CardContent>

				<CardActions
					sx={{
						alignContent: "end",
						background: (theme) => theme.palette.cards.footer,
					}}
				>
					<Grid container alignItems="center" justifyContent={"space-between"} >
						<Grid item container xs>
							<Grid item>
								<Tooltip title={resource.resource_type} arrow>
									<Avatar
										aria-label="file type" iant="rounded"
										sx={{
											background: 'transparent',
											width: '40px',
											height: '40px',
											'& svg': {
												color: '#333',
											}
										}}
									>
										{resource.resource_type.includes('pdf') ?
											<FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={faFilePdf} />
											:
											<FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={faVideo} />
										}
									</Avatar>
								</Tooltip>
							</Grid>
							{resource?.resource_settings?.resource_promotion ?
								<Grid item>
									<Tooltip title="Promotion" arrow>
										<Avatar
											aria-label="file type"
											//variant="rounded"
											sx={{
												background: 'transparent',
												width: '40px',
												height: '40px',
												'& svg': {
													color: '#333',
												}
											}}
										>
											<FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={faTags} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
							{resource?.resource_settings?.resource_announcement ?
								<Grid item>
									<Tooltip title="Announcement" arrow>
										<Avatar
											aria-label="file type"
											//variant="rounded"
											sx={{
												background: 'transparent',
												width: '40px',
												height: '40px',
												'& svg': {
													color: '#333',
												}
											}}
										>
											<FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={faMegaphone} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
						</Grid>
						{/* <Grid item>
							<Chip
								//color='primary'
								//size="small"
								label={resource.resource_cat_name}
								sx={{ minWidth: 'auto' }}
							/>
						</Grid> */}
						<Grid item xs="auto">
							<Button
								onClick={e => showResource(resource)}
								variant="contained"
							//className={classes.view_button}
							>
								{/* {resource.resource_type.includes('pdf') ? "View PDF" : "Watch Video"} */}
								{resource?.resource_type?.includes('pdf') ? "View" : "Watch"}
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</Card >
			{
				editResource ?
					<EditResource
						initialValues={initialValues} //current resource
						categories={categories}
						editResource={editResource}
						setEditResource={setEditResource}
						refetchCategories={refetchCategories}
						refreshResources={refreshResources}
					/>
					: null}

			{
				confirmDelete &&
				<GlobalDialog
					open={confirmDelete}
					onClose={() => setConfirmDelete(false)}
					title="Delete Resource"
					buttonClick={handleDeleteFile}
					buttonTitle="Delete Resource"
					disabled={mutation.isLoading}
					loading={mutation.isLoading}
					maxWidth="sm"
				>
					<Grid item>
						<Alert severity="error">
							Are you sure you want to delete this resource?
						</Alert>
					</Grid>
				</GlobalDialog>
			}
			{/*{
				initialValues ?
					<Formik
						innerRef={current => formRef.current = current} //access methods
						initialValues={initialValues}
						validationSchema={resouceValidation}
						onSubmit={submitResource}
					>
						{({ handleSubmit, errors }) => {
							// console.log(errors)
							return (
								<GlobalDialog
									open={editResource}
									onClose={() => setEditResource(false)}
									title={'Edit Resource'}
									buttonTitle={'Edit Resource'}
									buttonClick={handleSubmit}
								>
									<ResourceForm
										edit={true}
										fileUrl={fileUrl}
										preview={preview}
										// categories={categories}
										// selectedCategory={selectedCategory}
										// setSelectedCategory={setSelectedCategory}
										// openLevels={openLevels}
										// setOpenLevels={setOpenLevels}
										// signedImageUrl={signedImageUrl}
										// removeSilderImage={removeSilderImage}
										// setSilderUpload={setSilderUpload}
										// sliderImage={sliderImage}
										// setPreviewImage={setPreviewImage}

										categories={categories}
										setFileUpload={setFileUpload}
										setPreviewUpload={setPreviewUpload}
										setSilderUpload={setSilderUpload}
										slider={slider}
										selectedCategory={selectedCategory}
										openLevels={openLevels}
										setOpenLevels={setOpenLevels}
										file={file}

									/>


									{mutation.isLoading || mutation.isSuccess || mutation.isError ?
										<DialogLoader
											isLoading={mutation.isLoading}
											mutation={mutation}
											loadingMessage="Saving Resource..."
											successMessage="Resource Saved"
											closeDialog={() => {
												setEditResource(false)
												refreshResources()
												refetchCategories()
											}}
											fixed
										/>
										: null}
								</GlobalDialog>
							)
						}
						}
					</Formik>

					: null
			} */}
		</>
	);
};

export default FileCard;
