import React, { useState } from "react";
import { Grid } from "@mui/material";
import VendCard from "./VendCard";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import PageContent from "../coolantcare/PageContent";
import supplypro from '../images/xsupplypro.png';
import cribwise from '../images/xcribwise.png';
import supplypoint from '../images/xsupply-point.webp';
import VendIframe from "./VendIframe";
import actstock from '../images/actstock.png';
import IframePage from "../reusable/IframePage";
import config from "../config";

const Vend = () => {

    const [iframeUrl, setIframeUrl] = useState();

    return (
        <>
            <InnerHeaderWrap />
            <PageContent>
                {iframeUrl ?
                    <IframePage url={iframeUrl} />
                    :
                    <Grid
                        container
                        spacing={2}
                        alignContent={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {config.APPNAME === "act" ?
                            <VendCard
                                logo={actstock}
                                text="Gain Complete Control of your Warehouse"
                                buttonText="Sign in to ACTStock"
                                title="ACTStock"
                                link="https://cms.irmatic.net/"
                            />
                            : <>
                                <VendCard
                                    logo={cribwise}
                                    text="Tool inventory management and logistics"
                                    buttonText="Sign in to CribWise"
                                    title="CribWise"
                                    link="https://app.cribwise.com/"
                                />
                                <VendCard
                                    logo={supplypoint}
                                    text="Intelligent inventory vending solution"
                                    buttonText="Sign in to SupplyPoint"
                                    title="SupplyPoint"
                                    link="https://www.supplypoint.com/user/login"
                                />
                            </>
                        }
                        <VendCard
                            logo={supplypro}
                            text="SupplySystem intelligence software"
                            buttonText="Sign in to SupplyPro"
                            title="Supplypro"
                            link="https://supplysystem.supplypro.com/Login.aspx"
                        //setIframeUrl={setIframeUrl}
                        />
                    </Grid>
                }
            </PageContent>
        </>
    );
};





export default Vend;
