import { useState, useEffect } from 'react';

const useImageValidity = (imageUrl) => {
    
    const [isValid, setIsValid] = useState(null);

    useEffect(() => {

        if (!imageUrl) {
            setIsValid(null);
            return;
        }
        const img = new Image();
        img.onload = () => setIsValid(true);
        img.onerror = () => setIsValid(false);
        img.src = imageUrl;

    }, [imageUrl]);

    return isValid;
}
 export default useImageValidity