import React from "react";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import { Backdrop, Grid } from "@mui/material";
import config from "../config";
import GlobalCompanySelect from "../reusable/GlobalCompanySelect";
import SearchBar from "./SearchBar";
import { isMobile } from "react-device-detect";
import Welcome from "../reusable/Welcome";

export default function SearchHeader({ link, large, landing, setValue, value, focused, setFocused, setPagination, searchParams, setSearchParams, backdrop, props }) {

    return (
        <>
            <InnerHeaderWrap props={props}>
                <Grid spacing={isMobile ? 1 : 2} container justifyContent="space-between" alignItems="center" >
                    <Grid item xs={12} md={3} >
                        <GlobalCompanySelect fullWidth large />
                    </Grid>
                    <Grid item xs>
                        <SearchBar
                            large={large}
                            link={link}
                            landing={landing}
                            setValue={setValue}
                            value={value}
                            setFocused={setFocused}
                            setPagination={setPagination}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />
                    </Grid>
                    {!isMobile &&
                        <Grid item md="auto" >
                            <Welcome />
                        </Grid>
                    }
                </Grid>
            </InnerHeaderWrap>
            {backdrop ?
                <Backdrop
                    sx={{
                        position: 'absolute',
                        //color: '#fff',
                        zIndex: 7,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        alignItems: 'flex-start',
                        padding: '1em 2em',
                        backdropFilter: 'blur(5px)',
                        overflow: 'auto',
                    }}
                    open={focused}
                />
                : null}

        </>
    );
};

