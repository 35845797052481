import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTable,
	faBorderAll
} from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from "../store/LayoutContext";
import { UPDATE_LAYOUT } from "../store/LayoutReducers";
import { Grid, ToggleButton, ToggleButtonGroup, TablePagination } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import Sort from "./Sort";


export default function SearchOptions({ hideIcons, pagination, getProducts, isFetching, setSearchParams, searchParams, filters }) {

	const { layout, dispatchLayout } = useContext(LayoutContext);

	//set to grid layout if mobile
	useEffect(() => {
		if (isMobileOnly) {
			dispatchLayout({
				type: UPDATE_LAYOUT,
				payload: {
					layout: 'grid',
				}
			});
		}
	}, [isMobileOnly]);

	const updateLayout = (e, value) => {
		if (value) {
			dispatchLayout({
				type: UPDATE_LAYOUT,
				payload: {
					layout: value,
				}
			});
			sessionStorage.setItem("layout", value);
		}
	};

	return (
		<Grid item container spacing={isMobileOnly ? 1 : 2} alignItems="center" justifyContent="space-between">
			{isMobileOnly || hideIcons ? null :
				<Grid item >
					{layout.layout ?
						<ToggleButtonGroup
							size="small"
							color="primary"
							fullWidth
							value={layout.layout}
							exclusive
							onChange={updateLayout}
							aria-label="layout"
							sx={{ maxWidth: '120px' }}
							disabled={isFetching}
						>
							<ToggleButton value="engineer">
								<FontAwesomeIcon
									icon={faTable}
									size="2x"
								/>
							</ToggleButton>
							<ToggleButton value="grid" >
								<FontAwesomeIcon
									icon={faBorderAll}
									size="2x"
								/>
							</ToggleButton>
						</ToggleButtonGroup>
						: null}
				</Grid>
			}

			<Grid item container xs={12} md alignItems="center">
				<Grid item xs={12} md>
					<TablePagination
						labelRowsPerPage=""
						rowsPerPageOptions={[12, 24, 36]}
						component="div"
						count={getProducts?.data?.hits?.total?.value || 0}
						rowsPerPage={pagination?.pageSize || 0}
						page={pagination?.pageIndex || 0}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							native: true,
						}}
						onPageChange={(_, page) => {
							console.log(page)

							//table.setPageIndex(page)
							const newUrl = searchParams
							newUrl.set("page", page);
							setSearchParams(newUrl)
							//set session storage
							sessionStorage.setItem("pageIndex", page);
						}}
						onRowsPerPageChange={e => {
							const size = e.target.value ? Number(e.target.value) : 50
							//table.setPageSize(size)
							const newUrl = searchParams
							newUrl.set("size", size);
							setSearchParams(newUrl)
							//set session storage
							sessionStorage.setItem("pageSize", size);

						}}
					//ActionsComponent={TablePaginationActions}
					/>
				</Grid>
				<Grid item xs={12} md="auto"
					 sx={{
						'& .MuiAutocomplete-popper': {
							fontSize: '14px'
						},
					}}
				>
					<Sort
						filters={filters}
						getProducts={getProducts}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
					/>
				</Grid>
			</Grid>
		</Grid >
	);
};

