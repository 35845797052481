
import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from "yup"
import { useMutation } from 'react-query';
import { UserContext } from '../store/UserContext';
import setLanguageText from '../language/setLanguageText';
import { LayoutContext } from '../store/LayoutContext';
import LoadingButton from '../coolantcare/buttons/LoadingButton';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import TabHeader from '../layout/TabHeader';
import TabContent from '../layout/TabContent';
import userDBObject from '../users/helpers/userDBObject';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import packageJson from '../../package.json';
import DialogLoader from '../reusable/DialogLoader';

const UserSettings = () => {

  const { user } = useContext(UserContext)
  const { language } = useContext(LayoutContext);
  const [formDefault, setFormDefault] = useState();

  useEffect(() => {

    console.log(user)
    if (user.user) {

      console.log(user.user?.user_settings)
      let formDefault
      if (user.user?.user_settings) {
        //console.log(user)
        formDefault = {
          menu_default: user.user?.user_settings?.menu_default,
          update_notice: user.user?.user_settings?.update_notice,
          app_version: user.user?.user_settings?.app_version,
        }
      } else {
        formDefault = {

          menu_default: true,
          update_notice: true,
          app_version: packageJson.version,  //add the latest version from package.json
        }
      }
      console.log(formDefault)
      setFormDefault(formDefault)
    }
  }, [user])

  const userValidation = Yup.object({
    menu_default: Yup
      .boolean('Set menu default')
  });

  //save user_settings
  const update_setting = useMutation((settings) => {

    //make sure all other settings are not lost
    const fullSettings = {
      ...user.user?.user_settings,
      ...settings
    }
    //console.log(userObject)

    return axiosRequest({
      gateway: config.factoryAPI.URL,
      endpoint: 'administrator/user',
      api_key: user.api_key,
      method: 'post',
      body: {
        update: true,
        update_settings: true,
        user_settings: fullSettings,
        user_id: user.user.user_id,
        aws_sub: user.aws_sub
      }
    })
  }, {
    onSuccess: (data) => {
      if (data.errorMessage) {
        //do something
      }
      //closeDialog()
    }
  })

  const handleSubmit = (values) => {
    console.log(values)
    update_setting.mutate(values)

  }

  return (
    <>
      <TabHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText("My Settings")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </TabHeader>
      <TabContent>
        {formDefault ?
          <Formik
            initialValues={formDefault}
            validationSchema={userValidation}
            onSubmit={handleSubmit}
          >
            {({ values, touched, errors, handleChange, submitForm }) =>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h6">{setLanguageText("Sidebar Menu Default")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Collapsed</Typography>
                    <Switch
                      id='menu_default'
                      checked={values?.menu_default}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Open</Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    id="update_notice"
                    name="update_notice"
                    label="Viewed Latest Update Notice"
                    value={values?.update_notice}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.update_notice && Boolean(errors.update_notice)}
                    helperText={touched.update_notice && errors.update_notice}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    id="app_version"
                    name="app_version"
                    label="Latest App Version Veiwed"
                    value={values?.app_version}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    error={touched.app_version && Boolean(errors.app_version)}
                    helperText={touched.app_version && errors.app_version}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item>
                    <LoadingButton
                      //disabled={true}
                      icon={faSave}
                      text="Update My Settings"
                      iconSize="1x"
                      color="primary"
                      loading={update_setting.isLoading}
                      onClick={submitForm}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          </Formik>
          : null}
          {update_setting.isLoading || update_setting.isSuccess || update_setting.isError ?
            <DialogLoader
              isLoading={update_setting.isLoading}
              mutation={update_setting}
              loadingMessage="Updating User"
              successMessage="Updating User"
              fixed={true}
          
            />
            : null}
      </TabContent>
    </>
  );
};

export default UserSettings;

