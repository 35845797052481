import { Alert, Box, Card, CardContent, CardHeader, Grid, Radio, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../store/UserContext";
import { CompanyContext } from "../store/CompanyContext";

const Delivery = () => {

    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext);

    // AccountName
    // AccountNumber
    // AddressLine1
    // AddressLine2
    // AddressLine3
    // AddressLine4
    // City
    // Country
    // County
    // Held
    // PostCode


    return (

        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: 'text.primary' }} >Delivery Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent sx={{ padding: '1em!important' }}>
                        {selectedCompany.company_erp ?

                            <>
                                <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                    <Radio
                                        checked={true}
                                        name="selected-address"
                                    />
                                    Selected Delivery Address
                                </Typography>
                                <Typography variant="body1" sx={{ marginLeft: '1em', color: 'text.primary' }}>
                                    <strong>{selectedCompany.company_erp.AccountName}</strong><br />
                                    {selectedCompany.company_erp.AddressLine1 ? selectedCompany.company_erp.AddressLine1 : '-'}<br />
                                    {selectedCompany.company_erp.AddressLine2 ? selectedCompany.company_erp.AddressLine2 : '-'}<br />
                                    {selectedCompany.company_erp.City ? selectedCompany.company_erp.City : '-'}<br />
                                    {selectedCompany.company_erp.Country ? selectedCompany.company_erp.Country : '-'}<br />
                                    {selectedCompany.company_erp.PostCode ? selectedCompany.company_erp.PostCode : '-'}
                                </Typography>
                            </>
                            : selectedCompany.company_erp.customerAddressOnly === 0 ?
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="name"
                                        label="Customer Name"
                                        value={selectedCompany.company_erp.customerName}
                                        required
                                    //onChange={handleChange}   
                                    />
                                    <TextField
                                        fullWidth
                                        id="address_1"
                                        label="Address"
                                        value={selectedCompany.company_erp.address_1}
                                        required
                                    //onChange={handleChange}   
                                    />
                                    <TextField
                                        fullWidth
                                        id="address_2"
                                        label="Address line 2 (optional)"
                                        value={selectedCompany.company_erp.address_2}
                                    //onChange={handleChange}   
                                    />
                                    <TextField
                                        id="city"
                                        label="City"
                                        value={selectedCompany.company_erp.city}
                                    //onChange={handleChange}   
                                    />
                                    <TextField
                                        id="postcode"
                                        label="Postcode"
                                        value={selectedCompany.company_erp.postcode}
                                    //onChange={handleChange}   
                                    />
                                </Box>
                                :
                               null
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Delivery;
