import { useContext, useEffect, useState } from "react";
import { UserContext } from "../store/UserContext";
import getUserLevel from "../helpers/getUserLevel";

const useGetPermissions = () => {

    const { user } = useContext(UserContext);
    const [checkoutPermissions, setCheckoutPermissions] = useState(false);

    useEffect(() => {
        console.log(user)
        //if user is a Superuser on any of the below areas, they can give checkout permissions
        if (getUserLevel(user.user, 'buyers', 'allow_checkout', 7)) {
            setCheckoutPermissions(true);
        } else {
            setCheckoutPermissions(false);
        }
    }, [user])

    return {
        checkoutPermissions
    }

};

export default useGetPermissions;