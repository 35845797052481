import QRCode from 'qrcode'

export const dipslideQRCodeCreater = details => {

  //console.log(details)
  let data;
  let options = { 
    errorCorrectionLevel: 'L'
  }
  if (details) {

    QRCode.toDataURL(details, options , function (err, url) {
      data = url
    })
  }
  return data

};

