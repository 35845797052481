import React, { useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, Box, FormControl, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { SelectedContext } from "../../../store/SelectedContext";
import { UPDATE_PARAMETRIC_FILTERS } from "../../../store/SelectedReducers";
import InputLables from "./InputLables";
import { CompanyContext } from "../../../store/CompanyContext";

const MulitSelectImage = ({ field, filtersView }) => {

    const [localValue, setLocalValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { selected, dispatchSelected } = useContext(SelectedContext);
    const { selectedCompany } = useContext(CompanyContext);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (field.Values && selectedCompany?.company_supplier_order) {
            //if field is company, then only show brands that are available to the company  
            if (field.Field === 'COMPC') {
                //console.log(field.Values)
                const clone = structuredClone(field.Values)
                let values = clone.filter(item => {
                    if (selectedCompany?.company_supplier_order.includes(item.ID)) {
                        return item
                    } 
                })
                setOptions(values)
            }
        } else {
            setOptions([]) //set to no options
        }
    }, [field.Values, selectedCompany])

    //Reset field on "Clear Form"
    useEffect(() => {
        //console.log(selected.resetFilters);
        if (selected.resetFilters) {
            setLocalValue([])
            setInputValue('')
        }
    }, [selected.resetFilters]);

    //use QueryString on first load to set fields that are availble
    useEffect(() => {
        if (window.location.search) {
            //console.log('Querystring');
            let urlParams = new URLSearchParams(window.location.search);
            let searchArr = JSON.parse(urlParams.get("search"));
            //console.log(searchArr)
            if (searchArr && Object.values(searchArr).length) {
                let found = searchArr[0].filter(element => element.includes(`${field.Field}§`));
                //console.log(searchArr[index].value) 
                if (Object.values(found).length > 0) {
                    let valueCopy = [...field.Values]
                    let fieldValue = found[0].split('§')[1].split(',');
                    //console.log(fieldValue)
                    let foundItems = (fieldValue).map((item) => {
                        let found = valueCopy.find((value) => value.ID === item);
                        //console.log(found)
                        if (found) {
                            return found
                        }
                    })
                    //console.log('set form item')
                    setLocalValue(foundItems);
                    updateSelected(foundItems);
                } else {
                    setLocalValue([]);
                }
            }
        }
    }, [field, setLocalValue]);

    //update select
    const updateSelected = useCallback((value) => {
        //Update poarametric search - which in turn updates URL using a debounce
        setLocalValue(value);
        //console.log(value);
        let IDs = value.map((item) => item?.ID);
        let Names = value.map((item) => item?.Name);
        //console.log(IDs);
        //IDs = IDs.join('|');
        dispatchSelected({
            type: UPDATE_PARAMETRIC_FILTERS,
            payload: {
                'Field': field.Field,
                'Values': IDs,
                'ValueNames': Names,
                'Name': field.Name,
                "DIN": field.Ref,
                "Toggle": true
            }
        });
    }, [field.Field, dispatchSelected])

    const SelectFieldStyle = {
        padding: 7,
        fontSize: "0.75rem",
    };

    //console.log(localValue)

    return (
        <>
            <InputLables filtersView={filtersView} field={field} />
            <Grid item xs={12} xl={filtersView ? 12 : 6}>
                {localValue && options ?
                    <Autocomplete
                        id={field.ID}
                        multiple
                        //sx={{ minWidth: 300 }}
                        options={options}
                        value={localValue ? localValue : []}
                        onChange={(event, newValue) => {
                            updateSelected(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.Name}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
                                {option.Icon &&
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={option.Icon}
                                        srcSet={option.Icon}
                                        alt=""
                                    />
                                }
                                {option.Name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    width: '100%',
                                    '& input': { zIndex: 2 },
                                    '& legend': { display: 'none' },
                                    '& fieldset': { 
                                        backgroundColor: theme => theme.palette.background.paper,
                                        color: theme => theme.palette.text.primary,
                                    },
                                    '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
                                        zIndex: 2
                                     }
                                }}
                                //label={''}
                                InputLabelProps={{ shrink: false }}
                                placeholder={`Select ${field.Name}`}
                                size="small"
                            />
                        )}
                    />
                    : null}
            </Grid>
        </>
    );
};

export default MulitSelectImage;



// import React, { useContext, useEffect, useRef, useState } from "react";
// import { Autocomplete, Box, TextField } from "@mui/material";
// import { useFormContext } from "react-hook-form";
// import { SelectedContext } from "../store/SelectedContext";
// import ReactTestUtils from "react-dom/test-utils";

// const MulitSelectImage = ({ field, index }) => {

//     const [localValue, setLocalValue] = useState([]);
//     const [inputValue, setInputValue] = useState('');
//     const [localOptions, setLocalOptions] = useState([]);
//     const { selected } = useContext(SelectedContext);
//     const { setValue, unregister, register } = useFormContext(); // retrieve all hook methods
//     //const unique = `${field.Field}_${index}`
//     const unique = field.Field

//     //register & unmount 
//     // useEffect(()=>{
//     //     register(unique , [])
//     //     return () =>{
//     //         //unmount
//     //         unregister(unique)
//     //     }
//     // },[unique])

//     //use QueryString on first load to set fields that are availble
//     useEffect(() => {
//         if (window.location.search) {
//             //console.log('Querystring');
//             let urlParams = new URLSearchParams(window.location.search);
//             let searchArr = JSON.parse(urlParams.get("search"))
//             //console.log(searchArr)
//             let index = (searchArr).findIndex((obj) => obj.name === field.Field);
//             //console.log(searchArr[index].value) 
//             //console.log(field.Values) 
//             if (index > -1) {
//                 setValue(unique, searchArr[index].value);
//                 //update selected on Autocomplete
//                 let valueCopy = [...field.Values]
//                 //console.log(valueCopy)
//                 let foundItems = (searchArr[index].value).map((item) => {
//                     let found = valueCopy.find((value) => value.ID === item)
//                     //console.log(found)
//                     if (found) {
//                         return found
//                     }
//                 })
//                 //console.log(foundItems)
//                 setLocalValue(foundItems);
//             } else {
//                 setValue(unique, '');
//             }
//         }
//     }, [unique]);

//     //set local options
//     useEffect(() => {
//         if (field.Values) {
//             //console.log(field.Values)
//             const options = [...field.Values] // create copy of values
//             setLocalOptions(options.map((item) => item.ID))
//         }
//     }, [field.Values])


//     // hidden select needed to force react-hooks-form to see onChange
//     const hiddenSelect = useRef(unique);
//     const updateHiddenSelect = (event, item) => {
//         setLocalValue(item);
//         let IDs = item.map((item) => item.ID)
//         setValue(unique, IDs);
//          ReactTestUtils.Simulate.change(hiddenSelect.current);
//             hiddenSelect.current.dispatchEvent(
//                 new Event("change", { bubbles: true })
//             );

//     };

//     //force onchange event on hiddden select
//     useEffect(() => {
//         if (localValue) {
//            //console.log('fire click')
//             // ReactTestUtils.Simulate.change(hiddenSelect.current);
//             // hiddenSelect.current.dispatchEvent(
//             //     new Event("change", { bubbles: true })
//             // );
//         }
//     }, [localValue, hiddenSelect])

//     return (
//         <>
//             <select
//                 ref={hiddenSelect}
//                 multiple
//                 name={field.Name}
//                 value={localValue}
//                 onChange={(e) => {
//                     //console.log(e);
//                 }}
//                 readOnly
//                 className="d-none"
//             >
//                 {localOptions &&
//                     (localOptions).map((option, i) => (
//                         <option key={i} value={option}>
//                             {option}
//                         </option>
//                     ))}
//             </select>

//             <Autocomplete
//                 id={field.ID}
//                 multiple
//                 sx={{ minWidth: 300 }}
//                 options={field.Values}
//                 value={localValue}
//                 onChange={(event, newValue) => {
//                     //console.log(newValue)
//                     //console.log(event)
//                     //update hidden select, as react-hooks-form doesn't pickup onChange
//                     updateHiddenSelect(event, newValue);
//                 }}
//                 inputValue={inputValue}
//                 onInputChange={(event, newInputValue) => {
//                     setInputValue(newInputValue);
//                 }}
//                 autoHighlight
//                 getOptionLabel={(option) => option.Name}
//                 renderOption={(props, option) => (
//                     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//                         <img
//                             loading="lazy"
//                             width="20"
//                             src={option.Icon}
//                             srcSet={option.Icon}
//                             alt=""
//                         />
//                         {option.Name}
//                     </Box>
//                 )}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label={field.Name}
//                         placeholder="Please Select"
//                     />
//                 )}

//             />
//         </>
//     );
// };

// export default MulitSelectImage;



// import React, { useContext, useEffect, useRef, useState } from "react";
// import { Autocomplete, Box, TextField } from "@mui/material";
// import { Controller, useFormContext } from "react-hook-form";
// import { SelectedContext } from "../store/SelectedContext";
// import ReactTestUtils from "react-dom/test-utils";

// const MulitSelectImage = ({ field, index, control }) => {

//   const [localValue, setLocalValue] = useState([]);
//   const { selected } = useContext(SelectedContext);
//   const { setValue, getValues, register, watch } = useFormContext(); // retrieve all hook methods
//   const [defaultSelected, setDefaultSelected] = useState([]);
//   //const unique = `${field.Field}_${index}`
//   const unique = field.Field


//   // useEffect(() => {
//   //   //console.log(defaultSelected)
//   //   if (defaultSelected) {
//   //     //console.log('update values')
//   //     setValue(unique, defaultSelected);
//   //   }
//   // }, [setValue, defaultSelected, unique]);

//   const watchFields = watch([unique])

//    //remove value
//    useEffect(() => {
//       if (watchFields[0] === '' || watchFields[0] === undefined) {
//         //console.log(watchFields)
//         //ReactTestUtils.Simulate.change(hiddenSelect.current);
//       } 
//   }, [watchFields]);

//   const hiddenText = useRef();
//   const updateHiddenSelect = (event, item) => {
//     //event.persist()
//     setLocalValue(item);
//     let IDs = item.map((item) => item.ID)
//     setValue(unique, JSON.stringify(IDs));
//     ReactTestUtils.Simulate.change(hiddenText.current);
//     hiddenText.current.dispatchEvent(
//      	new Event("change", { bubbles: true })
//      );
//   };

//   return (
//     <>

//       <TextField
//           ref={hiddenText}
//             fullWidth
//             label={field.Name}
//             //value={localValue}
//             name={field.Field}
//             //defaultValue={localValue} 
//             //InputLabelProps={{ shrink: shrink }}
//             {...register(unique)} // wire up the input ref
//         />
//       {/* <select
//         ref={hiddenSelect}
//         multiple
//         name={field.Name}
//         value={localValue}
//         onChange={(e) => {
//           //console.log(e);
//         }}
//         readOnly
//       //className="d-none"
//       >
//         <option key="selected" value={null}>
//           Select mutipule brands
//         </option>
//         {field.Values &&
//           (field.Values).map((option, i) => (
//             <option key={i} value={option}>
//               {option.Name}
//             </option>
//           ))}
//       </select> */}

//       {defaultSelected &&

//         //   <Controller
//         //   name={unique}
//         //   control={control}
//         //   efaultValue={defaultSelected}
//         //   render={(props) => (
//         //     <Autocomplete
//         //       {...props.field}
//         //       id="address"
//         //       getOptionLabel={(option) => option.Name}
//         //       options={field.Values} 
//         //       value={localValue}
//         //       getOptionSelected={(optionA, optionB) =>
//         //         optionA.Name === optionB.Name
//         //       }
//         //       onChange={(event, newValue) => {

//         //         //setOptions(newValue ? [newValue, ...options] : options);
//         //         setLocalValue(newValue);
//         //         handleChange(newValue)
//         //         props.field.onChange(JSON.stringify(newValue));
//         //       }}
//         //       onInputChange={(event, newInputValue) => {
//         //         setInputValue(newInputValue);
//         //       }}
//         //       renderInput={(params) => (
//         //         <TextField
//         //             {...params}
//         //             label={field.Name}
//         //             margin="normal"
//         //             variant="outlined"
//         //             //error={!!errors.item}
//         //             //helperText={errors.item && "item required"}
//         //             placeholder="Please Select"
//         //             required
//         //           />
//         //       )}
//         //       renderOption={(props, option) => (

//         //                 <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//         //                   <img
//         //                     loading="lazy"
//         //                     width="20"
//         //                     src={option.Icon}
//         //                     srcSet={option.Icon}
//         //                     alt=""
//         //                   />
//         //                   {option.Name}
//         //                 </Box>
//         //               )}
//         //     />
//         //   )}
//         // />
//         // <Controller
//         //   render={(props) => (
//         //     <Autocomplete
//         //       {...props}
//         //       options={field.Values}
//         //       getOptionLabel={(option) => option.Name}
//         //       renderOption={(props, option) => (

//         //         <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//         //           <img
//         //             loading="lazy"
//         //             width="20"
//         //             src={option.Icon}
//         //             srcSet={option.Icon}
//         //             alt=""
//         //           />
//         //           {option.Name}
//         //         </Box>
//         //       )}
//         //       renderInput={(params) => (
//         //         <TextField
//         //           {...params}
//         //           label={field.Name}
//         //           margin="normal"
//         //           variant="outlined"
//         //           //error={!!errors.item}
//         //           //helperText={errors.item && "item required"}
//         //           placeholder="Please Select"
//         //           required
//         //         />
//         //       )}
//         //       onChange={(_, data) => props.field.onChange(data)}
//         //     />
//         //   )}
//         //   name={unique}
//         //   control={control}
//         // />

//         ///BEST?
//         // <Controller
//         //   control={control}
//         //   name={unique}
//         //   //rules={{ required: true }}
//         //   defaultValue={defaultSelected}
//         //   render={({ field: { onChange, value } }) => (
//         //     <Autocomplete
//         //       onChange={(event, item) => {
//         //         //event.persist();
//         //         onChange(item);
//         //        //console.log(item)
//         //         //update hidden field to simulate onChange
//         //         updateHiddenSelect(event, item)
//         //       }}
//         //       multiple
//         //       value={value}
//         //       options={field.Values}
//         //       getOptionLabel={(option) => (option.Name ? option.Name : "")}
//         //       // getOptionSelected={(option, value) =>
//         //       //   value === undefined || value === "" || option.Name === value.Name
//         //       // }
//         //       renderOption={(props, option) => (

//         //         <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//         //           <img
//         //             loading="lazy"
//         //             width="20"
//         //             src={option.Icon}
//         //             srcSet={option.Icon}
//         //             alt=""
//         //           />
//         //           {option.Name}
//         //         </Box>
//         //       )}
//         //       renderInput={(params) => (
//         //         <TextField
//         //           {...params}
//         //           label={field.Name}
//         //           margin="normal"
//         //           variant="outlined"
//         //           //error={!!errors.item}
//         //           //helperText={errors.item && "item required"}
//         //           placeholder="Please Select"
//         //           required
//         //         />
//         //       )}
//         //     />
//         //   )}
//         // />
//           <Autocomplete
//           id={field.ID}
//           multiple
//           sx={{ minWidth: 300 }}
//           options={field.Values}
//           value={localValue}
//           onChange={(event, newValue) => {
//             //console.log(newValue)
//             updateHiddenSelect(event, newValue);
//           }}
//           // inputValue={inputValue}
//           // onInputChange={(event, newInputValue) => {
//           //     setInputValue(newInputValue);
//           // }}
//           autoHighlight
//           getOptionLabel={(option) => option.Name}
//           renderOption={(props, option) => (
//             <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.ID}>
//               <img
//                 loading="lazy"
//                 width="20"
//                 src={option.Icon}
//                 srcSet={option.Icon}
//                 alt=""
//               />
//               {option.Name}
//             </Box>
//           )}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label={field.Name}
//               placeholder="Please Select"
//               // inputProps={{
//               //   ...params.inputProps,
//               //   autoComplete: 'new-password', // disable autocomplete and autofill
//               // }}
//             />
//           )}

//          />
//       }
//     </>
//   );
// };

// export default MulitSelectImage;



