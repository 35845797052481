import React, { useContext, useMemo, useState } from 'react'
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import EditUser from './EditUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimes } from '@fortawesome/pro-light-svg-icons'
import useGetPermissions from '../../../hooks/useGetPermissions'
import TableHeaderCell from '../../../tables/TableHeaderCell'

const CompanyUserTable = ({ users, count, isFetching, refreshTable }) => {

    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const { checkoutPermissions } = useGetPermissions();
    const [sorting, setSorting] = useState([])

    //console.log(checkoutPermissions)

    const columns = useMemo(() => [
        {
            accessorKey: 'user_id',
            header: () => 'User ID',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'user_details',
            header: () => 'Name',
            footer: props => props.column.id,
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <Grid item >
                            {info.row.original.user_details.name} {info.row.original.user_details.surname}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            accessorKey: 'email',
            header: () => 'Email',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'username',
            header: () => 'Username',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        }, {
            accessorKey: 'user_settings',
            enableSorting: false,
            header: () => 'Can checkout',
            footer: props => props.column.id,
            cell: info => {
                //console.log(info)
                return (
                    <Typography variant='h5' sx={{ color: (theme) => info.row.original.user_settings?.can_checkout ? theme.palette.success.main : theme.palette.error.main }}>
                        {info.row.original.user_settings?.can_checkout ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}
                    </Typography>
                )
            },
        }, {
            accessorKey: 'user_settings',
            enableSorting: false,
            header: () => '',
            footer: props => props.column.id,
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        {checkoutPermissions ?
                            <EditUser user={info.row.original} refreshTable={refreshTable} />
                            : null}
                    </Grid>
                )
            },
        }
    ], [refreshTable, checkoutPermissions])

    //console.log(dataQuery.data)

    const table = useReactTable({
        data: users ?? [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    // Manage your own state
    // const [state, setState] = useState(table.initialState)

    // // Override the state managers for the table to your own
    // table.setOptions(prev => ({
    //     ...prev,
    //     state,
    //     onStateChange: setState,
    //     // These are just table options, so if things
    //     // need to change based on your state, you can
    //     // derive them here

    //     // Just for fun, let's debug everything if the pageIndex
    //     // is greater than 2
    //     debugTable: state.pagination.pageIndex > 2,
    // }))

    return (
        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                            <TableHead>
                                {table.getHeaderGroups().map((headerGroup, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {//added a custom header cell
                                            headerGroup.headers.map((header, i) => <TableHeaderCell key={i} header={header} i={i} />)
                                        }
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {isFetching ?
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={36} variant='rounded' /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !isFetching && users?.length > 0 ? table.getRowModel().rows.map((row, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell, i) => {
                                                    return (
                                                        <TableCell key={i}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}

                                            </TableRow>
                                        )
                                    })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No users found</Alert>
                                            </TableCell>
                                        </TableRow>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} >
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        component="div"
                        count={count ? count : -1}
                        rowsPerPage={table.getState().pagination.pageSize}
                        page={table.getState().pagination.pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}

                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CompanyUserTable;
