import React from 'react';
import { Alert } from '@mui/material';
import setLanguageText from '../../language/setLanguageText';
import { Grid } from '@mui/material';

const calcOverdueCoolant = (date, check_freq, language) => {

      if (date) {
            const today = new Date();
            const dateFrom = new Date(date);
            const time_difference = today.getTime() - dateFrom.getTime();
            const day_difference = time_difference / (1000 * 60 * 60 * 24);
            const days = parseInt(day_difference);

            // if(days ) {
            //       return <Alert severity="info">{setLanguageText("Coolant changed ")} { days } {setLanguageText("days ago")}</Alert>
            // }

            if (days > check_freq) {
                  return <Grid item xs={12}><Alert severity="error">{setLanguageText("Coolant")}&nbsp;{setLanguageText("change overdue by")}&nbsp;{days - check_freq}&nbsp;{setLanguageText("day(s)")}</Alert></Grid>
            } else if (days > check_freq - 10) {
                  return <Grid item xs={12}><Alert severity="warning">{setLanguageText("Coolant")}&nbsp;{setLanguageText("cleanout due in")}&nbsp;{check_freq - days}&nbsp;{setLanguageText("day(s)")}</Alert></Grid>
            }
      }
};

export default calcOverdueCoolant;
