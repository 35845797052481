import React, { useState, useContext } from 'react';
import { UserContext } from '../../store/UserContext';
import BrandForm from './BrandForm';
import { useMutation } from "@tanstack/react-query";
import axiosRequest from '../../axios/axoisRequest';
import GlobalDialog from '../../reusable/GlobalDialog';
import useActivityLog from '../../users/activity/useActivityLog';
import config from '../../config';
import DialogLoader from '../../reusable/DialogLoader';

export default function AddBrand({ refetch, brand, setBrand, closeAddDialog, open, updateAdditiveImage, updateCoolantImage }) {

	const { user } = useContext(UserContext);
	const [loading, setLoading] = useState(false);

	const {
		updateActivityLog,
	} = useActivityLog();

	const addBrand = useMutation({
		mutationFn: () => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'brands',
			api_key: user.api_key,
			method: 'post',
			body: {
				brand_name: brand.brand_name
			}
		}),
		onSuccess: (data) => {
			//update activity log
			updateActivityLog.mutate({
				activity_details: {
					area: "coolant",
					brand_name: brand.brand_name,
				},
				activity_type: "added brand"
			})
			//refetch brands
			setLoading(false)
			refetch()
			closeAddDialog()
		}
	})

	//set brand name from form
	const updateBrand = e => {
		setBrand({ brand_name: e.target.value });
	};

	//Create new brand
	const handleSubmit = async e => {
		e.preventDefault()
		addBrand.mutate(brand)
		setLoading(true)
	};

	return (
		<GlobalDialog
			open={open}
			onClose={closeAddDialog}
			title="Add New Brand"
			buttonClick={handleSubmit}
			buttonTitle={"Add Brand"}
			successMessage={"Brand added successfully!"}
			fullWidth
			maxWidth="sm"
		>
			<BrandForm
				brand={brand}
				updateBrand={updateBrand}
				updateAdditiveImage={updateAdditiveImage}
				updateCoolantImage={updateCoolantImage}
				newBrand
			/>
			{loading || addBrand.isLoading || addBrand.isSuccess || addBrand.isError ?
				<DialogLoader
					isLoading={loading}
					mutation={addBrand}
					loadingMessage="Adding Brand"
					successMessage="Adding Brand"
					//closeDialog={closeRefreshDialog}
					fixed
				/>
				: null}
		</GlobalDialog>
	);
};
