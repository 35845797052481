import React from "react";
import { Grid, InputLabel, Typography, useMediaQuery } from "@mui/material";

const InputLables = ({ field, filtersView }) => {

    const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl')) 

    return (
        <Grid item container xs={12} xl={filtersView ? 12 : 6} sx={{ marginBottom: filtersView  || xlDown ?  '5px'  : "none"}} alignContent="center">
            <InputLabel htmlFor={field.Name} sx={{ position: 'relative', transform: 'inherit', margin: '0' }}>
                <Typography sx={{ color: 'text.primary' }} >
                    <span style={{ display: 'inline-block', fontWeight : 600}}>
                        {field.Ref}
                    </span> - {field.Name}
                </Typography>
            </InputLabel>
        </Grid>
    );
};


export default InputLables;
