import React, {
	useState,
	useEffect,
	useCallback,
	useContext
} from 'react';
import {
	faFileCsv,
	faFilePdf,
	faFileExport
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Switch,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	CardContent,
} from '@mui/material';
import { makeStyles } from "@mui/styles"
import formatDate from '../helpers/formatDate';
import { buttonStyles } from '../styles/buttonStyles';
import AllMachinesPDF from '../pdf/AllMachinesPDF';
import { CompanyContext } from '../../store/CompanyContext';
import { MachineContext } from '../store/MachineContext';
import calcDaysPast from '../helpers/calcDaysPast';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../language/setLanguageText';
import GlobalDialogPDF from '../../reusable/GlobalDialogPDF';

const ExportMachines = ({ columns, columnVisibility, data }) => {

	const { selectedCompany } = useContext(CompanyContext);
	const { selectedGroup } = useContext(MachineContext);
	const { language } = useContext(LayoutContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState();
	const [csvData, setCsvData] = useState();
	const [pdfData, setPdfData] = useState();
	const [orientation, setOrientation] = useState('landscape');
	const [downloadObject, setDownloadObject] = useState();

	const setColour = useCallback(status => {
		let colour = '';
		selectedCompany.company_colour_rules.some(attr => {
			if (parseInt(attr.value) === parseInt(status)) {
				// remove transparent as pdf renders as black!
				colour = attr.colour.hex === 'transparent' ? '' : attr.colour.hex;
			}
			return null;
		});
		return colour;
	}, [selectedCompany.company_colour_rules]);

	const handleClick = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const handleChange = (event) => {
		//console.log(event.target.name)
		setSelectedOptions(current => {
			let newOptions = [...current]
			let index = newOptions.findIndex(obj => obj.id === event.target.name);
			newOptions[index].isVisible = !newOptions[index].isVisible;
			return newOptions;
		})
	};

	useEffect(() => {
		//console.log('fire')
		if (columnVisibility) {
			let clone = [...columns]
			clone.forEach(column => {
				//console.log(column)
				let found = columnVisibility[column.id]
				column.isVisible = found
			});
			setSelectedOptions(clone);
		}
	}, [columns, columnVisibility])

	useEffect(() => {

		if (selectedOptions && data) {
			const csvData = [];
			const pdfData = [];
			const headers = [];
			const options = [];

			selectedOptions.forEach(option => {
				if (option.isVisible) {
					headers.push(option.name);
					options.push(option.id);
				}
			});
			data.forEach(item => {
				const csvItem = [];
				const pdfItem = [];

				/*------------- FLATTEN OBJECT ---------------*/
				const flattenObject = obj => {
					const flattened = {};
					Object.keys(obj).forEach(key => {
						if (typeof obj[key] === 'object' && obj[key] !== null) {
							Object.assign(flattened, flattenObject(obj[key]));
						} else {
							flattened[key] = obj[key];
						}
					});
					return flattened;
				};


				let obj;
				//flatten the new attributes
				let factor = item.last_entry.factor
				if (item.last_entry.attributes_new) {
					let array = [];
					item.last_entry.attributes_new.forEach(item => {
						//console.log(item.attribute_id)
						if (item.attribute_id === 'conc' && factor) {
							array.push([[item.attribute_id], { 'factor': factor, ...item }]);
						} else {
							array.push([[item.attribute_id], item]);
						}
					});
					obj = Object.fromEntries(array);
				}

				let flatObj = { ...obj, ...flattenObject(item) };
				if (item.last_entry.additives) {
					flatObj.additives = item.last_entry.additives
				}

				options.forEach(option => {
					//match options
					let match = Object.entries(flatObj).find(
						([key, value]) => key === option
					);
					//console.log(match)
					if (match) {
						//if dates, format
						if (
							match[0] === 'date_stamp' ||
							match[0] === 'last_coolant_change'
						) {
							csvItem.push(formatDate(match[1]));
							pdfItem.push({ value: match[1] ? ` ${formatDate(match[1])} \n ${calcDaysPast(match[1], language, setLanguageText)}` : '-' });
						} else if (
							match[0] === 'additives'
						) {
							//console.log(match[1])
							if (match[1]?.length) {
								let string = match[1].map(add => `${add.additive_type} : ${add.additive_name}(${add.additive_details})\n`)
								csvItem.push(string);
								pdfItem.push({ value: string });

							} else {
								csvItem.push('-');
								pdfItem.push({ value: '-' });
							}

						} else {
							//Custom Attributes
							if (match[1] && typeof match[1] === 'object') {
								let control = setColour(match[1].attribute_values.status);
								if (match[1].factor) {
									csvItem.push(`${(Number(match[1].attribute_values.output) * Number(match[1].factor ? match[1].factor : 1)).toFixed(1)}(${match[1].attribute_values.output})`);
									pdfItem.push({
										value: `${(Number(match[1].attribute_values.output) * Number(match[1].factor ? match[1].factor : 1)).toFixed(1)} (${match[1].attribute_values.output})`,
										control: control
									});
								} else {
									csvItem.push(match[1].attribute_values.output);
									pdfItem.push({
										value: match[1].attribute_values.output,
										control: control
									});
								}
							} else {
								//All other attributes
								csvItem.push(match[1]);
								pdfItem.push({
									value: match[1]
								});
							}
						}
						//null values
					} else {
						csvItem.push('');
						pdfItem.push({ value: '' });
					}
				});
				csvData.push(csvItem);
				pdfData.push(pdfItem);
			});
			//add header to the top
			csvData.unshift(headers);
			setCsvData(csvData);
			pdfData.unshift(headers);
			setPdfData(pdfData);
			//console.log(pdfData);
		}

	}, [selectedOptions, data]);

	return (
		<>
			<Button
				disabled={!data && !selectedOptions ? true : false}
				variant="contained"
				onClick={e => handleClick(e)}
				sx={{ height: '100%' }}
				//size="large"
				endIcon={<FontAwesomeIcon icon={faFileExport} />}
			>
				{setLanguageText("Export Machines")}
			</Button>
			{open ?
				<GlobalDialogPDF
					title={`${selectedCompany.company_name} - ${setLanguageText("Machines")}`}
					open={open}
					onClose={handleClose}
					maxWidth="xl"
					downloadObject={downloadObject}
					orientation={orientation}
					updateOrientation={setOrientation}
					options={selectedOptions}
					handleOptionChange={handleChange}
					
				>
					{pdfData && orientation ?
						<AllMachinesPDF
							title="Machines"
							rows={pdfData}
							orientation={orientation}
							selectedCompany={selectedCompany}
							selectedGroup={selectedGroup}
							setDownloadObject={setDownloadObject}
							language={language}
						/>
						: null}
				</GlobalDialogPDF>
				: null}
		</>
	);
};

export default ExportMachines;
