import { Grid, Typography, useTheme, IconButton, Alert, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../store/CompanyContext';
import Ticker from 'react-ticker'
import DropdownGroups from '../dashboard/Selection_Row/DropdownGroups';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { MachineContext } from '../store/MachineContext';
import CreateChartData from '../helpers/createChartData';
import PieChart from '../dashboard/charts/PieChart';
import BarChart from '../dashboard/charts/BarChart';
import { LayoutContext } from '../../store/LayoutContext';
import InnerHeaderWrap from '../../layout/InnerHeaderWrap';
import PageContent from '../PageContent';
import GlobalCompanySelect from '../../reusable/GlobalCompanySelect';
import axiosRequest from '../helpers/axiosRequest';
import config from '../../config';
import { useQuery } from "@tanstack/react-query";
import { SAVE_GROUPS, SAVE_MACHINES, SELECTED_MACHINES } from '../store/MachineReducers';
import { UserContext } from '../../store/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsMaximize, faCompress } from '@fortawesome/pro-light-svg-icons';
import MachineCard from '../Machines/GridView/MachineCard';
import setLanguageText from '../../language/setLanguageText';

const useStyle = makeStyles(theme => ({
	slider: {
		padding: '10px',
		'& .carousel-status': {
			position: 'fixed!important',
			top: 0,
			right: 0,
			padding: '10px 16px !important',
			fontSize: '20px!important',
			textShadow: 'none!important',
			color: '#fff!important',
			margin: 0,
		}
	},
	ticker: {
		width: '100%',
		height: '70px',
		position: 'fixed',
		background: '#464646',
		padding: '5px',
		color: '#FFF',
		bottom: 0
	},
	header: {
		//height: theme.spacing(8),
		padding: theme.spacing(1, 2),
		background: theme.palette.primary.main,
		transition: 'background .75s',
		zIndex: 15
	},
	companyLogo: {
		width: '70px',
		'& a': {
			width: '100%',
			'& img': {
				width: '100%'
			}
		}
	},
	companyText: {
		color: theme.palette.primary.contrastText,
		textDecoration: 'none'
	}
}));

const DisplayView = () => {

	const { user } = useContext(UserContext);
	const {
		filteredMachines,
		selectedMachine,
		selectedGroup,
		noMachines,
		dispatchSelectedMachines,
		dispatchNoMachines,
		dispatchMachines,
		dispatchGroups
	} = useContext(MachineContext);
	const theme = useTheme();
	const { selectedCompany } = useContext(CompanyContext);
	const { language } = useContext(LayoutContext);
	const [chartData, setChartData] = useState();
	const [rows, setRows] = useState();
	const perScreen = 8;
	const gridSize = 12 / (perScreen / 2)
	const classes = useStyle();
	const [logo, setLogo] = useState('');
	const [fullScreen, setFullScreen] = useState(false);

	useEffect(() => {
		console.log(filteredMachines)
		if (filteredMachines) {
			if (Object.values(filteredMachines).length) {

				let charts = CreateChartData(filteredMachines, selectedCompany.company_attributes, theme, selectedCompany.company_colour_rules, language)
				let chartRows = [];
				let currentChartRow = [];

				//split charts into rows based on perScreen
				charts.forEach((chart, i) => {
					if (i % perScreen === 0 && i !== 0) {
						chartRows.push(currentChartRow);
						currentChartRow = [];
					}
					currentChartRow.push(chart);
				});
				chartRows.push(currentChartRow);

				setChartData(chartRows);

				//split machines into rows based on perScreen
				let rows = [];
				let currentRow = [];

				filteredMachines.forEach((machine, i) => {
					if (i % perScreen === 0 && i !== 0) {
						rows.push(currentRow);
						currentRow = [];
					}
					currentRow.push(machine);
				});
				rows.push(currentRow);
				setRows(rows);

			} else {
				setChartData()
				setRows()
			}
		} else {
			setChartData()
			setRows()
		}
	}, [filteredMachines, selectedCompany.company_attributes, theme, perScreen]);


	const createSlides = (chartData, rows) => {

		return (
			<Carousel
				autoFocus={true}
				showThumbs={false}
				showArrows={false}
				showIndicators={false}
				infiniteLoop={true}
				autoPlay={true}
				//className={classes.slider}
				interval={5000}
			>
				{rows.map((group, i) =>
					<div key={i}>
						<Grid container item spacing={2} alignItems="stretch">
							{group.map((machine) =>
								<Grid key={machine.machine_id} item xs={gridSize} >
									<MachineCard machine={machine} />
								</Grid>
							)}
						</Grid>
					</div>
				)}
				{chartData.map((group, i) =>
					<div key={i}>
						<Grid container item spacing={2} alignItems="stretch">
							{group.map(chart => {
								return (
									chart.chart_type === 'pie' ? (
										<Grid key={chart.id} item xs={gridSize}>
											<PieChart
												results={chart}
											/>
										</Grid>
									) : chart.chart_type === 'bar' ? (
										<Grid key={chart.id} item xs={gridSize}>
											<BarChart
												results={chart}
											/>
										</Grid>
									) : null
								);
							})}
						</Grid>
					</div>
				)}
			</Carousel>
		)
	}

	//get machine entries
	const { data } = useQuery({
		queryKey: ['machine entries', selectedCompany?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'machines/entries',
			api_key: user.api_key,
			method: 'get',
			params: {
				company_id: selectedCompany?.company_id,
				allow_access: true
			}
		}),
		enabled: !!selectedCompany.company_id,
		retry: false
	})

	useEffect(() => {
		if (data) {
			console.log(data)
			dispatchSelectedMachines({
				type: SELECTED_MACHINES,
				payload: data
			});
			dispatchGroups({ type: SAVE_GROUPS, payload: data });
			dispatchMachines({ type: SAVE_MACHINES, payload: data });
			//if no machines, show add machine button
			if (Object.values(data).length) {
				dispatchNoMachines({ type: SAVE_MACHINES, payload: false });
			} else {
				dispatchNoMachines({ type: SAVE_MACHINES, payload: true });
			}
		}
	}, [data])

	console.log(selectedCompany?.company_display_settings)

	return (
		<>
			<InnerHeaderWrap >
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<GlobalCompanySelect fullWidth />
					</Grid>
					<Grid item xs={12} md={3}>
						<DropdownGroups />
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<PageContent>
				<Grid container
					sx={{
						position: fullScreen ? 'fixed' : 'relative',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						width: fullScreen ? '100vw' : '100%',
						height: fullScreen ? '100vh' : '100%',
						zIndex: fullScreen ? 10002 : 1,
						background: theme => fullScreen ? theme.palette.background.main : 'transparent',
						padding: '1em'
					}}
					xs={12}
					spacing={1}
				>
					<Grid
						item
						container
						alignItems="center"
						xs={12}
					>
						<IconButton
							onClick={() => setFullScreen(fullScreen ? false : true)}
							edge="end"
							aria-label="full screen"
							sx={{
								position: 'absolute',
								top: '10px',
								left: '10px',
								width: '25px',
								height: '25px'
							}}
						>
							<FontAwesomeIcon style={{ width: '15px', height: '15px' }} icon={fullScreen ? faCompress : faArrowsMaximize} />
						</IconButton>

						<Grid item xs={12} style={{ textAlign: 'center' }} >
							<Typography variant="h5">{selectedCompany.company_name}</Typography>
						</Grid>
					</Grid>
					<Box
						sx={{
							width: '100%',
							height: '100%',
							padding: '10px',
							'& .carousel-root': {
								width: '100%',
								padding: '10px',
							}
						}}
					>
					{rows && chartData ?
						createSlides(chartData, rows, classes)
						:
						<Alert severity="info" sx={{ width: '100%' }}>
							{setLanguageText("No machines found")}
						</Alert>
					}
					</Box>
					{chartData && selectedCompany?.company_display_settings?.messages?.[0]?.active ?
						<Grid item>
							<Ticker offset="run-in" speed={10} >
								{({ index }) => (
									<h2 style={{ whiteSpace: "nowrap" }}>{selectedCompany.company_display_settings.messages[0].message}</h2>
								)}
							</Ticker>
						</Grid>
						: null}
				</Grid>
			</PageContent>
		</>
	);
};

export default DisplayView;
