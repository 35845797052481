import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Grid,
	ListItemText,
	Autocomplete,
	createFilterOptions,
	TextField,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Button
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSubtitles } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../store/UserContext";
import { useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";;
import highlightText from "../helpers/highlightText";
import { fa00, faBarcodeRead } from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import { CompanyContext } from "../store/CompanyContext";
import { useNavigate } from "react-router-dom";

const filter = createFilterOptions();

export default function LandingSearchBar({ value, setValue }) {

	//get params
	const navigate = useNavigate();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const searchValue = query.get('q');
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const suggestRef = useRef()
	const [suggestions, setSuggestions] = useState([]);
	const isFetching = false
	const [inputValue, setInputValue] = useState(searchValue || '');
	const [defaultManufacturers, setDefaultManufacturers] = useState(selectedCompany?.opensearch_template)

	const getSuggestion = useMutation({
		mutationKey: ['suggestions'],
		mutationFn: ([debouceValue, defaultManufacturers]) => {

			//console.log(debouceValue)
			if (!defaultManufacturers) {
				return
			}
			let params = {
				"q": encodeURI(debouceValue)
			}
			if (defaultManufacturers) {
				params.filters = JSON.stringify({ "manufacturer": defaultManufacturers })
			}

			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/suggest',
				api_key: user.api_key,
				method: 'post',
				body: {
					"params": {
						"querystring": {
							...params
						}
					}
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response)
			//set suggestions
			let merged = []
			response.map((option) => {
				if (option.title === "code") {
					option.hits.map((product) => {
						if (product._source.product_code) {
							//console.log(product._source.product_code)
							merged.push({ "title": product._source.product_code, "value": product._source.product_code, "type": "code" })
						}
					})
				} else if (option.title === "number") {
					option.hits.map((product) => {
						if (product._source.supplier_number) {
							//merged.push(product._source.product_number)
							merged.push({ "title": product._source.supplier_number, "value": product._source.supplier_number, "type": "number" })
						}
					})
				} else {
					option.hits.map((product) => {
						if (product._source.description) {
							//console.log(product._source.description)
							merged.push({ "title": product._source.description, "value": product._source.description, "type": "description" })
						}
					})
				}
			})
			//console.log(merged)
			//push it to the merged array
			setSuggestions(merged)
		}
	})

	useEffect(() => {
		if (selectedCompany?.opensearch_template) {
			setDefaultManufacturers(selectedCompany?.opensearch_template)
		} else {
			setDefaultManufacturers(null)
		}
	}, [selectedCompany])

	useEffect(() => {
		if (inputValue.length > 2 && defaultManufacturers) {
			//console.log(defaultManufacturers)
			getSuggestion.mutate([inputValue, defaultManufacturers])
		}
	}, [inputValue, defaultManufacturers]);

	return (
		<>
			<Grid
				ref={suggestRef}
				container
			>
				<Grid item xs >
					<Autocomplete
						sx={{
							//width: ' 100%',
							position: 'relative',
							zIndex: 1,
							'& .MuiInputBase-root': {
								padding: '0.5em',
								fontSize: '1.5em',
								border: 'none'
							}
						}}
						//getOptionLabel={(option) => option.title}
						getOptionLabel={(option) => {
							//console.log(option)
							// Value selected with enter, right from the input
							if (typeof option === 'string') {
								return option;
							}
							// Add "xxx" option created dynamically
							if (option.value) {
								return option.value;
							}
							// Regular option
							return option.title;
						}}
						//filterOptions={(x) => x}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							const { inputValue } = params;
							// Suggest the creation of a new value
							const isExisting = options.some((option) => inputValue === option.value);
							if (inputValue !== '' && !isExisting) {
								filtered.push({
									"value": inputValue,
									"title": `Search all products with "${inputValue}"`,
									"type": "all"
								});
							}
							return filtered;
						}}
						options={suggestions}
						autoComplete
						includeInputInList
						loading={isFetching}
						loadingText="Loading suggestions..."
						filterSelectedOptions
						value={value}
						clearOnBlur={false}
						freeSolo
						noOptionsText="No Suggestion"
						onChange={(event, newValue) => {
							if (newValue) {
								//console.log(newValue)
								if (!newValue.value) {
									//set value to the input value
									newValue = {
										"value": newValue,
										"title": `Search all products with "${newValue}"`,
										"type": "all"
									}
								}
								//console.log(newValue)
								setSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
								setValue(newValue);
								//getProducts.mutate(newValue)
								//getCodeProducts.mutate(newValue)
								//getDescProducts.mutate(newValue)

							} else {
								//clear
								setValue('')
								setSuggestions([])
							}
						}}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								navigate(`/search?q=${inputValue}&type=all&page=0&size=10`)
							}
						}}
						onInputChange={(event, newInputValue) => {
							//console.log(newInputValue)
							setInputValue(newInputValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									width: '100%',
									'& input': { zIndex: 2 },
									'& legend': { display: 'none' },
									'& fieldset': {
										backgroundColor: theme => theme.palette.background.paper,
										color: theme => theme.palette.text.primary,
									},
									'& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
										zIndex: 2
									}
								}}
								placeholder="Search our products by code, number or description..."
								//label="search" 
								fullWidth
							/>
						)}
						renderOption={(props, option) => {
							return (
								<MenuItem
									key={option.value}
									{...props}
									//exend the props
									onClick={(event) => {
										navigate(`/search?q=${option.value}&type=all&page=0&size=10`)
									}}

								>
									<ListItemIcon>
										{option.type === "description" ?
											<Tooltip title="Descirption" arrow>
												<FontAwesomeIcon icon={faSubtitles} size="sm" />
											</Tooltip>
											: option.type === "code" ?
												<Tooltip title="Product Code" arrow>
													<FontAwesomeIcon icon={faBarcodeRead} size="sm" />
												</Tooltip>
												: option.type === "number" ?
													<Tooltip title="Product Number" arrow>
														<FontAwesomeIcon icon={fa00} size="sm" />
													</Tooltip>
													:
													<FontAwesomeIcon icon={faSearch} size="sm" />
										}
									</ListItemIcon>
									<ListItemText
										sx={{
											fontSize: '10px',
											fontWeight: '700',
											color: "#ccc",
											'& span': {
												fontWeight: '700',
												color: "#000"
											},
											'& em': {
												fontStyle: 'normal',
												fontWeight: '300',
											}
										}}
									>
										{
											//console.log(value)
											//ReactHtmlParser(option.title)
											highlightText(option.title, inputValue)
										}
									</ListItemText>
								</MenuItem>
							);
						}}
					/>
				</Grid>
				<Grid item xs="auto">
					<Button
						color="primary"
						variant="contained"
						sx={{
							//position: 'absolute',
							marginLeft: '10px',
							//top: "15px",
							height: '100%',
							p: '1em 2em',
							zIndex: 2

						}}
						aria-label="directions"
						onClick={e => {
							navigate(`/search?q=${inputValue}&type=all&page=0&size=10`)
						}}
					>
						{/* {getProducts.isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="sm" />} */}
						<FontAwesomeIcon icon={faSearch} size="3x" />
					</Button>
				</Grid>
			</Grid>
		</>
	);
};