import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material';
import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';


const KPISkeleton = () => {

  return (


    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        disableTypography
        sx={{
          background: theme => theme.palette.primary.main,
          color: theme => theme.palette.primary.contrastText,
          fontSize: '1em'
        }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon sx={{ color: theme => theme.palette.primary.contrastText }} />
          </IconButton>
        }
        title={<Skeleton variant="text" width={'100%'} />}
      />
      <CardContent sx={{ display: 'flex', alignContent: 'flex-start' }}>

        <Grid container item xs={12} spacing={2} sx={{ position: 'relative', zIndex: 1 }}>
          <Grid item xs={12} sx={{ position: 'relative', zIndex: 1 }} >
            <Typography variant="h6" component="h2" sx={{ fontSize: '5em', textAlign: 'center' }}>
              <Skeleton variant="text" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative', zIndex: 1 }}>
            <Typography variant="h6" component="h2" sx={{ textAlign: 'center', marginTop: '-25px' }}>
              <Skeleton variant="text" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>

      </CardContent>
      <CardActions sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flex: 'auto',
        padding: '1em',
        position: 'relative',
        zIndex: 1
      }}>
        <Grid item container spacing={1}>
          <Grid item>
            <Skeleton variant="rectangular" width={'100%'} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default KPISkeleton;
