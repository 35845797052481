import React, { useEffect, useContext, useState } from 'react';
import { MachineContext } from "../coolantcare/store/MachineContext";
import { CompanyContext } from "../store/CompanyContext";
import { UserContext } from "../store/UserContext";
import config from "../config";
import axiosRequest from "../axios/axoisRequest";
import { ADD_ENTRIES, FILTER_MACHINES, SAVE_GROUPS, SAVE_MACHINES, SELECTED_MACHINES } from '../coolantcare/store/MachineReducers';
import { useMutation } from '@tanstack/react-query';

export default function useMachineEntry() {

    const { dispatchSelectedMachine, dispatchSelectedMachines, selectedMachine, dispatchMachines, dispatchGroups, dispatchFilteredMachines } = useContext(MachineContext);
    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    // const [dateFrom, setDateFrom] = useState();
    // const [dateTo, setDateTo] = useState();

    // useEffect(() => {
    //     if (selectedMachine) {
    //         let lastEntry = new Date(selectedMachine?.last_entry?.date_stamp)
    //         let lastYear = new Date(selectedMachine?.last_entry?.date_stamp).setMonth(lastEntry.getMonth() - 12);
    //         setDateFrom(lastYear);
    //         setDateTo(lastEntry.getTime());
    //     }
    // }, [selectedMachine])

    //save edit machine entry
    const refetchMachineEntries = useMutation({
        mutationFn: ({ dateFrom, dateTo }) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines/entries',
            api_key: user.api_key,
            method: 'get',
            params: {
                machine_id: selectedMachine?.machine_id,
                date_from: dateFrom,
                date_to: dateTo,
                voided: true,
                allow_access: user.user.user_permissions.administrator || user.user.user_permissions.access.all_companies || user.user.user_permissions.access.companies.includes(selectedCompany.company_id) ? true : false
            }
        }),
        onSuccess: (response) => {
            if (response) {
                dispatchSelectedMachine({
                    type: ADD_ENTRIES,
                    payload: response
                });
            }
        }
    })

    //save edit machine entry
    const refetchMachines = useMutation({
        mutationFn: () => {
            let params = {
                company_id: selectedCompany?.company_id,
                allow_access: true
            }
            //select all machines available to user
            if (selectedCompany?.company_id === 0) {
                //admin or all access
                if (user.user.user_permissions.access.all_companies || user.user.user_permissions.administrator) {
                    params = {
                        all_machine_entires: true,
                        allow_access: true
                    }
                } else {
                    //not admin or all access, so get all compnaies they are allowed
                    params = {
                        company_ids: user.user.user_permissions.access.companies,
                        allow_access: true
                    }
                }
            }
            return axiosRequest({
                gateway: config.coolantAPI.URL,
                endpoint: 'machines/entries',
                api_key: user.api_key,
                method: 'get',
                params: params
            })
        },
        onSuccess: (response) => {
            if (response) {
                console.log(response)
                dispatchSelectedMachines({
                    type: SELECTED_MACHINES,
                    payload: response
                });
                dispatchGroups({ type: SAVE_GROUPS, payload: response });
                dispatchMachines({ type: SAVE_MACHINES, payload: response });
                dispatchFilteredMachines({
                    type: FILTER_MACHINES,
                    payload: response
                });
            }
        }
    })

    return {
        refetchMachineEntries,
        refetchMachines
    }
}