import React, { useContext } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import createPercentage from '../../helpers/createPercentage';
import setLanguageText from '../../../language/setLanguageText';
import { CompanyContext } from '../../../store/CompanyContext';

const ProgressChart = ({ checked, totalMachines }) => {

	const percentage = checked ? (checked / totalMachines) * 100 : 0;
	const { selectedCompany } = useContext(CompanyContext);	

	const getColorByStatus = (status) => {
		const rule = selectedCompany.company_colour_rules.find(attr => parseInt(attr.value) === parseInt(status));
		return rule ? (rule.colour.hex === 'transparent' ? '' : rule.colour.hex) : '';
	};

	return (
		<Grid item
			sx={{
				position: 'relative',
				height: '100%'
			}}
		>
			<LinearProgress
				style={{ height: '100%' }}
				variant="determinate"
				className="progression-bar"
				sx={{
					height: theme => theme.spacing(8),
					borderRadius: 0,
					'& .MuiLinearProgress-barColorPrimary': {
						//backgroundColor: theme => theme.palette.charts.third ? theme.palette.charts.third : theme.palette.success.main
						backgroundColor: getColorByStatus(10) || theme.palette.charts.third || theme.palette.success.main
					},
					'&.MuiLinearProgress-determinate': {
						//backgroundColor: theme => theme.palette.charts.first ? theme.palette.charts.first : theme.palette.error.main
						backgroundColor: getColorByStatus(30) || theme.palette.charts.first || theme.palette.error.main
					}
				}}
				value={percentage}
			/>
			<Grid
				item
				container
				direction="column"
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					color: theme => theme.palette.background.light,
					padding: '.75em'
				}}
			>
				<Typography sx={{ color: (theme) => theme.palette.primary.contrastText }} variant="h4" component="p" align="center">
					<strong>{`${percentage ? createPercentage(percentage) : '0%'}`}</strong>
				</Typography>
				<Typography sx={{ color: (theme) => theme.palette.primary.contrastText }} variant="p" component="p" align="center" >
					<strong>{setLanguageText("Checked")}</strong>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default ProgressChart;
