import {
	Chip,
	Grid,
	Skeleton,
	Typography,
	Alert,
	useMediaQuery,
	Card,
	Divider
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import { useMutation } from 'react-query';
import config from '../config';
import { UserContext } from '../store/UserContext';
import axiosRequest from '../axios/axoisRequest';
import ReactHtmlParser from 'html-react-parser';
import FileView from '../media/FileView';
import SolutionsComments from './SolutionsComments';
import InnerContentWrap from '../layout/InnerContentWrap';
import { isMobileOnly } from 'react-device-detect';

const SolutionsView = () => {

	const { user } = useContext(UserContext);
	const [request, setRequest] = useState();
	const [checked, setChecked] = useState(false);
	const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

	// gets request full details
	const request_mutation = useMutation((details) => {
		//console.log(user)
		return axiosRequest({
			method: 'get',
			endpoint: 'requests',
			gateway: config.solutionAPI.URL,
			api_key: user.api_key,
			params: details
		})

	}, {
		onSuccess: (data) => {
			//console.log(data)
			if (data) {
				let parseObject = {
					...data[0],
					request_company: data[0].request_company,
					request_files: data[0].request_files,
					request_last_updated: data[0].request_last_updated,
					request_user: data[0].request_user,
				}
				setRequest(parseObject)
				let closed = Boolean(data[0].request_status === "Closed")
				setChecked(closed)
				//comments_mutation.mutate({ comment_request_id: data[0].request_id })
			}
		}
	})

	useEffect(() => {
		if (user) {
			const urlParams = new URLSearchParams(window.location.search);
			let company_id = urlParams.get("company")
			let request_id = urlParams.get("request")
			//console.log(user.user.user_permissions.access.companies.includes(parseInt(company_id)))
			//admin always allowed to access request
			if (user.user.user_permissions.administrator) {
				request_mutation.mutate({ company_id: company_id, request_id: request_id })
			} else if (user.user.user_permissions.access.companies.includes(parseInt(company_id))) {
				//check users allowed companies in
				request_mutation.mutate({ company_id: company_id, request_id: request_id })
			} else {
				//no access to this copnaies request
			}
			//request_mutation.mutate({ user: user, id: id })
		}
	}, [user, window.location.search])


	return (
		<>
			<InnerHeaderWrap >
				<Grid item container xs={12} spacing={2} justifyContent="space-between" alignItems="center">
					<Grid item xs={10}>
						<Typography component="h5" variant='h5'>{request ? request.request_name : <Skeleton />}</Typography>
					</Grid>
					<Grid container item xs={12} md={2} justifyContent={isMd ? "flex-end" : "flex-end"}>
						<Grid item><Chip label={request?.request_status} sx={{ marginRight: '1em', borderRadius: '5px', fontSize: '1em' }} /></Grid>
						<Grid item><Typography component="p" variant='h6' fontWeight={400} textAlign="right">#{request?.request_id}</Typography></Grid>
					</Grid>
				</Grid>
			</InnerHeaderWrap>
			<InnerContentWrap>
				<Card>
					<Grid item container alignContent="center"
						sx={{
							background: '#fff',
							position: 'relative',
							padding: isMobileOnly ? '1em' : '2em'
						}}
						spacing={2}
					>
						<Grid item xs={12} container spacing={2}>
							{request ?
								<>
									<Grid item xs={12}>
										<Typography component="p" variant='p' textAlign="center" >Added by <strong>{request?.request_user?.user_details?.name} {request?.request_user?.user_details?.surname}</strong> on <strong>{new Date(request?.request_submit_date).toLocaleDateString()} {new Date(request?.request_submit_date).toLocaleTimeString()}</strong></Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography component="p" variant='p' textAlign="center" >Required by <strong>{new Date(request?.request_required_date).toLocaleDateString()}</strong></Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography component="p" variant='p' textAlign="center" >Company <strong>{request?.request_company?.company_name}</strong></Typography>
									</Grid>
								</>
								: null}
						</Grid>
						<Grid item xs={12}>
							<Divider sx={{ margin: '2em auto' }} />
						</Grid>
						<Grid item xs={12}>
							<Typography textAlign="center" component="p" variant='h5' fontWeight={600}>{request ? request.request_name : <Skeleton />}</Typography>
						</Grid>
						<Grid item xs={12} container spacing={2}>
							<Grid item xs={12}>
								<Typography textAlign="center" component="p" variant='h5' fontWeight={600}>Details</Typography>
							</Grid>
							<Grid item xs={12} textAlign="center">
								{request ?
									ReactHtmlParser(request.request_details)
									: <>
										<Skeleton />
										<Skeleton />
										<Skeleton />
										<Skeleton />
										<Skeleton width={140} />
									</>
								}
							</Grid>

							<Grid item xs={12}>
								<Typography textAlign="center" component="p" variant='h5' fontWeight={600}>Uploads</Typography>
							</Grid>
							<Grid item xs={12}>
								{request?.request_files?.files ?
									<>
										{Object.values(request.request_files.files).length ?

											<Grid container item spacing={4} >
												{request.request_files.files.map((file, i) => {
													return (
														<React.Fragment key={i}>
															<FileView
																request_id={request?.request_id}
																file={file}
															/>
														</React.Fragment>
													)
												})}
											</Grid>
											:
											<Alert severity='info'>No files uploaded with original request.</Alert>
										}
									</>
									:
									<Grid container item spacing={4} >
										<Grid item >
											<Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
										</Grid>
										<Grid item >
											<Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
										</Grid>
										<Grid item >
											<Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" height={120} width={120} />
										</Grid>
									</Grid>
								}
							</Grid>
						</Grid>
					</Grid>
				</Card>
				<SolutionsComments request={request} checked={checked} setChecked={setChecked} />
			</InnerContentWrap>
		</>
	);
}

export default SolutionsView;
