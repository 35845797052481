import React, { useContext } from 'react';
import { Typography, ListItemText } from '@mui/material';
import { ShopContext } from '../../store/ShopContext';

const ProductGroupFullList = ({ product }) => {

	const { filters } = useContext(ShopContext);

	return (
		<>
			{filters?.productFilters && Object.values(filters.productFilters).length ?
				<>
					{filters.productFilters.map((button) => {
						//console.log(button)
						//console.log(product.product_attributes)
						//console.log(product.product_normalised_attributes)
						let found = product.product_normalised_attributes.find((item) => (parseInt(item.id) === button.i));
						//console.log(found)
						if (found) {
							return (
								<ListItemText
									primary={
										<Typography
											variant="subtitle2"
											sx={{
												textTransform: 'capitalize'
											}}
										><strong>{found.name}</strong> : {found.value}{found.unit}</Typography>
									}
								/>
							)
						}
					}
					)}
				</>
				: null}
		</>
	);
}
export default ProductGroupFullList;