import React, { useState } from 'react';
import config from '../config';
import { Text, View, Image } from '@react-pdf/renderer';

const PDFFooter = ({ styles, theme, footerLogo }) => {

    const year = new Date();
    const [ActLogo, setActLogo] = useState();
    import(`../images/act-group-black.png`).then((module) => {
        setActLogo(module.default);
    });

    return (
        <View style={{ marginTop: '10px'}} >
            {config.APPNAME === 'act' && ActLogo ?
                <View style={[styles.HEADER, {textAlign: 'center', padding: '16px 16px 11px 16px' }]}>
                     {ActLogo ? <Image src={ActLogo} style={{ width: '100%', maxWidth: '200px', height: 'auto', maxHeight: '100px', objectFit: 'contain', margin: '0 auto'  }} /> : null}
        
                </View>
         : null }
            <View style={[styles.HEADER, { backgroundColor: theme.palette.primary.main, textAlign: 'center', padding: '16px 16px 11px 16px', }]}>
                <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}>{`${config.APPTITLE} - copyright © ${year.getFullYear()}`}</Text>
                {/* <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}> Designed &amp; Developed by Design Monkey Media ltd</Text>
                <Text style={[styles.TEXT, { color: theme.palette.primary.contrastText }]}> Powered by AWS </Text> */}
                <Text
                    style={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                        fontSize: 10,
                        textAlign: 'center',
                        color: theme.palette.primary.contrastText
                    }}
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} />
            </View>
        </View>
    )
};

export default PDFFooter;  
