import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobileOnly } from 'react-device-detect';
import SingleImage from "../shop/reusable/SingleImage";


const ProductSlider = ({ images, url }) => {
  
  return (
    <Box sx={{
      '& .carousel': {
        '& .slide': {
          padding: '0 34px'
        },
        '& .thumbs-wrapper': {
          paddingTop: '1em',
          marginTop: '1em',
          borderTop: '1px solid #ccc'
        },
        '& .thumbs': {
          margin: 0,
          padding: 0,
          '& .thumb': {
            width: isMobileOnly ? '60px!important' : '80px!important',
            height: isMobileOnly ? '60px!important' : '80px!important'

          }
        },
        '& .thumb.selected, .carousel .thumb:hover': {
          borderColor: (theme) => theme.palette.primary.main
        },
        '& .control-prev.control-arrow:before': {
          borderRight: (theme) => `8px solid ${theme.palette.primary.main}`
        },
        '& .control-next.control-arrow:before': {
          borderLeft: (theme) => `8px solid ${theme.palette.primary.main}`
        },
        '&.carousel-slider .control-arrow:hover': {
          background: '#ffffffe0'
        }
      }
    }}>
      {images &&
        <Carousel
          showIndicators={false}
          showStatus={false}
          // renderArrowPrev={(onClickHandler, hasPrev, label) =>
          //   hasPrev && (
          //     <button type="button" onClick={onClickHandler} title={label} >
          //       -
          //     </button>
          //   )
          // }
          // renderArrowNext={(onClickHandler, hasNext, label) =>
          //   hasNext && (
          //     <button type="button" onClick={onClickHandler} title={label} >
          //       +
          //     </button>
          //   )
          // }
          renderThumbs={(children) => {
            //console.log(children)
            return children.map((item, i) => {
              //console.log(item)
              return (
              <Box key={i} sx={{ width: '100%', height: isMobileOnly ? '60px!important' : '70px!important', position: "relative" }}>
                <SingleImage imageUrl={item.props.children.props.imageUrl} alt="descrption" height={isMobileOnly ? '60px' : '70px'} viewer={item.props.children.props.viewer} thumbnail />
              </Box>
              )}
            )
          }}
        >
          {images.map((image, i) => {
            return (
              <Box key={i} sx={{ width: '100%', height: isMobileOnly ? '200px' : '340px', position: "relative" }}>
                <SingleImage imageUrl={image.url} alt={image.description} height={isMobileOnly ? '180px' : '320px'} icon="6x" viewer={image?.viewer} />
              </Box>
            )
          })}
        </Carousel>
      }
    </Box>
  );

}

export default ProductSlider