export const SAVE_ADDITIVES = 'SAVE_ADDITIVES';
export const SAVE_ADDITIVE = 'SAVE_ADDITIVE';
export const UPDATE_ADDITIVE = 'UPDATE_ADDITIVE';
export const SAVE_ADDITIVES_IN_USE = 'SAVE_ADDITIVES_IN_USE';
export const SAVE_ADDITIVES_COMPARE = 'SAVE_ADDITIVES_COMPARE';
export const SAVE_SELECTED_ADDITIVE = 'SAVE_SELECTED_ADDITIVE';
export const RESET_ADDITIVES_IN_USE = 'RESET_ADDITIVES_IN_USE';
export const TOGGLE_IN_USE = 'TOGGLE_IN_USE';
export const TOGGLE_SORT = 'TOGGLE_SORT';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';

export const AdditiveReducer = (state, action) => {
  switch (action.type) {
    case SAVE_ADDITIVES:
      return action.payload;
    case SAVE_ADDITIVE:
      return [...state, action.payload];
    case UPDATE_ADDITIVE:
      const index = state.findIndex(
        additive => additive.additive_id === action.payload.additive_id
      );
      const copy_additives = [...state];
      copy_additives[index] = action.payload;

      return copy_additives;
    case SAVE_ADDITIVES_IN_USE:
      return action.payload;
    case SAVE_ADDITIVES_COMPARE:
      return action.payload;
    case SAVE_SELECTED_ADDITIVE:
      return action.payload;
    case RESET_ADDITIVES_IN_USE:
      return [];
    case TOGGLE_IN_USE:
      return { ...state, inUse: action.payload };
    case TOGGLE_SORT:
      return { ...state, toggleSort: action.payload };
    case TOGGLE_VIEW:
      return { ...state, toggleView: action.payload };
    default:
      return state;
  }
};
