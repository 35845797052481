import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DonutChart from './DonutChart';
import KPIMenu from './KPIMenu';
import config from '../config';

const KPICard = ({ kpi, refetchKPIs ,groupOptions, areaOptions}) => {

  const [prefix, setPrefix] = useState();
  const [chart, setChart] = useState();

  useEffect(() => {
    //console.log(kpi)
    if(kpi.kpi_settings){
      const settings = JSON.parse(kpi.kpi_settings)
      setPrefix(settings.prefix)
      setChart(settings.chart)
    } 
  }, [kpi])

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        disableTypography
        sx={{
          background: theme => theme.palette.primary.main,
          color: theme => theme.palette.primary.contrastText,
          fontSize: '1em'
        }}
        // action={
        //   <KPIMenu kpi={kpi} refetchKPIs={refetchKPIs} groupOptions={groupOptions} areaOptions={areaOptions} />
        // }
        title={kpi.kpi_area ? kpi.kpi_area : config.APPTITLE}
      />
      <CardContent sx={{ display: 'flex', alignContent: 'flex-start' }}>

        <Grid container item xs={12} spacing={2} sx={{ position: 'relative', zIndex: 1 }}>
          {chart === 'pie' &&
            <Grid item sx={{ position: 'absolute', zIndex: 0, top: 0, textAlign: 'center', width: '100%' }}>
              <Box sx={{ position: 'relative', width: '200px', margin: '0 auto', top: '-40px' }}>
                <DonutChart />
              </Box>
            </Grid>
          }
          <Grid item xs={12} sx={{ position: 'relative', zIndex: 1 }} >
            <Typography variant="h6" component="h2" sx={{ fontSize: '4em', textAlign: 'center' }}>
              {prefix ? prefix : null}{kpi.kpiv_values ? kpi.kpiv_values : '0000'}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative', zIndex: 1 }}>
            <Typography variant="h6" component="h2" sx={{ textAlign: 'center', marginTop: '-25px' }}>
              {kpi.kpi_description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flex: 'auto',
        padding: '1em',
        position: 'relative',
        zIndex: 1
      }}>
        <Grid item container spacing={1}>
          {kpi?.kpi_tags ?
            <>
              {kpi.kpi_tags.map((tag, index) =>
                <Grid item key={index}>
                  <Chip label={tag.name} sx={{ borderRadius: '5px' }} />
                </Grid>
              )}
            </>
            : null}
        </Grid>
      </CardActions>
    </Card>
  );
}

export default KPICard;
