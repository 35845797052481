import React, { useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardActions, Grid, Modal, Skeleton, useTheme } from '@mui/material';
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ProductLoadingCard = () => {

	return (
		<>
			<Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >
				<CardActionArea>
					<Skeleton variant="rectangular" width={'100%'} height={140} />
					<CardContent
						sx={{ background :(theme) => theme.palette.cards.header , width: '100%', flexDirection: 'row-reverse' }}
					>
						<Box sx={{
							position: 'absolute',
							width: '90px',
							height: '40px',
							overflow: 'hidden',
							top: 0,
							right: 0,
							borderBottom: '1px solid #eeeeee29',
							borderLeft: '1px solid #eeeeee29',
							padding: '5px',
							background: '#ffffff29',
							borderRadius: '0 0 0 10px'
						}}>
						</Box>
						<Typography gutterBottom variant="h5" component="div">
							<Skeleton /> 
						</Typography>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
					</CardContent>
				</CardActionArea>
				<CardActions sx={{ background: (theme) => theme.palette.background.default }}>
						<Skeleton width="100%" height={80} sx={{ marginBottom: 0 }} />
				</CardActions>
			</Card>
		</>
	);
}


export default ProductLoadingCard;