
import React, { useContext } from 'react';
import { Grid, Divider } from '@mui/material';
import { makeStyles } from "@mui/styles"
import Alert from '@mui/material/Alert';
import setLanguageText from '../../language/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiAlert-icon': {
            alignItems: 'center'
        }
    },
    warnings: {
        padding: theme.spacing(2)
    }
}));

export default function Alerts({ errors, showWarning, showError, voidError, sqlErrors }) {

    const classes = useStyles();

    return (
        <div className={classes.root} >
            {sqlErrors && (
                <>
                    <Grid item className={classes.warnings}>
                        <Alert severity="warning" variant="standard">
                            {sqlErrors}
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {showWarning && errors && (
                <>
                    <Grid item className={classes.warnings}>
                        <Alert severity="warning" variant="standard">
                            <Grid item container>
                                {errors.map((err, i) => {
                                    if (err.type === "warning") {
                                        return <Grid key={i} item xs={12} >{err.message}</Grid>
                                    }
                                    return null
                                })}
                            </Grid>
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {showError && errors && (
                <>
                    <Grid item className={classes.warnings} >
                        <Alert severity="error" variant="standard">
                            <Grid item container>
                                {errors.map((err, i) => {
                                    if (err.type === "error") {
                                        return <Grid key={i} item xs={12} >{err.message}</Grid>
                                    }
                                    return null
                                })}
                            </Grid>
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {voidError && errors && (
                <>
                    <Grid item className={classes.warnings} >
                        <Alert severity="error" variant="standard">
                            {setLanguageText("Changing this vaule will void this entry")}

                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    )
};

