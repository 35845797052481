import React, { useContext } from "react";
import AWS from 'aws-sdk';
//import { Auth } from 'aws-amplify';
import config from "../config";
//import { UserContext } from "../store/UserContext";

const useSignedUrl = () => {

    //const { user } = useContext(UserContext)
    const s3 = new AWS.S3({ region: config.s3.REGION });

    const getSignedUrl = async (bucket, key) => {

        var params = {
            Bucket: bucket,
            Key: key
        };

        let url = s3.getSignedUrl("getObject", params);
        // s3.getSignedUrl('getObject', params, function (err, signedUrl) {
        //     if (err) {
        //         console.log(err)
        //             // Auth.currentCredentials().then(async (credentials) => {
        //             //     //console.log(credentials)
        //             //     return AWS.config.update({
        //             //         accessKeyId: credentials.accessKeyId,
        //             //         secretAccessKey: credentials.secretAccessKey,
        //             //         sessionToken: credentials.sessionToken
        //             //     })
        //             // })
        //             // //try again
        //             // url = s3.getSignedUrl("getObject", params)
        //     } else {
        //         url = signedUrl
        //     }
        // })
        //console.log(url)
        return url
    }
    return  {
        getSignedUrl
    }
};

export default useSignedUrl;

