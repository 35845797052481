import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Tooltip, Typography, Box } from "@mui/material"
import { faFileCircleQuestion, faFileImage, faFilePdf, faFileVideo, faTimes } from "@fortawesome/pro-light-svg-icons"
import { Document, Thumbnail } from 'react-pdf';
import getVideoFrameBase64FromFile from "./functions/getVideoFrameBase64FromFile";
import '../pdfWorker';
import SpinningLoader from "../reusable/SpinnningLoader";

const DocumentCanvas = ({ canvas, setPreviewImage, setLoading }) => {


	//console.log(canvas)
	const canvasRef = useRef();

	const onRenderSuccess = () => {
		//console.log('loaded sucess')
		if (canvasRef.current) {
			let canvas1 = document.getElementsByClassName("react-pdf__Thumbnail__page__canvas");
			//add a delay to allow the canvas to render
			let url = canvas1[0].toDataURL("image/png");
			setPreviewImage(url)
			setLoading(false)

		}
	}
	return (
		<Box sx={{
			visibility: 'hidden',
			position: 'absolute'

		}} >
			{canvas &&
				<Document file={canvas}>
					<Thumbnail
						canvasRef={canvasRef} pageNumber={1} onRenderSuccess={onRenderSuccess}
					/>
				</Document>
			}
		</Box>
	)
}

const IconSelect = (type) => {
	console.log(type)
	let icon = faFileCircleQuestion
	if (type.type === "image/jpeg") {
		icon = faFileImage
	}
	if (type.type === "image/png") {
		icon = faFileImage
	}
	if (type.type === "application/pdf") {
		icon = faFilePdf
	}
	if (type.type === "video/mp4") {
		icon = faFileVideo
	}
	return (
		<FontAwesomeIcon icon={icon} size="5x" />
	)
}

const FilePreview = ({
	edit,
	item,
	setPreviewUpload,
	loading,
	setLoading
}) => {

	const [previewImage, setPreviewImage] = useState()
	const [canvas, setCanvas] = useState()

	//clear preview image
	const removeItem = () => {
		setPreviewImage(null)
		setPreviewUpload(null)
	}

	//set preview image in parent componenet base64
	useEffect(() => {
		if (previewImage) {

			function base64ToFile(base64String, fileName, mimeType) {
				// Remove the 'data:image/png;base64,' prefix
				var base64DataCleaned = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
				const byteCharacters = window.atob(base64DataCleaned);
				const byteArrays = [];
				for (let offset = 0; offset < byteCharacters.length; offset += 512) {
					const slice = byteCharacters.slice(offset, offset + 512);
					const byteNumbers = new Array(slice.length);

					for (let i = 0; i < slice.length; i++) {
						byteNumbers[i] = slice.charCodeAt(i);
					}
					const byteArray = new Uint8Array(byteNumbers);
					byteArrays.push(byteArray);
				}

				const blob = new Blob(byteArrays, { type: mimeType });
				const file = new File([blob], fileName, { type: mimeType });

				return file;
			}
			if (previewImage) {
				const file = base64ToFile(previewImage, "preview.png", 'image/png');
				setPreviewUpload(file)
			} else {
				setPreviewUpload()
			}

		}
	}, [previewImage])

	useEffect(() => {
		if (item) {
			if (item.type === "image/jpeg" || item.type === "image/png") {

				let file = URL.createObjectURL(item)

				setPreviewImage(file)

			} else if (item.type === "application/pdf") {

				setCanvas(item)

			} else if (item.type === "video/mp4") {

				getVideoFrameBase64FromFile(item, function (base64Data, currentTime, e) {
					if (base64Data) {
						//console.log(base64Data)
						setPreviewImage(base64Data)
						setLoading(false)
					} else {
						console.error("Error capturing frame:", e);
					}
				}, 1);
				// Capture frame at 1 second
			}
		}
	}, [item])


	return (
		<>
			<Box
				sx={{
					position: 'relative',
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: '#ccc',
					width: '100%',
					borderRadius: '5px',
					background: (theme) => theme.palette.background.main
				}}
			>
				{!edit ? <Box
					sx={{
						position: "absolute",
						right: '-10px',
						top: '-10px',
						".MuiIconButton-root ": {
							borderRadius: 50,
							width: '35px',
							height: '35px',
							background: (theme) => theme.palette.secondary.main,
							".cross": {
								color: (theme) => theme.palette.secondary.contrastText
							},
							"&:hover": {
								background: (theme) => theme.palette.secondary.dark,
							},
							display: item ? 'grid' : 'none'
						},
					}}
				>
					<Tooltip title="Remove" placement="top" arrow>
						<IconButton onClick={() => removeItem(item)}>
							<FontAwesomeIcon icon={faTimes} size="sm" className="cross" />
						</IconButton>
					</Tooltip>
				</Box>
					: null}

				<Box sx={{
					padding: '10px',
					textAlign: 'center',
					"& > svg ": {
						color: (theme) => theme.palette.secondary.main
					},
					'& .react-pdf__Page__textContent , & .react-pdf__Page__annotations': {
						display: 'none'
					}
				}} >
					<Box sx={{ width: '100%', height: 300, display: 'grid', overflow: 'hidden', position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
						{canvas ? <DocumentCanvas canvas={canvas} setPreviewImage={setPreviewImage} setLoading={setLoading} /> : null}
						{previewImage ?
							<img
								style={{ width: '300px', height: '300px', objectFit: 'contain' }}
								src={previewImage}
								alt="Thumb"
							/>
							: item?.type && item.type !== "application/pdf" && item.type !== "video/mp4" && item.type !== "image/jpeg" && item.type !== "image/png" ?
								<IconSelect type={item?.type} />
								: null }
						{loading ? <SpinningLoader text={"Creating snapshot..."} size={100} /> : <Typography color="#999">Preview area (image / PDF)</Typography>}

					</Box>
				</Box>
			</Box>
			{!edit ? <Box
				sx={{
					//width: '120px',
					overflow: 'hidden',
					paddingTop: '5px',
					wordBreak: 'break-all'
				}}
			>
				<Typography variant="caption" lineHeight={1.4} component="p" textAlign="center">
					{item?.name}
				</Typography>
			</Box>
				: null}
		</>
	)
}

export default FilePreview
