import parseCompanies from '../helpers/parseCompanies';

export const SAVE_COMPANY_SETTINGS = 'SAVE_COMPANY_SETTINGS';
export const CHANGE_COMPANY_SETTINGS = 'CHANGE_COMPANY_SETTINGS';
export const SAVE_COMPANIES = 'SAVE_COMPANIES';
export const RESET_COMPANIES = 'RESET_COMPANIES';
export const RESET_SELECTED_COMPANY = 'RESET_SELECTED_COMPANY';
export const SELECTED_COMPANY = 'SELECTED_COMPANY';

export const CompanyReducer = (state, action) => {
  switch (action.type) {
    case SAVE_COMPANY_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case SAVE_COMPANIES:
      //console.log('save companies');
      //return parseCompanies(action.payload);
      return action.payload;
    case RESET_COMPANIES:
      return [];
    case RESET_SELECTED_COMPANY:
      return {};
    case SELECTED_COMPANY:
      //SAVE SELECTED COMPANY INTO SESSION
      // window.sessionStorage.setItem(
      //   'selectedCompany',
      //   JSON.stringify(action.payload)
      // );
      return parseCompanies(action.payload);
    default:
      return state;
  }
};
