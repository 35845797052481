import React, { useContext, useEffect, useState } from "react";
import { UPDATE_CATEGORIES } from "../../store/CategoryReducers";
import { ProductsContext } from "../../store/ProductsContext";
import { CategoryContext } from "../../store/CategoryContext";
import { LayoutContext } from "../../store/LayoutContext";
import { RESET_PRODUCTS, UPDATE_PRODUCT_COUNT } from "../../store/ProductsReducers";
import { SHOW_CATEGORIES, UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { Grid, Link, Typography } from "@mui/material";
import { SelectedContext } from "../../store/SelectedContext";
import { useNavigate } from "react-router-dom";
import findCurrentCategory from "../libs/findCurrentCategory";
import { RESET_ALL_FILTERS } from "../../store/SelectedReducers";

const Breadcrumb = ({ customHistory }) => {
    //Global state values
    const { categories, dispatchCategories } = useContext(CategoryContext);
    const { dispatchSelected } = useContext(SelectedContext);
    const { dispatchProducts } = useContext(ProductsContext);
    const { dispatchLayout, app } = useContext(LayoutContext);

    const [breadcrumb, setBreadcrumb] = useState();
    const [currentCat, setCurrentCat] = useState();
    const navigate = useNavigate()
    //Component state

    //--------------------------- HISTORY  ------------------------------//

    useEffect(() => {
        //listen for custon history router event
        const unlisten = customHistory.listen(({ location, event, action }) => {
            //console.log(location)
            if (action === "POP") {
                //console.log('load last currentCat')
                if (location.state && location.state.lastCurrentCat) {
                    setCurrentCat(location.state.lastCurrentCat)
                }
            }
        });
        return () => {
            unlisten();
        };
    }, [customHistory, setCurrentCat]);

    //fix
    useEffect(() => {
        if (categories.currentCat !== currentCat) {
            setCurrentCat(categories.currentCat)
        }
    }, [categories.currentCat, setCurrentCat]);

    //--------------------------- CREATE BREADCRUMBS ------------------------------//

    useEffect(() => {
        if (currentCat && categories.allCats) {
            //console.log('breadcrumbs')
            //console.log(categories.allCats)

            let breadcrumb = [currentCat]
            //console.log(breadcrumb)

            const createBreadcrumb = (arr, itemId, path) => (
                arr.reduce((a, cat) => {
                    if (!path) { path = []; }
                    if (a) return a;
                    if (cat.ID === itemId) return [...path, { "Name": cat.Name, "ID": cat.ID }];
                    if (cat.Items) return createBreadcrumb(cat.Items, itemId, [...path, { "Name": cat.Name, "ID": cat.ID }])
                }, null)
            );
            breadcrumb = createBreadcrumb([categories.allCats], currentCat.ID);
            //breadcrumb = findItemNested([categories.allCats], currentCat.ID);
            //console.log(breadcrumb)
            setBreadcrumb(breadcrumb)
        }
    }, [currentCat, categories.allCats])

    const updateCats = (allCats, ID) => {

        let category = findCurrentCategory([allCats], ID);
        console.log(category)
        //set current category
        dispatchLayout({
            type: SHOW_CATEGORIES
        });
        dispatchCategories({
            type: UPDATE_CATEGORIES,
            payload: {
                currentCat: category[0].cat,
                openLevel: category[0].path ? category[0].path : []
            }
        });
        //reset products
        dispatchProducts({
            type: RESET_PRODUCTS
        });

        dispatchProducts({
            type: UPDATE_PRODUCT_COUNT,
            payload: {
                count: category[0].cat.Count,
            }
        }); 
        
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                //type: currentCat.CatOutput,
                page: 1
            }
        });
        dispatchSelected({
            type: RESET_ALL_FILTERS //resets all filters
        });
        //console.log(window.location.pathname)
        //if(window.location.pathname.includes("product")){
        //if clicked on products page
        navigate(`/${app.selectedArea}/catalogue`)
        //}
    };

    return (
        <Grid item >
            {breadcrumb ?
                <Typography
                    variant="caption"
                    color="text.primary"
                >
                    {breadcrumb.map((bread, i) =>
                        breadcrumb.length !== i + 1 ? (
                            <span key={i}>
                                <Link
                                    sx={{ textDecoration: 'none', color: (theme) => theme.palette.text.primary, }}
                                    component="button"
                                    //variant="body2"
                                    onClick={() => {
                                        updateCats(categories.allCats, bread.ID);
                                    }}
                                >
                                    {bread.Name}
                                </Link>
                                <span> / </span>
                            </span>
                        ) : (
                            <span key={i}>
                                <Link
                                    sx={{ textDecoration: 'none', fontWeight: 500 }}

                                    component="button"
                                    //variant="body2"
                                    onClick={() => {
                                        updateCats(categories.allCats, bread.ID);
                                    }}
                                >
                                    {bread.Name}
                                </Link>
                            </span>
                        )
                    )}
                </Typography>
                : null}
        </Grid>
    );
};

export default Breadcrumb;