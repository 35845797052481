import React, { useReducer } from 'react';
import { CoolantReducer } from './CoolantReducers';

export const CoolantContext = React.createContext();

export const CoolantProvider = ({ children }) => {
    const [coolantOptions, dispatchCoolantOptions] = useReducer(CoolantReducer, {
        inUse: true,
        toggleSort: 'most_used',
        toggleView : 'grid'
    });
  const [coolants, dispatchCoolants] = useReducer(CoolantReducer, []);
  const [coolantsInUse, dispatchCoolantsInUse] = useReducer(
    CoolantReducer,
    []
  );
  const [coolantsCompare, dispatchCoolantsCompare] = useReducer(
    CoolantReducer,
    []
  );
  const [selectedCoolant, dispatchSelectedCoolant] = useReducer(
    CoolantReducer
  );

  const contextValues = {
    coolants,
    dispatchCoolants,
    coolantOptions,
    dispatchCoolantOptions,
    coolantsInUse,
    dispatchCoolantsInUse,
    coolantsCompare,
    dispatchCoolantsCompare,
    selectedCoolant,
    dispatchSelectedCoolant,
  };

  return (
    <CoolantContext.Provider value={contextValues}>
      {children}
    </CoolantContext.Provider>
  );
};
