const createPercentage = number => {
    //console.log(number);
    if (number) {
        if (Number.isInteger(number)) {
            return `${number}%`;
        } else {
            return `${number.toFixed(2)}%`;
        }
    }
};
export default createPercentage;
