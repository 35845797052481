import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import LoadingSuccessButton from '../coolantcare/buttons/LoadingSuccessButton';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../language/setLanguageText';

const GlobalDialog = ({
    title,
    open,
    onClose,
    buttonTitle,
    buttonClick,
    children,
    maxWidth,
    closeText,
    loading,
    disabled,
    customActions,
    fullWidth,
    fullHeight,
    noPadding,
    fullScreen
}) => {

    return (
        <Dialog
            fullScreen={isMobileOnly || fullScreen ? true : false}
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            scroll='paper'
            PaperProps={{
                sx: {
                    width: isMobileOnly ? '100%' : fullWidth ? '100%' : 'auto',
                    height: isMobileOnly ? '100%' : fullHeight ? '100%' : 'auto'
                }
            }}
        >
            <DialogTitle
                sx={{
                    background: theme => theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
                    paddingRight: '2.5em',
                }}
            >
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            width: '35px',
                            height: '35px',
                            position: 'absolute',
                            right: 8,
                            top: 14,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}
                sx={{
                    padding: noPadding ? 0 : '1em',
                    paddingTop: noPadding ? 0 : '1em!important',
                    overflowY: noPadding ? 'unset' : 'auto',
                    position: 'relative'
                }}>
                {children}
            </DialogContent>
            <DialogActions sx={{ background: theme => theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200], padding: '1em' }} >
                {customActions ? customActions :
                    <>
                        <Button
                            variant="text"
                            onClick={onClose}
                            disabled={disabled ? true : false}
                        >
                            {setLanguageText(closeText ? closeText : 'Cancel')}
                        </Button>

                        {buttonTitle ?
                            <Button
                                fullWidth={false}
                                onClick={buttonClick}
                                variant='contained'
                                color="primary"
                                type="submit"
                                loading={loading}
                                disabled={disabled ? true : false}
                            >

                                {setLanguageText(buttonTitle)}
                            </Button>
                            : null}
                    </>
                }
            </DialogActions>
        </Dialog >
    );
};

export default GlobalDialog;