import React, { useEffect, useMemo, useState, useContext, useRef } from 'react'
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	getFilteredRowModel
} from '@tanstack/react-table'
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Typography, TablePagination } from '@mui/material'
import TableHeaderCell from '../../tables/TableHeaderCell';
import CreateChip from '../reusable/CreateChip';
import { CompanyContext } from '../../store/CompanyContext';
import setLanguageText from '../../language/setLanguageText';
import formatDate from '../helpers/formatDate';
import { ContactlessOutlined } from '@mui/icons-material';


function IndeterminateCheckbox({ header, indeterminate, className = '', ...rest }) {
	const ref = useRef(null)
	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<Checkbox
			ref={ref}
			{...rest}
			sx={{
				color: header ? 'secondary.main' : 'default',
				' &.Mui-checked': {
					color: header ? 'secondary.main' : 'default'
				}
			}}
		/>
	)
}

export default function ServiceEntriesTable({ data, isFetching, rowSelection, setRowSelection, setHeaders, hideCheckboxes }) {

	const defaultData = useMemo(() => [], [])
	const [sorting, setSorting] = useState([])
	const { selectedCompany } = useContext(CompanyContext);

	const columns = useMemo(() => {

		let all = []

		if (!hideCheckboxes) {
			all = [
				{
					id: 'select',
					header: ({ table }) => (
						<IndeterminateCheckbox

							{...{
								checked: table.getIsAllRowsSelected(),
								indeterminate: table.getIsSomeRowsSelected(),
								onChange: table.getToggleAllRowsSelectedHandler(),
							}}
							header
						/>
					),
					cell: ({ row }) => (
						<div className="px-1">
							<IndeterminateCheckbox
								{...{
									checked: row.getIsSelected(),
									disabled: !row.getCanSelect(),
									indeterminate: row.getIsSomeSelected(),
									onChange: row.getToggleSelectedHandler(),
								}}
							/>
						</div>
					),
					enableHiding: hideCheckboxes ? true : false,
				}
			]
		}

		all = [
			...all,
			{
				header: () => <Typography componenet="p" variant="body2" >ID</Typography>,
				accessorKey: 'entry_id',
				cell: info => info.getValue(),
				size: 200,
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				header: () => <Typography componenet="p" variant="body2" >{setLanguageText("Date")}</Typography>,
				accessorKey: 'date_stamp',
				cell: info => {
					return <Typography variant="caption" >{formatDate(info.getValue())}</Typography>;
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				header: () => <Typography componenet="p" variant="body2" >{setLanguageText("Machine")}</Typography>,
				accessorKey: 'machine_name',
				cell: info => info.getValue(),
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				header: () => <Typography componenet="p" variant="body2" >{setLanguageText("Group")}</Typography>,
				accessorKey: 'machine_group',
				cell: info => info.getValue(),
				enableSorting: false,
				enableColumnFilter: false,
			}]
		if (selectedCompany?.company_attributes) {
			//console.log(selectedCompany)
			selectedCompany?.company_attributes.forEach(item => {
				all = [
					...all,
					{
						header: () => <Typography componenet="p" variant="body2" >{item.one.attr_name}</Typography>,
						accessorKey: item.one.attr_id,
						cell: ({ row }) => {
							const attributes_new = row.original.attributes_new;
							//console.log(row.original)
							return (
								<CreateChip
									company_attr_id={item.one.attr_id}
									entry_attributes={attributes_new}
									factorApplied={row.original?.factor ? true : false}
									entryFactor={row.original?.factor}
								/>
							);
						},
						enableSorting: false,
						enableColumnFilter: false,
					}
				];
			});
		}
		all = [
			...all,
			{
				header: () => <Typography componenet="p" variant="body2" >{setLanguageText("Additives")}</Typography>,
				accessorKey: 'additives',
				cell: info => {
					let value = info.getValue()
					return (
						<Grid container alignItems="center" justifyContent="center">
							{value ? (
								value.map((item, i) =>
									<Grid key={i} item xs={12}>
										<Typography variant="caption">
											{item.product_name ? `${item.product_name} - ${item.product_type}(${item.product_details})` : `${item.product_type} (${item.product_details})`}
										</Typography>
									</Grid>
								)
							) : null}
						</Grid>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			}, {
				header: () => <Typography componenet="p" variant="body2" >{setLanguageText("Notes")}</Typography>,
				accessorKey: 'notes',
				cell: ({ row, value }) => {
					return (
						<>
							{row.original.note_action === 'C' && row?.original?.notes &&
								<Alert style={{ width: '100%' }} severity="error"><strong>{setLanguageText("Details")}:</strong> {row.original.notes}</Alert>
							}
							{row.original.note_action === 'N' && row?.original?.notes &&
								<Alert style={{ width: '100%' }} severity="success"><strong>{setLanguageText("Details")}:</strong> {row.original.notes}</Alert>
							}
							{row.original.note_action === 'Y' && row?.original?.notes &&
								<Alert style={{ width: '100%' }} severity="warning"><strong>{setLanguageText("Details")}:</strong> {row.original.notes}</Alert>
							}
						</>
					)
				},
				minWidth: 200,
				enableSorting: false,
				enableColumnFilter: false,
			}
		]
		if (setHeaders) {
			setHeaders(all)
		}
		return all
	}, [selectedCompany])

	const table = useReactTable({
		data: data ?? defaultData,
		columns,
		state: {
			sorting,
			rowSelection: rowSelection || {},
			// pagination :{
			// 	pageSize: data?.length ? data.length : 10,
			// }
		},
		enableRowSelection: true, //enable row selection for all rows
		// enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
		onRowSelectionChange: setRowSelection ? setRowSelection : null,
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		//getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel()
		//debugTable: true,
	})

	//const { pageSize, pageIndex } = table.getState().pagination

	return (
		<Box sx={{ width: '100%', overflow: 'auto', marginTop: '1em' }}>
			<Grid item xs={12}>
				<TableContainer>
					<Table
						size="small"
						sx={{ minWidth: 650, border: '1px solid #ccc' }}
						aria-label="simple table
					 ">
						<TableHead>
							{table.getHeaderGroups().map((headerGroup, i) => (
								<TableRow
									key={i}
									sx={{
										backgroundColor: (theme) => theme.palette.table.header,
									}}
								>
									{//added a custom header cell
										headerGroup.headers.map((header, i) => <TableHeaderCell key={i} header={header} i={i} table={table} />)
									}
								</TableRow>
							))}
						</TableHead>
						<TableBody>
							{isFetching ?
								//create array from a number
								new Array(10).fill(0).map((_, i) => {
									return (
										<TableRow
											key={i}
											sx={{
												cursor: 'pointer',
												borderBottom: '1px solid #ccc',
												backgroundColor: (theme) => theme.palette.table.row,
												'&:nth-of-type(odd)': {
													backgroundColor: (theme) => theme.palette.table.hover,
												},
												// hide last border
												'&:last-child td, &:last-child th': {
													borderBottom: 0,
												},
											}}>
											{columns && columns.map(() =>
												<TableCell><Skeleton height={30} /></TableCell>
											)}

										</TableRow>
									)
								})

								: !isFetching && data?.length > 0 ? table.getRowModel().rows.map((row, i) => {
									return (
										<TableRow
											key={i}
											sx={{
												cursor: 'pointer',
												borderBottom: '1px solid #ccc',
												//backgroundColor: (theme) => row.original.last_entry.out_of_control ? theme.palette.table.out : theme.palette.table.rows,
												// '&:nth-of-type(odd)': {
												// 	backgroundColor: (theme) => theme.palette.table.hover,
												// },
												// hide last border
												'&:last-child td, &:last-child th': {
													borderBottom: 0,
												},
											}}
										>
											{row.getVisibleCells().map((cell, i) => {
												return (
													<TableCell
														sx={{
															backgroundColor: (theme) => row.original.out_of_control ? theme.palette.table.out : theme.palette.table.rows,
														}}
														key={i}>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</TableCell>
												)
											})}
										</TableRow>
									)
								})
									: !isFetching && data?.length === 0 ?
										<TableRow>
											<TableCell colSpan={columns.length} align="center">
												<Alert sx={{ width: '100%' }} severity="info">No entries found</Alert>
											</TableCell>
										</TableRow>
										: null}
						</TableBody>
						{/* <TablePagination
							rowsPerPageOptions={[10, 50, 100, 200]}
							component="div"
							count={table.getFilteredRowModel().rows.length}
							rowsPerPage={pageSize}
							page={pageIndex}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={(_, page) => {
								table.setPageIndex(page)
							}}
							onRowsPerPageChange={e => {
								const size = e.target.value ? Number(e.target.value) : 10
								table.setPageSize(size)
							}}
						//ActionsComponent={TablePaginationActions}
						/> */}
					</Table>
				</TableContainer>
			</Grid >
		</Box >
	)
}
