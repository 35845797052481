import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { DialogTitle, Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { MachineContext } from '../../store/MachineContext';
import { useNavigate } from 'react-router-dom';
import { RESET_MACHINES, SAVE_MACHINES } from '../../store/MachineReducers';
import Alert from '@mui/material/Alert';
import GlobalDialog from '../../../reusable/GlobalDialog';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from "@tanstack/react-query";
import DialogLoader from '../../../reusable/DialogLoader';
import config from '../../../config';

function DeleteMachine({ selectedMachine }) {

    const navigate = useNavigate();
    const {
        updateActivityLog,
    } = useActivityLog();
    const { user } = useContext(UserContext);
    const { dispatchMachines } = useContext(MachineContext);
    const [openDelete, setOpenDelete] = useState(false);

    const openDialog = () => {
        setOpenDelete(true)
    };

    const closeDialog = () => {
        setOpenDelete(false);
    };

    const deleteMachine = useMutation({
        mutationFn: (id) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'delete',
            body: {
                machine_id: id
            }
        }),
        onSuccess: () => {

            //update activity log
            updateActivityLog.mutate({
                activity_details: {
                    area: "coolant",
                    machine_id: selectedMachine.machine_id,
                    machine: selectedMachine
                },
                activity_type: "machine deleted"
            })

            //TODO : remove machine from context
            // remove machine from context
            // let machine = structuredClone(selectedMachine)
            // // remove machine by id
            // machine.filter((item) => item.machine_id !== selectedMachine.machine_id)

            // dispatchMachines({ 
            //     type: SAVE_MACHINES, 
            //     payload: machine 
            // });

        }
    })

    const confirmDeleteMachine = () => {
        deleteMachine.mutate(selectedMachine.machine_id)
    };

    return (
        <>
            <Button
                variant="contained"
                color="error"
                onClick={openDialog}
                startIcon={<FontAwesomeIcon icon={faTrash} size="xs" />}
            >
                Remove Machine
            </Button>

            <GlobalDialog
                open={openDelete}
                onClose={closeDialog}
                title="Remove Machine"
                buttonClick={confirmDeleteMachine}
                buttonTitle="Confirm"
                successMessage="Machine deleted successfully!"
            >
                <DialogTitle>
                    <Alert variant="standard" color="error">
                        {setLanguageText("Are you sure you want to delete this machine,this action will be permanent?")}
                    </Alert>
                </DialogTitle>
                {deleteMachine.isLoading || deleteMachine.isSuccess || deleteMachine.isError ?
                    <DialogLoader
                        isLoading={deleteMachine.isLoading}
                        mutation={deleteMachine}
                        loadingMessage="Deleting Machine..."
                        successMessage="Deleted Machine"
                        closeDialog={() => {
                            navigate('/coolantcare/machines');
                        }}
                        fixed

                    />
                    : null}
            </GlobalDialog>
        </>
    );
}

export default DeleteMachine;
