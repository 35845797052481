import createMainQuery from "./createMainQuery";
import search_pipline from "./searchPipeline.json";

export default function queryGetSuggestions({ type, size, term, filters }) {

	let query = {};

	//create global query
	let { minScore, mainQuery, highlight } = createMainQuery({ term, type })

	if (filters) {
		mainQuery.bool.filter.push({
			"terms": filters ? filters : []
		});
	}

	query = {
		"query": mainQuery,
		...search_pipline,
		"min_score": minScore,
		"track_scores": true,
		"size": size,
		"track_total_hits": true
	};

	if (highlight) {
		query.highlight = highlight
	}

	return query;
}

