import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import * as Yup from "yup"
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import useActivityLog from '../users/activity/useActivityLog';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import { Formik } from 'formik';
import DialogLoader from '../reusable/DialogLoader';
import { UserContext } from '../store/UserContext';
import { Grid, TextField } from '@mui/material';
import InputWrap from '../reusable/InputWrap';
import { CompanyContext } from '../store/CompanyContext';

const EditBasket = ({ open, setOpen, refreshBaskets }) => {

  const { user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const [initialValues] = useState({
    "basket_id": null,
    "basket_company": selectedCompany.company_id,
    "basket_name": '' ,
  });

  const {
    updateActivityLog,
    //loggingError,
    //loggingResponse
  } = useActivityLog();

  const basketValidation = Yup.object({
    basket_name: Yup
      .string('Enter the basket name')
      .required('Basket name is required')
  });


  const mutation = useMutation((basket) => {
    //console.log(basket)
    return axiosRequest({
      gateway: config.shopAPI.URL,
      endpoint: 'basket',
      api_key: user.api_key,
      method: 'patch',
      body: {
        "basket_company": basket.basket_company,
        "basket_items": [],
        "basket_name": basket.basket_name , 
      }
    })
  }, {
    onSuccess: (context, variables) => {
      //add new basket to the list
      refreshBaskets()
    }
  })


  const submitResource = (values) => {
    //console.log(values)
    mutation.mutate(values)
  };

  const formRef = useRef();

  return (
    <>
      {initialValues ?
        <Formik
          innerRef={current => formRef.current = current} //access methods
          initialValues={initialValues}
          validationSchema={basketValidation}
          onSubmit={submitResource}
        >
          {({ handleSubmit, errors, values, handleChange, touched }) => {
            console.log(values)
            return (
              <GlobalDialog
                open={open}
                onClose={() => setOpen(false)}
                title='New Basket'
                buttonTitle='Create New Basket'
                buttonClick={handleSubmit}
              >

                <Grid item xs={12}>
                  <InputWrap name="Name">
                    <TextField
                      id="basket_name"
                      name="basket_name"
                      value={values.basket_name}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      error={touched.basket_name && Boolean(errors.basket_name)}
                      helperText={touched.basket_name && errors.basket_name}
                      sx={{ '& legend': { display: 'none' } }}
                    />
                  </InputWrap>
                </Grid>

                {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                  <DialogLoader
                    isLoading={mutation.isLoading}
                    mutation={mutation}
                    loadingMessage="Saving Basket"
                    successMessage="basket Saved"
                    closeDialog={() => {
                      setOpen(false)
                    }}
                    fixed
                  />
                  : null}
              </GlobalDialog>
            )
          }
          }
        </Formik>
        : null
      }
    </>
  );
};

export default EditBasket;
