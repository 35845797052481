import { useContext, useEffect, useState } from "react";
import config from "../config";
import { LayoutContext } from "../store/LayoutContext";
import { createTheme } from "@mui/material";
import { APP_THEME } from "../store/LayoutReducers";
import axios from "axios";

const useAppTheme = () => {

    const [baseTheme, setBaseTheme] = useState();
    const [fullTheme, setFullTheme] = useState();
    const [background, setBackground] = useState();
    const { layout, theme, dispatchTheme } = useContext(LayoutContext)
    

    useEffect(() => {
        axios({
            method: 'get',
            url: config.factoryAPI.URL + '/theme',
            params: {
                app_id: config.APPID
            }
        })
            .then(({ data }) => {
               //console.log(data)
                if (data) {
                    //check to see id app_theme is an object
                    if (typeof data[0].app_theme === 'string') {
                        data[0].app_theme = JSON.parse(data[0].app_theme)
                    }
                    dispatchTheme({
                        type: APP_THEME,
                        payload: {
                            app: data[0].app_theme,
                            login_logo: data[0].login_logo,
                            email_logo: data[0].email_logo,
                            pdf_logo: data[0].pdf_logo,
                            app_version: data[0].app_version
                        }
                    });
                }
            });

        import(`../_config/${process.env.REACT_APP_CONFIG_PATH || 'dev'}/base_theme.js`).then(module => {
            setBaseTheme(module.default)
        });
        import(`../images/${process.env.REACT_APP_CONFIG_PATH || 'dev'}-background.jpg`).then((module) => {
            setBackground(module.default);
        });

    }, [])

    useEffect(() => {
        //console.log(theme)
        if (theme?.app && layout && baseTheme) {
            let full = createTheme({
                palette: {
                    mode: layout.colorMode,
                    ...(layout.colorMode === 'light'
                        ? theme.app.light : theme.app.dark
                    )
                },
                ...baseTheme.rest
            })
            setFullTheme(full)
        }
    }, [layout, theme, baseTheme])

    return {
        background,
        fullTheme
    }
};

export default useAppTheme;

