function flatArrayToTree(list,parent,id) {
    var map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i][`${id}`]] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node[`${parent}`] !== 0) {
            // if you have dangling branches check that map[node.parent] exists
            list[map[node[`${parent}`]]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}
export default flatArrayToTree

