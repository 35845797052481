import { makeStyles } from '@mui/styles';

const CardStyles = makeStyles(theme => ({
  header: {
    borderWidth: ` 0 0 1px 0`,
    borderStyle: 'solid',
    borderColor: theme.palette.tabs.border,
    backgroundColor: theme.palette.cards.header,
    padding: theme.spacing(1,2),
    alignItems: 'center'
  },
  adminHeader : {
    borderWidth: `1px`,
    borderStyle: 'solid',
    borderColor: theme.palette.tabs.border,
    padding: theme.spacing(1,2),
    backgroundColor: theme.palette.cards.header,
  },
  card: { 
    height: '100%', 
    width: '100%' ,
    borderWidth: `1px`,
    borderStyle: 'solid',
    borderColor: theme.palette.tabs.border,
  },
  cardContainer: { height: '100%' },
  cardContainerHide: {
    opacity: .2,
    height: '100%' 
  },
  cardContent: {
    //paddingTop: 0,
    flex: 1
  },
  cardContentHide: {
    opacity: .2,
    //paddingTop: 0,
    flex: 1
  },
  footer: {
    borderWidth: `1px 0 0 0`,
    borderStyle: 'solid',
    borderColor: theme.palette.tabs.border,
    backgroundColor: theme.palette.cards.header,
    padding: theme.spacing(1,2)
  },
  footerHide: {
    opacity: .2,
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1,2)
  },

}));
export default CardStyles;
