import React, {
	useContext,
	useState,
	useEffect
} from 'react';
import {
	Grid,
	Button,
	Typography
} from '@mui/material';
import CompanyVariables from './CompanyVariables';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../language/setLanguageText';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import { useQuery } from "@tanstack/react-query";
import axiosRequest from '../../axios/axoisRequest';
import config from '../../config';
import NewAttributeDialog from './NewAttributeDialog';
import AttributesTable from './AttributesTable';
import getUserLevel from '../../helpers/getUserLevel';
import EditAttributeDialog from './EditAttributeDialog';


export default function CompanyAttributes() {

	const { selectedCompany } = useContext(CompanyContext);
	const { user } = useContext(UserContext);
	const [selectedAttribute, setSelectedAttribute] = useState();
	const [currentAttributes, setCurrentAttributes] = useState();
	const [currentVariables, setCurrentVariables] = useState();
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	//get attributes
	const { data, isFetching, refetch } = useQuery({
		queryKey: ['attributes', selectedCompany?.company_id],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.coolantAPI.URL,
			endpoint: 'company/attributes',
			api_key: user.api_key,
			method: 'get',
			params: {
				id: selectedCompany.company_id
			}
		}),
		enabled: !!selectedCompany.company_id,
		retry: false
	})

	useEffect(() => {
		if (data) {
			setCurrentAttributes(data[0].company_attributes);
			setCurrentVariables(data[0].company_colour_rules);
		}
	}, [data])


	const handleClose = () => {
		setOpen(false);
		setSelectedAttribute();
	};

	const handleEditClose = () => {
		setEditOpen(false);
		setSelectedAttribute();
	};

	const addNewAttribute = () => {
		setOpen(true);
	};

	const closeModalRefresh = () => {
		setSelectedAttribute();
		setOpen(false);
		refetch();
	};

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Custom Attributes")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid item container xs={12} spacing={1}>
					{getUserLevel(user.user, 'coolantcare', 'custom_attributes', 9) ?
						<Grid item container xs={12} spacing={1}>
							<Grid item xs={3}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => addNewAttribute()}
								>
									{setLanguageText("Add a new Attribute")}
								</Button>
							</Grid>
						</Grid>
						: null}
					<Grid item xs={12}>
						<AttributesTable
							currentAttributes={currentAttributes}
							currentVariables={currentVariables}
							setSelectedAttribute={setSelectedAttribute}
							selectedAttribute={selectedAttribute}
							isFetching={isFetching}
							setOpen={setEditOpen}
							refetch={refetch}
						/>
					</Grid>
					<Grid item xs={12}>
						<CompanyVariables
							currentVariables={currentVariables}
							closeModalRefresh={closeModalRefresh}
							isFetching={isFetching}
							refetch={refetch}
						/>
					</Grid>
				</Grid>
				<NewAttributeDialog
					currentAttributes={currentAttributes}
					currentVariables={currentVariables}
					closeModalRefresh={closeModalRefresh}
					open={open}
					handleClose={handleClose}
				/>
				<EditAttributeDialog
					selectedAttribute={selectedAttribute}
					currentAttributes={currentAttributes}
					currentVariables={currentVariables}
					closeModalRefresh={closeModalRefresh}
					open={editOpen}
					handleClose={handleEditClose}
				/>
				{/* <Dialog
					fullWidth={true}
					maxWidth={'md'}
					onClose={handleClose}
					aria-labelledby="customized-dialog-title"
					open={open}
				>
					{
						<FormStepper
							selectedAttribute={selectedAttribute}
							currentAttributes={currentAttributes}
							currentVariables={currentVariables}
							closeModalRefresh={closeModalRefresh}
						/>
					}
				</Dialog> */}
			</TabContent>
		</>
	);
}
