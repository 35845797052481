import React, { useContext, useEffect, useState } from 'react';
import AccountMenu from '../menu/AccountMenu';
import { Grid, IconButton, Box, Typography, Tooltip, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { faHeart, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feedback from './Feedback';
import AdminMenu from '../menu/AdminMenu';
import { LayoutContext } from '../store/LayoutContext';
import Basket from '../basket/Basket';
import { UserContext } from '../store/UserContext';
import { UPDATE_MENU } from '../store/LayoutReducers';
import setLanguageText from '../language/setLanguageText';
import QrCode from '../coolantcare/FloatingActionButton/QrCode';
import FloatingDial from '../coolantcare/FloatingActionButton/FloatingActionButton';
export default function MobileBottomMenu() {

    const navigate = useNavigate();
    const { dispatchApp } = useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const [value, setValue] = useState(0);

    return (
        <Paper
            sx={{
                zIndex: 1000,
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: theme => `4px solid ${theme.palette.primary.main}`,
                padding: '8px 0 4px'
            }}
            elevation={3}>
            <BottomNavigation
                showLabels
                //value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {/* <BottomNavigationAction label="Search"
                    icon={<IconButton
                        color="inherit"
                        aria-label="Search"
                        //edge="start"
                        onClick={e => {
                            navigate('/')
                            //console.log(area,menu)
                            dispatchApp({
                                type: UPDATE_MENU,
                                payload: {
                                    selectedArea: "home",
                                    selectedMenu: "search"
                                }
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </IconButton>} /> */}

                <BottomNavigationAction label="Account" icon={<AdminMenu />} />
                <BottomNavigationAction label="Account" icon={<AccountMenu />} />
                {(
                    user?.user?.user_permissions?.buyers?.access === 1 ||
                    user?.user?.user_permissions?.application?.access === 1 ||
                    user?.user?.user_permissions?.engineers?.access === 1 ||
                    user?.user?.user_permissions?.parametric?.access === 1 ||
                    user?.user?.user_permissions?.cadcam?.access === 1
                ) ?
                    <BottomNavigationAction label="Basket" icon={<Basket />} />
                    : null}
                {/* <BottomNavigationAction label="Basket" icon={<QrCode />} />
                <BottomNavigationAction label="Basket" icon={<FloatingDial />} /> */}
            </BottomNavigation>
        </Paper>

    );
}

