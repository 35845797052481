export const SAVE_SHOP_CATEGORIES = 'SAVE_SHOP_CATEGORIES';
//export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_ALL_FILTERS = 'UPDATE_ALL_FILTERS';
export const UPDATE_BRANDS = 'UPDATE_BRANDS';

export const ShopReducer = (state, action) => {
  switch (action.type) {
    case SAVE_SHOP_CATEGORIES:
      return {
        ...state,
        breadcrumbCats: action.payload.breadcrumbCats ? action.payload.breadcrumbCats : state.breadcrumbCats,
        allCats: action.payload.allCats ? action.payload.allCats : state.allCats,
        currentCat: action.payload.currentCat ? action.payload.currentCat: state.currentCat,
        currentProductCat: action.payload.currentProductCat ? action.payload.currentProductCat: state.currentProductCat,
        openLevels: action.payload.openLevels ? action.payload.openLevels : state.openLevels,
        productSubCat: action.payload.productSubCat ? action.payload.productSubCat : state.productSubCat,
      };
    default:
      return state;
  }
};

export const FilterReducer = (state, action) => {
  switch (action.type) {
      case UPDATE_FILTERS:
        return {
          ...state,
          productFilters: action.payload //selected filters
        };
        case UPDATE_ALL_FILTERS:
          return {
            ...state,
            filters: action.payload //filters
          };
    default:
      return state;
  }
};

export const BrandsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BRANDS:
      return {
        ...state,
        //allBrands: action.payload.allBrands ? action.payload.allBrands: state.allBrands,
        currentBrands: action.payload.currentBrands ? action.payload.currentBrands : state.currentBrands,
        selectedBrands: action.payload.selectedBrands ? action.payload.selectedBrands : state.selectedBrands
      };
    default:
      return state;
  }
};


