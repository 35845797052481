import React, { useReducer } from 'react';
import { ProductsReducer } from './ProductsReducers';

export const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [products, dispatchProducts] = useReducer(ProductsReducer, []);
  const [productsOptions, dispatchProductsOptions] = useReducer(ProductsReducer, {
    visible: [],
    type: {},
    toggleSort: 'asc' ,
    toggleView : 'grid'
  });
  const [productTypes, dispatchProductTypes] = useReducer(ProductsReducer, []);
  const [selectedProduct, dispatchSelectedProduct] = useReducer(
    ProductsReducer
  );

  const contextValues = {
    products,
    dispatchProducts,
    productsOptions,
    dispatchProductsOptions,
    productTypes,
    dispatchProductTypes,
    selectedProduct,
    dispatchSelectedProduct
  };

  return (
    <ProductsContext.Provider value={contextValues}>
      {children}
    </ProductsContext.Provider>
  );
};
