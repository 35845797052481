import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import axiosRequest from '../../axios/axoisRequest';
import { CompanyContext } from "../../store/CompanyContext";
import RequestPriceEmail from "../../emails/RequestPriceEmail";
import RequestPriceConfirmationEmail from "../../emails/RequestPriceConfirmationEmail";
import DialogLoader from "../../reusable/DialogLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMemoCircleInfo, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import GlobalDialog from "../../reusable/GlobalDialog";
import { Formik } from "formik";
import { renderToString } from 'react-dom/server';
import { LayoutContext } from "../../store/LayoutContext";
import { useTheme } from "@emotion/react";


const RequestPrice = ({ product, table, error }) => {

    const { selectedCompany } = useContext(CompanyContext);
    const { app, theme: layoutTheme } = useContext(LayoutContext);
    const theme = useTheme();
    const { user } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const send_email = useMutation((request) => {

        //render the email
        const emailString = renderToString(<RequestPriceEmail request={request} logo={layoutTheme.email_logo} theme={theme} />)

        let emails = []

        //push application request email
        if (app.emails.request_email) {
            emails.push(app.emails.request_email)
        }
        if (selectedCompany?.company_settings?.request?.email) {
            //company request emails
            selectedCompany.company_settings.request.email.forEach((email) => {
                emails.push(email)
            })
        }

        //send the email
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'email',
            api_key: user.api_key,
            method: 'post',
            body: {
                //email: request.request_user.request_email,
                email: emails,
                html: emailString,
                subject: 'New Price Request'
            }
        })
    }, {
        onSuccess: ({ variables }) => {
            //send email on success
            console.log(variables)
            setLoading(false)
        }
    })

    const send_confirm_email = useMutation((request) => {

        //console.log(request)

        //render the email
        const emailString = renderToString(<RequestPriceConfirmationEmail request={request} logo={layoutTheme.email_logo} theme={theme} />)

        //send the email
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'email',
            api_key: user.api_key,
            method: 'post',
            body: {
                email: [request.request_email],
                html: emailString,
                subject: 'Price Confirmation Request'
            }
        })
    })

    const save_request = useMutation((request) => {

        //save the request
        return axiosRequest({
            gateway: config.factoryAPI.URL,
            endpoint: 'request/product_price',
            api_key: user.api_key,
            method: 'post',
            body: request
        })

    }, {
        onSuccess: (response, variables) => {
            //send email on success
            send_email.mutate(variables);
            send_confirm_email.mutate(variables);
        }
    })

    const handleSend = async (values) => {

        setLoading(true)
        // let products = structuredClone(product)
        // products.area = app.selectedArea
        // //products.Properties = [] //properties breaking due to 's in the label text
        // //Go through request and create correct object for email
        // let updateObject = await createProductObject([products])
        //console.log(updateObject)
        const request = {
            "request_user": {
                "user_name": user.user.username,
                "user_id": user.user.user_id,
                "user_email": user.user.email
            },
            "request_company": {
                "company_id": selectedCompany.company_id,
                "company_name": selectedCompany.company_name,
            },
            "request_email": values.email,
            "request_product": product,
            "request_amount": values.amount
        }
        //console.log(request)
        save_request.mutate(request);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                //color="success"
                size={table ? "small" : "large"}
                fullWidth
                startIcon={<FontAwesomeIcon icon={faMemoCircleInfo} />}
            >
                {table ? 'Request Price' : 'Request Price Quote'}
            </Button>
            <Formik
                initialValues={{
                    email: user.user.email,
                    amount: 1
                }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    handleSend(values)
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    //isSubmitting,
                    /* and other goodies */
                }) => (

                    <GlobalDialog
                        open={open}
                        onClose={handleClose}
                        title=" Request Price Quote"
                        buttonClick={handleSubmit}
                        buttonTitle="Send Request"
                        maxWidth="md"
                    >
                        <Grid container item xs={12} justifyContent="end" alignItems="end">
                            <TextField
                                fullWidth
                                sx={{ marginTop: '1em', minWidth: '300px' }}
                                name="email"
                                label="Email"
                                value={values.email}
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}

                            />
                        </Grid>
                        <Grid container item xs={12} justifyContent="end" alignItems="end">
                            <TextField
                                fullWidth
                                sx={{ marginTop: '1em', minWidth: '300px' }}
                                name="amount"
                                label="Amount Needed"
                                value={values.amount}
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.amount && Boolean(errors.amount)}
                                helperText={touched.amount && errors.amount}

                            />
                        </Grid>
                        {user.user.user_permissions.administrator && error && (
                            <Grid item >
                                <Alert severity="error" sx={{ marginTop: '1em' }}>
                                    {error}
                                </Alert>
                            </Grid>
                        )}
                        {loading || send_email.isLoading || send_email.isSuccess || send_email.isError ?
                            <DialogLoader
                                isLoading={loading || send_email.isLoading}
                                mutation={send_email}
                                loadingMessage="Sending Request..."
                                successMessage="Request Sent"
                                closeDialog={handleClose}
                                fixed
                            />
                            : null}
                    </GlobalDialog>

                )}
            </Formik>





        </>
    );
};

export default RequestPrice;