import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    flexRender
} from '@tanstack/react-table'
import config from '../../../config'
import { UserContext } from '../../../store/UserContext'
import { CompanyContext } from '../../../store/CompanyContext'
import axiosRequest from '../../../axios/axoisRequest';
import { Alert, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isMobileOnly } from 'react-device-detect'
import setLanguageText from '../../../language/setLanguageText'
import ViewQuote from './ViewQuote'
import DeleteQuote from './DeleteQuote'
import DotsMenu from '../../../reusable/DotsMenu'
import ContinueCheckout from '../../../checkout/ContinueCheckout'
import Filter from '../../../tables/Filter'

const QuoteTable = () => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const [count, setCount] = useState();
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const columns = useMemo(() => [

        // {
        //     accessorKey: 'order_id',
        //     header: () => 'ID',
        //     footer: props => props.column.id,
        //     cell: info => info.getValue(),
        // },
        // {
        //     accessorKey: 'order_stage',
        //     header: () => 'Stage',
        //     footer: props => props.column.id,
        //     cell: info => info.getValue(),
        // },
        {
            accessorKey: 'order_number',
            header: () => '',
            name: 'Reference Number',
            footer: props => props.column.id,
            cell: info => info.getValue(),
        },
        {
            accessorKey: 'order_date',
            header: () => 'Date',
            footer: props => props.column.id,
            cell: info => new Date(info.getValue()).toLocaleDateString(),
            enableColumnFilter: false,
        },


        {
            header: () => 'Username',
            accessorKey: 'order_user.user_name',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            enableColumnFilter: false,
        }, {
            header: () => 'Emails',
            accessorKey: 'order_user.user_email',
            cell: info => info.getValue(),
            footer: props => props.column.id,
            enableColumnFilter: false,
        }, {

            header: () => 'Actions',
            accessorKey: 'order',
            cell: info => {
                //console.log(info)
                return (
                    <Grid container spacing={1}>
                        <DotsMenu > 
                            <ViewQuote order={info.row.original}  />
                            <ContinueCheckout order={info.row.original} title={`Checkout Quote: ${info.row.original.order_number}`} />
                            <DeleteQuote order={info.row.original} title={`Delete Quote: ${info.row.original.order_number}`} refreshTable={dataQuery.refetch} />
                        </DotsMenu>
                    </Grid>
                )
            },
            footer: props => props.column.id,
            enableColumnFilter: false,
        },
    ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, })
    const [dateFrom, setDateFrom] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)) //from 1 month ago
    const [dateTo, setDateTo] = useState(new Date()) //today


    const dataQuery = useQuery(['data', pageSize, pageIndex, selectedCompany.company_id, dateFrom, dateTo],
        () =>
            axiosRequest({
                gateway: config.factoryAPI.URL,
                endpoint: 'orders',
                api_key: user.api_key,
                method: 'get',
                params: {
                    "type": "quotes",
                    "company_id": selectedCompany.company_id,
                    "limit": pageSize,
                    "offset": pageSize * pageIndex,
                    //change date time to 00:00:00
                    "date_from": new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate()).toISOString(),
                    //change date to midnight on the current day
                    "date_to": new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() + 1).toISOString()

                }
            })
        ,
        {
            //notifyOnChangeProps: ['data'],
            keepPreviousData: true
        }
    )


    useEffect(() => {
        if (dataQuery.data) {
            setCount(dataQuery?.data[0]?.order_count)
        } else {
            setCount(0)
        }
        return () => {
            setCount(0)
        }

    }, [dataQuery.data])

    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize])

    //console.log(dataQuery.data)

    const table = useReactTable({
        data: dataQuery?.data ?? defaultData,
        columns,
        pageCount: dataQuery.data?.pageCount ?? -1,
        state: {
            pagination,
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })



    const handleDateFrom = (date) => {
        console.log(date)
        setDateFrom(date)
    }

    const handleDateTo = (date) => {
        console.log(date)
        setDateTo(date)
    }

    return (

        <Box sx={{ width: '100%' }}>
            <Grid item xs={12} container spacing={2}>
                <Grid item md={12} xl={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            size="medium"
                            label={setLanguageText("Date From")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={dateFrom}
                            onChange={handleDateFrom}
                            disableFuture
                            sx={{ marginRight: 2 }}
                        />
                        <DatePicker
                            size="medium"
                            label={setLanguageText("Date To")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={dateTo}
                            onChange={handleDateTo}
                            minDate={dateFrom}
                            disableFuture
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={12} xl={6}>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        component="div"
                        count={count ? count : -1}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onPageChange={(_, page) => {
                            table.setPageIndex(page)
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 10
                            table.setPageSize(size)
                        }}
                    //ActionsComponent={TablePaginationActions}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TableContainer >
                        <Table sx={{ minWidth: 650, border: '1px solid #ccc', }} aria-label="simple table">
                            <TableHead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow
                                        key={headerGroup.id}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.table.header,
                                        }}
                                    >
                                        {headerGroup.headers.map((header, i) => {
                                            return (
                                                <TableCell key={i} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {header.column.getCanFilter() ? (
                                                                <div>
                                                                    <Filter column={header.column} table={table} />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {dataQuery.isFetching ?
                                    //create array from a number
                                    new Array(pageSize).fill(0).map((_, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}>

                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={30} /></TableCell>
                                                <TableCell><Skeleton height={36} variant='rounded' /></TableCell>
                                            </TableRow>
                                        )
                                    })

                                    : !dataQuery.isFetching && dataQuery?.data.length > 0 ? table.getRowModel().rows.map(row => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: (theme) => theme.palette.table.row,
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: (theme) => theme.palette.table.hover,
                                                    },
                                                    // hide last border
                                                    '&:last-child td, &:last-child th': {
                                                        borderBottom: 0,
                                                    },
                                                }}
                                            >
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                        :

                                        <Alert sx={{ width: '100%', margin: '1em' }} severity="info">No quotes found</Alert>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default QuoteTable;
