import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CompanyContext } from '../../store/CompanyContext';
import ImageUpload from '../../coolantcare/reusable/ImageUpload';
import { UserContext } from '../../store/UserContext';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { Storage } from 'aws-amplify';
import config from '../../config';
import { SAVE_COMPANIES, SELECTED_COMPANY } from '../../store/CompanyReducers';
import TabHeader from '../../layout/TabHeader';
import TabContent from '../../layout/TabContent';
import setLanguageText from '../../language/setLanguageText';
import useAsync from '../../coolantcare/Hooks/useAsync';
import axiosRequest from '../../coolantcare/helpers/axiosRequest';
import LoadingButton from '../../coolantcare/buttons/LoadingButton';
import OutlinedDiv from '../../coolantcare/reusable/OutlinedDiv';
import useActivityLog from '../../users/activity/useActivityLog';
import useSignedUrl from '../../hooks/useSignedUrl';
import GlobalDialog from '../../reusable/GlobalDialog';
import { useMutation } from 'react-query';

const CompanyDetails = ({ edit }) => {

	const {
		updateActivityLog,
		//loggingError,
		//loggingResponse
	} = useActivityLog();
	const { user } = useContext(UserContext);
	const { companies, selectedCompany, dispatchCompanies, dispatchSelectedCompany } = useContext(CompanyContext);
	const [company, setCompany] = useState();
	const [logo, setLogo] = useState();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [url, setUrl] = useState();
	const { getSignedUrl } = useSignedUrl();


	const {
		loading,
		executeAsync,
		setData
	} = useAsync({
		axiosRequest: axiosRequest
	});


	useEffect(() => {
		if (Object.values(selectedCompany).length) {
			//console.log(selectedCompany);
			setCompany(selectedCompany);
		}
	}, [selectedCompany]);


	const setCompanyLogo = e => {
		setLogo(e);
	};

	const setCompanyName = e => {
		e.persist();
		setCompany(prevState => ({
			...prevState,
			company_name: e.target.value
		}));
	};

	const handleSubmit = async e => {
		//console.log('save edit');
		e.preventDefault();
		let body = {
			company_id: company.company_id,
			company_name: company.company_name
		};
		//add logo if new one has been uploaded
		let type;

		if (logo) {
			//console.log(logo)
			if (logo.type === 'image/png') {
				type = 'png'
			} else if (logo.type === 'image/jpeg') {
				type = 'jpg'
			} else if (logo.type === 'image/webp') {
				type = 'webp'
			}
			body.company_logo = `${company.company_id}_logo.${type}`;
		}

		await executeAsync({
			endpoint: 'company',
			api_key: user.api_key,
			method: 'patch',
			body: body
		});

		//console.log(logo)

		if (logo) {

			Storage.configure({
				AWSS3: {
					bucket: config.s3.BUCKET,
					region: config.s3.REGION
				}
			});

			try {
				await Storage.put(`company/${body.company_logo}`, logo, {
					ACL: 'public-read',
					contentType: logo.type,
					visibility: 'public',
					level: 'public'
				});
			} catch (err) {
				//console.log(err);
			}
		}

		updateActivityLog.mutate({
			activity_details: {
				area: "factory",
				company_id: company.company_id
			},
			activity_type: "edit company"
		})

		let updateLogo = {
			...selectedCompany,
			company_name: company.company_name
		}

		if (logo) {
			updateLogo = {
				...updateLogo,
				company_logo_path: body.company_logo
			}
		}

		dispatchSelectedCompany({
			type: SELECTED_COMPANY,
			payload: updateLogo
		});
		//setTimeout(() => {
		//reset data from aysnc
		setData();
		setLogo();
		//}, 1000);
		//re fetch brands
	};

	//disable company
	const disabled_compnay = useMutation(() => {
		return axiosRequest({
			endpoint: 'company/settings',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			method: 'patch',
			body: {
				company_disabled: true,
				company_id: selectedCompany.company_id,
			}
		})
	}, {
		onSuccess: (data, variables, context) => {
			updateActivityLog.mutate({
				activity_details: {
					area: "factory",
					company_id: selectedCompany.company_id,
				},
				activity_type: "disabled company"
			})

			//remove compnay from context
			let updatedCompanies = companies.filter(company => company.company_id !== selectedCompany.company_id);
			dispatchCompanies({
				type: SAVE_COMPANIES,
				payload: updatedCompanies
			});

			dispatchSelectedCompany({
				type: SELECTED_COMPANY,
				payload: updatedCompanies[0]
			});
			window.sessionStorage.setItem('selectedCompany', updatedCompanies[0].company_id);
			setConfirmDelete(false);
		}
	})

	useEffect(() => {
		if (company?.company_logo_path) {
			//console.log(path)
			getSignedUrl(config.s3.BUCKET, `public/company/${company.company_logo_path}`).then((url) => setUrl(url))
		} else {
			setUrl()
		}
	}, [company, setUrl]);

	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("Details")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				{company ? (
					<Grid container spacing={2} >
						<Grid item xs={12}>
							<TextField
								disabled={!edit}
								fullWidth
								InputLabelProps={{
									shrink: company.company_name ? true : false
								}}
								name="company_name"
								label={setLanguageText("Company Name")}
								variant="outlined"
								value={company.company_name}
								onChange={e => setCompanyName(e)}
							/>
						</Grid>
						<Grid item container xs={12}>
							<OutlinedDiv label={setLanguageText("Company Logo")}>
								<ImageUpload
									id="formatted-text-mask-input"
									path={url ? url : null}
									setImage={setCompanyLogo}
									edit={edit}
								/>
							</OutlinedDiv>
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item>
								<LoadingButton
									disabled={!edit}
									icon={faSave}
									text="Update Company Details"
									iconSize="1x"
									color="primary"
									loading={loading}
									onClick={e => handleSubmit(e)}
								/>
							</Grid>
							{user.user.user_permissions.administrator ? 
							<Grid item>
								<Button
									variant='contained'
									color="error"
									onClick={e => setConfirmDelete(true)}
									disabled={!edit}
								>
									Disable Company
								</Button>
							</Grid>
							: null}
						</Grid>
					</Grid>

				) : null}
			</TabContent>
			{confirmDelete &&
				<GlobalDialog
					open={confirmDelete}
					onClose={() => setConfirmDelete(false)}
					title={`Delete ${selectedCompany.company_name}`}
					buttonClick={
						() => {
							disabled_compnay.mutate();
						}
					}
					buttonTitle="Delete Company"
					disabled={disabled_compnay.isLoading}
					loading={disabled_compnay.isLoading}
					maxWidth="sm"
				>
					<Grid item>
						<Alert severity="error">
							Are you sure you want to disable <strong>{selectedCompany.company_name}</strong>?
						</Alert>
					</Grid>
				</GlobalDialog>
			}
		</>
	);
};

export default CompanyDetails;
