import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../store/UserContext';
import * as Yup from "yup"
import GlobalDialog from '../reusable/GlobalDialog';
import { useMutation } from 'react-query';
import useActivityLog from '../users/activity/useActivityLog';
import axiosRequest from '../axios/axoisRequest';
import config from '../config';
import { Formik } from 'formik';
import DialogLoader from '../reusable/DialogLoader';
import KPIForm from './KPIForm';

const EditKPI = ({ kpi, refetchKPIs, editKPI, setEditKPI, groupOptions, areaOptions }) => {

  const { user } = useContext(UserContext);
  const [initialValues, setInitialValues] = useState();

  const {
    updateActivityLog,
    //loggingError,
    //loggingResponse
  } = useActivityLog();

  useEffect(() => {
    if (kpi) {

      console.log(kpi)
      let settings = kpi?.kpi_settings ? JSON.parse(kpi?.kpi_settings) : {}

      let object = {
        "kpi_id": kpi?.kpi_id,
        "kpi_name": kpi?.kpi_name,
        "kpi_area": kpi?.kpi_area,
        "kpi_description": kpi?.kpi_description,
        "kpi_type": kpi?.kpi_type,
        "kpi_tags": kpi?.kpi_tags,
        "prefix": settings?.prefix || null,
        "global": settings?.global || null,
        "chart": settings?.chart || null
      }
      console.log(object)
      setInitialValues(object)
    }
  }, [kpi])


  const kpiValidation = Yup.object({
    kpi_name: Yup
      .string('Enter the KPI name')
      .required('KPI name is required')
  });


  const mutation = useMutation((values) => {
    //console.log(values)
    return axiosRequest({
      endpoint: `kpi`,
      method: 'post',
      gateway: config.factoryAPI.URL,
      api_key: user.api_key,
      body: {
        "update": true,
        "kpi_id": values.kpi_id,
        "kpi_name": values.kpi_name,
        "kpi_area": values.kpi_area,
        "kpi_description": values.kpi_description,
        "kpi_type": values.kpi_type,
        "kpi_tags": values.kpi_tags,
        "kpi_settings": {
          "prefix": values.prefix,
          "global": values.global,
          "chart": values.chart
        }
      }
    })
  }, {
    onSuccess: (response) => {
      if (!response.errorMessage) {
        // const file_ext = getFileExtension(file[0])
        refetchKPIs()
      }
    }
  })

  const submitResource = (values) => {
    //console.log(values)
    mutation.mutate(values)
  };

  const formRef = useRef();


  return (
    <>
      {initialValues ?
        <Formik
          innerRef={current => formRef.current = current} //access methods
          initialValues={initialValues}
          validationSchema={kpiValidation}
          onSubmit={submitResource}
        >
          {({ handleSubmit, errors, values }) => {
            console.log(values)
            return (
              <GlobalDialog
                open={editKPI}
                onClose={() => setEditKPI(false)}
                title={'Edit KPI'}
                buttonTitle={'Edit KPI'}
                buttonClick={handleSubmit}
              >
                <KPIForm
                  edit={true}
                  groupOptions={groupOptions}
                  areaOptions={areaOptions}
                />
                {mutation.isLoading || mutation.isSuccess || mutation.isError ?
                  <DialogLoader
                    isLoading={mutation.isLoading}
                    mutation={mutation}
                    loadingMessage="Saving KPI..."
                    successMessage="KPI Saved"
                    closeDialog={() => {
                      setEditKPI(false)
                    }}
                    fixed
                  />
                  : null}
              </GlobalDialog>
            )
          }
          }
        </Formik>
        : null
      }
    </>
  );
};

export default EditKPI;
