import React from "react";
import String from "./String";
//import MultiSelectImage from "./MultiSelectImage";
import Float from "./Float";
//import Check from "./Check";
import Dropdown from "./Dropdown";
import Integer from "./Integer";
import { Grid } from "@mui/material";

const InputTypes = ({ field, updateField }) => {
	//render fields
	const renderFilterValues = (field, updateField) => {
		//console.log(field)
		if (
			field.Ref !== "J21" //remove Article
			&& field.Ref !== "J3" //remove Company
			&& field.Ref !== "W1" //remove material
			&& field.Ref !== "H3" //remove Grade
		) {

			if (field.Type === "float") {
				return (
					<Float field={field} updateField={updateField} />
				);
			} else if (field.Type === "string") {
				return (
					<String field={field} updateField={updateField} />
				);
				// } else if (field.Type === "imageMultiselect") {
				// 	return (
				// 		<MultiSelectImage field={field} updateField={updateField} />
				// 	);
				// } else if (field.Type === "check") {
				// 	return (
				// 		<Check field={field} updateField={updateField} />
				// 	);
			} else if (field.Type === "imageDropdown") {
				return (
					<Dropdown field={field} updateField={updateField} />
				);
			} else if (field.Type === "dropdown") {
				return (
					<Dropdown field={field} updateField={updateField} />
				);
			} else if (field.Type === "int") {
				//console.log(field)
				return (
					<Integer field={field} updateField={updateField} />
				);
			}
		}
		return null;
	};

	//console.log(field)

	return (
		<Grid container item spacing={1} alignContent="center" alignItems="center">
			<Grid container item xs>
				{field ? renderFilterValues(field, updateField) : null}
			</Grid>
			{/* {!filtersView &&
			<Grid container item sx={{width: '40px'}}>
				{field.Ref === 'C11' && 
					<InterfaceInformation field={field} />
				}
				{field.Ref === 'W1' && 
					<Materials field={field} />
				}
				{field.Ref === 'J11' && 
					<IsoAssistant field={field} />
				}
			</Grid>
			} */}
		</Grid>
	)
};

export default InputTypes;
