import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Tabs,
    Tab,
    Card,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../language/setLanguageText';
import EngineerSuppliers from './engineer/EngineerSuppliers';

import Templates from '../../../templates/Templates';
import EccommerceSuppliers from './ecommerce/EccommerceSuppliers';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: isMobileOnly ? 'block' : 'flex',
        //height: 224,
    },
    dropdown: {
        width: '100%',
        padding: '10px 10px 10px 0',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: isMobileOnly ? '100%' : '240px',
        overflow: 'visible',
        background: theme.palette.tabs.menu
    },
    tabs_content: {
        width: '100%',
        overflow: 'auto',
        background: theme.palette.tabs.content
    },
}));



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className={classes.tabs_content}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function createProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CompanySettings = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const { user } = useContext(UserContext);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let tab = urlParams.get("tab")
        if (tab) {
            setValue(parseInt(tab));
        }
    }, [])

    const handleChange = (event, newValue) => {

        let urlParams = new URLSearchParams(window.location.search);
        let section = urlParams.get("section")
        navigate(`/settings/company?section=${section}&tab=${newValue}`)
        setValue(parseInt(newValue));
    };


    return (

        <Card className={classes.root} >
            <Tabs
                allowScrollButtonsMobile
                orientation={isMobileOnly ? 'horizontal' : 'vertical'}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Company Settings Menu"
                className={classes.tabs}
                sx={{
                    '& .MuiTab-root': {
                        textAlign: 'left',
                        alignItems: 'start',
                        borderBottom: '1px solid #e8e8e8'
                    }
                }}
            >
                <Tab label={setLanguageText("Catalogue")} {...createProps(0)} />
                <Tab label={setLanguageText("Engineer")} {...createProps(1)} />
                <Tab label={setLanguageText("Templates")} {...createProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <EccommerceSuppliers/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EngineerSuppliers />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Templates type={["engineer_brands","catalogue_brands","opensearch_brands"]} />
            </TabPanel>
        </Card>
    );
}

export default CompanySettings;


