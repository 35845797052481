import React, { useContext, useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Box, CardActionArea, CardActions, Dialog, DialogContent, Fade, Grid, IconButton, Skeleton, Tooltip, useTheme } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImageSlash, faTimes } from "@fortawesome/pro-light-svg-icons";
import { isMobileOnly } from 'react-device-detect';
import BrandLogo from "../../shop/reusable/BrandLogo";
import ProductGroupFull from "./ProductGroupFull";
import AddToBasket from "../../basket/AddToBasket";
import ProductDetails from "../cim/ProductDetails";
import { faDolly, faTruck } from "@fortawesome/pro-regular-svg-icons";
import ProductGroupFullList from "./ProductGroupFullList";
import AddToFavourites from "../../favourites/AddToFavourites";

const ProductGroupedItems = ({ product, isFetching }) => {

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [photo, setPhoto] = useState();
	const [noImage, setNoImage] = useState();
	const [loading, setLoading] = useState(true);
	const [erpDetails, setErpDetails] = useState();
	const [productDetails, setProductDetails] = useState(product);

	useEffect(() => {
		if (product) {
			setLoading(true);
			setProductDetails(product)
		}
	}, [product])


	useEffect(() => {
		function getWorkingImage(images) {
			const promises = images.map((image, i) => {
				//console.log(image)
				const selectedImage = image.url;
				return new Promise((resolve) => {
					const img = new Image();
					img.src = selectedImage;
					img.onload = () => resolve({ "url": selectedImage });
					img.onerror = () => resolve();
				});
			});
			Promise.all(promises).then((result) => {
				//console.log(result)
				if (result && Object.values(result).length && result[0] !== undefined) {
					//console.log(result)
					setPhoto(result[0]?.url);
					setNoImage(false);
					setLoading(false);
				} else {
					setPhoto();
					setNoImage(true);
					setLoading(false);
				}
			});
		}

		if (productDetails?.product_image) {
			getWorkingImage(productDetails.product_image)
		}

		return () => {
			setPhoto()
			setNoImage()
			setLoading(true)
		}

	}, [productDetails])

	return (
		<>
			<>
				<Card 
				sx={{
					position: 'relative',
					height: '100%',
					display: 'flex', justifyContent: 'space-between', flexDirection: 'column', background: (theme) => theme.palette.background.default
				}} >
					<Box
					sx={{
						position: 'absolute',
						top: 10,
						left: 10,
						zIndex: 2,
					}}
				>
					<AddToFavourites product={productDetails} area="catalogue" isFetching={isFetching} />
				</Box>
					<Box sx={{
						position: 'absolute',
						width: '90px',
						height: '40px',
						overflow: 'hidden',
						top: 0,
						right: 0,
						borderBottom: '1px solid #eeeeee29',
						borderLeft: '1px solid #eeeeee29',
						padding: '5px',
						background: '#ffffff60',
						borderRadius: '0 0 0 10px',
						cursor: 'pointer',
						zIndex: 2
					}}
						onClick={handleOpen}
					>
						{!isFetching ?
							<>
								{productDetails?.product_brand ?
									<Tooltip title={productDetails?.product_brand?.description}>
										<img
											src={productDetails?.product_brand?.url}
											alt={productDetails?.product_brand?.Boxdescription}
											onError={e => {
											}}
											style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0 auto' }}
										/>
									</Tooltip>
									:
									<Typography variant="caption">{productDetails?.product_brand?.description}</Typography>}
							</>
							: productDetails?.product_company ?
								<BrandLogo companyID={productDetails.product_company} />
								: null}
					</Box>
					<Box
						onClick={handleOpen}
						sx={{
							cursor: 'pointer',
							height: "140px",
							width: '100%',
							textAlign: 'center',
							background: '#fff',
							display: 'grid',
							position: 'relative'
						}}>
						<Skeleton variant="rectangular" width={'100%'} height={140} />
						{photo && !isFetching && !loading ?
							<Fade in={photo ? true : false}>
								<Box sx={{
									position: 'absolute',
									height: '140px',
									width: '100%',
									top: 0,
									left: 0,
									background: '#fff',
									zIndex: 1,
								}}>
									<img
										src={photo}
										alt=""
										style={{
											objectFit: "contain",
											padding: 10,
											background: '#fff',
											cursor: "pointer",
											height: '140px',
											width: '100%'
										}}
										onError={e => {
											setPhoto()
										}}
										onClick={handleOpen}
									/>
								</Box>
							</Fade>
							: noImage && !isFetching && !loading ?
								<Fade in={noImage ? true : false}>
									<Box sx={{
										position: 'absolute',
										height: '140px',
										width: '100%',
										top: 0,
										left: 0,
										background: '#fff',
										zIndex: 1,
									}}>
										<FontAwesomeIcon size="5x" icon={faImageSlash} color="#ccc" style={{ margin: '.5em auto' }} />
									</Box>
								</Fade>
								: null}
						<Grid container spacing={1 / 2}
							sx={{
								position: 'absolute',
								left: '10px',
								bottom: '10px',
								zIndex: 2
							}}>
							{erpDetails?.product_stock > 0 ?
								<Grid item >
									<Tooltip title={"Warehouse Stock Available"} placement="bottom" arrow>
										<Avatar
											//variant="rounded"
											sx={{
												width: '30px',
												height: '30px',
												float: 'left',
												cursor: 'pointer',
												backgroundColor: (theme) => theme.palette.primary.main,
												'& svg': {
													width: '18px',
													height: '18px'
												}
											}}
										>
											<FontAwesomeIcon icon={faDolly} />
										</Avatar>
									</Tooltip>
								</Grid>
								: null}
							<Grid item >
								{!isFetching ?
									<Tooltip title={"Manufacturer Stock Available"} placement="bottom" arrow>
										<Avatar
											//variant="rounded"
											sx={{
												width: '30px',
												height: '30px',
												float: 'left',
												cursor: 'pointer',
												backgroundColor: (theme) => theme.palette.primary.main,
												'& svg': {
													width: '18px',
													height: '18px'
												}
											}}
										>
											<FontAwesomeIcon icon={faTruck} />
										</Avatar>
									</Tooltip>
									:
									<Skeleton variant="circular" width={30} height={30} />
								}
							</Grid>
						</Grid>

					</Box>

					<CardActionArea
						onClick={handleOpen}
						sx={{
							display: 'block',
							height: '100%'
						}}
					>
						<CardContent
							sx={{
								background: (theme) => theme.palette.cards.main, width: '100%',
								flexDirection: 'row-reverse',
								display: "grid",
								height: "100%",
								alignContent: "start",
								padding: '0.5em 1em'
							}}
						>
							<Typography gutterBottom variant="p" fontWeight={700} component="div">
								{isFetching ? <Skeleton /> : productDetails?.product_name}
							</Typography>
							{isFetching ?
								<>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</>
								: productDetails ?
									<Grid item >
										<ProductGroupFullList product={productDetails} />
										<ProductDetails product={productDetails} isFetching={false} erpDetails={erpDetails} />
									</Grid>
									: null}




							{productDetails?.product_variants > 1 && !isFetching ?
								<Box
									sx={{
										padding: '.5em 1em',
										border: theme => `1px solid ${theme.palette.primary.main}`,
										color: theme => theme.palette.primary.main,
										fontSize: '14px',
										borderRadius: '5px',
										textAlign: 'center'

									}}
								>
									{productDetails?.product_variants} variants available
								</Box>
								: null}
						</CardContent>
					</CardActionArea>
					<CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
						<AddToBasket
							isFetching={isFetching || !productDetails}
							product={productDetails}
							area="buyers" //group products is only used in buyer area
							erpDetails={erpDetails}
							setErpDetails={setErpDetails}
						/>
					</CardActions>
				</Card>
				{open && productDetails &&
					<Dialog
						fullScreen={isMobileOnly ? true : false}
						maxWidth="md"
						open={open}
						onClose={handleClose}
						scroll='paper'
						sx={{ width: '100%' }}
					>
						<IconButton
							sx={{
								cursor: 'pointer',
								position: 'absolute',
								right: 16,
								top: 16,
								//background: (theme) => theme.palette.background.default,
								background: '#0000000f',
								padding: '10px 15px',
								zIndex: 200
							}}
							onClick={handleClose}
						>
							<FontAwesomeIcon
								aria-label="close"
								sx={{
									color: (theme) => theme.palette.grey[500],
								}}
								icon={faTimes}
							/>
						</IconButton>
						<DialogContent

							dividers={scroll === 'paper'}
							sx={{
								padding: '0px',
								width: '100%'
							}}
						>
							<ProductGroupFull productDetails={productDetails} closeDialog={handleClose} />
						</DialogContent>
					</Dialog>
				}
			</>
		</>
	);
}


export default ProductGroupedItems;
