import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Grid,
	ListItemText,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Button,
	Popover,
	List,
	Stack,
	Box,
	Divider,
	Alert,
	Select,
	Paper,
	InputBase,
	Typography,
	Backdrop,
	Fade
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSubtitles, faTimes } from "@fortawesome/pro-light-svg-icons";
import { UserContext } from "../store/UserContext";
import { useMutation } from "react-query";
import axiosRequest from '../axios/axoisRequest';
import config from "../config";;
import highlightText from "../helpers/highlightText";
import { fa00, faBarcodeRead } from "@fortawesome/pro-solid-svg-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyContext } from "../store/CompanyContext";
import { isMobile, isMobileOnly } from "react-device-detect";
import queryGetSuggestions from "../opensearch/queryGetSuggestions";
import useDebounce from "../shop/libs/useDebounce";
import useSearchCount from "../hooks/useSearchCount";

export default function SearchBar({ searchParams, setSearchParams, setFocused, setPagination, landing, large, link }) {

	const navigate = useNavigate();
	const { search } = useLocation();
	const { updateSearchCount } = useSearchCount();
	const query = new URLSearchParams(search);
	const searchValue = query.get('q');
	const searchType = query.get('type');
	const { user } = useContext(UserContext);
	const { selectedCompany } = useContext(CompanyContext);
	const suggestRef = useRef()
	const [suggestions, setSuggestions] = useState([]);
	const [inputValue, setInputValue] = useState(searchValue || '');
	const [type, setType] = useState(searchType || 'all');
	const [defaultManufacturers, setDefaultManufacturers] = useState(selectedCompany?.opensearch_template)
	const [open, setOpen] = useState(false);
	//const [options, setOptions] = useState([]);
	const [noResults, setNoResults] = useState(false);


	const getSuggestion = useMutation({
		mutationKey: ['suggestions'],
		mutationFn: ([debouceValue, defaultManufacturers, type]) => {

			//console.log(debouceValue)
			if (!defaultManufacturers) {
				return
			}

			let query = queryGetSuggestions({
				"term": debouceValue,
				"type": type,
				"size": isMobileOnly ? 3 : 10,
				"filters": {
					"manufacturer": defaultManufacturers
				},
			})

			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/suggest',
				api_key: user.api_key,
				method: 'post',
				body: {
					"query": query
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response?.hit?.hits)
			let suggestions = []
			if (response?.hits?.hits) {
				response.hits.hits.forEach((product) => {
					if (product._source) {
						//push source options

						if (type === "all") {
							suggestions.push({
								"title": product._source.description,
								"subtitle": `${product._source.product_id} | ${product._source.product_code}`,
								"value": product._source.description,
								"type": type
							})
						} else if (type === "erp") {
							suggestions.push({
								"title": product._source.product_id,
								"value": product._source.product_id,
								"type": type
							})
						} else if (type === "code") {
							suggestions.push({
								"title": product._source.product_code,
								"value": product._source.product_code,
								"type": type
							})
						} else if (type === "number") {
							suggestions.push({
								"title": product._source.supplier_number,
								"value": product._source.supplier_number,
								"type": type
							})
						} else if (type === "description") {
							suggestions.push({
								"title": product._source.description,
								"value": product._source.description,
								"type": type
							})
						}
					}
				})
				setNoResults(false)
				setSuggestions(suggestions)
				//setOptions(suggestions)
			}
			//no results
			if (response?.hits?.hits.length === 0) {
				setSuggestions()
				//setOptions()
				setNoResults(true)
			}
		}
	})


	useEffect(() => {
		if (selectedCompany?.opensearch_template) {
			setDefaultManufacturers(selectedCompany?.opensearch_template)
		} else {
			setDefaultManufacturers(null)
		}
		return () => {
			setDefaultManufacturers(null)
		}
	}, [selectedCompany])

	const debouceValue = useDebounce(inputValue, 400);

	useEffect(() => {
		if (debouceValue.length > 2 && defaultManufacturers) {
			//console.log(defaultManufacturers)
			getSuggestion.mutate([debouceValue, defaultManufacturers, type])
		}
	}, [debouceValue, defaultManufacturers, type]);

	const anchorRef = useRef(null);
	const inputRef = useRef(null);

	// Clicking outside the component should close the dropdown
	useEffect(() => {
		const handleClickOutside = (event) => {
			// if (landing && config.APPNAME === "act") {
			// 	return
			// }
			if (anchorRef.current && !anchorRef.current.contains(event.target)) {
				setFocused(false)
				setOpen(false);
			}
		};

		// Attach mousedown listener to document
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Cleanup listener on component unmount
			document.removeEventListener('mousedown', handleClickOutside);
			setFocused(false)
			setOpen(false);
		};
	}, [anchorRef]);

	const handleButtonClick = (type) => {
		setType(type)
		if (setSearchParams) {
			const newUrl = new URLSearchParams(searchParams);
			newUrl.set("type", type);
			setSearchParams(newUrl)
		}
		// if (!landing) {
		// 	setFocused(false)
		// 	setOpen(false)
		// }
	}


	return (
		<>
			<Grid
				ref={suggestRef}
				container
			>
				<Grid item xs >
					<Box
						sx={{
							position: 'relative',
						}}
						ref={anchorRef}>

						<Paper
							elevation={0}
							sx={{
								paddingBottom: '0px!important',
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								border: '1px solid rgba(0, 0, 0, 0.23)',
								'& input': {
									zIndex: 2,
									padding: '0.5px 0px',
								},
								'& > .MuiInputBase-root > input': {
									height: large ? isMobile ? 'auto' : '68px' : 'auto',
									fontSize: large ? isMobile ? '1rem' : '1.125em' : '1em',
									padding: '0.5px 0px',
								},
								'& .MuiSelect-select:focus': {
									backgroundColor: 'transparent!important'
								},
							}}
						>
							<Select
								variant="standard"
								value={type}
								onChange={(e) => {
									handleButtonClick(e.target.value)
								}}
								sx={{
									//backgroundColor: theme => theme.palette.background.paper,
									color: theme => theme.palette.text.primary,
									height: large ? isMobile ? 'auto' : '68px' : 'auto',
									//fontSize: large ? isMobile ? '.5rem' : '.75em' : '.5em',
									paddingLeft: '1em',
									zIndex: 2,
									'&:before, &:after': {
										border: 'none',
										display: 'none'
									}


								}}
								autoWidth
								//style={{ minWidth: 150 }}
								displayEmpty
								renderValue={(selected) => {
									switch (selected) {
										case 'all':
											return 'All';
										case 'erp':
											return config.APPNAME === "act" ? 'ACT No' : config.APPNAME === "cis" ? 'CIS No' : 'ERP No';
										case 'code':
											return 'Product No';
										case 'number':
											return 'Manufacturer No';
										case 'description':
											return 'Description';
										default:
											return 'Select';
									}
								}}

							>
								<MenuItem value="all">All</MenuItem>
								<MenuItem value="erp">{config.APPNAME === "act" ? 'ACT No' : config.APPNAME === "cis" ? 'CIS No' : 'ERP No'}</MenuItem>
								<MenuItem value="code">Product No</MenuItem>
								<MenuItem value="number">Manufacturer No</MenuItem>
								<MenuItem value="description">Description</MenuItem>
							</Select>
							<Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
							<InputBase

								ref={inputRef}
								fullWidth
								placeholder={isMobile ? "Search MILLIONS of products" : config.APPNAME === "cis" ? "Search 1.4 Million products by part numbers and description fields..." : "Search MILLIONS of products by part number and description fields..."}
								onClick={(e) => {
									e.preventDefault()
									//if (!open && e.target.value.length > 2) {
									if (!open) {
										setOpen(true)
										setFocused(true)
									}
								}}
								//onFocus={handleFocus}
								//onBlur={handleBlur}
								value={inputValue}
								onChange={(e) => {
									setInputValue(e.target.value)
									//if (!open && e.target.value.length > 2) {
									if (!open) {
										setOpen(true)
										setFocused(true)
									}
								}}
								endAdornment={
									<>
										{inputValue.length > 0 ?
											<Box
												sx={{
													padding: '1em',
													cursor: 'pointer'
												}}
												onClick={(e) => {
													e.preventDefault();
													setInputValue('');

													let pageSize = sessionStorage.getItem('pageSize');
													if (!pageSize) {
														pageSize = 12
													}

													const newUrl = new URLSearchParams();
													newUrl.set("q", "");
													newUrl.set("type", type);
													newUrl.set("page", 0);
													newUrl.set("size", pageSize);
													newUrl.set("sort", 1);
													setSearchParams(newUrl);

													setOpen(false);
													setFocused(false);
													document.activeElement.blur();
												}}
											>
												<FontAwesomeIcon icon={faTimes} />
											</Box>
											: null
										}
									</>
								}
								onKeyUp={(event) => {
									if (event.key === "Enter") {


										// // Send a custom event
										// ReactGA.event({
										// 	"category": "search",
										// 	"action": "search",
										// 	"label": "product search", // optional
										// 	// value: 99, // optional, must be a number
										// 	// nonInteraction: true, // optional, true/false
										// 	// transport: "xhr", // optional, beacon/xhr/image
										// 	"titleCase": false,
										// 	"customData": {
										// 		"company_id": selectedCompany?.company_id,
										// 		"user_id": user?.user_id,
										// 		"search_term": inputValue
										// 	}
										// });


										//get session page size
										let pageSize = sessionStorage.getItem('pageSize')
										if (!pageSize) {
											pageSize = 12
										}
										//if landing search
										if (link) {
											navigate(`/search/search?q=${inputValue}&type=${type}&page=0&size=${pageSize}&sort=1`)
										}
										if (setPagination) {
											setPagination(current => ({
												...current,
												pageIndex: 0
											}))
											//set url params
											const newUrl = new URLSearchParams(searchParams);
											newUrl.set("q", inputValue);
											newUrl.set("type", type);
											newUrl.set("page", 0);
											newUrl.set("sort", 1);
											setSearchParams(newUrl)
										}
										updateSearchCount.mutate('opensearch')
										//remove focus on dom element
										document.activeElement.blur()

										setOpen(false)
										setFocused(false)
									}
								}}
								variant="outlined"
								sx={{
									width: '100%',
									'& input': {
										zIndex: 2
									},
									'& > .MuiInputBase-root': {
										height: large ? isMobile ? 'auto' : '68px' : 'auto',
										fontSize: large ? isMobile ? '1rem' : '1.125em' : '1em',
										p: 0
									},
									'& .MuiSelect-select': {
										fontSize: '16px',
										marginLeft: '2px'
									},
									'& legend': { display: 'none' },
									'& fieldset': {
										backgroundColor: theme => theme.palette.background.paper,
										color: theme => theme.palette.text.primary,
									},
									'& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-tag': {
										zIndex: 2,
										top: "auto"
									}
								}}
							/>

						</Paper>
						<Fade in={open} >
							<Box
								sx={{
									position: 'absolute',
									zIndex: 3,
									width: '100%',
									backgroundColor: theme => theme.palette.background.paper,
									border: '1px solid rgba(0, 0, 0, 0.23)',
									borderTop: 'none',
								}}
							// open={open}
							// anchorEl={anchorRef.current}
							// onClose={() => {
							// 	if (landing && config.APPNAME === "act") {
							// 		setOpen(false)
							// 	} else {
							// 		setOpen(false)
							// 		setFocused(false)
							// 	}
							// }}
							// slotProps={{
							// 	paper: {
							// 		sx: {
							// 			marginLeft: '1px',
							// 			width: isMobile ? '100%' : 'auto',
							// 			left: isMobile ? '-5px' : 'inherit',
							// 			//</div>borderLeft: '1px solid #ccc',
							// 		}
							// 	}
							// }}

							// // sx={{
							// // 	width: '100%',
							// // 	borderLeft: '1px solid #ccc',
							// // }}
							// anchorOrigin={{
							// 	vertical: 'bottom',
							// 	horizontal: 'left',
							// }}
							// transformOrigin={{
							// 	vertical: 'top',
							// 	horizontal: 'left',
							// }}
							// disablePortal
							// disableAutoFocus

							>
								{!defaultManufacturers ?

									<Alert severity="info" sx={{ margin: '1em' }}>
										Please contact an administrator to allow access.
									</Alert>
									:
									<>
										{!isMobileOnly ?
											<Stack direction="row" spacing={2} sx={{ p: 2 }}>
												<Button
													onClick={(e) => handleButtonClick('all')}
													variant={"outlined"}
													color="primary"
													sx={{
														backgroundColor: theme => type === 'all' ? theme.palette.primary.main : 'transparent',
														color: theme => type === 'all' ? theme.palette.primary.contrastText : theme.palette.primary.main,
														'&:hover': {
															color: theme => theme.palette.primary.main,
														}
													}}
												>
													All
												</Button>
												<Button
													onClick={(e) => handleButtonClick('erp')}
													variant={"outlined"}
													color="primary"
													sx={{
														backgroundColor: theme => type === 'erp' ? theme.palette.primary.main : 'transparent',
														color: theme => type === 'erp' ? theme.palette.primary.contrastText : theme.palette.primary.main,
														'&:hover': {
															color: theme => theme.palette.primary.main,
														}
													}}
												>
													{config.APPNAME === "act" ? 'ACT No' : config.APPNAME === "cis" ? 'CIS No' : 'ERP No'}
												</Button>
												<Button
													onClick={(e) => handleButtonClick('code')}
													variant={"outlined"}
													color="primary"
													sx={{
														backgroundColor: theme => type === 'code' ? theme.palette.primary.main : 'transparent',
														color: theme => type === 'code' ? theme.palette.primary.contrastText : theme.palette.primary.main,
														'&:hover': {
															color: theme => theme.palette.primary.main,
														}
													}}
												>
													Product No
												</Button>
												<Button
													onClick={(e) => handleButtonClick('number')}
													variant={"outlined"}
													color="primary"
													sx={{
														backgroundColor: theme => type === 'number' ? theme.palette.primary.main : 'transparent',
														color: theme => type === 'number' ? theme.palette.primary.contrastText : theme.palette.primary.main,
														'&:hover': {
															color: theme => theme.palette.primary.main,
														}
													}}
												>
													Manufacturer No
												</Button>
												<Button
													onClick={(e) => handleButtonClick('description')}
													variant={"outlined"}
													color="primary"
													sx={{
														backgroundColor: theme => type === 'description' ? theme.palette.primary.main : 'transparent',
														color: theme => type === 'description' ? theme.palette.primary.contrastText : theme.palette.primary.main,
														'&:hover': {
															color: theme => theme.palette.primary.main,
														}
													}}
												>
													Description
												</Button>
											</Stack>
											: null}
										<Divider />
										<List>
											{suggestions && !noResults && debouceValue.length > 2 && suggestions.map((option, index) => (
												<MenuItem

													key={index}
													//exend the props
													onClick={(event) => {

														setInputValue(option.value)
														//get session page size
														let pageSize = sessionStorage.getItem('pageSize')
														if (!pageSize) {
															pageSize = 12
														}
														//if link to full search
														if (link) {
															navigate(`/search/search?q=${option.value}&type=${type}&page=0&size=${pageSize}&sort=1`)
														}
														if (setPagination) {
															setPagination(current => ({
																...current,
																pageIndex: 0
															}))
														}
														if (setSearchParams) {
															//set url params
															const newUrl = new URLSearchParams();
															newUrl.set("q", option.value);
															newUrl.set("type", type);
															newUrl.set("page", 0);
															newUrl.set("size", pageSize);
															newUrl.set("sort", 1);
															setSearchParams(newUrl)
															//props.onClick(event);
														}
														updateSearchCount.mutate('opensearch')
														setOpen(false)
														setFocused(false)
													}}

												>
													<ListItemIcon>
														{option.type === "description" ?
															<Tooltip title="Descirption" arrow>
																<FontAwesomeIcon icon={faSubtitles} size="sm" />
															</Tooltip>
															: option.type === "code" ?
																<Tooltip title="Product Code" arrow>
																	<FontAwesomeIcon icon={faBarcodeRead} size="sm" />
																</Tooltip>
																: option.type === "number" ?
																	<Tooltip title="Product Number" arrow>
																		<FontAwesomeIcon icon={fa00} size="sm" />
																	</Tooltip>
																	:
																	<FontAwesomeIcon icon={faSearch} size="sm" />
														}
													</ListItemIcon>
													<ListItemText
														disableTypography
														sx={{
															whiteSpace: 'normal',
														}}
														// sx={{
														// 	fontSize: '10px',
														// 	fontWeight: '700',
														// 	color: "#ccc",
														// 	'& span': {
														// 		fontWeight: '700',
														// 		color: "#000"
														// 	},
														// 	'& em': {
														// 		fontStyle: 'normal',
														// 		fontWeight: '300',
														// 	}
														// }}
														primary={highlightText(option.title, inputValue)}
														secondary={<Typography component="div" variant="caption">{option.subtitle ? highlightText(option.subtitle, inputValue) : null}</Typography>}
													/>
												</MenuItem>
											))}
											{inputValue.length > 2 && !noResults &&
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'right',
														alignItems: 'right',

														p: '1em 1em 0.5em 1em'
													}}
												>
													<Button
														color="primary"
														variant="contained"
														size="large"
														fullWidth
														startIcon={<FontAwesomeIcon icon={faSearch} size="sm" />}
														sx={{
															fontSize: '16px',
														}}
														onClick={(event) => {


															// // Send a custom event
															// ReactGA.event({
															// 	"category": "search",
															// 	"action": "search",
															// 	"label": "product search", // optional
															// 	// value: 99, // optional, must be a number
															// 	// nonInteraction: true, // optional, true/false
															// 	// transport: "xhr", // optional, beacon/xhr/image
															// 	"titleCase": false,
															// 	"customData": {
															// 		"company_id": selectedCompany?.company_id,
															// 		"user_id": user?.user_id,
															// 		"search_term": inputValue
															// 	}
															// });

															//get session page size
															let pageSize = sessionStorage.getItem('pageSize')
															if (!pageSize) {
																pageSize = 12
															}
															//if link to full search
															if (link) {
																navigate(`/search/search?q=${inputValue}&type=${type}&page=0&size=${pageSize}&sort=1`)
															}
															if (setPagination) {
																setPagination(current => ({
																	...current,
																	pageIndex: 0
																}))
															}
															if (setSearchParams) {
																//set url params
																const newUrl = new URLSearchParams();
																newUrl.set("q", inputValue);
																newUrl.set("type", type);
																newUrl.set("page", 0);
																newUrl.set("size", pageSize);
																newUrl.set("sort", 1);
																setSearchParams(newUrl)
																//props.onClick(event);
															}
															updateSearchCount.mutate('opensearch')
															setOpen(false)
															setFocused(false)
														}}
													>
														{/* <ListItemIcon>
														<FontAwesomeIcon icon={faSearch} size="sm" />
													</ListItemIcon>
													<ListItemText
														sx={{
															//fontSize: '10px',
															//color: "#ccc",
															'& span': {
																fontWeight: '700',
																//color: "#000"
															},
															'& em': {
																fontStyle: 'normal',
																fontWeight: '300',
															}
														}}
													> */}

														<em>Search {!isMobile ?
															type === "all" ? 'all products with ' :
																type === "code" ? "product number " :
																	type === "number" ? "manufacturer numbers " :
																		"descriptions with " : null} </em><strong>&nbsp; &quot;{inputValue}&quot;</strong>
														{/* </ListItemText> */}
													</Button>
												</Box>

											}

											{noResults &&
												<MenuItem
													disabled
													sx={{
														opacity: "1!important"
													}}
												>
													<Alert severity="info" >
														No suggestions found, please refine you search term or select another search type
													</Alert>
												</MenuItem>

											}
										</List>
									</>
								}
							</Box>
						</Fade>
					</Box>
				</Grid>

				<Grid item>
					<Button
						color="primary"
						variant="contained"
						sx={{
							//position: 'absolute',
							marginLeft: '5px',
							boxShadow: 'none',
							height: large ? isMobile ? '100%' : '70px' : 'auto',
							p: '1em 2em',
							zIndex: 2,
							'&:hover': {
								boxShadow: 'none'
							}

						}}
						aria-label="directions"
						onClick={e => {
							//get session page size
							let pageSize = sessionStorage.getItem('pageSize')
							if (!pageSize) {
								pageSize = 12
							}
							//if link to full search
							if (link) {
								navigate(`/search/search?q=${inputValue}&type=${type}&page=0&size=${pageSize}&sort=1`)
							}
							//reset the filters
							if (setPagination) {
								setPagination(current => ({
									//...current,
									pageIndex: 0,
									pageSize: pageSize
								}))
							}
							if (setSearchParams) {
								//set url params
								const newUrl = new URLSearchParams(searchParams);
								newUrl.set("q", inputValue);
								newUrl.set("type", type);
								newUrl.set("page", 0);
								newUrl.set("size", pageSize);
								setSearchParams(newUrl)
							}
							updateSearchCount.mutate('opensearch')
							setFocused(false)
						}}
					>
						{/* {getProducts.isLoading ? <CircularProgress size={24} /> : <FontAwesomeIcon icon={faSearch} size="sm" />} */}
						<FontAwesomeIcon
							icon={faSearch}
							size={large ? isMobile ? "lg" : "2x" : "lg"}
						/>
					</Button>
				</Grid>

			</Grid>
		</>
	);
};